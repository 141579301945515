import { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import _ from 'lodash';
import ProductVendor, { createEmptyVendor } from 'model/ProductVendor';
import { ProductVendorsStatus } from 'reducers/productVendors/ProductVendorsState';
import VendorDetails from './VendorDetails';
import { Button } from 'components/UI';
import VendorProductLibrary from './VendorProductLibrary';
import {
    deleteThunk,
    fetchThunk as fetchVendorsThunk,
    saveThunk
} from 'actions/productVendors/ProductVendorsActions';
import ConfirmDialog from '../UI/Dialog';
import {
    isEmailValid,
    validatePhoneNumber,
    validateZipcode
} from '@spike/validations';
import { validateRequired } from 'utils/ValidationUtils';
import { FieldError } from '@spike/model';
import { fetchProductsThunk } from '@spike/products-action';
import { fetchThunk as fetchCategoriesThunk } from 'actions/productCategories/ProductCategoriesActions';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import WarningDialog from '../UI/WarningDialog';
import { useApiClientWrapper, useMarketplace } from 'hooks';

interface Props {
    onBack: (redirection: boolean) => void;
    vendor?: ProductVendor;
    redirection: boolean;
    vendorSaved: (vendor: ProductVendor) => void;
    onRedirection: () => void;
}

interface SectionState {
    collapsed: boolean;
    disabled: boolean;
    editable: boolean;
    completed: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: '#FAFAFA',
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        newVendorContainer: {
            [theme.breakpoints.down('md')]: {
                height: 'calc(100vh - 88px)'
            },
            [theme.breakpoints.up('lg')]: {
                height: 'calc(100vh - 110px)'
            }
        },
        headerContainer: {
            backgroundColor: '#FAFAFA',
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '120px',
                paddingRight: '120px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: '150px',
                paddingRight: '150px'
            }
        },
        bodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: '#FAFAFA',
            height: '100%',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '112px',
                paddingRight: '112px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: '140px',
                paddingRight: '140px'
            }
        },
        backContainer: {
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            width: '32px',
            height: '32px',
            [theme.breakpoints.down('md')]: {
                marginTop: '32px',
                marginLeft: '-72px',
                fontSize: '29px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '40px',
                marginLeft: '-90px',
                fontSize: '36px'
            }
        },
        titleButtonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                minWidth: '800px',
                marginTop: '25px',
                marginBottom: '22px'
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: '1000px',
                marginTop: '31px',
                marginBottom: '27px'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%'
        },
        title: {
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: '29px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '36px'
            }
        },
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                minWidth: '800px',
                marginTop: '31px'
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: '1000px',
                marginTop: '39px'
            }
        },
        saveMessage: {
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '15px'
            }
        },
        detailsAndLibraryContainer: {
            [theme.breakpoints.down('md')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '30px'
            }
        },
        saveButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '50%'
        },
        saveButton: {
            [theme.breakpoints.down('md')]: {
                width: '136px',
                height: '39px'
            },
            [theme.breakpoints.up('lg')]: {
                width: '170px',
                height: '49px'
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down('md')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.up('lg')]: {
                    fontSize: '16px'
                }
            }
        },
        changeStatusButton: {
            fontWeight: 600,
            float: 'right',
            cursor: 'pointer',
            color: '#92B4A7',
            [theme.breakpoints.down('md')]: {
                marginBottom: '16px',
                marginRight: '29px'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '20px',
                marginRight: '36px'
            }
        },
        buttonContainer: {
            width: '100%'
        },
        textDialog: {
            fontWeight: 400,
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        },
        addProductButton: {
            [theme.breakpoints.down('md')]: {
                marginRight: '22px',
                height: '39px'
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: '27px',
                height: '49px'
            }
        },
        textdialog: {
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                fontSize: '13px',
                lineHeight: '22px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px',
                lineHeight: '27px'
            }
        },
        titleDialog: {
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: '19px',
                lineHeight: '29px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '24px',
                lineHeight: '36px'
            }
        },
        deleteContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        deleteContent: {
            display: 'flex'
        },
        deleteBox: {
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {
                paddingBottom: '48px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingBottom: '60px'
            }
        },
        iconDelete: {
            fontWeight: 500,
            color: '#7A7A7A',
            [theme.breakpoints.down('md')]: {
                fontSize: '13px',
                marginRight: '5px',
                marginTop: '2px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px',
                marginRight: '6px',
                marginTop: '3px'
            }
        },
        delete: {
            fontWeight: 500,
            color: '#7A7A7A',
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        }
    })
);

enum ProductStep {
    NewStep,
    EditStep
}

export const VendorComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();

    const [errors, setErrors] = useState<Array<FieldError>>([]);

    const newVendor = createEmptyVendor();
    const [vendor, setVendor] = useState(
        props.vendor ? props.vendor : newVendor
    );
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [assignProducts, setAssignProducts] = useState(false);

    const [showWarningDeleteVendor, setShowWarningDeleteVendor] =
        useState(false);

    const [showWarningAssignedProducts, setShowWarningAssignedProducts] =
        useState(false);

    const [details, setDetails] = useState<SectionState>({
        collapsed: false,
        disabled: false,
        editable: true,
        completed: false
    });

    const [productLibrary, setProductLibrary] = useState<SectionState>({
        collapsed: false,
        disabled: false,
        editable: true,
        completed: false
    });

    const isNewVendor = () => {
        return props.vendor === undefined;
    };

    const loading = useSelector<RootState, boolean>(
        state => state.productVendors.loading
    );
    const status = useSelector<RootState, ProductVendorsStatus>(
        state => state.productVendors.status
    );

    const [step] = useState<ProductStep>(
        isNewVendor() ? ProductStep.NewStep : ProductStep.EditStep
    );

    useEffect(() => {
        if (status === ProductVendorsStatus.SaveSuccess) {
            props.vendorSaved(vendor);
            dispatch(fetchProductsThunk(apiClientWrapper));
            dispatch(fetchVendorsThunk());
            dispatch(fetchCategoriesThunk());
            props.onBack(false);
            setTimeout(() => {
                setUnsavedChanges(false);
            }, 1500);
            if (props.redirection) {
                props.onRedirection();
            }
        }
        if (status === ProductVendorsStatus.DeleteSuccess) {
            dispatch(fetchProductsThunk(apiClientWrapper));
            dispatch(fetchVendorsThunk());
            dispatch(fetchCategoriesThunk());
            props.onBack(false);
            setTimeout(() => {
                setUnsavedChanges(false);
            }, 1500);
        }
    }, [status]);

    useEffect(() => {
        setUnsavedChanges(
            !_.isEqual(props.vendor, vendor) && !_.isEqual(newVendor, vendor)
        );
    }, [vendor]);

    useEffect(() => {
        if (props.vendor) {
            setVendor(props.vendor);
        }
    }, [props.vendor]);

    const detailsEditHandler = () => {
        setDetails(prev => {
            return { ...prev, collapsed: !prev.collapsed };
        });
    };

    const productLibraryEditHandler = () => {
        setProductLibrary(prev => {
            return { ...prev, collapsed: !prev.collapsed };
        });
    };

    const cleanErrors = () => {
        setErrors([]);
    };

    const validate = (vendorToValidate: ProductVendor) => {
        const errors: Array<FieldError> = [
            validateRequired('name', { ...vendorToValidate }, 'Company name')
        ].filter((t): t is FieldError => !!t);

        vendorToValidate.phone &&
            errors.push(
                ...validatePhoneNumber(
                    vendorToValidate.phone,
                    marketplace.basics.address.country!.id,
                    'phone'
                )
            );

        if (vendorToValidate.email && !isEmailValid(vendorToValidate.email)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Invalid Email format.'
            });
        }

        vendorToValidate.address &&
            vendorToValidate.address?.zipcode !== undefined &&
            errors.push(
                ...validateZipcode(
                    vendorToValidate.address.zipcode,
                    marketplace.basics.address.country?.id,
                    'zipcode'
                )
            );

        setErrors([...errors]);

        return errors;
    };

    const saveHandler = () => {
        const validationErrors = validate(vendor);
        if (validationErrors.length === 0) {
            dispatch(saveThunk(vendor));
        }
    };

    const handleChangeVendor = (vendorModified: ProductVendor) => {
        cleanErrors();
        validate(vendorModified);
        setVendor(vendorModified);
    };

    const handleDeleteVendor = () => {
        if (vendor.products.length) {
            setShowWarningAssignedProducts(true);
        } else {
            if (vendor.id) {
                setShowWarningDeleteVendor(true);
            }
        }
    };

    const handleConfirmDeleteVendor = () => {
        const validationErrors = validate(vendor);
        if (validationErrors.length === 0) {
            dispatch(deleteThunk(vendor));
        }
    };

    const addVendorHeaderTitle = (
        <Typography className={classes.title}>Add Vendor</Typography>
    );

    const newVendorNameHeaderTitle = (
        <Fragment>
            <Typography className={classes.title}>{vendor.name}</Typography>
        </Fragment>
    );
    const editVendorNameHeaderTitle = (
        <Typography className={classes.title}>{vendor.name}</Typography>
    );

    const vendorNameHeaderTitle = isNewVendor()
        ? newVendorNameHeaderTitle
        : editVendorNameHeaderTitle;

    const headerTitle = vendor.name
        ? vendorNameHeaderTitle
        : addVendorHeaderTitle;

    const header = (
        <Fragment>
            <Box
                className={classes.backContainer}
                onClick={() => props.onBack(props.redirection)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>{headerTitle}</Box>
                <Box className={classes.saveButtonContainer}>
                    {vendor.products.length > 0 && (
                        <Button
                            label="+ Add/Delete Products"
                            variant="secondary"
                            onClick={() => setAssignProducts(true)}
                            className={classes.addProductButton}
                        />
                    )}
                    <Button
                        label="Save"
                        onClick={saveHandler}
                        disabled={false}
                        loading={loading}
                        className={classes.saveButton}
                    />
                </Box>
            </Box>
        </Fragment>
    );

    const newContent = (
        <Fragment>
            <Box className={classes.sectionContainer}>
                <Box className={classes.detailsAndLibraryContainer}>
                    <VendorDetails
                        {...details}
                        vendor={vendor}
                        onEdit={() => {}}
                        onChange={handleChangeVendor}
                        errors={errors}
                    />
                </Box>
                {vendor.id && (
                    <Box className={classes.deleteBox}>
                        <Box className={classes.deleteContainer}>
                            <Box
                                className={classes.deleteContent}
                                onClick={handleDeleteVendor}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className={classes.iconDelete}
                                />
                                <Typography className={classes.delete}>
                                    Delete Vendor
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Fragment>
    );

    const editContent = (
        <Fragment>
            <Box className={classes.sectionContainer}>
                <Box className={classes.detailsAndLibraryContainer}>
                    <VendorDetails
                        {...details}
                        vendor={vendor}
                        onEdit={detailsEditHandler}
                        onChange={handleChangeVendor}
                        errors={errors}
                    />
                </Box>
                <Box className={classes.detailsAndLibraryContainer}>
                    <VendorProductLibrary
                        {...productLibrary}
                        vendor={vendor}
                        onEdit={productLibraryEditHandler}
                        assignProducts={assignProducts}
                        onCloseAssignProducts={() => setAssignProducts(false)}
                    />
                </Box>
                {vendor.id && (
                    <Box className={classes.deleteBox}>
                        <Box className={classes.deleteContainer}>
                            <Box
                                className={classes.deleteContent}
                                onClick={handleDeleteVendor}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className={classes.iconDelete}
                                />
                                <Typography className={classes.delete}>
                                    Delete Vendor
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Fragment>
    );

    const stepContent = (): JSX.Element => {
        switch (step) {
            case ProductStep.NewStep: {
                return newContent;
            }
            default: {
                return editContent;
            }
        }
    };

    return (
        <Fragment>
            <Box
                className={clsx(classes.container, {
                    [classes.newVendorContainer]: step === ProductStep.NewStep
                })}
            >
                <Box className={clsx(classes.headerContainer)}>{header}</Box>
                <Box className={clsx(classes.bodyContainer)}>
                    {stepContent()}
                    <ConfirmDialog
                        open={showWarningDeleteVendor}
                        centered
                        onCancel={() => setShowWarningDeleteVendor(false)}
                        onConfirm={handleConfirmDeleteVendor}
                        question={
                            <Typography className={classes.titleDialog}>
                                Are you sure you want to delete this vendor?
                            </Typography>
                        }
                        subQuestion={
                            <Typography className={classes.textdialog}>
                                Deleting vendor will permanently remove it from
                                your database. You can always recreate it in the
                                future.
                            </Typography>
                        }
                        confirmButtonLabel="Delete"
                        cancelButtonLabel="Nevermind"
                    />
                    <WarningDialog
                        open={showWarningAssignedProducts}
                        title={`(${vendor.products.length}) products currently assigned to this vendor."`}
                        description="In order to delete, you must first remove products assigned to this vendor."
                        onOk={() => setShowWarningAssignedProducts(false)}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default VendorComponent;
