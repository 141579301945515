import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { wbp, headerHeight, sidebarWidth, pageSidebarWidth } from 'Theme';
import { PropsWithChildren } from 'react';
interface OverDetailProps extends PropsWithChildren {
	className?: string;
	withoutScroll?: boolean;
	fullWindow?: boolean;
	fullHeight?: boolean;
	pageSidebar?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: (props: OverDetailProps) => ({
			top: props.fullHeight ? 0 : 56,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 2,
			padding: 0,
			display: 'flex',
			position: 'fixed',
			backgroundColor: '#ffffff',

			[theme.breakpoints.up(wbp)]: {
				top: props.fullWindow ? 0 : `${headerHeight}px`,
				left: props.fullWindow
					? 0
					: props.pageSidebar
					? `${sidebarWidth + pageSidebarWidth}px`
					: `${sidebarWidth}px`
			}
		}),
		detailContainer: {
			display: 'flex',
			width: '100%',
			height: '100%',
			background: 'white'
		},
		scroll: {
			overflowY: 'auto',
			width: '100%',
			minHeight: '100%'
		}
	})
);

export const OverDetail: FunctionComponent<OverDetailProps> = props => {
	const classes = useStyles(props);
	const header = document.querySelector<HTMLElement>('.MuiAppBar-root');

	useEffect(() => {
		if (header)
			header.style.cssText =
				'position: fixed; top: 0px; left: 0px; right: 0px;';
		document.body.style.overflow = 'hidden';
	}, []);

	useEffect(
		() => () => {
			if (header) header.style.cssText = '';
			document.body.style.overflow = 'auto';
		},
		[]
	);

	const detail = (
		<Box className={classes.detailContainer}>{props.children}</Box>
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			{props.withoutScroll && detail}
			{!props.withoutScroll && (
				<Box className={classes.scroll}>{detail}</Box>
			)}
		</Box>
	);
};

export default OverDetail;
