export interface RevenuesState {
  today: number;
  status: RevenuesStatus;
  loading: boolean;
}

export enum RevenuesStatus {
  Initial,
  FetchingToday,
  FetchTodaySuccess,
  Error
} 
