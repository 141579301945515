import { FunctionComponent, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BodySection } from "components/UI/Section";
import Section from "components/Products/UI/Section";
import ProductVendor from "model/ProductVendor";
import { Typography } from "@material-ui/core";
import Table from "../Table";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Button } from "components/UI";
import AssignProductsComponent from "./AssignProductsComponent";

interface Props {
  vendor: ProductVendor;
  collapsed?: boolean;
  disabled?: boolean;
  editable?: boolean;
  completed?: boolean;
  modifiable?: boolean;
  assignProducts: boolean;
  onEdit: () => void;
  onCloseAssignProducts: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        height: "372px"
      },
      [theme.breakpoints.up("lg")]: {
        height: "465px"
      },
    },
    productLibraryContainer: {
      display: "flex",
      justifyContent: "center",
    },
    productLibrary: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center"
    },
    productLibraryMessage: {
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        marginBottom: "16px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        marginBottom: "20px"
      },
    },
    productsTableContainer: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "-47px"
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "-59px"
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      width: "auto"
    }
  })
);

export const VendorProductLibrary: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showAssignProducts, setShowAssignProducts] = useState(props.assignProducts);
  const [vendor, setVendor] = useState(props.vendor);

  useEffect(() => {
    setShowAssignProducts(props.assignProducts); 
  }, [props.assignProducts]);

  const handleCloseAssignProducts = (vendorSaved: ProductVendor | undefined) => {
    setShowAssignProducts(false);
    if(vendorSaved) {
      setVendor(vendorSaved);
    }
    props.onCloseAssignProducts();
  };
  
  const assignProducts = (
    <AssignProductsComponent 
      vendor={vendor} 
      onClose={handleCloseAssignProducts} 
    />
  )
  
  return (
    <Section title="Products Library" {...props} onEdit={() =>props.onEdit()}>
      <BodySection>
        <Box className={classes.container}>
          {!showAssignProducts && vendor.products.length > 0 ? 
            <Box className={classes.productsTableContainer}>
              <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
                <Table 
                  products={vendor.products}
                />
              </OverlayScrollbarsComponent>
            </Box>
            :
            <Box className={classes.productLibraryContainer}>
              <Box className={classes.productLibrary}>
                <Typography className={classes.productLibraryMessage}>No products have been added to this vendor yet.</Typography>
                <Box className={classes.buttonContainer}>
                  <Button label="Add Products to Vendor" variant="secondary" className={classes.button} onClick={() => setShowAssignProducts(true)}/>
                </Box>
              </Box>
            </Box>
          }
          {showAssignProducts && assignProducts}
        </Box>
      </BodySection>
    </Section>
  );
};

export default VendorProductLibrary;