import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableContainer, Table as TableMUI } from "@material-ui/core";
import TableHeader from "./TableHeader";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { Product } from "@spike/product-model";
import VendorRow from "./VendorRow";

interface Props {
  product: Product;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      display: "flex",
      width: "100%",
      overflowX: "visible",
    },
  })
);

export const Table: FunctionComponent<Props> = (props) => {
  const classes = useStyles(); 

  

  return (
    <TableContainer className={classes.tableContainer}>
    <TableMUI>
      <TableHeader />
      <TableBodyMUI>
          {props.product && props.product.vendors && props.product.vendors.map((v) => {
            return <VendorRow 
                      key={v.uuid}
                      vendorCost={v} 
                    />
          })}
        </TableBodyMUI>
      </TableMUI>
    </TableContainer>
  );
};

export default Table;
