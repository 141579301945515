import { faFileSignature } from '@fortawesome/pro-regular-svg-icons';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import IconCircle from 'components/UI/IconCircle';
import TitlePrimary from 'components/UI/Title/TitlePrimary';
import { Fragment, FunctionComponent } from 'react';
import PrimaryButton from 'rocket/components/Buttons/PrimaryButton';

interface Props {
    buttonAction: () => void;
    buttonLabel: string;
    title: string;
    subtitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 150,
            flex: 1,
            fontSize: 15,
            lineHeight: 1.4,
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            heigth: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingTop: 75,
            },
            '& a': {
                display: 'inline-block'
            }
        },
        icon: {
            margin: '0px auto 15px',
            '& svg': {
                marginRight: -10
            }
        },
        paragraph: {
            fontSize: 15,
            lineHeight: 1.4,
            marginBottom: 20,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300
            }
        },
        title: {
            [theme.breakpoints.down('sm')]: {
                fontSize: 28,
                fontWeight: 600,
                color: 'black',
                fontFamily: 'Poppins'
            }
        },
        button: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: 35,
            paddingRight: 35,
            margin: '0px auto',
            display: 'inline-block'
        }
    })
);

const AgreementsNotFound: FunctionComponent<Props> = props => {
    const classes = useStyles();
    return (
        <Fragment>
            <Box className={classes.root}>
                <IconCircle icon={faFileSignature} className={classes.icon} />
                <TitlePrimary className={classes.title}>
                    {props.title}
                </TitlePrimary>
                <Typography className={classes.paragraph}>
                    {props.subtitle}
                </Typography>
                <PrimaryButton
                    label={props.buttonLabel}
                    className={classes.button}
                    onClick={props.buttonAction}
                />
            </Box>
        </Fragment>
    );
};

export default AgreementsNotFound;
