import React, { FunctionComponent, useState } from "react";
import { Box, Typography, makeStyles, Theme, createStyles, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { FieldError } from "@spike/model";
import { useFieldStyles } from "./FieldStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { wbp, reduceResolution } from "Theme";
import { ActionsPopup } from "./ActionsPopup/ActionsPopup";

export interface LabelFieldProps {
  label?: string | JSX.Element;
  required?: boolean;
  errors?: Array<FieldError>;
  name?: string;
  information?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      height: "100%"
    },
    infoIcon: {
      color: "#5E8677",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        marginLeft: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        marginLeft: "10px",
      },
      "&:hover": {
        [theme.breakpoints.up("md")]: {
          "& ~ .info": {
            display: "block",
          }
        }
      }
    },
    infoContainer: {
      position: "absolute",
      zIndex: 10,
      bottom: 20,
      left: 0,
      width: "100%",
      backgroundColor: "black",
      display: "none",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(8)}px`,
        padding: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "8px",
        padding: "14px",
      },
    },
    info: {
      fontFamilty: "Poppins",
      fontWeight: 400,
      lineHeight: "120%",
      color: "white",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },
    label: {
      [theme.breakpoints.up('md')]: {
        fontWeight: 500
      }
    }
  })
);



export const LabelField: FunctionComponent<LabelFieldProps> = (props) => {
  const fieldClasses = useFieldStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPopupInfo, setShowPopupInfo] = useState(false);

  const hasError = props.errors?.some((error) => error.fieldName === props.name);
  const label = hasError ? props.errors?.find((error) => error.fieldName === props.name)!.errorMessage : props.label;

  return props.label ? (
    <Box className={clsx(props.className, fieldClasses.labelContainer)}>
      <Typography className={clsx(fieldClasses.label, { [fieldClasses.labelError]: hasError }, classes.label)}>{label}</Typography>
      {props.required && !hasError &&<Typography className={fieldClasses.required}>*</Typography>}

      {props.information && <Box className={classes.iconContainer}><FontAwesomeIcon onClick={isSmall ? () => { setShowPopupInfo(true) } : undefined} icon={faCircleInfo} className={classes.infoIcon} />
        {!isSmall && <Box className={clsx(classes.infoContainer, "info")}><Typography className={classes.info}>{props.information}</Typography></Box>}
        
        {isSmall && props.information && showPopupInfo && (
          <ActionsPopup
            info={props.information}
            onClose={() => setShowPopupInfo(false)}
          />
        )}
      </Box>}

      
    </Box>
  ) : null;
};

export default LabelField;
