import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { Box, TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    fetchBankAccountsThunk,
    PaymentsStatus,
    setBankAccountAsPrimaryNewProfileThunk,
    setBankAccountAsPrimaryThunk,
    getHopLinkThunk
} from '@spike/payments-action';
import {
    PaymentsBankAccount as BankAccountModel,
    EnrollmentStatus
} from '@spike/payments-model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { useApiClientWrapper, useAuth } from 'hooks';
import {
    Fragment,
    FunctionComponent,
    useEffect,
    useState,
    useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { Header } from '../ui/Header';
import Table from '../Table';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';
import AccountType from './AccountType';
import Title from '../ui/Title';
import Dropdown from 'components/Agreements/ui/Dropdown';
import BankAccountCardMobile from './BankAccountCardMobile';
import { AlertBox } from 'components/UI/Alerts';
import NoBankAccounts from './NoBankAccounts';
import RestictedDialog from 'components/Payments/ui/RestictedDialog';
import { OWNER_ID } from '@spike/model';
import { isNewAccount } from 'utils/PaymentsUtils';

interface BankAccountsProps {
    className?: string;
    onClose?: () => void;
}

export interface OptionType {
    id: string;
    icon: IconDefinition;
    title: string;
    disabled?: boolean;
}

export enum OptionTypes {
    SetActive = 'active',
    UploadDocument = 'upload'
}

const setActiveOption: OptionType = {
    id: 'active',
    title: 'Set as Primary',
    icon: faCheck
};

export const dropdownOptions: Array<OptionType> = [setActiveOption];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#fafafa',
            transition: 'visibility 0s, opacity 0.5s linear',
            visibility: 'visible',
            opacity: 1,
            [theme.breakpoints.down('sm')]: {
                background: '#fff',
                width: '100%'
            }
        },
        row: {
            cursor: 'default'
        },
        descriptionContainer: {
            display: 'flex',
            alignItems: 'center',
            background: '#fafafa',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                paddingTop: 15,
                paddingBottom: 15
            }
        },
        description: {
            color: 'black',
            fontSize: 14,
            lineHeight: 1.4,
            padding: '32px 0',
            fontWeight: 400,
            [theme.breakpoints.up('sm')]: {
                maxWidth: '411px',
                fontSize: 15,
                padding: 0,
                paddingTop: 10,
                paddingBottom: 0
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 10,
                paddingBottom: 12
            }
        },
        paddingWrapper: {
            width: '100%',
            paddingLeft: 24,
            paddingRight: 24,
            marginBottom: 16,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
                marginBottom: 0
            }
        },
        tableContainer: {
            flexGrow: 1,
            background: '#fff',
            display: 'flex',
            width: '100%'
        },
        tableWrapper: {
            flexGrow: 1,
            width: '100%'
        },
        mobileCardsContainer: {
            width: '100%'
        },

        header: {
            paddingTop: 50,
            paddingBottom: 0,
            [theme.breakpoints.down('xs')]: {
                paddingBottom: 16,
                paddingTop: 16
            }
        },
        none: {
            display: 'none'
        },
        question: {
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        accountTypeCell: {
            [theme.breakpoints.up('sm')]: {
                width: '93%'
            }
        },
        statusCell: {
            [theme.breakpoints.up('sm')]: {
                width: '8%'
            }
        },
        actionsCell: {
            [theme.breakpoints.up('sm')]: {
                width: '7%'
            }
        },
        descriptionWrapper: {
            width: '100%'
        },
        alertBox: {
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start'
            }
        },
        bgGray: {
            backgroundColor: '#fafafa',
            marginBottom: 0
        }
    })
);

export const BankAccounts: FunctionComponent<BankAccountsProps> = () => {
    const classes = useStyles();

    const tableStyles = useTableStyles();

    const [selectedOptionId, setSelectedOptionId] = useState<string | null>(
        null
    );

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const auth = useAuth();
    const userIsOwner = auth.user!.role.id === OWNER_ID;
    const [openRestrictedDialog, setOpenRestrictedDialog] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const fetchInitiated = useRef(false);
    const [polling, setPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const maxPollingCount = 25;

    const bankAccounts = useSelector<RootState, Array<BankAccountModel>>(
        state => state.payments.bankAccounts
    ).sort(a => {
        if (a.primary === true) return -1;
        else return 1;
    });

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const hopLink = useSelector<RootState, string | undefined>(
        state => state.payments.hopLink
    );

    const [loading, setLoading] = useState(true);
    const [selectedBankAccount, setSelectedBankAccount] =
        useState<BankAccountModel>();
    const [deleting, setDeleting] = useState(false);
    const [addBankAccount, setAddBankAccount] = useState(false);

    const paymentsEnrollmentStatus = useSelector<
        RootState,
        EnrollmentStatus | undefined
    >(state => state.payments.enrollmentStatus);

    const [bankAccountLoading, setBankAccountLoading] =
        useState<boolean>(false);
    const [bankAccountUpload, setBankAccountUpload] = useState<
        BankAccountModel | undefined
    >();

    useEffect(() => {
        dispatch(fetchBankAccountsThunk(apiClientWrapper));
        setLoading(true);
    }, []);

    useNonInitialEffect(() => {
        if (
            [
                PaymentsStatus.FetchBankAccountsSuccess,
                PaymentsStatus.Error
            ].includes(paymentsStatus)
        ) {
            setLoading(false);
            setBankAccountLoading(false);
        }

        if (paymentsStatus === PaymentsStatus.SetBankAccountAsPrimarySuccess) {
            setBankAccountLoading(false);
        }
    }, [paymentsStatus]);

    useNonInitialEffect(() => {
        if (selectedBankAccount !== undefined) {
            switch (selectedOptionId) {
                case OptionTypes.SetActive:
                    setBankAccountLoading(true);
                    if (selectedBankAccount.accountIdentifier) {
                        dispatch(
                            setBankAccountAsPrimaryNewProfileThunk(
                                apiClientWrapper,
                                selectedBankAccount.id as string
                            )
                        );
                    } else {
                        dispatch(
                            setBankAccountAsPrimaryThunk(
                                apiClientWrapper,
                                selectedBankAccount.id!
                            )
                        );
                    }
                    break;
                case OptionTypes.UploadDocument:
                    setBankAccountUpload(selectedBankAccount);
                    setLoading(true);
                    break;
            }
            setSelectedBankAccount(undefined);
            setSelectedOptionId(null);
            setLoading(false);
        }
    }, [selectedOptionId]);

    useEffect(() => {
        if (fetchInitiated.current && hopLink) {
            // Check if fetch was initiated and hopLink is available
            window.location.href = hopLink;
            fetchInitiated.current = false;
            setIsButtonDisabled(false);
        }
    }, [hopLink]);

    // Function to fetch or refresh the hopLink
    const fetchHopLink = async (): Promise<void> => {
        await dispatch(getHopLinkThunk(apiClientWrapper));
        fetchInitiated.current = true; // Set that fetching has been initiated
    };

    const handleHopLinkTrigger = async (startPolling: boolean) => {
        setIsButtonDisabled(true);
        if (!userIsOwner) {
            setOpenRestrictedDialog(true);
        } else {
            await fetchHopLink(); // This sets fetchInitiated to true
            if (startPolling) {
                setPolling(true);
                setPollingCount(0);
            }
        }
    };

    const handleCloseRestrictedDialog = () => {
        setOpenRestrictedDialog(false);
    };

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (polling) {
            interval = setInterval(() => {
                dispatch(fetchBankAccountsThunk(apiClientWrapper));
                setBankAccountLoading(true);
                setPollingCount(prevCount => prevCount + 1);
            }, 15000);
        }

        if (pollingCount >= maxPollingCount) {
            setPolling(false);
            setBankAccountLoading(false);
            if (interval) clearInterval(interval);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [polling, pollingCount]);

    const headers = (
        <>
            <TableCell
                className={clsx(
                    tableStyles.headerCell,
                    tableStyles.darkCell,
                    classes.accountTypeCell
                )}
            >
                Account Number
            </TableCell>
            <TableCell
                className={clsx(
                    tableStyles.headerCell,
                    tableStyles.darkCell,
                    classes.actionsCell
                )}
            ></TableCell>
        </>
    );

    const rows = (
        <Fragment>
            {bankAccounts.map((bankAccount, index) => (
                <TableRow key={index}>
                    <TableCell
                        className={clsx(
                            tableStyles.cell,
                            classes.accountTypeCell
                        )}
                    >
                        <AccountType
                            bankAccountsQty={bankAccounts.length}
                            bankAccount={bankAccount}
                        />
                    </TableCell>
                    <TableCell
                        className={clsx(tableStyles.cell, classes.actionsCell)}
                    >
                        {!bankAccount.primary && bankAccounts.length > 1 && (
                            <Dropdown
                                options={dropdownOptions.filter(option =>
                                    bankAccount.document
                                        ? option.id !==
                                          OptionTypes.UploadDocument
                                        : option
                                )}
                                onClick={(optionId: string) => {
                                    setSelectedBankAccount(bankAccount);
                                    setSelectedOptionId(optionId);
                                }}
                            />
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </Fragment>
    );

    return (
        <Box className={classes.container}>
            <Header
                title="Bank Accounts"
                buttonLabel={
                    isMobile ? 'Manage Accounts' : 'Manage Bank Accounts'
                }
                onClick={() => {
                    handleHopLinkTrigger(true);
                }}
                className={clsx([
                    classes.paddingWrapper,
                    classes.header,
                    bankAccounts.length < 1 && classes.bgGray
                ])}
                showButton={
                    isNewAccount(paymentsEnrollmentStatus) &&
                    bankAccounts.length > 0
                }
                isButtonDisabled={isButtonDisabled}
            />
            {bankAccounts.length > 0 && (
                <Box
                    className={clsx([
                        classes.paddingWrapper,
                        classes.descriptionContainer
                    ])}
                >
                    <Box className={classes.descriptionWrapper}>
                        <Title
                            className={classes.description}
                            text="Your funds will only be transferred into a verified bank account. Verification usually takes a few minutes, but for some banks can take up to 4 business days."
                        />
                    </Box>
                </Box>
            )}
            <Box
                className={clsx(
                    classes.tableContainer,
                    bankAccounts.length < 1 && classes.bgGray
                )}
            >
                {loading || bankAccountLoading ? (
                    <Spinner />
                ) : bankAccounts.length === 0 &&
                  paymentsStatus === PaymentsStatus.FetchBankAccountsSuccess ? (
                    <NoBankAccounts
                        onClick={() => handleHopLinkTrigger(true)}
                        showButton={isNewAccount(paymentsEnrollmentStatus)}
                        isButtonDisabled={isButtonDisabled}
                    />
                ) : isMobile ? (
                    <Box
                        className={clsx([
                            classes.mobileCardsContainer,
                            addBankAccount || bankAccountUpload
                        ])}
                    >
                        {bankAccounts.map((bankAccount, index) => (
                            <BankAccountCardMobile
                                account={bankAccount}
                                bankAccountsQty={bankAccounts.length}
                                key={index}
                                onSelectOption={(optionId: string) => {
                                    setSelectedBankAccount(bankAccount);
                                    setSelectedOptionId(optionId);
                                }}
                            />
                        ))}
                    </Box>
                ) : (
                    <Table
                        headers={headers}
                        rows={rows}
                        loading={loading}
                        wrapperClassName={classes.tableWrapper}
                    />
                )}
            </Box>
            {openRestrictedDialog && (
                <RestictedDialog
                    open={openRestrictedDialog}
                    title="Access Restricted"
                    question="Your current role does not permit access to this section. Please contact the owner if you need assistance or require access."
                    handleClose={handleCloseRestrictedDialog}
                    cancelButtonLabel="Done"
                />
            )}
        </Box>
    );
};

export default BankAccounts;
