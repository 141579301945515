import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

export interface VaccineItemsProps {
  vaccines: Array<Option<string>>;
  onChange: (vaccine: Option<string>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      height: "auto",
      width: "auto",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(22)}px`,
        paddingLeft: `${reduceResolution(24)}px`,
        paddingRight: `${reduceResolution(24)}px`,
        paddingTop: `${reduceResolution(22)}px`,
        paddingBottom: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "22px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "22px",
        paddingBottom: "5px",
      },
    },
    vaccineItem: {
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        paddingBottom: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        paddingBottom: "22px",
      },
    },
  })
);

export const VaccinesItems: FunctionComponent<VaccineItemsProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container)}>
      <Box>
        {props.vaccines.map((vaccine) => {
          return (
            <Typography key={vaccine.id} className={classes.vaccineItem} onClick={() => props.onChange(vaccine)}>
              {vaccine.name}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default VaccinesItems;
