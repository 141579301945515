import React from 'react';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import { createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            aspectRatio: 1.25,
            fontFamily: 'Poppins',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '2px 5px 4px'
        },
        staffInfo: {
            'gap': 6,
            'width': '100%',
            'display': 'flex',
            'alignItems': 'center',

            '& p': {
                margin: 0
            }
        },
        staffAvatar: {
            width: 34,
            height: 34,
            objectFit: 'cover',
            borderRadius: '50%'
        },
        staffName: {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 500
        },
        staffRole: {
            fontSize: 11,
            lineHeight: 1,
            color: '#7A7A7A'
        },
        weeklyPets: {
            margin: 0,
            fontSize: 12,
            lineHeight: 1,
            color: '#7A7A7A'
        }
    })
);

export const ScheduleTypeCalendarStaffCell: React.FC<ResourceLabelContentArg> = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.staffInfo}>
                <img
                    alt={props.resource.title}
                    src={props.resource.extendedProps.person.avatar}
                    className={classes.staffAvatar}
                />
                <div>
                    <p className={classes.staffName}>{props.resource.title}</p>
                    <p className={classes.staffRole}>{props.resource.extendedProps.role?.name}</p>
                </div>
            </div>

            <p className={classes.weeklyPets}>
                <FontAwesomeIcon icon={faPaw} /> 0 weekly pets
            </p>
        </div>
    );
};
