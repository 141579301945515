import { FunctionComponent, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    Divider
} from '@material-ui/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import DentalReport from 'model/DentalReport';
import HeaderDentalReport from 'components/UI/HeaderReport';
import OralHygieneLevels from './UI/OralHygieneLevels';
import DogDentalChart from './UI/DogDentalChart';
import Remarks from 'components/UI/Remarks';
import { Img } from 'react-image';
import ProcedureRecords from './UI/ProcedureRecords';
import { SelectableOption } from 'model';
import Recommendations from 'components/UI/Recommendations';
import Homecare from './UI/Homecare';
import NextDentalDate from 'components/UI/DateReport';
import Button from 'components/UI/Button';
import ToothAbnormalities from './UI/ToothAbnormalities';
import { DentalReportImagesSummary } from './UI/DentalReportImages';
import PeriodontalFindings from './UI/PeriodontalFindings';
import { isEmpty } from 'lodash';
import Declined from '../UI/Declined';
import { faArrowToBottom, faEdit } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { status } from '@spike/appointment-model';
import { MobileDivider } from 'components/UI/MobileDivider';

interface Props {
    singleImage: boolean;
    dentalReport: DentalReport;
    onComplete?: (report: DentalReport) => void;
    onResend?: (report: DentalReport) => void;
    back: () => void;
    onClose: () => void;
    onEditReport: (appointmentId: number) => void;
    onSendAndComplete?: (report: DentalReport) => void;
    onDownloadReport?: (appointmentId: number) => void;
    sending: boolean;
    declined?: boolean;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: '100%',
            display: 'flex',
            overflowX: 'hidden',
            padding: '24px 16px',
            position: 'relative',
            flexDirection: 'column',
            backgroundColor: '#ffffff',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '136px',
                paddingRight: '79px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '181px',
                paddingRight: '106px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '274px',
                paddingRight: '160px'
            }
        },
        backTitleContainer: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            marginBottom: 32,

            [theme.breakpoints.up('md')]: {
                marginBottom: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '36px'
            }
        },
        backContainer: {
            display: 'flex',
            position: 'absolute',
            cursor: 'pointer',
            bottom: '0px',
            [theme.breakpoints.down('sm')]: {
                width: '30px',
                height: '30px',
                marginLeft: '-49px',
                fontSize: '25px'
            },
            [theme.breakpoints.only('md')]: {
                width: '32px',
                height: '32px',
                marginLeft: '-80px',
                fontSize: '26px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '32px',
                height: '32px',
                marginLeft: '-106px',
                fontSize: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '36px',
                height: '36px',
                marginLeft: '-160px',
                fontSize: '36px'
            }
        },
        backButton: {
            all: 'unset'
        },
        titleButtonContainer: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                marginTop: 56,
                display: 'flex',
                justifyContent: 'space-between'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '75px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '114px'
            }
        },
        titleContainer: {
            gap: 10,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                marginBottom: 24
            },
            [theme.breakpoints.up('md')]: {
                width: '50%',
                flexDirection: 'column'
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: '26px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '36px'
            }
        },
        rowContainer: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '20px'
            }
        },
        rightContainer: {
            width: 'auto'
        },
        leftContainer: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                fontSize: '27px',
                width: '252px',
                marginRight: '150px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '36px',
                width: '336px',
                marginRight: '200px'
            }
        },
        dentalMap: {
            width: '100%',
            marginTop: 24,

            [theme.breakpoints.up('md')]: {
                marginLeft: '-75px',
                height: '816px',
                width: '360px',
                marginTop: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '-100px',
                height: '1088px',
                width: '480px',
                marginTop: '40px'
            }
        },
        oralHygieneLevelsContainer: {
            display: 'flex'
        },
        oralHygieneLevels: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('lg')]: {
                marginTop: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '40px'
            }
        },
        titleSection: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 16,

            [theme.breakpoints.up('md')]: {
                fontSize: 14,
                fontWeight: 400,
                color: '#A3A3A3',
                marginBottom: 24,
                textTransform: 'uppercase'
            }
        },
        levelsContainer: {
            display: 'flex',
            [theme.breakpoints.down('lg')]: {
                width: '375px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '500px'
            }
        },
        plaqueContainer: {
            [theme.breakpoints.down('lg')]: {
                marginRight: '98px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '130px'
            }
        },
        procedureRecordsContainer: {
            [theme.breakpoints.up('md')]: {
                marginBottom: '44px',
                marginTop: (props: Props) =>
                    props.singleImage ? '98px' : '30px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '58px',
                marginTop: (props: Props) =>
                    props.singleImage ? '130px' : '40px'
            }
        },
        recommendationsContainer: {
            [theme.breakpoints.up('md')]: {
                marginTop: '53px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '71px'
            }
        },
        nextDentalDate: {
            marginBottom: 40,

            [theme.breakpoints.down('sm')]: {
                '& > div': {
                    padding: 0
                }
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '37px',
                marginBottom: '26px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '50px',
                marginBottom: '40px'
            }
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'flex-end'
        },
        buttonSendAndCompleteContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
        },
        buttonComplete: {
            'height': 55,
            'width': '100%',

            '& span': {
                fontSize: 18,
                fontWeight: 600
            },

            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
            [theme.breakpoints.up('md')]: {
                width: 200,
                height: 38
            },
            [theme.breakpoints.up('xl')]: {
                width: 245,
                height: 50
            }
        },
        finalRowContainer: {
            display: 'flex',
            justifyContent: 'space-between',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '20%'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '35%'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '50%'
            }
        },
        totalDecline: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '37px',
                marginBottom: '97px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '50px',
                marginBottom: '129px'
            }
        },
        buttonSendAndComplete: {
            float: 'right',
            [theme.breakpoints.down('lg')]: {
                width: '172px',
                height: '38px',
                fontSize: '14px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '229px',
                height: '50px',
                fontSize: '18px'
            }
        },
        downloadContainer: {
            display: 'flex'
        },
        actionOptions: {
            gap: 32,
            width: '100%',
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                gap: 30
            },
            [theme.breakpoints.up('xl')]: {
                gap: 180
            }
        },
        downloadContent: {
            'float': 'right',
            'display': 'flex',
            'cursor': 'pointer',
            'color': '#92B4A7',

            '&:hover': {
                color: '#222'
            }
        },

        editContent: {
            'float': 'right',
            'display': 'flex',
            'cursor': 'pointer',
            'color': '#92B4A7',

            '&:hover': {
                color: '#222'
            }
        },

        download: {
            cursor: 'pointer',
            color: '#92B4A7',
            [theme.breakpoints.down('lg')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '18px'
            }
        },
        icon: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '3px',
                marginRight: '6px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '5px',
                marginRight: '8px'
            }
        },
        boxResend: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                gap: 10,
                width: '100%'
            }
        },
        buttonBack: {
            'width': '100%',
            'fontSize': 16,
            'fontWeight': 600,
            'color': '#EAB464',
            'cursor': 'pointer',

            '&:hover': {
                color: '#C18733'
            },

            [theme.breakpoints.down('sm')]: {
                height: 55,
                color: '#ffffff',
                borderRadius: 30,
                lineHeight: '55px',
                textAlign: 'center',
                backgroundColor: '#000000'
            },
            [theme.breakpoints.up('md')]: {
                width: 180,
                padding: '10px 10px 0px 0px'
            }
        },

        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const DentalReportSummary: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const declineStatus =
        !props.declined ||
        (props.declined &&
            (!isEmpty(props.dentalReport.calculus) ||
                !isEmpty(props.dentalReport.plaque) ||
                !isEmpty(props.dentalReport.toothAbnomalities) ||
                !isEmpty(props.dentalReport.periodontalFindings) ||
                !isEmpty(props.dentalReport.homecare) ||
                !isEmpty(props.dentalReport.recommendations) ||
                !isEmpty(props.dentalReport.nextDentalDate)))
            ? true
            : false;

    const totalDeclined =
        props.declined &&
        isEmpty(props.dentalReport.calculus) &&
        isEmpty(props.dentalReport.plaque) &&
        isEmpty(props.dentalReport.toothAbnomalities) &&
        isEmpty(props.dentalReport.periodontalFindings) &&
        isEmpty(props.dentalReport.homecare) &&
        isEmpty(props.dentalReport.recommendations)
            ? true
            : false;

    const [levelsCalculus, setLevelsCalculus] = useState<
        Array<SelectableOption<number>>
    >([
        { element: { id: 1, name: '1' }, selected: false },
        { element: { id: 2, name: '2' }, selected: false },
        { element: { id: 3, name: '3' }, selected: false }
    ]);

    const [levelsPlaque, setLevelsPlaque] = useState<
        Array<SelectableOption<number>>
    >([
        { element: { id: 1, name: '1' }, selected: false },
        { element: { id: 2, name: '2' }, selected: false },
        { element: { id: 3, name: '3' }, selected: false }
    ]);

    useEffect(() => {
        const levelCalculusAux = [...levelsCalculus];
        const levelPlaqueAux = [...levelsPlaque];
        levelCalculusAux.forEach(lvl => {
            if (props.dentalReport.calculus === lvl.element.id) {
                lvl.selected = true;
            }
        });
        levelPlaqueAux.forEach(lvl => {
            if (props.dentalReport.plaque === lvl.element.id) {
                lvl.selected = true;
            }
        });
        setLevelsCalculus(levelCalculusAux);
        setLevelsPlaque(levelPlaqueAux);
    }, []);

    const closeHandler = () => {
        props.onClose();
    };

    const completeHandler = () => {
        props.onComplete && props.onComplete(props.dentalReport);
    };

    const handlerEditReport = () => {
        props.onEditReport &&
            props.onEditReport(props.dentalReport.appointment.id!);
    };

    const resendHandler = () => {
        props.onResend && props.onResend(props.dentalReport);
    };

    const sendAndCompleteHandler = () => {
        props.onSendAndComplete && props.onSendAndComplete(props.dentalReport);
    };

    const handleDownloadPDF = () => {
        props.onDownloadReport &&
            props.onDownloadReport(props.dentalReport.appointment.id!);
    };

    const header = (
        <Box className={classes.backTitleContainer}>
            <Box className={classes.backContainer} onClick={() => props.back()}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>
                    <button
                        className={clsx(classes.backButton, classes.mobile)}
                        onClick={() => props.back()}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>

                    <Typography
                        className={classes.title}
                    >{`${props.dentalReport.appointment.pet.name}'s Dental Report`}</Typography>
                </Box>
                <Box className={classes.downloadContainer}>
                    {props.loading ? (
                        <Spinner />
                    ) : (
                        <Box className={classes.actionOptions}>
                            {props.dentalReport.appointment.status.id !==
                            status.COMPLETED ? (
                                <Box
                                    className={classes.editContent}
                                    onClick={handlerEditReport}
                                >
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className={clsx(
                                            classes.download,
                                            classes.icon
                                        )}
                                    />
                                    <Typography className={classes.download}>
                                        Edit Report
                                    </Typography>
                                </Box>
                            ) : (
                                <Box className={classes.editContent}>
                                    <Typography className={classes.download}>
                                        {' '}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                className={classes.downloadContent}
                                onClick={handleDownloadPDF}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowToBottom}
                                    className={clsx(
                                        classes.download,
                                        classes.icon
                                    )}
                                />
                                <Typography className={classes.download}>
                                    Download PDF
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            <Divider />
        </Box>
    );

    const dentalMap = (
        <>
            {declineStatus && (
                <Box className={classes.recommendationsContainer}>
                    <DogDentalChart />
                </Box>
            )}

            {declineStatus && (
                <Box>
                    {props.dentalReport.appointment.pet.type.id === 'dogs' ? (
                        <Img
                            src="/images/dentalReport/DogDentalChart.png"
                            className={classes.dentalMap}
                        />
                    ) : (
                        <Img
                            src="/images/dentalReport/CatDentalExamination.svg"
                            className={classes.dentalMap}
                        />
                    )}
                </Box>
            )}
        </>
    );

    const goBackButton = (
        <Button
            label="Go back to booking"
            className={classes.buttonComplete}
            onClick={closeHandler}
        />
    );

    const sendButton = (
        <Box className={classes.boxResend}>
            <Box onClick={closeHandler} className={classes.buttonBack}>
                <span className={classes.desktop}>Go back to booking</span>
                <span className={classes.mobile}>Go back</span>
            </Box>
            <Button
                label="Send Report"
                className={classes.buttonComplete}
                onClick={resendHandler}
                loading={props.sending}
            />
        </Box>
    );

    const resendButton = (
        <Box className={classes.boxResend}>
            <Box onClick={closeHandler} className={classes.buttonBack}>
                Go back to booking
            </Box>
            <Button
                label="Resend"
                className={classes.buttonComplete}
                onClick={resendHandler}
                loading={props.sending}
            />
        </Box>
    );

    const sendAndCompleteButton = (
        <Box className={classes.buttonSendAndCompleteContainer}>
            <Button
                label="Send & Complete"
                className={classes.buttonSendAndComplete}
                onClick={sendAndCompleteHandler}
                loading={props.sending}
            />
        </Box>
    );

    return (
        <Box className={classes.container}>
            {header}
            <Box className={classes.rowContainer}>
                <Box className={classes.leftContainer}>
                    <Box className={classes.desktop}>
                        <HeaderDentalReport
                            appointment={props.dentalReport.appointment}
                            isReport={false}
                        />
                    </Box>

                    <Box className={classes.desktop}>{dentalMap}</Box>
                </Box>
                <Box className={classes.rightContainer}>
                    <Box className={classes.mobile}>
                        <HeaderDentalReport
                            isReport={false}
                            appointment={props.dentalReport.appointment}
                        />
                    </Box>

                    {declineStatus &&
                        props.dentalReport.images.every(
                            img => img && img.length > 0
                        ) && (
                            <Box>
                                <DentalReportImagesSummary
                                    images={props.dentalReport.images}
                                />
                            </Box>
                        )}

                    <Box
                        className={
                            totalDeclined
                                ? undefined
                                : classes.procedureRecordsContainer
                        }
                    >
                        {props.declined ? (
                            <Declined title={true} />
                        ) : (
                            <>
                                <ProcedureRecords
                                    procedureRecords={
                                        props.dentalReport.procedureRecords
                                    }
                                    isDentalReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                />

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box className={classes.oralHygieneLevels}>
                        {!props.declined &&
                            (!isEmpty(levelsCalculus) ||
                                !isEmpty(levelsPlaque)) && (
                                <Typography
                                    className={clsx(
                                        classes.titleSection,
                                        classes.desktop
                                    )}
                                >
                                    Oral Hygiene Levels
                                </Typography>
                            )}

                        <Box className={classes.levelsContainer}>
                            {!props.declined &&
                                (!isEmpty(levelsCalculus) ||
                                    !isEmpty(levelsPlaque)) && (
                                    <Box className={classes.plaqueContainer}>
                                        <OralHygieneLevels
                                            label={'Calculus'}
                                            isDentalReport={false}
                                            levels={levelsCalculus}
                                            onChange={() => {}}
                                        />
                                    </Box>
                                )}
                            {!props.declined &&
                                (!isEmpty(levelsCalculus) ||
                                    !isEmpty(levelsPlaque)) && (
                                    <Box>
                                        <OralHygieneLevels
                                            label={'Plaque'}
                                            isDentalReport={false}
                                            levels={levelsPlaque}
                                            onChange={() => {}}
                                        />
                                    </Box>
                                )}
                        </Box>

                        {!isEmpty(levelsCalculus) && !isEmpty(levelsPlaque) && (
                            <Box style={{ margin: '0px -16px' }}>
                                <MobileDivider />
                            </Box>
                        )}

                        {!isEmpty(props.dentalReport.toothAbnomalities) && (
                            <Box className={classes.recommendationsContainer}>
                                <ToothAbnormalities
                                    petType={
                                        props.dentalReport.appointment.pet.type
                                    }
                                    toothAbnormalities={
                                        props.dentalReport.toothAbnomalities
                                    }
                                    isDentalReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                />

                                {!props.declined && (
                                    <Box style={{ margin: '0px -16px' }}>
                                        <MobileDivider />
                                    </Box>
                                )}
                            </Box>
                        )}

                        {!isEmpty(props.dentalReport.periodontalFindings) && (
                            <Box className={classes.recommendationsContainer}>
                                <PeriodontalFindings
                                    petType={
                                        props.dentalReport.appointment.pet.type
                                    }
                                    isDentalReport={false}
                                    periodontalFindings={
                                        props.dentalReport.periodontalFindings
                                    }
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                />
                            </Box>
                        )}

                        {declineStatus && (
                            <Box
                                className={classes.mobile}
                                style={{ marginTop: 40 }}
                            >
                                {dentalMap}

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </Box>
                        )}

                        <Box
                            className={
                                totalDeclined
                                    ? undefined
                                    : classes.recommendationsContainer
                            }
                        >
                            <Recommendations
                                recommendations={
                                    props.dentalReport.recommendations
                                }
                                isReport={false}
                                section="dental"
                                multipleSelection={true}
                                onChangeSelection={() => {}}
                            />

                            {props.dentalReport.recommendations.length > 0 && (
                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            )}
                        </Box>
                        <Box
                            className={
                                totalDeclined
                                    ? undefined
                                    : classes.recommendationsContainer
                            }
                        >
                            <Homecare
                                homecare={props.dentalReport.homecare}
                                isDentalReport={false}
                                multipleSelection={true}
                                onChangeSelection={() => {}}
                            />

                            {props.dentalReport.homecare.length > 0 && (
                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            )}
                        </Box>
                        <Box
                            className={
                                totalDeclined
                                    ? classes.totalDecline
                                    : classes.recommendationsContainer
                            }
                        >
                            <Remarks
                                remark={props.dentalReport.remarks}
                                isReport={false}
                                onChange={() => {}}
                                totalDecline={totalDeclined}
                            />
                        </Box>
                        {totalDeclined && sendAndCompleteButton}
                    </Box>
                </Box>
            </Box>
            {declineStatus && (
                <Box className={classes.finalRowContainer}>
                    <Box className={classes.nextDentalDate}>
                        <NextDentalDate
                            nextDentalDate={props.dentalReport.nextDentalDate}
                            haveReport={!!props.dentalReport.id}
                            section="Next Dental Date"
                            isReport={false}
                            isDental={true}
                            onChange={() => {}}
                        />
                    </Box>
                    <Box className={classes.buttonContainer}>
                        {props.onComplete && goBackButton}
                        {props.onResend &&
                            props.dentalReport.appointment.status.id !==
                                status.COMPLETED &&
                            sendButton}
                        {props.onResend &&
                            props.dentalReport.appointment.status.id ===
                                status.COMPLETED &&
                            resendButton}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default DentalReportSummary;
