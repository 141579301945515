import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faRotateLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { PaymentStates, RefundStates } from "./Categories";

interface StateProps {
  state: Option<string>;
  type: "payment" | "refund",
  className?: string;
}

interface StateDesign {
  color: string;
  backgroundColor: string;
  icon?: IconDefinition;
}

const paymentStates: Map<string, any> = new Map([
  [PaymentStates.AUTHORIZED, { color: "white", backgroundColor: "#5E8677", icon: faCheck }],
  [PaymentStates.AUTHORISED_PENDING, { color: "#5E8677", backgroundColor: "#E9F0ED" }],
  [PaymentStates.ERROR, { color: "#CC444B", backgroundColor: "#FFE5E7" }],
  [PaymentStates.REFUNDED, { color: "#7A7A7A", backgroundColor: "#F1F1F1", icon: faRotateLeft }],
  [PaymentStates.CANCELLED, { color: "white", backgroundColor: "#EF4F57" }],
  [PaymentStates.REFUSED, { color: "white", backgroundColor: "#CC444B" }],
  [PaymentStates.EXPIRED, { color: "#white", backgroundColor: "#7A7A7A" }],
]);

const refundStates: Map<string, any> = new Map([
  [RefundStates.PENDING, { color: "#C18733", backgroundColor: "#FFF8DD", icon: faCheck }],
  [RefundStates.RECEIVED, { color: "#5E8677", backgroundColor: "#E9F0ED" }],
  [RefundStates.FAILED, { color: "white", backgroundColor: "#EF4F57" }],
  [RefundStates.SUCCESS, { color: "white", backgroundColor: "#5E8677", icon: faCheck }],
]);

const defaultDesign: StateDesign = {
  color: "black",
  backgroundColor: "#FAFAFA",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (design: StateDesign) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "fit-content",
      backgroundColor: design.backgroundColor,
      [theme.breakpoints.down(wbp)]: {
        padding: `0px ${reduceResolution(11)}px`,
        height: `${reduceResolution(27)}px`,
        borderRadius: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0px 11px",
        height: "27px",
        borderRadius: "27px",
      },
    }),
    text: (design: StateDesign) => ({
      fontFamilty: "Poppins",
      fontWeight: 500,
      color: design.color,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        lineHeight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    }),
    icon: (design: StateDesign) => ({
      color: design.color,
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(13)}px`,
        height: `${reduceResolution(13)}px`,
        marginRight: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "13px",
        height: "13px",
        marginRight: "5px",
      },
    }),
  })
);

export const State: FunctionComponent<StateProps> = (props) => {
  const design = (props.type === "payment" && paymentStates.get(props.state.id)) || (props.type === "refund" && refundStates.get(props.state.id)) || defaultDesign;

  const classes = useStyles(design);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {design.icon && <FontAwesomeIcon icon={design.icon} className={classes.icon}></FontAwesomeIcon>}
      <Typography className={classes.text}>{props.state.name}</Typography>
    </Box>
  );
};

export default State;
