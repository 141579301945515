import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Theme, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { FunctionComponent } from "react";

interface DateSelectorComponentProps extends PropsWithChildren {
    id: string;
    expanded: boolean;
    className?: string;
    onExpand: () => void;
    onClickAway: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
        },
        expandButton: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "50%",
            border: "1px solid #D4D4D4",
            textAlign: "center",
            width: 34,
            height: 34
        },
        icon: {
            fontSize: 16
        },
        content: {
            position: "absolute",
            zIndex: 1000,
            left: 0,
        },
    })
);

export const DateSelectorComponent: FunctionComponent<DateSelectorComponentProps> = (props) => {
    const classes = useStyles();

    return (
        <ClickAwayListener onClickAway={props.onClickAway}>
            <Box className={classes.container}>
                <Box 
                    id={`booking_div_dropdown_${props.id}`}
                    className={classes.expandButton} 
                    onClick={props.onExpand}
                >
                    <FontAwesomeIcon 
                        className={classes.icon}
                        icon={ props.expanded ? faChevronUp : faChevronDown } 
                    />
                </Box>
                {props.expanded && 
                    <Box className={clsx(classes.content, props.className)}>
                        {props.children}
                    </Box>
                }
            </Box>
        </ClickAwayListener>
    );
};

export default DateSelectorComponent;