import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import Price from './Price';

interface ItemProps {
	description?: string;
	price: string;
	refund?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'display': 'flex',
			'width': '100%',
			'alignItems': 'center',
			'padding': '10px 0px',

			[theme.breakpoints.up('md')]: {
				padding: '13px 0px'
			},

			'&:not(:first-child)': {
				[theme.breakpoints.down('sm')]: {
					borderTop: 'solid 1px #D4D4D4'
				}
			}
		},
		left: {
			display: 'flex',
			flexGrow: 10
		},
		right: {
			display: 'flex',
			justifyContent: 'flex-end',
			width: '200px'
		},
		text: {
			fontSize: 14,
			lineHeight: 1.4,
			fontWeight: 400,
			color: '#7A7A7A'
		},
		refund: {
			color: '#EF4F57'
		}
	})
);

export const Item: FunctionComponent<ItemProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.left}>
				<Typography
					className={clsx(classes.text, {
						[classes.refund]: props.refund
					})}
				>
					{props.description}
				</Typography>
			</Box>
			<Box className={classes.right}>
				<Price refund={props.refund} price={props.price} />
			</Box>
		</Box>
	);
};

export default Item;
