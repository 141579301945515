import { Typography } from '@material-ui/core';
import {
	InvoiceDiscount as InvoiceDiscountModel,
	InvoiceLine
} from '@spike/invoice-model';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useCommonStyles } from './CommonStyles';
import InvoiceDiscount from './InvoiceDiscount';
import ViewStates from './ViewStates';

interface LineDiscountProps {
	line: InvoiceLine;
	errors: Array<FieldError>;
	viewState: ViewStates;
	editable: boolean;
	onEdit?: () => void;
	onChange?: (discount: InvoiceDiscountModel) => void;
}

export const LineDiscount: FunctionComponent<LineDiscountProps> = props => {
	const commonClasses = useCommonStyles();

	return props.viewState === ViewStates.Editing ? (
		<InvoiceDiscount
			discount={props.line.discount}
			onChange={props.onChange}
			name={`${props.line.uuid}_discount`}
			errors={props.errors}
		/>
	) : (
		<Typography
			className={clsx(
				commonClasses.textCell,
				commonClasses.amountTextCell,
				{[commonClasses.editable]: props.editable}
			)}
			onClick={props.editable ? props.onEdit : undefined}
		>
			{props.line.discount.percentage === undefined
				? `$${props.line.discount.amount}`
				: `${props.line.discount.percentage}%`}
		</Typography>
	);
};

export default LineDiscount;
