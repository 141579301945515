import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import { FieldError } from "@spike/model";
import NumberField2 from "./NumberField2";
import { useFieldStyles } from "./FieldStyles";
import clsx from "clsx";

export interface PercentageField2Props {
  id?: string;
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: number | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: number | undefined, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  onEnterPressed?: () => void;
  disabled?: boolean;
  scale?: number;
  precision?: number;
  information?: string;
  alignRight?: boolean;
  max?: number;
}

export const PercentageField2: FunctionComponent<PercentageField2Props> = (props) => {

  const classes = useFieldStyles();

  return (
    <NumberField2
      {...props}
      endIcon={<Typography className={classes.adornmentText}>%</Typography>}
      className={(props.alignRight === true || props.alignRight === undefined) ? clsx(classes.inputTextRight,props.className) : props.className}
    />
  );
};

export default PercentageField2;