import React, { FunctionComponent } from "react";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { StaffSchedule } from "model/Staff";
import { faExclamationCircle, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ScheduleStatus as ScheduleStatusEnum,
  getStatusFromThisWeekOn as getScheduleStatusFromThisWeekOn,
} from "components/StaffSchedule/utils/StaffScheduleUtils";
import _ from "lodash";
import clsx from "clsx";

export interface ScheduleStatusProps {
  schedule: StaffSchedule;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      marginLeft: "8px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },      
    },
    icon: {
      color: "#92B4A7",
    },
    missing: {
      color: "#EF4F57",
    }
  })
);

export const ScheduleStatus: FunctionComponent<ScheduleStatusProps> = (props) => {
  const classes = useStyles(props);

  const icons: Map<ScheduleStatusEnum, IconDefinition> = new Map([
    [ScheduleStatusEnum.MISSING, faExclamationCircle],
    [ScheduleStatusEnum.DEFAULT, faCheckCircle],
    [ScheduleStatusEnum.CUSTOMIZED, faCheckCircle],
  ]);
  const status = getScheduleStatusFromThisWeekOn(props.schedule);

  return (
    <Box className={classes.container}>
      <FontAwesomeIcon icon={icons.get(status)!} className={clsx(classes.icon, {[classes.missing]: status === ScheduleStatusEnum.MISSING})}/>
      <Typography className={classes.text}>{_.capitalize(status)}</Typography>
    </Box>
  );
};

export default ScheduleStatus;
