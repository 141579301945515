import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Week } from '@spike/model';
import { WeekDay } from 'model';
import clsx from 'clsx';
import DaySelector from './DaySelector';

interface DefaultScheduleDaysProps {
	id?: string;
	week: Week;
	className?: string;
	onChange?: (week: Week) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		header: {
			display: 'flex',
			[theme.breakpoints.down('sm')]: {},
			[theme.breakpoints.only('md')]: {},
			[theme.breakpoints.only('lg')]: {},
			[theme.breakpoints.only('xl')]: {}
		},
		title: {
			fontSize: 16,
			fontWeight: 600,
			color: '#222222',
			marginBottom: 20
		},
		body: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			borderBottom: 'solid 1px #D4D4D4',
			paddingBottom: 16,
			marginBottom: 8
		}
	})
);

export const DefaultScheduleDays: FunctionComponent<
	DefaultScheduleDaysProps
> = props => {
	const classes = useStyles();

	const selectionDayHandler = (day: WeekDay) => {
		const newWeek = { ...props.week };
		newWeek[day as keyof Week] =
			newWeek[day as keyof Week] === null
				? { open: '8:00', close: '17:00' }
				: null;
		props.onChange && props.onChange(newWeek);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.header}>
				<Typography className={classes.title}>
					Add Schedule To
				</Typography>
			</Box>
			<Box className={classes.body}>
				{[
					'monday',
					'tuesday',
					'wednesday',
					'thursday',
					'friday',
					'saturday',
					'sunday'
				].map((day, index) => (
					<DaySelector
						id={
							props.id
								? props.id + '_' + day + '_' + index
								: props.id
						}
						key={day}
						day={day as WeekDay}
						selected={
							props.week[day as keyof Week] !== null &&
							props.week[day as keyof Week] !== undefined
						}
						onClick={selectionDayHandler}
					/>
				))}
			</Box>
		</Box>
	);
};

export default DefaultScheduleDays;
