import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { useTimeZone } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, MouseEvent } from 'react';
import { Calendar as CalendarDateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { reduceResolution, wbp } from 'Theme';

export interface CalendarProps {
    date?: Moment;
    onChange?: (date: Moment) => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: '#fff',
            height: 'auto',
            paddingTop: '10px',
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                fontSize: `${reduceResolution(16)}px`,
                borderRadius: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '16px',
                borderRadius: '15px'
            }
        },
        calendar: {
            'display': 'flex',
            'flexDirection': 'column',
            'width': '100%',
            '& .rdrMonth': {
                width: 'auto'
            },
            '& .rdrWeekDay': {
                fontWeight: 600,
                color: '#000 !important'
            },
            '& .rdrDayNumber': {
                fontWeight: 400,
                color: '#000 !important'
            },
            '& .rdrMonthAndYearPickers select': {
                fontWeight: 600,
                backgroundColor: '#fff !important'
            },
            '& .rdrSelected': {
                fontWeight: 600,
                backgroundColor: '#E9F0ED !important'
            },
            '& .rdrDateDisplayItemActive': {
                borderColor: '#fff !important'
            },
            '& .rdrDayToday .rdrDayNumber span:after': {
                display: 'none'
            },
            '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
                {
                    color: '#000',
                    fontWeight: 600
                },
            '& .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview':
                {
                    border: 'none'
                },

            '&.rdrDayHovered': {
                border: 'none'
            },
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '12px'
            }
        },
        actionsContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        button: {
            'border': 'none !important',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(136)}px`,
                height: `${reduceResolution(40)}px`,
                borderRadius: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '136px',
                height: '40px',
                borderRadius: '8px'
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down(wbp)]: {
                    fontSize: `${reduceResolution(14)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    fontSize: '14px'
                }
            }
        },
        cancel: {
            color: '#000',
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px'
            }
        }
    })
);

export const Calendar: FunctionComponent<CalendarProps> = props => {
    const classes = useStyles();
    const timezone = useTimeZone();

    const handleChange = (date: Date) => {
        props.onChange && props.onChange(moment(date).tz(timezone));
        props.onClose && props.onClose();
    };

    return (
        <Box className={clsx(classes.container)}>
            <Box>
                <CalendarDateRange
                    date={
                        props.date
                            ? new Date(
                                  props
                                      .date!.clone()
                                      .format('YYYY-MM-DDThh:mm:ss')
                              )
                            : undefined
                    }
                    onChange={handleChange}
                    showMonthArrow={false}
                    fixedHeight={true}
                    weekStartsOn={1}
                    color="transparent"
                    weekdayDisplayFormat={'EEEEEE'}
                    className={classes.calendar}
                    maxDate={moment().tz(timezone).toDate()}
                    minDate={moment()
                        .tz(timezone)
                        .subtract(25, 'years')
                        .toDate()}
                />
                <Box className={classes.actionsContainer}>
                    <Button
                        label="Cancel"
                        variant="secondary"
                        className={clsx(classes.button, classes.cancel)}
                        color="green"
                        onClick={(event: MouseEvent) => {
                            event.stopPropagation();
                            props.onClose && props.onClose();
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Calendar;
