import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faCalendar,
    faCheckDouble,
    faCommentLines,
    faUsdCircle
} from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Typography,
    useTheme
} from '@material-ui/core';
import SMSCampaign from 'model/SMSCampaign';
import moment from 'moment-timezone';
import { Fragment, FunctionComponent } from 'react';

interface CampaignOverviewProps {
    campaign: SMSCampaign;
}

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            padding: 12,
            color: '#222',
            width: '100%',
            height: '100%',
            borderRadius: 10,
            backgroundColor: '#F4F3F0',
            border: '1px solid #D4D4D4'
        },
        label: {
            'fontSize': 14,
            'lineHeight': 1,
            'fontWeight': 500,
            'marginBottom': 10,
            '& svg': {
                top: -1,
                marginRight: 5,
                position: 'relative',
                verticalAlign: 'middle'
            }
        },
        value: {
            fontSize: 18,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 6
        },
        small: {
            margin: 0,
            fontSize: 12,
            lineHeight: 1,
            fontWeight: 400
        }
    })
);

interface OverviewBoxProps {
    icon: IconProp;
    title: string;
    value?: string | number;
    small?: string;
}

const OverviewBox: FunctionComponent<OverviewBoxProps> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <Typography className={classes.label}>
                <FontAwesomeIcon icon={props.icon} /> {props.title}
            </Typography>
            <Typography className={classes.value}>{props.value}</Typography>
            {props.small && (
                <Typography className={classes.small}>{props.small}</Typography>
            )}
        </Box>
    );
};

const CampaignOverview: FunctionComponent<CampaignOverviewProps> = props => {
    const theme = useTheme();

    const successRate = ((props.campaign.stats?.percentage || 0) * 100).toFixed(
        0
    );

    return (
        <Fragment>
            <Grid container spacing={2}>
                {props.campaign.stats?.sent_at && (
                    <Grid item xs={6} md={3}>
                        <OverviewBox
                            title="Sent Date"
                            icon={faCalendar}
                            value={moment(props.campaign.stats.sent_at).format(
                                'MMM D, YYYY'
                            )}
                            small={moment(props.campaign.stats.sent_at).format(
                                'h:mm a'
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={6} md={3}>
                    <OverviewBox
                        title="Success"
                        icon={faCheckDouble}
                        value={`${successRate}%`}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <OverviewBox
                        title="Total Sent"
                        icon={faCommentLines}
                        value={props.campaign.stats?.sent}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    {props.campaign.price && (
                        <OverviewBox
                            title="Total Cost"
                            icon={faUsdCircle}
                            value={`$${props.campaign.price}`}
                        />
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default CampaignOverview;
