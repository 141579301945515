import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	CircularProgress,
	ClickAwayListener,
	InputAdornment,
	Typography
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import ChipPet from 'components/UI/ChipPet';
import AES from 'crypto-js/aes';
import { isNull } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	faCalendarAlt,
	faCat,
	faCut,
	faDog,
	faSearch,
	faUser,
	IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { faNarwhal } from '@fortawesome/pro-solid-svg-icons';
import { globalSearchThunk } from 'actions/globalSearch/GlobalSearchAction';
import { ControlledTextField } from 'components/UI';
import { GlobalSearch as GlobalMaster } from 'model/GlobalSearch';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	GlobalSearchState,
	GlobalSearchStatus
} from 'reducers/globalSearch/GlobalSearchState';
import { RootState } from 'store';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			height: '100%',
			padding: '10px 0px',
			position: 'relative'
		},

		fieldSearch: {
			'width': '100%',
			'height': '100%',
			'paddingLeft': '0 !important',

			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none !important'
			},

			'& .MuiOutlinedInput-input': {
				'fontSize': 12,
				'color': '#7A7A7A',
				'padding': '0px !important',
				'backgroundColor': '#F4F4F4',
				'borderRadius': '0px 20px 20px 0px',

				[theme.breakpoints.up('sm')]: {
					fontSize: 14
				},

				'&::placeholder': {
					opacity: 1
				}
			},

			'& .MuiInputAdornment-positionStart': {
				marginRight: 4
			},

			'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: 'none !important'
			},

			'& .MuiInputBase-root': {
				height: '100%',
				color: '#7A7A7A',
				backgroundColor: '#F4F4F4'
			},

			'& input': {
				height: 'auto'
			},

			'& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':
				{
					border: 'none !important'
				}
		},

		fieldSearchResult: {
			'& .MuiOutlinedInput-root': {
				borderRadius: '20px 20px 0 0'
			}
		},

		boxResult: {
			'border': 0,
			'width': '100%',
			'color': '#7A7A7A',
			'background': '#fff',
			'position': 'absolute',
			'borderRadius': '0 0 20px 20px',
			'fontSize': '0.7rem',
			'padding': '10px 0px',
			'backgroundColor': '#F4F4F4',
			'zIndex': 10000,

			'&:hover': {
				borderColor: '#222'
			}
		},

		boxLoading: {
			paddingTop: '10px',
			textAlign: 'center'
		},
		boxItem: {
			'display': 'flex',
			'padding': '4px 10px 4px 28px',
			'margin': '3px 0 0 0',
			'&:hover': {
				color: '#7A7A7A',
				background: '#f1f1f1',
				cursor: 'pointer'
			}
		},
		circularLoading: {
			color: '#AAA'
		},
		listAlignedIcons: {
			width: '16px !important'
		},
		fonts: {
			fontSize: '14px'
		},

		sectionIcon: {
			color: '#222',
			marginRight: '8px',
			marginTop: '1px',
			fontSize: '12px'
		},

		link: {
			textDecoration: 'none'
		},

		avatar: {
			marginRight: '9px',
			[theme.breakpoints.down('lg')]: {
				height: '15px !important',
				width: '15px !important'
			},
			[theme.breakpoints.up('xl')]: {
				height: '20px !important',
				width: '20px !important'
			}
		},

		searching: {
			borderColor: '#222 !important'
		},

		primaryText: {
			color: '#222',
			fontSize: '0.7rem'
		},
		typoService: {
			color: '#222',
			fontSize: '0.7rem',
			paddingLeft: '3px',
			whiteSpace: 'nowrap',
			overflow: 'hidden !important',
			textOverflow: 'ellipsis',
			width: '90px'
		},
		typoDate: {
			fontStyle: 'italic',
			fontSize: '10px',
			paddingLeft: '4px',
			paddingTop: '1px'
		},

		petType: {
			height: '15px',
			width: '45px',
			fontSize: '8px',
			background: '#E9F0ED'
		},
		flex: {
			display: 'flex',
			flexDirection: 'row'
		}
	})
);

const secret = `${process.env.REACT_APP_GLOBAL_SECRET}`;

export const GlobalSearch: FunctionComponent = () => {
	const classes = useStyles();
	const history = useHistory();

	const dispatch = useDispatch();

	const globalSearch = useSelector<RootState, GlobalSearchState>(
		state => state.globalSearch
	);

	const [resultSearch, setResultSearch] = useState<boolean>(false);
	const [searching, setSearching] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');
	const [searchingResult, setSearchingResult] = useState<boolean>(true);
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [globalMaster, setGlobalMaster] = useState<Array<GlobalMaster>>();

	useEffect(() => {
		if (searchText.length >= 3) {
			setSearching(true);
		} else {
			setSearching(false);
		}
		searchText.length >= 3
			? setShowSearchResult(true)
			: setShowSearchResult(false);
	}, [searchText]);

	useEffect(() => {
		if (globalSearch.status === GlobalSearchStatus.SearchingSuccess) {
			setGlobalMaster(globalSearch.results);

			globalSearch.results.length !== 0
				? setResultSearch(true)
				: setResultSearch(false);
			setSearchingResult(false);
		}
	}, [globalSearch]);

	const iconSVG = (icon: IconDefinition, styles: string) => {
		return <FontAwesomeIcon icon={icon} className={styles} />;
	};

	const changeHandler = (value: string) => {
		setSearchingResult(true);
		setShowSearchResult(true);
		setSearchText(value);
		setResultSearch(true);

		if (value === '') {
			setShowSearchResult(false);
		} else {
			if (value.length >= 3) {
				dispatch(globalSearchThunk(value));
			}
		}
	};

	const handlerSelect = () => {
		setShowSearchResult(false);
	};

	const searchIconCustomer = (pet: string | undefined) => {
		let icon;

		switch (pet) {
			case 'cats':
				icon = iconSVG(
					faCat,
					`${classes.sectionIcon} ${classes.listAlignedIcons}`
				);
				break;
			case 'dogs':
				icon = iconSVG(
					faDog,
					`${classes.sectionIcon} ${classes.listAlignedIcons}`
				);
				break;
			default:
				icon = iconSVG(
					faNarwhal,
					`${classes.sectionIcon} ${classes.listAlignedIcons}`
				);
				break;
		}
		return icon;
	};

	const singularPert = (pet: string) => {
		let type;

		switch (pet) {
			case 'cats':
				type = 'Cat';
				break;
			case 'dogs':
				type = 'Dog';
				break;
			default:
				type = 'Exotic';
				break;
		}
		return type;
	};

	const linkFormat = (typeSearch: string, id: number) => {
		let link = '';

		if (id !== undefined && !isNull(id)) {
			link = '/globalResult/' + typeSearch + '&' + id.toString();
		}

		return link;
	};

	const clickAway = () => {
		setSearchText('');
		setShowSearchResult(false);
	};

	const blurHandler = () => {
		if (searchText.length < 3) {
			setSearchText('');
		}
	};

	const linkHandler = (link: string) => {
		history.push(link);
	};

	const clientClickHandler = (clientId: number, petId?: number) => {
		const hash = encodeURIComponent(
			AES.encrypt(JSON.stringify({ clientId, petId }), secret).toString()
		);

		history.replace(`/clients/${hash}`);
	};

	const searchBoxView = (
		<ControlledTextField
			placeholder="Search"
			name="search"
			value={searchText}
			onChange={changeHandler}
			onBlur={blurHandler}
			className={clsx(classes.fieldSearch, {
				[classes.fieldSearchResult]: showSearchResult
			})}
			autoComplete="off"
			startIcon={
				<InputAdornment position="start">
					<FontAwesomeIcon
						icon={faSearch}
						className={classes.fonts}
					/>
				</InputAdornment>
			}
		/>
	);

	const clientNotFoundView = (
		<Box className={classes.boxLoading}>
			<Box>No result found</Box>
		</Box>
	);

	const loadingView = (
		<Box className={classes.boxLoading}>
			<CircularProgress
				size="1.5rem"
				className={classes.circularLoading}
			/>
		</Box>
	);

	const customerView = () => {
		let customers: Array<GlobalMaster> = [];

		if (globalMaster)
			customers =
				globalMaster &&
				globalMaster.filter(customer => customer.type === 'Customer');

		return customers.map(customer => (
			<Box
				onClick={() => clientClickHandler(customer.id)}
				className={classes.link}
				key={customer.id + customer.name}
			>
				<Box
					key={customer.id + customer.name}
					className={classes.boxItem}
					onClick={() => handlerSelect()}
				>
					{iconSVG(
						faUser,
						`${classes.sectionIcon} ${classes.listAlignedIcons}`
					)}{' '}
					<Typography className={classes.primaryText}>
						{customer.name}
					</Typography>
					{customer.pets && customer.pets.length > 0 ? (
						<Box className={classes.flex}>
							<Typography className={classes.typoDate}>
								{customer.pets && customer.pets[0]
									? customer.pets[0].name
									: ''}
							</Typography>
							<ChipPet
								petType={
									customer.pets &&
									singularPert(customer.pets[0].pet_type)
								}
								className={classes.petType}
							/>
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Box>
		));
	};

	const petView = () => {
		let pets: Array<GlobalMaster> = [];

		if (globalMaster)
			pets =
				globalMaster &&
				globalMaster.filter(customer => customer.type === 'Pet');

		return pets.map(pet => (
			<Box
				onClick={() =>
					clientClickHandler(pet.customers![0].id!, pet.id)
				}
				className={classes.link}
				key={pet.id + pet.name}
			>
				<Box
					key={pet.id + pet.name}
					className={classes.boxItem}
					onClick={() => handlerSelect()}
				>
					{searchIconCustomer(pet.pet_type)}{' '}
					<Typography className={classes.primaryText}>
						{pet.name}
					</Typography>{' '}
					<Typography className={classes.typoDate}>
						{pet.customers![0].first_name}{' '}
						{pet.customers![0].last_name}
					</Typography>
				</Box>
			</Box>
		));
	};

	const appointmentView = () => {
		let appointments: Array<GlobalMaster> = [];

		if (globalMaster)
			appointments =
				globalMaster &&
				globalMaster.filter(
					customer => customer.type === 'Appointment'
				);

		return appointments.map(appointment => (
			<Box
				onClick={() =>
					linkHandler(
						'/globalResult/booking&' +
							appointment.booking_id +
							'&appointment&' +
							appointment.id
					)
				}
				className={classes.link}
				key={appointment.id}
			>
				<Box
					key={appointment.id}
					className={classes.boxItem}
					onClick={() => handlerSelect()}
				>
					{iconSVG(
						faCalendarAlt,
						`${classes.sectionIcon} ${classes.listAlignedIcons}`
					)}{' '}
					<Typography className={classes.primaryText}>
						{appointment.pet_name}{' '}
					</Typography>{' '}
					<Typography className={classes.typoService}>
						- {appointment.service_name}
					</Typography>{' '}
					<Typography className={classes.typoDate}>
						{moment(appointment.booked_at).format('MM/DD/YYYY')}
					</Typography>
				</Box>
			</Box>
		));
	};

	const staffView = () => {
		let staffs: Array<GlobalMaster> = [];

		if (globalMaster)
			staffs =
				globalMaster &&
				globalMaster.filter(staff => staff.type === 'Staff');

		return staffs.map(staff => (
			<Box
				onClick={() => linkHandler(linkFormat('staff', staff!.id))}
				className={classes.link}
				key={staff.id + staff.name}
			>
				<Box
					key={staff.id + staff.name}
					className={classes.boxItem}
					onClick={() => handlerSelect()}
				>
					<Avatar
						src={staff.image}
						alt={staff.name}
						className={classes.avatar}
					/>
					<Typography className={classes.primaryText}>
						{staff.name}
					</Typography>
				</Box>
			</Box>
		));
	};

	const servicesView = () => {
		let services: Array<GlobalMaster> = [];
		if (globalMaster)
			services =
				globalMaster &&
				globalMaster.filter(service => service.type === 'Service');

		return services.map(service => (
			<Box
				onClick={() => linkHandler(linkFormat('service', service.id))}
				className={classes.link}
				key={service.id + service.name}
			>
				<Box
					key={service.id + service.name}
					className={classes.boxItem}
					onClick={() => handlerSelect()}
				>
					{iconSVG(
						faCut,
						`${classes.sectionIcon} ${classes.listAlignedIcons}`
					)}{' '}
					<Typography className={classes.primaryText}>
						{service.name}
					</Typography>
				</Box>
			</Box>
		));
	};

	const searchResultView = (
		<ClickAwayListener
			onClickAway={() => {
				clickAway();
			}}
		>
			<Box
				className={clsx(classes.boxResult, {
					[classes.searching]: searching
				})}
			>
				{searchingResult && loadingView}
				{!searchingResult && !resultSearch && clientNotFoundView}

				{!searchingResult && resultSearch && customerView()}
				{!searchingResult && resultSearch && petView()}
				{!searchingResult && resultSearch && appointmentView()}
				{!searchingResult && resultSearch && staffView()}
				{!searchingResult && resultSearch && servicesView()}
			</Box>
		</ClickAwayListener>
	);

	return (
		<Box className={classes.container}>
			{searchBoxView}
			{showSearchResult && searchResultView}
		</Box>
	);
};

export default GlobalSearch;
