import React, { FunctionComponent, useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAuth } from "hooks";

export interface DashboardProps {
  onGotoTransactions: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "block",
      justifyContent: "center",
      background: "white",
      height: "99%",
      width: "100%",
    },
    iframe: {
      width: "100%",
      height: "99%",
      border: "none",
    },
  })
);

export const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const classes = useStyles();

  const auth = useAuth();

  const url= `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_WEB_BASE_URL}payments_dashboard?access_token=${auth.token}&marketplace_id=${auth.marketplaceId}`;

  const messageHandler = (event: MessageEvent) => {
    const action: string | undefined = event.data.action;
    if(action === "goto_transactions") {
      props.onGotoTransactions && props.onGotoTransactions();
    }
  };

  useEffect(()=>{
    window.addEventListener("message", messageHandler);
    return () => {window.removeEventListener("message", messageHandler)}
  },[]);

  return (
    <Box className={classes.container}>
      <iframe title="Payments Dashboard" className={classes.iframe} src={url}></iframe>
    </Box>
  );
};

export default Dashboard;

