import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import DentalReport, {
    DentalReportAbnormality,
    DentalReportFinding
} from 'model/DentalReport';
import HeaderDentalReport from 'components/UI/HeaderReport';
import OralHygieneLevels from './UI/OralHygieneLevels';
import Remarks from 'components/UI/Remarks';
import NextDentalDate from 'components/UI/DateReport';
import { SelectableOption } from 'model';
import { Option } from '@spike/model';
import Recommendations from 'components/UI/Recommendations';
import ProcedureRecords from './UI/ProcedureRecords';
import Homecare from './UI/Homecare';
import ToothAbnormalities from './UI/ToothAbnormalities';
import PeriodontalFindings from './UI/PeriodontalFindings';
import { Button } from 'components/UI';
import { Img } from 'react-image';
import DentalReportImages, {
    ImageFile,
    ImageTypes
} from './UI/DentalReportImages';
import Declined from '../UI/Declined';
import { reduceResolution, wbp } from 'Theme';
import { isEqual } from 'lodash';
import { MobileDivider } from 'components/UI/MobileDivider';
import clsx from 'clsx';
import { useHasPremiumAccess } from 'hooks/premiumPlan';
import PremiumFeatureAlert from 'components/UI/PremiumFeatureAlert';

interface Props {
    dentalReport: DentalReport;
    singleImage: boolean;
    onSave: (report: DentalReport) => void;
    onSkip: () => void;
    back: () => void;
    onSend: (report: DentalReport) => void;
    saving?: boolean;
    declined?: boolean;
    emailConfigured?: boolean;
    sent?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: '100%',
            overflowX: 'hidden'
        },
        containerHeader: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FAFAFA',
            padding: '24px 16px 16px',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '136px',
                paddingRight: '79px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '181px',
                paddingRight: '106px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '274px',
                paddingRight: '160px'
            }
        },
        containerBody: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: '#fff',
            [theme.breakpoints.down('lg')]: {
                padding: '50px 80px 50px 50px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '274px',
                paddingRight: '160px',
                paddingTop: '80px'
            }
        },
        backTitleContainer: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            marginBottom: 24,

            [theme.breakpoints.down('sm')]: {
                gap: 10,
                alignItems: 'center'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '36px'
            }
        },
        backContainer: {
            fontSize: 20,
            display: 'flex',
            cursor: 'pointer',

            [theme.breakpoints.up('md')]: {
                width: 32,
                height: 32,
                fontSize: 27,
                marginLeft: -106,
                position: 'absolute',
                bottom: 0
            },
            [theme.breakpoints.only('xl')]: {
                width: 36,
                height: 36,
                fontSize: 36,
                marginLeft: -160
            }
        },
        titleButtonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [theme.breakpoints.up('md')]: {
                marginTop: '56px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '75px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '114px'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%'
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: '26px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '36px'
            }
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        procedureRecordsAndLevelsContainer: {
            display: 'flex'
        },
        procedureRecordsContainer: {
            display: 'flex',
            width: 'auto',
            [theme.breakpoints.down('lg')]: {
                marginRight: '75px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '100px'
            }
        },
        oralHygieneLevels: {
            gap: 16,
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                flexWrap: 'wrap'
            }
        },
        containers: {
            display: 'flex'
        },
        leftContainer: {
            [theme.breakpoints.down('lg')]: {
                width: '533px'
            },
            [theme.breakpoints.up('xl')]: {
                widht: '711px'
            }
        },
        rightContainer: {
            width: 'auto'
        },
        recommendationsContainer: {
            [theme.breakpoints.up('md')]: {
                marginTop: 40
            }
        },
        dentalMap: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                width: '80%'
            }
        },
        remarksContainer: {
            [theme.breakpoints.up('md')]: {
                marginTop: 40
            }
        },
        finalRow: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('lg')]: {
                marginBottom: '45px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '60px'
            }
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'flex-end',
            [theme.breakpoints.down('lg')]: {
                marginTop: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '16px'
            }
        },
        buttonComplete: {
            fontSize: '14px',
            height: '40px'
        },

        skipButton: {
            fontSize: '14px',
            height: '40px',
            marginRight: '10px',

            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#000000 !important'
            }
        },

        boxButtons: {
            'display': 'flex',
            'flexDirection': 'row',
            'justifyContent': 'flex-end',
            'gap': '5px',

            [theme.breakpoints.down('sm')]: {
                marginTop: 32,
                padding: '0px 16px'
            },

            '& button': {
                [theme.breakpoints.down('sm')]: {
                    'flex': 1,
                    'height': 55,

                    '& span': {
                        fontSize: 16
                    }
                }
            }
        },
        buttonPDF: {
            'color': '#EAB464',
            'cursor': 'pointer',
            'fontWeight': 600,
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(10)}px`,
                paddingRight: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '10px 20px 10px 10px'
            },

            '&:hover': {
                color: '#C18733'
            }
        },
        gridContainer: {
            padding: '16px 16px 0px',

            [theme.breakpoints.up('md')]: {
                padding: '40px 0px'
            }
        },

        gridItemContainer: {
            padding: '10px 0'
        },

        titleSection: {
            color: '#A3A3A3',
            [theme.breakpoints.down('lg')]: {
                fontSize: '11px',
                marginBottom: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
                marginBottom: '16px'
            }
        },

        nextDentalDateContainer: {
            [theme.breakpoints.down('lg')]: {
                width: '262px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '350px'
            }
        },
        nextDentalDate: {
            padding: 0
        },

        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const DentalReportComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const [dentalReport, setDentalReport] = useState<DentalReport>(
        props.dentalReport
    );

    const [levelsCalculus, setLevelsCalculus] = useState<
        Array<SelectableOption<number>>
    >([
        {
            element: { id: 1, name: '1' },
            selected: props.dentalReport.calculus === 1
        },
        {
            element: { id: 2, name: '2' },
            selected: props.dentalReport.calculus === 2
        },
        {
            element: { id: 3, name: '3' },
            selected: props.dentalReport.calculus === 3
        }
    ]);

    const [levelsPlaque, setLevelsPlaque] = useState<
        Array<SelectableOption<number>>
    >([
        {
            element: { id: 1, name: '1' },
            selected: props.dentalReport.plaque === 1
        },
        {
            element: { id: 2, name: '2' },
            selected: props.dentalReport.plaque === 2
        },
        {
            element: { id: 3, name: '3' },
            selected: props.dentalReport.plaque === 3
        }
    ]);

    const [edited, setEdited] = useState(false);

    useEffect(
        () => setEdited(isEqual(props.dentalReport, dentalReport)),
        [dentalReport]
    );

    const handleSaveDentalReport = () => {
        const dentalReportAux = { ...dentalReport };
        const dateToday =
            dentalReport.nextDentalDate?.setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0);
        if (dateToday) dentalReportAux.nextDentalDate = null;
        if (props.declined) dentalReportAux.declined = true;
        props.onSave(dentalReportAux);
    };

    const handleSendReport = () => {
        const dentalReportAux = { ...dentalReport };
        const dateToday =
            dentalReport.nextDentalDate?.setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0);
        if (dateToday) dentalReportAux.nextDentalDate = null;
        if (props.declined) dentalReportAux.declined = true;
        props.onSend(dentalReportAux);
    };

    const handleSkipDentalReport = () => {
        props.onSkip();
    };

    const handleChangeDate = (value: null | Date) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.nextDentalDate = value;
        setDentalReport(dentalReportAux);
    };

    const handleRemark = (value: string) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.remarks = value;
        setDentalReport(dentalReportAux);
    };

    const handleChangeLevelCalculus = (
        value: Array<SelectableOption<number>>
    ) => {
        const dentalReportAux = { ...dentalReport };
        setLevelsCalculus(value);
        value.forEach(lvl => {
            if (lvl.selected) {
                dentalReportAux.calculus = lvl.element.id;
            }
        });
        setDentalReport(dentalReportAux);
    };

    const handleChangeLevelPlaque = (
        value: Array<SelectableOption<number>>
    ) => {
        const dentalReportAux = { ...dentalReport };
        setLevelsPlaque(value);
        value.forEach(lvl => {
            if (lvl.selected) {
                dentalReportAux.plaque = lvl.element.id;
            }
        });
        setDentalReport(dentalReportAux);
    };

    const handleChangeRecommendation = (
        recommendations: Array<Option<number>>
    ) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.recommendations = recommendations;
        setDentalReport(dentalReportAux);
    };

    const handleChangeProcedureRecords = (
        procedureRecords: Array<Option<number>>
    ) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.procedureRecords = procedureRecords;
        setDentalReport(dentalReportAux);
    };

    const handleChangeHomecare = (homecare: Array<Option<number>>) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.homecare = homecare;
        setDentalReport(dentalReportAux);
    };

    const handleChangeToothAbnormalities = (
        toothAbnormalities: Array<DentalReportAbnormality>
    ) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.toothAbnomalities = toothAbnormalities;
        setDentalReport(dentalReportAux);
    };

    const handleChangePeriodontalFindings = (
        periodontalFindings: Array<DentalReportFinding>
    ) => {
        const dentalReportAux = { ...dentalReport };
        dentalReportAux.periodontalFindings = periodontalFindings;
        setDentalReport(dentalReportAux);
    };

    const handleImageChange = (changedImage: ImageFile) => {
        if (changedImage.type === ImageTypes.BEFORE) {
            setDentalReport(prev => ({
                ...prev,
                images: [changedImage.image, prev.images[1]],
                imageFilesToUpload: [
                    changedImage.file,
                    prev.imageFilesToUpload[1]
                ]
            }));
        } else if (changedImage.type === ImageTypes.AFTER) {
            setDentalReport(prev => ({
                ...prev,
                images: [prev.images[0], changedImage.image],
                imageFilesToUpload: [
                    prev.imageFilesToUpload[0],
                    changedImage.file
                ]
            }));
        }
    };

    const backTitleHeader = (
        <Box className={classes.backTitleContainer}>
            <Box className={classes.backContainer} onClick={() => props.back()}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        Dental Report
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    const dentalMap = (
        <Box>
            {props.dentalReport.appointment.pet.type.id === 'dogs' ? (
                <Img
                    src="/images/dentalReport/DogDentalChart.png"
                    className={classes.dentalMap}
                />
            ) : (
                <Img
                    src="/images/dentalReport/CatDentalExamination.svg"
                    className={classes.dentalMap}
                />
            )}
        </Box>
    );

    return (
        <Box className={classes.container}>
            <PremiumFeatureAlert
                isOpen={!hasReportsPremiumAccess}
                text={
                    <>
                        Grooming reports are exclusive to <b>Cuddles Premium</b>
                        . Upgrade now to unlock the full benefits of reports and
                        start sending them to your clients.
                    </>
                }
                onCancel={() => props.back()}
            />
            <Box className={classes.containerHeader}>
                {backTitleHeader}
                <HeaderDentalReport
                    appointment={props.dentalReport.appointment}
                    isReport={true}
                />
            </Box>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={10}>
                    <Grid container wrap="wrap">
                        <Grid item xs={12} md={6}>
                            {props.declined ? (
                                <Declined title={true} />
                            ) : (
                                <ProcedureRecords
                                    procedureRecords={
                                        dentalReport.procedureRecords
                                    }
                                    isDentalReport={true}
                                    multipleSelection={true}
                                    onChangeSelection={
                                        handleChangeProcedureRecords
                                    }
                                />
                            )}

                            <Box style={{ margin: '0px -16px' }}>
                                <MobileDivider />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box className={classes.oralHygieneLevels}>
                                <Box style={{ marginRight: '30px' }}>
                                    <OralHygieneLevels
                                        label={'Calculus'}
                                        isDentalReport={true}
                                        levels={levelsCalculus}
                                        onChange={handleChangeLevelCalculus}
                                    />
                                </Box>
                                <Box>
                                    <OralHygieneLevels
                                        label={'Plaque'}
                                        isDentalReport={true}
                                        levels={levelsPlaque}
                                        onChange={handleChangeLevelPlaque}
                                    />
                                </Box>
                            </Box>

                            <Box style={{ margin: '0px -16px' }}>
                                <MobileDivider />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            className={classes.recommendationsContainer}
                        >
                            <Box className={classes.recommendationsContainer}>
                                <ToothAbnormalities
                                    petType={dentalReport.appointment.pet.type}
                                    toothAbnormalities={
                                        dentalReport.toothAbnomalities
                                    }
                                    isDentalReport={true}
                                    multipleSelection={true}
                                    onChangeSelection={
                                        handleChangeToothAbnormalities
                                    }
                                />

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </Box>
                            <Box className={classes.recommendationsContainer}>
                                <PeriodontalFindings
                                    petType={dentalReport.appointment.pet.type}
                                    periodontalFindings={
                                        dentalReport.periodontalFindings
                                    }
                                    isDentalReport={true}
                                    multipleSelection={true}
                                    onChangeSelection={
                                        handleChangePeriodontalFindings
                                    }
                                />
                            </Box>

                            <Box
                                className={classes.mobile}
                                style={{ marginTop: 40 }}
                            >
                                {dentalMap}

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </Box>

                            <Box>
                                <Recommendations
                                    recommendations={
                                        dentalReport.recommendations
                                    }
                                    isReport={true}
                                    section="dental"
                                    multipleSelection={true}
                                    onChangeSelection={
                                        handleChangeRecommendation
                                    }
                                />

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </Box>
                            <Box className={classes.recommendationsContainer}>
                                <Homecare
                                    homecare={dentalReport.homecare}
                                    isDentalReport={true}
                                    multipleSelection={true}
                                    onChangeSelection={handleChangeHomecare}
                                />

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            className={clsx(
                                classes.recommendationsContainer,
                                classes.desktop
                            )}
                        >
                            {dentalMap}

                            <Box style={{ margin: '0px -16px' }}>
                                <MobileDivider />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={1}></Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        className={classes.remarksContainer}
                    >
                        <Grid item xs={12} md={6}>
                            <Remarks
                                isReport={true}
                                remark={dentalReport.remarks}
                                onChange={handleRemark}
                            />

                            <Box style={{ margin: '0px -16px' }}>
                                <MobileDivider />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DentalReportImages
                                images={dentalReport.images}
                                onChangeImage={handleImageChange}
                            />

                            <Box style={{ margin: '0px -16px' }}>
                                <MobileDivider />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={classes.gridItemContainer}
                        alignItems="flex-end"
                    >
                        <Grid item xs={12} md={6} xl={8}>
                            <Box
                                className={
                                    dentalReport.id
                                        ? classes.nextDentalDateContainer
                                        : ''
                                }
                            >
                                <NextDentalDate
                                    nextDentalDate={dentalReport.nextDentalDate}
                                    haveReport={!!dentalReport.id}
                                    section="Next Dental Date"
                                    isReport={true}
                                    isDental={true}
                                    className={classes.nextDentalDate}
                                    onChange={handleChangeDate}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} xl={4}>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={12} md={12}>
                                    <Box className={classes.boxButtons}>
                                        <Button
                                            label="Save Report"
                                            variant="secondary"
                                            className={classes.buttonComplete}
                                            onClick={handleSaveDentalReport}
                                            loading={props.saving}
                                            disabled={
                                                edited ||
                                                !hasReportsPremiumAccess
                                            }
                                        />
                                        {(props.emailConfigured ||
                                            props.sent) && (
                                            <Button
                                                label={
                                                    props.sent
                                                        ? 'Resend Report'
                                                        : 'Send Report'
                                                }
                                                className={classes.skipButton}
                                                onClick={handleSendReport}
                                                loading={props.saving}
                                                disabled={
                                                    edited ||
                                                    !hasReportsPremiumAccess
                                                }
                                                id="grooming_report_send_report_button"
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
        </Box>
    );
};

export default DentalReportComponent;
