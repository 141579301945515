import React, { FunctionComponent } from "react";
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { Moment } from "moment-timezone";

interface CalendarBookingProps {
    date: Moment;
    onDaySelect: (value: Date) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        calendar: {
            border: "1px solid #e5e5e5",
            fontFamily: "poppins",
            borderRadius: 8,
            padding: "4px 0px",
            [theme.breakpoints.up('md')]: {
                borderRadius: 9,
                padding: "5px 0px"
            },
            color: "#212529",

            "& .react-calendar__tile:hover": {
                backgroundColor: 'transparent !important',
            },

            "& .react-calendar__month-view__days__day--weekend": {
                color: "#212529",
                fontFamily: "poppins",
                padding: 5
            },

            "& .react-calendar__month-view__weekdays": {
                color: "#212529",
                fontFamily: "poppins",
                fontWeight: 500,
                textTransform: "capitalize",
                padding: "0 5px"
            },

            "& .react-calendar__month-view__days__day--neighboringMonth": {
                color: "#fff",
            },

            "& .react-calendar__navigation button": {
                borderBottom: "1px solid #e5e5e5",
                fontSize: 15
            },

            "& .react-calendar__navigation button:hover": {
                backgroundColor: "#fff",
            },


            "& .react-calendar__navigation__label:hover, & .react-calendar__navigation button,  & .react-calendar__tile, & .react-calendar__tile:disabled": {
                backgroundColor: "#fff",
                fontFamily: "poppins",
            },
            "& .react-calendar__month-view__days__day--neighboringMonth:hover": {
                color: "#fff",
            },
            "& .react-calendar__tile": {
                color: '#222',
                
                padding: '0px 5px',
                
                '& abbr': {
                    fontWeight: 500,
                    fontSize: 14,
                    width: 36,
                    height: 36,
                    borderRadius: 28,
                    [theme.breakpoints.up('md')]: {
                        borderRadius: 30
                    },
                    margin: 'auto',
                    display: 'block',
                    lineHeight: '36px'
                }
            },
            "& .react-calendar__navigation__arrow:not([disabled])": {
                color: '#222',
            },
            "& .react-calendar__tile:not(.react-calendar__tile--active)": {
               
                '& abbr': {
                    '&:hover': {
                        backgroundColor: "#FAEFDF !important",
                    }
                }
            },
            "& .react-calendar__tile--active": {
                fontWeight: 600,
                color: "#fff !important",
                backgroundColor: 'transparent !important',

                '& > abbr': {
                    backgroundColor: "#F2D2A2 !important",
                    color: "#222"
                }
            },
            "& .react-calendar__tile:disabled": {
                color: "#d5d5d5",
            },
            "& abbr": {
                textDecoration: "none",
            },
            "& .react-calendar__navigation__label__labelText": {
                color: '#222',
                fontWeight: 500,
                fontSize: 15
            },
            "&. react-calendar__navigation": {
                borderBottom: "3px solid #000",
            },

            "& .react-calendar__month-view__weekdays__weekday": {
                fontWeight: 500,
                fontSize: 12,
                [theme.breakpoints.up('md')]: {
                    fontSize: 14
                },
            },
        },
    })
);

export const CalendarBooking: FunctionComponent<CalendarBookingProps> = (props) => {
    const classes = useStyles();

    const changeDate = (daySelect: Date) => {
        props.onDaySelect(daySelect);
    };

    return (
        <Calendar
            calendarType="US"
            onChange={(value: any) => changeDate(value)}
            value={props.date.toDate()}
            className={classes.calendar}
            nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
            prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
            prev2Label={null}
            next2Label={null}
            showNeighboringMonth={false}
            locale={"en"}
        />
    )
}

export default CalendarBooking
