import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ConfirmDialog, LogoCuddles } from "components/UI";
import React, { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import { faCheck, faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

import { StepsHeaderOrder } from "@spike/masterdata-model";

interface OverFullWindowHeaderStepsProps {
  title?: string;
  optionsSteps?: Array<StepsHeaderOrder>;
  className?: string;
  showBackClose: boolean;
  //
  onBack?: () => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      borderBottom: "1px solid #DDDDDD;",
      [theme.breakpoints.down(wbp)]: {
        minHeight: `${reduceResolution(66)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        minHeight: "66px",
      },
    },
    boxIconBack: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "35px",
      },
    },
    leftContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "20%",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(32)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "32px",
      },
    },
    centerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "60%",
    },
    rightContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "20%",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(32)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "32px",
      },
    },
    logo: {
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(42)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "42px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "black",
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
      },
    },
    closeIcon: {
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(24)}px !important`,
        height: `${reduceResolution(24)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "24px !important",
        height: "24px !important",
      },
    },
    boxStepsContainer: {
      display: "flex",
    },
    stepItem: {
      display: "flex",
      color: "#CCC",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "5px",
      },
    },
    boxNumber: {
      background: "#CCC",
      color: "#fff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      fontWeight: 500,
      cursor: "not-allowed",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
        marginRight: `${reduceResolution(5)}px`,
        borderRadius: `${reduceResolution(15)}px`,
        height: `${reduceResolution(25)}px`,
        width: `${reduceResolution(25)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
        marginRight: "5px",
        borderRadius: "15px",
        height: "25px",
        width: "25px",
      },
    },
    stepActive: {
      background: "#000",
      cursor: "pointer",
    },
    stepFinished: {
      background: "#5E8677",
    },
    checkIcon: {
      color: "#fff",
    },
    boxNameStep: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      paddingRight: "5px",
      fontWeight: 500,
      cursor: "not-allowed",
      lineHeight: 1,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },
    nameActive: {
      color: "#000",
      cursor: "pointer",
    },
    boxStepMiddle: {
      borderBottom: "1px dashed #D3D3D3",
      width: "30px",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "15px",
      },
    }
  })
);

export const OverFullWindowHeaderSteps: FunctionComponent<OverFullWindowHeaderStepsProps> = (props) => {
  const classes = useStyles();

  const totalSteps = props.optionsSteps?.length;
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const closeHandler = () => {
    let option = props.optionsSteps?.find((option) => option.active === true);

    option?.id === "payment" ? setShowDelete(true) : props.onClose && props.onClose();
  };

  const deleteHandler = () => {
    props.onClose && props.onClose();
  };

  const deletePopUp = (
    <ConfirmDialog
      open={showDelete}
      title={<Typography>Delete Order</Typography>}
      question={
        <Typography >
          Are you sure you want to Delete the order?
        </Typography>}
      confirmButtonLabel={"Delete"}
      cancelButtonLabel={"Cancel"}
      onCancel={() => setShowDelete(false)}
      onConfirm={deleteHandler}
    />
  );

  return (
    <>
      <Box className={clsx(classes.container, props.className)}>
        <Box className={classes.leftContainer}>
          <Box className={classes.boxIconBack}>
            {props.onBack
              && !props.optionsSteps!.find((op) => op.order === 1)?.active
              && props.showBackClose
              && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className={classes.closeIcon}
                  onClick={() => {
                    props.onBack && props.onBack();
                  }}
                />
              )}
          </Box>
          <Box>
            <LogoCuddles />
          </Box>
        </Box>
        <Box className={classes.centerContainer}>
          {
            props.optionsSteps &&
            <Box className={classes.boxStepsContainer}>
              {
                props.optionsSteps
                  .sort((a, b) => a.order! > b.order! ? 1 : -1)
                  .map((step) => {
                    return <Box
                      className={clsx(classes.stepItem)}
                    >
                      <Box className={
                        clsx(classes.boxNumber,
                          { [classes.stepActive]: step.active },
                          { [classes.stepFinished]: step.finished },
                        )}>
                        {step.finished ?
                          <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
                          : step.order}
                      </Box>
                      <Box className={clsx(classes.boxNameStep, { [classes.nameActive]: step.active || step.finished })}>{step.name}</Box>
                      {step.order !== totalSteps && <Box className={clsx(classes.boxStepMiddle)}></Box>}
                    </Box>
                  })
              }
            </Box>
          }
        </Box>
        <Box className={classes.rightContainer}>
          {props.onClose
            && props.showBackClose
            && (
              <FontAwesomeIcon
                icon={faTimes}
                className={classes.closeIcon}
                onClick={closeHandler}
              />
            )}
        </Box>
      </Box >
      {deletePopUp}

    </>
  );
};

export default OverFullWindowHeaderSteps;
