import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Box, Dialog, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Week, FieldError } from '@spike/model';
import { Button, Link } from 'components/UI';
import { useTimeZone } from 'hooks';
import _ from 'lodash';
import { Period } from '@spike/model';
import { Marketplace } from '@spike/marketplace-model';
import { StaffSchedule as Schedule } from 'model/Staff';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
import DefaultSchedule from './DefaultSchedule';
import StaffScheduleDay, { Day } from './StaffScheduleDay';
import StaffScheduleHeader from './StaffScheduleHeader';
import {
	isDefault as hasDefaultSchedule,
	removeCustomsEqualsAsDefault
} from './utils/StaffScheduleUtils';
import {
	validateDays,
	validateDaysByMarketplaceSchedule
} from './utils/Validations';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface StaffScheduleProps {
	schedule: Schedule;
	staffId?: number;
	errors?: Array<FieldError>;
	onChange?: (schedule: Schedule) => void;
	customTitle?: string;
	titleClass?: string;
	weeklyScheduleTitle?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%'
		},
		header: {
			display: 'flex',
			width: '100%'
		},
		headerLeft: {
			display: 'flex',
			flexDirection: 'column',
			width: '50%',
			justifyContent: 'flex-start'
		},
		headerRight: {
			display: 'flex',
			width: '50%',
			justifyContent: 'flex-end',
			alignItems: 'flex-start'
		},
		editDefaultLink: {
			'height': '20px',
			'& span': {
				fontWeight: 500,
				[theme.breakpoints.down('sm')]: {
					fontSize: '8px',
					lineHeight: '14px'
				},
				[theme.breakpoints.only('md')]: {
					fontSize: '10px',
					lineHeight: '18px'
				},
				[theme.breakpoints.only('lg')]: {
					fontSize: '13px',
					lineHeight: '22px'
				},
				[theme.breakpoints.only('xl')]: {
					fontSize: '16px',
					lineHeight: '27px'
				}
			}
		},
		mobileHeader: {
			'display': 'flex',
			'alignItems': 'center',
			'justifyContent': 'space-between',

			'& button': {
				'all': 'unset',
				'border': 0,
				'fontSize': 14,
				'fontWeight': 500,
				'paddingTop': 0,
				'paddingLeft': 0,
				'paddingRight': 0,
				'paddingBottom': 3,
				'color': '#92B4A7',
				'background': 'none',
				'borderBottom': 'solid 1.5px #92B4A7',

				'& svg': {
					marginRight: 4
				}
			}
		},
		title: {
			fontSize: 20,
			fontWeight: 600,
			color: '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: '16px',
				lineHeight: '18px'
			}
		},
		subtitle: {
			fontSize: 14,
			marginTop: 15,
			fontWeight: 400,
			color: '#000',

			[theme.breakpoints.up('md')]: {
				fontSize: 16,
				marginTop: 5,
				color: '#7A7A7A'
			}
		},
		weekContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			borderBottom: '1.5px solid #D4D4D4',
			paddingBottom: 12,
			marginTop: 36,

			[theme.breakpoints.down('sm')]: {
				marginBottom: 10
			}
		},
		body: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.down('sm')]: {
				marginBottom: 24
			}
		},
		dayContainer: {
			[theme.breakpoints.down('sm')]: {
				marginTop: '10px'
			},
			[theme.breakpoints.only('md')]: {
				marginTop: '16px'
			},
			[theme.breakpoints.only('lg')]: {
				marginTop: '21px'
			},
			[theme.breakpoints.only('xl')]: {
				marginTop: '32px'
			}
		},
		defaultScheduleDialog: {
			'& .MuiPaper-root': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			},
			'& .MuiDialog-paper': {
				backgroundColor: '#fff',
				justifyContent: 'flex-start',

				[theme.breakpoints.up('md')]: {
					padding: '45px 0px',
					backgroundColor: 'rgba(255,255,255,0)'
				}
			},
			'& .MuiPaper-rounded': {
				borderRadius: 0
			}
		},
		desktop: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		mobile: {
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}
	})
);

const convertToWeekDays = (
	schedule: Schedule,
	period: Period,
	timeZone: string
): Array<Day> => {
	const defaultDays = _.range(
		period.to.clone().add(1, 'day').diff(period.from.clone(), 'day')
	).map(day =>
		convertByDefault(period.from.clone().add(day, 'd'), schedule, timeZone)
	);

	return defaultDays.map(day => convertByCustom(day, schedule));
};

const convertByDefault = (
	day: Moment,
	schedule: Schedule,
	timeZone: string
): Day => {
	const weekDay = day.clone().format('dddd').toLowerCase() as keyof Week;
	const businessHours = schedule.default[weekDay];

	return businessHours === null || businessHours === undefined
		? {
				uuid: uuid(),
				from: day.clone().startOf('day'),
				to: day.clone().endOf('day'),
				on: false
		  }
		: {
				uuid: uuid(),
				from: moment.tz(
					`${day.format('YYYYMMDD')} ${businessHours.open}`,
					'YYYYMMDD HH:mm',
					timeZone
				),
				to: moment.tz(
					`${day.format('YYYYMMDD')} ${businessHours.close}`,
					'YYYYMMDD HH:mm',
					timeZone
				),
				on: true
		  };
};

const convertByCustom = (day: Day, schedule: Schedule): Day => {
	const customDay = schedule.customDays.find(
		customDay =>
			customDay.from.clone().startOf('day').format('YYYYMMDD') ===
			day.from.clone().startOf('day').format('YYYYMMDD')
	);

	return customDay
		? {
				id: customDay.id,
				uuid: customDay.uuid,
				from: customDay.from,
				to: customDay.to,
				on: customDay.on
		  }
		: { ...day };
};

export const StaffSchedule: FunctionComponent<StaffScheduleProps> = props => {
	const classes = useStyles();

	const timeZone = useTimeZone();
	const marketplace = useSelector<RootState, Marketplace>(
		state => state.marketplace.marketplace
	);

	const [weekPeriod, setWeekPeriod] = useState<Period>({
		from: moment.tz(timeZone).startOf('isoWeek').startOf('day'),
		to: moment.tz(timeZone).endOf('isoWeek').endOf('day')
	});

	const [weekDays, setWeekDays] = useState<Array<Day>>([]);
	const [showDefaultSchedule, setShowDefaultSchedule] = useState(false);
	const [errors, setErrors] = useState<Array<FieldError>>([]);

	useEffect(() => {
		setErrors(props.errors || []);
	}, [props.errors]);

	useEffect(() => {
		setWeekDays(convertToWeekDays(props.schedule, weekPeriod, timeZone));
	}, [weekPeriod]);

	const changeDayHandler = (day: Day, timeZone: string) => {
		setWeekDays(prevDays =>
			prevDays.map(prevDay =>
				prevDay.from.format('YYYYMMDD') === day.from.format('YYYYMMDD')
					? { ...day }
					: { ...prevDay }
			)
		);

		const defaultBusinessHours =
			props.schedule.default[
				day.from.format('dddd').toLowerCase() as keyof Week
			];

		const defaultDay = {
			from:
				defaultBusinessHours === null
					? moment
							.tz(
								`${day.from.format('YYYYMMDD')}`,
								'YYYYMMDD',
								timeZone
							)
							.startOf('day')
					: moment.tz(
							`${day.from.format('YYYYMMDD')} ${
								defaultBusinessHours.open
							}`,
							'YYYYMMDD HH:mm',
							timeZone
					  ),
			to:
				defaultBusinessHours === null
					? moment
							.tz(
								`${day.to.format('YYYYMMDD')}`,
								'YYYYMMDD',
								timeZone
							)
							.endOf('day')
					: moment.tz(
							`${day.to.format('YYYYMMDD')} ${
								defaultBusinessHours.close
							}`,
							'YYYYMMDD HH:mm',
							timeZone
					  ),
			on: defaultBusinessHours !== null
		};

		if (
			(!defaultDay.on && !day.on) ||
			(defaultDay.from.isSame(day.from) &&
				defaultDay.to.isSame(day.to) &&
				defaultDay.on === day.on)
		) {
			props.onChange &&
				props.onChange({
					...props.schedule,
					customDays: props.schedule.customDays.filter(
						customDay =>
							customDay.from.clone().format('YYYYMMDD') !==
							day.from.clone().format('YYYYMMDD')
					)
				});
		} else if (
			props.schedule.customDays.some(
				customDay => customDay.uuid === day.uuid
			)
		) {
			props.onChange &&
				props.onChange({
					...props.schedule,
					customDays: props.schedule.customDays.map(customDay =>
						customDay.uuid === day.uuid
							? {
									id: day.id,
									uuid: day.uuid,
									from: day.from.clone(),
									to: day.to.clone(),
									on: day.on
							  }
							: customDay
					)
				});
		} else {
			props.onChange &&
				props.onChange({
					...props.schedule,
					customDays: [
						...props.schedule.customDays,
						{
							uuid: day.uuid,
							from: day.from.clone(),
							to: day.to.clone(),
							on: day.on
						}
					]
				});
		}
	};

	const changeDefaultScheduleHandler = (defaultSchedule: Week) => {
		const filteredCustomDays = removeCustomsEqualsAsDefault(
			props.schedule.customDays,
			defaultSchedule
		);
		const newSchedule = {
			...props.schedule,
			customDays: filteredCustomDays,
			default: { ...defaultSchedule }
		};
		props.onChange && props.onChange(newSchedule);
		setWeekDays(
			convertToWeekDays({ ...newSchedule }, weekPeriod, timeZone)
		);
	};

	const changeWeekPeriod = (period: Period) => {
		//const errors = [...validateDays(weekDays, weekPeriod), ...validateDaysByMarketplaceSchedule(weekDays, marketplace.schedule, weekPeriod)];
		const errors = validateDays(weekDays, weekPeriod);
		setErrors(errors);
		if (errors.length === 0) {
			setWeekPeriod(period);
		}
	};

	const defaultSchedule = (
		<Dialog
			fullScreen={true}
			open={true}
			className={classes.defaultScheduleDialog}
		>
			<DefaultSchedule
				week={props.schedule.default}
				staffId={props.staffId}
				onClose={() => setShowDefaultSchedule(false)}
				onChange={changeDefaultScheduleHandler}
			/>
		</Dialog>
	);

	return (
		<Box className={classes.container}>
			<Box className={classes.header}>
				<Box className={clsx(classes.headerLeft, classes.desktop)}>
					<Typography className={classes.title}>
						Weekly Schedule
					</Typography>
					<Typography className={classes.subtitle}>
						Customize schedule by week.
					</Typography>
				</Box>
				<Box className={clsx(classes.headerRight, classes.desktop)}>
					{!hasDefaultSchedule(props.schedule) ? (
						<Button
							id="staff_add_member_button_setupdefaultschedule"
							label="Setup Default Schedule"
							color="green"
							size="small"
							startIcon={faCalendarAlt}
							onClick={() => setShowDefaultSchedule(true)}
						/>
					) : (
						<Link
							id="staff_add_member_button_editdefaultschedule"
							label="Edit Default"
							color="green"
							size="small"
							startIcon={faCalendarAlt}
							className={classes.editDefaultLink}
							onClick={() => setShowDefaultSchedule(true)}
						/>
					)}
				</Box>
				<Box className={classes.mobile} style={{ width: '100%' }}>
					<Box className={classes.mobileHeader}>
						<Typography className={clsx(classes.title, props.titleClass && props.titleClass)}>
							{props.customTitle || 'Schedule'}
						</Typography>

						<button
							id="staff_add_member_button_setupdefaultschedule"
							onClick={() => setShowDefaultSchedule(true)}
						>
							<FontAwesomeIcon icon={faPen} />
							{props.weeklyScheduleTitle ? props.weeklyScheduleTitle : "Setup Weekly Schedule"}
						</button>
					</Box>

					<Typography className={classes.subtitle}>
						Customize schedule by week.
					</Typography>
				</Box>
			</Box>
			<Box className={classes.weekContainer}>
				<StaffScheduleHeader
					week={weekPeriod}
					onChange={period => changeWeekPeriod(period)}
				/>
			</Box>
			<Box className={classes.body}>
				{weekDays.map((day, index) => (
					<StaffScheduleDay
						id={'staff_add_member_schedule_' + index}
						key={day.from.format('YYYYMMDD')}
						className={classes.dayContainer}
						day={day}
						onChange={day => changeDayHandler(day, timeZone)}
						marketplaceSchedule={{ ...marketplace.schedule.week }}
						errors={errors}
					/>
				))}
			</Box>
			{showDefaultSchedule && defaultSchedule}
		</Box>
	);
};

export default StaffSchedule;
