import { makeStyles, Theme } from "@material-ui/core";

const commonsStyles = makeStyles((theme: Theme) => ({
    underlinedLink: {
        color: '#EAB464',
        fontSize: 16,
        lineHeight: 1.69,
        fontWeight: 600,
        textDecoration: 'underline',
        '&:hover': {
            color: '#C18733',
            textDecoration: 'none'
        }
    },
    underlinedBlackLink: {
        color: '#000000',
        fontSize: 16,
        lineHeight: 1.69,
        fontWeight: 500,
        textDecoration: 'underline',
        '&:hover': {
            color: '#EAB464'
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 14
        },
    }
}))

export default commonsStyles