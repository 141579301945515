import React, { FunctionComponent, useState, useEffect } from 'react';
import { Checkbox as MUICheckbox, Typography, Box } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface Props {
	label?: string;
	checked: boolean;
	fontColor?: string;
	fontWeight?: number;
	onChange?: (checked: boolean) => void;
	className?: string;
	iconColor?: string;
	icon?: React.ReactNode;
	checkedIcon?: React.ReactNode;
	disabled?: boolean;
	id?: string;
	description?: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: props => ({
			display: 'flex',
			flexDirection: 'column',
			justifyContent:
				props.label === undefined || props.label.length === 0
					? 'center'
					: 'flex-start'
		}),
		form: {
			'marginLeft': 0,
			'marginRight': 0,

			'& .MuiCheckbox-root': {
				paddingLeft: 0,
				paddingRight: 11
			}
		},
		noneLabel: {
			marginLeft: 0,
			marginRight: 0
		},
		label: (props: Props) => ({
			fontSize: 16,
			fontWeight: props.fontWeight,
			fontColor: props.fontColor,
			whiteSpace: 'nowrap'
		}),
		description: {
			fontSize: 14,
			color: '#7A7A7A',

			[theme.breakpoints.up('md')]: {
				marginLeft: '35px'
			}
		},
		checkbox: (props: Props) => ({
			paddingTop: 0,
			paddingBottom: 0,
			color: props.iconColor || '#000000'
		})
	})
);

export const Checkbox: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const [checked, setChecked] = useState(props.checked);

	useEffect(() => {
		setChecked(props.checked);
	}, [props.checked]);

	const label = (
		<Typography className={classes.label}>{props.label || ''}</Typography>
	);

	const clickHandler = (): void => {
		props.onChange && props.onChange(!checked);
	};

	return (
		<Box className={classes.container}>
			<FormControlLabel
				value="top"
				control={
					<MUICheckbox
						id={props.id || ''}
						checked={checked}
						color="default"
						disableRipple={true}
						disableFocusRipple={true}
						disableTouchRipple={true}
						onChange={clickHandler}
						icon={props.icon}
						checkedIcon={props.checkedIcon}
						disabled={props.disabled}
						className={classes.checkbox}
					/>
				}
				label={label}
				labelPlacement="end"
				className={clsx(classes.form, props.className)}
			/>
			<Typography className={classes.description}>
				{props.description || ''}
			</Typography>
		</Box>
	);
};

export default Checkbox;
