import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { wbp, reduceResolution } from "Theme";


interface Props {
  title: string;
  subtitle: string;
  icon?: string;
  alertIncon?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(26)}px`
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "26px",
      },
    },
    caption: {
      color: "#7A7A7A",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },
    iconTitle: {
      width: "100%",
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(26)}px`
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "26px",
      },
    },
    icon: {
      marginLeft: "7px",
      width: "24.24px",
    },
    alertIcon: {
      marginLeft: "7px",
      width: "24.24px",
      marginBottom: "5px",
    },
  })
);

export const OnboardingTitle: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.iconTitle}>
        👋🏻
      </Typography>
      <Typography className={classes.title}>
        {props.title}
      </Typography>

      <Typography variant="caption" className={classes.caption}>
        {props.subtitle}
      </Typography>
    </>
  );
};
