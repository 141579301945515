import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ChipStatus } from "components/PetCard/UI/ChipStatus";
import { vaccineAttention, vaccineWarning } from "components/PetCard/utils/PetCardUtils";
import { PetVaccine } from "@spike/medical-history-model";
import { FunctionComponent, useEffect, useState } from "react";
import { reduceResolution, wbp } from "Theme";

interface VaccinesStatusFieldProps {
  vaccine: PetVaccine;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    expiredIcon: {
      color: "#C18733",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },
    expirationContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    missingExpirationMessageContainer: {
      display: "flex",
      position: "absolute",
      background: "#ffffff",
      zIndex: 300,
      border: "1px solid #D3D3D3",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(4)}px`,
        padding: `${reduceResolution(10)}px ${reduceResolution(12)}px`,
        marginLeft: `${reduceResolution(90)}px`,
        marginTop: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "4px",
        padding: "10px 12px",
        marginLeft: "90px",
        marginTop: "30px",
      },
    },
    missingExpirationMessage: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px",
      },
    },
  })
);

export const VaccinesStatusField: FunctionComponent<VaccinesStatusFieldProps> = (props) => {
  const classes = useStyles();

  const [statusVaccine, setStatusVaccine] = useState<"success" | "attention" | "warning">("success");
  const [statusLabel, setStatusLabel] = useState<string>("Active");
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (props.vaccine) {
      if (vaccineWarning(props.vaccine)) {
        setStatusVaccine("warning");
        setStatusLabel("Expired");
      }

      if (vaccineAttention(props.vaccine)) {
        setStatusVaccine("attention");
        setStatusLabel("Missing Expiration");
      }
    }
  }, [props.vaccine]);

  const overHandler = () => {
    setShowWarning(true);
  };

  const leaveHandler = () => {
    setShowWarning(false);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <ChipStatus label={statusLabel} type={statusVaccine} />

      <Box className={classes.expirationContainer} onMouseOver={overHandler} onMouseOut={leaveHandler}>
        {statusVaccine === "attention" && (
          <FontAwesomeIcon icon={faExclamationCircle} className={classes.expiredIcon} />
        )}
      </Box>

      {showWarning && (
        <Box className={classes.missingExpirationMessageContainer}>
          <Typography className={classes.missingExpirationMessage}>
            Please add vaccination <br /> expiration date
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default VaccinesStatusField;
