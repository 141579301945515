import React, { Fragment, useMemo, useState } from 'react';
import Pet from '@spike/pet-model';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Button, ConfirmDialog } from 'components/UI';
import { useDispatch } from 'react-redux';
import { markPetAsDeceasedThunk, savePetThunk } from '@spike/pets-action';
import { useApiClientWrapper } from 'hooks';

interface DeceasedPetProps {
    pet: Pet
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 45,
        paddingBottom: 35,
        borderBottom: 'solid 1px #D4D4D4',
        [theme.breakpoints.down('md')]: {
            marginTop: "10px",
            paddingBottom: "25px",
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: "18px",
        }
    },
    box: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: '14px 27px',
        backgroundColor: '#fff',
        border: 'solid 1px #DDDDDD',
        [theme.breakpoints.down('md')]: {
            padding: "0px",
            border: "none",
            borderRadius: "0px",
        }
    },
    button: {
        height: 47,
        [theme.breakpoints.down('md')]: {
            height: "40px",
            fontSize: "14px",
            "& span": {
                fontSize: "14px"
            }
        }
    },
    dialogActions: {
        width: '100%',
        display: 'flex',

        '& button': {
            flex: 1,
            height: "50px",
            '& + button': {
                marginLeft: 10
            }
        }
    },
    deceasedInfo:{
        [theme.breakpoints.down('md')]: {
            "& p": {
                fontSize: "14px"
            }
        }
    },
    statusPet:{
        [theme.breakpoints.down('md')]: {
            fontSize: "16px"
        }
    }
}));

export const DeceasedPet: React.FunctionComponent<DeceasedPetProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const [loading, setLoading] = useState(false);
    const [showConfirm, setConfirm] = useState(false);

    const toggle = () => {
        setConfirm(false);
        props.pet.deceased ? enable() : disable();
    };

    const disable = async () => {
        if (!props.pet.id || loading) {
            return;
        }

        setLoading(true);
        await dispatch(savePetThunk(apiClientWrapper, { ...props.pet, active: false, deceased: true }));
        setLoading(false);
    };

    const enable = async () => {
        if (!props.pet.id || loading) {
            return;
        }

        setLoading(true);
        await dispatch(savePetThunk(apiClientWrapper, { ...props.pet, active: true, deceased: false }));
        setLoading(false);
    };

    const isDeceased = useMemo(() => {
        return props.pet.deceased;
    }, [props.pet]);

    return (
        <Fragment>
            <Grid
                container
                spacing={4}
                className={classes.root}
            >
                <Grid className={classes.deceasedInfo} item xs={12} md={5} lg={5}>
                    <Typography
                        variant="h3"
                        className={classes.title}
                        children={"🌈 Deceased Pet"}
                    />
                    <Typography>If this pet has passed away, you can make their account inactive here. By marking the account inactive, you can still view the pet's history, but their parent will no longer receive notifications about their pet & appointments will be disabled.</Typography>
                </Grid>

                <Grid item xs>
                    <Box className={classes.box}>
                        {
                            props.pet.deceased ?
                                <Typography className={classes.statusPet}>😔 This pet is inactive</Typography> :
                                <Typography className={classes.statusPet}>😃 This pet is active</Typography>
                        }

                        {props.pet.deceased ?
                            <Button
                                color="orange"
                                label="Enable pet"
                                loading={loading}
                                className={classes.button}
                                onClick={() => setConfirm(true)}
                            /> :
                            <Button
                                id='pet_component_disable_pet_button'
                                color="black"
                                label="Disable pet"
                                loading={loading}
                                className={classes.button}
                                onClick={() => setConfirm(true)}
                            />
                        }
                    </Box>
                </Grid>
            </Grid>

            <ConfirmDialog
                open={showConfirm}
                title={<Typography>Deceased Pet</Typography>}
                onCancel={() => setConfirm(false)}
                question={
                    <Typography>
                        {
                            props.pet.deceased ?
                                'Are you sure you want to enable this pet?' :
                                'Are you sure you want to disable this pet?'
                        }
                    </Typography>
                }

                actions={
                    <Box className={classes.dialogActions}>
                        <Button
                            id="clients_dialog_deceased_pet_cancel_button"
                            color="black"
                            label="Cancel"
                            disabled={loading}
                            onClick={() => setConfirm(false)}
                        />

                        <Button
                            id="clients_dialog_deceased_pet_confirm_button"
                            label="Yes"
                            color="green"
                            loading={loading}
                            variant={isDeceased ? "primary" : "danger"}
                            onClick={toggle}
                        />
                    </Box>
                }
            />
        </Fragment>
    )
};