import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import ToolbarButton from 'components/UI/Toolbar/ToolbarButton';
import { ToolbarItemWithConfirmation } from 'components/UI/Toolbar/ToolbarItemWithConfirmation';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
    position?: number;
    title?: string;
    collapsed?: boolean;
    disabled?: boolean;
    editable?: boolean;
    completed?: boolean;
    modifiable?: boolean;
    removeQuestion?: JSX.Element;
    onEdit?: () => void;
    onRemove?: () => void;
    onModify?: () => void;
    onCollapse?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',

            [theme.breakpoints.down('sm')]: {
                padding: '0px'
            },
            [theme.breakpoints.up('md')]: {
                overflow: 'hidden',
                borderRadius: '18px',
                border: '1px solid #F4F3F0'
            }
        },
        headerContainer: {
            gap: 11,
            height: 48,
            width: '100%',
            display: 'flex',
            padding: '0px 16px',
            backgroundColor: '#F4F4F4',
            borderRadius: 10,

            [theme.breakpoints.down('sm')]: {
                marginBottom: 20
            },
            [theme.breakpoints.up('md')]: {
                height: 34,
                padding: 0,
                background: '#E9F0ED',
                borderTopLeftRadius: '18px',
                borderTopRightRadius: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                height: 46
            }
        },
        collapsedHeaderContainerDisabled: {
            [theme.breakpoints.up('md')]: {
                background: '#EFEFEF'
            }
        },
        collapsedHeaderContainer: {
            [theme.breakpoints.up('md')]: {
                'background': '#EFEFEF',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#E9F0ED'
                }
            }
        },

        leftHeaderContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '32px'
            }
        },
        noneLeftHeaderContainer: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '26px'
            }
        },
        completedCheck: {
            color: '#5E8677'
        },
        positionCircle: {
            width: 23,
            height: 23,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000',
            borderRadius: '50%',

            [theme.breakpoints.up('md')]: {
                background: '#5E8677'
            }
        },
        position: {
            fontSize: 14,
            fontWeight: 500,
            color: '#FFFFFF'
        },
        centerHeaderContainer: {
            display: 'flex',
            flex: '1 1 auto',
            alignItems: 'center'
        },
        title: {
            color: '#000',
            fontSize: 16,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                color: '#5E8677'
            }
        },
        rightHeaderContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            [theme.breakpoints.up('md')]: {
                width: '150px',
                paddingRight: '32px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '200px',
                paddingRight: '43px'
            }
        },
        bodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: '#FFFFFF',
            [theme.breakpoints.down('lg')]: {
                borderBottomLeftRadius: '18px',
                borderBottomRightRadius: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px'
            }
        },
        disabled: {
            [theme.breakpoints.up('md')]: {
                'color': '#989898',
                '&:hover': {
                    cursor: 'auto !important',
                    backgroundColor: '#EFEFEF !important'
                }
            }
        },
        postionDisabled: {
            [theme.breakpoints.up('md')]: {
                background: '#989898',
                color: '#EFEFEF'
            }
        },
        editLink: {
            cursor: 'pointer',
            color: '#92B4A7',
            textDecoration: 'underline',
            fontWeight: 500,
            [theme.breakpoints.down('lg')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '18px'
            }
        },
        editLinkDisabled: {
            cursor: 'auto !important',
            color: '#989898 !important'
        },
        angleIcon: {
            fontSize: 20,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const Section: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const headerContainerStyle = clsx(classes.headerContainer, {
        [classes.collapsedHeaderContainerDisabled]: props.collapsed,
        [classes.collapsedHeaderContainer]: props.collapsed,
        [classes.disabled]: props.disabled || props.onEdit === undefined
    });

    const titleStyle = clsx(classes.title, {
        [classes.disabled]: props.disabled
    });

    const positionCircleStyle = clsx(classes.positionCircle, {
        [classes.postionDisabled]: props.disabled
    });

    const positionStyle = clsx(classes.position, {
        [classes.postionDisabled]: props.disabled
    });

    const menu = (
        <Box>
            <ToolbarButton>
                <ToolbarItemWithConfirmation
                    icon={faTrash}
                    text={'Delete'}
                    onConfirmedClick={() => props.onRemove && props.onRemove()}
                    confirmQuestion={
                        props.removeQuestion || <Typography>Are you sure you want to delete it?</Typography>
                    }
                    confirmButtonLabel="Delete"
                    cancelButtonLabel="Cancel"
                />
            </ToolbarButton>
        </Box>
    );

    const handleEdit = () => {
        if (props.disabled === false) {
            if (props.collapsed && (props.editable || props.modifiable)) {
                props.onEdit && props.onEdit();
            } else {
                props.onCollapse && props.onCollapse();
            }
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={headerContainerStyle} onClick={handleEdit}>
                <Box
                    className={clsx(classes.leftHeaderContainer, {
                        [classes.noneLeftHeaderContainer]: !props.position && !props.completed
                    })}
                >
                    {props.completed ? (
                        <FontAwesomeIcon size="lg" icon={faCheckCircle} className={classes.completedCheck} />
                    ) : props.position ? (
                        <Box className={positionCircleStyle}>
                            <Typography className={positionStyle}>{props.position}</Typography>
                        </Box>
                    ) : null}
                </Box>
                <Box className={classes.centerHeaderContainer}>
                    <Typography className={titleStyle}>{props.title}</Typography>
                </Box>
                <Box className={classes.rightHeaderContainer}>
                    {props.collapsed && props.editable && (
                        <Box>
                            <Typography
                                className={
                                    props.disabled || props.onEdit === undefined
                                        ? clsx(classes.editLink, classes.editLinkDisabled)
                                        : classes.editLink
                                }
                            >
                                Edit
                            </Typography>
                        </Box>
                    )}
                    {props.onRemove && menu}

                    <FontAwesomeIcon className={classes.angleIcon} icon={props.collapsed ? faAngleDown : faAngleUp} />
                </Box>
            </Box>
            {!props.collapsed && <Box className={classes.bodyContainer}>{props.children}</Box>}
        </Box>
    );
};

export default Section;
