import { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCheckCircle, faTrash } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import { ToolbarItemWithConfirmation } from "components/UI/Toolbar/ToolbarItemWithConfirmation";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  position?: number;
  title?: string;
  collapsed?: boolean;
  disabled?: boolean;
  editable?: boolean;
  completed?: boolean;
  modifiable?: boolean;
  removeQuestion?: JSX.Element;
  onEdit?: () => void;
  onRemove?: () => void;
  onModify?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      border: "1px solid #F4F3F0",
      [theme.breakpoints.down("md")]: {
        borderRadius: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "24px",
      },
    },
    headerContainer: {
      display: "flex",
      width: "100%",
      background: "#E9F0ED",
      [theme.breakpoints.down("md")]: {
        height: "37px",
        borderTopLeftRadius: "19px",
        borderTopRightRadius: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "46px",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
      },
    },
    collapsedHeaderContainerDisabled: {
      background: "#EFEFEF",
      [theme.breakpoints.down("md")]: {
        borderBottomLeftRadius: "19px",
        borderBottomRightRadius: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        borderBottomLeftRadius: "24px",
        borderBottomRightRadius: "24px",
      },
    },
    collapsedHeaderContainer: {
      background: "#EFEFEF",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#E9F0ED"
      },
      [theme.breakpoints.down("md")]: {
        borderBottomLeftRadius: "19px",
        borderBottomRightRadius: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        borderBottomLeftRadius: "24px",
        borderBottomRightRadius: "24px",
      },
    },
    leftHeaderContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "34px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "43px",
      },
    },
    noneLeftHeaderContainer: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "21px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "26px",
      },
    },
    completedCheck: {
      color: "#5E8677",
    },
    positionCircle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#5E8677",
      [theme.breakpoints.down("md")]: {
        width: "19px",
        height: "19px",
        borderRadius: "21px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "24px",
        height: "24px",
        borderRadius: "26px",
      },
    },
    position: {
      background: "#5E8677",
      color: "#FFFFFF",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px",
      },
    },
    centerHeaderContainer: {
      display: "flex",
      flex: "1 1 auto",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10px",
      },
    },
    title: {
      color: "#5E8677",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    rightHeaderContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "160px",
        paddingRight: "34px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "200px",
        paddingRight: "43px",
      },
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      background: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        borderBottomLeftRadius: "19px",
        borderBottomRightRadius: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        borderBottomLeftRadius: "24px",
        borderBottomRightRadius: "24px",
      },
    },
    disabled: {
      color: "#989898",
    },
    postionDisabled: {
      background: "#989898",
      color: "#EFEFEF",
    },
    editLink: {
      cursor: "pointer",
      color: "#92B4A7",
      textDecoration: "underline",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    icon: {
      color: "#5E8677",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
      },
    }
  })
);

export const Section: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const headerContainerStyle = clsx(classes.headerContainer, {
    [classes.collapsedHeaderContainerDisabled]: props.collapsed,
    [classes.collapsedHeaderContainer]: (props.collapsed),
    [classes.disabled]: props.disabled,
  });

  const titleStyle = clsx(classes.title, {
    [classes.disabled]: props.disabled,
  });

  const positionCircleStyle = clsx(classes.positionCircle, {
    [classes.postionDisabled]: props.disabled,
  });

  const positionStyle = clsx(classes.position, {
    [classes.postionDisabled]: props.disabled,
  });

  const menu = (<Box>
  <ToolbarButton>
    <ToolbarItemWithConfirmation
      icon={faTrash}
      text={"Delete"}
      onConfirmedClick={() => props.onRemove && props.onRemove()}
      confirmQuestion={props.removeQuestion || <Typography>Are you sure you want to delete it?</Typography>}
      confirmButtonLabel="Delete"
      cancelButtonLabel="Cancel"
    />
  </ToolbarButton>
  </Box>
);

  const handleEdit = () => {
    if(props.collapsed && (props.editable || props.modifiable)) {
      props.onEdit && props.onEdit();
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={headerContainerStyle} onClick={handleEdit}>
        <Box className={clsx(classes.leftHeaderContainer, {[classes.noneLeftHeaderContainer]:(!props.position && !props.completed)})}>
          {props.completed ? <FontAwesomeIcon size="lg" icon={faCheckCircle} className={classes.completedCheck}/>
          : (props.position ? <Box className={positionCircleStyle}>
            <Typography className={positionStyle}>
              {props.position}
            </Typography>
          </Box>: null )}
        </Box>
        <Box className={classes.centerHeaderContainer}>
          <Typography className={titleStyle}>{props.title}</Typography>
        </Box>
        <Box className={classes.rightHeaderContainer}>
          {props.collapsed && props.editable && <Box><Typography className={classes.editLink}>Edit</Typography></Box>}
          {props.collapsed && !props.editable && <Box><FontAwesomeIcon icon={faCaretRight} className={classes.icon}/></Box>}
          {!props.collapsed && !props.editable && <Box><FontAwesomeIcon icon={faCaretDown} className={classes.icon}/></Box>}
          {props.onRemove && menu}
        </Box>
      </Box>
      {!props.collapsed && <Box className={classes.bodyContainer}>{props.children}</Box>}
    </Box>
  );
};

export default Section;
