import { faPassport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import {
	fetchShareholdersThunk,
	saveDocumentsShareholderThunk
} from '@spike/payments-action';
import { ConfirmDialog, Spinner } from 'components/UI';
import { useApiClientWrapper, useMasterData } from 'hooks';
import { FileWrapper } from '@spike/model';
import {
	DocumentTypeIds,
	PaymentsShareholder as ShareholderModel
} from '@spike/payments-model';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import { wbp } from 'Theme';
import BackDocumentIcon from '../Files/BackDocumentIcon';
import DropZoneFile from '../Files/DropZoneFile';
import FileView from '../Files/FileView';
import FrontDocumentIcon from '../Files/FrontDocumentIcon';
import UploadedFiles, { UploadedFileModel } from '../Files/UploadedFiles';
import Important from '../Important';
import StepFrame from '../StepFrame';
import { validateFile } from '../Validations';

interface ShareholderDocumentsStepProps {
	className?: string;
	onPrevious?: () => void;
	onNext?: () => void;
	onCompleted?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center'
		},
		title: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: 'black',
			textTransformation: 'capitalize',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '22px',
				lineHeight: '33px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '27px',
				lineHeight: '41px'
			}
		},
		description: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: 'black',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				lineHeight: '22px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '18px',
				lineHeight: '27px'
			}
		},
		important: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '21px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '26px'
			}
		},
		importantText: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: 'black',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '13px',
				lineHeight: '20px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeight: '25px'
			}
		},
		dropZonesContainer: {
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				marginTop: '26px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '32px'
			}
		},
		singleDropZone: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '18px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '22px'
			}
		},
		leftDropZone: {
			boxSizing: 'content-box',
			[theme.breakpoints.down(wbp)]: {
				marginRight: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginRight: '15px'
			}
		},
		rightDropZone: {
			boxSizing: 'content-box',
			[theme.breakpoints.down(wbp)]: {
				marginLeft: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginLeft: '15px'
			}
		},
		uploadedFiles: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '34px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '42px'
			}
		},
		icon: {
			color: '#5E8677',
			[theme.breakpoints.down(wbp)]: {
				width: '30x !important',
				height: '30px !important'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '38px !important',
				height: '38px !important'
			}
		}
	})
);

const selectUploadedFile = (
	document: FileWrapper | undefined,
	newDocument: File | null,
	deleted: boolean
): Array<UploadedFileModel> => {
	if (newDocument) {
		return [{ name: newDocument.name, size: newDocument.size }];
	} else if (document && !deleted) {
		return [{ name: document.fileName!, size: document.fileSize! }];
	} else {
		return [];
	}
};

export const ShareholderDocumentsStep: FunctionComponent<
	ShareholderDocumentsStepProps
> = props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const masterData = useMasterData();
	const apiClientWrapper = useApiClientWrapper();

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const shareholder = useSelector<RootState, Array<ShareholderModel> | []>(
		state => state.payments.shareholders
	)[0];

	const paymentsStatus = useSelector<RootState, PaymentsStatus>(
		state => state.payments.status
	);

	const [newShareholderDocuments, setNewShareholderDocuments] = useState<{
		front: File | null;
		back: File | null;
		document: File | null;
	}>({ front: null, back: null, document: null });

	const [errors, setErrors] = useState<Array<FieldError>>([]);
	const [saving, setSaving] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState<
		'document' | 'front' | 'back' | null
	>(null);
	const [deletedDocuments, setDeletedDocuments] = useState({
		front: false,
		back: false,
		document: false
	});

	const uploadedFiles = {
		document: selectUploadedFile(
			shareholder?.document,
			newShareholderDocuments.document,
			deletedDocuments.document
		),
		front: selectUploadedFile(
			shareholder?.documentFront,
			newShareholderDocuments.front,
			deletedDocuments.front
		),
		back: selectUploadedFile(
			shareholder?.documentBack,
			newShareholderDocuments.back,
			deletedDocuments.back
		)
	};

	const finish = () => {
		props.onNext && props.onNext();
		props.onCompleted && props.onCompleted();
	};

	useEffect(() => {
		if (!shareholder) {
			dispatch(fetchShareholdersThunk(apiClientWrapper));
		}
	}, []);

	useNonInitialEffect(() => {
		if (PaymentsStatus.SaveDocumentsShareholderSuccess === paymentsStatus) {
			setSaving(false);
			finish();
		} else if (PaymentsStatus.Error === paymentsStatus) {
			setSaving(false);
		}
	}, [paymentsStatus]);

	const saveHandler = () => {
		if (
			[
				DocumentTypeIds.PASSPORT.toString(),
				DocumentTypeIds.VISA.toString()
			].includes(shareholder?.documentType?.id || '')
		) {
			if (
				newShareholderDocuments.document ||
				!shareholder?.document ||
				deletedDocuments.document
			) {
				const errors = validateFile(
					newShareholderDocuments.document,
					masterData,
					shareholder?.documentType?.name || ''
				);
				setErrors(errors);
				if (errors.length === 0) {
					setSaving(true);
					dispatch(
						saveDocumentsShareholderThunk(
							apiClientWrapper,
							shareholder!.id!,
							null,
							null,
							newShareholderDocuments.document
						)
					);
				}
			} else {
				finish();
			}
		} else {
			if (
				newShareholderDocuments.front ||
				newShareholderDocuments.back ||
				!shareholder?.documentFront ||
				!shareholder?.documentBack ||
				deletedDocuments.front ||
				deletedDocuments.back
			) {
				const errors = [
					...validateFile(
						newShareholderDocuments.front,
						masterData,
						'front'
					),
					...validateFile(
						newShareholderDocuments.back,
						masterData,
						'back'
					)
				];
				setErrors(errors);
				if (errors.length === 0) {
					setSaving(true);
					dispatch(
						saveDocumentsShareholderThunk(
							apiClientWrapper,
							shareholder!.id!,
							newShareholderDocuments.front,
							newShareholderDocuments.back,
							null
						)
					);
				}
			} else {
				finish();
			}
		}
	};

	const singleFile = (
		<Fragment>
			{' '}
			<Typography className={classes.title}>
				Upload Identity Document
			</Typography>
			<Typography className={classes.description}>
				{`Upload your ${
					shareholder?.documentType?.name?.toLowerCase() || 'document'
				}. Make sure your information is up-to-date.`}
			</Typography>
			<DropZoneFile
				className={classes.singleDropZone}
				icon={
					<FontAwesomeIcon
						icon={faPassport}
						className={classes.icon}
					/>
				}
				onDrop={(file: File) => {
					setNewShareholderDocuments({
						front: null,
						back: null,
						document: file
					});
					setErrors([]);
				}}
			/>
		</Fragment>
	);

	const doubleFile = (
		<Fragment>
			<Typography className={classes.title}>
				Upload identity Document
			</Typography>
			<Typography className={classes.description}>
				Upload a color photo or scan of the complete front and back
				sides of your government-issued ID.
			</Typography>
			<Important className={classes.important}>
				<Typography className={classes.importantText}>
					This shoud include your picture, name, and date of birth.
					Other requirements: front and back in 2 separate files,
					fully visible, full color, cropped, and straightened,
					without a background (use a flat service for best results),
					not expired.
				</Typography>
			</Important>
			<Box className={classes.dropZonesContainer}>
				<DropZoneFile
					icon={<FrontDocumentIcon className={classes.icon} />}
					title="(FRONT)"
					className={classes.leftDropZone}
					onDrop={(file: File) => {
						setNewShareholderDocuments(prev => ({
							...prev,
							front: file,
							document: null
						}));
						setErrors([]);
					}}
					buttonLabel="Upload"
				/>
				<DropZoneFile
					icon={<BackDocumentIcon className={classes.icon} />}
					title="(BACK)"
					className={classes.rightDropZone}
					onDrop={(file: File) => {
						setNewShareholderDocuments(prev => ({
							...prev,
							back: file,
							document: null
						}));
						setErrors([]);
					}}
					buttonLabel="Upload"
				/>
			</Box>
		</Fragment>
	);

	return (
		<Fragment>
			<Box className={classes.container}>
				{props.onCompleted || props.onNext ? (
					<StepFrame
						btnId="cuddlespay-owner-documents"
						title="Business Owner Identification"
						description="Cuddles will use your personal information to verify your identity. We will never use or share your personal information to any third parties."
						onPrevious={props.onPrevious}
						onNext={props.onNext ? saveHandler : undefined}
						onCompleted={
							props.onCompleted ? saveHandler : undefined
						}
						saving={saving}
						errors={errors.map(error => error.errorMessage)}
						className={props.className}
					>
						{shareholder === undefined && <Spinner />}
						{shareholder !== undefined &&
							[
								DocumentTypeIds.PASSPORT.toString(),
								DocumentTypeIds.VISA.toString()
							].includes(shareholder.documentType?.id || '') && (
								<Fragment>
									{singleFile}
									{uploadedFiles.document.length > 0 && (
										<UploadedFiles
											files={uploadedFiles.document}
											className={classes.uploadedFiles}
											onRemove={() =>
												setShowConfirmation('document')
											}
										/>
									)}
								</Fragment>
							)}
						{shareholder !== undefined &&
							![
								DocumentTypeIds.PASSPORT.toString(),
								DocumentTypeIds.VISA.toString()
							].includes(shareholder.documentType?.id || '') && (
								<Fragment>
									{doubleFile}
									{uploadedFiles.front.length > 0 && (
										<UploadedFiles
											title="Front Uploaded"
											files={uploadedFiles.front}
											className={classes.uploadedFiles}
											onRemove={() =>
												setShowConfirmation('front')
											}
										/>
									)}
									{uploadedFiles.back.length > 0 && (
										<UploadedFiles
											title="Back Uploaded"
											files={uploadedFiles.back}
											className={classes.uploadedFiles}
											onRemove={() =>
												setShowConfirmation('back')
											}
										/>
									)}
								</Fragment>
							)}
					</StepFrame>
				) : (
					<Grid
						container
						spacing={isMobile ? 0 : 5}
						className={classes.container}
					>
						{shareholder !== undefined && shareholder.document && (
							<Grid item sm={12}>
								<FileView
									fileUrl={shareholder.document?.url || ''}
									fileName={
										shareholder.document?.fileName || ''
									}
									fileSize={
										shareholder.document?.fileSize || 0
									}
								/>
							</Grid>
						)}
						{shareholder !== undefined &&
							shareholder.documentFront && (
								<Grid item sm={12} md={6}>
									<FileView
										fileUrl={
											shareholder.documentFront?.url || ''
										}
										fileName={
											shareholder.documentFront
												?.fileName || ''
										}
										fileSize={
											shareholder.documentFront
												?.fileSize || 0
										}
									/>
								</Grid>
							)}
						{shareholder !== undefined &&
							shareholder.documentBack && (
								<Grid item sm={12} md={6}>
									<FileView
										fileUrl={
											shareholder.documentBack?.url || ''
										}
										fileName={
											shareholder.documentBack
												?.fileName || ''
										}
										fileSize={
											shareholder.documentBack
												?.fileSize || 0
										}
									/>
								</Grid>
							)}
					</Grid>
				)}
			</Box>
			<ConfirmDialog
				open={showConfirmation !== null}
				question={
					<Typography>Do you want to delete the document?</Typography>
				}
				cancelButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				onCancel={() => {
					setShowConfirmation(null);
				}}
				onConfirm={() => {
					if (showConfirmation === 'document') {
						setDeletedDocuments(prev => ({
							...prev,
							document: true
						}));
						setNewShareholderDocuments(prev => ({
							...prev,
							document: null
						}));
					} else if (showConfirmation === 'front') {
						setDeletedDocuments(prev => ({ ...prev, front: true }));
						setNewShareholderDocuments(prev => ({
							...prev,
							front: null
						}));
					} else if (showConfirmation === 'back') {
						setDeletedDocuments(prev => ({ ...prev, back: true }));
						setNewShareholderDocuments(prev => ({
							...prev,
							back: null
						}));
					}
					setErrors([]);
					setShowConfirmation(null);
				}}
			/>
		</Fragment>
	);
};

export default ShareholderDocumentsStep;
