import { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
  title?: string;
  searchPlaceholder?: string;
  onSearch?: (text: string) => void;
  onBack?: () => void;
  className?: string;
  rightElements?: JSX.Element;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "13px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "43px",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    leftHeaderContainer: {
      display: "flex",
    },
    rightHeaderContainer: {
      display: "flex",
    },

    backContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
        marginLeft: "-20px",
      },
      [theme.breakpoints.only("md")]: {
        width: "50px",
        marginLeft: "-50px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "70px",
        marginLeft: "-70px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "100px",
        marginLeft: "-100px",
      },
    },
    backIcon: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "36px",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontWeight: 500,
      color: "#222",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "36px",
      },
    },
    searchContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    searchBox: {
      marginLeft: "20px",
    },
  })
);

export const HeaderClient: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container)}>
      <Box className={classes.headerContainer}>
        <Box className={classes.leftHeaderContainer}>
          <Typography className={classes.title}>{props.title}</Typography>
        </Box>
        <Box className={classes.rightHeaderContainer}>
          {props.rightElements}
        </Box>
      </Box>
    </Box >
  );
};

export default HeaderClient;
