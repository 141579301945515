import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import ConfirmDialogUI from "components/UI/ConfirmDialog";
import Button from "components/UI/Button";
import Spinner from "components/UI/Spinner";
import Booking from "@spike/booking-model";
import { DateFormat, TimeFormat, ConfirmDialog as Confirmation, Link } from "components/UI";
import PetImage from "components/UI/PetImage"
import { useSelector, useDispatch } from "react-redux";
import { getPetThunk, savePetThunk } from "@spike/pets-action";
import { RootState } from "store";
import { Pet } from "@spike/pet-model";
import { faPhone, faEnvelope, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { formatPhoneNumber } from "@spike/phone-utils";
import { useApiClientWrapper } from "hooks";
import { reduceResolution, wbp } from "Theme";
import useNonInitialEffect from "@versiondos/hooks";
import { PetsStatus } from "@spike/pets-action";
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from "constants/index";

interface ConfirmBookingProps {
  booking: Booking;
  open: boolean;
  pet?: Pet;
  //
  onConfirm: () => void;
  onCancel: () => void;
  onReschedule: (appointmentId: number) => void;
  onCloseDialog: () => void;
  id?:string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionComponentContainer: {},
    confirmDialogTitle: {
      fontWeight: 500,
      fontFamily: "Poppins",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
      },
    },
    appointmentText: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
        paddingTop: `${reduceResolution(5)}px`,

      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
        paddingTop: "5px",
      },
    },
    typoApp: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
        paddingRight: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
        paddingRight: "3px",
      },
    },
    typoAppStrong: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
        paddingRight: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
        paddingRight: "3px",
      },
    },
    callTextEmailContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    customerContactDataContainer: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "20px",
      },
    },
    customerContactData: {
      fontWeight: 600,
    },
    customerContact: {
      color: "#7A7A7A",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(17)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "17px",
      },
    },
    actionsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    reschedule: {
      color: "#EAB464",
      borderColor: "#EAB464",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(17)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "17px",
      },
    },
    cancelled: {
      color: "#919191",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(16)}px`,
        paddingBottom: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "16px",
        paddingBottom: "22px",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "5px",
      },
    },
    button: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(150)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "150px",
      },
    },
    textDialog: {
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },
    boxRowFlex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    boxImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(110)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "110px",
      },
    },
    boxPhone: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(15)}px`,
        paddingTop: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "15px",
        paddingTop: "5px",
      },
    },
    boxEmail: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        paddingRight: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        paddingRight: "30px",
      },
    },
    sectionIcon: {
      margin: "0",
      padding: "0",
    },

  })
);

export const ConfirmBooking: FunctionComponent<ConfirmBookingProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const pet = useSelector<RootState, Pet | undefined>(state => state.pets.pet);
  const petStatus = useSelector<RootState, PetsStatus>(state => state.pets.status);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [petSave, setPetSave] = useState<Pet>();
  const [disableEdition, setDisableEdition] = useState(false);

  const [loadingPet, setLoadingPet] = useState(true);

  const booking = { ...props.booking, appointments: props.booking.appointments.filter(appointment => appointment.status.name !== 'Canceled') } as Booking;

  useEffect(() => {
    if (pet?.id !== booking.appointments[0].pet.id) {
      dispatch(getPetThunk(apiClientWrapper, booking.appointments[0].pet.id));
    }
    else {
      setPetSave(pet);
      setLoadingPet(false);
    }
  }, [booking.appointments[0].pet.id]);

  useEffect(() => {
    if (pet !== undefined) setPetSave(pet);
    setDisableEdition(true)
  }, [pet]);

  useEffect(() => {
    amplitude.track(AMPLITUDE.LOAD_CONFIRM_APPOINTMENT_MODAL);
  }, [])


  useNonInitialEffect(() => {
    switch (petStatus) {
      case PetsStatus.GetSuccess:
        setLoadingPet(false);
        break;
      case PetsStatus.Error:
        setLoadingPet(false);
        setPetSave(undefined);
        break;
    }
  }, [petStatus]);

  const iconSVG = (icon: IconDefinition, styles: string, size?: any) => {
    return <FontAwesomeIcon icon={icon} className={styles} size={size} color="#000" />;
  };

  const handleConfirm = () => {
    props.onConfirm();
    amplitude.track(AMPLITUDE.CTA_CONFIRM_APPOINTMENT);
  };

  const handleReschedule = () => {
    props.onReschedule(booking.appointments[0].id!);
    amplitude.track(AMPLITUDE.CTA_RESCHEDULE_APPOINTMENT);
  };

  const handleCancelled = () => {
    setShowConfirmation(true);
    amplitude.track(AMPLITUDE.CTA_CANCEL_APPOINTMENT);
  };

  const handleConfirmationNo = () => {
    setShowConfirmation(false);
    amplitude.track(AMPLITUDE.CTA_NO_CANCEL_APPOINTMENT);
  };

  const handleConfirmationYes = () => {
    setShowConfirmation(false);
    props.onCancel();
    amplitude.track(AMPLITUDE.CTA_YES_CANCEL_APPOINTMENT);
  };
  const changePetHandler = (pet: Pet) => {
    dispatch(savePetThunk(apiClientWrapper, pet))
  };


  const questionComponent = (
    <Box className={classes.questionComponentContainer} textAlign="center" width="100%">
      <Box style={{ marginBottom: "20px" }}>
        <Typography className={classes.confirmDialogTitle}>Confirm Appointment</Typography>
        {booking.appointments.map((appointment) => (
          <Box key={appointment.uuid} className={classes.appointmentText}>
            <Typography className={classes.typoAppStrong}>{appointment.pet.name}</Typography>
            <Typography className={classes.typoApp}>on</Typography>
            <Typography className={classes.typoAppStrong}><DateFormat date={appointment.duration.from} /></Typography>
            <Typography className={classes.typoApp}>{"@"}</Typography>
            <Typography className={classes.typoAppStrong}><TimeFormat date={appointment.duration.from} /></Typography>
          </Box>
        ))}
      </Box>

      <Box className={classes.callTextEmailContainer}>
        <Box className={classes.boxImage}>
          {loadingPet ? <Spinner /> :
            <PetImage
              disableEdition={disableEdition}
              pet={petSave}
              onChangePet={changePetHandler} />}
        </Box>
      </Box>

      <Box className={classes.customerContactDataContainer}>
        <Typography className={classes.customerContact}>
          {`${props.booking.customer.firstName} ${props.booking.customer.lastName} `}
        </Typography>

        <Box className={clsx(classes.boxRowFlex, classes.boxPhone)}>
          {
            props.booking.customer.phone && (
              <Box className={clsx(classes.boxRowFlex)}>
                <Box pr={1}>
                  {iconSVG(faPhone, `${classes.sectionIcon}`)}
                </Box>
                <Box>{formatPhoneNumber(props.booking.customer.phone || "", apiClientWrapper.marketplace!.basics.address.country!.id)}</Box>
              </Box>
            )}
        </Box>

        <Box className={clsx(classes.boxRowFlex)}>
          {
            props.booking.customer.email && (
              <Box className={clsx(classes.boxRowFlex, classes.boxEmail)}>
                <Box pl={4} pr={1}>
                  {iconSVG(faEnvelope, `${classes.sectionIcon}`)}
                </Box>
                <Box>{props.booking.customer.email}</Box>
              </Box>
            )}
        </Box>
      </Box>
    </Box >
  );

  const actionsComponent = (
    <Box className={classes.actionsContainer}>
      <Box className={classes.buttonsContainer}>
        {booking.appointments.length === 1 && (
          <Button
            className={clsx(classes.button, classes.reschedule)}
            label="Reschedule"
            variant="secondary"
            onClick={handleReschedule}
          />
        )}
        <Button label="Confirm" onClick={handleConfirm} className={classes.button} id={props.id||""}/>
      </Box>
      <Link
        label="Cancel Appointment"
        className={classes.cancelled}
        onClick={handleCancelled}
        underline={true}
        color="black"
      />
    </Box>
  );

  return (
    <Fragment>
      <ConfirmDialogUI
        open={props.open}
        question={questionComponent}
        actions={actionsComponent}
        onCancel={props.onCloseDialog}
      />
      <Confirmation
        open={showConfirmation}
        question={
          <Typography className={classes.textDialog}>
            Are you sure you want to <strong>Cancel</strong> this appointment?
          </Typography>
        }
        confirmButtonLabel={"Yes"}
        cancelButtonLabel={"No"}
        onCancel={handleConfirmationNo}
        onConfirm={handleConfirmationYes}
      />
    </Fragment>
  );
};

export default ConfirmBooking;
