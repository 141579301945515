import ReactGA from 'react-ga4';
import _ from 'lodash';
import { store } from 'store';

export const pageView = (page: string) => {
    const enabledGA = `${process.env.REACT_APP_ENABLE_GA}` === 'true';
    const cuddlesGATrackingID = `${process.env.REACT_APP_CUDDLES_GA_TRACKING_ID}`;
    const marketplace = store.getState().marketplace.marketplace;
    const auth = store.getState().login.auth;
    const marketplaceTrackingId = marketplace.integrations.googleAnalyticsTrackingId;
    if (enabledGA) {
        if (
            !ReactGA.isInitialized ||
            (!_.isEmpty(marketplaceTrackingId) && ReactGA._currentMeasurementId === cuddlesGATrackingID) ||
            (_.isEmpty(marketplaceTrackingId) && ReactGA._currentMeasurementId !== cuddlesGATrackingID) ||
            (!auth.signedIn && ReactGA._currentMeasurementId !== cuddlesGATrackingID)
        ) {
            ReactGA.reset();

            if (!_.isEmpty(marketplaceTrackingId) && auth.signedIn) {
                ReactGA._gtag('config', marketplaceTrackingId, { user_id: marketplace.id });
                ReactGA._gtag('config', cuddlesGATrackingID, { user_id: marketplace.id });
            } else {
                ReactGA.initialize(cuddlesGATrackingID);
                ReactGA._gtag('config', cuddlesGATrackingID, { user_id: marketplace.id });
            }
        }

        ReactGA.send({ hitType: 'pageview', page });
    }
};
