import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import ListItem from './ListItem';

interface ListProps {
	options: Array<Option<number>>;
	selectedIds: Array<number>;
	onChangeSelection?: (selectedIds: Array<number>) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			flexShrink: 1,
			overflowY: 'scroll',
			maxHeight: 300,
			paddingLeft: 15,
			paddingRight: 15
		},
		item: {
			paddingTop: 2,
			paddingBottom: 2
		}
	})
);

export const List: FunctionComponent<ListProps> = props => {
	const classes = useStyles();

	const clickHandler = (id: number) => {
		props.onChangeSelection &&
			props.onChangeSelection(
				props.selectedIds.includes(id)
					? props.selectedIds.filter(selectedId => selectedId !== id)
					: [...props.selectedIds, id]
			);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			{props.options.map(option => (
				<ListItem
					key={option.id}
					id={option.id}
					label={option.name}
					selected={props.selectedIds.includes(option.id)}
					onClick={() => clickHandler(option.id)}
					className={classes.item}
				/>
			))}
		</Box>
	);
};

export default List;
