import {
    Typography,
    Box,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import Client, { createEmptyClient } from '@spike/client-model';
import { saveClientThunk } from '@spike/clients-action';
import { FieldError } from '@spike/model';
import { isEmailValid, validatePhoneNumber } from '@spike/validations';
import useNonInitialEffect from '@versiondos/hooks';
import { ConfirmDialog, TextField2, PhoneField3 } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from 'utils/ValidationUtils';
import { ClientsStatus } from '@spike/clients-action';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';

interface NewClientModalProps {
    onClose?: () => void;
    isOpen: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newClientDropdownContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 16px'
        },
        newClientFirstInputContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '8px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '15px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '20px'
            }
        },
        newClientInputContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '15px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '24px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '32px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '49px'
            }
        }
    })
);

const NewClientModal: FunctionComponent<NewClientModalProps> = props => {
    const clientState = useSelector<RootState, ClientsStatus>(
        state => state.clients.status
    );

    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [clientToAdd, setClientToAdd] = useState(createEmptyClient(uuid));

    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    useNonInitialEffect(() => {
        if (clientState === ClientsStatus.SaveSuccess) {
            props.onClose && props.onClose();
        }
    }, [clientState]);

    const cleanErrors = (fieldName: string) => {
        setErrors(prev => prev.filter(e => e.fieldName !== fieldName));
    };

    const changeValueClientHandler = (value: string, fieldName: string) => {
        cleanErrors(fieldName);
        const clientAux: any = { ...clientToAdd };
        const attributes = fieldName;
        clientAux[attributes] = value;
        setClientToAdd(clientAux);
    };

    const validate = (client: Client) => {
        const errors: Array<FieldError> = [
            validateRequired('firstName', client),
            validateRequired('lastName', client),
            validateRequired('phone', client),
            validateRequired('email', client)
        ].filter((t): t is FieldError => !!t);

        errors.push(
            ...validatePhoneNumber(
                client.phone,
                apiClientWrapper.marketplace!.basics.address.country!.id,
                'phone'
            )
        );

        if (!isEmailValid(client.email)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Invalid Email format.'
            });
        }
        return errors;
    };

    const handleNewClientConfirm = () => {
        const validationErrors = validate({ ...clientToAdd });
        if (validationErrors.length === 0) {
            dispatch(saveClientThunk(apiClientWrapper, { ...clientToAdd }));
        }
        setErrors(validationErrors);
    };

    return (
        <ConfirmDialog
            open={props.isOpen}
            title={
                <Typography style={{ padding: '0px 16px' }}>
                    Add New Client
                </Typography>
            }
            question={
                <Box className={classes.newClientDropdownContainer}>
                    <Box className={classes.newClientFirstInputContainer}>
                        <TextField2
                            label="First Name"
                            placeholder="First Name"
                            name="firstName"
                            value={clientToAdd.firstName}
                            errors={errors}
                            maxLength={30}
                            required={true}
                            onChange={changeValueClientHandler}
                        />
                    </Box>
                    <Box className={classes.newClientInputContainer}>
                        <TextField2
                            label="Last Name"
                            placeholder="Last Name"
                            name="lastName"
                            errors={errors}
                            maxLength={30}
                            required={true}
                            value={clientToAdd.lastName}
                            onChange={changeValueClientHandler}
                        />
                    </Box>
                    <Box className={classes.newClientInputContainer}>
                        <PhoneField3
                            label="Phone"
                            placeholder="Phone"
                            name="phone"
                            errors={errors}
                            required={true}
                            value={clientToAdd.phone}
                            onChange={changeValueClientHandler}
                        />
                    </Box>
                    <Box className={classes.newClientInputContainer}>
                        <TextField2
                            label="Email"
                            placeholder="Email"
                            name="email"
                            required={true}
                            errors={errors}
                            value={clientToAdd.email}
                            onChange={changeValueClientHandler}
                        />
                    </Box>
                </Box>
            }
            isSuccess={true}
            isForm={true}
            cancelButtonLabel="Discard"
            confirmButtonLabel="Save"
            elementAlignment="left"
            onCancel={() => props.onClose && props.onClose()}
            onConfirm={handleNewClientConfirm}
        />
    );
};

export default NewClientModal;
