import { Auth } from '@spike/auth-model';
import { MasterData } from '@spike/masterdata-model';
import { showWarning } from '@spike/notifications-action';
import ActionCable, { Cable, Channel } from 'actioncable';
import { setRoleAndAccessLevel } from 'actions/login/LoginActions';
import { convertStaff } from 'actions/staff/StaffConverter';
import { useAuth, useMasterData } from 'hooks';
import { StaffWebSocketsMessage } from 'model/WebSockets';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const StaffWebSockets: FunctionComponent = () => {
    const masterData = useMasterData();
    const auth = useAuth();
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        let cable: Cable;
        let channel: Channel;

        //console.info({ action: "StaffWebSockets useEffect", signedIn: auth.signedIn });

        if (auth.signedIn) {
            [cable, channel] = subscribe(auth, masterData);
        }

        return () => {
            unsubscribe(cable, channel);
        };
    }, [auth, masterData]);

    const subscribe = (
        auth: Auth,
        masterData: MasterData
    ): [Cable, Channel] => {
        const cable = ActionCable.createConsumer(
            `${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
        );

        const channel = cable!.subscriptions.create(
            {
                channel: 'StaffChannel',
                marketplace_id: auth.marketplaceId!
            },
            {
                received: (message: any) => {
                    messageHandler(message, auth, masterData);
                }
            }
        );

        console.info({
            action: 'StaffWebSockets subscribed!',
            channel: 'StaffChannel',
            marketplaceId: auth.marketplaceId!
        });

        return [cable, channel];
    };

    const unsubscribe = (cable: Cable, channel: Channel) => {
        cable && cable.disconnect();
        channel && channel.unsubscribe();

        console.info({
            action: 'StaffWebSockets unsubscribed!',
            cable,
            channel
        });
    };

    const messageHandler = (
        message: StaffWebSocketsMessage,
        auth: Auth,
        masterData: MasterData
    ) => {
        console.info({
            action: 'StaffWebSockets message received',
            message
        });

        const staff = convertStaff(message.staff);

        const newAccessLevel = staff.accessLevel
            ? { ...staff.accessLevel }
            : undefined;
        const newRole = staff.role ? { ...staff.role } : undefined;
        const staffId = staff.id ?? 0;

        if (
            auth.user &&
            auth.user.staffId === staffId &&
            newAccessLevel &&
            newRole &&
            (auth.user?.accessLevel.id !== newAccessLevel.id ||
                auth.user?.role.id !== newRole.id)
        ) {
            dispatch(
                showWarning('Your role or access level has been changed.')
            );
            setRoleAndAccessLevel(newRole, newAccessLevel, staffId, dispatch);
        }
    };

    return null;
};

export default StaffWebSockets;
