import React, { FunctionComponent, memo } from "react";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import "./plyr.css";

interface VideoPlayerProps {
  poster?: string;
  source: string;
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = memo((props) => {
  return (<Plyr
    source={{
      type: "video",
      sources: [{ src: props.source, type: "video/mp4" }],
      poster: props.poster,
    }}
    options={{
      controls: ["play", "progress", "current-time", "mute", "volume", "fullscreen", "airplay"],
      volume: 0.5,
    }}
  />);
});

export default VideoPlayer;
