import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

export interface MedicalConditionSectionProps {
    title: string;
    text?: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        header: {
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                paddingTop: '10px'
            }
        },
        body: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '15px'
            },
            [theme.breakpoints.down('md')]: {
                paddingTop: '6px'
            }
        },
        title: {
            fontWeight: 400,
            color: '#919191',
            textTransform: 'uppercase',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`,
                lineHeight: `${reduceResolution(21)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px',
                lineHeight: '21px'
            },
            [theme.breakpoints.down('md')]: {
                textTransform: 'none',
                fontSize: '14px',
                color: '#000',
                fontWeight: 500
            }
        },
        text: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#222222',
            textWrap: 'wrap',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`,
                lineHeight: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px',
                lineHeight: '26px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                color: '#707070',
                fontWeight: 400
            }
        }
    })
);

export const MedicalConditionSection: FunctionComponent<
    MedicalConditionSectionProps
> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.header}>
                <Typography className={classes.title}>{props.title}</Typography>
            </Box>
            <Box className={classes.body}>
                <Typography className={classes.text}>
                    {props.text || '-'}
                </Typography>
            </Box>
        </Box>
    );
};

export default MedicalConditionSection;
