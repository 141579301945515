import {
    MasterDataAction,
    set as setMasterData
} from '@spike/masterdata-action';
import { NotificationsAction, showError } from '@spike/notifications-action';
import {
    setMarketplaceDto,
    MarketplaceAction
} from '@spike/marketplace-action';
import ApiClient, { isCancelled } from 'api/ApiClient';
import {
    changeAvatarInStorage,
    removeFromStorage,
    saveInStorage,
    setRoleAndAccessLevelInStorage,
    setTimeZoneInStorage
} from 'auth';
import { AxiosResponse } from 'axios';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { Auth, AuthMarketplace } from '@spike/auth-model';
import { LoginState } from 'reducers/login/LoginState';
import { ThunkAction } from 'redux-thunk';
import { LoginPath } from 'routes/Paths';
import store from 'store';
import {
    LoginAction,
    LOGIN_CHANGE_IMAGE_ACTION_TYPE,
    LOGIN_CHANGE_MARKETPLACE_ACTION_TYPE,
    LOGIN_END_ACTION_TYPE,
    LOGIN_FAIL_ACTION_TYPE,
    LOGIN_RESET_ACTION_TYPE,
    LOGIN_SET_TIME_ZONE_ACTION_TYPE,
    LOGIN_START_ACTION_TYPE,
    LOGIN_SUCCESS_ACTION_TYPE,
    LOGOUT_ACTION_TYPE,
    LOGIN_SET_ROLE_AND_ACCESS_LEVEL_ACTION_TYPE
} from './LoginActionsTypes';
import { convert } from './LoginConverter';
import { LoginResponseDto } from './LoginDtos';
import {
    identifyUserInStonly,
    trackEventInStonly,
    deidentifyUser
} from 'hooks';
import { Option } from '@spike/model';

export const resetThunk = (): ThunkAction<
    void,
    LoginState,
    null,
    LoginAction
> => {
    return async dispatch => {
        removeFromStorage();
        dispatch(reset());
    };
};

export const loginThunk = (
    email: string,
    password: string,
    rememberMe: boolean
): ThunkAction<
    void,
    LoginState,
    null,
    | LoginAction
    | NotificationsAction
    | CallHistoryMethodAction
    | MasterDataAction
    | MarketplaceAction
> => {
    return async dispatch => {
        const HTTP_STATUS_401_UNAUTHORIZED = 401;

        dispatch(start());

        removeFromStorage();

        try {
            const response: AxiosResponse<LoginResponseDto> =
                await ApiClient.post('login', {
                    email: email.trim().toLowerCase(),
                    password
                });

            const auth = convert(response.data, true);

            dispatch(setMasterData(response.data.master_data));
            dispatch(
                setMarketplaceDto(
                    response.data.marketplace,
                    response.data.master_data
                )
            );

            dispatch(loginSuccess(auth));

            /* Stonly code */
            deidentifyUser(); // Deidentify user in Stonly

            const user_id = response.data.user.id;
            const user_date = response.data.user.created_at;
            const user_created_at = new Date(user_date); // Convert creation date to Date object
            const user_admin = response.data.user.admin;
            const marketplace_id = response.data.marketplace.id;
            const marketplace_date = response.data.marketplace.created_at;
            const marketplace_created_at = new Date(marketplace_date);

            //Identify user in Stonly
            identifyUserInStonly(user_id.toString(), {});

            //Identify user in Stonly with custom properties
            identifyUserInStonly(user_id.toString(), {
                'user-created-at': user_created_at,
                'user-id': user_id,
                'admin': user_admin,
                'market-created': marketplace_created_at,
                'marketplace-id': marketplace_id
            });

            //Track event in Stonly
            trackEventInStonly('login');

            /* End Stonly code */

            rememberUser(auth, rememberMe);

            dispatch(push('/'));
        } catch (apiError: any) {
            if (!isCancelled(apiError)) {
                if (
                    apiError.response &&
                    apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED
                ) {
                    dispatch(fail());
                } else {
                    dispatch(showError('Login error.'));
                }
            }
        }
        dispatch(end());
    };
};

export const impersonateThunk = (
    marketplaceId: number,
    token: string
): ThunkAction<
    void,
    LoginState,
    null,
    | LoginAction
    | NotificationsAction
    | CallHistoryMethodAction
    | MasterDataAction
    | MarketplaceAction
> => {
    return async dispatch => {
        const HTTP_STATUS_401_UNAUTHORIZED = 401;

        dispatch(start());

        removeFromStorage();

        try {
            const response: AxiosResponse<LoginResponseDto> =
                await ApiClient.get(
                    `admin/impersonate/${token}?marketplace_id=${marketplaceId}`
                );

            const auth = convert(response.data, true);

            if (auth.marketplaces.some(m => m.id === marketplaceId)) {
                auth.marketplaceId = marketplaceId;

                dispatch(setMasterData(response.data.master_data));
                dispatch(
                    setMarketplaceDto(
                        response.data.marketplace,
                        response.data.master_data
                    )
                );
                dispatch(loginSuccess(auth));

                rememberUser(auth, false);

                dispatch(push('/'));
            } else {
                dispatch(push(LoginPath));
            }
        } catch (apiError: any) {
            if (!isCancelled(apiError)) {
                if (
                    apiError.response &&
                    apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED
                ) {
                    dispatch(showError(apiError.response.data.error));
                    dispatch(push(LoginPath));
                } else {
                    dispatch(showError('Impersonate error.'));
                }
            }
        }
        dispatch(end());
    };
};

export const setTimeZone = (
    timeZone: string,
    marketplaceId: number,
    dispatch: any
) => {
    dispatch(setTimeZoneInStore(timeZone));
    setTimeZoneInStorage(timeZone, marketplaceId);
};

export const setRoleAndAccessLevel = (
    role: Option<number>,
    accessLevel: Option<number>,
    staffId: number,
    dispatch: any
) => {
    dispatch(setRoleAndAccessLevelInStore(role, accessLevel, staffId));
    setRoleAndAccessLevelInStorage(role, accessLevel, staffId);
};

export const changeAvatar = (image: string, staffId: number, dispatch: any) => {
    if (store.getState().login.auth.user?.staffId === staffId) {
        dispatch(changeImage(image));
        changeAvatarInStorage(image, staffId);
    }
};

export const logoutThunk = (): ThunkAction<
    void,
    LoginState,
    null,
    LoginAction | CallHistoryMethodAction
> => {
    return async dispatch => {
        dispatch(start());
        removeFromStorage();
        dispatch(logout());
        dispatch(push('/login'));
        dispatch(end());
    };
};

const rememberUser = (auth: Auth, rememberMe: boolean) => {
    saveInStorage(auth, rememberMe);
};

export const loginSuccess = (auth: Auth): LoginAction => {
    return {
        type: LOGIN_SUCCESS_ACTION_TYPE,
        payload: {
            auth
        }
    };
};

const setTimeZoneInStore = (timeZone: string): LoginAction => {
    return {
        type: LOGIN_SET_TIME_ZONE_ACTION_TYPE,
        payload: {
            timeZone
        }
    };
};

const setRoleAndAccessLevelInStore = (
    role: Option<number>,
    accessLevel: Option<number>,
    staffId: number
): LoginAction => {
    return {
        type: LOGIN_SET_ROLE_AND_ACCESS_LEVEL_ACTION_TYPE,
        payload: {
            role,
            accessLevel,
            staffId
        }
    };
};

const changeImage = (image: string): LoginAction => {
    return {
        type: LOGIN_CHANGE_IMAGE_ACTION_TYPE,
        payload: {
            image
        }
    };
};

const start = (): LoginAction => {
    return {
        type: LOGIN_START_ACTION_TYPE
    };
};

const fail = (): LoginAction => {
    return {
        type: LOGIN_FAIL_ACTION_TYPE
    };
};

const reset = (): LoginAction => {
    return {
        type: LOGIN_RESET_ACTION_TYPE
    };
};

export const changeMarketplace = (
    authMarketplace: AuthMarketplace
): LoginAction => {
    return {
        type: LOGIN_CHANGE_MARKETPLACE_ACTION_TYPE,
        payload: {
            marketplaceId: authMarketplace.id,
            addressId: authMarketplace.addressId,
            timeZone: authMarketplace.timeZone,
            role: { ...authMarketplace.role },
            accessLevel: { ...authMarketplace.accessLevel }
        }
    };
};

const end = (): LoginAction => {
    return {
        type: LOGIN_END_ACTION_TYPE
    };
};

const logout = (): LoginAction => {
    return {
        type: LOGOUT_ACTION_TYPE
    };
};
