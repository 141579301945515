import {
    faHouseMedical,
    faStethoscope,
    faSyringe,
    faTooth
} from '@fortawesome/pro-regular-svg-icons';
import { Box, Grid } from '@material-ui/core';
import { Button } from 'components/UI';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
    PetDental,
    PetMedicalCondition,
    PetMedicalHistory,
    PetVaccine
} from '@spike/medical-history-model';
import { FieldError, Option } from '@spike/model';
import Pet from '@spike/pet-model';
import { PetsStatus, saveMedicalHistoryThunk } from '@spike/pets-action';
import VetModel, { createEmptyVet } from '@spike/vet-model';
import { VetsStatus, saveVetThunk } from '@spike/vets-action';
import useNonInitialEffect from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import EditVet from '../../Vet/Edit';
import MedicalHistoryHeader from '../MedicalHistoryHeader';
import MedicalHistorySection from '../MedicalHistorySection';
import Dentals from './Dentals';
import EditMedicalConditions from './MedicalConditions';
import Vaccines from './Vaccines';
import { isEmptyVet, validate } from './Validations';
import { v4 as uuid } from 'uuid';

interface EditMedicalHistoryProps {
    petId: number;
    petGender?: Option<string>;
    medicalHistory: PetMedicalHistory;
    vet?: VetModel;
    onSaved?: (medicalHistory: PetMedicalHistory, vet?: VetModel) => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            [theme.breakpoints.down(wbp)]: {
                marginBottom: `${reduceResolution(32)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginBottom: '32px'
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0
            }
        },
        section: {
            [theme.breakpoints.down(wbp)]: {
                marginBottom: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginBottom: '30px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        buttonMedical: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                'display': 'flex',
                'width': '100%',
                'height': '50px',
                '& span > span': {
                    fontSize: '18px'
                }
            }
        }
    })
);

const Separator = () => {
    return <Box className={useStyles().separator}></Box>;
};

export const EditMedicalHistory: FunctionComponent<
    EditMedicalHistoryProps
> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const isDental = useMarketplace().isDental;

    const petStatus = useSelector<RootState, PetsStatus>(
        state => state.pets.status
    );
    const savedPet = useSelector<RootState, Pet | undefined>(
        state => state.pets.savedPet
    );

    const vetStatus = useSelector<RootState, VetsStatus>(
        state => state.vets.status
    );
    const savedVet = useSelector<RootState, VetModel | undefined>(
        state => state.vets.savedVet
    );

    const [editMedicalHistory, setEditMedicalHistory] = useState(
        cloneDeep<PetMedicalHistory>(props.medicalHistory)
    );
    const [editVet, setEditVet] = useState(
        props.vet ? cloneDeep<VetModel>(props.vet) : createEmptyVet(uuid)
    );
    const [savingMedicalHistory, setSavingMedicalHistory] = useState(false);
    const [savingVet, setSavingVet] = useState(false);

    const [errors, setErrors] = useState<Array<FieldError>>([]);

    useNonInitialEffect(() => {
        switch (petStatus) {
            case PetsStatus.SaveMedicalHistorySuccess:
                setSavingMedicalHistory(false);
                setEditMedicalHistory(
                    cloneDeep<PetMedicalHistory>(savedPet!.medicalHistory)
                );
                props.onSaved &&
                    props.onSaved(savedPet!.medicalHistory, editVet);
                break;
            case PetsStatus.Error:
                setSavingMedicalHistory(false);
                break;
        }
    }, [petStatus]);

    useNonInitialEffect(() => {
        switch (vetStatus) {
            case VetsStatus.SaveSuccess:
                setSavingVet(false);
                setEditVet({ ...savedVet! });
                dispatch(
                    saveMedicalHistoryThunk(
                        apiClientWrapper,
                        props.petId,
                        editMedicalHistory,
                        savedVet!.id
                    )
                );
                break;
            case VetsStatus.Error:
                setSavingVet(false);
                break;
        }
    }, [vetStatus]);

    const changeVaccinesHandler = (vaccines: Array<PetVaccine>) => {
        setEditMedicalHistory(prev => ({ ...prev, vaccines }));
    };

    const changeMedicalConditionsHandler = (
        medicalConditions: Array<PetMedicalCondition>
    ) => {
        setEditMedicalHistory(prev => ({ ...prev, medicalConditions }));
    };

    const changeDentalsHandler = (dentals: Array<PetDental>) => {
        setEditMedicalHistory(prev => ({ ...prev, dentals }));
    };

    const saveHandler = () => {
        const errors = validate(
            apiClientWrapper.marketplace!.basics.address.country!,
            editMedicalHistory,
            editVet
        );
        setErrors(errors);

        if (errors.length === 0) {
            setSavingMedicalHistory(true);

            if (
                isEqual(props.vet, editVet) ||
                (props.vet === undefined && isEmptyVet(editVet))
            ) {
                if (isEqual(props.medicalHistory, editMedicalHistory)) {
                    setSavingMedicalHistory(false);
                    props.onClose && props.onClose();
                } else {
                    dispatch(
                        saveMedicalHistoryThunk(
                            apiClientWrapper,
                            props.petId,
                            editMedicalHistory
                        )
                    );
                }
            } else if (isEmptyVet(editVet)) {
                dispatch(
                    saveMedicalHistoryThunk(
                        apiClientWrapper,
                        props.petId,
                        editMedicalHistory,
                        null
                    )
                );
            } else {
                setSavingVet(true);
                dispatch(saveVetThunk(apiClientWrapper, editVet));
            }
        }
    };

    return (
        <>
            <Grid container xs={12}>
                <MedicalHistoryHeader
                    description="As disclosed by the owner."
                    className={classes.header}
                    saving={savingMedicalHistory || savingVet}
                    onSave={saveHandler}
                    onBack={props.onClose}
                />
                <Grid container xs={12}>
                    <MedicalHistorySection
                        title="Medical Conditions"
                        icon={faHouseMedical}
                        className={classes.section}
                        initExpanded={true}
                    >
                        <EditMedicalConditions
                            gender={props.petGender}
                            medicalConditions={
                                editMedicalHistory.medicalConditions
                            }
                            onChange={changeMedicalConditionsHandler}
                            errors={errors}
                        />
                    </MedicalHistorySection>
                </Grid>
            </Grid>

            {isDental && (
                <>
                    <Separator></Separator>
                    <Grid container xs={12}>
                        <Grid container xs={12}>
                            <MedicalHistorySection
                                title="Dentals"
                                icon={faTooth}
                                className={classes.section}
                                initExpanded={true}
                            >
                                <Dentals
                                    dentals={editMedicalHistory.dentals || []}
                                    onChange={changeDentalsHandler}
                                />
                            </MedicalHistorySection>
                        </Grid>
                    </Grid>
                </>
            )}

            <Separator></Separator>

            <Grid container xs={12}>
                <Grid container xs={12}>
                    <MedicalHistorySection
                        title="Vaccines"
                        icon={faSyringe}
                        className={classes.section}
                        initExpanded={true}
                    >
                        <Vaccines
                            vaccines={editMedicalHistory?.vaccines || []}
                            errors={errors}
                            onChange={changeVaccinesHandler}
                        />
                    </MedicalHistorySection>
                </Grid>
            </Grid>

            <Separator></Separator>

            <Grid container xs={12}>
                <Grid container xs={12}>
                    <MedicalHistorySection
                        title="Vet"
                        icon={faStethoscope}
                        className={classes.section}
                        initExpanded={true}
                    >
                        <EditVet
                            vet={editVet}
                            onChange={setEditVet}
                            errors={errors}
                        />
                    </MedicalHistorySection>
                </Grid>

                <Button
                    className={classes.buttonMedical}
                    label="Save Medical History"
                    loading={savingMedicalHistory || savingVet}
                    onClick={saveHandler}
                />
            </Grid>
        </>
    );
};

export default EditMedicalHistory;
