import { Box, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { reduceResolution, wbp } from "Theme";
import { TextField2, ZipCodeField3 } from "components/UI";
import clsx from "clsx";
import set from "lodash/set";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TerminalOrder,
} from "@spike/terminal-model";
import { FieldError } from "@spike/model";

interface OrderShipTopProps {
  order: TerminalOrder;
  errors: Array<FieldError>;
  className?: string;
  //
  onChange: (order: TerminalOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typoTitle: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px 0",
      },
    },

    boxField: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(15)}px ${reduceResolution(0)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "15px 0px",
      },
    },

    address: {
      display: "flex",
      width: "68%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "6px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "10px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "13px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "19px",
      },
    },

    suite: {
      display: "flex",
      width: "30%",
    },

    state: {
      display: "flex",
      width: "30%",
    },

    city: {
      display: "flex",
      width: "30%",
    },

    zipcode: {
      display: "flex",
      width: "30%",
    }
  })
);

export const OrderShipTo: FunctionComponent<OrderShipTopProps> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  const changeBusinessHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedOrder = { ...props.order };
      set(updatedOrder, fieldName, value);
      props.onChange(updatedOrder);
    }
  };

  return (
    <Box>
      <Box className={classes.typoTitle}>
        <Typography className={clsx(terminalCommonClasses.typo20_600)}>Ship To</Typography>
        <Typography className={terminalCommonClasses.typo15_400}>Address where we will send the POS Terminals.</Typography>
      </Box>

      <Box className={terminalCommonClasses.boxRadiusWhite}>
        <Box className={terminalCommonClasses.rowContainer}>
          <Box className={classes.address}>
            <TextField2
              placeholder={"Business Address"}
              label="Address"
              name="deliveryAddress"
              value={props.order.deliveryAddress ? props.order.deliveryAddress : ""}
              onChange={changeBusinessHandler}
              required={true}
              errors={props.errors}
            />
          </Box>
          <Box className={classes.suite}>
            <TextField2
              placeholder={"Suite"}
              label="Suite"
              name="deliverySuite"
              value={props.order.deliverySuite ? props.order.deliverySuite : ""}
              onChange={changeBusinessHandler}
              required={true}
              errors={props.errors}
            />
          </Box>
        </Box>
        <Box className={terminalCommonClasses.rowContainer}>
          <Box className={classes.city}>
            <TextField2
              placeholder={"City"}
              label="City"
              name="deliveryCity"
              value={props.order.deliveryCity ? props.order.deliveryCity : ""}
              onChange={changeBusinessHandler}
              capitalize={true}
              required={true}
              errors={props.errors}
            />
          </Box>
          <Box className={classes.state}>
            <TextField2
              placeholder={"State"}
              label="State"
              name="deliveryState"
              value={props.order.deliveryState ? props.order.deliveryState : ""}
              onChange={changeBusinessHandler}
              capitalize={true}
              required={true}
              errors={props.errors}
            />
          </Box>
          <Box className={classes.zipcode}>
            <ZipCodeField3
              placeholder={"Zip Code"}
              label="Zip Code"
              name="deliveryZipcode"
              value={props.order.deliveryZipcode ? props.order.deliveryZipcode : ""}
              onChange={changeBusinessHandler}
              required={true}
              errors={props.errors}
            />
          </Box>
        </Box>
      </Box>
    </Box >
  );
};

export default OrderShipTo;
