import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Option } from '@spike/model';
import { CheckboxField2, DescriptionField2, SelectField } from 'components/UI';
import { useMasterData } from 'hooks';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

export interface ConditionsProps {
	name: string;
	selected: boolean;
	description?: string;
	medications?: string;
	diagnoses?: Option<string>;
	onSelect?: (selected: boolean) => void;
	onChange?: (
		description?: string,
		medications?: string,
		diagnosis?: Option<string>
	) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		condition: {
			[theme.breakpoints.down(wbp)]: {
				paddingTop: `${reduceResolution(20)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: '20px'
			}
		},
		medications: {
			[theme.breakpoints.down('md')]: {
				'& input::placeholder': {
					color: 'transparent',
					visibility: 'hidden'
				},
				'& textarea::placeholder': {
					color: 'transparent',
					visibility: 'hidden'
				}
			}
		},
		description: {
			[theme.breakpoints.down('md')]: {
				'& textarea': {
					fontSize: '15px'
				}
			}
		},
		diagnosis: {
			[theme.breakpoints.down('md')]: {
				'& .MuiSelect-root': {
					paddingTop: '13px',
					paddingBottom: '12px'
				}
			}
		}
	})
);

export const Condition: FunctionComponent<ConditionsProps> = props => {
	const classes = useStyles();

	const masterData = useMasterData();

	const changeDescriptionHandler = (description?: string) => {
		props.onChange &&
			props.onChange(description, props.medications, props.diagnoses);
	};

	const changeMedicationsHandler = (medications?: string) => {
		props.onChange &&
			props.onChange(props.description, medications, props.diagnoses);
	};

	const changeDiagnosisHandler = (diagnosis?: Option<string | number>) => {
		props.onChange &&
			props.onChange(
				props.description,
				props.medications,
				diagnosis
					? { id: diagnosis.id.toString(), name: diagnosis.name }
					: undefined
			);
	};

	return (
		<Grid xs={12} container spacing={2} className={classes.condition}>
			<Grid item xs={12} lg={3}>
				<CheckboxField2
					label={props.name}
					checked={props.selected}
					onChange={selected => {
						props.onSelect && props.onSelect(selected);
					}}
				/>
			</Grid>
			{props.selected && (
				<>
					<Grid item xs={12} lg={3}>
						<DescriptionField2
							value={props.description || ''}
							label="Description"
							placeholder="Anything important to note?"
							onChange={changeDescriptionHandler}
							minRows={3}
							className={classes.description}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<DescriptionField2
							value={props.medications || ''}
							label="Medications"
							placeholder="Medications"
							onChange={changeMedicationsHandler}
							minRows={3}
							className={classes.medications}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<SelectField
							label="Diagnosis"
							placeholder="Select"
							selectedOption={props.diagnoses}
							options={masterData.ageOptions}
							onSelect={changeDiagnosisHandler}
							className={classes.diagnosis}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default Condition;
