import React, { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useFieldStyles } from "components/UI/FieldStyles";
import LabelField from "components/UI/LabelField";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import Staff from "model/Staff";

export interface SelectStaffProps {
  id?: string;
	label?: string | JSX.Element;
	name?: string;
	selectedOption?: Staff;
	placeholder?: string;
	options: Array<Staff>;
	disabled?: boolean;
	className?: string;
	required?: boolean;
	information?: string;
	onSelect: (option: Staff) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 110,
    },
    dropdownContainer: {
      position: "relative",
      height: 54
    },
    collapsedContainer: {
      height: "100%",
    },
    expandedContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #222222 !important",
      paddingBottom: 30,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 33
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: 49,
      cursor: "pointer",
    },
    header: {
      display: "flex",
      alignItems: "center",
      color: "#000000",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: "16px",
      lineHeight: "27px",
      paddingLeft: 28,
      width: 290,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 30,
        width: 420
      },
    },
    listContainer: {
      borderTop: "1px solid #222222",
      overflowY: "auto",
      height: "auto",
      maxHeight: 200,
      paddingTop: 12,
      [theme.breakpoints.up('md')]: {
        paddingTop: 14
      },
    },
    text: {
      color: "#000000",
      fontSize: 16,
      lineHeight: "27px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    placeholder: {
      color:"#7A7A7A",
      paddingLeft: 18,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 20
      },
    },
    border: {
      border: "1px solid #222222",
      borderRadius: 27,
      [theme.breakpoints.up('md')]: {
        borderRadius: 30
      },
    },
    textContainer: {
      alignItems: "center",
      paddingRight: 10,
      width: "85%",
    },
    chevronContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      paddingRight: 32,
      [theme.breakpoints.up('md')]: {
        paddingRight: 35
      },
    },
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 45,
      "&:hover": {
        background: "#F8F8F8",
      },
    },
    itemContent: {
      display: "flex",
      width: "100%",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 20,
      paddingRight: 20,
      cursor: "pointer"
    },
    itemContentSelected: {
      paddingLeft: 18,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      alignItems: "center",
      marginRight: 4,
      width: "95%",
      [theme.breakpoints.up('md')]: {
        paddingLeft: 20
      },
    },
    item: {
      fontSize: 16,
      lineHeight: "16px"
    },
    optionNotSelected: {
      border: "1px solid #D3D3D3 !important",
    },
    chevron: {
      fontSize: 16
    },
    bold: {
      fontWeight: 500
    },
    sectionStaff: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      alignItems: "center",
      marginRight: 4,
      height: 17,
      width: "80%",
      [theme.breakpoints.up('md')]: {
        marginRight: 5
      }
    },
    avatarStaff: {
      width: 30,
      height: 30,
      marginRight: 9,
      [theme.breakpoints.up('md')]: {
        marginRight: 10
      },
    },
    staffNameContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%"
    }
  })
);

export const SelectStaff: FunctionComponent<SelectStaffProps> = (props) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const clickCollapseHandler = () => {
    setIsCollapsed((prev) => !prev);
  };

	const changeHandler = (
		idSelected: number
	) => {
		props.onSelect(
			props.options.find((option) => option.id === idSelected)!
		);
	};

  const header = (
    <Box className={classes.headerContainer}>
      <Box className={classes.textContainer}>
        {props.selectedOption?.person ?
          <Box className={clsx(classes.itemContent, classes.itemContentSelected, classes.text)}>
            <Avatar 
              alt={props.selectedOption.person.avatar} 
              src={props.selectedOption.person.avatar} 
              className={classes.avatarStaff} 
            />
            <Typography className={classes.text}>
              {props.selectedOption.person.firstName + " " + props.selectedOption.person.lastName}
            </Typography>
          </Box> :
          <Typography className={clsx(classes.placeholder, classes.text)}>
            {props.placeholder}
          </Typography>
        }
      </Box>
      <Box className={classes.chevronContainer}>
        <FontAwesomeIcon 
          icon={isCollapsed ? faChevronDown : faChevronUp} 
          color={isCollapsed ? "#7A7A7A" : "#222222"}
          className={classes.chevron}
        />
      </Box>
    </Box>
  );

  const list = (
    <Box className={classes.listContainer}>
      {props.options.map((opt, index) => (
        <Box 
          className={classes.itemContainer} 
          key={opt.id} 
          id={`booking_option_staff_${index}`}
          onClick={() => changeHandler(opt.id!)}
        >
          <Box className={classes.itemContent}>
            <Avatar alt={opt.person.avatar} src={opt.person.avatar} className={classes.avatarStaff} />
            <Box className={classes.staffNameContainer}>
              <Typography className={clsx(classes.item, classes.sectionStaff)}>
                {opt.person.firstName + " " + opt.person.lastName}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );

  const collapsed = (
    <Box
      className={clsx(classes.border, classes.collapsedContainer, [classes.optionNotSelected])}
      onClick={clickCollapseHandler}
    >
      {header}
    </Box>
  );

  const expanded = (
    <Box
      className={clsx(classes.border, classes.expandedContainer)}
      onClick={clickCollapseHandler}
    >
      {header}
      {list}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={() => setIsCollapsed(true)}>
      <Box className={clsx(classes.container, fieldClasses.container, props.className)}>
        <LabelField {...props} />
        <Box className={classes.dropdownContainer} id="booking_list_staffs">{isCollapsed ? collapsed : expanded}</Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SelectStaff;
