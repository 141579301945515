import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
	overflow?: boolean;
	minHeigth?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: 0,
			width: '100%',
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				overflow: 'unset !important'
			},

			[theme.breakpoints.up('md')]: {
				paddingTop: '31px',
				paddingLeft: '33px',
				paddingRight: '33px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingTop: '42px',
				paddingLeft: '44px',
				paddingRight: '44px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '63px',
				paddingLeft: '67px',
				paddingRight: '67px'
			}
		}
	})
);

export const BodySection: FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Box
			className={classes.container}
			style={{
				overflow: props.overflow ? 'scroll' : 'auto',
				minHeight: props.minHeigth ? props.minHeigth : 'auto'
			}}
		>
			{props.children}
		</Box>
	);
};

export default BodySection;
