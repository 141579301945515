import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons/faArrowsRepeat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventContentArg } from '@fullcalendar/core';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import isEmpty from 'lodash/isEmpty';
import { FunctionComponent } from 'react';

interface EventFullCalendarProps extends EventContentArg {
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            color: 'black',
            height: '100%',
            width: '100%',
            border: '1px solid rgba(255,255,255,0)',
            overflowX: 'hidden',
            overflowY: 'hidden',
            backgroundImage:
                'linear-gradient(135deg, #bdbdbd 4.55%, #f6f6f6 4.55%, #f6f6f6 50%, #bdbdbd 50%, #bdbdbd 54.55%, #f6f6f6 54.55%, #f6f6f6 100%)',
            backgroundSize: '15.56px 15.56px',
            [theme.breakpoints.down('sm')]: {
                borderRadius: '4px',
                padding: '5px',
                paddingTop: '2px'
            },
            [theme.breakpoints.only('md')]: {
                borderRadius: '6px',
                padding: '5px',
                paddingTop: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                borderRadius: '8px',
                padding: '6px',
                paddingTop: '4px'
            },
            [theme.breakpoints.only('xl')]: {
                borderRadius: '10px',
                padding: '8px',
                paddingTop: '5px'
            }
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
        },
        spinner: {
            [theme.breakpoints.down('sm')]: {
                height: '16px !important',
                width: '16px !important'
            },
            [theme.breakpoints.only('md')]: {
                height: '18px !important',
                width: '18px !important'
            },
            [theme.breakpoints.only('lg')]: {
                height: '24px !important',
                width: '24px !important'
            },
            [theme.breakpoints.only('xl')]: {
                height: '28px !important',
                width: '28px !important'
            }
        },
        loading: {
            '&:hover': {
                cursor: 'default'
            }
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        duration: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px'
            }
        },
        title: {
            fontFamily: 'Poppins',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px',
                paddingTop: '1px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px'
            }
        },
        avatar: {
            [theme.breakpoints.down('sm')]: {
                width: '12px',
                height: '12px'
            },
            [theme.breakpoints.only('md')]: {
                width: '14px',
                height: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '16px',
                height: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '18px',
                height: '18px'
            }
        },
        staffContainer: {
            display: 'flex',
            width: 'auto'
        },
        containerIcon: {
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '12px',
                marginRight: 3
            },
            [theme.breakpoints.only('md')]: {
                width: '14px',
                marginRight: 4
            },
            [theme.breakpoints.only('lg')]: {
                width: '16px',
                marginRight: 4
            },
            [theme.breakpoints.only('xl')]: {
                width: '18px',
                marginRight: 4
            }
        },
        icon: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px',
                paddingBottom: '4px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px',
                paddingBottom: '2px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px'
            }
        },
        containerCentered: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    })
);

export const EventFullCalendar: FunctionComponent<
    EventFullCalendarProps
> = props => {
    const classes = useStyles();
    const serviceDurationInMinutes = props.event.extendedProps.to.diff(
        props.event.extendedProps.from,
        'minutes'
    );
    const isExtendedVersion = serviceDurationInMinutes > 15;

    return (
        <Box
            id={`calendar_div_event_${props.event.extendedProps.appointmentId}`}
            className={clsx(classes.container, {
                [classes.loading]: props.loading,
                [classes.containerCentered]: !isExtendedVersion
            })}
        >
            {props.loading ? (
                <Box className={classes.spinnerContainer}>
                    <Spinner classNameCircularProgress={classes.spinner} />
                </Box>
            ) : (
                <>
                    <Box className={classes.row}>
                        <Typography className={classes.duration}>
                            {`${props.event.extendedProps.from.format(
                                'h:mm a'
                            )} - ${props.event.extendedProps.to.format(
                                'h:mm a'
                            )}`}
                        </Typography>
                        <Box className={classes.staffContainer}>
                            {!isEmpty(
                                props.event.extendedProps.recurringUuid
                            ) && (
                                <Box className={classes.containerIcon}>
                                    <FontAwesomeIcon
                                        icon={faArrowsRepeat}
                                        className={classes.icon}
                                    />
                                </Box>
                            )}
                            <Avatar
                                className={classes.avatar}
                                src={props.event.extendedProps.staffAvatar}
                            />
                        </Box>
                    </Box>
                    {isExtendedVersion && (
                        <Box className={classes.row}>
                            <Typography className={classes.title}>
                                {`${props.event.title}`}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default EventFullCalendar;
