// @ts-nocheck 
import { Box, InputAdornment, TextField as MUITextField } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { useNonInitialEffect } from "@versiondos/hooks";
import clsx from "clsx";
import moment, { Moment } from "moment-timezone";
import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState, useRef } from "react";
import InputMask, {BeforeMaskedStateChangeStates, InputState} from "react-input-mask";
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";

export interface DateField2Props {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  date: Moment | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (date: Moment, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  endIcon?: JSX.Element;
  onEnterPressed?: () => void;
  disabled?: boolean;
  information?: string;
}

export const DateField2: FunctionComponent<DateField2Props> = (props) => {
  const classes = useFieldStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const [text, setText] = useState(props.date?.format("MM/DD/YYYY") || undefined);

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(moment(text), props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (newValue.indexOf("_") === -1 && !moment(newValue).isValid()) {
      setText("");
      inputRef.current && inputRef.current.focus();
      //inputRef.current && inputRef.current.setSelectionRange(0,0,"forward");
    } else {
      setText(newValue);
    }
  };

  const onFocusHandler = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  const mask = '99/99/9999';

  const beforeMaskedValueChangeHandler = (states: BeforeMaskedStateChangeStates): InputState =>{
    return {
      value: "111",
      selection: {
        start: 0,
        end: 3
      }
    }
  }

  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField {...props} />
      <Box>
        <InputMask
          mask={mask}
          name={props.name}
          alwaysShowMask={true}
          placeholder="mm-dd-yyyy"
          value={text}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
          beforeMaskedStateChange={beforeMaskedValueChangeHandler}
          inputRef={inputRef}
        >
          {() => (
            <MUITextField
              variant="outlined"
              error={hasError}
              className={clsx(classes.field, {
                [classes.emptyField]: (text || "").length === 0,
                [classes.fieldError]: hasError,
              })}
            />
          )}
        </InputMask>
      </Box>
    </Box>
  );
};

export default DateField2;
