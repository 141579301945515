import { ApiClientWrapper } from '@spike/api-client';
import { createWrapper } from 'api/ApiClient';
import { useStore } from 'react-redux';

export const useApiClientWrapper = (): ApiClientWrapper => {
    const store = useStore();
    return createWrapper(store.getState());
};

export default useApiClientWrapper;
