import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PaymentsBankAccount } from "@spike/payments-model";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface PrimaryChipProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#C0EBD1",
      borderRadius: "23px",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(23)}px`,
        padding: `0px ${reduceResolution(7)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "23px",
        padding: "0px 7px",
      },
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#306344",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
      },
    },
  })
);

export const PrimaryChip: FunctionComponent<PrimaryChipProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.text}>Primary</Typography>
    </Box>
  );
};

export default PrimaryChip;
