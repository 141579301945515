import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { ClientTags } from '@spike/client-model';
import { FunctionComponent } from 'react';
import TagChips from './TagChips';

interface TagsProps {
    disabled?: boolean;
    tags: ClientTags;
    onChange?: (tags: ClientTags) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {},
        title: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            marginBottom: 24
        }
    })
);

export const Tags: FunctionComponent<TagsProps> = props => {
    const classes = useStyles();

    const changePositiveTags = (selectedTags: Array<Option<string>>) => {
        props.onChange && props.onChange({ ...props.tags, positive: selectedTags });
    };

    const changeNegativeTags = (selectedTags: Array<Option<string>>) => {
        props.onChange && props.onChange({ ...props.tags, negative: selectedTags });
    };

    return (
        <Grid className={classes.container}>
            <Typography className={classes.title}>You can select multiple tags</Typography>
            <TagChips
                disabled={props.disabled}
                id="clients_div_tags"
                selectedPositiveTags={props.tags.positive}
                selectedNegativeTags={props.tags.negative}
                onChangeSelectionPositiveTags={changePositiveTags}
                onChangeSelectionNegativeTags={changeNegativeTags}
            />
        </Grid>
    );
};

export default Tags;
