import React, { FunctionComponent, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CalendarBooking from "./CalendarBooking";
import DateSelectorComponent from "./DateSelectorComponent";
import moment, { Moment } from "moment-timezone";
import HoursBooking from "./HoursBooking";
import { useMarketplace } from "hooks";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE } from "constants/index";

interface Props {
    date: Moment;
    onSelectDate: (date: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            paddingBottom: 18,
            [theme.breakpoints.up('md')]: {
                paddingBottom: 20
            },
        },
        sectionCalendar: {
            display: "flex",
            justifyContent: "space-between",
            marginRight: 17,
            [theme.breakpoints.up('md')]: {
                marginRight: 19
            }
        },
        section: {
            display: "flex",
            marginRight: 0,
            [theme.breakpoints.up('md')]: {
                marginRight: 19
            }
        },
        text: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "14px",
            marginRight: 9,
            [theme.breakpoints.up('md')]: {
                marginRight: 10,
                fontSize: 16,
                lineHeight: "16px",
            }
        },
		contentDate: {
			top: 40,
			[theme.breakpoints.up('md')]: {
				top: 45,
			},
		},
        contentHours: {
            display: "flex",
            flexDirection: "column",
            border: "1px solid #D4D4D4",
            backgroundColor: "#fff",
            height: 252,
            width: 115,
            top: 40,
            left: -80,
            borderRadius: 11,
            paddingTop: 9,
            paddingBottom: 9,
            [theme.breakpoints.up('md')]: {
                height: 232,
                top: 45,
                borderRadius: 12,
                paddingTop: 10,
                paddingBottom: 10
            },
        }
    })
);

const getDateFormatted = (date: Moment) => {
    const minutes = date.minutes();
    let dateFormatted = date;
    
    if(minutes > 0 && minutes <= 15) {
        dateFormatted.add(15 - minutes, 'minutes');
    } else if(minutes > 15 && minutes <= 30) {
        dateFormatted.add(30 - minutes, 'minutes');
    } else if(minutes > 30 && minutes <= 45) {
        dateFormatted.add(45 - minutes, 'minutes');
    } else if(minutes > 45 && minutes <= 59) {
        dateFormatted.add(60 - minutes, 'minutes');
    }

    return dateFormatted;
}

export const DateSelectorSection: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const marketplace = useMarketplace();

    const [calendarExpanded, setCalendarExpanded] = useState(false);
    const [hoursExpanded, setHoursExpanded] = useState(false);

    const handleExpandCalendar = () => {
        setCalendarExpanded(!calendarExpanded);
        setHoursExpanded(false);
    }

    const handleExpandHours = () => {
        setCalendarExpanded(false);
        setHoursExpanded(!hoursExpanded);
    }

    const handleSelectDate = (dateSelected: Date) => {
        setCalendarExpanded(false);
        props.onSelectDate(moment.tz(`${moment(dateSelected).format('YYYY-MM-DD')} ${props.date.format('LT').toUpperCase()}`, 'YYYY-MM-DD hh:mm A', marketplace.schedule.timeZone));
    }

    const handleSelectHours = (hourValue: string) => {
        amplitude.track(AMPLITUDE.CTA_SELECT_TIME);
        const dateHour = moment.tz(`${getDateFormatted(props.date).format('YYYY-MM-DD')} ${hourValue.toUpperCase()}`, 'YYYY-MM-DD hh:mm A', marketplace.schedule.timeZone);
        setHoursExpanded(false);
        props.onSelectDate(dateHour);
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.sectionCalendar}>
                <Typography className={classes.text}>
                    {props.date.format("dddd, DD MMM YYYY")}
                </Typography>
                <DateSelectorComponent 
                    id="date"
                    expanded={calendarExpanded}
                    className={classes.contentDate}
                    onExpand={handleExpandCalendar}
                    onClickAway={() => setCalendarExpanded(false)}
                >
                    <CalendarBooking 
                        date={getDateFormatted(props.date)}
                        onDaySelect={handleSelectDate}
                     />
                </DateSelectorComponent>
            </Box>
            <Box className={classes.section}>
                <Typography className={classes.text}>
                    {props.date.format('LT').toLowerCase()}
                </Typography>
                <DateSelectorComponent 
                    id="hour"
                    expanded={hoursExpanded}
                    className={classes.contentHours}
                    onExpand={handleExpandHours}
                    onClickAway={() => setHoursExpanded(false)}
                >
                    <HoursBooking 
                        dayMoment={getDateFormatted(props.date)}
                        onSelectHour={handleSelectHours}
                    />
                </DateSelectorComponent>
            </Box>
        </Box>
    )
};

export default DateSelectorSection