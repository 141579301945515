import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RootState } from 'store';
import { searchCustomersThunk } from 'actions/autocomplete/AutocompleteActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControlledTextField, Spinner } from 'components/UI';
import {
    faCat,
    faDog,
    faNarwhal,
    faUser,
    IconDefinition
} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

// Action
import { getClientThunk } from '@spike/clients-action';
import { clearClientsThunk } from '@spike/clients-action';
import { clearPetsThunk } from '@spike/pets-action';

// Model
import AutocompleteResult from 'model/AutocompleteResult';

// Reducer
import { useDispatch, useSelector } from 'react-redux';
import { ClientsState, ClientsStatus } from '@spike/clients-action';
import {
    AutocompleteState,
    AutocompleteStatus
} from 'reducers/autocomplete/AutocompleteState';
import { useApiClientWrapper } from 'hooks';
import ClientResult from './ClientResult';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface Props {
    multiplePet: boolean;
    fromClient?: boolean;
    setPetParent: (parentID: number, parentName: string) => void;
    setPetId?: (petId: number) => void;
    onAddClient: () => void;
    parentID: number | undefined;
    parentName: string | undefined;
    readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        parentTitle: {
            fontWeight: 600,
            paddingBottom: 14,
            fontSize: 16,
            lineHeight: '16px',
            [theme.breakpoints.up('md')]: {
                paddingBottom: '16px'
            }
        },
        boxResult: {
            borderLeft: '1px solid #D3D3D3',
            borderRight: '1px solid #D3D3D3',
            borderBottom: '1px solid #D3D3D3',
            height: 'auto',
            padding: '13px 0px 13px 0px',
            maxHeight: 200,
            fontSize: 11,
            borderBottomLeftRadius: 17,
            borderBottomRightRadius: 17,
            [theme.breakpoints.up('md')]: {
                padding: '15px 0px 15px 0px',
                borderBottomLeftRadius: 19,
                borderBottomRightRadius: 19
            }
        },
        boxItem: {
            'display': 'flex',
            'flexWrap': 'nowrap',
            'alignItems': 'center',
            'fontSize': 14,
            'fontWeight': 400,
            'height': 30,
            'paddingLeft': 13,
            [theme.breakpoints.up('md')]: {
                height: 33,
                paddingLeft: 15
            },
            '&:hover': {
                background: '#eee',
                cursor: 'pointer'
            }
        },
        field: {
            'width': '100%',
            '& .MuiInputLabel-outlined': {
                color: '#999',
                fontSize: 13
            },

            '& .MuiInputBase-root': {
                fontSize: 13,
                height: 51,
                borderRadius: 33,
                [theme.breakpoints.up('md')]: {
                    height: 54,
                    borderRadius: 36
                }
            },

            '& .MuiOutlinedInput-input': {
                fontSize: 16,
                padding: '13px 13px 16px 16px !important',
                [theme.breakpoints.up('md')]: {
                    padding: '15px 15px 18px 18px !important'
                }
            }
        },
        fieldSearch: {
            'width': '100%',
            'border': '0 !important',
            'fontSize': 16,
            '& .MuiOutlinedInput-root': {
                [theme.breakpoints.up('md')]: {
                    border: '10px !important',
                    borderRadius: '20px 20px 0 0 !important'
                },
                border: '8px !important',
                borderRadius: '18px 18px 0 0 !important',
                padding: '0px !important'
            }
        },
        clientLinkContainer: {
            paddingTop: 14,
            [theme.breakpoints.up('md')]: {
                paddingTop: 16
            }
        },
        clientLink: {
            fontWeight: 500,
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#000000',
            fontSize: 16,
            lineHeight: '24px',
            [theme.breakpoints.up('md')]: {
                lineHeight: '27px'
            }
        },
        listAlignedIcons: {
            width: '14px !important',
            [theme.breakpoints.up('md')]: {
                width: '16px !important'
            }
        },
        sectionIcon: {
            marginRight: 6,
            [theme.breakpoints.up('md')]: {
                marginRight: 8
            }
        },
        clientNoFound: {
            fontSize: 14,
            fontWeight: 400,
            padding: 4,
            paddingLeft: 16,
            [theme.breakpoints.up('md')]: {
                padding: 5,
                paddingLeft: 18
            }
        }
    })
);

export const ClientSearch: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [parentName, setParentName] = useState<string | undefined>(
        props.parentName
    );
    const [petName, setPetName] = useState<string>('');
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [showSearchField, setShowSearchField] = useState(true);

    const [searchingParent, setSearchingParent] = useState(false);
    const [resultParent, setResultParent] = useState(true);

    const client = useSelector<RootState, ClientsState>(state => state.clients);
    const parent = useSelector<RootState, AutocompleteState>(
        state => state.autocomplete
    );
    const parents = useSelector<RootState, Array<AutocompleteResult>>(
        state => state.autocomplete.results
    );

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const iconSVG = (icon: IconDefinition, styles: string, size: any) => {
        return <FontAwesomeIcon icon={icon} className={styles} size={size} />;
    };

    useEffect(() => {
        if (parent.status === AutocompleteStatus.SearchingSuccess) {
            setSearchingParent(false);
            if (parents.length === 0) {
                setResultParent(false);
            } else {
                setResultParent(true);
            }
        } else {
            setSearchingParent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent.status, parents.length]);

    useEffect(() => {
        if (props.parentID !== undefined && !props.fromClient) {
            dispatch(getClientThunk(apiClientWrapper, props.parentID));
        } else {
            if (props.fromClient) {
                setShowSearchField(false);
                setShowSearchResult(true);
            } else {
                setShowSearchField(true);
                setShowSearchResult(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.parentID, dispatch]);

    useEffect(() => {
        if (client.status === ClientsStatus.GetSuccess) {
            const petNameFormatted = petName
                ?.split('(')[0]
                ?.trim()
                ?.toLowerCase();
            const result = {
                id: props.parentID,
                type: 'Customer',
                name: client.client?.firstName + ' ' + client.client?.lastName,
                petId: client.client?.pets?.find(
                    p =>
                        petNameFormatted?.trim()?.toLowerCase() ===
                        p.name?.trim()?.toLowerCase()
                )?.id
            };
            setShowSearchField(false);
            handletParentFirst(result as AutocompleteResult);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client.status]);

    const changeHandler = (value: string, name: string) => {
        setParentName(value);
        setShowSearchResult(true);

        if (value === '') {
            setShowSearchResult(false);
        } else {
            dispatch(searchCustomersThunk(value));
        }
    };

    const handletParent = (value: AutocompleteResult) => {
        setShowSearchResult(false);
        setShowSearchField(false);
        props.setPetParent(value.id, value.name);
        setParentName(value.name);
        dispatch(getClientThunk(apiClientWrapper, value.id!));
        if (value.type === 'Pet') {
            setPetName(value.name);
        } else {
            setPetName('');
        }
    };

    const handletParentFirst = (value: AutocompleteResult) => {
        setShowSearchResult(false);
        setShowSearchField(false);

        if (props.parentID === undefined && value.id !== undefined) {
            props.setPetParent(value.id, value.name);
        }

        if (value.petId) {
            props.setPetId && props.setPetId(value.petId);
        }

        if (value.id !== undefined) {
            setParentName(value.name);
        } else {
            if (!props.fromClient) {
                handlerClearSearch();
            }
        }
    };

    const handlerClearSearch = () => {
        setParentName('');
        setShowSearchField(true);
        setShowSearchResult(false);
        dispatch(clearClientsThunk());
        dispatch(clearPetsThunk());
        props.setPetParent(0, '');
    };

    const addNewClient = () => {
        dispatch(clearClientsThunk());
        dispatch(clearPetsThunk());
        props.onAddClient();
    };

    const searchIconType = (parent: AutocompleteResult) => {
        let icon;

        switch (parent.type) {
            case 'Customer':
                icon = iconSVG(
                    faUser,
                    `${classes.sectionIcon} ${classes.listAlignedIcons}`,
                    'lg'
                );
                break;
            case 'Pet':
                switch (parent.petType?.singular) {
                    case 'Cat':
                        icon = iconSVG(
                            faCat,
                            `${classes.sectionIcon} ${classes.listAlignedIcons}`,
                            'lg'
                        );
                        break;
                    case 'Dog':
                        icon = iconSVG(
                            faDog,
                            `${classes.sectionIcon} ${classes.listAlignedIcons}`,
                            'lg'
                        );
                        break;
                    default:
                        icon = iconSVG(
                            faNarwhal,
                            `${classes.sectionIcon} ${classes.listAlignedIcons}`,
                            'lg'
                        );
                        break;
                }
                break;
        }
        return icon;
    };

    const viewSearchComponent = (
        <ControlledTextField
            id="booking_input_search"
            placeholder="Search by client name or pet name"
            name="name"
            value={parentName!}
            className={clsx(classes.field, {
                [classes.fieldSearch]: showSearchResult
            })}
            onChange={changeHandler}
            autoComplete="off"
        />
    );

    const viewClientSelectComponent = (
        <ClientResult
            readOnly={props.readOnly}
            client={client.client}
            multiplePet={props.multiplePet}
            onRemoveClient={handlerClearSearch}
        />
    );

    const searchResultComponent = (
        <Box className={classes.boxResult}>
            {searchingParent && <Spinner />}
            {!searchingParent && !resultParent && (
                <Box className={classes.clientNoFound}>No clients found</Box>
            )}
            {!searchingParent && resultParent && (
                <OverlayScrollbarsComponent style={{ maxHeight: '120px' }}>
                    {parents.map((parent, index) => (
                        <Box
                            id={`booking_option_client_${index}`}
                            key={parent.id + parent.name}
                            className={classes.boxItem}
                            onClick={() => handletParent(parent)}
                        >
                            {searchIconType(parent)} {parent.name}
                        </Box>
                    ))}
                </OverlayScrollbarsComponent>
            )}
        </Box>
    );

    return (
        <Box>
            {showSearchField && (
                <Typography className={classes.parentTitle}>
                    Select a Client
                </Typography>
            )}
            {showSearchField && viewSearchComponent}
            {!showSearchField && viewClientSelectComponent}
            {showSearchResult && searchResultComponent}
            {showSearchField && (
                <Box className={classes.clientLinkContainer}>
                    <Box
                        id="booking_div_add_client"
                        onClick={addNewClient}
                        className={classes.clientLink}
                    >
                        + Add New Client
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ClientSearch;
