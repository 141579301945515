import { faPhoneFlip, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Fragment, FunctionComponent } from 'react';
import { ClientData } from 'model/SingleSale';
import { useCommonStyles } from 'components/UI/CommonStyles';

interface ClientInfoProps {
	data: ClientData;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',

			[theme.breakpoints.up('md')]: {
				height: '188px',
				paddingTop: '5px'
			}
		},
		info: {
			gap: 6,
			alignItems: 'center'
		},
		icon: {
			width: 20,
			height: 26,
			color: '#000'
		},
		tagContent: {
			gap: '7px 4px',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'start',
			paddingTop: 10,
			paddingBottom: 24,

			[theme.breakpoints.down('sm')]: {
				borderBottom: '1px solid #d4d4d4'
			}
		},
		clientTag: {
			color: '#fff',
			height: 'auto',
			fontSize: 13,
			fontWeight: 500,
			padding: '5.5px 0px',
			backgroundColor: '#BCB8AE'
		},
		typo: {
			color: '#222'
		}
	})
);

export const ClientInfo: FunctionComponent<ClientInfoProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const { name, phone, email, tags } = props.data;

	return (
		<Fragment>
			<Box className={clsx(classes.container, commonClasses.flexColumn)}>
				<Box>
					<Typography
						className={clsx(classes.typo, commonClasses.typo16_600)}
					>
						{name}
					</Typography>
				</Box>
				<Box className={clsx(classes.info, commonClasses.flexRow)}>
					<FontAwesomeIcon
						icon={faPhoneFlip}
						className={clsx(classes.icon)}
					/>
					<Typography
						className={clsx(classes.typo, commonClasses.typo15_400)}
					>
						{phone}
					</Typography>
				</Box>
				<Box className={clsx(classes.info, commonClasses.flexRow)}>
					<FontAwesomeIcon
						icon={faEnvelope}
						className={clsx(classes.icon)}
					/>
					<Typography
						className={clsx(classes.typo, commonClasses.typo15_400)}
					>
						{email}
					</Typography>
				</Box>
				<Box className={classes.tagContent}>
					{tags.positive &&
						tags.positive.map(tag => (
							<Chip
								key={tag.id}
								label={tag.name}
								className={classes.clientTag}
							/>
						))}
					{tags.negative &&
						tags.negative.map(tag => (
							<Chip
								key={tag.id}
								label={tag.name}
								className={classes.clientTag}
							/>
						))}
				</Box>
			</Box>
		</Fragment>
	);
};

export default ClientInfo;
