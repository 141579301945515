import React, { FunctionComponent, useState, Fragment } from 'react';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { CropperImage } from 'components/UI';
import { ImageSelector } from './ImageSelector';

interface DentalReportImagesProps {
	images: Array<string>;
	onChangeImage?: (image: ImageFile) => void;
	className?: string;
}

export interface ImageFile {
	image: string;
	file: File | null;
	type: ImageTypes;
}

export enum ImageTypes {
	BEFORE = 0,
	AFTER = 2
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column'
		},
		title: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		imageSelectorRow: {
			[theme.breakpoints.down('sm')]: {
				'gap': 16,
				'display': 'flex',

				'& > div': {
					flex: 1
				}
			}
		}
	})
);

export const DentalReportImages: FunctionComponent<
	DentalReportImagesProps
> = props => {
	const classes = useStyles(props);

	const [showCropper, setShowCropper] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string>();
	const [fileName, setFileName] = useState<string>();
	const [imageType, setImageType] = useState<ImageTypes | null>(null);

	const selectImageHandler = (file: File, type: ImageTypes) => {
		setImageType(type);
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setSelectedImage(reader.result?.toString()!);
				setFileName(file.name);
				setShowCropper(true);
			}
		};
		reader.readAsDataURL(file);
	};

	const cropHandler = (file: File) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				const image = reader.result?.toString()!;
				props.onChangeImage &&
					props.onChangeImage({ image, file, type: imageType! });
				setImageType(null);
			}
		};
		reader.readAsDataURL(file);

		setShowCropper(false);
	};

	return (
		<Fragment>
			<Box className={clsx(classes.container, props.className)}>
				<Typography className={classes.title}>
					Before & After Picture
				</Typography>

				<Box className={classes.imageSelectorRow}>
					<ImageSelector
						title={
							<>
								<strong>Before</strong> Image
							</>
						}
						image={props.images[0]}
						onSelect={file =>
							selectImageHandler(file, ImageTypes.BEFORE)
						}
					/>
					<ImageSelector
						title={
							<>
								<strong>After</strong> Image
							</>
						}
						image={props.images[1]}
						onSelect={file =>
							selectImageHandler(file, ImageTypes.AFTER)
						}
					/>
				</Box>
			</Box>
			{showCropper && (
				<CropperImage
					image={selectedImage!}
					fileName={fileName!}
					cropWidth={750}
					cropHeight={215}
					onClose={() => setShowCropper(false)}
					onCrop={cropHandler}
					viewScale={0.5}
				/>
			)}
		</Fragment>
	);
};

export default DentalReportImages;
