import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Appointment, status } from '@spike/appointment-model';
import clsx from 'clsx';
import { useTimeZone } from 'hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { isFuture } from 'utils/DateUtils';
import CheckoutButton from './CheckoutButton';
import StatusButton from './StatusButton';

interface AppointmentButtonProps {
	appointment: Appointment;
	isUndo?: boolean;
	loadingAction?: boolean;
	className?: string;
	onCheckIn?: (appointmentId: number) => void;
	onCheckOut?: () => void;
	onComplete?: () => void;
	onShowDetails?: () => void;
	onStart?: () => void;
	id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		checkIn: {
			color: 'white',
			borderColor: '#EAB464',
			backgroundColor: '#EAB464',

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					backgroundColor: '#D4A259',
					borderColor: '#D4A259'
				}
			}
		},
		start: {
			color: 'white',
			borderColor: '#92B4A7',
			backgroundColor: '#92B4A7 !important',

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					backgroundColor: '#5E8677 !important',
					borderColor: '#5E8677'
				}
			}
		},
		complete: {
			color: '#92B4A7',
			borderColor: '#92B4A7',
			backgroundColor: 'white',

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					backgroundColor: '#E9F0ED',
					borderColor: '#92B4A7'
				}
			}
		},
		details: {
			color: '#5E8677',
			borderColor: '#5E8677',
			backgroundColor: 'white',

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					backgroundColor: '#E9F0ED',
					borderColor: '#5E8677'
				}
			}
		},
		status: {
			color: '#BCB8AE',
			fontWeight: 600,
			fontSize: '14px'
		},
		waitingPaymentConfirmation: {
			textAlign: 'center',
			color: '#BCB8AE',
			fontWeight: 500,
			fontSize: '12px'
		},
		completed: {
			color: '#92B4A7'
		},
		icon: {
			top: -1,
			color: '#92B4A7',
			marginRight: '5px',
			position: 'relative'
		},
		nowrap: {
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			flexWrap: 'nowrap'
		},
		button: {
			cursor: 'default',
			color: '#fff',
			backgroundColor: '#D3D3D3',
			borderColor: '#D3D3D3',

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					backgroundColor: '#D3D3D3',
					borderColor: '#D3D3D3'
				}
			}
		}
	})
);

export const AppointmentButton: FunctionComponent<
	AppointmentButtonProps
> = props => {
	const classes = useStyles(props);
	const timeZone = useTimeZone();

	const future = isFuture(props.appointment.duration.from, timeZone);

	const [loading, setLoading] = useState(false);
	const [appointmentStatusId, setAppointmentStatusId] = useState(
		props.appointment.status.id
	);

	useEffect(() => {
		if (props.appointment.status.id !== appointmentStatusId) {
			setLoading(false);
			setAppointmentStatusId(props.appointment.status.id);
		}
	}, [props.appointment]);

	useEffect(() => {
		props.isUndo || props.loadingAction
			? setLoading(true)
			: setLoading(false);
	}, [props.isUndo, props.loadingAction]);

	const isStaffAssigned = () => {
		let checkStaff = false;

		if (props.appointment.services.length > 0)
			props.appointment.services[0].staff === undefined
				? (checkStaff = false)
				: (checkStaff = true);
		else checkStaff = false;

		return checkStaff;
	};

	const checkInHandler = () => {
		if (props.appointment.services[0].staff !== undefined) {
			setLoading(true);
			props.onCheckIn && props.onCheckIn(props.appointment.id!);
		}
	};

	const startHandler = () => {
		if (props.appointment.services[0].staff !== undefined) {
			setLoading(true);
			props.onStart && props.onStart();
		}
	};

	const completeHandler = () => {
		if (isStaffAssigned()) props.onComplete && props.onComplete();
	};

	const checkOutHandler = () => {
		props.onCheckOut && props.onCheckOut();
	};

	const handlerShowDetail = () => {
		props.onShowDetails && props.onShowDetails();
	};

	const createButton = () => {
		if (!future && props.appointment.status.id === status.CONFIRMED) {
			return props.onCheckIn ? (
				<StatusButton
					label="Check In"
					id={`${props.id || ''}`}
					className={clsx(classes.checkIn, {
						[classes.button]: !isStaffAssigned()
					})}
					onClick={checkInHandler}
					spinnerColor="white"
					loading={loading}
				/>
			) : null;
		} else if (
			!future &&
			props.appointment.status.id === status.CHECKED_IN
		) {
			return props.onStart ? (
				<StatusButton
					label="Start"
					id={`${props.id || ''}`}
					className={clsx(classes.start, {
						[classes.button]: !isStaffAssigned()
					})}
					onClick={startHandler}
					spinnerColor="white"
					loading={loading}
				/>
			) : null;
		} else if (
			!future &&
			props.appointment.status.id === status.IN_PROGRESS
		) {
			return props.onComplete ? (
				<StatusButton
					label="Complete"
					id={`${props.id || ''}`}
					className={clsx(classes.complete, {
						[classes.button]: !isStaffAssigned()
					})}
					onClick={completeHandler}
					spinnerColor="#92B4A7"
					loading={loading}
				/>
			) : null;
		} else if (
			!future &&
			props.appointment.status.id === status.READY_FOR_CHECK_OUT
		) {
			return props.onCheckOut ? (
				<CheckoutButton
					id={`${props.id || ''}`}
					onClick={checkOutHandler}
					loading={loading}
				/>
			) : null;
		} else if (!future && props.appointment.status.id === status.PAYMENT) {
			return props.onCheckOut ? (
				<CheckoutButton
					id={`${props.id || ''}`}
					onClick={checkOutHandler}
					loading={loading}
				/>
			) : null;
		} else if (
			!future &&
			props.appointment.status.id === status.WAITING_PAYMENT_CONFIRMATION
		) {
			return (
				<Typography className={classes.waitingPaymentConfirmation}>
					{props.appointment.status.name}
				</Typography>
			);
		} else if (future && props.appointment.status.id === status.CONFIRMED) {
			return (
				<Typography className={classes.status}>Confirmed</Typography>
			);
		} else if (props.appointment.status.id === status.CANCELLED) {
			return <Typography className={classes.status}>Canceled</Typography>;
		} else if (props.appointment.status.id === status.NO_SHOW) {
			return <Typography className={classes.status}>No Show</Typography>;
		} else if (props.appointment.status.id === status.DECLINED) {
			return <Typography className={classes.status}>Declined</Typography>;
		} else if (props.appointment.status.id === status.COMPLETED) {
			return (
				<Box className={classes.nowrap}>
					<FontAwesomeIcon
						icon={faCheckCircle}
						className={classes.icon}
					/>
					<Typography
						className={clsx(classes.status, classes.completed)}
					>
						Completed
					</Typography>
				</Box>
			);
		} else {
			return props.onShowDetails ? (
				<StatusButton
					label="Details"
					className={classes.details}
					onClick={handlerShowDetail}
				/>
			) : null;
		}
	};

	return createButton();
};

export default AppointmentButton;
