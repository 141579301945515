import React, { FunctionComponent, Fragment } from "react";
import { formatRawDate } from "utils/DateUtils";
import { Moment } from "moment-timezone";
import { Box } from "@material-ui/core";

export interface Props {
  date: Moment;
  className?: string;
}

export const DateFormat: FunctionComponent<Props> = (props) => {
  const date = formatRawDate(props.date);

  return (<Fragment><Box className={props.className}>{date}</Box></Fragment>);
};

export default DateFormat;
