import React, { FunctionComponent } from "react";
import {createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import { FieldError } from "@spike/model";
import NumberField2 from "./NumberField2";
import { useFieldStyles } from "./FieldStyles";
import clsx from "clsx";

export interface PriceField2Props {
  id?: string;
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: number | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  disabled?: boolean;
  scale?: number;
  precision?: number;
  information?: string;
  //
  onEnterPressed?: () => void;
  onBlur?: () => void;
  onChange: (value: number | undefined, name?: any) => void;
  onFocus?: () => void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      labelContainer2: {
        marginLeft: 0,
        color: 'red'
      }
    })
)

export const PriceField2: FunctionComponent<PriceField2Props> = (props) => {

  const classes = useFieldStyles();
  const classes2 = useStyles()

  return (
    <NumberField2
      {...props}
        labelClassName={classes2.labelContainer2}
      startIcon={<Typography className={classes.adornmentText}>$</Typography>}
      className={clsx(classes.inputTextLeft, props.className)}
    />
  );
};

export default PriceField2;
