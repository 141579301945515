import moment, { Moment } from "moment-timezone";
import { v4 as uuid } from "uuid";

export interface Agreement {
  active: boolean;
  content: string;
  createdAt: Moment;
  id?: number;
  mandatory: boolean;
  marketplaceId: number;
  sendTo: string;
  title: string;
  updatedAt: Moment;
  uuid: string;
  client?: string;
  channel?: string;
  required?: string;
  signedAt?: Moment;
  status?: AgreementStatus;
}

export type AgreementStatus = "signed" | "unsigned";

export interface Signature {
  id: number;
  uuid: string;
  marketplaceId: number;
  agreementId: number;
  createdAt: Moment;
  delivered: boolean;
  email: boolean;
  sms: boolean;
  secureHash: string;
  sentAt?: Moment;
  signedAt?: Moment;
  content: string;
  title: string;
  updatedAt: Moment;
  client: SignatureClient;
  status: AgreementStatus;
}

export interface SignatureClient {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
}

export interface AgreementSummary {
  agreement: Agreement;
  sent: number;
  signed: number;
  unsigned: number;
}

export const createEmptyAgreement = (marketplaceId: number) => {
  const now = moment();
  return {
    active: true,
    content: "",
    createdAt: now.clone(),
    mandatory: false,
    marketplaceId,
    sendTo: "",
    title: "",
    updatedAt: now.clone(),
    uuid: uuid(),
  };
};
