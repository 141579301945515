import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button } from "components/UI/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import clsx from "clsx";
import Appointment from "@spike/appointment-model";

interface Props {
  appointment?: Appointment;
  onGoToBookings: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    checkIcon: {
      justifyContent: "center",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("lg")]: {
        fontSize: "82px",
        marginBottom: "7px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "110px",
        marginBottom: "10px",
      },
    },
    title: {
      color: "#92B4A7",
      justifyContent: "center",
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "53px",
        marginBottom: "7px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "70px",
        marginBottom: "10px",
      },
    },
    description: {
      textAlign: "center",
      width: "100%",
      minWidth: "400px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "23px",
        marginBottom: "34px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "30px",
        marginBottom: "45px",
      },
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    button: {
      [theme.breakpoints.down("lg")]: {
        minWidth: "111px",
        height: "43px",
        fontSize: "13px",
        marginBottom: "32px",
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: "148px",
        height: "57px",
        fontSize: "18px",
        marginBottom: "43px",
      },
    },
    buttonBookings: {
      backgroundColor: "#92B4A7",
      "&:hover": {
        backgroundColor: "#92B4A7",
      },
    },
  })
);

export const SuccessDecline: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const bookingsButton = (
    <Button label="Go back to Bookings" onClick={() => props.onGoToBookings()} className={clsx(classes.button, classes.buttonBookings)} />
  );

  return (
    <Box className={classes.container}>
      <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
      <Typography className={classes.title}>Done.</Typography>
      <Typography className={classes.description}>
        <strong>{props.appointment?.customer.firstName}</strong>´s appointment has <br />
        been completed.
      </Typography>
      <Box className={classes.buttons}>
        {bookingsButton}
      </Box>
    </Box>
  );
};

export default SuccessDecline;
