import { FunctionComponent } from 'react';
import MedicalCategorySection from '../../MedicalCategorySection';
import { MedicalCategory as MedicalCategoryModel } from '../../MedicalConditionModel';
import MedicalCondition from './MedicalCondition';

export interface MedicalCategoryProps {
    category: MedicalCategoryModel;
    className?: string;
}

export const MedicalCategory: FunctionComponent<
    MedicalCategoryProps
> = props => {
    return (
        <MedicalCategorySection
            categoryName={props.category.name}
            categoryId={props.category.categoryId}
            expandable={true}
            className={props.className}
        >
            {props.category.categoryId !== 11 &&
                props.category.conditions.map((condition, index) => {
                    return (
                        <MedicalCondition condition={condition} key={index} />
                    );
                })}
        </MedicalCategorySection>
    );
};

export default MedicalCategory;
