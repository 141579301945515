import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { wbp, reduceResolution } from 'Theme';

export const useCommonStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleText: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			color: '#2C2C2C',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`,
				lineHeight: `${reduceResolution(24)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeight: '24px'
			}
		},
		inputText: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px'
			}
		},
		errorBorder: {
			border: '2px solid #EF4F57 !important'
		},
		errorBorderColor: {
			borderColor: '#EF4F57 !important'
		},
		errorText: {
			color: '#EF4F57 !important'
		},
		right: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		firstHeader: {
			[theme.breakpoints.down(wbp)]: {
				paddingLeft: `${reduceResolution(70)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingLeft: '70px'
			}
		},
		firstCell: {
			[theme.breakpoints.up('md')]: {
				paddingLeft: '33px'
			}
		},
		textCell: {
			fontSize: 14,
			lineHeight: 1.4,
			fontWeight: 400,
			color: '#222222',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',

			[theme.breakpoints.up('md')]: {
				fontSize: 16
			}
		},
		amountTextCell: {
			textAlign: 'right',

			[theme.breakpoints.down('sm')]: {
				fontWeight: 500
			}
		},
		totalCell: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		iconCell: {
			display: 'flex',
			justifyContent: 'center',
			paddingLeft: '0px !important',

			[theme.breakpoints.down('sm')]: {
				display: 'none !important'
			}
		},
		icon: {
			cursor: 'pointer'
		},
		editable: {
			cursor: 'pointer'
		}
	})
);
