import React, { FunctionComponent, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import TextField from '@material-ui/core/TextField';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { Checkbox } from "../Checkbox";
import { SelectableOption } from "../../../model";
import Button from "../Button";

interface Props {
  open: boolean;
  name: string;
  duplicateOptions: Array<SelectableOption<string>>;
  onDuplicate: (name: string, selectedOptionsIds: Array<string>) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        border: "1px solid #222222",
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("lg")]: {
          width: "361px",
          maxHeight: "424px",
          borderRadius: "15px",
          paddingBottom: "36px",
        },
        [theme.breakpoints.up("xl")]: {
          width: "482px",
          maxHeight: "566px",
          borderRadius: "20px",
          paddingBottom: "48px",
        },
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0px",
        [theme.breakpoints.down("lg")]: {
          paddingBottom: "18px",
          paddingLeft: "30px",
          paddingRight: "30px",
        },
        [theme.breakpoints.up("xl")]: {
          paddingBottom: "24px",
          paddingLeft: "41px",
          paddingRight: "41px",
        },
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "center",
      },
    },
    title: {
      display: "flex",
      justifyContent: "flex-end",
      padding: 0,
      [theme.breakpoints.down("lg")]: {
        paddingRight: "30px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: "41px",
      },
    },
    option: {
      [theme.breakpoints.down("lg")]: {
        marginTop: "12px",
        marginLeft: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "23px",
        marginLeft: "32px",
      },
    },
    button: {
      border: 0,
      background: "#5E8677",
      color: "#FFFFFF",
      [theme.breakpoints.down("lg")]: {
        width: "114px",
        minWidth: "114px",
        height: "42px",
        fontSize: "13pt",
        fontWeight: 600
      },
      [theme.breakpoints.up("xl")]: {
        width: "152px",
        minWidth: "152px",
        height: "57px",
        fontSize: "18pt",
        fontWeight: 600
      },
      "&:hover": {
        background: "#5E8677",
      },
    },
  })
);

export const DuplicateDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  
  const [options, setOptions] = useState(props.duplicateOptions);
  const [name, setName] = useState(`Copy of ${props.name}`);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const changeCheckboxHandler = (id: string, checked: boolean): void => {
    setOptions((prevOptions) => {
      prevOptions.find(option => option.element.id === id)!.selected = checked;
      return prevOptions.slice();
    });
  }

  const duplicateHandler = (): void => {
    props.onDuplicate(name, options.filter(option => option.selected).map(option => option.element.id));
  }

  useEffect(() => {
    setButtonDisabled(!options.some(option => !option.selected) || name.length === 0);
  }, [options, name]);


  const checkBoxes = options.map(option => (<Box key={option.element.id} className={classes.option}>
    <Checkbox label={option.element.name} checked={option.selected} onChange={(checked: boolean) => {changeCheckboxHandler(option.element.id, checked)}} />
  </Box>));

  return (
      <Dialog onClose={props.onClose} open={props.open} className={classes.dialog}>
        <DialogTitle disableTypography className={classes.title}>
          <IconButton aria-label="close" onClick={props.onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              defaultValue={name}
              variant="outlined"
              size="small"
              onChange={(event) => {setName(event.target.value)}}/>
          </Box>
          {checkBoxes}
        </DialogContent>
        <DialogActions>
          <Button
            label="Duplicate"
            onClick={duplicateHandler}
            className={clsx(classes.button)}
            disabled={buttonDisabled}
          />
        </DialogActions>
      </Dialog>
  );
};

export default DuplicateDialog;
