import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PetSearch from '../PetSearch';
import ServiceSelector from '../ServiceSelector';
import StaffSelector from '../StaffSelector';
import { NewBookingAlert, PetService } from '@spike/new-booking-model';
import { useApiClientWrapper, useAuth, useTimeZone } from 'hooks';
import Pet from '@spike/pet-model';
import Staff from 'model/Staff';
import { Moment } from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { fetchAlertsThunk } from '@spike/new-bookings-action';

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

interface Props {
    parentId: number;
    petId?: number;
    service?: PetServiceWrapper;
    staffId?: number;
    firstLoad?: boolean;
    versionPetAndServices?: 'another' | 'add' | 'edit' | undefined;
    screen?: 'add' | 'edit' | undefined;
    date?: Moment;
    petsLoaded?: boolean;
    multipleAppointments: Array<AppointmentMultiple>;
    onChangePet: (value: Pet) => void;
    onChangeService: (value: PetServiceWrapper) => void;
    onChangeStaff: (value?: Staff, first?: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        sectionContainer: {
            paddingTop: 18,
            [theme.breakpoints.up('md')]: {
                paddingTop: 20
            }
        }
    })
);

export const AddOrEditService: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const auth = useAuth();
    const apiClientWrapper = useApiClientWrapper();
    const dispatch = useDispatch();
    const timezone = useTimeZone();
    const [showService, setShowService] = useState(false);
    const [showStaff, setShowStaff] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [date, setDate] = useState<Moment | undefined>(props.date);
    const [servicesUsed, setServicesUsed] = useState<Array<number>>([]);

    const alerts = useSelector<RootState, Array<NewBookingAlert> | undefined>(
        state => state.newBookings.alerts
    );

    useEffect(() => {
        if (date) {
            dispatch(
                fetchAlertsThunk(
                    apiClientWrapper,
                    props.parentId || 0,
                    props.petId || 0,
                    date.clone().tz('utc'),
                    false,
                    props.service?.service.id,
                    props.staffId,
                    undefined
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, props.service, props.staffId]);

    useEffect(() => {
        if (props.multipleAppointments.length > 0) {
            let durationMinutesTotal = 0;
            if (props.versionPetAndServices === 'another') {
                props.multipleAppointments.forEach(
                    (apt: AppointmentMultiple) => {
                        apt.variableData?.forEach(
                            (data: AppointmentDataVaraible) => {
                                durationMinutesTotal =
                                    durationMinutesTotal +
                                    data.service.durationInMinutes;
                            }
                        );
                    }
                );
            }

            if (
                props.versionPetAndServices === 'add' ||
                props.screen === 'add'
            ) {
                let founded = false;
                props.multipleAppointments.forEach(
                    (apt: AppointmentMultiple) => {
                        if (!founded) {
                            apt.variableData?.forEach(
                                (data: AppointmentDataVaraible) => {
                                    durationMinutesTotal =
                                        durationMinutesTotal +
                                        data.service.durationInMinutes;
                                }
                            );
                        }
                        if (apt.petId === props.petId) {
                            founded = true;
                        }
                    }
                );
            }

            if (props.screen === 'edit') {
                let founded = false;
                props.multipleAppointments.forEach(
                    (apt: AppointmentMultiple) => {
                        apt.variableData?.forEach(
                            (data: AppointmentDataVaraible) => {
                                if (
                                    apt.petId === props.petId &&
                                    data.service.service.id ===
                                        props.service?.service.id
                                ) {
                                    founded = true;
                                } else {
                                    if (!founded) {
                                        durationMinutesTotal =
                                            durationMinutesTotal +
                                            data.service.durationInMinutes;
                                    }
                                }
                            }
                        );
                    }
                );
            }

            const newDate =
                props.multipleAppointments[0]?.variableData[0]?.date?.clone();

            const newFinalDate = newDate
                ?.add(durationMinutesTotal, 'minutes')
                .tz(timezone);
            setDate(newFinalDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.multipleAppointments]);

    useEffect(() => {
        if (props.service) {
            setShowStaff(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.service]);

    useEffect(() => {
        if (props.multipleAppointments) {
            setServicesUsed(getServicesUsed());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlerPet = (value: Pet) => {
        setShowService(true);
        if (
            props.versionPetAndServices === 'add' ||
            props.versionPetAndServices === 'another'
        ) {
            setShowStaff(false);
        }
        props.onChangePet(value);
    };

    const handlerService = (value: PetServiceWrapper) => {
        setShowStaff(true);
        props.onChangeService(value);
        setReadOnly(false);
    };

    const handlerStaff = (staffSelected?: Staff, first?: boolean) => {
        props.onChangeStaff(staffSelected, first);
    };

    const getServicesUsed = () => {
        const servicesUsed: Array<number> = [];
        props.multipleAppointments.forEach(app => {
            if (app.petId === props.petId) {
                app.variableData?.forEach(data => {
                    if (
                        props.versionPetAndServices === 'edit' &&
                        props.screen === 'edit'
                    ) {
                        if (
                            data.service.service.id !==
                            props.service?.service.id
                        ) {
                            servicesUsed.push(data.service.service.id);
                        }
                    } else {
                        servicesUsed.push(data.service.service.id);
                    }
                });
            }
        });
        return servicesUsed;
    };

    const petsView = (
        <Box className={classes.sectionContainer}>
            <PetSearch
                parentID={props.parentId}
                petId={props.petId}
                multiplePets={true}
                multipleAppointments={props.multipleAppointments}
                petsLoaded={props.petsLoaded}
                readOnly={props.versionPetAndServices !== 'another'}
                hideSelect={
                    props.versionPetAndServices !== 'add' &&
                    props.versionPetAndServices !== 'another'
                }
                handlerPet={handlerPet}
            />
        </Box>
    );

    const serviceView = (
        <Box className={classes.sectionContainer}>
            <ServiceSelector
                selectedService={props.service}
                servicesSelected={servicesUsed}
                clientId={props.parentId!}
                petId={props.petId!}
                multiplePets={true}
                onSelectService={handlerService}
            />
        </Box>
    );

    const staffView = (
        <Box className={classes.sectionContainer}>
            <StaffSelector
                summary={false}
                selectedStaff={props.staffId}
                loginStaff={auth.user!.staffId}
                service={props.service}
                firstLoad={props.firstLoad}
                multiplePets={true}
                readOnly={readOnly}
                onSelectStaff={handlerStaff}
                alerts={alerts?.filter(alert => alert.alertType === 'staff')}
            />
        </Box>
    );

    return (
        <Box className={classes.container}>
            {petsView}
            {showService && serviceView}
            {showStaff && staffView}
        </Box>
    );
};

export default AddOrEditService;
