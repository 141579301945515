import { faUserHeadset } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface SidebarFooterIconProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#92B4A7",
      borderRadius: "41px",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(41)}px`,
        height: `${reduceResolution(41)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "41px",
        height: "41px",
      },
    },
    icon: {
      color: "white",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(22)}px !important`,
        height: `${reduceResolution(22)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "22px !important",
        height: "22px !important",
      },
    },
  })
);

export const SidebarFooterIcon: FunctionComponent<SidebarFooterIconProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <FontAwesomeIcon icon={faUserHeadset} className={classes.icon} />
    </Box>
  );
};

export default SidebarFooterIcon;
