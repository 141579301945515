import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from 'components/UI';
import React, { FunctionComponent } from 'react';
import CloudUploadIcon from './CloudUploadIcon';
import Dropzone from 'react-dropzone';
import { reduceResolution, wbp } from 'Theme';
interface DropZoneFileProps {
	icon?: JSX.Element;
	title?: string;
	buttonLabel?: string;
	onDrop: (file: File) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%'
		},
		dropZone: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			alignItems: 'center',
			border: '1px dashed #7A7A7A',
			[theme.breakpoints.down(wbp)]: {
				padding: '50px 0px 45px 0px',
				borderRadius: '8px'
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '62px 0px 56px 0px',
				borderRadius: '10px'
			}
		},
		title: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			color: 'black',
			width: '100%',
			textAlign: 'center',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '12px',
				lineHeight: '18px',
				marginTop: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15px',
				lineHeight: '22px',
				marginTop: '15px'
			}
		},
		selectText: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: 'black',
			width: '100%',
			textAlign: 'center',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				lineHeight: '16px',
				marginTop: '28px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '14px',
				lineHeight: '20px',
				marginTop: '35px'
			}
		},
		fileTypesText: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: 'rgba(0, 0, 0, 0.4)',
			width: '100%',
			textAlign: 'center',
			fontSize: 13,
			[theme.breakpoints.down(wbp)]: {
				lineHeight: '14px',
				marginTop: '10px'
			},
			[theme.breakpoints.up(wbp)]: {
				lineHeight: '18px',
				marginTop: '12px'
			}
		},
		button: {
			'height': 39,
			'padding': '0px 20px',
			'fontSize': 14,
			[theme.breakpoints.down(wbp)]: {
				'marginTop': '14px',
				'& span': {
					fontSize: 14,
					fontWeight: 600
				}
			},
			[theme.breakpoints.up(wbp)]: {
				'marginTop': '18px',
				'& span': {
					fontSize: 14,
					fontWeight: 600
				}
			},
			'& .MuiButton-startIcon': {
				'& svg': {
					width: 16,
					height: 16,
					[theme.breakpoints.down(wbp)]: {
						width: 14,
						height: 14
					}
				}
			}
		}
	})
);
export const DropZoneFile: FunctionComponent<DropZoneFileProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Dropzone
				accept={[
					'image/png',
					'imagfe/jpg',
					'image/jpeg',
					'application/pdf'
				]}
				maxFiles={1}
				onDrop={acceptedFiles => props.onDrop(acceptedFiles[0])}
			>
				{({ getRootProps, getInputProps }) => (
					<section style={{ width: '100%' }}>
						<input {...getInputProps()} />
						<Box {...getRootProps()} className={classes.dropZone}>
							{props.icon || <CloudUploadIcon />}
							{props.title && (
								<Typography className={classes.title}>
									{props.title}
								</Typography>
							)}
							<Typography className={classes.selectText}>
								Select a file or drag and drop here
							</Typography>
							<Typography className={classes.fileTypesText}>
								JPG, JPEG, PNG or PDF, file size no more than
								4MB
							</Typography>
							<Typography
								className={classes.fileTypesText}
							></Typography>
							<Button
								label={props.buttonLabel || '+ Add document'}
								className={classes.button}
							/>
						</Box>
					</section>
				)}
			</Dropzone>
		</Box>
	);
};

export default DropZoneFile;
