import { Tip as InvoiceTip} from '@spike/invoice-model';
export interface TipOption {
  uuid: string;
  amount: string | null;
  percentage: string | null; 
}

export interface CheckoutTip {
  default: TipOption | null;
  customTip: InvoiceTip | null;
}

export const createNotSelectedTip = (): CheckoutTip => ({
    default: null,
    customTip: null,
});