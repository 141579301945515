import { Box, FormControl, InputLabel, MenuItem, Select, Theme, createStyles, makeStyles } from "@material-ui/core";
import { FieldError, Option } from "@spike/model";
import { FunctionComponent, useMemo } from "react";
import LabelField from "../../UI/LabelField";
import clsx from "clsx";

export interface SelectFieldProps {
	id?: string;
	label?: string | JSX.Element;
	name?: string;
	selectedOption?: Option<string | number>;
	placeholder?: string;
	options: Array<Option<string | number>>;
	errors?: Array<FieldError>;
	disabled?: boolean;
	className?: string;
	required?: boolean;
	information?: string;
	//
	onSelect: (option: Option<string | number>, name?: string) => void;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",

			"& .MuiOutlinedInput": {
				"&-root": {
					borderRadius: 30,
				},
				"&-notchedOutline": {
					borderWidth: "1px !important",
					borderColor: "#D3D3D3 !important",
				},
				"&-input": {
					"&, &:focus": {
						backgroundColor: "transparent",
					},
					fontSize: 16,
					lineHeight: "27px",
					padding: "8px 32px 8px 32px",
					[theme.breakpoints.up('md')]: {
						padding: "10px 35px 10px 35px",
					},
				},
			},

			"& .MuiInputLabel": {
				"&-root": {
					transform: "translate(35px, 16px) scale(1)",
					"&[data-shrink=true]": {
						opacity: 0,
					},
				},
			},
		},
		errorBorder: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#EF4F57 !important",
			},
		},
		disabled: {
			"& .MuiOutlinedInput-root": {
				backgroundColor: "#f5f5f5",
			},
		},
		placeholder: {
			color: "#7A7A7A",
			fontSize: 16,
			lineHeight: "27px",
		},
		selected: {
			color: "#000000",
			fontSize: 16,
			lineHeight: "27px"
		}
	})
);

export const SelectField: FunctionComponent<SelectFieldProps> = (props) => {
	const classes = useStyles();

	const changeHandler = (
		event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
	) => {
		const selectedId = event.target.value as string | number;

		props.onSelect(
			props.options.find((option) => option.id === selectedId)!,
			props.name
		);
	};

	const hasError = useMemo(
		() =>
			props.errors?.some((errorField) => errorField.fieldName === props.name),
		[props.errors, props.name]
	);

	const selectedValue = useMemo(() => {
		return props.selectedOption?.id;
	}, [props.selectedOption]);

	return (
		<Box
			className={clsx(classes.root, props.className, {
				[classes.errorBorder]: hasError,
				[classes.disabled]: props.disabled,
			})}
		>
			<LabelField {...props} />

			<FormControl fullWidth>
				<InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>

				<Select
					id={props.id || ""}
					variant="outlined"
					displayEmpty
					value={selectedValue ? selectedValue : props.placeholder}
					renderValue={selectedValue !== "" && selectedValue !== undefined ? undefined : () => props.placeholder}
					onChange={changeHandler}
					disabled={props.disabled}
					labelId={`${props.name}-label`}
					className={selectedValue !== "" && selectedValue !== undefined ? classes.selected : classes.placeholder}
				>
					{props.options.map((opt) => (
						<MenuItem key={opt.id} value={opt.id} id={`${props.id || ''}_${opt.name}`}>
							{opt.name} {opt.description && opt.description}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default SelectField;
