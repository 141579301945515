import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { logicalDeleteClientThunk, saveClientThunk } from '@spike/clients-action';
import { Button, Checkbox, ConfirmDialog, ConfirmDialogWarning, FormSection } from 'components/UI';
import { Client as ClientModel } from '@spike/client-model';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientsStatus } from '@spike/clients-action';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import AuthorizedContacts from './AuthorizedContacts';
import MarketingNotification from './MarketingNotifications';
import PersonalInformation from './PersonalInformation';
import Tags from './Tags';
import { validateClient } from './Validations';
import clsx from 'clsx';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { fetchOnboardingStepsThunk } from 'actions/onboardingSteps/OnboardingStepsActions';
import { MobileDivider } from 'components/UI/MobileDivider';
import { ClientEditHeader } from './ClientEditHeader';
import { Img } from 'react-image';
import BlockClient from 'components/Client/BlockClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import Info from 'components/UI/V2/Info';
import { Link, useHistory } from 'react-router-dom';
import { NoPetTypesAlert } from 'components/UI/V2/Info/Variants/NoPetTypesAlert';
interface ClientProps {
    client: ClientModel;
    onBook: () => void;
    onClose: () => void;
    onSaved?: (client: ClientModel) => void;
    onDeleted?: () => void;
    onPetNotesEdited?: () => void;
    onSelect: (key: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '24px 16px',
            backgroundColor: '#fff',
            heigth: '100%',
            [theme.breakpoints.up('md')]: {
                backgroundColor: '#FAFAFA',
                padding: '55px 56px 0px 61px'
            }
        },
        rowSpacing: {
            [theme.breakpoints.up('md')]: {
                marginTop: 53
            }
        },
        mobileTitle: {
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 24,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        contacts: {
            '& > div:first-child': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            }
        },
        footer: {
            'marginTop': 60,
            'paddingBottom': 8,
            'flexWrap': 'nowrap',

            [theme.breakpoints.up('md')]: {
                paddingTop: 40,
                paddingBottom: 100
            },

            '& button': {
                'height': 54,
                'borderRadius': 30,

                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },

                '& span': {
                    fontSize: 18,
                    fontWeight: 600
                }
            }
        },
        deleteButton: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '12px'
            }
        },
        noPadding: {
            '& > div:last-child > div': {
                padding: '0px'
            }
        },
        dividerWrapper: {
            margin: '0px -16px'
        },
        personalInfoHeader: {
            position: 'relative',
            width: '100%',
            heigth: '100px',
            background: '#FAEFDF',
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 12,
            overflow: 'hidden'
        },
        personalInfoHeaderLeft: {
            padding: '24px 0px 18px 23px',
            flex: 1,
            [theme.breakpoints.down('md')]: {
                padding: '24px 23px 18px 23px'
            }
        },
        personalInfoHeaderTitle: {
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 6
        },
        personalInfoHeaderText: {
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        },
        personalInfoHeaderRight: {
            'width': '38%',
            'position': 'relative',
            '& img': {
                position: 'absolute',
                top: '-12px',
                right: 0,
                objectFit: 'contain',
                transition: 'all .5s linear',
                [theme.breakpoints.down(1540)]: {
                    right: '-55px'
                }
            },
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        contentDialog: {
            display: 'flex',
            flexDirection: 'column'
        },
        contentDialogSubtitle: {
            textAlign: 'start',
            marginLeft: '50px',
            marginTop: '10px',
            marginBottom: '10px'
        },
        list: {
            'width': '85%',
            'alignSelf': 'center',
            '& .MuiListItemIcon-root': {
                minWidth: '20px',
                color: '#222',
                fontSize: '10px !important'
            }
        },
        question: {
            marginLeft: '45px',
            marginRight: '45px'
        },
        listItem: {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        checkboxContainer: {
            width: '85%',
            alignSelf: 'center',
            textAlign: 'start',
            whiteSpace: 'normal',
            flexWrap: 'wrap',
            marginTop: '10px',
            marginLeft: '10px'
        },
        checkbox: {
            'color': '#7A7A7A',
            '&.MuiFormControlLabel-root .MuiTypography-root': {
                whiteSpace: 'normal'
            },
            '& .MuiCheckbox-root': {
                alignSelf: 'flex-start'
            }
        },
        noPetTypesInfo: {
            marginBottom: 20
        }
    })
);

export const Client: FunctionComponent<ClientProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const clientsStatus = useSelector<RootState, ClientsStatus>(state => state.clients.status);
    const savedClient = useSelector<RootState, ClientModel | undefined>(state => state.clients.savedClient);
    const clientsDone = useSelector<RootState, boolean>(
        state => state.onboardingSteps.onboardingSteps.clientsDone ?? false
    );

    const [editClient, setEditClient] = useState(props.client);
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [saving, setSaving] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [checked, setChecked] = useState(false);
    const [showSaveAlert, setShowAlert] = useState(false);

    useNonInitialEffect(() => {
        setEditClient(props.client);
    }, [props.client.id]);

    useNonInitialEffect(() => {
        switch (clientsStatus) {
            case ClientsStatus.SaveSuccess: {
                setEditClient(savedClient!);
                props.onSaved && props.onSaved(savedClient!);
                setSaving(false);
                break;
            }
            case ClientsStatus.LogicalDeleteSuccess: {
                if (clientsDone === true) {
                    dispatch(fetchOnboardingStepsThunk());
                }
                props.onDeleted && props.onDeleted();
                setDeleting(false);
                break;
            }
            case ClientsStatus.Error: {
                setSaving(false);
                break;
            }
        }
    }, [clientsStatus]);

    const saveHandler = () => {
        const errors = validateClient(editClient, marketplace.basics.address.country!);
        setErrors(errors);

        if (errors.length === 0) {
            dispatch(saveClientThunk(apiClientWrapper, { ...editClient }));
            setSaving(true);
        }
    };

    const deleteHandler = () => {
        setDeleting(true);
        dispatch(logicalDeleteClientThunk(apiClientWrapper, editClient.id!));
    };

    const confirmBackHandler = () => {};

    const viewSaveAlert = (
        <ConfirmDialogWarning
            open={showSaveAlert}
            question={<Box className="saveChangeTitle">Save Changes</Box>}
            subQuestion={
                <Box className="saveChangeMessage">You have not saved your changes. Are you sure you want to exit?</Box>
            }
            confirmButtonLabel={'Exit Anyway'}
            cancelButtonLabel={'Cancel'}
            onCancel={() => setShowAlert(false)}
            onConfirm={() => confirmBackHandler()}
        />
    );

    const personalInfoHeader = (
        <Box className={classes.personalInfoHeader}>
            <Box className={classes.personalInfoHeaderLeft}>
                <Typography className={classes.personalInfoHeaderTitle}>Add new client</Typography>
                <Typography className={classes.personalInfoHeaderText}>
                    Please enter either an <strong>email address</strong> or a <strong>phone number</strong> to create a
                    new client.
                </Typography>
            </Box>
            <Box className={classes.personalInfoHeaderRight}>
                <Img src="images/clients/createClientInfo.png" width={300} height={147} />
            </Box>
        </Box>
    );

    const hasPetTypes = Object.values(marketplace.petTypes).some(petType => petType !== undefined);

    return (
        <>
            <Grid className={classes.container}>
                {!props.client.id ? (
                    <Typography variant="h2" className={classes.mobileTitle}>
                        Add client
                    </Typography>
                ) : isMobile ? (
                    <>
                        <ClientEditHeader
                            client={editClient}
                            onBook={props.onBook}
                            onClose={props.onClose}
                            onSelect={props.onSelect}
                        />
                    </>
                ) : undefined}

                {showSaveAlert && viewSaveAlert}

                <FormSection
                    title="Personal Information"
                    subtitle="Enter the client's personal information. You must add their first and last name, plus either an email or phone number."
                >
                    {editClient && !editClient.id && hasPetTypes && personalInfoHeader}
                    {!hasPetTypes && (
                        <Box className={classes.noPetTypesInfo}>
                            <NoPetTypesAlert />
                        </Box>
                    )}
                    <PersonalInformation
                        client={editClient}
                        errors={errors}
                        onChange={setEditClient}
                        disabled={!hasPetTypes}
                    />
                </FormSection>

                <Box className={classes.dividerWrapper}>
                    <MobileDivider />
                </Box>

                <FormSection
                    title="Tags"
                    subtitle="Choose tags that will allow you to segment client groups."
                    className={classes.rowSpacing}
                >
                    <Tags
                        disabled={!hasPetTypes}
                        tags={editClient.tags}
                        onChange={tags => setEditClient(prev => ({ ...prev, tags }))}
                    />
                </FormSection>

                <Box className={classes.dividerWrapper}>
                    <MobileDivider />
                </Box>

                <FormSection
                    title="Authorized & Emergency Contacts"
                    subtitle="Add authorized contacts that can pick up and drop off the client’s pet or emergency contacts that can make decisions on behalf of the client."
                    className={clsx(classes.rowSpacing, classes.contacts, classes.noPadding)}
                >
                    <AuthorizedContacts
                        disabled={!hasPetTypes}
                        clientId={editClient.id}
                        contacts={editClient.authorizedContacts}
                        onChange={authorizedContacts =>
                            setEditClient(prev => ({
                                ...prev,
                                authorizedContacts
                            }))
                        }
                    />
                </FormSection>

                <Box className={classes.dividerWrapper}>
                    <MobileDivider />
                </Box>

                <FormSection
                    title="Notifications"
                    subtitle="Enable or disable client notifications."
                    className={classes.rowSpacing}
                >
                    <MarketingNotification
                        disabled={!hasPetTypes}
                        notifications={editClient.notifications}
                        onChange={notifications => setEditClient(prev => ({ ...prev, notifications }))}
                    />
                </FormSection>

                {editClient.id && (
                    <>
                        <Box className={classes.dividerWrapper}>
                            <MobileDivider />
                        </Box>

                        <FormSection
                            title="Block Client"
                            subtitle="Blocking clients prevents them from booking appointments."
                            className={classes.rowSpacing}
                        >
                            <BlockClient client={editClient} />
                        </FormSection>
                    </>
                )}

                <Grid item container justifyContent="flex-end" className={classes.footer}>
                    {editClient?.id && (
                        <Button
                            label="Delete"
                            variant="danger"
                            id="clients_button_delete"
                            onClick={() => setShowDeleteConfirmation(true)}
                            loading={deleting}
                            className={classes.deleteButton}
                        />
                    )}
                    <Button
                        id="clients_button_save"
                        label="Save"
                        color="orange"
                        disabled={deleting || !hasPetTypes}
                        loading={saving}
                        onClick={saveHandler}
                    />
                </Grid>
            </Grid>
            {showDeleteConfirmation && (
                <ConfirmDialog
                    id="clients_confirm_dialog_"
                    open={true}
                    title={<Typography>Delete Client</Typography>}
                    question={
                        <Typography className={classes.question}>
                            Are you sure you want to permanently remove <strong>{editClient!.firstName}</strong> from
                            your client list?
                        </Typography>
                    }
                    subquestion={
                        <Box className={classes.contentDialog}>
                            <Typography className={classes.contentDialogSubtitle}>
                                Please note that this action will:
                            </Typography>
                            <List className={classes.list}>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faCircleSmall} size="1x" />
                                    </ListItemIcon>
                                    <ListItemText primary="Permanently delete the client´s profile from your records." />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faCircleSmall} size="1x" />
                                    </ListItemIcon>
                                    <ListItemText primary="Cancel all upcoming appointments." />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faCircleSmall} size="1x" />
                                    </ListItemIcon>
                                    <ListItemText primary="Keep a record of past completed appointments." />
                                </ListItem>
                            </List>
                            <Box className={classes.checkboxContainer}>
                                <Checkbox
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                    className={classes.checkbox}
                                    label="To proceed, please confirm you understand the impact of deleting this client."
                                />
                            </Box>
                        </Box>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete Client"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => {
                        if (checked) {
                            setShowDeleteConfirmation(false);
                            deleteHandler();
                        }
                    }}
                />
            )}
        </>
    );
};

export default Client;
