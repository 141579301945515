import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    faPen,
    faBan,
    faTrash,
    faCircleCheck
} from '@fortawesome/pro-light-svg-icons';
import { SelectableOption, Status } from '../../../model';
import { Typography } from '@material-ui/core';
import { ActionsPopup } from '../ActionsPopup/ActionsPopup';

interface Props {
    className?: string;
    statusId: number;
    name: string;
    duplicateOptions?: Array<SelectableOption<string>>;
    onEdit?: () => void;
    onActivate?: (active: boolean) => void;
    onDuplicate?: (name: string, selectedOptionsIds: Array<string>) => void;
    onDelete?: () => void;
    showDialog?: boolean;
    onCloseDialog?: () => void;
}

type ServiceAction = 'Activate' | 'Deactivate';

interface ServiceActionHeaderMobileProps {
    name: string;
    action: ServiceAction;
    subtitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex'
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            overflowX: 'visible',
            position: 'relative',
            [theme.breakpoints.down('lg')]: {
                height: '26px',
                fontSize: '11px'
            },
            [theme.breakpoints.up('xl')]: {
                height: '30px',
                fontSize: '16px'
            }
        },
        labelContainer: {
            cursor: 'pointer',
            position: 'absolute',
            [theme.breakpoints.down('lg')]: {
                right: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                right: '24px'
            }
        },
        label: {
            cursor: 'pointer',
            backgroundColor: '#5E8677',
            color: '#ffffff',
            [theme.breakpoints.down('lg')]: {
                height: '26px'
            },
            [theme.breakpoints.up('xl')]: {
                height: '30px'
            }
        },
        dialog: {
            '& .MuiDialog-paper': {
                border: '1px solid #222222',
                boxShadow: '0px 10px 40px #00000029',
                [theme.breakpoints.down('lg')]: {
                    width: '361px',
                    minHeight: '424px',
                    borderRadius: '15px',
                    paddingBottom: '36px'
                },
                [theme.breakpoints.up('xl')]: {
                    width: '482px',
                    minHeight: '566px',
                    borderRadius: '20px',
                    paddingBottom: '48px'
                }
            },
            '& .MuiDialogContent-root': {
                paddingTop: '0px',
                [theme.breakpoints.down('lg')]: {
                    paddingBottom: '18px',
                    paddingLeft: '30px',
                    paddingRight: '30px'
                },
                [theme.breakpoints.up('xl')]: {
                    paddingBottom: '24px',
                    paddingLeft: '41px',
                    paddingRight: '41px'
                }
            },
            '& .MuiDialogActions-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
                justifyContent: 'center'
            }
        },
        button: {
            'border': 0,
            'background': '#5E8677',
            'color': '#FFFFFF',
            [theme.breakpoints.down('lg')]: {
                width: '114px',
                minWidth: '114px',
                height: '42px',
                fontSize: '13pt',
                fontWeight: 600
            },
            [theme.breakpoints.up('xl')]: {
                width: '152px',
                minWidth: '152px',
                height: '57px',
                fontSize: '18pt',
                fontWeight: 600
            },
            '&:hover': {
                background: '#5E8677'
            }
        },
        duplicateDialogTitle: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 0,
            [theme.breakpoints.down('lg')]: {
                paddingRight: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingRight: '41px'
            }
        },
        duplicateDialogOption: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '17px',
                marginLeft: '24px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '23px',
                marginLeft: '32px'
            }
        },
        disabledButton: {
            cursor: 'default',
            color: '#797979'
        },
        headerServiceActions: {
            'display': 'flex',
            'marginTop': '20px',
            'marginBottom': '10px',
            '& p': {
                fontSize: '16px',
                fontWeight: 600
            }
        },
        headerConfirmDialog: {},
        titleDialog: {
            fontSize: '26px',
            fontWeight: 600,
            marginBottom: '10px'
        },
        subtitleDialog: {
            'fontSize': '16px',
            'fontWeight': 400,
            'width': '90%',
            'marginLeft': 'auto',
            'marginRight': 'auto',
            '& b': {
                fontWeight: 600
            }
        }
    })
);

export const RowToolbarMobile: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const containerStyle = clsx(classes.container, props.className);
    const [confirmStatusDialog, setConfirmStatusDialog] = useState(false);
    const [confirmStatusDialogDeactivate, setConfirmStatusDialogDeactivate] =
        useState(false);
    const [showActions, setShowActions] = useState(props.showDialog);

    useEffect(() => {
        if (window.HubSpotConversations && window.HubSpotConversations.widget) {
            if (showActions || confirmStatusDialog) {
                window.HubSpotConversations.widget.remove();
            } else {
                window.HubSpotConversations.widget.load();
            }
        }
    }, [showActions, confirmStatusDialog]);

    useEffect(() => {
        setShowActions(props.showDialog);
    }, [props.showDialog]);

    const onEditClickHandler = (): void => {
        props.onEdit && props.onEdit();
    };

    const onActiveClickHandler = (): void => {
        props.onActivate &&
            props.onActivate(props.statusId === Status.INACTIVE);
    };

    const onDeleteClickHandler = (): void => {
        setShowActions(false);
        props.onDelete && props.onDelete();
    };

    const disabledActiveButton =
        props.statusId === Status.DRAFT || !props.onActivate;

    const ServiceActionHeader: React.FunctionComponent<{
        name: Props['name'];
    }> = props => (
        <Box className={clsx(classes.headerServiceActions)}>
            <Box>
                <Typography>{props.name}</Typography>
            </Box>
        </Box>
    );

    const ServiceActionHeaderMobile: React.FunctionComponent<
        ServiceActionHeaderMobileProps
    > = props => (
        <Box className={clsx(classes.headerConfirmDialog)}>
            <Box>
                <Typography className={classes.titleDialog}>
                    {props.action} Service
                </Typography>
                <Typography className={classes.subtitleDialog}>
                    Are you sure you want to {props.subtitle} <br />{' '}
                    <b>{props.name}</b>?
                </Typography>
            </Box>
        </Box>
    );

    return (
        <>
            <Fragment>
                <Box className={containerStyle}>
                    <ConfirmDialog
                        open={confirmStatusDialog}
                        question={
                            <ServiceActionHeaderMobile
                                name={props.name}
                                action={'Activate'}
                                subtitle={'activate'}
                            />
                        }
                        isSuccess={true}
                        confirmButtonLabel="Confirm"
                        cancelButtonLabel="Cancel"
                        onConfirm={() => {
                            !disabledActiveButton && onActiveClickHandler();
                            props.onCloseDialog && props.onCloseDialog();
                        }}
                        onCancel={() => {
                            setConfirmStatusDialog(false);
                            props.onCloseDialog && props.onCloseDialog();
                        }}
                    />

                    <ConfirmDialog
                        open={confirmStatusDialogDeactivate}
                        question={
                            <ServiceActionHeaderMobile
                                name={props.name}
                                action={'Deactivate'}
                                subtitle={'disable'}
                            />
                        }
                        isSuccess={false}
                        confirmButtonLabel="Confirm"
                        cancelButtonLabel="Cancel"
                        onConfirm={() => {
                            !disabledActiveButton && onActiveClickHandler();
                            props.onCloseDialog && props.onCloseDialog();
                        }}
                        onCancel={() => {
                            setConfirmStatusDialogDeactivate(false);
                            props.onCloseDialog && props.onCloseDialog();
                        }}
                    />
                </Box>
            </Fragment>

            {showActions && (
                <ActionsPopup
                    header={<ServiceActionHeader name={props.name} />}
                    actions={[
                        {
                            label: 'Edit',
                            icon: faPen,
                            onClick: onEditClickHandler
                        },
                        ...(props.statusId === Status.INACTIVE
                            ? [
                                  {
                                      label: 'Activate',
                                      icon: faCircleCheck,
                                      onClick: () => {
                                          setConfirmStatusDialog(true);
                                          setShowActions(false);
                                      }
                                  }
                              ]
                            : []),
                        ...(props.statusId === Status.ACTIVE
                            ? [
                                  {
                                      label: 'Deactivate',
                                      icon: faBan,
                                      onClick: () => {
                                          setConfirmStatusDialogDeactivate(
                                              true
                                          );
                                          setShowActions(false);
                                      }
                                  }
                              ]
                            : []),
                        ...(props.onDelete
                            ? [
                                  {
                                      label: 'Delete',
                                      icon: faTrash,
                                      onClick: onDeleteClickHandler
                                  }
                              ]
                            : [])
                    ]}
                    onClose={() => {
                        setShowActions(false);
                        props.onCloseDialog && props.onCloseDialog();
                    }}
                />
            )}
        </>
    );
};

export default RowToolbarMobile;
