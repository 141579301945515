import React, { FunctionComponent, MouseEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button as MuiButton } from '@material-ui/core';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/react';
import clsx from 'clsx';

interface Props {
	label: string;
	spinnerColor?: string;
	loading?: boolean;
	onClick: () => void;
	className?: string;
	id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paddings: {
			textTransform: 'none',
			[theme.breakpoints.down('md')]: {
				borderRadius: '21px',
				paddingTop: '5px',
				paddingBottom: '5px',
				paddingRight: '24px',
				paddingLeft: '24px'
			},
			[theme.breakpoints.only('lg')]: {
				borderRadius: '31px',
				paddingTop: '7px',
				paddingBottom: '7px',
				paddingRight: '32px',
				paddingLeft: '32px'
			},
			[theme.breakpoints.up('xl')]: {
				borderRadius: '31px',
				paddingTop: '8px',
				paddingBottom: '8px',
				paddingRight: '36px',
				paddingLeft: '36px'
			}
		},
		button: {
			'color': '#222222',
			'borderColor': '#222222',
			'backgroundColor': 'white',
			'borderWidth': '2px',
			[theme.breakpoints.down('sm')]: {
				width: '82px',
				height: '31px'
			},
			[theme.breakpoints.only('md')]: {
				width: '82px',
				height: '31px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '95px',
				height: '31px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '143px',
				height: '47px'
			},
			'& .MuiButton-label': {
				fontWeight: 600,
				fontFamily: 'Poppins',
				whiteSpace: 'nowrap',
				[theme.breakpoints.down('sm')]: {
					fontSize: '12px',
					lineHeight: '14px'
				},
				[theme.breakpoints.only('md')]: {
					fontSize: '12px',
					lineHeight: '18px'
				},
				[theme.breakpoints.only('lg')]: {
					fontSize: '14px',
					lineHeight: '22px'
				},
				[theme.breakpoints.only('xl')]: {
					fontSize: '16px',
					lineHeight: '27px'
				}
			},

			[theme.breakpoints.up('md')]: {
				'&:hover': {
					backgroundColor: 'white',
					borderColor: '#222222'
				}
			}
		},
		loading: {
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			[theme.breakpoints.up('md')]: {
				'&:hover': {
					color: 'rgba(255,255,255,0)'
				}
			}
		},
		spinnerContainer: {
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 2,
			width: '100%',
			height: '100%'
		}
	})
);

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

export const StatusButton: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const clickHandler = (event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();
		if (!props.loading) {
			props.onClick();
		}
	};

	const spinner = (
		<Box className={classes.spinnerContainer}>
			<PulseLoader
				color={props.spinnerColor || 'white'}
				loading={props.loading}
				css={override}
				size={5}
			/>
		</Box>
	);

	return (
		<MuiButton
			id={`${props.id || ''}_${props.label.replaceAll(' ', '_')}_button`}
			variant="outlined"
			onClick={clickHandler}
			className={clsx(classes.paddings, classes.button, props.className, {
				[classes.loading]: props.loading || false
			})}
			disableRipple={true}
			disableTouchRipple={true}
			disableElevation={true}
		>
			{props.label}
			{props.loading && spinner}
		</MuiButton>
	);
};

export default StatusButton;
