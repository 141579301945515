import { Action } from "redux";
import ProductVendor from "model/ProductVendor";

//Action Types
export const PRODUCT_VENDORS_RESET_ACTION_TYPE = "product_vendors/reset";
export const PRODUCT_VENDORS_FETCH_START_ACTION_TYPE = "product_vendors/fetch_start";
export const PRODUCT_VENDORS_FETCH_SUCCESS_ACTION_TYPE = "product_vendors/fetch_success";
export const PRODUCT_VENDORS_GET_START_ACTION_TYPE = "product_vendors/get_start";
export const PRODUCT_VENDORS_GET_SUCCESS_ACTION_TYPE = "product_cvendorsget_success";
export const PRODUCT_VENDORS_SAVE_START_ACTION_TYPE = "product_vendors/save_start";
export const PRODUCT_VENDORS_SAVE_SUCCESS_ACTION_TYPE = "product_vendors/save_success";
export const PRODUCT_VENDORS_SAVE_PRODUCTS_START_ACTION_TYPE = "product_vendors/save_products_start";
export const PRODUCT_VENDORS_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE = "product_vendors/save_products_success";
export const PRODUCT_VENDORS_DELETE_PRODUCTS_START_ACTION_TYPE = "product_vendors/delete_products_start";
export const PRODUCT_VENDORS_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE = "product_vendors/delete_products_success";
export const PRODUCT_VENDORS_DELETE_START_ACTION_TYPE = "product_vendors/delete_start";
export const PRODUCT_VENDORS_DELETE_SUCCESS_ACTION_TYPE = "product_vendors/delete_success";
export const PRODUCT_VENDORS_ERROR_ACTION_TYPE = "product_vendors/error";

//Actions
interface ProductVendorsResetAction
  extends Action<typeof PRODUCT_VENDORS_RESET_ACTION_TYPE> { }

interface ProductVendorsFetchStartAction
  extends Action<typeof PRODUCT_VENDORS_FETCH_START_ACTION_TYPE> { }

interface ProductVendorsFetchSuccessAction
  extends Action<typeof PRODUCT_VENDORS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    vendors  : Array<ProductVendor>;
  };
}

interface ProductVendorsGetStartAction
  extends Action<typeof PRODUCT_VENDORS_GET_START_ACTION_TYPE> { }

interface ProductVendorsGetSuccessAction
  extends Action<typeof PRODUCT_VENDORS_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    vendor: ProductVendor;
  };
}
interface ProductVendorsSaveStartAction
  extends Action<typeof PRODUCT_VENDORS_SAVE_START_ACTION_TYPE> { }

interface ProductVendorsSaveSuccessAction
  extends Action<typeof PRODUCT_VENDORS_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    vendor: ProductVendor;
  }
}

interface ProductVendorsSaveProductsStartAction
  extends Action<typeof PRODUCT_VENDORS_SAVE_PRODUCTS_START_ACTION_TYPE> { }

interface ProductVendorsSaveProductsSuccessAction
  extends Action<typeof PRODUCT_VENDORS_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE> {
  payload: {
    vendor: ProductVendor;
  }
}

interface ProductVendorsDeleteProductsStartAction
  extends Action<typeof PRODUCT_VENDORS_DELETE_PRODUCTS_START_ACTION_TYPE> { }

interface ProductVendorsDeleteProductsSuccessAction
  extends Action<typeof PRODUCT_VENDORS_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE> {
  payload: {
    vendor: ProductVendor;
  }
}

interface ProductVendorsDeleteStartAction
  extends Action<typeof PRODUCT_VENDORS_DELETE_START_ACTION_TYPE> { }

interface ProductVendorsDeleteSuccessAction
  extends Action<typeof PRODUCT_VENDORS_DELETE_SUCCESS_ACTION_TYPE> {
    payload: {
      vendorUuid: string;
    }
}

interface ProductVendorsErrorAction extends Action<typeof PRODUCT_VENDORS_ERROR_ACTION_TYPE> { }

export type ProductVendorsAction =
  ProductVendorsResetAction
  | ProductVendorsFetchStartAction
  | ProductVendorsFetchSuccessAction
  | ProductVendorsGetStartAction
  | ProductVendorsGetSuccessAction
  | ProductVendorsSaveStartAction
  | ProductVendorsSaveSuccessAction
  | ProductVendorsSaveProductsStartAction
  | ProductVendorsSaveProductsSuccessAction
  | ProductVendorsDeleteProductsStartAction
  | ProductVendorsDeleteProductsSuccessAction
  | ProductVendorsDeleteStartAction
  | ProductVendorsDeleteSuccessAction
  | ProductVendorsErrorAction;
