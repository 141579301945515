import { FunctionComponent } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';

interface Props {
    message: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center'
        },
        title: {
            margin: 0,
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 25
        },
        iphone: {
            width: 260,
            height: 520,
            display: 'block',
            margin: '0px auto',
            padding: '40px 13px 15px',

            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(/images/marketing/iphone-mockup.svg)'
        },
        wrapper: {
            width: '100%',
            height: '100%',
            borderRadius: 30,
            overflow: 'hidden',
            backgroundColor: 'rgba(255,255,255,0.3)'
        },
        scroll: {
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            padding: '30px 20px 30px 15px'
        },
        msg: {
            padding: 20,
            width: '100%',
            minHeight: 110,
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.2,
            borderRadius: 14,
            marginBottom: 15,
            textAlign: 'left',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            backgroundColor: '#F8F5F1'
        }
    })
);

const PreviewMessage: FunctionComponent<Props> = ({ message }) => {
    const classes = useStyles();

    const messages = (): RegExpMatchArray | string[] => {
        return message.match(/[\s\S]{1,160}/g) || [];
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>Preview Message</Typography>

            <Box className={classes.iphone}>
                <Box className={classes.wrapper}>
                    <Box className={classes.scroll}>
                        {messages().map((msg, i) => (
                            <Box key={i} className={classes.msg}>
                                {msg.trim()}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewMessage;
