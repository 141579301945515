import React, { useState } from 'react';
import { SearchComponent } from 'components/UI';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			borderRadius: 12,
			padding: '12px 11px',
			backgroundColor: '#F1F1F1',

			[theme.breakpoints.up('md')]: {
				padding: '15px 20px'
			}
		},
		field: {
			'height': 47,
			'width': '100%',

			[theme.breakpoints.up('md')]: {
				width: 400
			},

			'& .MuiInputBase': {
				'&-root': {
					backgroundColor: '#fff'
				},
				'&-input': {
					fontSize: 14,

					[theme.breakpoints.up('md')]: {
						fontSize: 16
					}
				}
			},
			'& .MuiInputAdornment-root': {
				'pointer-events': 'none',

				'& svg': {
					fontSize: 18,
					cursor: 'default'
				}
			}
		}
	})
);

interface HeaderSearchableSearchProps {
	placeholder: string;
	onSearch: (text: string) => void;
	id?: string;
}

const HeaderSearchableSearch: React.FunctionComponent<
	HeaderSearchableSearchProps
> = props => {
	const classes = useStyles();

	const [mounted, setMounted] = useState(false);

	const onSearchHandler = (searchTerm: string) => {
		if (!mounted) {
			setMounted(true);
			return;
		}

		props.onSearch(searchTerm);
	};

	return (
		<Box className={classes.box}>
			<SearchComponent
				id={props.id}
				open
				className={classes.field}
				placeholder={props.placeholder}
				onSearch={onSearchHandler}
			/>
		</Box>
	);
};

export default HeaderSearchableSearch;
