import React, { FunctionComponent } from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Chip from "./Chip";
import { StaffMember } from "model/Service";

interface Props {
  staff: StaffMember;
  fullName?: boolean;
  cursorPointer?: boolean;
  fullWidth?: boolean;
  onDelete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: (props: Props) => ({
      [theme.breakpoints.down("md")]: {
        height: "5px !important",
        width: "5px !important",
      },
      [theme.breakpoints.only("lg")]: {
        height: "6px !important",
        width: "6px !important",
      },
      [theme.breakpoints.only("xl")]: {
        height: "10px !important",
        width: "10px !important",
      },
    }),
  })
);

export const StaffChip: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const avatar = <Avatar src={props.staff.avatar} className={classes.avatar} />;

  const label = props.fullName
    ? `${props.staff.firstName} ${props.staff.lastName}`
    : props.staff.firstName;

  return <Chip avatar={avatar} label={label} onDelete={props.onDelete} />;
};

export default StaffChip;
