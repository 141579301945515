import { FieldError, Option } from '@spike/model';
import SelectorFieldSearch from './SelectorFieldSearch';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import AddOnService from '@spike/addon-service-model';
import { fetchThunk as fetchAddOnServicesThunk } from '@spike/addon-services-action';
import { useApiClientWrapper } from 'hooks';

interface Props {
	name?: string;
	excludedIds?: Array<number>;
	errors?: Array<FieldError>;
	className?: string;
	onSelect?: (service: AddOnService | undefined) => void;
}

export const AddOnSelector: FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const addOnServices = useSelector<RootState, Array<AddOnService>>(
		state => state.addOnServices.services
	);

	const options = addOnServices
		.filter(addOnService => addOnService.active)
		.filter(
			addOnService =>
				props.excludedIds === undefined ||
				!props.excludedIds.includes(addOnService.id!)
		)
		.map(addOnService => ({
			id: addOnService.id!,
			name: addOnService.name
		}));

	useEffect(() => {
		dispatch(fetchAddOnServicesThunk(apiClientWrapper));
	}, []);

	const selectHandler = (option: Option<string | number> | undefined) => {
		props.onSelect &&
			props.onSelect(
				option === undefined
					? undefined
					: addOnServices.find(
							addOnService => addOnService.id === option.id
					  )!
			);
	};

	return (
		<SelectorFieldSearch
			{...props}
			options={options}
			placeholder="Search add-on"
			className={props.className}
			onSelect={selectHandler}
		/>
	);
};

export default AddOnSelector;
