import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      fontWeight: 500,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "16px",
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "20px",
        fontSize: "16px",
      },
    },
    row: {
      borderBottom: "2px solid #F1F1F1",
    },
  })
);

export const TableHeader: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.row}>
        <TableCell></TableCell>
        <TableCell className={classes.cell}>COMPANY</TableCell>
        <TableCell className={classes.cell}>CONTACT</TableCell>
        <TableCell className={classes.cell}>PHONE NUMBER</TableCell>
        <TableCell className={classes.cell}>EMAIL</TableCell>
        <TableCell className={classes.cell}>ADDRESS</TableCell>
        <TableCell className={classes.cell}>PRICE</TableCell>
      </TableRow>
    </TableHead>

  );
};

export default TableHeader;
