import React, { FunctionComponent, useRef, useState } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import Picker, { IEmojiData, SKIN_TONE_LIGHT } from 'emoji-picker-react';

interface MessageEditorProps {
    error?: boolean;
    message?: string;
    onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'width': '100%',
            'borderRadius': 18,
            'backgroundColor': '#fff',
            'border': '1px solid #D3D3D3',
            'transition': 'border-color 0.1s ease-out',

            '&.focus': {
                borderColor: '#222'
            },
            '&.error': {
                borderColor: '#C14A4A'
            }
        },
        body: {
            overflow: 'hidden',
            borderRadius: '18px 18px 0px 0px'
        },
        textarea: {
            border: 0,
            height: 165,
            width: '100%',
            resize: 'none',
            padding: '18px 20px',
            outline: '0px !important',

            fontSize: 16,
            lineHeight: 1.7,
            fontWeight: 400,
            color: '#222222',
            fontFamily: 'Poppins'
        },
        footer: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            padding: '11px 20px',
            justifyContent: 'space-between',
            borderTop: '1px solid #D3D3D3'
        },
        counter: {
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 500,
            color: '#7A7A7A'
        },
        emojiButton: {
            fontSize: 20,
            color: '#222',
            border: 'none',
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            padding: '0px 5px'
        },
        emojiPicker: {
            top: '95%',
            left: '20px',
            zIndex: 100,
            position: 'absolute'
        }
    })
);

const MessageEditor: FunctionComponent<MessageEditorProps> = props => {
    const classes = useStyles();

    const textarea = useRef<HTMLTextAreaElement>(null);

    const [focus, setFocus] = useState<boolean>(false);
    const [showPicker, setShowPicker] = useState<boolean>(false);

    const messageInfo = () => {
        if (!props.message) return `${0}/160 (1 message)`;

        if (props.message.length <= 160)
            return `${props.message.length}/160 (1 message)`;

        if (props.message.length > 160) {
            const messageLengthOver = props.message.length - 160;
            const messagesCount = Math.ceil(props.message.length / 160);

            return `${messageLengthOver}/160 (${messagesCount} messages)`;
        }
    };

    const onEmojiClick = (e: React.MouseEvent, emojiObject: IEmojiData) => {
        setShowPicker(false);
        if (textarea.current) {
            const newMessage = props.message + emojiObject?.emoji;
            textarea.current.value = newMessage;
            if (props.onChange) {
                props.onChange(newMessage);
            }
        }
    };

    return (
        <Box className={clsx(classes.root, { focus, error: props.error })}>
            <Box className={classes.body}>
                <textarea
                    ref={textarea}
                    defaultValue={props.message}
                    className={classes.textarea}
                    onBlur={() => setFocus(false)}
                    onFocus={() => setFocus(true)}
                    onChange={($e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        if (props.onChange) {
                            props.onChange($e.currentTarget.value);
                        }
                    }}
                ></textarea>
            </Box>
            <Box className={classes.footer}>
                <button
                    className={classes.emojiButton}
                    onClick={() => setShowPicker(!showPicker)}
                >
                    <FontAwesomeIcon icon={faSmile} />
                </button>

                {showPicker && (
                    <Box className={classes.emojiPicker}>
                        <Picker
                            onEmojiClick={onEmojiClick}
                            disableAutoFocus={true}
                            skinTone={SKIN_TONE_LIGHT}
                            groupNames={{ smileys_people: 'PEOPLE' }}
                            native
                        />
                    </Box>
                )}

                <span className={classes.counter}>{messageInfo()}</span>
            </Box>
        </Box>
    );
};

export default MessageEditor;
