import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { PetType, PetTypeIds } from '@spike/model';
import { FunctionComponent } from 'react';
import { uniquePetTypeIconMap } from '../model';
import clsx from 'clsx';

interface PetTypeUniqueItemProps {
    petType: PetType;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        height: 65,
        width: 65,
        [theme.breakpoints.down('md')]: {
            height: 40,
            width: 40
        }
    },
    icon: {
        fontSize: 25,
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    },
    cat: {
        backgroundColor: theme.palette.primary.main
    },
    dog: {
        backgroundColor: '#92B4A7'
    }
}));

const PetTypeUniqueItem: FunctionComponent<PetTypeUniqueItemProps> = props => {
    const classes = useStyles();

    return (
        <Box
            className={clsx([
                classes.container,
                {
                    [classes.dog]: props.petType.id === PetTypeIds.DOGS,
                    [classes.cat]: props.petType.id === PetTypeIds.CATS
                }
            ])}
        >
            <FontAwesomeIcon icon={uniquePetTypeIconMap.get(props.petType.id)!} className={classes.icon} />
        </Box>
    );
};

export default PetTypeUniqueItem;
