import React, {
    useState,
    useEffect,
    FunctionComponent,
    useRef,
    ChangeEvent,
    Fragment
} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import Pet from '@spike/pet-model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMasterData } from 'hooks';
import { faCamera } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { CropperImage } from 'components/UI';
import { wbp, reduceResolution } from 'Theme';
import { ImageUriUpload } from '@spike/model';
import ImageMenu from './ImageMenu';

interface ImageUploadProps {
    imageUrl?: string;
    onChangeImage: (
        image: string,
        imageFileToUpload: File | undefined,
        imageRemoved: boolean | undefined
    ) => void;
    className?: string;
    disableEdition?: boolean;
    squared?: boolean;
    small?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            position: 'relative',
            width: (props: ImageUploadProps) =>
                props.small ? '150px' : '200px',
            height: (props: ImageUploadProps) =>
                props.small ? '150px' : '200px',
            [theme.breakpoints.down('md')]: {
                width: '100px !important',
                height: '100px !important'
            },
            [theme.breakpoints.down('sm')]: {
                width: '75px !important',
                height: '75px !important'
            }
        },

        imageBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundImage: (props: ImageUploadProps) =>
                'url(' + props.imageUrl + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: '50%',
            textAlign: 'center',
            position: 'relative',
            width: '100%',
            height: '100%'
        },

        image: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundImage: (props: ImageUploadProps) =>
                'url(' + props.imageUrl + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: '50%',
            textAlign: 'center',
            position: 'relative',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(174)}px`,
                height: `${reduceResolution(174)}px`,
                marginRight: `${reduceResolution(44)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '174px',
                height: '174px',
                marginRight: '44px'
            }
        },
        edit: {
            cursor: 'pointer',
            fontWeight: 600,
            textDecoration: 'underline',
            color: '#fff',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },
        emptyImage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#F8F5F1',
            borderRadius: '50%',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(174)}px`,
                height: `${reduceResolution(174)}px`,
                marginRight: `${reduceResolution(44)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '174px',
                height: '174px',
                marginRight: '44px'
            }
        },

        imageText: {
            fontWeight: 600,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginBottom: `${reduceResolution(9)}px`,
                paddingRight: `${reduceResolution(12)}px`,
                paddingLeft: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                marginBottom: '9px',
                paddingRight: '12px',
                paddingLeft: '12px'
            }
        },

        uploadIcon: {
            color: '#5E8677',
            [theme.breakpoints.down(wbp)]: {
                fontSize: (props: ImageUploadProps) =>
                    props.small
                        ? `${reduceResolution(34)}px`
                        : `${reduceResolution(64)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: (props: ImageUploadProps) =>
                    props.small ? '34px' : '64px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '30px !important'
            }
        },

        iconFace: {
            marginRight: '5px',
            [theme.breakpoints.up(wbp)]: {
                marginTop: '5px'
            }
        },
        itemTextEmpty: {
            color: '#D3D3D3'
        },

        warningBox: {
            backgroundColor: '#E9F0ED',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            borderRadius: '50%',
            textAlign: 'center'
        },

        imageBoxWithOpacity: {
            filter: 'brightness(0.8)'
        },

        sizeText: {
            fontWeight: 600,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '13px'
            }
        },

        editOptions: {
            left: '50%'
        },

        uploadTitle: {
            fontWeight: 500,
            textDecoration: 'underline',
            color: '#5E8677',
            cursor: 'pointer',

            [theme.breakpoints.down(wbp)]: {
                fontSize: (props: ImageUploadProps) =>
                    props.small
                        ? `${reduceResolution(16)}px`
                        : `${reduceResolution(20)}px`,
                lineHeight: `${reduceResolution(30)}px`,
                marginBottom: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: (props: ImageUploadProps) =>
                    props.small ? '16px' : '20px',
                lineHeight: '30px',
                marginBottom: '8px'
            }
        },

        uploadBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: '#E9F0ED',
            color: '#5E8677',
            [theme.breakpoints.down('md')]: {
                borderRadius: (props: ImageUploadProps) =>
                    props.squared ? '8px' : '50%'
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: (props: ImageUploadProps) =>
                    props.squared ? '10px' : '50%'
            }
        }
    })
);

export const ImageUpload: FunctionComponent<ImageUploadProps> = props => {
    const classes = useStyles(props);
    const masterData = useMasterData();

    const [hover, setHover] = useState(false);

    const [showEmptyImage, setEmptyImage] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const [selectedImage, setSelectedImage] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [showCropper, setShowCropper] = useState(false);

    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (
            props.imageUrl === undefined ||
            props.imageUrl === null ||
            isEmpty(props.imageUrl)
        ) {
            setEmptyImage(true);
        } else {
            setEmptyImage(false);
        }
    }, [props.imageUrl]);

    const imageSelectionHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];

        if (file.size / 1024 <= masterData.imagesMaxSizes.avatarKb) {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setSelectedImage(reader.result?.toString()!);
                    setFileName(file.name);
                    setShowCropper(true);
                }
            };
            reader.readAsDataURL(file);
        } else {
            setShowWarning(true);
        }
    };

    const changeImageHandler = () => {
        inputFileRef.current?.click();
        setShowMenu(false);
    };

    const removeImageHandler = () => {
        props.onChangeImage && props.onChangeImage('', undefined, true);
        setShowMenu(false);
        setEmptyImage(true);
    };

    const cropHandler = async (file: File) => {
        props.onChangeImage &&
            props.onChangeImage(URL.createObjectURL(file), file, false);
        setShowWarning(false);
        setEmptyImage(false);
        setShowCropper(false);
    };

    const warningMessage = (
        <Box>
            <Typography className={classes.imageText}>
                Image is too large.
            </Typography>
            <Typography className={classes.sizeText}>{`Max size: ${
                masterData.imagesMaxSizes.avatarKb / 1024
            }MB`}</Typography>
        </Box>
    );

    const emptyImage = (
        <Box
            className={clsx(classes.uploadBox, {
                [classes.warningBox]: showWarning
            })}
            onClick={changeImageHandler}
        >
            {hover && (
                <Typography className={clsx(classes.uploadTitle)}>
                    Upload
                </Typography>
            )}
            {!hover && !showWarning && (
                <FontAwesomeIcon
                    icon={faCamera}
                    className={clsx(classes.uploadIcon)}
                />
            )}
            {!hover && showWarning && warningMessage}
        </Box>
    );

    const image = (
        <Box
            className={clsx(classes.uploadBox, {
                [classes.warningBox]: showWarning,
                [classes.imageBox]: !showWarning,
                [classes.imageBoxWithOpacity]: !props.disableEdition && hover
            })}
        >
            {showWarning && warningMessage}
            {hover && !props.disableEdition && (
                <Typography
                    className={classes.edit}
                    onClick={() => setShowMenu(true)}
                >
                    Edit
                </Typography>
            )}
        </Box>
    );

    return (
        <Fragment>
            <Box className={props.className}>
                <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={inputFileRef}
                    onChange={event => imageSelectionHandler(event)}
                    accept=".png, .jpg, .jpeg"
                    value=""
                />
                <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                    <Box
                        className={clsx(classes.imageContainer, 'imageWrapper')}
                        onMouseLeave={() => setHover(false)}
                        onMouseOver={() => setHover(true)}
                    >
                        {showEmptyImage && emptyImage}
                        {!showEmptyImage && image}

                        {showMenu && !props.disableEdition && (
                            <ImageMenu
                                onChange={changeImageHandler}
                                onRemove={removeImageHandler}
                                onClose={() => setShowMenu(false)}
                                className={classes.editOptions}
                            />
                        )}
                    </Box>
                </ClickAwayListener>
            </Box>
            {showCropper && (
                <CropperImage
                    image={selectedImage!}
                    fileName={fileName!}
                    cropWidth={256}
                    cropHeight={256}
                    onClose={() => setShowCropper(false)}
                    onCrop={cropHandler}
                />
            )}
        </Fragment>
    );
};

export default ImageUpload;
