import React, { FunctionComponent, Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ResumeSection, { BodyResumeSection } from "../ResumeSection";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Service } from "model/Service";
import StaffChip  from "components/UI/StaffChip";
import Staff from "model/Staff";

interface Props {
  service: Service;
  withoutStaff?: boolean;
  onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap"
    },
    titleContainer: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "14px",
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
      },
    },
    title: {
      textTransform: "uppercase",
      [theme.breakpoints.down("lg")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("md")]: {
        textTransform: "unset",
        fontSize: "14px",
        color: "#7A7A7A",
        fontWeight: "400"
      },
    },
    titleWithoutStaff:{
      [theme.breakpoints.down("md")]: {
        marginBottom: "-5px"
      },
    },
    memberContainer: {
      [theme.breakpoints.down("lg")]: {
        marginRight: "15px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "20px",
        marginBottom: "16px",
      },
      [theme.breakpoints.down("md")]: {
        //width: "calc(50% - 8px)",
        width: "auto",
        //marginRight: "0px",
        marginRight: "12px",
        marginleft: "0px",
        marginBottom: "12px",
        "&:nth-child(2n)": {
          //width: "calc(50% - 8px)",
          width: "auto",
          marginRight: "12px",
          marginLeft: "0px",
        },
        "& > div": {
          width: "100%",
          maxWidth: "100%",
        },
        "& .MuiChip-label":{
          fontWeight: "500",
          fontSize: "14px",
        }
      },
    },
    noneStaffTitle: {
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },
    },
    noneStaffDescription: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px",
      },
    }
  })
);

export const ResumeServiceStaff: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const existActiveStaff = useSelector<RootState, Array<Staff>>(state => state.staff.staff).length > 0;

  const members = props.service.staff.map(member => (
    <Box key={member.id} className={classes.memberContainer}>
      <StaffChip staff={member}/>
    </Box>
  ));

  const staff = (<Fragment>
    <Box className={`${classes.titleContainer} ${props.service.staff.length < 1 ? classes.titleWithoutStaff : ''}`}>
      <Typography className={`${classes.title}`}>
        {props.service.staff.length > 0 ? "Staff Members" : "Staff not assigned"}
      </Typography>
    </Box>
    {members}
  </Fragment>);

  const noneStaff = (<Fragment>
    <Box className={classes.titleContainer}>
      <Typography className={classes.noneStaffTitle}>Pending.</Typography>
    </Box>
    <Box>
      <Typography className={classes.noneStaffDescription}>No Staff have been created yet.</Typography>
    </Box>
  </Fragment>);

  return (
    <ResumeSection title="Staff" {...props} pending={!existActiveStaff}>
      <BodyResumeSection>
        <Box className={classes.container}>
          {!existActiveStaff ? noneStaff : staff}
        </Box>
      </BodyResumeSection>
    </ResumeSection>
  );
};

export default ResumeServiceStaff;
