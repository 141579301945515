import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMasterData } from 'hooks';
import { SelectableOption } from 'model';
import { Option, PetType } from '@spike/model';
import { DentalReportAbnormality } from 'model/DentalReport';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import ToothInput from './ToothInput';
import clsx from 'clsx';

interface Props {
	petType: PetType;
	toothAbnormalities: Array<DentalReportAbnormality>;
	isDentalReport: boolean;
	multipleSelection?: boolean;
	onChangeSelection: (
		toothAbnormalities: Array<DentalReportAbnormality>
	) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		problemsContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		problemsContainerDisabled: {
			'display': 'flex',
			'flexDirection': 'column',
			'pointerEvents': 'none',

			'& .MuiInputBase-input': {
				display: 'none !important'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		addCondition: {
			'color': '#92B4A7',
			'marginTop': 12,

			'&:hover': {
				cursor: 'pointer'
			},

			'& p': {
				fontSize: 14,
				lineHeight: 1,
				fontWeight: 500,

				[theme.breakpoints.up('xl')]: {
					fontSize: '16px',
					marginBottom: '42px'
				}
			},

			[theme.breakpoints.up('md')]: {
				fontSize: 12,
				fontWeight: 400,
				marginBottom: '34px',
				color: '#5E8677'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				marginBottom: '42px'
			}
		},
		icon: {
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '40px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '53px'
			}
		},
		condContainer: {
			top: 30,
			zIndex: 100,
			width: '100%',
			height: 'auto',
			background: '#fff',
			padding: '24px 16px',
			position: 'absolute',
			borderRadius: '20px',
			border: '1px solid #000',

			[theme.breakpoints.up('md')]: {
				padding: 25,
				width: '500px'
			}
		},
		conditionsContainer: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		conditionsEditablesContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				marginLeft: 6
			}
		},
		conditionEditableContainer: {
			'width': '100%',
			'display': 'flex',

			'&:not(:last-child)': {
				marginBottom: 16
			},

			[theme.breakpoints.down('sm')]: {
				gap: 8,
				flexWrap: 'wrap',
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				marginBottom: 24
			}
		},
		conditionContainer: {
			'height': 40,
			'padding': '12px 20px',
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#F0F4F2',
			'borderRadius': 30,

			'&:hover': {
				cursor: 'pointer'
			},

			[theme.breakpoints.up('xl')]: {
				height: '45px'
			}
		},
		conditionSelectedContainer: {
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff'
			}
		},
		label: {
			fontSize: 16,
			lineHeight: 1,
			display: 'flex',
			cursor: 'pointer',

			[theme.breakpoints.up('md')]: {
				fontSize: 12
			}
		},
		arrowContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		conditionsEditablesLabel: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px',
				width: '225px',
				paddingTop: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px',
				width: '300px',
				paddingTop: '16px'
			}
		},
		teethsContainer: {
			gap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		}
	})
);

export const ToothAbnormalities: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();
	const [showConditions, setShowConditions] = useState(false);
	const [showConditionsEditables, setShowConditionsEditables] =
		useState(true);
	const [toothValue, setToothValue] = useState('');

	const [toothAbnormalities, setToothAbnormalities] = useState<
		Array<SelectableOption<DentalReportAbnormality>>
	>([]);

	const handleSelectToothAbnormality = (
		value: DentalReportAbnormality,
		selected: boolean
	) => {
		const newToothAbnormalities = [...toothAbnormalities];

		newToothAbnormalities.forEach(toothAbn => {
			toothAbn.selected =
				toothAbn.element.id.abnormality === value.abnormality
					? selected
					: props.multipleSelection
					? toothAbn.selected
					: false;
		});

		setToothAbnormalities(newToothAbnormalities);
		props.onChangeSelection(
			newToothAbnormalities
				.filter(toothAbn => toothAbn.selected)
				.map(toothAbn => toothAbn.element.id)
		);
	};

	const handleAddConditions = () => {
		setShowConditions(true);
		setShowConditionsEditables(false);
	};

	const handleClickNext = () => {
		setShowConditions(false);
		setShowConditionsEditables(true);
	};

	useEffect(() => {
		setToothValue(toothValue);
	}, [toothValue]);

	useEffect(() => {
		setToothAbnormalities(toothAbnormalities);
	}, [toothAbnormalities]);

	const handleChangeTeeth = (
		teeth: Array<number>,
		allTeeth: boolean,
		toothAb: DentalReportAbnormality
	) => {
		const newToothAbnormalities = [...toothAbnormalities];

		newToothAbnormalities.forEach(toothAbn => {
			toothAbn.element.id.abnormality.id === toothAb.abnormality.id
				? (toothAbn.element.id = {
						abnormality: toothAb.abnormality,
						teeth,
						allTeeth
				  })
				: // eslint-disable-next-line no-self-assign
				  (toothAbn.element.id = toothAbn.element.id);
		});

		setToothAbnormalities(newToothAbnormalities);

		props.onChangeSelection(
			newToothAbnormalities
				.filter(toothAbn => toothAbn.selected)
				.map(thabn =>
					thabn.element.id.abnormality.id === toothAb.abnormality.id
						? {
								abnormality: thabn.element.id.abnormality,
								teeth,
								allTeeth
						  }
						: thabn.element.id
				)
		);
	};

	const searchTeeth = (toothAbs: Option<number>) => {
		const toothAbnomalityAux = props.toothAbnormalities.find(
			toothAb => toothAb.abnormality.id === toothAbs.id
		)!;
		if (isEmpty(toothAbnomalityAux)) {
			return [];
		} else {
			return toothAbnomalityAux.teeth;
		}
	};

	useEffect(() => {
		const toothAbnormalitiesSelectable =
			masterData.dentalReportOptions.toothAbnormalities.map(
				toothAbnormalities => {
					const tAbnAux = props.toothAbnormalities.find(
						t => t.abnormality.name === toothAbnormalities.name
					)!;
					return {
						element: {
							id: {
								abnormality: toothAbnormalities,
								allTeeth: tAbnAux?.allTeeth,
								teeth: searchTeeth(toothAbnormalities)
							},
							name: ''
						},
						selected: props.toothAbnormalities.find(
							toothAb =>
								toothAb.abnormality.id === toothAbnormalities.id
						)
							? true
							: false
					};
				}
			);
		setToothAbnormalities(toothAbnormalitiesSelectable);
	}, [props.toothAbnormalities]);

	return (
		<>
			<Box className={classes.container}>
				{(props.isDentalReport ||
					(!isEmpty(props.toothAbnormalities) &&
						!props.isDentalReport)) && (
					<Typography className={classes.titleSection}>
						Tooth Abnormalities
					</Typography>
				)}
				<Box className={classes.problemsContainer}>
					{props.isDentalReport && showConditions && (
						<Box className={classes.condContainer}>
							<Box className={classes.conditionsContainer}>
								{toothAbnormalities.map(
									(toothAbnormality, index) => {
										return (
											<Box
												key={index}
												className={clsx(
													classes.conditionContainer,
													{
														[classes.conditionSelectedContainer]:
															toothAbnormality.selected
													}
												)}
											>
												<Typography
													className={classes.label}
													onClick={() =>
														handleSelectToothAbnormality(
															toothAbnormality
																.element.id,
															!toothAbnormality.selected
														)
													}
												>
													{
														toothAbnormality.element
															.id.abnormality.name
													}
												</Typography>
											</Box>
										);
									}
								)}
							</Box>
							<Box className={classes.arrowContainer}>
								<FontAwesomeIcon
									icon={faArrowCircleRight}
									className={classes.icon}
									onClick={handleClickNext}
								/>
							</Box>
						</Box>
					)}
					{showConditionsEditables && (
						<Box
							className={
								props.isDentalReport
									? classes.problemsContainer
									: classes.problemsContainerDisabled
							}
						>
							<Box
								className={classes.conditionsEditablesContainer}
							>
								{toothAbnormalities.map(
									(toothAbnormality, index) => {
										if (toothAbnormality.selected) {
											return (
												<ToothInput
													key={index}
													label={
														toothAbnormality.element
															.id.abnormality.name
													}
													allTeeth={
														toothAbnormality.element
															.id.allTeeth
															? toothAbnormality
																	.element.id
																	.allTeeth
															: false
													}
													isDentalReport={
														props.isDentalReport
													}
													teeth={
														toothAbnormality.element
															.id.teeth
													}
													petType={props.petType}
													onChangeTeeth={(
														teeth,
														allTeeth
													) =>
														handleChangeTeeth(
															teeth,
															allTeeth,
															toothAbnormality
																.element.id
														)
													}
												/>
											);
										} else {
											return <></>;
										}
									}
								)}
							</Box>
						</Box>
					)}
					{props.isDentalReport && (
						<Box className={classes.addCondition}>
							<Typography
								onClick={handleAddConditions}
								style={{ fontWeight: 600 }}
							>
								+ Add Condition
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};

export default ToothAbnormalities;
