import { Agreement, AgreementStatus, AgreementSummary, Signature, SignatureClient } from "model/Agreements";
import moment from "moment";
import store from "store";
import { AgreementDto, AgreementSignatureDto, SignatureCustomerDto, SummaryDto } from "./AgreementsDtos";

export const convertToAgreement = (dto: AgreementDto): Agreement => {
  const timeZone = store.getState().login.auth.timeZone;

  return {
    active: dto.active,
    content: dto.content,
    createdAt: moment(dto.created_at).tz(timeZone!),
    id: dto.id,
    mandatory: dto.mandatory,
    marketplaceId: dto.marketplace_id,
    sendTo: dto.send_to,
    title: dto.title,
    updatedAt: moment(dto.updated_at).tz(timeZone!),
    uuid: dto.uuid,
    client: dto.client,
    channel: dto.channel,
    required: dto.required,
    signedAt: dto.signed_at ? moment(dto.signed_at).tz(timeZone!) : undefined,
    status: dto.status as AgreementStatus,
  };
};

export const convertToSignature = (dto: AgreementSignatureDto): Signature => {
  const timeZone = store.getState().login.auth.timeZone!;

  return {
    id: dto.id,
    uuid: dto.uuid,
    marketplaceId: dto.marketplace_id,
    agreementId: dto.agreement_id,
    createdAt: moment(dto.created_at).tz(timeZone),
    delivered: dto.delivered,
    email: dto.email,
    secureHash: dto.secure_hash,
    sentAt: dto.sent_at ? moment(dto.sent_at).tz(timeZone) : undefined,
    signedAt: dto.signed_at ? moment(dto.signed_at).tz(timeZone) : undefined,
    content: dto.signed_content,
    title: dto.signed_title,
    sms: dto.sms,
    updatedAt: moment(dto.updated_at).tz(timeZone),
    client: convertToSignatureClient(dto.customer),
    status: dto.signed_at ? "signed" : "unsigned",
  };
};

export const convertToSignatureClient = (dto: SignatureCustomerDto): SignatureClient => {
  return {
    id: dto.id,
    uuid: dto.uuid,
    firstName: dto.first_name,
    lastName: dto.last_name,
  };
};

export const convertToSummary = (dto: SummaryDto): AgreementSummary => {
  return {
    agreement: convertToAgreement(dto.agreement),
    sent: dto.sent,
    signed: dto.signed,
    unsigned: dto.unsigned,
  };
};
