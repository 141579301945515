import { NewFeaturesState, NewFeaturesStatus } from "./NewFeaturesState";
import {
  NEW_FEATURES_RESET_ACTION_TYPE,
  NEW_FEATURES_FETCH_START_ACTION_TYPE,
  NEW_FEATURES_FETCH_SUCCESS_ACTION_TYPE,
  NEW_FEATURES_MARK_AS_READ_START_ACTION_TYPE,
  NEW_FEATURES_MARK_AS_READ_SUCCESS_ACTION_TYPE,
  NEW_FEATURES_ERROR_ACTION_TYPE,
  NewFeaturesAction,
} from "actions/newFeatures/NewFeaturesActionsTypes";
import { Reducer } from "redux";

const initialState: NewFeaturesState = {
  newFeatures: [],
  loading: false,
  status: NewFeaturesStatus.Initial,
};

export const newFeaturesReducer: Reducer<NewFeaturesState, NewFeaturesAction> = (state = initialState, action) => {
  switch (action.type) {
    case NEW_FEATURES_RESET_ACTION_TYPE:
      return { ...initialState };
    case NEW_FEATURES_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        newFeatures: [],
        loading: true,
        status: NewFeaturesStatus.Fetching,
      };
    case NEW_FEATURES_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        newFeatures: [...action.payload.newFeatures],
        loading: false,
        status: NewFeaturesStatus.FetchSuccess,
      };
    case NEW_FEATURES_MARK_AS_READ_START_ACTION_TYPE:
      return {
        ...state,
        loading: true,
        status: NewFeaturesStatus.MarkingAsRead,
      };
    case NEW_FEATURES_MARK_AS_READ_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        newFeatures: state.newFeatures.filter((newFeature) => !action.payload.readFeatureIds.includes(newFeature.id)),
        loading: false,
        status: NewFeaturesStatus.MarkAsReadSuccess,
      };
    case NEW_FEATURES_ERROR_ACTION_TYPE:
      return {
        ...state,
        newFeatures: [],
        loading: false,
        status: NewFeaturesStatus.Error,
      };
    default:
      return state;
  }
};

export default newFeaturesReducer;
