import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { useButtonStyles } from 'components/UI/V2/Button/Button.styles';
import PulseLoader from 'react-spinners/PulseLoader';

export type ButtonVariant = 'primary' | 'green' | 'black' | 'danger';

export type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
    label: string;
    size?: 'small' | 'medium' | 'large';
    variant?: ButtonVariant;
    isLoading?: boolean;
    leftIcon?: IconProp;
    rightIcon?: IconProp;
    className?: string;
};

const Button: React.FC<ButtonProps> = props => {
    const classes = useButtonStyles();

    const spinner = (
        <div className={classes.spinner}>
            <PulseLoader size={5} loading={true} color="#ffffff" />
        </div>
    );

    // extract props and define default values
    const { size = 'medium', variant = 'primary', label, leftIcon, rightIcon, ...buttonProps } = props;

    return (
        <button
            {...buttonProps}
            className={clsx(classes.root, props.className, {
                // Sizes
                [classes.small]: size === 'small',
                [classes.medium]: size === 'medium',
                [classes.large]: size === 'large',
                // States
                [classes.loading]: props.isLoading,
                // Variants
                [classes.primary]: variant === 'primary',
                [classes.green]: variant === 'green',
                [classes.black]: variant === 'black',
                [classes.danger]: variant === 'danger'
            })}
        >
            {leftIcon && <FontAwesomeIcon icon={leftIcon} />}

            {label}

            {rightIcon && <FontAwesomeIcon icon={rightIcon} />}

            {props.isLoading && spinner}
        </button>
    );
};

export default Button;
