import React, { FunctionComponent } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";

interface Props {
  label: string;
  fontColor?: string; // hex
  bgColor?: string; //hex
  hideIcon?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: (props: Props) => props.bgColor || "#F2D2A2",
      borderRadius: "2.91px",
      padding: "0px 4.36px",
      gap: "3.63px",
    },
    label: {
      color: (props: Props) => props.fontColor || "#222222",
      fontWeight: 500,
      letterSpacing: "0.76px",
      textTransform: "uppercase",
      textAlign: "center",
      fontStyle: "normal",
      fontSize: "11px",
    },
  })
);

const BadgeLabel: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.label}>{props.label}</Typography>
      {props.hideIcon !== true && <FontAwesomeIcon icon={faSparkles} className={classes.label} />}
    </Box>
  );
};

export default BadgeLabel;
