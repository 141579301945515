import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SingleChip } from 'components/UI';
import React, { FunctionComponent } from 'react';
import { statusTerminal } from '@spike/terminal-model';

interface TerminalStatusProps {
    status: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        default: {
            'backgroundColor': '#FFF8DD',
            'textTransform': 'capitalize',
            '& p': {
                color: '#C18733'
            },
            'width': 'fit-content'
        },

        ready: {
            'backgroundColor': '#5E8677',
            '& p': { color: '#FFF' }
        },

        inUse: {
            'backgroundColor': '#5E8677',
            '& p': { color: '#FFF' }
        },

        support: {
            'backgroundColor': '#C18733',
            '& p': { color: '#FFF' }
        },

        deleted: {
            'backgroundColor': '#EF4F57',
            '& p': { color: '#FFF' }
        },

        decomissioned: {
            'backgroundColor': '#E6E6E6',
            '& p': { color: '#000' }
        }
    })
);

export const TerminalStatus: FunctionComponent<TerminalStatusProps> = props => {
    const classes = useStyles();

    return (
        <SingleChip
            className={clsx(
                classes.default,
                { [classes.ready]: props.status === statusTerminal.READY },
                { [classes.inUse]: props.status === statusTerminal.IN_USE },
                { [classes.support]: props.status === statusTerminal.SUPPORT },
                { [classes.deleted]: props.status === statusTerminal.DELETED },
                {
                    [classes.decomissioned]:
                        props.status === statusTerminal.DECOMISSIONED
                }
            )}
        >
            {props.status || '-'}
        </SingleChip>
    );
};

export default TerminalStatus;
