import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FunctionComponent, useState } from "react";
import clsx from "clsx";

import Appointment from "@spike/appointment-model";
import Booking from "@spike/booking-model";
import Client from "@spike/client-model";

import { ChipPetSibling } from "./ChipPetSibling";
import { useDragScroll } from "../../../hooks/useDragScroll";

interface HeaderSiblingsProps {
  client?: Client;
  booking?: Booking;
  appointment?: Appointment;
  className?: string;
  uniquePet: boolean;
  petsInBooking: Array<number>;
  //
  onPetSelect: (petId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingRight: 4,
      paddingBottom: 16,
      cursor: "auto !important",
    },
    flexRow: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
    },
    pets: {
      display: "flex"
    },
    boxSibling: {
      color: "#707070",
      paddingRight: 10
    },
    chipPet: {
      padding: "0px 5px",
      height: 40,
      minWidth: 116,
      maxWidth: 150,
      marginBottom: 5,
    },
    slider: {
      width: "100%",
      display: "flex",
      cursor: "auto !important",
      touchAction: "none",
      overflow: "hidden"
    }
  })
);

export const HeaderSiblings: FunctionComponent<HeaderSiblingsProps> = (props) => {
  const classes = useStyles();
  const [ref] = useDragScroll();
  const [selected, setSelected] = useState<number | undefined>(props.appointment?.pet.id);

  const selectPetHandler = (petId: number) => {
    setSelected(petId);
    props.onPetSelect(petId);
  }

  return (
    <Box>
      {!props.uniquePet &&
        <Box className={clsx(classes.container, classes.flexRow, props.className)}>
          <div ref={ref} className={classes.slider}>
            {props.client?.pets
              .filter((pet) => props.petsInBooking?.includes(pet.id))
              .map((pet) => {
                return <Box className={classes.chipPet} onClick={() => selectPetHandler(pet.id)}>
                  <ChipPetSibling pet={pet} selected={pet.id === selected}/>
                </Box>
              })}
          </div>
        </Box>}
    </Box>
  )
};

export default HeaderSiblings;
