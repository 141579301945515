import { Grid, GridProps, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface GridHeaderProps extends GridProps {
	title?: string;
	align?: 'left' | 'right';
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		right: {
			textAlign: 'right'
		},
		title: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			color: '#2C2C2C',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(14)}px`,
				lineHeight: `${reduceResolution(14)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '14px',
				lineHeight: '14px'
			}
		}
	})
);

export const GridHeader: FunctionComponent<GridHeaderProps> = props => {
	const classes = useStyles(props);

	return (
		<Grid item {...props}>
			<Typography
				className={clsx(classes.title, {
					[classes.right]: props.align === 'right'
				})}
			>
				{props.title}
			</Typography>
		</Grid>
	);
};

export default GridHeader;
