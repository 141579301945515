import { ServicesState, ServicesStatus } from "./ServicesState";
import {
  SERVICES_RESET_ACTION_TYPE,
  SERVICES_CLEAR_ACTION_TYPE,
  SERVICES_FETCH_START_ACTION_TYPE,
  SERVICES_FETCH_SUCCESS_ACTION_TYPE,
  SERVICES_SAVE_START_ACTION_TYPE,
  SERVICES_SAVE_SUCCESS_ACTION_TYPE,
  SERVICES_DELETE_START_ACTION_TYPE,
  SERVICES_DELETE_SUCCESS_ACTION_TYPE,
  SERVICES_ACTIVATE_START_ACTION_TYPE,
  SERVICES_ACTIVATE_SUCCESS_ACTION_TYPE,
  SERVICES_INACTIVATE_START_ACTION_TYPE,
  SERVICES_INACTIVATE_SUCCESS_ACTION_TYPE,
  SERVICES_PET_TYPES_UPDATE_START_ACTION_TYPE,
  SERVICES_PET_TYPES_UPDATE_SUCCESS_ACTION_TYPE,
  SERVICES_BUSINESS_AREA_UPDATE_START_ACTION_TYPE,
  SERVICES_BUSINESS_AREA_UPDATE_SUCCESS_ACTION_TYPE,
  SERVICES_STAFF_UPDATE_START_ACTION_TYPE,
  SERVICES_STAFF_UPDATE_SUCCESS_ACTION_TYPE,
  SERVICES_ERROR_ACTION_TYPE,
  ServicesAction,
} from "actions/services/ServicesActionsTypes";
import { Reducer } from "redux";

const initialState: ServicesState = {
  services: [],
  status: ServicesStatus.Initial,
  loading: false,
};

export const servicesReducer: Reducer<ServicesState, ServicesAction> = (state = initialState, action) => {
  switch (action.type) {
    case SERVICES_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case SERVICES_CLEAR_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Initial,
        loading: false,
        services: [],
        uuidToId: new Map(),
      };
    case SERVICES_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Fetching,
        loading: true,
        duplicatedId: undefined,
      };
    case SERVICES_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.FetchSuccess,
        services: action.payload.services,
        loading: false,
        duplicatedServiceId: action.payload.duplicatedId,
      };
    case SERVICES_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Saving,
        savedService: undefined,
        loading: true,
      };
    case SERVICES_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.SaveSuccess,
        savedService: {...action.payload.service},
        services: state.services.some(service => service.uuid === action.payload.service.uuid) ? state.services.map(service => service.uuid === action.payload.service.uuid ? {...action.payload.service} : service): [...state.services, {...action.payload.service}],
        loading: false,
      };
    case SERVICES_DELETE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Deleting,
        loading: true,
      };
    case SERVICES_DELETE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.DeleteSuccess,
        loading: false,
      };
    case SERVICES_ACTIVATE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Activating,
        loading: true,
      };
    case SERVICES_ACTIVATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.ActivateSuccess,
        loading: false,
      };
    case SERVICES_INACTIVATE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Inactivating,
        loading: true,
      };
    case SERVICES_INACTIVATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.InactivateSuccess,
        loading: false,
      };
    case SERVICES_PET_TYPES_UPDATE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.PetTypesUpdating,
        loading: true,
      };
    case SERVICES_PET_TYPES_UPDATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.PetTypesUpdateSuccess,
        loading: false,
      };
    case SERVICES_BUSINESS_AREA_UPDATE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.BusinessAreaUpdating,
        loading: true,
      };
    case SERVICES_BUSINESS_AREA_UPDATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.BusinessAreaUpdateSuccess,
        loading: false,
      };
    case SERVICES_STAFF_UPDATE_START_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.StaffUpdating,
        loading: true,
      };
    case SERVICES_STAFF_UPDATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.StaffUpdateSuccess,
        loading: false,
      };
    case SERVICES_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: ServicesStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default servicesReducer;
