import {
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useCommonStyles } from './CommonStyles';

interface InvoiceExtraHeaderProps {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	})
);

export const InvoiceExtraHeader: FunctionComponent<
	InvoiceExtraHeaderProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<Grid container className={clsx(classes.container, props.className)}>
			<Grid item xs={3} className={commonClasses.firstHeader}>
				<Typography className={clsx(commonClasses.titleText)}>
					Item
				</Typography>
			</Grid>
			<Grid xs={9} container>
				<Grid item xs={3}></Grid>
				<Grid item xs={2} className={commonClasses.right}>
					<Typography className={commonClasses.titleText}>
						Quantity
					</Typography>
				</Grid>
				<Grid item xs={2} className={commonClasses.right}>
					<Typography className={commonClasses.titleText}>
						Price
					</Typography>
				</Grid>
				<Grid item xs={2} className={commonClasses.right}>
					<Typography className={commonClasses.titleText}>
						Discount
					</Typography>
				</Grid>
				<Grid item xs={2} className={commonClasses.right}>
					<Typography className={commonClasses.titleText}>
						Total
					</Typography>
				</Grid>
				<Grid item xs={1}></Grid>
			</Grid>
		</Grid>
	);
};

export default InvoiceExtraHeader;
