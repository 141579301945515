import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "store";
import _ from "lodash";
import clsx from "clsx";
import { StaffSchedule } from "model/Staff";
import { Button } from "components/UI";
import { useNonInitialEffect } from "@versiondos/hooks";
import { StaffStatus } from "reducers/staff/StaffState";
import StaffScheduleComponent from "components/StaffSchedule";
import { createEmptyWeek } from "@spike/model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  schedule?: StaffSchedule;
  staffId: number;
  onSave: (schedule: StaffSchedule) => void;
  className?: string;
  openSidebar?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.only("md")]: {
        width: "450px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "550px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "606px",
      },
    },
    titleContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "40px",
      },
    },
    title: {
      fontWeight: 600,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "20px",
        lineHeight: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "21px",
        lineHeight: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "32px",
        lineHeight: "42px",
      },
    },
    subtitle:{
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
      }
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
        marginBottom: "27px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "32px",
        marginBottom: "44px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "43px",
        marginBottom: "58px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "65px",
        marginBottom: "88px",
      },
    },
    buttonContainerFull: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "& button": {
          width: "100%",
          height: "50px",
          borderRadius: "31px",
          "& .MuiButton-label": {
            fontSize: "18px"
          }
        },
        "@supports (-webkit-touch-callout: none)": {
          paddingBottom: "70px",
        }
      }
    },
    iconTitle: {
      display: 'none',
      [theme.breakpoints.down("sm")]: {
        display: 'inline-flex',
        marginRight: '10px',
        fontSize: '18px',
      }
    },
  })
);

export const Schedule: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const loading = useSelector<RootState, boolean>((state) => state.marketplace.loading);
  const status = useSelector<RootState, StaffStatus>((state) => state.staff.status);
  const [saved, setSaved] = useState(false);

  const [schedule, setSchedule] = useState<StaffSchedule>({
    ...(props.schedule || {
      default: createEmptyWeek(),
      customDays: [],
    }),
  });

  useNonInitialEffect(() => {
    setSaved(_.isEqual(props.schedule, schedule));
  }, [schedule]);

  useNonInitialEffect(() => {
    if (status === StaffStatus.SaveSuccess) {
      setSaved(true);
    }
  }, [status]);

  const clickButtonHandler = () => {
    props.onSave(schedule);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>
          <FontAwesomeIcon
            className={classes.iconTitle}
            onClick={props.openSidebar}
            icon={faArrowLeft}
            size="2x"
          />
          Schedule
        </Typography>
      </Box>
      <StaffScheduleComponent
        schedule={schedule}
        staffId={props.staffId}
        onChange={setSchedule}
        customTitle="Weekly Schedule"
        titleClass={classes.subtitle}
        weeklyScheduleTitle="Edit Default"
      />
      <Box className={clsx(classes.buttonContainerFull, classes.buttonContainer)}>
        <Button label={saved ? "Saved!" : "Save"} onClick={clickButtonHandler} loading={loading} />        
      </Box>
    </Box>
  );
};

export default Schedule;
