import React, { useMemo } from 'react';
import {
    Box,
    Grid,
    Theme,
    Typography,
    createStyles,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { Button, PriceFormat } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartLineUp,
    faCommentAltLines,
    faCommentSms,
    faFileSearch,
    faFileSignature
} from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'model/Subscriptions';
import { useTimeZone } from 'hooks';
import moment from 'moment-timezone';
import { MobileDivider } from 'components/UI/MobileDivider';
import clsx from 'clsx';

interface Props {
    loading: boolean;
    subscription: Subscription;
    onDowngrade: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                flex: 1,
                flexWrap: 'nowrap',
                flexDirection: 'column'
            },

            '& b': {
                fontWeight: 600
            }
        },
        container: {
            padding: '0px 16px',

            [theme.breakpoints.up('md')]: {
                padding: 0
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 25
        },
        card: {
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                borderRadius: 16,
                padding: '0px 22px',
                border: 'solid 1px #D4D4D4'
            }
        },
        cardHeader: {
            'rowGap': 16,
            'paddingBottom': 15,
            'marginBottom': 17,
            'borderBottom': 'solid 1px #D4D4D4',

            '& .MuiTypography-body1': {
                fontSize: 16
            },

            [theme.breakpoints.up('sm')]: {
                paddingTop: 24
            }
        },
        cardFooter: {
            [theme.breakpoints.up('md')]: {
                paddingBottom: 25
            },

            '& .MuiTypography-body1': {
                fontSize: 16,
                lineHeight: 1.5,
                color: '#7A7A7A'
            },

            '& .MuiTypography-body2': {
                fontSize: 16,
                lineHeight: 1.4,
                fontWeight: 600,
                marginBottom: 12
            },

            '& ul': {
                padding: 0,
                fontSize: 16,
                fontWeight: 500,
                marginTop: 27,
                marginBottom: 0,
                listStyle: 'none',

                [theme.breakpoints.up('md')]: {
                    fontSize: 16
                }
            },

            '& li:not(:last-child)': {
                marginBottom: '1em'
            }
        },
        actions: {
            'display': 'flex',
            'alignItems': 'flex-end',
            'textAlign': 'center',
            'marginTop': 25,

            '& button': {
                'height': 47,
                'width': '100%',
                'padding': '0px 17px',
                'borderRadius': 30,

                '& label': {
                    fontSize: 16,
                    fontWeight: 600
                }
            }
        }
    })
);

export const DowngradePlanForm: React.FunctionComponent<Props> = props => {
    const theme = useTheme();
    const classes = useStyles();
    const timeZone = useTimeZone();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const cancelDate = moment().tz(timeZone).format('MMM Do, YYYY');

    const renewalDate = moment(props.subscription.renewAt).format(
        'MMM Do, YYYY'
    );

    return (
        <Grid container spacing={isMobile ? 0 : 4} className={classes.root}>
            <Grid item xs={12} className={classes.container}>
                <Box className={classes.card}>
                    <Grid container className={classes.cardHeader}>
                        <Grid item xs>
                            <Typography>
                                Current Plan: <b>Premium</b>
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" style={{ textAlign: 'right' }}>
                            <Typography>
                                <PriceFormat
                                    price={`${props.subscription.price?.total}`}
                                />
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                You still have access to all the{' '}
                                <b>Cuddles Premium</b> features until{' '}
                                <b>{renewalDate}</b>.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className={classes.cardFooter}>
                        <Typography>
                            By downgrading to the Free Plan, the following
                            features will no longer be available to you:
                        </Typography>

                        <ul>
                            <li>
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={faCommentAltLines}
                                    style={{ marginRight: 8 }}
                                />
                                Two-Way Texting (1,000 SMS)
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={faFileSignature}
                                    style={{ marginRight: 8 }}
                                />
                                Agreements
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={faChartLineUp}
                                    style={{ marginRight: 8 }}
                                />
                                Insights
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={faFileSearch}
                                    style={{ marginRight: 8 }}
                                />
                                Service Reports
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={faCommentSms}
                                    style={{ marginRight: 8 }}
                                />
                                Client Text Notifications
                            </li>
                        </ul>
                    </Box>
                </Box>
            </Grid>

            <Grid container xs={12}>
                <MobileDivider />
            </Grid>

            <Grid item xs={12} className={classes.container}>
                <Typography className={classes.title}>
                    Downgrade Plan
                </Typography>

                <Box className={classes.card}>
                    <Grid container className={classes.cardHeader}>
                        <Grid item xs={6}>
                            <Typography>
                                New Plan: <b>Free</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography>$0.00</Typography>
                        </Grid>
                    </Grid>

                    <Box className={classes.cardFooter}>
                        <Typography variant="body2">
                            Cancel date {cancelDate}
                        </Typography>

                        <Typography>
                            Your plan change will be effective on {renewalDate}.
                            Please continue to enjoy all the Premium features
                            until then.
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                className={clsx(classes.actions, classes.container)}
            >
                <Button
                    color={isMobile ? 'black' : 'orange'}
                    label="Downgrade"
                    loading={props.loading}
                    onClick={props.onDowngrade}
                />
            </Grid>
        </Grid>
    );
};
