import { FunctionComponent } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Button } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCardClip } from '@fortawesome/pro-regular-svg-icons';
import Client from '@spike/client-model';
import { wbp, reduceResolution } from 'Theme';
import clsx from 'clsx';

interface Props {
    clientData: Client;
    onClientCardClick: (clientId: number, petId?: number) => void;
    onBookNowClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatHeaderContainer: {
            width: '100%',
            height: '8%',
            display: 'flex',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '5px'
            },
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '#D4D4D4 1px solid'
        },
        chatHeaderLeftContainer: {
            display: 'flex',
            width: '50%',
            flexDirection: 'row',
            alignItems: 'center'
        },
        chatHeaderRightContainer: {
            display: 'flex',
            width: '50%',
            flexDirection: 'row-reverse',
            alignItems: 'center'
        },
        chatHeaderTitle: {
            color: '#000',
            fontWeight: 600,
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(25)}px !important`,
                fontSize: `${reduceResolution(16)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '25px !important',
                fontSize: '16px'
            }
        },
        chatHeaderItem: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(15)}px`,
                paddingLeft: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: '15px',
                paddingLeft: '15px'
            }
        },
        chatBookNowButton: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(37)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '37px'
            }
        }
    })
);

const ChatHeader: FunctionComponent<Props> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.chatHeaderContainer}>
            <Box className={classes.chatHeaderLeftContainer}>
                <Typography
                    className={clsx(
                        classes.chatHeaderTitle,
                        classes.chatHeaderItem
                    )}
                >
                    {props.clientData?.firstName} {props.clientData?.lastName}
                </Typography>
            </Box>
            <Box className={classes.chatHeaderRightContainer}>
                <FontAwesomeIcon
                    onClick={() =>
                        props.onClientCardClick(props.clientData.id!)
                    }
                    className={classes.chatHeaderItem}
                    icon={faIdCardClip}
                />
                <Button
                    onClick={props.onBookNowClick}
                    className={clsx(
                        classes.chatBookNowButton,
                        classes.chatHeaderItem
                    )}
                    size="small"
                    label="Book Now"
                    color="green"
                />
            </Box>
        </Box>
    );
};

export default ChatHeader;
