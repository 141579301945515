import { Action } from "redux";
import AutocompleteResult from "model/AutocompleteResult";

//Action Types
export const AUTOCOMPLETE_RESET_ACTION_TYPE = "AUTOCOMPLETE_RESET_START";
export const AUTOCOMPLETE_SEARCH_START_ACTION_TYPE = "AUTOCOMPLETE_SEARCH_START";
export const AUTOCOMPLETE_SEARCH_SUCCESS_ACTION_TYPE = "AUTOCOMPLETE_SEARCH_SUCCESS";
export const AUTOCOMPLETE_ERROR_ACTION_TYPE = "AUTOCOMPLETE_ERROR";

//Actions
interface AutocompleteResetAction
  extends Action<typeof AUTOCOMPLETE_RESET_ACTION_TYPE> { }

interface AutocompleteSearchStartAction
  extends Action<typeof AUTOCOMPLETE_SEARCH_START_ACTION_TYPE> { }

interface AutocompleteSearchSuccessAction
  extends Action<typeof AUTOCOMPLETE_SEARCH_SUCCESS_ACTION_TYPE> {
  payload: {
    results: Array<AutocompleteResult>;
  };
}

interface AutocompleteErrorAction extends Action<typeof AUTOCOMPLETE_ERROR_ACTION_TYPE> { }

export type AutocompleteAction =
  AutocompleteResetAction
  | AutocompleteSearchStartAction
  | AutocompleteSearchSuccessAction
  | AutocompleteErrorAction;
