import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Staff from "model/Staff";
import SelectStaff from "./SelectStaff";

interface Props {
    selectedOption?: Staff;
    options: Array<Staff>;
    onSelectStaff: (staff: Staff) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "16px",
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            },
        }
    })
);

export const StaffComponent: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    
    return (
        <>
            <Typography className={classes.title}>Staff</Typography>   
            <SelectStaff 
                placeholder="Select Staff"
                selectedOption={props.selectedOption}
                options={props.options}
                onSelect={props.onSelectStaff}
            />
        </>
    )
};

export default StaffComponent