import React, { FunctionComponent, MouseEvent, useState } from "react";
import { Box, useMediaQuery } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import Service from "model/Service";
import clsx from "clsx";
import ServiceRowStaff from "./ServiceRowStaff";
import { Status } from "model";
import Button from "components/UI/Button";

import VariablePriceTitle from "./VariablePriceTitle";
import ServiceRowDuration from "./Duration/ServiceRowDuration";
import TableDetailService from "./TableDetailService";
import { reduceResolution, wbp } from "Theme";

interface Props {
  service: Service;
  onEdit: () => void;
  onAddStaff: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "10px",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "15px",
      },
    },
    categoryNamePetTypeContainer: {
      display: "flex",
      flexDirection: "column",
      width: "19%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
      }
    },
    categoryContainer: {
      display: "flex",
    },
    category: {
      width: "100%",
      textAlign: "left",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "10px",
      },
    },
    nameContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "5px",
      },
      [theme.breakpoints.down("md")]: {
        width: "60%"
      },
      [theme.breakpoints.down(767)]: {
        width: "50vw"
      },
    },
    name: {
      textAlign: "left",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "97%",
      },
      fontWeight: 600,
    },
    petTypeContainer: {
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "20px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        alignItems: "center",
        marginLeft: "10px",
      },
    },
    petType: {
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(23)}px`,
        marginRight: `${reduceResolution(12)}px`,
        fontSize: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "23px",
        marginRight: "12px",
        fontSize: "12px",
      },
    },
    durationContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      width: "21%"
    },
    staffContainer: {
      display: "flex",
      width: "20%",
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    priceContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      width: "30%"
    },
    price: {
      display: "flex",
      width: "100%",
    },
    taxesContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      width: "10%"
    },
    taxes: {
      display: "flex",
      width: "100%",
    },
    noTaxes: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
        paddingLeft: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "10px",
        fontSize: "15px"
      },

      fontWeight: 500
    },
    inactive: {
      opacity: 0.4,
      [theme.breakpoints.down("md")]: {
        opacity: 1,
      }
    },
    button: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(164)}px`,
        height: `${reduceResolution(45)}px`,
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "164px",
        height: "45px",
        fontSize: "16px",
      },
      fontWeight: 600,
      "&:hover": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    gridFixedPrice: {
      textAlign: "right",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },

    fixedPriceContainer: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "left",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(5)}px 0`,
        width: `${reduceResolution(500)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "5px 0",
        width: "500px",
      },
    },

    fixedPriceType: {
      fontWeight: 500,
      fontSize: "12px",
    },

    fixedPrice: {
      textAlign: "left",
      fontWeight: 700,
      fontSize: "12px",
    },

    variablePriceGrid: {
      textAlign: "right",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },

    variablePriceContainer: {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "left",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(5)}px 0`,
        width: `${reduceResolution(500)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "5px 0",
        width: "500px",
      },
    },

    fontHeader: {
      fontWeight: 700,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(11)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "11px"
      },
    },

    variablePriceFont: {
      fontWeight: 500,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px"
      },
    },

    variablePrice: {
      width: "20%",
      textAlign: "left",
      fontWeight: 700,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px"
      },
    },

    undefined: {
      color: "red",
    },

    dogs: {
      "&.MuiChip-outlined": {
        color: "#5E8677",
        borderColor: "#5E8677",
      },
      [theme.breakpoints.down("md")]: {
        padding: "4px 10px",
        "&.MuiChip-outlined": {
          color: "#FFF",
          borderColor: "#5E8677",
          backgroundColor: "#5E8677",
          fontSize: "13px",
          fontWeight: 500,
          height: "24px",
          padding: "0px",
          "& .MuiChip-label": {
            padding: "4px 10px",
          }
        }
      },
    },

    cats: {
      [theme.breakpoints.down("md")]: {
        padding: "4px 10px",
        "&.MuiChip-outlined": {
          color: "#000",
          borderColor: "#F2D2A2",
          backgroundColor: "#F2D2A2",
          fontSize: "13px",
          fontWeight: 500,
          height: "24px",
          padding: "0px",
          "& .MuiChip-label": {
            padding: "4px 10px",
          }
        }
      },
    },

    exotics: {
      [theme.breakpoints.down("md")]: {
        padding: "4px 10px",
        "&.MuiChip-outlined": {
          color: "#9C8267",
          borderColor: "#EBE7E3",
          backgroundColor: "#EBE7E3",
          fontSize: "13px",
          fontWeight: 500,
          height: "24px",
          padding: "0px",
          "& .MuiChip-label": {
            padding: "4px 10px",
          }
        }
      },
    },

    priceTitle: {
      background: "#F1F1F1 !important",
      border: "none !important"
    },

    active: {
      border: "2px solid #000 !important"
    },

    hover: {
      background: "#fff !important"
    },
    hideOnMobile: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      }
    }
  })
);

export const ServicesRowContent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const addStaff = props.service.staff.length === 0;
  const buttonLabel = addStaff ? "Add Staff" : "Finish Setup";

  const [showFixedPrices, setShowFixedPrices] = useState(false);
  const [showVariablePrices, setShowVariablePrices] = useState(false);
  const [showDuration, setShowDuration] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showTaxes, setShowTaxes] = useState(false);
  const [hover, setHover] = useState(false);

  const getPetClass = (type:string) => {
    switch (type) {
      case "Dogs":
        return classes.dogs;
      case "Cats":
        return classes.cats;
      case "Exotics":
        return classes.exotics;
      default:
        return "";
    }
  }

  const petTypes = props.service.petTypes.map((petType) => (
    /*
    <Chip
      key={petType.id}
      label={petType.plural}
      variant="outlined"
      color={petType.plural === "Cats" ? "primary" : undefined}
      className={
        clsx(classes.petType, {
          [classes.dogs]: petType.plural === "Dogs"
        }
        )}
    />
    */

    <Chip
      key={petType.id}
      label={isMobile ? petType.singular : petType.plural}
      variant="outlined"
      color={petType.plural === "Cats" ? "primary" : undefined}
      className={clsx(classes.petType, getPetClass(petType.plural))}
    />
  ));

  const containerStyle = clsx(classes.container, {
    [classes.inactive]: props.service.status.id === Status.INACTIVE,
  });

  const clickButtonHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (addStaff) {
      props.onAddStaff();
    } else {
      props.onEdit();
    }
  };

  const taxes = (
    <Box>
      <VariablePriceTitle title={props.service.pricing.taxes.length + " Taxes"} expandable={true} className={clsx(classes.priceTitle, { [classes.hover]: hover, [classes.active]: showTaxes })} onChange={setShowTaxes} />
    </Box>
  );


  const noTaxes = (<Typography className={classes.noTaxes}>Not taxable</Typography>);

  const fixedPrice = (
    <Box>
      <VariablePriceTitle title="Price by Pet" expandable={true} className={clsx(classes.priceTitle, { [classes.hover]: hover, [classes.active]: showFixedPrices })} onChange={setShowFixedPrices} />
    </Box>
  );

  const variablePrice = (
    <Box>
      <VariablePriceTitle title="Variable Price" expandable={true} className={clsx(classes.priceTitle, { [classes.hover]: hover, [classes.active]: showVariablePrices })} onChange={setShowVariablePrices} />
    </Box>
  );

  return (
    <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Box className={containerStyle}>
        <Box className={classes.categoryNamePetTypeContainer}>
          <Box className={classes.nameContainer}>
            <Typography className={classes.name}>{props.service.name}</Typography>
          </Box>
          <Box className={classes.petTypeContainer}>{petTypes}</Box>
        </Box>
        <Box className={clsx(classes.staffContainer, classes.hideOnMobile)}>
          {props.service.status.id === Status.DRAFT ? (
            <Button label={buttonLabel} className={classes.button} variant="secondary" onClick={clickButtonHandler} />
          ) : (
            <ServiceRowStaff staff={props.service.staff} hover={hover} onShow={(show) => setShowStaff(show)} />
          )}
        </Box>
        <Box className={clsx(classes.durationContainer, classes.hideOnMobile)}>
          <ServiceRowDuration duration={props.service.duration} hover={hover} onShow={(show) => setShowDuration(show)} />
        </Box>
        <Box className={clsx(classes.priceContainer, classes.hideOnMobile)}>
          <Box className={classes.price}>
            {props.service.status.id !== Status.DRAFT &&

              <Box className={classes.container}>
                {props.service.pricing.fixedPrice.length > 0 && fixedPrice}
                {props.service.pricing.variablePrice && variablePrice}
              </Box>
            }
          </Box>
        </Box>
        <Box className={clsx(classes.taxesContainer, classes.hideOnMobile)}>
          <Box className={classes.taxes}>

            <Box className={classes.container}>
              {props.service.pricing.taxes !== undefined ? (props.service.pricing.taxes.length === 0 ? noTaxes : taxes)

                : noTaxes}
            </Box>

          </Box>

        </Box>
      </Box>
      {(showStaff || showVariablePrices || showFixedPrices || showDuration || showTaxes) &&
        <Box>
          <TableDetailService
            service={props.service}
            staff={props.service.staff}
            variablePrice={props.service.pricing.variablePrice}
            fixedPrice={props.service.pricing.fixedPrice}
            variableDuration={props.service.duration.variable}
            showStaff={showStaff}
            showVariablePrices={showVariablePrices}
            showFixedPrices={showFixedPrices}
            showDuration={showDuration}
            showTaxes={showTaxes}
          />
        </Box>}
    </Box>
  );
};

export default ServicesRowContent;
