import React, { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import moment, { Moment } from "moment";
import { MonthSelect, YearField, DayField } from "components/UI";
import { FieldError } from "@spike/model";

interface Props {
  date?: Moment;
  minYear?: number;
  maxYear?: number;
  onChange: (date: Moment | undefined, name?: string) => void;
  className?: string;
  name?: string;
  errors: Array<FieldError>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      justifyItems: "space-between",
      alignItems: "center",
    },
    monthContainer: {
      display: "flex",
      width: "50%",
      [theme.breakpoints.only("xs")]: {
        paddingRight: "4px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingRight: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: "14px",
      },
    },
    dayContainer: {
      display: "flex",
      width: "25%",
      [theme.breakpoints.only("xs")]: {
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "7px",
        paddingRight: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "14px",
        paddingRight: "14px",
      },
    },
    yearContainer: {
      display: "flex",
      width: "25%",
      [theme.breakpoints.only("xs")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "14px",
      },
    },
  })
);

export const DateField: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [month, setMonth] = useState<number | undefined>(props.date ? parseInt(props.date.format("MM")) : undefined);
  const [day, setDay] = useState<number | undefined>(props.date ? parseInt(props.date.format("DD")) : undefined);
  const [year, setYear] = useState<number | undefined>(props.date ? parseInt(props.date.format("YYYY")) : undefined);

  const hasError = props.errors.some((error) => error.fieldName === props.name);

  const errorMessage = hasError
    ? props.errors.find((error) => error.fieldName === props.name)?.errorMessage
    : undefined;

  useEffect(() => {
    if (
      month !== undefined &&
      day !== undefined &&
      year !== undefined &&
      moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()
    ) {
      props.onChange(moment(`${year}-${month}-${day}`, "YYYY-MM-DD"), props.name);
    } else {
      props.onChange(undefined, props.name);
    }
  }, [month, day, year]);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.monthContainer}>
        <MonthSelect label="Birthday" value={month} onChange={setMonth} error={hasError} errorMessage={errorMessage} />
      </Box>
      <Box className={classes.dayContainer}>
        <DayField {...props} label="Day" value={day} onChange={setDay} error={hasError} />
      </Box>
      <Box className={classes.yearContainer}>
        <YearField {...props} label="Year" value={year} onChange={setYear} error={hasError} />
      </Box>
    </Box>
  );
};

export default DateField;
