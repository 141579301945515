import { FunctionComponent } from 'react';
import { makeStyles, TextField as MUITextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { commonStyles } from 'Theme';
import clsx from 'clsx';
import { isEqual } from 'lodash';

interface PriceFieldProps {
    id?: string;
    value?: number;
    defaultValue?: number;
    placeholder?: string;
    error?: boolean;
    onChange: (price?: number) => void;
    onBlur?: () => void;
    className?: string;
    suffix?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
}

const useStyles = makeStyles(() => ({
    labelColor: {
        '& > *': {
            color: '#969696'
        }
    },
    valueColor: {
        '& > *': {
            color: '#000'
        }
    }
}));

export const PriceField: FunctionComponent<PriceFieldProps> = props => {
    const commonClasses = commonStyles();
    const classes = useStyles();
    const handleValueChange = (values: { floatValue: number | undefined }) => {
        const newValue = values.floatValue;

        // Validate min/max constraints
        if (newValue !== undefined) {
            if (props.min !== undefined && newValue < props.min) return;
            if (props.max !== undefined && newValue > props.max) return;
        }

        props.onChange(newValue);
    };

    return (
        <NumberFormat
            id={props.id}
            error={props.error}
            prefix="$"
            suffix={props.suffix}
            value={isEqual(props.value, 0) ? props.placeholder : props.value}
            defaultValue={props.defaultValue}
            allowNegative={false}
            decimalScale={2}
            allowLeadingZeros={false}
            thousandSeparator={true}
            customInput={MUITextField}
            variant="outlined"
            onValueChange={handleValueChange}
            onBlur={props.onBlur}
            className={clsx([
                commonClasses.field,
                props.className,
                {
                    [classes.labelColor]: isEqual(props.value, 0),
                    [classes.valueColor]: !isEqual(props.value, 0)
                }
            ])}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => event.target.select()}
            placeholder={props.placeholder || '$0.00'}
            disabled={props.disabled || false}
            inputProps={{
                'aria-label': 'price input',
                'min': props.min || 0,
                'max': props.max
            }}
        />
    );
};
