import { faCalendarDays, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError } from "@spike/model";
import clsx from "clsx";
import { Moment } from "moment-timezone";
import { FunctionComponent, useState } from "react";
import Calendar from "./Calendar";
import { useFieldStyles } from "../FieldStyles";
import LabelField from "../LabelField";

import { wbp } from "Theme";

export interface CalendarField2Props {
  id?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  date?: Moment;
  name?: string;
  errors?: Array<FieldError>;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  information?: string;
  onChange?: (moment?: Moment, fieldName?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      position: "relative",
      [theme.breakpoints.down(wbp)]: {
        height: "39px",
      },
      [theme.breakpoints.up(wbp)]: {
        height: "50px",
      },
      [theme.breakpoints.down("md")]: {
        height: "50px",
      },
    },
    collapsedContainer: {
      height: "100%",
    },
    expandedContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #222222 !important",
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "26px",
      },
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: "33px",
      },
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        height: "38px",
      },
      [theme.breakpoints.up(wbp)]: {
        height: "47px",
      },
      [theme.breakpoints.down("md")]: {
        height: "50px",
      },
      cursor: "pointer",
    },
    header: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: "10px",
        fontSize: "14px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "20px",
        fontSize: "18px",
        lineHeight: "27px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "22px",
      },
    },
    placeholder: {
      color: "#7A7A7A",
    },
    border: {
      border: "1px solid #222222",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: "26px",
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "33px",
      },
    },
    textContainer: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
    },
    chevronContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: "28px",
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "35px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: "15px",
      },
    },
    calendarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: "28px",
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "35px",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "25px",
      },
    },
    optionNotSelected: {
      border: "1px solid #D3D3D3 !important",
    },
  })
);

export const CalendarField2: FunctionComponent<CalendarField2Props> = (props) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const hasError = props.errors?.some((errorField) => errorField.fieldName === props.name);

  const clickToggleHandler = () => {
    setIsCollapsed((prev) => props.disabled || !prev);
  };

  const clickCloseHandler = () => {
    setIsCollapsed(true);
  };

  const header = (
    <Box className={classes.headerContainer} onClick={clickToggleHandler}>
      <Box className={classes.calendarContainer}>
        <FontAwesomeIcon icon={faCalendarDays} />
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={clsx(classes.header, { [classes.placeholder]: !props.date })}>
          {props.date ? props.date.format("MMM D, YYYY") : props.placeholder}
        </Typography>
      </Box>
      <Box className={classes.chevronContainer}>
        <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />
      </Box>
    </Box>
  );

  const collapsed = (
    <Box
      className={clsx(classes.border, classes.collapsedContainer, {
        [classes.optionNotSelected]: !props.date && !hasError,
        [fieldClasses.errorBorder]: hasError,
        [fieldClasses.disabled]: props.disabled,
      })}
    >
      {header}
    </Box>
  );

  const expanded = (
    <Box className={clsx(classes.border, classes.expandedContainer)}>
      {header}
      <Calendar
        date={props.date && props.date.isValid() ? props.date : undefined}
        onChange={(moment) => props.onChange && props.onChange(moment, props.name)}
        onClose={clickCloseHandler}
      />
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={clickCloseHandler}>
      <Box id={props.id} className={clsx(fieldClasses.container, props.className)}>
        <LabelField {...props} />
        <Box className={classes.dropdownContainer}>{isCollapsed ? collapsed : expanded}</Box>
      </Box>
    </ClickAwayListener>
  );
};

export default CalendarField2;
