import { alertErrorHandler } from "@spike/notifications-action";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { AxiosResponse } from "axios";
import { NotificationsAction }from "@spike/notifications-action";
import { GlobalSearchState } from "reducers/globalSearch/GlobalSearchState";
import { ThunkAction } from "redux-thunk";
import store from "store";
import {
    GlobalSearchAction, GLOBALSEARCH_ERROR_ACTION_TYPE, GLOBALSEARCH_START_ACTION_TYPE,
    GLOBALSEARCH_SUCCESS_ACTION_TYPE
} from "./GlobalSearchActionsTypes";
import { GlobalSearchDtos } from "./GlobalSearchDtos";

const globalSearchUrl = "/global_search";

export const globalSearchThunk = (
  searchText: string
): ThunkAction<void, GlobalSearchState, null, GlobalSearchAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(searchStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${globalSearchUrl}?marketplace_id=${marketplaceId}&query=${searchText}`;
    try {
      const response: AxiosResponse<Array<GlobalSearchDtos>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(searchSuccess(response.data));
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error searching.");
      }
    }
  };
};

const searchStart = (): GlobalSearchAction => {
  return {
    type: GLOBALSEARCH_START_ACTION_TYPE,
  };
};

export const searchSuccess = (resultDtos: Array<GlobalSearchDtos>): GlobalSearchAction => {
  return {
    type: GLOBALSEARCH_SUCCESS_ACTION_TYPE,
    payload: {
      results: resultDtos,
    },
  };
};

const error = (): GlobalSearchAction => {
  return {
    type: GLOBALSEARCH_ERROR_ACTION_TYPE,
  };
};
