import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, DialogTitle, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

interface MessageDialog2Props {
    title?: string;
    message: Array<string>;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiDialog-paper': {
                border: '1px solid #D3D3D3',
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: 0,
                [theme.breakpoints.down('sm')]: {
                    margin: 0
                },
                [theme.breakpoints.up('md')]: {
                    width: 464,
                    height: 'auto',
                    borderRadius: 30,
                    paddingBottom: 30
                }
            },
            '& .MuiDialogTitle-root': {
                padding: 13,
                paddingBottom: '4px !important',
                [theme.breakpoints.up('md')]: {
                    padding: 15,
                    paddingBottom: '5px !important'
                }
            },
            '& .MuiDialogContent-root': {
                paddingTop: '0px !important',
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                },
                [theme.breakpoints.up('md')]: {
                    padding: '15px'
                }
            }
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        title: {
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 20,
            lineheight: '24px',
            paddingBottom: 9,
            [theme.breakpoints.up('md')]: {
                paddingBottom: 10
            }
        },
        text: {
            textAlign: 'center',
            fontSize: 16
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        icon: {
            cursor: 'pointer',
            fontSize: 20
        }
    })
);

export const MessageDialog2: FunctionComponent<MessageDialog2Props> = props => {
    const classes = useStyles();

    return (
        <Dialog onClose={props.onClose} open={true} className={classes.dialog}>
            <DialogTitle className={classes.titleContainer}>
                <FontAwesomeIcon
                    id="booking_button_close"
                    icon={faTimes}
                    className={classes.icon}
                    onClick={props.onClose}
                />
            </DialogTitle>
            <DialogContent>
                <Box className={classes.textContainer}>
                    {props.title && <Typography className={classes.title}>{props.title}</Typography>}
                    {(props.message ?? []).map((line, index) => (
                        <Typography key={index} className={classes.text}>
                            {line}
                        </Typography>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default MessageDialog2;
