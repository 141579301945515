import { FunctionComponent, Fragment } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle, Typography } from "@material-ui/core";
import { Button } from "components/UI";

export interface Props {
  open: boolean;
  title?: string;
  description?: string;
  onOk: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        border: "1px solid #222222",
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("md")]: {
          width: "371px",
          minHeight: "194px",
          borderRadius: "16px",
          paddingBottom: "26px",
          paddingTop: "12px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "464px",
          minHeight: "243px",
          borderRadius: "20px",
          paddingBottom: "32px",
          paddingTop: "15px",
        },
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0px",
        [theme.breakpoints.down("md")]: {
          paddingBottom: "16px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingBottom: "20px",
        },
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "center",
      },
    },
    cancelButton: {
      [theme.breakpoints.down("md")]: {
        marginRight: "13px",
        width: "136px",
        height: "43px",
        "& .MuiButton-label": {
          fontSize: "15px"
        }
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "16px",
        width: "170px",
        height: "54px",
        "& .MuiButton-label": {
          fontSize: "18px"
        }
      },
    },
    contentContainer: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    title: {
      fontWeight: 600,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        paddingBottom: "6px",
        paddingTop: "10px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "19px",
        paddingBottom: "4px",
        paddingTop: "13px"
      },
    }
  })
);

export const WarningDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const cancelHandler = () => {
    props.onOk();
  };

  return (
    <Dialog open={props.open} className={classes.dialog}>
      <DialogTitle>
        <Typography className={classes.title}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>{props.description}</DialogContent>
      <DialogActions>
        <Fragment>
          <Button
            label="Got it"
            onClick={cancelHandler}
            size="medium"
            className={classes.cancelButton}
            color="orange"
          />
        </Fragment>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
