import { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { PetType } from '@spike/model';
import { MarketplaceDeposit, MarketplaceDepositType } from '@spike/marketplace-model';
import { FixedPriceOption } from 'model/Service';
import { PriceError } from '../../model';

export const useFixedPriceManagement = (
    initialPrices: Array<FixedPriceOption>,
    deposit: MarketplaceDeposit,
    initialErrors: Array<PriceError>,
    showErrors: boolean
) => {
    const [fixedPrices, setFixedPrices] = useState<Array<FixedPriceOption>>(initialPrices);
    const [errors, setErrors] = useState<Array<PriceError>>(initialErrors);
    const [showAlert, setShowAlert] = useState<Array<FixedPriceOption>>([]);

    useEffect(() => {
        setFixedPrices(initialPrices);
    }, [initialPrices]);

    useEffect(() => {
        if (showErrors) {
            setErrors(initialErrors);
            checkPriceAlerts();
        }
    }, [showErrors, initialErrors]);

    const handlePriceChange = useCallback(
        (petType: PetType, price?: number) => {
            const updatedFixedPrices = fixedPrices.map(fixedPrice =>
                isEqual(fixedPrice.petType.id, petType.id) ? { ...fixedPrice, price } : fixedPrice
            );
            setErrors(prevErrors => prevErrors.filter(error => !isEqual(error.petTypeId, petType.id)));
            setFixedPrices(updatedFixedPrices);
        },
        [fixedPrices]
    );

    const checkPriceAlerts = useCallback(() => {
        if (deposit.type === MarketplaceDepositType.Amount) {
            const alertPrices = fixedPrices.filter(price => price.price && price.price < Number(deposit.value));
            setShowAlert(alertPrices);
        }
    }, [fixedPrices, deposit]);

    return {
        fixedPrices,
        errors,
        showAlert,
        setErrors,
        setFixedPrices,
        setShowAlert,
        handlePriceChange,
        checkPriceAlerts
    };
};
