import { faCat, faDog, faTurtle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PetType, PetTypeIds } from "@spike/model";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface PetAvatarProps {
  petType: PetType;
  imageUrl?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(40)}px`,
        height: `${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "40px",
        height: "40px",
      },
    },
    image: {
      backgroundImage: (props: PetAvatarProps) => "url(" + props.imageUrl + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
      width: "100%",
      height: "100%",
    },
    emptyImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      background: "#F8F5F1",
      width: "100%",
      height: "100%",
    },
    cat: {
      backgroundColor: "#FAEFDF",
    },
    dog: {
      backgroundColor: "#E9F0ED",
    },
    icon: {
      color: "#BAA997",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(22)}px !important`,
        height: `${reduceResolution(22)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "22px !important",
        height: "22px !important",
      },
    },
    dogIcon: {
      color: "#5E8677",
    },
    catIcon: {
      color: "#EAB464",
    },
  })
);

export const PetAvatar: FunctionComponent<PetAvatarProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {!isEmpty(props.imageUrl) ? (
        <Box className={classes.image} />
      ) : (
        <Box
          className={clsx(
            classes.emptyImage,
            { [classes.dog]: props.petType?.id === PetTypeIds.DOGS },
            { [classes.cat]: props.petType?.id === PetTypeIds.CATS }
          )}
        >
          <FontAwesomeIcon
            icon={
              props.petType?.id === PetTypeIds.CATS ? faCat : props.petType?.id === PetTypeIds.DOGS ? faDog : faTurtle
            }
            className={clsx(
              classes.icon,
              { [classes.dogIcon]: props.petType?.id === PetTypeIds.DOGS },
              { [classes.catIcon]: props.petType?.id === PetTypeIds.CATS }
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default PetAvatar;
