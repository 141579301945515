import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Header as UIHeader } from 'components/UI';
import Button from 'components/UI/V2/Button/Button';
import { FunctionComponent } from 'react';

interface HeaderProps {
    onAdd?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiGrid-container': {
                [theme.breakpoints.down('md')]: {
                    justifyContent: 'space-around'
                },
                '& .MuiGrid-item:last-child': {
                    textAlign: 'right',
                    [theme.breakpoints.down('md')]: {
                        marginLeft: 10,
                        maxWidth: '136px'
                    }
                },
                '& .MuiGrid-item:first-child': {
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '55%'
                    },
                    '& p': {
                        [theme.breakpoints.down('md')]: {
                            fontSize: '20px'
                        }
                    }
                }
            }
        },
        rightElement: {}
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();

    return (
        <UIHeader
            title="Add-On Services"
            className={classes.root}
            rightElements={
                <Box className={classes.rightElement}>
                    {props.onAdd && (
                        <Button
                            leftIcon={faPlus}
                            label="Add Add-On"
                            onClick={props.onAdd}
                        />
                    )}
                </Box>
            }
        />
    );
};

export default Header;
