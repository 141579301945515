import React from 'react';
import {
    Box,
    Grid,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import HeaderSearchableSearch from './Search';
import { SelectionBar } from '../SelectionBar';
import { SelectionBarProps } from '../SelectionBar/SelectionBar';
import Button from 'components/UI/V2/Button/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            paddingTop: 20,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 20,

            [theme.breakpoints.up('md')]: {
                paddingTop: 30,
                paddingLeft: 25,
                paddingRight: 25,
                paddingBottom: 40,
                backgroundColor: '#FAFAFA'
            },
            [theme.breakpoints.up('lg')]: {
                paddingTop: 43,
                paddingLeft: 87,
                paddingRight: 132
            }
        },
        titleRow: {
            marginBottom: 20,
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                marginBottom: 30
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: 36
            }
        },
        addButton: {
            'height': 39,
            'width': 'auto',
            'fontWeight': 500,
            'borderRadius': 30,
            'paddingLeft': 16,
            'paddingRight': 16,
            'marginLeft': 'auto',
            'display': 'inline-block',

            [theme.breakpoints.up('md')]: {
                height: 50,
                paddingLeft: 35,
                paddingRight: 35
            },

            '& .MuiButton-label': {
                fontSize: 14,
                lineHeight: 1,

                [theme.breakpoints.up('md')]: {
                    fontSize: 18
                }
            },
            '& svg': {
                marginRight: 4,
                verticalAlign: 'middle',
                fontSize: '18px !important',
                width: '1rem !important',
                height: '1rem !important'
            }
        },
        searchCol: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: 15
            }
        },
        filterCol: {
            [theme.breakpoints.up('md')]: {
                marginBottom: 40
            },
            [theme.breakpoints.down('sm')]: {
                order: 3
            }
        },
        selectionBar: {
            display: 'inline-flex',
            backgroundColor: '#fff'
        }
    })
);

interface HeaderSearchableProps {
    title: string;
    button?: {
        id: string;
        label: string;
        onClick: () => void;
    };
    filter?: {
        options: SelectionBarProps['options'];
        selectedId: SelectionBarProps['selectedId'];
        onSelect: SelectionBarProps['onSelect'];
    };
    search: {
        placeholder: string;
        onSearch: (text: string) => void;
        id?: string;
    };
}

export const HeaderSearchable: React.FunctionComponent<
    HeaderSearchableProps
> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid container className={classes.titleRow}>
                    <Grid item xs>
                        <Typography variant="h1" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Grid>
                    {props.button && (
                        <Grid item xs="auto">
                            <Button
                                leftIcon={faAdd}
                                id={props.button.id}
                                label={props.button.label}
                                onClick={props.button.onClick}
                            />
                        </Grid>
                    )}
                </Grid>
                {props.filter && (
                    <Grid item xs={12} className={classes.filterCol}>
                        <SelectionBar
                            options={props.filter.options}
                            selectedId={props.filter.selectedId}
                            className={classes.selectionBar}
                            onSelect={props.filter.onSelect}
                        />
                    </Grid>
                )}
                <Grid item xs={12} className={classes.searchCol}>
                    <HeaderSearchableSearch
                        placeholder={props.search.placeholder}
                        onSearch={props.search.onSearch}
                        id={props.search.id}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
