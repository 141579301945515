import { MarketplacePaymentMethod } from '@spike/payments-model';
import {
	AdminInvoice,
	MarketplaceSMSMessagesStats,
	Subscription,
	SubscriptionQuote
} from 'model/Subscriptions';

export interface SubscriptionsState {
	loading: boolean;
	created?: Subscription;
	quote?: SubscriptionQuote;
	status: SubscriptionsStatus;
	invoices: Array<AdminInvoice>;
	subscriptions: Array<Subscription>;
	paymentMethod?: MarketplacePaymentMethod;
	stats?: MarketplaceSMSMessagesStats;
	showPopup: boolean;
}

export enum SubscriptionsStatus {
	Initial,

	Fetching,
	FetchSuccess,

	Deactivating,
	DeactivateSuccess,

	Saving,
	SaveSuccess,

	FetchingQuote,
	FetchQuoteSuccess,

	FetchingInvoices,
	FetchInvoicesSuccess,

	GettingPaymentMethod,
	GetPaymentMethodSuccess,

	GettingSMSMessagesStats,
	GetSMSMessagesStatsSuccess,

	Error
}
