import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { SelectBusinessArea } from './SelectBusinessArea';

interface Props {
	businessArea?: Option<string>;
	options: Array<Option<string>>;
	onChange: (businessArea: Option<string>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		descriptionContainer: {
			display: 'flex',
			width: '100%'
		},
		description: {
			fontSize: 20,
			fontWeight: 600,

			[theme.breakpoints.down('sm')]: {
				marginBottom: 10
			}
		},
		optionsContainer: {
			width: '100%',
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				marginTop: '22px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '30px'
			}
		},
		checkbox: {
			'& p': {
				fontSize: 16
			},
			'& svg': {
				[theme.breakpoints.down('sm')]: {
					color: '#000'
				}
			}
		}
	})
);

export const BusinessArea: FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.descriptionContainer}>
				<Typography className={classes.description}>
					Business Area
				</Typography>
			</Box>
			<Box className={classes.optionsContainer}>
				<SelectBusinessArea
					options={props.options}
					selected={props.businessArea}
					className={classes.checkbox}
					onChange={props.onChange}
				/>
			</Box>
		</Box>
	);
};

export default BusinessArea;
