import { Box } from "@material-ui/core";
import { FieldError, Option } from "@spike/model";
import clsx from "clsx";
import { SelectField } from "components/UI";
import { useMasterData } from "hooks";
import { FunctionComponent } from "react";

interface Props {
  id?: string;
  name?: string;
  selected?: Option<string>;
  required?: boolean;
  errors?: Array<FieldError>;
  className?: string;
  disabled?: boolean;
  onChange?: (gender?: Option<string>, fieldName?: string) => void;
}

export const PetGenderField: FunctionComponent<Props> = (props) => {
  const masterData = useMasterData();

  const options: Array<Option<string | number>> = masterData.petGenders.map((pt) => ({ id: pt.id, name: pt.name }));

  const onSelectHandler = (option: Option<string | number>, fieldName?: string) => {
    props.onChange && props.onChange({ id: option.id.toString(), name: option.name }, fieldName);
  };

  return (
    <Box className={clsx(props.className)}>
      <SelectField
        id={props.id}
        label={"Gender"}
        placeholder={"Gender"}
        selectedOption={props.selected}
        options={options}
        onSelect={onSelectHandler}
        required={props.required}
        errors={props.errors}
        name={props.name}
        disabled={props.disabled}
      />
    </Box>
  );
};

export default PetGenderField;
