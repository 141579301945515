import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Button } from 'components/UI';
import { FunctionComponent } from 'react';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useCommonStyles } from '../CommonStyles';
import clsx from 'clsx';

interface HeaderProps {
    className?: string;
    buttonLabel: string;
    title: string;
    id?: string;
    onClick?: () => void;
    onBack?: () => void;
    showButton?: boolean;
    isButtonDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#fafafa',
            [theme.breakpoints.down('xs')]: {
                background: '#fff'
            }
        },
        title: {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '24px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '24px'
            }
        },
        button: {
            'height': 55,
            'padding': '0px 20px',
            '& .MuiButton-startIcon': {
                '& svg': {
                    width: 16,
                    height: 16,
                    [theme.breakpoints.down('lg')]: {
                        width: 14,
                        height: 14
                    }
                }
            },
            '& span': {
                fontSize: 18,
                fontWeight: 600
            },
            [theme.breakpoints.down('lg')]: {
                'height': 39,
                'padding': '0px 20px',
                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        icon: {
            cursor: 'pointer',
            fontSize: 22,
            [theme.breakpoints.up('md')]: {
                fontSize: 24
            }
        }
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    return (
        <Box className={clsx([classes.container, props.className])}>
            <Box>
                {props.onBack && (
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={classes.icon}
                        onClick={props.onBack}
                    />
                )}
                <Typography className={classes.title}>{props.title}</Typography>
            </Box>
            {props.showButton && props.onClick && (
                <Button
                    size="large"
                    className={commonClasses.addButton}
                    label={props.buttonLabel}
                    onClick={props.onClick}
                    disabled={props.isButtonDisabled}
                />
            )}
        </Box>
    );
};

export default Header;
