import React, { FunctionComponent } from 'react';
import ResumeSection, { BodyResumeSection } from '../ResumeSection';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Service from 'model/Service';
import { Chip } from 'components/UI';
import Area from './Area';

interface ResumeDetailsProps {
    service: Service;
    onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 16,
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column'
            }
        },
        tags: {
            gap: 10,
            display: 'flex',
            flexWrap: 'wrap'
        },
        variablesContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        variableDurationContainer: {
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                marginTop: '4px',
                marginBottom: '4px'
            }
        },
        variableNameContainer: {
            display: 'flex',
            width: '30%',
            [theme.breakpoints.down('md')]: {
                width: '40%'
            }
        },
        variableValueContainer: {
            display: 'flex',
            width: '70%',
            [theme.breakpoints.down('md')]: {
                width: '60%'
            }
        }
    })
);

export const ResumeDetails: FunctionComponent<ResumeDetailsProps> = props => {
    const classes = useStyles();

    const tags = (
        <Box className={classes.tags}>
            {props.service.tags.map(tag => (
                <Box key={tag}>
                    <Chip label={tag} />
                </Box>
            ))}
        </Box>
    );

    const hours =
        props.service.duration.simple?.hours === 0
            ? ''
            : props.service.duration.simple?.hours === 1
            ? `${props.service.duration.simple?.hours} hour`
            : `${props.service.duration.simple?.hours} hours`;
    const minutes =
        props.service.duration.simple?.minutes === 0
            ? ''
            : props.service.duration.simple?.minutes === 1
            ? `${props.service.duration.simple?.minutes} minute`
            : `${props.service.duration.simple?.minutes} minutes`;

    const simpleDuration = props.service.duration.simple
        ? `${hours} ${minutes}`
        : 'By pet size';

    const durationMessage = (hours: number, minutes: number) => {
        let mensaje = '';

        if (hours > 0) {
            if (hours === 1) {
                minutes === 0
                    ? (mensaje = hours + '  hour')
                    : minutes === 1
                    ? (mensaje = hours + '  hour ' + minutes + ' minute')
                    : (mensaje = hours + '  hour ' + minutes + ' minutes');
            } else {
                minutes === 0
                    ? (mensaje = hours + '  hours')
                    : minutes === 1
                    ? (mensaje = hours + '  hours ' + minutes + ' minute')
                    : (mensaje = hours + '  hours ' + minutes + ' minutes');
            }
        } else {
            minutes === 0
                ? (mensaje = '')
                : minutes === 1
                ? (mensaje = minutes + ' minute')
                : (mensaje = minutes + ' minutes');
        }

        return mensaje;
    };

    const variableDuration = props.service.duration.simple ? undefined : (
        <Box className={classes.variablesContainer}>
            {props.service.duration.variable
                .filter(vp => vp.petSize.name !== undefined)
                .map(variable => (
                    <Box
                        key={variable.petSize.id}
                        className={classes.variableDurationContainer}
                    >
                        <Box className={classes.variableNameContainer}>
                            {variable.petSize.name}
                        </Box>
                        <Box className={classes.variableValueContainer}>
                            {durationMessage(
                                variable.duration.hours,
                                variable.duration.minutes
                            )}
                        </Box>
                    </Box>
                ))}
        </Box>
    );

    const serviceHoursCompleted =
        (props.service.duration.simple &&
            props.service.duration.simple.hours +
                props.service.duration.simple.minutes !==
                0) ||
        props.service.duration.variable.length !== 0;

    const serviceNameCompleted =
        props.service.name && props.service.name.length !== 0;

    return (
        <ResumeSection
            title="Service"
            pending={!(serviceNameCompleted && serviceHoursCompleted)}
            onEdit={props.onEdit}
        >
            <BodyResumeSection>
                <Box className={classes.container}>
                    <Area title="Service" info={props.service.name}>
                        {tags}
                    </Area>
                    <Area
                        title="Duration"
                        info={simpleDuration}
                        leftBorder={true}
                        className="noMarginBottom"
                    >
                        {variableDuration}
                    </Area>
                </Box>
            </BodyResumeSection>
        </ResumeSection>
    );
};

export default ResumeDetails;
