import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError } from "@spike/model";
import { CheckboxField2, Link, OnlyDigitsField2, PhoneField3, SelectField, TextField2 } from "components/UI";
import { useMasterData } from "hooks";
import set from "lodash/set";
import { PaymentsBusinessProfile as BusinessProfileModel } from "@spike/payments-model";
import { FunctionComponent } from "react";
import { wbp } from "Theme";
import AddressForm from "../AddressForm";
import { useCommonStyles } from "../CommonStyles";

interface BusinessProfileFormProps {
  businessProfile: BusinessProfileModel;
  errors: Array<FieldError>;
  className?: string;
  onChange: (businessProfile: BusinessProfileModel) => void;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "20px !important",
      },
    },
    termsOfService: {
      display: "flex",
      alignItems: "center",
    },
    checkbox: {
      marginRight: "10px",
    },
    dialogTitle: {
      "& h2": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    closeButton: {
      cursor: "pointer",
    },
    termsOfServiceTitle: {
      fontFamily: "Poppins",
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: "19px",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
      },
    },
    termsOfServiceText: {
      fontFamily: "Poppins",
      [theme.breakpoints.down(wbp)]: {
        fontSize: "16px",
        marginBottom: "33px",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
        marginBottom: "50px",
      },
    },
    error: {
      color: "#EF4F57",
    },
    accept: {
      fontFamily: "Poppins",
      [theme.breakpoints.down(wbp)]: {
        fontSize: "12px !important",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px !important",
      },
    },
    link: {
      [theme.breakpoints.up(wbp)]: {
        marginTop: "4px !important",
      },
      "& span": {
        fontFamily: "Poppins",
        [theme.breakpoints.down(wbp)]: {
          fontSize: "12px !important",
        },
        [theme.breakpoints.up(wbp)]: {
          fontSize: "15px !important",
        },
      },
    },
  })
);

export const BusinessProfileForm: FunctionComponent<BusinessProfileFormProps> = (props) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const masterData = useMasterData();

  const phoneTypes = [...masterData.payments.phoneTypes];

  const changeValueHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedBusinessProfile = { ...props.businessProfile };
      set(updatedBusinessProfile, fieldName, value);
      props.onChange(updatedBusinessProfile);
    }
  };

  const showLegalTermsHandler = () => {
    window.open(process.env.REACT_APP_PAYMENTS_LEGAL_TERMS_URL);
  };

  return (
    <Grid container spacing={5} className={props.className}>
      <Grid item xs={12} md={6} className={commonClasses.formCell}>
        <TextField2
          label="Legal Business Name"
          placeholder="Legal Business Name"
          name="legalName"
          value={props.businessProfile.legalName}
          onChange={changeValueHandler}
          errors={props.errors}
          upperCase={true}
          required={true}
        />
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.formCell}>
        <TextField2
          label="Trade Name"
          placeholder="Trade Name"
          name="tradeName"
          value={props.businessProfile.tradeName}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
        />
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.formCell}>
        <OnlyDigitsField2
          label="EIN"
          placeholder="EIN"
          name="einNumber"
          value={props.businessProfile.einNumber}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
          maxLength={9}
        />
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.formCell}>
        <TextField2
          label="Email"
          placeholder="Email"
          name="email"
          value={props.businessProfile.email}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
        />
      </Grid>
      <Grid item xs={12} className={commonClasses.formCell}>
        <TextField2
          label="Website"
          placeholder="Website"
          name="website"
          value={props.businessProfile.website}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
        />
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.formCell}>
        <SelectField
          label="Phone Type"
          name="phoneType"
          placeholder="Select"
          options={phoneTypes}
          selectedOption={props.businessProfile.phoneType}
          onSelect={changeValueHandler}
          errors={props.errors}
          required={true}
        />
      </Grid>
      <Grid item xs={12} md={6} className={commonClasses.formCell}>
        <PhoneField3
          label="Phone Number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={props.businessProfile.phoneNumber || ""}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
        />
      </Grid>
      <Grid item container xs={12}>
        <AddressForm
          address={props.businessProfile.address}
          onChange={(address) => changeValueHandler(address, "address")}
          errors={props.errors}
        />
      </Grid>
      <Grid item xs={12} className={classes.termsOfService}>
        <CheckboxField2
          name="termsOfService"
          checked={props.businessProfile.termsOfService}
          onChange={changeValueHandler}
          errors={props.errors}
          className={classes.checkbox}
          labelPosition="top"
        />
        <span className={classes.accept}>I accept</span>
        <Link onClick={showLegalTermsHandler} className={classes.link} color="black">
          Terms & Conditions *
        </Link>
        {props.errors.some((error) => error.fieldName === "termsOfService") && (
          <span className={classes.error}>
            {props.errors.find((error) => error.fieldName === "termsOfService")?.errorMessage}
          </span>
        )}
      </Grid>
    </Grid>
  );
};

export default BusinessProfileForm;
