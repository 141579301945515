import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
          display: "flex",
        },
        dataContainer: {
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            width: "180px"
          },
          [theme.breakpoints.only("lg")]: {
            width: "240px"
          },
          [theme.breakpoints.only("xl")]: {
            width: "364px"
          },
        },
        dateDataContainer: {
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            width: "210px"
          },
          [theme.breakpoints.only("lg")]: {
            width: "280px"
          },
          [theme.breakpoints.only("xl")]: {
            width: "424px"
          },
        },
        invoiceNumberContainer: {
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            width: "149px",
            paddingLeft: "8px"
          },
          [theme.breakpoints.only("lg")]: {
            width: "198px",
            paddingLeft: "10px"
          },
          [theme.breakpoints.only("xl")]: {
            width: "300px",
            paddingLeft: "18px"
          },
        },
        titleSection: {
          color: "#A3A3A3",
          [theme.breakpoints.down("md")]: {
            fontSize: "5px",
            marginBottom: "4px"
          },
          [theme.breakpoints.only("lg")]: {
            fontSize: "6px",
            marginBottom: "6px"
          },
          [theme.breakpoints.only("xl")]: {
            fontSize: "10px",
            marginBottom: "10px"
          },
        },
        text: {
          [theme.breakpoints.down("md")]: {
            fontSize: "4px",
            marginBottom: "4px"
          },
          [theme.breakpoints.only("lg")]: {
            fontSize: "5px",
            marginBottom: "5px"
          },
          [theme.breakpoints.only("xl")]: {
            fontSize: "8px",
            marginBottom: "8px"
          },
        },
    })
);

export const SectionDataInvoice: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.container}>
        <Box className={classes.dataContainer}>
            <Typography className={classes.titleSection}>PET PARENT</Typography>
            <Box className={classes.text}>
              Lucia Diaz
            </Box>
            <Box className={classes.text}>
              (786) 123 - 2345
            </Box>
        </Box>
        <Box className={classes.dateDataContainer}>
            <Typography className={classes.titleSection}>APP DATE & TIME</Typography>
            <Box className={classes.text}>
              Tuesday, March 12th 2020
            </Box>
            <Box className={classes.text}>
              4:30 pm
            </Box>
        </Box>
        <Box className={classes.invoiceNumberContainer}>
            <Typography className={classes.titleSection}>INVOICE NUMBER</Typography>
            <Box className={classes.text}>
              1230987-09 
            </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default SectionDataInvoice;
