import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, Link } from 'components/UI';
import React, { FunctionComponent } from 'react';
import { PropsWithChildren } from 'react';
import { wbp } from 'Theme';

interface StepFrameProps extends PropsWithChildren {
	title?: string | JSX.Element;
	description?: string | JSX.Element;
	errors?: Array<string>;
	saving?: boolean;
	btnId: string;
	className?: string;
	onNext?: () => void;
	onPrevious?: () => void;
	onCompleted?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			width: '100%'
		},
		title: {
			width: '100%',
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: '24px'
			}
		},
		description: {
			width: '100%',
			fontSize: 14,
			lineHeight: 1.4,
			fontWeight: 400,
			color: '#000000',
			marginTop: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 15,
				marginTop: 10
			}
		},
		head: {
			padding: '24px 16px',

			[theme.breakpoints.up('md')]: {
				padding: '56px 142px 25px 178px'
			}
		},
		body: {
			padding: '0px 16px',

			[theme.breakpoints.up('md')]: {
				borderRadius: 24,
				border: '1px solid #D3D3D3',
				margin: '0px 142px 0px 178px',
				padding: '50px 37px 78px 37px'
			}
		},
		footer: {
			display: 'flex',
			justifyContent: 'flex-end',
			margin: '0px 142px 0px 178px',
			padding: '50px 0px 20px 0px',
			gap: 20,
			[theme.breakpoints.down('sm')]: {
				'gap': 10,
				'justifyContent': 'space-between',
				'margin': 0,
				'padding': '20px 16px',
				'& button ': {
					'width': 'auto',
					'height': 54,
					'flexGrow': 1,
					'& > span': {
						fontSize: '16px !important'
					}
				}
			}
		},
		buttonBlack: {
			'background': '#000',
			'borderRadius': 30,
			'& span': {
				color: '#fff',
				fontSize: '16px !important',
				fontWeight: 600
			}
		},
		errors: {
			[theme.breakpoints.down(wbp)]: {
				// padding: '16px 228px 0px 228px'
				paddingTop: 16,
				paddingLeft: 159
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: 16,
				paddingLeft: 159
			},
			[theme.breakpoints.down('sm')]: {
				paddingTop: 16,
				paddingLeft: 0
			}
		},
		error: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			width: '100%',
			textAlign: 'left',
			padding: '0 16px',
			color: '#EF4F57',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				lineHeight: '34px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '14px',
				lineHeight: '42px'
			}
		}
	})
);
export const StepFrame: FunctionComponent<StepFrameProps> = props => {
	const classes = useStyles();

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.head}>
				{props.title &&
					(typeof props.title === 'string' ? (
						<Typography className={classes.title}>
							{props.title}
						</Typography>
					) : (
						props.title
					))}
				{props.description &&
					(typeof props.description === 'string' ? (
						<Typography className={classes.description}>
							{props.description}
						</Typography>
					) : (
						props.description
					))}
			</Box>
			<Box className={classes.body}>{props.children}</Box>
			{props.errors && props.errors.length > 0 && (
				<Box className={classes.errors}>
					{props.errors.map((error, index) => (
						<Typography key={index} className={classes.error}>
							{error}
						</Typography>
					))}
				</Box>
			)}
			<Box className={classes.footer}>
				{props.onPrevious && (
					<Link
						className={isMobile ? classes.buttonBlack : undefined}
						label="Back"
						color="black"
						onClick={props.onPrevious}
					/>
				)}
				{props.onNext && (
					<Button
						label="Next"
						color="green"
						id={props.btnId}
						onClick={props.onNext}
						loading={props.saving}
					/>
				)}
				{props.onCompleted && (
					<Button
						label="Complete!"
						color="green"
						endIcon={faCheck}
						onClick={props.onCompleted}
						loading={props.saving}
					/>
				)}
			</Box>
		</Box>
	);
};

export default StepFrame;
