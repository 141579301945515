import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import Header from "rocket/components/Header";
import LeftColumn from "rocket/components/Login/LeftColumn";
import RightColumn from "rocket/components/ResetPassword/RightColumn";
import SmallPrimaryButton from "rocket/components/Buttons/SmallPrimaryButton";
import HeaderBackLink from "rocket/components/Commons/HeaderBackLink";
import { LoginPath, SignUpPath } from "routes/Paths";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headerButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const ResetPassword: FunctionComponent = () => {
  const classes = useStyles();

  useEffect(() => {
    pageView("/forgotpassword");
  }, []);

  document.title = "Reset password - Cuddles";

  return (
    <div id="login">
      <Header
        containerStyles={classes.headerContainer}
        backLink={<HeaderBackLink to={LoginPath} />}
        rightLink={<SmallPrimaryButton className={classes.headerButton} to={SignUpPath} label="Get started" />}
      />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <LeftColumn />
          <RightColumn />
        </Grid>
      </div>
    </div>
  );
};

export default ResetPassword;
