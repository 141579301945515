import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Staff from 'model/Staff';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { NewBookingAlert, PetService } from '@spike/new-booking-model';
import { AlertNewBooking } from '../UI/AlertNewBooking';
import StaffComponent from './StaffComponent';
import clsx from 'clsx';

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface Props {
    summary: boolean;
    service?: PetServiceWrapper;
    selectedStaff?: number;
    loginStaff: number;
    alerts?: Array<NewBookingAlert>;
    readOnly?: boolean;
    firstLoad?: boolean;
    detailView?: boolean;
    multiplePets?: boolean;
    onSelectStaff: (staff?: Staff, first?: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderBottom: (props: Props) =>
                props.multiplePets ? 'none' : '1px solid #D4D4D4',
            paddingBottom: 18,
            [theme.breakpoints.up('md')]: {
                paddingBottom: 20
            }
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        alert: {
            marginBottom: '0px !important',
            marginTop: 18,
            [theme.breakpoints.up('md')]: {
                marginTop: 20
            }
        },
        alertText: {
            fontSize: 14,
            lineHeight: '20px'
        },
        withoutBorder: {
            borderBottom: 'none !important'
        }
    })
);

export const StaffSelector: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const ref = useRef<HTMLDivElement>(null);

    const [staffSelected, setStaffSelected] = useState<Staff | undefined>();
    const [alerts, setAlerts] = useState<Array<NewBookingAlert>>([]);

    const allActiveStaff = useSelector<RootState, Array<Staff>>(
        state => state.staff.staff
    ).filter(member => member.active && !member.deleted);

    const allActiveStaffForService = allActiveStaff.filter(member =>
        member.services.some(
            service => service.id === props.service?.service.id
        )
    );

    const getAlertMessage = (message: string) => {
        if (message === 'Staff not working on that date/time') {
            return `Conflicts with ${staffSelected?.person.firstName} ${staffSelected?.person.lastName}’s working hours.`;
        } else if (message === 'Staff busy') {
            return `${staffSelected?.person.firstName} ${staffSelected?.person.lastName} is already book at this time, but you can still double-book appointments for them.`;
        } else {
            return message;
        }
    };

    useEffect(() => {
        setStaffSelected(
            allActiveStaffForService.find(
                staff => props.selectedStaff === staff.id
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedStaff]);

    useEffect(() => {
        if (!props.summary && !props.detailView) {
            if (props.readOnly) {
                setStaffSelected(
                    allActiveStaff.find(
                        staff => props.selectedStaff === staff.id
                    )
                );
                props.onSelectStaff(
                    allActiveStaff.find(
                        staff => props.selectedStaff === staff.id
                    )
                );
            } else if (!props.firstLoad) {
                setStaffSelected(undefined);
                props.onSelectStaff(undefined);
            } else {
                const loginUserOption = allActiveStaffForService.find(
                    staff => props.loginStaff === staff.id
                );

                if (loginUserOption) {
                    setStaffSelected(loginUserOption);
                    props.onSelectStaff(loginUserOption);
                } else {
                    setStaffSelected(undefined);
                    props.onSelectStaff(undefined);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.service]);

    useEffect(() => {
        if (!props.readOnly) {
            ref?.current?.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, props.service]);

    useEffect(() => {
        if (props.alerts) {
            const alertBusy = props.alerts.find(
                alert => alert.message === 'Staff busy'
            );
            if (alertBusy) {
                setAlerts([alertBusy]);
            } else {
                setAlerts(props.alerts);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.alerts]);

    return (
        <Box
            className={clsx(classes.container, {
                [classes.withoutBorder]: props.detailView
            })}
        >
            <div ref={ref}>
                <StaffComponent
                    selectedOption={staffSelected}
                    options={allActiveStaffForService}
                    onSelectStaff={(staff: Staff) =>
                        props.onSelectStaff(staff, true)
                    }
                />
            </div>
            {alerts?.map(alert => (
                <Box
                    key={`booking_div_${alert.alertType}_alert`}
                    id={`booking_div_${alert.alertType}_alert`}
                >
                    <AlertNewBooking
                        className={classes.alert}
                        bWidth="2px"
                        icon={faExclamationTriangle}
                        iconSize="lg"
                        iconColor="#EAB464"
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                    >
                        <Typography className={classes.alertText}>
                            {getAlertMessage(alert.message)}
                        </Typography>
                    </AlertNewBooking>
                </Box>
            ))}
        </Box>
    );
};

export default StaffSelector;
