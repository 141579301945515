import { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Product } from "@spike/product-model";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
  onEdit: () => void;
  product?: Product;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      background: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        borderRadius: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "24px",
      },
    },
    headerContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        height: "60px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "74px",
      },
    },
    centerHeaderContainer: {
      display: "flex",
      flex: "1 1 auto",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "29px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "37px",
      },
    },
    rightHeaderContainer: {
      display: "flex",
      justifyContent: "flex-star",
      cursor: "pointer",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "85px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "107px",
      },
    },
    check: {
      color: "#92B4A7",
    },
    exclamation: {
      color: "black",
    },
    exclamationStaff: {
      color: "#EAB464"
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
      },
    },
    iconEdit: {
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        marginRight: "6px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        marginRight: "8px"
      },
    },
    editLink: {
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  })
);

export const Section: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Box className={classes.centerHeaderContainer}>
          <Typography className={classes.title}>{props.title}</Typography>
        </Box>
        <Box className={classes.rightHeaderContainer} onClick={props.onEdit}>
          <FontAwesomeIcon icon={faPencil} className={classes.iconEdit} />
          <Typography className={classes.editLink}>Edit</Typography>
        </Box>
      </Box>
      <Box className={classes.bodyContainer}>{props.children}</Box>
    </Box>
  );
};

export default Section;
