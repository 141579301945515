/* eslint-disable react/no-unescaped-entities */
import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Option, PetType } from '@spike/model';
import {
    ServiceDuration as Duration,
    Duration as SimpleDuration
} from 'model/Service';
import { Checkbox } from 'components/UI';
import ServiceDurationValues from './ServiceDurationValues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { MobileDivider } from 'components/UI/MobileDivider';
import Switch from 'components/UI/V2/Switch';
import { FieldErrorWithKey } from '../model';
import { isEmpty } from 'lodash';
interface Props {
    duration: Duration;
    petSizes: Array<Option<string>>;
    errors: Array<FieldErrorWithKey>;
    petType?: Array<PetType>;
    onChange: (durarion: Duration) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        title: {
            'fontSize': 20,
            'lineHeight': 1,
            'fontWeight': 600,
            'marginBottom': 16,
            '& span': {
                fontWeight: 600,
                color: '#EAB464'
            }
        },
        subtitle: {
            fontSize: 14,
            color: '#000000',
            marginBottom: 16,

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        switchContainer: {
            gap: 16,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                padding: '20px 0px',
                justifyContent: 'space-between',
                borderBottom: 'solid 1px #D4D4D4'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '20px'
            }
        },
        switchText: {
            fontSize: 16,
            fontWeight: 500
        },
        simple: {
            display: 'flex',
            alignContent: 'flex-start',
            marginTop: 24,
            flexDirection: 'column'
        },
        containerMessage: {
            gap: 10,
            width: '100%',
            display: 'flex',
            borderRadius: 10,
            flexDirection: 'row',
            backgroundColor: '#F8F5F1',
            padding: '19px 20px 19px 16px',
            border: '2px solid #BCB8AE'
        },
        iconMessage: {
            fontSize: '1.3rem',
            marginInline: '3px',
            marginTop: '2px'
        },
        textMessage: {
            fontSize: 14,
            fontWeight: 400,

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            }
        },
        variables: {
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.up('md')]: {
                gap: 8
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '20px'
            }
        },
        variable: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                'height': 78,

                '&:not(:last-child)': {
                    borderBottom: 'solid 1px #D4D4D4'
                },

                '& .MuiFormControlLabel-root + p': {
                    paddingLeft: 35
                }
            },
            [theme.breakpoints.up('md')]: {
                gap: 8
            }
        },
        variableSelection: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
        },
        variableDuration: {
            display: 'flex',
            flexDirection: 'column'
        },
        errorMessage: {
            color: 'red',
            marginBottom: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        }
    })
);

const emptyDuration = { hours: 0, minutes: 0 };

export const ServiceDuration: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [showVariable, setShowVariable] = useState(
        props.duration.simple === undefined
    );

    const selectedPetSizes = props.duration.variable.map(
        variableDuration => variableDuration.petSize.id
    );

    const changeSwitchHandler = (name: string, checked: boolean) => {
        props.onChange({
            simple: checked ? undefined : emptyDuration,
            variable: []
        });

        setShowVariable(checked);
    };

    const changeSimpleDurationHandler = (
        simpleDuration: SimpleDuration
    ): void => {
        props.onChange({
            simple: simpleDuration,
            variable: []
        });
    };

    const changeVariableDurationHandler = (
        petSize: Option<string>,
        duration: SimpleDuration
    ) => {
        const variable = props.duration.variable.slice();
        variable.forEach(variableDuration => {
            if (variableDuration.petSize.id === petSize.id) {
                variableDuration.duration = duration;
            }
        });

        props.onChange({
            simple: undefined,
            variable
        });
    };

    const selectionVariableDurationHandler = (
        petSize: Option<string>,
        selected: boolean
    ) => {
        const variable = props.duration.variable.filter(
            variableDuration => variableDuration.petSize.id !== petSize.id
        );

        if (selected) {
            variable.push({
                petSize,
                duration: {
                    hours: 0,
                    minutes: 0
                }
            });
        }

        props.onChange({
            ...props.duration,
            variable
        });
    };

    const getDuration = (petSize: Option<string>) => {
        const variableDuration = props.duration.variable.find(
            variable => variable.petSize.id === petSize.id
        );
        return variableDuration ? variableDuration.duration : emptyDuration;
    };
    const simpleDuration = (
        <Box className={classes.simple}>
            {!showVariable &&
                !isEmpty(props.errors) &&
                props.duration.simple?.hours === 0 &&
                props.duration.simple.minutes === 0 && (
                    <Typography className={classes.errorMessage}>
                        {props.errors[0].errorMessage}
                    </Typography>
                )}
            <ServiceDurationValues
                error={
                    !showVariable &&
                    !isEmpty(props.errors) &&
                    props.duration.simple?.hours === 0 &&
                    props.duration.simple.minutes === 0
                }
                duration={props.duration.simple! || 0}
                onChange={changeSimpleDurationHandler}
            />
        </Box>
    );

    const variablesDuration = (
        <Box className={classes.variables}>
            {props.petSizes.map(petSize => {
                const error = props.errors.find(
                    error => error.id === petSize.id
                );
                const validField = props.duration.variable.find(
                    duration => duration.petSize.id === petSize.id
                );
                return (
                    <Box key={petSize.id} className={classes.variable}>
                        <Box className={classes.variableSelection}>
                            <Checkbox
                                label={petSize.name}
                                description={petSize.description}
                                checked={selectedPetSizes.includes(petSize.id)}
                                onChange={selected => {
                                    selectionVariableDurationHandler(
                                        petSize,
                                        selected
                                    );
                                }}
                            />
                            {!isEmpty(props.errors) &&
                                isEmpty(selectedPetSizes) && (
                                    <Typography
                                        className={classes.errorMessage}
                                    >
                                        Duration is required
                                    </Typography>
                                )}
                        </Box>
                        <Box className={classes.variableDuration}>
                            {selectedPetSizes.includes(petSize.id) && (
                                <ServiceDurationValues
                                    small={true}
                                    error={
                                        error &&
                                        !isEmpty(props.errors) &&
                                        validField?.duration.hours === 0 &&
                                        validField?.duration.minutes === 0
                                    }
                                    duration={getDuration(petSize)}
                                    onChange={duration => {
                                        changeVariableDurationHandler(
                                            petSize,
                                            duration
                                        );
                                    }}
                                />
                            )}
                            {error &&
                                !isEmpty(error) &&
                                validField?.duration.hours === 0 &&
                                validField?.duration.minutes === 0 && (
                                    <Typography
                                        className={classes.errorMessage}
                                    >
                                        {error.errorMessage}
                                    </Typography>
                                )}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );

    useEffect(() => {
        if (props.petType && props.petType.length > 1) {
            setShowVariable(false);
        } else if (props.petType && props.petType.length === 1) {
            if (
                props.petType[0].id.toLocaleLowerCase() !== 'dogs' &&
                props.duration.variable?.length > 0
            ) {
                props.onChange({
                    simple: emptyDuration,
                    variable: []
                });
                setShowVariable(false);
            }
        }
    }, [props.petType]);

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>
                Service Duration <span>*</span>
            </Typography>
            <Typography className={classes.subtitle}>
                How long does it take to perform the service?
            </Typography>
            {props.petType && props.petType.length === 1 ? (
                props.petType[0].id.toLocaleLowerCase() === 'dogs' && (
                    <Box className={classes.switchContainer}>
                        <Typography className={classes.switchText}>
                            Set time by pet size
                        </Typography>
                        <Switch
                            checked={showVariable}
                            onCheck={changeSwitchHandler}
                        />
                    </Box>
                )
            ) : (
                <Box className={classes.containerMessage}>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#BCB8AE"
                        className={classes.iconMessage}
                    />
                    <Typography className={classes.textMessage}>
                        If you'd like to set duration by size, please create a
                        service for only one pet type.
                    </Typography>
                </Box>
            )}
            {!showVariable && simpleDuration}
            {showVariable && variablesDuration}

            <Box style={{ margin: '0px -16px' }}>
                <MobileDivider />
            </Box>
        </Box>
    );
};

export default ServiceDuration;
