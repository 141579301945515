import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Pet from '@spike/pet-model';
import HeaderSiblings from '../UI/HeaderSiblings';
import Client from '@spike/client-model';
import Booking from '@spike/booking-model';
import Appointment from '@spike/appointment-model';
import uniq from 'lodash/uniq';
import PetCard from 'components/UI/PetCard';

export interface PetInfoProps {
    pet?: Pet;
    client?: Client;
    booking?: Booking;
    appointment?: Appointment;
    onPetSelect: (petId: number) => void;
    onShowPetCard: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            padding: '18px 0px 18px 0px'
        },
        headerContainer: {
            border: '1px solid #D4D4D4',
            borderRadius: 8,
            padding: 16,
            display: 'flex',
            flexDirection: 'row'
        }
    })
);

export const PetInfo: FunctionComponent<PetInfoProps> = props => {
    const classes = useStyles();
    const [uniquePet, setUniquePet] = useState<boolean>(true);

    const getPetsFromBooking = () => {
        return props.booking?.appointments.map(function (d) {
            return d['pet'].id;
        });
    };

    useEffect(() => {
        const isUniquePet =
            uniq(
                props.booking?.appointments.map(
                    appointment => appointment.pet.id
                )
            ).length === 1;
        setUniquePet(isUniquePet);
    }, [props.booking]);

    return (
        <Box className={clsx(classes.container)}>
            <Typography className={classes.title}>Pets</Typography>
            {!uniquePet && (
                <HeaderSiblings
                    client={props.client}
                    booking={props.booking}
                    appointment={props.appointment}
                    uniquePet={uniquePet}
                    petsInBooking={getPetsFromBooking()!}
                    //
                    onPetSelect={props.onPetSelect}
                />
            )}
            <Box className={classes.headerContainer}>
                <PetCard pet={props.pet} onShowPetCard={props.onShowPetCard} />
            </Box>
        </Box>
    );
};

export default PetInfo;
