import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { Spinner } from 'components/UI/Spinner';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { OnboardingStepsState } from 'reducers/onboardingSettings/onboardingStepsState';
import { RootState } from 'store';
import ItemMenu from './ItemMenu';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
    alternativeTitle?: string;
    title: string;
    subtitle: string;
    sections: Array<SidebarSection>;
    selectedItemId: string;
    onSelect: (item: SidebarItem) => void;
    className?: string;
    avatar?: string;
    onClickItemHandler?: () => void;
    popUpOnMobile?: boolean;
}

export interface SidebarSection {
    title: string;
    icon: IconDefinition;
    items: Array<SidebarItem>;
    loading?: boolean;
    path?: string;
    onclick?: () => void;
    toggleClassOnTitle?: boolean;
    sectionAccordion?: boolean;
}

export interface SidebarItem {
    id: string;
    name: string;
    isNew?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: '#fff'
        },
        alternativeTitle: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                fontSize: 20,
                fontWeight: 600,
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        title: (props: Props) => ({
            fontWeight: 500,
            [theme.breakpoints.down('md')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                marginBottom: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '22px'
            },
            ...(props.popUpOnMobile
                ? {
                      [theme.breakpoints.down('md')]: {
                          fontSize: '18px !important',
                          fontWeight: 600
                      }
                  }
                : {})
        }),
        subtitle: (props: Props) => ({
            fontWeight: 300,
            [theme.breakpoints.down('md')]: {
                fontSize: '12px',
                marginBottom: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                marginBottom: '25px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '11px',
                marginBottom: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '16px',
                marginBottom: '41px'
            },
            ...(props.popUpOnMobile
                ? {
                      [theme.breakpoints.down('md')]: {
                          fontSize: '16px !important',
                          fontWeight: 400,
                          color: '#969696',
                          marginTop: '-4px'
                      }
                  }
                : {})
        }),
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                marginBottom: '13px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '17px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '26px'
            }
        },
        linksWrapper: {
            position: 'relative'
        },
        titleSectionContainer: (props: Props) => ({
            display: 'flex',

            [theme.breakpoints.down('md')]: {
                marginBottom: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '8px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '12px'
            },
            ...(props.popUpOnMobile
                ? {
                      [theme.breakpoints.down('sm')]: {
                          display: 'none'
                      }
                  }
                : {})
        }),
        icon: {
            [theme.breakpoints.down('md')]: {
                marginRight: '5px',
                fontSize: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '7px',
                fontSize: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '10px',
                fontSize: '13px'
            }
        },
        titleSection: {
            [theme.breakpoints.down('md')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px'
            }
        },
        sectionContainerAccordion: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '0px',
                paddingTop: '15px',
                paddingBottom: '15px',
                borderTop: 'solid 1px #D4D4D4'
            },
            '& $icon': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: '16px',
                    marginRight: '10px'
                }
            },
            '& $titleSectionContainer': {
                [theme.breakpoints.down('sm')]: {
                    'marginBottom': '0px',
                    'alignItems': 'center',

                    '& ~ $linksWrapper': {
                        'transition': 'all .2s',
                        'maxHeight': '0px',
                        'overflow': 'hidden',
                        '& a:last-child $itemContainer': {
                            marginBottom: '10px'
                        }
                    },
                    '&.active': {
                        '& ~ $linksWrapper': {
                            maxHeight: '400px'
                        }
                    }
                }
            },
            '& $titleSection': {
                [theme.breakpoints.down('sm')]: {
                    'fontSize': '16px',
                    'fontWeight': '500',
                    'textTransform': 'lowercase',
                    '&:first-letter': {
                        textTransform: 'uppercase'
                    }
                }
            },
            '& $itemContainer': {
                [theme.breakpoints.down('sm')]: {
                    'marginBottom': '20px',
                    'marginTop': '15px',
                    '& div': {
                        backgroundColor: '#fff'
                    },
                    '& p': {
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#000'
                    }
                }
            }
        },
        itemContainer: (props: Props) => ({
            [theme.breakpoints.down('md')]: {
                marginBottom: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '9px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '13px'
            },
            ...(props.popUpOnMobile
                ? {
                      [theme.breakpoints.down('sm')]: {
                          'marginBottom': '0px',
                          '& > div': {
                              backgroundColor: '#fff !important',
                              fontweight: '500 !important',
                              borderBottom: 'solid 1px #D4D4D4',
                              paddingTop: '15px',
                              marginBottom: '0px',
                              paddingBottom: '15px',
                              borderRadius: '0px',
                              height: 'auto',
                              paddingLeft: '0px'
                          },
                          '& p': {
                              color: '#000!important',
                              fontSize: '16px',
                              fontWeight: '500',
                              lineHeight: '120%'
                          }
                      }
                  }
                : {})
        }),
        avatar: {
            borderRadius: '50%',
            backgroundImage: (props: Props) => 'url(' + props.avatar + ')',
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.down('md')]: {
                width: '70px',
                height: '70px',
                marginBottom: '7px',
                marginTop: '13px',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100px',
                height: '100px',
                marginBottom: '7px',
                marginTop: '13px',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
            },
            [theme.breakpoints.only('lg')]: {
                width: '53px',
                height: '53px',
                marginBottom: '10px',
                marginTop: '17px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '81px',
                height: '81px',
                marginBottom: '15px',
                marginTop: '26px'
            }
        },
        links: (props: Props) => ({
            textDecoration: 'none',
            position: 'relative',
            ...(props.popUpOnMobile
                ? {
                      [theme.breakpoints.down('sm')]: {
                          'width': '100%',
                          'display': 'flex',
                          '&:last-child': {
                              '& $itemContainer > div': {
                                  borderBottom: 'none'
                              }
                          },
                          '& $itemContainer': {
                              width: '100%'
                          }
                      }
                  }
                : {})
        }),
        spinner: {
            '& .MuiCircularProgress-root ': {
                width: '16px !important',
                height: '16px !important'
            }
        },
        gotolink: (props: Props) => ({
            display: 'none',
            ...(props.popUpOnMobile
                ? {
                      [theme.breakpoints.down('sm')]: {
                          display: 'block',
                          fontSize: '15px',
                          position: 'absolute',
                          right: '0px',
                          top: '14px',
                          color: '#000'
                      }
                  }
                : {})
        })
    })
);

export const Sidebar: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const { url } = useRouteMatch();

    const { onboardingSteps } = useSelector<RootState, OnboardingStepsState>(
        state => state.onboardingSteps
    );

    const {
        businessHoursDone,
        teamDone,
        servicesDone,
        petTypesDone,
        clientsDone,
        bookingDone
    } = onboardingSteps;

    const [itemDisabled, setItemDisabled] = useState(false);

    /* 
	//Legacy code to verify if the onboarding is completed
	const verifyOnBoardingProgress = () => {
		const isOnBoardingCompleted =
			businessHoursDone &&
			teamDone &&
			petTypesDone &&
			servicesDone &&
			clientsDone &&
			bookingDone;
		isOnBoardingCompleted ? setItemDisabled(false) : setItemDisabled(true);
	};
	*/

    const verifyOnBoardingProgress = () => {
        setItemDisabled(false);
    };

    const handleTitleClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        const clickedElement = event.currentTarget;
        const parentElement = clickedElement.parentElement?.parentElement;

        const activeElements = parentElement?.querySelectorAll('.active');
        activeElements?.forEach(element => {
            if (element !== clickedElement) {
                element.classList.remove('active');
            }
        });

        clickedElement.classList.toggle('active');
    };

    const handleItemClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        sectionOnClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
    ) => {
        if (sectionOnClick) {
            sectionOnClick(e);
        }

        if (props.onClickItemHandler) {
            props.onClickItemHandler();
        }
    };

    useEffect(() => {
        verifyOnBoardingProgress();
    }, []);

    useNonInitialEffect(() => {
        verifyOnBoardingProgress();
    }, [onboardingSteps]);

    return (
        <Box
            className={clsx(
                classes.container,
                props.className,
                classes.conditionalStyles
            )}
        >
            {props.avatar && <Box className={classes.avatar} />}
            {props.alternativeTitle && (
                <Typography className={classes.alternativeTitle}>
                    {props.alternativeTitle}
                </Typography>
            )}

            {props.title && typeof props.title != undefined && (
                <Typography className={classes.title}>{props.title}</Typography>
            )}
            {props.subtitle && typeof props.subtitle != undefined && (
                <Typography className={classes.subtitle}>
                    {props.subtitle}
                </Typography>
            )}

            {props.sections.map((section, index) => {
                // Conditionally add the new prop based on sectionAccordion
                const accordionProp = section.sectionAccordion
                    ? { itemAccordion: true }
                    : {};
                return (
                    <Box
                        key={section.title}
                        className={clsx(
                            classes.sectionContainer,
                            section.sectionAccordion &&
                                classes.sectionContainerAccordion
                        )}
                    >
                        <Box
                            onClick={
                                section.toggleClassOnTitle
                                    ? handleTitleClick
                                    : undefined
                            }
                            className={classes.titleSectionContainer}
                        >
                            <FontAwesomeIcon
                                icon={section.icon}
                                className={classes.icon}
                            />
                            <Typography className={classes.titleSection}>
                                {section.title}
                            </Typography>
                        </Box>
                        {section.loading ? (
                            <Spinner className={classes.spinner} />
                        ) : (
                            <Box className={classes.linksWrapper}>
                                {section.items.map(item => (
                                    <Link
                                        key={item.id}
                                        onClick={e =>
                                            handleItemClick(e, section.onclick)
                                        }
                                        to={
                                            itemDisabled
                                                ? '#'
                                                : `${url}/${section.path}/${item.id}`
                                        }
                                        className={classes.links}
                                    >
                                        <Box
                                            id={`business_settings_sidebar_button_${item.id}`}
                                            key={item.id}
                                            className={classes.itemContainer}
                                        >
                                            <ItemMenu
                                                item={item}
                                                selected={props.selectedItemId}
                                                onSelectItem={props.onSelect}
                                                disabled={itemDisabled}
                                                {...accordionProp}
                                            />
                                        </Box>
                                        <FontAwesomeIcon
                                            className={classes.gotolink}
                                            icon={faAngleRight}
                                            size="2x"
                                        />
                                    </Link>
                                ))}
                            </Box>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default Sidebar;
