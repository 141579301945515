import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from 'components/UI';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Img } from 'react-image';
import { wbp } from 'Theme';
import { useHubspotTrackingCode, useMarketplace } from 'hooks';

interface WelcomeProps {
    className?: string;
    onGetStarted?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            height: '100%'
        },
        left: {
            alignItems: 'center',
            backgroundColor: '#F8F5F1',
            [theme.breakpoints.down('md')]: {
                display: 'none'
            },
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'flex-end'
            }
        },
        right: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                alignItems: 'center',
                minHeight: '100%'
            },
            [theme.breakpoints.up('md')]: {
                alignItems: 'flex-start',
                paddingLeft: '74px'
            }
        },
        body: {},
        image: {
            minWidth: '600px'
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#222222',
            letterSpacing: '-0.04em',
            [theme.breakpoints.down(wbp)]: {
                fontSize: '58px',
                lineHeight: '69px'
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '72px',
                lineHeight: '86px'
            }
        },
        subtitle: {
            color: '#EAB464',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: '58px'
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '72px'
            }
        },
        text: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#000000',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down(wbp)]: {
                fontSize: '14px',
                lineHeight: '22px',
                marginTop: '17px'
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                lineHeight: '27px',
                marginTop: '21px'
            }
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: '33px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '41px'
            }
        }
    })
);

export const Welcome: FunctionComponent<WelcomeProps> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();
    const [isFetching, setIsFetching] = useState(false);
    const { setTrackEvent } = useHubspotTrackingCode();

    const handleGetStarted = async () => {
        if (!props.onGetStarted) {
            console.error('onGetStarted function is not provided.');
            return;
        }

        setIsFetching(true);
        setTrackEvent(
            `${process.env.REACT_APP_HUBSPOT_EVENT_CUDDLESPAY_GET_STARTED}`
        );
        props.onGetStarted();
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.left}>
                    <Img
                        src="/images/payments/cuddlespay-welcome.png"
                        className={classes.image}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <Box className={classes.body}>
                        <Typography className={classes.title}>
                            Welcome
                        </Typography>
                        <Typography
                            className={clsx(classes.title, classes.subtitle)}
                        >
                            {marketplace.basics.businessName}!
                        </Typography>

                        <Typography className={classes.text}>
                            You have decided to partner with CuddlesPay for a
                            <br />
                            smoother and easier business management process. As
                            <br />
                            required by payment industry regulations, to set up
                            <br />
                            Cuddles Pay admins will have to go through a<br />
                            verification process to help prevent fraudulent
                            activities
                            <br />
                            in your platform.
                        </Typography>
                    </Box>
                    <Button
                        color="green"
                        label="Get Started"
                        id="cuddlespay-get-started"
                        className={classes.button}
                        onClick={handleGetStarted}
                        disabled={isFetching}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Welcome;
