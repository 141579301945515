import { FunctionComponent, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { Option } from "@spike/model";
import { useMasterData } from "hooks";
import { SelectField } from "components/UI";

interface Props {
  selected?: Option<string>;
  onChange: (hairType?: Option<string>) => void;
  className?: string;
  error?: boolean;
  errorMessage?: string;
}

export const PetAgeField: FunctionComponent<Props> = (props) => {
  const masterData = useMasterData();

  const options: Array<Option<string | number>> = masterData.ageOptions.map((pt) => ({ id: pt.id, name: pt.name }));
  const [optionSelected, setOptionSelected] = useState<Option<string | number>>();

  useEffect(() => {
    if (props.selected) {
      let option: Option<string | number> = { id: props.selected.id, name: props.selected.name };
      setOptionSelected(option);
    }
  }, []);

  const onSelectHandler = (value: Option<string | number>) => {
    const petAge = masterData.ageOptions.find((pt) => pt.id === value.id);
    setOptionSelected(value);
    props.onChange(petAge);
  };

  return (
    <Box className={clsx(props.className)}>
      <SelectField
        label={"Age"}
        placeholder={"Choose Age"}
        selectedOption={optionSelected}
        options={options}
        onSelect={onSelectHandler}
      />
    </Box>
  );
};

export default PetAgeField;
