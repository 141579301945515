import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import clsx from 'clsx';
import { SingleChip } from 'components/UI';
import { FunctionComponent } from 'react';

interface StatusProps {
	status?: Option<string>;
	className?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		default: {
			'backgroundColor': '#FFF8DD',
			'& p': {
				color: '#C18733'
			}
		},
		passed: {
			'backgroundColor': '#5E8677',
			'& p': {
				color: 'white'
			}
		}
	})
);

export const Status: FunctionComponent<StatusProps> = props => {
	const classes = useStyles();

	return (
		<SingleChip
			className={clsx(classes.default, props.className, {
				[classes.passed]: props.status?.id === 'PASSED'
			})}
		>
			{props.status?.name || '-'}
		</SingleChip>
	);
};

export default Status;
