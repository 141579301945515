import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { CalendarFilter } from 'components/UI';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import Filter from 'components/UI/Filter';
import { Option, Period } from '@spike/model';
import { TipsReport } from 'model/TipsReport';

interface HeaderProps {
    period: Period;
    tipsDetail?: TipsReport;
    className?: string;
    onChangePeriod: (period: Period) => void;
    onStaffChange: (staffs: Array<number>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            gap: 20,
            display: 'flex',
            borderRadius: 12,
            padding: '12px 16px',
            backgroundColor: '#F1F1F1',

            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-between'
            },
            [theme.breakpoints.up('md')]: {
                marginTop: 24,
                padding: '14px 20px'
            }
        },
        calendar: {
            '& > button': {
                'height': 39,
                'borderRadius': 30,
                'color': '#222222',
                'border': '2px solid #222222',
                'backgroundColor': 'white',

                [theme.breakpoints.up('md')]: {
                    height: 43
                },

                '&:hover': {
                    backgroundColor: 'white'
                }
            }
        },
        staffFilter: {
            'height': 39,
            'paddingTop': 0,
            'paddingLeft': 13,
            'paddingRight': 13,
            'paddingBottom': 0,
            'borderRadius': 30,
            'backgroundColor': '#000000',
            'border': '2px solid #000000',

            [theme.breakpoints.down('sm')]: {
                'maxWidth': '50%',

                '& .MuiButton-label': {
                    '& span:not(.MuiButton-startIcon)': {
                        width: '100%',

                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }
            },
            [theme.breakpoints.up('md')]: {
                height: 43,
                backgroundColor: '#FFFFFF'
            },

            '& button': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                }
            },
            '& svg': {
                marginRight: 4
            },
            '& .MuiButton-label': {
                color: '#fff',

                [theme.breakpoints.up('md')]: {
                    color: '#000'
                }
            },
            '& .MuiButton-outlined': {
                border: 'none !important'
            },
            '& .MuiButton-startIcon': {
                marginRight: 6
            }
        }
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();
    let staffsLength = 0;

    const getStaffFormatted = (): Array<Option<number>> => {
        const staffAux: Array<Option<number>> = [];

        props.tipsDetail?.invoices?.forEach(tipDetail => {
            tipDetail.tips.forEach(tip => {
                const staffInList = staffAux.find(s => s.id === tip.staffId);

                if (
                    !staffInList &&
                    tip.amount !== '0.00' &&
                    tip.staffId != 0 &&
                    tip.staffName.length > 0
                ) {
                    const itemStaff: Option<number> = {
                        id: tip.staffId,
                        name: tip.staffName,
                        description: tip.staffName
                    };

                    staffAux.push(itemStaff);
                }
            });
        });

        staffsLength = staffAux.length;

        return staffAux;
    };

    const onStaffChange = (ids: Array<number | undefined>) => {
        const staffIds: number[] = ids.filter(
            (id): id is number => id !== undefined
        );

        const allStaffSelected = staffsLength === staffIds.length;

        props.onStaffChange(allStaffSelected ? [] : staffIds);
    };

    return (
        <Grid container className={props.className}>
            <Grid container xs={12} className={classes.bar}>
                <CalendarFilter
                    hideUpcoming
                    disableFuture
                    showLabelOnMobile
                    hideAllTime
                    dropdownAlignment="left"
                    period={{
                        from: props.period.from,
                        to: props.period.to
                    }}
                    optionSelected="This month"
                    onChange={period => props.onChangePeriod({ ...period })}
                    className={classes.calendar}
                />
                <Filter
                    label="Staff"
                    icon={faUsers}
                    options={getStaffFormatted()}
                    onSelect={onStaffChange}
                    showLabelOnMobile={true}
                    className={classes.staffFilter}
                />
            </Grid>
        </Grid>
    );
};

export default Header;
