import { PetMedicalCondition } from "@spike/medical-history-model";
import {
  MedicalCategory as MedicalCategoryModel,
  MedicalCondition as MedicalConditionModel,
} from "./MedicalConditionModel";

export const convertToCategories = (medicalConditions: Array<PetMedicalCondition>): Array<MedicalCategoryModel> => {
  return medicalConditions.reduce<Array<MedicalCategoryModel>>((result, medicalCondition) => {
    const category = result.find((cat) => cat.categoryId === medicalCondition.medicalConditionCategoryId);
    if (category) {
      if (medicalCondition.medicalConditionId) {
        category.conditions.push(convertToCondition(medicalCondition));
      }
    } else {
      result.push({
        categoryId: medicalCondition.medicalConditionCategoryId!,
        name: medicalCondition.categoryName,
        conditions: medicalCondition.medicalConditionId ? [convertToCondition(medicalCondition)] : [],
      });
    }
    return result;
  }, []);
};

export const convertToCondition = (medicalCondition: PetMedicalCondition): MedicalConditionModel => {
  return {
    id: medicalCondition.id!,
    conditionId: medicalCondition.medicalConditionId!,
    name: medicalCondition.conditionName!,
    description: medicalCondition.explanation,
    medications: medicalCondition.medications,
    diagnosis: medicalCondition.howLongAgo,
  };
};

export const convertToPetMedicalCondition = (category: MedicalCategoryModel): Array<PetMedicalCondition> => {
  if(category.conditions.length === 0) {
    return [{
      medicalConditionCategoryId: category.categoryId,
      categoryName: category.name,
    }];
  } else {
    return category.conditions.map((condition) => ({
      id: condition.id,
      howLongAgo: condition.diagnosis,
      medications: condition.medications,
      explanation: condition.description,
      medicalConditionCategoryId: category.categoryId,
      medicalConditionId: condition.conditionId,
      categoryName: category.name,
      conditionName: condition.name,
    }));
  }
};
