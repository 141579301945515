import React, { FunctionComponent, ChangeEvent, useEffect, useState } from "react";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomInput from "rocket/components/Forms/CustomInput";
import CustomPasswordInput from "rocket/components/Forms/CustomPasswordInput";
import commonsStyles from "rocket/assets/css/Commons";
import { Link } from "react-router-dom";
import SecondaryButton from "rocket/components/Buttons/SecondaryButton";
import CustomCheckboxCenter from "rocket/components/Forms/CustomCheckboxCenter";
import ErrorAlert from "rocket/components/Commons/ErrorAlert";
import { resetThunk, loginThunk } from "actions/login/LoginActions";
import { SignUpPath, ForgotPasswordPath } from "routes/Paths";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: "0 auto",
    maxWidth: "400px",
    marginBottom: 45,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      marginBottom: 40,
    },
  },
  column: {
    display: "flex",
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    marginLeft: "auto",
    minHeight: "calc(100vh - 142px)",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F8F5F1",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "55%",
      flexBasis: "55%",
      paddingTop: 0,
      paddingLeft: "7%",
      paddingRight: "9%",
      paddingBottom: 0,
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 120,
      paddingLeft: "7%",
      paddingRight: "13%",
    },
  },
  paragraph: {
    maxWidth: 400,
    marginTop: 0,
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 35,
    textAlign: "center",
  },
  formColumn: {
    marginBottom: 16,
  },
}));

export const RightColumn: FunctionComponent = () => {
  const classes = useStyles();
  const otherClasses = commonsStyles();

  const dispatch = useDispatch();

  const loginFailed = useSelector<RootState, boolean>((state) => state.login.failed);
  const loading = useSelector<RootState, boolean>((state) => state.login.loading);

  const [errors, setErrors] = useState({ email: false, password: false });
  const [invalid, setInvalid] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });

  useEffect(() => {
    dispatch(resetThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInvalid(loginFailed);
    setErrors({ email: loginFailed, password: loginFailed });
  }, [loginFailed]);

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("password" in fieldValues) temp.password = fieldValues.password ? false : true;

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? false : true;
      if (fieldValues.email) temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? false : true;
    }

    setErrors({ ...temp });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;

    setInvalid(false);
    setValues({ ...values, [name]: value });
    validate();
  };

  const formIsValid = (fieldValues = values) => {
    const isValid = !!fieldValues.email && !!fieldValues.password && Object.values(errors).every((x) => x === false);

    return isValid;
  };

  const handleLogin = async () => {
    validate();
    if (formIsValid()) {
      dispatch(loginThunk(values.email, values.password, values.rememberMe));
    }
  };

  const handleRememberMeChange = (event: ChangeEvent<{}>, checked: boolean) => {
    setValues(prev => ({ ...prev, rememberMe: checked }));
  }

  return (
    <Grid item xs={12} className={classes.column}>
      <h2 className={classes.title}>Welcome back, hooman.</h2>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={11}>
          <Grid container spacing={2} alignItems="center">
            {invalid && (
              <Grid item className={classes.formColumn} xs={12}>
                <ErrorAlert label="Incorrect username or password." />
              </Grid>
            )}
            <Grid item className={classes.formColumn} xs={12}>
              <CustomInput
                label="Email"
                name="email"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["email"] && { error: true })}
                onEnterPressed={handleLogin}
                id="login_input_email"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomPasswordInput
                label="Password"
                name="password"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["password"] && { error: true })}
                onEnterPressed={handleLogin}
                id="login_input_password"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <CustomCheckboxCenter checked={values.rememberMe} label="Remember me" onChange={handleRememberMeChange} />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box textAlign="right">
                <Link to={ForgotPasswordPath} className={otherClasses.underlinedBlackLink}>
                  I forgot my password
                </Link>
              </Box>
            </Grid>
            <Grid className={classes.formColumn} item xs={12}>
              <SecondaryButton label="Login" onClick={(event) => { event.preventDefault(); handleLogin() }} loading={loading} id="login_button_signing"/>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.paragraph}>
                New to Cuddles?{" "}
                <Link id="login_text_signUp" to={SignUpPath} className={otherClasses.underlinedLink}>
                  Sign Up
                </Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightColumn;
