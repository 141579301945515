import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import Checkbox from './Checkbox';

export interface Props {
	label?: string;
	ban?: boolean;
	checked: boolean;
	fontColor?: string;
	iconColor?: string;
	onChange?: (checked: boolean) => void;
	className?: string;
	id?: string;
	disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		circle: (props: Props) => ({
			color: props.iconColor || '#92B4A7',
			[theme.breakpoints.down('lg')]: {
				width: '18px',
				height: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '24px',
				height: '24px'
			}
		})
	})
);

export const CheckboxCircle: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const checkecIcon = (
		<FontAwesomeIcon icon={faCheckCircle} className={classes.circle} />
	);

	const uncheckedIcon = (
		<>
			{props.ban ? (
				<FontAwesomeIcon icon={faBan} className={classes.circle} />
			) : (
				<FontAwesomeIcon icon={faCircle} className={classes.circle} />
			)}
		</>
	);

	return (
		<Checkbox
			{...props}
			icon={uncheckedIcon}
			checkedIcon={checkecIcon}
			className={props.className}
			id={`${props.id || ''}`}
		/>
	);
};

export default CheckboxCircle;
