import { FunctionComponent, useEffect, useState } from "react";
import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { Button } from "components/UI";
import { faPen, faPlus } from "@fortawesome/pro-light-svg-icons";
import Pet, { PetCardAction } from "@spike/pet-model";
import clsx from "clsx";
import { wbp } from "Theme";

interface PetActionButtonProps {
  pet: Pet;
  optionSelected: string;
  loading: boolean;
  className?: string;
  onSelectAction: (option: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '100%'
      }
    },
    button: {
      [theme.breakpoints.down(wbp)]: {
        height: 29,
        paddingTop: '7px',
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingBottom: '7px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexGrow: 1,
        height: 55,
        '& span': {
          fontSize: 18,
        },
        '& svg': {
          height: 24,
          width: 24,
          marginRight: 4
        }
      }
    }
  })
);


export const PetActionButton: FunctionComponent<PetActionButtonProps> = (props) => {
  const [icon, setIcon] = useState<IconDefinition | undefined>();
  const [label, setLabel] = useState<string | undefined>();
  const [action, setAction] = useState<string>(PetCardAction.editPetInformation);

  const classes = useStyles()

  useEffect(() => {
    switch (props.optionSelected) {
      case "info":
        setLabel("Edit Pet Information");
        setAction(PetCardAction.editPetInformation);
        setIcon(faPen);
        break;
      case "vaccines":
        if (props.pet!.medicalHistory.vaccines.length > 0) {
          setLabel("Edit Vaccines");
          setAction(PetCardAction.editVaccines);
          setIcon(faPen);
        }
        else {
          setLabel("Add Vaccines");
          setAction(PetCardAction.editVaccines);
          setIcon(faPlus);
        }
        break;
      case "medical":
        setLabel("Edit Medical Conditions");
        setAction(PetCardAction.editMedical);
        setIcon(faPen);
        break;
      case "dental":
        setLabel("Edit Dental");
        setAction(PetCardAction.editDental);
        setIcon(faPen);
        break;
      case "vet":
        setLabel("Edit Vet");
        setAction(PetCardAction.editVet);
        setIcon(faPen);
        break;
      case "notes":
        setLabel("Add Note");
        setAction('editNotes');
        setIcon(faPen);
        break;
    }
  }, [props.optionSelected, props.pet]);

  const onClickHandler = () => {
    props.onSelectAction(action);
  };



  return (
    <Box className={clsx([classes.container, props.className])}>
      <Button label={label} startIcon={icon} color="green" onClick={onClickHandler} loading={props.loading} className={classes.button} />
    </Box>
  );
};

export default PetActionButton;
