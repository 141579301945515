import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, PropsWithChildren } from 'react';

interface FormSectionProps extends PropsWithChildren {
	title?: string;
	subtitle?: string;
	className?: string;
	column?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		leftColumn: {
			[theme.breakpoints.down('sm')]: {
				paddingBottom: 24
			},
			[theme.breakpoints.up('md')]: {
				paddingRight: '24px'
			}
		},
		rightColumn: (props: FormSectionProps) => ({
			[theme.breakpoints.up('md')]: {
				marginTop: props.column ? '37px' : '0px'
			}
		}),
		rightContainer: {
			[theme.breakpoints.up('md')]: {
				padding: '33px',
				overflow: 'hidden',
				borderRadius: '24px',
				backgroundColor: '#fff',
				border: '1px solid #DDDDDD'
			}
		},
		title: {
			color: '#222',
			fontSize: 18,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontSize: '24px'
			}
		},
		subTitle: {
			color: '#000',
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
			marginTop: 10,

			[theme.breakpoints.up('md')]: {
				fontSize: 16
			}
		}
	})
);

export const FormSection: FunctionComponent<FormSectionProps> = props => {
	const classes = useStyles(props);

	return (
		<Grid container className={props.className}>
			<Grid
				item
				xs={12}
				md={props.column ? 12 : 4}
				className={classes.leftColumn}
			>
				<Typography className={classes.title}>{props.title}</Typography>
				<Typography className={classes.subTitle}>
					{props.subtitle}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				md={props.column ? 12 : 8}
				className={classes.rightColumn}
			>
				<Grid className={classes.rightContainer}>{props.children}</Grid>
			</Grid>
		</Grid>
	);
};

export default FormSection;
