import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface OverFullWindowHeaderProps {
    title?: string;
    className?: string;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderBottom: '1px solid #DDDDDD;',

            [theme.breakpoints.down(wbp)]: {
                minHeight: `${reduceResolution(66)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                minHeight: '66px'
            }
        },
        leftContainer: {
            width: '20%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: 16,

            [theme.breakpoints.down('sm')]: {
                width: '100% !important'
            },

            [theme.breakpoints.up('md')]: {
                paddingLeft: '32px'
            }
        },
        centerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60%'
        },
        rightContainer: {
            width: '20%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: 14,

            [theme.breakpoints.up('md')]: {
                paddingRight: '32px'
            }
        },
        logo: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(42)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '42px'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: 'black',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left !important',
                fontSize: '20px !important',
                fontWeight: '600 !important',
                lineHeight: '100% !important'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px'
            }
        },
        closeIcon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(24)}px !important`,
                height: `${reduceResolution(24)}px !important`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '24px !important',
                height: '24px !important'
            }
        }
    })
);
export const OverFullWindowHeader: FunctionComponent<
    OverFullWindowHeaderProps
> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={clsx(classes.container, props.className)}>
            {!isMobile && (
                <Box className={classes.leftContainer}>
                    <img
                        src="/images/logo/logo.png"
                        alt="Logo"
                        className={classes.logo}
                    />
                </Box>
            )}
            <Box
                className={
                    isMobile ? classes.leftContainer : classes.centerContainer
                }
            >
                {props.title && (
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                )}
            </Box>
            <Box className={classes.rightContainer}>
                {props.onClose && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className={classes.closeIcon}
                        onClick={event => {
                            event.stopPropagation();
                            props.onClose && props.onClose();
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default OverFullWindowHeader;
