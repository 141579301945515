import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles'
import Header from 'rocket/components/Header';
import LeftColumn from 'rocket/components/Login/LeftColumn';
import RightColumn from 'rocket/components/Login/RightColumn';
import SmallPrimaryButton from 'rocket/components/Buttons/SmallPrimaryButton';
import { cleanAll } from "actions/ActionUtils";
import { SignUpPath } from "routes/Paths";
import { pageView } from "googleAnalytics/GoogleAnalytics";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    }
}))

export const Login: FunctionComponent = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        cleanAll(dispatch);
        pageView("/login");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    document.title = 'Log in - Cuddles';

    return (
        <div id="login">
            <Header
                rightLink={
                    <SmallPrimaryButton to={SignUpPath} label="Get started" />
                } />
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <LeftColumn />
                    <RightColumn />
                </Grid>
            </div>
        </div>
    )
}

export default Login;
