import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FunctionComponent } from 'react';
import Button from './Button';

interface CancelButtonProps {
	size?: number;
	className?: string;
	onClick?: () => void;
}

export const CancelButton: FunctionComponent<CancelButtonProps> = props => {
	return (
		<Button
			icon={faTimes}
			size={props.size}
			className={props.className}
			onClick={props.onClick}
		/>
	);
};

export default CancelButton;
