import { CommissionPlan } from '@spike/commission-model';
import { FieldError } from '@spike/model';
import isEmpty from 'lodash/isEmpty';
import { debugConsoleLog } from 'utils/GeneralUtils';

export const validate = (commissionPlan: CommissionPlan): Array<FieldError> => {
    const errors: Array<FieldError> = [];
    for (const commission of commissionPlan.commissions) {
        if (isEmpty(commission.rate)) {
            errors.push({
                fieldName: 'rate',
                errorMessage: 'Commission rate is required'
            });
        } else {
            if (Number(commission.rate) > 100) {
                errors.push({
                    fieldName: 'rate',
                    errorMessage: 'Commission rate cannot exceed 100%'
                });
            }
        }
        debugConsoleLog(errors);
    }

    return errors;
};
