import { Reducer } from 'redux';
import { MenuState } from './MenuState';
import {
	MENU_CLOSE_ACTION,
	MENU_OPEN_ACTION,
	MENU_SET_DROPDOWN_ACTION,
	MenuAction
} from 'actions/menu/MenuActionsTypes';

const initialState: MenuState = {
	open: false,
	dropdown: ''
};

export const menuReducer: Reducer<MenuState, MenuAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case MENU_OPEN_ACTION:
			return {
				...state,
				open: true
			};
		case MENU_CLOSE_ACTION:
			return {
				...state,
				open: false
			};
		case MENU_SET_DROPDOWN_ACTION:
			return {
				...state,
				dropdown: action.payload.dropdown
			};
		default:
			return state;
	}
};

export default menuReducer;
