import { faPen, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Contact } from '@spike/client-model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { formatPhoneNumber } from '@spike/phone-utils';
import { useMarketplace } from 'hooks';

interface ContactsTableProps {
	title: string;
	contacts: Array<Contact>;
	className?: string;
	showLastRowLine?: boolean;
	onEdit?: (contact: Contact) => void;
	onDelete?: (contact: Contact) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		head: {
			display: 'flex',
			alignItems: 'center',
			padding: '9px 30px',
			backgroundColor: '#F4F3F0',
			borderTop: '1px solid #DDDDDD',
			borderBottom: '1px solid #DDDDDD'
		},
		title: {
			color: '#000',
			fontSize: 15,
			lineHeight: 1,
			fontWeight: 600,
			letterSpacing: '1.28px',
			textTransform: 'uppercase'
		},
		row: {
			'gap': 16,
			'display': 'flex',
			'paddingLeft': 30,
			'paddingRight': 22,
			'alignItems': 'center',

			'&:not(:last-child)': {
				borderBottom: '1px solid #DDDDDD'
			},

			[theme.breakpoints.down('sm')]: {
				paddingTop: 24,
				paddingLeft: 6,
				paddingRight: 0,
				paddingBottom: 21,
				margin: '0px 24px'
			},
			[theme.breakpoints.up('md')]: {
				height: '70px'
			}
		},
		lastRow: {
			border: 'none'
		},
		text: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: 'black',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`,
				lineHeight: `${reduceResolution(24)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeight: '24px'
			}
		},
		button: {
			all: 'unset',
			width: 38,
			height: 38,
			fontSize: 16,
			cursor: 'pointer',
			borderRadius: '50%',
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#F1F1F1',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},

		contactInfo: {
			gap: 6,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				gap: 0,
				display: 'grid',
				alignItems: 'center',
				gridTemplateColumns: '1fr 1fr 1fr'
			}
		},
		name: {
			color: '#000',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontWeight: 500
			}
		},
		phone: {
			color: '#000',
			fontSize: 16,
			lineHeight: 1
		},
		relationship: {
			color: '#707070',
			fontSize: 13,
			lineHeight: 1,

			[theme.breakpoints.up('md')]: {
				color: '#000',
				fontSize: 16
			}
		}
	})
);

export const ContactsTable: FunctionComponent<ContactsTableProps> = props => {
	const classes = useStyles();

	const marketplace = useMarketplace();

	return (
		<Grid container xs={12} className={props.className}>
			<Grid item xs={12} className={classes.head}>
				<Typography className={classes.title}>{props.title}</Typography>
			</Grid>
			{props.contacts.map((contact, index) => (
				<Grid
					xs={12}
					container
					key={contact.uuid}
					className={clsx(classes.row, {
						[classes.lastRow]:
							!props.showLastRowLine &&
							index + 1 === props.contacts.length
					})}
				>
					<Grid item xs="auto" className={classes.contactInfo}>
						<Typography className={classes.name}>
							{`${contact.firstName} ${contact.lastName}`}
						</Typography>

						<Typography className={classes.phone}>
							{formatPhoneNumber(
								contact.phone,
								String(marketplace.basics.address.country?.id)
							)}
						</Typography>

						<Typography className={classes.relationship}>
							{contact.relationship?.name}
						</Typography>
					</Grid>

					{props.onDelete && (
						<Grid item xs="auto">
							<button
								type="button"
								aria-label="Delete"
								className={classes.button}
								onClick={() =>
									props.onDelete && props.onDelete(contact)
								}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</button>
						</Grid>
					)}
					{props.onEdit && (
						<Grid item xs="auto">
							<button
								type="button"
								aria-label="Edit"
								className={classes.button}
								onClick={() =>
									props.onEdit && props.onEdit(contact)
								}
							>
								<FontAwesomeIcon icon={faPen} />
							</button>
						</Grid>
					)}
				</Grid>
			))}
		</Grid>
	);
};

export default ContactsTable;
