import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { createDepositRequestThunk, createRequestThunk, PosStatus } from '@spike/pos-action';
import { statusTerminal, Terminal, TerminalStatus } from '@spike/terminal-model';
import { checkTerminalStatusThunk, fetchTerminalsThunk, TerminalsStatus } from '@spike/terminals-action';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { Button, SelectField, Spinner } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import PosWebSocket from 'WebSockets/PosWebSockets';
import Section from './Section';
interface PosPaymentProps {
  invoiceId?: number;
  depositInvoiceId?: number;
  className?: string;
  onSuccess?: () => void;
  onGetPosServiceId?: (terminalId: number, serviceId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    terminalsContainer: {
      display: 'flex',
      width: '100%',
    },
    paymentFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(60)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: '60px',
      },
    },
    terminal: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      color: '#222222',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: '20px',
      },
    },
    errorMessage: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      color: '#C14A4A',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: '20px',
      },
    },
    payButton: {
      [theme.breakpoints.down(wbp)]: {
        marginLeft: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginLeft: '20px',
      },
    },
    tryAgainButton: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: '40px',
      },
    },
    checkingSpinner: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: '30px',
      },
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#F7F7F7',
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(13)}px`,
        marginTop: `${reduceResolution(30)}px`,
        padding: `${reduceResolution(30)}px`,
        minHeight: `${reduceResolution(80)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: '13px',
        marginTop: '30px',
        padding: '30px',
        minHeight: '80px',
      },
    },
    message: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      color: '#666666',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: '20px',
      },
    },
    blinkingMessage: {
      color: '#222222',
      animation: '$blink 2s linear infinite',
    },
    '@keyframes blink': {
      '0%': { opacity: 1 },
      '25%': { opacity: 0.5 },
      '50%': { opacity: 0 },
      '75%': { opacity: 0.5 },
      '100%': { opacity: 1 },
    },
    successContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(60)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: '60px',
      },
    },
    icon: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(70)}px`,
        marginBottom: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: '70px',
        marginBottom: '30px',
      },
    },
    finalText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: '18px',
      },
    },
    success: {
      color: '#00AA00',
    },
    error: {
      color: '#EF4F57',
    },
    successSpinner: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(50)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: '50px',
      },
    },
    retryButton: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(50)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: '50px',
      },
    },
  })
);

interface TerminalWrapper {
  terminal: Terminal | null;
  status: TerminalStatus | null;
  posServiceId: string | null;
}

interface POSMessage {
  message: string;
  error: boolean;
}

export const PosPayment: FunctionComponent<PosPaymentProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const terminalsStatus = useSelector<RootState, TerminalsStatus>((state) => state.terminals.status);
  const terminals = useSelector<RootState, Array<Terminal>>((state) => state.terminals.terminals).filter(
    (terminal) => terminal.active && terminal.status === statusTerminal.READY.toString()
  );
  const terminalStatus = useSelector<RootState, TerminalStatus | undefined>((state) => state.terminals.terminalStatus);

  const posStatus = useSelector<RootState, PosStatus>((state) => state.pos.status);
  const posServiceId = useSelector<RootState, string | null>((state) => state.pos.serviceId || null);

  const [loadingTerminals, setLoadingTerminals] = useState(true);
  const [checkingTerminal, setCheckingTerminal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState<TerminalWrapper>({
    terminal: null,
    status: null,
    posServiceId: null,
  });
  const [posMessages, setPosMessages] = useState<Array<POSMessage>>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(fetchTerminalsThunk(apiClientWrapper));
  }, []);

  useNonInitialEffect(() => {
    switch (terminalsStatus) {
      case TerminalsStatus.FetchTerminalsSuccess:
        setLoadingTerminals(false);
        break;
      case TerminalsStatus.CheckStatusSuccess:
        setSelectedTerminal((prev) => ({ ...prev, status: terminalStatus ? { ...terminalStatus } : null }));
        if (terminalStatus?.ready) {
          props.invoiceId && dispatch(createRequestThunk(apiClientWrapper, props.invoiceId, selectedTerminal.terminal!.id!));
          props.depositInvoiceId &&
            dispatch(createDepositRequestThunk(apiClientWrapper, props.depositInvoiceId, selectedTerminal.terminal!.id!));
        } else {
          setCheckingTerminal(false);
        }
        break;
      case TerminalsStatus.Error:
        setLoadingTerminals(false);
        setCheckingTerminal(false);
        break;
    }
  }, [terminalsStatus]);

  useNonInitialEffect(() => {
    switch (posStatus) {
      case PosStatus.CreateDespositRequestSuccess:
      case PosStatus.CreateRequestSuccess:
        setCheckingTerminal(false);
        setSelectedTerminal((prev) => ({ ...prev, posServiceId }));
        props.onGetPosServiceId && props.onGetPosServiceId(selectedTerminal.terminal!.id!, posServiceId!);
        break;
      case PosStatus.Error:
        setLoadingTerminals(false);
        setCheckingTerminal(false);
        break;
    }
  }, [posStatus]);

  const payHandler = () => {
    if (selectedTerminal.terminal) {
      setCheckingTerminal(true);
      dispatch(checkTerminalStatusThunk(apiClientWrapper, selectedTerminal.terminal.id!));
    }
  };

  const retryHandler = () => {
    setShowSuccess(false);
    setShowError(false);
    setSelectedTerminal({ terminal: null, status: null, posServiceId: null });
    setPosMessages([]);
  };

  return (
    <Section title="POS" className={props.className}>
      <Box className={classes.container}>
        <Box className={classes.terminalsContainer}>
          {loadingTerminals ? (
            <Spinner />
          ) : (
            <>
              <SelectField
                placeholder="Select a Terminal"
                options={terminals.map((terminal) => ({ id: terminal.id!, name: terminal.name }))}
                selectedOption={
                  selectedTerminal.terminal
                    ? { id: selectedTerminal.terminal.id!, name: selectedTerminal.terminal.name }
                    : undefined
                }
                onSelect={(option) =>
                  setSelectedTerminal({
                    terminal: terminals.find((terminal) => terminal.id === option.id) || null,
                    status: null,
                    posServiceId: null,
                  })
                }
                disabled={selectedTerminal.status?.ready === true}
              />
              <Button
                label="Pay"
                onClick={payHandler}
                className={classes.payButton}
                disabled={selectedTerminal.terminal === null || selectedTerminal.status !== null || checkingTerminal}
              />
            </>
          )}
        </Box>
        {checkingTerminal && <Spinner className={classes.checkingSpinner} />}
        {!checkingTerminal && selectedTerminal.posServiceId && (
          <PosWebSocket
            invoiceId={props.invoiceId || props.depositInvoiceId!}
            terminalId={selectedTerminal.terminal!.id!}
            onMessageReceived={(message, error) => setPosMessages((prev) => [...prev, { message, error }])}
            onSuccess={() => {
              setShowSuccess(true);
              props.onSuccess && props.onSuccess();
            }}
            onError={() => {
              setShowError(true);
            }}
            onGetPosServiceId={(serviceId) =>
              props.onGetPosServiceId && props.onGetPosServiceId(selectedTerminal.terminal!.id!, serviceId)
            }
          />
        )}
        {!checkingTerminal && selectedTerminal.terminal && selectedTerminal.status?.ready === false && (
          <Box className={classes.messageContainer}>
            <Typography className={classes.errorMessage}>{selectedTerminal.status?.message}</Typography>
            <Button label="Try Again!" color="black" onClick={payHandler} className={classes.tryAgainButton} />
          </Box>
        )}
        {!checkingTerminal && selectedTerminal.posServiceId && !showSuccess && (
          <Box className={classes.messageContainer}>
            {posMessages.map((posMessage, index) => (
              <Typography
                key={index}
                className={clsx(classes.message, {
                  [classes.blinkingMessage]: index === posMessages.length - 1,
                  [classes.error]: posMessage.error,
                })}
              >
                {posMessage.message}
              </Typography>
            ))}
          </Box>
        )}
        {showSuccess && (
          <Box className={classes.successContainer}>
            <FontAwesomeIcon icon={faCircleCheck} className={clsx(classes.icon, classes.success)} />
            <Typography className={clsx(classes.finalText, classes.success)}>Payment successful!</Typography>
            <Spinner className={classes.successSpinner} />
          </Box>
        )}
        {showError && (
          <Box className={classes.successContainer}>
            <FontAwesomeIcon icon={faCircleXmark} className={clsx(classes.icon, classes.error)} />
            <Button label="Retry" color="black" className={classes.retryButton} onClick={retryHandler} />
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default PosPayment;
