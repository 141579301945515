import React, { FunctionComponent, KeyboardEvent } from "react";
import { makeStyles, TextField, TextFieldProps, Theme } from "@material-ui/core";

export type CustomInputProps = TextFieldProps & {
  onEnterPressed?: () => void;
  maxLength?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: "100%",
    "& .MuiInputBase-root": {
      "& legend, & span": {
        display: "none",
      },
    },
    "& .MuiFormLabel-root": {
      [theme.breakpoints.down('sm')]: {
        left: '0 !important',
      },
      left: 20,
      color: "#222",
      fontFamily: '"Poppins", sans-serif',
      "&.Mui-error.MuiFormLabel-filled": {
        color: "#f44336",
      },
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      borderRadius: "33px",
      padding: "17.5px 35px",
      backgroundColor: "#fff",
    },
    "& .MuiInputLabel-root": {
      "& .MuiFormLabel-asterisk": {
        color: "#EAB464",
        fontWeight: 600,
      },
      "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -16px) scale(0.75)",
      },
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: '"Poppins", sans-serif',
      "& label": {
        fontSize: "18px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        top: 0,
        borderRadius: "33px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: 1,
        borderColor: "#222222",
      },
    },
  },
}));

export const CustomInput: FunctionComponent<CustomInputProps> = (props) => {
  let classes = useStyles(props);

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  return <TextField {...props} className={classes.field} variant="outlined" onKeyPress={keyPressHandler} />;
};

export default CustomInput;
