import React, { Fragment, FunctionComponent} from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { formatPhoneNumber } from "@spike/phone-utils";
import { MarketplaceBasics } from "@spike/marketplace-model";
import { isEmpty } from "lodash";
import { useMarketplace } from "hooks";

interface Props {
  basics: MarketplaceBasics;
  logo?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleSubtitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "36px",
      },
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "60%",
    },
    subtitle: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
      },
    },
    text: {
      [theme.breakpoints.down("md")]: {
        fontSize: "5px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "10px",
      },
    },
    textSeparator: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "4px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "5px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "8px",
      },
    },
    logoEmpty: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: "1px solid #E6E6E6",
      backgroundColor: "#E6E6E6",
      [theme.breakpoints.down("md")]: {
        marginRight: "18px",
        height: "30px",
        width: "30px",
        paddingLeft: "8px",
        borderRadius: "5px",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "26px",
        height: "40px",
        width: "40px",
        paddingLeft: "10px",
        borderRadius: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: "40px",
        height: "60px",
        width: "60px",
        paddingLeft: "18px",
        borderRadius: "8px",

      },
    },
    logoText: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "7px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "12px",
      },
    },
    squareBox: {
      backgroundImage: (props: Props) => "url(" + props.logo + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        height: "30px",
        marginRight: "18px",
        width: "30px",
        borderRadius: "5px",
      },
      [theme.breakpoints.only("lg")]: {
        height: "40px",
        marginRight: "26px",
        width: "40px",
        borderRadius: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        height: "60px",
        marginRight: "40px",
        width: "60px",
        borderRadius: "8px",
      },
    },
  })
);

export const HeaderInvoice: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const marketplace = useMarketplace();

  return (
    <Fragment>
      <Box className={classes.titleSubtitleContainer}>
        <Box className={classes.titleContainer}>
          <Box className={classes.textSeparator}>
            <Typography className={classes.subtitle}>
              {props.basics.businessName}
            </Typography>
          </Box>
          <Box className={classes.textSeparator}>
            <Typography
              className={classes.text}
            >{`${props.basics.address.street}, ${props.basics.address.city}, ${props.basics.address.state}, ${props.basics.address.zipcode}`}</Typography>
          </Box>
          <Box className={classes.textSeparator}>
            <Typography className={classes.text}>
              {formatPhoneNumber(props.basics.phone, marketplace.basics.address.country!.id)}
            </Typography>
          </Box>
        </Box>
        {!isEmpty(props.logo) ? 
          <Box className={classes.squareBox} />
        : 
          <Box className={classes.logoEmpty}>
            <Typography className={classes.logoText}>Logo</Typography>
          </Box>
        }
      </Box>
    </Fragment>
  );
};

export default HeaderInvoice;
