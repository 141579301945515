import { FunctionComponent } from "react";
import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import { ItemLabelRead } from "components/UI/ItemLabelRead";
import Pet from "@spike/pet-model";
import clsx from "clsx";

interface PetBirthdayProps {
  pet?: Pet;
  className?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      fontWeight: 400,
      color: '#A3A3A3',
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    description: {
      fontSize: 18,
      fontWeight: 400,
      color: '#000000',
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        fontWeight: 500,
      },
    }
  })
);

export const PetBirthday: FunctionComponent<PetBirthdayProps> = (props) => {

  const classes = useStyles()

  return (
    <Box className={clsx(props.className)}>
      <ItemLabelRead title={"BIRTHDAY"}
        description={props.pet?.birthdate ? props.pet.birthdate.format("MMMM D") : "Not Specified"}
        classTitle={classes.title}
        classDescription={classes.description}
      />
    </Box>);
};

export default PetBirthday;
