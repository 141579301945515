import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DescriptionField2 } from 'components/UI';

interface Props {
    title: string;
    placeholder: string;
    staffId?: number;
    readOnly?: boolean;
    rebooking?: boolean;
    notes: string;
    onChangeNote: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingBottom: 13,
            [theme.breakpoints.up('md')]: {
                paddingBottom: 15
            }
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        notesField: {
            'fontSize': 14,
            'lineHeight': '18px',
            'marginTop': 10,
            '& textarea': {
                height: '121px !important',
                fontSize: 14,
                padding: '23px 19px',
                [theme.breakpoints.up('md')]: {
                    padding: '23px 28px'
                }
            }
        }
    })
);

export const StaffSelector: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!props.readOnly && !props.rebooking) {
            ref?.current?.scrollIntoView();
        }
    }, [ref, props.staffId]);

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>{props.title}</Typography>
            <div id="booking_input_notes" ref={ref}>
                <DescriptionField2
                    name="notes"
                    placeholder={props.placeholder}
                    label=""
                    className={classes.notesField}
                    value={props.notes}
                    onChange={props.onChangeNote}
                    maxRows={3}
                />
            </div>
        </Box>
    );
};

export default StaffSelector;
