import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    OutlinedInput,
    Theme,
    Typography
} from '@material-ui/core';
import { useToggle } from 'hooks';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { faPen, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export interface Props {
    optionName: string;
    checked: boolean;
    editable?: boolean;
    index: number;
    onEditName?: (optionName: string, i: number) => void;
    onDelete: (optionName: string) => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: () => ({
            display: 'flex',
            height: 'auto',
            marginRight: '5px',

            justifyContent: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                minHeight: '15px',
                borderRadius: '7px',
                paddingTop: '2px',
                paddingBottom: '2px'
            },
            [theme.breakpoints.only('md')]: {
                minHeight: '24px',
                borderRadius: '12px',
                paddingTop: '3px',
                paddingBottom: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                minHeight: '32px',
                borderRadius: '16px',
                paddingTop: '5px',
                paddingBottom: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                minHeight: '49px',
                borderRadius: '24px',
                paddingTop: '10px',
                paddingBottom: '9px'
            }
        }),
        leftColumn: {
            width: '75%'
        },
        rightColumn: {
            width: '25%',
            display: 'flex',
            justifyContent: 'end',
            gap: '15px'
        },

        textContainer: {
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'flex-start',
            '&:hover': {
                cursor: 'pointer'
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: '28px',
                width: '100%'
            },
            [theme.breakpoints.only('md')]: {
                marginRight: '45px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '59px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '90px'
            }
        },
        optionName: {
            width: '100%',
            overflowWrap: 'break-word',
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px',
                minHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '6px',
                minHeight: '22px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '3px',
                minHeight: '18px'
            },
            [theme.breakpoints.only('xl')]: {
                minHeight: '30px'
            }
        },
        containerCheck: {
            'display': 'flex',
            'alignItems': 'flex-start',
            'marginTop': '2.5px',
            '& .MuiCheckbox-root': {
                paddingLeft: '0px !important',
                fontSize: '16px',
                [theme.breakpoints.down('sm')]: {
                    paddingRight: '10px',
                    fontSize: '6px',
                    marginLeft: '6px'
                },
                [theme.breakpoints.only('md')]: {
                    paddingRight: '16px',
                    marginLeft: '10px'
                },
                [theme.breakpoints.only('lg')]: {
                    paddingRight: '21px',
                    marginLeft: '14px'
                },
                [theme.breakpoints.only('xl')]: {
                    paddingRight: '32px',
                    marginLeft: '21px'
                }
            }
        },
        outlineInput: {
            'height': 'auto !important',
            '& .MuiInputBase-root': {
                padding: '0px !important',
                paddingLeft: '0px !important'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            },
            '&.MuiOutlinedInput-multiline': {
                padding: '0px'
            },
            'overflowWrap': 'break-word',
            [theme.breakpoints.only('lg')]: {
                paddingTop: '3px !important'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '2px'
            }
        },
        iconClose: {
            '&:hover': {
                color: '#EAB464',
                cursor: 'pointer'
            },
            'color': '#000',
            'fontWeight': 400,
            'fontSize': '18px',
            [theme.breakpoints.down('sm')]: {
                marginTop: '4px',
                fontSize: '16px !important',
                right: '0px',
                color: '#000'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '7px'
            }
        }
    })
);

export const EmailPoint: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const [isEditing, handleEdit, setEdit] = useToggle(false);
    const [inputValue, setInputValue] = useState<string>(props.optionName);
    const [hover, setHover] = useState(false);

    const handleClick = () => {
        setEdit(true);
    };

    const handleChange = (value: string) => {
        setInputValue(value);
    };

    const mouseLeaveHandler = () => {
        if (typeof props.isMobile === 'undefined' || props.isMobile === false) {
            setHover(false);
        }
    };

    const mouseEnterHandler = () => {
        if (typeof props.isMobile === 'undefined' || props.isMobile === false) {
            setHover(true);
        }
    };

    const handleBlur = () => {
        setEdit(false);
        if (!isEmpty(inputValue)) {
            props.onEditName && props.onEditName(inputValue, props.index);
        }
    };

    return (
        <Box
            className={classes.container}
            onMouseLeave={mouseLeaveHandler}
            onMouseOver={mouseEnterHandler}
        >
            <Box className={classes.leftColumn}>
                <Box className={classes.textContainer}>
                    {isEditing ? (
                        <OutlinedInput
                            label=""
                            value={inputValue}
                            autoFocus={true}
                            multiline={true}
                            onBlur={handleBlur}
                            onChange={e => handleChange(e.target.value)}
                            className={classes.outlineInput}
                        />
                    ) : (
                        <Typography className={classes.optionName}>
                            {inputValue}
                        </Typography>
                    )}
                </Box>
            </Box>

            <Box className={classes.rightColumn}>
                <FontAwesomeIcon
                    icon={faPen}
                    className={classes.iconClose}
                    onClick={() => handleClick()}
                />

                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={classes.iconClose}
                    onClick={() => props.onDelete(inputValue)}
                />
            </Box>
        </Box>
    );
};

export default EmailPoint;
