import { Box, Grid, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BusinessSettingsTitle } from '../UI';
import { FreePlanCard } from './Cards/FreePlanCard';
import { PremiumPlanCard } from './Cards/PremiumPlanCard';
import { PaymentsFlyer } from '../UI/PaymentsFlyer';
import { useDispatch, useSelector } from 'react-redux';
import {
	deactivateSubscriptionThunk,
	fetchSubscriptionsThunk,
	purgeSubscriptionsThunk,
	saveSubscriptionsThunk
} from 'actions/subscriptions/SubscriptionsActions';
import { useApiClientWrapper, usePremiumPlan } from 'hooks';
import { RootState } from 'store';
import { Button, ConfirmDialog, OverFullWindow, Spinner } from 'components/UI';
import { getEnrollmentStatusThunk } from '@spike/payments-action';
import { EnrollmentStatus } from '@spike/payments-model';
import { UpgradePlanForm } from './Upgrade/UpgradePlanForm';
import { DowngradePlanForm } from './Downgrade/DowngradePlanForm';
import { DowngradeSuccess } from './Downgrade/DowngradeSuccess';
import { UpgradeSuccess } from './Upgrade/UpgradeSuccess';
import { Subscription } from 'model/Subscriptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export interface Props {
	openSidebar?: () => void;
	isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
	},
	modal: {
		zIndex: 1299
	},
	modalContainer: {
		maxWidth: 465,
		display: 'flex',
		flex: '0 0 100%',
		flexDirection: 'column',
		padding: '40px 0px 20px',
		marginLeft: 'auto',
		marginRight: 'auto',

		[theme.breakpoints.up('md')]: {
			flex: '0 0 465px',
			padding: '90px 0px',
			justifyContent: 'center'
		}
	},
	dialog: {
		'& .MuiPaper-root': {
			maxWidth: 464,
			minWidth: 'unset'
		},
		'& .MuiButtonBase-root': {
			'height': 47,
			'width': '100%',
			'borderRadius': 30,

			'& span': {
				fontSize: 16,
				fontWeight: 600
			}
		}
	}
}));

export const Plans: React.FunctionComponent<Props> = ({
	openSidebar,
	isMobile
}) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const activeSubscription = usePremiumPlan();

	const [showUpgrade, setShowUpgrade] = useState(false);
	const [showDowngrade, setShowDowngrade] = useState(false);
	const [showConfirmDowngrade, setShowConfirm] = useState(false);
	const [showDowngradeSuccess, setShowDowngradeSuccess] = useState(false);
	const [showUpgradeSuccess, setShowUpgradeSuccess] = useState(false);

	const apiClientWrapper = useApiClientWrapper();

	const created = useSelector<RootState, Subscription | undefined>(
		state => state.subscriptions.created
	);

	const loading = useSelector<RootState, boolean>(
		state => state.subscriptions.loading
	);

	const entrollmentStatus = useSelector<
		RootState,
		EnrollmentStatus | undefined
	>(state => state.payments.enrollmentStatus);

	/**
	 * Fetch subscriptions
	 */
	const fetch = () => {
		dispatch(fetchSubscriptionsThunk());
	};

	/**
	 * Upgrade plan handler
	 */
	const upgradeHandler = async () => {
		setShowUpgradeSuccess(true);

		if (created) {
			await dispatch(
				saveSubscriptionsThunk({
					...created,
					active: true
				})
			);
		}

		fetch();
	};

	/**
	 * Show upgrade handler
	 */
	const showUpgradeHandler = () => {
		dispatch(purgeSubscriptionsThunk());
		setShowUpgrade(true);
	};

	/**
	 * Downgrade plan handler
	 */
	const downgradeHandler = async () => {
		if (!activeSubscription?.id) {
			return;
		}

		await dispatch(deactivateSubscriptionThunk(activeSubscription?.id));
		setShowConfirm(false);
		setShowDowngrade(true);
		setShowDowngradeSuccess(true);
		fetch();
	};

	/**
	 * Cancel confirm downgrade handler
	 */
	const cancelConfirmDowngrade = () => {
		setShowConfirm(false);
		setShowDowngrade(false);
	};

	/**
	 * Completes downgrade flow successfully
	 */
	const confirmSuccessDowngrade = () => {
		setShowConfirm(false);
		setShowDowngrade(false);
		setShowDowngradeSuccess(false);
	};

	/**
	 * Completes upgrade flow successfully
	 */
	const confirmSuccessUpgrade = () => {
		setShowUpgrade(false);
		setShowUpgradeSuccess(false);
		fetch();
	};

	/**
	 * Fetch subscriptions on load & get cuddles pay enrollment status
	 */
	useEffect(() => {
		fetch();
		dispatch(getEnrollmentStatusThunk(apiClientWrapper));
		// eslint-disable-next-line
	}, []);

	return (
		<Box className={classes.root}>
			<BusinessSettingsTitle>
				{isMobile && (
					<FontAwesomeIcon
						onClick={openSidebar}
						icon={faArrowLeft}
						size="2x"
					/>
				)}
				Plans
			</BusinessSettingsTitle>

			{loading && <Spinner />}

			{!loading && (
				<>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							{activeSubscription ? (
								<>
									<PremiumPlanCard
										subscription={activeSubscription}
										onDowngrade={() =>
											setShowDowngrade(true)
										}
									/>
								</>
							) : (
								<FreePlanCard onUpgrade={showUpgradeHandler} />
							)}
						</Grid>
						<Grid item xs={12}>
							{entrollmentStatus &&
								!entrollmentStatus?.enabled && (
									<PaymentsFlyer />
								)}
						</Grid>
					</Grid>
				</>
			)}

			{showUpgrade && (
				<OverFullWindow
					title="Change your Plan"
					className={classes.modal}
					onClose={() =>
						showUpgradeSuccess
							? confirmSuccessUpgrade()
							: setShowUpgrade(false)
					}
				>
					{showUpgradeSuccess ? (
						<UpgradeSuccess onClose={confirmSuccessUpgrade} />
					) : (
						<Box className={classes.modalContainer}>
							<UpgradePlanForm
								loading={loading}
								onUpgrade={upgradeHandler}
							/>
						</Box>
					)}
				</OverFullWindow>
			)}

			{showDowngrade && (
				<OverFullWindow
					title="Change your Plan"
					className={classes.modal}
					onClose={() =>
						showDowngradeSuccess
							? confirmSuccessDowngrade()
							: setShowDowngrade(false)
					}
				>
					<Box className={classes.modalContainer}>
						{showDowngradeSuccess && (
							<DowngradeSuccess
								onClose={confirmSuccessDowngrade}
							/>
						)}

						{!showDowngradeSuccess && activeSubscription && (
							<DowngradePlanForm
								loading={loading}
								subscription={activeSubscription}
								onDowngrade={() => setShowConfirm(true)}
							/>
						)}
					</Box>
				</OverFullWindow>
			)}

			<ConfirmDialog
				isSuccess
				title={<Typography variant="h6">Confirm Downgrade</Typography>}
				question={
					<>
						Are you sure you want to downgrade to the{' '}
						<b>Free Plan</b>?
					</>
				}
				actions={
					<>
						<Button
							label="No"
							color="black"
							disabled={loading}
							onClick={cancelConfirmDowngrade}
						/>
						<Button
							label="Yes"
							color="green"
							variant="primary"
							loading={loading}
							onClick={downgradeHandler}
						/>
					</>
				}
				className={classes.dialog}
				open={showConfirmDowngrade}
				onCancel={cancelConfirmDowngrade}
			/>

			{/* <AlertMessage2
				bWidth="2px"
				icon={faInfoCircle}
				bgColor="rgba(251, 185, 188, 0.2)"
				bdColor="#EF4F57"
			>
				<strong>Payment failed.</strong> To avoid problems with the use of
				your account, please{" "}
				<u style={{ cursor: "pointer" }} onClick={() => {}}>
					<b>make a payment</b>
				</u>
				.
			</AlertMessage2> */}
		</Box>
	);
};
