import moment from 'moment';
import { DentalReportDto, GroomingReportDto } from './ReportsDtos';
import { Appointment } from '@spike/appointment-model';
import { DentalReport } from 'model/DentalReport';
import { GroomingReport } from 'model/GroomerReport';

export const convertDentalReport = (
    dentalReportDto: DentalReportDto,
    appointment: Appointment
): DentalReport => {
    const report: DentalReport = {
        id: dentalReportDto.id,
        uuid: dentalReportDto.uuid,
        appointment: appointment,
        procedureRecords: JSON.parse(dentalReportDto.procedure_records),
        remarks: dentalReportDto.remarks,
        calculus: dentalReportDto.calculus,
        plaque: dentalReportDto.plaque,
        homecare: JSON.parse(dentalReportDto.homecare),
        recommendations: JSON.parse(dentalReportDto.recommendation),
        images: dentalReportDto ? JSON.parse(dentalReportDto.images) : [],
        imageFilesToUpload: [],
        nextDentalDate: dentalReportDto.next_dental_date
            ? moment(dentalReportDto.next_dental_date).toDate()
            : null,
        toothAbnomalities: JSON.parse(dentalReportDto.tooth_abnormalities),
        periodontalFindings: JSON.parse(dentalReportDto.periodontal_findings),
        declined: dentalReportDto.declined
    };

    report.toothAbnomalities.forEach(ta => {
        ta.allTeeth = setDefaultAll(ta.allTeeth);
    });

    report.periodontalFindings.forEach(pf => {
        pf.allTeeth = setDefaultAll(pf.allTeeth);
    });

    return report;
};

export const convertGroomingReport = (
    groomingReportDto: GroomingReportDto,
    appointment: Appointment
): GroomingReport => {
    const report: GroomingReport = {
        id: groomingReportDto.id,
        uuid: groomingReportDto.uuid,
        appointment: appointment,
        skin: JSON.parse(groomingReportDto.skin) ?? [],
        teeth: JSON.parse(groomingReportDto.teeth) ?? [],
        nails: JSON.parse(groomingReportDto.nails) ?? [],
        coat: JSON.parse(groomingReportDto.coat) ?? [],
        ears: JSON.parse(groomingReportDto.ears) ?? [],
        eyes: JSON.parse(groomingReportDto.eyes) ?? [],
        behavior: JSON.parse(groomingReportDto.behavior) ?? [],
        findings: JSON.parse(groomingReportDto.findings) ?? [],
        remarks: groomingReportDto.remarks,
        recommendations: JSON.parse(groomingReportDto.recommendation) ?? [],
        images: groomingReportDto.images
            ? JSON.parse(groomingReportDto.images)
            : [],
        imageFilesToUpload: [],
        nextVisit: groomingReportDto.next_visit
            ? moment(groomingReportDto.next_visit).toDate()
            : null,
        declined: groomingReportDto.declined
    };

    report.findings.forEach(f => {
        f.all = setDefaultAll(f.all);
    });

    return report;
};

const setDefaultAll = (all: boolean | undefined | null): boolean => {
    if (all === undefined || all === null) {
        return false;
    } else {
        return all;
    }
};
