import { Option } from '@spike/model';
export const petViewOptions: Array<Option<string>> = [
	{
		id: 'info',
		name: 'Pet Information'
	},
	{
		id: 'vaccines',
		name: 'Vaccines'
	},
	{
		id: 'medical',
		name: 'Medical Conditions'
	},
	{
		id: 'dental',
		name: 'Dental'
	},
	{
		id: 'vet',
		name: 'Vet Information'
	}
];
