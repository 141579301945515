import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import UploadedFile from "./UploadedFile";
import { wbp } from "Theme";

export interface UploadedFileModel {
  name: string;
  size: number;
}

interface UploadedFilesProps {
  title?: string;
  files: Array<UploadedFileModel>;
  className?: string;
  onRemove?: (fileName: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "black",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "16px",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        lineHeight: "27px",
        marginBottom: "14px",
      },
    },
    file: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: "11px",
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "14px",
      },
    },
  })
);

export const UploadedFiles: FunctionComponent<UploadedFilesProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.title}>{props.title || "Files Uploaded"}</Typography>
      {props.files.map((file) => (
        <UploadedFile key={file.name} fileName={file.name} fileSize={file.size} onRemove={() => props.onRemove && props.onRemove(file.name)} />
      ))}
    </Box>
  );
};

export default UploadedFiles;
