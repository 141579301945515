import {
  faBabyCarriage,
  faBone,
  faBrain,
  faEar,
  faHeart,
  faKidneys,
  faLungs,
  faPaw,
  faScalpel,
  faVial,
  faViruses,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretDown, faCaretUp, faAngleDown, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "@fullcalendar/resource/internal-common";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";

export interface MedicalCategorySectionProps extends PropsWithChildren {
  categoryName: string;
  categoryId: number;
  expandable?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    header: {
      display: "flex",
      cursor: "pointer",
      backgroundColor: "#E9F0ED",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(7)}px`,
        padding: `${reduceResolution(8)}px ${reduceResolution(33)}px ${reduceResolution(8)}px ${reduceResolution(
          27
        )}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "7px",
        padding: "8px 33px 8px 27px",
      },
      [theme.breakpoints.down("md")]: {
        borderRadius: "7px",
        padding: "10px 15px",
      },
    },
    headerRight: {
      display: "flex",
      alignItems: "center",
    },
    headerLeft: {
      display: "flex",
      alignItems: "center",
    },
    arrow: {
      color: "#5E8677",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(26)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "26px",
      },
      [theme.breakpoints.down("md")]: {
        color: "#000",
        fontSize: "18px",
      },
    },
    icon: {
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
        marginRight: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        marginRight: "8px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
        lineHeight: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fornSize: "18px",
        lineHeight: "27px",
      },
    },
    containerConditions:{
      [theme.breakpoints.down("md")]: {
        "& > div:last-child": {
          borderBottom: "none",
        }
      }
    }
  })
);

const icons: Map<number, IconDefinition> = new Map([
  [1, faHeart],
  [2, faLungs],
  [3, faKidneys],
  [4, faBone],
  [5, faViruses],
  [6, faVial],
  [7, faBrain],
  [8, faBabyCarriage],
  [9, faEar],
  [10, faScalpel],
  [12, faPaw],
]);

export const MedicalCategorySection: FunctionComponent<MedicalCategorySectionProps> = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);

  const icon = icons.get(props.categoryId);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.header} onClick={() => props.expandable && setExpanded((prev) => !prev)}>
        <Box className={classes.headerLeft}>
          {icon !== undefined && <FontAwesomeIcon icon={icon} className={classes.icon} />}
          <Typography className={classes.title}>{props.categoryName}</Typography>
        </Box>
        <Box className={classes.headerRight}>
          {props.expandable && <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} className={classes.arrow} />}
        </Box>
      </Box>
      {expanded && <Box className={classes.containerConditions}>{props.children}</Box>}
    </Box>
  );
};

export default MedicalCategorySection;
