import ProductVendor from "model/ProductVendor";

export interface ProductVendorsState {
  list: Array<ProductVendor>;
  savedVendor: ProductVendor | undefined;
  vendor: ProductVendor | undefined;
  status: ProductVendorsStatus;
  loading: boolean;
}

export enum ProductVendorsStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Getting,
  GetSuccess,
  Saving,
  SaveSuccess,
  SavingProducts,
  SaveProductsSuccess,  
  Deleting,
  DeleteSuccess,
  Error,
  DeletingProducts,
  DeleteProductsSuccess, 
}
