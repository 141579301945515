import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleCheck,
    faCircleXmark,
    faPaperPlane
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { PriceFormat } from 'components/UI';
import AdyenPayment, {
    CompletedInfo
} from 'components/Payments/Adyen/AdyenPayment';
import { completeCampaignThunk } from 'actions/smsCampaigns/SMSCampaignsActions';
import clsx from 'clsx';
import { Button } from 'components/UI';

interface Props {
    moreThanOneRecepient?: boolean;
    onPaid: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        almost: {
            width: '100%',
            marginBottom: 45,
            textAlign: 'center',
            margin: '0px auto',
            paddingTop: 45,
            [theme.breakpoints.down('sm')]: {
                paddingTop: 33,
                maxWidth: 315
            }
        },
        icon: {
            fontSize: 64,
            color: '#5E8677',
            marginBottom: 18,
            [theme.breakpoints.down('sm')]: {
                fontSize: 44
            }
        },
        title: {
            color: '#222',
            fontSize: 32,
            fontWeight: 600,
            lineHeight: 1.2,
            marginBottom: 8,
            [theme.breakpoints.down('sm')]: {
                fontSize: 26,
                marginBottom: 16
            }
        },
        subtitle: {
            marign: 0,
            color: '#000',
            fontSize: 15,
            lineHeight: 1.4,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        total: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 18,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 20,
            padding: '30px 0px',
            borderBottom: '1px solid #D4D4D4',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                padding: '24px 0px'
            }
        },
        totalFooter: {
            color: '#222',
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        },
        adyen: {
            marginTop: 30
        },
        success: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#00AA00',
            width: '100%',
            textAlign: 'center',
            fontSize: '18px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        successIcon: {
            color: '#00AA00'
        },
        error: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#C14A4A',
            width: '100%',
            textAlign: 'center',
            fontSize: '18px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        errorIcon: {
            color: '#C14A4A'
        },
        tryAgainButton: {
            marginTop: '40px',
            [theme.breakpoints.down('sm')]: {
                marginTop: 20
            }
        },
        adyenResultMessageContainer: {
            paddingTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
);

const SMSCreateThirdStep: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const campaign = useSelector(
        (state: RootState) => state.smsCampaigns.created
    );
    const quote = useSelector((state: RootState) => state.smsCampaigns.quote);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const paymentErrorHandler = (message: string) => {
        if (message.length > 1) {
            setErrorMessage(message);
        }
    };

    const paymentCompleteHandler = (completedInfo: CompletedInfo) => {
        if (completedInfo.success) {
            completeCampaignHandler();
            setSuccessMessage(completedInfo.message);
            props.onPaid && props.onPaid();
        } else {
            setErrorMessage(completedInfo.message);
        }
    };

    const completeCampaignHandler = () => {
        campaign && dispatch(completeCampaignThunk(campaign));
    };

    return (
        <Grid justifyContent="center" spacing={0} container>
            <Grid item xs={12} md={10}>
                <Box className={classes.almost}>
                    <FontAwesomeIcon
                        icon={faPaperPlane}
                        className={classes.icon}
                    />
                    <Typography className={classes.title}>
                        Almost Done!
                    </Typography>
                    <Typography className={classes.subtitle}>
                        Your blast SMS Campaign is ready. Select your payment
                        method and send your messages.
                    </Typography>
                </Box>

                <Box className={classes.total}>
                    <label>Total</label>
                    <span>
                        <PriceFormat price={quote?.total || 0} />
                    </span>
                </Box>
                <Box className={classes.totalFooter}>
                    {quote?.subscriptions} SMS to selected{' '}
                    {props.moreThanOneRecepient ? 'clients' : 'client'}
                </Box>

                {errorMessage === null && successMessage === null && (
                    <AdyenPayment
                        className={classes.adyen}
                        campaignId={campaign?.id}
                        onError={paymentErrorHandler}
                        onComplete={paymentCompleteHandler}
                        hideRemovePaymentMethodButton={true}
                        hideStoredPaymentMethods={true}
                    />
                )}
                {errorMessage !== null && (
                    <Box className={classes.adyenResultMessageContainer}>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            className={clsx(classes.icon, classes.errorIcon)}
                        />
                        <Typography className={classes.error}>
                            {errorMessage}
                        </Typography>
                        <Button
                            label="Try again"
                            color="black"
                            variant="primary"
                            onClick={() => setErrorMessage(null)}
                            className={classes.tryAgainButton}
                        />
                    </Box>
                )}
                {successMessage !== null && (
                    <Box className={classes.adyenResultMessageContainer}>
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={clsx(classes.icon, classes.successIcon)}
                        />
                        <Typography className={classes.success}>
                            {successMessage}
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default SMSCreateThirdStep;
