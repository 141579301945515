import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTrashAlt,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  selectedQty: number;
  removeDeleteIcon?: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixedOverBarContainer: {
      position: "fixed",
      zIndex: 100,
      width: "100%",
      pointerEvents: "none",
      [theme.breakpoints.down("lg")]: {
        left: "0px",
        bottom: "70px",
      },
      [theme.breakpoints.up("xl")]: {
        left: "0px",
        bottom: "100px",
      },
    },
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    bar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#E9F0ED",
      pointerEvents: "auto",
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "40px",
        paddingRight: "40px",
        height: "55px",
        borderRadius: "55px",
        fontSize: "18px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "50px",
        paddingRight: "50px",
        height: "74px",
        borderRadius: "74px",
        fontSize: "24px",
      },
    },
    quantityContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "25%",
      [theme.breakpoints.down("lg")]: {
        minWidth: "120px",
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: "130px",
      },

    },
    quantityIcon: {
      color: "#5E8677",
      [theme.breakpoints.down("lg")]: {
        marginRight: "8px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "10px",
      },
    },
    quantity: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },
    },
    iconsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    customIconsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      cursor: "pointer",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "32px",
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: "44px",
      },
    },
  })
);

export const MultiselectionBar: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const deleteIcon = (
    <Box className={classes.iconContainer}>
      <FontAwesomeIcon
        className={classes.icon}
        icon={faTrashAlt}
        onClick={props.onDelete}
      />
    </Box>
  );

  return (
    <Box className={classes.fixedOverBarContainer}>
      <Box className={classes.container}>
        <Box className={classes.bar}>
          <Box className={classes.quantityContainer}>
            <FontAwesomeIcon
              className={classes.quantityIcon}
              icon={faCheckCircle}
            />
            <Typography>{props.selectedQty} Selected</Typography>
          </Box>
          <Box className={classes.iconsContainer}>
            <Box className={classes.customIconsContainer}>{props.children}</Box>
            {!props.removeDeleteIcon && deleteIcon}
            <Box className={classes.iconContainer}>
              <FontAwesomeIcon
                className={classes.icon}
                icon={faTimes}
                onClick={props.onClose}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MultiselectionBar;
