import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import { wbp, reduceResolution } from 'Theme';

interface NewChatHeaderProps {
    handleGoBack: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerNewChat: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(13)}px ${reduceResolution(
                    13
                )}px ${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '13px 13px 15px'
            },
            color: '#FFF',
            backgroundColor: '#000',
            borderBottom: '1px solid #D4D4D4'
        },
        menuContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10%',
            textAlign: 'center'
        },
        goBackIcon: {
            cursor: 'pointer'
        },
        searchContainer: {
            display: 'flex',
            width: '95%',
            margin: 0
        },
        newChatTitle: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px'
            },
            fontWeight: 600
        }
    })
);
const NewChatHeader: FunctionComponent<NewChatHeaderProps> = props => {
    const classes = useStyles();
    return (
        <Box className={classes.headerNewChat}>
            <Box className={classes.menuContainer}>
                <FontAwesomeIcon
                    className={classes.goBackIcon}
                    onClick={props.handleGoBack}
                    icon={faArrowLeft}
                />
            </Box>
            <Box className={classes.searchContainer}>
                <Typography className={classes.newChatTitle}>
                    New Chat
                </Typography>
            </Box>
        </Box>
    );
};

export default NewChatHeader;
