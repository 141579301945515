import { useMediaQuery } from '@material-ui/core';
import {
    Theme,
    createStyles,
    makeStyles,
    useTheme
} from '@material-ui/core/styles';
import { Option } from '@spike/model';
import PointerSteps from 'components/NewFeatures/PointerSteps';
import { SelectField, SelectionBar } from 'components/UI';
import useIsMobile from 'hooks/useIsMobile';
import { FunctionComponent } from 'react';

interface CalendarSelectorProps {
    selectedId: string;
    onSelect: (id: string) => void;
}

export const weekly = {
    id: 'week',
    name: 'Week'
};

export const daily = {
    id: 'day',
    name: 'Day'
};

export const monthly = {
    id: 'month',
    name: 'Month'
};

const options: Option<string>[] = [daily, weekly, monthly];

const breakpoint = 1200;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selector: {
            '& > div > div': {
                height: '29px'
            },
            [theme.breakpoints.up(breakpoint)]: {
                marginLeft: '20px'
            }
        },
        dropdown: {
            'height': '30px',
            '& .MuiOutlinedInput-root': {
                height: '30px'
            },
            '& p': {
                fontSize: '14px',
                fontWeight: 600
            },
            '& .MuiSelect-root': { padding: '11px 20px 11px 10px !important' },
            '& .MuiSelect-iconOutlined': { right: 0 }
        }
    })
);

export const CalendarSelector: FunctionComponent<
    CalendarSelectorProps
> = props => {
    const classes = useStyles();

    const isMobile = useIsMobile();

    const selectionBar = (
        <SelectionBar
            className={classes.selector}
            options={options}
            selectedId={props.selectedId}
            onSelect={props.onSelect}
        />
    );

    const dropDown = (
        <SelectField
            selectedOption={options.find(
                option => option.id === props.selectedId
            )}
            options={options}
            onSelect={option => props.onSelect(option.id.toString())}
            popUpChild={true}
            className={classes.dropdown}
        />
    );

    return isMobile ? dropDown : selectionBar;
};

export default CalendarSelector;
