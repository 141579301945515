import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { TextField2 } from 'components/UI';
import { FunctionComponent } from 'react';

interface InputTextProps {
	title: string;
	placeholder: string;
	value: string;
	onChange: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			paddingBottom: 13,
			[theme.breakpoints.up('md')]: {
				paddingBottom: 15
			}
		},
		title: {
			fontWeight: 600,
			fontSize: 16,
			lineHeight: '16px',
			marginBottom: 18,
			[theme.breakpoints.up('md')]: {
				marginBottom: 20
			}
		},
		field: {
			'fontSize': 14,
			'lineHeight': '18px',
			'marginTop': 10,
			'& textarea': {
				fontSize: 14,
				padding: '23px 19px',
				[theme.breakpoints.up('md')]: {
					padding: '23px 28px'
				}
			}
		}
	})
);

export const InputText: FunctionComponent<InputTextProps> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography className={classes.title}>{props.title}</Typography>
			<TextField2
				placeholder={props.placeholder}
				label=""
				className={classes.field}
				value={props.value}
				onChange={props.onChange}
			/>
		</Box>
	);
};

export default InputText;
