import React, { FunctionComponent, MouseEvent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

interface Props {
    name: string;
    bold?: boolean;
    hasMedicalConditions: boolean;
    deceased?: boolean;
    className?: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxPet: {
            'gap': 6,
            'fontSize': 15,
            'lineHeight': 1,
            'border': '1px solid transparent',
            'transition':
                'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'borderRadius': '20px',
            'display': 'inline-flex',
            'alignItems': 'center',

            [theme.breakpoints.up('md')]: {
                fontSize: 14,
                padding: '2px 10px 2px 10px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: 18
            },

            '&:hover': {
                [theme.breakpoints.up(768)]: {
                    cursor: 'pointer',
                    border: '1px solid',
                    background: '#eee'
                }
            }
        },

        bold: {
            fontWeight: 600
        },

        heartIcon: {
            fontSize: 17,
            color: '#EF4F57'
        }
    })
);

export const PetName: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const iconSVG = (icon: IconDefinition, styles: string) => {
        return <FontAwesomeIcon icon={icon} className={styles} />;
    };

    const clickHandler = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        props.onClick && props.onClick();
    };

    return (
        <Box
            className={clsx(
                classes.boxPet,
                { [classes.bold]: props.bold },
                props.className
            )}
            onClick={clickHandler}
        >
            {props.name}
            {props.deceased ? ' 🌈' : ''}
            {props.hasMedicalConditions &&
                iconSVG(faHeartbeat, `${classes.heartIcon}`)}
        </Box>
    );
};

export default PetName;
