import React, { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";
import { useMasterData } from "hooks";
import { Pet } from "@spike/pet-model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile,
  faMeh,
  faAngry,
  IconDefinition
} from "@fortawesome/pro-light-svg-icons"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

interface PetPersonalitySelectProps {
  selected: Pet;
  onChange: (personality?: Personality) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    titleElement: {
      paddingBottom: "8px",
      fontSize: "0.8rem",
      width: "100%",
    },
    boxSearch: {
      border: "1px solid #ccc",
      borderRadius: "30px",
      color: "#999",
      cursor: "default",
      display: "flex",
      fontSize: "0.8rem",
      justifyContent: "space-between",
      padding: "15px 19px",
      width: "150px",
      [theme.breakpoints.only("sm")]: {
        padding: "9px 15px 9px 30px",
        fontSize: "12px",
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "50px",
        fontSize: "14px",
        padding: "8px 15px 8px 30px",
        width: "190px",
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "50px",
        fontSize: "1rem",
        padding: "15px 15px 15px 30px",
        width: "190px",
      },
      "&:hover": {
        border: "1px solid #222",
      },

    },
    boxResult: {
      border: "1px solid #222",
      borderRadius: "0 0 20px 20px",
      padding: "5px 9px 5px 9px",
      width: "150px",
      position: "absolute",
      background: "#fff",
      marginTop: "35px",
      zIndex: 99999,
      [theme.breakpoints.down("md")]: {
        marginTop: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "35px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "190px",
        marginTop: "43px",
      },
    },
    boxResultOpen: {
      border: "1px solid #222",
      borderRadius: "20px 20px 0 0",
    },
    boxResultItem: {
      borderRadius: "10px",
      color: "#222",
      fontSize: "0.8rem",
      margin: "2px 0",
      padding: "5px 10px",
      "&:hover": {
        background: "#eee",
        cursor: "pointer",
      },
    },
    boxValueService: {
      width: "90%",
      whiteSpace: "nowrap",
      [theme.breakpoints.up("xl")]: {
        width: "90%",
      },
    },
    selected: {
      border: "1px solid #222",
      color: "#222",
    },

    listAlignedIcons: {
      width: "15px !important",
    },
    sectionIcon: {
      marginRight: "0.5rem",
    },
    sectionIconSearch: {
      marginRight: "0rem",
    },
    iconFace: {
      marginRight: "5px",
    },
  })
);

export interface Personality {
  behaviorConditionId: number;
  idBehaviour?: number;
  categoryID: number;
  name: string;
  icon: IconDefinition;
  order: number;
}

export const PetPersonalitySelect: FunctionComponent<PetPersonalitySelectProps> = (props) => {
  const classes = useStyles();
  const masterData = useMasterData();

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const [showBoxResult, setShowBoxResult] = useState(false);
  const [selected, setSelected] = useState<boolean | undefined>();
  const [personalitySelected, setPersonalitySelected] = useState<Personality>();
  const [listPersonality, setListPersonality] = useState<Array<Personality>>();

  useEffect(() => {
    const loadPersonality = () => {
      let array: Array<Personality> = [];
      let idBehaviour = props.selected?.personalities.length > 0 ? props.selected?.personalities![0].id : undefined;

      masterData.medicalHistory.behaviorConditionCategories
        .forEach((behavior) => {
          behavior.options.forEach((option) => {
            array.push({ order: asignOrderPersonality(option.name), behaviorConditionId: option.id, categoryID: behavior.id, idBehaviour: idBehaviour, name: option.name, icon: iconSearch(behavior.icon) });
          })
        })

      setListPersonality(array);

      if ((props.selected?.personalities!.length > 0) && (array !== undefined)) {
        const selection = array?.filter((p) => p.behaviorConditionId === props.selected!.personalities[0]!.conditionId);
        setPersonalitySelected(selection![0]);

        setSelected(true);
        setShowBoxResult(false);
      }
    };

    loadPersonality();
  }, [props.selected, masterData]);

  const iconSearch = (icon: string | undefined) => {
    switch (icon) {
      case "faSmile":
        return faSmile;
      case "faAngry":
        return faAngry;
      case "faMeh":
        return faMeh;
      default:
        return faSmile;
    }
  }

  const asignOrderPersonality = (name: string) => {
    switch (name) {
      case "Friendly":
        return 1
      case "Cage Shy":
        return 2
      case "People Shy":
        return 3
      case "Agressive":
        return 4
      default:
        return 5
    }
  }

  const selectionHandler = (id: number) => {
    const selection = listPersonality?.filter((p) => p.behaviorConditionId === id);
    setPersonalitySelected(selection![0]);
    setSelected(true);
    setShowBoxResult(false);
    props.onChange(selection![0]);
  }

  return (
    <ClickAwayListener onClickAway={() => setShowBoxResult(false)}>
      <Box className={clsx(classes.container, props.className)}>
        <Box
          className={
            clsx(classes.boxSearch,
              { [classes.boxResultOpen]: showBoxResult },
              { [classes.selected]: selected }
            )}
          onClick={() => (showBoxResult ? setShowBoxResult(false) : setShowBoxResult(true))
          }>

          <div className={classes.boxValueService}>
            <Box
              component="div"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {selected && personalitySelected ?
                <Box>
                  {iconSVG(personalitySelected!.icon, `${classes.iconFace}`)}
                  {personalitySelected?.name}
                </Box>
                :
                <Box>
                  {iconSVG(faSmile, `${classes.iconFace}`)}
                  {"Friendly"}
                </Box>
              }

            </Box>
          </div>
          <Box>
            {!showBoxResult && (iconSVG(faCaretDown, `${classes.sectionIconSearch}`))}
            {showBoxResult && (iconSVG(faCaretUp, `${classes.sectionIconSearch}`))}
          </Box>
        </Box>
        {
          showBoxResult && (
            <Box className={classes.boxResult}>
              {listPersonality && listPersonality
                .sort((a, b) => a.order > b.order ? 1 : -1)
                .map((personality) => (
                  <Box key={personality.categoryID + " " + personality.behaviorConditionId} className={classes.boxResultItem} onClick={() => (selectionHandler(personality!.behaviorConditionId))}>
                    {iconSVG(personality!.icon, `${classes.iconFace}`)}
                    {personality?.name}
                  </Box>
                ))}
            </Box>
          )
        }
      </Box>
    </ClickAwayListener>
  );
};

export default PetPersonalitySelect;
