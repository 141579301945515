import {
	Avatar,
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { InvoiceTipStaff } from '@spike/invoice-model';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface StaffProps {
	staff: InvoiceTipStaff;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			backgroundColor: '#F1F1F1',
			borderRadius: '100px',
			width: 'max-content',
			[theme.breakpoints.down(wbp)]: {
				padding: '8px 12px 8px 10px'
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '8px 12px 8px 10px'
			}
		},
		avatar: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(27)}px`,
				height: `${reduceResolution(27)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '27px',
				height: '27px'
			}
		},
		name: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: '#222222',
			textTransform: 'capitalize',
			textOverflow: 'ellipsis',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(14)}px`,
				lineHeight: `${reduceResolution(21)}px`,
				marginLeft: `${reduceResolution(8)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '14px',
				lineHeight: '21px',
				marginLeft: '10px'
			}
		}
	})
);

export const Staff: FunctionComponent<StaffProps> = props => {
	const classes = useStyles();
	return (
		<Box className={clsx(classes.container, props.className)}>
			<Avatar className={classes.avatar} src={props.staff.imageUrl} />
			<Typography className={classes.name}>{props.staff.name}</Typography>
		</Box>
	);
};

export default Staff;
