import { Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import AlertLabel from "components/PetCard/UI/AlertLabel";
import Pet from "@spike/pet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { convertToCategories } from "../../MedicalHistoryConverter";
import { MedicalCategory } from "./MedicalCategory";

interface MedicalConditionsProps {
  pet: Pet;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categories: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    categoryTopSpace: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(36)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "36px",
      },
    },
    alerts: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
    },
    alertInfo: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        "& p": {
          fontSize: "14px",
          fontWeight: 400,
        }
      }
    }
  })
);

export const MedicalConditions: FunctionComponent<MedicalConditionsProps> = (props) => {
  const classes = useStyles();

  const hasMedical = props.pet.medicalHistory.medicalConditions.length > 0;

  const medicalCategories = convertToCategories(props.pet.medicalHistory.medicalConditions);

  return (
    <Box className={props.className}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.alerts}>
            {hasMedical && (
              <AlertLabel
                className={classes.alertInfo}
                label={`${props.pet.medicalHistory.medicalConditions.length} Medical conditions that apply to ${props.pet.name}`}
                type="info"
              />
            )}
            {!hasMedical && <AlertLabel className={classes.alertInfo} label="No medical conditions added yet." type="info" />}
          </Box>
          {hasMedical && (
            <Box className={clsx(classes.categories)}>
              {medicalCategories.map((category, index) => (
                <MedicalCategory
                  key={index}
                  category={category}
                  className={clsx({ [classes.categoryTopSpace]: index > 0 })}
                />
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MedicalConditions;
