import {
	Grid,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

interface TitleProps {
	title: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			paddingBottom: 14,

			[theme.breakpoints.up('md')]: {
				paddingBottom: 21
			}
		},
		title: {
			width: '100%',
			color: '#222222',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontSize: 20
			}
		}
	})
);

export const Title: FunctionComponent<TitleProps> = props => {
	const classes = useStyles();

	return (
		<Grid container className={clsx(classes.container, props.className)}>
			<Typography className={classes.title}>{props.title}</Typography>
		</Grid>
	);
};

export default Title;
