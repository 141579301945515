import React, { useState, FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Dialog, DialogContent, Slider } from "@material-ui/core";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { Button } from "components/UI";
import { croppImgAsBlob } from "./cropImage";

interface CropperImageProps {
  image: string;
  fileName: string;
  cropWidth: number;
  cropHeight: number;
  viewScale?: number;
  onCrop: (file: File) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        borderRadius: "10px",
      },
    },
    dialogContent: (props: CropperImageProps) => ({
      width: (props.cropWidth * (props.viewScale || 1) + 80) + "px",
      height: (props.cropHeight * (props.viewScale || 1) + 194) + "px",
      padding: "20px",
    }),
    cropperContainer: (props: CropperImageProps) => ({
      position: "relative",
      width: (props.cropWidth * (props.viewScale || 1) + 40) + "px",
      height: (props.cropHeight * (props.viewScale || 1) + 40) + "px",
    }),
    footer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100px",
      paddingTop: "30px",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
    footerRow: {
      width: "100%",
    },
    zoom: {
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        lineHeight: "16px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "12px",
        lineHeight: "18px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "10px",
    },
  })
);

export const CropperImage: FunctionComponent<CropperImageProps> = (props) => {
  const classes = useStyles(props);

  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);


  const cropCompleteHandler = (croppedAreaPercentage: Area, croppedAreaPixels: Area) => {
    setCroppedArea({ x: croppedAreaPixels.x, y: croppedAreaPixels.y,width: croppedAreaPixels.width, height: croppedAreaPixels.height });
  };

  const cropHandler = async (image: string, croppedAreaPixels: Area) => {
    const croppedImage: Blob = await croppImgAsBlob(image, croppedAreaPixels);
    const file = new File([croppedImage], props.fileName, {type: croppedImage.type});
    props.onCrop(file);
  };

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.cropperContainer}>
          <Cropper
            image={props.image}
            crop={crop}
            zoom={zoom}
            aspect={props.cropWidth / props.cropHeight}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={cropCompleteHandler}
            cropSize={{ width: props.cropWidth * (props.viewScale || 1), height: props.cropHeight * (props.viewScale || 1) }}
            minZoom={0.5}
            maxZoom={5}
            zoomSpeed={0.2}
            style={{
              containerStyle: { borderRadius: "10px", border: "1px solid black" },
              cropAreaStyle: { width: "100%", height: "100%" },
              mediaStyle: {},
            }}
            restrictPosition={false}
          />
        </Box>
        <Box className={classes.footer}>
          <Box className={classes.footerRow}>
            <Typography className={classes.zoom}>Zoom:</Typography>
          </Box>
          <Box className={classes.footerRow}>
            <Slider
              value={zoom}
              min={0.5}
              max={5}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(event, zoom) => setZoom(typeof zoom === "number" ? zoom : zoom[0])}
              classes={{ root: "slider" }}
            />
          </Box>
          <Box className={classes.buttonsContainer}>
            <Button label="Crop" onClick={() => cropHandler(props.image, croppedArea!)} size="medium" />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CropperImage;
