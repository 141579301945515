import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useMasterData } from 'hooks';
import { SelectableOption } from 'model';
import { Option } from '@spike/model';
import { isEmpty } from 'lodash';

interface Props {
	homecare: Array<Option<number>>;
	isDentalReport: boolean;
	multipleSelection?: boolean;
	onChangeSelection: (homecare: Array<Option<number>>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				marginBottom: 24,
				textTransform: 'uppercase'
			}
		},
		content: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap',

			[theme.breakpoints.up('md')]: {
				columnGap: 20,
				rowGap: 12
			}
		},
		procedureRecordContainer: {
			height: 40,
			width: 'auto',
			borderRadius: 30,
			display: 'inline-flex',
			alignItems: 'center',
			backgroundColor: '#F0F4F2',

			[theme.breakpoints.up('xl')]: {
				height: 45
			}
		},
		procedureRecordSelectedContainer: {
			'height': 40,
			'width': 'auto',
			'borderRadius': 30,
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff !important'
			},

			[theme.breakpoints.up('xl')]: {
				height: 45
			}
		},

		label: {
			'fontSize': 16,
			'display': 'flex',
			'justifyContent': 'center',
			'padding': '0px 23px',

			'&:hover': {
				cursor: 'pointer'
			},

			[theme.breakpoints.up('md')]: {
				width: 102,
				paddingTop: '6px',
				paddingBottom: '8px',
				fontSize: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '8px',
				paddingBottom: '10px',
				fontSize: '20px',
				width: '154px'
			}
		}
	})
);

export const Homecare: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();

	const [homecare, setHomecare] = useState<Array<SelectableOption<number>>>(
		[]
	);

	useEffect(() => {
		const homecareAux = masterData.dentalReportOptions.homecare.map(hc => ({
			element: hc,
			selected: props.homecare.find(homec => homec.id === hc.id)
				? true
				: false
		}));
		setHomecare(homecareAux);
	}, [props.homecare]);

	const handleSelectHomecare = (value: Option<number>, selected: boolean) => {
		if (props.multipleSelection || selected) {
			const newHomecare = [...homecare];

			newHomecare.forEach(hc => {
				hc.selected =
					hc.element.id === value.id
						? selected
						: props.multipleSelection
						? hc.selected
						: false;
			});

			setHomecare(newHomecare);
			props.onChangeSelection(
				newHomecare.filter(hc => hc.selected).map(hc => hc.element)
			);
		}
	};

	return (
		<Box>
			{(props.isDentalReport ||
				(!isEmpty(props.homecare) && !props.isDentalReport)) && (
				<Typography className={classes.titleSection}>
					Homecare
				</Typography>
			)}

			{(props.isDentalReport ||
				(!isEmpty(props.homecare) && !props.isDentalReport)) && (
				<Box className={classes.content}>
					{props.isDentalReport
						? homecare.map(hc => {
								return (
									<Box
										key={hc.element.id}
										className={
											hc.selected
												? classes.procedureRecordSelectedContainer
												: classes.procedureRecordContainer
										}
									>
										<Typography
											className={classes.label}
											onClick={() =>
												handleSelectHomecare(
													hc.element,
													!hc.selected
												)
											}
										>
											{hc.element.name}
										</Typography>
									</Box>
								);
						  })
						: props.homecare.map(hc => {
								return (
									<Box
										key={hc.id}
										className={
											classes.procedureRecordSelectedContainer
										}
									>
										<Typography className={classes.label}>
											{hc.name}
										</Typography>
									</Box>
								);
						  })}
				</Box>
			)}
		</Box>
	);
};

export default Homecare;
