import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PetImage from "components/UI/PetImage";
import { Pet } from "@spike/pet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { useMasterData } from "hooks";

interface ImageAddPetProps {
  pet: Pet;
  onChange: (pet: Pet) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleContainer: {
      [theme.breakpoints.down(wbp)]: {
        marginLeft: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginLeft: "14px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "27px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      }
    },
    size: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#BCB8AE",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
        lineHeight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
        lineHeight: "20px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      }
    },
  })
);

export const ImageAddPet: FunctionComponent<ImageAddPetProps> = (props) => {
  const classes = useStyles();

  const masterData = useMasterData();

  return (
    <Box className={classes.container}>
      <Box className={classes.imageContainer} id="clients_div_upload_photo_pet">
        <PetImage pet={props.pet} onChangePet={() => {}} />
      </Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>Upload a photo of your pet</Typography>
        <Typography className={classes.size}>Max size: {masterData.imagesMaxSizes.avatarKb}Kb</Typography>
      </Box>
    </Box>
  );
};

export default ImageAddPet;
