import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { StaffMember } from "../../model/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import StaffChip from "../UI/StaffChip";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface Props {
  serviceStaff: Array<StaffMember>;
  onDelete: (staffId: number) => void;
  onAdd: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("lg")]: {
        width: "141px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "187px",
      },
      height: "fit-content",
    },
    titleContainer: {
      display: "flex",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px",
      },
    },
    contentContainer: {
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "column",
      height: "fit-content",
      [theme.breakpoints.down("lg")]: {
        marginTop: "15px",
        marginBottom: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "21px",
        marginBottom: "21px",
      },
    },
    memberContainer: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "17px",
      },
    },
    actionContainer: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    addMemberIcon: {
      [theme.breakpoints.down("lg")]: {
        marginLeft: "8px",
        marginRight: "8px",
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: "11px",
        marginRight: "11px",
      },
    },
    addMember: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("xl")]: {
        fonrSize: "16px",
      },
    },
  })
);

export const StaffRemove: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const members = props.serviceStaff
    .sort((s1, s2) => {
      return s1.firstName.localeCompare(s2.firstName);
    })
    .map((member) => (
      <Box key={member.id} className={classes.memberContainer}>
        <StaffChip
          staff={member}
          small={true}
          onDelete={() => {
            props.onDelete(member.id);
          }}
        />
      </Box>
    ));

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>MEMBERS</Typography>
      </Box>
      <Box className={classes.contentContainer}>
        <OverlayScrollbarsComponent style={{ maxHeight: '120px' }}>
          {members}
        </OverlayScrollbarsComponent>
      </Box>
      <Box className={classes.actionContainer} onClick={props.onAdd}>
        <FontAwesomeIcon
          className={classes.addMemberIcon}
          icon={faPlusCircle}
          size="sm"
        />
        <Typography className={classes.addMember}>Add Member</Typography>
      </Box>
    </Box>
  );
};

export default StaffRemove;
