import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Appointment from '@spike/appointment-model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons';
import PetImage from 'components/UI/PetCard/PetImage';
import Pet from '@spike/pet-model';
import PetDescription from 'components/UI/PetCard/PetDescription';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { Button, StaffChip } from 'components/UI';
import { useHistory } from 'react-router-dom';
import { daily } from 'components/Calendar3/CalendarHeader/CalendarSelector/CalendarSelector';

interface BookingRequestDetailProps {
    pet?: Pet;
    appointment: Appointment;

    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 24,
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        label: {
            margin: 0,
            fontSize: 14,
            lineHeight: 1,
            marginBottom: 4
        },
        requestedDate: {
            margin: 0,
            fontSize: 16,
            fontWeight: 600
        },
        requestedDateContainer: {
            padding: '24px 0px',
            borderBottom: '1px solid #D4D4D4'
        },
        customerInfoContainer: {
            'fontSize': 14,

            '& p': {
                'margin': 0,

                '& + *': {
                    marginTop: 3
                }
            }
        },
        customerName: {
            fontSize: 16,
            fontWeight: 600
        },
        calendarInfoContainer: {
            gap: 16,
            paddingTop: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',

            [theme.breakpoints.up('sm')]: {
                alignItems: 'center',
                flexDirection: 'row'
            }
        },
        calendarInfoLabel: {
            margin: 0,
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 8
        },
        appointmentDate: {
            'margin': 0,
            'fontSize': 16,
            'fontWeight': 500,

            '& svg': {
                marginRight: 6
            }
        },
        petServiceContainer: {
            padding: 16,
            borderRadius: 8,
            border: 'solid 1px #D4D4D4'
        },
        petInfoContainer: {
            gap: 16,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 16,
            borderBottom: '1px solid #D4D4D4'
        },
        petName: {
            fontSize: 16,
            fontWeight: 600
        },
        petImage: {
            'width': 'auto',

            '& svg': {
                fontSize: 24
            }
        },
        serviceInfoContainer: {
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'paddingTop': 16,

            '& p': {
                margin: 0,
                fontSize: 14
            },
            '& svg': {
                marginRight: 4
            }
        },
        serviceName: {
            fontWeight: 500
        },
        serviceMeta: {
            gap: 8,
            marginTop: 4,
            display: 'flex',
            alignItems: 'center',
            color: '#7A7A7A'
        },

        staffChip: {
            '& .MuiChip-root .MuiChip-avatar': {
                margin: 0
            }
        },

        notesContainer: {},
        notesLabel: {
            fontSize: 16,
            fontWeight: 600,
            marginTop: 0,
            marginBottom: 8
        },
        notes: {
            height: 160,
            fontSize: 16,
            lineHeight: 1.3,
            padding: '20px 24px',
            border: 'solid 1px #D4D4D4',
            borderRadius: 20
        }
    })
);

const BookingRequestDetail: React.FC<BookingRequestDetailProps> = props => {
    const classes = useStyles();

    const history = useHistory();

    const getServiceDuration = () => {
        const timeInMinutes = props.appointment?.duration.to.diff(
            props.appointment?.duration.from,
            'minutes'
        );
        return timeInMinutes! / 60 > 1
            ? `${Math.trunc(timeInMinutes! / 60)}hrs ${
                  timeInMinutes! % 60 !== 0 ? timeInMinutes! % 60 : ''
              }${timeInMinutes! % 60 !== 0 ? ' min' : ''}`
            : `${timeInMinutes! === 60 ? 60 : timeInMinutes! % 60} min`;
    };

    const goToCalendaryDayHandler = () => {
        props.onClose();

        history.push(
            `/calendar?calendar=${
                daily.id
            }&date=${props.appointment.duration.from.format('YYYY-MM-DD')}`
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.requestedDateContainer}>
                <p className={classes.label}>Received on</p>
                <p className={classes.requestedDate}>
                    {props.appointment.createAt?.format(
                        'dddd, DD MMM YYYY - hh:mmA'
                    )}
                </p>
            </div>

            <div className={classes.customerInfoContainer}>
                <p className={classes.customerName}>
                    {props.appointment.customer.firstName}
                    {props.appointment.customer.lastName}
                </p>
                {props.appointment.customer.phone && (
                    <p>{props.appointment.customer.phone}</p>
                )}
                {props.appointment.customer.email && (
                    <p>{props.appointment.customer.email}</p>
                )}
            </div>

            <div className={classes.calendarInfoContainer}>
                <div>
                    <p className={classes.calendarInfoLabel}>Booking Detail</p>
                    <p className={classes.appointmentDate}>
                        <FontAwesomeIcon icon={faCalendarDays} />
                        {props.appointment.duration.from.format(
                            'dddd, DD MMM YYYY - hh:mmA'
                        )}
                    </p>
                </div>

                <Button
                    color="black"
                    label="View on Calendar"
                    onClick={goToCalendaryDayHandler}
                />
            </div>

            <div className={classes.petServiceContainer}>
                <div className={classes.petInfoContainer}>
                    <PetImage
                        className={classes.petImage}
                        petImage={props.appointment.pet.image}
                        petType={props.appointment.pet.type?.id}
                    />
                    <PetDescription pet={props.pet} />
                </div>
                <div className={classes.serviceInfoContainer}>
                    <div>
                        <p className={classes.serviceName}>
                            {props.appointment.services[0].name}
                        </p>
                        <div className={classes.serviceMeta}>
                            <p>
                                $
                                {props.appointment.services[0].price.toFixed(2)}
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faClock} />
                                {getServiceDuration()}
                            </p>
                        </div>
                    </div>

                    <div className={classes.staffChip}>
                        <StaffChip
                            staff={props.appointment.services[0].staff}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.notesContainer}>
                <p className={classes.notesLabel}>Booking Notes</p>
                <p className={classes.notes}>{props.appointment.notes}</p>
            </div>
        </div>
    );
};

export default BookingRequestDetail;
