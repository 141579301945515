import {
	Box,
	ClickAwayListener,
	Dialog,
	DialogContent,
	Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';
import { Button } from 'components/UI';
import React, { FunctionComponent, useState } from 'react';

interface PopUpUpdateBlockCalendarProps {
	onClose: () => void;
	onConfirm: (recurrent: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'& .MuiDialog-paper': {
				boxShadow: '0px 10px 40px #00000029',
				[theme.breakpoints.down(wbp)]: {
					width: `${reduceResolution(600)}px`,
					minWidth: `${reduceResolution(600)}px`,
					height: `${reduceResolution(270)}px`,
					borderRadius: `${reduceResolution(21)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					width: '600px',
					minWidth: '600px',
					height: '270px',
					borderRadius: '21px'
				}
			},
			'& .MuiDialogContent-root': {
				overflowY: 'hidden',
				[theme.breakpoints.down(wbp)]: {
					padding: `${reduceResolution(15)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					padding: '15px'
				}
			}
		},

		containerMin: {
			'& .MuiDialog-paper': {
				[theme.breakpoints.down(wbp)]: {
					height: `${reduceResolution(270)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					height: '270px'
				}
			}
		},

		boxContainer: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%'
		},

		boxHeader: {},

		boxSpinner: {
			display: 'flex',
			height: '100%'
		},

		radioServices: {
			'display': 'flex',
			'& .MuiRadio-colorSecondary.Mui-checked': {
				color: '#222'
			},
			[theme.breakpoints.down('sm')]: {
				'marginBottom': 15,
				'paddingBottom': 15,
				'borderBottom': '1px solid #F1F1F1',
				'& .MuiFormControlLabel-root': {
					marginLeft: 0
				},
				'& .MuiRadio-root': {
					paddingTop: 7,
					paddingLeft: 0,
					paddingBottom: 7
				}
			}
		},
		dialogContainer: {
			'width': '100%',
			'& .MuiDialog-paper': {
				overflowY: 'hidden'
			},
			'display': 'flex',
			'flexDirection': 'column'
		},
		boxButtons: {
			borderTop: '1px solid #DDDDDD',
			paddingTop: '15px',
			display: 'flex',
			justifyContent: 'center'
		},
		boxButtonsFlex: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(400)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '400px'
			}
		},
		buttonsContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		button: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(180)}px`,
				height: `${reduceResolution(40)}px`,
				marginBottom: `${reduceResolution(5)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '180px',
				height: '50px',
				marginBottom: '5px'
			}
		},
		bodyContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			paddingBottom: '20px'
		},
		boxRadioContainer: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(380)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '380px'
			},
		},
		appointmentsContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: '85%'
		},
		question: {
			fontWeight: 600,
			textAlign: 'center',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(30)}px`,
				lineHeight: `${reduceResolution(30)}px`,
				paddingTop: `${reduceResolution(50)}px`,
				paddingBottom: `${reduceResolution(20)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '30px',
				lineHeight: '30px',
				paddingTop: '50px',
				paddingBottom: '20px'
			}
		},
		radioItems: {
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px'
			}
		}
	})
);

const updateSingle = 'single';
const updateRecurrent = 'recurrent';

export const PopUpUpdateBlockCalendar: FunctionComponent<
	PopUpUpdateBlockCalendarProps
> = props => {
	const classes = useStyles();

	const [selectedOption, setSelectedOption] = useState(updateRecurrent);
	const [updating, setUpdating] = useState(false);

	const confirmHandler = () => {
		setUpdating(true);
		props.onConfirm(selectedOption === updateRecurrent);
	};

	const closeHandler = () => !updating && props.onClose();

	const singleRadio = (
		<FormControlLabel
			value={updateSingle}
			control={<Radio />}
			key={updateSingle}
			label={
				<React.Fragment>
					<Typography className={classes.radioItems}>
						Update only this blocked time
					</Typography>
				</React.Fragment>
			}
		/>
	);

	const recurrentRadio = (
		<FormControlLabel
			value={updateRecurrent}
			control={<Radio />}
			key={updateRecurrent}
			label={
				<React.Fragment>
					<Typography className={classes.radioItems}>
						Update this and recurring blocked time
					</Typography>
				</React.Fragment>
			}
		/>
	);
	const optionsView = (
		<Box className={classes.bodyContainer}>
			<Box className={classes.boxRadioContainer}>
				<RadioGroup
					aria-label="appointments"
					value={selectedOption}
					name="appointments"
					className={classes.radioServices}
					onChange={(_event, value) => setSelectedOption(value)}
				>
					{recurrentRadio}
				</RadioGroup>
			</Box>
		</Box>
	);

	const contentView = (
		<>
			<Box className={classes.boxContainer}>
				<Box className={classes.boxHeader}>
					<Typography className={classes.question}>
						Update Recurring Blocked Time
					</Typography>
				</Box>
				{optionsView}
			</Box>
			<Box className={classes.boxButtons}>
				<Box className={classes.boxButtonsFlex}>
					<Box className={classes.buttonsContainer}>
						<Button
							label="Cancel"
							onClick={closeHandler}
							variant="primary"
							size="medium"
							color="black"
							className={classes.button}
						/>
						<Button
							label="Update"
							loading={updating}
							onClick={confirmHandler}
							variant="primary"
							color="green"
							size="medium"
							className={classes.button}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);

	return (
		<Box>
			<Dialog open={true} className={classes.container}>
				<ClickAwayListener onClickAway={closeHandler}>
					<DialogContent className={classes.dialogContainer}>
						{contentView}
					</DialogContent>
				</ClickAwayListener>
			</Dialog>
		</Box>
	);
};

export default PopUpUpdateBlockCalendar;
