import { Reducer } from "redux";
import { GlobalSearchState, GlobalSearchStatus } from "./GlobalSearchState";
import {
  GlobalSearchAction,
  GLOBALSEARCH_ERROR_ACTION_TYPE,
  GLOBALSEARCH_START_ACTION_TYPE,
  GLOBALSEARCH_SUCCESS_ACTION_TYPE,
} from "actions/globalSearch/GlobalSearchActionsTypes";

const initialState: GlobalSearchState = {
  results: [],
  status: GlobalSearchStatus.Initial,
  loading: false,
};

export const globalSearchReducer: Reducer<GlobalSearchState, GlobalSearchAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GLOBALSEARCH_START_ACTION_TYPE:
      return {
        ...state,
        status: GlobalSearchStatus.Searching,
        loading: true,
        results: []
      };
    case GLOBALSEARCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: GlobalSearchStatus.SearchingSuccess,
        results: action.payload.results,
        loading: false,
      };
    case GLOBALSEARCH_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: GlobalSearchStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default globalSearchReducer;
