import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ForgotPasswordStatus, forgotPasswordThunk, resetThunk } from "@spike/forgot-password-action";
import { FunctionComponent, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ForgotPasswordSentPath, LoginPath } from "routes/Paths";
import { RootState } from "store";
import arrow from "../../assets/images/arrow-left.svg";
import PrimaryButton from "../Buttons/PrimaryButton";
import ErrorAlert from "../Commons/ErrorAlert";
import CustomInput from "../Forms/CustomInput";
import ApiClient from "api/ApiClient";

const initialFormValues = {
  email: "",
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    position: "relative",
  },
  back: {
    top: 5,
    left: 0,
    bottom: 0,
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  backImage: {
    width: 26,
  },
  title: {
    marginTop: 0,
    marginBottom: 30,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  paragraph: {
    maxWidth: 220,
    marginTop: 0,
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 5,
    textAlign: "center",
    "& + &": {
      marginBottom: 30,
    },
  },
  paragraphBold: {
    fontWeight: 600,
  },
  column: {
    display: "flex",
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    marginLeft: "auto",
    minHeight: "calc(100vh - 142px)",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F8F5F1",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "55%",
      flexBasis: "55%",
      paddingTop: 0,
      paddingLeft: "7%",
      paddingRight: "9%",
      paddingBottom: 80,
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "100vh",
      paddingTop: 50,
      paddingLeft: "7%",
      paddingRight: "13%",
      paddingBottom: 0,
    },
  },
  formColumn: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 5,
    "&:first-child": {
      marginBottom: 20,
    },
  },
}));

export const RightColumn: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const forgotPasswordStatus = useSelector<RootState, ForgotPasswordStatus>((state) => state.forgotPassword.status);
  const [sending, setSending] = useState(false);

  const [errors, setErrors] = useState({
    email: false,
  });

  const [invalid, setInvalid] = useState(false);
  const [values, setValues] = useState(initialFormValues);

  useEffect(() => {
    dispatch(resetThunk());
  }, []);

  useEffect(() => {
    switch(forgotPasswordStatus) {
      case ForgotPasswordStatus.SendSuccess:
        setSending(false);
        history.push(ForgotPasswordSentPath);
        break;
      case ForgotPasswordStatus.UnknowEmail:
        setInvalid(true);
        setSending(false);
        break;
      case ForgotPasswordStatus.Error:
        setSending(false);
        break;
    }
  }, [forgotPasswordStatus, history]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? false : true;
      if (fieldValues.email) temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? false : true;
    }

    setErrors({ ...temp });
  };

  const handleInputValue = (event: any) => {
    const { name, value } = event.target;

    setInvalid(false);
    setValues({ ...values, [name]: value });
    validate();
  };

  const formIsValid = (fieldValues = values) => {
    const isValid = !!fieldValues.email && Object.values(errors).every((x) => x === false);

    return isValid;
  };

  const handleFormSubmit = async (event: MouseEvent) => {
    event.preventDefault();
    validate();

    if (formIsValid()) {
      setSending(true);
      dispatch(forgotPasswordThunk(values.email, ApiClient));
    }
  };

  return (
    <Grid item xs={12} className={classes.column}>
      <div className={classes.heading}>
        <Link to={LoginPath} className={classes.back}>
          <img src={arrow} alt="Arrow left" className={classes.backImage} />
        </Link>
        <h2 className={classes.title}>Ok, let’s do a reset</h2>
      </div>

      <p className={[classes.paragraph, classes.paragraphBold].join(" ")}>Reset Password</p>

      <p className={classes.paragraph}>Write your email to send you a reset link.</p>

      <Grid container spacing={2}>
        {invalid && (
          <Grid className={classes.formColumn} item xs={12} sm={10}>
            <ErrorAlert label="No account found with that email" />
          </Grid>
        )}
        <Grid className={classes.formColumn} item xs={12} sm={10}>
          <CustomInput
            label="Email"
            name="email"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["email"] && { error: true })}
          />
        </Grid>
        <Grid className={classes.formColumn} item xs={12} sm={10}>
          <PrimaryButton label="Send Reset Link" loading={sending} onClick={handleFormSubmit} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightColumn;
