import {
  OnboardingStepsAction,
  ONBOARDING_STEPS_RESET_ACTION_TYPE,
  ONBOARDING_STEPS_FETCH_ACTION_TYPE,
  ONBOARDING_STEPS_FETCH_SUCCESS_ACTION_TYPE,
  ONBOARDING_STEPS_ERROR_ACTION_TYPE,
  ONBOARDING_STEPS_OPEN_MODAL_ACTION_TYPE,
  ONBOARDING_STEPS_CLOSE_MODAL_ACTION_TYPE,
  ONBOARDING_STEPS_SHOW_VIDEO_ACTION_TYPE,
  ONBOARDING_STEPS_HIDE_VIDEO_ACTION_TYPE,
  ONBOARDING_STEPS_SET_ACTION_TYPE,
  ONBOARDING_STEPS_SET_SUCCESS_ACTION_TYPE,
} from "./OnboardingStepsActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";

import { convertOnboardingSteps } from "./OnboardingStepsConverter";
import { alertErrorHandler } from "@spike/notifications-action";
import OnboardingStepsDtos from "./OnboardingStepsDtos";

import { OnboardingStepsState } from "reducers/onboardingSettings/onboardingStepsState";

import store from "store";
import { setNotificationBellAmount } from "actions/notificationBell/NotificationBellActions";
import { NotificationBellAction } from "actions/notificationBell/NotificationBellActionsTypes";
import { OnboardingSteps, OnboardingStepsEnum } from "model/OnboardingSteps";

const onboardingStepsUrl = "/marketplace/settings_status";

export const fetchOnboardingStepsThunk = (): ThunkAction<
  void,
  OnboardingStepsState,
  null,
  OnboardingStepsAction | NotificationBellAction
> => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${onboardingStepsUrl}?marketplace_id=${marketplaceId}`;
    try {
      const response: AxiosResponse<OnboardingStepsDtos> = await ApiClient.get(
        url,
        createTokenConfig(store.getState().login.auth.token!)
      );

      const onboardingPendings = Object.entries(response.data).filter(
        (onboardingDone, index, arr) =>
          arr[index][1] === false
      ).length;

      dispatch(fetchSucces(convertOnboardingSteps(response.data)));
      dispatch(setNotificationBellAmount(onboardingPendings));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error onboarding steps load.");
      }
    }
  };
};


export const setOnboardingStepsThunk = (stepName: OnboardingStepsEnum): ThunkAction<
  void,
  OnboardingStepsState,
  null,
  OnboardingStepsAction | NotificationBellAction
> => {
  return async (dispatch) => {
    dispatch(setStart());
    const onBoardingProgress = store.getState().onboardingSteps.onboardingSteps;
    onBoardingProgress[stepName] = true;
    console.log(onBoardingProgress);
    try {
      
      const onboardingPendings = Object.entries(onBoardingProgress).filter(
        (onboardingDone, index, arr) =>
          arr[index][1] === false
      ).length;

      dispatch(setSuccess(onBoardingProgress));
      dispatch(setNotificationBellAmount(onboardingPendings));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error onboarding steps load.");
      }
    }
  };
};

export const reset = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_RESET_ACTION_TYPE,
  };
};

const fetchStart = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_FETCH_ACTION_TYPE,
  };
};

const setStart = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_SET_ACTION_TYPE,
  };
};

export const fetchSucces = (
  onboardingSteps: OnboardingSteps
): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      onboardingSteps,
    },
  };
};

export const setSuccess = (
  onboardingSteps: OnboardingSteps
): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_SET_SUCCESS_ACTION_TYPE,
    payload: {
      onboardingSteps,
    },
  };
};

const error = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_ERROR_ACTION_TYPE,
  };
};

export const onboardingOpenModal = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_OPEN_MODAL_ACTION_TYPE,
  };
};
export const onboardingCloseModal = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_CLOSE_MODAL_ACTION_TYPE,
  };
};
export const onboardingShowVideo = (url: string): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_SHOW_VIDEO_ACTION_TYPE,
    payload: url,
  };
};
export const onboardingHideVideo = (): OnboardingStepsAction => {
  return {
    type: ONBOARDING_STEPS_HIDE_VIDEO_ACTION_TYPE,
  };
};
