import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Fragment, FunctionComponent } from 'react';
import TitlePrimary from 'components/UI/Title/TitlePrimary';
import PrimaryButton from 'rocket/components/Buttons/PrimaryButton';
import PoweredByCuddlesPay from '../Transactions/PoweredByCuddlesPay';

interface NoBankAccountsProps {
    className?: string;
    onClick: () => void;
    showButton: boolean;
    isButtonDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            textAlign: 'center',
            padding: theme.spacing(4)
        },
        boxWrapper: {
            position: 'relative',
            marginTop: '-100px'
        },
        typoTitle: {
            fontWeight: 600,
            fontSize: '30px',
            color: '#BAA997',
            fontFamily: 'YoungSerif',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                fontSize: '26px',
                color: '#000',
                fontFamily: 'Poppins'
            }
        },
        typoMessage: {
            color: '#000',
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px'
            }
        },
        button: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: 35,
            paddingRight: 35
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            paddingBottom: '30px',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                '@supports (-webkit-touch-callout: none)': {
                    paddingBottom: '110px'
                }
            }
        }
    })
);

export const NoBankAccounts: FunctionComponent<NoBankAccountsProps> = props => {
    const classes = useStyles();

    return (
        <Fragment>
            <Box className={clsx(classes.boxContainer, props.className)}>
                <Box className={classes.boxWrapper}>
                    <TitlePrimary className={classes.typoTitle}>
                        No Bank Accounts Yet
                    </TitlePrimary>
                    <Typography className={classes.typoMessage}>
                        You have no registered bank accounts
                    </Typography>

                    {props.showButton && (
                        <PrimaryButton
                            label="Add Bank Account"
                            className={classes.button}
                            onClick={props.onClick}
                            disabled={props.isButtonDisabled}
                        />
                    )}
                </Box>
                <Box className={classes.footer}>
                    <PoweredByCuddlesPay />
                </Box>
            </Box>
        </Fragment>
    );
};

export default NoBankAccounts;
