import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "components/UI";
import moment, { Moment } from "moment-timezone";
import { FunctionComponent, useState } from "react";
import { Calendar as CalendarDateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { reduceResolution, wbp } from "Theme";

export interface CalendarProps {
  date?: Moment;
  rawDate?: boolean;
  onChangeDate: (date: Moment) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#fff",
      height: "auto",
      paddingTop: "10px",
      [theme.breakpoints.down("lg")]: {
        fontSize: `${reduceResolution(16)}px`,
        borderRadius: `${reduceResolution(15)}px`,
        width: `${reduceResolution(344)}px`,
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
        borderRadius: "15px",
        width: "344px",
      },
    },
    calendar: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "& .rdrMonth": {
        width: "auto",
      },
      "& .rdrWeekDay": {
        fontWeight: 600,
        color: "#000 !important",
      },
      "& .rdrDayNumber": {
        fontWeight: 400,
        color: "#000 !important",
      },
      "& .rdrMonthAndYearPickers select": {
        fontWeight: 600,
        backgroundColor: "#fff !important",
      },
      "& .rdrSelected": {
        fontWeight: 600,
        backgroundColor: "#E9F0ED !important",
      },
      "& .rdrDateDisplayItemActive": {
        borderColor: "#fff !important",
      },
      "& .rdrDayToday .rdrDayNumber span:after": {
        display: "none",
      },
      "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span":
      {
        color: "#000",
        fontWeight: 600,
      },
      "& .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview":
      {
        border: "none",
      },

      "&.rdrDayHovered": {
        border: "none",
      },
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "12px",
      },
    },
    actionsContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "24px",
      },
    },
    button: {
      border: "none !important",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(136)}px`,
        height: `${reduceResolution(40)}px`,
        borderRadius: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "136px",
        height: "40px",
        borderRadius: "8px",
      },
      "& .MuiButton-label": {
        [theme.breakpoints.down("lg")]: {
          fontSize: `${reduceResolution(14)}px`,
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "14px",
        },
      },
    },
    cancel: {
      color: "#000",
      [theme.breakpoints.down("lg")]: {
        marginRight: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "8px",
      },
    },
  })
);

export const Calendar: FunctionComponent<CalendarProps> = (props) => {
  const classes = useStyles();

  const [expirationDate, setExpirationDate] = useState<Moment | undefined>(props.date);

  const handleChangeExpirationDate = (expiration: Date) => {
    setExpirationDate(moment(expiration));
    props.onClose();
    props.onChangeDate(moment(expiration));
  };

  return (
    <Box className={clsx(classes.container)}>
      <Box>
        <CalendarDateRange
          date={props.rawDate === true && expirationDate ? new Date(Number(expirationDate.format('YYYY')), Number(expirationDate.format('M')) - 1, Number(expirationDate.format('D'))) : expirationDate ? expirationDate.clone().toDate() : expirationDate}
          onChange={handleChangeExpirationDate}
          showMonthArrow={false}
          fixedHeight={true}
          weekStartsOn={1}
          color="transparent"
          weekdayDisplayFormat={"EEEEEE"}
          className={classes.calendar}
        />
        <Box className={classes.actionsContainer}>
          <Button
            label="Cancel"
            variant="secondary"
            className={clsx(classes.button, classes.cancel)}
            color="green"
            onClick={() => props.onClose()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
