import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { FunctionComponent } from "react";
import { wbp } from "Theme";

interface EnrollmentStepsProps extends PropsWithChildren {
  sidebar?: JSX.Element;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      overflowY: "auto",
    },
    sidebar: {
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        minWidth: "349px",
      },
      [theme.breakpoints.up(wbp)]: {
        minWidth: "436px",
      },
    },
    step: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      overflowY: "auto",
    },
  })
);

export const EnrollmentSteps: FunctionComponent<EnrollmentStepsProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      {props.sidebar && <Box className={classes.sidebar}>{props.sidebar}</Box>}
      <Box className={classes.step}>{props.children}</Box>
    </Box>
  );
};

export default EnrollmentSteps;
