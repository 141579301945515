import store from 'store';
import { v4 as uuid } from 'uuid';
import { Moment } from 'moment-timezone';
import {
    SmsReceiverType,
    SmsSenderType
} from 'actions/twoWaySms/twoWaySmsDtos';

export interface SmsMessage {
    id?: number | undefined;
    uuid: string;
    marketplaceId: number;
    clientId: number;
    senderId: number;
    senderType: SmsSenderType;
    receiverId: number;
    receiverType: SmsReceiverType;
    message: string;
    note: boolean;
    notification: boolean;
    sentAt?: Date;
    receivedAt?: Date;
    readAt?: Date;
    createdAt?: Moment;
}

export interface SmsChat {
    client: SmsClient;
    message: SmsMessage;
    unread: number;
}

export interface SmsClient {
    id: number;
    name: string;
}

export enum SmsActorType {
    CUSTOMER = 'Customer',
    STAFF = 'Staff'
}

export const createEmptySmsMessage = (clientId: number): SmsMessage => {
    const marketplaceId = store.getState().login.auth.marketplaceId ?? 1;
    return {
        uuid: uuid(),
        marketplaceId: marketplaceId,
        clientId: clientId,
        senderId: 0,
        senderType: SmsSenderType.STAFF,
        receiverId: clientId,
        receiverType: SmsReceiverType.CUSTOMER,
        message: '',
        note: false,
        notification: false
    };
};
