import {
    Box,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core';
import { SearchComponent } from 'components/UI';
import { toLower } from 'lodash';
import SMSCampaign from 'model/SMSCampaign';
import { FunctionComponent, useMemo, useState } from 'react';
import CampaignItem from './SearchCampaignItem';

interface SearchCampaignProps {
    page: number;
    maxPages: number;
    loading: boolean;
    campaigns: SMSCampaign[];
    selectedCampaign?: SMSCampaign;
    onNextPage: () => void;
    onCampaignChange: (campaign: SMSCampaign) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderRadius: 20,
            overflow: 'hidden',
            backgroundColor: '#FFF',
            border: '1px solid #D4D4D4',
            [theme.breakpoints.down('md')]: {
                border: 'none',
                borderRadius: 0
            }
        },
        header: {
            padding: '13px 13px 15px',
            backgroundColor: '#F1F1F1',
            borderBottom: '1px solid #D4D4D4',
            [theme.breakpoints.down('md')]: {
                backgroundColor: '#fff',
                borderBottom: 'none',
                padding: '0 16px'
            }
        },
        search: {
            'height': 47,
            [theme.breakpoints.down('md')]: {
                'backgroundColor': '#F1F1F1',
                'padding': '11px 12px',
                'height': 71,
                'borderRadius': 12,
                '& .MuiInputBase': {
                    '&-input': {
                        height: 47
                    }
                }
            },
            '& .MuiInputBase': {
                '&-root': {
                    'width': '100%',
                    'backgroundColor': '#fff',
                    '& svg': {
                        width: 16,
                        height: 16,
                        color: '#7A7A7A'
                    }
                },
                '&-input': {
                    fontSize: 14
                }
            }
        },
        body: {
            'flex': 1,
            'overflow': 'hidden scroll',
            'padding': '12px 12px 0px',
            [theme.breakpoints.down('md')]: {
                maxHeight: 'none',
                padding: '10px 0px 0px',
                height: '100%'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '10px 16px 0px'
            },
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        listContainer: {
            [theme.breakpoints.down('xs')]: {
                '& div:last-child': {
                    border: 'none'
                }
            }
        },
        loading: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        loadMore: {
            fontSize: 14,
            fontWeight: 500,
            color: '#EAB464',
            display: 'block',
            cursor: 'pointer',
            padding: '8px 0px',
            textAlign: 'center',
            marginBottom: 8
        }
    })
);

const SearchCampaign: FunctionComponent<SearchCampaignProps> = props => {
    const classes = useStyles();

    const [search, setSearch] = useState<string>('');

    const searchCampaigns = useMemo(() => {
        return props.campaigns.filter(campaign => {
            return toLower(campaign.name).includes(search);
        });
    }, [search]);

    const onCampaignClick = (campaign: SMSCampaign) => {
        props.onCampaignChange(campaign);
    };

    const onSearch = (term: string) => {
        setSearch(term.length > 0 ? toLower(term) : '');
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <SearchComponent
                    open={true}
                    onSearch={onSearch}
                    className={classes.search}
                    placeholder="Search Campaign"
                />
            </Box>
            <Box className={classes.body}>
                {props.loading && (
                    <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                )}

                {!props.loading && (
                    <Box className={classes.listContainer}>
                        {search.length === 0 &&
                            props.campaigns.map((campaign, i) => (
                                <CampaignItem
                                    key={i}
                                    campaign={campaign}
                                    onClick={onCampaignClick}
                                    active={
                                        props.selectedCampaign?.id ===
                                        campaign.id
                                    }
                                />
                            ))}

                        {search.length > 0 &&
                            searchCampaigns.map((campaign, i) => (
                                <CampaignItem
                                    key={i}
                                    campaign={campaign}
                                    onClick={onCampaignClick}
                                    active={
                                        props.selectedCampaign?.id ===
                                        campaign.id
                                    }
                                />
                            ))}

                        {props.page < props.maxPages && (
                            <span
                                onClick={props.onNextPage}
                                className={classes.loadMore}
                            >
                                Load more
                            </span>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SearchCampaign;
