import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { wbp } from 'Theme';
import { Img } from 'react-image';
interface PaymentMethodProps {
	paymentMethodId: string;
	paymentMethodName: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center'
		},
		icon: {
			color: '#7A7A7A',
			[theme.breakpoints.down(wbp)]: {
				width: '60px !important',
				height: '21px !important',
				marginRight: '11px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '81px !important',
				height: '27px !important',
				marginRight: '14px'
			}
		}
	})
);

export const PaymentMethod: FunctionComponent<PaymentMethodProps> = props => {
	const classes = useStyles();

	const getIconPaymentMethod = () => {
		switch (props.paymentMethodId) {
			case 'cash':
				return 'images/paymentMethods/icon-cash.svg';
			case 'card':
				return 'images/paymentMethods/icon-cash.svg';
			case 'pos':
				return 'images/paymentMethods/icon-pos.svg';
			default:
				return 'images/paymentMethods/icon-other.svg';
		}
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Img src={getIconPaymentMethod()} className={classes.icon} />
		</Box>
	);
};

export default PaymentMethod;
