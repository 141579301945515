import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { reduceResolution, wbp } from 'Theme';

interface WeekAheadItemProps {
    label: string;
	selected?: boolean;
	className?: string;
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
            alignItems: 'center',
			cursor: 'pointer',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				borderRadius: `${reduceResolution(8)}px`,
				padding: `${reduceResolution(5)}px ${reduceResolution(10)}px`,
				margin: `${reduceResolution(5)}px 0px`
			  },
			  [theme.breakpoints.up(wbp)]: {
				borderRadius: "8px",
				padding: "5px 10px",
				margin: "5px 0px",
			  },
		},
		selected: {
			backgroundColor: '#222222',
		},
		label: {
			fontFamilty: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`,
			  },
			  [theme.breakpoints.up(wbp)]: {
				fontSize: "16px",
			  },
		},
		labelSelected: {
			color: 'white'
		}
	})
);


export const WeekAheadItem: FunctionComponent<WeekAheadItemProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, {[classes.selected]: props.selected},props.className)} onClick={props.onClick}>
            <Typography className={clsx(classes.label, {[classes.labelSelected]: props.selected})}>{props.label}</Typography>
        </Box>
	);
};

export default WeekAheadItem;
