import { Box, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import { PetType } from '@spike/model';
import { PriceField } from 'components/UI';
import { FunctionComponent, useEffect } from 'react';
import { PriceError } from '../model';
import { FixedPriceOption } from 'model/Service';
import { isEmpty, isEqual } from 'lodash';
import { usePricingContext } from './PricingContext/PricingContext';
import MinPriceAlert from './UI/MinPriceAlert';
import { useMarketplace } from 'hooks';
import { useFixedPriceManagement } from './hooks/useFixedPricesManagment';
import { MarketplaceDepositType } from '@spike/marketplace-model';

export interface FixedPriceType {
    petType: PetType;
    price?: number;
    uuid?: string;
}

interface FixedPriceProps {
    fixedPrices: Array<FixedPriceOption>;
    id: string;
    errors: Array<PriceError>;
    onServiceDone?: (done: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            maxWidth: 800
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 30
        },
        priceContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8
        },
        petType: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000'
        },
        errorMessage: {
            color: 'red',
            fontSize: 16,
            fontWeight: 400,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        },
        alert: {
            marginTop: 20
        }
    })
);

export const FixedPrice: FunctionComponent<FixedPriceProps> = props => {
    const classes = useStyles();
    const { updatePricing } = usePricingContext();
    const { selectedPricingOption, petTypes, selectedPetType, showErrors } = usePricingContext().state;
    const { deposit } = useMarketplace();

    const { fixedPrices, errors, showAlert, handlePriceChange, checkPriceAlerts } = useFixedPriceManagement(
        props.fixedPrices,
        deposit,
        props.errors,
        showErrors
    );

    useEffect(() => {
        props.onServiceDone &&
            props.onServiceDone(
                fixedPrices?.every(
                    price =>
                        price.price !== 0 &&
                        (deposit.type === MarketplaceDepositType.Amount ? Number(deposit.value) <= price.price! : true)
                )
            );
    }, [fixedPrices, deposit.type, deposit.value, props.onServiceDone]);

    const updatePricingHandler = () => {
        checkPriceAlerts();
        updatePricing(fixedPrices);
    };

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.container}>
                {fixedPrices?.map((fixedPrice, index) => (
                    <Box key={fixedPrice.petType.id} className={classes.priceContainer}>
                        {showErrors &&
                        !isEmpty(
                            errors.find(error => isEqual(error.petTypeId, fixedPrice.petType.id))?.errorMessage
                        ) ? (
                            <Typography className={classes.errorMessage}>
                                Price for {fixedPrice.petType.plural} is required
                            </Typography>
                        ) : (
                            <Typography className={classes.petType}>Price for {fixedPrice.petType.plural}</Typography>
                        )}

                        <PriceField
                            id={props.id ? `${props.id}_input_price_${fixedPrice.petType.plural}_${index}` : props.id}
                            placeholder="$0.00"
                            value={fixedPrice.price === 0 ? undefined : fixedPrice.price}
                            onChange={price => handlePriceChange(fixedPrice.petType, price)}
                            onBlur={updatePricingHandler}
                            error={
                                showErrors &&
                                errors?.find(error => isEqual(error.petTypeId, fixedPrice.petType.id)) !== undefined
                            }
                        />
                    </Box>
                ))}
            </Box>
            {showAlert.length > 0 && (
                <Box>
                    <MinPriceAlert
                        pricingOption={selectedPricingOption}
                        petType={selectedPetType!}
                        petTypes={petTypes}
                        className={classes.alert}
                    />
                </Box>
            )}
        </Box>
    );
};
