import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { FunctionComponent } from 'react';
import Button from './Button';

interface SaveButtonProps {
	size?: number;
	className?: string;
	onClick?: () => void;
}

export const SaveButton: FunctionComponent<SaveButtonProps> = props => {
	return (
		<Button
			icon={faCheck}
			size={props.size}
			className={props.className}
			onClick={props.onClick}
		/>
	);
};

export default SaveButton;
