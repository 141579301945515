import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Button } from 'components/UI';

interface Props {
	id?: string;
	buttonLabel?: string;
	onNext: () => void;
	disabled?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
			paddingBottom: 32,

			[theme.breakpoints.down('sm')]: {
				paddingTop: 20
			},
			[theme.breakpoints.up('md')]: {
				paddingBottom: '19px',
				paddingLeft: '29px',
				paddingRight: '29px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingBottom: '26px',
				paddingLeft: '39px',
				paddingRight: '39px'
			}
		},
		button: {
			[theme.breakpoints.down('sm')]: {
				'height': 55,
				'width': '100%',
				'borderRadius': 30,
				'backgroundColor': '#EAB464',

				'&.Mui-disabled': {
					backgroundColor: 'rgb(234 180 100 / 40%)'
				}
			}
		}
	})
);

export const ActionSection: FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Button
				id={props.id}
				color="green"
				onClick={props.onNext}
				disabled={props.disabled}
				className={classes.button}
				label={props.buttonLabel || 'Next'}
			/>
		</Box>
	);
};

export default ActionSection;
