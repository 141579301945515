import ActionCable, { Cable, Channel } from "actioncable";
import { Auth } from "@spike/auth-model";
import { PosSuccessStatus, PosFailureStatus } from "model/WebSockets";
import { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface PosWebSocketProps {
  invoiceId: number;
  terminalId: number;
  onMessageReceived?: (message: string, error: boolean) => void;
  onSuccess?: () => void;
  onError?: () => void;
  onGetPosServiceId?: (serviceId: string) => void;
}

const PosWebSockets: FunctionComponent<PosWebSocketProps> = (props) => {
  const auth = useSelector<RootState, Auth>((state) => state.login.auth);

  useEffect(() => {
    let cable: Cable;
    let channel: Channel;

    if (auth.signedIn && auth.marketplaceId !== undefined) {
      [cable, channel] = subscribe(auth.marketplaceId, props.invoiceId, props.terminalId);
    }
    return () => {
      unsubscribe(cable, channel, props.invoiceId, props.terminalId);
    };
  }, []);

  const subscribe = (marketplaceId: number, invoiceId: number, terminalId: number): [Cable, Channel] => {
    const cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
    );
    const channel = cable!.subscriptions.create(
      {
        channel: "PosTerminalChannel",
        marketplace_id: auth.marketplaceId,
        terminal_id: terminalId,
        invoice_id: invoiceId,
      },
      { received: (message: any) => messageHandler(message) }
    );

    //console.info({ action: "PosWebSocket subscribed!", invoiceId, terminalId });

    return [cable, channel];
  };

  const unsubscribe = (cable: Cable, channel: Channel, invoiceId: number, terminalId: number) => {
    channel && channel.unsubscribe();
    cable && cable.disconnect();

    /*console.info({
      action: "PosWebSocket unsubscribed!",
      cable,
      channel,
      invoiceId,
      terminalId,
    });*/
  };

  const messageHandler = (webSocketMessage: any) => {
    //console.info({ action: "PosWebSocket message received", webSocketMessage });
    const isFailure = webSocketMessage.body.status?.toUpperCase() === PosFailureStatus;
    props.onMessageReceived && props.onMessageReceived(webSocketMessage.body.message, isFailure);
    if (webSocketMessage.body.status?.toUpperCase() === PosSuccessStatus) {
      props.onSuccess && props.onSuccess();
    } else if (isFailure) {
      props.onError && props.onError();
    }
  };

  return null;
};

export default PosWebSockets;
