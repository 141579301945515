import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from "react"
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import { Option } from "./ChooseModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"

enum FiltersType {
    ALL = "all",
    SELECTED = "selected",
    UNSELECTED = "unselected"
}

interface Props {
    label: string,
    value: Option[],
    items: Option[],
    onChange: (selected: Option[]) => void
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
    },
    tabs: {
        top: 0,
        zIndex: 10,
        width: "100%",
        position: "sticky",
        padding: "0px 45px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #D4D4D4",

        "& button": {
            border: 0,
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            color: "#BCB8AE",
            cursor: "pointer",
            padding: "18px 0px 14px",
            backgroundColor: "transparent",
            borderBottom: "solid 2px transparent",

            "& + button": {
                marginLeft: 22
            },
            "&.active": {
                color: "#222",
                borderBottomColor: "#222"
            }
        }
    },
    table: {
        width: "100%",
    },
    tableRow: {
        color: "#222",
        display: "flex",
        alignItems: "center",
        padding: "15px 48px",

        "& div:nth-child(2)": {
            width: "50%",
            fontWeight: 600
        },
        "& div:last-child": {
            width: "40%",
        },

        "& .MuiButtonBase-root": {
            padding: 0,
            color: "#222",
            marginRight: 18
        }
    },
    tableHead: {
        top: 51,
        zIndex: 10,
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 600,
        paddingTop: 15,
        position: "sticky",
        backgroundColor: "#fff",
        borderBottom: "2px solid #000000",
    },
    tableBodyRow: {
        borderBottom: "solid 1px #DDDDDD",
    },
    checkbox: {
        width: 18,
        height: 18,
        fontSize: 14,
        color: "#fff",
        marginLeft: 3,
        marginRight: 24,
        borderRadius: 2,
        cursor: "pointer",
        border: "solid 2px #000",

        "& svg": {
            display: "none"
        },

        "&[data-checked='true']": {
            backgroundColor: "#000",

            "& svg": {
                display: "block"
            }
        }
    }
}));

const ChooseModalResults: FunctionComponent<Props> = ({ label, value, items, onChange }) => {
    const classes = useStyles();

    const [filter, setFilter] = useState<FiltersType>(FiltersType.ALL);

    /**
     * Functions
     */
    const optionIsInValue = (opt: Option): boolean => {
        return value.some((o) => o.id === opt.id);
    };

    const isChecked = (opt: Option): boolean => {
        if ([FiltersType.ALL, FiltersType.SELECTED].includes(filter))
            return optionIsInValue(opt);

        if (filter === FiltersType.UNSELECTED)
            return !optionIsInValue(opt);

        return true;
    };

    /**
     * Computed
     */
    const filteredResults = useMemo(() => {
        return items.filter((item) => {
            if (filter === FiltersType.ALL) return true;

            if (filter === FiltersType.SELECTED) return optionIsInValue(item);

            if (filter === FiltersType.UNSELECTED) return !optionIsInValue(item);
        });
    }, [items, value, filter]);

    const selectedAll = useMemo(() => {
        return filter !== FiltersType.UNSELECTED ? value.length === items.length : value.length === 0;
    }, [filter, items, value]);

    /**
     * Events
     */
    const selectAllToggle = () => {
        if (filter === FiltersType.UNSELECTED) {
            onChange(selectedAll ? items : []);
        } else if (filter === FiltersType.SELECTED) {
            onChange(selectedAll ? [] : items);
        } else {
            onChange(selectedAll ? [] : items);
        }
    };

    const itemToggle = (opt: Option) => {
        if (isChecked(opt) && filter !== FiltersType.UNSELECTED)
            onChange(value.filter((o) => o.id !== opt.id));
        else
            onChange(value.filter((o) => o.id !== opt.id).concat(opt));
    };

    /**
     * Components
     */
    const Checkbox = ({ checked, onClick }: { checked: boolean, onClick(c: boolean): void }) => (
        <Fragment>
            <Box
                onClick={() => onClick(checked)}
                data-checked={checked}
                className={classes.checkbox}>
                <FontAwesomeIcon icon={faCheck} />
            </Box>
        </Fragment>
    );

    const Filters = () => (
        <Box className={classes.tabs}>
            <button type="button"
                onClick={() => setFilter(FiltersType.ALL)}
                className={clsx({ active: filter === FiltersType.ALL })}>
                All ({items.length})
            </button>
            <button type="button"
                onClick={() => setFilter(FiltersType.SELECTED)}
                className={clsx({ active: filter === FiltersType.SELECTED })}>
                Selected ({value.length})
            </button>
            <button type="button"
                onClick={() => setFilter(FiltersType.UNSELECTED)}
                className={clsx({ active: filter === FiltersType.UNSELECTED })}>
                Unselected ({items.length - value.length})
            </button>
        </Box>
    );

    const TableRow = ({ item }: { item: Option }) => (
        <Fragment>
            <Box className={clsx(classes.tableBodyRow, classes.tableRow)} id={`item-${item.id}`}>
                <Checkbox
                    checked={isChecked(item)}
                    onClick={() => itemToggle(item)} />

                <Box>{item.label}</Box>
                <Box>{item.count} Clients</Box>
            </Box>
        </Fragment>
    );

    const Table = () => (
        <Box className={classes.table}>
            <Box className={clsx(classes.tableHead, classes.tableRow)}>
                <Checkbox
                    checked={selectedAll}
                    onClick={selectAllToggle} />

                <Box>{label}</Box>
            </Box>

            {filteredResults.map((item, i) => (<TableRow key={i} item={item} />))}
        </Box>
    );

    return (
        <Box className={classes.root}>
            <Filters />

            <Table />
        </Box>
    );
};

export default ChooseModalResults;