import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';
import { Mark } from '../SelectionBar';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

interface Props {
    label: string;
    selected: boolean;
    selectedBackgroundColor?: string;
    onClick: () => void;
    mark?: Mark;
    count?: number;
    className?: string;
    id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: 39,
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            position: 'relative',
            borderRadius: '30px',
            paddingLeft: 15,
            paddingRight: 15,

            [theme.breakpoints.up(1025)]: {
                paddingLeft: '20px',
                paddingRight: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: '34px',
                paddingLeft: '25px',
                paddingRight: '25px'
            }
        },
        selectedContainer: {
            background: (props: Props) =>
                props.selectedBackgroundColor || '#92B4A7'
        },
        label: {
            gap: 4,
            fontSize: 15,
            lineHeight: 1,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: 18
            }
        },
        errorLabel: {
            [theme.breakpoints.down('md')]: {
                marginRight: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '8px'
            }
        },
        selectedLabel: {
            color: '#ffffff'
        },
        icon: {
            marginRight: -2,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        mark: {
            position: 'absolute',
            verticalAlign: 'top',
            [theme.breakpoints.down('md')]: {
                right: '4px',
                fontSize: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                right: '4px',
                fontSize: '8px'
            },
            [theme.breakpoints.only('xl')]: {
                right: '8px',
                fontSize: '11px'
            }
        },
        warningMark: {
            color: theme.palette.primary.main
        },
        errorMark: {
            color: '#E36E74',
            [theme.breakpoints.down('md')]: {
                width: '11px !important',
                height: '11px !important',
                marginLeft: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '13px !important',
                height: '13px !important'
            },
            [theme.breakpoints.only('xl')]: {
                width: '16px !important',
                height: '16px !important'
            }
        }
    })
);

const createMark = (
    markType: Mark,
    classes: ClassNameMap<'mark' | 'warningMark' | 'errorMark'>
) => {
    if (markType === Mark.WARNING) {
        return (
            <FontAwesomeIcon
                className={clsx(classes.mark, classes.warningMark)}
                icon={faCircle}
            />
        );
    } else if (markType === Mark.ERROR) {
        return (
            <FontAwesomeIcon
                className={clsx(classes.mark, classes.errorMark)}
                icon={faExclamationCircle}
            />
        );
    } else {
        return null;
    }
};

export const SelectionBarItem: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    <FontAwesomeIcon className={classes.mark} icon={faCircle} />;

    const count = props.selected && props.count ? ` (${props.count})` : '';
    return (
        <Box
            id={`${props.id || ''}_${props.label}`}
            className={clsx(
                {
                    [classes.container]: true,
                    [classes.selectedContainer]: props.selected
                },
                props.className
            )}
            onClick={props.onClick}
        >
            <Typography
                className={clsx({
                    [classes.label]: true,
                    [classes.errorLabel]: props.mark === Mark.ERROR,
                    [classes.selectedLabel]: props.selected
                })}
            >
                {props.label}
                {count}

                <FontAwesomeIcon icon={faAngleDown} className={classes.icon} />
            </Typography>
            {props.mark && !props.selected && createMark(props.mark, classes)}
        </Box>
    );
};

export default SelectionBarItem;
