import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from './CommonStyles';
import HeaderCell from './HeaderCell';

interface TableHeaderProps {
	className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(50)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '50px'
			}
		},
		header: {
			backgroundColor: '#FAFAFA',
			borderBottom: '2px solid black',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: '18px',
				paddingBottom: '18px'
			}
		}
	})
);

export const TableHeader: FunctionComponent<TableHeaderProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<Grid
			container
			className={clsx(
				classes.header,
				commonClasses.rowContainer,
				props.className
			)}
		>
			<HeaderCell xs={3} title="Client" />
			<HeaderCell
				xs={1}
				title="# Pets"
				className={commonClasses.cellCenter}
			/>
			<HeaderCell xs={2} title="Phone" />
			<HeaderCell
				xs={1}
				title="Visits"
				className={commonClasses.cellCenter}
			/>
			<HeaderCell xs={1} title="Last Visit" />
			<HeaderCell xs={2} title="Next Visit" />
			<HeaderCell xs={1} />
			<HeaderCell xs={1} />
		</Grid>
	);
};

export default TableHeader;
