import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { getInvoiceThunk } from '@spike/invoices-action';
import clsx from 'clsx';
import { Button, PriceField2, Spinner, DescriptionField2 } from 'components/UI';
import Invoice from '@spike/invoice-model';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InvoicesStatus } from '@spike/invoices-action';
import { RootState } from 'store';
import { wbp } from 'Theme';
import { Items } from './Items';
import { Payment } from '@spike/payments-model';
import { refundThunk } from '@spike/payments-action';
import { PaymentsStatus } from '@spike/payments-action';
import { FieldError } from '@spike/model';
import { useApiClientWrapper } from 'hooks';

interface RefundProps {
	payment: Payment;
	className?: string;
	onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			height: '100%',
			display: 'flex',
			overflowY: 'auto',
			flexDirection: 'column',
			backgroundColor: '#ffffff',

			[theme.breakpoints.up('md')]: {
				width: '680px',
				minHeight: '90vh',
				maxHeight: '95vh',
				borderRadius: 27
			}
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			padding: '24px 16px',

			[theme.breakpoints.up('md')]: {
				height: '54px',
				justifyContent: 'center',
				backgroundColor: '#222222'
			}
		},
		leftHeader: {
			flex: 1,

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		centerHeader: {},
		rightHeader: {
			flex: 1,
			textAlign: 'right'
		},
		headerTitle: {
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,
			textAlign: 'center',

			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				color: '#ffffff',
				textTransform: 'uppercase'
			}
		},
		closeIcon: {
			fontSize: 22,
			cursor: 'pointer',

			[theme.breakpoints.up('md')]: {
				color: '#ffffff'
			}
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: '#ffffff',
			padding: '16px 16px 4px',

			[theme.breakpoints.up('md')]: {
				padding: '32px 32px 0px 32px'
			}
		},
		bodyHeader: {
			display: 'flex',
			flexWrap: 'wrap',
			marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				marginBottom: 32
			}
		},
		bodyHeaderLeft: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.down('sm')]: {
				marginBottom: 16,
				paddingBottom: 16,
				borderBottom: 'solid 1px #D4D4D4'
			},
			[theme.breakpoints.up('md')]: {
				width: '70%'
			}
		},
		bodyHeaderRight: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				width: '30%',
				alignItems: 'flex-end'
			}
		},
		name: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		email: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 400,
			color: '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		title: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 400,
			color: '#8E8E8E',
			marginBottom: 8,
			letterSpacing: '1.12px',
			textTransform: 'uppercase'
		},
		date: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 400,
			color: '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		reason: {
			'& textarea': {
				[theme.breakpoints.down(wbp)]: {
					height: '60px !important'
				},
				[theme.breakpoints.up(wbp)]: {
					height: '80px !important'
				}
			}
		},
		footer: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: 'white',
			padding: '0px 16px 24px',

			[theme.breakpoints.up('md')]: {
				marginTop: 24,
				padding: '0px 32px 24px'
			}
		},
		footerRow: {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'flex-end',
			justifyContent: 'space-between',
			marginTop: 16,

			[theme.breakpoints.up(wbp)]: {
				padding: '15px 0px'
			}
		},

		spinner: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '40px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '50px'
			}
		},
		amountField: {
			'width': '100%',

			[theme.breakpoints.up('md')]: {
				width: '180px'
			},

			'& .MuiInputBase-root': {
				height: 54,

				[theme.breakpoints.up('md')]: {
					height: 47
				}
			}
		},
		button: {
			[theme.breakpoints.down('sm')]: {
				'height': 55,
				'width': '100%',
				'marginTop': 32,

				'& span': {
					fontSize: 18
				}
			}
		}
	})
);

export const Refund: FunctionComponent<RefundProps> = props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const invoice = useSelector<RootState, Invoice | undefined>(
		state => state.invoices.invoice
	);
	const invoicesStatus = useSelector<RootState, InvoicesStatus>(
		state => state.invoices.status
	);

	const paymentsStatus = useSelector<RootState, PaymentsStatus>(
		state => state.payments.status
	);

	const [loading, setLoading] = useState(true);
	const [refunding, setRefunding] = useState(false);
	const [reason, setReason] = useState('');
	const [refundAmount, setRefundAmount] = useState(0);
	const [errors, setErrors] = useState<Array<FieldError>>([]);

	useEffect(() => {
		dispatch(getInvoiceThunk(apiClientWrapper, props.payment.invoice.id));
	}, []);

	useNonInitialEffect(() => {
		if (InvoicesStatus.GetInvoiceSuccess === invoicesStatus) {
			setLoading(false);
		}
	}, [invoicesStatus]);

	useNonInitialEffect(() => {
		if (PaymentsStatus.RefundSuccess === paymentsStatus) {
			setRefunding(false);
			props.onClose && props.onClose();
		} else if (PaymentsStatus.Error === paymentsStatus) {
			setRefunding(false);
		}
	}, [paymentsStatus]);

	const validate = (amount: number, payment: Payment): Array<FieldError> => {
		const errors: Array<FieldError> = [];

		if (amount <= 0) {
			errors.push({
				fieldName: 'amount',
				errorMessage: 'Amount must be greater than 0.00'
			});
		}

		if (amount > 0 && amount > Number(payment.balance)) {
			errors.push({
				fieldName: 'amount',
				errorMessage: `Amount cannot be greater than $${payment.balance}`
			});
		}

		return errors;
	};

	const refundHandler = () => {
		const errors = validate(refundAmount, props.payment);

		if (errors.length === 0) {
			setRefunding(true);
			dispatch(
				refundThunk(
					apiClientWrapper,
					props.payment.id,
					refundAmount,
					reason
				)
			);
		}

		setErrors(errors);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.header}>
				<Box className={classes.leftHeader}></Box>
				<Box className={classes.centerHeader}>
					<Typography className={classes.headerTitle}>
						Refund
					</Typography>
				</Box>
				<Box className={classes.rightHeader}>
					<FontAwesomeIcon
						className={classes.closeIcon}
						icon={faTimes}
						onClick={() => props.onClose && props.onClose()}
					/>
				</Box>
			</Box>
			<Box className={classes.body}>
				{loading && <Spinner className={classes.spinner} />}

				{!loading && (
					<>
						<Box className={classes.bodyHeader}>
							<Box className={classes.bodyHeaderLeft}>
								<Typography className={classes.name}>
									{invoice!.customer?.name}
								</Typography>
								<Typography className={classes.email}>
									{invoice!.customer?.email}
								</Typography>
							</Box>
							<Box className={classes.bodyHeaderRight}>
								<Typography className={classes.title}>
									INVOICE DATE
								</Typography>
								<Typography className={classes.date}>
									{invoice?.invoiceDate.format(
										'll - hh:mm a'
									)}
								</Typography>
							</Box>
						</Box>
						<Items invoice={invoice!} payment={props.payment} />
					</>
				)}
			</Box>

			<Box className={classes.footer}>
				{!loading && (
					<Fragment>
						<Box className={classes.footerRow}>
							<DescriptionField2
								label="Reason (optional)"
								value={reason}
								onChange={value => {
									setReason(value);
								}}
								maxRows={3}
								className={classes.reason}
							/>
						</Box>
						<Box className={classes.footerRow}>
							<PriceField2
								label="Amount:"
								name="amount"
								errors={errors}
								value={0}
								className={classes.amountField}
								onChange={value => setRefundAmount(value || 0)}
								scale={2}
							/>
							<Button
								label="Refund"
								loading={refunding}
								disabled={refundAmount <= 0}
								className={classes.button}
								onClick={refundHandler}
							/>
						</Box>
					</Fragment>
				)}
			</Box>
		</Box>
	);
};

export default Refund;
