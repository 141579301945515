import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { Pet } from "@spike/pet-model";
import { ItemLabelRead } from "components/UI/ItemLabelRead";
import { DateFormat } from "components/UI";
import moment from "moment";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface SideBarPetVisitProps {
  pet: Pet;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxFlexRow: {
      display: "flex",
      flexDirection: "row",
    },
    noneVisit: {
      border: "1px solid #000",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(23)}px`,
        marginTop: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "23px",
        marginTop: "8px",
      },
    },
    // typoVisit: {
    //   [theme.breakpoints.down(wbp)]: {
    //     fontSize: `${reduceResolution(16)}px`,
    //   },
    //   [theme.breakpoints.up(wbp)]: {
    //     fontSize: "16px",
    //   },
    // },
    title: {
      fontSize: 15,
      fontWeight: 400,
      color: '#A3A3A3',
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    description: {
      fontSize: 16,
      fontWeight: 500,
      color: '#000000',
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        fontWeight: 500,
      },
    },
  })
);

export const SidebarPetVisit: FunctionComponent<SideBarPetVisitProps> = (props) => {
  const classes = useStyles();

  const noneVisit = <Box className={classes.noneVisit}></Box>

  return (
    <Box className={clsx(props.className)}>
      <Grid container>
        <Grid item xs={5}>
          <ItemLabelRead classTitle={classes.title} title={"LAST VISIT"} description={(props.pet?.lastAppointmentId !== undefined)
            ? <DateFormat date={moment(props.pet?.lastAppointmentDate)} className={classes.description} />
            : noneVisit} />
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={5}>
          <ItemLabelRead classTitle={classes.title} title={"NEXT VISIT"} description={(props.pet?.nextAppointmentId !== undefined)
            ? <DateFormat date={moment(props.pet?.nextAppointmentDate)} className={classes.description} />
            : noneVisit} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SidebarPetVisit;
