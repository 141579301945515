import { Action } from "redux";
import { OnboardingSteps } from "model/OnboardingSteps";
//Types
export const ONBOARDING_STEPS_RESET_ACTION_TYPE = "onboardingSteps/reset";
export const ONBOARDING_STEPS_CLEAR_ACTION_TYPE = "onboardingSteps/clear";
export const ONBOARDING_STEPS_FETCH_ACTION_TYPE = "onboardingSteps/fetch_start";
export const ONBOARDING_STEPS_SET_ACTION_TYPE = "onboardingSteps/set_start";
export const ONBOARDING_STEPS_FETCH_SUCCESS_ACTION_TYPE =
  "onboardingSteps/fetch_success";
export const ONBOARDING_STEPS_SET_SUCCESS_ACTION_TYPE =
  "onboardingSteps/set_success";
export const ONBOARDING_STEPS_ERROR_ACTION_TYPE = "onboardingSteps/error";
export const ONBOARDING_STEPS_OPEN_MODAL_ACTION_TYPE =
  "onboardingSteps/open_modal";
export const ONBOARDING_STEPS_CLOSE_MODAL_ACTION_TYPE =
  "onboardingSteps/close_modal";
export const ONBOARDING_STEPS_SHOW_VIDEO_ACTION_TYPE =
  "onboardingSteps/show_video";
export const ONBOARDING_STEPS_HIDE_VIDEO_ACTION_TYPE =
  "onboardingSteps/hide_video";

//Marketplace Onboarding
interface OnboardingStepsResetAction
  extends Action<typeof ONBOARDING_STEPS_RESET_ACTION_TYPE> { }

interface OnboardingStepsClearAction
  extends Action<typeof ONBOARDING_STEPS_CLEAR_ACTION_TYPE> { }

interface OnboardingStepsFetchAction
  extends Action<typeof ONBOARDING_STEPS_FETCH_ACTION_TYPE> { }

  interface OnboardingStepsSetAction
  extends Action<typeof ONBOARDING_STEPS_SET_ACTION_TYPE> { }

interface OnboardingStepsFetchSuccessAction
  extends Action<typeof ONBOARDING_STEPS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    onboardingSteps: OnboardingSteps;
  };
}

interface OnboardingStepsSetSuccessAction
  extends Action<typeof ONBOARDING_STEPS_SET_SUCCESS_ACTION_TYPE> {
  payload: {
    onboardingSteps: OnboardingSteps;
  };
}

interface OnboardingStepsOpenModalAction
  extends Action<typeof ONBOARDING_STEPS_OPEN_MODAL_ACTION_TYPE> { }
interface OnboardingStepsCloseModalAction
  extends Action<typeof ONBOARDING_STEPS_CLOSE_MODAL_ACTION_TYPE> { }
interface OnboardingStepsShowVideoAction
  extends Action<typeof ONBOARDING_STEPS_SHOW_VIDEO_ACTION_TYPE> {
  payload: string;
}
interface OnboardingStepsHideVideoAction
  extends Action<typeof ONBOARDING_STEPS_HIDE_VIDEO_ACTION_TYPE> { }
interface OnboardingStepsErrorAction
  extends Action<typeof ONBOARDING_STEPS_ERROR_ACTION_TYPE> { }

//OnboardingAction
export type OnboardingStepsAction =
  | OnboardingStepsResetAction
  | OnboardingStepsClearAction
  | OnboardingStepsFetchAction
  | OnboardingStepsSetAction
  | OnboardingStepsFetchSuccessAction
  | OnboardingStepsSetSuccessAction
  | OnboardingStepsOpenModalAction
  | OnboardingStepsCloseModalAction
  | OnboardingStepsErrorAction
  | OnboardingStepsShowVideoAction
  | OnboardingStepsHideVideoAction;
