import { Box, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { reduceResolution, wbp } from "Theme";
import { TextField2, PhoneField3 } from "components/UI";
import clsx from "clsx";
import set from "lodash/set";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TerminalOrder,
} from "@spike/terminal-model";
import { FieldError } from "@spike/model";

interface OrderShippingInfoProps {
  order: TerminalOrder;
  errors: Array<FieldError>;
  className?: string;
  //
  onChange: (order: TerminalOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typoTitle: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px 0",
      },
    },
  })
);

export const OrderShippingInfo: FunctionComponent<OrderShippingInfoProps> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  const changeBusinessHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedOrder = { ...props.order };
      set(updatedOrder, fieldName, value);
      props.onChange(updatedOrder);
    }
  }

  return (
    <Box>
      <Box className={classes.typoTitle}>
        <Typography className={clsx(terminalCommonClasses.typo20_600)}>Shipping Information</Typography>
      </Box>

      <Box className={terminalCommonClasses.boxRadiusWhite}>
        <Box className={terminalCommonClasses.rowContainer}>
          <TextField2
            placeholder={"Full Name"}
            label="Name"
            name="deliveryName"
            value={props.order.deliveryName ? props.order.deliveryName : ""}
            onChange={changeBusinessHandler}
            required={true}
            errors={props.errors}
          />
        </Box>
        <Box className={terminalCommonClasses.rowContainer}>
          <PhoneField3
            placeholder={"Phone Number"}
            label="Phone Number"
            name="deliveryPhone"
            value={props.order.deliveryPhone ? props.order.deliveryPhone : ""}
            onChange={changeBusinessHandler}
            required={true}
            errors={props.errors}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderShippingInfo;
