import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import HeaderGroomingReport from 'components/UI/HeaderReport';
import Remarks from 'components/UI/Remarks';
import NextGroomingDate from 'components/UI/DateReport';
import { Option } from '@spike/model';
import Recommendations from 'components/UI/Recommendations';
import { Button } from 'components/UI';
import { Img } from 'react-image';
import BeforeAndAfter from './UI/BeforeAndAfter/BeforeAndAfter';
import GroomingReport, { Finding } from 'model/GroomerReport';
import OptionsComponent from './UI/OptionsComponent';
import { useApiClientWrapper, useHasPremiumAccess, useMasterData } from 'hooks';
import FindingsComponent from './UI/FindingsComponent';
import { reduceResolution, wbp } from 'Theme';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import { MobileDivider } from 'components/UI/MobileDivider';
import PremiumFeatureAlert from 'components/UI/PremiumFeatureAlert';
import { getClientThunk } from '@spike/clients-action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

interface Props {
    groomingReport: GroomingReport;
    singleImage: boolean;
    onSave: (report: GroomingReport) => void;
    onSkip?: () => void;
    onSend: (report: GroomingReport) => void;
    back: () => void;
    saving?: boolean;
    declined?: boolean;
    componentClient?: boolean;
    emailConfigured?: boolean;
    sent?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: '100%',
            overflowX: 'hidden'
        },
        containerHeader: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FAFAFA',
            padding: '24px 16px 16px',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '136px',
                paddingRight: '107px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '181px',
                paddingRight: '150px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '274px',
                paddingRight: '227px'
            }
        },
        containerBody: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: '#fff',
            [theme.breakpoints.down('lg')]: {
                padding: '50px 80px 50px 50px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '274px',
                paddingRight: '160px',
                paddingTop: '80px'
            }
        },
        backTitleContainer: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            marginBottom: 24,

            [theme.breakpoints.down('sm')]: {
                gap: 10,
                alignItems: 'center'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '36px'
            }
        },
        backContainer: {
            fontSize: 20,
            display: 'flex',
            cursor: 'pointer',

            [theme.breakpoints.up('md')]: {
                width: 32,
                height: 32,
                fontSize: 27,
                marginLeft: -106,
                position: 'absolute',
                bottom: 0
            },
            [theme.breakpoints.only('xl')]: {
                width: 36,
                height: 36,
                fontSize: 36,
                marginLeft: -160
            }
        },
        titleButtonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [theme.breakpoints.up('md')]: {
                marginTop: '56px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '75px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '114px'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: '26px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '36px'
            }
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        containers: {
            display: 'flex'
        },
        leftContainer: {
            [theme.breakpoints.down('lg')]: {
                width: '533px'
            },
            [theme.breakpoints.up('xl')]: {
                widht: '711px'
            }
        },
        rightContainer: {
            width: 'auto'
        },
        recommendationsContainer: {
            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: 40
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: 318
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: 385
            }
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'flex-end',
            [theme.breakpoints.down('lg')]: {
                marginTop: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '16px'
            }
        },
        boxButtons: {
            'display': 'flex',
            'flexDirection': 'row',
            'justifyContent': 'flex-end',
            'gap': '5px',

            [theme.breakpoints.down('sm')]: {
                marginTop: 32,
                padding: '0px 16px'
            },

            '& button': {
                [theme.breakpoints.down('sm')]: {
                    'flex': 1,
                    'height': 55,

                    '& span': {
                        fontSize: 16
                    }
                }
            }
        },
        buttonPDF: {
            'color': '#EAB464',
            'cursor': 'pointer',
            'fontWeight': 600,
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(10)}px`,
                paddingRight: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '10px 20px 10px 10px'
            },

            '&:hover': {
                color: '#C18733'
            }
        },
        buttonComplete: {
            fontSize: '14px',
            height: '40px'
        },

        skipButton: {
            fontSize: '14px',
            height: '40px',
            marginRight: '10px',

            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#000000 !important'
            }
        },

        gridContainer: {
            backgroundColor: '#FFF',
            [theme.breakpoints.only('md')]: {
                padding: '20px 0'
            },
            [theme.breakpoints.only('lg')]: {
                padding: '26px 0'
            },
            [theme.breakpoints.only('xl')]: {
                padding: '40px 0'
            }
        },
        gridItemContainer: {
            padding: '10px 0',
            [theme.breakpoints.only('md')]: {
                marginTop: '13px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '26px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '40px'
            }
        },

        titleSection: {
            color: '#A3A3A3',
            [theme.breakpoints.down('lg')]: {
                fontSize: '11px',
                marginBottom: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
                marginBottom: '16px'
            }
        },
        groomingMap: {
            width: '100%',

            [theme.breakpoints.down('sm')]: {
                marginTop: 32
            },
            [theme.breakpoints.up('md')]: {
                width: '80%'
            }
        },
        containerContent: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '8px',
                display: 'none !important'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '53px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '70px'
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: '115px'
            }
        },
        containerRow: {
            [theme.breakpoints.only('md')]: {
                marginTop: '13px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '26px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '40px'
            }
        },
        left: {
            [theme.breakpoints.up('md')]: {
                borderRight: '1px solid #E7E7E7',
                borderBottom: '1px solid #E7E7E7',
                paddingLeft: '46px !important',
                paddingRight: '46px !important'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '70px !important',
                paddingRight: '70px !important'
            }
        },
        right: {
            [theme.breakpoints.up('md')]: {
                borderBottom: '1px solid #E7E7E7',
                paddingLeft: '46px !important',
                paddingRight: '46px !important'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '70px !important',
                paddingRight: '70px !important'
            }
        },
        center: {
            borderBottom: '1px solid #E7E7E7'
        },
        optionsContainer: {
            height: 'auto',
            padding: '0px 16px',
            backgroundColor: '#ffffff',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '106px !important',
                paddingRight: '40px !important',
                borderBottom: '1px solid #E7E7E7'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '142px !important',
                paddingRight: '95px !important'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '215px !important',
                paddingRight: '140px !important'
            }
        },
        behaviourContainer: {
            height: 'auto',
            padding: '0px 16px',
            backgroundColor: '#ffffff',

            [theme.breakpoints.up('md')]: {
                paddingTop: '20px',
                paddingLeft: '134px',
                paddingRight: '84px',
                paddingBottom: '30px',
                borderBottom: '1px solid #E7E7E7'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '26px',
                paddingLeft: '178px',
                paddingRight: '112px',
                paddingBottom: '40px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '40px',
                paddingLeft: '270px',
                paddingRight: '170px',
                paddingBottom: '60px'
            }
        },
        contionContainer: {
            '&:not(:first-child)': {
                marginTop: 16
            },

            [theme.breakpoints.down('sm')]: {
                '&.first': {
                    marginTop: 16
                },
                '&:not(.last)': {
                    paddingBottom: 24,
                    borderBottom: 'solid 1px #D4D4D4'
                }
            },
            [theme.breakpoints.only('xl')]: {
                height: '280px',
                marginTop: '40px'
            }
        },
        smallContainer: {
            [theme.breakpoints.up('md')]: {
                marginTop: '110px !important'
            }
        },
        imageContainer: {
            height: '100%'
        },
        containerFindings: {
            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px'
            },
            [theme.breakpoints.down('md')]: {
                minHeight: '310px'
            },
            [theme.breakpoints.only('lg')]: {
                minHeight: '350px'
            },
            [theme.breakpoints.only('xl')]: {
                minHeight: '530px'
            }
        },
        finding: {
            [theme.breakpoints.up('md')]: {
                marginRight: '64px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '86px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '130px'
            }
        },
        remarksContainer: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '24px !important',
                paddingRight: '24px !important'
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '36px'
            }
        },
        nextDentalDateContainer: {
            [theme.breakpoints.up('md')]: {
                width: '460px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '540px'
            }
        },
        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const GroomingReportComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [groomingReport, setGroomingReport] = useState<GroomingReport>(
        props.groomingReport
    );

    const edited = useMemo(() => {
        return isEqual(props.groomingReport, groomingReport);
    }, [props.groomingReport, groomingReport]);

    useEffect(() => {
        setGroomingReport(props.groomingReport);
    }, [props.groomingReport]);

    const handleSaveReport = () => {
        const groomingReportAux = prepareSaveReport();
        props.onSave(groomingReportAux);
    };

    const handleSendReport = () => {
        const groomingReportAux = prepareSaveReport();
        props.onSend(groomingReportAux);
    };

    const prepareSaveReport = () => {
        const groomingReportAux = { ...groomingReport };
        const dateToday =
            groomingReport.nextVisit?.setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0);
        if (dateToday) groomingReportAux.nextVisit = null;
        if (props.declined) groomingReportAux.declined = true;
        return groomingReportAux;
    };

    const handleChangeVisit = (value: Date | null) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.nextVisit = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeRemark = (value: string) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.remarks = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeRecommendation = (
        recommendations: Array<Option<number>>
    ) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.recommendations = recommendations;
        setGroomingReport(groomingReportAux);
    };

    const handleImageChange = (
        images: Array<string>,
        imageFilesToUpload: Array<File | null>
    ) => {
        setGroomingReport(prev => ({ ...prev, images, imageFilesToUpload }));
    };

    const handleChangeSkins = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.skin = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeTeeth = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.teeth = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeCoat = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.coat = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeNails = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.nails = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeEars = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.ears = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeEyes = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.eyes = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeBehavior = (value: Array<Option<number>>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.behavior = value;
        setGroomingReport(groomingReportAux);
    };

    const handleChangeFindings = (value: Array<Finding>) => {
        const groomingReportAux = { ...groomingReport };
        groomingReportAux.findings = value;
        setGroomingReport(groomingReportAux);
    };

    const backTitleHeader = (
        <Box className={classes.backTitleContainer}>
            <Box className={classes.backContainer} onClick={() => props.back()}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        Grooming Report
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    const recommendationsGrid = (
        <Grid container>
            <Grid item xs={12} className={classes.recommendationsContainer}>
                <Box>
                    <Recommendations
                        recommendations={groomingReport.recommendations}
                        isReport={true}
                        section="grooming"
                        multipleSelection={true}
                        onChangeSelection={handleChangeRecommendation}
                        id="grooming_report_recommendations_option"
                    />
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <Box className={classes.container}>
            <PremiumFeatureAlert
                text={
                    <>
                        Grooming reports are exclusive to <b>Cuddles Premium</b>
                        . Upgrade now to unlock the full benefits of reports and
                        start sending them to your clients.
                    </>
                }
                isOpen={!hasReportsPremiumAccess}
                onCancel={() => props.back()}
            />
            <Box className={classes.containerHeader}>
                {backTitleHeader}
                <HeaderGroomingReport
                    appointment={props.groomingReport.appointment}
                    isReport={true}
                    declined={props.declined}
                    isGrooming={true}
                />
            </Box>
            <Grid container spacing={2} className={classes.optionsContainer}>
                <Grid item xs={12} md={4} className={classes.left}>
                    <Box className={clsx(classes.contionContainer, 'first')}>
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.skin
                            }
                            options={groomingReport.skin}
                            title={'Skin'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeSkins}
                        />
                    </Box>
                    <Box
                        className={clsx(
                            classes.contionContainer,
                            classes.desktop
                        )}
                    >
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.teeth
                            }
                            options={groomingReport.teeth}
                            title={'Teeth'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeTeeth}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} className={classes.left}>
                    <Box className={classes.contionContainer}>
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.coat
                            }
                            options={groomingReport.coat}
                            title={'Coat'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeCoat}
                        />
                    </Box>
                    <Box
                        className={clsx(
                            classes.contionContainer,
                            classes.desktop,
                            {
                                [classes.smallContainer]: props.componentClient
                            }
                        )}
                    >
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.nails
                            }
                            options={groomingReport.nails}
                            title={'Nails'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeNails}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} className={classes.right}>
                    <Box className={classes.contionContainer}>
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.ears
                            }
                            options={groomingReport.ears}
                            title={'Ears'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeEars}
                        />
                    </Box>

                    <Box
                        className={clsx(
                            classes.contionContainer,
                            classes.mobile
                        )}
                    >
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.teeth
                            }
                            options={groomingReport.teeth}
                            title={'Teeth'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeTeeth}
                        />
                    </Box>

                    <Box
                        className={clsx(
                            classes.contionContainer,
                            classes.mobile,
                            {
                                [classes.smallContainer]: props.componentClient
                            }
                        )}
                    >
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.nails
                            }
                            options={groomingReport.nails}
                            title={'Nails'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeNails}
                        />
                    </Box>

                    <Box
                        className={clsx(classes.contionContainer, 'last', {
                            [classes.smallContainer]: props.componentClient
                        })}
                    >
                        <OptionsComponent
                            masterOptions={
                                masterData.groomingReportOptions.eyes
                            }
                            options={groomingReport.eyes}
                            title={'Eyes'}
                            isReport={true}
                            multipleSelection={true}
                            onChangeSelection={handleChangeEyes}
                        />
                    </Box>
                </Grid>
            </Grid>
            <MobileDivider />
            <Grid container spacing={2} className={classes.behaviourContainer}>
                <Grid item xs={12} md={9}>
                    <OptionsComponent
                        masterOptions={
                            masterData.groomingReportOptions.behavior
                        }
                        options={groomingReport.behavior}
                        title={'Behavior'}
                        isReport={true}
                        multipleSelection={true}
                        onChangeSelection={handleChangeBehavior}
                    />
                </Grid>
            </Grid>
            <MobileDivider />
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={1} className={classes.containerContent}></Grid>
                <Grid item xs={12} md={10}>
                    <Grid container className={classes.containerFindings}>
                        <Grid item xs={12} md={5} className={classes.finding}>
                            <Box>
                                <FindingsComponent
                                    findings={groomingReport.findings}
                                    multipleSelection={true}
                                    onChangeSelection={handleChangeFindings}
                                    isReport={true}
                                />
                                {!isMobile && recommendationsGrid}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            className={classes.imageContainer}
                        >
                            {props.groomingReport.appointment.pet.type.id ===
                            'dogs' ? (
                                <Img
                                    src="/images/groomingReport/dogBodyChart.png"
                                    className={classes.groomingMap}
                                />
                            ) : (
                                <Img
                                    src="/images/groomingReport/catBodyChart.png"
                                    className={classes.groomingMap}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <MobileDivider />

                    {isMobile && recommendationsGrid}

                    <MobileDivider />

                    <Grid
                        container
                        spacing={2}
                        className={classes.containerRow}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                            className={classes.remarksContainer}
                        >
                            <Remarks
                                remark={groomingReport.remarks}
                                isReport={true}
                                onChange={handleChangeRemark}
                                componentClient={
                                    props.componentClient &&
                                    props.componentClient
                                }
                            />
                        </Grid>

                        <MobileDivider />

                        <Grid item xs={12} md={5}>
                            <BeforeAndAfter
                                onSingleImageChange={() => {}}
                                images={groomingReport.images}
                                onChangeImage={handleImageChange}
                                isGrooming={true}
                                componentClient={
                                    props.componentClient &&
                                    props.componentClient
                                }
                            />
                        </Grid>
                    </Grid>

                    <MobileDivider />
                    <Grid
                        container
                        className={classes.gridItemContainer}
                        alignItems="flex-end"
                    >
                        <Grid item xl={8} md={6} xs={12}>
                            <Box
                                className={
                                    groomingReport.id
                                        ? classes.nextDentalDateContainer
                                        : ''
                                }
                            >
                                <NextGroomingDate
                                    nextDentalDate={groomingReport.nextVisit}
                                    haveReport={false}
                                    section="Next Recommended Visit"
                                    isReport={true}
                                    isDental={false}
                                    onChange={handleChangeVisit}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Grid item xl={3} md={5} xs={12}>
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="flex-end"
                            >
                                <Grid item xs={12} md={12}>
                                    <Box className={classes.boxButtons}>
                                        <Button
                                            label="Save Report"
                                            className={classes.buttonComplete}
                                            onClick={handleSaveReport}
                                            loading={props.saving}
                                            variant={'secondary'}
                                            disabled={
                                                edited ||
                                                !hasReportsPremiumAccess
                                            }
                                            id="grooming_report_save_report_button"
                                        />
                                        {(props.emailConfigured ||
                                            props.sent) && (
                                            <Button
                                                label={
                                                    props.sent
                                                        ? 'Resend Report'
                                                        : 'Send Report'
                                                }
                                                className={classes.skipButton}
                                                onClick={handleSendReport}
                                                loading={props.saving}
                                                disabled={
                                                    edited ||
                                                    !hasReportsPremiumAccess
                                                }
                                                id="grooming_report_send_report_button"
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
        </Box>
    );
};

export default GroomingReportComponent;
