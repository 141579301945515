import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      width: "100%"
    },
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      fontWeight: 600,
      color: "#BEBEBE",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
      "&.MuiTableCell-root": {
        border: "none",
        paddingTop: "0px",
        [theme.breakpoints.down("md")]: {
          paddingBottom: "13px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingBottom: "16px",
        },
      }
    },
    productCell: {
      width: "25%"
    },
    qtyCell: {
      width: "15%"
    },
    cells: {
      width: "20%"
    }
  })
);

export const ProductsTableHeader: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.row}>
        <TableCell className={clsx(classes.cell, classes.productCell)}>Product</TableCell>
        <TableCell className={clsx(classes.cell, classes.qtyCell)}>Retail Price</TableCell>
        <TableCell className={clsx(classes.cell, classes.cells)}>Vendor</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ProductsTableHeader;
