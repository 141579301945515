import { Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import AlertLabel from "components/PetCard/UI/AlertLabel";
import Pet from "@spike/pet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import DentalsTable from "./DentalsTable";

interface DentalsProps {
  pet: Pet;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alerts: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
    },
    alertInfo: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        "& p": {
          fontSize: "14px",
          fontWeight: 400,
        }
      }
    }
  })
);

export const Dentals: FunctionComponent<DentalsProps> = (props) => {
  const classes = useStyles();

  const hasDentals = props.pet.medicalHistory.dentals.length > 0;

  return (
    <Box className={clsx(props.className)}>
      <Grid container>
        <Grid item xs={12}>

          {!hasDentals && 
            <Box className={classes.alerts}>
              <AlertLabel className={classes.alertInfo} label="No dentals added yet." type="info" />
            </Box>
          }
          
          <Box>{hasDentals && <DentalsTable dentals={props.pet.medicalHistory.dentals} />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dentals;
