// actions.ts

export const OPEN_BUSINESS_SETTINGS_SIDEBAR = 'OPEN_BUSINESS_SETTINGS_SIDEBAR';
export const CLOSE_BUSINESS_SETTINGS_SIDEBAR = 'CLOSE_BUSINESS_SETTINGS_SIDEBAR';
export const TOGGLE_BUSINESS_SETTINGS_SIDEBAR = 'TOGGLE_BUSINESS_SETTINGS_SIDEBAR';

export const openBusinessSettingsSidebar = () => {
    return { type: 'OPEN_BUSINESS_SETTINGS_SIDEBAR' };
};

export const closeBusinessSettingsSidebar = () => {
    return { type: 'CLOSE_BUSINESS_SETTINGS_SIDEBAR' };
};

export const toggleBusinessSettingsSidebar = () => {
    return { type: 'TOGGLE_BUSINESS_SETTINGS_SIDEBAR' };
};
