import { Option, PetType } from '@spike/model';
import { fixedPriceOption, PricingObject } from '../model';
import {
    SET_SELECTED_PRICING_OPTION,
    SET_PRICING_OPTIONS,
    SET_PRICING_OBJECT,
    SET_SELECTED_PET_TYPE,
    SET_PRICING,
    UPDATE_SELECTED_PRICING_OPTION,
    UPDATE_PRICING,
    SET_PRICING_VARIABLES,
    SET_PRICING_TAXES,
    CREATE_SERVICE_PRICING_OBJECT_SUCCESS,
    CREATE_SERVICE_PRICING_OBJECT_ERROR,
    RESET_PRICING_STATE,
    SET_PET_TYPES,
    SHOW_PRICING_ERRORS
} from './PricingActions';
import { FixedPriceOption, ServicePricing, VariablePriceOption, VariablePriceVariables } from 'model/Service';
import { MarketplaceTax } from '@spike/marketplace-model';
import { FieldErrorWithKey } from 'components/Service/model';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

export enum PricingStatus {
    CREATING_SERVICE_PRICING_OBJECT = 'CREATING_SERVICE_PRICING_OBJECT',
    CREATING_SERVICE_PRICING_OBJECT_SUCCESS = 'CREATING_SERVICE_PRICING_OBJECT_SUCCESS',
    CREATING_SERVICE_PRICING_OBJECT_ERROR = 'CREATING_SERVICE_PRICING_OBJECT_ERROR'
}

export interface PricingState {
    selectedPetType: PetType | null;
    petTypes: Array<PetType>;
    selectedPricingOption: Option<string>;
    pricingOptions: Array<Option<string>>;
    pricingObject: PricingObject;
    pricing: Array<VariablePriceOption | FixedPriceOption>;
    pricingVariables: VariablePriceVariables;
    servicePricingObject: ServicePricing;
    taxes: Array<MarketplaceTax>;
    errors: Array<FieldErrorWithKey & { petTypeId: string; priceUuid: string | null; type: 'inline' | 'alert' }>;
    showErrors: boolean;
    status: PricingStatus | null;
}

export const initialState: PricingState = {
    selectedPetType: null,
    petTypes: [],
    selectedPricingOption: fixedPriceOption,
    pricingOptions: [],
    pricingObject: {} as PricingObject,
    pricing: [],
    pricingVariables: {} as VariablePriceVariables,
    taxes: [],
    servicePricingObject: {} as ServicePricing,
    errors: [],
    showErrors: false,
    status: null
};

const MODULE_NAME = 'PricingReducer';

const pricingReducer = (state = initialState, action: any): PricingState => {
    switch (action.type) {
        case SET_PET_TYPES:
            return {
                ...state,
                petTypes: action.payload,
                selectedPetType: action.payload[0],
                errors: []
            };
        case SET_SELECTED_PET_TYPE:
            return {
                ...state,
                selectedPetType: action.payload
            };

        case SET_PRICING_VARIABLES:
            return {
                ...state,
                pricingVariables: action.payload
            };

        case SET_SELECTED_PRICING_OPTION:
            return {
                ...state,
                selectedPricingOption: action.payload
            };
        case UPDATE_SELECTED_PRICING_OPTION:
            const nextPricing = cloneDeep(state.pricingObject[action.payload.id]);
            return {
                ...state,
                pricingObject: { ...state.pricingObject, [state.selectedPricingOption.id]: cloneDeep(state.pricing) },
                selectedPricingOption: action.payload,
                pricing: nextPricing,
                showErrors: false,
                errors: []
            };

        case SET_PRICING_OPTIONS:
            return {
                ...state,
                pricingOptions: action.payload
            };
        case SET_PRICING_OBJECT:
            return {
                ...state,
                pricingObject: action.payload
            };

        case SET_PRICING:
            return {
                ...state,
                pricing: action.payload
            };

        case UPDATE_PRICING:
            return {
                ...state,
                pricing: action.payload
            };
        case SET_PRICING_TAXES:
            return {
                ...state,
                taxes: action.payload
            };

        case CREATE_SERVICE_PRICING_OBJECT_SUCCESS:
            return {
                ...state,
                servicePricingObject: action.payload,
                status: PricingStatus.CREATING_SERVICE_PRICING_OBJECT_SUCCESS,
                errors: []
            };

        case CREATE_SERVICE_PRICING_OBJECT_ERROR:
            return {
                ...state,
                errors: action.payload
            };

        case SHOW_PRICING_ERRORS:
            const petId = state.errors[0]?.petTypeId;
            return {
                ...state,
                showErrors: action.payload,
                selectedPetType: petId
                    ? { ...state.petTypes.find(petType => petType.id === petId)! }
                    : state.selectedPetType
            };

        case RESET_PRICING_STATE:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

export default pricingReducer;
