import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { OptionType } from './OptionType';
import { OrderedOption } from '@spike/model';
import clsx from 'clsx';

export interface Props {
    title: string;
    masterDataOptions: Array<OrderedOption<string>>;
    options: Array<OrderedOption<string>>;
    onChange: (options: Array<OrderedOption<string>>) => void;
    feature?: string[];
    requireTwoColumnsOnMobile?: boolean;
    mobileTextContainerStyle?: string;
    gap?: number;
    width?: number;
}

interface StyleProps {
    requireTwoColumnsOnMobile?: boolean;
    width?: number;
}

const useStyles = makeStyles<Theme, StyleProps>(theme =>
    createStyles({
        container: props => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            width: props.width,
            [theme.breakpoints.down('md')]: {
                'width': '100%',
                'gap': 0,
                '&:last-child': {
                    borderBottom: 'none'
                },
                ...(props.requireTwoColumnsOnMobile
                    ? {
                          'flexDirection': 'inherit',
                          'flexWrap': 'wrap',
                          '& > p': {
                              width: '100%'
                          },
                          '& > div': {
                              'width': '50%',
                              '& p': {
                                  width: '100%'
                              }
                          }
                      }
                    : {})
            }
        }),
        title: {
            color: '#000000',
            [theme.breakpoints.down('lg')]: {
                fontSize: '11px',
                marginBottom: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
                marginBottom: '24px'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '10px',
                fontSize: '15px',
                marginBottom: '15px',
                textTransform: 'uppercase',
                fontWeight: '600'
            }
        },
        mobileTextContainer: {
            [theme.breakpoints.down('md')]: {
                'display': 'flex',
                'flexDirection': 'inherit',
                'justifyContent': 'space-between',
                'flexWrap': 'wrap',
                'alignItems': 'center',
                '& > p': {
                    width: '46%',
                    maxWidth: '46%'
                },
                '& > div': {
                    width: '54%',
                    maxWidth: '54%'
                }
            }
        },
        optionsTwoColumnsInMobile: {
            [theme.breakpoints.down('md')]: {
                'flexWrap': 'wrap',
                'flexDirection': 'initial',
                '& > div': {
                    width: '50%'
                }
            }
        }
    })
);

export const Category: FunctionComponent<Props> = props => {
    const classes = useStyles({
        requireTwoColumnsOnMobile: props.requireTwoColumnsOnMobile,
        width: props.width
    });

    const selectionHandler = (optionId: string, selected: boolean) => {
        if (selected) {
            const currentOptionIds = props.options.map(e => e.element).map(option => option.id);
            props.onChange(
                props.masterDataOptions.filter(
                    option => currentOptionIds.includes(option.element.id) || option.element.id === optionId
                )
            );
        } else {
            if (props.feature?.includes(optionId)) {
                props.onChange(props.options);
            } else {
                props.onChange(props.options.filter(option => option.element.id !== optionId));
            }
        }
    };

    const changeDescriptionHandler = (optionId: string, description: string) => {
        props.onChange(
            props.options.map(option => {
                if (option.element.id === optionId) {
                    return {
                        ...option,
                        element: {
                            ...option.element,
                            description: description
                        }
                    };
                } else {
                    return option;
                }
            })
        );
    };

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>{props.title}</Typography>
            {props.masterDataOptions.map((masterDataOption, index) => {
                const option =
                    props.options.map(e => e.element).find(o => o.id === masterDataOption.element.id) ||
                    masterDataOption.element;
                const checked = props.options.map(e => e.element).some(o => o.id === option.id);
                return (
                    <OptionType
                        key={option.id}
                        index={index}
                        checked={checked}
                        optionName={option.name}
                        description={option.description}
                        disabled={props.feature?.includes(option.id)}
                        section="petType"
                        onTypeChange={checked => selectionHandler(option.id, checked)}
                        onChangeDescription={description => changeDescriptionHandler(option.id, description)}
                        customTextContainer={clsx([classes.mobileTextContainer, props.mobileTextContainerStyle])}
                    />
                );
            })}
        </Box>
    );
};

export default Category;
