import React, { FunctionComponent, MouseEvent, useEffect, useRef, useState } from 'react';
import { faEllipsisH, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles, makeStyles, Theme, ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export interface Props extends PropsWithChildren {
    id?: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerDropDown: {
            display: 'flex',
            position: 'relative',
            width: '30px',
            justifyContent: 'center'
        },
        dropdown: {
            top: 30,
            right: 0,
            width: 'max-content',
            borderRadius: 14,
            padding: '10px 0px',
            position: 'absolute',
            backgroundColor: '#fff',
            border: 'solid 1px #000',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column'
        },
        dropdownCloseToBottom: {
            top: 'auto',
            bottom: 30
        },
        dropdownItemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        icon: {
            cursor: 'pointer'
        }
    })
);

function checkIfCloseToBottom(element: HTMLElement, threshold = 100): boolean {
    if (element) {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        return rect.bottom >= viewportHeight - threshold;
    }

    return false;
}

export const ToolbarButton: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [show, setShow] = useState(false);
    const [isCloseToBottom, setIsCloseToBottom] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const clickToolbarButtonHandler = (event: MouseEvent): void => {
        setShow(prev => {
            return !prev;
        });
    };

    useEffect(() => {
        window.addEventListener(
            'scroll',
            () => {
                setIsCloseToBottom(checkIfCloseToBottom(dropdownRef.current!));
            },
            true
        );
    }, []);

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <div
                ref={dropdownRef}
                className={clsx(classes.containerDropDown, props.className)}
                onClick={clickToolbarButtonHandler}
            >
                <Box className={classes.icon} id={props.id && props.id.concat('_toolbar_button')}>
                    {show ? (
                        <FontAwesomeIcon color="#92B4A7" icon={faEllipsisV} />
                    ) : (
                        <FontAwesomeIcon color="#000000" icon={faEllipsisH} />
                    )}
                </Box>

                {show && (
                    <ClickAwayListener onClickAway={() => setShow(false)}>
                        <Box
                            className={clsx(classes.dropdown, {
                                [classes.dropdownCloseToBottom]: isCloseToBottom
                            })}
                            id={props.id && props.id.concat('_toolbar_list')}
                        >
                            {props.children}
                        </Box>
                    </ClickAwayListener>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default ToolbarButton;
