import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import clsx from 'clsx';
import Button from 'components/UI/Button';
import { FunctionComponent, useState } from 'react';

interface OptionsButtonProps {
	options: Array<Option<string>>;
	label?: string;
	color?: 'orange' | 'green' | 'black' | 'secondaryGreen';
	variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning';
	className?: string;
	onClick?: (option: Option<string>) => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			position: 'relative'
		},
		list: {
			top: 56,
			zIndex: 20,
			overflow: 'hidden',
			padding: '2px 0px',
			position: 'absolute',
			border: '1px solid black',
			backgroundColor: 'white',
			borderRadius: 10
		},
		listItem: {
			'cursor': 'pointer',
			'padding': '8px 16px',

			'&:hover': {
				backgroundColor: '#F5F5F5'
			}
		},
		listItemText: {
			color: '#000',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500
		}
	})
);

export const OptionsButton: FunctionComponent<OptionsButtonProps> = props => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	const list = (
		<ClickAwayListener onClickAway={() => setExpanded(false)}>
			<Box className={classes.list}>
				{props.options.map(option => (
					<Box
						key={option.id}
						className={classes.listItem}
						onClick={() => {
							setExpanded(false);
							props.onClick && props.onClick(option);
						}}
					>
						<Typography className={classes.listItemText}>
							{option.name}
						</Typography>
					</Box>
				))}
			</Box>
		</ClickAwayListener>
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Button
				{...props}
				onClick={() => setExpanded(prev => !prev)}
				endIcon={expanded ? faChevronUp : faChevronDown}
			/>
			{expanded && list}
		</Box>
	);
};

export default OptionsButton;
