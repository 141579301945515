import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface HeaderCloseProps {
  title?: string;
  className?: string;
  loading?: boolean;
  //
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      color: "#222",
    },
    closeContainer: {
      cursor: "pointer",
      padding: "auto",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "30px",
      },
    },
    closeIcon: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "30px",
      },
    },
    title: {
      fontWeight: 600,
      whiteSpace: "nowrap",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(26)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "26px",
      },
    },
  })
);

export const HeaderClose: FunctionComponent<HeaderCloseProps> = (props) => {
  const classes = useStyles();

  const clickCloseButtonHandler = () => {
    !props.loading && props.onClose && props.onClose();
  };

  const close = props.onClose && (
    <Box onClick={clickCloseButtonHandler}>
      <FontAwesomeIcon
        className={classes.closeIcon}
        icon={faTimes}
      />
    </Box>
  );

  const title = props.title && (
    <Box>
      <Typography className={classes.title}>{props.title}</Typography>
    </Box>
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box>
        {title}
      </Box>
      <Box className={classes.closeContainer}>
        {props.onClose && close}
      </Box>
    </Box>
  );
};

export default HeaderClose;
