import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';
import { wbp } from 'Theme';
import { Img } from 'react-image';
interface PaymentMethodProps {
	paymentMethodId: string;
	paymentMethodName: string;
	cardDigits: string | null;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center'
		},
		icon: {
			color: '#7A7A7A',
			[theme.breakpoints.down(wbp)]: {
				width: '21px !important',
				height: '21px !important',
				marginRight: '11px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '26px !important',
				height: '26px !important',
				marginRight: '14px'
			}
		}
	})
);

const visa = 'visa';
const mastercard = 'mc';
const amex = 'amex';
const cash = 'cash';
const path = 'images/creditCards/';

const icons: Map<string, string> = new Map([
	[cash, path.concat('icon-cash.svg')],
	[visa, path.concat('icon-visa.svg')],
	[mastercard, path.concat('icon-mastercard.svg')],
	[amex, path.concat('icon-amex.svg')]
]);

export const PaymentMethod: FunctionComponent<PaymentMethodProps> = props => {
	const classes = useStyles();
	const tableClasses = useTableStyles();
	const label =
		props.cardDigits === null ? props.paymentMethodName : props.cardDigits;

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Img
				src={
					icons.get(props.paymentMethodId) ||
					path.concat('icon-credit-card.svg')
				}
				className={classes.icon}
			/>
			{label}
		</Box>
	);
};

export default PaymentMethod;
