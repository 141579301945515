import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { InvoiceLine } from '@spike/invoice-model';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { FunctionComponent, useMemo } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from './CommonStyles';
import InvoicePet from './InvoicePet';
import InvoiceServiceLine from './InvoiceServiceLine';

interface InvoiceLinesGroupProps {
	lines: Array<InvoiceLine>;
	errors: Array<FieldError>;
	className?: string;
	saving: boolean;
	editedUuidLine: string | undefined;
	onSave?: (line: InvoiceLine) => void;
	onEdit?: (uuidLine: string) => void;
	onCancelEdit?: (uuidLine: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			borderRadius: 16,
			padding: '14px 16px',
			backgroundColor: '#FFFFFF',

			[theme.breakpoints.down('sm')]: {
				border: 'solid 1px #D4D4D4'
			},
			[theme.breakpoints.up('md')]: {
				borderRadius: 19,
				padding: '37px 0px 37px 0px',
				boxShadow: '0px 10px 20px 0px #0000000A'
			}
		},
		invoiceLineCol: {
			[theme.breakpoints.down('sm')]: {
				paddingTop: 10
			}
		}
	})
);

export const InvoiceLinesGroup: FunctionComponent<
	InvoiceLinesGroupProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const sortedLines = useMemo(
		() =>
			props.lines.sort((line, otherLine) =>
				(line.service?.name ?? '').localeCompare(
					otherLine?.service?.name ?? ''
				)
			),
		[props.lines]
	);
	const [firstLine] = sortedLines;
	const pet = firstLine?.pet;

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Grid container>
				<Grid item xs={12} md={3}>
					<InvoicePet
						pet={pet!}
						className={commonClasses.firstCell}
					/>
				</Grid>
				<Grid item container xs={12} md={9}>
					{sortedLines.map(line => (
						<InvoiceServiceLine
							key={line.uuid}
							line={line}
							saving={props.saving}
							errors={props.errors}
							editable={[line.uuid, undefined].includes(
								props.editedUuidLine
							)}
							onSave={props.onSave}
							onEdit={props.onEdit}
							onCancelEdit={props.onCancelEdit}
						/>
					))}
				</Grid>
			</Grid>
		</Box>
	);
};

export default InvoiceLinesGroup;
