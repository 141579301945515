import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BusinessHours, FieldError, Week } from '@spike/model';
import clsx from 'clsx';
import { HourField } from 'components/UI';
import _ from 'lodash';
import { WeekDay } from 'model';
import { FunctionComponent } from 'react';

interface DefaultBusinessHoursProps {
	day: WeekDay;
	businessHours: BusinessHours | null;
	marketplaceSchedule: Week;
	errors?: Array<FieldError>;
	className?: string;
	onChange?: (day: WeekDay, businessHours: BusinessHours) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '8px 0px'
		},
		dayContainer: {
			width: '40%'
		},
		day: {
			fontSize: 16,
			fontWeight: 500,

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		dayOff: {
			color: '#7A7A7A'
		},
		hourOff: {
			'borderColor': '#D3D3D3',
			'& input': {
				color: '#7A7A7A'
			},
			'& button': {
				backgroundColor: 'white'
			},
			'& button > span': {
				color: '#7A7A7A'
			}
		},
		error: {
			color: '#EA6464'
		},
		errorMessage: {
			fontSize: '10px'
		}
	})
);

export const DefaultBusinessHours: FunctionComponent<
	DefaultBusinessHoursProps
> = props => {
	const classes = useStyles();

	const marketplaceBusinessHours =
		props.marketplaceSchedule[props.day as keyof Week]!;

	const hasError =
		props.errors?.some(
			error => error.fieldName.toLowerCase() === props.day.toLowerCase()
		) || false;

	const focusHandler = (
		day: WeekDay,
		businessHours: BusinessHours | null,
		marketplaceBusinessHours: BusinessHours
	) => {
		const newDay =
			businessHours === null
				? { ...marketplaceBusinessHours }
				: { ...businessHours! };
		props.onChange && props.onChange(props.day, newDay);
	};

	const changeFromHandler = (
		open24Hs: string,
		day: WeekDay,
		businessHours: BusinessHours | null,
		marketplaceBusinessHours: BusinessHours
	) => {
		const newDay =
			businessHours === null
				? { ...marketplaceBusinessHours }
				: { ...businessHours! };
		newDay.open = open24Hs;
		props.onChange && props.onChange(props.day, newDay);
	};

	const changeToHandler = (
		open24Hs: string,
		day: WeekDay,
		businessHours: BusinessHours | null,
		marketplaceBusinessHours: BusinessHours
	) => {
		const newDay =
			businessHours === null
				? { ...marketplaceBusinessHours }
				: { ...businessHours! };
		newDay.close = open24Hs;
		props.onChange && props.onChange(props.day, newDay);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.dayContainer}>
				<Typography
					className={clsx(
						classes.day,
						{ [classes.dayOff]: props.businessHours === null },
						{ [classes.error]: hasError }
					)}
				>
					{_.capitalize(props.day)}
				</Typography>
				{hasError && (
					<Typography
						className={clsx(classes.errorMessage, classes.error)}
					>
						{
							props.errors?.find(
								error =>
									error.fieldName.toLowerCase() ===
									props.day.toLowerCase()
							)?.errorMessage
						}
					</Typography>
				)}
			</Box>
			<Box>
				<HourField
					className={clsx({
						[classes.hourOff]:
							props.businessHours === null ||
							props.businessHours === undefined
					})}
					value24Hs={
						props.businessHours?.open
							? props.businessHours?.open
							: marketplaceBusinessHours?.open
					}
					onChange={value24Hs =>
						changeFromHandler(
							value24Hs,
							props.day,
							props.businessHours,
							marketplaceBusinessHours
						)
					}
					onFocus={() =>
						focusHandler(
							props.day,
							props.businessHours,
							marketplaceBusinessHours
						)
					}
					error={hasError}
				/>
			</Box>
			<Box className={clsx({ [classes.error]: hasError })}> &nbsp;to&nbsp; </Box>
			<Box>
				<HourField
					className={clsx({
						[classes.hourOff]:
							props.businessHours === null ||
							props.businessHours === undefined
					})}
					value24Hs={
						props.businessHours?.close
							? props.businessHours?.close
							: marketplaceBusinessHours?.close
					}
					onChange={value24Hs =>
						changeToHandler(
							value24Hs,
							props.day,
							props.businessHours,
							marketplaceBusinessHours
						)
					}
					onFocus={() =>
						focusHandler(
							props.day,
							props.businessHours,
							marketplaceBusinessHours
						)
					}
					error={hasError}
				/>
			</Box>
		</Box>
	);
};

export default DefaultBusinessHours;
