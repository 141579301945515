import React, { FunctionComponent, useRef, useState } from 'react';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMasterData } from 'hooks';

interface ImageSelectorProps {
	title: JSX.Element;
	image?: string;
	onSelect: (file: File) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column'
		},
		imageContainer: {
			height: 175,
			width: '100%',
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			borderRadius: 20,
			overflow: 'hidden',

			[theme.breakpoints.down('sm')]: {
				border: '2px dashed #000000'
			},
			[theme.breakpoints.up('md')]: {
				width: '450px',
				height: '107px',
				backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23222222FF' stroke-width='1' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
			},
			[theme.breakpoints.only('lg')]: {
				width: '525px',
				height: '142px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '750px',
				height: '215px'
			}
		},
		uploadContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%',
			[theme.breakpoints.down('lg')]: {
				maxHeight: '100%'
			},
			[theme.breakpoints.up('xl')]: {
				maxHeight: '100%'
			}
		},
		titleContainer: {
			'fontSize': 14,
			'lineHeight': 1,
			'fontWeight': 600,

			[theme.breakpoints.up('xl')]: {
				fontSize: 20,
				fontWeight: 400
			},

			'& strong': {
				fontWeight: 600
			}
		},
		iconContainer: {
			marginTop: 12,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',

			[theme.breakpoints.up('md')]: {
				marginTop: '22px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '34px'
			}
		},
		uploadTextContainer: {},
		uploadIcon: {
			fontSize: 30,
			marginBottom: 6,
			color: '#5E8677',

			[theme.breakpoints.up('md')]: {
				fontSize: 40,
				marginBottom: 16
			}
		},
		uploadText: {
			fontSize: 12,
			lineHeight: 1,
			fontWeight: 500,
			color: '#5E8677',

			[theme.breakpoints.up('md')]: {
				fontSize: 14
			}
		},
		image: {
			backgroundImage: (props: ImageSelectorProps) =>
				'url(' + props.image + ')',
			backgroundPosition: 'center',
			backgroundSize: '100% 100%',
			backgroundRepeat: 'no-repeat',
			width: '100%',
			height: '100%'
		},
		maxSize: {
			fontSize: 14,
			lineHeight: 1,
			color: '#969696',

			[theme.breakpoints.only('md')]: {
				fontSize: '6px',
				lineHeight: '13px',
				marginLeft: '15px',
				marginRight: '10px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '8px',
				lineHeight: '18px',
				marginLeft: '20px',
				marginRight: '13px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '12px',
				lineHeight: '27px',
				marginLeft: '30px',
				marginRight: '20px'
			}
		},
		warningSize: {
			color: 'red',
			[theme.breakpoints.down('sm')]: {
				fontSize: '4px',
				lineHeight: '8px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '6px',
				lineHeight: '13px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '8px',
				lineHeight: '18px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '12px',
				lineHeight: '27px'
			}
		},
		infoContainer: {
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				marginTop: 12
			}
		}
	})
);

export const ImageSelector: FunctionComponent<ImageSelectorProps> = props => {
	const classes = useStyles(props);

	const masterData = useMasterData();

	const [showWarning, setShowWarning] = useState(false);

	const imageInput = useRef<HTMLInputElement>(null);

	const handleImageClick = () => {
		imageInput.current?.click();
	};

	const selectImageHandler = (event: any) => {
		const file = event.target.files[0];

		if (file.size / 1024 <= masterData.imagesMaxSizes.dentalReportKb) {
			props.onSelect(file);
		} else {
			setShowWarning(true);
		}
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<input
				style={{ display: 'none' }}
				type={'file'}
				ref={imageInput}
				onChange={event => selectImageHandler(event)}
				accept=".png, .jpg, .jpeg"
				value={''}
			/>
			<Box className={clsx(classes.imageContainer, props.className)}>
				{props.image ? (
					<Box className={classes.image} onClick={handleImageClick} />
				) : (
					<Box
						className={classes.uploadContainer}
						onClick={() => handleImageClick()}
					>
						<Box className={classes.titleContainer}>
							{props.title}
						</Box>
						<Box className={classes.iconContainer}>
							<FontAwesomeIcon
								icon={faFileUpload}
								className={classes.uploadIcon}
							/>
						</Box>
						<Box className={classes.uploadTextContainer}>
							<Typography className={classes.uploadText}>
								Upload
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
			<Box className={classes.infoContainer}>
				<Typography
					className={classes.maxSize}
				>{`Max.${masterData.imagesMaxSizes.dentalReportKb}KB`}</Typography>
				{showWarning && (
					<Typography className={classes.warningSize}>
						Maximum size exceeded
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default ImageSelector;
