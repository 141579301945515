import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { PropsWithChildren } from 'react';

interface SingleChipProps extends PropsWithChildren {
	color?: string;
	backgroundColor?: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: (props: SingleChipProps) => ({
			height: 24,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: props.backgroundColor,
			borderRadius: '27px',

			[theme.breakpoints.up('md')]: {
				height: 27,
				padding: '0px 11px'
			}
		}),
		text: (props: SingleChipProps) => ({
			fontSize: 13,
			fontWeight: 500,
			color: props.color,

			[theme.breakpoints.up('md')]: {
				fontSize: 15
			}
		})
	})
);

export const SingleChip: FunctionComponent<SingleChipProps> = props => {
	const classes = useStyles(props);

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Typography className={classes.text}>{props.children}</Typography>
		</Box>
	);
};

export default SingleChip;
