import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import { Product, Vendor } from "@spike/product-model";
import ProductVendor from "model/ProductVendor";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import EditableSelect from "../EditableSelect";

interface Props {
  product: Product;
  vendor?: Vendor;
  default?: boolean;
  onChange?: (vendor: Vendor) => void;
  onAdd: () => void;
  error?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    title: {
      fontWeight: 400,
      fontSize: "0.875rem",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "18px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "22px",
      },
    },
    description: {
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    nameContainer: {
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: {
      display: "flex",
    },
    icon: {
      fontWeight: 600,
      color: "#EAB464",
      padding: "0px",
    },
    error: {
      color: "#EF4F57",
    },
  })
);

export const ProductVendorComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const productVendorIds = props.product.vendors.map((vendorCost) => vendorCost.vendor?.id);

  const vendors = useSelector<RootState, Array<ProductVendor>>((state) => state.productVendors.list).filter((vendor) =>
    !productVendorIds.includes(vendor.id)
  );

  const sortedVendors = vendors
    .sort((vendor, otherVendor) => vendor.name.localeCompare(otherVendor.name))
    .map((vendor) => ({ id: vendor.id!, name: vendor.name }));

  const selectVendorHandler = (option: Option<number>) => {
    const vendor = vendors.find((vendor) => vendor.id === option.id);
    vendor &&
      props.onChange &&
      props.onChange({
        id: vendor.id!,
        uuid: vendor.uuid,
        contact: vendor.contact,
        deleted: vendor.deleted,
        email: vendor.email,
        name: vendor.name,
        phone: vendor.phone,
        website: vendor.website,
      });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.nameContainer}>
        {props.error ? (
          <Box className={classes.titleContainer}>
            <Typography className={clsx(classes.title, classes.error)}>Vendor is required</Typography>
            <Typography className={clsx(classes.title, classes.icon)}>*</Typography>
          </Box>
        ) : (
          <Box className={classes.titleContainer}>
            <Typography className={classes.title}>Vendor</Typography>
          </Box>
        )}
        <EditableSelect
          label="Vendor"
          options={sortedVendors}
          selected={props.vendor ? { id: props.vendor.id!, name: props.vendor.name || "" } : undefined}
          onChange={selectVendorHandler}
          onAdd={props.onAdd}
          error={props.error}
          default={props.default}
          type="vendors"
        />
      </Box>
    </Box>
  );
};

export default ProductVendorComponent;
