import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Header } from "components/UI";

interface Props {
  title?: string;
  searchPlaceholder?: string;
  buttonLabel?: string;
  onClickButton?: () => void;
  onSearch?: (text: string) => void;
  onBack?: () => void;
  className?: string;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

export const HeaderButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const clickButtonHandler = () => {
    props.onClickButton && props.onClickButton();
  };

  const button: JSX.Element | undefined = (props.buttonLabel ? (
    <Box className={classes.buttonContainer}>
      <Button
        label={props.buttonLabel}
        onClick={clickButtonHandler}
        loading={props.loading}
        disabled={props.loading}
      />
    </Box>
  ) : undefined);

  return (
    <Header {...props} rightElements={button}/>
  );
};

export default HeaderButton;
