import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent } from "react";

interface ActionProps {
    className?: string;
    icon: IconProp;
    label: string;
    onClick: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer"
        },
        iconContainer: {
            textAlign: "right",
            width: "15%"

        },
        icon: {
            fontSize: "14px",
            fontWeight: 600,
            color: "#000"
        },
        labelContainer: {
            width: "85%",
        },
        label: {
            fontSize: "14px",
            fontWeight: 600,
            color: "#000",
 
        },
        labelDecoration: {
            borderBottom: "3px #000 solid"

        }

    }));

export const ActionText: FunctionComponent<ActionProps> = props => {
    const classes = useStyles();
    return (
        <Box className={clsx(props.className, classes.container)} onClick={props.onClick}>
            <Box className={classes.iconContainer}> <FontAwesomeIcon className={classes.icon} icon={props.icon}></FontAwesomeIcon></Box>
            <Box className={classes.labelContainer}>  <Typography className={classes.label}><span className={classes.labelDecoration}>{props.label}</span></Typography></Box>
        </Box>
    )
}

export default ActionText;