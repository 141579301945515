import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { wbp, reduceResolution } from 'Theme';

interface Props {
    stepNumber: number;
    label: string;
    firstLinkLabel: string;
    firstURL: string | object;
    onClickFirstLink: () => void;
    secondLinkLabel: string;
    secondURL: string;
    onClickSecondLink: () => void;
    checked?: boolean;
    buttonId?: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepLabel: {
            fontWeight: 500,
            color: '#7A7A7A',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            }
        },
        label: {
            fontWeight: 600,
            color: (props: Props) => (props.checked ? '#7A7A7A' : '#222222'),
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18.5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18.5px'
            }
        },
        pipe: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(8)}px`,
                marginRight: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '8px',
                marginRight: '8px'
            }
        }
    })
);

const BusinessStep: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    return (
        <>
            <Typography className={classes.stepLabel}>
                STEP {props.stepNumber}:{' '}
            </Typography>
            <Typography className={classes.label}>{props.label}</Typography>
        </>
    );
};

export default BusinessStep;
