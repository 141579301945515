import React, { FunctionComponent } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Drawer,
} from "@material-ui/core";
import { wbp, reduceResolution } from "Theme";

interface Props {
  children?: JSX.Element;
  open: boolean;
  close: () => void;
}

const drawerWidth = 482; //482

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(drawerWidth)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: `${drawerWidth}px`
      },

    },
  })
);

export const OnboardingDrawer: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={props.close}
      elevation={0}
      BackdropProps={{ invisible: false }}
    >
      <Box className={classes.content}>{props.children}</Box>
    </Drawer>
  );
};

export default OnboardingDrawer;
