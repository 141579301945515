import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell, useTheme } from "@material-ui/core";
import clsx from "clsx";
import TableHeaderItem from "./TableHeaderItem";
import { SortFields, SortOrder } from "@spike/booking-model";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface Props {
  onSort: (field: SortFields, order: SortOrder) => void;
  viewClient?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      [theme.breakpoints.down(768)]: {
        display: 'none'
      }
    },
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      borderBottom: "1px solid #222222",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "9px",
        fontSize: "11px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "14px",
        fontSize: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "19x",
        fontSize: "14px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "29px",
        fontSize: "16px",
      },
    },
    firstCell: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "27px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "43px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "57px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "87px",
      },
    },
    petCell: {
      paddingLeft: "13px",
    }
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up(1025));

  const [sortField, setSortField] = useState<SortFields | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);

  const sortHandler = (field: SortFields, order: SortOrder) => {
    setSortField(field);
    setSortOrder(order);
    props.onSort(field, order);
  };

  const tableBooking = (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.firstCell)}>
        <TableHeaderItem
          label={useMediaQuery(theme.breakpoints.down(1025)) ? 'Client' : 'Client Name'}
          sortField={SortFields.CLIENT_NAME}
          sortOrder={sortField === SortFields.CLIENT_NAME ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label={useMediaQuery(theme.breakpoints.down(1025)) ? 'Pet' : 'Pet Name'}
          sortField={SortFields.PET_NAME}
          sortOrder={sortField === SortFields.PET_NAME ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      { isDesktop ?
        <>
        <TableCell className={classes.cell}>
          <TableHeaderItem
            label="Breed"
            sortField={SortFields.PET_BREED}
            sortOrder={sortField === SortFields.PET_BREED ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        </>
      : null }
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Type"
          sortField={SortFields.PET_TYPE}
          sortOrder={sortField === SortFields.PET_TYPE ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Service"
          sortField={SortFields.SERVICE}
          sortOrder={sortField === SortFields.SERVICE ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Staff"
          sortField={SortFields.STAFF}
          sortOrder={sortField === SortFields.STAFF ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Date & Time"
          sortField={SortFields.DATE_TIME}
          sortOrder={sortField === SortFields.DATE_TIME ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      {isDesktop ?
        <>
          <TableCell className={classes.cell}>
            <TableHeaderItem
              label="Total"
              sortField={SortFields.TOTAL}
              sortOrder={sortField === SortFields.TOTAL ? sortOrder : undefined}
              onSort={sortHandler}
            />
          </TableCell>
        </>
        : null}
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Status"
          sortField={SortFields.STATUS}
          sortOrder={sortField === SortFields.STATUS ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      {isDesktop ?
        <>
          <TableCell className={classes.cell}></TableCell>
        </>
        : null}
    </TableRow>

  )

  const tableClientBooking = (
    <TableRow>
      <TableCell className={clsx(classes.cell, classes.firstCell)}>
        <TableHeaderItem
          label="Service"
          sortField={SortFields.SERVICE}
          sortOrder={sortField === SortFields.SERVICE ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.petCell)}>
        <TableHeaderItem
          label="Pet"
          sortField={SortFields.PET_NAME}
          sortOrder={sortField === SortFields.PET_NAME ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Date & Time"
          sortField={SortFields.DATE_TIME}
          sortOrder={sortField === SortFields.DATE_TIME ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <TableHeaderItem
          label="Staff"
          sortField={SortFields.STAFF}
          sortOrder={sortField === SortFields.STAFF ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Price"
          sortField={SortFields.TOTAL}
          sortOrder={sortField === SortFields.TOTAL ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TableHeaderItem
          label="Status"
          sortField={SortFields.STATUS}
          sortOrder={sortField === SortFields.STATUS ? sortOrder : undefined}
          onSort={sortHandler}
        />
      </TableCell>
      {isDesktop ?
        <>
          <TableCell className={classes.cell}></TableCell>
        </>
        : null}
    </TableRow>
  )

  return (
    <TableHead>
      {!props.viewClient && tableBooking}
      {props.viewClient && tableClientBooking}
    </TableHead>

  );
};

export default TableHeader;
