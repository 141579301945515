import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMasterData } from 'hooks';
import { SelectableOption } from 'model';
import { Option, PetType } from '@spike/model';
import { DentalReportFinding } from 'model/DentalReport';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import ToothInput from './ToothInput';
import clsx from 'clsx';

interface Props {
	petType: PetType;
	periodontalFindings: Array<DentalReportFinding>;
	isDentalReport: boolean;
	multipleSelection?: boolean;
	onChangeSelection: (
		periodontalFindings: Array<DentalReportFinding>
	) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		problemsContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		problemsContainerDisabled: {
			'display': 'flex',
			'flexDirection': 'column',
			'pointerEvents': 'none',

			'& .MuiInputBase-input': {
				display: 'none !important'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		addCondition: {
			'color': '#92B4A7',
			'marginTop': 12,

			'&:hover': {
				cursor: 'pointer'
			},

			'& p': {
				fontSize: 14,
				lineHeight: 1,
				fontWeight: 500,

				[theme.breakpoints.up('xl')]: {
					fontSize: '16px',
					marginBottom: '42px'
				}
			},

			[theme.breakpoints.up('md')]: {
				fontSize: 12,
				fontWeight: 400,
				marginBottom: '34px',
				color: '#5E8677'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				marginBottom: '42px'
			}
		},
		icon: {
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '40px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '53px'
			}
		},
		condContainer: {
			top: 30,
			zIndex: 100,
			width: '100%',
			height: 'auto',
			background: '#fff',
			padding: '24px 16px',
			position: 'absolute',
			borderRadius: '20px',
			border: '1px solid #000',

			[theme.breakpoints.up('md')]: {
				padding: 25,
				width: '500px'
			}
		},
		conditionsContainer: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		conditionsEditablesContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				marginLeft: 6
			}
		},
		conditionEditableContainer: {
			'width': '100%',
			'display': 'flex',
			'flexWrap': 'wrap',

			'&:not(:last-child)': {
				marginBottom: 16
			},

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				marginBottom: 24
			}
		},
		conditionContainer: {
			'height': 40,
			'padding': '12px 20px',
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#F0F4F2',
			'borderRadius': 30,

			'&:hover': {
				cursor: 'pointer'
			},

			[theme.breakpoints.up('xl')]: {
				height: '45px'
			}
		},
		conditionSelectedContainer: {
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff'
			}
		},
		label: {
			fontSize: 16,
			lineHeight: 1,
			display: 'flex',
			cursor: 'pointer',

			[theme.breakpoints.up('md')]: {
				fontSize: 12
			}
		},
		arrowContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		conditionsEditablesLabel: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px',
				width: '225px',
				paddingTop: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px',
				width: '300px',
				paddingTop: '16px'
			}
		},
		inputFinding: {
			'minHeight': '53px',
			'height': 'auto',
			'border': '1px solid #EAEAEA',
			'& .MuiOutlinedInput-inputAdornedStart': {
				alignSelf: 'flex-end',
				[theme.breakpoints.down('lg')]: {
					marginBottom: '12px'
				},
				[theme.breakpoints.up('xl')]: {
					marginBottom: '16px'
				}
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				padding: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				padding: '12px'
			}
		},
		finding: {
			cursor: 'pointer',
			[theme.breakpoints.down('lg')]: {
				marginRight: '6px',
				width: '65px',
				marginBottom: '6px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '8px',
				width: '70px',
				marginBottom: '8px'
			}
		},
		teethsContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			width: '700%'
		}
	})
);

export const PeriodontalFindings: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();
	const [showConditions, setShowConditions] = useState(false);
	const [showConditionsEditables, setShowConditionsEditables] =
		useState(true);
	const [addConditions, setShowAddConditions] = useState(true);

	const [periodontalFindings, setPeriodontalFindings] = useState<
		Array<SelectableOption<DentalReportFinding>>
	>([]);

	const handleSelectPeriodontalFindings = (
		value: DentalReportFinding,
		selected: boolean
	) => {
		const newPeriodontalFindings = [...periodontalFindings];

		newPeriodontalFindings.forEach(periodontalFin => {
			periodontalFin.selected =
				periodontalFin.element.id.finding === value.finding
					? selected
					: props.multipleSelection
					? periodontalFin.selected
					: false;
		});

		setPeriodontalFindings(newPeriodontalFindings);
		props.onChangeSelection(
			newPeriodontalFindings
				.filter(periodontalFin => periodontalFin.selected)
				.map(periodontalFin => periodontalFin.element.id)
		);
	};

	const handleAddConditions = () => {
		setShowConditions(true);
		setShowConditionsEditables(false);
		setShowAddConditions(false);
	};

	const handleClickNext = () => {
		setShowConditions(false);
		setShowConditionsEditables(true);
		setShowAddConditions(true);
	};

	const searchTeeth = (periodontalFind: Option<number>) => {
		const periodontalFindingsAux = props.periodontalFindings.find(
			periodontalF => periodontalF.finding.id === periodontalFind.id
		)!;
		if (isEmpty(periodontalFindingsAux)) {
			return [];
		} else {
			return periodontalFindingsAux.teeth;
		}
	};

	useEffect(() => {
		const periodontalFindingsSelectable =
			masterData.dentalReportOptions.periodontalFindings.map(
				periodontalFindings => {
					const pFdnAux = props.periodontalFindings.find(
						p => p.finding.name === periodontalFindings.name
					)!;
					return {
						element: {
							id: {
								finding: periodontalFindings,
								allTeeth: pFdnAux?.allTeeth,
								teeth: searchTeeth(periodontalFindings)
							},
							name: ''
						},
						selected: props.periodontalFindings.find(
							periodontalF =>
								periodontalF.finding.id ===
								periodontalFindings.id
						)
							? true
							: false
					};
				}
			);
		setPeriodontalFindings(periodontalFindingsSelectable);
	}, [props.periodontalFindings]);

	const handleChangeTeeth = (
		teeth: Array<number>,
		allTeeth: boolean,
		finding: DentalReportFinding
	) => {
		const newPeriodontalFindings = [...periodontalFindings];

		newPeriodontalFindings.forEach(perFindings => {
			perFindings.element.id.finding.id === finding.finding.id
				? (finding = {
						finding: finding.finding,
						teeth,
						allTeeth
				  })
				: // eslint-disable-next-line no-self-assign
				  (finding = finding);
		});

		setPeriodontalFindings(newPeriodontalFindings);

		props.onChangeSelection(
			newPeriodontalFindings
				.filter(perFindings => perFindings.selected)
				.map(perfin =>
					perfin.element.id.finding.id === finding.finding.id
						? {
								finding: perfin.element.id.finding,
								teeth,
								allTeeth
						  }
						: perfin.element.id
				)
		);
	};

	return (
		<Box className={classes.container}>
			{(props.isDentalReport ||
				(!isEmpty(props.periodontalFindings) &&
					!props.isDentalReport)) && (
				<Typography className={classes.titleSection}>
					Periodontal Findings
				</Typography>
			)}
			<Box className={classes.problemsContainer}>
				{props.isDentalReport && showConditions && (
					<Box className={classes.condContainer}>
						<Box className={classes.conditionsContainer}>
							{periodontalFindings.map(
								(periodontalFinding, index) => {
									return (
										<Box
											key={index}
											className={clsx(
												classes.conditionContainer,
												{
													[classes.conditionSelectedContainer]:
														periodontalFinding.selected
												}
											)}
										>
											<Typography
												className={classes.label}
												onClick={() =>
													handleSelectPeriodontalFindings(
														periodontalFinding
															.element.id,
														!periodontalFinding.selected
													)
												}
											>
												{
													periodontalFinding.element
														.id.finding.name
												}
											</Typography>
										</Box>
									);
								}
							)}
						</Box>
						<Box className={classes.arrowContainer}>
							<FontAwesomeIcon
								icon={faArrowCircleRight}
								className={classes.icon}
								onClick={handleClickNext}
							/>
						</Box>
					</Box>
				)}
				{showConditionsEditables && (
					<Box
						className={
							props.isDentalReport
								? classes.problemsContainer
								: classes.problemsContainerDisabled
						}
					>
						<Box className={classes.conditionsEditablesContainer}>
							{periodontalFindings.map(
								(periodontalFinding, index) => {
									if (periodontalFinding.selected) {
										return (
											<ToothInput
												key={index}
												label={
													periodontalFinding.element
														.id.finding.name
												}
												allTeeth={
													periodontalFinding.element
														.id.allTeeth
														? periodontalFinding
																.element.id
																.allTeeth
														: false
												}
												isDentalReport={
													props.isDentalReport
												}
												teeth={
													periodontalFinding.element
														.id.teeth
												}
												petType={props.petType}
												onChangeTeeth={(
													teeth,
													allTeeth
												) =>
													handleChangeTeeth(
														teeth,
														allTeeth,
														periodontalFinding
															.element.id
													)
												}
											/>
										);
									} else {
										return <></>;
									}
								}
							)}
						</Box>
					</Box>
				)}
				{props.isDentalReport && addConditions && (
					<Box className={classes.addCondition}>
						<Typography
							onClick={handleAddConditions}
							style={{ fontWeight: 600 }}
						>
							+ Add Condition
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default PeriodontalFindings;
