import { NowIndicatorContentArg } from '@fullcalendar/core';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useTimeZone } from 'hooks';
import moment from 'moment-timezone';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		nowIndicatorContainer: {
			display: 'flex',
			alignItems: 'center',
			marginTop: '-2px',
		},
		nowIndicatorHour: {
			backgroundColor: 'black',
			color: 'white',
			fontFamily: 'Poppins',
			fontWeight: 500,
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				fontSize: '8px',
				lineHeight: '12px',
				paddingLeft: '2px',
				paddingRight: '2px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '9px',
				lineHeight: '14px',
				paddingLeft: '3px',
				paddingRight: '3px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '11px',
				lineHeight: '16px',
				paddingLeft: '4px',
				paddingRight: '4px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '14px',
				lineHeight: '20px',
				paddingLeft: '5px',
				paddingRight: '5px'
			}
		},
		nowIndicatorArrow: {
			borderStyle: 'solid',
			borderColor:
				'rgba(255,255,255,0) rgba(255,255,255,0) rgba(255,255,255,0) black',
			[theme.breakpoints.down('sm')]: {
				borderWidth: '6px 0px 6px 6px',
			},
			[theme.breakpoints.only('md')]: {
				borderWidth: '7px 0px 7px 7px',
			},
			[theme.breakpoints.only('lg')]: {
				borderWidth: '8px 0px 8px 8px',
			},
			[theme.breakpoints.only('xl')]: {
				borderWidth: '10px 0px 10px 10px',
			}
		}
	})
);

export const NowIndicatorFullCalendar: FunctionComponent<
	NowIndicatorContentArg
> = props => {
	const classes = useStyles();
	const timeZone = useTimeZone();

	return props.isAxis ? (
		<Box className={classes.nowIndicatorContainer}>
			<Box className={classes.nowIndicatorHour}>
				{moment(props.date).tz(timeZone).format('h:mm')}
			</Box>
			<Box className={classes.nowIndicatorArrow}></Box>
		</Box>
	) : <></>;
};

export default NowIndicatorFullCalendar;
