import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentsBankAccount as BankAccountModel } from '@spike/payments-model';
import { FunctionComponent } from 'react';
import { useCommonStyles } from '../CommonStyles';

interface BankAccountViewProps {
	bankAccount: BankAccountModel;
	className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			display: 'block',

			[theme.breakpoints.up('md')]: {
				marginRight: 8,
				fontWeight: 500,
				display: 'inline-block'
			}
		}
	})
);

export const BankAccountView: FunctionComponent<
	BankAccountViewProps
> = props => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();

	return (
		<Grid container spacing={2} className={props.className}>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Owner Name:</span>{' '}
				{props.bankAccount.ownerName}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Country:</span>{' '}
				{props.bankAccount.country?.name}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Account Type:</span>{' '}
				{props.bankAccount.accountType?.name}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Currency:</span>{' '}
				{props.bankAccount.currency?.name}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Account Number:</span>
				{props.bankAccount.accountNumber}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Routing Number:</span>
				{props.bankAccount.routingNumber}
			</Grid>
			<Grid item xs={12} className={commonClasses.formCell}>
				<span className={classes.label}>Description:</span>
				{props.bankAccount.description}
			</Grid>
		</Grid>
	);
};

export default BankAccountView;
