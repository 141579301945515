import React, { FunctionComponent } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";
import { faImage, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { wbp, reduceResolution } from "Theme";

export interface ImageMenuProps {
  onClose?: () => void;
  onChange?: () => void;
  onRemove?: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      position: "absolute",
    },
    dropdown: {
      display: "flex",
      width: "auto",
      left: 0,
      zIndex: 10,
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: "1px",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(10)}px`,
        borderRadius: `${reduceResolution(15)}px`,
        padding: `${reduceResolution(12)}px ${reduceResolution(12)}px ${reduceResolution(12)}px ${reduceResolution(12)}px`,
        height: `${reduceResolution(110)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "10px",
        borderRadius: "15px",
        padding: "12px 12px 12px 12px",
        height: "110px",
      },
    },
    dropdownItemsContainer: {
      display: "flex",
      flexDirection: "column",
    },
    icon: {
      cursor: "pointer"
    },
    optionChange: {
      "&:hover": {
        color: "#EAB464"
      }
    },
    optionRemove: {
      "&:hover": {
        color: "#EAB464"
      }
    },
  })
);

const ImageMenu: FunctionComponent<ImageMenuProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)} onClick={() => props.onClose && props.onClose()}>
      <Box className={classes.dropdown}>
        <Box className={classes.dropdownItemsContainer}>
          <Box className={classes.optionChange}>
            <ToolbarItem
              icon={faImage}
              text={"Change"}
              onClick={() => props.onChange && props.onChange()}
            />
          </Box>
          <Box className={classes.optionRemove}>
            <ToolbarItem
              icon={faTrashAlt}
              text={"Remove"}
              onClick={() => props.onRemove && props.onRemove()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageMenu;
