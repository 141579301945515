import React from 'react';
import Status from './Status';
import { EnrollmentStatusItem as EnrollmentStatusItemModel } from '@spike/payments-model';
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Option } from '@spike/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEye,
	faPencil,
	IconDefinition
} from '@fortawesome/pro-solid-svg-icons';

type SetupRowMobileProps = {
	title: string;
	item?: null | EnrollmentStatusItemModel;
	status?: Option<string>;
	className?: string;
	editIcon?: IconDefinition;
	onEdit?: () => void;
	onView?: () => void;
};

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'width': '100%',
			'display': 'grid',
			'gridTemplateColumns': '1fr minmax(min-content, auto)',
			'padding': '20px 20px 16px',
			'backgroundColor': '#ffffff',

			'&:not(:last-child)': {
				borderBottom: 'solid 1px #D4D4D4'
			}
		},
		content: {
			gap: 7,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start'
		},
		title: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600
		},
		date: {
			fontSize: 14,
			lineHeight: 1
		},
		status: {
			marginTop: 4,
			padding: '0px 11px',
			display: 'inline-flex'
		},

		actions: {},
		button: {
			border: 0,
			width: 38,
			height: 38,
			color: '#000000',
			borderRadius: '50%',
			backgroundColor: '#F1F1F1'
		}
	})
);

export const SetupRowMobile: React.FunctionComponent<
	SetupRowMobileProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.content}>
				<Typography className={classes.title}>{props.title}</Typography>
				{props.item && (
					<Typography className={classes.date}>
						{`Updated ${props.item.updatedAt?.format(
							'MM/DD/YYYY'
						)}`}
					</Typography>
				)}
				<Status
					status={props.item?.verificationStatus || props.status}
					className={classes.status}
				/>
			</Box>
			<Box className={classes.actions}>
				{props.onEdit &&
					(
						props.status || props.item?.verificationStatus
					)?.id?.toLowerCase() !== 'passed' && (
						<button
							type="button"
							aria-label="Edit"
							className={classes.button}
						>
							<FontAwesomeIcon
								icon={props.editIcon || faPencil}
								onClick={props.onEdit}
							/>
						</button>
					)}

				{props.onView &&
					(
						props.status || props.item?.verificationStatus
					)?.id?.toLowerCase() === 'passed' && (
						<button
							type="button"
							aria-label="View details"
							className={classes.button}
						>
							<FontAwesomeIcon
								icon={props.editIcon || faEye}
								onClick={props.onView}
							/>
						</button>
					)}
			</Box>
		</Box>
	);
};
