import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	ClickAwayListener,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CalculatingSpinner } from 'components/UI';
import { FunctionComponent, useState } from 'react';

interface SummaryCardProps {
	title: string;
	value: string | number;
	info?: string;
	color?: 'light' | 'dark' | 'white';
	infoAlignment?: 'center' | 'right';
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: (props: SummaryCardProps) => ({
			height: 102,
			width: '100%',
			display: 'flex',
			borderRadius: 14,
			backgroundColor:
				props.color === 'dark'
					? '#92B4A7'
					: props.color === 'white'
					? '#FFFFFF'
					: '#F1F1F1',

			[theme.breakpoints.up('md')]: {
				height: 84
			},
			[theme.breakpoints.up('lg')]: {
				height: 105
			}
		}),
		left: (props: SummaryCardProps) => ({
			flex: 1,
			padding: 19,
			height: '100%',
			display: 'flex',
			paddingRight: '0px !important',
			flexDirection: 'column',
			justifyContent: 'space-between',
			width: props.info ? '80%' : '100%',

			[theme.breakpoints.up('md')]: {
				padding: 14
			},
			[theme.breakpoints.up('lg')]: {
				padding: 20
			}
		}),
		right: {
			height: '100%',
			display: 'flex',
			position: 'relative',
			flexDirection: 'column',
			alignItems: 'flex-end',
			padding: '19px 19px 0px 19px',

			[theme.breakpoints.up('md')]: {
				padding: '8px 10px 0px 10px'
			},
			[theme.breakpoints.up('lg')]: {
				padding: '11px 13px 0px 13px'
			}
		},
		title: (props: SummaryCardProps) => ({
			fontSize: 18,
			fontWeight: 500,
			lineHeight: 1.1,
			color: props.color === 'dark' ? 'white' : '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 12
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: 15
			}
		}),
		value: (props: SummaryCardProps) => ({
			fontSize: 28,
			lineHeight: 1,
			fontWeight: 600,
			color: props.color === 'dark' ? 'white' : '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: 22
			}
		}),
		infoIcon: (props: SummaryCardProps) => ({
			'fontSize': 16,
			'cursor': 'pointer',
			'color': props.color === 'dark' ? 'white' : '#5E8677',

			'&:hover': {
				[theme.breakpoints.up('md')]: {
					'& + div': {
						display: 'flex'
					}
				}
			}
		}),
		infoWrapper: (props: SummaryCardProps) => ({
			zIndex: 10,
			width: 192,
			bottom: '110%',
			display: 'flex',
			position: 'absolute',
			pointerEvents: 'none',
			left: props.infoAlignment !== 'right' ? -80 : undefined,
			right: props.infoAlignment === 'right' ? '0px' : undefined,

			[theme.breakpoints.down('sm')]: {
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 9999,
				width: 'auto',
				position: 'fixed',
				backgroundColor: 'rgba(0,0,0,0.4)'
			},
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}),
		infoContainer: {
			width: '100%',
			marginTop: 'auto',
			pointerEvents: 'all',
			backgroundColor: '#fff',
			padding: '0px 24px 42px',
			borderRadius: '25px 25px 0px 0px',

			[theme.breakpoints.up('md')]: {
				padding: 15,
				borderRadius: 8,
				border: '1px solid black'
			}
		},
		infoChip: {
			width: 56,
			height: 6,
			display: 'block',
			borderRadius: 100,
			margin: '15px auto 24px',
			backgroundColor: '#D4D4D4',

			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		info: {
			color: '#000',
			fontSize: 14,
			lineHeight: 1.2,
			fontWeight: 400
		}
	})
);

export const SummaryCard: FunctionComponent<SummaryCardProps> = props => {
	const classes = useStyles(props);

	const [showInfo, setShowInfo] = useState(false);

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const info = (
		<ClickAwayListener onClickAway={() => setShowInfo(false)}>
			<Box className={classes.infoWrapper}>
				<Box className={classes.infoContainer}>
					<Box className={classes.infoChip} />

					<Typography className={classes.info}>
						{props.info}
					</Typography>
				</Box>
			</Box>
		</ClickAwayListener>
	);

	return (
		<Box className={clsx(classes.card, props.className)}>
			<Box className={classes.left}>
				<Typography className={classes.title}>{props.title}</Typography>
				{props.loading ? (
					<CalculatingSpinner />
				) : (
					<Typography className={classes.value}>
						{props.value}
					</Typography>
				)}
			</Box>
			{props.info && (
				<Box className={classes.right}>
					<FontAwesomeIcon
						icon={faInfoCircle}
						className={classes.infoIcon}
						onClick={() => setShowInfo(true)}
					/>
					{isMobile ? showInfo && info : info}
				</Box>
			)}
		</Box>
	);
};

export default SummaryCard;
