import { Reducer } from "redux";
import { ProductCategoriesState, ProductCategoriesStatus } from "./ProductCategoriesState";
import {
  PRODUCT_CATEGORIES_RESET_ACTION_TYPE,
  PRODUCT_CATEGORIES_FETCH_START_ACTION_TYPE,
  PRODUCT_CATEGORIES_FETCH_SUCCESS_ACTION_TYPE,
  PRODUCT_CATEGORIES_GET_START_ACTION_TYPE,
  PRODUCT_CATEGORIES_GET_SUCCESS_ACTION_TYPE,
  PRODUCT_CATEGORIES_SAVE_START_ACTION_TYPE,
  PRODUCT_CATEGORIES_SAVE_SUCCESS_ACTION_TYPE,
  PRODUCT_CATEGORIES_SAVE_PRODUCTS_START_ACTION_TYPE,
  PRODUCT_CATEGORIES_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE,
  PRODUCT_CATEGORIES_DELETE_PRODUCTS_START_ACTION_TYPE,
  PRODUCT_CATEGORIES_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE,
  PRODUCT_CATEGORIES_DELETE_START_ACTION_TYPE,
  PRODUCT_CATEGORIES_DELETE_SUCCESS_ACTION_TYPE,
  PRODUCT_CATEGORIES_ERROR_ACTION_TYPE,
  ProductCategoriesAction,
} from "actions/productCategories/ProductCategoriesActionsTypes";

const initialState: ProductCategoriesState = {
  list: [],
  savedCategory: undefined,
  category: undefined,
  status: ProductCategoriesStatus.Initial,
  loading: false,
};

export const productCategoriesReducer: Reducer<ProductCategoriesState, ProductCategoriesAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORIES_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    case PRODUCT_CATEGORIES_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.Fetching,
        loading: true,
      };
    case PRODUCT_CATEGORIES_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: [...action.payload.categories],
        status: ProductCategoriesStatus.FetchSuccess,
        loading: false,
      };
    case PRODUCT_CATEGORIES_GET_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.Getting,
        loading: true,
        category: undefined,
      };
    case PRODUCT_CATEGORIES_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.map(c => c.uuid === action.payload.category.uuid ? action.payload.category : c),
        status: ProductCategoriesStatus.GetSuccess,
        category: { ...action.payload.category },
        loading: false,
      };
    case PRODUCT_CATEGORIES_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.Saving,
        loading: true,
        savedCategory: undefined
      };
    case PRODUCT_CATEGORIES_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.some(c => c.uuid === action.payload.category.uuid)
        ? state.list.map(c => c.uuid === action.payload.category.uuid ? action.payload.category : c)
        : [...state.list, action.payload.category],
        status: ProductCategoriesStatus.SaveSuccess,
        loading: false,
        savedCategory: { ...action.payload.category },
        category: (state.category?.uuid === action.payload.category.uuid ? { ...action.payload.category } : state.category)
      };
    case PRODUCT_CATEGORIES_SAVE_PRODUCTS_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.SavingProducts,
        loading: true,
        savedCategory: undefined
      };

    case PRODUCT_CATEGORIES_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.some(c => c.uuid === action.payload.category.uuid)
        ? state.list.map(c => c.uuid === action.payload.category.uuid ? action.payload.category : c)
        : [...state.list, action.payload.category],
        status: ProductCategoriesStatus.SaveProductsSuccess,
        loading: false,
        savedCategory: { ...action.payload.category },
        category: (state.category?.uuid === action.payload.category.uuid ? { ...action.payload.category } : state.category)
      };
    case PRODUCT_CATEGORIES_DELETE_PRODUCTS_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.DeletingProducts,
        loading: true,
        savedCategory: undefined
      };

    case PRODUCT_CATEGORIES_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.some(c => c.uuid === action.payload.category.uuid)
        ? state.list.map(c => c.uuid === action.payload.category.uuid ? action.payload.category : c)
        : [...state.list, action.payload.category],
        status: ProductCategoriesStatus.DeleteProductsSuccess,
        loading: false,
        savedCategory: { ...action.payload.category },
        category: (state.category?.uuid === action.payload.category.uuid ? { ...action.payload.category } : state.category)
      };
    case PRODUCT_CATEGORIES_DELETE_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.Deleting,
        loading: true,
        savedCategory: undefined
      };


    case PRODUCT_CATEGORIES_DELETE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.filter(productCategory => productCategory.uuid !== action.payload.productCategoryUuid),
        status: ProductCategoriesStatus.DeleteSuccess,
        loading: false,
        savedCategory: (state.savedCategory?.uuid === action.payload.productCategoryUuid ? undefined : state.savedCategory),
        category: (state.category?.uuid === action.payload.productCategoryUuid ? undefined : state.category)
      };

    case PRODUCT_CATEGORIES_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: ProductCategoriesStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default productCategoriesReducer;
