import {
    FunctionComponent,
    RefObject,
    useEffect,
    useRef,
    useState
} from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from 'store';
import { createEmptySmsMessage, SmsMessage } from 'model/TwoWaySms';
import {
    clearTwoWaySmsMessagesThunk,
    fetchTwoWaySmsCountThunk,
    fetchTwoWaySmsMessagesThunk,
    saveMessageThunk,
    searchTwoWaySmsThunk
} from 'actions/twoWaySms/twoWaySmsActions';
import useNonInitialEffect from '@versiondos/hooks';
import { TwoWaySmsStatus } from 'reducers/twowaysms/TwoWaySmsState';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useApiClientWrapper } from 'hooks';
import { ClientsStatus, getClientThunk } from '@spike/clients-action';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import Client from '@spike/client-model';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import AES from 'crypto-js/aes';

export interface ChatProps {
    clientSelected: number;
    className?: string;
    onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
        },
        chatDisplayContainerNoHeader: {
            width: '100%',
            height: '70%'
        },
        chatDisplayContainer: {
            width: '100%',
            height: '62%',
            maxWidth: '100%'
        },
        chatInputContainer: {
            height: '30%',
            width: '100%',
            zIndex: 289,
            borderTop: '#D4D4D4 1px solid'
        }
    })
);
const initMessages = 4;

const secret = `${process.env.REACT_APP_GLOBAL_SECRET}`;

const Chat: FunctionComponent<ChatProps> = props => {
    const apiClientWrapper = useApiClientWrapper();
    const classes = useStyles();
    const dispatch = useDispatch();

    const marketplaceId = useSelector<RootState, number>(
        state => state.marketplace.marketplace.id!
    );
    const clientData = useSelector<RootState, Client>(
        state => state.clients.client!
    );

    const clientsStatus = useSelector<RootState, ClientsStatus>(
        state => state.clients.status
    );

    const twoWayStatus = useSelector<RootState, TwoWaySmsStatus>(
        state => state.twoWaySms.status
    );
    const messages = useSelector<RootState, Array<SmsMessage>>(
        state => state.twoWaySms.conversation ?? []
    );
    const timeZone = useSelector<RootState, string>(
        state => state.login.auth.timeZone!
    );

    const [clientBeingSelected, setClientBeingSelected] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [showMessages, setShowMessages] = useState(initMessages);
    const [messageToSent, setMessageToSent] = useState<SmsMessage | undefined>(
        undefined
    );

    const [loading, setLoading] = useState(false);

    const chatScrollRef = useRef<OverlayScrollbarsComponent>(null);

    const [showNewBooking, setShowNewBooking] = useState<boolean>(false);

    const isClientSelected = props.clientSelected > 0;

    useEffect(() => {
        setMessagesAsRead(messages);
        dispatch(clearTwoWaySmsMessagesThunk());
        if (clientData && clientData.id) {
            dispatch(fetchTwoWaySmsMessagesThunk(clientData.id));
        }
        return () => {
            dispatch(clearTwoWaySmsMessagesThunk());
        };
    }, []);

    useEffect(() => {
        if (clientData && clientData.id) {
            setMessageToSent(createEmptySmsMessage(clientData.id));
        }
    }, [clientData]);

    useEffect(() => {
        if (showNewBooking)
            amplitude.track(AMPLITUDE.CTA_BOOK_APPOINTMENT_CLIENT_PROFILE);
    }, [showNewBooking]);

    useNonInitialEffect(() => {
        if (twoWayStatus === TwoWaySmsStatus.SaveSuccess) {
            setMessageText('');
            if (clientData && clientData.id) {
                dispatch(fetchTwoWaySmsMessagesThunk(clientData.id));
                dispatch(fetchTwoWaySmsCountThunk());
                dispatch(searchTwoWaySmsThunk('', 1, 7, 'all', 'newest'));
            }
        }

        if (twoWayStatus === TwoWaySmsStatus.Fetching) {
            setLoading(true);
        }

        if (twoWayStatus === TwoWaySmsStatus.FetchSuccess) {
            scrollToBottomHandler(chatScrollRef);
            setClientBeingSelected(false);
            setLoading(false);
        }
    }, [twoWayStatus]);

    useNonInitialEffect(() => {
        scrollToBottomHandler(chatScrollRef);
    }, [loading]);

    useNonInitialEffect(() => {
        setMessageText('');
        setShowMessages(initMessages);
        if (clientData && clientData.id) {
            dispatch(fetchTwoWaySmsMessagesThunk(clientData.id));
        }
    }, [clientData]);

    useEffect(() => {
        if (props.clientSelected) {
            dispatch(getClientThunk(apiClientWrapper, props.clientSelected));
            setClientBeingSelected(true);
        }
    }, [props.clientSelected]);

    useNonInitialEffect(() => {
        if (clientData && clientData.id) {
            setMessagesAsRead(messages);
        }
    }, [messages]);

    const setMessagesAsRead = (messages: Array<SmsMessage>) => {
        const unReadMessages = messages.filter(
            message => !message.readAt && message.senderType === 'Customer'
        );
        if (unReadMessages && unReadMessages.length > 0) {
            for (const message of unReadMessages) {
                message.readAt = new Date();
                dispatch(saveMessageThunk(message));
            }
            dispatch(fetchTwoWaySmsCountThunk());
            if (clientData.id) {
                dispatch(fetchTwoWaySmsMessagesThunk(clientData.id));
            }
        }
    };

    const sendMessageHandler = () => {
        if (messageToSent) {
            const staffId = store.getState().login.auth.user?.staffId;
            const messageToSentOther = { ...messageToSent };
            if (marketplaceId && marketplaceId !== 0) {
                messageToSentOther.marketplaceId = marketplaceId;
            }
            if (staffId) {
                messageToSentOther.senderId = staffId;
            }
            if (clientData.id) {
                messageToSentOther.clientId = clientData.id;
                messageToSentOther.receiverId = clientData.id;
            }
            messageToSentOther.sentAt = new Date();
            setMessageToSent(messageToSentOther);
            dispatch(saveMessageThunk(messageToSentOther));
        }
    };

    const changeMessageHandler = (text: string) => {
        if (messageToSent) {
            const messageToSentOther = { ...messageToSent };
            messageToSentOther.message = text;
            setMessageToSent(messageToSentOther);
            setMessageText(text);
        }
    };

    const scrollToBottomHandler = (
        ref: RefObject<OverlayScrollbarsComponent>
    ) => {
        if (ref.current) {
            const vp = ref.current.osInstance()?.getElements().viewport;
            if (vp) {
                const scrollHeight = vp.scrollHeight;
                const height = vp.clientHeight;
                const maxScrollTop = scrollHeight - height;
                vp.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            }
        }
    };

    const scrollHandler = () => {
        if (chatScrollRef.current) {
            const vp = chatScrollRef.current
                .osInstance()
                ?.getElements().viewport;
            if (vp) {
                if (vp.scrollTop === 0) {
                    const newN = showMessages + 1;
                    setShowMessages(newN);
                    vp.scrollTop = vp.scrollTop + 20;
                }
            }
        }
        return null;
    };

    const clientCardClickHandler = (clientId: number) => {
        if (clientId) {
            const hash = encodeURIComponent(
                AES.encrypt(
                    JSON.stringify({ clientId, petId: undefined }),
                    secret
                ).toString()
            );
            window.open(`/clients/${hash}`, '_blank');
        }
    };

    const bookNowClickHandler = () => {
        if (clientData) {
            setShowNewBooking(true);
        }
    };

    const handleBackNewBooking = () => {
        setShowNewBooking(false);
    };

    const isLoadingMessages =
        clientsStatus === ClientsStatus.Getting ||
        twoWayStatus === TwoWaySmsStatus.Fetching ||
        twoWayStatus === TwoWaySmsStatus.Saving ||
        twoWayStatus === TwoWaySmsStatus.Getting;

    const newBookingView = (
        <CreateBookingDrawer
            parentID={clientData && clientData.id}
            parentName={`${clientData && clientData.firstName} ${
                clientData && clientData.lastName
            }`}
            fromClient={true}
            onBooked={handleBackNewBooking}
            onClose={handleBackNewBooking}
            showTabs={false}
        />
    );

    return (
        <Box className={classes.root}>
            {isClientSelected && (
                <ChatHeader
                    clientData={clientData}
                    onClientCardClick={clientCardClickHandler}
                    onBookNowClick={bookNowClickHandler}
                />
            )}
            <Box
                className={
                    isClientSelected
                        ? classes.chatDisplayContainer
                        : classes.chatDisplayContainerNoHeader
                }
            >
                {isClientSelected && (
                    <ChatMessages
                        clientData={clientData}
                        loading={isLoadingMessages}
                        messages={messages.slice(-showMessages)}
                        timeZone={timeZone}
                        chatScrollRef={chatScrollRef}
                        onScroll={scrollHandler}
                    />
                )}
            </Box>
            <Box className={classes.chatInputContainer}>
                <ChatInput
                    isActive={isClientSelected}
                    messageText={messageText}
                    onMessageChange={changeMessageHandler}
                    onSendMessage={sendMessageHandler}
                />
            </Box>
            {showNewBooking && newBookingView}
        </Box>
    );
};

export default Chat;
