import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
interface Props {
	label: string;
	selected: boolean;
	onClick: () => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: 32,
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			cursor: 'pointer',
			display: 'inline-flex',
			alignItems: 'center',
			paddingLeft: 11,
			paddingRight: 11,
			borderRadius: 30,

			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#F1F1F1'
			},

			[theme.breakpoints.up('md')]: {
				height: 34,
				fontSize: 18,
				fontWeight: 400,
				paddingLeft: 25,
				paddingRight: 25
			}
		},
		selectedContainer: {
			color: '#fff',
			fontWeight: 500,
			background: '#92B4A7'
		}
	})
);

export const SelectionBarItem: React.FunctionComponent<Props> = props => {
	const classes = useStyles();

	const containerStyle = clsx(
		{
			[classes.container]: true,
			[classes.selectedContainer]: props.selected
		},
		props.className
	);

	return (
		<Box className={containerStyle} onClick={props.onClick}>
			{props.label}
		</Box>
	);
};

export default SelectionBarItem;
