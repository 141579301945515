import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Fragment, FunctionComponent } from 'react';
import { ItemsRow } from './ItemsRow';
import { useCommonStyles } from 'components/UI/CommonStyles';
import { InvoiceLine } from '@spike/invoice-model';
import { FieldError } from '@spike/model';

interface ItemsTableProps {
	invoiceLines: Array<InvoiceLine>;
	calculating?: boolean;
	errors?: Array<FieldError>;
	className?: string;
	onChange?: (invoiceLines: Array<InvoiceLine>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			background: '#fff'
		},
		gridHeader: {
			borderBottom: '1px solid #D4D4D4',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			},
			[theme.breakpoints.up('md')]: {
				paddingBottom: '15px',
				marginBottom: '15px'
			}
		},
		tableList: {
			[theme.breakpoints.down('sm')]: {
				gap: 24,
				display: 'flex',
				flexWrap: 'wrap'
			},
			[theme.breakpoints.up('md')]: {
				paddingBottom: 24
			}
		},
		gridCenter: {
			display: 'flex',
			justifyContent: 'center'
		},
		row: {
			[theme.breakpoints.down('sm')]: {
				'&:last-child': {
					marginBottom: 24
				}
			},
			[theme.breakpoints.up('md')]: {
				marginTop: 24
			}
		}
	})
);

export const ItemsTable: FunctionComponent<ItemsTableProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const changeHandler = (updatedLine: InvoiceLine) => {
		props.onChange &&
			props.onChange(
				props.invoiceLines.map(line =>
					line.uuid === updatedLine.uuid ? { ...updatedLine } : line
				)
			);
	};

	const removeHandler = (uuid: string) => {
		props.onChange &&
			props.onChange(
				props.invoiceLines.filter(line => line.uuid !== uuid)
			);
	};

	const headersItems = (
		<Grid container className={classes.gridHeader}>
			<Grid item xs={3} className={commonClasses.typo15_600}>
				Item
			</Grid>
			<Grid
				item
				xs={1}
				className={clsx(commonClasses.typo15_600, classes.gridCenter)}
			>
				Type
			</Grid>
			<Grid
				item
				xs={1}
				className={clsx(commonClasses.typo15_600, classes.gridCenter)}
			>
				Quantity
			</Grid>
			<Grid
				item
				xs={2}
				className={clsx(commonClasses.typo15_600, classes.gridCenter)}
			>
				Discount
			</Grid>
			<Grid
				item
				xs={2}
				className={clsx(commonClasses.typo15_600, classes.gridCenter)}
			>
				Tax
			</Grid>
			<Grid
				item
				xs={2}
				className={clsx(commonClasses.typo15_600, classes.gridCenter)}
			>
				Total
			</Grid>
			<Grid item xs={1}></Grid>
		</Grid>
	);

	const rowsItems = (
		<Box className={classes.tableList}>
			{props.invoiceLines?.map(invoiceLine => {
				return (
					<ItemsRow
						key={invoiceLine.uuid}
						invoiceLine={invoiceLine}
						calculating={props.calculating}
						invoiceAddOnServiceIds={props.invoiceLines
							.filter(line => line.addOnServiceId)
							.map(line => line.addOnServiceId!)}
						invoiceProductIds={props.invoiceLines
							.filter(line => line.productId)
							.map(line => line.productId!)}
						errors={props.errors}
						onChange={changeHandler}
						onRemove={removeHandler}
						className={classes.row}
					/>
				);
			})}
		</Box>
	);

	return (
		<Fragment>
			<Box className={clsx(classes.container, props.className)}>
				{headersItems}
				{rowsItems}
			</Box>
		</Fragment>
	);
};

export default ItemsTable;
