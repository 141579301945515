import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { SortFields, SortOrder } from "model/Service";
import React, { FunctionComponent, useState } from "react";
import TableHeaderItem from "./TableHeaderItem";
import clsx from "clsx";

interface Props {
  onSort: (field: SortFields, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      borderBottom: "1px solid #F1F1F1",
      [theme.breakpoints.down("lg")]: {
        paddingBottom: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingBottom: "20px",
      },
    },
    rowContainer: {
      display: "flex",
      width: "100%",
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
    },
    leftSpace: {
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "32px",
      },
    },
    rightSpace: {
      [theme.breakpoints.down("lg")]: {
        marginLeft: "-15px",
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: "-20px",
      },
    },
    headerInactive: {
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "75px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "105px",
      },
    },
    leftSpaceServices: {
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "45px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "60px",
      },
    },
    hideOnMobile:{
      [theme.breakpoints.down("md")]: {
        display: "none",
      }
    }
  })
);


export const ServicesHeaderList: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [sortField, setSortField] = useState<SortFields | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);

  const sortHandler = (field: SortFields, order: SortOrder) => {
    setSortField(field);
    setSortOrder(order);
    props.onSort(field, order);
  };

  return (
    <Grid container className={clsx(classes.container, classes.hideOnMobile)}>
      <Grid container className={classes.rowContainer}>
        <Box className={classes.headerInactive}></Box>
        <Grid item xs={2}>
          <TableHeaderItem
            label="Service Name"
            sortField={SortFields.NAME}
            sortOrder={sortField === SortFields.NAME ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </Grid>
        <Grid className={classes.hideOnMobile} item xs={2}>
          Staff
        </Grid>
        <Grid className={classes.hideOnMobile} item xs={2}>
          Duration
        </Grid>
        <Grid className={classes.hideOnMobile} item xs={3}>
          Price
        </Grid>
        <Grid className={classes.hideOnMobile} item xs={1}>
          Taxes
        </Grid>
      </Grid>
    </Grid>
  );
};
