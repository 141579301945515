import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { faImage, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";

export interface Props {
  show: boolean;
  color?: string;
  onChange?: () => void;
  onRemove?: () => void;
  onShow?: () => void;
  className?: string;
  dropdownClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerDropDown: {
      display: "flex",
      position: "relative",
      width: "30px",
      justifyContent: "center",
    },
    dropdown: {
      display: "flex",
      position: "absolute",
      right: "0px",
      zIndex: 10,
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: "1px",
      [theme.breakpoints.only("xs")]: {
        borderRadius: "8px",
      },
      [theme.breakpoints.only("sm")]: {
        borderRadius: "8px",
        width: "90px"
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "9px",
        width: "90px",
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "10px",
        width: "90px",
      },
      [theme.breakpoints.only("xl")]: {
        borderRadius: "15px",
        width: "130px",
      },
    },
    dropdownItemsContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    icon: {
      cursor: "pointer"
    },
    optionContainer: {
      display: "flex",
      justifyContent: "center",
    },
    optionChange: {
      "&:hover": {
        backgroundColor: "#E6E6E6",
      },
      [theme.breakpoints.only("xs")]: {
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
      },
      [theme.breakpoints.only("sm")]: {
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
      },
      [theme.breakpoints.only("md")]: {
        borderTopRightRadius: "9px",
        borderTopLeftRadius: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        borderTopRightRadius: "15px",
        borderTopLeftRadius: "15px",
      },
    },
    optionRemove: {
      "&:hover": {
        backgroundColor: "#E6E6E6"
      },
      [theme.breakpoints.only("xs")]: {
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      [theme.breakpoints.only("sm")]: {
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      [theme.breakpoints.only("md")]: {
        borderBottomRightRadius: "9px",
        borderBottomLeftRadius: "9px",
      },
      [theme.breakpoints.only("lg")]: {
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        borderBottomRightRadius: "15px",
        borderBottomLeftRadius: "15px",
      },
    },
  })
);

const EditImageOptions: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setShow(props.show);
  }, [props.show])

  const clickToolbarButtonHandler = (): void => {
    props.onShow && props.onShow();
  };

  return (
    <Box className={clsx(classes.containerDropDown, props.className, props.dropdownClassName)} onClick={clickToolbarButtonHandler}>
      {show && (
        <Box className={clsx(classes.dropdown)}>
          <Box className={classes.dropdownItemsContainer}>
            <Box className={clsx(classes.optionContainer, classes.optionChange)}>
              <ToolbarItem 
                icon={faImage} 
                text={"Change"} 
                color={props.color}
                onClick={() => props.onChange && props.onChange()} 
              />
            </Box>
            <Box className={clsx(classes.optionContainer, classes.optionRemove)}>
              <ToolbarItem 
                icon={faTrashAlt} 
                text={"Remove"} 
                color={props.color}
                onClick={() => props.onRemove && props.onRemove()} 
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditImageOptions;
