import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, Option } from '@spike/model';
import clsx from 'clsx';
import React, { FunctionComponent, useState } from 'react';
import { useFieldStyles } from './FieldStyles';
import LabelField from './LabelField';

import { wbp } from 'Theme';
import { PropsWithChildren } from 'react';

export interface SelectFieldHeaderProps extends PropsWithChildren {
	id?: string;
	label?: string | JSX.Element;
	selectedOption?: Option<string | number>;
	placeholder?: string;
	errors?: Array<FieldError>;
	name?: string;
	disabled?: boolean;
	className?: string;
	required?: boolean;
	size?: 'md' | 'lg';
	information?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dropdownContainer: {
			position: 'relative',
			[theme.breakpoints.down(wbp)]: {
				height: '39px'
			},
			[theme.breakpoints.up(wbp)]: {
				height: '50px'
			}
		},
		collapsedContainer: {
			height: '100%'
		},
		expandedContainer: {
			display: 'flex',
			flexDirection: 'column',
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 10,
			width: '100%',
			backgroundColor: 'white',
			border: '1px solid #222222 !important',
			[theme.breakpoints.up(wbp)]: {
				paddingBottom: '26px'
			},
			[theme.breakpoints.down(wbp)]: {
				paddingBottom: '33px'
			}
		},
		headerContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				height: '38px'
			},
			[theme.breakpoints.up(wbp)]: {
				height: '47px'
			},
			cursor: 'pointer'
		},
		header: {
			fontSize: 14,
			lineHeight: 1.4,
			paddingLeft: 19,
			display: 'flex',
			alignItems: 'center',

			[theme.breakpoints.down('sm')]: {
				color: '#000 !important'
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				paddingLeft: '35px'
			}
		},
		headerMD: {
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.down(wbp)]: {
				paddingLeft: '14px',
				fontSize: '12px',
				lineHeight: '23px'
			},
			[theme.breakpoints.up(wbp)]: {
				paddingLeft: '15px',
				fontSize: '14px',
				lineHeight: '27px'
			}
		},
		placeholder: {
			color: '#7A7A7A'
		},
		border: {
			borderRadius: 30,
			border: '1px solid #D4D4D4',

			[theme.breakpoints.up('md')]: {
				borderColor: '#222'
			}
		},
		textContainer: {
			'width': '100%',
			'height': '100%',
			'display': 'flex',
			'overflow': 'hidden',
			'justifyContent': 'flex-start',
			'alignItems': 'center',

			'& p': {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				display: 'block'
			}
		},
		chevronContainer: {
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingRight: 16,
			marginLeft: 8,

			[theme.breakpoints.up('md')]: {
				paddingRight: '35px'
			}
		},
		chevronContainerMD: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			[theme.breakpoints.down(wbp)]: {
				paddingRight: '28px'
			},
			[theme.breakpoints.up(wbp)]: {
				paddingRight: '15px'
			}
		},
		optionNotSelected: {
			border: '1px solid #D3D3D3 !important'
		}
	})
);

export const SelectFieldHeader: FunctionComponent<
	SelectFieldHeaderProps
> = props => {
	const classes = useStyles();
	const fieldClasses = useFieldStyles();

	const [isCollapsed, setIsCollapsed] = useState(true);

	const hasError = props.errors?.some(
		errorField => errorField.fieldName === props.name
	);

	const clickCollapseHandler = () => {
		setIsCollapsed(prev => props.disabled || !prev);
	};

	const header = (
		<Box
			className={classes.headerContainer}
			id={props.id ? props.id + '_header' : props.id}
		>
			<Box className={classes.textContainer}>
				<Typography
					className={clsx(
						{ [classes.header]: !props.size },
						{ [classes.headerMD]: props.size === 'md' },
						{ [classes.placeholder]: !props.selectedOption }
					)}
				>
					{props.selectedOption
						? props.selectedOption.name
						: props.placeholder}
				</Typography>
			</Box>
			<Box
				className={clsx(
					{ [classes.chevronContainer]: !props.size },
					{ [classes.chevronContainerMD]: props.size === 'md' }
				)}
			>
				<FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />
			</Box>
		</Box>
	);

	const collapsed = (
		<Box
			className={clsx(classes.border, classes.collapsedContainer, {
				[classes.optionNotSelected]: !props.selectedOption && !hasError,
				[fieldClasses.errorBorder]: hasError,
				[fieldClasses.disabled]: props.disabled
			})}
			onClick={clickCollapseHandler}
		>
			{header}
		</Box>
	);

	const expanded = (
		<Box
			className={clsx(classes.border, classes.expandedContainer)}
			onClick={clickCollapseHandler}
		>
			{header}
			{props.children}
		</Box>
	);

	return (
		<ClickAwayListener onClickAway={() => setIsCollapsed(true)}>
			<Box
				id={props.id}
				className={clsx(fieldClasses.container, props.className)}
			>
				<LabelField {...props} />
				<Box className={classes.dropdownContainer}>
					{isCollapsed ? collapsed : expanded}
				</Box>
			</Box>
		</ClickAwayListener>
	);
};

export default SelectFieldHeader;
