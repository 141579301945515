import React, {
    FunctionComponent,
    Fragment,
    MouseEvent,
    useState
} from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { ConfirmDialog } from 'components/UI';
import { wbp, reduceResolution } from 'Theme';
import clsx from 'clsx';

export interface Props {
    id?: string;
    icon: IconProp;
    onConfirmedClick: () => void;
    text: string;
    color?: string;
    title?: JSX.Element;
    confirmQuestion: JSX.Element;
    confirmSubquestion?: JSX.Element;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    isDetail?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            'width': '100%',
            'color': '#222',
            'fontSize': 14,
            'fontWeight': 500,
            'lineHeight': 1.95,
            'cursor': 'pointer',
            'margin': '2px 0px',
            'padding': '0px 20px',
            'display': 'inline-block',
            'whiteSpace': 'nowrap',
            'textAlign': 'left',

            '& svg': {
                marginRight: 6,
                verticalAlign: 'middle'
            },

            '&:hover': {
                backgroundColor: '#FAFAFA'
            }
        },
        icon: {
            width: '15px',
            [theme.breakpoints.down('lg')]: {
                marginRight: '7px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '10px'
            }
        },
        title: {
            whiteSpace: 'nowrap',
            marginLeft: 2,
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '16px'
            }
        },
        detail: {
            color: '#EF4F57'
        }
    })
);

export const ToolbarItemWithConfirmation: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [showDialog, setShowDialog] = useState(false);

    const clickHandler = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setShowDialog(true);
    };

    const cancelHandler = () => {
        setShowDialog(false);
    };

    const confirmHandler = () => {
        setShowDialog(false);
        props.onConfirmedClick();
    };

    return (
        <Fragment>
            <Box
                id={props.id}
                className={clsx(classes.item, {
                    [classes.detail]: props.isDetail
                })}
                onClick={clickHandler}
                style={{
                    color: props.color
                }}
            >
                <FontAwesomeIcon icon={props.icon} />

                {props.text}
            </Box>

            <ConfirmDialog
                id={props.id}
                open={showDialog}
                title={props.title}
                question={props.confirmQuestion}
                subquestion={props.confirmSubquestion}
                confirmButtonLabel={props.confirmButtonLabel || 'Confirm'}
                cancelButtonLabel={props.cancelButtonLabel || 'Cancel'}
                onCancel={cancelHandler}
                onConfirm={confirmHandler}
            />
        </Fragment>
    );
};

export default ToolbarItemWithConfirmation;
