import React, { FunctionComponent, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import moment, { Moment } from "moment-timezone";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import clsx from "clsx";

interface HoursBookingProps {
	dayMoment: Moment;
	onSelectHour: (hours: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
		},
		item: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "#F8F8F8",
			},
			fontSize: 16,
			lineHeight: "16px",
			height: 25,
			paddingLeft: 11,
			[theme.breakpoints.up('md')]: {
				paddingLeft: 12
			},
		},
		itemSelected: {
			backgroundColor: "#F8F8F8"
		}
	})
);

export const HoursBooking: FunctionComponent<HoursBookingProps> = (props) => {
	const classes = useStyles();
	const selectedRef = useRef<HTMLElement>(null);

	const createHours = () => {
		const midnight = moment().startOf('day');
		const hours = [midnight.format('LT').toLowerCase()];
		let dateAdded = moment().startOf('day');
		
		while(midnight.format("L") === dateAdded.format("L")) {
			const datePlus15Min = dateAdded.add(15, "minutes");
			hours.push(datePlus15Min.format('LT').toLowerCase());
			dateAdded = datePlus15Min;
		}
		hours.pop();
		
		return hours;
	}

	useEffect(() => {
		selectedRef?.current?.scrollIntoView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRef]);

	return (
		<OverlayScrollbarsComponent className={classes.container}>
			{createHours().map(hour => {
				const itemProps = hour === props.dayMoment.format('LT').toLowerCase() ? { ref: selectedRef } : {};
				return <Typography 
						className={clsx(classes.item, {
							[classes.itemSelected]: hour === props.dayMoment.format('LT').toLowerCase()
						})} 
						onClick={() => props.onSelectHour(hour)} 
						id={`booking_option_hour_${hour}`}
						{...itemProps}
					>
						{hour}
					</Typography>
				})}
		</OverlayScrollbarsComponent>
	);
};

export default HoursBooking;
