// src/pages/PayByLink/PayByLink.tsx
import PayByLinkComponent from 'components/PayByLink';
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
    uuid?: string;
}

type PayByLinkProps = RouteComponentProps<MatchParams>;

const PayByLink: FunctionComponent<PayByLinkProps> = props => {
    return <PayByLinkComponent uuid={props.match.params.uuid || ''} />;
};

export default PayByLink;
