import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBan } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import { ToolbarItemWithConfirmation } from "components/UI/Toolbar/ToolbarItemWithConfirmation";
import {
    faTrashAlt,
    faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    position?: number;
    title?: string;
    collapsed?: boolean;
    disabled?: boolean;
    editable?: boolean;
    completed?: boolean;
    reactivate?: boolean;
    show?: boolean;
    removeQuestion?: JSX.Element;
    onDeactivate?: () => void;
    onReactivate?: () => void;
    onRemove?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        headerContainer: {
            display: "flex",
            width: "100%",
            background: "#E9F0ED",
            [theme.breakpoints.down("lg")]: {
                height: "34px",
                borderTopLeftRadius: "18px",
                borderTopRightRadius: "18px",
            },
            [theme.breakpoints.up("xl")]: {
                height: "46px",
                borderTopLeftRadius: "24px",
                borderTopRightRadius: "24px",
            },
        },
        collapsedHeaderContainer: {
            background: "#EFEFEF",
            [theme.breakpoints.down("lg")]: {
                borderBottomLeftRadius: "18px",
                borderBottomRightRadius: "18px",
            },
            [theme.breakpoints.up("xl")]: {
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
            },
        },
        leftHeaderContainer: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            [theme.breakpoints.down("lg")]: {
                paddingLeft: "32px",
            },
            [theme.breakpoints.up("xl")]: {
                paddingLeft: "43px",
            },
        },
        noneLeftHeaderContainer: {
            [theme.breakpoints.down("lg")]: {
                paddingLeft: "18px",
            },
            [theme.breakpoints.up("xl")]: {
                paddingLeft: "26px",
            },
        },
        completedCheck: {
            color: "#5E8677",
        },
        positionCircle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#5E8677",
            [theme.breakpoints.down("lg")]: {
                width: "20px",
                height: "20px",
                borderRadius: "20px",
            },
            [theme.breakpoints.up("xl")]: {
                width: "26px",
                height: "26px",
                borderRadius: "26px",
            },
        },
        position: {
            background: "#5E8677",
            color: "#FFFFFF",
            fontWeight: 500,
            [theme.breakpoints.down("lg")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "14px",
            },
        },
        centerHeaderContainer: {
            display: "flex",
            flex: "1 1 auto",
            alignItems: "center",
            [theme.breakpoints.down("lg")]: {
                paddingLeft: "8px",
            },
            [theme.breakpoints.up("xl")]: {
                paddingLeft: "10px",
            },
        },
        title: {
            color: "#5E8677",
            fontWeight: 600,
            [theme.breakpoints.down("lg")]: {
                fontSize: "13px",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "16px",
            },
        },
        rightHeaderContainer: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            [theme.breakpoints.down("lg")]: {
                width: "150px",
                paddingRight: "32px",
            },
            [theme.breakpoints.up("xl")]: {
                width: "200px",
                paddingRight: "43px",
            },
        },
        bodyContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            background: "#FFFFFF",
            [theme.breakpoints.down("lg")]: {
                borderBottomLeftRadius: "18px",
                borderBottomRightRadius: "18px",
            },
            [theme.breakpoints.up("xl")]: {
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
            },
        },
        disabled: {
            color: "#989898",
            background: "#EFEFEF",
        },
        postionDisabled: {
            background: "#989898",
            color: "#EFEFEF",
        },
        editLink: {
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: 500,
            [theme.breakpoints.down("lg")]: {
                fontSize: "14px",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "18px",
            },
        },
        typoDelete: {
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "center",
        },
        typoSubQuestion: {
            fontSize: "18px",
            textAlign: "center",
        }

    })
);

export const SectionClient: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const headerContainerStyle = clsx(classes.headerContainer, {
        [classes.collapsedHeaderContainer]: props.collapsed,
        [classes.disabled]: props.disabled,
    });

    const titleStyle = clsx(classes.title, {
        [classes.disabled]: props.disabled,
    });

    const positionCircleStyle = clsx(classes.positionCircle, {
        [classes.postionDisabled]: props.disabled,
    });

    const positionStyle = clsx(classes.position, {
        [classes.postionDisabled]: props.disabled,
    });

    const menu = (<Box>
        <ToolbarButton>
            {
                props.reactivate && (
                    <ToolbarItemWithConfirmation
                        icon={faPlusCircle}
                        text={"Reactivate"}
                        onConfirmedClick={() => props.onReactivate && props.onReactivate()}
                        confirmQuestion={props.removeQuestion || <Typography className={classes.typoDelete}>Are you sure you want to reactivate client?</Typography>}
                        confirmButtonLabel="Reactivate"
                        cancelButtonLabel="Cancel"
                    />
                )
            }
            {
                !props.reactivate && (
                    <ToolbarItemWithConfirmation
                        icon={faBan}
                        text={"Deactivate"}
                        onConfirmedClick={() => props.onDeactivate && props.onDeactivate()}
                        confirmQuestion={props.removeQuestion || <Typography className={classes.typoDelete}>Are you sure you want to deactivate client?</Typography>}
                        confirmSubquestion={<Typography className={classes.typoSubQuestion}>You will still be able to access client information. You can choose to reactivate client, if necessary.</Typography>}
                        confirmButtonLabel="Deactivate"
                        cancelButtonLabel="Cancel"
                    />
                )
            }
            <ToolbarItemWithConfirmation
                icon={faTrashAlt}
                text={"Delete Client"}
                onConfirmedClick={() => props.onRemove && props.onRemove()}
                confirmQuestion={props.removeQuestion || <Typography className={classes.typoDelete}>Are you sure you want to delete client?</Typography>}
                confirmSubquestion={<Typography className={classes.typoSubQuestion}>You will no longer be able to access this client if you choose to delete from your database.</Typography>}
                confirmButtonLabel="Delete"
                cancelButtonLabel="Cancel"
            />
        </ToolbarButton>
    </Box>
    );

    return (
        <Box className={classes.container}>
            <Box className={headerContainerStyle}>
                <Box className={clsx(classes.leftHeaderContainer, { [classes.noneLeftHeaderContainer]: (!props.position && !props.completed) })}>
                    {props.completed ? <FontAwesomeIcon size="lg" icon={faCheckCircle} className={classes.completedCheck} />
                        : (props.position ? <Box className={positionCircleStyle}>
                            <Typography className={positionStyle}>
                                {props.position}
                            </Typography>
                        </Box> : null)}
                </Box>
                <Box className={classes.centerHeaderContainer}>
                    <Typography className={titleStyle}>{props.title}</Typography>
                </Box>
                <Box className={classes.rightHeaderContainer}>
                    {
                        props.show && menu
                    }
                </Box>
            </Box>
            {!props.collapsed && <Box className={classes.bodyContainer}>{props.children}</Box>}
        </Box>
    );
};

export default SectionClient;
