import Bookings from 'pages/Bookings';
import Calendar from 'pages/Calendar';
import Clients from 'pages/Clients';
import GlobalResult from 'pages/GlobalResult';
import Impersonate from 'pages/Impersonate';
import Logout from 'pages/Logout';
import Services from 'pages/Services';
import { Fragment, FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ActivateAccountPath,
    AgreementsSignaturePath,
    BookingsPath,
    BusinessSettingsPath,
    CalendarPath,
    ClientsEditPath,
    ClientsPath,
    CreationServicePath,
    ForgotPasswordPath,
    ForgotPasswordSentPath,
    GlobalPath,
    ImpersonatePath,
    InsightsPath,
    LoginPath,
    LogoutPath,
    MarketingPath,
    MyAccountPath,
    NewPasswordPath,
    PaymentsPath,
    ProductsPath,
    ResetCustomerPath,
    ResetPasswordPath,
    ServicesPath,
    SignUpAlmostPath,
    SignUpPath,
    SignUpBusinessPath,
    SignUpVerifyPath,
    SMSGetStartedPath,
    SMSCampaignCreatePath,
    TwoWaySMSPath,
    StaffPath,
    BookingDetailPath,
    HelpSupportPath,
    PayByLinkPath
} from './Paths';
import PrivateRoute from './PrivateRoute';
import { useAuth, useHasPremiumAccess, useMarketplace, useMasterData } from 'hooks';
import ActivateAccount from 'pages/ActivateAccount';
import { AgreementsSignature } from 'pages/AgreementsSignature';
import BusinessSettings from 'pages/BusinessSettings';
import Insights from 'pages/Insights';
import MyAccount from 'pages/MyAccount';
import Payments from 'pages/Payments';
import Products from 'pages/Products';

import Staff from 'pages/Staff';
import { Login } from 'rocket/pages/Login';
import NewPassword from 'rocket/pages/NewPassword';
import ResetPassword from 'rocket/pages/ResetPassword';
import ResetSent from 'rocket/pages/ResetSent';
import SetPassword from 'rocket/pages/SetPassword';
import SignUp from 'rocket/pages/Signup';
import SignUpAlmost from 'rocket/pages/SignupAlmost';
import SignUpBusiness from 'rocket/pages/SignUpBusiness';
import SignUpVerify from 'rocket/pages/SignupVerify';
import { isMarketingEnabled, isPaymentsEnabled } from 'utils/MarketingUtils';
import MarketingPage from 'pages/Marketing';
import SMSGetStartedPage from 'pages/SMSGetStarted';
import SMSCampaignCreate from 'pages/SMSCampaignCreate';
import TwoWaySMS from 'pages/TwoWaySMS';
import HelpAndSupport from 'pages/HelpCenterSupport';
import PayByLink from 'pages/PayByLink';

import { hasAccessLevel } from 'hooks/accessLevel';

// import TestPage from 'pages/Test';
// const SPIKE_ENV = process.env.REACT_APP_HOST_URL?.toLowerCase() === 'https://spike-api.versiondos.com';

export const Routes: FunctionComponent = () => {
    const auth = useAuth();
    const marketplace = useMarketplace();
    const masterData = useMasterData();
    const hasInsightsPremiumAccess = useHasPremiumAccess('insights');
    const hasTwoWaySMSPremiumAccess = useHasPremiumAccess('two_way_sms');

    const marketingEnabled =
        isMarketingEnabled(auth.marketplaceId) && hasAccessLevel('marketing', masterData, auth.user);
    const twoWaySMSEnabled = process.env.REACT_APP_TWO_WAY_SMS_ENABLED?.toLowerCase() === 'true';
    const paymentsEnabled = isPaymentsEnabled(marketplace) && hasAccessLevel('payments', masterData, auth.user);

    const bookingEnabled = hasAccessLevel('bookings', masterData, auth.user);
    const calendarEnabled = hasAccessLevel('calendar', masterData, auth.user);
    const clientsEnabled = hasAccessLevel('clients', masterData, auth.user);
    const staffEnabled = hasAccessLevel('staff', masterData, auth.user);
    const servicesEnabled = hasAccessLevel('staff', masterData, auth.user);
    const helpEnabled = hasAccessLevel('helpCenter', masterData, auth.user);
    const insightsEnabled = hasAccessLevel('insights', masterData, auth.user);
    const settingsEnabled = hasAccessLevel('settings', masterData, auth.user);
    const productsEnabled = hasAccessLevel('products', masterData, auth.user);

    return (
        <Fragment>
            <Switch>
                {/* {SPIKE_ENV && <Route path="/test" component={TestPage} />} */}
                <Route path="/we-are-not-mobile" component={Login} />
                <Route path={LoginPath} component={Login} />
                <Route path={ImpersonatePath} component={Impersonate} />
                <Route path={LogoutPath} component={Logout} />
                <Route path={ForgotPasswordPath} component={ResetPassword} />
                <Route path={ForgotPasswordSentPath} component={ResetSent} />
                <Route path={ResetPasswordPath} component={SetPassword} />
                <Route path={NewPasswordPath} component={NewPassword} />
                <Route path={ResetCustomerPath} component={SetPassword} />
                <Route strict exact path={SignUpPath} component={SignUp} />
                <Route strict exact path={SignUpAlmostPath} component={SignUpAlmost} />
                <Route strict exact path={SignUpBusinessPath} component={SignUpBusiness} />
                <Route strict exact path={SignUpVerifyPath} component={SignUpVerify} />
                <Route path={ActivateAccountPath} component={ActivateAccount} />

                <Route strict exact path={AgreementsSignaturePath} component={AgreementsSignature} />

                <Route strict exact path={PayByLinkPath} component={PayByLink} />

                {marketingEnabled && <PrivateRoute strict exact path={MarketingPath} component={MarketingPage} />}
                {marketingEnabled && (
                    <PrivateRoute strict exact path={SMSGetStartedPath} component={SMSGetStartedPage} />
                )}
                {marketingEnabled && (
                    <PrivateRoute strict exact path={SMSCampaignCreatePath} component={SMSCampaignCreate} />
                )}
                {twoWaySMSEnabled && hasTwoWaySMSPremiumAccess && (
                    <PrivateRoute strict exact path={TwoWaySMSPath} component={TwoWaySMS} />
                )}

                {!auth.signedIn && <Redirect to={LoginPath} />}
                {auth.signedIn && auth.version !== process.env.REACT_APP_VERSION && <Redirect to={LogoutPath} />}

                {calendarEnabled && <PrivateRoute path={CalendarPath} component={Calendar} />}
                <PrivateRoute path={GlobalPath} component={GlobalResult} />

                {bookingEnabled && <PrivateRoute path={BookingsPath} component={Bookings} />}
                {bookingEnabled && <PrivateRoute path={BookingDetailPath} component={Bookings} />}

                {servicesEnabled && <PrivateRoute strict exact path={CreationServicePath} component={Services} />}
                {servicesEnabled && <PrivateRoute strict exact path={ServicesPath} component={Services} />}

                {clientsEnabled && <PrivateRoute exact path={ClientsEditPath} component={Clients} />}
                {clientsEnabled && <PrivateRoute path={ClientsPath} component={Clients} />}

                {staffEnabled && <PrivateRoute path={StaffPath} component={Staff} />}
                {settingsEnabled && <PrivateRoute path={BusinessSettingsPath} component={BusinessSettings} />}
                {helpEnabled && <PrivateRoute path={HelpSupportPath} component={HelpAndSupport} />}
                <PrivateRoute path={MyAccountPath} component={MyAccount} />

                {marketingEnabled && <PrivateRoute strict exact path={MarketingPath} component={MarketingPage} />}
                {marketingEnabled && (
                    <PrivateRoute strict exact path={SMSGetStartedPath} component={SMSGetStartedPage} />
                )}
                {marketingEnabled && (
                    <PrivateRoute strict exact path={SMSCampaignCreatePath} component={SMSCampaignCreate} />
                )}

                {/* <PrivateRoute strict exact path={NewAgreementsPath} component={AgreementsCreate} />  */}
                {insightsEnabled && hasInsightsPremiumAccess && (
                    <PrivateRoute path={InsightsPath} component={Insights} />
                )}
                {paymentsEnabled && <PrivateRoute path={PaymentsPath} component={Payments} />}
                {productsEnabled && <PrivateRoute path={ProductsPath} component={Products} />}
                <Redirect to={BookingsPath} />
            </Switch>
        </Fragment>
    );
};

export default Routes;
