import { Box, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Client, Contact, createEmptyContact } from '@spike/client-model';
import { ClientsStatus, saveAuthorizedContactsThunk } from '@spike/clients-action';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { Button, ConfirmDialog } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import { v4 as uuid } from 'uuid';
import ContactForm from './UI/ContactForm';
import ContactsTable from './UI/ContactsTable';

interface AuthorizedContactsProps {
    disabled?: boolean;
    clientId?: number;
    contacts: Array<Contact>;
    onChange?: (contacts: Array<Contact>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center'
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`,
                lineHeight: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px',
                lineHeight: '24px'
            }
        },
        dialog: {
            '& .MuiDialog-paperScrollPaper': {
                [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    borderRadius: 0,
                    maxHeight: '100%'
                }
            },
            '& .MuiDialog-paper': {
                width: '100%',

                [theme.breakpoints.down('sm')]: {
                    margin: 0
                },

                [theme.breakpoints.up('md')]: {
                    borderRadius: '28px',
                    width: '486px'
                }
            },
            '& .MuiDialogContent-root': {
                overflowY: 'auto',
                padding: '0px'
            }
        },
        marginTable: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(33)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '33px'
            }
        },
        footer: {
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            [theme.breakpoints.up('md')]: {
                height: '78px'
            }
        },
        addContact: {
            'fontSize': 14,
            'fontWeight': 600,
            'color': '#EAB464',
            'cursor': 'pointer',

            [theme.breakpoints.up(wbp)]: {
                fontSize: 16,
                paddingLeft: '33px'
            },
            '&.disabled': {
                color: '#999',
                cursor: 'not-allowed'
            }
        },
        contactsContainer: {
            [theme.breakpoints.down('sm')]: {
                margin: '0px -16px',
                width: 'calc(100% + 32px)'
            }
        },
        emptyContactsContainer: {
            margin: 33,

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        heading: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            },

            '& h5': {
                gap: 10,
                color: '#222',
                fontSize: 18,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center'
            },
            '& p': {
                color: '#000',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.4,
                marginTop: 16
            }
        },
        button: {
            height: 39,
            paddingLeft: 14,
            paddingRight: 14,
            borderRadius: 30,
            minWidth: 'unset'
        }
    })
);

export const AuthorizedContacts: FunctionComponent<AuthorizedContactsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const clientsStatus = useSelector<RootState, ClientsStatus>(state => state.clients.status);
    const savedClient = useSelector<RootState, Client | undefined>(state => state.clients.savedClient);

    const hasContacts = props.contacts.length > 0;

    const [editContact, setEditContact] = useState<Contact | null>(null);
    const [deleteContact, setDeleteContact] = useState<Contact | null>(null);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const authorizedContacts = props.contacts.filter(contact => !contact.emergencyContact);
    const emergencyContacts = props.contacts.filter(contact => contact.emergencyContact);

    const change = (contacts: Array<Contact>) => {
        props.onChange && props.onChange(contacts);
        setSaving(false);
        setEditContact(null);
        setDeleteContact(null);
    };

    useNonInitialEffect(() => {
        switch (clientsStatus) {
            case ClientsStatus.SaveAuthorizedContactsSuccess: {
                change(savedClient?.authorizedContacts || []);
                setSaving(false);
                setDeleting(false);
                break;
            }
            case ClientsStatus.Error: {
                setSaving(false);
                break;
            }
        }
    }, [clientsStatus]);

    const saveHandler = (person: Contact) => {
        const contacts = props.contacts.some(p => p.uuid === person.uuid)
            ? props.contacts.map(p => (p.uuid === person.uuid ? person : p))
            : [...props.contacts, person];

        if (props.clientId) {
            setSaving(true);
            dispatch(saveAuthorizedContactsThunk(apiClientWrapper, props.clientId, contacts));
        } else {
            change(contacts);
        }
    };

    const deleteHandler = (contact: Contact) => {
        const contacts = props.contacts.filter(p => p.uuid !== contact.uuid);

        if (props.clientId) {
            setDeleting(true);
            dispatch(saveAuthorizedContactsThunk(apiClientWrapper, props.clientId, contacts));
        } else {
            change(contacts);
        }
    };

    return (
        <Grid container xs={12} className={classes.container}>
            <Box className={classes.heading}>
                <Typography variant="h5">
                    <span>Authorized & Emergency Contacts</span>

                    <Button
                        disabled={props.disabled}
                        label="+ Add Contact"
                        id="clients_button_add_contact"
                        className={classes.button}
                        onClick={() => setEditContact(createEmptyContact(uuid))}
                    />
                </Typography>
                <Typography>
                    Add authorized contacts that can pick up and drop off the client’s pet or emergency contacts that
                    can make decisions on behalf of the client.
                </Typography>
            </Box>

            {hasContacts ? (
                <Grid container className={classes.contactsContainer}>
                    {authorizedContacts.length > 0 && (
                        <ContactsTable
                            title="AUTHORIZED"
                            className={classes.marginTable}
                            contacts={authorizedContacts}
                            showLastRowLine={emergencyContacts.length === 0}
                            onDelete={contact => {
                                setDeleteContact(contact);
                            }}
                            onEdit={contact => {
                                setEditContact(contact);
                            }}
                        />
                    )}

                    {emergencyContacts.length > 0 && (
                        <ContactsTable
                            title="AUTHORIZED & EMERGENCY"
                            contacts={emergencyContacts}
                            showLastRowLine={true}
                            className={clsx({
                                [classes.marginTable]: authorizedContacts.length === 0
                            })}
                            onDelete={contact => {
                                setDeleteContact(contact);
                            }}
                            onEdit={contact => {
                                setEditContact(contact);
                            }}
                        />
                    )}

                    <Grid xs={12} className={classes.footer}>
                        <Typography
                            onClick={() => !props.disabled && setEditContact(createEmptyContact(uuid))}
                            className={clsx(classes.addContact, {
                                disabled: props.disabled
                            })}
                        >
                            + Add Contact
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container xs={12} className={classes.emptyContactsContainer}>
                    <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
                        <Typography className={classes.title}>No contacts yet.</Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end">
                        <Button
                            disabled={props.disabled}
                            id="clients_button_add_contact"
                            label="+ Add Contact"
                            onClick={() => setEditContact(createEmptyContact(uuid))}
                        />
                    </Grid>
                </Grid>
            )}
            {editContact && (
                <Dialog open={true} className={clsx(classes.dialog)}>
                    <DialogContent>
                        <ContactForm
                            title={editContact.id ? 'Edit Contact' : 'Add Contact'}
                            contact={editContact}
                            saving={saving}
                            onSave={saveHandler}
                            onClose={() => {
                                setSaving(false);
                                setEditContact(null);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {deleteContact !== null && (
                <ConfirmDialog
                    open={true}
                    title={
                        <Typography>
                            {deleteContact!.firstName} {deleteContact!.lastName}
                        </Typography>
                    }
                    question={<Typography>Do you want to delete this Autorized Person?</Typography>}
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    processing={deleting}
                    onCancel={() => setDeleteContact(null)}
                    onConfirm={() => deleteHandler(deleteContact)}
                />
            )}
        </Grid>
    );
};

export default AuthorizedContacts;
