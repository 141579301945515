import { Avatar, Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { InvoiceStaff as InvoiceStaffModel } from "@spike/invoice-model";
import React, { FunctionComponent } from "react";
import { wbp } from "Theme";

interface InvoiceStaffProps {
  staff: InvoiceStaffModel;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container:{
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F1F1F1",
      borderRadius: "100px",
      width: "max-content",
      [theme.breakpoints.down(wbp)]: {
        padding: "5px 10px 5px 8px",
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "7px 12px 7px 10px",
      },
    },
    avatar: {
      [theme.breakpoints.down(wbp)]: {
        width: "19px",
        height: "19px",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "24px",
        height: "24px",
      },
    },
    name: {
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#222222",
      textTransform: "capitalize",
      [theme.breakpoints.down(wbp)]: {
        fontSize: "13px",
        lineHeight: "17px",
        marginLeft: "8px",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "21px",
        marginLeft: "10px",
      },
    },
  })
);

export const InvoiceStaff: FunctionComponent<InvoiceStaffProps> = (props) => {
  const classes = useStyles();
  const [name] = props.staff.name.split(" ");
  return (
    <Box className={clsx(classes.container, props.className)}>
      <Avatar className={classes.avatar} src={props.staff.imageUrl} />
      <Typography className={classes.name}>{name}</Typography>
    </Box>
  );
};

export default InvoiceStaff;
