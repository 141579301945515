import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { FunctionComponent } from "react";
import { wbp, reduceResolution } from "Theme";
import PercentageField2 from "../PercentageField2";

export interface TipPercentageFieldProps {
    tipNumber: number;
    value: number | undefined;
    required?: boolean;
    errors?: Array<FieldError>;
    onBlur?: () => void;
    onChange: (value: number | undefined, name?: any) => void;
    onFocus?: () => void;
    className?: string;
    autoFocus?: boolean;
    autoComplete?: "off" | "on";
    onEnterPressed?: () => void;
    disabled?: boolean;
    scale?: number;
    precision?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tipContainer: (props: TipPercentageFieldProps) => ({
            display: "flex",
            width: "100%",
            [theme.breakpoints.down(wbp)]: {
                marginTop: props.tipNumber === 1 ? '0px' : `${reduceResolution(44)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: props.tipNumber === 1 ? '0px' : "44px",
            },
        }),
        tipTitleContainer: {
            display: "flex",
            width: "30%",
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(19)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "19px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "100%",
            },
        },
        tipTitle: {
            fontSize: "16px",
            marginTop: "auto",
            marginBottom: "auto",
            fontWeight: 600,
            fontFamily: 'Poppins',
            fontStyle: "normal",
            lineHeight: "100%",
            color: "#222222",

        },

        tipValueContainer: {
            fontSize: "16px",
            display: "flex",
            width: "19%",
            [theme.breakpoints.down("sm")]: {
                width: "70%",
            },
            [theme.breakpoints.only("md")]: {
                width: "19%",
            },
            [theme.breakpoints.up("lg")]: {
                width: "19%",
            },
        },

        field: {
            "& input": {
                [theme.breakpoints.down(wbp)]: {
                    paddingLeft: `${reduceResolution(30)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    paddingLeft: "30px",
                },
            },
            "& .MuiOutlinedInput-root": {
                fontWeight: 500
            },
            "& fieldset": {
                border: "1px solid #D3D3D3",
                borderColor: "#D3D3D3 !important"

            },
            "& input.focus-visible ~ fieldset": {
              border: "1px solid #222222 !important",
            },
             "& p": {
                    color: "#7A7A7A"
                },

          },

        tipValueField: {
            textAlign: "right",
            "& .MuiOutlinedInput-adornedEnd": {
                [theme.breakpoints.down(wbp)]: {
                    paddingRight: `${reduceResolution(10)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    paddingRight: "10px",
                },
                "& .MuiInputBase-input": {
                    fontWeight: 500
                },
                "& p": {
                    color: "#7A7A7A"
                },
                "& input": {
                    [theme.breakpoints.down(wbp)]: {
                        paddingLeft: `${reduceResolution(30)}px`,
                    },
                    [theme.breakpoints.up(wbp)]: {
                        paddingLeft: "30px",
                    },
                },
                "& fieldset": {
                    border: "1px solid #D3D3D3",
                    borderColor: "#D3D3D3 !important"
                },
                "& input.focus-visible ~ fieldset": {
                    border: "1px solid #222222 !important",
                },

            }
        }
    })
);

export const TipPercentageField: FunctionComponent<TipPercentageFieldProps> = (props) => {

    const classes = useStyles(props);

    return (
        <Box className={classes.tipContainer} key={props.tipNumber}>
            <Box className={classes.tipTitleContainer}>
                <Typography className={classes.tipTitle}>
                    Tip #{props.tipNumber}
                </Typography>
            </Box>
            <Box className={classes.tipValueContainer}>

                <PercentageField2
                    {...props}
                    className={classes.tipValueField}
                    scale={0}
                    precision={2}
                    max={100}
                    name="tips.percentage"
                    alignRight={false}

                />
            </Box>
        </Box>
    );

}

export default TipPercentageField;
