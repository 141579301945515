import { makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

export interface Props extends LinkProps {
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 47,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#fff",
    borderRadius: 31,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: "#EAB464",
    fontFamily: '"Poppins", sans-serif',
    "&:hover": {
      backgroundColor: "#C18733",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      height: 39,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
}));

export const SmallPrimaryButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Link {...props} className={clsx(classes.root, props.className)}>
      {props.label || "Falta label"}
    </Link>
  );
};

export default SmallPrimaryButton;
