import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import Header from "rocket/components/Header";
import LeftColumn from "rocket/components/Signup/LeftColumn";
import RightColumn from "rocket/components/Signup/RightColumn";
import HeaderLink from "rocket/components/Commons/HeaderLink";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export const Signup: FunctionComponent = () => {
  const classes = useStyles();

  document.title = "Create account - Cuddles";

  useEffect(() => {
    pageView("/signup");
  }, []);

  return (
    <div id="signup">
      <Header rightLink={<HeaderLink to="/login">Login</HeaderLink>} />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <LeftColumn />
          <RightColumn />
        </Grid>
      </div>
    </div>
  );
};

export default Signup;
