import { Action } from "redux";
import GlobalSearch from "model/GlobalSearch";

//Action Types
export const GLOBALSEARCH_START_ACTION_TYPE = "GLOBALSEARCH_SEARCH_START";
export const GLOBALSEARCH_SUCCESS_ACTION_TYPE = "GLOBALSEARCH_SEARCH_SUCCESS";
export const GLOBALSEARCH_ERROR_ACTION_TYPE = "GLOBALSEARCH_SEARCH_ERROR";

//Actions
interface GlobalSearchStartAction
  extends Action<typeof GLOBALSEARCH_START_ACTION_TYPE> { }

interface GlobalSeachSuccessAction
  extends Action<typeof GLOBALSEARCH_SUCCESS_ACTION_TYPE> {
  payload: {
    results: Array<GlobalSearch>;
  };
}

interface GlobalSeachErrorAction extends Action<typeof GLOBALSEARCH_ERROR_ACTION_TYPE> { }


export type GlobalSearchAction =
  GlobalSearchStartAction
  | GlobalSeachSuccessAction
  | GlobalSeachErrorAction

