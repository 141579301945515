import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Header } from 'components/UI';
import SelectionBar, { SelectionBarOption } from './SelectionBar';
import Filter from 'components/UI/Filter';
import { Product } from '@spike/product-model';
import ProductVendor from 'model/ProductVendor';
import ProductCategory from 'model/ProductCategory';
import { faHandshake, faPaw } from '@fortawesome/pro-regular-svg-icons';
import VendorsFilter from './VendorsFilter';
import SearchComponent from '../UI/Search';
import uniqWith from 'lodash/uniqWith';
import Button from 'components/UI/V2/Button/Button';
import { Button as ButtonV1 } from 'components/UI/';

export interface Properties {
    views: Array<SelectionBarOption>;
    selectedViewId: string;
    vendorsActive: boolean;
    vendorsDeactive: boolean;
    products: Array<Product>;
    vendors: Array<ProductVendor>;
    categories: Array<ProductCategory>;
    onSelectView: (id: string) => void;
    onSearch: (text: string) => void;
    onAddProduct: () => void;
    onAddVendor: () => void;
    onAddCategory: () => void;
    onSelectProductCategory: (
        ids: (number | undefined)[],
        all: boolean
    ) => void;
    onSelectVendor: (active: boolean, deactive: boolean) => void;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: 'white'
        },
        headerContainer: {
            width: '100% !important',
            [theme.breakpoints.down('md')]: {
                paddingTop: '19px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingTop: '24px'
            }
        },
        titleHeader: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '8px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: '10px'
            }
        },
        secondLineHeader: {
            marginTop: '28px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down(768)]: {
                marginTop: 0,
                paddingTop: 10,
                paddingLeft: 16,
                paddingRight: 16
            },
            [theme.breakpoints.between(768, 'sm')]: {
                paddingTop: '13px',
                paddingRight: '40px',
                paddingLeft: '27px'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '21px',
                paddingRight: '65px',
                paddingLeft: '43px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '35px',
                paddingTop: '28px',
                paddingRight: '87px',
                paddingLeft: '57px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '43px',
                paddingRight: '132px',
                paddingLeft: '87px'
            }
        },
        buttonsContainer: {
            display: 'flex',
            flex: '1 1 auto',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        button: {
            [theme.breakpoints.down('md')]: {
                marginLeft: '22px'
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '28px'
            }
        },
        productContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
        },
        paddingSearch: {
            flex: 1,
            width: 'auto',

            [theme.breakpoints.down('md')]: {
                marginLeft: '22px'
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '28px'
            }
        }
    })
);

export const ProductsHeader: FunctionComponent<Properties> = props => {
    const classes = useStyles();

    const getCategories = (products: Array<Product>) => {
        return uniqWith(
            products
                .filter(product => product.category)
                .map(product => product.category!),
            (category, otherCategory) => category.id === otherCategory.id
        );
    };

    const handleSelectFilterProduct = (
        ids: (number | undefined)[],
        all: boolean
    ) => {
        props.onSelectProductCategory(ids, all);
    };

    const selectionBar = (
        <SelectionBar
            options={props.views}
            selectedOptionId={props.selectedViewId}
            onSelect={props.onSelectView}
            count={
                props.selectedViewId === 'products'
                    ? props.views[0].count
                    : props.selectedViewId === 'vendors'
                    ? props.views[1].count
                    : props.views[2].count
            }
        />
    );

    const productsCategoriesFilter = (
        <Filter
            label="Categories"
            icon={faPaw}
            options={getCategories(props.products)}
            onSelect={(ids, all) => handleSelectFilterProduct(ids, all)}
            className={classes.button}
        />
    );

    const vendorsFilter = (
        <VendorsFilter
            label="Vendors"
            icon={faHandshake}
            active={props.vendorsActive}
            deactive={props.vendorsDeactive}
            onSelect={(active, deactive) =>
                props.onSelectVendor(active, deactive)
            }
            className={classes.button}
        />
    );

    const search = (
        <SearchComponent
            dashboard={true}
            viewSelected={props.selectedViewId}
            onSearch={search => props.onSearch(search)}
            placeholder="Search item"
            className={classes.paddingSearch}
        />
    );

    const addProductButton = () => {
        return (
            <Box className={classes.productContainer}>
                <Button
                    label="Add Product"
                    leftIcon={faPlus}
                    className={classes.button}
                    onClick={props.onAddProduct}
                />
            </Box>
        );
    };

    const addVendorButton = () => {
        return (
            <Box className={classes.productContainer}>
                <ButtonV1
                    label={'Add Vendor'}
                    onClick={props.onAddVendor}
                    startIcon={faPlus}
                    className={classes.button}
                />
            </Box>
        );
    };

    const addCategoryButton = () => {
        return (
            <Box className={classes.productContainer}>
                <ButtonV1
                    label={'Add Category'}
                    onClick={props.onAddCategory}
                    startIcon={faPlus}
                    className={classes.button}
                />
            </Box>
        );
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.titleHeader}>
                <Header title="Products" className={classes.headerContainer} />
            </Box>
            <Box className={classes.secondLineHeader}>
                <Box>{selectionBar}</Box>
                <Box className={classes.buttonsContainer}>
                    {search}
                    {props.selectedViewId === 'products' &&
                        productsCategoriesFilter}
                    {props.selectedViewId === 'products' && addProductButton()}
                    {props.selectedViewId === 'vendors' && vendorsFilter}
                    {props.selectedViewId === 'vendors' && addVendorButton()}
                    {props.selectedViewId === 'categories' &&
                        addCategoryButton()}
                </Box>
            </Box>
        </Box>
    );
};

export default ProductsHeader;
