import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "components/UI";
import React, { FunctionComponent } from "react";
import { Img } from "react-image";
import { wbp } from "Theme";

interface EnrollmentDoneProps {
	className?: string;
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			[theme.breakpoints.down(wbp)]: {
				paddingTop: "86px",
				paddingBottom: "86px",
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: "107px",
				paddingBottom: "107px",
			},
		},
		image: {
			[theme.breakpoints.down(wbp)]: {
				height: "277px",
			},
			[theme.breakpoints.up(wbp)]: {
				height: "346px",
			},
		},
		title: {
			fontFamily: "Poppins",
			fontWeight: 600,
			textAlign: "center",
			width: "100%",
			color: "#222222",
			[theme.breakpoints.down(wbp)]: {
				marginTop: "28px",
				fontSize: "19px",
				lineHeight: "23px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "35px",
				fontSize: "24px",
				lineHeight: "29px",
			},
		},
		description: {
			fontFamily: "Poppins",
			fontWeight: 400,
			textAlign: "center",
			width: "100%",
			color: "black",
			[theme.breakpoints.down(wbp)]: {
				marginTop: "17px",
				fontSize: "12px",
				lineHeight: "17px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "21px",
				fontSize: "15px",
				lineHeight: "21px",
			},
		},
		pci: {
			fontFamily: "Poppins",
			fontWeight: 400,
			textAlign: "center",
			width: "100%",
			color: "black",
			[theme.breakpoints.down(wbp)]: {
				marginTop: "17px",
				fontSize: "14px",
				lineHeight: "19px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "21px",
				fontSize: "17px",
				lineHeight: "22px",
			},
		},
		button: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: "23px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "29px",
			},
		},
	})
);

export const EnrollmentDone: FunctionComponent<EnrollmentDoneProps> = (props) => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Img
				src="/images/payments/cuddlespay-successful.png"
				className={classes.image}
			/>
			<Typography className={classes.title}>
				You have successfully
				<br />
				submitted your request.
			</Typography>
			<Typography className={classes.description}>
				We are validating the information.
			</Typography>
			<Typography className={classes.pci}>
				Please answer the PCI questionnaire
				<br />
				to complete your sign up process.
			</Typography>
			<Button
				label="Ok"
				color="black"
				className={classes.button}
				onClick={props.onClick}
				id="cuddlespay-completed"
			/>
		</Box>
	);
};

export default EnrollmentDone;
