// src/actions/payByLink/PayByLinkActions.ts
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';
import axios, { AxiosResponse } from 'axios';

import {
    PAY_BY_LINK_FETCH_START,
    PAY_BY_LINK_FETCH_SUCCESS,
    PAY_BY_LINK_FETCH_ERROR,
    PAY_BY_LINK_SAVE_TIP_START,
    PAY_BY_LINK_SAVE_TIP_SUCCESS,
    PAY_BY_LINK_SAVE_TIP_ERROR,
    PayByLinkAction,
    PAY_BY_LINK_RESET_SAVE_TIP_ERROR,
    PAY_BY_LINK_DROPIN_SESSION_START,
    PAY_BY_LINK_DROPIN_SESSION_SUCCESS,
    PAY_BY_LINK_DROPIN_SESSION_ERROR,
    PAY_BY_LINK_LOCK_INVOICE_START,
    PAY_BY_LINK_LOCK_INVOICE_SUCCESS,
    PAY_BY_LINK_LOCK_INVOICE_ERROR,
    PAY_BY_LINK_UNLOCK_INVOICE_START,
    PAY_BY_LINK_UNLOCK_INVOICE_SUCCESS,
    PAY_BY_LINK_UNLOCK_INVOICE_ERROR,
    PAY_BY_LINK_CONFIRM_PAYMENT_START,
    PAY_BY_LINK_CONFIRM_PAYMENT_SUCCESS,
    PAY_BY_LINK_CONFIRM_PAYMENT_ERROR
} from './PayByLinkActionTypes';
import { PaymentLink } from './PayByLinkDto';
import { InvoiceDto } from '@spike/invoices-action';
import ApiClient from 'api';
import { DropInDto } from '@spike/payments-action';

const base_url = `${process.env.REACT_APP_HOST_URL}`;
const getPaymentLinkUrl = base_url + '/api/public/v1/pay_by_link/';
const updateInvoiceUrl = base_url + '/api/v1/invoices/';
const dropInUrl = `${base_url}/api/v1/create_dropin`;

export const fetchPayByLinkStart = (): PayByLinkAction => ({
    type: PAY_BY_LINK_FETCH_START
});

export const fetchPayByLinkSuccess = (
    paymentLink: PaymentLink
): PayByLinkAction => ({
    type: PAY_BY_LINK_FETCH_SUCCESS,
    payload: { loading: false, paymentLink }
});

export const fetchPayByLinkError = (error: string): PayByLinkAction => ({
    type: PAY_BY_LINK_FETCH_ERROR,
    payload: { loading: false, fetchError: error }
});

export const saveTipStart = (): PayByLinkAction => ({
    type: PAY_BY_LINK_SAVE_TIP_START,
    payload: { loading: true }
});

export const saveTipSuccess = (invoice: InvoiceDto): PayByLinkAction => ({
    type: PAY_BY_LINK_SAVE_TIP_SUCCESS,
    payload: { loading: false, invoice }
});

export const saveTipError = (error: string): PayByLinkAction => ({
    type: PAY_BY_LINK_SAVE_TIP_ERROR,
    payload: { loading: false, saveTipError: error }
});

export const resetSaveTipError = (): PayByLinkAction => ({
    type: PAY_BY_LINK_RESET_SAVE_TIP_ERROR
});

export const dropInSessionStart = (): PayByLinkAction => ({
    type: PAY_BY_LINK_DROPIN_SESSION_START
});

export const dropInSessionSuccess = (
    dropInSession: DropInDto
): PayByLinkAction => ({
    type: PAY_BY_LINK_DROPIN_SESSION_SUCCESS,
    payload: { dropInSession }
});

export const dropInSessionError = (error: string): PayByLinkAction => ({
    type: PAY_BY_LINK_DROPIN_SESSION_ERROR,
    payload: { error }
});

export const lockInvoiceStart = (): PayByLinkAction => ({
    type: PAY_BY_LINK_LOCK_INVOICE_START,
    payload: { loading: true }
});

export const lockInvoiceSuccess = (): PayByLinkAction => ({
    type: PAY_BY_LINK_LOCK_INVOICE_SUCCESS,
    payload: { loading: false }
});

export const lockInvoiceError = (error: string): PayByLinkAction => ({
    type: PAY_BY_LINK_LOCK_INVOICE_ERROR,
    payload: { loading: false, lockInvoiceError: error }
});

export const unlockInvoiceStart = (): PayByLinkAction => ({
    type: PAY_BY_LINK_UNLOCK_INVOICE_START,
    payload: { loading: true }
});

export const unlockInvoiceSuccess = (): PayByLinkAction => ({
    type: PAY_BY_LINK_UNLOCK_INVOICE_SUCCESS,
    payload: { loading: false }
});

export const unlockInvoiceError = (error: string): PayByLinkAction => ({
    type: PAY_BY_LINK_UNLOCK_INVOICE_ERROR,
    payload: { loading: false, unlockInvoiceError: error }
});

export const confirmPaymentStart = (): PayByLinkAction => ({
    type: PAY_BY_LINK_CONFIRM_PAYMENT_START,
    payload: { loading: true }
});

export const confirmPaymentSuccess = (): PayByLinkAction => ({
    type: PAY_BY_LINK_CONFIRM_PAYMENT_SUCCESS,
    payload: { loading: false }
});

export const confirmPaymentError = (error: string): PayByLinkAction => ({
    type: PAY_BY_LINK_CONFIRM_PAYMENT_ERROR,
    payload: { loading: false, confirmPaymentError: error }
});

export const fetchPayByLink = (
    uuid: string
): ThunkAction<void, RootState, unknown, any> => {
    return async dispatch => {
        dispatch(fetchPayByLinkStart());

        try {
            const url = `${getPaymentLinkUrl}${uuid}`;
            const response: AxiosResponse<PaymentLink> = await ApiClient.get(
                url
            );
            dispatch(fetchPayByLinkSuccess(response.data));
        } catch (error) {
            dispatch(fetchPayByLinkError('Error retrieving payment link.'));
        }
    };
};

export const saveTip = (
    invoiceId: number,
    marketplaceId: number,
    uuid: string,
    amount: string | null,
    percentage: string | null,
    token: string
): ThunkAction<void, RootState, unknown, any> => {
    return async dispatch => {
        dispatch(saveTipStart());

        try {
            const url = `${updateInvoiceUrl}${invoiceId}?marketplace_id=${marketplaceId}`;
            const response: AxiosResponse<InvoiceDto> = await ApiClient.patch(
                url,
                {
                    invoice: {
                        id: invoiceId,
                        tip_amount: amount,
                        tip_percentage: percentage
                    },
                    payment_link_uuid: uuid
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            dispatch(saveTipSuccess(response.data));
        } catch (error) {
            dispatch(saveTipError('Error saving tip.'));
        }
    };
};

export const getDropInPayByLinkSessionThunk = (
    marketplaceId: number,
    invoiceId: number,
    authToken: string
): ThunkAction<void, RootState, null, PayByLinkAction> => {
    return async dispatch => {
        dispatch(dropInSessionStart());
        try {
            const url = `${dropInUrl}?marketplace_id=${marketplaceId}&invoice_id=${invoiceId}`;

            const response: AxiosResponse<DropInDto> = await ApiClient.get(
                url,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                }
            );

            dispatch(dropInSessionSuccess(response.data));
        } catch (error) {
            dispatch(dropInSessionError('Error getting Drop In Session.'));
        }
    };
};

export const getDropInSessionThunk = (
    payment_link_uuid: string
): ThunkAction<void, RootState, null, PayByLinkAction> => {
    return async dispatch => {
        dispatch(dropInSessionStart());

        try {
            const url = `${dropInUrl}${payment_link_uuid}`;
            const response: AxiosResponse<DropInDto> = await ApiClient.get(url);

            dispatch(dropInSessionSuccess(response.data));
        } catch (apiError) {
            dispatch(dropInSessionError('Error getting Drop In Session.'));
        }
    };
};

export const lockInvoice = (
    bookingId: number,
    marketplaceId: number,
    sessionUuid: string,
    authToken: string
): ThunkAction<void, RootState, unknown, PayByLinkAction> => {
    return async dispatch => {
        dispatch(lockInvoiceStart());

        const lockUrl = `${base_url}/api/v1/booking/lock/${bookingId}?marketplace_id=${marketplaceId}`;

        try {
            const response = await fetch(lockUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Connection': 'keep-alive',
                    'Session-Uuid': sessionUuid,
                    'Authorization': `Bearer ${authToken}`
                },
                body: ''
            });

            if (response.status === 423) {
                const data = await response.json();
                const errorMessage = `This invoice is being updated by ${data.user.first_name} ${data.user.last_name} or you already opened this invoice in another tab/browser.`;
                dispatch(lockInvoiceError(errorMessage));
            } else if (!response.ok) {
                throw new Error('Failed to lock the invoice.');
            } else {
                dispatch(lockInvoiceSuccess());
            }
        } catch (error) {
            dispatch(lockInvoiceError('Error locking the invoice.'));
        }
    };
};

export const unlockInvoice = (
    bookingId: number,
    marketplaceId: number,
    sessionUuid: string,
    authToken: string
): ThunkAction<void, RootState, unknown, PayByLinkAction> => {
    return dispatch => {
        dispatch(unlockInvoiceStart());

        const unlockUrl = `${base_url}/api/v1/booking/unlock/${bookingId}?marketplace_id=${marketplaceId}`;

        fetch(unlockUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Connection': 'keep-alive',
                'Session-Uuid': sessionUuid,
                'Authorization': `Bearer ${authToken}`
            },
            body: '',
            keepalive: true // Permite que la solicitud se complete incluso si la página se cierra
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log('Unlock successful:', data);
                dispatch(unlockInvoiceSuccess());
            })
            .catch(error => {
                console.error('Error during unlock:', error);
                dispatch(unlockInvoiceError('Error unlocking the invoice.'));
            });
    };
};

export const unlockBooking = (
    base_url: string,
    token: string,
    sessionUuid: string,
    bookingId: number,
    marketplaceId: number
) => {
    fetch(
        `${base_url}/api/v1/booking/unlock/${bookingId}?marketplace_id=${marketplaceId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Connection': 'keep-alive',
                'Session-Uuid': sessionUuid,
                'Authorization': `Bearer ${token}`
            },
            body: '',
            keepalive: true // Permite que la solicitud se complete incluso si la página se cierra
        }
    )
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Unlock successful:', data);
        })
        .catch(error => {
            console.error('Error during unlock:', error);
        });
};

// ... existing code ...

export const completeTransactionStart = (): PayByLinkAction => ({
    type: 'COMPLETE_TRANSACTION_START',
    payload: { loading: true }
});

export const completeTransactionSuccess = (): PayByLinkAction => ({
    type: 'COMPLETE_TRANSACTION_SUCCESS',
    payload: { loading: false }
});

export const completeTransactionError = (error: string): PayByLinkAction => ({
    type: 'COMPLETE_TRANSACTION_ERROR',
    payload: { loading: false, completeTransactionError: error }
});

export const completeTransaction = (
    invoiceId: number,
    marketplaceId: number,
    sessionUuid: string,
    authToken: string,
    customerEmail: string
): ThunkAction<void, RootState, unknown, PayByLinkAction> => {
    return async dispatch => {
        dispatch(completeTransactionStart());

        const completeTransactionUrl = `${base_url}/api/v1/complete_transaction/${invoiceId}?marketplace_id=${marketplaceId}`;

        try {
            const response = await fetch(completeTransactionUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Session-Uuid': sessionUuid
                },
                body: JSON.stringify({
                    invoice: {
                        send_invoice: true,
                        send_report: true,
                        customer_email: customerEmail
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Failed to complete the transaction.');
            }

            dispatch(completeTransactionSuccess());
        } catch (error) {
            dispatch(
                completeTransactionError('Error completing the transaction.')
            );
        }
    };
};

export const confirmPayment = (
    invoiceId: number,
    marketplaceId: number,
    sessionUuid: string,
    authToken: string,
    pspSessionId: string
): ThunkAction<void, RootState, unknown, PayByLinkAction> => {
    return async dispatch => {
        dispatch(confirmPaymentStart());

        const confirmPaymentUrl = `${base_url}/api/v1/confirm_payment/${invoiceId}?marketplace_id=${marketplaceId}`;

        try {
            const response = await fetch(confirmPaymentUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Session-Uuid': sessionUuid
                },
                body: JSON.stringify({
                    invoice: {
                        payment_type: 'credit_card'
                    },
                    psp_session_id: pspSessionId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to confirm the payment.');
            }

            dispatch(confirmPaymentSuccess());
        } catch (error) {
            dispatch(confirmPaymentError('Error confirming the payment.'));
        }
    };
};
