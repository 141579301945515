import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import AddOnService from '@spike/addon-service-model';
import {
	InvoiceDiscount as InvoiceDiscountModel,
	InvoiceLine,
	InvoiceLineType
} from '@spike/invoice-model';
import { Product } from '@spike/product-model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { Discount } from './Discount';
import { ItemType } from './ItemType';
import { Quantity } from './Quantity';
import { MarketplaceTax } from '@spike/marketplace-model';
import { ItemPrice } from './ItemPrice';
import { ItemSelector } from './ItemSelector';
import { ItemSubtotal } from './ItemSubtotal';
import { ItemTaxes } from './ItemTaxes';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

interface ItemsRowProps {
	invoiceLine: InvoiceLine;
	calculating?: boolean;
	invoiceAddOnServiceIds?: Array<number>;
	invoiceProductIds?: Array<number>;
	errors?: Array<FieldError>;
	className?: string;
	onClick?: () => void;
	onChange: (invoiceLine: InvoiceLine) => void;
	onRemove: (uuid: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
		gridContainer: {
			width: '100%',
			borderRadius: 16,

			[theme.breakpoints.down('md')]: {
				padding: 16,
				border: 'solid 1px #D4D4D4'
			},
			[theme.breakpoints.up('lg')]: {
				alignItems: 'center',
				boxShadow:
					'0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
			}
		},
		gridCellName: {
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(19)}px 28px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '19px 28px'
			}
		},
		cellCenter: {
			textAlign: 'center'
		},
		closeIcon: {
			cursor: 'pointer',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(24)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '24px'
			}
		},
		spinner: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '15px'
			}
		},
		errorBackground: {
			backgroundColor: 'rgba(250,0,0,0.15)'
		},

		field: {
			width: 150,

			[theme.breakpoints.down('md')]: {
				'& input': {
					height: 36,
					paddingTop: 0,
					paddingBottom: 0
				},
				'& input, & .MuiTypography-root': {
					fontSize: 14,
					textAlign: 'left'
				},
				'& .MuiInputAdornment-positionStart': {
					paddingLeft: 0
				},
				'& .MuiOutlinedInput-notchedOutline': {
					top: -6
				}
			},
			[theme.breakpoints.up('lg')]: {
				width: 200
			}
		},
		individualFieldPrice: {
			width: 120
		},
		gridCenter: {
			display: 'flex',
			justifyContent: 'center'
		},

		boxCell: {
			width: '100%'
		},

		// mobile
		mobileGridHeader: {
			gap: 12,
			display: 'grid',
			paddingBottom: 16,
			borderBottom: 'solid 1px #D4D4D4'
		},
		mobilePrice: {
			fontSize: 15,
			lineHeight: 1,
			fontWeight: 500
		},
		mobileDescription: {
			fontSize: 15,
			lineHeight: 1.2,
			fontWeight: 500,
			display: 'block',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis'
		},
		mobileGridTwoColumns: {
			gap: 16,
			display: 'grid',
			alignItems: 'center',
			gridTemplateColumns: '1fr minmax(min-content, auto)'
		},
		mobileGridTable: {
			'gap': 4,
			'display': 'flex',
			'flexWrap': 'wrap',
			'paddingTop': 12,
			'paddingBottom': 12,

			'& > *': {
				width: '100%'
			}
		},
		mobileGridRow: {
			minHeight: 36
		},
		mobileGridTableLabel: {
			fontSize: 14,
			lineHeight: 1
		},
		mobileGridFooter: {
			paddingTop: 12,
			borderTop: 'solid 1px #D4D4D4'
		},
		mobileDeleteButton: {
			'gap': 4,
			'border': 0,
			'padding': 0,
			'color': '#EF4F57',
			'fontSize': 14,
			'lineHeight': 1,
			'fontWeight': 500,
			'paddingBottom': 7,
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': 'transparent',
			'borderBottom': 'solid 2px #EF4F57',

			'& svg': {
				fontSize: 14
			}
		}
	})
);

export const ItemsRow: FunctionComponent<ItemsRowProps> = props => {
	const classes = useStyles();

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	);

	const onChangeQuantityHandler = (quantity: number) => {
		props.onChange &&
			props.onChange({
				...props.invoiceLine,
				quantity
			});
	};

	const changeSelectorHandler = (
		type: InvoiceLineType,
		product?: Product,
		addonService?: AddOnService,
		description?: string
	) => {
		if (type === InvoiceLineType.Product) {
			props.onChange &&
				props.onChange({
					...props.invoiceLine,
					description: isEmpty(product?.businessProduct?.name)
						? product?.name
						: product?.businessProduct?.name,
					productId: product?.businessProduct!.id,
					subtotal:
						product?.businessProduct?.pricing.price?.toString() ||
						'0.00'
				});
		}

		if (type === InvoiceLineType.AddOnService) {
			props.onChange &&
				props.onChange({
					...props.invoiceLine,
					description: addonService?.name,
					addOnServiceId: addonService?.id,
					subtotal: addonService?.price.toString() || '0.00'
				});
		}

		if (type === InvoiceLineType.Custom) {
			props.onChange &&
				props.onChange({
					...props.invoiceLine,
					description
				});
		}
	};

	const changeSubtotalHandler = (subtotal: number | undefined) => {
		props.onChange &&
			props.onChange({
				...props.invoiceLine,
				subtotal: subtotal ? subtotal.toString() : '0.00'
			});
	};

	const changeDiscountHandler = (discount: InvoiceDiscountModel) => {
		props.onChange &&
			props.onChange({
				...props.invoiceLine,
				discount: { ...discount }
			});
	};

	const changeTaxHandler = (tax: MarketplaceTax | undefined) => {
		props.onChange &&
			props.onChange({
				...props.invoiceLine,
				taxes: tax === undefined ? [] : [{ ...tax }]
			});
	};

	const hasError =
		props.errors &&
		props.errors.some(
			error => error.fieldName === `${props.invoiceLine.uuid}_duplicated`
		);

	const invoiceLineType = props.invoiceLine.isAddOnService
		? InvoiceLineType.AddOnService
		: props.invoiceLine.isProduct
		? InvoiceLineType.Product
		: InvoiceLineType.Custom;

	return (
		<>
			{isMobile && (
				<>
					<Box
						className={clsx(classes.gridContainer, props.className)}
					>
						<Box className={classes.mobileGridHeader}>
							<Box className={classes.mobileGridTwoColumns}>
								{((props.invoiceLine.isProduct &&
									props.invoiceLine.productId) ||
									(props.invoiceLine.isAddOnService &&
										props.invoiceLine.addOnServiceId)) && (
									<Typography
										className={classes.mobileDescription}
									>
										{props.invoiceLine.description}
									</Typography>
								)}

								<ItemSelector
									errors={props.errors}
									invoiceLine={props.invoiceLine}
									invoiceLineType={invoiceLineType}
									invoiceProductIds={props.invoiceProductIds}
									invoiceAddOnServiceIds={
										props.invoiceAddOnServiceIds
									}
									onChange={changeSelectorHandler}
								/>

								<ItemPrice
									errors={props.errors}
									className={classes.mobilePrice}
									inputClassName={clsx(classes.field, {
										[classes.individualFieldPrice]:
											invoiceLineType ===
											InvoiceLineType.Custom
									})}
									calculating={props.calculating}
									invoiceLine={props.invoiceLine}
									invoiceLineType={invoiceLineType}
									onChange={changeSubtotalHandler}
								/>
							</Box>

							<Box className={classes.mobileGridTwoColumns}>
								<ItemType type={invoiceLineType} />

								<Quantity
									value={props.invoiceLine.quantity}
									onChange={onChangeQuantityHandler}
								/>
							</Box>
						</Box>

						<Box className={classes.mobileGridTable}>
							{invoiceLineType !== InvoiceLineType.Custom && (
								<Box
									className={clsx(
										classes.mobileGridTwoColumns,
										classes.mobileGridRow
									)}
								>
									<Typography
										className={classes.mobileGridTableLabel}
									>
										Discount
									</Typography>

									<Discount
										discount={props.invoiceLine?.discount}
										name={`${props.invoiceLine.uuid}_discount`}
										errors={props.errors}
										onChange={changeDiscountHandler}
									/>
								</Box>
							)}
							<Box
								className={clsx(
									classes.mobileGridTwoColumns,
									classes.mobileGridRow
								)}
							>
								<Typography
									className={classes.mobileGridTableLabel}
								>
									Tax
								</Typography>

								<ItemTaxes
									invoiceLine={props.invoiceLine}
									invoiceLineType={invoiceLineType}
									inputClassName={classes.field}
									onChange={changeTaxHandler}
								/>
							</Box>
							<Box
								className={clsx(
									classes.mobileGridTwoColumns,
									classes.mobileGridRow
								)}
							>
								<Typography
									className={classes.mobileGridTableLabel}
									style={{ fontWeight: '600' }}
								>
									Total
								</Typography>

								<span style={{ fontWeight: '600' }}>
									<ItemSubtotal
										errors={props.errors}
										calculating={props.calculating}
										invoiceLine={props.invoiceLine}
										invoiceLineType={invoiceLineType}
										inputClassName={classes.field}
										onChange={changeSubtotalHandler}
									/>
								</span>
							</Box>
						</Box>

						<Box className={classes.mobileGridFooter}>
							<button
								type="button"
								aria-label="Remove item"
								className={classes.mobileDeleteButton}
								onClick={() =>
									props.onRemove(props.invoiceLine.uuid)
								}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
								Delete
							</button>
						</Box>
					</Box>
				</>
			)}

			{!isMobile && (
				<Grid
					container
					key={props.invoiceLine.uuid}
					className={clsx(
						classes.gridContainer,
						{ [classes.errorBackground]: hasError },
						props.className
					)}
				>
					<Grid item md={3} className={classes.gridCellName}>
						{((props.invoiceLine.isProduct &&
							props.invoiceLine.productId) ||
							(props.invoiceLine.isAddOnService &&
								props.invoiceLine.addOnServiceId)) && (
							<Typography>
								{props.invoiceLine.description}
							</Typography>
						)}

						<ItemSelector
							errors={props.errors}
							invoiceLine={props.invoiceLine}
							invoiceLineType={invoiceLineType}
							invoiceProductIds={props.invoiceProductIds}
							invoiceAddOnServiceIds={
								props.invoiceAddOnServiceIds
							}
							onChange={changeSelectorHandler}
						/>
					</Grid>
					<Grid item xs={1} className={classes.gridCenter}>
						<ItemType type={invoiceLineType} />
					</Grid>
					<Grid item xs={1} className={classes.gridCenter}>
						<Quantity
							value={props.invoiceLine.quantity}
							onChange={onChangeQuantityHandler}
						/>
					</Grid>
					<Grid item xs={2} className={classes.gridCenter}>
						{invoiceLineType !== InvoiceLineType.Custom && (
							<Discount
								discount={props.invoiceLine?.discount}
								name={`${props.invoiceLine.uuid}_discount`}
								errors={props.errors}
								onChange={changeDiscountHandler}
							/>
						)}
					</Grid>
					<Grid item xs={2} className={classes.gridCenter}>
						<ItemTaxes
							invoiceLine={props.invoiceLine}
							invoiceLineType={invoiceLineType}
							onChange={changeTaxHandler}
						/>
					</Grid>
					<Grid item xs={2} className={classes.gridCenter}>
						<ItemSubtotal
							errors={props.errors}
							invoiceLine={props.invoiceLine}
							invoiceLineType={invoiceLineType}
							onChange={changeSubtotalHandler}
						/>
					</Grid>
					<Grid item xs={1} className={classes.gridCenter}>
						<Box>
							<FontAwesomeIcon
								icon={faTimes}
								className={classes.closeIcon}
								onClick={() =>
									props.onRemove(props.invoiceLine.uuid)
								}
							/>
						</Box>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default ItemsRow;
