import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "components/UI/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import { Box, DialogTitle, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

export interface Props {
  title: string;
  question: string;
  onDiscard?: () => void;
  onSave?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        border: "1px solid #D3D3D3",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
          margin: 0
        },
        [theme.breakpoints.up('md')]: {
          width: 464,
          height: 257,
          borderRadius: 30
        },
      },
      "& .MuiDialogTitle-root": {
        padding: 13,
        paddingBottom: "4px !important",
        [theme.breakpoints.up('md')]: {
          padding: 15,
          paddingBottom: "5px !important"
        },
      },    
      "& .MuiDialogContent-root": {
        paddingTop: "0px !important",
        [theme.breakpoints.down('sm')]: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        },
        [theme.breakpoints.up('md')]: {
          padding: "15px",
        },
      },
      "& .MuiDialogActions-root": {
        width: "100%",
        justifyContent: "center",
        paddingBottom: 13,
        paddingTop: 13,
        [theme.breakpoints.up('md')]: {
          borderTop: "1px solid #D3D3D3",
          paddingBottom: 15,
          paddingTop: 15
        },
      },
    },
    button: {
      fontWeight: 600,
      width: "40%",
      height: 49,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        borderRadius: 30
      },
    },
    buttonNo: {
      marginRight: 11,
      [theme.breakpoints.up('md')]: {
        marginRight: 12
      },
    },
    titleContainer: {
      display: "flex",
      justifyContent: "flex-end"
    },
    confirmCloseQuestionTitle: {
      fontWeight: 600,
      textAlign: "center",
      fontSize: 20,
      lineheight: "24px",
      paddingBottom: 9,
      [theme.breakpoints.up('md')]: {
        paddingBottom: 10
      },
    },
    confirmCloseQuestion: {
      textAlign: "center",
      fontSize: 16
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    icon: {
      cursor: "pointer",
      fontSize: 20
    }
  })
);

export const ConfirmClosePopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const discardHandler = () => {
    props.onDiscard && props.onDiscard();
  };

  const saveHandler = () => {
    props.onSave && props.onSave();
  };

  return (
    <Dialog onClose={discardHandler} open={true} className={classes.dialog}>
      <DialogTitle className={classes.titleContainer}>
        <FontAwesomeIcon 
          id="booking_button_close"
          icon={faTimes} 
          className={classes.icon}
          onClick={discardHandler}
        />
      </DialogTitle>
      <DialogContent>
        <Box className={classes.textContainer}>
            <Typography className={classes.confirmCloseQuestionTitle}>{props.title}</Typography>
            <Typography className={classes.confirmCloseQuestion}>{props.question}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.buttonsContainer}>
          <Button
            id="booking_button_no"
            onClick={discardHandler}
            color="black"
            className={clsx(classes.button, classes.buttonNo)}
            label="No"
          />
          <Button
            id="booking_button_yes"
            onClick={saveHandler}
            variant="danger"
            className={classes.button}
            label="Yes, exit"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmClosePopup;
