import { Fragment, FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BodySection } from "components/UI/Section";
import Section from "components/Products/UI/Section";
import { Product, VendorCost } from "@spike/product-model";
import { Typography } from "@material-ui/core";
import VendorRow from "./UI/VendorRow";
import { v4 as createUuid } from "uuid";
import { clone } from "lodash";

interface Props {
  product: Product;
  onChange: (product: Product) => void;
  onWithoutVendor: () => void;
  onShowVendor: (vendorId: number) => void;
  onEdit: () => void;
  editable: boolean;
  collapsed: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        lineHeight: "19px",
        marginBottom: "8px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: "11px"
      },
    },
    subtitle: {
      fontWeight: 400,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "19px",
        marginBottom: "40px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "50px"
      },
    },
    vendorsContainer: {
      display: "flex",
      flexDirection: "column",
    },
    addVendor: {
      cursor: "pointer",
      color: "#5E8677",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        marginTop: "13px",
        marginBottom: "13px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        marginTop: "16px",
        marginBottom: "16px"
      },
  }
  })
);

export const VendorsAndCost: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const createEmptyVendor = () => {
      return {
        default: false,
        cost: 0,
        uuid: createUuid()
      };
  };

  const handleAddVendor = () => {
    props.onChange({...props.product, vendors: [...props.product.vendors, createEmptyVendor()]});
  };

  const handleRemoveVendor = (uuid: string) => {
    const updatedVendors = props.product.vendors.filter(vendor => vendor.uuid !== uuid);

    if(updatedVendors.length > 0 && !updatedVendors.some(vendor => vendor.default)) {
      updatedVendors[0].default = true;
    }

    props.onChange({...props.product, vendors: updatedVendors});
  };

  const handleChangeVendor = (changedVendor : VendorCost) => {
    props.onChange({...props.product, vendors: props.product.vendors.map(vendor => vendor.uuid === changedVendor.uuid ? clone(changedVendor): vendor)});
  };

  const handleChangeDefaultVendor = (uuid : string) => {
    props.onChange({...props.product, vendors: props.product.vendors.map(vendor => ({...vendor, default: vendor.uuid === uuid}))});
  };

  return (
    <Fragment>
      <Section position={2} title="Vendors & Unit Cost" {...props} onEdit={() => props.onEdit()}>
        <BodySection overflow={true}>
          <Box className={classes.container}>
            <Typography className={classes.title}>Vendors & Unit Cost</Typography>
            <Typography className={classes.subtitle}>Default vendors will show on the product dashboard.</Typography>
            <Box className={classes.vendorsContainer}>
              {props.product.vendors.map((vc) => {
                return (<VendorRow 
                  key={vc.uuid}
                  product={props.product}
                  vendorCost={vc}
                  onChange={handleChangeVendor}
                  onRemove={handleRemoveVendor}
                  onShowVendor={props.onShowVendor}
                  onSetAsDefault={handleChangeDefaultVendor}
                />)
              })}
              <Typography className={classes.addVendor} onClick={handleAddVendor}>+ Add another vendor</Typography>
            </Box>
          </Box>
        </BodySection>
      </Section>
    </Fragment>
  );
};

export default VendorsAndCost;
