import Marketplace from '@spike/marketplace-model';

export const isMarketingEnabled = (marketplaceId: number | undefined): boolean => {
  const marketingEnabled = process.env.REACT_APP_MARKETING_ENABLED?.toLowerCase() === 'true';
  const enabledMarketplaceIds = (process.env.REACT_APP_MARKETING_MARKETPLACE_IDS || '')
    .split(',')
    .map((id) => id.replaceAll(/\D/g, ''))
    .filter((id) => id !== '')
    .map((id) => Number(id));

  return (
    marketplaceId !== undefined &&
    marketingEnabled &&
    (enabledMarketplaceIds.length === 0 || enabledMarketplaceIds.includes(marketplaceId))
  );
};

export const isPaymentsEnabled = (marketplace: Marketplace): boolean => {
  return (['US'].includes(marketplace.basics.address.country?.id.toUpperCase() ?? '') || ['CA'].includes(marketplace.basics.address.country?.id.toUpperCase() ?? ''));
};
