import { faBan, faCheck, faUser } from "@fortawesome/pro-light-svg-icons";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PriceField2 } from "components/UI";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";
import { Product, Vendor, VendorCost } from "@spike/product-model";
import ProductVendor from "model/ProductVendor";
import { FunctionComponent, useState } from "react";
import ProductVendorComponent from "../../Details/ProductVendorComponent";
import AddVendorDialog from "./AddVendorDialog";

interface Props {
  product: Product;
  vendorCost: VendorCost;
  onChange?: (vendor: VendorCost) => void;
  onRemove?: (uuid: string) => void;
  onSetAsDefault?: (uuid: string) => void;
  onShowVendor?: (vendorId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vendorRowContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    vendorsRowContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "auto",
      [theme.breakpoints.down("md")]: {
        minHeight: "36px !important",
        marginBottom: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: "45px !important",
        marginBottom: "12px",
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
    marginCells: {
      [theme.breakpoints.down("md")]: {
        marginRight: "32px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "40px",
      },
    },
    cellVendorContainer: {
      width: "50%",
    },
    cellCostContainer: {
      width: "42%",
    },
    cellActionContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "8%",
      marginTop: "18px",
    },
  })
);

export const VendorRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showAddVendor, setShowAddVendor] = useState(false);

  const handleChangeVendor = (vendor: Vendor) => {
    props.onChange && props.onChange({ ...props.vendorCost, vendor });
  };

  const handleChangeCost = (cost: number | undefined) => {
    props.onChange && props.onChange({ ...props.vendorCost, cost: cost || 0 });
  };

  const handleAddVendor = (vendorAdd: ProductVendor) => {};

  return (
    <Box className={classes.vendorRowContainer}>
      <Box className={classes.vendorsRowContainer}>
        <Box className={clsx(classes.cellVendorContainer, classes.marginCells)}>
          <ProductVendorComponent
            product={props.product}
            vendor={props.vendorCost.vendor}
            onChange={handleChangeVendor}
            onAdd={() => setShowAddVendor(true)}
            default={props.vendorCost.default}
          />
        </Box>
        <Box className={clsx(classes.cellCostContainer, classes.marginCells)}>
          <PriceField2
            label="Cost"
            name="cost"
            value={props.vendorCost.cost !== 0 ? props.vendorCost.cost : undefined}
            placeholder="0.00"
            onChange={handleChangeCost}
            scale={2}
          />
        </Box>
        <Box className={clsx(classes.cellActionContainer)}>
          <ToolbarButton>
            {props.vendorCost && props.vendorCost.vendor && props.vendorCost.vendor.id ? (
              <ToolbarItem
                icon={faCheck}
                onClick={() => props.onSetAsDefault && props.onSetAsDefault(props.vendorCost.uuid)}
                text={"Set as Default"}
              />
            ) : undefined}
            {props.vendorCost && props.vendorCost.vendor && props.vendorCost.vendor.id ? (
              <ToolbarItem
                icon={faUser}
                onClick={() => props.onShowVendor && props.onShowVendor(props.vendorCost.vendor!.id)}
                text={"See Vendor"}
              />
            ) : undefined}
            <ToolbarItem
              icon={faBan}
              onClick={() => props.onRemove && props.onRemove(props.vendorCost.uuid)}
              text={"Remove Vendor"}
            />
          </ToolbarButton>
        </Box>
      </Box>
      {showAddVendor && <AddVendorDialog onAddVendor={handleAddVendor} onClose={() => setShowAddVendor(false)} />}
    </Box>
  );
};

export default VendorRow;
