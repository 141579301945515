import { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Option, PetType } from '@spike/model';
import { MarketplaceDepositType } from '@spike/marketplace-model';
import { v4 as uuidv4 } from 'uuid';

import { VariablePriceOption } from 'model/Service';
import { PriceError } from '../../model';

export interface PriceChangeParams {
    price: number | undefined;
    size: Option<string>;
    selectedPetType: PetType;
    hairLength?: Option<string>;
    uuid?: string;
}

export const usePriceManagement = (
    initialPrices: Array<VariablePriceOption>,
    deposit: { type: MarketplaceDepositType; value: string },
    initialErrors: Array<PriceError>,
    selectedPricingOption: Option<string>,
    showErrors: boolean
) => {
    const [variablePrices, setVariablePrices] = useState<Array<VariablePriceOption>>(() => {
        return initialPrices.filter(
            price =>
                price.petType?.id &&
                (price.petSize?.id || !price.petSize) &&
                (price.hairLength?.id || !price.hairLength)
        );
    });
    const [errors, setErrors] = useState<Array<PriceError>>(initialErrors);
    const [showAlert, setShowAlert] = useState<Array<VariablePriceOption>>([]);

    useEffect(() => {
        setErrors([]);
        setShowAlert([]);
    }, [selectedPricingOption]);

    useEffect(() => {
        if (showErrors) {
            setErrors(initialErrors);
            checkPriceAlerts();
        }
    }, [showErrors, initialErrors]);

    const handlePriceChange = useCallback(
        ({ price, size, selectedPetType, hairLength, uuid }: PriceChangeParams) => {
            const updatedPrices = [...variablePrices];
            const existingPriceIndex = updatedPrices.findIndex(
                existingPrice =>
                    isEqual(existingPrice.petSize?.id, size.id) &&
                    isEqual(existingPrice.petType?.id, selectedPetType.id) &&
                    isEqual(existingPrice.hairLength?.id, hairLength?.id)
            );

            const currentFieldUuid = uuid || updatedPrices[existingPriceIndex]?.uuid;
            if (currentFieldUuid) {
                setErrors(prevErrors => prevErrors.filter(error => !isEqual(error.priceUuid, currentFieldUuid)));
            }

            if (existingPriceIndex === -1) {
                const newPrice: VariablePriceOption = {
                    uuid: uuid || uuidv4(),
                    petType: selectedPetType,
                    petSize: size,
                    hairLength: hairLength,
                    price: price
                };
                updatedPrices.push(newPrice);
            } else {
                updatedPrices[existingPriceIndex] = {
                    ...updatedPrices[existingPriceIndex],
                    price: price
                };
            }

            setVariablePrices(updatedPrices);
        },
        [variablePrices]
    );

    const checkPriceAlerts = useCallback(() => {
        if (deposit.type === MarketplaceDepositType.Amount) {
            const alertPrices = variablePrices.filter(
                price => price.price !== undefined && price.price !== 0 && price.price < Number(deposit.value)
            );
            setShowAlert(alertPrices);
        }
    }, [variablePrices, deposit]);

    return {
        variablePrices,
        errors,
        showAlert,
        setErrors,
        setVariablePrices,
        setShowAlert,
        handlePriceChange,
        checkPriceAlerts
    };
};
