import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { SearchComponent } from 'components/UI';
import { FunctionComponent } from 'react';

interface SearchProps {
	onSearch: (serachText: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
		box: {
			borderRadius: 12,
			padding: '15px 20px',
			backgroundColor: '#F1F1F1'
		},
		field: {
			'maxWidth': 400,
			'minWidth': 250,
			'height': 47,
			'display': 'flex',
			'alignItems': 'center',
			'& .MuiInputBase': {
				'&-root': {
					paddingLeft: 17,
					backgroundColor: '#fff',
					color: '#black !important'
				},
				'&-input': {
					opacity: 1,
					color: '#black !important',
					fontSize: 16,
					[theme.breakpoints.down(768)]: {
						fontSize: 14,
						paddingTop: 21
					}
				}
			},
			'& .MuiInputAdornment-root': {
				'pointer-events': 'none',
				'& svg': {
					heigth: 14,
					width: 14,
					cursor: 'default',
					color: '#7A7A7A',
					[theme.breakpoints.between(768, 'sm')]: {
						color: 'black',
						heigth: 17,
						width: 17
					},
					[theme.breakpoints.down('lg')]: {
						color: 'black',
						heigth: 17,
						width: 17
					}
				}
			},
			'& .MuiInputBase-input': {
				color: 'black'
			}
		}
	})
);

const Search: FunctionComponent<SearchProps> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.box}>
				<SearchComponent
					open
					onSearch={props.onSearch}
					className={classes.field}
					placeholder="Search by agreement name"
				/>
			</Box>
		</Box>
	);
};

export default Search;
