import React, { FunctionComponent, useState, useEffect } from 'react';
import { Radio as MUIRadio, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface Props {
	id?: string;
	label: string;
	checked: boolean;
	fontSize?: string;
	fontColor?: string;
	onChange: (checked: boolean) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: (props: Props) => ({
			fontColor: props.fontColor,
			fontSize: props.fontSize || 16
		}),
		radio: {
			'&.Mui-checked': {
				color: '#222222'
			}
		}
	})
);

export const Radio: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const [checked, setChecked] = useState(props.checked);

	useEffect(() => {
		setChecked(props.checked);
	}, [props.checked]);

	const label = (
		<Typography className={classes.label}>{props.label}</Typography>
	);

	const onChangeHandler = (checked: boolean): void => {
		props.onChange(checked);
		setChecked(checked);
	};

	return (
		<FormControlLabel
			control={
				<MUIRadio
					id={props.id}
					color="primary"
					disableRipple={true}
					disableFocusRipple={true}
					disableTouchRipple={true}
					className={classes.radio}
				/>
			}
			label={label}
			checked={checked}
			labelPlacement="end"
			onChange={(event, checked) => {
				onChangeHandler(checked);
			}}
			className={props.className}
		/>
	);
};

export default Radio;
