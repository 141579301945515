import React, { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import moment, { Moment } from "moment";
import { MonthSelect, YearField, DayField } from "components/UI";
import { FieldError } from "@spike/model";

interface Props {
  date?: Moment;
  minYear?: number;
  maxYear?: number;
  onChange: (date: Moment | undefined) => void;
  className?: string;
  errors: Array<FieldError>;
  handleMonthSelected?(month: Number): void | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      justifyItems: "space-between",
      alignItems: "center",
    },
    monthContainer: {
      display: "flex",
      width: "50%",
      [theme.breakpoints.only("xs")]: {
        paddingRight: "4px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingRight: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: "14px",
      },
    },
    dayContainer: {
      display: "flex",
      width: "25%",
      [theme.breakpoints.only("xs")]: {
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "7px",
        paddingRight: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "14px",
        paddingRight: "14px",
      },
    },
    yearContainer: {
      display: "flex",
      width: "25%",
      [theme.breakpoints.only("xs")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "7px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "14px",
      },
    },
  })
);

export const Date: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [month, setMonth] = useState<number | undefined>(
    props.date ? parseInt(props.date.format("MM")) : undefined
  );
  const [day, setDay] = useState<number | undefined>(
    props.date ? parseInt(props.date.format("DD")) : undefined
  );
  const [year, setYear] = useState<number | undefined>(
    props.date ? parseInt(props.date.format("YYYY")) : undefined
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    const empty = (!year && !month && !day);
    const valid = empty
      || moment([year || 0, (month || 0) - 1, day || 0]).isValid();

    if(month){
      props.handleMonthSelected && props.handleMonthSelected(month);
    }
    
    if (valid && !empty) {
      props.onChange(moment([year!, month! - 1, day!]).startOf("day"));
      setError(false);
    } else {
      props.onChange(undefined);
    }
  }, [day, month, year]);

  useEffect(() => {
    props.errors.forEach((err) => {
      if (err.fieldName === "birthdate") {
        setError(true);
      } else {
        setError(false);
      }
    })
  }, [props.errors]);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.monthContainer}>
        <MonthSelect
          label=" "
          value={month}
          onChange={setMonth}
          error={error}
          errorMessage={"Invalid Date."}
        />
      </Box>
      <Box className={classes.dayContainer}>
        <DayField {...props} label="Day" value={day} onChange={setDay} error={error} />
      </Box>
      <Box className={classes.yearContainer}>
        <YearField {...props} label="Year" value={year} onChange={setYear} error={error} />
      </Box>
    </Box>
  );
};

export default Date;
