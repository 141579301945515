import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { Button } from 'components/UI';
import OptionsButton from 'components/UI/OptionsButton';
import {
	Invoice,
	InvoiceLine as InvoiceLineModel,
	InvoiceLineType
} from '@spike/invoice-model';
import { Fragment, FunctionComponent, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { ItemsTable } from './Items/ItemsTable';
import SingleSaleTotals from './SingleSaleTotals';
import { Option } from '@spike/model';
import { Product } from '@spike/product-model';
import { AddOnService } from '@spike/addon-service-model';

interface SingleSaleItemsProps {
	invoice: Invoice;
	calculating?: boolean;
	saving?: boolean;
	errors?: Array<FieldError>;
	addOnServices: Array<AddOnService>;
	products: Array<Product>;
	className?: string;
	onChange?: (invoice: Invoice) => void;
	onPay?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			paddingTop: 24,
			paddingBottom: 24,

			[theme.breakpoints.up('md')]: {
				paddingTop: '45px',
				paddingBottom: '45px'
			}
		},
		boxTitle: {
			paddingBottom: 16,

			[theme.breakpoints.up('md')]: {
				paddingBottom: '45px'
			}
		},
		title: {
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: 8
		},
		subtitle: {
			fontSize: 14,
			lineHeight: 1.4,

			[theme.breakpoints.up('md')]: {
				fontSize: 16
			}
		},
		boxBottom: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-between'
		},
		boxButton: {
			[theme.breakpoints.down('sm')]: {
				flex: '0 0 100%'
			}
		},
		addLineButton: {
			'& button': {
				[theme.breakpoints.down('sm')]: {
					'height': 47,
					'padding': '0px 22px',

					'& .MuiButton-endIcon': {
						'marginLeft': 8,
						'marginRight': 0,

						'& svg': {
							width: 14,
							height: 14
						}
					}
				}
			}
		},
		boxPayment: {
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'column',
			paddingTop: 12,

			[theme.breakpoints.down('sm')]: {
				width: '100%'
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: 128
			}
		},

		payButton: {
			[theme.breakpoints.down('sm')]: {
				'height': 55,
				'marginTop': 8,
				'borderRadius': 30,

				'& span': {
					fontSize: 18,
					fontWeight: 600
				}
			},
			[theme.breakpoints.up('md')]: {
				marginTop: '30px'
			}
		}
	})
);

const createInvoiceLine = (type: InvoiceLineType) => {
	return {
		uuid: uuid(),
		isProduct: type === InvoiceLineType.Product,
		isAddOnService: type === InvoiceLineType.AddOnService,
		subtotal: '0.00',
		quantity: 1,
		discount: { amount: '0.00' },
		total: '0.00',
		taxesAmount: '0.00',
		taxes: []
	};
};

const lineTypeOptions: Array<Option<string>> = [];

const productObject = {
	id: InvoiceLineType.Product.toString(),
	name: 'Product'
};

const addOnObject = {
	id: InvoiceLineType.AddOnService.toString(),
	name: 'Add-on'
};

const customItemObject = {
	id: InvoiceLineType.Custom.toString(),
	name: 'Custom Item'
};

export const SingleSaleItems: FunctionComponent<
	SingleSaleItemsProps
> = props => {
	const classes = useStyles();

	useEffect(() => {
		if (
			props.products.length > 0 &&
			lineTypeOptions.indexOf(productObject) === -1
		) {
			lineTypeOptions.push(productObject);
		}
		if (
			props.addOnServices.length > 0 &&
			lineTypeOptions.indexOf(addOnObject) === -1
		) {
			lineTypeOptions.push(addOnObject);
		}
		if (lineTypeOptions.indexOf(customItemObject) === -1) {
			lineTypeOptions.push(customItemObject);
		}
	}, []);

	const addLineHandler = (type: InvoiceLineType) => {
		props.onChange &&
			props.onChange({
				...props.invoice,
				lines: [...props.invoice.lines, createInvoiceLine(type)]
			});
	};

	const changeHandler = (lines: Array<InvoiceLineModel>) => {
		props.onChange && props.onChange({ ...props.invoice, lines });
	};

	return (
		<Fragment>
			<Box className={clsx(classes.container, props.className)}>
				<Box className={classes.boxTitle}>
					<Typography className={classes.title}>Items</Typography>
					<Typography className={classes.subtitle}>
						Complete a sale by quickly adding a product, add-on, or
						custom item.
					</Typography>
				</Box>
				<Box>
					<ItemsTable
						invoiceLines={props.invoice.lines}
						calculating={props.calculating}
						errors={props.errors}
						onChange={changeHandler}
					/>
				</Box>

				<Box className={classes.boxBottom}>
					<Box className={classes.boxButton}>
						<OptionsButton
							options={lineTypeOptions}
							color="green"
							label="Add Item"
							className={classes.addLineButton}
							onClick={option =>
								addLineHandler(option.id as InvoiceLineType)
							}
						/>
					</Box>
					<Box className={classes.boxPayment}>
						<SingleSaleTotals
							invoice={props.invoice}
							calculating={props.calculating}
						/>
						<Button
							label="Pay"
							className={classes.payButton}
							loading={props.saving}
							onClick={props.onPay}
						/>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
};

export default SingleSaleItems;
