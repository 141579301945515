import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useAuth } from "hooks";
import React, { FunctionComponent } from "react";
import PrivatePage from "./PrivatePage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "block",
      justifyContent: "center",
      background: "white",
      height: "100%",
      width: "100%",
    },
    iframe: {
      width: "100%",
      height: "100%",
      border: "none",
    },
  })
);

export const Insights: FunctionComponent = () => {
  const classes = useStyles();

  const auth = useAuth();

  const insightsUrl = `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_WEB_BASE_URL}stats?access_token=${auth.token}&marketplace_id=${auth.marketplaceId}`;

  document.title = "Insights - Cuddles";

  return (
    <PrivatePage title="Insights - Cuddles" pageName="/insights">
      <Box className={classes.container}>
        <iframe title="Insights" className={classes.iframe} src={insightsUrl}></iframe>
      </Box>
    </PrivatePage>
  );
};

export default Insights;
