import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Section from '../UI/Section';
import { Box, Typography } from '@material-ui/core';
import { StaffSchedule } from 'model/Staff';
import StaffScheduleComponent from 'components/StaffSchedule';
import { createEmptyWeek, FieldError } from '@spike/model';
import clsx from 'clsx';
import { MobileDivider } from 'components/UI/MobileDivider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface Props {
	schedule?: StaffSchedule;
	staffId?: number;
	onChange: (schedule: StaffSchedule) => void;
	disabled?: boolean;
	className?: string;
	errors: Array<FieldError>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			height: '100%'
		},
		sectionTitle: {
			[theme.breakpoints.down('lg')]: {
				display: 'none'
			}
		},
		bodySection: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: '0px 16px',

			[theme.breakpoints.down('sm')]: {
				marginTop: -20
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: 33,
				paddingLeft: 33,
				paddingRight: 33,
				paddingBottom: 10
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: 44,
				paddingRight: 43,
				paddingBottom: 20
			}
		},
		rowDays: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			[theme.breakpoints.down('lg')]: {
				marginTop: '30px',
				paddingRight: '80px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '40px',
				paddingRight: '106px'
			}
		},
		rowTime: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			[theme.breakpoints.down('lg')]: {
				marginTop: '30px',
				paddingRight: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '40px',
				paddingRight: '25px'
			}
		},
		subtitle: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '11px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '14px'
			}
		},
		days: {
			display: 'flex',
			justifyContent: 'center',
			borderRadius: '50%',

			textAlign: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				width: '38px',
				height: '38px',
				marginRight: '7px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				width: '50px',
				height: '50px',
				marginRight: '9px'
			}
		},
		time: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			border: '1px solid #EFEFEF',
			outline: 'none',
			[theme.breakpoints.down('lg')]: {
				padding: '10px',
				borderRadius: '15px',
				width: '120px',
				height: '30px'
			},
			[theme.breakpoints.up('xl')]: {
				padding: '15px',
				borderRadius: '20px',
				width: '148px',
				height: '40px'
			}
		},
		staticText: {
			[theme.breakpoints.down('lg')]: {
				paddingTop: '3px',
				fontSize: '13px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '5px',
				fontSize: '18px'
			}
		},
		staticTextTimeGeneral: {
			[theme.breakpoints.down('lg')]: {
				paddingTop: '3px',
				paddingLeft: '8px',
				paddingRight: '8px',
				fontSize: '13px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '5px',
				paddingLeft: '12px',
				paddingRight: '12px',
				fontSize: '18px'
			}
		},
		staticTextTimeByDay: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '13px',
				paddingTop: '3px',
				marginLeft: '4px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px',
				paddingTop: '5px',
				marginLeft: '6px'
			}
		},
		week: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			[theme.breakpoints.down('lg')]: {
				marginTop: '30px',
				paddingRight: '45px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '40px',
				paddingRight: '61px'
			}
		},
		day: {
			[theme.breakpoints.down('lg')]: {
				width: '98px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '130px'
			}
		},
		switch: {
			marginTop: '6px'
		},
		generalTime: {
			display: 'flex',
			flexDirection: 'row'
		},
		generalTimeHidden: {
			display: 'flex',
			flexDirection: 'row',
			visibility: 'hidden'
		},
		disabled: {
			cursor: 'default',
			backgroundColor: '#f5f5f5',
			color: '#b7b7b7 !important'
		}
	})
);

export const Schedule: FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<MobileDivider />

			<Section title="Schedule" titleClassName={classes.sectionTitle}>
				<Box className={classes.bodySection}>
					<StaffScheduleComponent
						schedule={
							props.schedule || {
								default: createEmptyWeek(),
								customDays: []
							}
						}
						staffId={props.staffId}
						onChange={props.onChange}
						errors={props.errors}
					/>
				</Box>
			</Section>
		</Box>
	);
};

export default Schedule;
