import ActionCable, { Cable, Channel } from "actioncable";
import { setTwoWaySmsThunk } from "actions/twoWaySms/twoWaySmsActions";
import { useAuth, useHasPremiumAccess } from "hooks";
import { TwoWaySmsWebSocketsMessage } from "model/WebSockets";
import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { debugConsoleLog } from "utils/GeneralUtils";

const TwoWaySmsWebSockets: FunctionComponent = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const hasReportsPremiumAccess = useHasPremiumAccess('two_way_sms');

  useEffect(() => {

    let cable: Cable;
    let channel: Channel;


 debugConsoleLog({ "authData": auth, "signed": auth.signedIn, "premium": hasReportsPremiumAccess }) ;

    if (auth.signedIn && hasReportsPremiumAccess) {
      [cable, channel] = subscribe(auth.marketplaceId!);
    }

    return () => {
      unsubscribe(cable, channel);
    };

  }, [auth, hasReportsPremiumAccess]);



  const subscribe = (marketplaceId: number): [Cable, Channel] => {
    const cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
    );

    const channel = cable!.subscriptions.create(
      {
        channel: "MessagesChannel",
        marketplace_id: marketplaceId,
      },
      { received: (message: any) => messageHandler(message) }
    );

      debugConsoleLog({
        action: "TwoWaySmsWebSockets subscribed",
        channel,
      });
    

    return [cable, channel];
  };

  const unsubscribe = (cable: Cable, channel: Channel) => {
    cable && cable.disconnect();
    channel && channel.unsubscribe();

  };

  const messageHandler = (message: TwoWaySmsWebSocketsMessage) => {


    debugConsoleLog({
        action: "TwoWaySmsWebSockets message received",
        message,
      });
    


    if (message.sms_message) {
      dispatch(setTwoWaySmsThunk(message.sms_message, message.unread_count));
    }

  };
  return null;
};

export default TwoWaySmsWebSockets;
