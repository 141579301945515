import { Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import AlertLabel from "components/PetCard/UI/AlertLabel";
import { vaccinesAttention, vaccinesWarning } from "components/PetCard/utils/PetCardUtils";
import Pet from "@spike/pet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import VaccinesTable from "./VaccinesTable";

interface VaccinesProps {
  pet: Pet;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alerts: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
      [theme.breakpoints.down("md")]: {
        "& p": {
          fontSize: "14px",
          fontWeight: 400,
        }
      },
    },
    alertInfo: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        "& p": {
          fontSize: "14px",
          fontWeight: 400,
        }
      }
    }
  })
);

export const Vaccines: FunctionComponent<VaccinesProps> = (props) => {
  const classes = useStyles();

  const hasVaccines = props.pet.medicalHistory.vaccines.length > 0;

  const hasWarning =
    vaccinesAttention(props.pet.medicalHistory.vaccines) || vaccinesWarning(props.pet.medicalHistory.vaccines);

  return (
    <Box className={clsx(props.className)}>
      <Grid container>
        <Grid item xs={12}>
          {(!hasVaccines || (hasVaccines && hasWarning)) && (
            <Box className={classes.alerts}>
              {!hasVaccines && <AlertLabel className={classes.alertInfo} label="No vaccines added yet" type="info" />}
              {hasVaccines && hasWarning && (
                <AlertLabel className={classes.alertInfo} label={`${props.pet.name} has one or more vaccines pending review.`} type="attention" />
              )}
            </Box>
          )}
          <Box>{hasVaccines && <VaccinesTable vaccines={props.pet.medicalHistory.vaccines} />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Vaccines;
