import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

export interface DisplayFieldProps {
  label?: string | JSX.Element;
  value: string | number | undefined | null;
  className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    label: {
      fontWeight: 400,
      color: "#222222",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
        lineHeigth: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        lineHeigth: "22px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "16px",
        lineHeigth: "27px",
      },
    },
    value: {
      width: "100%",
      color: "#222222",
      fontFamily: "Poppins",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "22px",
      },
    },
  })
);

export const DisplayField: FunctionComponent<DisplayFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.label}>{props.label}</Typography>
      <Typography className={classes.value}>{props.value || ""}</Typography>
    </Box>);
};

export default DisplayField;
