import { Box, Grid, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { reduceResolution, wbp } from "Theme";
import clsx from "clsx";
import set from "lodash/set";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TerminalImage } from "../UI/TerminalImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TerminalOrder, TerminalModel, } from "@spike/terminal-model";
import {
  faInfoCircle,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";

interface OrderSelectTerminalsProps {
  order: TerminalOrder;
  modelsTerminal: Array<TerminalModel>;
  className?: string;
  //
  onChange: (order: TerminalOrder) => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typoTitle: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px 0 0 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px 0 0 0",
      },
    },

    typoTotal: {
    },

    typoProductInfo: {
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
      }
    },

    boxTerminal: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(25)}px`,
        marginTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "25px",
        marginTop: "20px",
      },
    },

    sectionIconInfo: {
      color: "#5E8677",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        marginRight: `${reduceResolution(4)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        marginRight: "4px",
      },
    },

    boxQty: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "20px",
      },
    },

    typoQty: {
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(17)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "17px",
      },
    },

    sectionIconQty: {
      display: "flex",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
      },
    },

    typoAmount: {
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(10)}px`,
        paddingRight: `${reduceResolution(10)}px`,
        width: `${reduceResolution(28)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "28px",
      },
    },

    boxProductInfo: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
      },
    },

    gridPrice: {
      textAlign: "right",
    }
  })
);

export const OrderSelectTerminals: FunctionComponent<OrderSelectTerminalsProps> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const productInfoHandler = () => {
    window.open(process.env.REACT_APP_TERMINAL_INFO_URL);
  };

  const reduceQtyHandler = (idModel: string) => {
    const updateOrder = { ...props.order };
    const orderModel = updateOrder.terminals.find((m) => m.id.toUpperCase() === idModel.toUpperCase());
    if (orderModel) {
      const updateOrderFilter = updateOrder.terminals.filter((m) => m.id.toUpperCase() !== idModel.toUpperCase());

      if (orderModel!.qty > 0) {
        orderModel!.qty = orderModel!.qty - 1;

        updateOrderFilter.push(orderModel!);
        set(updateOrder, "qtyModel", updateOrderFilter);
      }
    }

    props.onChange(updateOrder);
  }

  const addQtyHandler = (idModel: string) => {
    const updateOrder = { ...props.order };
    const orderModel = updateOrder.terminals.find((m) => m.id.toUpperCase() === idModel.toUpperCase());

    if (orderModel) {
      const updateOrderFilter = updateOrder.terminals.filter((m) => m.id!.toUpperCase() !== idModel.toUpperCase());
      orderModel!.qty = orderModel!.qty + 1;
      updateOrderFilter.push(orderModel!);

      set(updateOrder, "qtyModel", updateOrderFilter);
    }

    props.onChange(updateOrder);
  };

  const terminalView = (model: TerminalModel) => {
    let qty = props.order.terminals.find((m) => m.id.toUpperCase() === model.id.toUpperCase());

    return (
      <Box className={clsx(terminalCommonClasses.boxRadiusWhite, classes.boxTerminal)}>
        <Grid container>
          <Grid item xs={1}>
            <TerminalImage id={model.id} />
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={8}>
            <Typography className={terminalCommonClasses.typo15_400_orange}>{model.type}</Typography>
            <Typography className={terminalCommonClasses.typo16_600}>{model.name}</Typography>
            <Typography className={clsx(terminalCommonClasses.typo14_400)}>{model.description}</Typography>
            <Box className={clsx(terminalCommonClasses.flexRowCenter, classes.boxProductInfo)} onClick={productInfoHandler}>
              {iconSVG(faInfoCircle, `${classes.sectionIconInfo}`)}
              <Typography className={clsx(terminalCommonClasses.flexRow, terminalCommonClasses.typo12_500, classes.typoProductInfo)}>
                Product Info
              </Typography>

            </Box>
            <Box className={clsx(terminalCommonClasses.flexRowCenter, classes.boxQty)}>
              <Typography className={clsx(terminalCommonClasses.typo14_600, classes.typoQty)}>Qty</Typography>
              <Box onClick={() => reduceQtyHandler(model.id)}>{iconSVG(faMinusCircle, `${classes.sectionIconQty}`)}</Box>
              <Typography className={clsx(terminalCommonClasses.typo15_400, classes.typoAmount)}>{qty?.qty ? qty.qty : 0}</Typography>
              <Box onClick={() => addQtyHandler(model.id)}>{iconSVG(faPlusCircle, `${classes.sectionIconQty}`)}</Box>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.gridPrice}>
            <Typography className={clsx(terminalCommonClasses.typo16_600, classes.typoTotal)}>${model.price}</Typography>
          </Grid>
        </Grid>
      </Box>)
  };

  return (
    <Box>
      <Box className={classes.typoTitle}>
        <Typography className={clsx(terminalCommonClasses.typo20_600)}>Select Terminals</Typography>
        <Typography className={terminalCommonClasses.typo15_400}>We have two types of terminals, choose the one that best fits your business.</Typography>
      </Box>
      {
        props.modelsTerminal.map((model) => (terminalView(model)))
      }
    </Box >
  );
};

export default OrderSelectTerminals;
