export interface NotificationBellState {
  notificationAmount: number;
  status: NotificationBellStatus;
}

export enum NotificationBellStatus {
  Initial,
  Add,
  Remove,
  Set,
}
