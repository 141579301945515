import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { RootState } from "../store";
import { activateAccountThunk } from "../actions/activateAccount/ActivateAccountActions";
import { resetThunk as resetLoginThunk } from "../actions/login/LoginActions";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import { setNotificationBellAmount } from "actions/notificationBell/NotificationBellActions";
import { onboardingOpenModal } from "actions/onboardingSteps/OnboardingStepsActions";

interface Props {
  token: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    validating: {
      [theme.breakpoints.only("xs")]: {
        marginTop: "20px",
        fontSize: "14px",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "24px",
        fontSize: "16px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "30px",
        fontSize: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "36px",
        fontSize: "20px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "40px",
        fontSize: "22px",
      },
    },
  })
);

export const ActivateAccount: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const onboardingSteps = 6;
  useEffect(() => {
    dispatch(resetLoginThunk());
    dispatch(activateAccountThunk(props.token));
    dispatch(setNotificationBellAmount(onboardingSteps));
    dispatch(onboardingOpenModal());
    pageView("/activate_business");
  }, [dispatch, props.token]);

  document.title = "Activate Account - Cuddles";

  return (
    <Box className={classes.container}>
      <CircularProgress size={100} thickness={4} />
      <Typography className={classes.validating}>Validating...</Typography>
    </Box>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    ...ownProps,
    token: ownProps.match.params.token,
  };
};

export default connect(mapStateToProps)(ActivateAccount);
