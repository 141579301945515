import React, { FunctionComponent, DetailedHTMLProps, HTMLAttributes } from "react";
import { makeStyles, Theme } from "@material-ui/core";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    fontSize: 15,
    paddingTop: 6.5,
    paddingBottom: 6.5,
    lineHeight: 1.8,
    fontWeight: 500,
    borderRadius: 6,
    textAlign: "center",
    backgroundColor: "#EF4F57",
  },
}));

export const ErrorAlert: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <div {...props} className={classes.root}>
      {props.label || "Error"}
    </div>
  );
};

export default ErrorAlert;
