import React, { FunctionComponent, useEffect, useState, MouseEvent } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomPasswordInput from "../Forms/CustomPasswordInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { NewPasswordPath } from "routes/Paths";
import { resetPasswordThunk } from "actions/resetPassword/ResetPasswordActions";
import { FieldError } from "@spike/model";
import { validatePassword } from "@spike/validations";
import { showError }from "@spike/notifications-action";

interface Props {
  token: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    maxWidth: 260,
    margin: "0 auto 50px auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  column: {
    display: "flex",
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    marginLeft: "auto",
    minHeight: "calc(100vh - 142px)",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F8F5F1",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "55%",
      flexBasis: "55%",
      paddingTop: 0,
      paddingLeft: "7%",
      paddingRight: "9%",
      paddingBottom: 0,
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 120,
      paddingLeft: "7%",
      paddingRight: "13%",
    },
  },
  formColumn: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 16,
  },
}));

const initialFormValues = {
  password: "",
  confirmPassword: "",
};

export const RightColumn: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const success = useSelector<RootState, boolean>((state) => state.resetPassword.success);
  const loading = useSelector<RootState, boolean>((state) => state.resetPassword.loading);

  useEffect(() => {
    if (success) {
      history.push(NewPasswordPath);
    }
  }, [success, history]);

  const [errors, setErrors] = useState<Array<FieldError>>([]);

  const [values, setValues] = useState(initialFormValues);

  const validate = (fieldValues = values): Array<FieldError> => {
    const errors = validatePassword(fieldValues.password);

    if(fieldValues.password !== fieldValues.confirmPassword) {
      errors.push({fieldName: "confirmPassword", errorMessage: "Confirm password is not equals."});
    }

    return errors;
  };

  const handleInputValue = (event: any) => {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
    validate();
  };

  const handleFormSubmit = async (event: MouseEvent) => {
    event.preventDefault();

    const errors = validate(values);
    setErrors(errors);

    if (errors.length === 0) {
      dispatch(resetPasswordThunk(values.password, props.token));
    } else {
      errors.forEach(error => dispatch(showError(error.errorMessage)));
    }
  };

  return (
    <Grid item xs={12} className={classes.column}>
      <h2 className={classes.title}>Create your new password</h2>

      <Grid container spacing={2} justifyContent="center">
        <Grid className={classes.formColumn} item xs={12} sm={10}>
          <CustomPasswordInput
            label="New Password"
            name="password"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some(error => error.fieldName==="password")}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <CustomPasswordInput
            label="Confirm Password"
            name="confirmPassword"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some(error => error.fieldName==="confirmPassword")}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <PrimaryButton onClick={handleFormSubmit} loading={loading} label="Create Password" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightColumn;
