import { FieldError, Option } from "@spike/model";
import { SelectField } from "components/UI";
import { useMasterData } from "hooks";
import moment, { Moment } from "moment-timezone";
import { FunctionComponent } from "react";

interface AgeFieldProps {
  id?: string;
  className?: string;
  age: Option<string> | undefined;
  birthdate: Moment | undefined;
  name?: string;
  errors?: Array<FieldError>;
  disabled?: boolean;
  onChange?: (age: Option<string> | undefined, fieldName?: string) => void;
}

const getAgeOption = (birthdate: Moment | undefined, ages: Array<Option<string>>): Option<string> | undefined => {
  const plusPrefix = "plus";
  const lessPrefix = "less";

  const maxYears = getPlusAge(ages, plusPrefix);
  const years = getAge(birthdate);

  if (years === undefined) {
    return undefined;
  } else if (years > maxYears) {
    return ages.find((age) => age.id.startsWith(plusPrefix));
  } else if (years === 0) {
    return ages.find((age) => age.id.startsWith(lessPrefix));
  } else {
    return ages.find((age) => Number(age.id.split("_")[0]) === years);
  }
};

const getPlusAge = (ages: Array<Option<string>>, plusPrefix: string): number => {
  return Number(ages.find((age) => age.id.startsWith(plusPrefix))!.id.split("_")[1]);
};

const getAge = (birthdate: Moment | undefined): number | undefined => {
  return birthdate === undefined ? undefined : moment().diff(birthdate, "year", false);
};

const AgeField: FunctionComponent<AgeFieldProps> = (props) => {
  const masterData = useMasterData();

  return (
    <SelectField
      id={props.id}
      options={masterData.ageOptions}
      selectedOption={getAgeOption(props.birthdate, masterData.ageOptions) || props.age}
      label="Age"
      errors={props.errors}
      name={props.name}
      onSelect={(age, fieldName) =>
        props.onChange && props.onChange(age ? { id: age.id.toString(), name: age.name } : undefined, fieldName)
      }
      information="If you only enter the pet's age without a specific birthday, the age will not update automatically."
      disabled={props.disabled}
    />
  );
};

export default AgeField;
