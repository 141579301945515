import React, { FunctionComponent } from 'react';
import Chip from './Chip';
import { StaffService } from '../../model/Service';

interface Props {
	staff: StaffService;
	small?: boolean;
	cursorPointer?: boolean;
	fullWidth?: boolean;
	className?: string;
	onDelete?: () => void;
}

export const StaffChipService: FunctionComponent<Props> = props => {
	return (
		<Chip
			small={props.small}
			label={props.staff.name}
			className={props.className}
			onDelete={props.onDelete}
		/>
	);
};

export default StaffChipService;
