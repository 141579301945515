import React, { FunctionComponent, useEffect } from 'react';
import { Option } from '@spike/model';
import { Radio } from 'components/UI';
import { Box } from '@material-ui/core';

export interface Props {
	options: Array<Option<string>>;
	selected?: Option<string>;
	onChange: (value: Option<string>) => void;
	required?: boolean;
	error?: boolean;
	errorMessage?: string;
	className?: string;
}

export const SelectBusinessArea: FunctionComponent<Props> = props => {
	const changeHandler = (value: string | number) => {
		props.onChange(props.options.find(a => a.id === value)!);
	};

	useEffect(() => {
		if (props.options.length === 1) {
			props.onChange(props.options[0]);
		}
	}, [props.options]);

	const options = props.options.map(option => (
		<Radio
			id={`services_radio_${option.name.toLowerCase()}`}
			key={option.id}
			label={option.name}
			className={props.className}
			checked={
				props.options.length === 1
					? true
					: props.selected?.id === option.id
					? true
					: false
			}
			onChange={() => {
				changeHandler(option.id);
			}}
		/>
	));

	return <>{options}</>;
};

export default SelectBusinessArea;
