import React, { FunctionComponent, Fragment, useEffect, useState } from "react";
import AppointmentsWebSockets from "WebSockets";
import BookingsComponent from "components/Bookings";
import PrivatePage from "./PrivatePage";
import { RouteComponentProps, useLocation, useParams } from "react-router";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import { BookingsViewData } from "model/BookingsPath";
import { withRouter } from "react-router-dom";
import useNonInitialEffect from "@versiondos/hooks";


interface MatchParams {
  hash?: string;
}

interface BookingProps extends RouteComponentProps<MatchParams> { }


const secret: string = `${process.env.REACT_APP_GLOBAL_SECRET}`;


const convertHash = (hash: string | undefined, secret: string): BookingsViewData | undefined => {
  if (hash) {
    const json: BookingsViewData = JSON.parse(AES.decrypt(decodeURIComponent(hash), secret).toString(CryptoJS.enc.Utf8));

//    console.log(json);

    if (json) {
      return json;
    }
  }
};



export const Bookings: FunctionComponent<BookingProps> = (props) => {

  const [viewData, setViewData] = useState<BookingsViewData | undefined>(convertHash(props.match.params.hash, secret));

  const location = useLocation();


  /* For some reason it is not getting the match param */
  useEffect(() => {
    // console.log(props.match.params);
    //  setViewData(convertHash(props.match.params.hash, secret));
  }, [props.match.params.hash]);



  /* This piece of code is provisional and only for development purposes as we're not able to get the match param */
  useEffect(() => {
    // console.log(props.match.params, location.pathname.substring(10));
    setViewData(convertHash(location.pathname.substring(10), secret));
  }, [location.pathname]);

  useNonInitialEffect(() => {
    //console.log("inf:", viewData);
  }, [viewData]);


  return (
    <Fragment>
      <AppointmentsWebSockets />
      <PrivatePage title="Bookings - Cuddles" pageName="/bookings">
        <BookingsComponent viewData={viewData} />

      </PrivatePage>
    </Fragment>
  );
};

export default withRouter(Bookings);
