import React, { FunctionComponent, ChangeEvent, KeyboardEvent } from "react";
import { Box, OutlinedInput, InputBaseComponentProps } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FieldError } from "@spike/model";
import camelCase from "lodash/camelCase";
import { useFieldStyles } from "./FieldStyles";
import { CharacterCounter } from "components/UI/CharacterCounter";
import LabelField from "./LabelField";

export interface DescriptionField2Props {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  id?: string;
  value: string;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: string, name?: any) => void;
  onFocus?: () => void;
  labelClassName?: string;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  onEnterPressed?: () => void;
  disabled?: boolean;
  capitalize?: boolean;
  upperCase?: boolean;
  maxCharacters?: number;
  maxRows?: number;
  minRows?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    withConter: {
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingBottom: "4px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "23px",
        paddingRight: "23px",
        paddingBottom: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingBottom: "10px",
      },
    },
    counterContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
  })
);

export const DescriptionField2: FunctionComponent<DescriptionField2Props> = (props) => {
  const fieldClasses = useFieldStyles();
  const classes = useStyles();

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    if (!props.maxCharacters || props.maxCharacters >= newValue.length) {
      if (props.capitalize) {
        props.onChange(camelCase(newValue), props.name);
      } else if (props.upperCase) {
        props.onChange(newValue?.toUpperCase(), props.name);
      } else {
        props.onChange(newValue, props.name);
      }
    }
  };

  const onFocusHandler = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  const counter = (
    <Box className={classes.counterContainer}>
      <CharacterCounter max={props.maxCharacters || 0} text={props.value} />
    </Box>
  );

  let style: InputBaseComponentProps | undefined = undefined;
  if (props.capitalize && (props.value ?? "").length > 0) {
    style = { style: { textTransform: "capitalize" } };
  } else if (props.upperCase && (props.value ?? "").length > 0) {
    style = { style: { textTransform: "uppercase" } };
  }

  return (
    <Box className={clsx(fieldClasses.container, props.className)}>
      <LabelField {...props} className={props.labelClassName} />
      <Box>
        <OutlinedInput
          name={props.name}
          placeholder={props.placeholder}
          id={props.id}
          value={props.value}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          className={clsx(fieldClasses.descriptionField, {
            [fieldClasses.emptyField]: props.value === null || props.value.length === 0,
            [classes.withConter]: props.maxCharacters,
            [fieldClasses.fieldError]: hasError,
          })}
          error={hasError}
          multiline={true}
          maxRows={props.maxRows}
          minRows={props.minRows}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
          inputProps={style}
          endAdornment={props.maxCharacters ? counter : undefined}
        />
      </Box>
    </Box>
  );
};

export default DescriptionField2;
