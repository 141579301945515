import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { ActionButton, ActionButtonType } from './ActionButton';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            position: 'fixed',
            display: 'flex'
        },
        backdrop: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.1,
            position: 'absolute',
            backgroundColor: '#000'
        },
        popup: {
            width: '100%',
            marginTop: 'auto',
            padding: '0px 16px 11px',
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: '#fff',
            position: 'relative',
            overflow: 'hidden',
            zIndex: 100
        },
        popupChip: {
            width: 56,
            height: 6,
            display: 'block',
            margin: '15px auto',
            borderRadius: 100,
            backgroundColor: '#D4D4D4'
        },
        popupInfo:{
            marginTop: '20px',
            marginBottom: '20px',
            paddingLeft: '13px',
            paddingRight: '13px',
            textAlign: 'center',
        }
    })
);

interface ActionsPopupProps {
	header?: React.ReactNode;
    info?: React.ReactNode;
	actions?: Array<ActionButtonType>;
	onClose: () => void;
}

export const ActionsPopup: React.FunctionComponent<
	ActionsPopupProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.backdrop} onClick={props.onClose} />

			<Box className={classes.popup}>
				<span
					className={classes.popupChip}
					onClick={props.onClose}
				></span>

                {props.header && props.header}
                
                {props.info && <Box className={classes.popupInfo}>
                    {props.info}
                </Box>}
                
                

                {props.actions?.map((action, i) => (
                    <ActionButton key={i} {...action} />
                ))}
            </Box>
        </Box>
    );
};
