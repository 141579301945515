import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Client from '@spike/client-model';
import React, { FunctionComponent, useState } from 'react';
import ClientRow from './ClientRow';
import { useCommonStyles } from './CommonStyles';
import PetRows from './PetRows';
import TableHeaderDetail from './TableHeaderDetail';
import { wbp, reduceResolution } from 'Theme';

interface TableBodyProps {
	clients: Array<Client>;
	onEdit?: (clientId: number) => void;
	onEditPet?: (clientId: number, petId: number) => void;
	onBook?: (client: Client) => void;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: {
			backgroundColor: 'white',
			borderBottom: '1px solid #F1F1F1',

			[theme.breakpoints.down('sm')]: {
				padding: '12px 16px'
			},
			[theme.breakpoints.up('md')]: {
				borderLeft: '4px solid white',
				borderBottom: '1px solid #DDDDDD'
			}
		},
		expandedClientRow: {
			borderLeft: '4px solid #BAA997',
			borderBottom: '1px solid white',
			backgroundColor: '#F8F5F1'
		},
		petsRow: {
			borderLeft: '4px solid #BAA997',
			[theme.breakpoints.down(wbp)]: {
				boxShadow: `0px ${reduceResolution(5)}px ${reduceResolution(
					12
				)}px rgba(0, 0, 0, 0.08)`,
				marginBottom: `${reduceResolution(12)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
				marginBottom: '12px'
			}
		}
	})
);

const TableBody: FunctionComponent<TableBodyProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const [expandedClientId, setExpandedClientId] = useState<number | null>(
		null
	);

	const handleToggle = (clientId: number | null) => {
		setExpandedClientId(prev => (prev === clientId ? null : clientId));
	};

	return (
		<>
			{props.clients.map(client => (
				<>
					<Grid
						key={client.uuid}
						container
						xs={12}
						className={clsx(
							classes.row,
							commonClasses.rowContainer,
							{
								[classes.expandedClientRow]:
									client.id === expandedClientId
							}
						)}
					>
						<ClientRow
							client={client}
							expanded={client.id === expandedClientId}
							onToggle={() => handleToggle(client.id || null)}
							onBook={() => props.onBook && props.onBook(client)}
							onEdit={() =>
								props.onEdit && props.onEdit(client.id!)
							}
						/>
					</Grid>
					{client.id === expandedClientId && (
						<Grid
							container
							item
							className={clsx(
								classes.petsRow,
								commonClasses.rowContainer
							)}
						>
							<TableHeaderDetail />
							<PetRows client={client} onEdit={props.onEditPet} />
						</Grid>
					)}
				</>
			))}
		</>
	);
};

export default TableBody;
