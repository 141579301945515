
export interface PetTypeStatusState {
    pet_size: Array<PetType>,
    pet_age: Array<PetType>,
    pet_hair_type: Array<PetType>,
    pet_hair_length: Array<PetType>,
    exotic_types: Array<PetType>,
    status: PetTypeStatusEnum
}

export interface PetType {
    dogs?: DogsVariable,
    cats?: CatsVariable
}

export interface DogsVariable {
    young?: number,
    adult?: number,
    senior?: number,
    xsmall?: number,
    small?: number,
    medium?: number,
    large?: number,
    xlarge?: number,
    giant?: number,
    smooth?: number,
    curly?: number,
    wire?: number,
    wavy?: number,
    thick?: number,
    double?: number,
    drop_coat?: number,
    short?: number,
    long?: number
}

export interface CatsVariable {
    young?: number,
    adult?: number,
    senior?: number,
    smooth?: number,
    rex?: number,
    normal_coat?: number,
    normal_double?: number,
    normal_triple?: number,
    hairless?: number,
    short?: number,
    medium?: number,
    long?: number
}

export enum PetTypeStatusEnum {
    Initial,
    Fetching,
    FetchSuccess
}
