import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { Period } from '@spike/model';

interface StaffScheduleHeaderProps {
	week: Period;
	onChange?: (week: Period) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%'
		},
		week: {
			fontSize: 16,
			fontWeight: 600,
			color: '#303134',
			textAlign: 'center',
			margin: '0px 10px',
			[theme.breakpoints.up('md')]: {
				width: '250px',
				fontSize: '20px',
				lineHeight: '30px',
				marginLeft: '14px',
				marginRight: '14px'
			}
		},
		icon: {
			width: 10,
			cursor: 'pointer',
			[theme.breakpoints.up('md')]: {
				width: 26,
				height: 26
			}
		}
	})
);

export const StaffScheduleHeaders: FunctionComponent<
	StaffScheduleHeaderProps
> = props => {
	const classes = useStyles();

	const title = `${props.week.from
		.clone()
		.format('MMMM')
		.toUpperCase()} ${props.week.from.clone().format('D')} - ${props.week.to
		.clone()
		.format('D')}, ${props.week.from.clone().format('YYYY')}`;

	const clickPrevHandler = () => {
		const week: Period = {
			from: props.week.from.clone().subtract(1, 'week'),
			to: props.week.to.clone().subtract(1, 'week')
		};
		props.onChange && props.onChange({ ...week });
	};

	const clickNextHandler = () => {
		const week: Period = {
			from: props.week.from.clone().add(1, 'week'),
			to: props.week.to.clone().add(1, 'week')
		};
		props.onChange && props.onChange({ ...week });
	};

	return (
		<Box className={classes.container}>
			<FontAwesomeIcon
				icon={faCaretLeft}
				className={classes.icon}
				onClick={clickPrevHandler}
			/>
			<Typography className={classes.week}>{title}</Typography>
			<FontAwesomeIcon
				icon={faCaretRight}
				className={classes.icon}
				onClick={clickNextHandler}
			/>
		</Box>
	);
};

export default StaffScheduleHeaders;
