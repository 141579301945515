import React, { FunctionComponent, MouseEvent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartbeat,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons"
import clsx from "clsx";

interface Props {
  name: string;
  bold?: boolean;
  hasMedicalConditions: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxPet: {
      padding: "2px 10px 2px 10px",
      border: "1px solid transparent",
      width: "fit-content",
      transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      boxSizing: "border-box",
      borderRadius: "20px",
      display: 'inline-block',
      "&:hover": {
        [theme.breakpoints.up(768)]: {
          cursor: "pointer",
          border: "1px solid",
          background: "#eee"
        }
      }
    },

    bold: {
      fontWeight: 500,
    },

    heartIcon: {
      color: "#EF4F57",
      marginLeft: "5px",
      fontSize: "16px",
      verticalAlign: 'middle',
      [theme.breakpoints.down(768)]: {
        marginLeft: 8
      }
    },
  })
);

export const CustomerName: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const clickHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    props.onClick && props.onClick();
  }

  return (
    <Box
      className={clsx(classes.boxPet, { [classes.bold]: props.bold })} onClick={clickHandler}>
      {props.name}
      {props.hasMedicalConditions && iconSVG(faHeartbeat, `${classes.heartIcon}`)}
    </Box>
  );
};

export default CustomerName;
