import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	ClickAwayListener,
	createStyles,
	InputBase,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import {
	InvoiceDiscount as InvoiceDiscountModel,
	InvoiceLine
} from '@spike/invoice-model';
import { FieldError, Option } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { wbp } from 'Theme';
import { useCommonStyles } from './CommonStyles';

interface InvoiceDiscountProps {
	discount?: InvoiceDiscountModel;
	className?: string;
	name?: string;
	errors?: Array<FieldError>;
	line?: InvoiceLine;
	diff?: number;
	onChange?: (discount: InvoiceDiscountModel, name?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			borderRadius: '100px',
			border: '1px solid #222222',
			[theme.breakpoints.down(wbp)]: {
				width: '144px',
				height: '29px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '180px',
				height: '36px'
			}
		},
		valueContainer: {
			display: 'flex',
			alignContent: 'center',
			width: '60%',
			[theme.breakpoints.down(wbp)]: {
				paddingLeft: '15px'
			},
			[theme.breakpoints.up(wbp)]: {
				paddingLeft: '19px'
			}
		},
		dropdownContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '40%',
			borderLeft: '1px solid #F1F1F1',
			height: '100%',
			cursor: 'pointer'
		},
		chevron: {
			marginLeft: '5px',
			[theme.breakpoints.down(wbp)]: {
				width: '9px',
				height: '9px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '11px',
				height: '11px'
			}
		},
		text: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: '#222222',
			textTransform: 'capitalize',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '13px',
				lineHeight: '17px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeight: '30px'
			}
		},
		adornment: {
			marginTop: '-1px'
		},
		list: {
			position: 'absolute',
			right: 0,
			top: 0,
			marginTop: -1,
			marginRight: -1,
			zIndex: 10,
			border: '1px solid #000000',
			backgroundColor: '#FFFFFF',
			[theme.breakpoints.down(wbp)]: {
				borderRadius: '11px',
				padding: '10px 0px',
				width: '60px'
			},
			[theme.breakpoints.up(wbp)]: {
				borderRadius: '14px',
				padding: '12px 0px',
				width: '75px'
			}
		},
		listItem: {
			'cursor': 'pointer',
			'fontFamily': 'Poppins',
			'fontWeight': 400,
			'color': '#222222',
			'textTransform': 'uppercase',
			'textAlign': 'center',
			'width': '100%',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				lineHeight: '22px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '18px',
				lineHeight: '27px'
			},
			'&:hover': {
				backgroundColor: '#F4F4F4'
			}
		},
		inputRight: {
			'& input': {
				textAlign: 'right',
				paddingRight: '5px'
			}
		}
	})
);

const amount: Option<string> = {
	id: '$',
	name: '$',
	description: '$'
};
const percentage: Option<string> = { id: '%', name: '%', description: '%' };

const options: Array<Option<string>> = [amount, percentage];

export const InvoiceDiscount: FunctionComponent<
	InvoiceDiscountProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const [selected, setSelected] = useState<Option<string>>(
		props.discount?.percentage ? percentage : amount
	);
	const [showList, setShowList] = useState(false);
	const [value, setValue] = useState(
		props.discount?.percentage?.toString() ||
			props.discount?.amount?.toString() ||
			'0.00'
	);

	const hasError = props.errors?.some(
		error => error.fieldName === props.name
	);
	useNonInitialEffect(() => {
		const timer: NodeJS.Timer = setTimeout(() => {
			props.onChange &&
				props.onChange(
					selected.id === amount.id
						? {
								amount: value
						  }
						: {
								percentage: Number(value)
						  },
					props.name
				);
		}, 200);

		return () => clearTimeout(timer);
	}, [value]);

	const selectHandler = (option: Option<string>) => {
		setSelected(option);
		setValue('0');
		setShowList(false);
	};

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();

		const value = event.target.value;
		const text = value.substring(
			0,
			value.indexOf('.') === -1 ? value.length : value.indexOf('.') + 3
		);

		const number = Number(text);

		if (
			text === '' ||
			(!isNaN(number) &&
				number >= 0 &&
				(selected.id === amount.id || number <= 100))
		) {
			setValue(text.endsWith('.') ? text : number.toString());
		}
	};

	const dollarAdornment =
		selected.id === amount.id ? (
			<Typography
				className={clsx(commonClasses.inputText, classes.adornment)}
			>
				$
			</Typography>
		) : null;

	return (
		<Box
			className={clsx(classes.container, props.className, {
				[commonClasses.errorBorder]: hasError,
				[commonClasses.errorText]: hasError
			})}
		>
			<Box className={classes.valueContainer}>
				<InputBase
					className={clsx(
						commonClasses.inputText,
						classes.inputRight,
						{ [commonClasses.errorText]: hasError }
					)}
					value={value}
					onChange={changeHandler}
					startAdornment={dollarAdornment}
				/>
			</Box>
			<Box
				className={classes.dropdownContainer}
				onClick={() => setShowList(true)}
			>
				<Typography
					className={clsx(classes.text, {
						[commonClasses.errorText]: hasError
					})}
				>
					{selected.name}
				</Typography>
				<FontAwesomeIcon
					icon={faChevronDown}
					className={classes.chevron}
				/>
			</Box>
			{showList && (
				<ClickAwayListener onClickAway={() => setShowList(false)}>
					<Box className={classes.list}>
						{options.map(option => (
							<Typography
								key={option.id}
								className={classes.listItem}
								onClick={() => selectHandler(option)}
							>
								{option.name}
							</Typography>
						))}
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};

export default InvoiceDiscount;
