import { Agreement, AgreementSummary, Signature } from "model/Agreements";
import { Action } from "redux";
export const AGREEMENTS_RESET = "agreements/reset";
export const AGREEMENTS_FETCH_START = "agreements/fetch_start";
export const AGREEMENTS_FETCH_SUCCESS = "agreements/fetch_success";
export const AGREEMENTS_SAVE_START = "agreements/save_start";
export const AGREEMENTS_SAVE_SUCCESS = "agreements/save_success";
export const AGREEMENTS_SEND_START = "agreements/send_start";
export const AGREEMENTS_SEND_SUCCESS = "agreements/send_success";
export const AGREEMENTS_RESEND_START = "agreements/resend_start";
export const AGREEMENTS_RESEND_SUCCESS = "agreements/resend_success";
export const AGREEMENTS_SEND_SIGNATURE_PDF_START = "agreements/send_signature_pdf_start";
export const AGREEMENTS_SEND_SIGNATURE_PDF_SUCCESS = "agreements/send_signature_pdf_success";
export const AGREEMENTS_SIGN_START = "agreements/sign_start";
export const AGREEMENTS_SIGN_SUCCESS = "agreements/sign_success";
export const AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_START = "agreements/fetch_signatures_by_client_start";
export const AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_SUCCESS = "agreements/fetch_signatures_by_client_success";
export const AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_START = "agreements/fetch_signatureS_by_agreement_start";
export const AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_SUCCESS = "agreements/fetch_signatureS_by_agreement_success";
export const AGREEMENTS_DOWNLOAD_PDF_START = "agreements/download_pdf_start";
export const AGREEMENTS_DOWNLOAD_PDF_SUCCESS = "agreements/download_pdf_success";
export const AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_START = "agreements/download_signature_pdf_start";
export const AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_SUCCESS = "agreements/download_signature_pdf_success";
export const AGREEMENTS_PRINT_START = "agreements/print_start";
export const AGREEMENTS_PRINT_SUCCESS = "agreements/print_success";
export const AGREEMENTS_PRINT_SIGNATURE_START = "agreements/print_signature_start";
export const AGREEMENTS_PRINT_SIGNATURE_SUCCESS = "agreements/print_signature_success";
export const AGREEMENTS_GET_SUMMARY_START = "agreements/get_summary_start";
export const AGREEMENTS_GET_SUMMARY_SUCCESS = "agreements/get_summary_success";
export const AGREEMENTS_GET_SIGNATURE_BY_HASH_START = "agreements/get_signature_by_hash_start";
export const AGREEMENTS_GET_SIGNATURE_BY_HASH_SUCCESS = "agreements/get_signature_by_hash_success";
export const AGREEMENTS_DELETE_START = "agreements/delete_start";
export const AGREEMENTS_DELETE_SUCCESS = "agreements/delete_success";
export const AGREEMENTS_DELETE_SIGNATURE_START = "agreements/delete_signature_start";
export const AGREEMENTS_DELETE_SIGNATURE_SUCCESS = "agreements/delete_signature_success";
export const AGREEMENTS_ERROR = "agreements/error";

interface AgreementsResetAction extends Action<typeof AGREEMENTS_RESET> {}

interface AgreementsFetchStartAction extends Action<typeof AGREEMENTS_FETCH_START> {}

interface AgreementsFetchSuccessAction extends Action<typeof AGREEMENTS_FETCH_SUCCESS> {
  payload: {
    agreements: Array<Agreement>;
  };
}

interface AgreementsSaveStartAction extends Action<typeof AGREEMENTS_SAVE_START> {}

interface AgreementsSaveSuccessAction extends Action<typeof AGREEMENTS_SAVE_SUCCESS> {
  payload: {
    agreement: Agreement;
  };
}

interface AgreementsDeleteStartAction extends Action<typeof AGREEMENTS_DELETE_START> {}

interface AgreementsDeleteSuccessAction extends Action<typeof AGREEMENTS_DELETE_SUCCESS> {
  payload: {
    agreementId: number;
  };
}

interface AgreementsDeleteSignatureStartAction extends Action<typeof AGREEMENTS_DELETE_SIGNATURE_START> {}

interface AgreementsDeleteSignatureSuccessAction extends Action<typeof AGREEMENTS_DELETE_SIGNATURE_SUCCESS> {
  payload: {
    signatureId: number;
  };
}

interface AgreementsErrorActionAction extends Action<typeof AGREEMENTS_ERROR> {}

interface AgreementsSignStartAction extends Action<typeof AGREEMENTS_SIGN_START> {}

interface AgreementsSignSuccessAction extends Action<typeof AGREEMENTS_SIGN_SUCCESS> {}

interface AgreementsFetchSignaturesByClientStartAction
  extends Action<typeof AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_START> {}

interface AgreementsFetchSignaturesByClientSuccessAction
  extends Action<typeof AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_SUCCESS> {
  payload: {
    signatures: Array<Signature>;
  };
}

interface AgreementsFetchSignaturesByAgreementStartAction
  extends Action<typeof AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_START> {}

interface AgreementsFetchSignaturesByAgreementSuccessAction
  extends Action<typeof AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_SUCCESS> {
  payload: {
    signatures: Array<Signature>;
  };
}

interface AgreementsDownloadPdfStartAction extends Action<typeof AGREEMENTS_DOWNLOAD_PDF_START> {}

interface AgreementsDownloadPdfSuccessAction extends Action<typeof AGREEMENTS_DOWNLOAD_PDF_SUCCESS> {}

interface AgreementsDownloadSignaturePdfStartAction extends Action<typeof AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_START> {}

interface AgreementsDownloadSignaturePdfSuccessAction
  extends Action<typeof AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_SUCCESS> {}

interface AgreementsPrintStartAction extends Action<typeof AGREEMENTS_PRINT_START> {}

interface AgreementsPrintSuccessAction extends Action<typeof AGREEMENTS_PRINT_SUCCESS> {}

interface AgreementsPrintSignatureStartAction extends Action<typeof AGREEMENTS_PRINT_SIGNATURE_START> {}

interface AgreementsPrintSignatureSuccessAction extends Action<typeof AGREEMENTS_PRINT_SIGNATURE_SUCCESS> {}

interface AgreementsGetSummaryStartAction extends Action<typeof AGREEMENTS_GET_SUMMARY_START> {}

interface AgreementsGetSummarySuccessAction extends Action<typeof AGREEMENTS_GET_SUMMARY_SUCCESS> {
  payload: {
    summary: AgreementSummary;
  };
}

interface AgreementsGetSignatureByHashStartAction extends Action<typeof AGREEMENTS_GET_SIGNATURE_BY_HASH_START> {}

interface AgreementsGetSignatureByHashSuccessAction extends Action<typeof AGREEMENTS_GET_SIGNATURE_BY_HASH_SUCCESS> {
  payload: {
    signature: Signature;
  };
}

interface AgreementsSendStartAction extends Action<typeof AGREEMENTS_SEND_START> {}
interface AgreementsSendSuccessAction extends Action<typeof AGREEMENTS_SEND_SUCCESS> {
  payload: {
    summary: AgreementSummary;
  };
}

interface AgreementsResendStartAction extends Action<typeof AGREEMENTS_RESEND_START> {}
interface AgreementsResendSuccessAction extends Action<typeof AGREEMENTS_RESEND_SUCCESS> {
  payload: {
    signature: Signature;
  };
}

interface AgreementsSendSignaturePdfStartAction extends Action<typeof AGREEMENTS_SEND_SIGNATURE_PDF_START> {}
interface AgreementsSendSignaturePdfSuccessAction extends Action<typeof AGREEMENTS_SEND_SIGNATURE_PDF_SUCCESS> {
  payload: {
    signature: Signature;
  };
}

export type AgreementsAction =
  | AgreementsResetAction
  | AgreementsFetchStartAction
  | AgreementsFetchSuccessAction
  | AgreementsSaveStartAction
  | AgreementsSaveSuccessAction
  | AgreementsDeleteStartAction
  | AgreementsDeleteSuccessAction
  | AgreementsDeleteSignatureStartAction
  | AgreementsDeleteSignatureSuccessAction
  | AgreementsSendStartAction
  | AgreementsSendSuccessAction
  | AgreementsResendStartAction
  | AgreementsResendSuccessAction
  | AgreementsSendSignaturePdfStartAction
  | AgreementsSendSignaturePdfSuccessAction
  | AgreementsPrintStartAction
  | AgreementsPrintSuccessAction
  | AgreementsPrintSignatureStartAction
  | AgreementsPrintSignatureSuccessAction
  | AgreementsSignStartAction
  | AgreementsSignSuccessAction
  | AgreementsFetchSignaturesByClientStartAction
  | AgreementsFetchSignaturesByClientSuccessAction
  | AgreementsFetchSignaturesByAgreementStartAction
  | AgreementsFetchSignaturesByAgreementSuccessAction
  | AgreementsDownloadPdfStartAction
  | AgreementsDownloadPdfSuccessAction
  | AgreementsDownloadSignaturePdfStartAction
  | AgreementsDownloadSignaturePdfSuccessAction
  | AgreementsGetSummaryStartAction
  | AgreementsGetSummarySuccessAction
  | AgreementsErrorActionAction
  | AgreementsGetSignatureByHashStartAction
  | AgreementsGetSignatureByHashSuccessAction;
