import {
    BusinessHours,
    FieldError,
    MarketplaceSchedule,
    SpecialHour,
    Week
} from '@spike/model';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { Period } from '@spike/model';
import { convertToAmPm } from 'utils/DateUtils';
import { Day } from '../StaffScheduleDay';

const getSpecialHour = (
    day: Day,
    marketplaceSchedule: MarketplaceSchedule
): SpecialHour | null => {
    return (
        marketplaceSchedule.specialHours.find(
            specialHour =>
                specialHour.date ===
                day.from
                    .clone()
                    .tz(marketplaceSchedule.timeZone)
                    .format('YYYY-MM-DD')
        ) || null
    );
};

const getBusinessHours = (
    day: Day,
    marketplaceSchedule: MarketplaceSchedule
): BusinessHours | null => {
    const weekday = day.from
        .clone()
        .tz(marketplaceSchedule.timeZone)
        .format('dddd')
        .toLowerCase();
    return marketplaceSchedule.week[weekday as keyof Week];
};

const isOpenFirst = (businessHours: BusinessHours) => {
    return (
        parseInt(businessHours.open.replaceAll(':', '')) <
        parseInt(businessHours.close.replaceAll(':', ''))
    );
};

const isBetween = (businessHours: BusinessHours, boundaries: BusinessHours) => {
    const open = parseInt(businessHours!.open.replaceAll(':', ''));
    const close = parseInt(businessHours!.close.replaceAll(':', ''));
    const boundaryOpen = parseInt(boundaries!.open.replaceAll(':', ''));
    const boundaryClose = parseInt(boundaries!.close.replaceAll(':', ''));

    return (
        open >= boundaryOpen &&
        open <= boundaryClose &&
        close >= boundaryOpen &&
        close <= boundaryClose
    );
};

const isInPeriod = (day: Day, period: Period) => {
    return (
        day.from
            .clone()
            .startOf('day')
            .isSameOrAfter(period.from.clone().startOf('day')) &&
        day.to
            .clone()
            .endOf('day')
            .isSameOrBefore(period.to.clone().endOf('day'))
    );
};

const isInBusinessHours = (
    day: Day,
    businessHours: BusinessHours,
    timeZone: string
) => {
    return isBetween(
        {
            open: day.from.clone().tz(timeZone).format('HH:MM'),
            close: day.to.clone().tz(timeZone).format('HH:MM')
        },
        businessHours
    );
};

const validateByWeek = (day: Day, marketplaceSchedule: MarketplaceSchedule) => {
    const businessHours = getBusinessHours(day, marketplaceSchedule);
    return (
        businessHours !== null &&
        isInBusinessHours(day, businessHours, marketplaceSchedule.timeZone)
    );
};

export const validateWeek = (week: Week): Array<FieldError> => {
    return keys(week)
        .filter(day => {
            const businessHours = week[day as keyof Week];
            return businessHours !== null && !isOpenFirst(businessHours);
        })
        .map(day => ({
            fieldName: day,
            errorMessage: 'End befor start hour.'
        }));
};

export const validateWeekByMarketplaceSchedule = (
    week: Week,
    marketplaceSchedule: MarketplaceSchedule
): Array<FieldError> => {
    return keys(week)
        .filter(day => week[day as keyof Week] !== null)
        .filter(day => {
            const weekBusinessHours = week[day as keyof Week];
            const marketplaceBusinessHours =
                marketplaceSchedule.week[day as keyof Week];

            return (
                marketplaceBusinessHours === null ||
                !isBetween(weekBusinessHours!, marketplaceBusinessHours)
            );
        })
        .map(day => {
            const marketplaceBusinessHours =
                marketplaceSchedule.week[day as keyof Week];
            return {
                fieldName: day,
                errorMessage:
                    marketplaceBusinessHours === null
                        ? 'Closed.'
                        : `Open from ${convertToAmPm(
                              marketplaceBusinessHours.open
                          )} to ${convertToAmPm(
                              marketplaceBusinessHours.close
                          )}`
            };
        });
};

export const validateTimeZone = (
    schedule: MarketplaceSchedule
): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (isEmpty(schedule.timeZone)) {
        errors.push({
            fieldName: 'timeZone',
            errorMessage: 'Time Zone is required'
        });
    }

    return errors;
};

const getMessage = (day: Day, marketplaceSchedule: MarketplaceSchedule) => {
    const specialHour = getSpecialHour(day, marketplaceSchedule);
    const businessHours = getBusinessHours(day, marketplaceSchedule);

    if (specialHour && specialHour.isClosed) {
        return 'Closed.';
    } else if (specialHour) {
        return `From ${convertToAmPm(
            specialHour.businessHours?.open || ''
        )} to ${convertToAmPm(specialHour.businessHours?.close || '')}`;
    } else if (businessHours) {
        return `From ${convertToAmPm(businessHours.open)} to ${convertToAmPm(
            businessHours.close
        )}`;
    } else {
        return 'Closed.';
    }
};

export const validateDays = (
    days: Array<Day>,
    period?: Period
): Array<FieldError> => {
    return days
        .filter(day => !period || isInPeriod(day, period))
        .filter(day => day.from.isSameOrAfter(day.to))
        .map(day => ({
            fieldName: day.uuid,
            errorMessage: 'End befor start hour.'
        }));
};

export const validateDaysByMarketplaceSchedule = (
    days: Array<Day>,
    marketplaceSchedule: MarketplaceSchedule,
    period?: Period
): Array<FieldError> => {
    return days
        .filter(day => !period || isInPeriod(day, period))
        .filter(day => day.on)
        .filter(day => {
            const marketplaceSpecialHour = getSpecialHour(
                day,
                marketplaceSchedule
            );

            if (marketplaceSpecialHour) {
                const result =
                    marketplaceSpecialHour.isClosed ||
                    !isInBusinessHours(
                        day,
                        marketplaceSpecialHour.businessHours!,
                        marketplaceSchedule.timeZone
                    );
                return result;
            } else {
                const result = !validateByWeek(day, marketplaceSchedule);
                return result;
            }
        })
        .map(day => {
            return {
                fieldName: day.uuid,
                errorMessage: getMessage(day, marketplaceSchedule)
            };
        });
};

export const validateSpecialHours = (
    specialHours: Array<SpecialHour>
): Array<FieldError> => {
    const wrongHours = specialHours
        .filter(
            specialHour =>
                specialHour.businessHours &&
                !isOpenFirst(specialHour.businessHours)
        )
        .map(specialHour => ({
            fieldName: specialHour.uuid,
            errorMessage: 'End befor start hour.'
        }));

    const emptyDate = specialHours
        .filter(specialHour => isEmpty(specialHour.date))
        .map(specialHour => ({
            fieldName: specialHour.uuid,
            errorMessage: 'Empty date.'
        }));

    const duplicated = specialHours
        .filter(specialHour =>
            specialHours.some(
                otherSpecialHour =>
                    specialHour.uuid !== otherSpecialHour.uuid &&
                    specialHour.date === otherSpecialHour.date
            )
        )
        .map(specialHour => ({
            fieldName: specialHour.uuid,
            errorMessage: 'Duplicated date.'
        }));

    return [...wrongHours, ...emptyDate, ...duplicated];
};
