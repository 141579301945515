import SignUp from "model/SignUp";
import { Action } from "redux";

//Types
export const SIGN_UP_RESET_ACTION_TYPE = "sign_up/reset";
export const SIGN_UP_SET_DATA_ACTION_TYPE = "sign-up/set_data";
export const SIGN_UP_EMAIL_VERIFICATION_START_ACTION_TYPE = "sign_up/email_verification_start";
export const SIGN_UP_EMAIL_VERIFICATION_SUCCESS_ACTION_TYPE = "sign_up/email_verification_success";
export const SIGN_UP_EMAIL_VERIFICATION_FAILED_ACTION_TYPE = "sign_up/email_verification_failed";
export const SIGN_UP_START_ACTION_TYPE = "sign_up/start";
export const SIGN_UP_SUCCESS_ACTION_TYPE = "sign_up/success";
export const SIGN_UP_FAILED_ACTION_TYPE = "sign_up/failed";
export const SIGN_UP_ERROR_ACTION_TYPE = "sign_up/error";

//SingUp
interface SignUpResetAction extends Action<typeof SIGN_UP_RESET_ACTION_TYPE> {}

interface SignUpSetDataAction extends Action<typeof SIGN_UP_SET_DATA_ACTION_TYPE> {
  payload: {
    data: SignUp;
  };
}
interface SignUpEmailVerificationStartAction extends Action<typeof SIGN_UP_EMAIL_VERIFICATION_START_ACTION_TYPE> {}
interface SignUpEmailVerificationSuccessAction extends Action<typeof SIGN_UP_EMAIL_VERIFICATION_SUCCESS_ACTION_TYPE> {}
interface SignUpEmailVerificationFailedAction extends Action<typeof SIGN_UP_EMAIL_VERIFICATION_FAILED_ACTION_TYPE> {}

interface SignUpStartAction extends Action<typeof SIGN_UP_START_ACTION_TYPE> {}
interface SignUpSuccessAction extends Action<typeof SIGN_UP_SUCCESS_ACTION_TYPE> {}
interface SignUpFailedAction extends Action<typeof SIGN_UP_FAILED_ACTION_TYPE> {}

interface SignUpErrorAction extends Action<typeof SIGN_UP_ERROR_ACTION_TYPE> {}

//SignUpAction
export type SignUpAction =
  | SignUpResetAction
  | SignUpSetDataAction
  | SignUpEmailVerificationStartAction
  | SignUpEmailVerificationSuccessAction
  | SignUpEmailVerificationFailedAction
  | SignUpStartAction
  | SignUpSuccessAction
  | SignUpFailedAction
  | SignUpErrorAction;
