import React, { FunctionComponent, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Notification } from "@spike/notifications-action";
import clsx from "clsx";
import { removeNotificationThunk } from "@spike/notifications-action";
import Check from "./Check";

interface BookedProps {
  notification: Notification;
  className?: string;
  rescheduled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notification: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: "white",
      borderRadius: "10px",
      border: "1px solid #DDDDDD",
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        borderRaidus: 8,
        padding: '12px',
        backgroundColor: '#5E8677'
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "8px",
        marginRight: "8px",
        minWidth: "185px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "10px",
        marginRight: "10px",
        minWidth: "246px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "10px",
        marginRight: "10px",
        minWidth: "372px",
      },
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "67px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "67px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "102px",
      },
    },
    icon: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "12px",
        marginRight: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: "15px",
        marginRight: "15px",
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: "30px",
        marginRight: "30px",
      },
    },
    textContainer: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingRight: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "8px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingRight: "12px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "black",
      [theme.breakpoints.down("sm")]: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 500
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "15px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "19px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "black",
      marginTop: "5px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
        lineHeight: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "12px",
        lineHeight: "14px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "15px",
        lineHeight: "17px",
      },
    },
    pet: {
      color: "#5E8677"
    }
  })
);

export const Booked: FunctionComponent<BookedProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useDispatch();

  const clickAlertHandler = (event: MouseEvent, notificationKey: string) => {
    event.stopPropagation();
    dispatch(removeNotificationThunk(notificationKey));
  };

  return (
    <Box
      key={props.notification.key}
      className={clsx(classes.notification, props.className)}
      onClick={(event) => clickAlertHandler(event, props.notification.key)}
    >
      {!isMobile && <>
        <Box className={classes.iconContainer}>
          <Check className={classes.icon} />
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>Appointment {props.rescheduled ? "Rescheduled" : "Booked"}</Typography>
          <Typography className={classes.text}>
            <span className={classes.pet}>{props.notification.appointment!.pet.name}'s appointment</span> was {props.rescheduled ? "rescheduled" : "booked"} <br /> for{" "}
            {props.notification.appointment!.duration.from.format("dddd, MMM D \\at h:mm a.")}
          </Typography>
        </Box>
      </>}

      {isMobile && <Box>
        <Typography className={classes.title}>🎉&nbsp;Appointment {props.rescheduled ? "Rescheduled" : "Booked"} Successfully</Typography>
      </Box>}
    </Box>
  );
};

export default Booked;
