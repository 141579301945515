import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { wbp } from "Theme";

interface BackProps {
  className?: string;
  onBack: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        width: "26px !important",
        height: "26px",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "33px !important",
        height: "33px",
      },
    },
  })
);

export const Back: FunctionComponent<BackProps> = (props) => {
  const classes = useStyles();

  return <FontAwesomeIcon icon={faArrowLeft} className={clsx(classes.icon, props.className)} onClick={props.onBack} />;
};

export default Back;
