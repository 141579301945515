import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PropsWithChildren } from "react";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface NewPetMedicalHistorySectionProps extends PropsWithChildren {
  title: string;
  icon: IconDefinition;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "8px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(22)}px`,
        lineHeight: `${reduceResolution(39)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "22px",
        lineHeight: "39px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    divider: {
      border: "0px solid #DDDDDD",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "5px",
      },
    },
    icon: {
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(22)}px`,
        marginRight: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "22px",
        marginRight: "8px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        marginRight: "10px",
      },
    },
    body: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(32)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "32px",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "20px",
      },      
    }
  })
);

const NewPetMedicalHistorySection: FunctionComponent<NewPetMedicalHistorySectionProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} className={props.className}>
      <Grid container xs={12} className={classes.header} alignItems="center">
        <FontAwesomeIcon icon={props.icon} className={classes.icon} />
        <Typography className={classes.title}>{props.title}</Typography>
      </Grid>
      <Grid className={classes.divider}>
        <Divider />
      </Grid>
      <Grid className={classes.body}>{props.children}</Grid>
    </Grid>
  );
};

export default NewPetMedicalHistorySection;
