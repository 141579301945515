import React, { FunctionComponent, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { DescriptionField2 } from "components/UI";
import useNonInitialEffect from "@versiondos/hooks";

interface Props {
    notes: string;
    onChangeNote?: (value: string) => void;
    onChange?: (note: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingBottom: 13,
            [theme.breakpoints.up('md')]: {
                paddingBottom: 15
            },
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "16px",
            padding: "0px 0px 18px 0px"
        },
        notesField: {
            fontSize: 14,
            lineHeight: "18px",
            "& textarea": { 
                height: "121px !important", 
                fontSize: 14,
                padding: "23px 19px",
                [theme.breakpoints.up('md')]: {
                    padding: "23px 28px",
                },
            }
        },
    })
);

export const Notes: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const [notes, setNotes] = useState<string>(props.notes ?? '');

    useNonInitialEffect(() => {
            const timer: NodeJS.Timer = setTimeout(() => {
                props.onChange && props.onChange(notes);
            }, 500);
            return () => clearTimeout(timer);
    }, [notes]);

    
    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Booking Notes</Typography>   
            <div id="booking_input_notes">
                <DescriptionField2
                    name="notes"
                    placeholder="Notes here..."
                    label=""
                    className={classes.notesField}
                    value={props.onChange ? notes : props.notes}
                    onChange={(value: string) => props.onChange ? setNotes(value) : props.onChangeNote!(value)}
                    maxRows={3}
                />
            </div>
        </Box>
    )
};

export default Notes