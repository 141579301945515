import {
  RevenuesAction,
  REVENUES_RESET_ACTION_TYPE,
  REVENUES_FETCH_TODAY_START_ACTION_TYPE,
  REVENUES_FETCH_TODAY_SUCCESS_ACTION_TYPE,
  REVENUES_FETCH_TODAY_CLIENT_START_ACTION_TYPE,
  REVENUES_FETCH_TODAY_CLIENT_SUCCESS_ACTION_TYPE,
  REVENUES_ERROR_ACTION_TYPE,
} from "./RevenuesActionsTypes";

import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction }from "@spike/notifications-action";
import { showError }from "@spike/notifications-action";
import store from "store";
import { getTodayAsYMD } from "@spike/date-utils";
import { RevenuesState } from "reducers/revenues/RevenuesState";
import { TodayRevenueDto } from "./RevenuewDto";

const revenuesUrl = "/revenues";
const revenuesCustomerUrl = '/revenue_by_customer';

export const fetchTodayRevenueThunk = (): ThunkAction<
  void,
  RevenuesState,
  null,
  RevenuesAction | NotificationsAction
> => {
  return async (dispatch) => {
    dispatch(fetchTodayStart());
    try {
      const marketplaceId = store.getState().login.auth.marketplaceId;
      const timeZone =
        store.getState().marketplace.marketplace?.schedule.timeZone || store.getState().login.auth.timeZone!;
      const today = getTodayAsYMD(timeZone);
      const url = `${revenuesUrl}/${today}?marketplace_id=${marketplaceId}`;

      const response: AxiosResponse<TodayRevenueDto> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchTodaySuccess(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching todays revenue."));
      }
    }
  };
};

export const fetchTodayRevenueCustomerThunk = (customerId: number, petIds: Array<number>): ThunkAction<
  void,
  RevenuesState,
  null,
  RevenuesAction | NotificationsAction
> => {
  return async (dispatch) => {
    dispatch(fetchTodayClientStart());
    try {
      const marketplaceId = store.getState().login.auth.marketplaceId;
      const url = `${revenuesCustomerUrl}/?marketplace_id=${marketplaceId}&customer_id=${customerId}&pet_id=${petIds}`;

      const response: AxiosResponse<TodayRevenueDto> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchTodayClientSuccess(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching todays revenue."));
      }
    }
  };
};

export const reset = (): RevenuesAction => {
  return { type: REVENUES_RESET_ACTION_TYPE };
};

const fetchTodayStart = (): RevenuesAction => {
  return { type: REVENUES_FETCH_TODAY_START_ACTION_TYPE };
};

export const fetchTodaySuccess = (responseDto: TodayRevenueDto): RevenuesAction => {
  return {
    type: REVENUES_FETCH_TODAY_SUCCESS_ACTION_TYPE,
    payload: {
      amount: responseDto.total,
    },
  };
};

const fetchTodayClientStart = (): RevenuesAction => {
  return { type: REVENUES_FETCH_TODAY_CLIENT_START_ACTION_TYPE };
};

export const fetchTodayClientSuccess = (responseDto: TodayRevenueDto): RevenuesAction => {
  return {
    type: REVENUES_FETCH_TODAY_CLIENT_SUCCESS_ACTION_TYPE,
    payload: {
      amountClient: responseDto.total,
    },
  };
};


const error = (): RevenuesAction => {
  return {
    type: REVENUES_ERROR_ACTION_TYPE,
  };
};
