import React, { FunctionComponent } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Client from '@spike/client-model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Chip } from 'components/UI';
import clsx from 'clsx';
import Tag from 'components/UI/V2/Tag/Tag';

interface Props {
    client?: Client;
    readOnly?: boolean;
    multiplePet: boolean;
    onRemoveClient: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (props: Props) =>
                props.multiplePet ? 'none' : '1px solid #D4D4D4',
            paddingBottom: 13,
            width: '100%',
            [theme.breakpoints.up('md')]: {
                paddingBottom: 15
            }
        },
        clientInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            paddingRight: 9,
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        },
        iconContainer: {
            textAlign: 'right',
            width: '5%'
        },
        iconTrash: {
            cursor: 'pointer',
            fontSize: 18
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            paddingBottom: 8
        },
        text: {
            fontSize: 14,
            lineHeight: '14px',
            paddingBottom: 8,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
                paddingRight: 5,
                width: '90%'
            }
        },
        tagsContainer: {
            gap: 6,
            display: 'flex',
            paddingTop: 3,
            width: '50%',
            flexWrap: 'wrap',
            marginBottom: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            [theme.breakpoints.up('md')]: {
                paddingRight: 5,
                width: '50%'
            }
        },
        expandedInfoContainer: {
            [theme.breakpoints.up('md')]: {
                width: '90% !important'
            }
        },
        tag: {
            'height': 24,
            'background': '#BCB8AE',
            'color': '#fff',
            '& .MuiChip-label': {
                fontSize: '13px !important'
            }
        },
        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const ClientResult: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    return (
        <Box>
            {!props.client ? (
                <CircularProgress size="1.5rem" style={{ color: '#EAB464' }} />
            ) : (
                <Box className={classes.container}>
                    <Box className={classes.clientInfoContainer}>
                        <Box
                            className={clsx(classes.infoContainer, {
                                [classes.expandedInfoContainer]:
                                    props.client.tags?.positive?.length === 0 &&
                                    props.client.tags?.negative?.length === 0
                            })}
                        >
                            <Typography className={classes.title}>
                                {props.client.firstName} {props.client.lastName}
                            </Typography>
                            <Typography className={classes.text}>
                                {props.client.phone}
                            </Typography>
                            <Typography className={classes.text}>
                                {props.client.email}
                            </Typography>

                            {props.client.blocked && (
                                <div className={classes.desktop}>
                                    <Tag size="small" variant="danger">
                                        Blocked
                                    </Tag>
                                </div>
                            )}
                        </Box>
                        {(props.client.tags?.positive?.length > 0 ||
                            props.client.tags?.negative?.length > 0 ||
                            props.client.blocked) && (
                            <Box className={classes.tagsContainer}>
                                {props.client.blocked && (
                                    <Tag
                                        size="small"
                                        variant="danger"
                                        className={classes.mobile}
                                    >
                                        Blocked
                                    </Tag>
                                )}
                                {props.client.tags?.positive.map(tag => {
                                    return (
                                        <Chip
                                            key={tag.id}
                                            label={tag.name}
                                            className={classes.tag}
                                        />
                                    );
                                })}
                                {props.client.tags?.negative.map(tag => {
                                    return (
                                        <Chip
                                            key={tag.id}
                                            label={tag.name}
                                            className={classes.tag}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                    {!props.readOnly && (
                        <Box
                            id="booking_img_delete"
                            className={classes.iconContainer}
                        >
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className={classes.iconTrash}
                                onClick={props.onRemoveClient}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ClientResult;
