import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { onboardingShowVideo } from 'actions/onboardingSteps/OnboardingStepsActions';
import { ONBOARDING_VIDEOS } from 'components/OnboardingSteps/videos';
import OnboardingTutorial from 'components/OnboardingTutorial';
import { ResultNotFound } from 'components/Services/ResultsNotFound';
import { Spinner } from 'components/UI/Spinner';
import { OnboardingSteps } from 'model/OnboardingSteps';
import Staff from 'model/Staff';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StaffStatus } from 'reducers/staff/StaffState';
import { RootState } from 'store';
import { StaffList } from './StaffList';
import { HeaderSearchable } from 'components/UI/HeaderSearchable/HeaderSearchable';
import { resendInvitationThunk } from 'actions/staff/StaffActions';

interface Props {
    staff: Array<Staff>;
    editStaff: (staffMemberId: number) => void;
    deleteStaff: (member: Staff) => void;
    addStaff: () => void;
    activate: (member: Staff) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFF'
        },
        header: {
            marginBottom: '30px'
        },

        selectorBarContainer: {
            display: 'flex',
            marginLeft: '100px',
            marginBottom: '20px',
            [theme.breakpoints.down('lg')]: {
                marginLeft: '5%'
            }
        },
        resultNotFound: {
            height: '60vh'
        },
        onboarding: {
            paddingTop: '0px',
            marginTop: '-60px'
        }
    })
);

export const StaffListComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const dispatch = useDispatch();
    const staffStatus = useSelector<RootState, StaffStatus>(
        state => state.staff.status
    );
    const onboardingSteps = useSelector<RootState, OnboardingSteps>(
        state => state.onboardingSteps.onboardingSteps
    );

    const [staffList, setStaffList] = useState(props.staff);
    const [textSearch, setTextSearch] = useState('');
    const [statusPills, setStatusPills] = useState('active');

    useEffect(() => {
        setStaffList(props.staff.filter(item => item.active));

        onSelectFilter(statusPills);
        // eslint-disable-next-line
    }, [props.staff]);

    const allOption: Option<string> = {
        id: 'all',
        name: 'All'
    };

    const activeOption: Option<string> = {
        id: 'active',
        name: 'Active'
    };

    const inactiveOption: Option<string> = {
        id: 'inactive',
        name: 'Inactive'
    };

    const filterOptions: Array<Option<string>> = [
        activeOption,
        inactiveOption,
        allOption
    ];

    const handleSearchMemberName = (text: string): void => {
        setTextSearch(text);

        if (statusPills === 'inactive') {
            const staffListAux = props.staff.filter(item => {
                const name = `${item.person.firstName.toLowerCase()} ${item.person.lastName.toLowerCase()}`;
                return (
                    !item.active &&
                    (text.length === 0 || name.indexOf(text.toLowerCase()) > -1)
                );
            });
            setStaffList(staffListAux);
        } else if (statusPills === 'active') {
            const staffListAux = props.staff.filter(item => {
                const name = `${item.person.firstName.toLowerCase()} ${item.person.lastName.toLowerCase()}`;
                return (
                    item.active &&
                    (text.length === 0 || name.indexOf(text.toLowerCase()) > -1)
                );
            });
            setStaffList(staffListAux);
        } else {
            const staffListAux = props.staff.filter(item => {
                const name = `${item.person.firstName.toLowerCase()} ${item.person.lastName.toLowerCase()}`;
                return (
                    text.length === 0 || name.indexOf(text.toLowerCase()) > -1
                );
            });
            setStaffList(staffListAux);
        }
    };

    const onSelectFilter = (id: string) => {
        setStatusPills(id);
        if (id === 'inactive') {
            const staffListAux = props.staff.filter(item => {
                const name = `${item.person.firstName.toLowerCase()} ${item.person.lastName.toLowerCase()}`;
                return (
                    !item.active &&
                    (textSearch.length === 0 ||
                        name.indexOf(textSearch.toLowerCase()) > -1)
                );
            });
            setStaffList(staffListAux);
        } else if (id === 'active') {
            const staffListAux = props.staff.filter(item => {
                const name = `${item.person.firstName.toLowerCase()} ${item.person.lastName.toLowerCase()}`;
                return (
                    item.active &&
                    (textSearch.length === 0 ||
                        name.indexOf(textSearch.toLowerCase()) > -1)
                );
            });
            setStaffList(staffListAux);
        } else {
            const staffListAux = props.staff.filter(item => {
                const name = `${item.person.firstName.toLowerCase()} ${item.person.lastName.toLowerCase()}`;
                return (
                    textSearch.length === 0 ||
                    name.indexOf(textSearch.toLowerCase()) > -1
                );
            });
            setStaffList(staffListAux);
        }
    };

    const handleActivate = (activateStaffarr: Staff) => {
        onSelectFilter(statusPills);
        props.activate(activateStaffarr);
    };

    const handleEdit = (staffId: number) => {
        props.editStaff(staffId);
    };

    const handleResendInvitation = (staffId: number) => {
        dispatch(resendInvitationThunk(staffId));
    };

    const onboardingPageParams = isMobile
        ? {
              image: 'images/onboarding/emptyscreen-staff-mobile.svg',
              imageWidth: '100%',
              imageHeight: 'auto'
          }
        : {
              image: 'images/onboarding/team.svg',
              imageWidth: 1027,
              imageHeight: 539
          };

    const onboarding = (
        <OnboardingTutorial
            urlImage={onboardingPageParams.image}
            imageWidth={onboardingPageParams.imageWidth}
            imageHeight={onboardingPageParams.imageHeight}
            onPressLinkToVideo={() => {
                dispatch(onboardingShowVideo(ONBOARDING_VIDEOS.TEAM_SCHEDULE));
            }}
            className={classes.onboarding}
            isMobile={isMobile}
        />
    );

    return (
        <Box className={classes.container}>
            <OverlayScrollbarsComponent style={{ height: '100%' }}>
                <HeaderSearchable
                    title="Staff"
                    button={{
                        label: 'Add Member',
                        id: 'staff_button_add_member',
                        onClick: props.addStaff
                    }}
                    filter={{
                        selectedId: statusPills,
                        options: filterOptions,
                        onSelect: onSelectFilter
                    }}
                    search={{
                        onSearch: handleSearchMemberName,
                        placeholder: 'Search by member name',
                        id: 'staff_filter_search_by_member'
                    }}
                />

                <Box style={{ width: '100%' }}>
                    {staffList.length > 0 && (
                        <StaffList
                            staff={staffList}
                            editStaff={handleEdit}
                            deleteStaff={props.deleteStaff}
                            activate={handleActivate}
                            sortedStaff={sortedStaff =>
                                setStaffList(sortedStaff)
                            }
                            resendInvitation={handleResendInvitation}
                        />
                    )}
                    {staffList.length === 0 && onboardingSteps.teamDone && (
                        <Box className={classes.resultNotFound}>
                            {staffStatus !== StaffStatus.Fetching ? (
                                <ResultNotFound
                                    title={
                                        <Fragment>Nothing to see here</Fragment>
                                    }
                                    message={
                                        <Fragment>
                                            There are currently no{' '}
                                            {statusPills === 'active'
                                                ? 'active'
                                                : statusPills === 'inactive'
                                                ? 'inactive'
                                                : ''}{' '}
                                            staff members.
                                            <br />
                                            Just keep Cuddling!
                                        </Fragment>
                                    }
                                />
                            ) : (
                                <Spinner />
                            )}
                        </Box>
                    )}
                    {!onboardingSteps.teamDone && onboarding}
                </Box>
            </OverlayScrollbarsComponent>
        </Box>
    );
};

export default StaffListComponent;
