import ApiClient, {
    createMultipartTokenConfig,
    createTokenConfig,
    isCancelled
} from 'api/ApiClient';
import { AxiosResponse } from 'axios';
import { Agreement, AgreementSummary, Signature } from 'model/Agreements';
import moment from 'moment-timezone';
import { showError, showSuccess } from '@spike/notifications-action';
import { NotificationsAction } from '@spike/notifications-action';
import { serialize } from 'object-to-formdata';
import { AgreementsState } from 'reducers/agreements/AgreementsState';
import { ThunkAction } from 'redux-thunk';
import store from 'store';
import {
    AgreementsAction,
    AGREEMENTS_DELETE_SIGNATURE_START,
    AGREEMENTS_DELETE_SIGNATURE_SUCCESS,
    AGREEMENTS_DELETE_START,
    AGREEMENTS_DELETE_SUCCESS,
    AGREEMENTS_DOWNLOAD_PDF_START,
    AGREEMENTS_DOWNLOAD_PDF_SUCCESS,
    AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_START,
    AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_SUCCESS,
    AGREEMENTS_ERROR,
    AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_START,
    AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_SUCCESS,
    AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_START,
    AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_SUCCESS,
    AGREEMENTS_FETCH_START,
    AGREEMENTS_FETCH_SUCCESS,
    AGREEMENTS_GET_SIGNATURE_BY_HASH_START,
    AGREEMENTS_GET_SIGNATURE_BY_HASH_SUCCESS,
    AGREEMENTS_GET_SUMMARY_START,
    AGREEMENTS_GET_SUMMARY_SUCCESS,
    AGREEMENTS_PRINT_SIGNATURE_START,
    AGREEMENTS_PRINT_SIGNATURE_SUCCESS,
    AGREEMENTS_PRINT_START,
    AGREEMENTS_PRINT_SUCCESS,
    AGREEMENTS_RESEND_START,
    AGREEMENTS_RESEND_SUCCESS,
    AGREEMENTS_RESET,
    AGREEMENTS_SAVE_START,
    AGREEMENTS_SAVE_SUCCESS,
    AGREEMENTS_SEND_SIGNATURE_PDF_START,
    AGREEMENTS_SEND_SIGNATURE_PDF_SUCCESS,
    AGREEMENTS_SEND_START,
    AGREEMENTS_SEND_SUCCESS,
    AGREEMENTS_SIGN_START,
    AGREEMENTS_SIGN_SUCCESS
} from './AgreementsActionsTypes';
import {
    convertToAgreement,
    convertToSignature,
    convertToSummary
} from './AgreementsConverter';
import {
    AgreementDto,
    AgreementSignatureDto,
    SummaryDto
} from './AgreementsDtos';
import { debugConsoleLog } from 'utils/GeneralUtils';

const agreementsUrl = '/agreements';
const signaturesUrl = '/signatures';
const agreementUrl = '/agreement';
const signaturesByStatusUrl = '/signatures_status';
const signatureUrl = '/signature';

export const fetchAgreementsThunk = (): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${agreementsUrl}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<Array<AgreementDto>> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(
                fetchSuccess(response.data.map(dto => convertToAgreement(dto)))
            );
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching agreements.'));
            }
        }
    };
};

export const saveThunk = (
    agreement: Agreement
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(saveStart());

        const url = agreement.id
            ? `${agreementsUrl}/${agreement.id}`
            : agreementsUrl;
        const apiMethod = agreement.id ? ApiClient.patch : ApiClient.post;

        const marketplaceId = store.getState().marketplace.marketplace.id;

        try {
            const agreementRequest = {
                active: agreement.active,
                content: agreement.content,
                id: agreement.id,
                mandatory: agreement.mandatory,
                marketplace_id: marketplaceId,
                send_to: agreement.sendTo,
                title: agreement.title,
                uuid: agreement.uuid,
                client: agreement.client,
                channel: agreement.channel,
                required: agreement.required,
                signed_at: agreement.signedAt,
                status: agreement.status
            };

            const response: AxiosResponse<AgreementDto> = await apiMethod(
                url,
                agreementRequest,
                createTokenConfig(store.getState().login.auth.token!)
            );

            dispatch(saveSuccess(convertToAgreement(response.data)));

            dispatch(showSuccess('Agreement saved!'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error saving agreements.'));
            }
        }
    };
};

export const deleteThunk = (
    agreementId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(deleteStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${agreementsUrl}/${agreementId}?marketplace_id=${marketplaceId}`;

        try {
            await ApiClient.delete(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(deleteSuccess(agreementId));
            dispatch(showSuccess('Agreement deleted.'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error deleting agreement.'));
            }
        }
    };
};

export const deleteSignatureThunk = (
    signatureId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(deleteSignatureStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${signaturesUrl}/${signatureId}?marketplace_id=${marketplaceId}`;

        try {
            await ApiClient.delete(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(deleteSignatureSuccess(signatureId));
            dispatch(showSuccess('Signature deleted.'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error deleting signature.'));
            }
        }
    };
};

export const signThunk = (
    marketplaceId: number,
    signatureId: number,
    signatureFile: File
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(signStart());

        const url = `${signaturesUrl}/${signatureId}`;

        try {
            const signatureRequest = {
                marketplace_id: marketplaceId,
                id: signatureId,
                signed_at: moment().tz('UTC').format('YYYY-MM-DDTHH:mm:ss'),
                customer_signature: signatureFile
            };

            let formData = new FormData();

            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };

            formData = serialize(
                signatureRequest,
                options,
                formData,
                'signature'
            );
            await ApiClient.patch(
                url,
                formData,
                createMultipartTokenConfig(store.getState().login.auth.token!)
            );

            dispatch(signSuccess());

            dispatch(showSuccess('Agreement signed!'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(showError('Error signing agreements.'));
                dispatch(error());
            }
        }
    };
};

export const fetchSignaturesByClientThunk = (
    clientId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchSignaturesByClientStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const urlGet = `${signaturesUrl}?marketplace_id=${marketplaceId}&customer_id=${clientId}`;

        try {
            const response: AxiosResponse<Array<AgreementSignatureDto>> =
                await ApiClient.get(
                    urlGet,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(
                fetchSignaturesByClientSuccess(
                    response.data.map(dto => convertToSignature(dto))
                )
            );
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching signatures by client.'));
            }
        }
    };
};

export const fetchSignaturesByAgreementThunk = (
    agreementId: number,
    status?: string
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchSignaturesByAgreementStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const urlGet = `${signaturesByStatusUrl}/${
            status || ''
        }?marketplace_id=${marketplaceId}&agreement_id=${agreementId}`;

        try {
            const response: AxiosResponse<Array<AgreementSignatureDto>> =
                await ApiClient.get(
                    urlGet,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(
                fetchSignaturesByAgreementSuccess(
                    response.data.map(dto => convertToSignature(dto))
                )
            );
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching signatures by agreement.'));
            }
        }
    };
};

export const downloadAgreementPDFThunk = (
    agreementId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(dispatch(downloadPdfStart()));
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${agreementUrl}/${agreementId}/download?marketplace_id=${marketplaceId}`;

        try {
            const response = await ApiClient.get(url, {
                ...createTokenConfig(store.getState().login.auth.token!),
                responseType: 'blob'
            });

            const pdfUrl = window.URL.createObjectURL(
                new Blob([response.data])
            );
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'Agreement.pdf');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(pdfUrl);

            dispatch(downloadPdfSuccess());
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error downloading PDF.'));
            }
        }
    };
};

export const downloadSignaturePDFThunk = (
    signatureId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(dispatch(downloadSignaturePdfStart()));
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${signatureUrl}/${signatureId}/download?marketplace_id=${marketplaceId}`;

        try {
            const response = await ApiClient.get(url, {
                ...createTokenConfig(store.getState().login.auth.token!),
                responseType: 'blob'
            });

            const pdfUrl = window.URL.createObjectURL(
                new Blob([response.data])
            );
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'Signature.pdf');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(pdfUrl);

            dispatch(downloadSignaturePdfSuccess());
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error downloading signature PDF.'));
            }
        }
    };
};

export const printAgreementThunk = (
    agreementId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(dispatch(printStart()));
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${agreementUrl}/${agreementId}/download?marketplace_id=${marketplaceId}`;

        try {
            const response = await ApiClient.get(url, {
                ...createTokenConfig(store.getState().login.auth.token!),
                responseType: 'blob'
            });

            const pdfUrl = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
            );
            window.open(pdfUrl, '__blank');
            window.URL.revokeObjectURL(pdfUrl);

            dispatch(printSuccess());
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error printing agreement.'));
            }
        }
    };
};

export const printSignatureThunk = (
    signatureId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(dispatch(printSignatureStart()));
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${signatureUrl}/${signatureId}/download?marketplace_id=${marketplaceId}`;

        try {
            const response = await ApiClient.get(url, {
                ...createTokenConfig(store.getState().login.auth.token!),
                responseType: 'blob'
            });

            const pdfUrl = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
            );
            window.open(pdfUrl);
            window.URL.revokeObjectURL(pdfUrl);

            dispatch(printSignatureSuccess());
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error printing signature.'));
            }
        }
    };
};

export const getSummaryThunk = (
    agreementId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getSummaryStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${agreementsUrl}/${agreementId}/summary?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<SummaryDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );

            dispatch(getSummarySuccess(convertToSummary(response.data)));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error getting summary.'));
            }
        }
    };
};

export const sendThunk = (
    agreementId: number,
    clientIds: Array<number>,
    channel: 'email' | 'sms'
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(sendStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;

        try {
            const response: AxiosResponse<SummaryDto> = await ApiClient.post(
                `${agreementsUrl}/${agreementId}/send?marketplace_id=${marketplaceId}`,
                {
                    marketplace_id: marketplaceId,
                    customer_ids: clientIds,
                    sms: channel === 'sms',
                    email: channel === 'email'
                },
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(sendSuccess(convertToSummary(response.data)));
            dispatch(showSuccess('Agreement sent!'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(showError('Error sending agreement.'));
                dispatch(error());
            }
        }
    };
};

export const resendThunk = (
    signatureId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(resendStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;

        try {
            const response: AxiosResponse<AgreementSignatureDto> =
                await ApiClient.post(
                    `${signatureUrl}/${signatureId}/resend?marketplace_id=${marketplaceId}`,
                    '',
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(resendSuccess(convertToSignature(response.data)));
            dispatch(showSuccess('Agreement resent!'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(showError('Error resending agreement.'));
                dispatch(error());
            }
        }
    };
};

export const sendSignaturePdfThunk = (
    signatureId: number
): ThunkAction<
    void,
    AgreementsState,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(sendSignaturePdfStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;

        try {
            const response: AxiosResponse<AgreementSignatureDto> =
                await ApiClient.post(
                    `${signatureUrl}/${signatureId}/send_pdf?marketplace_id=${marketplaceId}`,
                    '',
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(
                sendSignaturePdfSuccess(convertToSignature(response.data))
            );
            dispatch(showSuccess('Signature pdf sent!'));
        } catch (apiError) {
            console.error({ apiError });
            if (!isCancelled(apiError)) {
                dispatch(showError('Error sending signature pdf.'));
                dispatch(error());
            }
        }
    };
};

export const getSignatureByHashThunk = (
    hash: string
): ThunkAction<
    void,
    NotificationsAction,
    null,
    AgreementsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getSignatureByHashStart());
        try {
            const response: AxiosResponse<AgreementSignatureDto> =
                await ApiClient.get(
                    `${agreementsUrl}/${hash}/lookup`,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(
                getSignatureByHashSuccess(convertToSignature(response.data))
            );
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(showError('Error getting signature by hash.'));
                dispatch(error());
            }
        }
    };
};

export const reset = (): AgreementsAction => {
    return { type: AGREEMENTS_RESET };
};

const signStart = (): AgreementsAction => {
    return { type: AGREEMENTS_SIGN_START };
};

const signSuccess = (): AgreementsAction => {
    return { type: AGREEMENTS_SIGN_SUCCESS };
};
export const fetchSignaturesByClientStart = (): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_START
    };
};

export const fetchSignaturesByClientSuccess = (
    signatures: Array<Signature>
): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_SUCCESS,
        payload: {
            signatures
        }
    };
};

export const fetchSignaturesByAgreementStart = (): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_START
    };
};

export const fetchSignaturesByAgreementSuccess = (
    signatures: Array<Signature>
): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_SUCCESS,
        payload: {
            signatures
        }
    };
};

const fetchStart = (): AgreementsAction => {
    return { type: AGREEMENTS_FETCH_START };
};

const fetchSuccess = (agreements: Array<Agreement>): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_SUCCESS,
        payload: {
            agreements
        }
    };
};

const downloadPdfStart = (): AgreementsAction => {
    return { type: AGREEMENTS_DOWNLOAD_PDF_START };
};

const downloadPdfSuccess = (): AgreementsAction => {
    return { type: AGREEMENTS_DOWNLOAD_PDF_SUCCESS };
};

const downloadSignaturePdfStart = (): AgreementsAction => {
    return { type: AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_START };
};

const downloadSignaturePdfSuccess = (): AgreementsAction => {
    return { type: AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_SUCCESS };
};

const printStart = (): AgreementsAction => {
    return { type: AGREEMENTS_PRINT_START };
};

const printSuccess = (): AgreementsAction => {
    return { type: AGREEMENTS_PRINT_SUCCESS };
};

const printSignatureStart = (): AgreementsAction => {
    return { type: AGREEMENTS_PRINT_SIGNATURE_START };
};

const printSignatureSuccess = (): AgreementsAction => {
    return { type: AGREEMENTS_PRINT_SIGNATURE_SUCCESS };
};

const getSummaryStart = (): AgreementsAction => {
    return { type: AGREEMENTS_GET_SUMMARY_START };
};

const getSummarySuccess = (summary: AgreementSummary): AgreementsAction => {
    return {
        type: AGREEMENTS_GET_SUMMARY_SUCCESS,
        payload: {
            summary
        }
    };
};

const getSignatureByHashStart = (): AgreementsAction => {
    return { type: AGREEMENTS_GET_SIGNATURE_BY_HASH_START };
};

const getSignatureByHashSuccess = (signature: Signature): AgreementsAction => {
    return {
        type: AGREEMENTS_GET_SIGNATURE_BY_HASH_SUCCESS,
        payload: {
            signature
        }
    };
};

const saveStart = (): AgreementsAction => {
    return { type: AGREEMENTS_SAVE_START };
};

const saveSuccess = (agreement: Agreement): AgreementsAction => {
    return {
        type: AGREEMENTS_SAVE_SUCCESS,
        payload: {
            agreement
        }
    };
};

const resendStart = (): AgreementsAction => {
    return { type: AGREEMENTS_RESEND_START };
};

const resendSuccess = (signature: Signature): AgreementsAction => {
    return {
        type: AGREEMENTS_RESEND_SUCCESS,
        payload: {
            signature
        }
    };
};

const sendStart = (): AgreementsAction => {
    return { type: AGREEMENTS_SEND_START };
};

const sendSuccess = (summary: AgreementSummary): AgreementsAction => {
    return {
        type: AGREEMENTS_SEND_SUCCESS,
        payload: {
            summary
        }
    };
};

const sendSignaturePdfStart = (): AgreementsAction => {
    return { type: AGREEMENTS_SEND_SIGNATURE_PDF_START };
};

const sendSignaturePdfSuccess = (signature: Signature): AgreementsAction => {
    return {
        type: AGREEMENTS_SEND_SIGNATURE_PDF_SUCCESS,
        payload: {
            signature
        }
    };
};

const deleteStart = (): AgreementsAction => {
    return { type: AGREEMENTS_DELETE_START };
};

const deleteSuccess = (agreementId: number): AgreementsAction => {
    return {
        type: AGREEMENTS_DELETE_SUCCESS,
        payload: {
            agreementId
        }
    };
};

const deleteSignatureStart = (): AgreementsAction => {
    return { type: AGREEMENTS_DELETE_SIGNATURE_START };
};

const deleteSignatureSuccess = (signatureId: number): AgreementsAction => {
    return {
        type: AGREEMENTS_DELETE_SIGNATURE_SUCCESS,
        payload: {
            signatureId
        }
    };
};

export const error = (): AgreementsAction => {
    return {
        type: AGREEMENTS_ERROR
    };
};
