import { Dispatch, SetStateAction, useState } from "react";

export const useToggle = (
  initialState: boolean = false
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [boolean, setBoolean] = useState<boolean>(initialState);

  const changeBooleanHandler = () => setBoolean((prev) => !boolean);

  return [boolean, changeBooleanHandler, setBoolean];
};
