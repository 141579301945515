import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useSwitchStyles = makeStyles((theme: Theme) =>{

  const slideTransitionTime = '0.15s'
  const backgroundTransitionTime = '0.2s'
  return createStyles({
    root: {
      background: 'rgb(211, 211, 211)',
      width: 41,
      minWidth:41,
      height: 22,
      minHeight:22,
      borderRadius: 50,
      position: 'relative',
      overflow: 'hidden',
      transition: `background ${backgroundTransitionTime} linear`,
    },
    checked: {
      background: '#5E8677',
    },
    input: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      opacity: 0,
      '&:disabled':{
        cursor:'default'
      }
    },
    slide: {
      transition: `all ${slideTransitionTime} ease-in-out`,
      borderRadius: '50%',
      height: 16,
      width: 16,
      minWidth:16,
      minHeight:16,
      background: '#E9F0ED',
      position: 'absolute',
      top: '50%',
      transform: 'translate(4px, -50%)',
      left: 0,
    },
    slideChecked: {
      left: 10,
      transform: 'translate(calc(100% - 5px), -50%)',
    },
    disabled:{
      opacity:0.60,
      cursor:'default'
    }
  })
}
);