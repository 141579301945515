import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from 'components/UI/Button';
import { ServicesPath, StaffPath } from '../../routes/Paths';
import { wbp, reduceResolution } from 'Theme';

interface Props {
	memberName: string;
	memberEmail?: string;
	active: boolean;
	onCreateService: () => void;
	onDoLater: () => void;
	onGoBack: () => void;
	onAddAnother?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			margin: 'auto 0px',
			textAlign: 'center',
			padding: '0px 16px'
		},
		checkIcon: {
			width: 80,
			height: 80,
			fontSize: 32,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: '0px auto 8px',

			color: '#fff',
			borderRadius: '100%',
			backgroundColor: '#5E8677',

			[theme.breakpoints.up('md')]: {
				width: 92,
				height: 92,
				fontSize: 46
			}
		},
		title: {
			fontSize: 20,
			fontWeight: 600,
			color: '#222222',
			marginBottom: 12,

			[theme.breakpoints.up('md')]: {
				fontSize: 28
			}
		},
		subtitle: {
			fontSize: 15,
			fontWeight: 400,
			marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				marginLeft: '12px',
				marginRight: '12px'
			}
		},
		description: {
			textAlign: 'center',
			width: '100%',
			minWidth: '400px',
			[theme.breakpoints.down('lg')]: {
				fontSize: '15px',
				marginBottom: '34px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '20px',
				marginBottom: '45px'
			}
		},
		buttons: {
			'gap': 20,
			'display': 'flex',
			'alignItems': 'center',

			[theme.breakpoints.up('md')]: {
				justifyContent: 'center'
			},

			'& a': {
				[theme.breakpoints.down('sm')]: {
					width: '100%'
				}
			},
			'& button': {
				[theme.breakpoints.down('sm')]: {
					height: 55,
					width: '100%',
					borderRadius: 30
				}
			}
		},
		goBackButton: {
			'border': '#000 2px solid',
			'color': '#000',
			'backgroundColor': '#fff',
			'&:hover': {
				backgroundColor: '#F4F4F4',
				color: '#222'
			}
		},

		button: {
			[theme.breakpoints.down('lg')]: {
				minWidth: '111px',
				height: '43px',
				fontSize: '13px',
				marginBottom: '32px'
			},
			[theme.breakpoints.up('xl')]: {
				minWidth: '148px',
				height: '57px',
				fontSize: '18px',
				marginBottom: '43px'
			}
		},
		buttonLink: {
			textAlign: 'center',
			textDecoration: 'none'
		},
		doLater: {
			textDecoration: 'none',
			color: '#000',
			textAlign: 'center',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			}
		}
	})
);

export const StaffSavedSuccess: FunctionComponent<Props> = props => {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<Box className={classes.checkIcon}>
				<FontAwesomeIcon icon={faCheck} />
			</Box>
			<Typography className={classes.title}>
				{props.memberName} was added successfully.
			</Typography>
			<Typography className={classes.subtitle}>
				An invitation has been sent to <b>{props.memberEmail}</b> to log
				in your business
			</Typography>
			<Box>
				<Box className={classes.buttons}>
					<Link to={'#'} className={classes.buttonLink}>
						<Button
							id="staff_success_bottom_go_to_staff"
							label="Go to staff"
							onClick={props.onGoBack}
							className={classes.goBackButton}
						/>
					</Link>
					<Link to={'#'} className={classes.buttonLink}>
						<Button
							label="Add Another"
							onClick={props.onAddAnother}
							color="green"
						/>
					</Link>
				</Box>
			</Box>
			{!props.active && (
				<Box>
					<Typography className={classes.description}>
						{props.memberName} will remain <strong>inactive</strong>{' '}
						until your assign at least <br />
						one service to her profile.
					</Typography>
					<Box className={classes.buttons}>
						<Link to={ServicesPath} className={classes.buttonLink}>
							<Button
								label="+ Create Service"
								onClick={() => {}}
								className={classes.button}
							/>
						</Link>
						<Link
							to={'#'}
							className={classes.doLater}
							onClick={props.onDoLater}
						>
							I’ll do this later
						</Link>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default StaffSavedSuccess;
