import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Option } from "@spike/model";
import useNonInitialEffect from "@versiondos/hooks";
import { fetchThunk, deleteThunk, changeStateThunk, AddOnServicesStatus } from "@spike/addon-services-action";
import {Button, ConfirmDialog, OverFullWindow, Spinner} from "components/UI";
import AddOnService, { createEmptyAddOnService } from "@spike/addon-service-model";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import AddOnServiceForm from "./AddOnServiceForm";
import ServicesAddonsEmpty from "./Empty";
import ServicesAddonsHeader from "./Header";
import Table from "./Table";
import Filters from "./Table/Filters";
import Search from "./Table/Search";
import isEmpty from "lodash/isEmpty";
import { v4 as uuid } from "uuid";
import { useApiClientWrapper } from "hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      borderTop: "2px solid #F1F1F1",
    },
    containerSuccess: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down('sm')]: {
        height: "90%",
      },
    },
    checkIcon: {
      justifyContent: "center",
      color: '#EAB464',
      [theme.breakpoints.down("lg")]: {
        fontSize: "82px",
        marginBottom: "7px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "110px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        visibility: 'hidden',
        height: 0
      }
    },
    checkIconSm: {
      justifyContent: "center",
      color: '#92B4A7',
      [theme.breakpoints.down("lg")]: {
        fontSize: "82px",
        marginBottom: "7px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "110px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        visibility: 'hidden',
        height: 0
      }
    },
    titleSuccess: {
      fontSize: 20,
      fontWeight: 600,

      [theme.breakpoints.up('md')]: {
        fontSize: 40,
        color: '#92B4A7'
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 600,

      [theme.breakpoints.up('md')]: {
        fontSize: 27,
        color: '#92B4A7'
      }
    },
    descriptionSuccess: {
      textAlign: "center",
      width: "100%",
      minWidth: "400px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
        marginBottom: "34px",
        paddingRight: 20,
        paddingLeft: 20,
        maxWidth: '80%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "30px",
        marginBottom: "45px",
      },
      [theme.breakpoints.down("sm")]: {
        display: 'inline-flex',
        justifyContent: 'center',
        '& p:first-child': {
          paddingRight: 5,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
      width: '100%',
      padding: '0px 16px',
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        position: 'absolute',
        bottom: '0px'
      },
    },
    button: {
      [theme.breakpoints.down("lg")]: {
        minWidth: "111px",
        height: "55px",
        fontSize: "13px",
        marginBottom: "32px",
        borderRadius: 30
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: "148px",
        height: "57px",
        fontSize: "18px",
        marginBottom: "43px",
      },
    },
    buttonServices: {
      backgroundColor: "#92B4A7",
      "&:hover": {
        backgroundColor: "#92B4A7",
      },
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "black",
      }
    },
    containerMainSuccess: {
      width: '100%',
      alignSelf: 'center',
      [theme.breakpoints.down("sm")]: {
        height: 'inherit',
        backgroundColor: 'white'
      }
    },
    labelServiceName: {
      fontSize: 24,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      },
    },
    labelDescriptionSuccess: {
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        whiteSpace: 'nowrap'
      },
    }
  })
);

const ActiveFilterOption: Option<string> = {
  id: "active",
  name: "Active",
};

const InactiveFilterOption: Option<string> = {
  id: "inactive",
  name: "Inactive",
};

const AllFilterOption: Option<string> = {
  id: "all",
  name: "All",
};

const FilterOptions: Array<Option<string>> = [ActiveFilterOption, InactiveFilterOption, AllFilterOption];

const filterByState = (service: AddOnService, selectedFilterId: string): boolean => {
  switch (selectedFilterId) {
    case ActiveFilterOption.id:
      return service.active;
    case InactiveFilterOption.id:
      return !service.active;
    default:
      return true;
  }
};

const match = (text: string, service: AddOnService): boolean => {
  if (isEmpty(text)) {
    return true;
  } else {
    const tokens = text.split(" ").map(token => token.toLowerCase());
    return tokens.every((token) => service.name.toLowerCase().indexOf(token) > -1);
  }
};

export const AddOnServices: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();
  const [width] = useState(window.innerWidth);
  const serviceStatus = useSelector<RootState, AddOnServicesStatus>((state) => state.addOnServices.status);
  const services = useSelector<RootState, Array<AddOnService>>((state) => state.addOnServices.services);
  const isSm = width < 415;
  const [loading, setLoading] = useState(true);
  const [selectedFilterId, setSelectedFilterId] = useState(ActiveFilterOption.id);
  const [searchText, setSearchText] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [editedService, setEditedService] = useState<AddOnService | null>(null);
  const [deleteService, setDeleteService] = useState<AddOnService | null>(null);

  const filteredServices = services
    .filter((service) => filterByState(service, selectedFilterId))
    .filter((service) => match(searchText, service));

  useEffect(() => {
    dispatch(fetchThunk(apiClientWrapper));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNonInitialEffect(() => {
    switch (serviceStatus) {
      case AddOnServicesStatus.FetchSuccess:
        setLoading(false);
        break;
      case AddOnServicesStatus.Error:
        setLoading(false);
    }
  }, [serviceStatus]);

  const hideSuccess = () => {
    setShowSuccess(false);
    setEditedService(null);
  }

  const success = (
      <Box className={classes.containerMainSuccess}>
        <Box className={classes.containerSuccess}>
          <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
          <FontAwesomeIcon className={classes.checkIconSm} icon={faCheckCircle} />
          <Typography className={classes.titleSuccess}>{"Good Job!"}</Typography>
          {editedService && (
              <Box className={classes.descriptionSuccess}>
                <Typography className={classes.labelServiceName}>
                  <strong>{editedService.name } </strong>
                </Typography>
                <Typography className={classes.labelDescriptionSuccess}>
                  was added successfully.
                </Typography>
              </Box>
          )}
        </Box>
        <Box className={classes.buttons}>
          <Button  id="services_success_button_go_to_services_addon" label="Go to Add-Ons" onClick={() => hideSuccess()} className={clsx(classes.button, classes.buttonServices)} />
        </Box>
      </Box>
  )

  const table = (
    <>
      <Search onSearch={setSearchText} />
      <Filters selectedId={selectedFilterId} options={FilterOptions} onChange={setSelectedFilterId} />
      <Table
        showStatus={selectedFilterId === AllFilterOption.id}
        services={filteredServices}
        onEdit={setEditedService}
        onDelete={setDeleteService}
        onChangeStatus={(service) => dispatch(changeStateThunk(apiClientWrapper, service.id!, service.active))}
      />
    </>
  );
  return (
    <>
      {showSuccess && success}
      {!editedService && !showSuccess && (
          <Box className={classes.container}>
            <ServicesAddonsHeader
                onAdd={services.length > 0 ? () => setEditedService(createEmptyAddOnService(uuid)) : undefined}
            />
            {loading && <Spinner />}
            {!loading && services.length > 0 && table}
            {!loading && services.length === 0 && (
                <ServicesAddonsEmpty onAdd={() => setEditedService(createEmptyAddOnService(uuid))} />
            )}
          </Box>
      )}
      {editedService && !showSuccess && (
/*        <OverFullWindow
          hideHeader={true}
          title={editedService.id ? "Editing Add-On Service" : "Adding Add-On Service"}
          onClose={() => setEditedService(null)}
        >*/
          <AddOnServiceForm
            service={editedService}
            onClose={() => {
              setEditedService(null);
            }}
            onSaved={(savedService) => {
              setEditedService(savedService)
              setShowSuccess(true)
            }}
          />
/*
        </OverFullWindow>
*/
      )}
      {deleteService && (
        <ConfirmDialog
          open={true}
          title={<Typography>Delete Add-On Service</Typography>}
          question={
            <Typography>
              Are you sure want to delete<br />
              <strong>{deleteService!.name}</strong> add-on service?
            </Typography>
          }
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Confirm"
          onCancel={() => setDeleteService(null)}
          onConfirm={() => {
            dispatch(deleteThunk(apiClientWrapper, deleteService!.id!));
            setDeleteService(null);
          }}
        />
      )}
    </>
  );
};

export default AddOnServices;
