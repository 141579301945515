import { FunctionComponent, Fragment, MouseEvent, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import ConfirmDialog from 'components/Products/UI/Dialog';

export interface Props {
    icon: IconProp;
    onConfirmedClick: () => void;
    text: string;
    title: string;
    color?: string;
    confirmQuestion: JSX.Element;
    subConfirmQuestion?: JSX.Element;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            'width': '100%',
            'color': '#222',
            'fontSize': 14,
            'fontWeight': 500,
            'lineHeight': 1.95,
            'cursor': 'pointer',
            'margin': '2px 0px',
            'padding': '0px 20px',
            'display': 'inline-block',
            'whiteSpace': 'nowrap',
            'textAlign': 'left',

            '& svg': {
                marginRight: 6,
                verticalAlign: 'middle'
            },

            '&:hover': {
                backgroundColor: '#FAFAFA'
            }
        },
        icon: {
            width: '15px',
            [theme.breakpoints.down('lg')]: {
                marginRight: '7px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '10px'
            }
        }
    })
);

export const ChangeStatusItemWithConfirmation: FunctionComponent<
    Props
> = props => {
    const classes = useStyles();

    const [showDialog, setShowDialog] = useState(false);

    const clickHandler = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setShowDialog(true);
    };

    const cancelHandler = () => {
        setShowDialog(false);
    };

    const confirmHandler = () => {
        setShowDialog(false);
        props.onConfirmedClick();
    };

    return (
        <Fragment>
            <Box
                className={classes.item}
                onClick={clickHandler}
                style={{
                    color: props.color
                }}
            >
                <FontAwesomeIcon icon={props.icon} className={classes.icon} />
                {props.text}
            </Box>

            <ConfirmDialog
                open={showDialog}
                title={props.title + ' vendor'}
                question={props.confirmQuestion}
                onCancel={cancelHandler}
                onConfirm={confirmHandler}
                confirmButtonLabel={props.title}
                cancelButtonLabel="Cancel"
            />
        </Fragment>
    );
};

export default ChangeStatusItemWithConfirmation;
