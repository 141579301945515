import React, { FunctionComponent } from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { Spinner } from "components/UI";

export const TableSpinner: FunctionComponent = () => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={10} align="center" height="100px">
          <Spinner />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableSpinner;
