import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Button as MUIButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

export interface Props {
  onSelect: (selected: boolean, value: number) => void;
  disabled?: boolean;
  className?: string;
  selected?: boolean;
  value: number;
  minValue?:number;
  maxValue?:number;
  selectOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      border: "1px solid #707070",
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xs")]: {
        borderRadius: 20,
        height: 40,
      },
      [theme.breakpoints.only("sm")]: {
        borderRadius: 25,
        height: 50,
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: 25,
        height: 50,
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: 29,
        height: 58,
      },
      "&:hover": {
        color: theme.palette.primary.main,
        background: "#ffffff",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        "& button" :{
          color: theme.palette.primary.main,
          background: "transparent",
        }

      },
    },
    signButton: {
      width: "33%",
      minWidth: "33%",
      height: "100%",
      padding: "0px",
      color: theme.palette.text.primary,
      background: "transparent",
      border: "none",
      borderRadius: 0,
    },
    valueButton: {
      width: "34%",
      minWidth: "34%",
      height: "100%",
      padding: "0px",
      color: theme.palette.text.primary,
      background: "transparent",
      border: "none",
      borderRadius: 0,
    },
    selected: {
      background: "#FAEFDF",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      "&:hover": {
        background: "#FAEFDF",
      },
    },
  })
);

export const SelectIncButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [selected, setSelected] = useState(props.selected);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const clickHandler = (): void => {
    let newSelected = !selected;
    if (newSelected || !props.selectOnly) {
      setSelected(newSelected);
      props.onSelect(newSelected, value);
    }
  };

  const clickSubstractHandler = (): void => {
    let newValue = value - 1;
    
    if(props.minValue === undefined || newValue >= props.minValue) {
      setValue(newValue);
      props.onSelect(true, newValue);
    } else {
      props.onSelect(true, value);
    }
  };

  const clickAddHandler = (): void => {
    let newValue = value + 1;
    
    if(props.maxValue === undefined || newValue <= props.maxValue) {
      setValue(newValue);
      props.onSelect(true, newValue);
    } else {
      props.onSelect(true, value);
    }
    
  };

  const styleContainer = selected
  ? clsx(classes.container, classes.selected, props.className)
  : clsx(classes.container, props.className);

  return (
    <Box className={styleContainer}>
      <MUIButton
        variant="contained"
        color="primary"
        disableRipple={true}
        disableTouchRipple={true}
        disableElevation={true}
        onClick={clickSubstractHandler}
        disabled={props.disabled}
        className={classes.signButton}
      >
        -
      </MUIButton>
      <MUIButton
        variant="contained"
        color="primary"
        disableRipple={true}
        disableTouchRipple={true}
        disableElevation={true}
        onClick={clickHandler}
        disabled={props.disabled}
        className={classes.valueButton}
      >
        {value}
      </MUIButton>
      <MUIButton
        variant="contained"
        color="primary"
        disableRipple={true}
        disableTouchRipple={true}
        disableElevation={true}
        onClick={clickAddHandler}
        disabled={props.disabled}
        className={classes.signButton}
      >
        +
      </MUIButton>
    </Box>
  );
};

export default SelectIncButton;
