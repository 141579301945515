import { Box, createStyles, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { FieldError } from "@spike/model";
import useNonInitialEffect from "@versiondos/hooks";
import {
	fetchBankAccountsThunk,
	getBusinessProfileThunk,
	saveBankAccountThunk,
} from "@spike/payments-action";
import { Spinner } from "components/UI";
import isEqual from "lodash/isEqual";
import {
	createBankAccount,
	PaymentsBankAccount as BankAccountModel,
	PaymentsBusinessProfile as BusinessProfileModel,
} from "@spike/payments-model";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentsStatus } from "@spike/payments-action";
import { RootState } from "store";
import StepFrame from "../StepFrame";
import { validateBankAccount } from "../Validations";
import BankAccountForm from "./BankAccountForm";
import BankAccountView from "./BankAccountView";
import { v4 as uuid } from "uuid";
import { useApiClientWrapper } from "hooks";
import HelpCard from "components/Payments/Sidebar/HelpCard";

interface BankAccountStepProps {
	bankAccount?: BankAccountModel;
	hideDescription?: boolean;
	className?: string;
	onPrevious?: () => void;
	onNext?: (bankAccount: BankAccountModel) => void;
	onCompleted?: (bankAccount: BankAccountModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: "100%",
		},
		info: {
			background: '#FBFAF9',
			borderRadius: 12,
			paddingLeft: 14,
			paddingRight: 14,
			paddingTop: 17,
			paddingBottom: 10,
			marginBottom: 21
		}
	})
);

export const BankAccountStep: FunctionComponent<BankAccountStepProps> = (props) => {
	const classes = useStyles();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const businessProfile = useSelector<RootState, BusinessProfileModel | undefined>(
		(state) => state.payments.businessProfile
	);

	const bankAccounts = useSelector<RootState, Array<BankAccountModel>>(
		(state) => state.payments.bankAccounts
	);
	const primaryBankAccount = bankAccounts.find(
		(bankAccount) => bankAccount.primary
	);
	const paymentsStatus = useSelector<RootState, PaymentsStatus>(
		(state) => state.payments.status
	);

	const [editedBankAccount, setEditedBankAccount] = useState<
		BankAccountModel | undefined
	>();
	const [errors, setErrors] = useState<Array<FieldError>>([]);
	const [saving, setSaving] = useState(false);

	const finish = (bankAccount: BankAccountModel) => {
		props.onNext && props.onNext(bankAccount);
		props.onCompleted && props.onCompleted(bankAccount);
	};

	useEffect(() => {
		if (props.bankAccount) {
			setEditedBankAccount({ ...props.bankAccount });
		} else {
			if (!businessProfile) {
				dispatch(getBusinessProfileThunk(apiClientWrapper));
			}

			dispatch(fetchBankAccountsThunk(apiClientWrapper));
		}
	}, []);

	useNonInitialEffect(() => {
		if (PaymentsStatus.FetchBankAccountsSuccess === paymentsStatus) {
			if (primaryBankAccount) {
				setEditedBankAccount(primaryBankAccount);
			} else {
				if (businessProfile) {
					setEditedBankAccount(
						createBankAccount(uuid, businessProfile.legalName, true)
					);
				}
			}
		} else if (PaymentsStatus.SaveBankAccountSuccess === paymentsStatus) {
			const savedBankAccount = bankAccounts.find(
				(bankAccount) => bankAccount.uuid === editedBankAccount!.uuid
			);
			setEditedBankAccount(savedBankAccount);
			setSaving(false);
			finish(savedBankAccount!);
		} else if (PaymentsStatus.Error === paymentsStatus) {
			setSaving(false);
		}

		if (
			!primaryBankAccount &&
			PaymentsStatus.GetBusinessProfileSuccess === paymentsStatus
		) {
			if (businessProfile) {
				setEditedBankAccount(
					createBankAccount(uuid, businessProfile.legalName, true)
				);
			} else {
				setEditedBankAccount(createBankAccount(uuid, "", true));
			}
		}
	}, [paymentsStatus]);

	const saveHandler = () => {
		const errors = validateBankAccount(editedBankAccount!);
		setErrors(errors);
		if (errors.length === 0) {
			if (
				(props.bankAccount &&
					isEqual(props.bankAccount, editedBankAccount)) ||
				(!props.bankAccount &&
					isEqual(primaryBankAccount, editedBankAccount))
			) {
				finish(editedBankAccount!);
			} else {
				setSaving(true);
				dispatch(saveBankAccountThunk(apiClientWrapper, editedBankAccount!));
			}
		}
	};

	return (
		<Box className={classes.container}>
			<StepFrame
				btnId="cuddlespay-bank-account"
				title="Bank Account Details"
				description={
					props.hideDescription
						? undefined
						: "These are the bank account details for the account you wish to receive payouts to."
				}
				onPrevious={props.onPrevious}
				onNext={props.onNext ? saveHandler : undefined}
				onCompleted={props.onCompleted ? saveHandler : undefined}
				saving={saving}
				className={props.className}
			>
				{editedBankAccount ? (
					props.onCompleted || props.onNext ? (
						<>
							{isMobile && <HelpCard className={classes.info} />}
							<BankAccountForm
								bankAccount={editedBankAccount!}
								onChange={setEditedBankAccount}
								errors={errors}
							/>
						</>
					) : (
						<BankAccountView bankAccount={editedBankAccount!} />
					)
				) : (
					<Spinner />
				)}
			</StepFrame>
		</Box>
	);
};

export default BankAccountStep;
