import React, { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import { useFieldStyles } from "components/UI/FieldStyles";
import LabelField from "components/UI/LabelField";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import Pet from "@spike/pet-model";

export interface SelectPetsProps {
  label?: string | JSX.Element;
  name?: string;
  selectedOption?: string | undefined;
  placeholder?: string;
  options: Array<Pet>;
  onSelect: (option: Pet) => void;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  id?:string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 110,
    },
    dropdownContainer: {
      position: "relative",
      height: 54
    },
    collapsedContainer: {
      height: "100%",
    },
    expandedContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #222222 !important",
      paddingBottom: 30,
      [theme.breakpoints.up('md')]: {
        paddingBottom: 33
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: 49,
      cursor: "pointer",
    },
    header: {
      display: "flex",
      alignItems: "center",
      color: "#000000",
      fontSize: 16,
      lineHeight: "27px",
      paddingLeft: 17,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 19
      },
    },
    listContainer: {
      borderTop: "1px solid #222222",
      overflowY: "auto",
      height: "auto",
      paddingTop: 13,
      maxHeight: 200,
      [theme.breakpoints.up('md')]: {
        paddingTop: 14
      }
    },
    placeholder: {
      color: "#7A7A7A",
      fontSize: 16,
      lineHeight: "27px"
    },
    border: {
      border: "1px solid #222222",
      borderRadius: 27,
      [theme.breakpoints.up('md')]: {
        borderRadius: 30
      },
    },
    textContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      paddingRight: 10
    },
    chevronContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      paddingRight: 32,
      [theme.breakpoints.up('md')]: {
        paddingRight: 35
      },
    },
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 33,
      "&:hover": {
        background: "#F8F8F8",
      },
    },
    itemContent: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 13,
      paddingRight: 14,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 14,
        paddingRight: 16
      },
    },
    item: {
      padding: "3px 5px",
      fontSize: 16,
      lineHeight: "16px",
      [theme.breakpoints.up('md')]: {
        padding: "4px 6px"
      },
    },
    optionNotSelected: {
      border: "1px solid #D3D3D3 !important",
    },
    petIcon: {
      width: 50,
      height: 24,
      padding: "3px 12px 3px 12px",
      borderRadius: 18,
      [theme.breakpoints.up('md')]: {
        width: 52,
        height: 26,
        padding: "3px 14px 3px 14px",
        borderRadius: 20
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    petIconDogs: {
        color: '#5e8677',
        backgroundColor: '#e9f0ed'
    },
    petIconCats: {
        color: '#c18733',
        backgroundColor: '#faefdf'
    },
    petIconOthers: {
        color: '#9c8267',
        backgroundColor: '#ebe7e3'
    },
    chip: {
      fontSize: 14,
      lineHeight: "14px"
    },
    chevron: {
      fontSize: 16
    }
  })
);

export const SelectPets: FunctionComponent<SelectPetsProps> = (props) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [optionFormatted, setOptionFormatted] = useState<Option<number> | undefined>(undefined);
  const [optionsFormatted, setOptionsFormatted] = useState<Array<Option<number>> | []>([]);

  const clickCollapseHandler = () => {
    setIsCollapsed((prev) => !prev);
  };

  const changeHandler = (selectedId: number) => {
    props.onSelect(props.options.find((option) => option.id === selectedId)!);
  };

  const petIconType = (type: string) => {
    if (type === 'dogs') return 'Dogs'
    if (type === 'cats') return 'Cats'
    return 'Exotics'
};

useEffect(() => {
  if(props.options) {
    const optionsFormatted: Array<Option<number>> = [];
    props.options.forEach(optFiltered => {
        const optFormatted: Option<number> = {
            id: optFiltered.id!,
            name: optFiltered.name, 
            description: optFiltered.type?.id
        }
        optionsFormatted.push(optFormatted)
    })
    setOptionFormatted(optionsFormatted.find(opt => opt.id?.toString() === props.selectedOption));
    setOptionsFormatted(optionsFormatted);
  }
}, [props.options, props.selectedOption]);

  const header = (
    <Box className={classes.headerContainer}>
      <Box className={classes.textContainer}>
        <Typography className={clsx(classes.header, { [classes.placeholder]: !props.selectedOption })}>
          {optionFormatted ? optionFormatted.name : props.placeholder}
        </Typography>
      </Box>
      <Box className={classes.chevronContainer}>
        <FontAwesomeIcon 
          icon={isCollapsed ? faChevronDown : faChevronUp} 
          color={isCollapsed ? "#7A7A7A" : "#222222"}
          className={classes.chevron}
        />
      </Box>
    </Box>
  );

  const list = (
    <Box className={classes.listContainer}>
      {optionsFormatted.map((option, index) => (
        <Box 
          className={classes.itemContainer} 
          key={option.id}
          id={`booking_option_pet_${index}`}
        >
          <Box  
            className={classes.itemContent} 
            onClick={() => changeHandler(option.id)}
          >
            <Typography className={classes.item}>{option.name}</Typography>
            {option.description && <Box className={clsx([
              classes.petIcon,
              { [classes.petIconDogs]: option.description === 'dogs' },
              { [classes.petIconCats]: option.description === 'cats' },
              { [classes.petIconOthers]: option.description === 'exotics' }
            ])}>
              <Typography className={classes.chip}>{petIconType(option.description)}</Typography>
            </Box>}
          </Box>
        </Box>
      ))}
    </Box>
  );

  const collapsed = (
    <Box
      className={clsx(classes.border, classes.collapsedContainer, [classes.optionNotSelected])}
      onClick={clickCollapseHandler}
    >
      {header}
    </Box>
  );

  const expanded = (
    <Box
      className={clsx(classes.border, classes.expandedContainer)}
      onClick={clickCollapseHandler}
    >
      {header}
      {list}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={() => setIsCollapsed(true)}>
      <Box className={clsx(classes.container, fieldClasses.container, props.className)}>
        <LabelField {...props} />
        <Box className={classes.dropdownContainer} id="booking_pet_search">{isCollapsed ? collapsed : expanded}</Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SelectPets;
