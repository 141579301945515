import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { MarketplaceAdditionalInfo } from '@spike/marketplace-model';
import { Button } from 'components/UI';
import { useMarketplace } from 'hooks';
import { FieldError } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { BusinessSettingsTitle } from '../UI';
import { wbp, reduceResolution } from 'Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export interface Props {
    additionalInfo: MarketplaceAdditionalInfo;
    onSave?: (additionalInfo: MarketplaceAdditionalInfo) => void;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(487)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '527px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px'
            }
        },
        businessSettingsTittle: {
            'display': 'flex',
            'alignItems': 'center',
            '& $iconBusinessTitle': {
                fontSize: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                'fontSize': '20px',
                'marginBottom': '5px',
                '& $iconBusinessTitle': {
                    fontSize: '20px',
                    fontWeight: '300'
                }
            }
        },
        businessSettingsTittleContainer: {
            marginBottom: 0
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        row: {
            display: 'flex',
            width: '100%'
        },
        column: {
            flexDirection: 'column'
        },
        radioButtonContainer: {
            'display': 'flex',
            [theme.breakpoints.only('md')]: {
                marginTop: '12px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '20px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '24px'
            },
            '& .MuiRadio-root': {
                [theme.breakpoints.down('sm')]: {
                    color: '#000 !important',
                    opacity: '1 !important'
                }
            }
        },

        labelContainer: {
            display: 'flex',
            width: '50%',
            alignItems: 'center'
        },

        label: {
            fontWeight: 600,
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '30px'
            }
        },
        sublabel: {
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '15px'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginTop: '6px'
            }
        },
        componentContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(28)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '28px'
            }
        },
        buttonContainerFull: {
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                'position': 'absolute',
                'bottom': '25px',
                'left': 0,
                'paddingLeft': '15px',
                'paddingRight': '15px',
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '18px'
                    }
                },
                '@supports (-webkit-touch-callout: none)': {
                    paddingBottom: '80px'
                }
            }
        },
        mobileBusinessCheckpoint: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '30px'
            }
        },
        error: {
            color: '#EF4F57'
        },
        icon: {
            width: '12px !important',
            height: '12px !important'
        }
    })
);

export const AdditionalInfo: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const status = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );
    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState({
        ...props.additionalInfo
    });
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    useEffect(() => {
        if (
            additionalInfo.mobileBusiness === false &&
            additionalInfo.localBusiness === false &&
            additionalInfo.inHomeBusiness === false &&
            additionalInfo.atHomeBusiness === false
        ) {
            setAdditionalInfo(prev => ({
                ...prev,
                mobileBusiness: false,
                localBusiness: true,
                inHomeBusiness: false,
                atHomeBusiness: false
            }));
        }
    }, [additionalInfo]);

    useNonInitialEffect(() => {
        setSaved(isEqual(props.additionalInfo, additionalInfo));
    }, [additionalInfo]);

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setSaved(true);
            setLoading(false);
        }
    }, [status]);

    const validate = (): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        return errors;
    };

    const changeMobileBusinessHandler = (checked: boolean) => {
        setAdditionalInfo(prev => ({
            ...prev,
            mobileBusiness: checked,
            localBusiness: !checked,
            inHomeBusiness: !checked,
            atHomeBusiness: !checked
        }));
    };

    const changeLocalBusinessHandler = (checked: boolean) => {
        setAdditionalInfo(prev => ({
            ...prev,
            mobileBusiness: !checked,
            localBusiness: checked,
            inHomeBusiness: !checked,
            atHomeBusiness: !checked
        }));
    };

    const changeInHomeBusinessHandler = (checked: boolean) => {
        setAdditionalInfo(prev => ({
            ...prev,
            mobileBusiness: !checked,
            localBusiness: !checked,
            inHomeBusiness: checked,
            atHomeBusiness: !checked
        }));
    };

    const changeAtHomeBusinessHandler = (checked: boolean) => {
        setAdditionalInfo(prev => ({
            ...prev,
            mobileBusiness: !checked,
            localBusiness: !checked,
            inHomeBusiness: !checked,
            atHomeBusiness: checked
        }));
    };

    const saveHandler = () => {
        const errors = validate();
        setErrors(errors);
        if (errors.length === 0) {
            props.onSave && props.onSave({ ...additionalInfo });
            props.onSave && setLoading(true);
        }
    };

    /* LEGACY PARTS

      <Box className={classes.row}>
        <Box className={classes.labelContainer}>
          <Typography className={classes.label}>Years in Business</Typography>
        </Box>
        <Box className={classes.componentContainer}>
          <IncrementField
            value={additionalInfo.yearsInBusiness}
            name="yearsInBusiness"
            minValue={0}
            onChange={changeValueHandler}
          />
        </Box>
      </Box>

            <Box className={classes.row}>
        <Box className={classes.labelContainer}>
          <Typography className={classes.label}>Number of Employees</Typography>
        </Box>
        <Box className={classes.componentContainer}>
          <IncrementField
            value={additionalInfo.numberOfEmployees}
            name="numberOfEmployees"
            minValue={0}
            onChange={changeValueHandler}
          />
        </Box>
      </Box>


        {marketplace.id === 1 && <Box className={clsx(classes.row, classes.column)}>
          <Box className={classes.labelContainer}>
            <Typography className={classes.label}>Local Business</Typography>
          </Box>
          <Box className={classes.radioButtonContainer}>
            <CheckboxCircle
              label="Yes"
              checked={additionalInfo.localBusiness}
              onChange={changeLocalBusinessHandler}
              iconColor="black"
            />

          </Box>
        </Box>}


  */

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.titleContainer}>
                <BusinessSettingsTitle
                    className={classes.businessSettingsTittle}
                    containerClassName={classes.businessSettingsTittleContainer}
                >
                    {' '}
                    {props.isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={props.openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}{' '}
                    Business Model
                </BusinessSettingsTitle>
            </Box>
            <Box className={clsx(classes.row, classes.column)}>
                <Box>
                    <Typography className={classes.sublabel}>
                        Choose the type of business you operate from the options
                        below:{' '}
                    </Typography>
                </Box>
                <Box className={classes.radioButtonContainer}>
                    <RadioGroup>
                        <FormControlLabel
                            id="business_settings_additional_info_radio_b&m"
                            checked={additionalInfo.localBusiness}
                            value="b&m"
                            onChange={(event, checked) => {
                                changeLocalBusinessHandler(checked);
                            }}
                            control={<Radio color="default" />}
                            label="Brick & Mortar"
                        />
                        <FormControlLabel
                            id="business_settings_additional_info_radio_mobile"
                            checked={additionalInfo.mobileBusiness}
                            value="mobile"
                            onChange={(event, checked) => {
                                changeMobileBusinessHandler(checked);
                            }}
                            control={<Radio color="default" />}
                            label="Mobile"
                        />
                        <FormControlLabel
                            id="business_settings_additional_info_radio_inhome"
                            checked={additionalInfo.inHomeBusiness}
                            value="inhome"
                            onChange={(event, checked) => {
                                changeInHomeBusinessHandler(checked);
                            }}
                            control={<Radio color="default" />}
                            label="House Call"
                        />
                        <FormControlLabel
                            id="business_settings_additional_info_radio_athome"
                            checked={additionalInfo.atHomeBusiness}
                            value="athome"
                            onChange={(event, checked) => {
                                changeAtHomeBusinessHandler(checked);
                            }}
                            control={<Radio color="default" />}
                            label="Your Home"
                        />
                    </RadioGroup>
                </Box>
            </Box>
            <Box
                className={clsx(
                    classes.buttonContainer,
                    classes.buttonContainerFull
                )}
            >
                <Button
                    id="business_settings_additional_info_button_save"
                    label={saved ? 'Saved!' : 'Save'}
                    onClick={saveHandler}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default AdditionalInfo;
