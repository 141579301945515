import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ServiceDuration } from "../../../model/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { Typography } from "@material-ui/core";
import VariableDurationComponent from "components/Services/Duration/VariableDurationComponent";
import padStart from "lodash/padStart";
import ShowDurationComponent from "./ShowDurationComponent";
interface Props {
  duration: ServiceDuration;
  hover?: boolean;
  onShow: (show: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "start",
    },
    durationContainer: {
      display: "flex",

    }
  })
);

export const ServiceRowDuration: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const showDuration = <Box className={classes.durationContainer}>
    <ShowDurationComponent variable={props.duration.variable} hover={props.hover} onShow={props.onShow}></ShowDurationComponent>  </Box>;


  return (
    <Box className={classes.container}>
      {showDuration}
    </Box>
  );
};

export default ServiceRowDuration;
