import { faExclamationCircle, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface CannotBeDeletedProps {
  text: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
    },
    text: {
      fontFamilty: "Poppins",
      fontWeight: 400,
      color: "black",
      lineHeight: "120%",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
      },
    },
    icon: {
      color: "#5E8677",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
        marginRight: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px",
        marginRight: "3px",
      },
    },
  })
);

export const CannotBeDeleted: FunctionComponent<CannotBeDeletedProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <FontAwesomeIcon className={classes.icon} icon={faInfoCircle} />
      <Typography className={classes.text}>
        {props.text}
      </Typography>
    </Box>
  );
};

export default CannotBeDeleted;
