import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import store, { RootState } from "store";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import {
  AvailabilityAction,
  AVAILABILITY_RESET_ACTION_TYPE,
  AVAILABILITY_FETCH_START_ACTION_TYPE,
  AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE,
  AVAILABILITY_STAFF_FETCH_START_ACTION_TYPE,
  AVAILABILITY_STAFF_FETCH_SUCCESS_ACTION_TYPE,
  AVAILABILITY_FETCH_BOOKING_SUCCESS_ACTION_TYPE,
  AVAILABILITY_ERROR_ACTION_TYPE,
  FILTER_AVAILABILITY_FETCH_START_ACTION_TYPE,
  FILTER_AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE,
  AVAILABILITY_FETCH_BOOKING_START_ACTION_TYPE,
} from "./AvailabilityActionsTypes";
import { AvailabilityState } from "reducers/availability/AvailabilityState";
import { NotificationsAction }from "@spike/notifications-action";
import { showError }from "@spike/notifications-action";
import { convertAvailability, convertFilterAvailability, convertAvailabilityBooking } from "./AvailabilityConverter";
import AvailabilityDto from "./AvailabilityDto";
import {v4 as uuid} from 'uuid';

const availabilityStaffUrl = "/booking/availability_staff";
const availabilityBookingUrl = "/booking/availability";
const availabilityFilterUrl = "/booking/calendar";
const availabilitySlotUrl = "/booking/availability_by_hour";

const isLastAvailabilityRequest = (requestToken: string, state: RootState) => state.availability.requestTokens.availability === requestToken;
const isLastAvailabilityBookingRequest = (requestToken: string, state: RootState) => state.availability.requestTokens.availabilityBooking === requestToken;

export const fetchAvailabilityStaffThunk = (
  serviceId: number,
  startDate: string,
  endDate: string,
  petId?: number,
): ThunkAction<void, AvailabilityState, null, AvailabilityAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(fetchStaffStart());

    const marketplaceId = store.getState().marketplace.marketplace.id;
    const locationId = store.getState().marketplace.marketplace.basics.address.id;
    let url = "";

    if (petId !== undefined)
      url = `${availabilityStaffUrl}?marketplace_id=${marketplaceId}&location_id=${locationId}&service_id=${serviceId}&pet_id=${petId}&start_date=${startDate}&end_date=${endDate}`;
    else
      url = `${availabilityStaffUrl}?marketplace_id=${marketplaceId}&location_id=${locationId}&service_id=${serviceId}&start_date=${startDate}&end_date=${endDate}`;

    try {
      const response: AxiosResponse<Array<AvailabilityDto>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchStaffSuccess(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching availability."));
      }
    }
  };
};

export const fetchAvailabilitySlotsThunk = (
  serviceId: number,
  startDate: string,
  hours: string,
  petId?: number,
): ThunkAction<void, AvailabilityState, null, AvailabilityAction | NotificationsAction> => {
  return async (dispatch) => {
    const requestToken = uuid();

    dispatch(fetchStart(requestToken));

    const marketplaceId = store.getState().marketplace.marketplace.id;
    const locationId = store.getState().marketplace.marketplace.basics.address.id;
    let url = "";

    url = `${availabilitySlotUrl}?marketplace_id=${marketplaceId}&location_id=${locationId}&service_id=${serviceId}&pet_id=${petId}&start_date=${startDate}&hours=${hours}`;

    try {
      const response: AxiosResponse<Array<AvailabilityDto>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      isLastAvailabilityRequest(requestToken, store.getState()) && dispatch(fetchSuccess(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching availability."));
      }
    }
  };
};

export const fetchAvailabilityBookingThunk = (
  serviceId: number,
  petId: number,
  startDate: string,
  endDate: string
): ThunkAction<void, AvailabilityState, null, AvailabilityAction | NotificationsAction> => {
  return async (dispatch) => {
    const requestToken = uuid();

    dispatch(fetchBookingStart(requestToken));

    const marketplaceId = store.getState().marketplace.marketplace.id;
    const locationId = store.getState().marketplace.marketplace.basics.address.id;
    const url = `${availabilityBookingUrl}?marketplace_id=${marketplaceId}&location_id=${locationId}&service_id=${serviceId}&pet_id=${petId}&start_date=${startDate}&end_date=${endDate}`;

    try {
      const response: AxiosResponse<Array<Object>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      isLastAvailabilityBookingRequest(requestToken, store.getState()) && dispatch(fetchBookingSucces(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching availability."));
      }
    }
  };
};

export const fetchFilterAvailabilityThunk = (
  serviceId: number,
  startDate: string,
  endDate: string,
  petId?: number,
): ThunkAction<void, AvailabilityState, null, AvailabilityAction | NotificationsAction> => {
  return async (dispatch) => {
    const marketplaceId = store.getState().login.auth.marketplaceId;

    let url = "";

    if (petId !== undefined) {
      url = `${availabilityFilterUrl}?marketplace_id=${marketplaceId}&service_id=${serviceId}&pet_id=${petId}&start_date=${startDate}&end_date=${endDate}`
    }
    else {
      url = `${availabilityFilterUrl}?marketplace_id=${marketplaceId}&service_id=${serviceId}&start_date=${startDate}&end_date=${endDate}`;
    }

    dispatch(fetchFilterStart());
    try {
      const response: AxiosResponse<Array<Object>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchFilterSucces(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching availability filter."));
      }
    }
  };
};

export const reset = (): AvailabilityAction => {
  return {
    type: AVAILABILITY_RESET_ACTION_TYPE,
  };
};

const fetchStart = (requestToken: string): AvailabilityAction => {
  return {
    type: AVAILABILITY_FETCH_START_ACTION_TYPE,
    payload: {
      requestToken
    }
  };
};

const fetchStaffStart = (): AvailabilityAction => {
  return {
    type: AVAILABILITY_STAFF_FETCH_START_ACTION_TYPE,
  };
};

const fetchSuccess = (response: Array<AvailabilityDto>): AvailabilityAction => {
  return {
    type: AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      availability: convertAvailability(response, store.getState().staff.staff),
    },
  };
};

const fetchStaffSuccess = (response: Array<AvailabilityDto>): AvailabilityAction => {
  return {
    type: AVAILABILITY_STAFF_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      availability: convertAvailability(response, store.getState().staff.staff),
    },
  };
};

const fetchFilterStart = (): AvailabilityAction => {
  return {
    type: FILTER_AVAILABILITY_FETCH_START_ACTION_TYPE,
  };
};

const fetchFilterSucces = (response: Array<Object>): AvailabilityAction => {
  return {
    type: FILTER_AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      filterAvailability: convertFilterAvailability(response),
    },
  };
};

const fetchBookingStart = (requestToken: string): AvailabilityAction => {
  return {
    type: AVAILABILITY_FETCH_BOOKING_START_ACTION_TYPE,
    payload: {
      requestToken
    }
  };
};

const fetchBookingSucces = (response: Array<Object>): AvailabilityAction => {
  return {
    type: AVAILABILITY_FETCH_BOOKING_SUCCESS_ACTION_TYPE,
    payload: {
      availabilityBooking: convertAvailabilityBooking(response),
    },
  };
};

const error = (): AvailabilityAction => {
  return {
    type: AVAILABILITY_ERROR_ACTION_TYPE,
  };
};
