import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { LoginPath } from "routes/Paths";
import { useParams } from "react-router-dom";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import Header from "rocket/components/Header";
import LeftColumn from "rocket/components/SetPassword/LeftColumn";
import RightColumn from "rocket/components/SetPassword/RightColumn";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headerLink: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const SetPassword: FunctionComponent = () => {
  const classes = useStyles();

  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    pageView("/resetpassword");
  }, []);

  useEffect(() => {
    if (token === null) {
      history.push(LoginPath);
    }
  }, [token, history]);

  document.title = "Create your new password - Cuddles";

  return (
    <div id="set-password">
      <Header containerStyles={classes.headerContainer} />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <LeftColumn />
          <RightColumn token={token!} />
        </Grid>
      </div>
    </div>
  );
};

export default SetPassword;
