import { FunctionComponent } from 'react';
import Button from './Button';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface EditButtonProps {
	size?: number;
	className?: string;
	onClick?: () => void;
}

export const EditButton: FunctionComponent<EditButtonProps> = props => {
	return (
		<Button
			icon={faPen}
			className={props.className}
			size={props.size ?? 15}
			onClick={props.onClick}
		/>
	);
};

export default EditButton;
