import {
	faFileImage,
	faFilePdf,
	faTrashAlt
} from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { wbp } from 'Theme';

interface UploadedFileProps {
	fileName: string;
	fileSize: number;
	className?: string;
	onRemove: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			border: '1px solid #7A7A7A',
			[theme.breakpoints.down(wbp)]: {
				padding: '12px 24px 12px 27px',
				borderRadius: '14px',
				flexDirection: 'column',
				alignItems: 'baseline',
				gap: 5
			},
			[theme.breakpoints.up(wbp)]: {
				flexDirection: 'row',
				padding: '15px 30px 15px 34px',
				borderRadius: '6px'
			}
		},
		leftContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center'
		},
		rightContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center'
		},
		check: {
			color: '#5E8677',
			[theme.breakpoints.down(wbp)]: {
				width: '21px !important',
				height: '21px !important'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '26px !important',
				height: '26px !important'
			}
		},
		fileIcon: {
			[theme.breakpoints.down(wbp)]: {
				width: '29px !important',
				height: '29px !important',
				margin: '0px 6px 0px 12px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '36px !important',
				height: '36px !important',
				margin: '0px 8px 0px 27px'
			}
		},
		name: {
			fontFamilty: 'Poppins',
			fontWeight: 500,
			color: '#000',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				lineHeight: '20px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '14px',
				lineHeight: '20px'
			}
		},
		deleteContainer: {
			'display': 'flex',
			'cursor': 'pointer',
			'flexDirection': 'row',
			'gap': 4,
			'alignItems': 'center',
			'color': '#7A7A7A',
			'& p': {
				fontSize: 13,
				fontWeigth: 500,
				paddingTop: 3,
				color: '#7A7A7A'
			},
			'&:hover, p:hover': {
				color: 'red'
			},
			[theme.breakpoints.down(wbp)]: {
				'borderBottom': '1px solid #EF4F57',
				color: '#EF4F57',
				'& p': {
					lineHeight: '18px',
					color: '#EF4F57',
					paddingTop: 3,
					fontWeight: 500,
					fontSize: 14
				}
			}
		},
		delete: {
			width: 14,
			height: 14
		},
		size: {
			fontFamilty: 'Poppins',
			fontWeight: 500,
			color: '#7A7A7A',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '12px',
				lineHeight: '16px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '12px',
				lineHeight: '16px'
			}
		}
	})
);
export const UploadedFile: FunctionComponent<UploadedFileProps> = props => {
	const classes = useStyles();

	const pdf = (
		<FontAwesomeIcon
			icon={faFilePdf}
			size="4x"
			className={classes.fileIcon}
		/>
	);
	const image = (
		<FontAwesomeIcon
			icon={faFileImage}
			size="4x"
			className={classes.fileIcon}
		/>
	);

	const [fileName, fileExtension] = props.fileName.split('.')

	const shortFileName = fileName.length > 22 ? `${fileName.substring(0, 22)}...${fileExtension}` : props.fileName

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.leftContainer}>
				<FontAwesomeIcon
					icon={faCheckCircle}
					className={classes.check}
				/>
				{props.fileName && props.fileName.endsWith('pdf') && pdf}
				{props.fileName &&
					(props.fileName.endsWith('png') ||
						props.fileName.endsWith('jpg') ||
						props.fileName.endsWith('jpeg')) &&
					image}
				<Box>
					<Typography className={classes.name}>
						{shortFileName}
					</Typography>
					<Typography className={classes.size}>{`${Math.round(
						props.fileSize / 1024
					)} KB`}</Typography>
				</Box>
			</Box>
			<Box className={classes.rightContainer}>
				<Box
					className={classes.deleteContainer}
					onClick={props.onRemove}
				>
					<FontAwesomeIcon
						icon={faTrashAlt}
						className={classes.delete}
					/>
					<Typography>Delete</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default UploadedFile;
