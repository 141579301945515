import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CalculatingSpinner } from 'components/UI';
import { FunctionComponent } from 'react';

interface NormalItemProps {
	label: string;
	value: string | number;
	topLine?: boolean;
	bottomLine?: boolean;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: (props: NormalItemProps) => ({
			width: '100%',
			display: 'flex',
			padding: '19px 0px',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: props.bottomLine ? '1px solid #D4D4D4' : undefined,

			[theme.breakpoints.down('sm')]: {
				borderTop: props.topLine ? '1px solid #D4D4D4' : undefined,

				...(props.topLine && {
					marginTop: 8
				})
			}
		}),
		text: {
			color: '#000',
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 500
		}
	})
);

export const NormalItem: FunctionComponent<NormalItemProps> = props => {
	const classes = useStyles(props);

	return (
		<Box className={clsx(classes.row, props.className)}>
			<Typography className={classes.text}>{props.label}</Typography>
			{props.loading ? (
				<CalculatingSpinner />
			) : (
				<Typography className={classes.text}>{props.value}</Typography>
			)}
		</Box>
	);
};

export default NormalItem;
