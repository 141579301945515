import { Reducer } from "redux";
import { ProductVendorsState, ProductVendorsStatus } from "./ProductVendorsState";
import {
  PRODUCT_VENDORS_RESET_ACTION_TYPE,
  PRODUCT_VENDORS_FETCH_START_ACTION_TYPE,
  PRODUCT_VENDORS_FETCH_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_GET_START_ACTION_TYPE,
  PRODUCT_VENDORS_GET_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_START_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_PRODUCTS_START_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_PRODUCTS_START_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_START_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_ERROR_ACTION_TYPE,
  ProductVendorsAction,
} from "actions/productVendors/ProductVendorsActionsTypes";

const initialState: ProductVendorsState = {
  list: [],
  savedVendor: undefined,
  vendor: undefined,
  status: ProductVendorsStatus.Initial,
  loading: false,
};

export const productVendorsReducer: Reducer<ProductVendorsState, ProductVendorsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PRODUCT_VENDORS_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    case PRODUCT_VENDORS_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.Fetching,
        loading: true,
      };
    case PRODUCT_VENDORS_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: [...action.payload.vendors],
        status: ProductVendorsStatus.FetchSuccess,
        loading: false,
      };
    case PRODUCT_VENDORS_GET_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.Getting,
        loading: true,
        vendor: undefined,
      };
    case PRODUCT_VENDORS_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.map(v => v.uuid === action.payload.vendor.uuid ? action.payload.vendor : v),
        status: ProductVendorsStatus.GetSuccess,
        vendor: { ...action.payload.vendor },
        loading: false,
      };
    case PRODUCT_VENDORS_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.Saving,
        loading: true,
        savedVendor: undefined
      };
    case PRODUCT_VENDORS_SAVE_SUCCESS_ACTION_TYPE:
      const vendors = state.list.some(v => v.uuid === action.payload.vendor.uuid)
        ? state.list.map(v => v.uuid === action.payload.vendor.uuid ? action.payload.vendor : v)
        : [...state.list, action.payload.vendor];

      return {
        ...state,
        list: vendors,
        status: ProductVendorsStatus.SaveSuccess,
        loading: false,
        savedVendor: { ...action.payload.vendor },
        vendor: (state.vendor?.uuid === action.payload.vendor.uuid ? { ...action.payload.vendor } : state.vendor)
      };
    case PRODUCT_VENDORS_SAVE_PRODUCTS_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.SavingProducts,
        loading: true,
        savedVendor: undefined
      };

    case PRODUCT_VENDORS_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.some(c => c.uuid === action.payload.vendor.uuid)
        ? state.list.map(v => v.uuid === action.payload.vendor.uuid ? action.payload.vendor : v)
        : [...state.list, action.payload.vendor],
        status: ProductVendorsStatus.SaveProductsSuccess,
        loading: false,
        savedVendor: { ...action.payload.vendor },
        vendor: (state.vendor?.uuid === action.payload.vendor.uuid ? { ...action.payload.vendor } : state.vendor)
      };
    case PRODUCT_VENDORS_DELETE_PRODUCTS_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.DeletingProducts,
        loading: true,
        savedVendor: undefined
      };

    case PRODUCT_VENDORS_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        list: state.list.some(c => c.uuid === action.payload.vendor.uuid)
        ? state.list.map(v => v.uuid === action.payload.vendor.uuid ? action.payload.vendor : v)
        : [...state.list, action.payload.vendor],
        status: ProductVendorsStatus.DeleteProductsSuccess,
        loading: false,
        savedVendor: { ...action.payload.vendor },
        vendor: (state.vendor?.uuid === action.payload.vendor.uuid ? { ...action.payload.vendor } : state.vendor)
      };
    case PRODUCT_VENDORS_DELETE_START_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.Deleting,
        loading: true,
      };


    case PRODUCT_VENDORS_DELETE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.DeleteSuccess,
        loading: false,
        savedVendor: state.savedVendor?.uuid === action.payload.vendorUuid ? undefined : state.savedVendor,
        vendor: state.vendor?.uuid === action.payload.vendorUuid ? undefined : state.vendor,
      };

    case PRODUCT_VENDORS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: ProductVendorsStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default productVendorsReducer;
