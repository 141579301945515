import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RootState } from 'store';
import {
    fetchServicesThunk,
    resetServicesThunk,
    saveServiceThunk,
    deleteServicesThunk,
    activateServicesThunk,
    updatePetTypesServicesThunk,
    updateBusinessAreaServicesThunk
} from 'actions/services/ServicesActions';
import { ServicesStatus } from 'reducers/services/ServicesState';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import Service from 'model/Service';
import { useMasterData, useMarketplace } from 'hooks';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { ServicesTable } from 'components/Services/ServicesTable';
import ServiceComponent from 'components/Service';
import { Option, PetType } from '@spike/model';
import OnboardingTutorial from 'components/OnboardingTutorial';
import { OnboardingSteps } from 'model/OnboardingSteps';
import {
    fetchOnboardingStepsThunk,
    onboardingCloseModal,
    onboardingShowVideo
} from 'actions/onboardingSteps/OnboardingStepsActions';
import { ONBOARDING_VIDEOS } from 'components/OnboardingSteps/videos';
import cloneDeep from 'lodash/cloneDeep';

interface Props {
    create?: string;
    serviceId?: number;
    onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
        }
    })
);

export const ServicesComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const marketplaceStatus = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
    const services = useSelector<RootState, Array<Service>>(state => state.services.services);
    const servicesStatus = useSelector<RootState, ServicesStatus>(state => state.services.status);

    const onboardingSteps = useSelector<RootState, OnboardingSteps>(state => state.onboardingSteps.onboardingSteps);

    const onboardingPageParams = isMobile
        ? {
              title: 'Your Services',
              buttonLabel: 'Add Service',
              image: 'images/onboarding/emptyscreen-services-mobile.svg',
              imageWidth: '100%',
              imageHeight: 'auto'
          }
        : {
              title: 'Your Services',
              buttonLabel: 'Add Service',
              image: 'images/onboarding/services.svg',
              imageWidth: 1027,
              imageHeight: 587
          };

    const { businessHoursDone, teamDone, servicesDone, petTypesDone } = onboardingSteps;

    const [addStaff, setAddStaff] = useState(false);
    const [businessAreas, setBusinessAreas] = useState<Array<Option<string>>>([]);
    const [isEdition, setIsEdition] = useState(props.create === 'create');
    const [petTypes, setPetTypes] = useState<Array<PetType>>([]);
    const [serviceToEdit, setServiceToEdit] = useState<Service | undefined>(undefined);

    useEffect(() => {
        dispatch(resetServicesThunk());
        dispatch(fetchStaffThunk());
        dispatch(fetchServicesThunk());
    }, []);

    useEffect(() => {
        if (marketplaceStatus === MarketplaceStatus.FetchSuccess) {
            setPetTypes(masterData.petTypes.filter(pt => marketplace.legacy.petTypes.includes(pt.id)));
            setBusinessAreas(marketplace.businessAreas);
        }
    }, [marketplaceStatus]);

    useEffect(() => {
        if (servicesStatus === ServicesStatus.FetchSuccess) {
            if (props.serviceId) {
                let service = [];
                service = services.filter(service => {
                    return service.id === props.serviceId;
                });
                editService(service[0]);
            }
        }

        if (servicesStatus === ServicesStatus.SaveSuccess) {
            dispatch(fetchOnboardingStepsThunk());
        }
    }, [servicesStatus]);

    const goToServicesDashboard = () => {
        setIsEdition(false);
    };

    const addService = () => {
        setServiceToEdit(undefined);
        setIsEdition(true);
    };

    const editService = (service: Service) => {
        setAddStaff(false);
        setServiceToEdit(cloneDeep(service));
        setIsEdition(true);
    };

    const addStaffToService = (service: Service) => {
        setAddStaff(true);
        setServiceToEdit(cloneDeep(service));
        setIsEdition(true);
    };

    const saveHandler = (service: Service, changedName?: boolean) => {
        dispatch(saveServiceThunk(service, changedName));
        if (businessHoursDone && teamDone && petTypesDone) {
            dispatch(onboardingCloseModal());
        }
    };

    const deleteHandler = (serviceIds: Array<number>) => {
        dispatch(deleteServicesThunk(serviceIds));
    };

    const activateHandler = (serviceIds: Array<number>, active: boolean) => {
        dispatch(activateServicesThunk(serviceIds, active));
    };

    const updatePetTypesHandler = (serviceIds: Array<number>, petTypeIds: Array<string>) => {
        dispatch(updatePetTypesServicesThunk(serviceIds, petTypeIds));
    };

    const updateBusinessAreaHandler = (serviceIds: Array<number>, businessAreaId: string) => {
        dispatch(updateBusinessAreaServicesThunk(serviceIds, businessAreaId));
    };

    const onboarding = (
        <OnboardingTutorial
            titlePage={onboardingPageParams.title}
            addButtonLabel={onboardingPageParams.buttonLabel}
            onPressAdd={addService}
            urlImage={onboardingPageParams.image}
            imageWidth={onboardingPageParams.imageWidth}
            imageHeight={onboardingPageParams.imageHeight}
            onPressLinkToVideo={() => dispatch(onboardingShowVideo(ONBOARDING_VIDEOS.ADD_SERVICE))}
            isMobile={isMobile}
        />
    );

    const servicesComponent = !servicesDone ? (
        onboarding
    ) : (
        <ServicesTable
            {...props}
            save={saveHandler}
            businessAreas={businessAreas}
            petTypes={petTypes}
            addService={addService}
            editService={editService}
            addStaff={addStaffToService}
            delete={deleteHandler}
            activate={activateHandler}
            updatePetTypes={updatePetTypesHandler}
            updateBusinessArea={updateBusinessAreaHandler}
        />
    );

    const serviceComponent = (
        <ServiceComponent
            services={services}
            onBack={goToServicesDashboard}
            onSave={saveHandler}
            service={serviceToEdit}
            addStaff={addStaff}
        />
    );
    return <Box className={classes.container}>{!isEdition ? servicesComponent : serviceComponent}</Box>;
};

export default ServicesComponent;
