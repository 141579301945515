import React, { FunctionComponent, ReactNode, ReactElement, useState } from "react";
import ChipMUI from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

interface Props {
  label: ReactNode;
  avatar?: ReactElement;
  cursorPointer?: boolean;
  fullWidth?: boolean;
  variant?: "default" | "outlined";
  onDelete?: () => void;
  className?: string;
  center?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: (props: Props) => ({
      display: "flex",
      background: props.variant === "outlined" ? "#FFFFFF" : "#F1F1F1",
      justifyContent: props.center ? "center" : "flex-start",
      width: props.fullWidth ? "100%" : "fit-content",
      cursor: props.cursorPointer ? "pointer" : "default",
      paddingLeft: "0px",
      paddingRight: "0px",
      [theme.breakpoints.down("md")]: {
        height: "11px",
        borderRadius: "14px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("lg")]: {
        height: "15px",
        borderRadius: "18px",
        fontSize: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        height: "20px",
        borderRadius: "27px",
        fontSize: "10px",
      },
    })
  })
);

export const Chip: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const mouseEnterHandler = () => {
    if(props.onDelete) {
      setShowDeleteButton(true);
    }
  };
  
  const mouseLeaveHandler = () => {
    if(props.onDelete) {
      setShowDeleteButton(false);
    }
  };
  
  return (
    <ChipMUI
      avatar={props.avatar}
      label={props.label}
      className={clsx(classes.chip, props.className)}
      onMouseLeave={mouseLeaveHandler}
      onMouseOver={mouseEnterHandler}
      onDelete={showDeleteButton ? props.onDelete : undefined}
      deleteIcon={<FontAwesomeIcon icon={faTimes} />}
      clickable={false}
      variant={props.variant}
    />
  );
};

export default Chip;
