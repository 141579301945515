import React, { FunctionComponent, useState, useRef, Fragment } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/pro-light-svg-icons";
import EditImageOptions from "components/Marketplace/BusinessSettings/Logos/UI/EditImageOptions";
import { CropperImage } from "components/UI";

export interface Props {
  imageUrl?: string;
  onChangeImage: (imageFile: File | undefined, image: string | undefined) => void;
  maxSize: number;
  maxWidth: number;
  maxHeight: number;
  imageTypes: Array<string>;
  onRemove?: () => void;
  className?: string;
  childClassName?: string
  dropdownClassName?: string
  type?: "square";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "20px",
    },
    content: {
      display: "flex"
    },
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%"
    },

    sectionContainer: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "32px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "43px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "65px",
      },
    },
    titleSection: {
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7px",
        lineHeight: "8px",
        fontSize: "6px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "11px",
        lineHeight: "13px",
        fontSize: "10px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "14px",
        lineHeight: "18px",
        fontSize: "13px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "21px",
        lineHeight: "27px",
        fontSize: "20px",
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "2px solid #000",
      [theme.breakpoints.down("sm")]: {
        height: "41px",
        width: (props: Props) => props.type === "square" ? "41px" : "113px",
        marginBottom: "2px"
      },
      [theme.breakpoints.only("md")]: {
        height: "66px",
        width: (props: Props) => props.type === "square" ? "66px" : "183px",
        marginBottom: "4px"
      },
      [theme.breakpoints.only("lg")]: {
        height: "88px",
        width: (props: Props) => props.type === "square" ? "88px" : "244px",
        marginBottom: "5px"
      },
      [theme.breakpoints.only("xl")]: {
        height: "134px",
        width: (props: Props) => props.type === "square" ? "134px" : "369px",
        marginBottom: "8px"
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      backgroundColor: "#FAFAFA",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "6px",
        height: "41px",
        width: (props: Props) => props.type === "square" ? "41px" : "113px",
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "10px",
        height: "66px",
        width: (props: Props) => props.type === "square" ? "66px" : "183px",
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "13px",
        height: "88px",
        width: (props: Props) => props.type === "square" ? "88px" : "244px",
      },
      [theme.breakpoints.only("xl")]: {
        borderRadius: "20px",
        height: "134px",
        width: (props: Props) => props.type === "square" ? "134px" : "369px",
      },
    },
    containerBox: {
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "6px",
        height: "41px",
        width: (props: Props) => props.type === "square" ? "41px" : "113px",
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "10px",
        height: "66px",
        width: (props: Props) => props.type === "square" ? "66px" : "183px",
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "13px",
        height: "88px",
        width: (props: Props) => props.type === "square" ? "88px" : "244px",
      },
      [theme.breakpoints.only("xl")]: {
        borderRadius: "20px",
        height: "134px",
        width: (props: Props) => props.type === "square" ? "134px" : "369px",
      },
    },
    box: {
      cursor: "pointer",
      backgroundImage: (props: Props) => "url(" + props.imageUrl + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100%",
      width: "100%"
    },
    uploadIcon: {
      color: "#5E8677",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: "26px",
        lineHeight: "9px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "30px",
        lineHeight: "15px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "37px",
        lineHeight: "20px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "49px",
        lineHeight: "30px",
      },
    },
    uploadTitle: {
      fontWeight: 500,
      color: "#5E8677",
      cursor: "pointer",
      textDecoration: "underline",
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
        lineHeight: "9px",
        marginBottom: "2px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
        lineHeight: "15px",
        marginBottom: "4px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "14px",
        lineHeight: "20px",
        marginBottom: "5px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
        lineHeight: "30px",
        marginBottom: "8px"
      },
    },
    uploadIconContainer: {
      display: "flex",
      flexDirection: "column",
    },
    maxSize: {
      color: "#969696",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        lineHeight: "8px",
        marginRight: "6px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "6px",
        lineHeight: "13px",
        marginRight: "10px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "8px",
        lineHeight: "18px",
        marginRight: "13px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "12px",
        lineHeight: "27px",
        marginRight: "20px"
      },
    },
    warningSize: {
      color: "red",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        lineHeight: "8px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "6px",
        lineHeight: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "8px",
        lineHeight: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "12px",
        lineHeight: "27px",
      },
    },
    sizeContainer: {
      display: "flex",
      flexDirection: "column"
    },
    edit: {
      cursor: "pointer",
      position: "absolute",
      fontWeight: 600,
      textDecoration: "underline",
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        left: (props: Props) => props.type === "square" ? "9px" : "45px",
        top: (props: Props) => props.type === "square" ? "10px" : "12px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        left: (props: Props) => props.type === "square" ? "23px" : "79px",
        top: (props: Props) => props.type === "square" ? "20px" : "20px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        left: (props: Props) => props.type === "square" ? "33px" : "105px",
        top: (props: Props) => props.type === "square" ? "30px" : "30px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
        left: (props: Props) => props.type === "square" ? "48px" : "160px",
        top: (props: Props) => props.type === "square" ? "50px" : "50px",
      },
    },
    editOptions: {
      position: "absolute",
      "&.MuiTypography-root": {
        "&.hover": {
          color: "#000 !important"
        }
      },
      [theme.breakpoints.down("sm")]: {
        left: (props: Props) => props.type === "square" ? "210px" : "290px",
        top: (props: Props) => props.type === "square" ? "89px" : "200px",
      },
      [theme.breakpoints.only("md")]: {
        left: (props: Props) => props.type === "square" ? "290px" : "410px",
        top: (props: Props) => props.type === "square" ? "138px" : "309px",
      },
      [theme.breakpoints.only("lg")]: {
        left: (props: Props) => props.type === "square" ? "360px" : "525px",
        top: (props: Props) => props.type === "square" ? "183px" : "407px",
      },
      [theme.breakpoints.up("xl")]: {
        left: (props: Props) => props.type === "square" ? "560px" : "790px",
        top: (props: Props) => props.type === "square" ? "275px" : "602px",
      },
    },
    warningBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      textAlign: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: (props: Props) => props.type === "square" ? "41px" : "113px",
      },
      [theme.breakpoints.only("md")]: {
        width: (props: Props) => props.type === "square" ? "66px" : "183px",
      },
      [theme.breakpoints.only("lg")]: {
        width: (props: Props) => props.type === "square" ? "88px" : "244px",
      },
      [theme.breakpoints.only("xl")]: {
        width: (props: Props) => props.type === "square" ? "134px" : "369px",
      },
    },
    imageWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: "50%",
      height: "167px",
      width: "167px",
      [theme.breakpoints.only("xl")]: {
        height: "200px",
        width: "200px",
      },
    },
    imageBoxWithOpacity: {
      filter: "brightness(0.8)"
    },
    imageText: {
      fontWeight: 500,
      color: "#CC444B",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: "6px",
        marginBottom: "2px",
        paddingRight: "4px",
        paddingLeft: "4px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        marginBottom: "4px",
        paddingRight: "6px",
        paddingLeft: "6px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        marginBottom: "6px",
        paddingRight: "8px",
        paddingLeft: "8px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
        marginBottom: "9px",
        paddingRight: "20px",
        paddingLeft: "20px"
      },
    },
    redDescription: {
      color: "#CC444B",
    },
  })
);

export const ImageComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [hoverImage, setHoverImage] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);

  const [warning, setWarning] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const [showCropper, setShowCropper] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const handleChangeClick = () => {
    input.current?.click();
    setShowEditImage(false);
    setHoverImage(false);
  };

  const mouseLeaveHandler = () => {
    setHoverImage(false);
  };

  const mouseEnterHandler = () => {
    setHoverImage(true);
  };

  const handleClickEdit = () => {
    setShowEditImage(true)
  };

  const handleRemoveEdit = () => {
    props.onChangeImage && props.onChangeImage(undefined, undefined);
    setShowEditImage(false);
    setHoverImage(false);
  };

  const imageTypesDescription = () => {
    let typesDescription = "";

    props.imageTypes.forEach((imgType, index) => {
      if (index === 0) {
        typesDescription = typesDescription + imgType;
      } else {
        typesDescription = typesDescription + " or " + imgType
      }
    });

    return typesDescription;
  };

  const getImagesType = () => {
    let typesDescription = "";

    props.imageTypes.forEach((imgType, index) => {
      if (index === 0) {
        typesDescription = typesDescription + " ." + imgType.toLowerCase();
      } else {
        typesDescription = typesDescription + ", ." + imgType.toLowerCase();
      }
    });

    return typesDescription;
  };

  const selectImageHandler = (event: any) => {

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        const weightCondition = file.size / 1024 <= props.maxSize;

        if (weightCondition) {
          setSelectedImage(reader.result?.toString());
          setFileName(file.name);
          setShowCropper(true);
          setWarning(false);
        } else {
          setWarning(true);
        }
        setShowEditImage(false);
        setHoverImage(false);
      }
    }
    reader.readAsDataURL(file);
  };

  const cropHandler = (file: File) => {

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        props.onChangeImage && props.onChangeImage(file, reader.result?.toString()!);
        setShowCropper(false);
      }
    }
    reader.readAsDataURL(file);
  };

  return (
    <Fragment>
      <Box className={clsx(classes.container, props.className)}>
        <input
          style={{ display: "none" }}
          type={"file"}
          ref={input}
          onChange={(e) => selectImageHandler(e)}
          accept={getImagesType()}
          value={""}
        />
        <ClickAwayListener onClickAway={() => setShowEditImage(false)}>
          <Box className={clsx(classes.containerBox, { [classes.wrapper]: props.imageUrl }, props.childClassName)} onMouseLeave={mouseLeaveHandler} onMouseOver={mouseEnterHandler}>
            {props.imageUrl ?
              <Box
                className={clsx({ [classes.warningBox]: warning, [classes.box]: !warning, [classes.imageBoxWithOpacity]: hoverImage })}
                onClick={() => { !warning ? handleClickEdit() : handleChangeClick() }}
              >
                {warning && !hoverImage &&
                  <Box>
                    <Typography className={classes.imageText}>This file is too large.</Typography>
                  </Box>}
                {hoverImage && !warning && <Typography className={classes.edit}>Edit</Typography>}
                {hoverImage && warning && <Typography className={classes.uploadTitle}>Upload</Typography>}
              </Box>
              :
              <Box className={clsx(classes.uploadBox)} onClick={handleChangeClick}>
                {!hoverImage && !warning && <Box className={classes.uploadIconContainer}>
                  <FontAwesomeIcon icon={faPaw} className={classes.uploadIcon} />
                </Box>}
                {!hoverImage && warning && <Box>
                  <Typography className={classes.imageText}>This file is too large.</Typography>
                </Box>
                }
                {hoverImage &&
                  <Typography className={classes.uploadTitle}>Upload</Typography>}
              </Box>
            }
            <EditImageOptions
              show={showEditImage}
              color={"#000"}
              className={classes.editOptions}
              onChange={handleChangeClick}
              onRemove={handleRemoveEdit}
              onShow={() => setShowEditImage(false)}
              dropdownClassName={props.dropdownClassName}
            />
          </Box>
        </ClickAwayListener>
        <Box className={classes.sizeContainer}>
          <Typography className={classes.maxSize}>{`Recommended Ratio: ${props.maxWidth}x${props.maxHeight}, ${imageTypesDescription()}`}</Typography>
          <Typography className={clsx(classes.maxSize, { [classes.redDescription]: warning })}>{`Max Size: ${props.maxSize}KB`}</Typography>
        </Box>
      </Box>
      {showCropper && (
        <CropperImage
          image={selectedImage!}
          fileName={fileName!}
          cropWidth={props.maxWidth}
          cropHeight={props.maxHeight}
          onClose={() => setShowCropper(false)}
          onCrop={cropHandler}
          viewScale={props.type === "square" ? 0.5 : 1}
        />
      )}
    </Fragment>
  );
};

export default ImageComponent;
