import { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PetType, PetTypeIds } from "@spike/model";
import { wbp, reduceResolution } from "Theme";

interface PetTypeChipProps {
  petType: PetType;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      width: "fit-content",
      cursor: "default",
      borderRadius: "50px",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
        padding: `${reduceResolution(3)}px ${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px",
        padding: "3px 14px",
      },      
    },
    dogs: {
      color: "#FFF !important",
      backgroundColor: "#5E8677 !important",
    },
    cats: {
      color: "#C18733 !important",
      backgroundColor: "#FAEFDF !important",
    },
    exotics: {
      color: "#9C8267 !important",
      backgroundColor: "#EBE7E3 !important",
    },
    label: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px",
      },
    }
  })
);

export const PetTypeChip: FunctionComponent<PetTypeChipProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className,
      {
        [classes.dogs]: props.petType.id === PetTypeIds.DOGS,
        [classes.cats]: props.petType.id === PetTypeIds.CATS,
        [classes.exotics]: props.petType.id === PetTypeIds.EXOTICS,
      }
    )
    }>
      <Typography className={classes.label}>{props.petType.singular}</Typography>
    </Box >
  );
};

export default PetTypeChip;
