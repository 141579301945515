import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useMasterData } from 'hooks';
import { SelectableOption } from 'model';
import { Option } from '@spike/model';
import CheckboxCircle from 'components/UI/CheckboxCircle';
import { isEmpty } from 'lodash';

interface Props {
	recommendations: Array<Option<number>>;
	isReport: boolean;
	section: string;
	multipleSelection?: boolean;
	onChangeSelection: (recommendations: Array<Option<number>>) => void;
	id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		content: {
			gap: 16,
			display: 'flex',
			flexWrap: 'wrap'
		},
		recommendationContainer: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 500,
			display: 'flex',
			alignItems: 'center'
		},
		checkbox: {
			'& svg': {
				width: 20,
				height: 20,
				color: '#000'
			},
			'& .MuiCheckbox-root': {
				paddingRight: 8
			}
		}
	})
);

export const Recommendations: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();

	const [recommendations, setRecommendations] = useState<
		Array<SelectableOption<number>>
	>([]);

	useEffect(() => {
		const recommendationsMaster =
			props.section === 'dental'
				? masterData.dentalReportOptions.recommendations
				: masterData.groomingReportOptions.recommendations;
		let recommendationAux = recommendationsMaster.map(recommendation => ({
			element: recommendation,
			selected: props.recommendations.find(
				rec => rec.id === recommendation.id
			)
				? true
				: false
		}));
		setRecommendations(recommendationAux);
	}, [props.recommendations]);

	const handleSelectRecommendation = (
		value: Option<number>,
		selected: boolean
	) => {
		if (props.multipleSelection || selected) {
			const newRecommendation = [...recommendations];

			newRecommendation.forEach(recOption => {
				recOption.selected =
					recOption.element.id === value.id
						? selected
						: props.multipleSelection
						? recOption.selected
						: false;
			});

			setRecommendations(newRecommendation);
			props.onChangeSelection(
				newRecommendation
					.filter(rec => rec.selected)
					.map(rec => rec.element)
			);
		}
	};

	return (
		<Box>
			{(props.isReport ||
				(!isEmpty(props.recommendations) && !props.isReport)) && (
				<Typography className={classes.titleSection}>
					Recommendations
				</Typography>
			)}
			{(props.isReport ||
				(!isEmpty(props.recommendations) && !props.isReport)) && (
				<Box className={classes.content}>
					{props.isReport
						? recommendations.map((recommendation, index) => {
								return (
									<Box
										key={recommendation.element.id}
										className={
											classes.recommendationContainer
										}
									>
										<CheckboxCircle
											id={`${props.id || ''}_${index}`}
											checked={recommendation.selected}
											iconColor="#5E8677"
											className={classes.checkbox}
											onChange={selected =>
												handleSelectRecommendation(
													recommendation.element,
													selected
												)
											}
										/>
										{recommendation.element.name}
									</Box>
								);
						  })
						: props.recommendations.map(recommendation => {
								return (
									<Box
										key={recommendation.id}
										className={
											classes.recommendationContainer
										}
									>
										<CheckboxCircle
											iconColor="#5E8677"
											checked={true}
											onChange={() => {}}
										/>
										{recommendation.name}
									</Box>
								);
						  })}
				</Box>
			)}
		</Box>
	);
};

export default Recommendations;
