import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Appointment, status } from "@spike/appointment-model";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import { ToolbarItem } from "components/UI/Toolbar/ToolbarItem";
import { NoShowToolbarItem } from "components/UI/Toolbar/NoShowToolbarItem";
import { CancelToolbarItem } from "components/UI/Toolbar/CancelToolbarItem";

export interface Props {
  appointment: Appointment;
  onNoShow: () => void;
  onCancel: () => void;
  onReschedule: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolBarButtonContainer: {
    [theme.breakpoints.down("lg")]: {
      right: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      right: "65px",
    },
  },
}));

const menuStatus = [
  status.BOOKED,
  status.CONFIRMED,
  status.CHECKED_IN,
  status.IN_PROGRESS,
  status.CANCELLED,
  status.NO_SHOW
];

const cancelStatus = [status.BOOKED, status.CONFIRMED, status.CHECKED_IN];
const noShowStatus = [status.BOOKED, status.CONFIRMED];
const rescheduleStatus = [
  status.BOOKED,
  status.CONFIRMED,
  status.NO_SHOW,
  status.CANCELLED,
];

export const AppointmentToolbarButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const cancelItem = cancelStatus.includes(props.appointment.status.id) ? (
    <CancelToolbarItem
      onConfirmedClick={props.onCancel}
    />
  ) : null;

  const noShowItem = noShowStatus.includes(props.appointment.status.id) ? (
    <NoShowToolbarItem
      onConfirmedClick={props.onNoShow}
    />
  ) : null;
  const rescheduleItem = rescheduleStatus.includes(props.appointment.status.id) ? (
    <ToolbarItem
      icon={faCalendarAlt}
      text="Reschedule"
      onClick={props.onReschedule}
    />
  ) : null;

  const menu = (
    <ToolbarButton className={classes.toolBarButtonContainer}>
      {cancelItem}
      {noShowItem}
      {rescheduleItem}
    </ToolbarButton>
  );

  return menuStatus.includes(props.appointment.status.id) ? menu : null;
};

export default AppointmentToolbarButton;
