import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { PercentageField2, PriceField2 } from '.';
import { useFieldStyles } from './FieldStyles';

interface AmountPercetageFieldProps {
	name?: string;
	percentage: number | null;
	amount: number | null;
	errors?: Array<FieldError>;
	precision?: number;
	scale?: number;
	onlyAmount?: boolean;
	loading?: boolean;
	className?: string;
	onChange?: (amount: number | null, percentage: number | null) => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		errorContainer: {
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				marginBottom: `${reduceResolution(10)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginBottom: '10px'
			}
		},
		componentContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		option: {
			width: 50,
			height: 42,
			flex: '0 0 50px',
			display: 'flex',
			cursor: 'pointer',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#F1F1F1',
			borderRadius: 6,
			marginRight: 14,

			[theme.breakpoints.up('md')]: {
				width: 62,
				height: 54,
				flex: '0 0 62px'
			}
		},
		selectedOption: {
			backgroundColor: '#222222'
		},
		symbol: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#7A7A7A',
			lineHeight: '100%',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(20)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px'
			}
		},
		selectedSymbol: {
			color: 'white'
		},
		field: {
			width: '100%',

			[theme.breakpoints.up(wbp)]: {
				width: '200px'
			}
		}
	})
);

export const AmountPercetageField: FunctionComponent<
	AmountPercetageFieldProps
> = props => {
	const classes = useStyles();
	const fieldClasses = useFieldStyles();

	const [isAmount, setIsAmount] = useState(
		props.onlyAmount || props.percentage === null
	);

	const [value, setValue] = useState(
		props.percentage && !props.onlyAmount
			? props.percentage || 0
			: props.amount || 0
	);

	useNonInitialEffect(() => {
		const timer: NodeJS.Timer = setTimeout(() => {
			if (isAmount) {
				props.onChange && props.onChange(value, null);
			} else {
				props.onChange && props.onChange(null, value);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [value]);

	const hasError =
		props.errors?.some(error => error.fieldName === props.name) || false;

	const errorMessage =
		props.errors?.find(error => error.fieldName === props.name)
			?.errorMessage || '';

	const selectType = (type: 'amount' | 'percentage') => {
		if(!props.loading) {
			setIsAmount(type === 'amount');
			setValue(0);
		}
	};

	const selectAmountHandler = () => selectType('amount');

	const selectPercentageHandler = () => selectType('percentage');

	return (
		<Box className={clsx(classes.container, props.className)}>
			{hasError && (
				<Box className={classes.errorContainer}>
					<Typography
						className={clsx(
							fieldClasses.label,
							fieldClasses.labelError
						)}
					>
						{errorMessage}
					</Typography>
				</Box>
			)}
			<Box className={classes.componentContainer}>
				<Box
					id={'booking_payment_div_amount'}
					className={clsx(classes.option, {
						[classes.selectedOption]: isAmount
					})}
					onClick={selectAmountHandler}
				>
					<Typography
						className={clsx(classes.symbol, {
							[classes.selectedSymbol]: isAmount
						})}
					>
						$
					</Typography>
				</Box>
				{!props.onlyAmount && (
					<Box
						id={'booking_payment_div_percentage'}
						className={clsx(classes.option, {
							[classes.selectedOption]: !isAmount
						})}
						onClick={selectPercentageHandler}
					>
						<Typography
							className={clsx(classes.symbol, {
								[classes.selectedSymbol]: !isAmount
							})}
						>
							%
						</Typography>
					</Box>
				)}
				<Box>
					{isAmount && (
						<PriceField2
							id={'booking_payment_input_amount'}
							name={props.name}
							scale={2}
							precision={6}
							value={value || 0.0}
							onChange={value => setValue(value || 0)}
							onBlur={props.onBlur}
							onFocus={props.onFocus}
							errors={props.errors}
							className={classes.field}
						/>
					)}
					{!props.onlyAmount && !isAmount && (
						<PercentageField2
							id={'booking_payment_input_percentage'}
							name={props.name}
							scale={props.scale || 0}
							precision={props.precision || 2}
							value={value || 0.0}
							onChange={value => setValue(value || 0)}
							onBlur={props.onBlur}
							onFocus={props.onFocus}
							errors={props.errors}
							className={classes.field}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default AmountPercetageField;
