import React, { FunctionComponent } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Appointment, { AppointmentCountRecurrent } from "@spike/appointment-model";
import BookingRecurrencyInfo from "../UI/BookingRecurrencyInfo";


interface Props {
    appointment?: Appointment; 
    frequency?: AppointmentCountRecurrent;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        },
        date: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "16px"
        },
        recurrencyContainer: {
            width: "100%",
			fontWeight: 500,
			marginTop: 8
        }
    })
);

export const DateInfo: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    return (
        <Box>
            {!props.appointment ? 
                <CircularProgress size="1.5rem" style={{ 'color': '#EAB464' }} /> : 
            
            <Box className={classes.container}>
                <Typography className={classes.date}>
                    {`${props.appointment.duration.from.format("dddd, DD MMM YYYY")} - ${props.appointment.duration.from.format("LT")} - ${props.appointment.duration.to.format("LT")}`}
                </Typography>
                {props.appointment?.recurringUuid && (
					<Box className={classes.recurrencyContainer}>
						<BookingRecurrencyInfo recurrent={props.frequency} />
					</Box>
				)}
            </Box>}
        </Box>
    );
};

export default DateInfo
