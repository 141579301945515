import {
    FunctionComponent,
    useRef,
    useState,
    MouseEvent,
    ChangeEvent
} from 'react';
import {
    Box,
    ClickAwayListener,
    createStyles,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Theme
} from '@material-ui/core';
import { Button } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { wbp, reduceResolution } from 'Theme';
import Picker, { IEmojiData, SKIN_TONE_LIGHT } from 'emoji-picker-react';

interface ChatInputProps {
    isActive: boolean;
    messageText: string;
    onMessageChange: (text: string) => void;
    onSendMessage: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatTextInput: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(105)}px`,
                paddingTop: `${reduceResolution(10)}px`,
                paddingLeft: `${reduceResolution(45)}px`,
                paddingRight: `${reduceResolution(25)}px`,
                fontSize: reduceResolution(14),
                lineHeight: reduceResolution(1.7)
            },
            [theme.breakpoints.up(wbp)]: {
                height: '105px',
                paddingTop: '10px',
                paddingLeft: '45px',
                paddingRight: '25px',
                fontSize: 14,
                lineHeight: 1.7
            },
            'border': 0,
            'width': '100%',
            'resize': 'none',
            'outline': '0px !important',
            'fontWeight': 400,
            'color': '#222222',
            'fontFamily': 'Poppins',
            'backgroundColor': '#FFF !important',
            '& :disabled': {
                backgroundColor: '#FFF !important'
            },
            '& fieldset': {
                border: '0px solid #D3D3D3 !important'
            },
            '& .MuiInputBase-root': {
                [theme.breakpoints.down(wbp)]: {
                    height: `${reduceResolution(100)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    height: '100px'
                },
                alignItems: 'start'
            }
        },
        chatTab: {
            'textTransform': 'none',
            'fontWeight': 400,
            '& .MuiTab-wrapper': {
                borderBottom: '0',
                color: '#D4D4D4'
            },
            '& .MuiTab-root': {
                minWidth: '70px !important'
            }
        },
        chatTabSelectedActive: {
            'textTransform': 'none',
            'fontWeight': 500,
            '& .MuiTab-wrapper': {
                borderBottom: '2px #000 solid',
                [theme.breakpoints.down(wbp)]: {
                    marginRight: `${reduceResolution(35)}px`,
                    marginLeft: `${reduceResolution(35)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    marginRight: '35px',
                    marginLeft: '35px'
                },
                color: '#000'
            },
            '& .MuiTab-root': {
                [theme.breakpoints.down(wbp)]: {
                    minWidth: `${reduceResolution(70)}px !important`
                },
                [theme.breakpoints.up(wbp)]: {
                    minWidth: '70px !important'
                }
            }
        },
        chatTabSelected: {
            'textTransform': 'none',
            'fontWeight': 500,
            '& .MuiTab-wrapper': {
                borderBottom: '2px #D4D4D4 solid',
                [theme.breakpoints.down(wbp)]: {
                    marginRight: `${reduceResolution(35)}px`,
                    marginLeft: `${reduceResolution(35)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    marginRight: '35px',
                    marginLeft: '35px'
                },
                color: '#D4D4D4'
            },
            '& .MuiTab-root': {
                [theme.breakpoints.down(wbp)]: {
                    minWidth: `${reduceResolution(70)}px !important`
                },
                [theme.breakpoints.up(wbp)]: {
                    minWidth: '70px !important'
                }
            }
        },
        chatFooterContainer: {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyItems: 'right'
        },
        chatFooterItem: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(10)}px`,
                paddingLeft: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: '10px',
                paddingLeft: '10px'
            }
        },
        chatSendButton: {
            '& .Mui-disabled': {
                backgroundColor: '#D3D3D3 !important'
            }
        },
        counter: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`,
                paddingTop: `${reduceResolution(15)}px`,
                paddingRight: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px',
                paddingTop: '15px',
                paddingRight: '15px'
            },
            lineHeight: 1,
            fontWeight: 500,
            color: '#7A7A7A',
            textAlign: 'right'
        },
        emojiButton: {
            fontSize: 20,
            color: '#222',
            border: 'none',
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            padding: '0px 5px'
        },
        emojiPicker: {
            top: '50%',
            left: '74%',
            zIndex: 100,
            position: 'absolute'
        }
    })
);

const ChatInput: FunctionComponent<ChatInputProps> = props => {
    const classes = useStyles();

    const [showPicker, setShowPicker] = useState<boolean>(false);

    const textarea = useRef<HTMLTextAreaElement>(null);

    const handleEmojiPickerClickAway = () => {
        setShowPicker(false);
    };

    const onEmojiClick = (e: MouseEvent, emojiObject: IEmojiData) => {
        setShowPicker(false);
        if (textarea.current) {
            const newMessage = props.messageText + emojiObject.emoji;
            textarea.current.value = newMessage;
            props.onMessageChange(newMessage);
        }
    };

    const messageInfo = () => {
        if (!props.messageText) return `${0}/160 (1 message)`;
        if (props.messageText.length <= 160)
            return `${props.messageText.length}/160 (1 message)`;
        if (props.messageText.length > 160) {
            const messageLengthOver = props.messageText.length - 160;
            return `${messageLengthOver}/160 (1 message)`;
        }
    };

    return (
        <>
            <Grid container justifyContent="flex-end">
                <Grid item xs={10}>
                    <Tabs aria-label="chatContentMenu" disabled={true}>
                        <Tab
                            className={
                                props.isActive
                                    ? classes.chatTabSelectedActive
                                    : classes.chatTabSelected
                            }
                            selected={true}
                            label="Message"
                        />
                    </Tabs>
                </Grid>
                <Grid className={classes.counter} item xs={2}>
                    <span>{messageInfo()}</span>
                </Grid>
            </Grid>
            <textarea
                ref={textarea}
                defaultValue={props.messageText}
                value={props.messageText}
                className={classes.chatTextInput}
                maxLength={160}
                disabled={!props.isActive}
                placeholder="Type your message..."
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    props.onMessageChange(e.currentTarget.value)
                }
            ></textarea>
            <Box className={classes.chatFooterContainer}>
                <Box className={classes.chatFooterItem}>
                    <Button
                        className={classes.chatSendButton}
                        color="black"
                        disabled={
                            !props.isActive ||
                            !props.messageText ||
                            props.messageText.length === 0
                        }
                        onClick={props.onSendMessage}
                        size="medium"
                        label="Send"
                    />
                </Box>
                <Box className={classes.chatFooterItem}>
                    <FontAwesomeIcon
                        color={props.isActive ? '#000' : '#D4D4D4'}
                        icon={faSmile}
                        onClick={() => setShowPicker(!showPicker)}
                    />
                </Box>
                {showPicker && (
                    <ClickAwayListener onClickAway={handleEmojiPickerClickAway}>
                        <Box className={classes.emojiPicker}>
                            <Picker
                                onEmojiClick={onEmojiClick}
                                disableAutoFocus={true}
                                skinTone={SKIN_TONE_LIGHT}
                                groupNames={{ smileys_people: 'PEOPLE' }}
                                native
                            />
                        </Box>
                    </ClickAwayListener>
                )}
            </Box>
        </>
    );
};

export default ChatInput;
