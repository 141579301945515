import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "store";
import isEqual from "lodash/isEqual";
import clsx from "clsx";
import { Button, Radio } from "components/UI";
import { MarketplaceGrooming } from "@spike/marketplace-model";
import { SelectableOption } from "model";
import { useMasterData } from "hooks";
import { AddOther } from "../UI/AddOther";
import { OptionType } from "../PetTypes/UI/OptionType";
import { useNonInitialEffect } from "@versiondos/hooks";
import { MarketplaceStatus } from "@spike/marketplace-action";
import { BusinessSettingsTitle } from "../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  grooming: MarketplaceGrooming;
  onSave?: (grooming: MarketplaceGrooming) => void;
  className?: string;
  openSidebar?: () => void;
  isMobile?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "171px",
      },
      [theme.breakpoints.only("md")]: {
        width: "276px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "368px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "558px",
      },
    },
    titleContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "14px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "22px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "45px",
      },
    },
    businessSettingsTittle: {
      display: "flex",
      alignItems: "center",
      "& $iconBusinessTitle": {
        fontSize: "14px"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginBottom: "5px",
      }
    },
    iconBusinessTitle: {
      position: "relative",
      marginRight: "8px"
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "13px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "22px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "29px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "44px",
      },
    },
    sectionContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    titleSection: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "6px",
        lineHeight: "9px",
        marginBottom: "2px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "10px",
        lineHeight: "15px",
        marginBottom: "3px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
        lineHeight: "20px",
        marginBottom: "4px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "20px",
        lineHeight: "30px",
        marginBottom: "6px",
      },
    },
    subtitleSection: {
      fontWeight: 400,
      color: "#969696",
      [theme.breakpoints.down("sm")]: {
        fontSize: "4px",
        lineHeight: "7px",
        marginBottom: "7px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "7px",
        lineHeight: "12px",
        marginBottom: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "9px",
        lineHeight: "16px",
        marginBottom: "15px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "14px",
        lineHeight: "24px",
        marginBottom: "23px",
      },
    },
    contentSection: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "14px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "23px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "46px",
      },
    },
    holdingAreasAndSecurity: {
      "& .MuiCheckbox-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
          paddingRight: "4px !important",
          paddingLeft: "11px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
          paddingRight: "6px !important",
          paddingLeft: "18px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
          paddingRight: "8px !important",
          paddingLeft: "24px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "20px",
          paddingRight: "12px !important",
          paddingLeft: "36px",
        },
      },
      "& .MuiTypography-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "24px",
      },
    },
    addOtherContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "92px",
        paddingLeft: "11px",
      },
      [theme.breakpoints.only("md")]: {
        width: "149px",
        paddingLeft: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "198px",
        paddingLeft: "24px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "300px",
        paddingLeft: "36px",
      },
    },
    visibilityAndSizeSeparation: {
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "20px",
        },
      },
    },
    visibilityAndSizeSeparationContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "11px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "24px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "36px",
      },
    },
    holdingAreasAndSecurityContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "11px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "18px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "24px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "36px",
      },
    },
  })
);

export const Amenities: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const masterData = useMasterData();

  const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
  const [saved, setSaved] = useState(true);
  const [loading, setLoading] = useState(false);

  const [visibility, setVisibility] = useState<Array<SelectableOption<string>>>(
    masterData.grooming.visibility.map((visibility) => ({
      element: { ...visibility },
      selected: props.grooming.visibility.some((v) => v.id === visibility.id),
    }))
  );
  const [sizeSeparation, setSizeSeparation] = useState<Array<SelectableOption<string>>>(
    masterData.grooming.sizeSeparation.map((sizeSeparation) => ({
      element: { ...sizeSeparation },
      selected: props.grooming.sizeSeparation.some((ss) => ss.id === sizeSeparation.id),
    }))
  );
  const [holdingAreas, setHoldingAreas] = useState<Array<SelectableOption<string>>>(
    masterData.grooming.holdingAreas.map((holdingArea) => ({
      element: { ...holdingArea },
      selected: props.grooming.holdingAreas.some((ha) => ha.id === holdingArea.id),
    }))
  );
  const [security, setSecurity] = useState<Array<SelectableOption<string>>>(
    masterData.grooming.security.map((security) => ({
      element: { ...security },
      selected: props.grooming.security.some((s) => s.id === security.id),
    }))
  );
  const [others, setOthers] = useState<Array<string>>(props.grooming.others);

  useNonInitialEffect(() => {
    const groomingAux: MarketplaceGrooming = {
      holdingAreas: holdingAreas.filter(ha => ha.selected).map(ha => ha.element),
      security: security.filter(s => s.selected).map(s => s.element),
      visibility: visibility.filter(v => v.selected).map(v => v.element),
      sizeSeparation: sizeSeparation.filter(ss => ss.selected).map(ss => ss.element),
      others,
    };

    setSaved(isEqual(props.grooming, groomingAux));
  }, [holdingAreas, security, visibility, sizeSeparation, others]);

  useNonInitialEffect(() => {
    if (status === MarketplaceStatus.SaveSuccess) {
      setSaved(true);
      setLoading(false);
    }
  }, [status]);

  const handleCheckHoldingAreas = (hold: SelectableOption<string>, checked: boolean) => {
    let holdingAreasAux = [...holdingAreas];
    holdingAreasAux.forEach((t) => {
      if (t.element.id === hold.element.id) {
        t.selected = checked;
      }
    });
    setHoldingAreas(holdingAreasAux);
  };

  const handleCheckSecurity = (sec: SelectableOption<string>, checked: boolean) => {
    let securityAux = [...security];
    securityAux.forEach((t) => {
      if (t.element.id === sec.element.id) {
        t.selected = checked;
      }
    });
    setSecurity(securityAux);
  };

  const handleCheckVisibility = (visib: SelectableOption<string>) => {
    let visibilityAux = [...visibility];
    visibilityAux.forEach((t) => {
      if (t.element.id === visib.element.id) {
        t.selected = true;
      } else {
        t.selected = false;
      }
    });
    setVisibility(visibilityAux);
  };

  const handleCheckSizeSeparation = (sizeSep: SelectableOption<string>) => {
    let sizeSeparationAux = [...sizeSeparation];
    sizeSeparationAux.forEach((t) => {
      if (t.element.id === sizeSep.element.id) {
        t.selected = true;
      } else {
        t.selected = false;
      }
    });
    setSizeSeparation(sizeSeparationAux);
  };

  const handleAddOther = (value: string) => {
    const notExist = others.indexOf(value.toLowerCase()) === -1;
    if (notExist) {
      const newOthers = [...others, value];
      setOthers(newOthers);
    } else {
      return null;
    }
  };

  const handleEditOther = (value: string, index: number) => {
    let copyOthers = [...others];
    copyOthers[index] = value;
    setOthers(copyOthers);
  };

  const handleTypeOtherChange = (checked: boolean, index: number, description: string) => {
    const newOthers = others.filter((o) => o !== others[index]);
    setOthers(newOthers);
  };

  const saveHandler = () => {
    const groomingAux: MarketplaceGrooming = {
      holdingAreas: holdingAreas.filter(ha => ha.selected).map(ha => ha.element),
      security: security.filter(s => s.selected).map(s => s.element),
      visibility: visibility.filter(v => v.selected).map(v => v.element),
      sizeSeparation: sizeSeparation.filter(ss => ss.selected).map(ss => ss.element),
      others,
    };

    props.onSave && props.onSave({ ...groomingAux });
    props.onSave && setLoading(true);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.titleContainer}>
        <BusinessSettingsTitle className={classes.businessSettingsTittle}> {props.isMobile && (
          <FontAwesomeIcon
            className={classes.iconBusinessTitle}
            onClick={props.openSidebar}
            icon={faArrowLeft}
            size="2x"
          />
        )} Amenities</BusinessSettingsTitle>
      </Box>
      <Box className={classes.sectionContainer}>
        <Typography className={classes.titleSection}>Grooming Area Visibility</Typography>
        <Typography className={classes.subtitleSection}>Can clients see their pets getting groomed?</Typography>
        <Box className={classes.contentSection}>
          <Box className={classes.visibilityAndSizeSeparationContainer}>
            {visibility.map((visib) => {
              return (
                <Radio
                  key={visib.element.id}
                  label={visib.element.name}
                  checked={visib.selected}
                  onChange={() => {
                    handleCheckVisibility(visib);
                  }}
                  className={classes.visibilityAndSizeSeparation}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Typography className={classes.titleSection}>Holding Area</Typography>
        <Typography className={classes.subtitleSection}>Where do you hold pets while waiting?</Typography>
        <Box className={classes.contentSection}>
          <Box className={classes.holdingAreasAndSecurityContainer}>
            {holdingAreas.map((hold, index) => {
              return (
                <OptionType
                  key={hold.element.id}
                  index={index}
                  checked={hold.selected}
                  optionName={hold.element.name}
                  onTypeChange={(checked: boolean) => {
                    handleCheckHoldingAreas(hold, checked);
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Typography className={classes.titleSection}>Size Separation</Typography>
        <Typography className={classes.subtitleSection}>Do you separate pets by size?</Typography>
        <Box className={classes.contentSection}>
          <Box className={classes.visibilityAndSizeSeparationContainer}>
            {sizeSeparation.map((sizeSep) => {
              return (
                <Radio
                  key={sizeSep.element.id}
                  label={sizeSep.element.name}
                  checked={sizeSep.selected}
                  onChange={() => {
                    handleCheckSizeSeparation(sizeSep);
                  }}
                  className={classes.visibilityAndSizeSeparation}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Typography className={classes.titleSection}>Security</Typography>
        <Typography className={classes.subtitleSection}>
          Select the security measures that apply to your facility:
        </Typography>
        <Box className={classes.contentSection}>
          <Box className={classes.holdingAreasAndSecurityContainer}>
            {security.map((sec, index) => {
              return (
                <OptionType
                  key={sec.element.id}
                  index={index}
                  checked={sec.selected}
                  optionName={sec.element.name}
                  onTypeChange={(checked: boolean) => {
                    handleCheckSecurity(sec, checked);
                  }}
                />
              );
            })}
            {others.map((ot, index) => {
              return (
                <OptionType
                  key={ot}
                  index={index}
                  checked={true}
                  editable
                  optionName={ot}
                  onEditName={(nameEdited) => handleEditOther(nameEdited, index!)}
                  onTypeChange={(checked, index, des) => handleTypeOtherChange(checked, index!, des!)}
                />
              );
            })}
          </Box>
          <Box className={classes.addOtherContainer}>
            <AddOther onAddType={handleAddOther} />
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button label={saved ? "Saved!" : "Save"} onClick={saveHandler} loading={loading} />
      </Box>
    </Box>
  );
};

export default Amenities;
