import { Box, ClickAwayListener, Dialog, DialogContent, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError, Option } from "@spike/model";
import clsx from "clsx";
import { Button, DescriptionField2, SelectField } from "components/UI";
import isEmpty from "lodash/isEmpty";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import { TerminalOrder } from "@spike/terminal-model";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";

interface OrderReceivedPopUpProps {
    order: TerminalOrder
    //
    onClose: () => void;
    onConfirmCancel: (bookingId?: number, appointmentId?: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            "& .MuiDialog-paper": {
                boxShadow: "0px 10px 40px #00000029",
                [theme.breakpoints.down(wbp)]: {
                    width: `${reduceResolution(464)}px`,
                    borderRadius: `${reduceResolution(21)}px`,
                    height: `${reduceResolution(288)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    width: "464px",
                    borderRadius: "21px",
                    height: "288px",
                },
            },

            "& .MuiDialogContent-root": {
                overflowY: "hidden",
                paddingTop: 0,
            }
        },

        boxContainer: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(50)}px`,
                paddingRight: `${reduceResolution(50)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "50px",
                paddingRight: "50px",
            },
        },

        boxSpinner: {
            display: "flex",
            height: "100%",
        },

        dialogContainer: {
            width: "100%",
            "& .MuiDialog-paper": {
                overflowY: "hidden"
            },
            display: "flex",
            flexDirection: "column",
            padding: 0,
        },

        boxButtons: {
            borderTop: "1px solid #DDDDDD",
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`,
                paddingTop: `${reduceResolution(10)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: "20px",
                paddingTop: "10px",
            },
        },
        boxButtonsFlex: {
            width: "100%",
        },
        buttonsContainer: {
            display: "flex",
            justifyContent: "center",
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(191)}px`,
                height: `${reduceResolution(47)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: "191px",
                height: "47px",
            },
        },

        buttonCancel: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(8)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: "8px",
            },
        },

        bodyContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: "20px",
        },

        boxQuestion: {
            textAlign: "center",
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(48)}px`,
                paddingBottom: `${reduceResolution(16)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: "48px",
                paddingBottom: "16px",
            },
        },

        boxComment: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: "20px",
            },
        },

        comment: {
            "& .MuiOutlinedInput-multiline": {
                [theme.breakpoints.down(wbp)]: {
                    height: `${reduceResolution(150)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    height: "150px",
                },
                display: "flex",
                padding: "0",
                alignContent: "baseline",
                alignItems: "baseline",
            }

        },
    })
);

export const OrderReceivedPopUp: FunctionComponent<OrderReceivedPopUpProps> = (props) => {
    const classes = useStyles();
    const terminalCommonClasses = useTerminalCommonStyles();

    const handleConfirmSelection = () => {
        props.onConfirmCancel();
    };

    const handlerCancel = () => {
        props.onClose();
    };

    const contentView = (
        <>
            <Box className={classes.boxContainer}>
                <Box>
                    <Box className={classes.boxQuestion}>
                        <Typography className={terminalCommonClasses.typo24_600}>Did you received your order</Typography>
                        <Typography className={terminalCommonClasses.typo24_600}> # {props.order.id} ?</Typography>
                    </Box>
                    <Box className={terminalCommonClasses.flexRowCenter}>
                        <Typography align="center" className={clsx(terminalCommonClasses.typo16_400)}>If you have received your order click on <strong>"Yes, I got it"</strong> otherwise, wait a little longer.</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.boxButtons}>
                <Box className={classes.boxButtonsFlex}>
                    <Box className={classes.buttonsContainer}>
                        <Button
                            label="Not Yet"
                            loading={false}
                            onClick={handlerCancel}
                            variant="primary"
                            size="medium"
                            color={"black"}
                            className={clsx(classes.button)}
                        />
                        <Button
                            label="Yes, I got it"
                            loading={false}
                            onClick={handleConfirmSelection}
                            variant="primary"
                            color="green"
                            size="medium"
                            className={clsx(classes.button, classes.buttonCancel)}
                        />
                    </Box>
                </Box>
            </Box>
        </>);

    return (<Box>
        <Dialog open={true} className={clsx(classes.container)}>
            <ClickAwayListener onClickAway={handlerCancel}>
                <DialogContent className={classes.dialogContainer}>
                    {contentView}
                </DialogContent>
            </ClickAwayListener>
        </Dialog>
    </Box>);
};

export default OrderReceivedPopUp;
