import React from 'react'
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

interface Props {
    title: string,
    icon: IconProp,
    bgColor: string,
    value: string | number,
    onClick?: (value: string) => void,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        minWidth: 180,
        borderRadius: 10,
        cursor: 'pointer',
        padding: '18px 15px'
    },
    title: {
        color: '#222',
        fontSize: 16,
        lineHeight: 1,
        fontWeight: 500,
        marginBottom: 17,
        position: 'relative',

        '& svg': {
            fontSize: 18,
            marginRight: 11,
            verticalAlign: 'middle'
        }
    },
    value: {
        color: '#222',
        fontSize: 26,
        lineHeight: 1,
        fontWeight: 600,
        letterSpacing: '-0.02em'
    },
    arrow: {
        top: -1,
        right: 0,
        position: 'absolute',
        fontSize: '20px !important'
    }
}))

const MetricBox = ({ title, icon, value, bgColor, onClick }: Props) => {
    const classes = useStyles()

    return (
        <Box className={classes.root} style={{ backgroundColor: bgColor }} onClick={() => onClick && onClick(title)}>
            <Typography className={classes.title}>
                <FontAwesomeIcon icon={icon} />
                {title}

                <FontAwesomeIcon icon={faArrowRight} className={classes.arrow} />
            </Typography>

            <Typography className={classes.value}>{value}</Typography>
        </Box>
    )
}

export default MetricBox