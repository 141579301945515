import GlobalSearch from "model/GlobalSearch";

export interface GlobalSearchState {
  results: Array<GlobalSearch>;
  status: GlobalSearchStatus;
  loading: boolean;
}

export enum GlobalSearchStatus {
  Initial,
  Searching,
  SearchingSuccess,
  Error
}
