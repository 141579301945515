import React from 'react';
import { useTimeZone } from 'hooks';
import moment from 'moment-timezone';
import { SlotLabelContentArg } from '@fullcalendar/core';
import { createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles(() =>
    createStyles({
        date: {
            margin: 0,
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Poppins'
        },
        petsCount: {
            'fontSize': 12,
            'lineHeight': 1,
            'fontWeight': 400,
            'margin': '4px 0px 0px',
            'fontFamily': 'Poppins',
            'color': '#7A7A7A',

            '& svg': {
                marginRight: 4
            }
        }
    })
);

export const ScheduleTypeCalendarSlotLabel: React.FC<SlotLabelContentArg> = props => {
    const classes = useStyles();
    const timeZone = useTimeZone();

    return (
        <>
            <p className={classes.date}>{moment(props.date).tz(timeZone).format('ddd, D MMM')}</p>
            <p className={classes.petsCount}>
                <FontAwesomeIcon icon={faPaw} />0 pets
            </p>
        </>
    );
};
