import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button } from 'components/UI';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(theme => ({
    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 200px)',
        width: '100%',
        flexDirection: 'column'
    },
    notFoundWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 15
    },
    notFoundBadge: {
        width: 90,
        height: 90,
        backgroundColor: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        color: '#fff',
        fontSize: 40,
        transform: 'none',
        position: 'relative',
        fontWeight: 600
    },
    notFoundContent: {
        textAlign: 'center'
    },
    notFoundTitle: {
        fontSize: 24,
        fontWeight: 600,
        color: '#000',
        marginBottom: 3,
        [theme.breakpoints.up('md')]: {
            fontSize: 32
        }
    },
    notFoundSubtitle: {
        fontSize: 16,
        color: '#000'
    },
    buttonReload: {
        display: 'block',
        margin: '0 auto',
        marginTop: 20,
        height: 'auto'
    }
}));

const InactivityModal: React.FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.notFoundContainer}>
            <Box className={classes.notFoundWrapper}>
                <Box className={classes.notFoundBadge}>
                    <FontAwesomeIcon icon={faTimer} />
                </Box>
                <Box className={classes.notFoundContent}>
                    <Typography className={classes.notFoundTitle}>
                        Payment Link Expired
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.notFoundSubtitle}
                    >
                        Oops! It seems your payment link has expired by
                        inactivity.
                        <Button
                            label="Reload"
                            color="black"
                            onClick={() => window.location.reload()}
                            className={classes.buttonReload}
                        />
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default InactivityModal;
