import React, { useState, useEffect, FunctionComponent, useRef, ChangeEvent, Fragment } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import Pet from "@spike/pet-model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMasterData } from "hooks";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import ImageMenu from "./ImageMenu";
import { isEmpty } from "lodash";
import { CropperImage } from "components/UI";
import { wbp, reduceResolution } from "Theme";
import { PetTypeIds} from "@spike/model";

interface PetImageProps {
  pet: Pet | undefined;
  onChangePet: (pet: Pet) => void;
  className?: string;
  disableEdition?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      position: "relative",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(100)}px`,
        height: `${reduceResolution(100)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "100px",
        height: "100px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "75px",
        height: "75px",
      },
    },

    imageBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundImage: (props: PetImageProps) => "url(" + props.pet?.image + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
      textAlign: "center",
      position: "relative",
      width: "100%",
      height: "100%",
    },

    image: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundImage: (props: PetImageProps) => "url(" + props.pet?.image + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
      textAlign: "center",
      position: "relative",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(174)}px`,
        height: `${reduceResolution(174)}px`,
        marginRight: `${reduceResolution(44)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "174px",
        height: "174px",
        marginRight: "44px",
      }
    },
    edit: {
      cursor: "pointer",
      fontWeight: 600,
      textDecoration: "underline",
      color: "#fff",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
      },
    },
    emptyImage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "#F8F5F1",
      borderRadius: "50%",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(174)}px`,
        height: `${reduceResolution(174)}px`,
        marginRight: `${reduceResolution(44)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "174px",
        height: "174px",
        marginRight: "44px",
      },
    },

    imageText: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
        marginBottom: `${reduceResolution(9)}px`,
        paddingRight: `${reduceResolution(12)}px`,
        paddingLeft: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        marginBottom: "9px",
        paddingRight: "12px",
        paddingLeft: "12px",
      },
    },
    bgCat: {
      backgroundColor: "#FAEFDF",
    },
    bgDog: {
      backgroundColor: "#E9F0ED",
    },

    uploadIcon: {
      color: "#BAA997",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(34)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "34px",
      },
      [theme.breakpoints.down("md")]: {
        color: "#92B4A7",
      }
    },

    uploadDog: {
      color: "#5E8677",
    },
    uploadCat: {
      color: "#EAB464 !important",
    },

    iconFace: {
      marginRight: "5px",
      [theme.breakpoints.up(wbp)]: {
        marginTop: "5px",
      },
    },
    itemTextEmpty: {
      color: "#D3D3D3",
    },

    warningBox: {
      backgroundColor: "#E9F0ED",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      borderRadius: "50%",
      textAlign: "center",
    },

    imageBoxWithOpacity: {
      filter: "brightness(0.8)",
    },

    sizeText: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
      },
    },

    editOptions: {
      left: "50%",
    },

    uploadTitle: {
      fontWeight: 500,
      textDecoration: "underline",
      color: "#5E8677",
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(30)}px`,
        marginBottom: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "30px",
        marginBottom: "8px",
      },
    },

    uploadBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#E9F0ED",
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#BDD2CA",
        "& $uploadIcon": {
          color: "#FFF"
        }
      }
    },

    uploadCatBox: {
      backgroundColor: "#FAEFDF !important",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#F2D2A2 !important",
        "& $uploadIcon": {
          color: "#C18733"
        }
      }
    },
  })
);

export const PetImage: FunctionComponent<PetImageProps> = (props) => {
  const classes = useStyles(props);
  const masterData = useMasterData();

  const [hover, setHover] = useState(false);
  const [pet, setPet] = useState<Pet | undefined>(props.pet);
  const [showEmptyImage, setEmptyImage] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [selectedImage, setSelectedImage] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [showCropper, setShowCropper] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.pet) {
      setPet(props.pet);
      if (props.pet.image === undefined || props.pet.image === null || isEmpty(props.pet.image)) {
        setEmptyImage(true);
      } else {
        setEmptyImage(false);
      }
    }
  }, [props.pet]);

  const imageSelectionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    if (file.size / 1024 <= masterData.imagesMaxSizes.avatarKb) {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setSelectedImage(reader.result?.toString()!);
          setFileName(file.name);
          setShowCropper(true);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setShowWarning(true);
    }
  };

  const changeImageHandler = () => {
    inputFileRef.current?.click();
    setShowMenu(false);
  };

  const removeImageHandler = () => {
    if (pet) {
      let petAux: Pet = pet;
      petAux.image = "";
      petAux.imageFileToUpload = undefined;
      petAux.imageRemoved = true;
      props.onChangePet && props.onChangePet(petAux);
      setPet(petAux);
      setShowMenu(false);
      setEmptyImage(true);
    }
  };

  const cropHandler = async (file: File) => {
    const petAux: Pet = pet!;
    petAux.image = URL.createObjectURL(file);
    petAux.imageFileToUpload = file;
    petAux.imageRemoved = false;

    props.onChangePet && props.onChangePet(petAux);
    setPet(petAux);
    setShowWarning(false);
    setEmptyImage(false);
    setShowCropper(false);
  };

  const warningMessage = (
    <Box>
      <Typography className={classes.imageText}>Image is too large.</Typography>
      <Typography className={classes.sizeText}>{`Max size: ${masterData.imagesMaxSizes.avatarKb / 1024}MB`}</Typography>
    </Box>
  );

  const emptyImage = (
    <Box
      className={clsx(classes.uploadBox, {
        [classes.uploadCatBox]: pet && !isEmpty(pet.type) && pet.type?.id === PetTypeIds.CATS,
        [classes.warningBox]: showWarning,
      })}
      onClick={changeImageHandler}
    >
      {hover && (
        <Typography
          className={clsx(
            classes.uploadTitle,
            { [classes.uploadCat]: pet && !isEmpty(pet.type) && pet.type?.id === PetTypeIds.CATS },
            { [classes.uploadDog]: pet && !isEmpty(pet.type) && pet.type?.id === PetTypeIds.DOGS }
          )}
        >
          Upload
        </Typography>
      )}
      {!hover && !showWarning && (
        <FontAwesomeIcon
          icon={faCamera}
          className={clsx(
            classes.uploadIcon,
            { [classes.uploadCat]: pet && !isEmpty(pet.type) && pet.type?.id === PetTypeIds.CATS },
            { [classes.uploadDog]: pet && !isEmpty(pet.type) && pet.type?.id === PetTypeIds.DOGS }
          )}
        />
      )}
      {!hover && showWarning && warningMessage}
    </Box>
  );

  const image = (
    <Box
      className={clsx({
        [classes.warningBox]: showWarning,
        [classes.imageBox]: !showWarning,
        [classes.imageBoxWithOpacity]: !props.disableEdition && hover,
        [classes.uploadCatBox]: pet && !isEmpty(pet.type) && pet.type?.id === PetTypeIds.CATS,
      })}
    >
      {showWarning && warningMessage}
      {hover && !props.disableEdition && (
        <Typography className={classes.edit} onClick={() => setShowMenu(true)}>
          Edit
        </Typography>
      )}
    </Box>  
  );

  return (
    <Fragment>
      <Box className={props.className}>
        <input
          style={{ display: "none" }}
          type="file"
          ref={inputFileRef}
          onChange={(event) => imageSelectionHandler(event)}
          accept=".png, .jpg, .jpeg"
          value=""
        />
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
          <Box
            className={clsx(classes.imageContainer, 'imageWrapper')}
            onMouseLeave={() => setHover(false)}
            onMouseOver={() => setHover(true)}
          >
            {showEmptyImage && emptyImage}
            {!showEmptyImage && image}

            {showMenu && !props.disableEdition && (
              <ImageMenu
                onChange={changeImageHandler}
                onRemove={removeImageHandler}
                onClose={() => setShowMenu(false)}
                className={classes.editOptions}
              />
            )}
          </Box>
        </ClickAwayListener>
      </Box>
      {showCropper && (
        <CropperImage
          image={selectedImage!}
          fileName={fileName!}
          cropWidth={256}
          cropHeight={256}
          onClose={() => setShowCropper(false)}
          onCrop={cropHandler}
        />
      )}
    </Fragment>
  );
};

export default PetImage;
