import { Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentsBusinessProfile as BusinessProfileModel } from '@spike/payments-model';
import { FunctionComponent } from 'react';
import AddressView from '../AddressView';
import { useCommonStyles } from '../CommonStyles';

interface BusinessProfileViewProps {
	businessProfile: BusinessProfileModel;
	className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			display: 'block',

			[theme.breakpoints.up('md')]: {
				marginRight: 8,
				fontWeight: 500,
				display: 'inline-block'
			}
		}
	})
);

export const BusinessProfileView: FunctionComponent<
	BusinessProfileViewProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	return (
		<Grid container spacing={isMobile ? 3 : 2} className={props.className}>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Legal Business Name:</span>{' '}
				{props.businessProfile.legalName}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Trade Name:</span>
				{props.businessProfile.tradeName}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>EIN:</span>
				{props.businessProfile.einNumber}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Email:</span>
				{props.businessProfile.email}
			</Grid>
			<Grid item xs={12} className={commonClasses.formCell}>
				<span className={classes.label}>Website:</span>
				{props.businessProfile.website}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Phone Type:</span>
				{props.businessProfile.phoneType?.name}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Phone Number:</span>
				{props.businessProfile.phoneNumber || ''}
			</Grid>
			<Grid item container xs={12}>
				<AddressView address={props.businessProfile.address} />
			</Grid>
		</Grid>
	);
};

export default BusinessProfileView;
