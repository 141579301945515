import { AgreementsSignature as AgreementsSignatureComponent } from 'components/Agreements/AgreementsSignature';
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
    hash?: string;
}

type AgreementsSignatureProps = RouteComponentProps<MatchParams>;

export const AgreementsSignature: FunctionComponent<
    AgreementsSignatureProps
> = props => {
    return (
        <AgreementsSignatureComponent hash={props.match.params.hash || ''} />
    );
};

export default AgreementsSignature;
