import AutocompleteResult from "model/AutocompleteResult";
import { store } from "store";
import { AutocompleteResultDto } from "./AutocompleteDtos";
import { MasterData } from "@spike/masterdata-model";

export const convert = (dto: AutocompleteResultDto): AutocompleteResult => {
  const masterData: MasterData = store.getState().masterData.data;

  return {
    id: dto.id,
    type: dto.type,
    petType: masterData.petTypes.find(pt => pt.id === dto.pet_type),
    name: dto.name
  };
};
