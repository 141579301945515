import React, { Fragment, FunctionComponent, useEffect, useState } from "react"
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import ChooseModalHeader from "./Header"
import ChooseModalFooter from "./Footer"
import clsx from "clsx"
import ChooseModalResults from "./Results"

export type Option = { id: string | number, label: string | number, count: number | string };

interface Props {
    title: string,
    subtitle?: string,

    selectAllLabel: string,

    show: boolean,
    value: Option[],
    options: Option[],
    onClose: () => void,
    onSave: (selected: Option[]) => void,
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        "&.hidden": {
            display: "none"
        }
    },
    root: {
        top: 0,
        right: 0,
        bottom: 0,
        width: 590,
        zIndex: 110,
        display: "flex",
        flexFlow: "column",
        position: "fixed",
        backgroundColor: "#fff"
    },
    backdrop: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.4)"
    },
    results: {
        flex: 1,
        overflow: "auto",
        backgroundColor: "#fff"
    }
}));

const ChooseModal: FunctionComponent<Props> = ({ title, subtitle, selectAllLabel, show, value, options, onClose, onSave }) => {
    const classes = useStyles();

    const [selected, setSelected] = useState<Option[]>(value);

    const changeSelection = (selection: Option[]) => setSelected(selection);

    const saveSelection = () => onSave(selected);

    useEffect(() => setSelected(value), [value]);

    return (
        <Fragment>
            <Box className={clsx(classes.wrapper, { hidden: !show })}>
                <Box className={classes.backdrop} />

                <Box className={classes.root}>
                    <ChooseModalHeader
                        title={title}
                        subtitle={subtitle}
                        variant="custom"
                        onClose={onClose}
                        onSearch={(s: string) => { }} />

                    <Box className={classes.results}>
                        {show && <ChooseModalResults
                            label={selectAllLabel}
                            items={options}
                            value={selected}
                            onChange={changeSelection} />}
                    </Box>

                    <ChooseModalFooter onCancel={onClose} onSave={saveSelection} />
                </Box>
            </Box>
        </Fragment>
    )
}

export default ChooseModal