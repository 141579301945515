export interface UsersState {
  status: UsersStatus;
  loading: boolean;
}

export enum UsersStatus {
  Initial,
  ChangingPassowrd,
  ChangePasswordSuccess,
  Error
} 
