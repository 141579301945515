import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError, Option } from "@spike/model";
import clsx from "clsx";
import { useMarketplace, useMasterData } from "hooks";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import VaccinesItems from "./VaccinesItems";

export interface VaccineTypesProps {
  type?: Option<string>;
  errors?: Array<FieldError>;
  onChange: (type: Option<string>) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    chevron: {
      vaccines: {
        [theme.breakpoints.down(wbp)]: {
          fontSize: `${reduceResolution(16)}px`,
        },
        [theme.breakpoints.up(wbp)]: {
          fontSize: "16px",
        },
      },
    },
    vaccinesBox: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: "1px solid #000",
      height: "auto",
      "&:hover": {
        background: "#fff",
      },
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(100)}px`,
        maxWidth: `${reduceResolution(200)}px`,
        minHeight: `${reduceResolution(45)}px`,
        paddingLeft: `${reduceResolution(22)}px`,
        paddingRight: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "100px",
        maxWidth: "200px",
        minHeight: "45px",
        paddingLeft: "22px",
        paddingRight: "22px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        minHeight: "47px",
        borderColor: "#d3d3d3",
        marginBottom: "15px",
      }
    },
    vaccines: {
      width: "95%",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    vaccinesRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    vaccinesContainer: {
      display: "flex",
      background: "#ffffff",
      width: "auto",
      height: "auto",
      border: "1px solid #000",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(30)}px`,
        borderRadius: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "30px",
        borderRadius: "22px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        marginBottom: "15px",
        width: "100%",
      },
    },
    selected: {
      border: "2px solid #000",
    },
    errorBorder: {
      borderColor: "#EA6464",
    },
    errorText: {
      color: "#EA6464",
    },
  })
);

export const VaccineTypes: FunctionComponent<VaccineTypesProps> = (props) => {
  const classes = useStyles(props);

  const [showList, setShowList] = useState(false);

  const masterData = useMasterData();
  const marketplace = useMarketplace();
  /*const vaccineTypes = masterData.vaccines.filter((v) =>
    marketplace.requirements.requirements.some((r) => r.id === v.id)
  );*/
  const vaccineTypes = [...marketplace.requirements.requirements, ...marketplace.requirements.otherRequirements.map(other => ({id: other, name: other}))];

  const hasError =
    props.type === undefined && (props.errors?.some((error) => error.fieldName === "vaccine_type") || false);

  const toggleHandler = () => {
    setShowList((prev) => !prev);
  };

  const changeHandler = (type: Option<string>) => {
    setShowList(false);
    props.onChange(type);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box
        className={clsx(classes.vaccinesBox, { [classes.selected]: showList, [classes.errorBorder]: hasError })}
        onClick={toggleHandler}
      >
        <Box className={classes.vaccinesRow}>
          <Typography className={clsx(classes.vaccines, { [classes.errorText]: hasError })} noWrap={true}>
            {props.type ? props.type.name : "Add Vaccines"}
          </Typography>
          <FontAwesomeIcon
            icon={showList ? faChevronUp : faChevronDown}
            className={clsx(classes.chevron, { [classes.errorText]: hasError })}
          />
        </Box>
      </Box>
      {showList && (
        <ClickAwayListener onClickAway={() => setShowList(false)}>
          <Box className={classes.vaccinesContainer}>
            <VaccinesItems vaccines={vaccineTypes} onChange={changeHandler} />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default VaccineTypes;
