// src/actions/payByLink/PayByLinkActionTypes.ts
import { Action } from 'redux';
import { PaymentLink } from './PayByLinkDto';
import { InvoiceDto } from '@spike/invoices-action';
import { DropInDto } from '@spike/payments-action';

export const PAY_BY_LINK_FETCH_START = 'PAY_BY_LINK_FETCH_START';
export const PAY_BY_LINK_FETCH_SUCCESS = 'PAY_BY_LINK_FETCH_SUCCESS';
export const PAY_BY_LINK_FETCH_ERROR = 'PAY_BY_LINK_FETCH_ERROR';

export const PAY_BY_LINK_SAVE_TIP_START = 'PAY_BY_LINK_SAVE_TIP_START';
export const PAY_BY_LINK_SAVE_TIP_SUCCESS = 'PAY_BY_LINK_SAVE_TIP_SUCCESS';
export const PAY_BY_LINK_SAVE_TIP_ERROR = 'PAY_BY_LINK_SAVE_TIP_ERROR';
export const PAY_BY_LINK_RESET_SAVE_TIP_ERROR =
    'PAY_BY_LINK_RESET_SAVE_TIP_ERROR';

export const PAY_BY_LINK_DROPIN_SESSION_START =
    'PAY_BY_LINK_DROPIN_SESSION_START';
export const PAY_BY_LINK_DROPIN_SESSION_SUCCESS =
    'PAY_BY_LINK_DROPIN_SESSION_SUCCESS';
export const PAY_BY_LINK_DROPIN_SESSION_ERROR =
    'PAY_BY_LINK_DROPIN_SESSION_ERROR';

export const PAY_BY_LINK_LOCK_INVOICE_START = 'PAY_BY_LINK_LOCK_INVOICE_START';
export const PAY_BY_LINK_LOCK_INVOICE_SUCCESS =
    'PAY_BY_LINK_LOCK_INVOICE_SUCCESS';
export const PAY_BY_LINK_LOCK_INVOICE_ERROR = 'PAY_BY_LINK_LOCK_INVOICE_ERROR';

export const PAY_BY_LINK_UNLOCK_INVOICE_START =
    'PAY_BY_LINK_UNLOCK_INVOICE_START';
export const PAY_BY_LINK_UNLOCK_INVOICE_SUCCESS =
    'PAY_BY_LINK_UNLOCK_INVOICE_SUCCESS';
export const PAY_BY_LINK_UNLOCK_INVOICE_ERROR =
    'PAY_BY_LINK_UNLOCK_INVOICE_ERROR';

export const COMPLETE_TRANSACTION_START = 'COMPLETE_TRANSACTION_START';
export const COMPLETE_TRANSACTION_SUCCESS = 'COMPLETE_TRANSACTION_SUCCESS';
export const COMPLETE_TRANSACTION_ERROR = 'COMPLETE_TRANSACTION_ERROR';

export const PAY_BY_LINK_CONFIRM_PAYMENT_START =
    'PAY_BY_LINK_CONFIRM_PAYMENT_START';
export const PAY_BY_LINK_CONFIRM_PAYMENT_SUCCESS =
    'PAY_BY_LINK_CONFIRM_PAYMENT_SUCCESS';
export const PAY_BY_LINK_CONFIRM_PAYMENT_ERROR =
    'PAY_BY_LINK_CONFIRM_PAYMENT_ERROR';

// Actions
type PayByLinkFetchStartAction = Action<typeof PAY_BY_LINK_FETCH_START>;

interface PayByLinkFetchSuccessAction
    extends Action<typeof PAY_BY_LINK_FETCH_SUCCESS> {
    payload: {
        loading: boolean;
        paymentLink: PaymentLink;
    };
}

interface PayByLinkFetchErrorAction
    extends Action<typeof PAY_BY_LINK_FETCH_ERROR> {
    payload: {
        loading: boolean;
        fetchError: string;
    };
}

interface PayByLinkSaveTipStartAction
    extends Action<typeof PAY_BY_LINK_SAVE_TIP_START> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkSaveTipSuccessAction
    extends Action<typeof PAY_BY_LINK_SAVE_TIP_SUCCESS> {
    payload: {
        loading: boolean;
        invoice: InvoiceDto;
    };
}

interface PayByLinkSaveTipErrorAction
    extends Action<typeof PAY_BY_LINK_SAVE_TIP_ERROR> {
    payload: {
        loading: boolean;
        saveTipError: string;
    };
}

type PayByLinkResetSaveTipErrorAction = Action<
    typeof PAY_BY_LINK_RESET_SAVE_TIP_ERROR
>;

// Define the DropIn actions
type PayByLinkDropInSessionStartAction = Action<
    typeof PAY_BY_LINK_DROPIN_SESSION_START
>;

interface PayByLinkDropInSessionSuccessAction
    extends Action<typeof PAY_BY_LINK_DROPIN_SESSION_SUCCESS> {
    payload: {
        dropInSession: DropInDto;
    };
}

interface PayByLinkDropInSessionErrorAction
    extends Action<typeof PAY_BY_LINK_DROPIN_SESSION_ERROR> {
    payload: {
        error: string;
    };
}

interface PayByLinkLockInvoiceStartAction
    extends Action<typeof PAY_BY_LINK_LOCK_INVOICE_START> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkLockInvoiceSuccessAction
    extends Action<typeof PAY_BY_LINK_LOCK_INVOICE_SUCCESS> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkLockInvoiceErrorAction
    extends Action<typeof PAY_BY_LINK_LOCK_INVOICE_ERROR> {
    payload: {
        loading: boolean;
        lockInvoiceError: string;
    };
}

interface PayByLinkUnlockInvoiceStartAction
    extends Action<typeof PAY_BY_LINK_UNLOCK_INVOICE_START> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkUnlockInvoiceSuccessAction
    extends Action<typeof PAY_BY_LINK_UNLOCK_INVOICE_SUCCESS> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkUnlockInvoiceErrorAction
    extends Action<typeof PAY_BY_LINK_UNLOCK_INVOICE_ERROR> {
    payload: {
        loading: boolean;
        unlockInvoiceError: string;
    };
}

interface CompleteTransactionStartAction
    extends Action<typeof COMPLETE_TRANSACTION_START> {
    payload: {
        loading: boolean;
    };
}

interface CompleteTransactionSuccessAction
    extends Action<typeof COMPLETE_TRANSACTION_SUCCESS> {
    payload: {
        loading: boolean;
    };
}

interface CompleteTransactionErrorAction
    extends Action<typeof COMPLETE_TRANSACTION_ERROR> {
    payload: {
        loading: boolean;
        completeTransactionError: string;
    };
}

interface PayByLinkConfirmPaymentStartAction
    extends Action<typeof PAY_BY_LINK_CONFIRM_PAYMENT_START> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkConfirmPaymentSuccessAction
    extends Action<typeof PAY_BY_LINK_CONFIRM_PAYMENT_SUCCESS> {
    payload: {
        loading: boolean;
    };
}

interface PayByLinkConfirmPaymentErrorAction
    extends Action<typeof PAY_BY_LINK_CONFIRM_PAYMENT_ERROR> {
    payload: {
        loading: boolean;
        confirmPaymentError: string;
    };
}

export type PayByLinkAction =
    | PayByLinkFetchStartAction
    | PayByLinkFetchSuccessAction
    | PayByLinkFetchErrorAction
    | PayByLinkSaveTipStartAction
    | PayByLinkSaveTipSuccessAction
    | PayByLinkSaveTipErrorAction
    | PayByLinkResetSaveTipErrorAction
    | PayByLinkDropInSessionStartAction
    | PayByLinkDropInSessionSuccessAction
    | PayByLinkDropInSessionErrorAction
    | PayByLinkLockInvoiceStartAction
    | PayByLinkLockInvoiceSuccessAction
    | PayByLinkLockInvoiceErrorAction
    | PayByLinkUnlockInvoiceStartAction
    | PayByLinkUnlockInvoiceSuccessAction
    | PayByLinkUnlockInvoiceErrorAction
    | CompleteTransactionStartAction
    | CompleteTransactionSuccessAction
    | CompleteTransactionErrorAction
    | PayByLinkConfirmPaymentStartAction
    | PayByLinkConfirmPaymentSuccessAction
    | PayByLinkConfirmPaymentErrorAction;
