import { FunctionComponent } from 'react';
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import PulseLoader from 'react-spinners/PulseLoader';
import { wbp, reduceResolution } from 'Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loaderContainer: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '15px'
            }
        }
    })
);

const Loader: FunctionComponent = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            className={classes.loaderContainer}
            justifyContent="center"
        >
            <Grid item xs={5} />
            <Grid item xs={2}>
                <PulseLoader color="grey" loading={true} size={6} />
            </Grid>
            <Grid item xs={5} />
        </Grid>
    );
};

export default Loader;
