import SignUp from "model/SignUp";

export interface SignUpState {
  data?: SignUp;
  status: SignUpStatus;
  loading: boolean;
}

export enum SignUpStatus {
  Initial,
  SetData,
  EmailVerificating,
  EmailVerificationFailed,
  EmailVerificationSuccess,
  SigningUp,
  Success,
  Failed,
  Error,
}
