import React from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import { Button, LabelField, TextField2 } from "components/UI";

interface Props {
	open: boolean;
	onClose: () => void;
	onComplete: () => void;
}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& .MuiDialog-scrollPaper": {
				display: "block",
				padding: "45px 0px",
				overflowY: "scroll",
			},
			"& .MuiPaper-root": {
				width: "100%",
				maxWidth: 605,
				borderRadius: 24,
				maxHeight: "unset",
				margin: "0px auto",
			},
		},
		header: {
			position: "relative",
			padding: "30px 40px 10px",
		},
		title: {
			fontSize: 22,
			fontWeight: 600,
		},
		body: {
			overflowY: "unset",
			padding: "15px 40px 40px",
		},
		formGroup: {
			marginBottom: 24,
		},
		footer: {
			padding: "15px 40px 25px",
			borderTop: "solid 1px #DDDDDD",

			"& button": {
				height: 47,
				minWidth: 165,
			},
		},
	})
);

export const UpdateCard: React.FunctionComponent<Props> = (props) => {
	const classes = useStyles();

	return (
		<Dialog open={props.open} className={classes.root}>
			<DialogTitle className={classes.header}>
				<Typography className={classes.title}>
					Update Card Details
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.body}>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<LabelField required label="Card Holder Name" />
						<TextField2
							value=""
							placeholder="Card Holder Name"
							onChange={() => {}}
						/>
					</Grid>
					<Grid item xs={12}>
						<LabelField required label="Card Holder Name" />
						<TextField2
							value=""
							placeholder="Card Holder Name"
							onChange={() => {}}
						/>
					</Grid>
					<Grid item xs={6}>
						<LabelField required label="Card Holder Name" />
						<TextField2
							value=""
							placeholder="Card Holder Name"
							onChange={() => {}}
						/>
					</Grid>
					<Grid item xs={6}>
						<LabelField required label="Card Holder Name" />
						<TextField2
							value=""
							placeholder="Card Holder Name"
							onChange={() => {}}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions className={classes.footer}>
				<Button
					size="large"
					color="black"
					label="Discard"
					onClick={props.onClose}
				/>
				<Button
					size="large"
					color="green"
					label="Update"
					onClick={props.onComplete}
				/>
			</DialogActions>
		</Dialog>
	);
};
