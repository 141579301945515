import Appointment, { status } from "@spike/appointment-model";

export const isAddingServicesEnabled = (appointments: Array<Appointment>) => {
    return appointments.map((ap) => {
        switch (ap.status.id) {
            case status.WAITING_PAYMENT_CONFIRMATION:
            case status.COMPLETED:
            case status.DECLINED:
            case status.CANCELLED:
            case status.NO_SHOW:
                return false;
            default:
                return true;
        };
    }).some(result => result);

};

export const isStatusAppointmentOkToAction = (appointment: Appointment) => {
    switch (appointment.status.id) {
        case status.BOOKED:
            return false;
        default:
            return true;
    };
};

export const isStatusAppointmentOkToOptions = (statusId: number) => {
    switch (statusId) {
        case status.CANCELLED:
            return false;
        default:
            return true;
    };
};
