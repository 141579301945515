import React from 'react'
import clsx from 'clsx'
import 'quill/dist/quill.snow.css'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'

interface Props {
    content: string,
    className?: Object
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        '& *': {
            lineHeight: 1.6,
            fontFamily: 'Poppins, sans-serif'
        },

        '& h1': {
            fontSize: 28,
            fontWeight: 600,
            marginBottom: 10
        },
        '& h2': {
            fontSize: 24,
            fontWeight: 600,
            marginBottom: 8,
        },
        '& p, & ul, & ol': {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.65,
            paddingLeft: '0px',

            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        },
        '& li': {
            marginBottom: '1rem'
        },

        '& .ql-size-large': {
            fontSize: '1.25rem'
        },
        '& .ql-size-huge': {
            fontSize: '1.5rem'
        },
    
    }
}))

const QuillContent = ({ content, className }: Props) => {
    const classes = useStyles()

    return (
        <Box className={clsx(classes.root, 'ql-editor', className)}
            dangerouslySetInnerHTML={{ __html: content }} />
    )
}

export default QuillContent