import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';
import { MarketplaceAdditionalInfo } from '@spike/marketplace-model';
import { Checkbox } from 'components/UI';
import { MobileDivider } from 'components/UI/MobileDivider';
import useNonInitialEffect from '@versiondos/hooks';
import { width } from '@fortawesome/pro-light-svg-icons/faTimes';

interface Props {
    additionalInfo: MarketplaceAdditionalInfo;
    editable: boolean | undefined;
    houseCall: boolean;
    onChange?: (isMobile: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginTop: -2
        },
        checkContainer: {
            marginTop: 24
        }
    })
);

const BusinessLocation: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const { mobileBusiness, inHomeBusiness } = props.additionalInfo;

    const [isChecked, setIsChecked] = useState<boolean>(
        mobileBusiness || inHomeBusiness
    );

    useNonInitialEffect(() => {
        props.onChange && props.onChange(isChecked);
    }, [isChecked]);

    useEffect(() => {
        if (props.editable) {
            setIsChecked(props.houseCall);
        }
        props.onChange && props.onChange(isChecked);
    }, []);

    const handleChange = (checked: boolean) => {
        setIsChecked(checked);
    };

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Service Location</Typography>
            <Box className={classes.checkContainer}>
                <Checkbox
                    label={'Mobile/In-home'}
                    checked={isChecked}
                    onChange={handleChange}
                />
            </Box>

            <Box style={{ margin: '0px -16px' }}>
                <MobileDivider />
            </Box>
        </Box>
    );
};

export default BusinessLocation;
