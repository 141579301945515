import React from 'react';
import { AddSlotHeader } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/AddSlot/AddSlotHeader';
import { MultiSlotDrawerView } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawer';
import { AddSlotDatePicker } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/AddSlot/AddSlotDatePicker';
import { Period } from '@spike/model';
import moment, { Moment } from 'moment-timezone';
import Staff, { StaffSlotCustomDay } from 'model/Staff';
import { StaffCard } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/StaffCard';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlotField } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/SlotField';
import { useMarketplace, useTimeZone } from 'hooks';
import { DropdownSelectOption } from 'components/UI/V2/DropdownSelect/DropdownSelect';
import { AlertMessage2 } from 'components/AlertMessage2';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/UI/V2/Button/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveStaffThunk } from 'actions/staff/StaffActions';
import { RootState } from 'store';
import { StaffStatus } from 'reducers/staff/StaffState';
import { showSuccessThunk } from '@spike/notifications-action';
import clsx from 'clsx';
import { ConfirmDialog } from 'components/UI';
import { getStaffScheduleByDate } from 'components/StaffSchedule/utils/StaffScheduleUtils';

export interface AddSlotProps {
    period: Period;
    selectedStaff?: Staff;
    defaultDate?: Moment;
    onClose: () => void;
    onViewChange: (view: MultiSlotDrawerView) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        header: {
            top: 0,
            zIndex: 10,
            width: '100%',
            padding: '0px 16px',
            position: 'sticky',
            backgroundColor: '#ffffff',

            [theme.breakpoints.up('md')]: {
                padding: '0px 30px'
            }
        },
        body: {
            gap: 24,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 16px 40px',

            [theme.breakpoints.up('md')]: {
                padding: '24px 30px 40px'
            }
        },
        footer: {
            'gap': 14,
            'bottom': 0,
            'zIndex': 10,
            'position': 'sticky',
            'display': 'flex',
            'marginTop': 'auto',
            'padding': '16px 30px',
            'backgroundColor': '#ffffff',
            'borderTop': '1px solid #D4D4D4',

            '& button': {
                height: 47,
                width: '100%'
            }
        },
        slotsContainer: {
            width: '100%'
        },
        addSlotButton: {
            gap: 6,
            border: 0,
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            display: 'flex',
            alignItems: 'center'
        },
        alertMessage: {
            'margin': 0,

            '& p': {
                fontSize: 16,
                lineHeight: 1.4,
                paddingRight: 10
            },
            '& button': {
                marginTop: 16
            }
        },
        pastDateMessage: {
            '& .MuiGrid-container': {
                [theme.breakpoints.up('sm')]: {
                    alignItems: 'center'
                }
            }
        },
        confirmDialog: {
            '& .MuiDialog-paperWidthSm': {
                maxWidth: 455
            },
            '& .MuiDialogActions-root': {
                '& button': {
                    margin: 0,
                    height: 47,
                    width: '100%'
                }
            }
        },
        confirmDialogText: {
            'maxWidth': 320,
            'margin': '0 auto',

            '& b': {
                fontWeight: 600
            }
        }
    })
);

const generateHourOptions = (start: Moment, end: Moment) => {
    const hours: Array<DropdownSelectOption<string>> = [];

    while (start.isSameOrBefore(end)) {
        hours.push({
            label: start.format('LT'),
            value: start.format('HH:mm')
        });

        start.add(15, 'minutes');
    }

    return hours;
};

export const AddSlot: React.FC<AddSlotProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const today = moment().tz(timeZone);

    const staffState = useSelector((state: RootState) => state.staff);

    const [selectedDate, setSelectedDate] = React.useState<Moment>();
    const [originalSlots, setOriginalSlots] = React.useState<Array<StaffSlotCustomDay>>([]);
    const [modifiedSlots, setModifiedSlots] = React.useState<Array<StaffSlotCustomDay>>([]);
    const [showDeleteAllDialog, setShowDeleteAllDialog] = React.useState(false);

    const isBeforeToday = React.useMemo(() => {
        return selectedDate?.isBefore(today, 'day');
    }, [today, selectedDate]);

    const daySlots = React.useMemo(() => {
        return modifiedSlots.filter(slot => slot.date.isSame(selectedDate, 'day'));
    }, [modifiedSlots, selectedDate]);

    const selectedDateHours = React.useMemo(() => {
        return daySlots.map(slot => slot.time);
    }, [daySlots]);

    const daySchedule = React.useMemo(() => {
        if (!selectedDate || !props.selectedStaff) return null;

        return getStaffScheduleByDate(marketplace, props.selectedStaff?.schedule, selectedDate.clone(), timeZone);
    }, [selectedDate, props.selectedStaff]);

    const hourOptions = React.useMemo(() => {
        if (!selectedDate || !daySchedule) return [];

        const hours = generateHourOptions(daySchedule.from, daySchedule.to);

        return hours;
    }, [selectedDate, daySchedule]);

    const addSlotHandler = () => {
        if (!selectedDate) return;

        let index = 0;
        let selectedTime: string;

        while (index < hourOptions.length) {
            const value = hourOptions[index].value;
            if (!selectedDateHours.includes(value)) {
                selectedTime = value;
                break;
            }
            index++;
        }

        setModifiedSlots(prevSlots => [...prevSlots, { date: selectedDate, time: selectedTime, petsCount: 1 }]);
    };

    const changeSlotHandler = (slot: StaffSlotCustomDay, time?: string, petsCount?: number) => {
        if (!time || !petsCount) return;

        setModifiedSlots(prevSlots =>
            prevSlots.map(prevSlot => (prevSlot === slot ? { ...prevSlot, time, petsCount } : prevSlot))
        );
    };

    const deleteSlotHandler = (slot: StaffSlotCustomDay) => {
        setModifiedSlots(prevSlots => prevSlots.filter(prevSlot => prevSlot !== slot));
    };

    const saveSlotsHandler = () => {
        if (!props.selectedStaff) return;

        dispatch(
            saveStaffThunk({
                ...props.selectedStaff,
                slots: {
                    ...props.selectedStaff.slots,
                    customDays: modifiedSlots
                }
            })
        );
    };

    const deleteDaySlotsHandler = () => {
        if (!props.selectedStaff) return;

        setShowDeleteAllDialog(true);
    };

    const confirmDeleteDaySlotsHandler = () => {
        if (!props.selectedStaff) return;

        dispatch(
            saveStaffThunk({
                ...props.selectedStaff,
                slots: {
                    ...props.selectedStaff.slots,
                    customDays: originalSlots.filter(slot => !slot.date.isSame(selectedDate, 'day'))
                }
            })
        );
    };

    /*
     * Check if the modified slots are different from the original slots
     */
    const isModified = React.useMemo(() => {
        return JSON.stringify(originalSlots) !== JSON.stringify(modifiedSlots);
    }, [originalSlots, modifiedSlots]);

    /*
     * Assign slots from staff, and assign the slots to be modified
     */
    React.useEffect(() => {
        setOriginalSlots(props.selectedStaff?.slots.customDays || []);
        setModifiedSlots(props.selectedStaff?.slots.customDays || []);
    }, [props.selectedStaff]);

    /*
     * Set the selected date, if no default date is provided, use the period's start date
     */
    React.useEffect(() => {
        const defaultSelectedDate = props.defaultDate || props.period.from;

        setSelectedDate(defaultSelectedDate.clone());
    }, []);

    React.useEffect(() => {
        if (staffState.status === StaffStatus.SaveSuccess) {
            if (showDeleteAllDialog) {
                setShowDeleteAllDialog(false);
                dispatch(showSuccessThunk('Slots deleted successfully'));
                return;
            }

            dispatch(showSuccessThunk('Slots updated successfully'));
        }
    }, [staffState.status]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <AddSlotHeader
                    onClose={props.onClose}
                    onSetupDefaultSlots={() => props.onViewChange(MultiSlotDrawerView.SetupDefaults)}
                />
                <AddSlotDatePicker period={props.period} selectedDate={selectedDate} onDateChange={setSelectedDate} />
            </div>

            <div className={classes.body}>
                {props.selectedStaff && <StaffCard staff={props.selectedStaff} selectedDate={selectedDate} />}

                {isBeforeToday ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={clsx(classes.alertMessage, classes.pastDateMessage)}
                    >
                        <Typography>You can&apos;t make changes to slots from past dates.</Typography>
                    </AlertMessage2>
                ) : daySchedule?.isBusinessClosed ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={classes.alertMessage}
                    >
                        <Typography>
                            Your business hours are set to closed for this day. Update your business hours to add time
                            slots.
                        </Typography>

                        <Link to="/business_settings/info/hours" id="add-slot-alert-setup-hours-button">
                            <Button variant="black" label="Setup Business Hours" />
                        </Link>
                    </AlertMessage2>
                ) : !daySchedule?.isStaffAvailable ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={classes.alertMessage}
                    >
                        <Typography>
                            This staff member is not scheduled to work on this day. Update their schedule to add time
                            slots.
                        </Typography>

                        <Link to={`/staff#staffId=${props.selectedStaff?.id}`} id="add-slot-alert-edit-schedule-button">
                            <Button variant="black" label="Edit Schedule" />
                        </Link>
                    </AlertMessage2>
                ) : (
                    <>
                        {daySlots.length > 0 && (
                            <div className={classes.slotsContainer}>
                                <SlotField showLabel />

                                {daySlots.map((slot, index) => (
                                    <SlotField
                                        key={index}
                                        hours={hourOptions}
                                        time={slot.time}
                                        petsCount={slot.petsCount}
                                        readOnly={!isBeforeToday}
                                        selectedHours={selectedDateHours}
                                        onDelete={() => deleteSlotHandler(slot)}
                                        onChange={(time, petsCount) => changeSlotHandler(slot, time, petsCount)}
                                    />
                                ))}
                            </div>
                        )}

                        {!isBeforeToday && (
                            <button type="button" className={classes.addSlotButton} onClick={addSlotHandler}>
                                <FontAwesomeIcon icon={faPlus} />
                                Add Slot
                            </button>
                        )}
                    </>
                )}
            </div>

            {!isBeforeToday && !daySchedule?.isBusinessClosed && daySchedule?.isStaffAvailable && (
                <div className={classes.footer}>
                    <Button
                        variant="danger"
                        label="Delete All"
                        id="add-slot-delete-all-button"
                        disabled={daySlots.length === 0}
                        onClick={deleteDaySlotsHandler}
                    />
                    <Button
                        label="Save"
                        variant="green"
                        id="add-slot-save-button"
                        disabled={!isModified}
                        isLoading={staffState.loading}
                        onClick={saveSlotsHandler}
                    />
                </div>
            )}

            {showDeleteAllDialog && (
                <ConfirmDialog
                    open={true}
                    processing={staffState.loading}
                    className={classes.confirmDialog}
                    title={<Typography>Delete Multi Slots</Typography>}
                    question={
                        <Typography className={classes.confirmDialogText}>
                            Are you sure you want to delete all multi-slots for
                            <b>{selectedDate?.format('ddd, DD')}</b>?
                        </Typography>
                    }
                    cancelButtonLabel="No"
                    confirmButtonLabel="Yes"
                    onCancel={() => setShowDeleteAllDialog(false)}
                    onConfirm={confirmDeleteDaySlotsHandler}
                />
            )}
        </div>
    );
};
