import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import Header from "../components/Header";
import LeftColumn from "../components/Login/LeftColumn";
import RightColumn from "../components/ResetSent/RightColumn";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export const ResetSent: FunctionComponent = () => {
  const classes = useStyles();

  useEffect(() => {
    pageView("/forgotpassword_sent");
  }, []);

  document.title = "Link sent to reset password - Cuddles";

  return (
    <div id="login">
      <Header containerStyles={classes.headerContainer} />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <LeftColumn />
          <RightColumn />
        </Grid>
      </div>
    </div>
  );
};

export default ResetSent;
