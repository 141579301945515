import { NotificationsAction, showError } from '@spike/notifications-action';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { UserNotificationsState } from 'reducers/userNotifications/UserNotificationsState';
import { ThunkAction } from 'redux-thunk';
import store from 'store';

import { AxiosResponse } from 'axios';
import {
    USER_NOTIFICATIONS_ADD_START_ACTION_TYPE,
    USER_NOTIFICATIONS_ADD_SUCCESS_ACTION_TYPE,
    USER_NOTIFICATIONS_ERROR_ACTION_TYPE,
    USER_NOTIFICATIONS_FETCH_START_ACTION_TYPE,
    USER_NOTIFICATIONS_FETCH_SUCCESS_ACTION_TYPE,
    USER_NOTIFICATIONS_UPDATE_START_ACTION_TYPE,
    USER_NOTIFICATIONS_UPDATE_SUCCESS_ACTION_TYPE,
    UserNotificationsAction
} from './userNotificationActionTypes';
import { convertToUserNotification } from './userNotificationsConverter';
import { UserReceiptDto } from './userNotificationsDtos';
import { debugConsoleLog } from 'utils/GeneralUtils';

export const addThunk = (
    receipt: UserReceiptDto
): ThunkAction<
    void,
    UserNotificationsState,
    null,
    UserNotificationsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(addStart());
        const timeZone = store.getState().login.auth.timeZone;

        try {
            dispatch(addSuccess(receipt, timeZone!));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                debugConsoleLog(apiError);
                dispatch(error());
                dispatch(showError('Error adding User Notification.'));
            }
        }
    };
};

export const fetchThunk = (): ThunkAction<
    void,
    UserNotificationsState,
    null,
    UserNotificationsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `/notification/receipts?marketplace_id=${marketplaceId}`;
        const timeZone = store.getState().login.auth.timeZone;

        try {
            const response: AxiosResponse<Array<UserReceiptDto>> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(fetchSuccess(response.data, timeZone!));
        } catch (apiError) {
            console.log(apiError);
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching User Notifications.'));
            }
        }
    };
};

export const updateThunk = (
    receiptId: number,
    isRead: boolean,
    isOpened: boolean
): ThunkAction<
    void,
    UserNotificationsState,
    null,
    UserNotificationsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(updateStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `/notification/receipt/${receiptId}?marketplace_id=${marketplaceId}`;

        const requestBody = {
            notification_receipt: {
                id: receiptId,
                channel: 'web',
                received_at: isOpened ? new Date() : undefined,
                read_at: isRead ? new Date() : undefined
            }
        };

        try {
            await ApiClient.patch(
                url,
                requestBody,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(updateSuccess());
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error updating User Notification.'));
            }
        }
    };
};

const addStart = (): UserNotificationsAction => {
    return {
        type: USER_NOTIFICATIONS_ADD_START_ACTION_TYPE
    };
};

export const addSuccess = (
    receiptDto: UserReceiptDto,
    timeZone: string
): UserNotificationsAction => {
    const notification = convertToUserNotification(receiptDto, timeZone);
    return {
        type: USER_NOTIFICATIONS_ADD_SUCCESS_ACTION_TYPE,
        payload: {
            notification
        }
    };
};

const error = (): UserNotificationsAction => {
    return {
        type: USER_NOTIFICATIONS_ERROR_ACTION_TYPE
    };
};

const fetchStart = (): UserNotificationsAction => {
    return {
        type: USER_NOTIFICATIONS_FETCH_START_ACTION_TYPE
    };
};

export const fetchSuccess = (
    receiptDtos: Array<UserReceiptDto>,
    timeZone: string
): UserNotificationsAction => {
    const notifications = receiptDtos.map(receipt =>
        convertToUserNotification(receipt, timeZone)
    );

    const unread = notifications.filter(notification => !notification.readAt);
    const read = notifications.filter(notification => notification.readAt);

    return {
        type: USER_NOTIFICATIONS_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            unread,
            read
        }
    };
};

const updateStart = (): UserNotificationsAction => {
    return {
        type: USER_NOTIFICATIONS_UPDATE_START_ACTION_TYPE
    };
};

export const updateSuccess = (): UserNotificationsAction => {
    return {
        type: USER_NOTIFICATIONS_UPDATE_SUCCESS_ACTION_TYPE
    };
};
