import { Box } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Spinner } from 'components/UI';

interface InvoicePdfProps {
	url: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		document: {
			'width': '100%',

			'& canvas': {
				width: '100% !important',
				height: 'auto !important'
			}
		},
		spinner: {
			marginTop: 40,

			[theme.breakpoints.up('md')]: {
				marginTop: 50
			}
		}
	})
);

export const InvoicePdf: FunctionComponent<InvoicePdfProps> = props => {
	const classes = useStyles();

	return (
		<Box className={props.className}>
			<Document
				file={{ url: props.url }}
				onLoadSuccess={() => null}
				loading={<Spinner className={classes.spinner} />}
				className={classes.document}
			>
				<Page pageNumber={1} scale={1.7} />
			</Document>
		</Box>
	);
};

export default React.memo(InvoicePdf);
