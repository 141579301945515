import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { CommissionPlan } from '@spike/commission-model';
import { ActionText } from 'components/UI/ActionableTexts/ActionText';
import State from 'components/UI/Chips/State';
import { FunctionComponent } from 'react';

interface CommissionItemProps {
	className?: string;
	commissionPlan: CommissionPlan;
	active: boolean;
	isLast?: boolean;
	onView?: (uuid: string, active: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		commissionListItemContainer: {
			width: '100% !important',
			display: 'flex',
			alignItems: 'center',
			paddingTop: (props: CommissionItemProps) =>
				props.active === true
					? '0px'
					: '20px',
			paddingBottom: '20px',
			marginTop: '5px',
			borderBottom: (props: CommissionItemProps) =>
				props.isLast === true
					? '0px #D4D4D4 solid'
					: '1px #D4D4D4 solid'
		},
		comissionListItemChipContainer: {
			width: '25%',
			[theme.breakpoints.down('sm')]: {
				width: '20%'
			},

		},
		commisionListItemActionTextContainer: {
			width: '25%',
			[theme.breakpoints.down('sm')]: {
				width: '20%'
			},
		},
		commisionListItemDescContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '60%'
			},
			width: '50%'
		},
		commissionListItemDescText: {
			fontWeight: 500,
			[theme.breakpoints.down('sm')]: {
				fontSize: 13,
			}
		},
		commissionListItemChip: {
			margin: '0 auto'
		}
	})
);

export const CommissionItem: FunctionComponent<CommissionItemProps> = props => {
	const classes = useStyles(props);

	return (
		<Box className={classes.commissionListItemContainer}>
			<Box className={classes.commisionListItemDescContainer}>
				<Typography className={classes.commissionListItemDescText}>
					From {props.commissionPlan.from.format('MMM DD, YYYY')}{' '}
					{props.active &&
						'at ' + props.commissionPlan.from.format('hh:mm a')}</Typography>
			</Box>
			<Box className={classes.comissionListItemChipContainer}>
				{' '}
				<State
					className={classes.commissionListItemChip}
					state={
						props.active
							? { id: 'SUCCESS', name: 'Active' }
							: { id: 'DISABLED', name: 'Past' }
					}
				></State>{' '}
			</Box>
			<Box className={classes.commisionListItemActionTextContainer}>
				{' '}
				<ActionText
					icon={faEye}
					label={'View'}
					onClick={() =>
						props.onView &&
						props.onView(props.commissionPlan.uuid, props.active)
					}
				></ActionText>
			</Box>
		</Box>
	);
};
