import React, { Fragment, useState } from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
    icon: IconProp,
    tooltip: string,
    onClick?: () => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center'
    },
    button: {
        width: 38,
        height: 38,
        border: 0,
        color: '#222',
        fontSize: 18,
        cursor: 'pointer',
        lineHeith: '38px',
        appearance: 'none',
        textAlign: 'center',
        borderRadius: '50%',
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: ' #F1F1F1'
        }
    },
    tooltip: {
        zIndex: 10,
        margin: 'auto',
        color: '#fff',
        bottom: '110%',
        fontSize: 14,
        lineHeight: 1.2,
        borderRadius: 4,
        padding: '4px 7px',
        letterSpacing: '-0.02em',
        position: 'absolute',
        pointerEvents: 'none',
        backgroundColor: '#222',
    }
}))

const TooltipButton = ({ icon, tooltip, onClick }: Props) => {
    const classes = useStyles()

    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    return (
        <Box className={classes.root}>
            {showTooltip && <Box className={classes.tooltip}>{tooltip}</Box>}

            <button
                type="button"
                className={classes.button}
                onClick={() => onClick && onClick()}
                onMouseLeave={() => setShowTooltip(false)}
                onMouseEnter={() => setShowTooltip(true)}>
                <FontAwesomeIcon icon={icon} />
            </button>
        </Box>
    )
}

export default TooltipButton