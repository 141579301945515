import { Signature } from 'model/Agreements';
import { FunctionComponent } from 'react';
import SignaturesTableRow from './SignaturesTableRow';
import { ScrolleableTable } from 'components/UI/ScrolleableTable';

interface SignaturesTableProps {
	signatures: Array<Signature>;
	hideStatus?: boolean;
	hideSignedDate?: boolean;
	loading?: boolean;
}

const SignaturesTable: FunctionComponent<SignaturesTableProps> = props => {
	return (
		<ScrolleableTable
			loading={props.loading}
			columnLabels={[
				'Client',
				'Date Sent',
				(!props.hideSignedDate ? 'Date Signed' : undefined)!,
				(!props.hideStatus ? 'Status' : undefined)!,
				'Channel',
				'Action'
			].filter(item => item !== undefined)}
			renderRows={props.signatures.map(signature => (
				<SignaturesTableRow
					key={signature.uuid}
					signature={signature}
					hideStatus={props.hideStatus}
					hideSignedDate={props.hideSignedDate}
				/>
			))}
		/>
	);
};

export default SignaturesTable;
