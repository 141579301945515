import * as amplitude from '@amplitude/analytics-browser';
import {
    faArrowLeft,
    faTimes,
    faCopy
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { Invoice, Tip as InvoiceTip } from '@spike/invoice-model';
import { InvoicesStatus, saveTipThunk } from '@spike/invoices-action';
import { Option } from '@spike/model';
import {
    PaymentsStatus,
    confirmAndSendThunk,
    getEnrollmentStatusThunk,
    sendThunk
} from '@spike/payments-action';
import { PaymentLink, PaymentMethodIds } from '@spike/payments-model';
import { cancelThunk as cancelPosThunk } from '@spike/pos-action';
import { isEmailValid } from '@spike/validations';
import { useNonInitialEffect } from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { Button, ControlledTextField2, Spinner } from 'components/UI';
import { AMPLITUDE } from 'constants/index';
import { useApiClientWrapper, useHasPremiumPlan } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import CreditCardPayment from './CreditCardPayment';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import PosPayment from './PosPayment';
import SendingInformation from './SendingInformation';
import Tips from './Tips';
import { isSameTip } from './Tips/TipUtils';
import Total from './Total';
import { AlertBox } from 'components/UI/Alerts';
import { createPaymentLinkThunk } from '@spike/payments-action';
import { PayByLinkPath } from 'routes/Paths';
import { replace } from 'lodash';
import { showSuccess } from '@spike/notifications-action';
import { getBookingThunk } from '@spike/bookings-action';
import Booking from '@spike/booking-model';

interface PaymentsProps {
    title?: string;
    bookingId?: number;
    invoiceId: number;
    totalBalance: string;
    subtotalWithoutTipTaxes: string;
    tip: InvoiceTip | null;
    customerEmail: string | null;
    customerPhone?: string | null;
    customerId?: number;
    className?: string;
    hiddenReport?: boolean;
    mobileTitle?: string;
    source?: string;
    dispatchUnlock?: () => void;
    onComplete?: (total: string, date: Moment) => void;
    onClose?: () => void;
    onScrollToTop?: () => void;
    backHandler?: () => void;
    onShowClient?: (clientId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',

            [theme.breakpoints.down('sm')]: {
                padding: '24px 16px'
            },
            [theme.breakpoints.up('md')]: {
                margin: '30px'
            }
        },
        title: {
            color: '#000',
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        totalBalanceContainer: {
            width: '100%',
            borderBottom: '2px solid #F1F1F1',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '30px'
            }
        },
        reportAndReceipt: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(33)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '33px'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '50px'
            }
        },
        button: {
            width: '100%',
            borderRadius: '100px',
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(72)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '72px'
            }
        },
        messageContainer: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(40)}px ${reduceResolution(
                    50
                )}px 0px ${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px 50px 0px 50px'
            }
        },
        success: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#00AA00',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginTop: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                marginTop: '10px'
            }
        },
        error: {
            fontFamily: 'Poppins',
            color: '#C14A4A',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginBottom: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                marginBottom: '10px'
            }
        },
        paymentFormContainer: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '50px'
            }
        },
        backCloseContainer: {
            'display': 'flex',
            'alignItems': 'center',

            [theme.breakpoints.down('sm')]: {
                gap: 10,
                marginBottom: 32
            },

            '& svg:last-child': {
                marginLeft: 'auto'
            }
        },
        closeIcon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px'
            }
        },
        spinner: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '20px'
            }
        },
        sectionMargin: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(33)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '33px'
            }
        },
        payByLinkContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 140px)',
            position: 'relative'
        },
        badgeIcon: {
            backgroundColor: '#000',
            borderRadius: '50%',
            width: '90px',
            height: '90px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '15px',
            marginTop: '-120px'
        },
        payByLinkIcon: {
            fontSize: '36px',
            color: '#fff'
        },
        payByLinkTitle: {
            fontSize: '32px',
            fontWeight: 600,
            marginBottom: '20px'
        },
        payByLinkDescription: {
            fontSize: '18px',
            lineHeight: 1.2,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '420px',
            textAlign: 'center',
            marginBottom: '30px'
        },
        payByLinkInputContainer: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            width: '100%'
        },
        payByLinkInput: {
            'width': '100%',
            '& input': {
                paddingRight: '103px',
                paddingTop: '17px',
                paddingBottom: '17px'
            },
            '& fieldset': {
                borderColor: '#D3D3D3 !important'
            }
        },
        payByLinkCopyButton: {
            'position': 'absolute',
            'right': 10,
            'backgroundColor': '#222',
            'color': '#fff',
            'minHeight': '40px',
            'minWidth': '80px',
            '& span': {
                fontSize: '14px'
            },
            '&:hover': {
                backgroundColor: '#333'
            },
            '& .MuiButton-startIcon': {
                marginRight: 5,
                width: '15px',
                height: '15px'
            },
            '& svg': {
                marginRight: 5,
                width: '15px',
                height: '15px'
            }
        },
        buttonDoneContainer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0
        },
        payByLinkDoneButton: {
            width: '100%',
            backgroundColor: '#222',
            color: '#fff',
            height: '55px',
            [theme.breakpoints.up('md')]: {
                height: '55px'
            }
        }
    })
);

interface CheckoutData {
    paymentMethodId: string | null;
    tip: InvoiceTip | null;
    email: string | null;
    sendReport: boolean;
    sendInvoice: boolean;
    posTerminalId: number | null;
    posServiceId: string | null;
    isZeroTotalBalance: boolean;
}

export const Payments: FunctionComponent<PaymentsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );
    const isPaymentsEnabled = useSelector<RootState, boolean | undefined>(
        state => state.payments.enrollmentStatus?.enabled
    );

    const invoicesStatus = useSelector<RootState, InvoicesStatus>(
        state => state.invoices.status
    );
    const savedInvoice = useSelector<RootState, Invoice | undefined>(
        state => state.invoices.savedInvoice
    );

    const createdPaymentLink = useSelector<RootState, PaymentLink | null>(
        state => state.payments.paymentLink
    );

    const booking = useSelector<RootState, Booking | undefined>(
        state => state.bookings.booking
    );

    const [checkoutData, setCheckoutData] = useState<CheckoutData>({
        paymentMethodId: null,
        tip: props.tip,
        email: null,
        sendReport: true,
        sendInvoice: true,
        posTerminalId: null,
        posServiceId: null,
        isZeroTotalBalance: Number(props.totalBalance) === 0
    });

    const isFreeServicesInvoice = Number(props.subtotalWithoutTipTaxes) === 0;

    const [completing, setCompleting] = useState(false);

    const [totalBalance, setTotalBalance] = useState(props.totalBalance);
    const [prevTip, setPrevTip] = useState<InvoiceTip | null>(
        props.tip ? { ...props.tip } : null
    );
    const [saving, setSaving] = useState(false);

    const [errors, setErrors] = useState<Array<string>>([]);
    const [show, setShow] = useState({
        paymentMethods: true,
        tips: true,
        totalBalance: true,
        creditCard: false,
        pos: false,
        linkGenerated: false,
        sending: Number(props.totalBalance) === 0
    });

    const [payLink, setPayLink] = useState<string | null>(null);
    const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(true);
    const [paymentLinkError, setPaymentLinkError] = useState<string | null>(
        null
    );

    useNonInitialEffect(() => {
        if (
            paymentsStatus === PaymentsStatus.CreatePaymentLinkSuccess &&
            createdPaymentLink
        ) {
            const payLinkUrlPath = replace(
                PayByLinkPath,
                ':uuid',
                createdPaymentLink.uuid
            );
            const fullPayLinkUrl = `${process.env.REACT_APP_BASE_URL}${payLinkUrlPath}`;
            setPayLink(fullPayLinkUrl);
            setPaymentLinkLoading(false);
            setShowCloseButton(false);
        }
    }, [paymentsStatus, createdPaymentLink]);

    const hasPremiumPlan = useHasPremiumPlan();

    useEffect(() => {
        if (payLink) {
            setPaymentLinkLoading(false);
        }
    }, [payLink]);

    useEffect(() => {
        if (props.bookingId) {
            dispatch(getBookingThunk(apiClientWrapper, props.bookingId!));
        }
        dispatch(getEnrollmentStatusThunk(apiClientWrapper));
        amplitude.track(AMPLITUDE.LOAD_PAYMENT_METHOD_CHECK_OUT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (checkoutData.email === null && props.customerEmail !== null) {
            setCheckoutData(prev => ({ ...prev, email: props.customerEmail }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customerEmail]);

    useNonInitialEffect(() => {
        switch (invoicesStatus) {
            case InvoicesStatus.SaveTipSuccess:
                const isZeroTotalBalance = Number(savedInvoice!.totalDue) === 0;
                setTotalBalance(savedInvoice!.totalDue);
                setCheckoutData(prev => ({
                    ...prev,
                    isZeroTotalBalance,
                    paymentMethodId: isZeroTotalBalance
                        ? null
                        : prev.paymentMethodId
                }));
                setShow(prev => ({
                    ...prev,
                    sending:
                        isZeroTotalBalance ||
                        checkoutData.paymentMethodId !== null
                }));
                setSaving(false);
                break;
            case InvoicesStatus.Error:
                setSaving(false);
                setCheckoutData(prev => ({ ...prev, tip: null }));
        }
    }, [invoicesStatus, savedInvoice]);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.SendSuccess:
                setCompleting(false);
                props.onComplete && props.onComplete(totalBalance, moment());
                break;
            case PaymentsStatus.Error:
                setCompleting(false);
        }
    }, [paymentsStatus]);

    const dispatchSaveTip = (
        amount: string | null,
        percentage: string | null
    ) => {
        dispatch(
            saveTipThunk(apiClientWrapper, props.invoiceId, amount, percentage)
        );
    };

    const changeTipHandler = (tip: InvoiceTip | null) => {
        if (!isSameTip(checkoutData.tip, tip)) {
            setSaving(true);
            setCheckoutData(prev => ({ ...prev, tip }));
            setPrevTip(tip);
            dispatchSaveTip(
                tip === null ? null : tip.amount,
                tip === null ? null : tip.percentage
            );
        }
    };

    const changeSendingDataHandler = (
        sendReport: boolean,
        sendInvoice: boolean,
        email: string
    ) => {
        setCheckoutData(prev => ({ ...prev, sendReport, sendInvoice, email }));
    };

    const changePaymentMethodHandler = (paymentMethod: Option<string>) => {
        if (paymentMethod.id === PaymentMethodIds.POS) {
            setSaving(true);
            dispatchSaveTip(null, null);
        } else if (checkoutData.paymentMethodId === PaymentMethodIds.POS) {
            setSaving(true);
            dispatchSaveTip(
                prevTip?.amount || null,
                prevTip?.percentage || null
            );
        }

        setCheckoutData(prev => ({
            ...prev,
            paymentMethodId: paymentMethod.id,
            tip:
                paymentMethod.id === PaymentMethodIds.POS
                    ? null
                    : prev.paymentMethodId === PaymentMethodIds.POS
                    ? prevTip
                    : prev.tip
        }));

        setShow({
            paymentMethods: true,
            tips: paymentMethod.id !== PaymentMethodIds.POS,
            totalBalance: true,
            creditCard: false,
            pos: false,
            linkGenerated: false,
            sending: true
        });

        switch (paymentMethod.id) {
            case 'cash':
                amplitude.track(AMPLITUDE.CTA_CASH_CHECK_OUT);
                break;
            case 'pos':
                amplitude.track(AMPLITUDE.CTA_POS_CHECK_OUT);
                break;
            case 'credit_card':
                amplitude.track(AMPLITUDE.CTA_CREDIT_CARD_CHECK_OUT);
                break;
            case 'other':
                amplitude.track(AMPLITUDE.CTA_OTHER_CHECK_OUT);
                break;
            default:
                break;
        }
    };

    const completeHandler = () => {
        const errors = validate(checkoutData);
        setErrors(errors);

        if (
            ![
                PaymentMethodIds.CreditCard.toString(),
                PaymentMethodIds.POS.toString(),
                PaymentMethodIds.PayByLink.toString()
            ].includes(checkoutData.paymentMethodId!) &&
            errors.length === 0
        ) {
            setCompleting(true);
            dispatch(
                confirmAndSendThunk(
                    apiClientWrapper,
                    props.invoiceId,
                    checkoutData.paymentMethodId || PaymentMethodIds.Other,
                    checkoutData.sendInvoice,
                    checkoutData.sendReport,
                    checkoutData.email!
                )
            );
        } else if (
            PaymentMethodIds.CreditCard === checkoutData.paymentMethodId! &&
            errors.length === 0
        ) {
            setShow({
                paymentMethods: false,
                tips: false,
                totalBalance: true,
                creditCard: true,
                pos: false,
                linkGenerated: false,
                sending: false
            });
        } else if (
            PaymentMethodIds.POS === checkoutData.paymentMethodId! &&
            errors.length === 0
        ) {
            setShow({
                paymentMethods: false,
                tips: false,
                totalBalance: true,
                creditCard: false,
                pos: true,
                linkGenerated: false,
                sending: false
            });
        } else if (
            PaymentMethodIds.PayByLink === checkoutData.paymentMethodId! &&
            errors.length === 0
        ) {
            setPaymentLinkLoading(true);

            setShow({
                paymentMethods: false,
                tips: false,
                totalBalance: false,
                creditCard: false,
                pos: false,
                linkGenerated: true,
                sending: false
            });

            //console.log("Send report: "+checkoutData.sendReport);

            createPaymentLink();
        }
    };

    const createPaymentLink = async () => {
        await dispatch(
            createPaymentLinkThunk(apiClientWrapper, props.invoiceId)
        );
        if (props.dispatchUnlock) {
            await props.dispatchUnlock();
        }
    };

    const successCreditCardPaymentHandler = (creditCardsessionId: string) => {
        setCompleting(true);
        dispatch(
            confirmAndSendThunk(
                apiClientWrapper,
                props.invoiceId,
                checkoutData.paymentMethodId!,
                checkoutData.sendInvoice,
                checkoutData.sendReport,
                checkoutData.email!,
                creditCardsessionId
            )
        );
    };

    const backCreditCardPaymentHandler = () => {
        setShow({
            paymentMethods: true,
            tips: true,
            totalBalance: true,
            creditCard: false,
            pos: false,
            linkGenerated: false,
            sending: true
        });
    };

    const closeHandler = () => {
        if (show.pos) {
            checkoutData.posServiceId &&
                checkoutData.posTerminalId &&
                dispatch(
                    cancelPosThunk(
                        apiClientWrapper,
                        props.invoiceId,
                        checkoutData.posTerminalId,
                        checkoutData.posServiceId
                    )
                );
            props.onClose && props.onClose();
        } else {
            props.onClose && props.onClose();
        }
        amplitude.track(AMPLITUDE.CTA_CLOSE_PAYMENT_METHODS);
    };

    const backPosPaymentHandler = () => {
        checkoutData.posServiceId &&
            checkoutData.posTerminalId &&
            dispatch(
                cancelPosThunk(
                    apiClientWrapper,
                    props.invoiceId,
                    checkoutData.posTerminalId,
                    checkoutData.posServiceId
                )
            );
        setShow({
            paymentMethods: true,
            tips: false,
            totalBalance: true,
            creditCard: false,
            pos: false,
            linkGenerated: false,
            sending: true
        });
    };

    const successPosPaymentHandler = () => {
        setCompleting(true);
        dispatch(
            sendThunk(
                apiClientWrapper,
                props.invoiceId,
                checkoutData.sendInvoice,
                checkoutData.sendReport,
                checkoutData.email!
            )
        );
    };

    const validate = (data: CheckoutData): Array<string> => {
        const errors: Array<string> = [];

        if (
            (data.sendInvoice || data.sendReport) &&
            isEmpty((data.email || '').trim())
        ) {
            errors.push('Email is required');
        }
        if (
            (data.sendInvoice || data.sendReport) &&
            !isEmpty((data.email || '').trim()) &&
            !isEmailValid(data.email || '')
        ) {
            errors.push('Email is invalid.');
        }
        if (!data.isZeroTotalBalance && data.paymentMethodId === null) {
            errors.push('Select a Payment Method.');
        }

        // if (data.paymentMethodId !== "pos" && data.tip === null) {
        //   errors.push("Select a Tip option.");
        // }

        return errors;
    };

    const showValidateEmailErr = (
        sendReport: boolean,
        sendReceipt: boolean
    ) => {
        return sendReport || sendReceipt;
    };

    const isPayByLinkAndNoPhone =
        checkoutData.paymentMethodId === PaymentMethodIds.PayByLink &&
        (!props.customerPhone || props.customerPhone.trim() === '');

    const PayByLinkContent = () => {
        const classes = useStyles();

        const copyToClipboard = () => {
            navigator.clipboard.writeText(payLink || '');
            dispatch(showSuccess('Link copied!'));
        };

        return paymentLinkLoading ? (
            <Spinner className={classes.spinner} />
        ) : (
            <Box className={classes.payByLinkContainer}>
                <Box className={classes.badgeIcon}>
                    <FontAwesomeIcon
                        icon={faLink}
                        className={classes.payByLinkIcon}
                    />
                </Box>
                <Typography className={classes.payByLinkTitle}>
                    Payment Link Sent
                </Typography>
                <Typography className={classes.payByLinkDescription}>
                    Your payment link for <strong>${totalBalance}</strong> has
                    been successfully created and sent to the client.
                </Typography>
                <Box className={classes.payByLinkInputContainer}>
                    <ControlledTextField2
                        className={classes.payByLinkInput}
                        name="payment_link"
                        id="payment_link"
                        value={payLink || ''}
                        readOnly={true}
                        onChange={() => {}}
                    />
                    <Button
                        size="medium"
                        color="black"
                        label="Copy"
                        startIcon={faCopy}
                        onClick={copyToClipboard}
                        className={classes.payByLinkCopyButton}
                    />
                </Box>
                <Box className={classes.buttonDoneContainer}>
                    <Button
                        size="large"
                        color="black"
                        label="Done"
                        className={classes.payByLinkDoneButton}
                        onClick={
                            props.backHandler ? props.backHandler : closeHandler
                        }
                    />
                </Box>
            </Box>
        );
    };

    const hasReport =
        booking?.appointments.some(
            appointment =>
                appointment.reports.dentalReportId !== null ||
                appointment.reports.groomingReportId !== null
        ) || false;

    const isButtonDisabled = saving || isPayByLinkAndNoPhone;

    const getButtonLabel = () => {
        if (checkoutData.paymentMethodId === PaymentMethodIds.PayByLink) {
            return 'Send Link';
        } else if (
            checkoutData.paymentMethodId === PaymentMethodIds.CreditCard
        ) {
            return 'Confirm & Pay';
        } else if (!props.hiddenReport) {
            return 'Complete Appointment';
        } else {
            return 'Pay';
        }
    };

    const handleAddPhoneNumber = () => {
        if (props.onShowClient && props.customerId) {
            props.onShowClient(props.customerId);
        }
        closeHandler();
    };

    return (
        <div className={clsx(classes.container, props.className)}>
            {showCloseButton && (
                <Box className={classes.backCloseContainer}>
                    {(show.creditCard || show.pos) && (
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={classes.closeIcon}
                            onClick={
                                show.creditCard
                                    ? backCreditCardPaymentHandler
                                    : backPosPaymentHandler
                            }
                        />
                    )}

                    {props.title && (
                        <Typography className={classes.title}>
                            {props.title}
                        </Typography>
                    )}

                    <FontAwesomeIcon
                        icon={faTimes}
                        className={classes.closeIcon}
                        onClick={closeHandler}
                    />
                </Box>
            )}

            {show.paymentMethods &&
                (isPaymentsEnabled === undefined ? (
                    <Spinner className={classes.spinner} />
                ) : (
                    <PaymentMethods
                        paymentsEnabled={isPaymentsEnabled}
                        isFreeServicesInvoice={isFreeServicesInvoice}
                        selectedId={checkoutData.paymentMethodId}
                        onSelect={changePaymentMethodHandler}
                        disabled={checkoutData.isZeroTotalBalance}
                        source={props.source}
                        hasPremiumPlan={hasPremiumPlan}
                    />
                ))}
            {show.tips && (
                <Tips
                    invoiceId={props.invoiceId}
                    invoiceTip={checkoutData.tip}
                    subtotalWithoutTipTaxes={props.subtotalWithoutTipTaxes}
                    tipDistribution={savedInvoice?.tipDistribution ?? []}
                    totalBalance={value => setTotalBalance(value)}
                    saving={saving}
                    className={classes.sectionMargin}
                    onChange={changeTipHandler}
                />
            )}
            {show.totalBalance && (
                <Total
                    amount={totalBalance}
                    title="Total Balance"
                    loading={saving}
                    className={classes.sectionMargin}
                />
            )}

            {show.pos && (
                <PosPayment
                    invoiceId={props.invoiceId}
                    className={classes.sectionMargin}
                    onSuccess={successPosPaymentHandler}
                    onGetPosServiceId={(posTerminalId, posServiceId) =>
                        setCheckoutData(prev => ({
                            ...prev,
                            posTerminalId,
                            posServiceId
                        }))
                    }
                />
            )}

            {show.sending &&
                (props.customerEmail === null ? (
                    <Spinner className={classes.reportAndReceipt} />
                ) : (
                    <>
                        {checkoutData.paymentMethodId !=
                            PaymentMethodIds.PayByLink && (
                            <SendingInformation
                                hasReport={hasReport}
                                className={classes.reportAndReceipt}
                                email={props.customerEmail}
                                hiddenReport={props.hiddenReport}
                                onChange={changeSendingDataHandler}
                                paymentMethodSelected={
                                    checkoutData.paymentMethodId
                                }
                            />
                        )}

                        {isPayByLinkAndNoPhone && (
                            <AlertBox
                                text={
                                    <>
                                        This client does not have a phone number
                                        added. Please{' '}
                                        <span
                                            onClick={() => {
                                                handleAddPhoneNumber();
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                fontWeight: '600'
                                            }}
                                        >
                                            add
                                        </span>{' '}
                                        it to send the pay by link.
                                    </>
                                }
                                actionable={false}
                            />
                        )}

                        <Box className={classes.buttonContainer}>
                            {showValidateEmailErr(
                                checkoutData.sendReport,
                                checkoutData.sendInvoice
                            ) &&
                                errors.map((error, index) => (
                                    <Typography
                                        className={classes.error}
                                        key={index}
                                    >
                                        {error}
                                    </Typography>
                                ))}

                            <Button
                                label={getButtonLabel()}
                                id="booking_confirm_pay_button"
                                className={classes.button}
                                variant={
                                    isButtonDisabled ? 'disabled' : undefined
                                }
                                onClick={completeHandler}
                                loading={completing}
                                disabled={isButtonDisabled}
                            />
                        </Box>
                    </>
                ))}
            {show.creditCard && (
                <CreditCardPayment
                    invoiceId={props.invoiceId}
                    onSuccess={successCreditCardPaymentHandler}
                    onBack={backCreditCardPaymentHandler}
                />
            )}

            {show.linkGenerated && <PayByLinkContent />}
        </div>
    );
};

export default Payments;
