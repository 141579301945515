import {
    Box,
    createStyles,
    makeStyles,
    TableCell,
    TableRow,
    Theme
} from '@material-ui/core';
import clsx from 'clsx';
import { AgreementStatus, Signature } from 'model/Agreements';
import { FunctionComponent } from 'react';
import SignatureDropdown from '../SignatureOptions';

interface SignaturesTableRowProps {
    signature: Signature;
    hideStatus?: boolean;
    hideSignedDate?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& td': {
                'height': 73,
                'fontSize': 15,
                'fontWeight': 500,
                'position': 'relative',
                'borderBottom': 'solid 1px #DDDDDD',

                '&:first-child': {
                    fontWeight: 500,

                    [theme.breakpoints.down(768)]: {
                        paddingLeft: 20
                    },
                    [theme.breakpoints.between(768, 'sm')]: {
                        paddingLeft: 30
                    },
                    [theme.breakpoints.only('md')]: {
                        paddingLeft: 50
                    },
                    [theme.breakpoints.only('lg')]: {
                        paddingLeft: 60
                    },
                    [theme.breakpoints.only('xl')]: {
                        paddingLeft: 90
                    }
                },
                '&:last-child': {
                    paddingRight: 60
                }
            },
            '& a': {
                color: '#222',
                display: 'block',
                padding: '5px 0px',
                textDecoration: 'none'
            }
        },
        status: {
            'fontSize': 15,
            'fontWeight': 500,
            'lineHeight': 1.8,
            'borderRadius': 14,
            'color': '#C18733',
            'padding': '0px 14px',
            'display': 'inline-block',
            'backgroundColor': '#FFF8DD',
            'textTransform': 'capitalize',

            '&.signed': {
                color: '#fff',
                backgroundColor: '#5E8677'
            }
        },

        text: {
            fontWeight: 500,
            fontHeigth: 15,
            lineHeight: 1.5,
            [theme.breakpoints.down(768)]: {
                fontHeigth: 14
            }
        }
    })
);

export const SignaturesTableRow: FunctionComponent<
    SignaturesTableRowProps
> = props => {
    const classes = useStyles();

    const StatusBadge = ({ status }: { status?: AgreementStatus }) => (
        <span className={clsx(classes.status, status)}>{status}</span>
    );

    return (
        <TableRow>
            <TableCell>
                <Box>
                    <b className={classes.text}>
                        {`${props.signature.client.firstName} ${props.signature.client.lastName}`}
                    </b>
                </Box>
            </TableCell>
            <TableCell>
                {props.signature.sentAt?.format('MMM D, YYYY h:mm a')}
            </TableCell>
            {!props.hideSignedDate && (
                <TableCell>
                    {props.signature.signedAt?.format('MMM D, YYYY h:mm a')}
                </TableCell>
            )}
            {!props.hideStatus && (
                <TableCell>
                    <StatusBadge status={props.signature.status} />
                </TableCell>
            )}
            <TableCell>
                {props.signature.email
                    ? 'Email'
                    : props.signature.sms
                    ? 'Sms'
                    : ''}
            </TableCell>
            <TableCell>
                <SignatureDropdown signature={props.signature} />
            </TableCell>
        </TableRow>
    );
};

export default SignaturesTableRow;
