import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      fontWeight: 600,
      borderBottom: "1px solid #F1F1F1",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "16px",
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "20px",
        fontSize: "16px",
      },
    },
    row: {
      borderBottom: "1px solid #F1F1F1",
    },
  })
);

export const TableHeader: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.row}>
        <TableCell className={clsx(classes.cell)}>Name</TableCell>
        <TableCell className={clsx(classes.cell)}>Category</TableCell>
        <TableCell className={clsx(classes.cell)}>Price</TableCell>
      </TableRow>
    </TableHead>

  );
};

export default TableHeader;
