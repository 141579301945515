import { faHouseMedical, faStethoscope, faSyringe, faTooth } from "@fortawesome/pro-light-svg-icons";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldError } from "@spike/model";
import { PetMedicalCondition, PetMedicalHistory, PetVaccine, PetDental } from "@spike/medical-history-model";
import Pet from "@spike/pet-model";
import VetModel, { createEmptyVet } from "@spike/vet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import EditVet from "../../Vet/Edit";
import MedicalHistoryHeader from "../MedicalHistoryHeader";
import NewPetMedicalHistorySection from "../NewPetMedicalHistorySection";
import EditMedicalConditions from "./MedicalConditions";
import Vaccines from "./Vaccines";
import { useMarketplace } from "hooks";
import Dentals from "./Dentals";
import {v4 as uuid } from 'uuid';
import clsx from "clsx";
interface NewPetEditMedicalHistoryProps {
  pet: Pet;
  vet?: VetModel;
  errors?: Array<FieldError>;
  onChangeMedicalHistory?: (medicalHistory: PetMedicalHistory) => void;
  onChangeVet?: (vet: VetModel) => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(32)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "32px",
      },
    },
    section: {
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(72)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "72px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
      },
    },
    sectionMargin:{
      [theme.breakpoints.down("md")]: {
        marginBottom: "0px"
      }
    },
    separator: {
      display: 'none',
      backgroundColor: '#F4F3F0',
      height: '16px',
      width: '110%',
      marginLeft: '-5%',
      marginBottom: '30px',
      marginTop: '25px',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
  })
);

const Separator = () => {
  return <Box className={useStyles().separator}></Box>;
};

export const NewPetEditMedicalHistory: FunctionComponent<NewPetEditMedicalHistoryProps> = (props) => {
  const classes = useStyles();

  const isDental = useMarketplace().isDental;

  const changeVaccinesHandler = (vaccines: Array<PetVaccine>) => {
    props.onChangeMedicalHistory &&
      props.onChangeMedicalHistory({ ...props.pet.medicalHistory, vaccines: [...vaccines] });
  };

  const changeMedicalConditionsHandler = (medicalConditions: Array<PetMedicalCondition>) => {
    props.onChangeMedicalHistory &&
      props.onChangeMedicalHistory({ ...props.pet.medicalHistory, medicalConditions: [...medicalConditions] });
  };

  const changeDentalsHandler = (dentals: Array<PetDental>) => {
    props.onChangeMedicalHistory &&
      props.onChangeMedicalHistory({ ...props.pet.medicalHistory, dentals: [...dentals] });
  };

  return (
    <>
    <Grid container xs={12}>
      <MedicalHistoryHeader description="As disclosed by the owner." className={classes.header} />

      <Grid container xs={12}>
        <NewPetMedicalHistorySection title="Vaccines" icon={faSyringe} className={classes.section}>
          <Vaccines
            vaccines={props.pet.medicalHistory.vaccines || []}
            errors={props.errors}
            onChange={changeVaccinesHandler}
          />
        </NewPetMedicalHistorySection>
      </Grid>
    </Grid>

    <Separator></Separator>

    <Grid container xs={12}>
      <Grid container xs={12}>
          <NewPetMedicalHistorySection title="Vet" icon={faStethoscope} className={clsx(classes.section, classes.sectionMargin)}>
          <EditVet vet={props.vet || createEmptyVet(uuid)} onChange={props.onChangeVet} errors={props.errors} />
        </NewPetMedicalHistorySection>
      </Grid>
    </Grid>

    <Separator></Separator>

    <Grid container xs={12}>
      <Grid container xs={12}>
          <NewPetMedicalHistorySection title="Medical Conditions" icon={faHouseMedical} className={clsx(classes.section, classes.sectionMargin)}>
          <EditMedicalConditions
            gender={props.pet.gender}
            medicalConditions={props.pet.medicalHistory.medicalConditions}
            onChange={changeMedicalConditionsHandler}
            errors={props.errors}
          />
        </NewPetMedicalHistorySection>
      </Grid>
    </Grid>

    <Separator></Separator>

    <Grid container xs={12}>
      {isDental &&
        <Grid container xs={12}>
          <NewPetMedicalHistorySection title="Dentals" icon={faTooth} className={classes.section}>
            <Dentals
              dentals={props.pet.medicalHistory.dentals || []}
              onChange={changeDentalsHandler}
            />
          </NewPetMedicalHistorySection>
        </Grid>
      }
    </Grid>
  </>
  );
};

export default NewPetEditMedicalHistory;
