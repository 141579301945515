import { Auth, User } from '@spike/auth-model';
import MasterData, { ResourceAccessLevel } from '@spike/masterdata-model';
import { ACCESS_LEVEL_COLLABORATOR_ID } from '@spike/model';

const frontToBackResources = new Map<string, string>([['helpCenter', 'help']]);

const hasResourceAccess = (
    resource: string,
    resourceAccessLevels: Array<ResourceAccessLevel>,
    userAccesLevelId: number
): boolean => {
    const backResource = frontToBackResources.get(resource) ?? resource;

    const resourceAccessLevel = resourceAccessLevels.find(
        resourceAccessLevel =>
            resourceAccessLevel.accessLevelId === userAccesLevelId
    );

    return (
        resourceAccessLevel === undefined ||
        resourceAccessLevel.allowedResources.some(
            accessLevelResource =>
                accessLevelResource.resourceId === backResource
        )
    );
};

export const hasAccessLevel = (
    resource: string,
    masterData?: MasterData,
    user?: User
) => {
    return (
        masterData !== undefined &&
        user !== undefined &&
        hasResourceAccess(
            resource,
            masterData.resourceAccessLevels,
            user.accessLevel.id
        )
    );
};
