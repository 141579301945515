import store from 'store';
import {
	AdminInvoice,
	MarketplaceSMSMessagesStats,
	Subscription
} from 'model/Subscriptions';
import {
	AdminInvoiceDto,
	AdminInvoiceLineDto,
	MarketplaceSMSMessagesStatsDto,
	SubscriptionDto
} from './SubscriptionsDtos';
import moment from 'moment-timezone';

export const convertToSubscription = (dto: SubscriptionDto): Subscription => {
	const auth = store.getState().login.auth;
	const marketplace = store.getState().marketplace.marketplace;

	const timeZone = marketplace?.schedule.timeZone || auth.timeZone;

	return {
		id: dto.id,
		uuid: dto.uuid,
		staffId: dto.staff_id,

		active: dto.active,
		channel: dto.channel,

		promoCode: dto.promo_code,
		categoryId: dto.category_id,
		marketplaceId: dto.marketplace_id,

		price: {
			amount: dto.price,
			total: dto.final_price,
			discount: dto.discount_amount
		},

		endAt: moment(dto.end_at).tz(`${timeZone}`),
		startAt: moment(dto.start_at).tz(`${timeZone}`),
		createdAt: moment(dto.created_at).tz(`${timeZone}`),
		updatedAt: moment(dto.updated_at).tz(`${timeZone}`),
		renewAt: moment(dto.next_renewal_date).tz(`${timeZone}`)
	};
};

export const convertToAdminInvoice = (dto: AdminInvoiceDto): AdminInvoice => {
	const auth = store.getState().login.auth;
	const marketplace = store.getState().marketplace.marketplace;

	const timeZone = marketplace?.schedule.timeZone || auth.timeZone;

	return {
		id: dto.id,
		uuid: dto.uuid,
		clientId: dto.client_id,
		subscriptionId: dto.subscription_id,
		number: dto.number,
		clientType: dto.client_type,
		paymentType: dto.payment_type,
		tipAmount: dto.tip_amount,
		taxRate: dto.tax_rate,
		paidInFull: dto.paid_in_full,
		tax: dto.tax,
		subtotal: dto.subtotal,
		total: dto.total,
		discount: dto.discount,
		grandTotal: dto.grand_total,
		invoiceLines: dto.admin_invoice_lines.map(line =>
			convertToAdminInvoiceLine(line)
		),
		pdfUrl: dto.pdf_file_url,
		issuedAt: moment(dto.issued_at).tz(`${timeZone}`),
		createdAt: moment(dto.created_at).tz(`${timeZone}`),
		updatedAt: moment(dto.updated_at).tz(`${timeZone}`)
	};
};

export const convertToAdminInvoiceLine = (dto: AdminInvoiceLineDto) => {
	const auth = store.getState().login.auth;
	const marketplace = store.getState().marketplace.marketplace;

	const timeZone = marketplace?.schedule.timeZone || auth.timeZone;

	return {
		id: dto.id,
		uuid: dto.uuid,
		adminInvoiceId: dto.admin_invoice_id,
		description: dto.description,
		quantity: dto.quantity,
		amount: dto.amount,
		total: dto.total,
		discount_amount: dto.discount_amount,
		discount_percentage: dto.discount_percentage,
		created_at: moment(dto.created_at).tz(`${timeZone}`),
		updated_at: moment(dto.updated_at).tz(`${timeZone}`)
	};
};

export const convertToSMSMessagesStats = (
	dto: MarketplaceSMSMessagesStatsDto
): MarketplaceSMSMessagesStats => {
	return {
		totalSentSms: dto.total_sent_sms,
		remainingSms: dto.remaining_sms
	};
};
