import { FunctionComponent } from "react";
import ResumeSection, { BodyResumeSection } from "../ResumeSection";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Product } from "@spike/product-model";
import ResumeTable from "./UI/ResumeTable";

interface Props {
  product: Product;
  onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      height: "auto",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "20px",
      },
    },
  })
);

export const ResumeVendorsAndCost: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <ResumeSection title="Vendors & Unit Cost" {...props}>
      <BodyResumeSection>
        <Box className={classes.container}>
          <ResumeTable 
            product={props.product}
          />
        </Box>
      </BodyResumeSection>
    </ResumeSection>
  );
};

export default ResumeVendorsAndCost;
