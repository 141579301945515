import { Booking } from "@spike/booking-model";
import { Action } from "redux";
import { NewAppointmentDto } from "./NewBookingsDtos";

//Action Types
export const NEW_BOOKINGS_SAVE_START_ACTION_TYPE = "new_bookings/save_start";
export const NEW_BOOKINGS_SAVE_SUCCESS_ACTION_TYPE = "new_bookings/save_success";
export const NEW_BOOKINGS_DEPOSIT_SAVE_START_ACTION_TYPE = "new_bookings/deposit_save_start";
export const NEW_BOOKINGS_DEPOSIT_SAVE_SUCCESS_ACTION_TYPE = "new_bookings/deposit_save_success";
export const NEW_BOOKINGS_ERROR_ACTION_TYPE = "new_bookings/error";
export const NEW_BOOKINGS_SAVE_CREATE_START_ACTION_TYPE = "new_bookings/save_create_start";
export const NEW_BOOKINGS_SAVE_CREATE_SUCCESS_ACTION_TYPE = "new_bookings/save_create_success";
export const NEW_BOOKINGS_GET_ALERTS_START_ACTION_TYPE = "new_bookings/get_alerts_start";
export const NEW_BOOKINGS_GET_ALERTS_SUCCESS_ACTION_TYPE = "new_bookings/get_alerts_success";

//Actions
interface NewBookingsSaveStartAction extends Action<typeof NEW_BOOKINGS_SAVE_START_ACTION_TYPE> {}

interface NewBookingsSaveSuccessAction extends Action<typeof NEW_BOOKINGS_SAVE_SUCCESS_ACTION_TYPE> {}

interface NewBookingsDepositSaveStartAction extends Action<typeof NEW_BOOKINGS_DEPOSIT_SAVE_START_ACTION_TYPE> {}

interface NewBookingsDepositSaveSuccessAction extends Action<typeof NEW_BOOKINGS_DEPOSIT_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    booking: Booking;
  };
}

interface NewBookingsErrorAction extends Action<typeof NEW_BOOKINGS_ERROR_ACTION_TYPE> {}

interface NewBookingsSaveCreateStartAction extends Action<typeof NEW_BOOKINGS_SAVE_CREATE_START_ACTION_TYPE> {}

interface NewBookingsSaveCreateSuccessAction extends Action<typeof NEW_BOOKINGS_SAVE_CREATE_SUCCESS_ACTION_TYPE> {
  payload: {
    newAppointment: NewAppointmentDto;
  };
}

export type NewBookingsAction =
  | NewBookingsSaveStartAction
  | NewBookingsSaveSuccessAction
  | NewBookingsDepositSaveStartAction
  | NewBookingsDepositSaveSuccessAction
  | NewBookingsSaveCreateStartAction
  | NewBookingsSaveCreateSuccessAction
  | NewBookingsErrorAction;
