import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import IconCircle from 'components/UI/IconCircle';
import TitlePrimary from 'components/UI/Title/TitlePrimary';
import { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from 'rocket/components/Buttons/PrimaryButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            fontSize: 15,
            lineHeight: 1.4,
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        headingTitle: {
            width: '100%',
            fontSize: '20px',
            fontWeight: 600,
            padding: '26px 16px 0'
        },
        icon: {
            margin: '0px auto 15px',
            [theme.breakpoints.down('xs')]: {
                margin: '0px auto 25px'
            }
        },
        title: {
            [theme.breakpoints.down('xs')]: {
                fontSize: 26,
                color: '#000',
                fontFamily: 'Poppins',
                fontWeight: 600
            }
        },
        paragraph: {
            fontSize: 15,
            lineHeight: 1.4,
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                fontWeight: 500,
                fontSize: 16,
                maxWidth: 300,
                margin: '10px auto 20px'
            }
        },
        button: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: 35,
            paddingRight: 35,
            margin: '0px auto',
            display: 'inline-block',
            [theme.breakpoints.down('xs')]: {
                fontWeight: 600
            }
        }
    })
);

const SMSCampaignsIsEmpty: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <Typography className={classes.headingTitle}>
                SMS campaigns
            </Typography>
            <Box className={classes.root}>
                <IconCircle icon={faBullhorn} className={classes.icon} />
                <TitlePrimary className={classes.title}>
                    Create a new campaign
                </TitlePrimary>
                <Typography className={classes.paragraph}>
                    Click the “New SMS Campaign” button to add one
                </Typography>

                <Link to="/marketing/sms/get-started">
                    <PrimaryButton
                        label="New SMS Campaign"
                        className={classes.button}
                    />
                </Link>
            </Box>
        </Fragment>
    );
};

export default SMSCampaignsIsEmpty;
