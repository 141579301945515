import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useCommonStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			'overflowX': 'scroll',

			'&::-webkit-scrollbar': {
				display: 'none'
			}
		},
		header: {
			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#FAFAFA'
			}
		},
		headerCell: {
			'fontSize': 14,
			'lineHeight': 1,
			'fontWeight': 600,
			'padding': '20px 16px',
			'whiteSpace': 'nowrap',
			'borderBottom': 'solid 1px #D4D4D4',

			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#FAFAFA'
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 16,
				borderBottom: 'solid 2px #000'
			},

			'&:first-child': {
				'left': 0,
				'position': 'sticky',

				[theme.breakpoints.up('md')]: {
					paddingLeft: 35
				},

				'&:before': {
					'content': `''`,
					'top': 0,
					'left': 0,
					'right': 0,
					'bottom': 0,
					'zIndex': -1,
					'display': 'block',
					'position': 'absolute',
					'backgroundColor': '#FAFAFA',
					'boxShadow': '0px 0px 0px 0px transparent',
					'transition': 'box-shadow 0.15s ease-out',

					[theme.breakpoints.up('md')]: {
						backgroundColor: '#ffffff'
					},

					'.scrolled &': {
						boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
					}
				}
			}
		},
		darkCell: {
			'backgroundColor': '#FAFAFA !important',

			'&:before': {
				backgroundColor: '#FAFAFA !important'
			}
		},
		body: {
			whiteSpace: 'nowrap',
			backgroundColor: '#ffffff'
		},
		cell: {
			'height': 54,
			'fontSize': 14,
			'lineHeight': 1,
			'borderBottomColor': '#D4D4D4',

			[theme.breakpoints.up('md')]: {
				height: 73,
				fontSize: 15
			},

			'&:first-child': {
				'left': 0,
				'fontWeight': 500,
				'position': 'sticky',

				[theme.breakpoints.up('md')]: {
					paddingLeft: 35
				},

				'&:before': {
					'content': `''`,
					'top': 1,
					'left': 0,
					'right': 0,
					'bottom': 1,
					'zIndex': -1,
					'display': 'block',
					'position': 'absolute',
					'backgroundColor': '#fff',
					'boxShadow': '0px 0px 0px 0px transparent',
					'transition': 'box-shadow 0.15s ease-out',

					'.scrolled &': {
						boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
					}
				}
			}
		}
	})
);
