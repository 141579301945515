import React, { FunctionComponent, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Row from "./Row";
import { SelectableOption } from "../../../model";

interface Props {
  elements: Array<GridElement>;
  onEdit?: (id: number) => void;
  onActivate?: (id: number, active: boolean) => void;
  onDuplicate?: (
    id: number,
    name: string,
    selectedOptionsIds: Array<string>
  ) => void;
  onDelete?: (id: number) => void;
  onSelect?: (id: number, selected: boolean) => void;
  duplicateOptions?: Array<SelectableOption<string>>;
  className?: string;
  mobileToolbar?: boolean;
  isMobile?: boolean;
}

export interface GridElement {
  id: number;
  name: string;
  selected: boolean;
  statusId: number;
  duplicated?: boolean;
  content: JSX.Element;
  disableActivateButton?: boolean;
  disableDeleteButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down("sm")]: {},
      [theme.breakpoints.only("md")]: {},
      [theme.breakpoints.only("lg")]: {},
      [theme.breakpoints.up("xl")]: {},
    },
  })
);

export const Grid: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [openToolbar, setOpenToolbar] = useState<number | undefined>(undefined);

  useEffect(() => {
    setOpenToolbar(undefined);
  }, [props.elements]);

  const onEditHandler = (id: number): void => {
    props.onEdit && props.onEdit(id);
  };

  const onActivateHandler = (id: number, active: boolean): void => {
    props.onActivate && props.onActivate(id, active);
  };

  const onDuplicateHandler = (
    id: number,
    name: string,
    selectedOptionIds: Array<string>
  ): void => {
    props.onDuplicate && props.onDuplicate(id, name, selectedOptionIds);
  };

  const onDeleteHandler = (id: number): void => {
    props.onDelete && props.onDelete(id);
  };

  const onSelectHandler = (id: number, selected: boolean): void => {
    props.onSelect && props.onSelect(id, selected);
  };

  const rows = props.elements.map((element) => (
    <Row
      key={element.id}
      {...element}
      onEdit={onEditHandler}
      onActivate={element.disableActivateButton ? undefined : onActivateHandler}
      onDuplicate={props.onDuplicate ? onDuplicateHandler : undefined}
      onDelete={element.disableDeleteButton ? undefined : onDeleteHandler}
      onSelect={props.onSelect ? onSelectHandler : undefined}
      duplicateOptions={props.duplicateOptions}
      showToolbar={openToolbar === element.id}
      onOpenToolbar={() => setOpenToolbar(element.id)}
      mobileToolbar={props.mobileToolbar}
      isMobile={props.isMobile}
    />
  ));

  return <Box className={clsx(classes.container, props.className)}>{rows}</Box>;
};

export default Grid;
