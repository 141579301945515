import {
    Box,
    Theme,
    createStyles,
    makeStyles,
    Typography
} from '@material-ui/core';
import CuddlesLogo from 'components/PayByLink/UI/CuddlesLogo';
import React from 'react';

interface NotFoundProps {
    customContainerClass?: string;
    title: string;
    subtitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
            backgroundColor: '#FFFFFF',
            zIndex: 9999,
            overflow: 'hidden'
        },
        image: {
            maxWidth: '700px',
            marginBottom: '20px',
            marginTop: '50px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: 'auto',
                paddingLeft: '20px',
                maxWidth: '500px',
                marginTop: '30px'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontSize: '46px',
            lineHeight: '100%',
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '25px',
            marginTop: '-30px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '28px',
                marginTop: '-10px',
                maxWidth: '290px',
                marginLeft: 'auto',
                marginRight: 'auto',
                lineHeight: '120%',
                marginBottom: '10px'
            }
        },
        subtitle: {
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 400,
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '300px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '260px'
            }
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            textAlign: 'center',
            paddingBottom: 30,
            [theme.breakpoints.down('sm')]: {
                marginTop: 40
            }
        },
        footerText: {
            textTransform: 'uppercase',
            fontSize: 10,
            letterSpacing: '0.96px',
            color: '#000',
            opacity: 0.5,
            marginBottom: 5,
            paddingLeft: 2
        },
        cuddlesLogo: {
            display: 'inline-block'
        }
    })
);

const NotFound: React.FC<NotFoundProps> = ({
    customContainerClass,
    title,
    subtitle
}) => {
    const classes = useStyles();

    return (
        <Box className={`${classes.container} ${customContainerClass}`}>
            <Typography variant="h1" className={classes.title}>
                {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
                {subtitle}
            </Typography>
            <img
                src="/images/common/img-404.jpg"
                alt="Not Found"
                className={classes.image}
            />
            <Box className={classes.footer}>
                <Typography className={classes.footerText}>
                    Powered by
                </Typography>
                <CuddlesLogo className={classes.cuddlesLogo} />
            </Box>
        </Box>
    );
};

export default NotFound;
