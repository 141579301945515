import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Moment } from "moment-timezone";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import Calendar from "./Calendar";
import { FieldError } from "@spike/model";

export interface CalendarFieldProps {
  placeholder?: string;
  name?: string;
  date?: Moment;
  rawDate?: boolean;
  onChange: (date: Moment, fieldName?: string) => void;
  errors?: Array<FieldError>; 
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    marginIcons: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "3px",
      },
    },
    iconFile: {
      width: "auto !important",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "5px",
      },
    },
    cellStyle: {
      [theme.breakpoints.down(wbp)]: {
        lineHeight: `${reduceResolution(24)}px`,
        fontSize: `${reduceResolution(16)}px`,
        width: `${reduceResolution(150)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        lineHeight: "24px",
        fontSize: "16px",
        width: "150px",
      },
    },
    expirationBox: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: "1px solid #000",
      "&:hover": {
        background: "#fff",
      },
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(100)}px`,
        maxWidth: `${reduceResolution(243)}px`,
        height: `${reduceResolution(45)}px`,
        paddingLeft: `${reduceResolution(31)}px`,
        paddingRight: `${reduceResolution(31)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "100px",
        maxWidth: "243px",
        height: "45px",
        paddingLeft: "31px",
        paddingRight: "31px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        height: "47px",
        borderColor: "#d3d3d3",
        marginBottom: "15px",
        textAlign: "center",
      }
    },
    expiration: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        fontWeight: 500,
      }
    },
    expirationRow: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      }
    },
    calendarContainer: {
      position: "absolute",
      display: "flex",
      background: "#ffffff",
      boxShadow:
        "0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)",
      height: "auto",
      zIndex: 300,
      marginRight: "-100px",
      [theme.breakpoints.down(wbp)]: {
        top: `${reduceResolution(60)}px`,
        borderRadius: `${reduceResolution(12)}px`,
        width: `${reduceResolution(344)}px`,
        minHeight: `${reduceResolution(432)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        top: "60px",
        borderRadius: "12px",
        width: "344px",
        minHeight: "432px",
      },
    },
    selected: {
      border: "2px solid #000",
    },
    errorBorder: {
      borderColor: "#EA6464",
    },
    errorText: {
      color: "#EA6464",
    },
  })
);

export const CalendarField: FunctionComponent<CalendarFieldProps> = (props) => {
  const classes = useStyles(props);

  const [showCalendar, setShowCalendar] = useState(false);

  const hasError =
  props.date === undefined && (props.errors?.some((error) => error.fieldName === props.name) || false);

  const handleChangeClick = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={clsx(classes.expirationBox, { [classes.selected]: showCalendar, [classes.errorBorder]: hasError })} onClick={handleChangeClick}>
        <Box className={classes.expirationRow}>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className={clsx(classes.cellStyle, classes.iconFile, classes.marginIcons, {[classes.errorText]: hasError})}
          />
          <Typography className={clsx(classes.expiration, {[classes.errorText]: hasError})}>
            {props.date && props.date.isValid()
              ? props.date.format("ll")
              : props.placeholder || "Add Date"}
          </Typography>
        </Box>
      </Box>
      {showCalendar && (
        <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
          <Box className={classes.calendarContainer}>
            <Calendar
              date={props.date && props.date.isValid() ? props.date : undefined}
              rawDate={props.rawDate}
              onChangeDate={moment => props.onChange(moment, props.name)}
              onClose={() => setShowCalendar(false)}
            />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default CalendarField;
