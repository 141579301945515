import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import envelopeLetter from "rocket/assets/images/envelope-letter.svg";
import commonsStyles from "rocket/assets/css/Commons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { resendVerificationEmailThunk } from "actions/signUp/SignUpActions";
import { RootState } from "store";
import SignUp from "model/SignUp";
import { SignUpPath } from "routes/Paths";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
  },
  envelopeLetter: {
    width: 54,
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  paragraph: {
    maxWidth: 400,
    marginTop: 0,
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 35,
    textAlign: "center",
  },
  column: {
    display: "flex",
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    marginLeft: "auto",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F8F5F1",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "55%",
      flexBasis: "55%",
      paddingTop: 0,
      paddingLeft: "7%",
      paddingRight: "9%",
      paddingBottom: 80,
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "7%",
      paddingRight: "13%",
    },
  },
}));

export const RightColumn: FunctionComponent = () => {
  const classes = useStyles();
  const otherClasses = commonsStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector<RootState, SignUp | undefined>(
    (state) => state.signUp.data
  );

  useEffect(() => {
    if (!data) {
      history.push(SignUpPath);
    }
  }, [data, history]);

  const resend = () => {
    dispatch(resendVerificationEmailThunk(data!.email));
  };

  return (
    <Grid item xs={12} className={classes.column}>
      <div className={classes.heading}>
        <img
          src={envelopeLetter}
          alt="Envelope Letter"
          className={classes.envelopeLetter}
        />
        <h2 className={classes.title}>Verify Email Address</h2>
      </div>

      <p className={classes.paragraph}>
        You’re almost there! We’ve sent a link to your email. Please take a
        moment to verify your account to finish signing up for Cuddles.
      </p>

      <p className={classes.paragraph}>
        Didn’t get an email?{" "}
        <a id="signup_button_resend" href="#" className={otherClasses.underlinedLink} onClick={resend}>
          Resend
        </a>
      </p>
    </Grid>
  );
};

export default RightColumn;
