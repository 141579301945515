import React, { FunctionComponent, useState, useEffect } from "react";
import { Button as MUIButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";


export interface Props {
  label: string;
  onChange: (selected: boolean) => void;
  disabled?: boolean;
  className?: string;
  selected?: boolean,
  selectOnly?: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.text.primary,
      background: "#ffffff",
      border: "1px solid #707070",
      "&:hover": {
        color: theme.palette.primary.main,
        background: "#ffffff",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
      }
    },
    selected: {
      background: "#FAEFDF",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      "&:hover": {
        background: "#FAEFDF",
      }
    }
  })
);


export const SelectButton: FunctionComponent<Props> = (props) => {

  const classes = useStyles(props);

  const [selected, setSelected] = useState(props.selected);
  
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const clickHandler = ():void => {
    let newSelected = !selected;
    if(newSelected || !props.selectOnly) {
      setSelected(newSelected);
      props.onChange(newSelected);
    }
  }

  const style = selected ? clsx(classes.button, classes.selected, props.className) : clsx(classes.button, props.className);
  
  return (
    <MUIButton
      variant="contained"
      color="primary"
      disableRipple={true}
      disableTouchRipple={true}
      disableElevation={true}
      onClick={clickHandler}
      disabled={props.disabled}
      className={style}
    >
      {props.label}
    </MUIButton>
  );
};

export default SelectButton;
