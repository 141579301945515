import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'components/UI';
import { StaffMember } from 'model/Service';
import StaffChip from 'components/UI/StaffChip';
import StaffSelector from 'components/UI/StaffSelector';
import { useDispatch } from 'react-redux';
import { showError } from '@spike/notifications-action';
interface Props {
    allMembers: Array<StaffMember>;
    serviceMembers: Array<StaffMember>;
    onChange: (staffMembers: Array<StaffMember>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 12,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',

            [theme.breakpoints.up('md')]: {
                gap: 16
            }
        },
        addedMember: {
            maxWidth: '100%'
        },
        button: {
            'height': 40,
            'borderRadius': 30,
            'marginBottom': 20,
            'paddingLeft': 16,
            'paddingRight': 16,

            '& .MuiButton-label': {
                fontSize: 14
            },
            '& .MuiButton-startIcon': {
                'marginLeft': 0,
                'marginRight': 4,

                '& svg': {
                    width: 16,
                    height: 16
                }
            }
        },
        plusButton: {
            width: 37,
            height: 37,
            cursor: 'pointer'
        }
    })
);

export const StaffMembers: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const [selectedMembers, setSelectedMembers] = useState<Array<StaffMember>>(
        props.serviceMembers
    );
    const [showMemberList, setShowMemberList] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        props.onChange(selectedMembers);
    }, [selectedMembers]);

    const openMembersListHandler = () => {
        setShowMemberList(true);
    };

    const selectMembersHandler = (
        selectedMembers: Array<StaffMember>
    ): void => {
        setSelectedMembers(prevSelectedMembers => {
            return [...prevSelectedMembers, ...selectedMembers];
        });

        setShowMemberList(false);
    };

    const deleteHandler = (deletedMember: StaffMember) => {
        if (selectedMembers.length > 1) {
            setSelectedMembers(prevSelectedMembers => {
                return prevSelectedMembers.filter(
                    member => member.id !== deletedMember.id
                );
            });
        } else {
            dispatch(
                showError('The service must have at least one staff assigned.')
            );
        }
    };

    const addMembersButton = (
        <Button
            id="services_add_service_members_button_add"
            label="Add Member"
            startIcon={faPlus}
            color="green"
            size="medium"
            className={classes.button}
            onClick={openMembersListHandler}
        />
    );

    const plusButton = (
        <FontAwesomeIcon
            icon={faPlusCircle}
            color="#92B4A7"
            size="2x"
            onClick={openMembersListHandler}
            className={classes.plusButton}
        />
    );

    const addButton =
        selectedMembers.length > 0 ? plusButton : addMembersButton;

    const memberList = (
        <StaffSelector
            id="services_add_service_members_add_staffselector"
            staff={props.allMembers.filter(
                member =>
                    member &&
                    !props.serviceMembers.map(sm => sm.id).includes(member.id)
            )}
            showAddNew={false}
            onBack={() => setShowMemberList(false)}
            onSelect={members => {
                selectMembersHandler(members);
            }}
        />
    );

    return (
        <Box
            id="services_add_service_members_add_staffcontainer"
            className={classes.container}
        >
            {selectedMembers.map((member, index) => (
                <Box key={member.id} className={classes.addedMember}>
                    <StaffChip
                        id={'services_add_service_members_' + index}
                        staff={member}
                        small={false}
                        onDelete={() => deleteHandler(member)}
                    />
                </Box>
            ))}
            {showMemberList ? memberList : addButton}
        </Box>
    );
};

export default StaffMembers;
