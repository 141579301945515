import {FunctionComponent} from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { SearchComponent } from 'components/UI';

interface SearchProperties {
    onSearch: (test: string) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginBottom: 20,
        padding: '0px 25px',

        [theme.breakpoints.down(768)]: {
            paddingLeft: 20,
            paddingRight: 20
        },
        [theme.breakpoints.between(768, "sm")]: {
            paddingLeft: 27,
            paddingRight: 40,
        },
        [theme.breakpoints.only("md")]: {
            paddingLeft: 43,
            paddingRight: 65,
        },
        [theme.breakpoints.only("lg")]: {
            paddingRight: 87,
            paddingLeft: 57,
        },
        [theme.breakpoints.only("xl")]: {
            paddingLeft: 87,
            paddingRight: 132,
        },
    },
    box: {
        borderRadius: 12,
        padding: '15px 20px',
        backgroundColor: '#F1F1F1',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 15px',
        },
    },
    field: {
        width: 400,
        height: 47,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        '& .MuiInputBase': {
            '&-root': {
                backgroundColor: '#fff'
            },
            '&-input': {
                fontSize: 14
            }
        },
        '& .MuiInputAdornment-root': {
            opacity: 0.3,
            'pointer-events': 'none',

            '& svg': {
                cursor: 'default',
            },
            [theme.breakpoints.down('md')]: {
                opacity: 1,
                '& svg': {
                    color: "#000",
                    fontWeight: 400,
                },
            }
        },
    }
}))

const Search: FunctionComponent<SearchProperties> = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <SearchComponent open
                    onSearch={props.onSearch}
                    className={classes.field}
                    placeholder='Search by add-on service name' />
            </Box>
        </Box>
    )
}

export default Search;
