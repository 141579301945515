import React, { FunctionComponent } from 'react';

import Badge from '@material-ui/core/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-duotone-svg-icons';
import { createStyles, makeStyles } from '@material-ui/core';

interface Props {
	notificationAmount: number;
	onClick: () => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			'width': 40,
			'height': 40,
			'cursor': 'pointer',
			'position': 'relative',
			'borderRadius': '50px',
			'display': 'inline-flex',
			'alignItems': 'center',
			'justifyContent': 'center',
			'backgroundColor': 'transparent',
			'transition': 'background-color 0.2s ease-out',

			'&:hover': {
				'backgroundColor': '#ededed',
				'& + #label': {
					display: 'flex'
				}
			}
		},
		customBadge: {
			top: 0,
			right: 26,
			left: 'auto',
			color: '#fff',
			fontSize: 12,
			lineHeight: 1,
			fontWeight: 600,
			borderRadius: 3,
			transform: 'none',
			alignItems: 'center',
			padding: '2px 5px 1px',
			justifyContent: 'center',
			backgroundColor: '#EF4F57'
		},
		bell: {
			fontSize: 24,
			color: '#C18734'
		}
	})
);

export const NotificationBell: FunctionComponent<Props> = props => {
	const classes = useStyles();
	return (
		<Badge
			className={classes.container}
			badgeContent={props.notificationAmount}
			classes={{ badge: classes.customBadge }}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			onClick={props.onClick}
		>
			<FontAwesomeIcon className={classes.bell} icon={faBell} />
		</Badge>
	);
};

export default NotificationBell;
