import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

interface ButtonProps {
	label: string;
	onClick?: () => void;
}

const breakpoint = 1000;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			border: '1px solid black',
			cursor: 'pointer',
			[theme.breakpoints.down(breakpoint)]: {
				paddingLeft: '8px',
				paddingRight: '8px',
				height: '30px',
				borderRadius: '30px',

			},
			[theme.breakpoints.up(breakpoint)]: {
				paddingLeft: '14px',
				paddingRight: '14px',
				height: '38px',
				borderRadius: '38px',


			}
		},
		label: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			[theme.breakpoints.down(breakpoint)]: {
				marginLeft: 5,
				fontSize: 14
			},
			[theme.breakpoints.up(breakpoint)]: {
				marginLeft: 10,
				fontSize: 16
			}
		},
		icon: {
			[theme.breakpoints.down(breakpoint)]: {
				width: '14px',
				height: '14px'
			},
			[theme.breakpoints.up(breakpoint)]: {
				width: '18px',
				height: '18px'
			}
		}
	})
);

export const Button: FunctionComponent<ButtonProps> = props => {
	const classes = useStyles();

	return (
		<Box
			id="calendar_button_staff"
			className={classes.container}
			onClick={props.onClick}
		>
			<FontAwesomeIcon icon={faUsers} className={classes.icon} />
			<Typography className={classes.label}>{props.label}</Typography>
		</Box>
	);
};

export default Button;
