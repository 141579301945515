import { Reducer } from "redux";
import { TipsReportState, TipsReportStatus } from "./TipsReportState";
import { TIPSREPORT_GET_DETAIL_START_ACTION_TYPE, TIPSREPORT_GET_DETAIL_SUCCESS_ACTION_TYPE, TipsReportAction } from '../../actions/tipsReport/TipsReportActionsTypes';

const initialState: TipsReportState = {
    invoices: [],
    status: TipsReportStatus.Initial,
    loading: false,
  };

  export const TipsReportReducer: Reducer<TipsReportState, TipsReportAction> = (state = initialState, action) => {
      switch(action.type) {
        case TIPSREPORT_GET_DETAIL_START_ACTION_TYPE:
        return {
            ...state,
            status: TipsReportStatus.Getting,
            loading: true
        };
        case TIPSREPORT_GET_DETAIL_SUCCESS_ACTION_TYPE:
        return {
            ...state,
            invoices: action.payload.invoices,
            totalAppointments: action.payload.totalAppointments,
            totalTips: action.payload.totalTips,
            status: TipsReportStatus.GettingSuccess,
            loading: false
        };
        default:
            return state;
    }
}