import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import ToolbarItemWithConfirmation from "./ToolbarItemWithConfirmation";

export interface Props {
  id?: string;
  onConfirmedClick: () => void;
  label?: string;
  confirmTitle?: JSX.Element;
  confirmQuestion?: JSX.Element;
  confirmSubquestion?: JSX.Element;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      fontFamily: "Poppins",
      fontWeight: 400,
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
      },
    },
  })
);

export const DeleteToolbarItem: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <ToolbarItemWithConfirmation
      id={props.id}
      icon={faTrashAlt}
      text={props.label ? props.label : "Delete Appt"}
      onConfirmedClick={props.onConfirmedClick}
      title={props.confirmTitle}
      confirmQuestion={
        props.confirmQuestion ? (
          props.confirmQuestion
        ) : (
          <Typography className={classes.question}>
            Are you sure you want to <strong>Delete</strong>?
          </Typography>
        )
      }
      confirmSubquestion={props.confirmSubquestion}
      confirmButtonLabel="Delete"
      cancelButtonLabel="Cancel"
    />
  );
};

export default DeleteToolbarItem;
