import { faPen, faTimes, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import { HourField } from 'components/UI';
import { FunctionComponent, useMemo, useState } from 'react';
import { lgPixel, mdPixel, xlPixel } from 'utils/VariableUtils';
import { SwitchComponent } from './CloseSwitch';
import SpecialHourDate from './SpecialHourDate';
import { SpecialHourWrapper } from './SpecialHours';
import moment from 'moment-timezone';
import { SpecialHourForm } from './SpecialHourForm';
import { SpecialHour as SpecialHourType } from '@spike/model';
import Switch from 'components/UI/V2/Switch';

export interface SpecialHourProps {
	specialHourWrapper: SpecialHourWrapper;
	onChange: (specialHourWrapper: SpecialHourWrapper) => void;
	onDelete: (specialHourUuid: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: '25px 0px',
			borderBottom: '1px solid #F1F1F1'
		},
		bodyContainer: {
			gap: 20,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		closedText: {
			[theme.breakpoints.down('md')]: {
				fontSize: mdPixel(16),
				lineHeight: mdPixel(23.64)
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: lgPixel(16),
				lineHeight: lgPixel(23.64)
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: xlPixel(16),
				lineHeight: xlPixel(23.64)
			}
		},
		switchContainer: {
			gap: 12,
			display: 'flex',
			alignItems: 'center'
		},
		toContainer: {
			display: 'flex',
			justifyContent: 'center',
			padding: '0px 5px'
		},
		to: {
			textAlign: 'center',
			[theme.breakpoints.down('md')]: {
				fontSize: '9px',
				lineHeight: '14px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '12px',
				lineHeight: '18px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '18px',
				lineHeight: '27px'
			}
		},
		openCloseContainer: {
			display: 'flex',
			alignItems: 'center',
			position: 'relative'
		},
		dateSwitchContainer: {
			gap: 10,
			display: 'flex'
		},
		deleteContainer: {
			display: 'flex',
			cursor: 'pointer'
		},
		error: {
			color: '#EA6464'
		},
		errorMessage: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '10px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '12px'
			}
		}
	})
);

const SpecialHourDesktop: FunctionComponent<SpecialHourProps> = props => {
	const classes = useStyles();

	const changeNameHandler = (name: string) => {
		props.onChange({
			...props.specialHourWrapper,
			specialHour: { ...props.specialHourWrapper.specialHour, name }
		});
	};

	const changeDateHandler = (date: string) => {
		props.onChange({
			...props.specialHourWrapper,
			specialHour: { ...props.specialHourWrapper.specialHour, date }
		});
	};

	const changeCloseHandler = () => {
		props.onChange({
			...props.specialHourWrapper,
			specialHour: {
				...props.specialHourWrapper.specialHour,
				isClosed: !props.specialHourWrapper.specialHour.isClosed,
				businessHours: props.specialHourWrapper.specialHour.isClosed
					? { open: '8:00', close: '17:00' }
					: undefined
			}
		});
	};

	const changeOpenHourHandler = (value24Hs: string) => {
		props.onChange({
			...props.specialHourWrapper,
			specialHour: {
				...props.specialHourWrapper.specialHour,
				businessHours: {
					...props.specialHourWrapper.specialHour.businessHours!,
					open: value24Hs
				}
			}
		});
	};

	const changeCloseHourHandler = (value24Hs: string) => {
		props.onChange({
			...props.specialHourWrapper,
			specialHour: {
				...props.specialHourWrapper.specialHour,
				businessHours: {
					...props.specialHourWrapper.specialHour.businessHours!,
					close: value24Hs
				}
			}
		});
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.bodyContainer}>
				<Box className={classes.dateSwitchContainer}>
					<Box>
						<SpecialHourDate
							date={
								props.specialHourWrapper.specialHour.date || ''
							}
							label={
								props.specialHourWrapper.specialHour.name ||
								'Description'
							}
							onChangeLabel={changeNameHandler}
							onDateChange={changeDateHandler}
							name={
								props.specialHourWrapper.specialHour.name ||
								'Description'
							}
							error={props.specialHourWrapper.error}
						/>
					</Box>
					<Box className={classes.switchContainer}>
						<Switch
							id={`business_settings_schedules_switch_special_hours`}
							checked={
								!props.specialHourWrapper.specialHour.isClosed
							}
							name={'Closed'}
							onCheck={changeCloseHandler}
						/>
						<Typography
							className={classes.closedText}
							noWrap={true}
						>
							{props.specialHourWrapper.specialHour.isClosed
								? 'Closed'
								: 'Open'}
						</Typography>
					</Box>
				</Box>
				{!props.specialHourWrapper.specialHour.isClosed && (
					<Box className={classes.openCloseContainer}>
						<Box>
							<HourField
								id="business_settings_special_hour_input_open_hour"
								value24Hs={
									props.specialHourWrapper.specialHour
										.businessHours?.open
								}
								onChange={changeOpenHourHandler}
								error={props.specialHourWrapper.error}
							/>
						</Box>
						<Box className={classes.toContainer}>
							<Typography
								className={clsx(classes.to, {
									[classes.error]:
										props.specialHourWrapper.error
								})}
							>
								to
							</Typography>
						</Box>
						<Box>
							<HourField
								id="business_settings_special_hour_input_close_hour"
								value24Hs={
									props.specialHourWrapper.specialHour
										.businessHours?.close
								}
								onChange={changeCloseHourHandler}
								error={props.specialHourWrapper.error}
							/>
						</Box>
					</Box>
				)}
				<Box
					className={classes.deleteContainer}
					onClick={() =>
						props.onDelete(
							props.specialHourWrapper.specialHour.uuid
						)
					}
				>
					<FontAwesomeIcon icon={faTimes} />
				</Box>
			</Box>
			<Box>
				{props.specialHourWrapper.error && (
					<Typography
						className={clsx(classes.errorMessage, classes.error)}
					>
						{props.specialHourWrapper.errorMessage}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

const useMobileStyles = makeStyles(() =>
	createStyles({
		root: {
			'alignItems': 'flex-start',
			'padding': '15px 0px 20px',

			'&:not(:first-child)': {
				borderTop: '1px solid #D4D4D4'
			}
		},
		hourName: {
			fontSize: 16,
			lineHeight: 1,
			color: '#7A7A7A'
		},
		copy: {
			'marginTop': 9,
			'fontSize': 15,
			'lineHeight': 1,

			'& b': {
				fontWeight: 500
			}
		},
		closedBadge: {
			color: '#fff',
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 500,
			borderRadius: 6,
			marginRight: 10,
			padding: '6px 9px',
			display: 'inline-block',
			backgroundColor: '#EF4F57'
		},
		actions: {
			'display': 'flex',
			'flexWrap': 'wrap',
			'alignItems': 'center',

			'& button': {
				all: 'unset',
				width: 38,
				height: 38,
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center'
			}
		},
		modal: {},
		modalBody: {
			'width': '100%',
			'padding': '24px 16px',

			// title
			'& h5': {
				'fontSize': 20,
				'fontWeight': 600,
				'marginBottom': 39,

				'& svg': {
					marginRight: 10,
					verticalAlign: 'middle'
				}
			}
		},
		modalFooter: {
			'width': '100%',
			'marginTop': 'auto',
			'padding': '16px 24px',

			'& button': {
				'height': 47,
				'width': '100%',
				'borderRadius': 30,

				'& span': {
					fontSize: 16,
					fontWeight: 600
				}
			}
		},
		scheduleCol: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		openToggle: {
			'display': 'flex',
			'alignItems': 'center',

			'& p': {
				fontSize: 16,
				fontWeight: 500,
				marginRight: 9
			}
		},
		schedulePicker: {
			gap: 8,
			display: 'flex',
			alignItems: 'center'
		},
		error: {
			color: '#EA6464'
		}
	})
);

export const SpecialHourMobile: FunctionComponent<SpecialHourProps> = props => {
	const classes = useMobileStyles();

	const [isEditing, setIsEditing] = useState(false);

	const openHour = useMemo(() => {
		const momentHour = moment(
			props.specialHourWrapper.specialHour.businessHours?.open,
			['HH:mm']
		);

		if (momentHour.isValid()) {
			return momentHour.format('h:mm a');
		}

		return null;
	}, [props.specialHourWrapper.specialHour.businessHours?.open]);

	const closeHour = useMemo(() => {
		const momentHour = moment(
			props.specialHourWrapper.specialHour.businessHours?.close,
			['HH:mm']
		);

		if (momentHour.isValid()) {
			return momentHour.format('h:mm a');
		}

		return null;
	}, [props.specialHourWrapper.specialHour.businessHours?.close]);

	const date = useMemo(() => {
		const momentDate = moment(props.specialHourWrapper.specialHour.date);

		if (momentDate.isValid()) {
			return momentDate.format('MM/DD/YYYY');
		}

		return null;
	}, [props.specialHourWrapper.specialHour.date]);

	const deleteHandler = () => {
		props.onDelete(props.specialHourWrapper.specialHour.uuid);
	};

	const saveHandler = (specialHour: SpecialHourType) => {
		props.onChange({
			...props.specialHourWrapper,
			specialHour
		});

		setIsEditing(false);
	};

	return (
		<>
			<Grid container className={classes.root}>
				<Grid item xs>
					<Typography
						className={clsx(classes.hourName, {
							[classes.error]: props.specialHourWrapper.error
						})}
					>
						{props.specialHourWrapper.specialHour.name ||
							'Description'}
					</Typography>

					{props.specialHourWrapper.errorMessage && (
						<Typography
							className={clsx(classes.copy, {
								[classes.error]: props.specialHourWrapper.error
							})}
						>
							Error: {props.specialHourWrapper.errorMessage}
						</Typography>
					)}

					<Typography
						className={clsx(classes.copy, {
							[classes.error]: props.specialHourWrapper.error
						})}
					>
						<b>{date || 'mm/dd/yyyy'}</b>
					</Typography>

					{openHour && closeHour && (
						<Typography
							className={clsx(classes.copy, {
								[classes.error]: props.specialHourWrapper.error
							})}
						>
							<b>{openHour}</b> to&nbsp;
							<b>{closeHour}</b>
						</Typography>
					)}
				</Grid>
				<Grid item xs="auto" className={classes.actions}>
					{props.specialHourWrapper.specialHour.isClosed && (
						<span className={classes.closedBadge}>Closed</span>
					)}

					<button
						type="button"
						aria-label="Edit"
						onClick={() => setIsEditing(true)}
					>
						<FontAwesomeIcon icon={faPen} />
					</button>
					<button
						type="button"
						aria-label="Edit"
						onClick={deleteHandler}
					>
						<FontAwesomeIcon icon={faTrashCan} />
					</button>
				</Grid>
			</Grid>

			{isEditing && (
				<SpecialHourForm
					specialHour={props.specialHourWrapper.specialHour}
					onClose={() => setIsEditing(false)}
					onSave={saveHandler}
				/>
			)}
		</>
	);
};

const SpecialHour: FunctionComponent<SpecialHourProps> = props => {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	return isMobile ? (
		<SpecialHourMobile {...props} />
	) : (
		<SpecialHourDesktop {...props} />
	);
};

export default SpecialHour;
