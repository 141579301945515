import {
	Box,
	createStyles,
	FormControl,
	FormControlLabel,
	makeStyles,
	Radio,
	RadioGroup,
	Theme,
	Typography
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import React, { FunctionComponent, useState } from 'react';
import moment, { Moment } from 'moment';
import DateSelector from './DateSelector';
import clsx from 'clsx';

interface Props {
	haveReport?: boolean;
	isReport: boolean;
	isDental: boolean;
	section: string;
	nextDentalDate?: Date | null;
	className?: string;
	onChange: (value: Date | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			[theme.breakpoints.down('sm')]: {
				padding: '0px 16px'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		dateContainer: {
			'& .MuiOutlinedInput-input': {
				textAlign: 'center'
			},

			'fontSize': '14px',
			'borderRadius': '20px',
			'height': '40px'
		},
		radioGroup: {
			'& .MuiRadio-colorSecondary.Mui-checked': {
				color: '#222'
			},
			'& .MuiFormControlLabel-label': {
				fontSize: 15,
				fontWeight: 500
			}
		}
	})
);

export const NextDentalDate: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const [nextDentalDate, setNextDentalDate] = useState<string | null>(
		props.haveReport ? `${moment(props.nextDentalDate).calendar()}` : null
	);

	const fourWeeks = `${moment().add(4, 'weeks').calendar()}`;
	const sixWeeks = `${moment().add(6, 'weeks').calendar()}`;
	const twoMonths = `${moment().add(2, 'months').calendar()}`;
	const threeMonths = `${moment().add(3, 'months').calendar()}`;
	const fourMonths = `${moment().add(4, 'months').calendar()}`;
	const sixMonths = `${moment().add(6, 'months').calendar()}`;
	const oneYear = `${moment().add(1, 'year').calendar()}`;

	const handleChange = (e: any) => {
		const newDate = e.target.value.trim().replace(/ /g, '');
		setNextDentalDate(newDate);
	};

	useNonInitialEffect(() => {
		props.onChange(moment(nextDentalDate).toDate());
		// eslint-disable-next-line
	}, [nextDentalDate]);

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Typography className={classes.titleSection}>
				{props.section}
			</Typography>

			{!props.haveReport && props.isReport ? (
				<FormControl component="fieldset">
					<RadioGroup
						row
						aria-label="nextVisit"
						value={nextDentalDate}
						onChange={handleChange}
						className={classes.radioGroup}
					>
						<FormControlLabel
							value={props.isDental ? threeMonths : fourWeeks}
							control={<Radio />}
							label={props.isDental ? '3 Months' : '4 Weeks'}
						/>
						<FormControlLabel
							value={props.isDental ? fourMonths : sixWeeks}
							control={<Radio />}
							label={props.isDental ? '4 Months' : '6 Weeks'}
						/>
						<FormControlLabel
							value={props.isDental ? sixMonths : twoMonths}
							control={<Radio />}
							label={props.isDental ? '6 Months' : '2 Months'}
						/>
						<FormControlLabel
							value={props.isDental ? oneYear : threeMonths}
							control={<Radio />}
							label={props.isDental ? '1 Year' : '3 Months'}
						/>
					</RadioGroup>
				</FormControl>
			) : props.haveReport && props.isReport ? (
				<DateSelector
					disabled={!props.isReport}
					haveReport={props.haveReport}
					placeholder={'MM / DD / YYYY'}
					date={
						props.haveReport
							? moment(props.nextDentalDate)
							: undefined
					}
					onChange={(date?: Moment) => setNextDentalDate(`${date}`)}
				/>
			) : (
				<Typography style={{ fontWeight: 500 }}>
					{`${moment(props.nextDentalDate).format('MM/DD/YYYY')}`}
				</Typography>
			)}
		</Box>
	);
};

export default NextDentalDate;
