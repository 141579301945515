import { Product } from "@spike/product-model";
import { v4 as createUuid } from "uuid";

export default interface ProductCategory {
  id?: number;
  uuid: string;
  name: string;
  description: string;
  products: Array<Product>;
  custom: boolean;
  active: boolean;
}

export const createEmptyCategory = (): ProductCategory => {
  return {
    uuid: createUuid(),
    name: "",
    description: "",
    products: [],
    custom: true,
    active: true
  };
};

export enum SortFields {
  CATEGORY = "category",
  PRODUCTS = "products",
  STATUS = "status"
}

export enum SortOrder {
  ASCENDANT = "ascendant",
  DESCENDANT = "descendant",
  CREATION_DATE = "creationDate"
}
