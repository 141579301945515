import React, { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import { useFieldStyles } from "components/UI/FieldStyles";
import LabelField from "components/UI/LabelField";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";

export interface SelectPetsProps {
  id?: string;
	label?: string | JSX.Element;
	name?: string;
	selectedOption?: Option<string>;
	placeholder?: string;
	options: Array<Option<string>>;
	disabled?: boolean;
	className?: string;
	required?: boolean;
	information?: string;
	onSelect: (option: Option<string>, name?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 110,
    },
    dropdownContainer: {
      position: "relative",
      height: 54
    },
    collapsedContainer: {
      height: "100%",
    },
    expandedContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #222222 !important",
      paddingBottom: 30,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 33
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: 49,
      cursor: "pointer",
    },
    header: {
      display: "flex",
      alignItems: "center",
      color: "#000000",
      paddingLeft: 17,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 19
      },
    },
    listContainer: {
      borderTop: "1px solid #222222",
      overflowY: "auto",
      height: "auto",
      maxHeight: 200,
      paddingTop: 13,
      [theme.breakpoints.up('md')]: {
        paddingTop: 14
      },
    },
    placeholder: {
      color: "#7A7A7A",
      fontSize: 16,
      lineHeight: "27px"
    },
    border: {
      border: "1px solid #222222",
      borderRadius: 27,
      [theme.breakpoints.up('md')]: {
        borderRadius: 30
      },
    },
    textContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      paddingRight: "10px",
    },
    chevronContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      paddingRight: 32,
      [theme.breakpoints.up('md')]: {
        paddingRight: 35
      },
    },
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 33,
      "&:hover": {
        background: "#F8F8F8",
      },
      cursor: "pointer",
      paddingLeft: 20
    },
    item: {
      fontSize: 16,
      lineHeight: "16px",
    },
    optionNotSelected: {
      border: "1px solid #D3D3D3 !important",
    },
    chevron: {
      fontSize: 16
    }
  })
);

export const SelectAppointmentFrequency: FunctionComponent<SelectPetsProps> = (props) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const clickCollapseHandler = () => {
    setIsCollapsed((prev) => !prev);
  };

	const changeHandler = (
		idSelected: string
	) => {
		props.onSelect(
			props.options.find((option) => option.id === idSelected)!,
			props.name
		);
	};

  const header = (
    <Box className={classes.headerContainer}>
      <Box className={classes.textContainer}>
        <Typography className={clsx(classes.header, { [classes.placeholder]: !props.selectedOption })}>
          {props.selectedOption ? props.selectedOption.name : props.placeholder}
        </Typography>
      </Box>
      <Box className={classes.chevronContainer}>
        <FontAwesomeIcon 
          icon={isCollapsed ? faChevronDown : faChevronUp} 
          color={isCollapsed ? "#7A7A7A" : "#222222"}
          className={classes.chevron}
        />
      </Box>
    </Box>
  );

  const list = (
    <Box className={classes.listContainer}>
      {props.options.map((opt) => (
        <Box 
          className={classes.itemContainer} 
          key={opt.id} 
          id={`booking_option_frequency_${opt.id}`}
          onClick={() => changeHandler(opt.id)}
        >
          <Box className={classes.item}>
            {opt.name} {opt.description && opt.description}
          </Box>
        </Box>
      ))}
    </Box>
  );

  const collapsed = (
    <Box
      className={clsx(classes.border, classes.collapsedContainer, [classes.optionNotSelected])}
      onClick={clickCollapseHandler}
    >
      {header}
    </Box>
  );

  const expanded = (
    <Box
      className={clsx(classes.border, classes.expandedContainer)}
      onClick={clickCollapseHandler}
    >
      {header}
      {list}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={() => setIsCollapsed(true)}>
      <Box className={clsx(classes.container, fieldClasses.container, props.className)}>
        <LabelField {...props} />
        <Box className={classes.dropdownContainer} id="booking_list_frequency">{isCollapsed ? collapsed : expanded}</Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SelectAppointmentFrequency;
