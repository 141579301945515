import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { iconSelector, IconVariantType } from './model';

interface InfoProps {
    className?: string;
    icon: IconVariantType;
    text?: string;
    textComponent?: ReactNode;
    backgroundColor?: string;
    color?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: (props: InfoProps) => props.backgroundColor || '#F8F5F1',
            border: (props: InfoProps) => `2px solid ${props.color || '#BAA997'}`,
            borderRadius: 10,
            padding: '12px 46px 12px 16px'
        },
        icon: {
            alignSelf: 'flex-start',
            marginRight: 10,
            fontSize: 24,
            paddingTop: 4
        },
        text: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#222222',
            maxWidth: '90%'
        }
    })
);

const Info: FunctionComponent<InfoProps> = props => {
    const classes = useStyles(props);

    return (
        <Box className={clsx(classes.container, props.className)}>
            <FontAwesomeIcon
                icon={iconSelector.get(props.icon)!}
                className={classes.icon}
                color={props.color || '#BAA997'}
            />
            {props.text && <Typography className={classes.text}>{props.text}</Typography>}
            {props.textComponent}
        </Box>
    );
};

export default Info;
