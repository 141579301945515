import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Button, Checkbox } from "components/UI";
import { fetchThunk  as fetchCategoriesThunk } from "actions/productCategories/ProductCategoriesActions";
import { RootState } from "store";
import AssignProductsHeader from "./AssignProductsHeader";
import { SelectableOption } from "model";
import { Product } from "@spike/product-model";
import { Option } from "@spike/model";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { fetchProductsThunk } from "@spike/products-action";
import { deleteProductsThunk, fetchThunk as fetchVendorsThunk, saveProductsThunk } from "actions/productVendors/ProductVendorsActions";
import ProductVendor from "model/ProductVendor";
import { ProductVendorsStatus } from "reducers/productVendors/ProductVendorsState";
import { ProductsState } from "@spike/products-action";
import { useApiClientWrapper } from "hooks";

interface Props {
  vendor: ProductVendor;
  onClose: (vendor?: ProductVendor) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("md")]: {
        height: "37px"
      },
      [theme.breakpoints.up("lg")]: {
        height: "46px"
      },
      " & .MuiTypography-h6": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("md")]: {
          paddingLeft: "43px",
          paddingTop: "11px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingLeft: "43px",
          paddingTop: "11px",
        },
      },
    },
    title: {
      fontWeight: 600,
      color: "#5E8677",
      letterSpacing: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "24px"
      },
    },
    container: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("md")]: {
          width: "582px",
          minWidth: "582px",
          height: "614px",
          borderRadius: "19px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "728px",
          minWidth: "728px",
          height: "768px",
          borderRadius: "24px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        [theme.breakpoints.down("md")]: {
          padding: "35px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "44px",
        },
      }
    },
    dialogContainer: {
      width: "100%",
      "& .MuiDialog-paper": {
        overflowY: "hidden"
      },
      display: "flex",
      flexDirection: "column",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        marginTop: "42px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "52px",
      },
    },
    button: {
      [theme.breakpoints.down("md")]: {
        width: "136px",
        height: "43px",
        "& .MuiButton-label": {
          fontSize: "15px"
        }
      },
      [theme.breakpoints.up("lg")]: {
        width: "170px",
        height: "54px",
        "& .MuiButton-label": {
          fontSize: "18px"
        }
      },
    },
    productsContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        height: "320px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "400px",
      },
    },
    check: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "24px",
      },
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("md")]: {
          fontSize: "19px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "24px",
        },
      }
    },
    label: {
      [theme.breakpoints.down("md")]: {
        fontSize: "58px",
        paddingBottom: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        paddingBottom: "24px",
      },
    },
    checked: {
      fontWeight: 600
    },
    checkboxContainer: {
      display: "flex"
    },
    productsScrollContainer: {
      [theme.breakpoints.down("md")]: {
        height: "320px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "400px",
      },
    },
    iconTimes: {
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer"
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        lineHeight: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        lineHeight: "24px"
      },
    },
    clearAll: {
      fontWeight: 600,
      cursor: "pointer",
      color: "#BCB8AE",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        lineHeight: "19px",
        marginRight: "34px",
        marginTop: "6px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        lineHeight: "24px",
        marginRight: "30px",
        marginTop: "8px"
      },
    },
    emptyProductsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    emptyProducts: {
      fontWeight: 600,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "22px",
      },
    },
    marginButton: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "10px",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "12px",
      },
    },
    buttons: {
      display: "flex"
    }
  })
);

export const AssignProductsComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const products = useSelector<RootState, ProductsState>(state => state.products);
  const status = useSelector<RootState, ProductVendorsStatus>(state => state.productVendors.status);
  const loading = useSelector<RootState, boolean>((state) => state.productVendors.loading);

  const [vendor, setVendor] = useState<ProductVendor>(props.vendor);
  const [productsSelectable, setProductsSelectable] = useState<Array<SelectableOption<number>>>([]);
  const [productsOriginal, setProductsOriginal] = useState<Array<SelectableOption<number>>>([]);
  const [productNumber, setProductNumber] = useState(productsSelectable.length);

  useEffect(() => {
    if (status === ProductVendorsStatus.SaveProductsSuccess || status === ProductVendorsStatus.DeleteProductsSuccess) {
      dispatch(fetchProductsThunk(apiClientWrapper));   
      dispatch(fetchVendorsThunk());
      dispatch(fetchCategoriesThunk());
      props.onClose(vendor);
    }
  }, [status]);

  useEffect(() => {
    let productsAux: Array<SelectableOption<number>> = [];

    if(products) {
      let productCount = 0;
      products.list.forEach((product) => {
        const productFounded = props.vendor.products.find((prod) => prod.id === product.id) ? true : false;
        productsAux.push({
          element: {id: product.id!, name: product.businessProduct?.name ? product.businessProduct.name : product.name, description: product.code},
          selected: productFounded
        })
        if(productFounded) {
          productCount++;
        }
      })
      setProductNumber(productCount);
      setProductsSelectable(productsAux);
      setProductsOriginal(productsAux);
    }
  }, [products]);

  const saveVendorHandler = () => {
    let productsToAdd: Array<Product> = [];
    let productsToDelete: Array<Product> = [];
    let vendorAddAux = {...vendor};
    let vendorDeleteAux = {...vendor};

    vendor.products.forEach((p) => {
      let vendorFounded = props.vendor.products.find((pc) => pc.id === p.id);
      let productFounded = products.list.find((prod) => prod.id === p.id);
      if(!vendorFounded && productFounded) {
        productsToAdd.push(p);
      }
    });

    if(productsToAdd.length > 0) {
      vendorAddAux.products = productsToAdd;
      dispatch(saveProductsThunk(vendor));
    }

    props.vendor.products.forEach((p) => {
      let vendorFounded = vendor.products.find((pc) => pc.id === p.id);
      let productFounded = products.list.find((prod) => prod.id === p.id);
      if(!vendorFounded && productFounded) {
        productsToDelete.push(p);
      }
    });

    if(productsToDelete.length > 0) {
      vendorDeleteAux.products = productsToDelete;
      dispatch(deleteProductsThunk(vendorDeleteAux));
    }
  };

  const handleChange = (value: Option<number>, selected: boolean) => {
    const newSelectableProducts = [...productsSelectable];
    const newProducts:Array<Product> = [];
    let productCount = 0;

    newSelectableProducts.forEach((prod) => {
      if(prod.element.id === value.id) {
        prod.selected = selected;

      }
      if(prod.selected) {
        let productAux = products.list.find((p) => p.id === prod.element.id);
        if(productAux) {
          newProducts.push(productAux);
        }
        productCount++;
      }
    });

    setProductsSelectable(newSelectableProducts);
    setProductNumber(productCount)
    setVendor((prevVendor: any) => {
      return {
        ...prevVendor,
        products: newProducts,
      };
    });
  };

  const handleSearchProducts = (searchText: string) => {
    let productListFiltered: Array<SelectableOption<number>> = [];

    if(searchText) {
      const prodcutListAux = productsSelectable.filter((item) => {
        const name = `${item.element.name.toLowerCase()}`;
        const barcode = item.element.description ? `${item.element.description.toLowerCase()}` : "";
        return (
          searchText.length === 0 || name.indexOf(searchText.toLowerCase()) > -1 ||
          barcode.indexOf(searchText.toLowerCase()) > -1
        );
      });
      productListFiltered = prodcutListAux;
    } 
    else {
      productListFiltered = productsOriginal;
    }
    setProductsSelectable(productListFiltered);
  };

  const handleClearAll = () => {
    const newSelectableProducts = [...productsSelectable];
    const newProducts:Array<Product> = [];
    let productCount = 0;

    newSelectableProducts.forEach((prod) => {
        prod.selected = false;
    });

    setProductsSelectable(newSelectableProducts);
    setProductNumber(productCount);
    setVendor((prevCategory: any) => {
      return {
        ...prevCategory,
        products: newProducts,
      };
    });
  };
  
  return (<Box>
    <Dialog open={true} className={clsx(classes.container)}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Assign Products to Vendor</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <AssignProductsHeader 
          vendor={vendor!} 
          productNumber={productNumber}
          onSearch={handleSearchProducts}
        />
        {productsSelectable.length > 0 ? <OverlayScrollbarsComponent className={classes.productsScrollContainer}>
          <Box className={classes.productsContainer}>
            {productsSelectable.map((product) => {
              return (<Box className={classes.checkboxContainer} key={product.element.id}>
                  <Checkbox
                    label=""
                    checked={product.selected}
                    onChange={() =>
                      handleChange(product.element, !product.selected)}
                    className={clsx(classes.check, {
                      [classes.checked]: product.selected
                    })}
                  />
                  <Typography className={clsx(classes.label, {
                      [classes.checked]: product.selected
                    })}>{product.element.name}</Typography>
                </Box>)
            })}
          </Box>
        </OverlayScrollbarsComponent> 
        : 
        <Box className={clsx(classes.productsScrollContainer, classes.emptyProductsContainer)}>
          <Typography className={classes.emptyProducts}>There are no products to be assigned.</Typography>
        </Box>
        }
        <Box className={classes.buttonsContainer}>
          <Box className={classes.buttons}>
            <Typography onClick={handleClearAll} className={classes.clearAll}>clear all</Typography>
          </Box>
          <Box className={classes.buttons}>
            <Button
              label="Cancel"
              onClick={() => props.onClose()}
              variant="secondary"
              size="medium"
              color="orange"
              className={classes.button}
            />
            {productsSelectable.length > 0 && <Button
              label="Save"
              loading={loading}
              onClick={saveVendorHandler}
              variant="primary"
              size="medium"
              color="orange"
              className={clsx(classes.button, classes.marginButton)}
            />}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  </Box>);
};

export default AssignProductsComponent;
