import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import QuillContent from 'components/UI/QuillContent';
import { useMasterData } from 'hooks';
import { Agreement } from 'model/Agreements';
import { FunctionComponent } from 'react';
import { formatDate } from '../utils';

interface AgreementInfoProps {
	agreement?: Agreement;
}

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'paddingTop': 35,
			'& label': {
				fontSize: 18,
				lineHeight: 1,
				fontWeight: 600,
				marginBottom: 18,
				display: 'block'
			}
		},
		infoItem: {
			'color': '#222',
			'display': 'inline-block',
			'width': 200,
			'& p': {
				fontSize: 16,
				lineHeight: 1
			}
		},
		info: {
			marginBottom: 55,
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			gap: 40,
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				gap: 24
			}
		},
		content: {
			borderRadius: 12,
			padding: '25px 20px',
			backgroundColor: '#fff',
			border: 'solid 1px #d4d4d4'
		}
	})
);

export const AgreementInfo: FunctionComponent<AgreementInfoProps> = props => {
	const classes = useStyle();

	const sendToOptions = useMasterData().agreementsSendTo;

	return (
		<Box className={classes.root}>
			<Box className={classes.info}>
				<Box className={classes.infoItem}>
					<label>Date Created</label>
					<Typography>
						{formatDate(props.agreement?.createdAt)}
					</Typography>
				</Box>
				<Box className={classes.infoItem}>
					<label>Modified</label>
					<Typography>
						{formatDate(props.agreement?.updatedAt)}
					</Typography>
				</Box>
				<Box className={classes.infoItem}>
					<label>Required</label>
					<Typography>
						{
							sendToOptions.find(
								option => option.id === props.agreement?.sendTo
							)?.name
						}
					</Typography>
				</Box>
			</Box>

			<Box>
				<label>Agreement Content</label>
				<QuillContent
					content={props.agreement?.content || ''}
					className={classes.content}
				/>
			</Box>
		</Box>
	);
};

export default AgreementInfo;
