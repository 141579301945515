import isEmpty from 'lodash/isEmpty';
import {
    isEmailValid,
    validatePhoneNumber,
    validateAddress
} from '@spike/validations';
import { FieldError, Country } from '@spike/model';
import { Client, Contact } from '@spike/client-model';

export const validateClient = (
    client: Client,
    country: Country
): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (isEmpty(client.firstName)) {
        errors.push({
            fieldName: 'firstName',
            errorMessage: 'First name is required'
        });
    }

    if (isEmpty(client.lastName)) {
        errors.push({
            fieldName: 'lastName',
            errorMessage: 'Last name is required'
        });
    }

    if (!client.email || isEmpty(client.email.trim())) {
        if (client.temporalEmail) {
            if (!client.phone || isEmpty(client.phone)) {
                errors.push({
                    fieldName: 'email',
                    errorMessage: 'Email or phone is required'
                });
            }
        } else {
            isEmpty(client.phone) &&
                errors.push({
                    fieldName: 'email',
                    errorMessage: 'Email or phone is required'
                });
        }
    }

    if (client.email.trim().length > 0 && !isEmailValid(client.email)) {
        errors.push({
            fieldName: 'email',
            errorMessage: 'Email incorrect format'
        });
    }

    if (
        client.temporalEmail &&
        isEmpty(client.email) &&
        isEmpty(client.phone)
    ) {
        errors.push({
            fieldName: 'phone',
            errorMessage: 'Phone or email is required'
        });
    }

    client.phone &&
        !isEmpty(client.phone) &&
        errors.push(...validatePhoneNumber(client.phone, country.id, 'phone'));

    return [...errors, ...validateAddress(client.address)];
};

export const validateContact = (
    contact: Contact,
    country: Country
): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (isEmpty(contact.firstName)) {
        errors.push({
            fieldName: 'firstName',
            errorMessage: 'First name is required'
        });
    }

    if (isEmpty(contact.lastName)) {
        errors.push({
            fieldName: 'lastName',
            errorMessage: 'Last name is required'
        });
    }

    if (isEmpty(contact.phone)) {
        errors.push({
            fieldName: 'phone',
            errorMessage: 'Phone is required'
        });
    }

    !isEmpty(contact.phone) &&
        errors.push(...validatePhoneNumber(contact.phone, country.id, 'phone'));

    if (contact.relationship === undefined) {
        errors.push({
            fieldName: 'relationship',
            errorMessage: 'Relationship is required'
        });
    }

    return errors;
};
