import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import clsx from "clsx";

import arrow from '../../assets/images/arrow-left.svg';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        top: 0,
        left: '5%',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        [theme.breakpoints.up("md")]: {
            display: 'none'
        }
    },
    img: {
        width: 40,
        [theme.breakpoints.down("md")]: {
            width: 23
        }
    }
}))

export const HeaderBackLink: FunctionComponent<LinkProps> = (props) => {
    const classes = useStyles()

    return (
        <Link {...props} className={clsx(classes.root, props.className)}>
            <img src={arrow} alt="Arrow left" className={classes.img} />
        </Link>
    );
}

export default HeaderBackLink;