import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Img } from "react-image";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import Button from "components/UI/V2/Button/Button";

interface Props {
  disableAddButton: boolean;
  onAddService: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
    },
    image: {
      [theme.breakpoints.down("lg")]: {
        width: "367px",
        height: "215px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "489px",
        height: "287px",
      },
    },
    title: {
      textAlign: "center",
      fontWeight: 700,
      [theme.breakpoints.down("lg")]: {
        marginTop: "17px",
        lineHeight: "27px",
        fontSize: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "22px",
        lineHeight: "36px",
        fontSize: "26px",
      },
    },
    description: {
      textAlign: "center",
      fontWeight: 500,
      [theme.breakpoints.down("lg")]: {
        marginTop: "17px",
        lineHeight: "20px",
        fontSize: "16px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "22px",
        lineHeight: "27px",
        fontSize: "18px",
      },
    },
    button: {
      [theme.breakpoints.down("lg")]: {
        marginTop: "26px",
        marginBottom: "26px",
        width: "160px",
        height: "41px",
        fontSize: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "34px",
        marginBottom: "34px",
        width: "213px",
        height: "55px",
        fontSize: "18px",
      },
    }
  })
);

export const ServicesNotCreated: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Img className={classes.image} src="/images/services/EmptyServices.svg"/>
      <Typography className={classes.title}>Bark.</Typography>
      <Typography className={classes.description}>
        You don't have any services setup yet.
      </Typography>
      <Button
        leftIcon={faPlus}
        label="Add Service"
        id="services_empty_button_add_service"
        className={classes.button}
        onClick={props.onAddService} disabled={props.disableAddButton}
      />
    </Box>
  );
};

export default ServicesNotCreated;
