import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface PercentageChipProps {
  percentage: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(54)}px`,
        height: `${reduceResolution(26)}px`,
        borderRadius: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "54px",
        height: "26px",
        borderRadius: "13px",
      },
    },
    percentage: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "white",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },
  })
);

export const PercentageChip: FunctionComponent<PercentageChipProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.percentage}>{props.percentage}%</Typography>
    </Box>
  );
};

export default PercentageChip;
