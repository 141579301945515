import clsx from 'clsx';
import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

type UnderlinedButtonColorsType = 'green' | 'orange';

type UnderlinedButtonProps = Exclude<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	'children'
> & {
	label: string;
	color: UnderlinedButtonColorsType;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
};

export const colorMap: Record<UnderlinedButtonColorsType, string> = {
	green: '#92B4A7',
	orange: '#EAB464'
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: (props: UnderlinedButtonProps) => ({
			gap: 4,
			border: 0,
			padding: 0,
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 500,
			alignItems: 'center',
			fontFamily: 'Poppins',
			display: 'inline-flex',
			backgroundColor: '#fff',

			color: colorMap[props.color],

			[theme.breakpoints.up('md')]: {
				fontSize: 16
			}
		}),
		icon: {
			fontSize: 13
		},
		label: {
			textUnderlineOffset: '5px',
			textDecoration: 'underline'
		}
	})
);

export const UnderlinedButton: React.FunctionComponent<
	UnderlinedButtonProps
> = props => {
	// eslint-disable-next-line
	const { label, leftIcon, rightIcon, className, ...buttonProps } = props;

	const classes = useStyles(props);

	return (
		<button {...buttonProps} className={clsx(classes.root, className)}>
			{props.leftIcon && props.leftIcon}

			<span className={classes.label}>{label}</span>

			{props.rightIcon && props.rightIcon}
		</button>
	);
};
