import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Section from '../UI/Section';
import { Box, Typography } from '@material-ui/core';
import { StaffService } from 'model/Staff';
import StaffChipService from 'components/UI/StaffChipService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { Autocomplete, Spinner } from 'components/UI';
import clsx from 'clsx';
import Button from 'components/UI/Button';
import { MobileDivider } from 'components/UI/MobileDivider';

interface Props {
    isEditing: boolean;
    allServices: Array<StaffService>;
    servicesSelected: Array<StaffService>;
    onChange: (staffServices: Array<StaffService>) => void;
    className?: string;
    isServicesLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullContainer: {
            width: '100%',
            height: '100%'
        },
        container: {
            gap: 12,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            padding: '0px 16px',

            [theme.breakpoints.up('md')]: {
                gap: 16,
                paddingLeft: '38px',
                paddingTop: '30px',
                paddingRight: '38px',
                paddingBottom: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '50px',
                paddingTop: '40px',
                paddingRight: '50px',
                paddingBottom: '40px'
            }
        },
        title: {
            [theme.breakpoints.down('lg')]: {
                fontSize: 20,
                fontWeight: 600
            }
        },
        addedService: {
            maxWidth: '100%'
        },
        chip: {
            '& span': {}
        },
        button: {
            height: 40,
            fontWeight: 100,
            border: '1px solid #92B4A7',
            color: '#92B4A7',

            [theme.breakpoints.down('lg')]: {
                'color': '#fff',
                'padding': '0px 16px',
                'backgroundColor': '#92B4A7',

                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            },
            [theme.breakpoints.up('xl')]: {
                height: '40px',
                marginBottom: '28px'
            }
        },
        plusButton: {
            width: 37,
            height: 37,
            cursor: 'pointer'
        },
        searchServices: {
            display: 'flex',
            flexDirection: 'column-reverse',
            [theme.breakpoints.down('lg')]: {
                height: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                height: '40px'
            }
        },
        servicesMessageHeader: {
            fontWeight: 500,
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '20px'
            }
        },
        servicesMessageBody: {
            color: '#B7B7B7',
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px',
                marginBottom: 15
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '16px'
            }
        },
        autocomplete: {
            [theme.breakpoints.down('lg')]: {
                width: '100%',
                marginBottom: 15
            }
        }
    })
);

export const Services: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const servicesIds: Array<string> = props.servicesSelected.map(
        service => service.uuid
    );

    const [selectedServices, setSelectedServices] = useState<
        Array<StaffService>
    >(props.servicesSelected);

    const [searchResult, setSearchResult] = useState<Array<StaffService>>(
        props.allServices.filter(service => !servicesIds.includes(service.uuid))
    );

    const [searchText, setSearchText] = useState('');

    const [isAutoSelected, setIsAutoSelected] = useState(false);

    const [showServiceList, setShowServiceList] = useState(false);

    useEffect(() => {
        const selectedServicesIds = selectedServices.map(
            service => service.uuid
        );

        setSearchResult(
            searchText.length === 0
                ? props.allServices.filter(
                      service => !selectedServicesIds.includes(service.uuid)
                  )
                : props.allServices
                      .filter(
                          service => !selectedServicesIds.includes(service.uuid)
                      )
                      .filter(service => {
                          const name = `${service.name.toLowerCase()}`;
                          return name.indexOf(searchText.toLowerCase()) > -1;
                      })
        );
    }, [searchText, selectedServices]);

    useEffect(() => {
        setSearchResult(
            props.allServices.filter(
                service => !servicesIds.includes(service.uuid)
            )
        );
    }, [props.allServices]);

    useEffect(() => {
        props.onChange(selectedServices);
    }, [selectedServices]);

    useEffect(() => {
        if (
            !isAutoSelected &&
            props.allServices.length > 0 &&
            !props.isEditing
        ) {
            setSelectedServices(props.allServices);
            setIsAutoSelected(true);
        }
    }, [props.allServices]);

    const changeSearchTextHanlder = (searchText: string) => {
        setSearchText(searchText);
    };

    const openServicesListHandler = () => {
        setShowServiceList(true);
    };

    const selectServiceHandler = (selectedService: StaffService): void => {
        setSelectedServices(prevSelectedServices => {
            return [...prevSelectedServices, selectedService];
        });

        setShowServiceList(false);
        setSearchText('');
    };

    const deleteHandler = (deletedService: StaffService) => {
        setSelectedServices(prevSelectedServices => {
            return prevSelectedServices.filter(
                service => service.uuid !== deletedService.uuid
            );
        });
    };

    const clickAwayHandler = () => {
        setSearchText('');
        setShowServiceList(false);
    };

    const addServicesButton = (
        <Button
            id="services_button_add_service"
            color="green"
            variant="secondary"
            className={classes.button}
            onClick={openServicesListHandler}
            label="+ Add Service"
        />
    );

    const plusButton = (
        <FontAwesomeIcon
            icon={faPlusCircle}
            color="#92B4A7"
            size="2x"
            onClick={openServicesListHandler}
            className={classes.plusButton}
        />
    );

    const noServiceMessage = (
        <Box>
            <Typography className={classes.servicesMessageHeader}>
                No services have been created yet.
            </Typography>
            <Typography className={classes.servicesMessageBody}>
                You can come back to add services once you create them.
            </Typography>
        </Box>
    );

    const serviceList = (
        <Autocomplete
            id="service_filter_search_by_name"
            options={searchResult
                .sort((service, otherService) => {
                    return service.name.localeCompare(otherService.name);
                })
                .map(service => ({ id: service.id!, name: service.name }))}
            placeholder="Search by name"
            emptyOptionsText="Services not found"
            emptySearhcBoxShowAllOptions={true}
            className={classes.autocomplete}
            onClickAway={clickAwayHandler}
            onChangeSearchText={changeSearchTextHanlder}
            onSelect={option => {
                const service = props.allServices.find(
                    s => s.id === option.id
                )!;
                selectServiceHandler(service);
            }}
        />
    );

    return (
        <Box className={clsx(classes.fullContainer, props.className)}>
            <MobileDivider />

            <Section
                title="Services"
                editable={true}
                collapsed={false}
                titleClassName={classes.title}
            >
                <Box className={classes.container}>
                    {props.isServicesLoading ? (
                        <Box>
                            <Spinner />
                        </Box>
                    ) : (
                        <>
                            {props.allServices.length === 0 ? (
                                noServiceMessage
                            ) : (
                                <>
                                    {showServiceList ? (
                                        serviceList
                                    ) : (
                                        <>
                                            {selectedServices.map(service => (
                                                <Box
                                                    key={service.uuid}
                                                    className={
                                                        classes.addedService
                                                    }
                                                >
                                                    <StaffChipService
                                                        small={false}
                                                        staff={service}
                                                        className={classes.chip}
                                                        onDelete={() =>
                                                            deleteHandler(
                                                                service
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            ))}

                                            {selectedServices.length > 0
                                                ? plusButton
                                                : addServicesButton}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Section>
        </Box>
    );
};

export default Services;
