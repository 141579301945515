import React from 'react';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';

type NotificationsEmptyProps = {
	image: string;
	title: string;
	subtitle: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			gap: 8,
			width: '100%',
			height: '100%',
			display: 'flex',
			padding: '0px 20%',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			textAlign: 'center',
			[theme.breakpoints.down("md")]: {
				padding: '0px 10%',
			}
		},
		image: {
			width: '100%',
			marginBottom: 8
		},
		title: {
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600
		},
		subtitle: {
			fontSize: 16,
			lineHeight: 1.4,
			maxWidth: 200,
			[theme.breakpoints.down("sm")]: {
				marginLeft: 'auto',
				marginRight: 'auto'
			}
		},
	})
);

export const NotificationsEmpty: React.FunctionComponent<
	NotificationsEmptyProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<img
				alt="All done image"
				className={classes.image}
				src={props.image}
			/>

			<Typography className={classes.title}>{props.title}</Typography>
			<Typography className={classes.subtitle}>
				{props.subtitle}
			</Typography>
		</Box>
	);
};
