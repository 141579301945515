import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { createDepositRequestThunk, createRequestThunk, PosStatus } from '@spike/pos-action';
import { statusTerminal, Terminal, TerminalStatus } from '@spike/terminal-model';
import { checkTerminalStatusThunk, fetchTerminalsThunk, TerminalsStatus } from '@spike/terminals-action';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { Button, Spinner } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import PosWebSocket from 'WebSockets/PosWebSockets';
import FooterComponent from '../FooterComponent';
import Invoice from "@spike/invoice-model";
import SelectField from '../UI/SelectField';

interface PosPaymentProps {
  invoiceId?: number;
  depositInvoiceId?: number;
  className?: string;
  onSuccess?: () => void;
  onGetPosServiceId?: (terminalId: number, serviceId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    terminalsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: "space-between",
      height: '100%',
    },
    terminal: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      color: '#222222',
      width: '100%',
      textAlign: 'center',
      fontSize: 20
    },
    errorMessage: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      color: '#C14A4A',
      width: '100%',
      textAlign: 'center',
      fontSize: 20
    },
    tryAgainButton: {
      marginTop: 37,
      [theme.breakpoints.up('md')]: {
        marginTop: 40
      },
    },
    checkingSpinner: {
      marginTop: 27,
      [theme.breakpoints.up('md')]: {
        marginTop: 30
      },
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#F7F7F7',
      borderRadius: 13,
      minHeight: 80,
      marginTop: 27,
      padding: 27,
      [theme.breakpoints.up('md')]: {
        marginTop: 30,
        padding: 30
      }
    },
    message: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      color: '#666666',
      width: '100%',
      textAlign: 'center',
      fontSize: 20
    },
    blinkingMessage: {
      color: '#222222',
      animation: '$blink 2s linear infinite',
    },
    '@keyframes blink': {
      '0%': { opacity: 1 },
      '25%': { opacity: 0.5 },
      '50%': { opacity: 0 },
      '75%': { opacity: 0.5 },
      '100%': { opacity: 1 },
    },
    successContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: 56,
      [theme.breakpoints.up('md')]: {
        padding: 60
      },
    },
    icon: {
      fontSize: 70,
      marginBottom: 27,
      [theme.breakpoints.up('md')]: {
        marginBottom: 30
      }
    },
    error: {
      color: '#EF4F57',
    },
    retryButton: {
      marginTop: 46,
      [theme.breakpoints.up('md')]: {
        marginTop: 50
      },
    },
    totalContainer: {
      borderTop: "1px solid #D4D4D4",
      height: "15%",
      marginTop: 10,
      marginBottom: 12,
      [theme.breakpoints.up('md')]: {
        height: "19%",
        marginTop: 11,
        marginBottom: 14
      }
    },
    terminals: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between"
    },
    title: {
      fontWeight: 600,
      fontSize: 17,
      marginBottom: 16,
      [theme.breakpoints.up('md')]: {
        marginBottom: 18
      }
    },
    sectionMargin: {
      padding: "0px 30px 32px",
      [theme.breakpoints.up('md')]: {
        padding: "0px 32px 35px"
      },
    }
  })
);

interface TerminalWrapper {
  terminal: Terminal | null;
  status: TerminalStatus | null;
  posServiceId: string | null;
}

interface POSMessage {
  message: string;
  error: boolean;
}

export const PosPayment: FunctionComponent<PosPaymentProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const terminalsStatus = useSelector<RootState, TerminalsStatus>((state) => state.terminals.status);
  const terminals = useSelector<RootState, Array<Terminal>>((state) => state.terminals.terminals).filter(
    (terminal) => terminal.active && terminal.status === statusTerminal.READY.toString()
  );
  const terminalStatus = useSelector<RootState, TerminalStatus | undefined>((state) => state.terminals.terminalStatus);
  const depositInvoice = useSelector<RootState, Invoice | undefined>((state) => state.invoices.depositInvoice);

  const posStatus = useSelector<RootState, PosStatus>((state) => state.pos.status);
  const posServiceId = useSelector<RootState, string | null>((state) => state.pos.serviceId || null);

  const [loadingTerminals, setLoadingTerminals] = useState(true);
  const [checkingTerminal, setCheckingTerminal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState<TerminalWrapper>({
    terminal: null,
    status: null,
    posServiceId: null,
  });
  const [posMessages, setPosMessages] = useState<Array<POSMessage>>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(fetchTerminalsThunk(apiClientWrapper));
  }, []);

  useNonInitialEffect(() => {
    switch (terminalsStatus) {
      case TerminalsStatus.FetchTerminalsSuccess:
        setLoadingTerminals(false);
        break;
      case TerminalsStatus.CheckStatusSuccess:
        setSelectedTerminal((prev) => ({ ...prev, status: terminalStatus ? { ...terminalStatus } : null }));
        if (terminalStatus?.ready) {
          props.invoiceId && dispatch(createRequestThunk(apiClientWrapper, props.invoiceId, selectedTerminal.terminal!.id!));
          props.depositInvoiceId &&
            dispatch(createDepositRequestThunk(apiClientWrapper, props.depositInvoiceId, selectedTerminal.terminal!.id!));
        } else {
          setCheckingTerminal(false);
        }
        break;
      case TerminalsStatus.Error:
        setLoadingTerminals(false);
        setCheckingTerminal(false);
        break;
    }
  }, [terminalsStatus]);

  useNonInitialEffect(() => {
    switch (posStatus) {
      case PosStatus.CreateDespositRequestSuccess:
      case PosStatus.CreateRequestSuccess:
        setCheckingTerminal(false);
        setSelectedTerminal((prev) => ({ ...prev, posServiceId }));
        props.onGetPosServiceId && props.onGetPosServiceId(selectedTerminal.terminal!.id!, posServiceId!);
        break;
      case PosStatus.Error:
        setLoadingTerminals(false);
        setCheckingTerminal(false);
        break;
    }
  }, [posStatus]);

  const payHandler = () => {
    if (selectedTerminal.terminal) {
      setCheckingTerminal(true);
      dispatch(checkTerminalStatusThunk(apiClientWrapper, selectedTerminal.terminal.id!));
    }
  };

  const getTruncatedUnpaidTotal = (total: string) => {
    const totalInteger = total.split(".");
    return totalInteger[totalInteger.length - 1] === "0" || totalInteger[totalInteger.length - 1] === "00" ? totalInteger[0] : total;
  };

  const retryHandler = () => {
    setShowSuccess(false);
    setShowError(false);
    setSelectedTerminal({ terminal: null, status: null, posServiceId: null });
    setPosMessages([]);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.terminalsContainer}>
        {loadingTerminals ? (
          <Spinner />
        ) : (
          <Box className={classes.terminals}>
            <Box className={classes.sectionMargin}>
              <Typography className={classes.title}>
                Select a POS
              </Typography>
              <SelectField
                placeholder="Select a POS"
                options={terminals.map((terminal) => ({ id: terminal.id!, name: terminal.name }))}
                selectedOption={
                  selectedTerminal.terminal
                    ? { id: selectedTerminal.terminal.id!, name: selectedTerminal.terminal.name }
                    : undefined
                }
                onSelect={(option) =>
                  setSelectedTerminal({
                    terminal: terminals.find((terminal) => terminal.id === option.id) || null,
                    status: null,
                    posServiceId: null,
                  })
                }
                disabled={selectedTerminal.status?.ready === true}
              />
              {checkingTerminal && <Spinner className={classes.checkingSpinner} />}
              {!checkingTerminal && selectedTerminal.posServiceId && (
                <PosWebSocket
                  invoiceId={props.invoiceId || props.depositInvoiceId!}
                  terminalId={selectedTerminal.terminal!.id!}
                  onMessageReceived={(message, error) => setPosMessages((prev) => [...prev, { message, error }])}
                  onSuccess={() => {
                    setShowSuccess(true);
                    props.onSuccess && props.onSuccess();
                  }}
                  onError={() => {
                    setShowError(true);
                  }}
                  onGetPosServiceId={(serviceId) =>
                    props.onGetPosServiceId && props.onGetPosServiceId(selectedTerminal.terminal!.id!, serviceId)
                  }
                />
              )}
              {!checkingTerminal && selectedTerminal.terminal && selectedTerminal.status?.ready === false && (
                <Box className={classes.messageContainer}>
                  <Typography className={classes.errorMessage}>{selectedTerminal.status?.message}</Typography>
                  <Button label="Try Again!" color="black" onClick={payHandler} className={classes.tryAgainButton} />
                </Box>
              )}
              {!checkingTerminal && selectedTerminal.posServiceId && !showSuccess && (
                <Box className={classes.messageContainer}>
                  {posMessages.map((posMessage, index) => (
                    <Typography
                      key={index}
                      className={clsx(classes.message, {
                        [classes.blinkingMessage]: index === posMessages.length - 1,
                        [classes.error]: posMessage.error,
                      })}
                    >
                      {posMessage.message}
                    </Typography>
                  ))}
                </Box>
              )}
              {showError && (
                <Box className={classes.successContainer}>
                  <FontAwesomeIcon icon={faCircleXmark} className={clsx(classes.icon, classes.error)} />
                  <Button label="Retry" color="black" className={classes.retryButton} onClick={retryHandler} />
                </Box>
              )}
            </Box>
            <Box className={classes.totalContainer}>
              <FooterComponent 
                title="Deposit"
                loading={false}
                total={depositInvoice?.deposits.unpaidTotal ? getTruncatedUnpaidTotal(depositInvoice?.deposits.unpaidTotal) : "0"}
                buttonText="Pay"
                disabledButton={selectedTerminal.terminal === null || selectedTerminal.status !== null || checkingTerminal}
                onBook={payHandler}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PosPayment;
