import React, { FunctionComponent, MouseEvent, useState } from "react";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Chip from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { StaffMember } from "../../model/Service";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface Props {
  staff: Array<StaffMember>;
  hover?: boolean;
  onShow: (show: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    chip: {
      background: "#F1F1F1",
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        paddingLeft: `${reduceResolution(12)}px`,
        paddingRight: `${reduceResolution(12)}px`,
        maxWidth: `${reduceResolution(135)}px`
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        paddingLeft: "12px",
        paddingRight: "12px",
        maxWidth: "185px"
      },
    },
    staffIcon: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        marginLeft: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        marginLeft: "6px",
      },
    },
    avatar: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: "2px",
        height: `${reduceResolution(24)}px !important`,
        width: `${reduceResolution(24)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "24px !important",
        width: "24px !important",
      },
    },
    multipleAvatarLabel: {
      [theme.breakpoints.down(wbp)]: {
        marginLeft: `${reduceResolution(20)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginLeft: "20px",
      },
    },
    expandedStaff: {
      display: "flex",
      flexDirection: "column",
      "& .MuiChip-root": {
        [theme.breakpoints.down(wbp)]: {
          display: "flex",
          justifyContent: "left",
          paddingLeft: `${reduceResolution(16)}px`,
          marginTop: `${reduceResolution(12)}px`,
        },
        [theme.breakpoints.up(wbp)]: {
          display: "flex",
          justifyContent: "left",
          paddingLeft: "16px",
          marginTop: "12px",
        },
      },
    },
    active: {
      border: "2px solid #000"
    },
    hover: {
      background: "#fff !important"
    }
  })
);

export const GroupedStaffChip: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showExpandedStaff, setShowEspandedStaff] = useState(false);

  const clickMultipleStaffHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowEspandedStaff((prevValue) => {
      props.onShow(!prevValue);
      return !prevValue;
    });
  };

  const multipleAvatars = (
    <AvatarGroup spacing="small">
      {props.staff.slice(0, 3).map((staff) => (
        <Avatar key={staff.id} src={staff.avatar} className={classes.avatar} />
      ))}
    </AvatarGroup>
  );

  return (
    <Box className={classes.container} onClick={clickMultipleStaffHandler}>
      <Chip
        avatar={multipleAvatars}
        label={
          <Box className={classes.multipleAvatarLabel}>
            {props.staff.length} Members{" "}
            <FontAwesomeIcon
              icon={showExpandedStaff ? faCaretUp : faCaretDown}
              className={classes.staffIcon}
            />
          </Box>
        }
        className={clsx(classes.chip, { [classes.active]: showExpandedStaff, [classes.hover]: props.hover })}
      />
    </Box>
  );
};

export default GroupedStaffChip;
