import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Section from './Section';
import { Spinner } from 'components/UI';

interface TotalProps {
	amount: string;
	title: string;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinnerContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				height: `${reduceResolution(80)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				height: '80px'
			}
		},
		amountContainer: {
			height: 68,
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#E9F0ED',
			borderRadius: 13,

			[theme.breakpoints.up('md')]: {
				height: 80
			}
		},
		amount: {
			fontSize: 24,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 32
			}
		}
	})
);

export const Total: FunctionComponent<TotalProps> = props => {
	const classes = useStyles();

	return (
		<Section title={props.title} className={props.className}>
			{props.loading ? (
				<Box className={classes.spinnerContainer}>
					<Spinner />
				</Box>
			) : (
				<Box className={classes.amountContainer}>
					<Typography
						id="booking_payment_input_text_total"
						className={classes.amount}
					>
						${props.amount}
					</Typography>
				</Box>
			)}
		</Section>
	);
};

export default Total;
