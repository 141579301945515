// @ts-nocheck
import { Box, TextField as MUITextField } from '@material-ui/core';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { useMarketplace } from 'hooks';
import { FunctionComponent } from 'react';
import InputMask from 'react-input-mask';
import { useFieldStyles } from './FieldStyles';
import LabelField from './LabelField';

export interface ControlledZipCodeField3Props {
  name?: string;
  label?: string | JSX.Element;
  value: string;
  placeholder?: string;
  errors?: Array<FieldError>;
  onChange: (value: string, name?: any) => void;
  labelClassName?: string;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  autoComplete?: 'off' | 'on' | 'nope';
  required?: boolean;
  information?: string;
  id?: string;
}

export const ControlledZipCodeField3: FunctionComponent<ControlledZipCodeField3Props> = (props) => {
  const classes = useFieldStyles();

  const marketplace = useMarketplace();

  const hasCACustomers = marketplace.id === 160;
  const isUS = (marketplace.basics.address.country?.id.toUpperCase() === 'US' && hasCACustomers === false) ?? false;
  const isCA = marketplace.basics.address.country?.id.toUpperCase() === 'CA' ?? false;


  const hasError = props.errors?.some((error) => error.fieldName === props.name) || false;

  const isNumeric = (value: string): boolean => {
    return /^\d+$/.test(value);
  };
  const convertToNumbers = (value: string): string => {
    return !value
      ? ''
      : value
        .split('')
        .filter((token) => isNumeric(token))
        .join('');
  };

  const isAlfanumeric = (value: string): boolean => {
    return /^(\d|[A-Z]|[a-z]|\s)+$/.test(value);
  };

  const convertToAlfanumeric = (value: string): string => {
    return !value
      ? ''
      : value
        .split('')
        .filter((token) => isAlfanumeric(token))
        .join('');
  };


  const changeUSHandler = (event: any): void => {
    const value = convertToNumbers(event.target.value);
    props.onChange(value, props.name);
  };

  const changeCAHandler = (event: any): void => {
    const value = convertToAlfanumeric(event.target.value);
    props.onChange(value.toUpperCase(), props.name);
  };

  const changeOtherHandler = (event: any): void => {
    const newValue = event.target.value;
    /^\([a-z]|[A-Z]|[0-9]{0,10}$/.test(newValue) && props.onChange(newValue.toUpperCase(), props.name);
  };

  const usField = (
    <Box>
      <InputMask mask="99999" value={props.value} onChange={changeUSHandler} disabled={props.disabled}>
        {(inputProps: any) => (
          <MUITextField
            id={props.id}
            name={props.name}
            placeholder={props.placeholder}
            variant="outlined"
            autoFocus={props.autoFocus}
            className={clsx(classes.field, {
              [classes.emptyField]: props.value.length === 0,
              [classes.fieldError]: hasError,
              [classes.disabledField]: props.disabled,
            })}
            error={hasError}
            disabled={props.disabled}
            autoComplete={'nope'}
          />
        )}
      </InputMask>
    </Box>
  );

  const caField = (
    <Box>
      <InputMask mask="a9a9a9" value={props.value} onChange={changeCAHandler} disabled={props.disabled}>
        {(inputProps: any) => (
          <MUITextField
            id={props.id}
            name={props.name}
            placeholder={props.placeholder}
            variant="outlined"
            autoFocus={props.autoFocus}
            className={clsx(classes.field, {
              [classes.emptyField]: props.value.length === 0,
              [classes.fieldError]: hasError,
              [classes.disabledField]: props.disabled,
            })}
            error={hasError}
            disabled={props.disabled}
            autoComplete={'nope'}
          />
        )}
      </InputMask>
    </Box>
  );

  const otherField = (
    <MUITextField
      id={props.id}
      name={props.name}
      placeholder="Zipcode"
      variant="outlined"
      autoFocus={props.autoFocus}
      className={clsx(classes.field, {
        [classes.emptyField]: props.value.length === 0,
        [classes.fieldError]: hasError,
        [classes.disabledField]: props.disabled,
      })}
      error={hasError}
      disabled={props.disabled}
      autoComplete={'nope'}
      onChange={changeOtherHandler}
      value={props.value}
      inputProps={{ maxLength: 10 }}
    />
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField {...props} />
      {isUS ? usField : (isCA ? caField : otherField)}
    </Box>
  );
};

export default ControlledZipCodeField3;
