import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
    faTimesCircle,
    faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';

interface AlertBoxProps {
    id?: string;
    text: string | JSX.Element;
    actionable: boolean;
    path?: string;
    label?: string;
    className?: string;
    type?: 'info' | 'error' | 'warning';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alertBox: {
            'display': 'flex',
            'backgroundColor': '#F8F5F1',
            'border': '2px solid #BCB8AE',
            'borderRadius': '10px',
            'padding': '14px 16px 16px',

            '& b': {
                fontWeight: 600
            },
            '&.error': {
                borderColor: '#EF4F57',
                backgroundColor: 'rgba(251, 185, 188, 0.2)'
            }
        },
        desktop: {
            marginTop: '30px',
            marginBottom: '0px'
        },
        mobile: {
            marginTop: '8px',
            marginBottom: '15px'
        },
        boxIcon: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(38)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '38px'
            }
        },
        sectionIconInfo: {
            'color': '#BAA997',
            '&:hover': {
                cursor: 'pointer'
            },
            '&.error': {
                color: '#CC444B'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                paddingRight: `${reduceResolution(10)}px`,
                marginTop: `${reduceResolution(2)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px',
                paddingRight: '10px',
                marginTop: '2px'
            }
        },
        spanMessage: {
            fontSize: 16,
            lineHeight: 1.3,
            fontWeight: 400
        },
        linkWrapper: {
            textDecoration: 'none',
            marginTop: '20px'
        },
        linkButton: {
            'marginTop': '10px',
            'paddingRight': 20,
            'paddingLeft': 20,
            'height': 40,

            '& .MuiButton-label': {
                fontSize: 14,
                fontWeight: 500
            }
        }
    })
);

export const AlertBox: FunctionComponent<AlertBoxProps> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getIconByType = (type?: string) => {
        switch (type) {
            case 'error':
                return faTimesCircle;
            case 'warning':
                return faExclamationTriangle;
            case 'info':
            default:
                return faInfoCircle;
        }
    };

    const iconSVG = (icon: IconDefinition, styles: string) => {
        return <FontAwesomeIcon icon={icon} className={styles} />;
    };

    return (
        <Box
            className={clsx(
                classes.alertBox,
                isMobile ? classes.mobile : classes.desktop,
                props.className,
                props.type === 'error' && 'error'
            )}
        >
            <Box>
                <Box className={classes.boxIcon}>
                    {iconSVG(
                        getIconByType(props.type),
                        `${classes.sectionIconInfo} ${props.type}`
                    )}
                </Box>
            </Box>
            <Box>
                <Typography className={classes.spanMessage}>
                    {props.text}
                </Typography>

                {props.actionable && props.path && (
                    <Link className={classes.linkWrapper} to={props.path}>
                        <Button
                            id={
                                props.id
                                    ? props.id + '_button_' + props.label
                                    : props.id
                            }
                            label={props.label}
                            size="medium"
                            color="green"
                            className={classes.linkButton}
                        ></Button>
                    </Link>
                )}
            </Box>
        </Box>
    );
};

export default AlertBox;
