import {
    ProductCategoriesAction,
    PRODUCT_CATEGORIES_RESET_ACTION_TYPE,
    PRODUCT_CATEGORIES_FETCH_START_ACTION_TYPE,
    PRODUCT_CATEGORIES_FETCH_SUCCESS_ACTION_TYPE,
    PRODUCT_CATEGORIES_GET_START_ACTION_TYPE,
    PRODUCT_CATEGORIES_GET_SUCCESS_ACTION_TYPE,
    PRODUCT_CATEGORIES_SAVE_START_ACTION_TYPE,
    PRODUCT_CATEGORIES_SAVE_SUCCESS_ACTION_TYPE,
    PRODUCT_CATEGORIES_SAVE_PRODUCTS_START_ACTION_TYPE,
    PRODUCT_CATEGORIES_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE,
    PRODUCT_CATEGORIES_DELETE_PRODUCTS_START_ACTION_TYPE,
    PRODUCT_CATEGORIES_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE,
    PRODUCT_CATEGORIES_DELETE_START_ACTION_TYPE,
    PRODUCT_CATEGORIES_DELETE_SUCCESS_ACTION_TYPE,
    PRODUCT_CATEGORIES_ERROR_ACTION_TYPE
} from './ProductCategoriesActionsTypes';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { ProductCategoriesState } from 'reducers/productCategories/ProductCategoriesState';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction } from '@spike/notifications-action';
import store from 'store';
import { convertToProductCategory } from './ProductCategoriesConverter';
import ProductCategory from 'model/ProductCategory';
import { ProductCategoryDto } from './ProductCategoriesDtos';
import { alertErrorHandler } from '@spike/notifications-action';
import { showSuccess, showError } from '@spike/notifications-action';

const categoriesUrl = '/product_categories';

export const fetchThunk = (): ThunkAction<
    void,
    ProductCategoriesState,
    null,
    ProductCategoriesAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${categoriesUrl}?marketplace_id=${marketplaceId}`;
        try {
            const response: AxiosResponse<Array<ProductCategoryDto>> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(
                fetchSuccess(
                    response.data.map(categoryDto =>
                        convertToProductCategory(categoryDto)
                    )
                )
            );
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(
                    apiError,
                    dispatch,
                    'Error fetching product categories.'
                );
            }
        }
    };
};

export const getThunk = (
    categoryId: number
): ThunkAction<
    void,
    ProductCategoriesState,
    null,
    ProductCategoriesAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${categoriesUrl}/${categoryId}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<ProductCategoryDto> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(getSuccess(convertToProductCategory(response.data)));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(
                    apiError,
                    dispatch,
                    'Error getting product category.'
                );
            }
        }
    };
};

export const saveThunk = (
    category: ProductCategory
): ThunkAction<
    void,
    ProductCategoriesState,
    null,
    ProductCategoriesAction | NotificationsAction
> => {
    return async dispatch => {
        if (category.custom) {
            dispatch(saveStart());

            const marketplaceId = store.getState().login.auth.marketplaceId;
            const apiClientMethod = category.id
                ? ApiClient.patch
                : ApiClient.post;
            const saveUrl = category.id
                ? `${categoriesUrl}/${category.id}?marketplace_id=${marketplaceId}`
                : `${categoriesUrl}?marketplace_id=${marketplaceId}`;

            const bodyRequest = {
                id: category.id,
                uuid: category.uuid,
                marketplace_id: marketplaceId,
                name: category.name,
                description: category.description,
                system_only: !category.custom,
                active: category.active
            };

            try {
                const response: AxiosResponse<ProductCategoryDto> =
                    await apiClientMethod(
                        saveUrl,
                        bodyRequest,
                        createTokenConfig(store.getState().login.auth.token!)
                    );
                dispatch(saveSuccess(convertToProductCategory(response.data)));
                dispatch(fetchThunk());
                dispatch(showSuccess('Category products saved successfully'));
            } catch (apiError) {
                if (!isCancelled(apiError)) {
                    dispatch(error());
                    alertErrorHandler(
                        apiError,
                        dispatch,
                        'Error saving product category.'
                    );
                }
            }
        } else {
            dispatch(
                showError(
                    'Product Category is a default categpry and cannot be updated.'
                )
            );
        }
    };
};

export const saveProductsThunk = (
    category: ProductCategory
): ThunkAction<
    void,
    ProductCategoriesState,
    null,
    ProductCategoriesAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(saveProductsStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${categoriesUrl}/${category.id}/add_products?marketplace_id=${marketplaceId}`;

        const bodyRequest = {
            marketplace_id: marketplaceId,
            product_ids: category.products.map(p => p.id).join(',')
        };

        try {
            const response: AxiosResponse<ProductCategoryDto> =
                await ApiClient.patch(
                    url,
                    bodyRequest,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(
                saveProductsSuccess(convertToProductCategory(response.data))
            );
            dispatch(fetchThunk());
            dispatch(showSuccess('Product Category saved successfully'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(
                    apiError,
                    dispatch,
                    'Error saving product category.'
                );
            }
        }
    };
};

export const deleteProductsThunk = (
    category: ProductCategory
): ThunkAction<
    void,
    ProductCategoriesState,
    null,
    ProductCategoriesAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(deleteProductsStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${categoriesUrl}/${category.id}/remove_products?marketplace_id=${marketplaceId}`;

        const bodyRequest = {
            marketplace_id: marketplaceId,
            product_ids: category.products.map(p => p.id).join(',')
        };

        try {
            const response: AxiosResponse<ProductCategoryDto> =
                await ApiClient.patch(
                    url,
                    bodyRequest,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(
                deleteProductsSuccess(convertToProductCategory(response.data))
            );
            dispatch(fetchThunk());
            dispatch(showSuccess('Product Category saved successfully'));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(
                    apiError,
                    dispatch,
                    'Error saving product category.'
                );
            }
        }
    };
};

export const deleteThunk = (
    category: ProductCategory
): ThunkAction<
    void,
    ProductCategoriesState,
    null,
    ProductCategoriesAction | NotificationsAction
> => {
    return async dispatch => {
        if (!category.custom) {
            dispatch(
                showError(
                    'Product Category is a default category and cannot be deleted.'
                )
            );
        } else if (category.products.length > 0) {
            dispatch(
                showError(
                    'Product Category has products and cannot be deleted.'
                )
            );
        } else {
            dispatch(deleteStart());

            const marketplaceId = store.getState().login.auth.marketplaceId;
            const url = `${categoriesUrl}/${category.id}?marketplace_id=${marketplaceId}`;

            try {
                const response: AxiosResponse<ProductCategoryDto> =
                    await ApiClient.delete(
                        url,
                        createTokenConfig(store.getState().login.auth.token!)
                    );
                dispatch(deleteSuccess(response.data.uuid));
                dispatch(showSuccess('Product category deleted'));
            } catch (apiError) {
                if (!isCancelled(apiError)) {
                    dispatch(error());
                    alertErrorHandler(
                        apiError,
                        dispatch,
                        'Error deleting product category.'
                    );
                }
            }
        }
    };
};

export const reset = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_RESET_ACTION_TYPE
    };
};

const fetchStart = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_FETCH_START_ACTION_TYPE
    };
};

export const fetchSuccess = (
    categories: Array<ProductCategory>
): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            categories
        }
    };
};

const getStart = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_GET_START_ACTION_TYPE
    };
};

export const getSuccess = (
    category: ProductCategory
): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_GET_SUCCESS_ACTION_TYPE,
        payload: {
            category
        }
    };
};

const saveStart = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_SAVE_START_ACTION_TYPE
    };
};

export const saveSuccess = (
    category: ProductCategory
): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_SAVE_SUCCESS_ACTION_TYPE,
        payload: {
            category
        }
    };
};

const saveProductsStart = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_DELETE_PRODUCTS_START_ACTION_TYPE
    };
};

export const saveProductsSuccess = (
    category: ProductCategory
): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE,
        payload: {
            category
        }
    };
};

const deleteProductsStart = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_SAVE_PRODUCTS_START_ACTION_TYPE
    };
};

export const deleteProductsSuccess = (
    category: ProductCategory
): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE,
        payload: {
            category
        }
    };
};

const deleteStart = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_DELETE_START_ACTION_TYPE
    };
};

export const deleteSuccess = (
    productCategoryUuid: string
): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_DELETE_SUCCESS_ACTION_TYPE,
        payload: {
            productCategoryUuid
        }
    };
};

const error = (): ProductCategoriesAction => {
    return {
        type: PRODUCT_CATEGORIES_ERROR_ACTION_TYPE
    };
};
