import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableContainer, Table as TableMUI, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import TableHeader from "./TableHeader";
import TableSpinner from "./TableSpinner";
import TableNotFound from "./TableNotFound";
import ProductVendor from "model/ProductVendor";
import { Product, SortFields as SortFieldsProducts, SortOrder as SortOrderProducts} from "@spike/product-model";
import { SortFields as SortFieldsVendors, SortOrder as SortOrderVendors} from "model/ProductVendor";
import { SortFields as SortFieldsCategories, SortOrder as SortOrderCategories} from "model/ProductCategory";
import ProductCategory from "model/ProductCategory";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import ProductRow from "./ProductRow";
import VendorRow from "./VendorRow";
import CategoryRow from "./CategoryRow";
import { useDispatch } from "react-redux";
import { deleteThunk, saveThunk } from "actions/productCategories/ProductCategoriesActions";
import ConfirmDialog from "components/Products/UI/Dialog";
import UnnassignProductsDialog from "../UI/WarningDialog";

interface Props {
  products?: Array<Product>;
  vendors?: Array<ProductVendor>;
  categories?: Array<ProductCategory>;
  selectedView: string;
  loading?: boolean;
  onAssign: (category: ProductCategory) => void;
  onEditProduct: (product: Product) => void;
  onEditVendor: (vendor: ProductVendor) => void;
  onEditCategory: (vendor: ProductCategory) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      display: "flex",
      width: "100%",
      overflowX: "visible",
      [theme.breakpoints.down("md")]: {
        paddingTop: "26px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "32px",
      },
    },
    textDialog: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    }
  })
);

export const Table: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [showDeleteCategoryDialog, setShowDeleteCategoryDialog] = useState(false);
  const [showActivateCategoryDialog, setShowActivateCategoryDialog] = useState(false);
  const [showDeactivateCategoryDialog, setShowDeactivateCategoryDialog] = useState(false);
  const [showUnnassignProducts, setShowUnnassignProducts] = useState(false);
  const [categoryToChange, setCategoryToChange] = useState<ProductCategory>();
  const [products, setProducts] = useState<Array<Product> | undefined>(props.products);
  const [vendors, setVendors] = useState<Array<ProductVendor> | undefined>(props.vendors);
  const [categories, setCategories] = useState<Array<ProductCategory> | undefined>(props.categories);

  const handleDelete = (category: ProductCategory) => {
    setCategoryToChange(category);
    if(category.products.length > 0) {
      setShowUnnassignProducts(true);
    } else {
      setShowDeleteCategoryDialog(true);
    }
  }

  const handleDeleteCategory = () => {
    dispatch(deleteThunk(categoryToChange!));
    setShowDeleteCategoryDialog(false);
  }

  const handleActivate = (category: ProductCategory) => {
    setCategoryToChange(category);
    setShowActivateCategoryDialog(true);
  }

  const handleActivateCategory = () => {
    if(categoryToChange) {
      let categoryAux = {...categoryToChange};
      categoryAux.active = true;
      dispatch(saveThunk(categoryAux!));
      setShowActivateCategoryDialog(false);
    }
  }

  const handleDeactivate = (category: ProductCategory) => {
    setCategoryToChange(category);
    setShowDeactivateCategoryDialog(true);
  }

  const handleDeactivateCategory = () => {
    if(categoryToChange) {
      let categoryAux = {...categoryToChange};
      categoryAux.active = false;
      dispatch(saveThunk(categoryAux!));
      setShowDeactivateCategoryDialog(false);
    }
  }

  useEffect(() => {
    setProducts(props.products);
    setVendors(props.vendors);
    setCategories(props.categories);
  }, [props.products, props.vendors, props.categories]);

  const sortProducts = (field: SortFieldsProducts | undefined, order: SortOrderProducts) => {
    if(field !== undefined){
      if(props.products) {
        let productsAux = [...props.products];
        if(order !== SortOrderProducts.CREATION_DATE) {
          productsAux.sort((product, otherProduct) => {
            if(field === "productName") {
              if(product.name.toLowerCase() > otherProduct.name.toLowerCase()) {
                return 1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else if(product.name.toLowerCase() < otherProduct.name.toLowerCase()) {
                return -1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "category") {
              const productFormatted = product.category ? product.category.name ? product.category.name.toLowerCase() : "" : "";
              const otherProductFormatted = otherProduct.category ? otherProduct.category.name ? otherProduct.category.name.toLowerCase() : "" : "";
  
              if(productFormatted > otherProductFormatted) {
                return 1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else if(productFormatted < otherProductFormatted) {
                return -1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "vendor") {
              const vendorDefault = product.vendors ? product.vendors.find((v) => v.default) : undefined;
              const otherVendorDefault = otherProduct.vendors ? otherProduct.vendors.find((v) => v.default) : undefined;
              const productFormatted = vendorDefault ? vendorDefault.vendor?.name ? vendorDefault.vendor.name.toLowerCase() : "" : "";
              const otherProductFormatted = otherVendorDefault ? otherVendorDefault.vendor?.name ? otherVendorDefault.vendor?.name.toLowerCase() : "" : "";
  
              if(productFormatted > otherProductFormatted) {
                return 1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else if(productFormatted < otherProductFormatted) {
                return -1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "brand") {
              const productFormatted = product.brand ? product.brand.toLowerCase() : "";
              const otherProductFormatted = otherProduct.brand ?  otherProduct.brand.toLowerCase() : "";
  
              if(productFormatted > otherProductFormatted) {
                return 1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else if(productFormatted < otherProductFormatted) {
                return -1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "manufacturer") {
              const productFormatted = product.brand ? product.brand.toLowerCase() : "";
              const otherProductFormatted = otherProduct.brand ?  otherProduct.brand.toLowerCase() : "";
  
              if(productFormatted > otherProductFormatted) {
                return 1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else if(productFormatted < otherProductFormatted) {
                return -1 * (order === SortOrderProducts.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else{
              return 1;
            }
          });
        }
        setProducts(productsAux);
      }
    }
  };

  const sortVendors = (field: SortFieldsVendors | undefined, order: SortOrderVendors) => {
    if(field !== undefined){
      if(props.vendors) {
        let vendorsAux = [...props.vendors.filter((v) => !v.deleted)];
        if(order !== SortOrderVendors.CREATION_DATE) {
          vendorsAux.sort((vendor, otherVendor) => {
            if(field === "vendorName") {
              if(vendor.name.toLowerCase() > otherVendor.name.toLowerCase()) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(vendor.name.toLowerCase() < otherVendor.name.toLowerCase()) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "contact") {
              const vendorFormatted = vendor.contact ? vendor.contact.toLowerCase() : "";
              const otherVendorFormatted = otherVendor.contact ?  otherVendor.contact.toLowerCase() : "";

              if (vendorFormatted > otherVendorFormatted) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if (vendorFormatted < otherVendorFormatted) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "phone") {
              const vendorFormatted = vendor.phone ? vendor.phone.toLowerCase() : "";
              const otherVendorFormatted = otherVendor.phone ?  otherVendor.phone.toLowerCase() : "";

              if(vendorFormatted > otherVendorFormatted) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(vendorFormatted < otherVendorFormatted) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "email") {
              const vendorFormatted = vendor.email ? vendor.email.toLowerCase() : "";
              const otherVendorFormatted = otherVendor.email ?  otherVendor.email.toLowerCase() : "";

              if(vendorFormatted > otherVendorFormatted) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(vendorFormatted < otherVendorFormatted) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "website") {
              const vendorFormatted = vendor.website ? vendor.website.toLowerCase() : "";
              const otherVendorFormatted = otherVendor.website ?  otherVendor.website.toLowerCase() : "";

              if(vendorFormatted > otherVendorFormatted) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(vendorFormatted < otherVendorFormatted) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "state") {
              const vendorFormatted = vendor.address ? vendor.address.state ? vendor.address.state.toLowerCase() : "" : "";
              const otherVendorFormatted = otherVendor.address ? otherVendor.address.state ? otherVendor.address.state.toLowerCase() : "" : "";

              if(vendorFormatted > otherVendorFormatted) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(vendorFormatted < otherVendorFormatted) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "products") {
                return (vendor.products.length - otherVendor.products.length) * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
            } else if(field === "status") {
              if(vendor.active > otherVendor.active) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(vendor.active < otherVendor.active) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else{
              return 1;
            }
          });
        }
        setVendors(vendorsAux);
      }
    }
  };

  const sortCategories = (field: SortFieldsCategories | undefined, order: SortOrderCategories) => {
    if(field !== undefined){
      if(props.categories) {
        let categoriesAux = [...props.categories];
          if(order !== SortOrderCategories.CREATION_DATE) {
          categoriesAux.sort((category, otherCategory) => {
            if(field === "category") {
              if(category.name.toLowerCase() > otherCategory.name.toLowerCase()) {
                return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else if(category.name.toLowerCase() < otherCategory.name.toLowerCase()) {
                return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
              } else {
                return 0;
              }
            } else if(field === "products") {
              return (category.products.length - otherCategory.products.length) * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
            } else if(field === "status") {
                if(category.active > otherCategory.active) {
                  return 1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
                } else if(category.active < otherCategory.active) {
                  return -1 * (order === SortOrderVendors.ASCENDANT ? 1 : -1);
                } else {
                  return 0;
                }
            } else{
              return 1;
            }
          });
        }  
        setCategories(categoriesAux);
      }
    }
  };

  const messageQuestionDelete = () => {
    return (
      <Box>
        <Typography className={classes.textDialog}>
        Are you sure you want to delete <strong>{categoryToChange?.name}</strong> as a category?
        </Typography>
      </Box>
    );
  }

  const messageQuestionActivate = () => {
    return (
      <Box>
        <Typography className={classes.textDialog}>
          Are you sure you want to activate category?
        </Typography>
      </Box>
    );
  }

  const messageQuestionDeactivate = () => {
    return (
      <Box>
        <Typography className={classes.textDialog}>
          Are you sure you want to deactivate category?
        </Typography>
      </Box>
    );
  }

  const getBody = (): JSX.Element => {
    switch (props.selectedView) {
      case "products": {
        return products && products.length > 0 ?
          <TableBodyMUI>
            {products && products.map((p) => {
              return <ProductRow 
                        key={p.uuid}
                        product={p} 
                        onEdit={props.onEditProduct}
                     />
            })}
          </TableBodyMUI>
          :
          <TableNotFound title="Not Found!" description="Products not found." />        
      }
      case "vendors": {
        let vendorsFiltered = [];
        if (vendors && vendors.length > 0) {
          vendorsFiltered = vendors.filter((v) => !v.deleted);
        }
        return vendorsFiltered.length > 0 ?
          <TableBodyMUI>
            {vendors && vendors.map((v) => {
              if(!v.deleted) {
                return <VendorRow 
                          key={v.uuid}
                          vendor={v} 
                          onEdit={props.onEditVendor}
                        />
              }
            })}
          </TableBodyMUI>
          :
          <TableNotFound title="Not Found!" description="Vendors not found." />       
      }
      default: {
        return categories && categories.length > 0 ?
          <TableBodyMUI>
            {categories && categories.map((c) =>{
              return <CategoryRow 
                        key={c.uuid}
                        category={c} 
                        onRename={props.onEditCategory}
                        onAssign={props.onAssign}
                        onDelete={handleDelete}
                        onActivate={handleActivate}
                        onDeactivate={handleDeactivate}
                      />
            })}
          </TableBodyMUI>
          :
          <TableNotFound title="Not Found!" description="Categories not found." />       
      }
    }
  };

  return (
    <TableContainer className={clsx(classes.tableContainer, props.className)}>
      <TableMUI>
        <TableHeader 
          selectedView={props.selectedView} 
          onSortProducts={sortProducts}
          onSortVendors={sortVendors}
          onSortCategories={sortCategories}
        />
        {props.loading ? (
          <TableSpinner />
        ) : 
          getBody()
        }
      </TableMUI>
      <ConfirmDialog 
        open={showDeleteCategoryDialog}
        title="Delete Category"
        centered
        question={messageQuestionDelete()}
        onCancel={() => setShowDeleteCategoryDialog(false)}
        onConfirm={handleDeleteCategory}
        confirmButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
      <ConfirmDialog 
        open={showActivateCategoryDialog}
        title="Activate Category"
        centered
        question={messageQuestionActivate()}
        onCancel={() => setShowActivateCategoryDialog(false)}
        onConfirm={handleActivateCategory}
        confirmButtonLabel="Activate"
        cancelButtonLabel="Cancel"
      />
      <ConfirmDialog 
        open={showDeactivateCategoryDialog}
        title="Deactivate Category"
        centered
        question={messageQuestionDeactivate()}
        onCancel={() => setShowDeactivateCategoryDialog(false)}
        onConfirm={handleDeactivateCategory}
        confirmButtonLabel="Deactivate"
        cancelButtonLabel="Cancel"
      />
      <UnnassignProductsDialog 
        open={showUnnassignProducts}
        title="Unable to delete"
        description="You must unnassign products from this category before deleting category."
        onOk={() => setShowUnnassignProducts(false)}
      />
    </TableContainer>
  );
};

export default Table;
