import { Box, Dialog, DialogTitle, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { Fragment, FunctionComponent } from 'react';

export interface Props {
    open: boolean;
    title?: JSX.Element | string;
    question?: JSX.Element | string;
    subquestion?: JSX.Element | string;
    actions?: JSX.Element;
    elementAlignment?: string;
    isSuccess?: boolean;
    isForm?: boolean;
    className?: string;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    processing?: boolean;
    onCancel?: () => void;
    onConfirm?: () => void;
    id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: (props: Props) => ({
            '& .MuiDialog-paper': {
                boxShadow: 'none',
                [theme.breakpoints.down('xs')]: {
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                    borderRadius: 0
                },
                [theme.breakpoints.up('sm')]: {
                    padding: 0,
                    minWidth: 465,
                    borderRadius: 30
                }
            },
            '& .MuiDialogContent-root': {
                padding: 0,
                width: '70%',
                display: 'flex',
                margin: '0px auto',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: props.elementAlignment ? 'left' : 'center',
                [theme.breakpoints.up('sm')]: {
                    width: '100%'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                }
            },
            '& .MuiDialogActions-root': {
                gap: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 16px',
                [theme.breakpoints.up('sm')]: {
                    marginTop: 37,
                    borderTop: '1px solid #DDDDDD',
                    padding: props.isForm ? '17px 49px' : '17px 30px'
                },
                [theme.breakpoints.down('md')]: {
                    '& > button': {
                        minWidth: '48%'
                    },
                    '& > button:last-child': {
                        marginLeft: 0
                    }
                }
            }
        }),
        title: {
            '& > *': {
                margin: 0,
                color: '#000',
                fontSize: 24,
                lineHeight: 1.2,
                fontWeight: 600
            }
        },
        question: (props: Props) => ({
            fontSize: 18,
            fontWeight: 400,
            color: props.isForm ? '' : '#000',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        }),
        space: {
            marginBottom: 15
        },
        subquestion: {
            color: '#000',
            fontWeight: 400,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px'
            }
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                minWidth: '47%'
            },
            [theme.breakpoints.up('sm')]: {
                width: '191px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '55px',
                borderRadius: '30px'
            }
        },
        iconClose: {
            fontSize: 20,
            float: 'right',
            cursor: 'pointer'
        }
    })
);

export const ConfirmDialog: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    return (
        <Dialog
            open={props.open}
            className={clsx(classes.dialog, props.className)}
            onClose={() => props.onCancel && props.onCancel()}
        >
            <DialogTitle>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.iconClose}
                    onClick={props.onCancel}
                />
            </DialogTitle>

            <DialogContent>
                {props.title && (
                    <Box
                        className={clsx(classes.title, {
                            [classes.space]: props.question || props.subquestion
                        })}
                    >
                        {typeof props.title === 'string' ? (
                            <Typography variant="h6">{props.title}</Typography>
                        ) : (
                            props.title
                        )}
                    </Box>
                )}

                {props.question && (
                    <Typography
                        className={clsx(classes.question, {
                            [classes.space]: props.subquestion
                        })}
                    >
                        {typeof props.question === 'string' ? (
                            <Typography>{props.question}</Typography>
                        ) : (
                            props.question
                        )}
                    </Typography>
                )}

                {props.subquestion && (
                    <Typography className={clsx(classes.subquestion)}>
                        {typeof props.subquestion === 'string' ? (
                            <Typography>{props.subquestion}</Typography>
                        ) : (
                            props.subquestion
                        )}
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                {props.actions ? (
                    props.actions
                ) : (
                    <Fragment>
                        {props.cancelButtonLabel && (
                            <Button
                                id={
                                    props.id &&
                                    props.id
                                        .concat(
                                            props.cancelButtonLabel?.toLowerCase() ||
                                                ''
                                        )
                                        .concat('_button')
                                }
                                label={props.cancelButtonLabel}
                                onClick={event => {
                                    event.stopPropagation();
                                    props.onCancel && props.onCancel();
                                }}
                                color="black"
                                className={classes.button}
                                disabled={props.processing}
                            />
                        )}
                        {props.confirmButtonLabel && (
                            <Button
                                id={
                                    props.id &&
                                    props.id
                                        .concat(
                                            props.confirmButtonLabel?.toLowerCase() ||
                                                ''
                                        )
                                        .concat('_button')
                                }
                                label={props.confirmButtonLabel}
                                onClick={event => {
                                    event.stopPropagation();
                                    props.onConfirm && props.onConfirm();
                                }}
                                variant={props.isSuccess ? 'primary' : 'danger'}
                                color={props.isSuccess ? 'green' : 'orange'}
                                className={classes.button}
                                loading={props.processing}
                            />
                        )}
                    </Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
