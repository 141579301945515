import React, { Fragment, FunctionComponent } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import StaffChip from "./StaffChip";
import clsx from "clsx";
import { PriceFormat } from "components/UI";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        width: "100%",
        overflowX: "visible",
        [theme.breakpoints.down("lg")]: {
          paddingLeft: "-8px"
        },
        [theme.breakpoints.up("xl")]: {
          paddingLeft: "-12px"
        },
        "& .MuiTableCell-root": {
          textAlign: "start",
          paddingLeft: "0px !important",
          borderBottom: "0px !important",
          padding: "6px !important"
        }
      },
      columnService: {
        fontWeight: 600,
        [theme.breakpoints.down("md")]: {
          width: "180px",
          fontSize: "5px"
        },
        [theme.breakpoints.only("lg")]: {
          width: "240px",
          fontSize: "6px"
        },
        [theme.breakpoints.only("xl")]: {
          width: "364px",
          fontSize: "10px",
        },
      },
      columnStaff: {
        fontWeight: 600,
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
          width: "64px",
          fontSize: "5px"
        },
        [theme.breakpoints.only("lg")]: {
          width: "86px",
          fontSize: "6px"
        },
        [theme.breakpoints.only("xl")]: {
          width: "130px",
          fontSize: "10px"
        },
      },
      column: {
        fontWeight: 600,
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
          width: "54px",
          fontSize: "5px"
        },
        [theme.breakpoints.only("lg")]: {
          width: "73px",
          fontSize: "6px"
        },
        [theme.breakpoints.only("xl")]: {
          width: "110px",
          fontSize: "10px"
        },
      }, 
      tableRow: {
        borderColor: "#fff !important",
      },
      tableHead: {
        borderColor: "1px solid #fff",
      },
      cellChip: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "8px"
        },
      },
      quantity: {
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "8px"
        },
      },
      cell: {
        textAlign:"center",
        [theme.breakpoints.down("md")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "8px"
        },
      },
      cellPrice: {
        textAlign: "right"
      },
      cellTotal: {
        paddingRight: "0px"
      },
      titleSection: {
        color: "#A3A3A3",
        [theme.breakpoints.down("md")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "10px",
        },
      },
      text: {
        [theme.breakpoints.down("md")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "8px"
        },
      },
      cellsGrey: {
        textAlign: "center",
      },
      grey: {
        color: "#7A7A7A",
        [theme.breakpoints.down("md")]: {
          fontSize: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "7px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "12px"
        },
      },
      total: {
        color: "#5E8677",
        fontWeight: 600,
        [theme.breakpoints.down("md")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "8px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "14px"
        },
      },
      tableBody: {
        [theme.breakpoints.down("md")]: {
          marginBottom: "21px",
        },
        [theme.breakpoints.only("lg")]: {
          marginBottom: "28px",
        },
        [theme.breakpoints.only("xl")]: {
          marginBottom: "42px"
        },
      },
      totalContainer: {
        fontWeight: 600,
        display: "flex",
        flexDirection:"column", 
        justifyContent: "center",
        width: "100%",
        [theme.breakpoints.down("md")]: {
          borderRadius: "10px",
          height: "10px",
          paddingLeft: "-16px"
        },
        [theme.breakpoints.only("lg")]: {
          borderRadius: "13px",
          height: "13px",
          paddingLeft: "15px",
          marginLeft: "-22px",
        },
        [theme.breakpoints.only("xl")]: {
          borderRadius: "20px",
          height: "20px",
          paddingLeft: "22px",
          marginLeft: "-32px",
        },
      },
      cellTotalTotal: {
        [theme.breakpoints.down("md")]: {
          paddingLeft: "-11px"
        },
        [theme.breakpoints.only("lg")]: {
          paddingLeft: "-15px"
        },
        [theme.breakpoints.only("xl")]: {
          paddingLeft: "-22px"
        },
      },
      priceTotal: {
        backgroundColor: "#E9F0ED",
        width: "100%",
        display: "flex",
        flexDirection:"column", 
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          borderRadius: "10px",
          height: "10px",
          width: "27px",
          paddingLeft: "5px"
        },
        [theme.breakpoints.only("lg")]: {
          borderRadius: "13px",
          height: "13px",
          width: "35px",
          paddingLeft: "7px"
        },
        [theme.breakpoints.only("xl")]: {
          borderRadius: "20px",
          height: "20px",
          width: "52px",
          paddingLeft: "12px"
        },
      }
    })
);

export const TablePricesInvoice: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const invoice = {
    invoices: [{
      description: "Full Groomed Deluxed",
      staff: {
        name: "Name",
        avatar: ""
      },
      pet: "Paco",
      price: "$59.99",
      total: "$59.99"
    },
    {
      description: "Full Groomed Deluxed",
      staff: {
        name: "Name",
        avatar: ""
      },
      pet: "Silvester",
      price: "$59.99",
      total: "$59.99"
    }], 
    subtotal: "$196.99",
    tax: {
      percentage: "7",
      amount: "$34.50"
    },
    paymentType: "Cash",
    total: "$250.00"

};

  return (
    <Fragment>
      <TableContainer className={classes.container}>
        <Table>
          <TableHead className={classes.tableHead} style={{borderBottom: "1px solid #F1F1F1"}}>
            <TableRow>
              <TableCell className={classes.columnService}>Service</TableCell>
              <TableCell className={classes.column}>Pet</TableCell>
              <TableCell className={classes.columnStaff}>Staff</TableCell>
              <TableCell className={classes.column}>Price</TableCell>
              <TableCell className={classes.column}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {invoice.invoices.map((line, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.text}>
                  {line.description}
                </TableCell>
                <TableCell className={classes.quantity}>
                  {line.pet}
                </TableCell>
                <TableCell className={classes.cellChip}>
                  <StaffChip
                    staff={{
                      id: index,
                      firstName: line.staff.name,
                      lastName: '',
                      avatar: line.staff.avatar 
                    }}
                  />
                </TableCell>
                <TableCell className={clsx(classes.cell, classes.cellPrice)}>
                  <PriceFormat price={line.price} />
                </TableCell>
                <TableCell className={clsx(classes.cell, classes.cellPrice)}>
                <PriceFormat price={line.total} />                  
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{borderTop : "1px solid #F1F1F1"}}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.cellsGrey}>
                <Typography className={classes.grey}>Subtotal</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.cellPrice)}>
              <PriceFormat price={invoice.subtotal} />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.cellsGrey}>
                <Typography className={classes.grey}>{`Tax (${invoice.tax.percentage})%`}</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.cellPrice)}>
                <PriceFormat price={invoice.tax.amount} />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.text}>
                <Box>
                  <Typography className={classes.titleSection}>PAYMENT METHOD</Typography>
                  {invoice.paymentType}
                </Box>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.cellTotal}>
                <Typography className={classes.total}>Total</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.cellTotalTotal)}>
                <Box className={classes.totalContainer}>
                  <Box className={classes.priceTotal}>
                    <PriceFormat price={invoice.total} />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default TablePricesInvoice;
