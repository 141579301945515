import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableContainer, Table as TableMUI } from "@material-ui/core";
import clsx from "clsx";
import TableHeader from "./TableHeader";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import ProductRow from "./ProductRow";
import { Product } from "@spike/product-model";

interface Props {
  products: Array<Product>;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      display: "flex",
      width: "100%",
      overflowX: "visible",
      [theme.breakpoints.down("md")]: {
        paddingTop: "26px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "32px",
      },
    },
  })
);

export const Table: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableContainer className={clsx(classes.tableContainer, props.className)}>
      <TableMUI>
        <TableHeader />
        <TableBodyMUI>
          {props.products.map((p) => {
            return <ProductRow
                      key={p.uuid}
                      product={p}
                    />
          })}
        </TableBodyMUI>
      </TableMUI>
    </TableContainer>
  );
};

export default Table;
