import { Box, ClickAwayListener } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Moment } from "moment-timezone";
import { FunctionComponent, useState } from "react";
import Calendar from "./Calendar";
import { useFieldStyles } from "../FieldStyles";
import moment from "moment";
import { wbp } from "Theme";
import NumberFormat from "react-number-format";

export interface DateSelectorProps {
  placeholder?: string;
  date?: Moment;
  disabled: boolean;
  className?: string;
  required?: boolean;
  haveReport?: boolean;
  onChange?: (moment?: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      position: "relative",
      [theme.breakpoints.down(wbp)]: {
        height: "39px",
      },
      [theme.breakpoints.up(wbp)]: {
        height: "50px",
      },
    },
    collapsedContainer: {
      height: "100%",
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        height: "38px",
      },
      [theme.breakpoints.up(wbp)]: {
        height: "47px",
      },
      cursor: "pointer",
    },
    header: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: "10px",
        fontSize: "14px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "20px",
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    placeholder: {
      color: "#7A7A7A",
    },
    border: {
      [theme.breakpoints.down(wbp)]: {
        borderRadius: "26px",
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "33px",
      },
    },
    textContainer: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
    },
    chevronContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: "28px",
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "35px",
      },
    },
    calendarContainer: {
      position: "absolute",
      zIndex: 10,
      height: "100%",
      border: "1px solid #000000",
      borderBottom: "none !important",
      [theme.breakpoints.down(wbp)]: {
        bottom: "38px",
        height: "400px",
      },
      [theme.breakpoints.up(wbp)]: {
        bottom: "45px",
        height: "415px",
      },
    },
    calendarContainerExpanded: {
      borderBottom: "none !important",
      width: "100%",
      borderTopLeftRadius: "30px !important", 
      borderTopRightRadius: "30px !important"
    },
    numberFormatCollapsed: {
      border: "3px solid #92B4A7 !important",
    },
    numberFormatCollapsedEditable: {
      borderTopLeftRadius: "0px !important", 
      borderTopRightRadius: "0px !important"
    },
    dateCollapsedContainer: {
      border: "1px solid #000000",
      textAlign: "center",
      outline: "none",
      pointerEvents: "none",
      width: "100%",
      cursor: 'default !important',
			"& .MuiOutlinedInput-input": {
				textAlign: "center",
			},
			fontSize: "14px",
			borderRadius: "20px",
			padding: "10px 15px",
    }
  })
);

export const DateSelector: FunctionComponent<DateSelectorProps> = (props) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [nextDentalDate, setNextDentalDate] = useState(props.date);

  const clickToggleHandler = () => {
    if(!props.disabled) setIsCollapsed((prev) => props.disabled || !prev);
  };

  const clickCloseHandler = () => {
    if(!props.disabled) setIsCollapsed(true);
  };

  const onChangeDate = (dateChanged?: Moment) => {
    setNextDentalDate(dateChanged);
    props.onChange && props.onChange(dateChanged);
  };

  const header = (
    <Box className={classes.headerContainer} onClick={clickToggleHandler}>
      <NumberFormat
        placeholder={"MM / DD / YYYY"}
        format="## / ## / ####"
        className={clsx(classes.dateCollapsedContainer, {
          [classes.numberFormatCollapsed]: props.disabled, 
          [classes.numberFormatCollapsedEditable]: !props.disabled && !isCollapsed
        })}
        value={props.haveReport ? `${moment(nextDentalDate).format('MM/DD/YYYY')}` : null}
      />
    </Box>
  );

  const collapsed = (
    <Box
      className={clsx(classes.border, classes.collapsedContainer, {
        [fieldClasses.disabled]: props.disabled
      })}
    >
      {header}
    </Box>
  );

  const expanded = (
    <Box className={classes.border}>
      <Box className={clsx(classes.calendarContainer, {
        [classes.calendarContainerExpanded]: !isCollapsed
      })}>
        <Calendar
          date={props.date && props.date.isValid() ? props.date : undefined}
          onChange={onChangeDate}
          onClose={clickCloseHandler}
        />
      </Box>
      {header}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={clickCloseHandler}>
      <Box className={clsx(fieldClasses.container, props.className)}>
        <Box className={classes.dropdownContainer}>{isCollapsed ? collapsed : expanded}</Box>
      </Box>
    </ClickAwayListener>
  );
};

export default DateSelector;
