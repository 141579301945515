import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    BusinessHours,
    createEmptyWeek,
    FieldError,
    MarketplaceSchedule,
    SpecialHour,
    Week
} from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import {
    validateSpecialHours,
    validateWeek,
    validateTimeZone
} from 'components/StaffSchedule/utils/Validations';
import { BusinessDay, Button, SelectorFieldSearch } from 'components/UI';
import Switch from 'components/UI/V2/Switch';
import _ from 'lodash';
import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { RootState } from 'store';
import { BusinessSettingsTitle } from '../UI';
import SpecialHours from './SpecialHours';
import { useMasterData } from 'hooks';
import { Option } from '@spike/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MobileDivider } from 'components/UI/MobileDivider';

export interface Props {
    schedule: MarketplaceSchedule;
    enableSpecialHours?: boolean;
    className?: string;
    onSave?: (schedule: MarketplaceSchedule) => void;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 20,

            [theme.breakpoints.down('sm')]: {
                margin: '0px auto'
            },
            [theme.breakpoints.up('sm')]: {
                width: 350
            },
            [theme.breakpoints.up('md')]: {
                width: 520
            },
            [theme.breakpoints.only('xl')]: {
                width: 620
            }
        },
        schedule: {
            [theme.breakpoints.down('xs')]: {
                order: 3,
                paddingTop: '0px !important',
                paddingBottom: '0px !important'
            },

            '& > p': {
                fontSize: 16,
                marginBottom: 18,
                [theme.breakpoints.up('sm')]: {
                    display: 'none'
                }
            }
        },
        scheduleHeader: {
            minHeight: 58,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 15,
            paddingBottom: 15,
            borderBottom: '1px solid #222222'
        },
        everydayContainer: {
            display: 'flex',
            flex: '1 1 auto',
            alignItems: 'center'
        },
        everyday: {
            fontSize: 16,
            fontWeight: 500,
            marginRight: '12px',

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        everydayHours: {
            width: 'auto !important'
        },
        everydayHoursContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                width: '92px'
            },
            [theme.breakpoints.only('md')]: {
                width: '149px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '198px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '300px'
            }
        },
        scheduleBody: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        dayContainer: {
            width: '100%',
            display: 'flex',
            minHeight: 64
        },
        timezone: {
            [theme.breakpoints.down('xs')]: {
                order: 1,
                paddingBottom: '0px !important'
            }
        },
        specialHours: {
            order: 5,

            [theme.breakpoints.down('xs')]: {
                paddingTop: '0px !important'
            }
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                'height': 47,
                'width': '100%',
                'borderRadius': 30,

                '& span': {
                    fontSize: 16
                }
            }
        }
    })
);

export const Schedule: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();

    const defaultBusinessHours = { open: '8:00', close: '17:00' };

    const areAllDaysEquals = (week: Week) => {
        const [firstDay, ...others] = _.values(week);
        return (
            firstDay !== null && others.every(day => _.isEqual(firstDay, day))
        );
    };

    const getEverydayBusinessHours = (week: Week) => {
        if (areAllDaysEquals(week)) {
            const [value] = _.values(week).filter(day => day !== null);
            return value || defaultBusinessHours;
        } else {
            return defaultBusinessHours;
        }
    };

    const status = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );

    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    const [schedule, setSchedule] = useState(props.schedule);
    const [selectedTimeZone, setSelectedTimeZone] = useState<
        Option<string | number> | undefined
    >(
        masterData.timeZones.find(
            timeZone => timeZone.id === props.schedule.timeZone
        )
    );

    const [everyday, setEveryday] = useState(
        areAllDaysEquals(props.schedule.week)
    );
    const [everydayBusinessHours, setEverydayBusinessHours] =
        useState<BusinessHours>(getEverydayBusinessHours(props.schedule.week));

    useNonInitialEffect(() => {
        setEveryday(areAllDaysEquals({ ...schedule.week }));
        setEverydayBusinessHours(
            getEverydayBusinessHours({ ...schedule.week })
        );
        setSaved(false);
    }, [schedule]);

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setSaved(true);
            setLoading(false);
        }
    }, [status]);

    const everydayChangeHandler = (name: string, checked: boolean) => {
        setEveryday(checked);

        if (checked) {
            setSchedule(prev => {
                return {
                    ...prev,
                    week: {
                        sunday: { ...everydayBusinessHours },
                        monday: { ...everydayBusinessHours },
                        tuesday: { ...everydayBusinessHours },
                        wednesday: { ...everydayBusinessHours },
                        thursday: { ...everydayBusinessHours },
                        friday: { ...everydayBusinessHours },
                        saturday: { ...everydayBusinessHours }
                    }
                };
            });
        } else {
            setSchedule(prev => {
                return {
                    ...prev,
                    week: createEmptyWeek()
                };
            });
        }
    };

    const everydayBusinessHoursChangeHandler = (
        businessHours: BusinessHours
    ) => {
        setEverydayBusinessHours({ ...businessHours });
        setSchedule(prev => {
            return {
                ...prev,
                week: {
                    sunday:
                        prev.week.sunday !== null ? { ...businessHours } : null,
                    monday:
                        prev.week.monday !== null ? { ...businessHours } : null,
                    tuesday:
                        prev.week.tuesday !== null
                            ? { ...businessHours }
                            : null,
                    wednesday:
                        prev.week.wednesday !== null
                            ? { ...businessHours }
                            : null,
                    thursday:
                        prev.week.thursday !== null
                            ? { ...businessHours }
                            : null,
                    friday:
                        prev.week.friday !== null ? { ...businessHours } : null,
                    saturday:
                        prev.week.saturday !== null
                            ? { ...businessHours }
                            : null
                }
            };
        });
    };

    const changeBusinessHoursHandler = (
        day: string,
        businessHours: BusinessHours
    ) => {
        const week: Week = { ...schedule.week };
        week[day as keyof Week] = businessHours;

        if (areAllDaysEquals({ ...week })) {
            setEverydayBusinessHours({ ...businessHours });
        }

        setSchedule({
            ...schedule,
            week: {
                ...schedule.week,
                [day]: businessHours
            }
        });
    };

    const selectDayHandler = (day: string, selected: boolean) => {
        setSchedule((prev: MarketplaceSchedule) => {
            const newSchedule = { ...prev };
            newSchedule.week[day as keyof Week] = selected
                ? everyday
                    ? { ...everydayBusinessHours }
                    : { ...defaultBusinessHours }
                : null;
            return newSchedule;
        });
    };

    const saveHandler = () => {
        const unsavedSchedule = {
            ...schedule,
            specialHours: [
                ...schedule.specialHours.filter(sH => sH.name !== 'deleted')
            ]
        };

        const errors = [
            ...validateWeek(unsavedSchedule.week),
            ...validateSpecialHours(unsavedSchedule.specialHours),
            ...validateTimeZone(unsavedSchedule)
        ];

        setErrors(errors);

        if (errors.length === 0) {
            props.onSave && props.onSave({ ...unsavedSchedule });
            props.onSave && setLoading(true);
        }
    };

    const handleSpecialHourChange = (specialHours: Array<SpecialHour>) => {
        setSchedule(prev => ({ ...prev, specialHours: [...specialHours] }));
    };

    const changeTimeZone = (timeZone?: Option<string | number>) => {
        setSchedule(prev => ({
            ...prev,
            timeZone: timeZone?.id.toString() || ''
        }));
        setSelectedTimeZone(timeZone);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Grid container spacing={4}>
                <Grid item xs={12} style={{ order: 0 }}>
                    <BusinessSettingsTitle>
                        {props.isMobile && (
                            <FontAwesomeIcon
                                onClick={props.openSidebar}
                                icon={faArrowLeft}
                            />
                        )}
                        Hours
                    </BusinessSettingsTitle>
                </Grid>

                <Grid item xs={12} className={classes.schedule}>
                    <Typography>
                        What are the normal hours your business is open for
                        customers each week?
                    </Typography>

                    <Box className={classes.scheduleHeader}>
                        <Box className={classes.everydayContainer}>
                            <Typography className={classes.everyday}>
                                Everyday
                            </Typography>
                            <Switch
                                id={'business_settings_switch_everyday'}
                                checked={everyday}
                                onCheck={everydayChangeHandler}
                            />
                        </Box>
                        <Box className={classes.everydayHoursContainer}>
                            {everyday && (
                                <BusinessDay
                                    className={classes.everydayHours}
                                    businessHours={everydayBusinessHours!}
                                    onChange={
                                        everydayBusinessHoursChangeHandler
                                    }
                                />
                            )}
                        </Box>
                    </Box>

                    <Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Sunday"
                                selected={schedule.week.sunday !== null}
                                businessHours={
                                    schedule.week.sunday || defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'sunday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    selectDayHandler('sunday', selected);
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'sunday'
                                        )
                                    );
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'sunday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Monday"
                                selected={schedule.week.monday !== null}
                                businessHours={
                                    schedule.week.monday || defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'monday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    selectDayHandler('monday', selected);
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'monday'
                                        )
                                    );
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'monday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Tuesday"
                                selected={schedule.week.tuesday !== null}
                                businessHours={
                                    schedule.week.tuesday ||
                                    defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'tuesday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    selectDayHandler('tuesday', selected);
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'tuesday'
                                        )
                                    );
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'tuesday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Wednesday"
                                selected={schedule.week.wednesday !== null}
                                businessHours={
                                    schedule.week.wednesday ||
                                    defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'wednesday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    selectDayHandler('wednesday', selected);
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'wednesday'
                                        )
                                    );
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'wednesday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Thursday"
                                selected={schedule.week.thursday !== null}
                                businessHours={
                                    schedule.week.thursday ||
                                    defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'thursday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    selectDayHandler('thursday', selected);
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'thursday'
                                        )
                                    );
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'thursday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Friday"
                                selected={schedule.week.friday !== null}
                                businessHours={
                                    schedule.week.friday || defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'friday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    selectDayHandler('friday', selected);
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'friday'
                                        )
                                    );
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'friday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                        <Box className={classes.dayContainer}>
                            <BusinessDay
                                label="Saturday"
                                selected={schedule.week.saturday !== null}
                                businessHours={
                                    schedule.week.saturday ||
                                    defaultBusinessHours
                                }
                                onChange={businessHours => {
                                    changeBusinessHoursHandler(
                                        'saturday',
                                        businessHours
                                    );
                                }}
                                onSelected={selected => {
                                    setErrors(prev =>
                                        prev.filter(
                                            error =>
                                                error.fieldName !== 'saturday'
                                        )
                                    );
                                    selectDayHandler('saturday', selected);
                                }}
                                errorMessage={
                                    errors.find(
                                        error =>
                                            error.fieldName.toLowerCase() ===
                                            'saturday'
                                    )?.errorMessage
                                }
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{ order: 2, padding: 0 }}>
                    <MobileDivider />
                </Grid>

                <Grid item xs={12} className={classes.timezone}>
                    <SelectorFieldSearch
                        id="business_settings_schedule_select_timezone"
                        label="Time Zone"
                        options={masterData.timeZones}
                        selectedOption={selectedTimeZone}
                        name="timeZone"
                        errors={errors}
                        required={true}
                        onSelect={changeTimeZone}
                        placeholder="Select a timezone..."
                    />
                </Grid>

                <Grid item xs={12} style={{ order: 4, padding: 0 }}>
                    <MobileDivider />
                </Grid>

                {props.enableSpecialHours && (
                    <Grid item xs={12} className={classes.specialHours}>
                        <SpecialHours
                            specialHours={[...schedule.specialHours]}
                            onChange={handleSpecialHourChange}
                            errors={errors}
                        />
                    </Grid>
                )}

                <Grid item xs={12} style={{ order: 6 }}>
                    <Button
                        id="business_settings_schedule_button_save"
                        label={saved ? 'Saved' : 'Save'}
                        className={classes.button}
                        onClick={saveHandler}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Schedule;
