import clsx from 'clsx';
import { useTagStyles } from 'components/UI/V2/Tag/Tag.styles';
import React from 'react';

export type TagProps = React.PropsWithChildren &
    React.HTMLAttributes<HTMLSpanElement> & {
        variant: 'danger';
        size?: 'small' | 'medium';
        className?: string;
    };

const Tag: React.FC<TagProps> = props => {
    const classes = useTagStyles();

    // extract props and define default values
    const { variant = 'danger', size = 'medium', ...tagProps } = props;

    return (
        <span
            {...tagProps}
            className={clsx(classes.root, props.className, {
                // Variants
                [classes.danger]: variant === 'danger',

                // Sizes
                [classes.small]: size === 'small',
                [classes.medium]: size === 'medium'
            })}
        >
            {props.children}
        </span>
    );
};

export default Tag;
