import { TwoWaySmsAction, TWOWAYSMS_CLEAR_ACTION_TYPE, TWOWAYSMS_ERROR_ACTION_TYPE, TWOWAYSMS_FETCH_CONVERSATIONS_START_ACTION_TYPE, TWOWAYSMS_FETCH_CONVERSATIONS_SUCCESS_ACTION_TYPE, TWOWAYSMS_FETCH_START_ACTION_TYPE, TWOWAYSMS_FETCH_SUCCESS_ACTION_TYPE, TWOWAYSMS_FILTER_SUCCESS_ACTION_TYPE, TWOWAYSMS_GET_COUNT_START_ACTION_TYPE, TWOWAYSMS_GET_COUNT_SUCCESS_ACTION_TYPE, TWOWAYSMS_RESET_ACTION_TYPE, TWOWAYSMS_SAVE_START_ACTION_TYPE, TWOWAYSMS_SAVE_SUCCESS_ACTION_TYPE, TWOWAYSMS_SEARCH_START_ACTION_TYPE, TWOWAYSMS_SEARCH_SUCCESS_ACTION_TYPE, TWOWAYSMS_SET_CONVERSATIONS_SUCCESS_ACTION_TYPE, TWOWAYSMS_SET_CONVERSATION_SUCCESS_ACTION_TYPE, TWOWAYSMS_SET_COUNT_SUCCESS_ACTION_TYPE, TWOWAYSMS_SET_STORE_START_ACTION_TYPE } from "actions/twoWaySms/twoWaySmsActionsTypes";
import { TwoWaySmsState, TwoWaySmsStatus } from "./TwoWaySmsState";

const initialState: TwoWaySmsState = {
    conversation: [],
    messageAmount: 0,
    unreadMessages: [],
    chats: [],
    status: TwoWaySmsStatus.Initial,
    loading: false,
    savedMessage: undefined,
    message: undefined
};


export const twoWaySmsReducer = (
    state = initialState,
    action: TwoWaySmsAction
) => {
    switch (action.type) {

        case TWOWAYSMS_RESET_ACTION_TYPE:
            return {
                ...initialState
            };
        case TWOWAYSMS_CLEAR_ACTION_TYPE:
            return {
                ...state,
                message: [],
                status: TwoWaySmsStatus.Cleared,
                loading: false,
                savedMessage: undefined
            };
        case TWOWAYSMS_SET_STORE_START_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.Setting,
                loading: true,
            };

        case TWOWAYSMS_SET_CONVERSATION_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                conversation: [...action.payload.conversation],
                status: TwoWaySmsStatus.SetConversationSuccess,
                loading: false,
            };
        case TWOWAYSMS_FETCH_START_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.Fetching,
                loading: true,
            };
        case TWOWAYSMS_FETCH_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                conversation: [...action.payload.conversation],
                status: TwoWaySmsStatus.FetchSuccess,
                loading: false,
            };
        case TWOWAYSMS_SET_CONVERSATIONS_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                chats: [...action.payload.chats],
                status: TwoWaySmsStatus.SetConversationsSuccess,
                loading: false,
            };
        case TWOWAYSMS_SET_COUNT_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.SetCountSuccess,
                loading: false,
                messageAmount: action.payload.messageQuantity
            };
        case TWOWAYSMS_GET_COUNT_START_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.Getting,
                loading: true,
            };
        case TWOWAYSMS_GET_COUNT_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.GetCountSuccess,
                loading: false,
                messageAmount: action.payload.messageQuantity,
                unreadMessages: action.payload.unreadMessages
            };

        case TWOWAYSMS_SEARCH_START_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.Searching,
                loading: true,
            };
        case TWOWAYSMS_SEARCH_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.SearchSuccess,
                loading: false,
                chats: action.payload.chats
            };
        case TWOWAYSMS_SAVE_START_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.Saving,
                loading: true,
                savedMessage: undefined
            };
        case TWOWAYSMS_SAVE_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.SaveSuccess,
                loading: false,
                savedMessage: { ...action.payload.message },
            };

        case TWOWAYSMS_ERROR_ACTION_TYPE:
            return {
                ...state,
                status: TwoWaySmsStatus.Error,
                loading: false,
            };

        default:
            return state;
    }
};

export default twoWaySmsReducer;
