/* eslint-disable react/no-unescaped-entities */
import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { PetBirthday } from "components/PetCard/UIContent/PetBirthday";
import { PetPersonality } from "components/UI/PetPersonality";
import { ItemLabelRead } from "components/UI";
import Pet, { Note } from "@spike/pet-model";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";
import PetNotes from "components/Pet/PetNotes";
import PetPersonalities from "../UI/PetPersonalities";

interface PetInformationProps {
  pet: Pet;
  className?: string;
  OnShowPageSizeDropdown?: () => void;
  OnPageSelectedChange?: () => void;
  onNewPetNote: (petNotes: Array<Note>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMenu: {
      display: "flex",
      flexDirection: "column",
    },
    itemMenu: {
      cursor: "pointer",
    },
    gridItems: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "30px",
      },
    },
    petTitle: {
      fontWeight: 600,
      whiteSpace: "nowrap",
      marginBottom: 24,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(24)}px`,
        marginTop: `${reduceResolution(9)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
        marginTop: "9px"
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 0,
        paddingLeft: 16
      }
    },
    line: {
      width: "100%",
      height: 20,
      background: '#F4F3F0',
      marginTop: 40
    },
    paddingWrapper: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px 16px ',

      }
    },
    gridItem: {
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 16,
      }
    },
    title: {
      fontSize: 16,
      fontWeight: 400,
      color: '#A3A3A3',
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    description: {
      fontSize: 18,
      fontWeight: 400,
      color: '#000000',
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        fontWeight: 500,
      },
    }

  })
);


export const PetInformation: FunctionComponent<PetInformationProps> = (props) => {
  const classes = useStyles();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Box className={clsx(props.className)}>
      <Grid container className={classes.paddingWrapper}>
        <Grid item xs={4} md={3} className={classes.gridItem}>
          <PetBirthday pet={props.pet} />
        </Grid>
        <Grid item xs={4} md={3} className={classes.gridItem}>
          <ItemLabelRead classDescription={classes.description} classTitle={classes.title} title={"COLOR"} description={(props.pet && props.pet?.color?.name) ? props.pet?.color?.name : "Not Specified"} />
        </Grid>
        <Grid item xs={4} md={3} className={classes.gridItem}>
          <ItemLabelRead classDescription={classes.description} classTitle={classes.title} title={"HAIR TYPE"} description={props.pet?.hair?.name ? props.pet?.hair.name : "Not Specified"} />
        </Grid>
        <Grid item xs={4} md={3} className={classes.gridItem}>
          <ItemLabelRead classDescription={classes.description} classTitle={classes.title} title={"HAIR LENGTH"} description={props.pet && props.pet?.hairLength?.name ? props.pet?.hairLength?.name : "Not Specified"} />
        </Grid>
        <Grid item xs={8} md={12} className={classes.gridItem}>
          <PetPersonalities pet={props.pet} />
        </Grid>
      </Grid>

      {isMobile && <Grid xs={12} className={classes.line}></Grid>}
      <Grid container className={clsx([classes.gridItems])}>
        <Grid item xs={12}>
          <Typography className={classes.petTitle}>{props.pet.name}'s Notes</Typography>
          <PetNotes bgColor={!isMobile ? "#FAFAFA" : undefined} editable={true} hideRightDate={isTablet} widget={true} scrollable={false} pagination={true} pet={props.pet} OnShowPageSizeDropdown={props.OnShowPageSizeDropdown} OnPageSelectedChange={props.OnPageSelectedChange} onChangePetNotes={props.onNewPetNote} />
        </Grid>
      </Grid>

    </Box>);
};

export default PetInformation;
