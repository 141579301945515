import { Reducer } from "redux";
import { SMSCampaignsState, SMSCampaignsStatus } from "./SMSCampaignsState";
import {
    SMSCampaignsAction,
    SMS_CAMPAIGNS_CLIENTS_FETCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_SEARCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_DECREASE_STEP_ACTION_TYPE,
    SMS_CAMPAIGNS_DELETE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_DELETE_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_DUPLICATE_ACTION_TYPE,
    SMS_CAMPAIGNS_EDIT_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_GLOBAL_START_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_GET_START_ACTION_TYPE,
    SMS_CAMPAIGNS_GET_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_INCREASE_STEP_ACTION_TYPE,
    SMS_CAMPAIGNS_QUOTE_ACTION_TYPE,
    SMS_CAMPAIGNS_RESET_ACTION_TYPE,
    SMS_CAMPAIGNS_SAVE_RESET_ACTION_TYPE,
    SMS_CAMPAIGNS_SAVE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_SAVE_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_SET_FILTER_ACTION_TYPE,
    SMS_CAMPAIGNS_UPDATE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_UPDATE_STATUS_ACTION_TYPE,
    SMS_CAMPAIGNS_UPDATE_SUCCESS_ACTION_TYPE
} from "actions/smsCampaigns/SMSCampaignsActionsTypes";
import { SMSCampaignStatus } from "model/SMSCampaign";

const initialState: SMSCampaignsState = {
    step: 1,

    filter: SMSCampaignStatus.sent,

    created: undefined,

    status: SMSCampaignsStatus.Initial,

    clients: {}
};

export const smsCampaignsReducer: Reducer<SMSCampaignsState, SMSCampaignsAction> = (state = initialState, action) => {
    switch (action.type) {
        // Reset
        case SMS_CAMPAIGNS_RESET_ACTION_TYPE:
            return {
                ...state,
                step: 1,
                created: undefined,
                status: SMSCampaignsStatus.Cleared
            };
        // Filter
        case SMS_CAMPAIGNS_SET_FILTER_ACTION_TYPE:
            return {
                ...state,
                filter: action.payload.filter
            };
        // Fetch
        case SMS_CAMPAIGNS_FETCH_GLOBAL_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.GlobalFetching
            };
        case SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                all: {
                    ...action.payload.data,
                    page: parseInt(action.payload.data.page),
                    size: parseInt(action.payload.data.size),
                },
                status: SMSCampaignsStatus.GlobalFetchSuccess,
            };
        case SMS_CAMPAIGNS_FETCH_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.Fetching
            };
        case SMS_CAMPAIGNS_FETCH_SUCCESS_ACTION_TYPE:
            const campaigns = state.campaigns?.sms_campaigns || [];
            const page = parseInt(action.payload.data.page);

            return {
                ...state,
                campaigns: {
                    page,
                    size: parseInt(action.payload.data.size),
                    total_pages: action.payload.data.total_pages,
                    total_records: action.payload.data.total_records,
                    sms_campaigns: page === 1 ?
                        action.payload.data.sms_campaigns :
                        campaigns.concat(action.payload.data.sms_campaigns)
                },
                status: SMSCampaignsStatus.FetchSuccess,
            };
        // Getting
        case SMS_CAMPAIGNS_GET_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.Getting
            };
        case SMS_CAMPAIGNS_GET_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                detail: action.payload.campaign,
                status: SMSCampaignsStatus.GetSuccess
            };
        // Save
        case SMS_CAMPAIGNS_SAVE_RESET_ACTION_TYPE:
            return {
                ...state,
                step: 1,
                created: undefined
            };
        case SMS_CAMPAIGNS_SAVE_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.Saving
            };
        case SMS_CAMPAIGNS_SAVE_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                created: action.payload.campaign,
                status: SMSCampaignsStatus.SaveSuccess
            };
        // Update
        case SMS_CAMPAIGNS_UPDATE_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.Updating
            };
        case SMS_CAMPAIGNS_UPDATE_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                detail: action.payload.campaign,
                status: SMSCampaignsStatus.UpdateSuccess
            };
        // Delete
        case SMS_CAMPAIGNS_DELETE_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.Deleting
            };
        case SMS_CAMPAIGNS_DELETE_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                detail: undefined,
                status: SMSCampaignsStatus.DeleteSuccess
            };
        // Customers
        case SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.Saving
            }
        // Customers
        case SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                created: action.payload.campaign,
                status: SMSCampaignsStatus.SaveSuccess,
            }
        // Clients
        case SMS_CAMPAIGNS_CLIENTS_FETCH_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.ClientsFetching,
            };
        case SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS_ACTION_TYPE:
            let newCustomers = action.payload.customers

            if (!action.payload.override && state.clients.all?.list)
                newCustomers = state.clients.all?.list.concat(action.payload.customers)

            return {
                ...state,
                status: SMSCampaignsStatus.ClientsFetchSuccess,
                clients: {
                    ...state.clients,
                    all: {
                        list: newCustomers,
                        page: action.payload.page,
                        size: action.payload.size,
                        total_pages: action.payload.total_pages,
                        total_records: action.payload.total_records,
                    }
                }
            };
        case SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING_ACTION_TYPE:
            return {
                ...state,
                status: action.payload.searching ? SMSCampaignsStatus.ClientsSearching : SMSCampaignsStatus.Initial,
            };
        case SMS_CAMPAIGNS_CLIENTS_SEARCH_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.ClientsSearching,
            };
        case SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.ClientsSearchSuccess,
                clients: {
                    ...state.clients,
                    search: {
                        list: action.payload.customers,
                        page: action.payload.page,
                        size: action.payload.size,
                        total_pages: action.payload.total_pages,
                        total_records: action.payload.total_records,
                    }
                }
            };
        case SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_START_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.ClientsFetching,
            };
        case SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                status: SMSCampaignsStatus.ClientsFetchSuccess,
                groups: action.payload.groups
            };
        // Step
        case SMS_CAMPAIGNS_DECREASE_STEP_ACTION_TYPE:
            return {
                ...state,
                step: state.step - 1,
            };
        case SMS_CAMPAIGNS_INCREASE_STEP_ACTION_TYPE:
            return {
                ...state,
                step: state.step + 1,
            };
        // Status
        case SMS_CAMPAIGNS_UPDATE_STATUS_ACTION_TYPE:
            return {
                ...state,
                status: action.payload.status
            };
        // Edit
        case SMS_CAMPAIGNS_EDIT_ACTION_TYPE:
            return {
                ...state,
                step: 1,
                created: action.payload.campaign
            }
        // Duplicate
        case SMS_CAMPAIGNS_DUPLICATE_ACTION_TYPE:
            return {
                ...state,
                step: 1,
                created: {
                    status_id: SMSCampaignStatus.draft,
                    message: action.payload.campaign.message,
                    name: `Copy of ${action.payload.campaign.name}`,
                    unsubscribe_message: action.payload.campaign.unsubscribe_message,
                    editing: action.payload.campaign.editing
                }
            }
        // Quote
        case SMS_CAMPAIGNS_QUOTE_ACTION_TYPE:
            return {
                ...state,
                quote: action.payload.quote
            };
        default:
            return state;
    }
};

export default smsCampaignsReducer;
