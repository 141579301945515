import React from 'react';
import {
    Box,
    Grid,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { Link as LinkRouter } from 'react-router-dom';
import { Button, Link } from 'components/UI';
import useHubspotTrackingCode from 'hooks/useHubspotTrackingCode';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#222',
            width: '100%',
            borderRadius: 16,
            padding: '27px 25px',
            backgroundColor: '#F8F5F1',
            overflow: 'hidden',

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center'
            },

            [theme.breakpoints.up('md')]: {
                padding: '42px 35px',
                backgroundSize: '30% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center right',
                backgroundImage: 'url(/images/cuddlespay/flyer-bg.png)'
            },
            [theme.breakpoints.up('lg')]: {
                backgroundSize: '40% auto'
            }
        },
        logo: {
            width: 145,
            display: 'inline-block'
        },
        title: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.2,

            [theme.breakpoints.up('md')]: {
                fontSize: 24
            }
        },
        body: {
            fontSize: 14,
            lineHeight: 1.4,

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        image: {
            width: '110%',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        footer: {
            'gap': 10,
            'display': 'flex',

            '& button:first-child': {
                'height': 47,
                'borderRadius': 30,
                'padding': '0px 18px',

                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },

                '& label': {
                    fontSize: 16,
                    fontWeight: 400,

                    [theme.breakpoints.up('md')]: {
                        fontWeight: 500
                    }
                }
            },

            [theme.breakpoints.down('sm')]: {
                'justifyContent': 'center',
                'flexDirection': 'column',
                '& button span': {
                    fontSize: '16px !important',
                    fontWeight: '500 !important'
                }
            }
        }
    })
);

export const PaymentsFlyer: React.FunctionComponent = () => {
    const classes = useStyles();
    const { setTrackEvent } = useHubspotTrackingCode();
    const learnMoreHandler = () => {
        setTrackEvent(
            `${process.env.REACT_APP_HUBSPOT_EVENT_CUDDLESPAY_LEARN}`
        );
        window.open(process.env.REACT_APP_PAYMENTS_LEARN_MORE_URL);
    };

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <img
                        src="/images/logo/cuddlespay.svg"
                        className={classes.logo}
                        alt="Logo Cuddles Pay"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        Fast, secure and easy payments
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.body}>
                        Only 2.5% + $0.20 per transaction, both online and
                        in-store.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <img
                        src="/images/cuddlespay/flyer-bg.png"
                        alt=""
                        className={classes.image}
                    />
                </Grid>
                <Grid item xs={12} className={classes.footer}>
                    <LinkRouter to={'/payments'}>
                        <Button color="green" label="Add Cuddles Pay" />
                    </LinkRouter>
                    <Link
                        onClick={learnMoreHandler}
                        color="black"
                        label="Learn More"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
