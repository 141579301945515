import React, { FunctionComponent } from "react";
import TwoWaySMSComponent from "components/SMS/TwoWay";
import PrivatePage from "./PrivatePage";

export const TwoWaySMS: FunctionComponent = () => {
  return (
    <PrivatePage title="Two Way SMS - Cuddles" pageName="/sms">
      <TwoWaySMSComponent />
    </PrivatePage>
  );
};

export default TwoWaySMS;
