import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Table as TableMUI,
  TableContainer,
} from "@material-ui/core";
import clsx from "clsx";
import { Spinner } from "components/UI";

interface Props {
  /* search:JSX.Element; */
  head: JSX.Element;
  body: JSX.Element;
  loading?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      overflowX: "visible",
    },
    loading: {
      paddingTop: "150px",
    }
  })
);

export const Table: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const table = (
    <TableContainer className={clsx(classes.container, props.className)}>
      <TableMUI>
        {/* {props.search} */}
        {props.head}
        {props.body}
      </TableMUI>
    </TableContainer>
  );
  return (props.loading ? <Spinner className={classes.loading} /> : table);

};

export default Table;
