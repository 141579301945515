import { Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ItemLabelRead } from "components/UI/ItemLabelRead";
import { PetVaccine } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { VaccinesTableRow } from "./VaccinesTableRow";

interface VaccinesTableProps {
  vaccines: Array<PetVaccine>;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px !important",
      },
    },
    containerVaccinesLabel:{
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    }
  })
);

export const VaccinesTable: FunctionComponent<VaccinesTableProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(props.className)}>
      <Grid container className={classes.containerVaccinesLabel}>
        <Grid item xs={3}>
          <ItemLabelRead title="Vaccine" classTitle={classes.header} />
        </Grid>
        <Grid item xs={3}>
          <ItemLabelRead title="Expiration" classTitle={classes.header} />
        </Grid>
        <Grid item xs={4}>
          <ItemLabelRead title="Status" classTitle={classes.header} />
        </Grid>
        <Grid item xs={2}>
          <ItemLabelRead title="Records" classTitle={classes.header} />
        </Grid>
      </Grid>
      <Grid container>
        {props.vaccines.map((vaccine, index) => (
          <VaccinesTableRow vaccine={vaccine} last={index + 1 === props.vaccines.length} />
        ))}
      </Grid>
    </Box>
  );
};

export default VaccinesTable;
