import { Action } from "redux";
import Availability, { FilterAvailability } from "model/Availability";
import { AvailabilityBooking } from "./AvailabilityDto"

//Action Types
export const AVAILABILITY_RESET_ACTION_TYPE = "AVAILABILITY_RESET";
export const AVAILABILITY_FETCH_START_ACTION_TYPE = "AVAILABILITY_FETCH_START";
export const AVAILABILITY_STAFF_FETCH_START_ACTION_TYPE = "AVAILABILITY_STAFF_FETCH_START";
export const AVAILABILITY_FETCH_BOOKING_START_ACTION_TYPE = "AVAILABILITY_FETCH_BOOKING_START";
export const AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE = "AVAILABILITY_FETCH_SUCCESS";
export const AVAILABILITY_STAFF_FETCH_SUCCESS_ACTION_TYPE = "AVAILABILITY_STAFF_FETCH_SUCCESS";
export const AVAILABILITY_FETCH_BOOKING_SUCCESS_ACTION_TYPE = "AVAILABILITY_FETCH_BOOKING_SUCCESS";
export const FILTER_AVAILABILITY_FETCH_START_ACTION_TYPE = "FILTER_AVAILABILITY_FETCH_START";
export const FILTER_AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE = "FILTER_AVAILABILITY_FETCH_SUCCESS";
export const AVAILABILITY_ERROR_ACTION_TYPE = "AVAILABILITY_ERROR";


//Actions
interface AvailabilityResetAction extends Action<typeof AVAILABILITY_RESET_ACTION_TYPE> { }

interface AvailabilityFetchStartAction extends Action<typeof AVAILABILITY_FETCH_START_ACTION_TYPE> {payload: {
  requestToken: string;
} }
interface AvailabilityFetchBookingStartAction extends Action<typeof AVAILABILITY_FETCH_BOOKING_START_ACTION_TYPE> { payload: {
  requestToken: string;
}}

interface FilterAvailabilityFetchStartAction extends Action<typeof FILTER_AVAILABILITY_FETCH_START_ACTION_TYPE> { }
interface AvailabilityFetchSuccessAction extends Action<typeof AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    availability: Availability;
  };
}

interface AvailabilityStaffFetchStartAction extends Action<typeof AVAILABILITY_STAFF_FETCH_START_ACTION_TYPE> { }
interface AvailabilityStaffFetchSuccessAction extends Action<typeof AVAILABILITY_STAFF_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    availability: Availability;
  };
}

interface AvailabilityFetchBookingSuccessAction extends Action<typeof AVAILABILITY_FETCH_BOOKING_SUCCESS_ACTION_TYPE> {
  payload: {
    availabilityBooking: AvailabilityBooking;
  };
}

interface FilterAvailabilityFetchSuccessAction extends Action<typeof FILTER_AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    filterAvailability: FilterAvailability;
  };
}

interface AvailabilityErrorAction extends Action<typeof AVAILABILITY_ERROR_ACTION_TYPE> { }

export type AvailabilityAction =
  AvailabilityResetAction
  | AvailabilityFetchStartAction
  | AvailabilityFetchSuccessAction
  | FilterAvailabilityFetchStartAction
  | FilterAvailabilityFetchSuccessAction
  | AvailabilityStaffFetchStartAction
  | AvailabilityStaffFetchSuccessAction
  | AvailabilityFetchBookingStartAction
  | AvailabilityFetchBookingSuccessAction
  | AvailabilityErrorAction;
