import { faCamera, faSearch } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, ImageUriUpload, Option } from '@spike/model';
import clsx from 'clsx';
import Section from 'components/Products/UI/Section';
import {
    Button,
    DescriptionField2,
    NumberField2,
    PriceField2,
    SelectField,
    TextField2
} from 'components/UI';
import { AntSwitch } from 'components/UI/AntSwitch';
import { BodySection } from 'components/UI/Section';
import { TaxesSelector } from 'components/UI/TaxesSelector';
import { useMasterData } from 'hooks';
import clone from 'lodash/clone';
import set from 'lodash/set';
import { MarketplaceTax } from '@spike/marketplace-model';
import {
    Product,
    WholeProduct,
    createBusinessProduct
} from '@spike/product-model';
import { FunctionComponent, useState } from 'react';
import { Img } from 'react-image';
import ProductCategoryComponent from './ProductCategoryComponent';
import LookupProduct from './UI/LookupProduct';
import isEmpty from 'lodash/isEmpty';
import { getUnit } from '../../Utils';
import { v4 as uuid } from 'uuid';
import ImageUpload from 'components/UI/ImageUpload';
import Switch from 'components/UI/V2/Switch';

interface Props {
    product: Product;
    showLookup?: boolean;
    errors: Array<FieldError>;
    withVendors: boolean;
    editable: boolean;
    collapsed: boolean;
    onChange?: (product: Product) => void;
    onAddCategory: () => void;
    onAddVendor: () => void;
    onLookup: () => void;
    onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                paddingRight: '18px',
                paddingLeft: '37px',
                minHeight: '480px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingRight: '22px',
                paddingLeft: '45px',
                minHeight: '600px'
            }
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '20%'
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '80%'
        },
        nameContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                marginBottom: '39px'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '48px'
            }
        },
        descriptionContainer: {
            display: 'flex',
            width: '100%'
        },
        barcodeCategoryContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%'
        },
        description: {
            '& .MuiOutlinedInput-multiline': {
                [theme.breakpoints.down('md')]: {
                    height: '137px'
                },
                [theme.breakpoints.up('lg')]: {
                    height: '173px'
                }
            }
        },
        imageBox: {
            [theme.breakpoints.down('md')]: {
                width: '118px',
                height: '118px',
                borderRadius: '8px'
            },
            [theme.breakpoints.up('lg')]: {
                width: '147px',
                height: '147px',
                borderRadius: '10px'
            }
        },
        uploadBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: '#E9F0ED',
            [theme.breakpoints.down('md')]: {
                borderRadius: '8px'
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: '10px'
            }
        },
        uploadIcon: {
            color: '#5E8677',
            [theme.breakpoints.down('md')]: {
                fontSize: '38px',
                lineHeight: '24px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '47px',
                lineHeight: '30px'
            }
        },
        titleSection: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '30px'
        },
        informationSection: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: '15px',
                lineHeight: '19px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '18px',
                lineHeight: '24px'
            }
        },
        lookup: {
            cursor: 'pointer',
            fontWeight: 500,
            color: '#EAB464',
            [theme.breakpoints.down('md')]: {
                fontSize: '13px',
                lineHeight: '19px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px',
                lineHeight: '24px'
            }
        },
        iconSearch: {
            [theme.breakpoints.down('md')]: {
                marginRight: '3px',
                marginTop: '3px'
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: '4px',
                marginTop: '4px'
            }
        },
        horizontalContainer: {
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                marginBottom: '39px'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '48px'
            }
        },
        paddingRight: {
            [theme.breakpoints.down('md')]: {
                paddingRight: '16px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingRight: '20px'
            }
        },
        iconInfo: {
            color: '#5E8677',
            [theme.breakpoints.down('md')]: {
                marginTop: '2px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '3px'
            }
        },
        messageContainer: {
            display: 'flex',
            position: 'absolute',
            background: '#ffffff',
            zIndex: 300,
            border: '1px solid #000',
            [theme.breakpoints.down('md')]: {
                borderRadius: '4px',
                padding: '9px 11px',
                marginLeft: '112px',
                marginTop: '-40px'
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: '6px',
                padding: '12px 14px',
                marginLeft: '140px',
                marginTop: '-50px'
            }
        },
        message: {
            [theme.breakpoints.down('md')]: {
                fontSize: '11px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '14px'
            }
        },
        titleManufacturer: {
            fontWeight: 500
        },
        manufacturerTitleContainer: {
            display: 'flex'
        },
        pricingContainer: {
            [theme.breakpoints.down('md')]: {
                marginTop: '37px',
                marginBottom: '39px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '45px',
                marginBottom: '48px'
            }
        },
        marginNone: {
            marginBottom: '0px !important'
        },
        checkout: {
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            },
            '& .MuiSwitch-root': {
                [theme.breakpoints.down('md')]: {
                    width: '30px',
                    height: '16px',
                    borderRadius: '16px'
                },
                [theme.breakpoints.up('lg')]: {
                    width: '36px',
                    height: '20px',
                    borderRadius: '20px'
                }
            },
            '& .MuiSwitch-thumb': {
                [theme.breakpoints.down('md')]: {
                    width: '13px',
                    height: '12px'
                },
                [theme.breakpoints.up('lg')]: {
                    width: '17px',
                    height: '16px'
                }
            }
        },
        checkoutPadding: {
            '&.MuiSwitch-track': {
                backgroundColor: '#5E8677'
            },
            [theme.breakpoints.down('md')]: {
                paddingRight: '5px',
                paddingLeft: '11px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingRight: '7px',
                paddingLeft: '14px'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                marginBottom: '39px'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '48px'
            }
        },
        amount: {
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        },
        iconAsterisk: {
            color: '#EAB464',
            [theme.breakpoints.down('md')]: {
                marginRight: '6px'
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: '8px'
            }
        }
    })
);

export const Details: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();

    const isTablet = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('md')
    );
    const isNetbook = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    const [hover, setHover] = useState(false);

    const [showLookup, setShowLookup] = useState(false);

    const [productImage, setProductImage] = useState(props.product.imageUrl);

    const unitOptions: Array<Option<number>> = masterData.productUnits.map(
        unit => ({
            id: unit.id,
            name: unit.name,
            description: unit.unit
        })
    );

    const changeHandler = (value: any, field: string) => {
        const updatedProduct = clone(props.product);
        set(updatedProduct.businessProduct!, field, value);

        if (updatedProduct.createdFromScratch) {
            set(updatedProduct, field, value);
        }

        if (field === 'size.unit' && value.id === WholeProduct.unit?.id) {
            updatedProduct.businessProduct!.size!.amount = undefined;
        }

        props.onChange && props.onChange(updatedProduct);
    };

    const imageChangeHandler = (
        image: string,
        imageFileToUpload: File | undefined,
        imageRemoved: boolean | undefined
    ) => {
        setProductImage(image);
        const updatedProduct = clone(props.product);

        updatedProduct.businessProduct!.imageUrl = image;
        updatedProduct.businessProduct!.imageFile = imageFileToUpload;
        updatedProduct.businessProduct!.deleteImage = imageRemoved;
        props.onChange && props.onChange(updatedProduct);
    };

    const availableForCheckoutChangeHandler = () => {
        const updatedProduct = clone(props.product);
        updatedProduct.businessProduct!.availableForCheckout =
            !updatedProduct.businessProduct!.availableForCheckout;
        props.onChange && props.onChange(updatedProduct);
    };

    const priceChangeHandler = (price: number | undefined) => {
        const updatedProduct = clone(props.product);
        updatedProduct.businessProduct!.pricing.price = price;

        if (updatedProduct.createdFromScratch) {
            updatedProduct.price = price || 0;
        }

        props.onChange && props.onChange(updatedProduct);
    };

    const taxesChangeHandler = (taxes: Array<MarketplaceTax>): void => {
        const updatedProduct = clone(props.product);
        updatedProduct.businessProduct!.pricing.taxes = [...taxes];
        props.onChange && props.onChange(updatedProduct);
    };

    const selectProductHandler = (product: Product) => {
        const newProduct = {
            ...product,
            createdFromScratch: false,
            businessProduct: createBusinessProduct(uuid, product)
        };
        props.onChange && props.onChange(newProduct);
        setShowLookup(false);
    };

    const manufacturerHeader = (
        <Box className={classes.manufacturerTitleContainer}>
            <Typography className={classes.titleManufacturer}>
                Manufacturer
            </Typography>
            <Typography className={classes.iconAsterisk}>*</Typography>
            <FontAwesomeIcon
                className={classes.iconInfo}
                icon={faInfoCircle}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            />
            {hover && (
                <Box className={classes.messageContainer}>
                    <Typography className={classes.message}>
                        The name of the business or
                        <br /> company which makes goods
                        <br /> in large quantities to sell. May
                        <br /> be the same or different name
                        <br /> from the brand itself.
                    </Typography>
                </Box>
            )}
        </Box>
    );

    return (
        <Section
            position={1}
            title="Product Details"
            {...props}
            onEdit={() => props.onEdit()}
        >
            <BodySection>
                <Box className={classes.container}>
                    <Box className={classes.leftContainer}>
                        <ImageUpload
                            squared={true}
                            small={isTablet || isNetbook ? true : false}
                            imageUrl={productImage}
                            onChangeImage={imageChangeHandler}
                        ></ImageUpload>
                    </Box>
                    <Box className={classes.rightContainer}>
                        <Box className={classes.titleSection}>
                            <Typography className={classes.title}>
                                Primary Information
                            </Typography>
                            {props.showLookup && (
                                <Box
                                    className={classes.horizontalContainer}
                                    onClick={() => setShowLookup(true)}
                                >
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className={clsx(
                                            classes.lookup,
                                            classes.iconSearch
                                        )}
                                    />
                                    <Typography className={classes.lookup}>
                                        Lookup product
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        {!showLookup && (
                            <Box className={classes.informationSection}>
                                <Box className={classes.nameContainer}>
                                    <TextField2
                                        label="Product Name"
                                        name="name"
                                        value={
                                            isEmpty(
                                                props.product.businessProduct
                                                    ?.name
                                            )
                                                ? props.product.name
                                                : props.product.businessProduct
                                                    ?.name || ''
                                        }
                                        placeholder="Product Name"
                                        required={true}
                                        onChange={changeHandler}
                                        capitalize={true}
                                        errors={props.errors}
                                    />
                                </Box>
                                <Box className={classes.horizontalContainer}>
                                    <Box
                                        className={clsx(
                                            classes.barcodeCategoryContainer,
                                            classes.paddingRight
                                        )}
                                    >
                                        <TextField2
                                            label="Barcode"
                                            name="code"
                                            value={
                                                props.product?.businessProduct
                                                    ?.code || ''
                                            }
                                            placeholder="Barcode"
                                            onChange={changeHandler}
                                            errors={props.errors}
                                        />
                                    </Box>
                                    <Box
                                        className={
                                            classes.barcodeCategoryContainer
                                        }
                                    >
                                        <ProductCategoryComponent
                                            categorySelected={
                                                props.product.category
                                            }
                                            onChange={category =>
                                                changeHandler(
                                                    category,
                                                    'category'
                                                )
                                            }
                                            onAdd={props.onAddCategory}
                                            error={props.errors.some(
                                                error =>
                                                    error.fieldName ===
                                                    'category'
                                            )}
                                            disabled={
                                                props.product.id !==
                                                undefined &&
                                                props.product.category !==
                                                undefined
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.horizontalContainer}>
                                    <Box
                                        className={clsx(
                                            classes.paddingRight,
                                            classes.barcodeCategoryContainer
                                        )}
                                    >
                                        <TextField2
                                            label={manufacturerHeader}
                                            name="manufacturer"
                                            value={
                                                isEmpty(
                                                    props.product
                                                        .businessProduct
                                                        ?.manufacturer
                                                )
                                                    ? props.product.manufacturer
                                                    : props.product
                                                        .businessProduct
                                                        ?.manufacturer || ''
                                            }
                                            placeholder="Manufacturer"
                                            onChange={changeHandler}
                                            capitalize={true}
                                            errors={props.errors}
                                        />
                                    </Box>
                                    <Box
                                        className={
                                            classes.barcodeCategoryContainer
                                        }
                                    >
                                        <TextField2
                                            label="Brand"
                                            name="brand"
                                            value={
                                                isEmpty(
                                                    props.product
                                                        .businessProduct?.brand
                                                )
                                                    ? props.product.brand
                                                    : props.product
                                                        .businessProduct
                                                        ?.brand || ''
                                            }
                                            placeholder="Brand"
                                            onChange={changeHandler}
                                            capitalize={true}
                                            required={true}
                                            errors={props.errors}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.horizontalContainer}>
                                    <Box
                                        className={clsx(
                                            classes.paddingRight,
                                            classes.barcodeCategoryContainer
                                        )}
                                    >
                                        <SelectField
                                            label="Unit Measurement"
                                            name="size.unit"
                                            placeholder="Select Measurement"
                                            options={unitOptions}
                                            selectedOption={
                                                props.product.businessProduct
                                                    ?.size?.unit
                                            }
                                            onSelect={(option, name) =>
                                                changeHandler(option, name!)
                                            }
                                            errors={props.errors}
                                        />
                                    </Box>
                                    <Box
                                        className={
                                            classes.barcodeCategoryContainer
                                        }
                                    >
                                        {props.product.businessProduct?.size &&
                                            props.product.businessProduct?.size
                                                ?.unit?.id !==
                                            WholeProduct.unit?.id && (
                                                <NumberField2
                                                    label="Amount"
                                                    name="size.amount"
                                                    value={
                                                        props.product
                                                            .businessProduct
                                                            ?.size?.amount
                                                    }
                                                    placeholder="0.00"
                                                    onChange={changeHandler}
                                                    endIcon={
                                                        <Typography>
                                                            {getUnit(
                                                                props.product
                                                                    .businessProduct,
                                                                masterData
                                                            )}
                                                        </Typography>
                                                    }
                                                    scale={2}
                                                />
                                            )}
                                    </Box>
                                </Box>
                                <Box className={classes.descriptionContainer}>
                                    <DescriptionField2
                                        label="Product Description"
                                        name="description"
                                        value={
                                            props.product.businessProduct
                                                ?.description || ''
                                        }
                                        onChange={changeHandler}
                                        maxCharacters={400}
                                        placeholder="Give a brief overview that describes your product’s best features. "
                                        className={classes.description}
                                    />
                                </Box>
                            </Box>
                        )}
                        {!showLookup && (
                            <Box className={classes.informationSection}>
                                <Box
                                    className={clsx(
                                        classes.titleSection,
                                        classes.pricingContainer
                                    )}
                                >
                                    <Typography className={classes.title}>
                                        Pricing
                                    </Typography>
                                    <Box
                                        className={clsx(
                                            classes.horizontalContainer,
                                            classes.marginNone
                                        )}
                                    >
                                        <Typography
                                            className={clsx(
                                                classes.checkout,
                                                classes.checkoutPadding
                                            )}
                                        >
                                            Available for checkout?
                                        </Typography>
                                        <Switch
                                            name="product.businessProduct.availableForCheckout"
                                            onCheck={availableForCheckoutChangeHandler}
                                            checked={props.product.businessProduct?.availableForCheckout || false}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.horizontalContainer}>
                                    <Box
                                        className={clsx(
                                            classes.paddingRight,
                                            classes.barcodeCategoryContainer
                                        )}
                                    >
                                        <PriceField2
                                            label="Retail Price"
                                            name="price"
                                            value={
                                                props.product.businessProduct
                                                    ?.pricing?.price
                                            }
                                            placeholder="0.00"
                                            onChange={priceChangeHandler}
                                            scale={2}
                                        />
                                    </Box>
                                </Box>
                                <TaxesSelector
                                    checked={
                                        (props.product.businessProduct?.pricing
                                            ?.taxes?.length || 0) > 0
                                    }
                                    selectedTaxes={
                                        props.product.businessProduct?.pricing
                                            ?.taxes ?? []
                                    }
                                    onChange={taxesChangeHandler}
                                    title="Taxable Product"
                                ></TaxesSelector>
                                <Box className={classes.buttonContainer}>
                                    {!props.withVendors && (
                                        <Button
                                            label="+ Add Vendors"
                                            color="green"
                                            onClick={() => props.onAddVendor()}
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                {showLookup && (
                    <LookupProduct
                        onSelect={selectProductHandler}
                        onClose={() => setShowLookup(false)}
                    />
                )}
            </BodySection>
        </Section>
    );
};

export default Details;
