import { Reducer } from 'redux';
import { SubscriptionsState, SubscriptionsStatus } from './SubscriptionsState';
import {
	SUBSCRIPTIONS_DEACTIVATE_START_ACTION_TYPE,
	SUBSCRIPTIONS_DEACTIVATE_SUCCESS_ACTION_TYPE,
	SUBSCRIPTIONS_FETCH_START_ACTION_TYPE,
	SUBSCRIPTIONS_FETCH_SUCCESS_ACTION_TYPE,
	SUBSCRIPTIONS_RESET_ACTION_TYPE,
	SUBSCRIPTIONS_SAVE_START_ACTION_TYPE,
	SUBSCRIPTIONS_SAVE_SUCCESS_ACTION_TYPE,
	SUBSCRIPTION_GET_PAYMENT_METHOD_START_ACTION_TYPE,
	SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS_ACTION_TYPE,
	SUBSCRIPTION_INVOICES_START_ACTION_TYPE,
	SUBSCRIPTION_INVOICES_SUCCESS_ACTION_TYPE,
	SUBSCRIPTION_QUOTE_START_ACTION_TYPE,
	SUBSCRIPTION_QUOTE_SUCCESS_ACTION_TYPE,
	SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE,
	SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE,
	SubscriptionsAction,
	SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE,
	SUBSCRIPTIONS_PURGE_START_ACTION_TYPE,
	SUBSCRIPTIONS_PURGE_SUCCESS_ACTION_TYPE
} from 'actions/subscriptions/SubscriptionsActionsTypes';

const initialState: SubscriptionsState = {
	loading: false,
	invoices: [],
	subscriptions: [],
	created: undefined,
	quote: undefined,
	paymentMethod: undefined,
	showPopup: false,
	status: SubscriptionsStatus.Initial
};

export const subscriptionsReducer: Reducer<
	SubscriptionsState,
	SubscriptionsAction
> = (state = initialState, action) => {
	switch (action.type) {
		case SUBSCRIPTIONS_RESET_ACTION_TYPE:
			return {
				...initialState
			};
		case SUBSCRIPTIONS_FETCH_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				status: SubscriptionsStatus.Fetching
			};
		case SUBSCRIPTIONS_DEACTIVATE_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				status: SubscriptionsStatus.Deactivating
			};
		case SUBSCRIPTIONS_DEACTIVATE_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				status: SubscriptionsStatus.DeactivateSuccess
			};
		case SUBSCRIPTIONS_FETCH_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				subscriptions: action.payload.subscriptions,
				status: SubscriptionsStatus.FetchSuccess
			};
		case SUBSCRIPTIONS_SAVE_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				created: undefined,
				status: SubscriptionsStatus.Saving
			};
		case SUBSCRIPTIONS_SAVE_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				created: action.payload.subscription,
				status: SubscriptionsStatus.SaveSuccess
			};
		case SUBSCRIPTION_QUOTE_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				status: SubscriptionsStatus.FetchingQuote
			};
		case SUBSCRIPTION_QUOTE_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				quote: action.payload.quote,
				status: SubscriptionsStatus.FetchQuoteSuccess
			};
		case SUBSCRIPTION_INVOICES_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				status: SubscriptionsStatus.FetchingInvoices
			};
		case SUBSCRIPTION_INVOICES_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				invoices: action.payload.invoices,
				status: SubscriptionsStatus.FetchInvoicesSuccess
			};
		case SUBSCRIPTION_GET_PAYMENT_METHOD_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				status: SubscriptionsStatus.GettingPaymentMethod
			};
		case SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				paymentMethod: action.payload.paymentMethod,
				status: SubscriptionsStatus.GetPaymentMethodSuccess
			};
		case SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE:
			return {
				...state,
				loading: true,
				status: SubscriptionsStatus.GettingSMSMessagesStats
			};
		case SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false,
				stats: action.payload.stats,
				status: SubscriptionsStatus.GetSMSMessagesStatsSuccess
			};
		case SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE:
			return {
				...state,
				showPopup: action.payload.open || !state.showPopup
			};
		case SUBSCRIPTIONS_PURGE_START_ACTION_TYPE:
			return {
				...state,
				loading: true
			};
		case SUBSCRIPTIONS_PURGE_SUCCESS_ACTION_TYPE:
			return {
				...state,
				loading: false
			};
		default:
			return state;
	}
};

export default subscriptionsReducer;
