import {
  OnboardingStepsState,
  OnboardingStepsStatus,
} from "./onboardingStepsState";
import {
  OnboardingStepsAction,
  ONBOARDING_STEPS_RESET_ACTION_TYPE,
  ONBOARDING_STEPS_CLEAR_ACTION_TYPE,
  ONBOARDING_STEPS_FETCH_ACTION_TYPE,
  ONBOARDING_STEPS_FETCH_SUCCESS_ACTION_TYPE,
  ONBOARDING_STEPS_ERROR_ACTION_TYPE,
  ONBOARDING_STEPS_OPEN_MODAL_ACTION_TYPE,
  ONBOARDING_STEPS_CLOSE_MODAL_ACTION_TYPE,
  ONBOARDING_STEPS_SHOW_VIDEO_ACTION_TYPE,
  ONBOARDING_STEPS_HIDE_VIDEO_ACTION_TYPE,
  ONBOARDING_STEPS_SET_ACTION_TYPE,
  ONBOARDING_STEPS_SET_SUCCESS_ACTION_TYPE,
} from "../../actions/onboardingSteps/OnboardingStepsActionsTypes";
import { Reducer } from "redux";

const onboardingStepsInit = {
  businessHoursDone: false,
  clientsDone: false,
  petTypesDone: false,
  servicesDone: false,
  teamDone: false,
};

const initialState: OnboardingStepsState = {
  onboardingSteps: onboardingStepsInit,
  status: OnboardingStepsStatus.Initial,
  loading: false,
  openModal: false,
  showVideo: false,
  videoURL: "",
};

export const onboardingStepsReducer: Reducer<
  OnboardingStepsState,
  OnboardingStepsAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_STEPS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case ONBOARDING_STEPS_CLEAR_ACTION_TYPE:
      return {
        ...state,
        status: OnboardingStepsStatus.Cleared,
        onboardingSteps: onboardingStepsInit,
        loading: false,
      };
    case ONBOARDING_STEPS_FETCH_ACTION_TYPE:
      return {
        ...state,
        status: OnboardingStepsStatus.Fetching,
        loading: true,
      };
    case ONBOARDING_STEPS_SET_ACTION_TYPE:
      return {
        ...state,
        status: OnboardingStepsStatus.Setting,
        loading: true,
      };
    case ONBOARDING_STEPS_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: OnboardingStepsStatus.FetchSuccess,
        loading: false,
        onboardingSteps: action.payload.onboardingSteps,
      };
    case ONBOARDING_STEPS_SET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: OnboardingStepsStatus.SetSuccess,
        loading: false,
        onboardingSteps: action.payload.onboardingSteps,
      };
    case ONBOARDING_STEPS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: OnboardingStepsStatus.Error,
        loading: false,
      };
    case ONBOARDING_STEPS_OPEN_MODAL_ACTION_TYPE:
      return {
        ...state,
        openModal: true,
      };
    case ONBOARDING_STEPS_CLOSE_MODAL_ACTION_TYPE:
      return {
        ...state,
        openModal: false,
      };
    case ONBOARDING_STEPS_SHOW_VIDEO_ACTION_TYPE:
      return {
        ...state,
        videoURL: action.payload,
        showVideo: true,
      };
    case ONBOARDING_STEPS_HIDE_VIDEO_ACTION_TYPE:
      return {
        ...state,
        showVideo: false,
        videoURL: "",
      };
    default:
      return state;
  }
};

export default onboardingStepsReducer;
