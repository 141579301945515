import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option, Period } from '@spike/model';
import { CalendarFilter } from 'components/UI';
import Filter from 'components/UI/Filter';
import { FunctionComponent } from 'react';
import { CSVLink } from 'react-csv';
import { Data, Headers } from 'react-csv/lib/core';
import ExportButton from 'components/UI/ExportCSV/ExportButton';

interface DetailTableHeaderProps {
    period: Period;
    staffOptions: Option<number>[];
    className?: string;
    export?: boolean;
    csv?: {
        fileName?: string | undefined;
        data: string | Data | (() => string | Data);
        headers?: Headers | undefined;
    };
    onChangePeriod: (period: Period) => void;
    onStaffChange: (staffs: Array<number>, all: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            gap: '20px',
            display: 'flex',
            borderRadius: 12,
            padding: '12px 16px',
            backgroundColor: '#F1F1F1',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
                marginTop: 24,
                padding: '14px 20px'
            },
            [theme.breakpoints.down('sm')]: {
                gap: '0px !important',
                flexDirection: 'row'
            }
        },
        leftTab: {
            display: 'flex',
            gap: '20px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-between',
                gap: '7px'
            }
        },

        calendar: {
            '& > button': {
                'height': 39,
                'borderRadius': 30,
                'color': '#222222',
                'border': '2px solid #222222',
                'backgroundColor': 'white',

                [theme.breakpoints.up('md')]: {
                    height: 43
                },

                '&:hover': {
                    backgroundColor: 'white'
                }
            }
        },
        staffFilter: {
            'height': 39,
            'paddingTop': 0,
            'paddingLeft': 13,
            'paddingRight': 13,
            'paddingBottom': 0,
            'borderRadius': 30,
            'backgroundColor': '#FFF',
            'border': '2px solid #000000',

            [theme.breakpoints.down('sm')]: {
                'maxWidth': '50%',

                '& .MuiButton-label': {
                    '& span:not(.MuiButton-startIcon)': {
                        width: '100%',

                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }
            },
            [theme.breakpoints.up('md')]: {
                height: 43,
                backgroundColor: '#FFFFFF'
            },

            '& button': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                }
            },
            '& svg': {
                marginRight: 4
            },

            '& .MuiButton-outlined': {
                border: 'none !important'
            },
            '& .MuiButton-startIcon': {
                marginRight: 6
            }
        },
        csv: {
            'textDecoration': 'none',
            [theme.breakpoints.down('sm')]: {
                //width: "45%"
            },
            '& button': {
                [theme.breakpoints.down('sm')]: {
                    'height': 39,

                    '& span': {
                        // !important used due MUI adds negative margin
                        margin: '0px !important'
                    },
                    '& svg': {
                        width: 10,
                        height: 16,
                        marginRight: 4
                    }
                }
            }
        },
        csvButton: {
            [theme.breakpoints.down('sm')]: {
                'height': 39,
                'minWidth': 'unset',
                'borderRadius': 30,
                'width': 39,
                'padding': 0,
                '& span': {
                    lineHeight: 1,
                    margin: '0px !important'
                },
                '& svg': {
                    width: 16,
                    height: 'auto',
                    marginRight: '0px !important'
                },
                '& .MuiButton-startIcon': {
                    marginLeft: '2px !important'
                }
            }
        }
    })
);

export const DetailTableHeader: FunctionComponent<DetailTableHeaderProps> = props => {
    const classes = useStyles();
    const staffsLength = 0;

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const onStaffChange = (ids: Array<number | undefined>, all: boolean) => {
        const staffIds: number[] = ids.filter((id): id is number => id !== undefined);

        const allStaffSelected = staffsLength === staffIds.length;

        props.onStaffChange(allStaffSelected ? [] : staffIds, all);
    };

    return (
        <Grid container className={props.className}>
            <Grid container xs={12} className={classes.bar}>
                <Box className={classes.leftTab}>
                    <CalendarFilter
                        hideUpcoming
                        hideAllTime
                        dropdownAlignment="left"
                        period={{
                            from: props.period.from,
                            to: props.period.to
                        }}
                        optionSelected="This month"
                        onChange={period => props.onChangePeriod({ ...period })}
                        disableFuture={true}
                        showLabelOnMobile={true}
                        className={classes.calendar}
                    />
                    <Filter
                        label="Staff"
                        icon={faUsers}
                        options={props.staffOptions}
                        onSelect={onStaffChange}
                        showLabelOnMobile={true}
                        className={classes.staffFilter}
                    />
                </Box>
                {props.export && props.csv && (
                    <CSVLink
                        data={props.csv.data}
                        headers={props.csv.headers}
                        enclosingCharacter=""
                        separator=","
                        filename={props.csv.fileName}
                        target="_blank"
                        className={classes.csv}
                    >
                        <ExportButton />
                    </CSVLink>
                )}
            </Grid>
        </Grid>
    );
};

export default DetailTableHeader;
