import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import {
  Select as MUISelect,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Option } from "@spike/model";
import { commonStyles } from "Theme";

export interface Props {
  label: string;
  value?: string | number;
  options: Array<Option<string | number>>;
  onChange: (value: string | number) => void;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-input": {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "31px",
        paddingRight: "31px",
      },
      "& .MuiSelect-select:focus": {
        background: "none !important",
      },
    },
    chevron: {
      marginTop: "5px",
      marginLeft: "20px",
      marginRight: "20px",
    },
    menuItem: {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
      },
    }
  })
);

const Chevron: FunctionComponent = () => {
  const classes = useStyles();
  
  return (
    <FontAwesomeIcon
      icon={faChevronDown}
      className={clsx(
        "MuiSelect-icon",
        "MuiSelect-iconOutlined",
        classes.chevron
      )}
    />
  );
};

//Deprecated use SelectNew
export const Select: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [error, setError] = useState(props.error);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);

  useEffect(() => {
    setError(props.error);
    setErrorMessage(props.errorMessage);
  }, [props.error, props.errorMessage]);

  const options = props.options.map((option) => (
    <MenuItem value={option.id} key={option.id} className={classes.menuItem}>
      {option.name}
    </MenuItem>
  ));

  const label = (): string => {
    return error && errorMessage ? errorMessage : props.label;
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    let value: string | number = event.target.value as string|number;
    setError(false);
    setErrorMessage(undefined);
    props.onChange(value);
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(commonClasses.field, classes.formControl, props.className)}
      error={error}
      disabled={props.disabled}
    >
      <InputLabel>{label()}</InputLabel>
      <MUISelect value={props.value} onChange={handleChange} IconComponent={Chevron}>
        {options}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
