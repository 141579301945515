import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import AddOnService from "@spike/addon-service-model";
import { FunctionComponent } from "react";
import Row from "./Row";

interface TableProps {
  showStatus?: boolean;
  services: Array<AddOnService>;
  onEdit: (service: AddOnService) => void;
  onDelete: (service: AddOnService) => void;
  onChangeStatus: (changedService: AddOnService) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flex: 1,
      backgroundColor: "#fff",
    },
    root: {
      border: 0,
      width: "100%",
      textAlign: "left",
      borderCollapse: "collapse",
      [theme.breakpoints.down('sm')]: {
        borderCollapse: 'initial',
        borderTop: '2px inset #f9f9f930'
      },
      "& td, & th": {
        padding: "0px 10px",
      },
    },
    head: {
      backgroundColor: "#FAFAFA",
      [theme.breakpoints.down('sm')]: {
        visibility: 'collapse',
        display: 'none'
      },
      "& th": {
        height: 50,
        borderBottom: "solid 2px #000",

        "&:first-child": {
          [theme.breakpoints.down(768)]: {
            paddingLeft: 20,
          },
          [theme.breakpoints.between(768, "sm")]: {
            paddingLeft: 30,
          },
          [theme.breakpoints.only("md")]: {
            paddingLeft: 50,
          },
          [theme.breakpoints.only("lg")]: {
            paddingLeft: 60,
          },
          [theme.breakpoints.only("xl")]: {
            paddingLeft: 90,
          },
        },
      },
    },
  })
);

const Table: FunctionComponent<TableProps> = (props) => {
  const classes = useStyles();
  const { innerWidth: width } = window;

  return (
    <Box className={classes.wrapper}>
      <table className={classes.root}>
        <thead className={classes.head}>
          <tr>
            <th>Name</th>
            <th>Price</th>
            {width > 500 && <th>Taxes</th>}
            {width > 500 && props.showStatus && <th>Status</th>}

            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.services.map((service) => (
            <Row key={service.uuid} service={service} showStatus={props.showStatus} onEdit={() => props.onEdit(service)} onDelete={() => props.onDelete(service)} onChangeStatus={active => props.onChangeStatus({...service, active})}/>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Table;
