import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, MouseEvent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface Chip2Props {
    label: string;
    icon?: IconDefinition;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: (event: MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            justifyConteent: 'center',
            cursor: 'pointer',
            borderRadius: 100,
            backgroundColor: '#F1F1F1',

            [theme.breakpoints.up('md')]: {
                padding: '8px 10px 8px 10px'
            }
        },
        selected: {
            'backgroundColor': '#92B4A7',
            '& p': {
                color: 'white'
            }
        },
        label: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            color: '#222222'
        },
        icon: {
            color: '#222',
            fontSize: 16,
            fontWeight: 500,
            marginRight: 4
        },
        selectedIcon: {
            color: '#fff'
        },
        disabled: {
            opacity: 0.5,
            cursor: 'not-allowed',
            pointerEvents: 'none'
        }
    })
);

export const Chip2: FunctionComponent<Chip2Props> = props => {
    const classes = useStyles();

    return (
        <Box
            className={clsx(
                classes.chip,
                {
                    [classes.selected]: props.selected,
                    [classes.disabled]: props.disabled
                },
                props.className
            )}
            onClick={props.disabled ? undefined : props.onClick}
        >
            {props.icon && (
                <FontAwesomeIcon
                    className={clsx(classes.icon, {
                        [classes.selectedIcon]: props.selected
                    })}
                    icon={props.icon}
                />
            )}
            <Typography className={classes.label}>{props.label}</Typography>
        </Box>
    );
};

export default Chip2;
