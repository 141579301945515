import {
  ADD_NOTIFICATION_BELL_ACTION_TYPE,
  NotificationBellAction,
  REMOVE_NOTIFICATION_BELL_ACTION_TYPE,
  RESET_NOTIFICATION_BELL_ACTION_TYPE,
  SET_NOTIFICATION_BELL_ACTION_TYPE,
} from "./NotificationBellActionsTypes";

export const addNotificationBell = (
  payload?: number
): NotificationBellAction => {
  return {
    type: ADD_NOTIFICATION_BELL_ACTION_TYPE,
    payload,
  };
};
export const removeNotificationBell = (
  payload?: number
): NotificationBellAction => {
  return {
    type: REMOVE_NOTIFICATION_BELL_ACTION_TYPE,
    payload,
  };
};
export const setNotificationBellAmount = (
  payload: number
): NotificationBellAction => {
  return { type: SET_NOTIFICATION_BELL_ACTION_TYPE, payload };
};

export const reset = (): NotificationBellAction => {
  return { type: RESET_NOTIFICATION_BELL_ACTION_TYPE };
};
