import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { FunctionComponent } from 'react';
import Button from './Button';

interface DeleteButtonProps {
	size?: number;
	className?: string;
	onClick?: () => void;
}

export const DeleteButton: FunctionComponent<DeleteButtonProps> = props => {
	return (
		<Button
			icon={faTrashCan}
			className={props.className}
			size={props.size ?? 15}
			onClick={props.onClick}
		/>
	);
};

export default DeleteButton;
