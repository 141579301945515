import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CalculatingSpinner } from 'components/UI';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface TotalItemProps {
	label: string;
	value: string | number;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: {
			height: 50,
			width: '100%',
			display: 'flex',
			borderRadius: 10,
			padding: '0px 16px',
			alignItems: 'center',
			justifyContent: 'space-between',
			backgroundColor: '#92B4A7',

			[theme.breakpoints.up(wbp)]: {
				height: 62,
				borderRadius: 15,
				padding: '0px 22px'
			}
		},
		text: {
			fontWeight: 600,
			color: 'white',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(20)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px'
			}
		}
	})
);

export const TotalItem: FunctionComponent<TotalItemProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.row, props.className)}>
			<Typography className={classes.text}>{props.label}</Typography>
			{props.loading ? (
				<CalculatingSpinner />
			) : (
				<Typography className={classes.text}>{props.value}</Typography>
			)}
		</Box>
	);
};

export default TotalItem;
