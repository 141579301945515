import {
	faFileSignature,
	faFileTimes,
	faPaperPlane
} from '@fortawesome/pro-regular-svg-icons';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import {
	fetchSignaturesByAgreementThunk,
	getSummaryThunk
} from 'actions/agreements/AgreementsActions';
import { Spinner } from 'components/UI';
import { AgreementSummary, Signature } from 'model/Agreements';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import SendModal from '../Send/SendModal';
import AgreementInfo from './AgreementInfo';
import Header from './Header';
import Metrics from './Metrics';
import { Metric } from './model';
import Search from '../ui/Search';
import SignaturesTable from '../Table/SignaturesTable';

interface AgreementSignaturesProps {
	agreementId: number;
	onBack?: () => void;
}

enum Filters {
	total = 'Total Sent',
	signed = 'Signed',
	unsigned = 'Unsigned'
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column'
		},
		wrapper: {
			width: '100%',
			paddingBottom: 45,
			// paddingTop: 25,
			backgroundColor: '#FAFAFA',
			display: 'flex',
			flexDirection: 'column',
			gap: 45,
			paddingTop: 50,
			paddingLeft: 32,
			paddingRight: 32,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 16,
				paddingRight: 16
			},
			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#fff',
				paddingTop: 16
			},
			[theme.breakpoints.down('xs')]: {
				paddingTop: 16
			}
		},
		selectedMetric: {
			fontSize: 20,
			fontWeight: 600,
			[theme.breakpoints.down('md')]: {
				fontSize: 18
			}
		},

		tableWrapper: {
			'transition': 'all 0.3s linear',
			'maxHeight': '70vh',
			'overflowY': 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none'
			},
			[theme.breakpoints.down('md')]: {
				height: '65dvh',
				paddingBottom: 55
			}
		}
	})
);

const AgreementSignatures: FunctionComponent<
	AgreementSignaturesProps
> = props => {
	const classes = useStyles();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const dispatch = useDispatch();

	const [metricFilter, setMetricFilter] = useState<Metric | null>(null);

	const summary = useSelector<RootState, AgreementSummary | undefined>(
		state => state.agreements.summary
	);
	const agreementsStatus = useSelector<RootState, AgreementsStatus>(
		state => state.agreements.status
	);
	const signatures = useSelector<RootState, Array<Signature>>(
		state => state.agreements.signaturesByAgreement
	);

	const metrics: Array<Metric> = [
		{
			id: Filters.total,
			value: summary?.sent || 0,
			color: '#FAEFDF',
			icon: faPaperPlane,
			title: 'Total Sent'
		},
		{
			id: Filters.signed,
			value: summary?.signed || 0,
			color: '#E9F0ED',
			icon: faFileSignature,
			title: 'Signed'
		},
		{
			id: Filters.unsigned,
			value: summary?.unsigned || 0,
			color: '#F4F3F0',
			icon: faFileTimes,
			title: 'Unsigned'
		}
	];

	const [loadingSummary, setLoadingSummary] = useState(true);
	const [loadingSignatures, setLoadingSignatures] = useState(false);
	const [searchTokens, setSearchTokens] = useState<Array<string>>([]);
	const [send, setSend] = useState(false);

	useEffect(() => {
		dispatch(getSummaryThunk(props.agreementId));
	}, []);

	useNonInitialEffect(() => {
		switch (agreementsStatus) {
			case AgreementsStatus.GetSummarySuccess:
				setLoadingSummary(false);
				break;
			case AgreementsStatus.FetchSignaturesByAgreementSuccess:
				setLoadingSignatures(false);
				break;
			case AgreementsStatus.SendSuccess:
				reloadHandler();
				break;
			case AgreementsStatus.SignSuccess:
				reloadHandler();
				break;
			case AgreementsStatus.DeleteSignatureSuccess:
				reloadHandler();
				break;
			case AgreementsStatus.Error:
				setLoadingSummary(false);
				setLoadingSignatures(false);
				break;
		}
	}, [agreementsStatus]);

	const reloadHandler = () => {
		dispatch(getSummaryThunk(props.agreementId));
		if (metricFilter !== null) {
			changeMetricHandler({ ...metricFilter });
		}
	};

	const changeMetricHandler = (metric: Metric) => {
		setLoadingSignatures(true);
		setMetricFilter({ ...metric });
		switch (metric.id) {
			case Filters.total:
				dispatch(fetchSignaturesByAgreementThunk(props.agreementId));
				break;
			case Filters.signed:
				dispatch(
					fetchSignaturesByAgreementThunk(props.agreementId, 'signed')
				);
				break;
			case Filters.unsigned:
				dispatch(
					fetchSignaturesByAgreementThunk(
						props.agreementId,
						'unsigned'
					)
				);
				break;
		}
	};

	return (
		<>
			<Box
				className={classes.root}
				style={{ display: send && isMobile ? 'none' : 'block' }}
			>
				<Header
					agreement={summary?.agreement}
					onBack={props.onBack}
					onSend={() => setSend(true)}
				/>
				{loadingSummary && <Spinner />}
				{!loadingSummary && (
					<Box className={classes.wrapper}>
						<Metrics
							metrics={metrics}
							onChange={changeMetricHandler}
						/>
						{!metricFilter && (
							<AgreementInfo agreement={summary?.agreement} />
						)}
						<Typography className={classes.selectedMetric}>
							{metricFilter?.title}
						</Typography>

						{metricFilter && (
							<Search
								onSearch={searchText =>
									setSearchTokens(
										searchText
											.split(' ')
											.filter(token => token.length > 0)
											.map(token => token.toLowerCase())
									)
								}
							/>
						)}
					</Box>
				)}

				{!loadingSummary && metricFilter && (
					<Box className={classes.tableWrapper}>
						<SignaturesTable
							signatures={signatures.filter(
								signature =>
									searchTokens.length === 0 ||
									searchTokens.every(
										token =>
											signature.client.firstName
												.toLowerCase()
												.indexOf(token) > -1 ||
											signature.client.lastName
												.toLowerCase()
												.indexOf(token) > -1
									)
							)}
							hideStatus={metricFilter.id !== Filters.total}
							hideSignedDate={
								metricFilter.id === Filters.unsigned
							}
							loading={loadingSignatures}
						/>
					</Box>
				)}
			</Box>
			{send && (
				<SendModal
					isActive={send}
					agreementId={props.agreementId}
					onClose={() => setSend(false)}
				/>
			)}
		</>
	);
};

export default AgreementSignatures;
