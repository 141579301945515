import ProductCategory from "model/ProductCategory";
import { ProductCategoryDto } from "./ProductCategoriesDtos";
import { convertToProduct } from "@spike/products-action";
import { store } from "store";

export const convertToProductCategory = (categoryDto: ProductCategoryDto): ProductCategory => {
  return {
    id: categoryDto.id,
    uuid: categoryDto.uuid,
    name: categoryDto.name,
    description: categoryDto.description,
    custom: !categoryDto.system_only,
    products: categoryDto.products.map(product => convertToProduct(product, store.getState().masterData.data, store.getState().marketplace.marketplace!)),
    active: categoryDto.active,
  };
};
