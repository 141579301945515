import { FunctionComponent, useState } from "react";
import {
  Box, Typography, OutlinedInput
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  text:string;
  onChange: (text:string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    title: {
      fontWeight: 500,
      marginBottom: "11px",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
      },
    },
    description: {
      fontWeight: 300,
      [theme.breakpoints.down("md")]: {
        fontSize: "18px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "22px"
      },
      borderRadius: "100px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !important",
        borderColor: "#222222 !important",
      },
      "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !imporant",
        borderColor: "#222222 !important",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EA6464 !important",
      },
      "& input": {
        paddingTop: "0px",
        paddingBottom: "0px",
        fontFamily: "Poppins",
        [theme.breakpoints.down("md")]: {
          paddingRight: "30px",
          paddingLeft: "30px",
          height: "42px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingRight: "35px",
          paddingLeft: "35px",
          height: "53px",
        },
      },   
      "& legend": {
        maxWidth: 0, //Avoid the label border interuption.
      },
    },
    nameContainer: {
      display: "flex"
    },
    required: {
      color: "#EAB464"
    }
  })
);

export const CategoryName: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const[text, setText] = useState(props.text);

  const handleChange = (event: any): void => {
    setText(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.nameContainer}>
        <Typography className={classes.title}>
          Category Name
        </Typography>
      </Box>
      <OutlinedInput
        label={""}
        value={text}
        onChange={handleChange}
        className={classes.description}
        placeholder={"Name"}
      />
    </Box>
  );
};

export default CategoryName;
