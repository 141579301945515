import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import useNonInitialEffect from "@versiondos/hooks";
import { getInvoiceThunk } from "@spike/invoices-action";
import clsx from "clsx";
import { BackButton, Spinner } from "components/UI";
import InvoiceModel from "@spike/invoice-model";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvoicesStatus } from "@spike/invoices-action";
import { RootState } from "store";
import InvoicePdf from "./InvoicePdf";
import { wbp } from "Theme";
import { useApiClientWrapper } from "hooks";

interface DisplayInvoiceProps {
  invoiceId?: number;
  pdfUrl?: string;
  className?: string;
  onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "max-content",
      minHeight: "100%",
      backgroundColor: "#FBFBFB",
      paddingTop: "70px",
      paddingBottom: "70px",
    },
    leftColumn: {
      display: "flex",
      justifyContent: "flex-end",
      flexGrow:4,
    },
    centerColumn: {
      display: "flex",
      justifyContent: "center",
      flexGrow: 6,
    },
    rightColumn: {
      display: "flex",
      flexGrow: 2,
    },
    back: {
      [theme.breakpoints.down(wbp)]: {
        width: "32px !important",
        height: "32px !important",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "40px !important",
        height: "40px !important",
      },
    },
  })
);

export const DisplayInvoice: FunctionComponent<DisplayInvoiceProps> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();
  
  const [url, setUrl] = useState(props.pdfUrl);
  const invoice = useSelector<RootState, InvoiceModel | undefined>((state) => state.invoices.invoice);
  const invoiceStatus = useSelector<RootState, InvoicesStatus>((state) => state.invoices.status);

  useEffect(() => {
    if (props.invoiceId && !props.pdfUrl) {
      dispatch(getInvoiceThunk(apiClientWrapper, props.invoiceId!));
    }
  }, []);

  useNonInitialEffect(() => {
    if (InvoicesStatus.GetInvoiceSuccess === invoiceStatus && !props.pdfUrl) {
      setUrl(invoice!.pdfUrl);
    }
  }, [invoiceStatus]);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.leftColumn}>
        <BackButton onClick={() => props.onBack && props.onBack()} />
      </Box>
      <Box className={classes.centerColumn}>
          {!url && <Spinner />}
          {url && <InvoicePdf url={url} />}
      </Box>
      <Box className={classes.rightColumn}></Box>
    </Box>
  );
};

export default DisplayInvoice;
