import ApiClient from "api";
import { ThunkAction } from "redux-thunk";
import { HubSpotAction } from "./HubSpotActionsTypes";
import SignUp from "model/SignUp";
import { MarketplaceBasics } from "@spike/marketplace-model";
import { useHubspotToken } from "hooks/useHubspotToken";

const baseUrl = process.env.REACT_APP_HUBSPOT_API_URL;
const signupFormUrl = `${baseUrl}/forms/sign-up`;
const businessBasicsUrl = `${baseUrl}/forms/business-settings`;
const activateAccountUrl = `${baseUrl}/contacts/activate`;
const OTHER_SOFTWARE_ID = 'other';

export const signupHubspot = (
	signUp: SignUp,
	marketplace_id: number
): ThunkAction<void, any, null, HubSpotAction> => {
	return async () => {
		const hutk = useHubspotToken();

		try {
			await ApiClient.post(signupFormUrl, {
				marketplace_id: marketplace_id,
				first_name: signUp.firstName,
				last_name: signUp.lastName,
				email: signUp.email.trim().toLowerCase(),
				password: signUp.password,
				terms: signUp.terms,
				privacy: signUp.privacy,
				business_name: signUp.businessName,
				business_type: signUp.businessType,
				phone_number: signUp.phoneNumber,
				promocode: signUp.promocode,
				address: signUp.address,
				suite: signUp.suite,
				city: signUp.city,
				state: signUp.state,
				zipcode: signUp.zipcode,
				hear_about_us: signUp.hearAboutUs,
				sms_marketing_optin: signUp.smsMarketingOptIn,
				country: signUp.country,
				locations: signUp.locations?.id,
				groomers: signUp.groomers?.id,
				software: signUp.software?.id?.toLowerCase() === OTHER_SOFTWARE_ID.toLowerCase() ? (signUp.otherSoftware ?? signUp.software?.id) : signUp.software?.id,
				services: signUp.services.map(service => service.id),
				new_business: signUp.newBusiness,
				page_name: "Sign Up Page",
				page_uri: "https://market.cuddlesapp.com/signup",
				hutk,
			});
		} catch (apiError: any) {}
	};
};

export const activateAccountHubspot = (
	email: string
): ThunkAction<void, any, null, HubSpotAction> => {
	return async () => {
		try {
			await ApiClient.post(activateAccountUrl, { email });
		} catch (apiError: any) {}
	};
};

export const businessBasicSettingsHubspot = (
	basics: MarketplaceBasics
): ThunkAction<void, any, null, HubSpotAction> => {
	return async () => {
		const hutk = useHubspotToken();

		try {
			await ApiClient.post(businessBasicsUrl, {
				marketplace: {
					business_name: basics.businessName,
					phone: basics.phone,
					email: basics.email,
					website: basics.website,
					addresses_attributes: [
						{ address_line_one: basics.address.street },
						{ address_line_two: basics.address.suite },
						{ city: basics.address.city },
						{ state: basics.address.state },
						{ zipcode: basics.address.zipcode },
						{ country: "US" },
					],
				},
				page_name: "Business Settings Page",
				page_uri:
					"https://market.cuddlesapp.com/business_settings/info/basics",
				hutk,
			});
		} catch (apiError: any) {}
	};
};
