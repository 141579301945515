import ProductCategory from "model/ProductCategory";

export interface ProductCategoriesState {
  list: Array<ProductCategory>;
  savedCategory: ProductCategory | undefined;
  category: ProductCategory | undefined;
  status: ProductCategoriesStatus;
  loading: boolean;
}

export enum ProductCategoriesStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Getting,
  GetSuccess,
  Saving,
  SaveSuccess,
  SavingProducts,
  SaveProductsSuccess,
  Deleting,
  DeleteSuccess,
  Error,
  DeletingProducts,
  DeleteProductsSuccess,
}
