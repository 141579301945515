import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Box, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import Sidebar, { SidebarItem, SidebarSection } from "components/UI/Sidebar/Sidebar";
import PersonalInfo from "./PersonalInfo";
import Schedule from "./Schedule";
import Security from "./Security";
import Staff, { StaffPerson, StaffEmergencyContact, StaffSchedule } from "model/Staff";
import { getStaffThunk, saveStaffThunk } from "actions/staff/StaffActions";
import { useAuth } from "hooks";
import { Spinner } from "components/UI/Spinner";
import { changePasswordUserThunk } from "actions/users/UsersActions";
import {
  openMyAccountSidebar,
  closeMyAccountSidebar
} from './actions/MyAccountSidebarActions';

export interface Props {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
      borderTop: "2px solid #F1F1F1",
      background: "white",
    },
    sidebarContainer: {
      display: "flex",
      borderRight: "2px solid #F1F1F1",
      [theme.breakpoints.down("sm")]: {
        width: "97px",
        paddingTop: "13px",
        paddingLeft: "22px",
        paddingRight: "12px",
      },
      [theme.breakpoints.only("md")]: {
        width: "157px",
        paddingTop: "21px",
        paddingLeft: "35px",
        paddingRight: "20px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "209px",
        paddingTop: "28px",
        paddingLeft: "47px",
        paddingRight: "26px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "316px",
        paddingTop: "42px",
        paddingLeft: "71px",
        paddingRight: "40px",
      },
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        paddingTop: '13px',
        paddingLeft: '15px',
        paddingRight: '15px',
        position: 'fixed',
        left: '-100%',
        visibility: 'hidden',
        top: '50px',
        height: 'calc(100vh - 50px)',
        background: '#fff',
        zIndex: 9,
        transition: 'all .3s',
        overflowY: 'scroll',
        paddingBottom: '180px',
        display: 'block'
      },
    },
    sidebarContainerDefault: {
      left: '0px !important',
      transition: 'none'
    },
    sidebarContainerMain: {
      transition: 'none !important'
    },
    sidebarContainerVisible: {
      [theme.breakpoints.down('sm')]: {
        left: '0px !important',
        visibility: 'visible !important'
      }
    },
    bodyContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: "19px",
        paddingLeft: "15px",
        paddingRight: "15px",
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        height: "calc(100vh - 60px)",
        position: "relative",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "31px",
        paddingLeft: "149px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "41px",
        paddingLeft: "198px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "62px",
        paddingLeft: "300px",
      },
    },
    sidebarMyAccount: {
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        "& > p": {
          textAlign: "center",
        },
      }
    }
  })
);

export const MyAccount: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const auth = useAuth();

  const member = useSelector<RootState, Staff | undefined>((state) => state.staff.member);
  const dispatch = useDispatch();
  const theme = useTheme();

  const personalInfoItem = { id: "personal", name: "Personal" };
  const scheduleItem = { id: "schedule", name: "Schedule" };
  const securityItem = { id: "security", name: "Security" };

  const [selectedSidebarItem, setSelectedSidebarItem] = useState(personalInfoItem);

  
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [bodyClass, setBodyClass] = useState('');

  const isOpenMyAccountSideBar = useSelector(
    (state: RootState) =>
      state.MyAccountSidebar.isOpenMyAccountSideBar
  );


  useEffect(() => {
    // Check if the query parameter "?open=menu" is present
    const isOpenFromQueryParam = location.search.includes('open=menu');

    dispatch(
      isOpenFromQueryParam
        ? openMyAccountSidebar()
        : closeMyAccountSidebar()
    );
    setBodyClass(isOpenFromQueryParam ? 'prevent-scroll' : '');
  }, [location.search, dispatch]);

  useEffect(() => {
    const body = document.body;
    body.classList.toggle('prevent-scroll', isOpenMyAccountSideBar);
    body.style.overflow = isOpenMyAccountSideBar ? 'hidden' : '';
    body.style.height = isOpenMyAccountSideBar ? '100%' : '';

    return () => {
      body.style.overflow = '';
      body.style.height = '';
      body.classList.remove('prevent-scroll');
    };
  }, [isOpenMyAccountSideBar]);

  

  const openSidebar = () => {
    dispatch(openMyAccountSidebar());
  };

  const closeSidebar = () => {
    dispatch(closeMyAccountSidebar());
  };

  useEffect(() => {
    dispatch(getStaffThunk(auth!.user!.staffId));
  }, []);

  const accountItems: SidebarSection = {
    title: "ACCOUNT",
    icon: faUser,
    items: [personalInfoItem, scheduleItem, securityItem],
  };

  const selectSidebareItemHandler = (item: SidebarItem) => {
    setSelectedSidebarItem(item);
  };

  const save = (staff: Staff) => {
    dispatch(saveStaffThunk(staff));
  };

  const savePersonalInfoHandler = (person: StaffPerson, emergencyContact: StaffEmergencyContact) => {
    save({ ...member!, person, emergencyContact });
  };

  const saveScheduleHandler = (schedule: StaffSchedule) => {
    save({ ...member!, schedule });
  };

  const saveSecurityHandler = (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    dispatch(changePasswordUserThunk(auth.user!.id, oldPassword, newPassword, confirmNewPassword));
  };

  const sidebar = (
    <Sidebar
      title={`${member?.person.firstName} ${member?.person.lastName}`}
      subtitle={`${member?.role?.name}`}
      sections={[accountItems]}
      selectedItemId={selectedSidebarItem.id}
      onSelect={selectSidebareItemHandler}
      avatar={member?.person.avatar || ""}
      onClickItemHandler={isMobile ? closeSidebar : undefined}
      className={classes.sidebarMyAccount}
      popUpOnMobile={true}
    />
  );
  
  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={clsx(classes.sidebarContainer, {
        [classes.sidebarContainerVisible]:
          isMobile && isOpenMyAccountSideBar
      })}>{sidebar}</Box>
      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        <Box className={classes.bodyContainer}>
          {!member && <Spinner />}
          {member && selectedSidebarItem.id === personalInfoItem.id && <PersonalInfo openSidebar={openSidebar} person={member!.person} emergencyContact={member!.emergencyContact} onSave={savePersonalInfoHandler} />}
          {member && selectedSidebarItem.id === scheduleItem.id && <Schedule schedule={member?.schedule} staffId={member.id!} onSave={saveScheduleHandler} />}
          {member && selectedSidebarItem.id === securityItem.id && <Security openSidebar={openSidebar} onSave={saveSecurityHandler} />}
        </Box>
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default MyAccount;
