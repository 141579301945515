import { FunctionComponent } from 'react';
import { Box, makeStyles, Typography, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { PetType } from '@spike/model';
import PetTypeSelectorItem from './PetTypeSelectorItem';
import PetTypeUniqueItem from './PetTypeUniqueItem';

interface PetTypeSelectorProps {
    petTypes: Array<PetType>;
    selectedPetType: PetType;
    onSelect: (petType: PetType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center'
    },
    uniqueItemContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 10
    },
    selectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        color: '#000',
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    },
    singleSizeDescription: {
        fontSize: 14,
        fontWeight: 300,
        color: '#7A7A7A'
    },
    buttonsContainer: {
        gap: 15,
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    }
}));

const PetTypeSelector: FunctionComponent<PetTypeSelectorProps> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const selectPetTypeHandler = (petType: PetType) => {
        props.onSelect && props.onSelect(petType);
    };

    return (
        <Box className={classes.container}>
            {props.petTypes.length > 1 && !isMobile && (
                <Typography className={classes.title}>Select Pet Type</Typography>
            )}
            <Box className={classes.selectorContainer}>
                {props.petTypes.length > 1 ? (
                    props.petTypes.map(petType => (
                        <PetTypeSelectorItem
                            key={petType.id}
                            petType={petType}
                            onSelect={selectPetTypeHandler}
                            selectedPetType={props.selectedPetType}
                        />
                    ))
                ) : (
                    <Box className={classes.uniqueItemContainer}>
                        <PetTypeUniqueItem petType={props.petTypes[0]!} />
                        <Box>
                            <Typography className={classes.title}>Pricing for {props.petTypes[0]!.plural}</Typography>
                            <Typography className={classes.singleSizeDescription}>
                                {props.petTypes[0]!.singular} are based in a single size
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PetTypeSelector;
