import {
    Box,
    createStyles,
    FormLabel,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography
} from '@material-ui/core';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { saveThunk } from 'actions/agreements/AgreementsActions';
import clsx from 'clsx';
import QuillContent from 'components/UI/QuillContent';
import QuillEditor from 'components/UI/QuillEditor';
import { WizardItem } from 'components/Wizard';
import { useMarketplace } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import { Agreement, createEmptyAgreement } from 'model/Agreements';
import WizardPage from 'pages/WizardPage';
import { Fragment, FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import AgreementSendOption from './AgreementSendOption';
import { sendToDescriptions } from './model';
import { useMasterData } from 'hooks';
import PortalModal from 'components/UI/Portals';

interface AgreementsEditModalProps {
    agreement?: Agreement;
    isActive: boolean;
    onClose?: () => void;
}

const headerItems: Array<WizardItem> = [
    {
        title: 'New Agreement'
    },
    {
        title: 'Preview & Save'
    }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#222',
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: '20px',
            marginLeft: '-24px',
            overflowY: 'scroll'
        },
        input: {
            'width': '100%',
            '& .MuiOutlinedInput': {
                '&-root': {
                    borderRadius: 30
                },
                '&-input': {
                    padding: '18px 35px'
                }
            },
            '& .MuiFormLabel': {
                '&-filled': {
                    display: 'none'
                },
                '&-asterisk': {
                    color: '#EAB464'
                }
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(30px, 19px) scale(1)'
            },
            '& fieldset': {
                'top': 0,
                'borderColor': 'rgba(0, 0, 0, 0.23) !important',

                '& legend': {
                    display: 'none'
                }
            },
            '& .Mui-focused': {
                'color': 'rgba(0, 0, 0, 0.54)',

                '& fieldset': {
                    borderWidth: '1px !important'
                }
            }
        },
        group: {
            'marginBottom': 35,
            [theme.breakpoints.down('md')]: {
                marginBottom: 25
            },
            '& > label': {
                color: '#222',
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 600,
                marginBottom: 20,
                display: 'block'
            },
            '& > p': {
                fontSize: 15,
                lineHeight: 1.4,
                fontWeight: 400,
                marginBottom: 30
            }
        },
        content: {
            fontSize: 18,
            lineHeight: 1.4,
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
                maxWidth: 'calc(100vw - 70px) !important'
            }
        },
        quillContent: {
            borderRadius: 12,
            padding: '35px 30px',
            border: '1px solid #D4D4D4',
            [theme.breakpoints.down('md')]: {
                fontSize: 13
            }
        },
        radioGroup: {
            'marginTop': 40,
            '& label': {
                marginBottom: 15
            }
        },
        radioOption: {
            'color': '#222',
            'height': '100%',
            'cursor': 'pointer',
            'padding': '20px 15px',
            'display': 'flex',
            'alignItems': 'flex-start',
            'borderRadius': 12,
            'backgroundColor': '#fff',
            'border': '1px solid #D3D3D3',

            '& .MuiRadio-root': {
                padding: 0,
                color: '#222',
                marginRight: 7
            },
            '& p': {
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 500,
                marginBottom: 9
            },
            '& span': {
                fontSize: 14,
                lineHeight: 1.2,
                fontWeight: 400,
                display: 'block'
            }
        },
        inputLabel: {
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        inputText: {
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        container: {
            minHeight: '52vh'
        },
        wrapper: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }
    })
);

const errorStyle = {
    color: '#EF4F57',
    fontSize: '0.875rem',
    marginLeft: '16px',
    fontWeight: 400
};

export const AgreementsEditModal: FunctionComponent<
    AgreementsEditModalProps
> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const sentToOptions = useMasterData().agreementsSendTo.map(option => ({
        ...option,
        description: sendToDescriptions.get(option.id)
    }));

    const agreementsStatus = useSelector<RootState, AgreementsStatus>(
        state => state.agreements.status
    );

    const [agreement, setAgreement] = useState<Agreement>(
        props.agreement
            ? props.agreement
            : createEmptyAgreement(marketplace.id!)
    );
    const [step, setStep] = useState<number>(1);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    useNonInitialEffect(() => {
        switch (agreementsStatus) {
            case AgreementsStatus.SaveSuccess:
                setSaving(false);
                setStep(1);
                setAgreement(createEmptyAgreement(marketplace.id!));
                props.onClose && props.onClose();
                break;
            case AgreementsStatus.Error:
                setSaving(false);
                break;
        }
    }, [agreementsStatus]);

    const nextHandler = () => {
        const errors = validate(agreement);
        setErrors(errors);
        if (errors.length === 0) {
            setStep(prev => prev + 1);
        }
    };

    const saveHandler = () => {
        const errors = validate(agreement);
        setErrors(errors);
        if (errors.length === 0) {
            setSaving(true);
            dispatch(saveThunk(agreement));
        }
    };

    const validate = (agreement: Agreement): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (isEmpty(agreement.title)) {
            errors.push({
                fieldName: 'title',
                errorMessage: 'Agreement Title is required'
            });
        }

        if (isEmpty(agreement.content)) {
            errors.push({
                fieldName: 'content',
                errorMessage: 'Agreement Content is required'
            });
        }

        if (isEmpty(agreement.sendTo)) {
            errors.push({
                fieldName: 'sendTo',
                errorMessage: 'One option must be selected.'
            });
        }

        return errors;
    };

    const closeHandler = () => {
        setStep(1);
        setAgreement(createEmptyAgreement(marketplace.id!));
        props.onClose && props.onClose();
    };

    return (
        <PortalModal
            mode="fullModal"
            isActive={props.isActive}
            onClose={closeHandler}
        >
            <Box className={classes.wrapper}>
                <WizardPage
                    isLoading={saving}
                    step={step}
                    items={headerItems}
                    onSave={saveHandler}
                    onNext={nextHandler}
                    onBack={() => setStep(prev => prev - 1)}
                    closePath=""
                    isFooter={true}
                    onClickClosePath={closeHandler}
                >
                    <Grid container justifyContent="center">
                        <Grid item md={6} xs={12}>
                            {step === 1 && (
                                <Fragment>
                                    <Box className={classes.group}>
                                        <FormLabel>Agreement Title</FormLabel>
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.inputLabel
                                            }}
                                            InputProps={{
                                                className: classes.inputText
                                            }}
                                            required
                                            value={agreement.title}
                                            name="title"
                                            variant="outlined"
                                            label="Agreement Title"
                                            className={classes.input}
                                            onChange={event =>
                                                setAgreement(prev => ({
                                                    ...prev,
                                                    title: event.currentTarget
                                                        .value
                                                }))
                                            }
                                        />
                                        <div
                                            style={{
                                                display: errors.some(
                                                    error =>
                                                        error.fieldName ===
                                                        'title'
                                                )
                                                    ? 'inline-block'
                                                    : 'none',
                                                ...errorStyle,
                                                paddingTop: '20px',
                                                marginLeft: '0px'
                                            }}
                                        >
                                            {
                                                errors.find(
                                                    error =>
                                                        error.fieldName ===
                                                        'title'
                                                )?.errorMessage
                                            }
                                        </div>
                                    </Box>
                                    <Box className={classes.group}>
                                        <FormLabel>Agreement Content</FormLabel>
                                        <QuillEditor
                                            value={agreement.content}
                                            onChange={value =>
                                                setAgreement(prev => ({
                                                    ...prev,
                                                    content: value
                                                        .replace('<p>', '')
                                                        .replace('</p>', '')
                                                        .replace('<br>', '')
                                                        .trim()
                                                }))
                                            }
                                        />
                                        <div
                                            style={{
                                                display: errors.some(
                                                    error =>
                                                        error.fieldName ===
                                                        'content'
                                                )
                                                    ? 'inline-block'
                                                    : 'none',
                                                ...errorStyle,
                                                paddingTop: '20px',
                                                marginLeft: '0px'
                                            }}
                                        >
                                            {
                                                errors.find(
                                                    error =>
                                                        error.fieldName ===
                                                        'content'
                                                )?.errorMessage
                                            }
                                        </div>
                                    </Box>

                                    <Box
                                        className={clsx(
                                            classes.group,
                                            classes.radioGroup
                                        )}
                                    >
                                        <FormLabel>
                                            When should this agreement be sent?{' '}
                                        </FormLabel>
                                        <Typography>
                                            Choose when the client should sign
                                            this agreement.
                                        </Typography>

                                        <Grid container spacing={3}>
                                            {sentToOptions.map(option => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    lg={4}
                                                    key={option.id}
                                                >
                                                    <AgreementSendOption
                                                        option={option}
                                                        selected={
                                                            option.id ===
                                                            agreement.sendTo
                                                        }
                                                        onSelect={() =>
                                                            setAgreement(
                                                                prev => ({
                                                                    ...prev,
                                                                    sendTo: option.id
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>

                                        <div
                                            style={{
                                                display: errors.some(
                                                    error =>
                                                        error.fieldName ===
                                                        'sendTo'
                                                )
                                                    ? 'inline-block'
                                                    : 'none',
                                                ...errorStyle,
                                                paddingTop: '20px',
                                                marginLeft: '0px'
                                            }}
                                        >
                                            {
                                                errors.find(
                                                    error =>
                                                        error.fieldName ===
                                                        'sendTo'
                                                )?.errorMessage
                                            }
                                        </div>
                                    </Box>
                                </Fragment>
                            )}

                            {step === 2 && (
                                <Box className={classes.container}>
                                    <Box className={classes.group}>
                                        <FormLabel>Agreement Title</FormLabel>
                                        <Box className={classes.content}>
                                            {agreement.title}
                                        </Box>
                                    </Box>
                                    <Box className={classes.group}>
                                        <FormLabel>
                                            When should this agreement be sent?
                                        </FormLabel>
                                        <Box className={classes.content}>
                                            {
                                                sentToOptions.find(
                                                    option =>
                                                        option.id ===
                                                        agreement.sendTo
                                                )?.name
                                            }
                                        </Box>
                                    </Box>

                                    <Box className={classes.group}>
                                        <FormLabel>Agreement Content</FormLabel>
                                        <QuillContent
                                            className={classes.quillContent}
                                            content={agreement.content}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </WizardPage>
            </Box>
        </PortalModal>
    );
};
export default AgreementsEditModal;
