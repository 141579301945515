import React, { FunctionComponent, useRef, useState, Fragment } from 'react';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import { useMasterData } from 'hooks';
import { CropperImage } from 'components/UI';

interface Props {
	onSingleImageChange?: (singleImage: boolean) => void;
	images: Array<string>;
	onChangeImage?: (images: Array<string>, files: Array<File | null>) => void;
	isGrooming?: boolean;
	componentClient?: boolean; //is for validate if the component is mounting from Clients section
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			[theme.breakpoints.down('sm')]: {
				padding: '0px 16px'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		beforeAfterWrapper: {
			height: 175,
			width: '100%',
			display: 'flex',
			flexDirection: (props: Props) =>
				props.isGrooming
					? 'row'
					: props.images[0]
					? 'column'
					: 'column-reverse',
			justifyContent: 'center',
			alignItems: 'center',

			[theme.breakpoints.up('md')]: {
				height: '297px',
				width: '238px',
				marginRight: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '450px',
				width: '360px',
				marginRight: '36px'
			}
		},
		uploadBox: {
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
			'justifyContent': 'center',
			'width': '98%',
			'height': '98%',
			'&:hover': {
				background: '#F9F9F9',
				cursor: 'pointer'
			}
		},

		beforeBox: {
			width: '100%',
			height: '100%',
			position: 'relative',
			backgroundImage: (props: Props) => 'url(' + props.images[0] + ')',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',

			[theme.breakpoints.up('md')]: {
				width: '238px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '360px'
			}
		},
		boxWithOpacity: {
			[theme.breakpoints.down('lg')]: {
				height: '221px',
				width: '229px',
				marginRight: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '295px',
				width: '305px',
				marginRight: '36px'
			}
		},
		afterBox: {
			width: '100%',
			height: '100%',
			position: 'relative',
			backgroundImage: (props: Props) => 'url(' + props.images[1] + ')',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',

			[theme.breakpoints.up('md')]: {
				width: '238px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '360px'
			}
		},
		beforeAfterContainer: {
			display: 'flex',
			flexDirection: 'row',

			[theme.breakpoints.down('sm')]: {
				gap: 15
			}
		},
		uploadTextIcon: {
			fontSize: 12,
			lineHeight: 1,
			fontWeight: 500,
			color: '#5E8677',

			[theme.breakpoints.up('md')]: {
				fontSize: 14
			}
		},
		uploadIcon: {
			fontSize: 30,
			marginBottom: 6,

			[theme.breakpoints.up('md')]: {
				fontSize: 40,
				marginBottom: 16
			}
		},
		uploadTitle: {
			'fontSize': 14,
			'lineHeight': 1,
			'fontWeight': 600,

			[theme.breakpoints.up('xl')]: {
				fontSize: 20,
				fontWeight: 400
			},

			'& strong': {
				fontWeight: 600
			}
		},
		uploadIconContainer: {
			marginTop: 12,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',

			[theme.breakpoints.up('md')]: {
				marginTop: '22px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '34px'
			}
		},
		uploadIconContainerHover: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			[theme.breakpoints.down('lg')]: {
				height: '60px',
				marginTop: '22px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '80px',
				marginTop: '34px'
			}
		},
		iconTrash: {
			cursor: 'pointer',
			fontWeight: 600,
			color: '#5E8677',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				marginTop: '2px',
				paddingRight: '2px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				marginTop: '3px',
				paddingRight: '3px'
			}
		},
		textTrash: {
			cursor: 'pointer',
			fontWeight: 600,
			color: '#5E8677',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				paddingBottom: '3px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				paddingBottom: '4px'
			}
		},
		removeContainer: {
			display: 'flex',
			justifyContent: 'center',
			[theme.breakpoints.down('lg')]: {
				marginTop: '22px',
				marginRight: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '30px',
				marginRight: '36px'
			}
		},
		beforeContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		afterContainer: {
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		changeImage: {
			'display': 'flex',
			'backgroundColor': 'rgb(224, 224, 224, 0.8)',
			'border': '1px dashed #000',
			'justifyContent': 'center',
			'position': 'absolute',
			'left': 0,
			'right': 0,
			'bottom': 0,
			'margin': 'auto',
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				height: '36px',
				width: '112px',
				top: 0,
				paddingTop: '6px',
				borderRadius: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '48px',
				width: '150px',
				top: 0,
				paddingTop: '10px',
				borderRadius: '10px'
			}
		},
		changeImageText: {
			'&:hover': {
				textDecoration: 'underline'
			},
			'fontWeight': 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '13px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px'
			}
		},
		maxSize: {
			fontSize: 14,
			lineHeight: 1,
			color: '#969696',

			[theme.breakpoints.only('md')]: {
				fontSize: '6px',
				lineHeight: '13px',
				marginLeft: '15px',
				marginRight: '10px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '8px',
				lineHeight: '18px',
				marginLeft: '20px',
				marginRight: '13px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '12px',
				lineHeight: '27px',
				marginLeft: '30px',
				marginRight: '20px'
			}
		},
		warningSize: {
			color: 'red',
			[theme.breakpoints.down('sm')]: {
				fontSize: '4px',
				lineHeight: '8px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '6px',
				lineHeight: '13px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '8px',
				lineHeight: '18px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '12px',
				lineHeight: '27px'
			}
		},
		sizeContainer: {
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				marginTop: 12
			}
		},
		smallContainer: {
			[theme.breakpoints.down('lg')]: {
				width: '250px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '320px'
			}
		}
	})
);

enum ImageType {
	BEFORE = 'before',
	AFTER = 'after'
}

export const BeforeAndAfter: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const masterData = useMasterData();
	const [filesToUpload, setFilesToUpload] = useState<Array<File | null>>([
		null,
		null
	]);
	const [warningBefore, setWarningBefore] = useState(false);
	const [warningAfter, setWarningAfter] = useState(false);
	const [hoverBefore, setHoverBefore] = useState(false);
	const [hoverAfter, setHoverAfter] = useState(false);

	const [selectedImage, setSelectedImage] = useState<string>();
	const [fileName, setFileName] = useState<string>();
	const [showCropper, setShowCropper] = useState(false);
	const [imageType, setImageType] = useState<ImageType>();

	const beforeInput = useRef<HTMLInputElement>(null);
	const afterInput = useRef<HTMLInputElement>(null);

	const handleBeforeClick = () => {
		beforeInput.current?.click();
	};
	const handleAfterClick = () => {
		afterInput.current?.click();
	};

	const handleBeforeRemove = () => {
		const imagesCopy = [...props.images];
		imagesCopy[0] = '';
		setWarningBefore(false);
		const files = [...filesToUpload];
		files[0] = null;
		setFilesToUpload(files);
		props.onChangeImage && props.onChangeImage(imagesCopy, files);
	};
	const handleAfterRemove = () => {
		const imagesCopy = [...props.images];
		imagesCopy[1] = '';
		setWarningAfter(false);
		const files = [...filesToUpload];
		files[1] = null;
		setFilesToUpload(files);
		props.onChangeImage && props.onChangeImage(imagesCopy, files);
	};

	const selectImageHandler = (event: any, imageType: ImageType) => {
		const file = event.target.files[0];

		if (file.size / 1024 <= masterData.imagesMaxSizes.groomingReportMaxKb) {
			setImageType(imageType);

			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
					setSelectedImage(reader.result?.toString()!);
					setFileName(file.name);
					setShowCropper(true);
					if (imageType === ImageType.BEFORE) {
						setWarningBefore(false);
					} else {
						setWarningAfter(false);
					}
				}
			};
			reader.readAsDataURL(file);
		} else {
			if (imageType === ImageType.BEFORE) {
				setWarningBefore(true);
			} else {
				setWarningAfter(true);
			}
		}
	};

	const cropHandler = (file: File) => {
		if (imageType === ImageType.BEFORE) {
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
					const images = [...props.images];
					const files = filesToUpload;
					images[0] = reader.result?.toString()!;
					files[0] = file;
					props.onChangeImage && props.onChangeImage(images, files);
					setWarningBefore(false);
					setFilesToUpload(files);
				}
			};
			reader.readAsDataURL(file);
		} else {
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
					const images = [...props.images];
					const files = filesToUpload;
					images[1] = reader.result?.toString()!;
					files[1] = file;
					props.onChangeImage && props.onChangeImage(images, files);
					setWarningAfter(false);
					setFilesToUpload(files);
				}
			};
			reader.readAsDataURL(file);
		}
		setShowCropper(false);
	};

	return (
		<Fragment>
			<Box className={classes.container}>
				<Typography className={classes.titleSection}>
					Before & After Picture
				</Typography>
				<input
					style={{ display: 'none' }}
					type={'file'}
					ref={beforeInput}
					onChange={e => selectImageHandler(e, ImageType.BEFORE)}
					accept=".png, .jpg, .jpeg"
					value={''}
				/>
				<input
					style={{ display: 'none' }}
					type={'file'}
					ref={afterInput}
					onChange={e => selectImageHandler(e, ImageType.AFTER)}
					accept=".png, .jpg, .jpeg"
					value={''}
				/>
				<Box className={classes.beforeAfterContainer}>
					<Box
						className={classes.beforeContainer}
						onMouseEnter={() => setHoverBefore(true)}
						onMouseLeave={() => setHoverBefore(false)}
					>
						<Box
							className={clsx(
								'container',
								classes.beforeAfterWrapper,
								{
									[classes.smallContainer]:
										props.componentClient
								}
							)}
						>
							{props.images[0] ? (
								<Box className={classes.beforeBox}>
									{hoverBefore && (
										<>
											<Box
												className={
													classes.boxWithOpacity
												}
											/>

											<Box
												className={classes.changeImage}
											>
												<Typography
													className={
														classes.changeImageText
													}
													onClick={handleBeforeClick}
												>
													Change Image
												</Typography>
											</Box>
										</>
									)}
								</Box>
							) : (
								<Box
									className={classes.uploadBox}
									onClick={handleBeforeClick}
									id="grooming_report_input_image_before"
								>
									<Typography className={classes.uploadTitle}>
										<strong>Before</strong> Image
									</Typography>
									<Box
										className={
											hoverBefore
												? classes.uploadIconContainerHover
												: classes.uploadIconContainer
										}
									>
										<FontAwesomeIcon
											icon={faFileUpload}
											color={'#5E8677'}
											className={classes.uploadIcon}
										/>
									</Box>
									<Typography
										className={classes.uploadTextIcon}
									>
										Upload
									</Typography>
								</Box>
							)}
						</Box>
						<Box className={classes.sizeContainer}>
							<Typography
								className={classes.maxSize}
							>{`Max.${masterData.imagesMaxSizes.groomingReportMaxKb}KB`}</Typography>
							{warningBefore && (
								<Typography className={classes.warningSize}>
									Maximum size exceeded
								</Typography>
							)}
						</Box>
						{props.images[0] && hoverBefore && (
							<Box
								className={classes.removeContainer}
								onClick={handleBeforeRemove}
							>
								<FontAwesomeIcon
									icon={faTrashAlt}
									className={classes.iconTrash}
								/>
								<Typography className={classes.textTrash}>
									Remove
								</Typography>
							</Box>
						)}
					</Box>
					<Box
						className={classes.afterContainer}
						onMouseEnter={() => setHoverAfter(true)}
						onMouseLeave={() => setHoverAfter(false)}
					>
						<Box
							className={clsx(
								'container',
								classes.beforeAfterWrapper,
								{
									[classes.smallContainer]:
										props.componentClient
								}
							)}
						>
							{props.images[1] ? (
								<Box className={classes.afterBox}>
									{hoverAfter && (
										<>
											<Box
												className={
													classes.boxWithOpacity
												}
											/>

											<Box
												className={classes.changeImage}
											>
												<Typography
													className={
														classes.changeImageText
													}
													onClick={handleAfterClick}
												>
													Change Image
												</Typography>
											</Box>
										</>
									)}
								</Box>
							) : (
								<Box
									className={classes.uploadBox}
									onClick={handleAfterClick}
									id="grooming_report_input_image_after"
								>
									<Typography className={classes.uploadTitle}>
										<strong>After</strong> Image
									</Typography>
									<Box
										className={
											hoverAfter
												? classes.uploadIconContainerHover
												: classes.uploadIconContainer
										}
									>
										<FontAwesomeIcon
											icon={faFileUpload}
											color={'#5E8677'}
											className={classes.uploadIcon}
										/>
									</Box>
									<Typography
										className={classes.uploadTextIcon}
									>
										Upload
									</Typography>
								</Box>
							)}
						</Box>
						<Box className={classes.sizeContainer}>
							<Typography
								className={classes.maxSize}
							>{`Max.${masterData.imagesMaxSizes.groomingReportMaxKb}KB`}</Typography>
							{warningAfter && (
								<Typography className={classes.warningSize}>
									Maximum size exceeded
								</Typography>
							)}
						</Box>
						{props.images[1] && hoverAfter && (
							<Box
								className={classes.removeContainer}
								onClick={handleAfterRemove}
							>
								<FontAwesomeIcon
									icon={faTrashAlt}
									className={classes.iconTrash}
								/>
								<Typography className={classes.textTrash}>
									Remove
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			{showCropper && (
				<CropperImage
					image={selectedImage!}
					fileName={fileName!}
					cropWidth={360}
					cropHeight={450}
					onClose={() => setShowCropper(false)}
					onCrop={cropHandler}
					viewScale={0.8}
				/>
			)}
		</Fragment>
	);
};

export default BeforeAndAfter;
