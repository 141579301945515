import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { Step as StepModel, StepStatus } from "./SidebarModel";
import SidebarStepChip from "./SidebarStepChip";

interface SidebarStepProps {
  index: number;
  step: StepModel;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      backgroundColor: "white",
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(12)}px ${reduceResolution(23)}px`,
        height: `${reduceResolution(50)}px`,
        borderRadius: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "12px 23px",
        height: "50px",
        borderRadius: "8px",
      },
    },
    label: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "black",
      width: "100%",
      borderRadius: "10px",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
        marginLeft: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        marginLeft: "10px",
      },
    },
    labelDisabled: {
      color: "rgba(0, 0, 0, 0.3)",
    },
    done: {
      backgroundColor: "#5E8677",
    },
    labelDone: {
      color: "white",
    },
  })
);

export const SidebarStep: FunctionComponent<SidebarStepProps> = (props) => {
  const classes = useStyles(props);

  const isDisabled = props.step.status === StepStatus.DISABLED;
  const isDone = props.step.status === StepStatus.DONE;

  return (
    <Box className={clsx(classes.container, { [classes.done]: isDone }, props.className)}>
      <SidebarStepChip index={props.index} step={props.step} />
      <Typography
        className={clsx(classes.label, { [classes.labelDone]: isDone }, { [classes.labelDisabled]: isDisabled })}
      >
        {props.step.label}
      </Typography>
    </Box>
  );
};

export default SidebarStep;
