import { Box } from "@material-ui/core";
import { FunctionComponent } from "react";
import Item from "./Item";
import Link from "./Link";
import { PageSidebarItem as PageSidebarItemModel } from "./PageSidebarModel";

interface PageSidebarItemProps {
  id?: string;
  page?: string;
  item: PageSidebarItemModel;
  selectedKey?: string;
  onClick: (key: string) => void;
}

export const PageSidebarItem: FunctionComponent<PageSidebarItemProps> = (props) => {
  return (
    <Box id={props.id}>
      <Item
        icon={props.item.icon}
        iconImage={props.item.iconImage}
        title={props.item.title}
        selected={props.item.key === props.selectedKey}
        onClick={(props.item.subitems?.length || 0) === 0 ? () => props.onClick(props.item.key) : undefined}
      />
      {props.item.subitems?.map((subitem) => (subitem.isLink ?
        <Link
          key={subitem.key}
          icon={subitem.icon}
          title={subitem.title}
          id={`${props.page}_link_${subitem.title.toLowerCase().replaceAll(' ', '_')}`}
          onClick={() => props.onClick(subitem.key)}
        />
        :
        <Item
          key={subitem.key}
          icon={subitem.icon}
          title={subitem.title}
          selected={subitem.key === props.selectedKey}
          id={`${props.page}_label_${subitem.title.toLowerCase().replaceAll(' ', '_')}`}
          onClick={() => props.onClick(subitem.key)}
          subitem={true}
        />
      ))}
    </Box>
  );
};

export default PageSidebarItem;
