import { Box, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { reduceResolution, wbp } from "Theme";
import { TextField2, PhoneField3 } from "components/UI";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TerminalOrder } from "@spike/terminal-model";
import { FieldError } from "@spike/model";
import set from "lodash/set";

interface OrderContactProps {
  order: TerminalOrder;
  errors: Array<FieldError>;
  className?: string;
  //
  onChange: (order: TerminalOrder) => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typoTitle: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px 0",
      },
    },
    email: {
      display: "flex",
      width: "50%",
    },
    phone: {
      display: "flex",
      width: "50%",
    },
  })
);

export const OrderContact: FunctionComponent<OrderContactProps> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  const changeBusinessHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedOrder = { ...props.order };
      set(updatedOrder, fieldName, value);
      props.onChange(updatedOrder);
    };
  };

  return (
    <Box>
      <Typography className={clsx(terminalCommonClasses.typo20_600, classes.typoTitle)}>Billing Information</Typography>
      <Box className={terminalCommonClasses.boxRadiusWhite}>
        <Box className={terminalCommonClasses.rowContainer}>
          <TextField2
            label="Business Name"
            placeholder="Business Name"
            name="contactBusinessName"
            value={props.order.contactBusinessName ? props.order.contactBusinessName : ""}
            onChange={changeBusinessHandler}
            required={true}
            disabled={true}
            errors={props.errors}
          />
        </Box>
        <Box className={terminalCommonClasses.rowContainer}>
          <TextField2
            label="Your Name"
            placeholder="Your Name"
            name="contactName"
            value={props.order.contactName ? props.order.contactName : ""}
            onChange={changeBusinessHandler}
            required={true}
            disabled={true}
            errors={props.errors}
          />
        </Box>
        <Box className={clsx(terminalCommonClasses.rowContainer)}>
          <Box className={classes.email}>
            <TextField2
              label="Email"
              placeholder="Email"
              name="contactEmail"
              value={props.order.contactEmail ? props.order.contactEmail : ""}
              onChange={changeBusinessHandler}
              required={true}
              disabled={true}
              errors={props.errors}
            />
          </Box>
          <Box className={classes.phone}>
            <PhoneField3
              label="Phone Number"
              placeholder="Phone Number"
              name="contactPhone"
              value={props.order.contactPhone ? props.order.contactPhone : ""}
              onChange={changeBusinessHandler}
              required={true}
              disabled={true}
              className={terminalCommonClasses.fieldLeftSeparator}
              errors={props.errors}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderContact;
