import { Box, ClickAwayListener, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import { Button, Checkbox, PercentageField2 } from 'components/UI';
import { useApiClientWrapper, useTimeZone } from 'hooks';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import Info from './Info';
import moment from 'moment';
import { saveThunk } from '@spike/commissions-action';
import { useDispatch } from 'react-redux';
import { Commission, CommissionPlan } from '@spike/commission-model';
import { v4 as uuid } from 'uuid';
import { Staff } from 'model/Staff';
import { validate } from '../CommissionValidation';
import { showErrorThunk } from '@spike/notifications-action/lib/esm/action/NotificationsActions';
import clsx from 'clsx';

interface AddCommissionsProps {
    title: string;
    onSave: () => void;
    onClose: () => void;
    saving?: boolean;
    member?: Staff;
    commissionPlan?: CommissionPlan;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        'container': {
            [theme.breakpoints.down('sm')]: {
                height: '100%',
                flexWrap: 'nowrap',
                flexDirection: 'column',
                justifyContent: 'flex-start'
            }
        },
        'header': {
            width: '100%',
            padding: '10px 16px 32px',

            [theme.breakpoints.up('md')]: {
                padding: '37px 40px 16px 40px'
            }
        },
        'close': {
            'cursor': 'pointer',
            '& button': {
                all: 'unset',
                padding: '6px 6px 0px',
                fontSize: 20
            }
        },
        'body': {
            width: '100%',
            padding: '0px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',

            [theme.breakpoints.up(wbp)]: {
                padding: '0px 40px 38px 40px'
            }
        },
        'title': {
            display: 'flex',
            justifyContent: 'space-between'
        },
        'titleFont': {
            color: '#000',
            fontWeight: 600,
            marginBottom: 25,
            marginTop: 10,

            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(25)}px`,
                lineHeight: `${reduceResolution(36)}px`,
                marginBottom: `${reduceResolution(70)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '22px',
                lineHeight: '36px'
            }
        },
        'subtitle': {
            color: '#000',
            fontWeight: 600,
            marginBottom: 10,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`,
                lineHeight: `${reduceResolution(18)}px`,
                marginBottom: `${reduceResolution(25)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18',
                lineHeight: '18px'
            }
        },
        'commissionTitle': {
            display: 'flex'
        },
        'commissionType': {
            color: '#000',
            fontWeight: 500,
            marginTop: 15,
            marginBottom: 15,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                lineHeight: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15',
                lineHeight: '15px'
            }
        },
        'date': {
            color: '#000',
            fontWeight: 500,
            marginBottom: 20,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(19)}px`,
                lineHeight: `${reduceResolution(15)}px`,
                marginBottom: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15',
                lineHeight: '15px'
            }
        },
        'rowSpacing': {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '15px'
            }
        },
        'footer': {
            justifyContent: 'end',
            gap: 10,
            width: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            padding: '40px 16px 20px',

            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                marginTop: 'auto'
            },
            [theme.breakpoints.up('md')]: {
                gap: 20,
                padding: '16px 40px',
                borderTop: '1px solid #DDDDDD'
            }
        },
        'button': {
            //width: '100%',

            [theme.breakpoints.down('sm')]: {
                'height': 44,
                'width': 150,
                'borderRadius': 30,

                '& span': {
                    fontSize: 18,
                    fontWeight: 600
                }
            }
        },
        'field': {
            [theme.breakpoints.down('sm')]: {
                width: '130px !important'
            },
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(125)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '125px'
            }
        },
        'itemNotLast': {
            [theme.breakpoints.down('sm')]: {
                paddingTop: '20px !important',
                paddingBottom: '20px !important',
                marginTop: '0px !important'
            },
            borderBottom: '1px #D4D4D4 solid'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#B7B7B7 !important',
            border: '1px #B7B7B7 solid !important'
        }
    })
);

export const AddCommissions: FunctionComponent<AddCommissionsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [serviceActive, setServiceActive] = useState<boolean>(true);
    //const [addonActive, setAddonActive] = useState<boolean>(false);
    //const [productActive, setProductActive] = useState<boolean>(false);

    const timeZone = useTimeZone();
    const effectiveDate = moment().tz(timeZone).format('MMM DD, YYYY');
    const effectiveTime = moment().tz(timeZone).format('HH:mm a');

    const staffId = props.member!.id;

    const emptyCommissionPlan = {
        uuid: uuid(),
        from: moment().tz(timeZone),
        to: null,
        staffId: staffId!,
        commissions: [],
        active: true
    };

    const createCommission = (type: string) => {
        return {
            uuid: uuid(),
            minimum: '0.00',
            maximum: null,
            rate: '0.0',
            category: { id: type, name: type },
            cadence: {
                id: 'daily',
                name: 'daily',
                days: 1
            }
        };
    };

    const commissionPlan = emptyCommissionPlan;

    const [serviceCommission, setServiceCommission] = useState<Commission>(
        createCommission('service')
    );
    const [addonCommission, setAddonCommission] = useState<Commission>(
        createCommission('addon')
    );
    const [productCommission, setProductCommission] = useState<Commission>(
        createCommission('product')
    );

    const changeValueHandler = (value: number | undefined, type: string) => {
        if (type === 'service') {
            const commissionAux = {
                ...serviceCommission,
                rate: String(value?.toFixed(2))
            };
            setServiceCommission(commissionAux);
        } else if (type === 'addon') {
            const commissionAux = {
                ...addonCommission,
                rate: String(value?.toFixed(2))
            };
            setAddonCommission(commissionAux);
        } else if (type === 'product') {
            const commissionAux = {
                ...productCommission,
                rate: String(value?.toFixed(2))
            };
            setProductCommission(commissionAux);
        }
    };

    const saveHandler = () => {
        const commissions = [];
        if (serviceActive && serviceCommission.rate !== '0.00') {
            commissions.push(serviceCommission);
        }
        /*
		if (addonActive) {
			commissions.push(addonCommission);
		}
		if (productActive) {
			commissions.push(productCommission);
		}
		*/

        const comissionPlanAux = {
            ...commissionPlan,
            from: moment().tz(timeZone),
            commissions: [...commissions]
        };
        const errors = validate(comissionPlanAux);
        setErrors(errors);
        if (errors.length === 0) {
            if (comissionPlanAux.commissions.length > 0) {
                dispatch(saveThunk(apiClientWrapper, comissionPlanAux));
            }
            props.onSave && props.onSave();
        } else {
            errors.forEach(error =>
                dispatch(showErrorThunk(error.errorMessage))
            );
        }
    };

    return (
        <ClickAwayListener onClickAway={() => props.onClose()}>
            <Grid container className={classes.container}>
                <Box className={classes.header}>
                    <Box className={classes.title}>
                        <Typography className={classes.titleFont}>
                            {props.title}
                        </Typography>
                        <Box className={classes.close}>
                            <button
                                type="button"
                                aria-label="Close"
                                onClick={() => props.onClose()}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </Box>
                    </Box>
                    <Typography className={classes.subtitle}>
                        Effective Date
                    </Typography>
                    <Typography
                        className={classes.date}
                    >{`${effectiveDate} at ${effectiveTime}`}</Typography>

                    <Info
                        description={
                            'Commission rates will apply from this moment on.'
                        }
                    />
                </Box>
                <Box className={clsx(classes.body, classes.itemNotLast)}>
                    <Box className={classes.commissionTitle}>
                        {/*
						<Checkbox
							checked={serviceActive}
							onChange={() => setServiceActive(!serviceActive)}
						/>
					*/}
                        <Typography className={classes.commissionType}>
                            Service Commission
                        </Typography>
                    </Box>

                    <PercentageField2
                        value={0}
                        autoComplete={'off'}
                        onChange={value => changeValueHandler(value, 'service')}
                        required={true}
                        errors={errors}
                        className={classes.field}
                        precision={4}
                        scale={1}
                        max={100}
                        disabled={!serviceActive}
                    />
                </Box>

                {/*
				<Box className={clsx(classes.body, classes.itemNotLast)}>
					<Box className={classes.commissionTitle}>
						<Checkbox
							checked={addonActive}
							onChange={() => setAddonActive(!addonActive)}
						/>
						<Typography className={classes.commissionType}>
							Add-On Service Commission
						</Typography>
					</Box>

					<PercentageField2
						value={0}
						autoComplete={'off'}
						onChange={value => changeValueHandler(value, 'addon')}
						required={true}
						errors={errors}
						className={classes.field}
						precision={4}
						scale={1}
						max={100}
						disabled={!addonActive}
					/>
				</Box>
				<Box className={classes.body}>
					<Box className={classes.commissionTitle}>
						<Checkbox
							checked={productActive}
							onChange={() => setProductActive(!productActive)}
						/>
						<Typography className={classes.commissionType}>
							Product Commission
						</Typography>
					</Box>

					<PercentageField2
						value={0}
						autoComplete={'off'}
						onChange={value => changeValueHandler(value, 'product')}
						required={true}
						errors={errors}
						className={classes.field}
						precision={4}
						scale={1}
						max={100}
						disabled={!productActive}
					/>
				</Box>
					*/}

                <Box className={classes.footer}>
                    <Button
                        className={classes.button}
                        label="Discard"
                        color="black"
                        onClick={props.onClose}
                    />
                    <Button
                        className={classes.button}
                        label="Save"
                        color="green"
                        loading={props.saving}
                        onClick={saveHandler}
                    />
                </Box>
            </Grid>
        </ClickAwayListener>
    );
};

export default AddCommissions;
