import { NewFeature } from "model/NewFeature";

export interface NewFeaturesState {
  newFeatures: Array<NewFeature>;
  status: NewFeaturesStatus;
  loading: boolean;
}

export enum NewFeaturesStatus {
  Initial,
  Fetching,
  FetchSuccess,
  MarkingAsRead,
  MarkAsReadSuccess,
  Error
} 
