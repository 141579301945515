import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
export interface DropdownOption {
    id: string;
    icon: IconDefinition;
    title: string;
    disabled?: boolean;
}
import {
    faEye,
    faCopy,
    faFileDownload,
    faPaperPlane,
    faPen,
    faCheck,
    faBan,
    faPrint,
    faTrash
} from '@fortawesome/pro-light-svg-icons';

const previewOption: DropdownOption = {
    id: 'preview',
    title: 'Preview',
    icon: faEye
};

const sendOption: DropdownOption = {
    id: 'send',
    title: 'Send',
    icon: faPaperPlane
};

const downloadOption: DropdownOption = {
    id: 'download',
    title: 'Download PDF',
    icon: faFileDownload
};

const printOption: DropdownOption = {
    id: 'print',
    title: 'Print',
    icon: faPrint
};

const editOption: DropdownOption = {
    id: 'edit',
    title: 'Edit',
    icon: faPen
};

const duplicateOption: DropdownOption = {
    id: 'duplicate',
    title: 'Duplicate',
    icon: faCopy
};

const deactivateOption: DropdownOption = {
    id: 'deactivate',
    title: 'Deactivate',
    icon: faBan
};

const activateOption: DropdownOption = {
    id: 'activate',
    title: 'Activate',
    icon: faCheck
};

const deleteOption: DropdownOption = {
    id: 'delete',
    title: 'Delete',
    icon: faTrash
};

export const dropdownOptions: Array<DropdownOption> = [
    previewOption,
    sendOption,
    downloadOption,
    printOption,
    editOption,
    duplicateOption,
    deactivateOption,
    activateOption,
    deleteOption
];
