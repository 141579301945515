import {
	Box,
	ClickAwayListener,
	Dialog,
	DialogContent,
	Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, Spinner } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduceResolution, wbp } from 'Theme';
import { BlockCalendarStatus, countRecurrentThunk } from '@spike/block-calendar-action';
import { RootState } from 'store';
import useNonInitialEffect from '@versiondos/hooks';

interface PopUpDeleteBlockCalendarProps {
	blockCalendarId: number;
	onClose: () => void;
	onConfirm: (recurrent: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'& .MuiDialog-paper': {
				boxShadow: '0px 10px 40px #00000029',
				[theme.breakpoints.down(wbp)]: {
					width: `${reduceResolution(600)}px`,
					minWidth: `${reduceResolution(600)}px`,
					height: `${reduceResolution(370)}px`,
					borderRadius: `${reduceResolution(21)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					width: '600px',
					minWidth: '600px',
					height: '370px',
					borderRadius: '21px'
				}
			},
			'& .MuiDialogContent-root': {
				overflowY: 'hidden',
				[theme.breakpoints.down(wbp)]: {
					padding: `${reduceResolution(15)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					padding: '15px'
				}
			}
		},

		containerMin: {
			'& .MuiDialog-paper': {
				[theme.breakpoints.down(wbp)]: {
					height: `${reduceResolution(270)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					height: '270px'
				}
			}
		},

		boxContainer: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%'
		},

		boxHeader: {},

		boxSpinner: {
			display: 'flex',
			height: '100%'
		},

		radioServices: {
			'display': 'flex',
			'& .MuiRadio-colorSecondary.Mui-checked': {
				color: '#222'
			},
			[theme.breakpoints.down('sm')]: {
				'marginBottom': 15,
				'paddingBottom': 15,
				'borderBottom': '1px solid #F1F1F1',
				'& .MuiFormControlLabel-root': {
					marginLeft: 0
				},
				'& .MuiRadio-root': {
					paddingTop: 7,
					paddingLeft: 0,
					paddingBottom: 7
				}
			}
		},
		dialogContainer: {
			'width': '100%',
			'& .MuiDialog-paper': {
				overflowY: 'hidden'
			},
			'display': 'flex',
			'flexDirection': 'column'
		},
		boxButtons: {
			borderTop: '1px solid #DDDDDD',
			paddingTop: '15px',
			display: 'flex',
			justifyContent: 'center'
		},
		boxButtonsFlex: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(400)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '400px'
			}
		},
		buttonsContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		button: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(180)}px`,
				height: `${reduceResolution(40)}px`,
				marginBottom: `${reduceResolution(5)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '180px',
				height: '50px',
				marginBottom: '5px'
			}
		},
		description: {
			fontWeight: 400,
			textAlign: 'center',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(15)}px`,
				marginBottom: `${reduceResolution(19)}px`,
				paddingRight: `${reduceResolution(56)}px`,
				paddingLeft: `${reduceResolution(56)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15px',
				marginBottom: '19px',
				paddingRight: '56px',
				paddingLeft: '56px'
			}
		},
		bodyContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			paddingBottom: '20px'
		},
		boxRadioContainer: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(350)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '350px'
			}
		},
		appointmentsContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: '85%'
		},
		question: {
			fontWeight: 600,
			textAlign: 'center',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(36)}px`,
				lineHeight: `${reduceResolution(30)}px`,
				paddingTop: `${reduceResolution(50)}px`,
				paddingBottom: `${reduceResolution(20)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '36px',
				lineHeight: '30px',
				paddingTop: '50px',
				paddingBottom: '20px'
			}
		},
		radioItems: {
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px'
			}
		}
	})
);

const deleteSingle = 'single';
const deleteRecurrent = 'recurrent';

export const PopUpDeleteBlockCalendar: FunctionComponent<
	PopUpDeleteBlockCalendarProps
> = props => {
	const classes = useStyles();
	const apiClientWrapper = useApiClientWrapper();
	const dispatch = useDispatch();

	const recurrent = useSelector<RootState, {blockId: number, remaining: number} | undefined>(state => state.blockCalendar.recurrent);
	const blockCalendarStatus = useSelector<RootState, BlockCalendarStatus>(state => state.blockCalendar.status);

	const showOptions = recurrent?.blockId === props.blockCalendarId && recurrent?.remaining > 1;
	const [loading, setLoading] = useState(true);
	const [selectedOption, setSelectedOption] = useState(deleteSingle);
	const [deleting, setDeleting] = useState(false);

	useEffect(()=> {dispatch(countRecurrentThunk(apiClientWrapper, props.blockCalendarId))},[]);

	useNonInitialEffect(()=>{
		switch(blockCalendarStatus) {
			case BlockCalendarStatus.CountRecurrentSuccess:
			case BlockCalendarStatus.Error:
				setLoading(false);
		}
	},[blockCalendarStatus]);

    const confirmHandler = () => {
        setDeleting(true);
        props.onConfirm(selectedOption === deleteRecurrent);
    }

	const closeHandler = () => !deleting && props.onClose();

	const optionsView = (
		<Box className={classes.bodyContainer}>
			<Box className={classes.boxRadioContainer}>
				<RadioGroup
					aria-label="appointments"
					value={selectedOption}
					name="appointments"
					className={classes.radioServices}
					onChange={(_event, value) => setSelectedOption(value)}
				>
					<FormControlLabel
						value={deleteSingle}
						control={<Radio />}
						key={deleteSingle}
						label={
							<React.Fragment>
								<Typography className={classes.radioItems}>
									Delete only this blocked time
								</Typography>
							</React.Fragment>
						}
					/>
					<FormControlLabel
						value={deleteRecurrent}
						control={<Radio />}
						key={deleteRecurrent}
						label={
							<React.Fragment>
								<Typography className={classes.radioItems}>
									Delete this and recurring blocked time
								</Typography>
							</React.Fragment>
						}
					/>
				</RadioGroup>
			</Box>
		</Box>
	);

	const contentView = (
		<>
			<Box className={classes.boxContainer}>
				<Box className={classes.boxHeader}>
					<Typography className={classes.question}>
						Delete Blocked Time
					</Typography>
					<Typography className={classes.description}>
						Are you sure you want to delete this blocked time?
					</Typography>
				</Box>
				{loading ? <Spinner/> : (showOptions && optionsView)}
			</Box>
			<Box className={classes.boxButtons}>
				{!loading && <Box className={classes.boxButtonsFlex}>
					<Box className={classes.buttonsContainer}>
						<Button
							label="No"
							loading={false}
							onClick={closeHandler}
							variant="primary"
							size="medium"
							color="black"
							className={classes.button}
						/>
						<Button
							label="Yes"
							loading={deleting}
							onClick={confirmHandler}
							variant="danger"
							size="medium"
							className={classes.button}
						/>
					</Box>
				</Box>}
			</Box>
		</>
	);

	return (
		<Box>
			<Dialog
				open={true}
				className={clsx(classes.container, {
					[classes.containerMin]: !showOptions
				})}
			>
				<ClickAwayListener onClickAway={closeHandler}>
					<DialogContent className={classes.dialogContainer}>
						{contentView}
					</DialogContent>
				</ClickAwayListener>
			</Dialog>
		</Box>
	);
};

export default PopUpDeleteBlockCalendar;
