import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';

interface TerminalIconProps {
	style?: boolean;
	white?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		terminal: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(15)}px !important`,
				height: `${reduceResolution(20)}px !important`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '15px !important',
				height: '20px !important'
			}
		},

		terminal100: {
			width: '100%',
			height: '100%'
		}
	})
);

export const TerminalIcon: FunctionComponent<TerminalIconProps> = props => {
	const classes = useStyles();

	return (
		<svg
			viewBox="0 0 36 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={clsx(
				{ [classes.terminal]: !props.style },
				{ [classes.terminal]: props.style },
				props.className
			)}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.6869 6.30202H9.50742C7.26818 6.30202 5.44668 8.15259 5.44668 10.4265V14.1921C5.44668 16.4664 7.26821 18.3165 9.50742 18.3165H26.6869C28.9261 18.3165 30.7477 16.4664 30.7477 14.1921V10.4265C30.7477 8.15259 28.9262 6.30202 26.6869 6.30202ZM27.5448 14.1921C27.5448 14.672 27.1602 15.0634 26.6869 15.0634H9.50742C9.03514 15.0634 8.64954 14.6717 8.64954 14.1921V10.4265C8.64954 9.94729 9.03505 9.55557 9.50742 9.55557H26.6869C27.1603 9.55557 27.5448 9.94705 27.5448 10.4265V14.1921Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.49371 21.0759C8.06474 21.0759 6.9061 22.2533 6.9061 23.7042C6.9061 25.1567 8.06466 26.3337 9.49371 26.3337C10.9228 26.3337 12.0813 25.1567 12.0813 23.7042C12.0813 22.2533 10.9227 21.0759 9.49371 21.0759Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.1848 21.0759C16.7558 21.0759 15.5971 22.2533 15.5971 23.7042C15.5971 25.1567 16.7557 26.3337 18.1848 26.3337C19.6138 26.3337 20.7724 25.1567 20.7724 23.7042C20.7724 22.2533 19.6137 21.0759 18.1848 21.0759Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				d="M26.8758 21.0759C25.4468 21.0759 24.2882 22.2533 24.2882 23.7042C24.2882 25.1567 25.4468 26.3337 26.8758 26.3337C28.3044 26.3337 29.4634 25.1567 29.4634 23.7042C29.4634 22.2533 28.3043 21.0759 26.8758 21.0759Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				d="M9.49393 29.4501C8.065 29.4501 6.90631 30.627 6.90631 32.0783C6.90631 33.5308 8.06487 34.7079 9.49393 34.7079C10.923 34.7079 12.0815 33.5308 12.0815 32.0783C12.0815 30.627 10.9228 29.4501 9.49393 29.4501Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				d="M18.185 29.4501C16.756 29.4501 15.5974 30.627 15.5974 32.0783C15.5974 33.5308 16.7559 34.7079 18.185 34.7079C19.614 34.7079 20.7726 33.5308 20.7726 32.0783C20.7726 30.627 19.6139 29.4501 18.185 29.4501Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.876 29.4501C25.4471 29.4501 24.2884 30.627 24.2884 32.0783C24.2884 33.5308 25.447 34.7079 26.876 34.7079C28.3046 34.7079 29.4636 33.5309 29.4636 32.0783C29.4636 30.627 28.3045 29.4501 26.876 29.4501Z"
				fill={props.white ? 'white' : 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.7236 0H5.27577C2.36601 0 0 2.40318 0 5.35898V35.1209C0 38.0767 2.36601 40.4799 5.27577 40.4799H5.29572V44.8901C5.29572 47.1578 7.11092 49 9.34215 49H26.8505C29.0817 49 30.8969 47.1578 30.8969 44.8901V40.4771C33.7272 40.384 36 38.0179 36 35.1209V5.35898C36 2.40315 33.6338 0 30.7236 0ZM8.46955 44.9034V40.4793H19.2046V45.7753H9.34163C8.86579 45.7753 8.47692 45.3857 8.46955 44.9034ZM32.8257 35.1203C32.8257 36.2975 31.8829 37.2552 30.7238 37.2552H5.27598C4.11738 37.2552 3.17447 36.2976 3.17447 35.1203V5.35834C3.17447 4.18152 4.11734 3.22385 5.27598 3.22385H30.7238C31.8829 3.22385 32.8257 4.18156 32.8257 5.35834V35.1203ZM27.7227 44.8894C27.7227 45.3782 27.3309 45.7753 26.8505 45.7753H24.7955V40.4793H27.7227V44.8894Z"
				fill={props.white ? 'white' : 'black'}
			/>
		</svg>
	);
};

export default TerminalIcon;
