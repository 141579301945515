import { faArrowLeft, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { Button } from 'components/UI';
import { Agreement } from 'model/Agreements';
import { FunctionComponent } from 'react';
import AgreementDropdown from '../AgreementsOptions';

interface HeaderProps {
	agreement?: Agreement;
	onBack?: () => void;
	onSend?: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'nowrap',
			alignItems: 'center',
			backgroundColor: '#fff',
			padding: '32px 0px',
			paddingTop: 50,
			paddingLeft: 32,
			paddingRight: 32,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 16,
				paddingRight: 16
			},
			[theme.breakpoints.down('sm')]: {
				paddingTop: 16
			},
			[theme.breakpoints.down('xs')]: {
				paddingTop: 16
			}
		},
		title: {
			fontWeight: 600,
			fontSize: 22,
			display: 'inline-block',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			textWrap: 'nowrap',
			[theme.breakpoints.down('md')]: {
				fontSize: 20,
				paddingRight: 10
			}
		},
		right: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: 'auto',
			position: 'relative'
		},
		sendBtn: {
			'marginRight': 10,
			'height': 55,
			'padding': '0px 20px',
			'& .MuiButton-startIcon': {
				'& svg': {
					width: 16,
					height: 16,
					[theme.breakpoints.down('lg')]: {
						width: 14,
						height: 14
					}
				}
			},
			'& span': {
				paddingLeft: 4,
				fontSize: 18,
				fontWeight: 600
			},
			[theme.breakpoints.down('lg')]: {
				'height': 39,
				'padding': '0px 10px',
				'& span': {
					fontSize: 14,
					fontWeight: 600
				}
			}
		},
		toggleIcon: {
			padding: 5,
			fontSize: 24,
			cursor: 'pointer'
		},
		dropdown: {
			top: 70,
			right: 0,
			width: 160,
			borderRadius: 14,
			padding: '10px 0px',
			position: 'absolute',
			backgroundColor: '#fff',
			border: 'solid 1px #000'
		},
		dropdownWrapper: {
			backgroundColor: '#fff',
			height: 35,
			width: 35,
			borderRadius: '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#f1f1f1'
			}
		},

		backIcon: {
			height: 20,
			width: 20,
			cursor: 'pointer',
			marginRight: '10px'
		}
	})
);

const Header: FunctionComponent<HeaderProps> = props => {
	const classes = useStyle();

	return (
		<Box className={classes.root}>
			<FontAwesomeIcon
				icon={faArrowLeft}
				onClick={() => {
					props.onBack && props.onBack();
				}}
				className={classes.backIcon}
			/>
			<Typography className={classes.title}>
				{props.agreement?.title}
			</Typography>

			<Box className={classes.right}>
				{props.agreement && (
					<>
						<Button
							label="Send"
							size="large"
							color="green"
							startIcon={faPaperPlane}
							className={classes.sendBtn}
							onClick={props.onSend}
						/>
						<Box className={classes.dropdownWrapper}>
							<AgreementDropdown
								agreement={props.agreement}
								onDeleted={props.onBack}
							/>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default Header;
