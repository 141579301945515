import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
	faChevronDown,
	faChevronUp,
	faGripDotsVertical
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface MedicalHistorySectionProps extends PropsWithChildren {
	title: string;
	icon: IconDefinition;
	initExpanded: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			border: '1px solid #DDDDDD',
			backgroundColor: 'white',
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(16)}px ${reduceResolution(
					26
				)}px ${reduceResolution(16)}px ${reduceResolution(16)}px`,
				borderRadius: `${reduceResolution(18)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '16px 26px 16px 16px ',
				borderRadius: '18px'
			},
			[theme.breakpoints.down('md')]: {
				border: 'none',
				padding: '0px'
			}
		},
		header: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			[theme.breakpoints.down("md")]: {
				paddingTop: '20px',
			},
		},
		leftHeader: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				'& $dotsIcon': {
					display: 'none'
				}
			}
		},
		rightHeader: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center'
		},
		title: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(20)}px`,
				lineHeight: `${reduceResolution(39)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px',
				lineHeight: '39px'
			}
		},
		icon: {
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(22)}px`,
				marginRight: `${reduceResolution(12)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '22px',
				marginRight: '12px'
			}
		},
		dotsIcon: {
			color: '#DBDAD7',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(24)}px`,
				marginRight: `${reduceResolution(17)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '24px',
				marginRight: '17px'
			}
		},
		chevronIcon: {
			color: 'black',
			cursor: 'pointer',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(24)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '24px'
			},
			[theme.breakpoints.down("md")]: {
				fontSize: '18px'
			}
		},
		body: {
			borderTop: '1px solid #DDDDDD',
			[theme.breakpoints.down(wbp)]: {
				paddingTop: `${reduceResolution(26)}px`,
				marginTop: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: '26px',
				marginTop: '16px'
			},
			[theme.breakpoints.down('md')]: {
				borderTop: 'none',
				paddingTop: '0px',
				marginTop: '10px'
			}
		}
	})
);

const MedicalHistorySection: FunctionComponent<
	MedicalHistorySectionProps
> = props => {
	const classes = useStyles();

	const [expanded, setExpanded] = useState(props.initExpanded || false);

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.header}>
				<Box className={classes.leftHeader}>
					<FontAwesomeIcon
						icon={faGripDotsVertical}
						className={classes.dotsIcon}
					/>
					<FontAwesomeIcon
						icon={props.icon}
						className={classes.icon}
					/>
					<Typography className={classes.title}>
						{props.title}
					</Typography>
				</Box>
				<Box className={classes.rightHeader}>
					<FontAwesomeIcon
						icon={expanded ? faChevronUp : faChevronDown}
						className={classes.chevronIcon}
						onClick={() => setExpanded(prev => !prev)}
					/>
				</Box>
			</Box>
			{expanded && <Box className={classes.body}>{props.children}</Box>}
		</Box>
	);
};

export default MedicalHistorySection;
