import React from 'react'
import { WizardHeader, WizardItem } from 'components/Wizard'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Theme } from '@material-ui/core'
import SMSGetStarted from 'components/SMS/SMSGetStarted'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column'
    }
}));

const SMSGetStartedPage = () => {
    const items: WizardItem[] = [];

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <WizardHeader
                step={1}
                items={items}
                // title='New SMS Campaign'
                closePath="/marketing" />

            <SMSGetStarted />
        </Box>
    )
}

export default SMSGetStartedPage