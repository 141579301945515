import {
  UsersAction,
  USERS_RESET_ACTION_TYPE,
  USERS_CHANGE_PASSWORD_START_ACTION_TYPE,
  USERS_CHANGE_PASSWORD_SUCCESS_ACTION_TYPE,
  USERS_ERROR_ACTION_TYPE,
} from "./UsersActionsTypes";
import { ThunkAction } from "redux-thunk";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction }from "@spike/notifications-action";
import { showError }from "@spike/notifications-action";
import { UsersState } from "reducers/users/UsersState";
import store from "store";

const changePasswordUrl = "/change_password";

export const changePasswordUserThunk = (
  userId: number,
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
): ThunkAction<void, UsersState, null, UsersAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(changePasswordStart());
    const marketplaceId = store.getState().login.auth.marketplaceId!;
    const url = `${changePasswordUrl}?marketplace_id=${marketplaceId}`;
    const body = {
      password: oldPassword,
      new_password: newPassword,
      password_confirmation: confirmNewPassword,
    };

    try {
      await ApiClient.post(url, body, createTokenConfig(store.getState().login.auth.token!));
      dispatch(changePasswordSuccess());
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error changing password."));
      }
    }
  };
};

export const reset = (): UsersAction => {
  return { type: USERS_RESET_ACTION_TYPE };
};

const changePasswordStart = (): UsersAction => {
  return { type: USERS_CHANGE_PASSWORD_START_ACTION_TYPE };
};

const changePasswordSuccess = (): UsersAction => {
  return { type: USERS_CHANGE_PASSWORD_SUCCESS_ACTION_TYPE };
};

const error = (): UsersAction => {
  return {
    type: USERS_ERROR_ACTION_TYPE,
  };
};
