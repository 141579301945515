import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import clsx from 'clsx';
import { SelectionBar } from 'components/UI';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { TerminalOrder, OrderModelTerminal } from '@spike/terminal-model';
import moment from 'moment';
import { Fragment, FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Table from '../../Table';
import { useCommonStyles as useTableStyles } from '../../Table/CommonStyles';
import { OrderStatus } from './OrderStatus';
import { DeleteToolbarItem, ToolbarButton } from 'components/UI';
import { deleteOrderThunk, fetchTerminalsOrderThunk } from '@spike/terminals-action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { TerminalsStatus } from '@spike/terminals-action';
import useNonInitialEffect from '@versiondos/hooks';

interface OrdersTableProps {
    orders?: Array<TerminalOrder>;
    className?: string;
    //
    onClose?: () => void;
    onShowOrder: (order: TerminalOrder) => void;
    onShowTerminal: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(63)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '63px'
            }
        },

        boxTableContainer: {
            flex: 1,
            display: 'flex',
            background: '#FAFAFA',
            flexDirection: 'column',

            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '50px'
            }
        },

        boxTable: {
            flex: 1,

            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(35)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '35px'
            }
        },

        boxSelectionContainer: {
            width: 'fit-content',
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '10px'
            }
        },

        selectionBar: {
            backgroundColor: 'white'
        },

        tableWrapper: {
            height: '100%'
        }
    })
);

export const All = { id: 'all', name: 'All' };
export const Unpaid = { id: 'new', name: 'Unpaid' };
export const Open = { id: 'open', name: 'Open' };
export const Delivered = { id: 'in_progress', name: 'In Progress' };
export const Shipped = { id: 'out_for_delivery', name: 'Shipped' };
export const Received = { id: 'received', name: 'Received' };
export const Cancelled = { id: 'cancelled', name: 'Canceled' };

export const filterOrders: Array<Option<string>> = [All, Open, Delivered, Shipped, Received, Cancelled];

export const OrdersTable: FunctionComponent<OrdersTableProps> = props => {
    const apiClientWrapper = useApiClientWrapper();
    const classes = useStyles();
    const tableStyles = useTableStyles();
    const marketplace = useMarketplace();
    const dispatch = useDispatch();
    const timeZone = marketplace.schedule.timeZone;

    const orderStatus = useSelector<RootState, TerminalsStatus>(state => state.terminals.status);
    const terminalOrder = useSelector<RootState, Array<TerminalOrder> | undefined>(state => state.terminals.orders);

    const [editedTerminals, setEditedTerminals] = useState<Array<TerminalOrder> | undefined>(props.orders);
    const [filterOrderId, setFilterOrderId] = useState<string>(All.id);

    useNonInitialEffect(() => {
        switch (orderStatus) {
            case TerminalsStatus.DeleteTerminalOrderSuccess:
                dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
                break;
            case TerminalsStatus.FetchTerminalsOrdersSuccess:
                setEditedTerminals(terminalOrder);
                break;
            default:
                break;
        }
    }, [orderStatus]);

    const changeFilterOrderHandler = (id: string) => {
        setFilterOrderId(id);
    };

    const checkItems = (terminals: Array<OrderModelTerminal>) => {
        const countItems = terminals.reduce((total, model) => total + model.qty, 0);
        return <>{countItems > 1 ? `${countItems} Items` : `${countItems} Item`}</>;
    };

    const showOrderHandler = (order: TerminalOrder) => {
        props.onShowOrder(order);
    };

    const deleteHandler = (order: TerminalOrder) => {
        dispatch(deleteOrderThunk(apiClientWrapper, order.id!));
    };

    const headersOrders = (
        <>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}>Order #</TableCell>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}>Order Placed</TableCell>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}>Items</TableCell>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}>Status</TableCell>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}>Shipped With</TableCell>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}>Tracking #</TableCell>
            <TableCell className={clsx(tableStyles.headerCell, tableStyles.darkCell)}></TableCell>
        </>
    );

    const rowsOrders = (
        <>
            {editedTerminals &&
                editedTerminals
                    .filter(order =>
                        filterOrderId === All.id
                            ? order.status
                            : filterOrderId === Open.id
                            ? order.status === Open.id || order.status === Unpaid.id
                            : order.status === filterOrderId
                    )
                    .map(order => {
                        const createAt = moment(order.createAt!);
                        const createAtFormat = createAt
                            ? createAt.tz(timeZone).format('MMM DD, HH:mm A').toString()
                            : '';

                        return (
                            <TableRow key={order.id}>
                                <TableCell
                                    className={tableStyles.cell}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showOrderHandler(order)}
                                >
                                    {order.number}
                                </TableCell>
                                <TableCell
                                    className={tableStyles.cell}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showOrderHandler(order)}
                                >
                                    {createAtFormat}
                                </TableCell>
                                <TableCell
                                    className={tableStyles.cell}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showOrderHandler(order)}
                                >
                                    {checkItems(order.terminals)}
                                </TableCell>
                                <TableCell
                                    className={tableStyles.cell}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showOrderHandler(order)}
                                >
                                    <OrderStatus orderStatus={order.status} />
                                </TableCell>
                                <TableCell
                                    className={tableStyles.cell}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showOrderHandler(order)}
                                >
                                    {order.courier ? order.courier : 'Pending'}
                                </TableCell>
                                <TableCell
                                    className={tableStyles.cell}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showOrderHandler(order)}
                                >
                                    {order.trackingNumber ? order.trackingNumber : 'Pending'}
                                </TableCell>
                                <TableCell className={tableStyles.cell}>
                                    {order.status === Unpaid.id && (
                                        <ToolbarButton>
                                            <DeleteToolbarItem
                                                label="Delete Order"
                                                onConfirmedClick={() => deleteHandler(order)}
                                                confirmTitle={<Typography>Delete Order</Typography>}
                                                confirmQuestion={
                                                    <Typography>
                                                        Are you sure you want to Delete the order
                                                        <br /># <strong>{order.number}</strong>?
                                                    </Typography>
                                                }
                                            />
                                        </ToolbarButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
        </>
    );

    return (
        <Fragment>
            <Box className={classes.boxTableContainer}>
                <Box className={classes.boxSelectionContainer}>
                    <SelectionBar
                        options={[...filterOrders]}
                        selectedId={filterOrderId}
                        selectedTotalRecords={
                            props.orders?.filter(order =>
                                filterOrderId === All.id
                                    ? order.status
                                    : filterOrderId === Open.id
                                    ? order.status === Open.id || order.status === Unpaid.id
                                    : order.status === filterOrderId
                            ).length
                        }
                        className={classes.selectionBar}
                        //
                        onSelect={changeFilterOrderHandler}
                    />
                </Box>
                <Box className={clsx(classes.boxTable)}>
                    <Table
                        headers={headersOrders}
                        rows={rowsOrders}
                        loading={false}
                        wrapperClassName={classes.tableWrapper}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default OrdersTable;
