import { ScrolleableTable } from "components/UI/ScrolleableTable"
import { Agreement } from "model/Agreements";
import AgreementsTableRow from './AgreementsTableRow'
import { FunctionComponent } from "react";


interface AgreementsTableProps {
  filteredAgreements: Agreement[]
  onSelectAgreement: (id: number) => void
}


const headColumnLabels = [
  'Name',
  'Date Created',
  'Modified',
  'Required',
  'Action'
];

const AgreementsTable: FunctionComponent<AgreementsTableProps> = (props) => {
  return <ScrolleableTable
    columnLabels={headColumnLabels}
    renderRows={props.filteredAgreements.map(
      (item, index) => (
        <AgreementsTableRow
          key={index}
          agreement={item}
          onShowDetail={() =>
            props.onSelectAgreement(item.id!)
          }
        />
      )
    )}
  />
}

export default AgreementsTable