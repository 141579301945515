import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
	createStyles({
		default: {
			'border': 0,
			'padding': 0,
			'fontSize': 14,
			'fontWeight': 500,
			'width': '100%',
			'color': '#000',
			'display': 'flex',
			'alignItems': 'center',
			'backgroundColor': '#fff',

			'& p': {
				fontSize: 14,
				fontWeight: 500
			}
		},
		parent: {
			'padding': '16px 0px',

			'&:not(:last-child):not(div)': {
				borderBottom: 'solid 1px #D4D4D4'
			},

			'& > svg': {
				fontSize: 18,
				marginRight: 13,
				marginBottom: -2
			}
		},
		parentWithChilds: {
			paddingBottom: 8
		},
		childContainer: {
			paddingBottom: 8,
			borderBottom: 'solid 1px #D4D4D4'
		},
		child: {
			padding: '6px 0px 6px 32px'
		},
		label: {
			marginRight: 'auto'
		}
	})
);

export interface ActionButtonType {
	icon: IconProp;
	label: string | React.ReactNode;
	items?: Array<ActionButtonItem>;
	rightElement?: React.ReactNode;
	onClick: () => void;
}

export interface ActionButtonItem {
	label: string | React.ReactNode;
	onClick: () => void;
}

export const ActionButton: React.FunctionComponent<
	ActionButtonType
> = props => {
	const classes = useStyles();

	const content = (
		<>
			<FontAwesomeIcon icon={props.icon} />

			<Typography className={classes.label}>{props.label}</Typography>

			{props.rightElement && props.rightElement}
		</>
	);

	return (
		<>
			{props.items ? (
				<>
					<div
						className={clsx(
							classes.default,
							classes.parent,
							classes.parentWithChilds
						)}
					>
						{content}
					</div>
					<div className={classes.childContainer}>
						{props.items.map((item, index) => (
							<button
								key={index}
								className={clsx(classes.default, classes.child)}
								onClick={item.onClick}
							>
								<Typography>{item.label}</Typography>
							</button>
						))}
					</div>
				</>
			) : (
				<button
					type="button"
					className={clsx(classes.default, classes.parent)}
					onClick={props.onClick}
				>
					{content}
				</button>
			)}
		</>
	);
};
