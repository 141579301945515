import React, {
	FunctionComponent,
	ReactNode,
	ReactElement,
	useState,
	useEffect
} from 'react';
import ChipMUI from '@material-ui/core/Chip';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';

interface Props {
	id?: string;
	label: ReactNode;
	avatar?: ReactElement;
	small?: boolean;
	cursorPointer?: boolean;
	fullWidth?: boolean;
	variant?: 'default' | 'outlined';
	onDelete?: () => void;
	className?: string;
	center?: boolean;
}

const useStyles = makeStyles(() =>
	createStyles({
		chip: (props: Props) => ({
			'gap': 7,
			'height': 38,
			'maxWidth': '100%',
			'borderRadius': 30,
			'padding': '0px 16px',
			'display': 'flex',
			'background': props.variant === 'outlined' ? '#FFFFFF' : '#F1F1F1',
			'justifyContent': props.center ? 'center' : 'flex-start',
			'width': props.fullWidth ? '100%' : 'fit-content',
			'cursor': props.cursorPointer ? 'pointer' : 'default',

			'& .MuiChip-label': {
				fontSize: 14,
				fontWeight: 500,
				padding: 0
			},
			'& svg': {
				width: 16,
				height: 16,
				fontSize: 16,
				color: '#000',
				margin: 0
			}
		})
	})
);

export const Chip: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('lg')
	);

	const [showDeleteButton, setShowDeleteButton] = useState(false);

	const mouseEnterHandler = () => {
		if (props.onDelete) {
			setShowDeleteButton(isMobile || true);
		}
	};

	const mouseLeaveHandler = () => {
		if (props.onDelete) {
			setShowDeleteButton(isMobile || false);
		}
	};

	useEffect(() => {
		if (isMobile) {
			setShowDeleteButton(true);
		}
	}, [isMobile]);

	return (
		<ChipMUI
			id={props.id}
			avatar={props.avatar}
			label={props.label}
			className={clsx(classes.chip, props.className)}
			onMouseLeave={mouseLeaveHandler}
			onMouseOver={mouseEnterHandler}
			onDelete={showDeleteButton ? props.onDelete : undefined}
			deleteIcon={<FontAwesomeIcon icon={faTimes} />}
			clickable={false}
			variant={props.variant}
		/>
	);
};

export default Chip;
