import React, { FunctionComponent } from 'react';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { Img } from 'react-image';

interface Props {
	images: Array<string>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',

			[theme.breakpoints.up('md')]: {
				width: '90%'
			}
		},
		titleContainer: {
			paddingBottom: 16,

			[theme.breakpoints.only('xl')]: {
				paddingBottom: 24
			}
		},
		title: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 11,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: 14
			}
		},
		imageContainer: {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',

			[theme.breakpoints.down('sm')]: {
				gap: 10
			}
		},
		emptyImageContainer: {
			border: '1px dashed #A3A3A3',
			width: '100%',
			[theme.breakpoints.down('lg')]: {
				height: '320px',
				borderRadius: '26px'
			},
			[theme.breakpoints.only('xl')]: {
				height: '480px',
				borderRadius: '40px'
			}
		},
		singleImage: {
			[theme.breakpoints.down('lg')]: {
				height: '320px',
				borderRadius: '26px'
			},
			[theme.breakpoints.only('xl')]: {
				height: '480px',
				borderRadius: '40px'
			}
		},
		leftImage: {
			width: '100%',
			borderRadius: 24,

			[theme.breakpoints.up('md')]: {
				borderTopLeftRadius: '26px',
				borderBottomLeftRadius: '26px',
				borderRight: '3px solid #fff'
			},
			[theme.breakpoints.only('xl')]: {
				borderTopLeftRadius: '40px',
				borderBottomLeftRadius: '40px',
				borderRight: '5px solid #fff'
			}
		},
		rightImage: {
			width: '100%',
			borderRadius: 24,

			[theme.breakpoints.up('md')]: {
				borderTopRightRadius: '26px',
				borderBottomRightRadius: '26px'
			},
			[theme.breakpoints.only('xl')]: {
				borderTopRightRadius: '40px',
				borderBottomRightRadius: '40px'
			}
		}
	})
);

export const BeforeAndAfterGroomingSummary: FunctionComponent<
	Props
> = props => {
	const classes = useStyles(props);

	return (
		<Box className={classes.container}>
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>
					Before & After Picture
				</Typography>
			</Box>
			<Box
				className={clsx(classes.imageContainer, {
					[classes.emptyImageContainer]: props.images.length === 0
				})}
			>
				<Img
					alt=""
					src={props.images[0]}
					className={clsx(
						{ [classes.singleImage]: props.images.length === 1 },
						{ [classes.leftImage]: props.images.length > 1 }
					)}
				/>
				{props.images.length > 1 && (
					<Img
						alt=""
						src={props.images[1]}
						className={classes.rightImage}
					/>
				)}
			</Box>
		</Box>
	);
};

export default BeforeAndAfterGroomingSummary;
