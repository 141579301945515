import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { wbp } from 'Theme';

export const useCommonStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonContainer: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '30px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '40px'
			}
		},
		formCell: {
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				gap: 8,
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				alignItems: 'flex-end'
			}
		},
		error: {
			color: '#EF4F57'
		},
		documentText: {
			fontFamily: 'Poppins',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '16px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px'
			}
		},
		documentItem: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '20px'
			}
		}
	})
);
