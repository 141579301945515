import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PaymentsBankAccount } from '@spike/payments-model';
import React, { FunctionComponent } from 'react';
import PrimaryChip from './PrimaryChip';

interface AccountTypeProps {
    bankAccount: PaymentsBankAccount;
    bankAccountsQty: number;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        row: {
            display: 'flex',
            width: '100%',
            alignItems: 'center'
        },
        accountType: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: 15,
            marginRight: 7,
            color: 'black',
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                fontWeight: 600,
                marginRight: 7
            }
        },
        description: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: 14,
            color: '#7a7a7a',
            [theme.breakpoints.down('xs')]: {
                color: '#000'
            }
        }
    })
);

export const AccountType: FunctionComponent<AccountTypeProps> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.row}>
                <Typography className={classes.accountType}>
                    {props.bankAccount.accountIdentifier ? (
                        <>
                            ••••••••••
                            {props.bankAccount.accountIdentifier.slice(-4)}
                        </>
                    ) : (
                        props.bankAccount.accountNumber && (
                            <>
                                ••••••••••
                                {props.bankAccount.accountNumber.slice(-4)}
                            </>
                        )
                    )}
                </Typography>
                {(props.bankAccount.primary || props.bankAccountsQty == 1) && (
                    <PrimaryChip />
                )}
            </Box>
        </Box>
    );
};

export default AccountType;
