import {
    SMSCampaignsAction,
    SMS_CAMPAIGNS_CLIENTS_FETCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_SEARCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_DECREASE_STEP_ACTION_TYPE,
    SMS_CAMPAIGNS_DUPLICATE_ACTION_TYPE,
    SMS_CAMPAIGNS_EDIT_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_GLOBAL_START_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_START_ACTION_TYPE,
    SMS_CAMPAIGNS_FETCH_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_GET_START_ACTION_TYPE,
    SMS_CAMPAIGNS_GET_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_INCREASE_STEP_ACTION_TYPE,
    SMS_CAMPAIGNS_QUOTE_ACTION_TYPE,
    SMS_CAMPAIGNS_SAVE_RESET_ACTION_TYPE,
    SMS_CAMPAIGNS_SAVE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_SAVE_SUCCESS_ACTION_TYPE,
    SMS_CAMPAIGNS_SET_FILTER_ACTION_TYPE,
    SMS_CAMPAIGNS_SET_LOADING_ACTION_TYPE,
    SMS_CAMPAIGNS_UPDATE_START_ACTION_TYPE,
    SMS_CAMPAIGNS_UPDATE_STATUS_ACTION_TYPE,
    SMS_CAMPAIGNS_UPDATE_SUCCESS_ACTION_TYPE
} from './SMSCampaignsActionsTypes';
import store from 'store';
import { AxiosResponse } from 'axios';
import { ThunkAction } from 'redux-thunk';
import SMSCampaign, {
    SMSCampaignQuote,
    SMSCampaignStatus
} from 'model/SMSCampaign';
import ApiClient, { createTokenConfig } from 'api/ApiClient';
import {
    SMSCampaignClientGroups,
    SMSCampaignsState,
    SMSCampaignsStatus
} from 'reducers/smsCampaigns/SMSCampaignsState';
import {
    SMSCampaignDto,
    SMSCampaignQuoteDto,
    SMSCampaignsDto,
    SMSCampaignStatsDto
} from './SMSCampaignsDtos';
import { showError, showSuccess } from '@spike/notifications-action';
import { NotificationsAction } from '@spike/notifications-action';
import {
    ClientDto,
    ClientDtoPage,
    convertToClient
} from '@spike/clients-action';
import { MasterData } from '@spike/masterdata-model';

const baseUrl = '/sms_campaigns';

const statsUrl = '/sms_campaign/stats/';
const clientsUrl = '/sms_campaign/customers';
const completeUrl = '/sms_campaign/complete/';
const clientGroupsUrl = '/sms_campaign/customer_group_counters';
const quoteUrl = '/sms_campaign/quote';
const cloneUrl = '/sms_campaign/clone';
const clientUrl = '/customer';

const dropinUrl = '/sms_campaign/create_dropin';

export const fetchCampaignsGlobalThunk = (): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchGlobalStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${baseUrl}?marketplace_id=${marketplaceId}&page=1&size=5&order=desc`;

        try {
            const { data }: AxiosResponse<SMSCampaignsDto> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(fetchGlobalSuccess(data));
        } catch (apiError) {
            dispatch(showError('Error fetching campaigns.'));
        }
    };
};

export const fetchCampaignsByStatusThunk = (
    page = 1,
    size = 25,
    status: SMSCampaignStatus = SMSCampaignStatus.sent
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${baseUrl}?marketplace_id=${marketplaceId}&page=${page}&size=${size}&status=${status}&order=desc`;

        try {
            const { data }: AxiosResponse<SMSCampaignsDto> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(fetchSuccess(data));
        } catch (apiError) {
            dispatch(showError('Error fetching campaigns.'));
        }
    };
};

export const saveCampaignThunk = (
    campaign: SMSCampaign
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(saveStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const apiClientMethod =
            campaign.id === undefined ? ApiClient.post : ApiClient.patch;

        const url =
            campaign.id === undefined
                ? `${baseUrl}?marketplace_id=${marketplaceId}`
                : `${baseUrl}/${campaign.id}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<SMSCampaignDto> =
                await apiClientMethod(
                    url,
                    {
                        sms_campaign: {
                            ...campaign,
                            marketplace_id: marketplaceId
                        }
                    },
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(increaseStep());
            dispatch(
                saveSuccess({
                    ...response.data,
                    ...(campaign.editing && { editing: true })
                })
            );
        } catch (apiError: any) {
            setTimeout(
                () => dispatch(updateStatus(SMSCampaignsStatus.Error)),
                100
            );

            if (apiError.response?.data?.name) {
                dispatch(showError('Campaign name has already been taken.'));
                return;
            }

            dispatch(showError('Error creating campaign.'));
        }
    };
};

export const completeCampaignThunk = (
    campaign: SMSCampaign
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${completeUrl}${campaign.id}?marketplace_id=${marketplaceId}`;

        try {
            const { data }: AxiosResponse<SMSCampaignDto> =
                await ApiClient.post(
                    url,
                    {},
                    createTokenConfig(store.getState().login.auth.token!)
                );
        } catch (apiError: any) {
            dispatch(showError('Error completing the operation.'));
        }
    };
};

export const resetSaveFormThunk = (): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => dispatch(saveReset());
};

export const getCampaignThunk = (
    campaign_id: number
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${baseUrl}/${campaign_id}?marketplace_id=${marketplaceId}`;
        const statsEP = `${statsUrl}${campaign_id}?marketplace_id=${marketplaceId}`;

        try {
            const { data }: AxiosResponse<SMSCampaign> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );

            const stats = await ApiClient.get(
                statsEP,
                createTokenConfig(store.getState().login.auth.token!)
            );

            dispatch(
                getSuccess({
                    ...data,
                    ...(data.status_id === SMSCampaignStatus.sent && {
                        stats: {
                            sent: stats?.data.sent,
                            sent_at: stats?.data.sent_at,
                            percentage: stats?.data.percentage
                        }
                    })
                })
            );
        } catch (apiError) {
            dispatch(showError('Error getting campaign.'));
        }
    };
};

export const updateCampaignStatusThunk = (
    campaign_id: number,
    status: SMSCampaignStatus
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(updateStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${baseUrl}/${campaign_id}?marketplace_id=${marketplaceId}`;

        try {
            const { data }: AxiosResponse<SMSCampaign> = await ApiClient.patch(
                url,
                {
                    sms_campaign: {
                        status_id: status,
                        marketplace_id: marketplaceId
                    }
                },
                createTokenConfig(store.getState().login.auth.token!)
            );

            dispatch(updateSuccess(data));
            dispatch(showSuccess('Campaign updated.'));
        } catch (apiError) {
            dispatch(showError('Error updating campaign.'));
        }
    };
};

export const deleteCampaignThunk = (
    campaign_id: number
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(updateStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${baseUrl}/${campaign_id}?marketplace_id=${marketplaceId}`;

        try {
            const { data }: AxiosResponse<SMSCampaign> = await ApiClient.delete(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );

            dispatch(updateSuccess(data));
            dispatch(showSuccess('Campaign deleted.'));
        } catch (apiError) {
            dispatch(showError('Error updating campaign.'));
        }
    };
};

export const fetchClientsPageThunk = (
    page = 1,
    size = 25,
    override = true
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        if (override) dispatch(fetchClientsStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const masterData = store.getState().masterData.data;
        const url = `${clientsUrl}?marketplace_id=${marketplaceId}&page=${page}&size=${size}`;

        try {
            const { data }: AxiosResponse<ClientDtoPage> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(fetchClientsSuccess(data, override, masterData));
        } catch (apiError) {
            dispatch(showError('Error fetching clients.'));
        }
    };
};

export const fetchClientsNameThunk = (
    name: string,
    page?: number,
    size?: number
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(searchClientsStart());

        const masterData = store.getState().masterData.data;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${clientUrl}/search?query=${name}&marketplace_id=${marketplaceId}&page=${page}&size=${size}&criteria=with_phone`;

        try {
            const { data }: AxiosResponse<ClientDtoPage> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(searchClientsSuccess(data, masterData));
        } catch (apiError) {
            dispatch(showError('Error fetching clients.'));
        }
    };
};

export const fetchClientGroupsThunk = (
    args?: string
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchClientGroupsStart());

        args = args || '30,60,90,180';
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${clientGroupsUrl}?marketplace_id=${marketplaceId}&args=${args}`;

        try {
            const { data }: AxiosResponse<SMSCampaignClientGroups> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );
            dispatch(fetchClientGroupsSuccess(data));
        } catch (apiError) {
            dispatch(showError('Error fetching client groups.'));
        }
    };
};

export const updateSMSCampaignCustomersThunk = (
    campaign: SMSCampaign,
    payload: {
        recipients: 'all' | 'criteria';
        selected_ids?: 'all' | number[];
        excluded_ids?: number[];
        criteria?: string;
        args?: number | string;
    }
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        if (!campaign.id) return;

        dispatch(updateCustomersStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${baseUrl}/${campaign.id}?marketplace_id=${marketplaceId}`;

        const data =
            payload.recipients === 'all'
                ? {
                      sms_campaign: {
                          // If all customers are selected, send all
                          ...(payload.selected_ids === 'all' && {
                              criteria: payload.recipients,
                              customer_ids: []
                          }),

                          // If only certain customers selected, send only ids
                          ...(payload.selected_ids !== 'all' && {
                              customer_ids: payload.selected_ids
                          })
                      },
                      exclude_ids: payload.excluded_ids
                  }
                : {
                      args: payload.args,
                      criteria: payload.criteria,
                      sms_campaign: {
                          customer_ids: ['criteria']
                      }
                  };

        try {
            const response: AxiosResponse<SMSCampaignDto> =
                await ApiClient.patch(
                    url,
                    data,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(increaseStep());
            dispatch(
                updateCustomersSuccess({
                    ...response.data,
                    ...(campaign.editing && { editing: true })
                })
            );
        } catch (apiError) {
            dispatch(showError('Error setting customers.'));
        }
    };
};

export const quoteSMSCampaignThunk = (
    subscriptions: number
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${quoteUrl}?marketplace_id=${marketplaceId}&subscriptions=${subscriptions}`;

        try {
            const { data }: AxiosResponse<SMSCampaignQuoteDto> =
                await ApiClient.get(
                    url,
                    createTokenConfig(store.getState().login.auth.token!)
                );

            dispatch(quoteSuccess(data));
        } catch (apiError) {
            dispatch(showError('Error getting campaign quote.'));
        }
    };
};

export const cloneSMSCampaignThunk = (
    campaign: SMSCampaign
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(duplicateCampaign({ ...campaign, editing: true }));
    };
};

export const editSMSCampaignThunk = (
    campaign: SMSCampaign
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(editCampaign({ ...campaign, editing: true }));
    };
};

export const createCampaginDropinThunk = (
    campaign_id?: number
): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction | NotificationsAction
> => {
    return async dispatch => {
        if (!campaign_id) {
            dispatch(showError('Error creating dropin'));
            return;
        }

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${dropinUrl}?marketplace_id=${marketplaceId}&id=${campaign_id}`;

        try {
            const response: AxiosResponse = await ApiClient.post(
                url,
                { id: campaign_id, marketplace_id: marketplaceId },
                createTokenConfig(store.getState().login.auth.token!)
            );
        } catch (apiError) {
            console.error(apiError);
        }
    };
};

export const setFilterThunk = (
    filter?: SMSCampaignStatus
): ThunkAction<void, SMSCampaignsState, null, SMSCampaignsAction> => {
    return async dispatch => {
        if (!filter) return;

        return dispatch(setFilter(filter));
    };
};

export const setSearchingThunk = (
    searching: boolean
): ThunkAction<void, SMSCampaignsState, null, SMSCampaignsAction> => {
    return async dispatch => dispatch(setClientSearching(searching));
};

export const decreaseStepThunk = (): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction
> => {
    return async dispatch => dispatch(decreaseStep());
};

export const increaseStepThunk = (): ThunkAction<
    void,
    SMSCampaignsState,
    null,
    SMSCampaignsAction
> => {
    return async dispatch => dispatch(increaseStep());
};

// Filter
const setFilter = (filter: SMSCampaignStatus): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_SET_FILTER_ACTION_TYPE,
        payload: { filter }
    };
};

// Fetch
const fetchGlobalStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_FETCH_GLOBAL_START_ACTION_TYPE
    };
};
const fetchGlobalSuccess = (data: SMSCampaignsDto): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS_ACTION_TYPE,
        payload: { data }
    };
};

const fetchStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_FETCH_START_ACTION_TYPE
    };
};
const fetchSuccess = (data: SMSCampaignsDto): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_FETCH_SUCCESS_ACTION_TYPE,
        payload: { data }
    };
};

// Getting
const getStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_GET_START_ACTION_TYPE
    };
};
const getSuccess = (campaign: SMSCampaign): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_GET_SUCCESS_ACTION_TYPE,
        payload: { campaign }
    };
};

// Save
const saveStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_SAVE_START_ACTION_TYPE
    };
};
const saveSuccess = (campaign: SMSCampaign): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_SAVE_SUCCESS_ACTION_TYPE,
        payload: { campaign }
    };
};
const saveReset = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_SAVE_RESET_ACTION_TYPE
    };
};

// Update
const updateStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_UPDATE_START_ACTION_TYPE
    };
};
const updateSuccess = (campaign: SMSCampaign): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_UPDATE_SUCCESS_ACTION_TYPE,
        payload: { campaign }
    };
};

// Customers
const updateCustomersStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START_ACTION_TYPE
    };
};
const updateCustomersSuccess = (campaign: SMSCampaign): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS_ACTION_TYPE,
        payload: { campaign }
    };
};

// Clients
const fetchClientsStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CLIENTS_FETCH_START_ACTION_TYPE
    };
};
const fetchClientsSuccess = (
    data: ClientDtoPage,
    override: boolean,
    masterData: MasterData
): SMSCampaignsAction => {
    const timezone = store.getState().marketplace.marketplace.schedule.timeZone;
    return {
        type: SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            ...data,
            override,
            customers: data.customers.map((customer: ClientDto) =>
                convertToClient(masterData, timezone!, customer)
            )
        }
    };
};

const searchClientsStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CLIENTS_SEARCH_START_ACTION_TYPE
    };
};
const searchClientsSuccess = (
    data: ClientDtoPage,
    masterData: MasterData
): SMSCampaignsAction => {
    const timezone = store.getState().marketplace.marketplace.schedule.timeZone;
    return {
        type: SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS_ACTION_TYPE,
        payload: {
            ...data,
            customers: data.customers.map((customer: ClientDto) =>
                convertToClient(masterData, timezone!, customer)
            )
        }
    };
};
const setClientSearching = (searching: boolean): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING_ACTION_TYPE,
        payload: { searching }
    };
};

// Edit
const editCampaign = (campaign: SMSCampaign): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_EDIT_ACTION_TYPE,
        payload: { campaign }
    };
};

// Clone
const duplicateCampaign = (campaign: SMSCampaign): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_DUPLICATE_ACTION_TYPE,
        payload: { campaign }
    };
};

/**
 * Client groups
 */
const fetchClientGroupsStart = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_START_ACTION_TYPE
    };
};
const fetchClientGroupsSuccess = (
    groups: SMSCampaignClientGroups
): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_SUCCESS_ACTION_TYPE,
        payload: { groups }
    };
};

// Step
const decreaseStep = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_DECREASE_STEP_ACTION_TYPE
    };
};
const increaseStep = (): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_INCREASE_STEP_ACTION_TYPE
    };
};

// Status
const updateStatus = (status: SMSCampaignsStatus): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_UPDATE_STATUS_ACTION_TYPE,
        payload: { status }
    };
};

// Quote
const quoteSuccess = (quote: SMSCampaignQuoteDto): SMSCampaignsAction => {
    return {
        type: SMS_CAMPAIGNS_QUOTE_ACTION_TYPE,
        payload: { quote }
    };
};
