import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import { Checkbox, TextField2 } from 'components/UI';
import { FunctionComponent, useEffect, useState } from 'react';
import { wbp } from 'Theme';
import { isEmailValid as validateEmail } from '@spike/validations';
import Section from './Section';
import { useHasPremiumAccess } from 'hooks';
import { isTemporalEmail } from 'utils/ValidationUtils';
import { PaymentMethodIds } from '@spike/payments-model';

interface SendingInformationProps {
    email: string;
    className?: string;
    hiddenReport?: boolean;
    paymentMethodSelected: string | null;
    hasReport?: boolean;
    onChange: (
        sendReport: boolean,
        sendInvoice: boolean,
        email: string
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridItem: {
            gap: 16,
            marginTop: 4,
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '0px !important'
        },
        emailValid: {
            color: '#5E8677'
        },
        emailInvalid: {
            color: '#FF0000'
        },
        gridMarginTop: {
            marginTop: 8
        },
        sendInvoice: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: '28px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '35px'
            }
        }
    })
);

export const SendingInformation: FunctionComponent<
    SendingInformationProps
> = props => {
    const classes = useStyles();
    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const [hideSendReport, setHideSendReport] = useState(true);
    const [showSendReport, setShowSendReport] = useState(false);
    const [sendReport, setSendReport] = useState(false);
    const [sendInvoice, setSendInvoice] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [email, setEmail] = useState(
        isTemporalEmail(props.email) ? '' : props.email
    );

    useNonInitialEffect(() => {
        const timer: NodeJS.Timer = setTimeout(() => {
            props.onChange && props.onChange(sendReport, sendInvoice, email);
        }, 200);
        return () => clearTimeout(timer);
    }, [email, sendReport, sendInvoice]);

    useEffect(() => {
        setIsEmailValid(validateEmail(email));
    }, [email]);

    const changeEmailHandler = (value: string) => {
        setEmail(value);
    };

    useEffect(() => {
        setSendReport(hasReportsPremiumAccess);
        setShowSendReport(hasReportsPremiumAccess);
    }, [hasReportsPremiumAccess]);

    useEffect(() => {
        if (!props.hiddenReport || !props.hasReport) {
            setSendReport(false);
        }
    }, [props.hiddenReport]);

    return (
        <Section title="Receipt & report" className={props.className}>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.gridItem}>
                    {!hideSendReport && (
                        <Checkbox
                            id={`booking_payment_send_info_checkbox_report`}
                            label="Send Report"
                            checked={sendReport}
                            disabled={!showSendReport}
                            onChange={setSendReport}
                        />
                    )}

                    {props.paymentMethodSelected !=
                        PaymentMethodIds.PayByLink && (
                        <Checkbox
                            id={`booking_payment_send_info_checkbox_receipt`}
                            label="Send Receipt"
                            checked={sendInvoice}
                            onChange={setSendInvoice}
                            className={clsx({
                                [classes.sendInvoice]: !props.hiddenReport
                            })}
                        />
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={clsx(classes.gridItem, classes.gridMarginTop)}
                >
                    {sendInvoice && (
                        <TextField2
                            id="booking_payment_send_info_email_text_input"
                            label=""
                            value={email}
                            onChange={changeEmailHandler}
                            endIcon={
                                isEmailValid ? (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={classes.emailValid}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className={classes.emailInvalid}
                                    />
                                )
                            }
                        />
                    )}
                </Grid>
            </Grid>
        </Section>
    );
};

export default SendingInformation;
