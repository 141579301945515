import React, { FunctionComponent, ChangeEvent, KeyboardEvent, useState, useEffect } from "react";
import { Box, OutlinedInput, InputBaseComponentProps } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "@spike/model";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { useFieldStyles } from "./FieldStyles";
import { CharacterCounter } from "components/UI/CharacterCounter";
import LabelField from "./LabelField";

export interface DescriptionField2Props {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: string;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: string, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  onEnterPressed?: () => void;
  disabled?: boolean;
  capitalize?: boolean;
  upperCase?: boolean;
  maxCharacters?: number;
  maxRows?: number;
  minRows?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    withConter: {
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "17px",
        paddingRight: "17px",
        paddingBottom: "4px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "23px",
        paddingRight: "23px",
        paddingBottom: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingBottom: "10px",
      },
    },
    counterContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
  })
);

export const DescriptionField2: FunctionComponent<DescriptionField2Props> = (props) => {
  const fieldClasses = useFieldStyles();
  const classes = useStyles();

  const [text, setText] = useState(props.value);

  useEffect(() => {
    setText(props.value);
  }, [props.value]);

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(text, props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (!props.maxCharacters || props.maxCharacters >= newValue.length) {
      if (props.capitalize) {
        setText(startCase(camelCase(newValue)));
      } else if (props.upperCase) {
        setText(newValue?.toUpperCase());
      } else {
        setText(newValue);
      }
    }
  };

  const onFocusHandler = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  const counter = (
    <Box className={classes.counterContainer}>
      <CharacterCounter max={props.maxCharacters || 0} text={text} />
    </Box>
  );

  let style: InputBaseComponentProps | undefined = undefined;
  if (props.capitalize) {
    style = { style: { textTransform: "capitalize" } };
  } else if (props.upperCase) {
    style = { style: { textTransform: "uppercase" } };
  }

  return (
    <Box className={clsx(fieldClasses.container, props.className)}>
      <LabelField {...props} />
      <Box>
        <OutlinedInput
          name={props.name}
          placeholder={props.placeholder}
          value={text}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          className={clsx(fieldClasses.descriptionField, {
            [fieldClasses.emptyField]: text === null || text.length === 0,
            [classes.withConter]: props.maxCharacters,
            [fieldClasses.fieldError]: hasError,
          })}
          error={hasError}
          multiline={true}
          maxRows={props.maxRows}
          minRows={props.minRows}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
          inputProps={style}
          endAdornment={props.maxCharacters ? counter : undefined}
        />
      </Box>
    </Box>
  );
};

export default DescriptionField2;
