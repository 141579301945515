import { Address } from "@spike/model";
import { Product } from "@spike/product-model";
import { v4 as createUuid } from "uuid";

export default interface ProductVendor {
  id?: number;
  uuid: string;
  name: string;
  website?: string;
  contact?: string;
  phone?: string;
  email?: string;
  address?: Address;
  products: Array<Product>;
  active: boolean;
  deleted: boolean;
}

export const createEmptyVendor = (): ProductVendor => {
  return {
    uuid: createUuid(),
    name: "",
    products: [],
    active: true,
    deleted: false
  };
};

export enum SortFields {
  VENDOR_NAME = "vendorName",
  CONTACT = "contact",
  PHONE = "phone",
  EMAIL = "email",
  STATE = "state",
  PRODUCTS = "products",
  WEBSITE = "website",
  STATUS = "status"
}

export enum SortOrder {
  ASCENDANT = "ascendant",
  DESCENDANT = "descendant",
  CREATION_DATE = "creationDate"
}
