import {
    Box,
    createStyles,
    FormLabel,
    Grid,
    makeStyles,
    Theme
} from '@material-ui/core';
import QuillContent from 'components/UI/QuillContent';
import { Agreement } from 'model/Agreements';
import WizardPage from 'pages/WizardPage';
import { FunctionComponent } from 'react';
import { sendToDescriptions } from './model';
import { useMasterData } from 'hooks';
import PortalModal from 'components/UI/Portals';

interface AgreementPreviewModalProps {
    agreement?: Agreement;
    isActive: boolean;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#222',
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: '20px',
            marginLeft: '-24px'
        },
        input: {
            'width': '100%',
            '& .MuiOutlinedInput': {
                '&-root': {
                    borderRadius: 30
                },
                '&-input': {
                    padding: '18px 35px'
                }
            },
            '& .MuiFormLabel': {
                '&-filled': {
                    display: 'none'
                },
                '&-asterisk': {
                    color: '#EAB464'
                }
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(30px, 19px) scale(1)'
            },
            '& fieldset': {
                'top': 0,
                'borderColor': 'rgba(0, 0, 0, 0.23) !important',

                '& legend': {
                    display: 'none'
                }
            },
            '& .Mui-focused': {
                'color': 'rgba(0, 0, 0, 0.54)',

                '& fieldset': {
                    borderWidth: '1px !important'
                }
            }
        },
        group: {
            'marginBottom': 35,
            [theme.breakpoints.down('md')]: {
                marginBottom: 25
            },
            '& > label': {
                color: '#222',
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 600,
                marginBottom: 20,
                display: 'block'
            },
            '& > p': {
                fontSize: 15,
                lineHeight: 1.4,
                fontWeight: 400,
                marginBottom: 30
            }
        },
        content: {
            fontSize: 18,
            lineHeight: 1.4,
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
                maxWidth: 'calc(100vw - 70px) !important'
            }
        },
        quillContent: {
            borderRadius: 12,
            padding: '35px 30px',
            border: '1px solid #D4D4D4',
            [theme.breakpoints.down('md')]: {
                fontSize: 13
            }
        },
        radioGroup: {
            'marginTop': 40,
            '& label': {
                marginBottom: 15
            }
        },
        radioOption: {
            'color': '#222',
            'height': '100%',
            'cursor': 'pointer',
            'padding': '20px 15px',
            'display': 'flex',
            'alignItems': 'flex-start',
            'borderRadius': 12,
            'backgroundColor': '#fff',
            'border': '1px solid #D3D3D3',
            '& .MuiRadio-root': {
                padding: 0,
                color: '#222',
                marginRight: 7
            },
            '& p': {
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 500,
                marginBottom: 9
            },
            '& span': {
                fontSize: 14,
                lineHeight: 1.2,
                fontWeight: 400,
                display: 'block'
            }
        },
        inputLabel: {
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        inputText: {
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        container: {
            minHeight: '65vh'
        },
        wrapper: {
            'display': 'flex',
            'flex': 1,
            '& > .MuiBox-root > div:nth-child(2)': {
                overflowY: 'hidden'
            }
        }
    })
);

export const AgreementPreviewModal: FunctionComponent<
    AgreementPreviewModalProps
> = props => {
    const classes = useStyles();
    const sentToOptions = useMasterData().agreementsSendTo.map(option => ({
        ...option,
        description: sendToDescriptions.get(option.id)
    }));

    return (
        <PortalModal mode="fullModal" isActive={props.isActive}>
            <Box className={classes.wrapper}>
                <WizardPage
                    isLoading={false}
                    closePath=""
                    isFooter={false}
                    onClickClosePath={props.onClose}
                >
                    <Grid container justifyContent="center">
                        <Grid item md={6} xs={12}>
                            <Box className={classes.container}>
                                <Box className={classes.group}>
                                    <FormLabel>Agreement Title</FormLabel>
                                    <Box className={classes.content}>
                                        {props.agreement?.title}
                                    </Box>
                                </Box>
                                <Box className={classes.group}>
                                    <FormLabel>
                                        When should this agreement be sent?
                                    </FormLabel>
                                    <Box className={classes.content}>
                                        {
                                            sentToOptions.find(
                                                option =>
                                                    option.id ===
                                                    props.agreement?.sendTo
                                            )?.name
                                        }
                                    </Box>
                                </Box>

                                <Box className={classes.group}>
                                    <FormLabel>Agreement Content</FormLabel>
                                    <QuillContent
                                        className={classes.quillContent}
                                        content={props.agreement!.content}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </WizardPage>
            </Box>
        </PortalModal>
    );
};
export default AgreementPreviewModal;
