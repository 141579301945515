import React, {
	FunctionComponent,
	MouseEvent,
	useState,
	useEffect
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { Chip, Avatar, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import SearchDropDown, { unassignedId } from './SearchDropDown';
import { AvailableStaffState } from '@spike/available-staff-action';
import { AvailableStaff } from '@spike/available-staff-model';
import { fetchAvailableStaffThunk } from '@spike/available-staff-action';
import { reduceResolution, wbp } from 'Theme';
import { useApiClientWrapper } from 'hooks';

interface Props {
	appointmentId: number;
	selectedMember?: AvailableStaff;
	className?: string;
	editable?: boolean;
	unassignable?: boolean;
	maxWidthButton?: boolean;
	//
	onSelectMember?: (member: AvailableStaff | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		chip: {
			border: '1px solid transparent',
			backgroundColor: '#f1f1f1 !important',
			color: '#222',
			cursor: 'default',
			maxWidth: '165px',
			fontSize: 14,
			padding: 4,

			[theme.breakpoints.up('md')]: {
				padding: '12px 2px',
				fontSize: '13px',
				maxWidth: '255px'
			}
		},
		editable: {
			'cursor': 'pointer',
			'&:hover': {
				border: '1px solid #000000',
				backgroundColor: '#F1F1F1 !important'
			}
		},
		isNew: {
			[theme.breakpoints.down(wbp)]: {
				maxWidth: '150px'
			},
			[theme.breakpoints.up(wbp)]: {
				maxWidth: '200px'
			}
		},
		searchDropDownContainer: {
			position: 'absolute',
			width: '130%',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '62px'
			},
			[theme.breakpoints.only('md')]: {
				maxWidth: '200px'
			},
			[theme.breakpoints.only('lg')]: {
				maxWidth: '200px'
			},
			[theme.breakpoints.only('xl')]: {
				maxWidth: '200px'
			},
			top: 0,
			left: 0,
			zIndex: 100
		}
	})
);

const EditableStaffChip: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const apiClientWrapper = useApiClientWrapper();

	const [availableStaff, setAvailableStaff] = useState<Array<AvailableStaff>>(
		[]
	);
	const [loading, setLoading] = useState(true);
	const [selectedMember, setSelectedMember] = useState(props.selectedMember);

	const [showDropDown, setShowDropDown] = useState(false);

	const dispatch = useDispatch();
	const state = useSelector<RootState, AvailableStaffState>(
		state => state.availableStaff
	);

	useEffect(() => {
		setSelectedMember(props.selectedMember);
		setAvailableStaff(prev => prev.filter(staff => staff.id));
	}, [props.selectedMember]);

	useEffect(() => {
		if (state.availableStaffForAppointment.has(props.appointmentId)) {
			const staff = [
				...state.availableStaffForAppointment.get(props.appointmentId)!
			];
			setAvailableStaff(staff);
			setLoading(false);
		}
	}, [state.availableStaffForAppointment]);

	const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		if (props.editable) {
			dispatch(
				fetchAvailableStaffThunk(apiClientWrapper, props.appointmentId)
			);
			setLoading(true);
			setShowDropDown(true);
		}
	};

	const selectHandler = (id: number) => {
		setShowDropDown(false);
		if (id === unassignedId) {
			setSelectedMember(undefined);
			props.onSelectMember && props.onSelectMember(undefined);
		} else {
			const member = availableStaff.find(member => member.id === id);
			setSelectedMember(member);
			props.onSelectMember && props.onSelectMember(member);
		}
	};

	const avatar = <Avatar src={selectedMember?.avatar} />;

	const chip = (
		<Chip
			avatar={avatar}
			label={selectedMember?.firstName || 'Unassigned'}
			className={clsx(
				classes.chip,
				{ [classes.editable]: props.editable },
				{ [classes.isNew]: props.maxWidthButton }
			)}
			onClick={clickHandler}
		/>
	);

	const searchDropDown = (
		<Box className={classes.searchDropDownContainer}>
			<SearchDropDown
				staff={availableStaff.filter(
					member => member.id !== selectedMember?.id
				)}
				selected={props.selectedMember}
				onSelect={selectHandler}
				onClickAway={() => {
					setShowDropDown(false);
				}}
				loading={loading}
				unassignable={props.unassignable}
			/>
		</Box>
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			{chip}
			{showDropDown && searchDropDown}
		</Box>
	);
};

export default EditableStaffChip;
