import { width } from '@fortawesome/pro-light-svg-icons/faTimes';
import {
	makeStyles,
	Theme,
	createStyles,
	Box,
	Drawer,
	ClickAwayListener
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { FunctionComponent, useState } from 'react';

interface Props {
	open: boolean;
	children?: JSX.Element;
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			flex: 1,
			width: 482,
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			[theme.breakpoints.down("sm")]: {
				width: '100%',
			}
		}
	})
);

export const NotificationsDrawer: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const [openModal, setOpenModal] = useState(props.open);

	useNonInitialEffect(() => {
		setOpenModal(props.open);
	}, [props.open]);

	const handleClickAway = () => {
		setOpenModal(false);
		props.onClose && props.onClose();
	};

	return (
		<ClickAwayListener
			onClickAway={() => (openModal === true ? handleClickAway() : {})}
		>
			<Drawer
				elevation={0}
				open={openModal}
				anchor={'right'}
				variant={'persistent'}
				BackdropProps={{ invisible: true }}
				onClose={props.onClose}
			>
				<Box className={classes.content}>{props.children}</Box>
			</Drawer>
		</ClickAwayListener>
	);
};

export default NotificationsDrawer;
