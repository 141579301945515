import { Box, createStyles, InputBase, makeStyles, Theme, Typography } from "@material-ui/core";
import { useNonInitialEffect } from "@versiondos/hooks";
import clsx from "clsx";
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useCommonStyles } from "./CommonStyles";
import { wbp } from "Theme";

interface InvoiceAmountProps {
  amount: number;
  className?: string;
  error?: boolean;
  onChange?: (amount: number) => void;
  onBlur?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      borderRadius: "100px",
      border: "1px solid #222222",
      [theme.breakpoints.down(wbp)]: {
        width: "111px",
        height: "29px",
        padding: "0px 15px",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "139px",
        height: "36px",
        padding: "0px 19px",
      },
    },
    adornment: {
      marginTop: "-1px",
    },
    input: {
      width: "100%",
      "& input": {
        textAlign: "right"
      }
    },
  })
);

export const InvoiceAmount: FunctionComponent<InvoiceAmountProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [value, setValue] = useState(props.amount || "0.00");

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange && props.onChange(Number(value));
    }, 200);
    return () => clearTimeout(timer);
  }, [value]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const text = event.target.value;
    const number = Number(text);

    if (text === "" || (!isNaN(number) && number >= 0)) {
      setValue(text);
    }
  };

  const dollarAdornment = <Typography className={clsx(commonClasses.inputText, classes.adornment)}>$</Typography>;

  return (
    <Box className={clsx(classes.container, props.className)}>
      <InputBase
        className={clsx(commonClasses.inputText, classes.input)}
        value={value}
        onChange={changeHandler}
        startAdornment={dollarAdornment}
        onBlur={props.onBlur}
      />
    </Box>
  );
};

export default InvoiceAmount;
