import { FieldError } from '@spike/model';
import AddOnService from '@spike/addon-service-model';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

export const validate = (service: AddOnService): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (isEmpty(service.name)) {
        errors.push({
            fieldName: 'name',
            errorMessage: 'Service name is required'
        });
    }
    if (isNil(service.price) || isEqual(service.price, 0)) {
        errors.push({ fieldName: 'price', errorMessage: 'Price is required' });
    }

    return errors;
};
