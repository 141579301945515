import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCommonStyles } from 'components/UI/CommonStyles';
import Invoice from '@spike/invoice-model';
import { Fragment, FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import CalculatingSpinner from 'components/UI/CalculatingSpinner';

interface SingleSaleTotalsProps {
	invoice: Invoice;
	calculating?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				width: '500px'
			}
		},
		boxTotal: {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(20)}px 0px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '20px 0px'
			}
		},
		line: {
			borderBottom: '1px solid #D4D4D4'
		}
	})
);

export const SingleSaleTotals: FunctionComponent<
	SingleSaleTotalsProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<Fragment>
			<Box className={clsx(classes.container, props.className)}>
				<Box
					className={clsx(
						commonClasses.flexRow,
						classes.boxTotal,
						classes.line
					)}
				>
					<Typography className={commonClasses.typo18_400}>
						Sub Total
					</Typography>
					{props.calculating ? (
						<CalculatingSpinner />
					) : (
						<Typography className={commonClasses.typo18_400}>
							${props.invoice.subtotal}
						</Typography>
					)}
				</Box>

				{/*<Box className={clsx(commonClasses.flexRow, classes.boxTotal, classes.line)}>
          <Typography className={commonClasses.typo18_400}>Tax</Typography>
          {props.calculating ? <CalculatingSpinner/> : <Typography className={commonClasses.typo18_400}>${props.invoice.tax.amount}</Typography>}
        </Box>*/}

				<Box>
					{Object.entries(props.invoice.tax.detail).map(entry => {
						const [key, value] = entry;

						return (
							<Box
								key={key}
								className={clsx(
									commonClasses.flexRow,
									classes.boxTotal,
									classes.line
								)}
							>
								<Typography
									className={commonClasses.typo18_400}
								>
									{key}
								</Typography>
								{props.calculating ? (
									<CalculatingSpinner />
								) : (
									<Typography
										className={commonClasses.typo18_400}
									>
										${value}
									</Typography>
								)}
							</Box>
						);
					})}
				</Box>

				{props.invoice.tip &&
					Number(props.invoice.tip.amount) !== 0 && (
						<Box
							className={clsx(
								commonClasses.flexRow,
								classes.boxTotal,
								classes.line
							)}
						>
							<Typography className={commonClasses.typo18_400}>
								Tip
							</Typography>
							{props.calculating ? (
								<CalculatingSpinner />
							) : (
								<Typography
									className={commonClasses.typo18_400}
								>{`${
									props.invoice.tip?.percentage
										? '(' +
										  props.invoice.tip?.percentage +
										  '%) '
										: ''
								}$${props.invoice.tip.amount}`}</Typography>
							)}
						</Box>
					)}
				<Box className={clsx(commonClasses.flexRow, classes.boxTotal)}>
					<Typography className={commonClasses.typo18_600}>
						Total
					</Typography>
					{props.calculating ? (
						<CalculatingSpinner />
					) : (
						<Typography className={commonClasses.typo18_600}>
							${props.invoice.grandTotal}
						</Typography>
					)}
				</Box>
			</Box>
		</Fragment>
	);
};

export default SingleSaleTotals;
