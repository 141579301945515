import {
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Theme,
    createStyles,
    makeStyles
} from '@material-ui/core';
import Client from '@spike/client-model';
import {
    ClientsStatus,
    clearClientsThunk,
    fetchClientsThunk,
    searchClientsThunk
} from '@spike/clients-action';
import { RootState } from 'store';
import useNonInitialEffect from '@versiondos/hooks';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { wbp, reduceResolution } from 'Theme';
import { Button } from 'components/UI';
import PulseLoader from 'react-spinners/PulseLoader';

interface SearchClientsResultProps {
    onSelectClient?: (clientId?: number) => void;
    onAddClient: () => void;
    clientSearchText: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: { display: 'flex', flexDirection: 'column', height: '100%' },
        clientList: {
            height: '90%'
        },
        clientScroll: {
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(15)}px ${reduceResolution(
                    15
                )}px ${reduceResolution(15)}px ${reduceResolution(15)}px`,
                maxHeight: reduceResolution(540),
                minHeight: `${reduceResolution(540)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '15px 15px 15px 15px',
                maxHeight: 540,
                minHeight: '540px'
            }
        },
        newClientButtonInList: {
            width: 'auto',
            fontWeight: 500,
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(35)}px`,
                paddingRight: `${reduceResolution(35)}px`,
                marginTop: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '35px',
                paddingRight: '35px',
                marginTop: '12px'
            },
            display: 'flex',
            justifyContent: 'right'
        },
        clientItemContainer: {
            [theme.breakpoints.down(wbp)]: {
                padddingTop: `${reduceResolution(12)}px`,
                padddingBottom: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '12px',
                paddingBottom: '12px'
            },
            'cursor': 'pointer',
            '&:hover': {
                backgroundColor: '#FAFBFA'
            }
        },
        clientDivider: {
            backgroundColor: '#F1F1F1',
            margin: 'auto',
            width: '90%'
        },
        clientText: {
            fontWeight: 600
        },
        clientAvatar: {
            backgroundColor: '#DCCCBB',
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(15)}px`,
                width: '25px',
                height: '25px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '15px'
            }
        },
        loaderContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
        }
    })
);

const SearchClientsResult: FunctionComponent<
    SearchClientsResultProps
> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const apiClientWrapper = useApiClientWrapper();

    const clientsStatus = useSelector<RootState, ClientsStatus>(
        state => state.clients.status
    );

    // const page = useSelector<RootState, number>(state =>
    //     Number(state.clients.page)
    // );

    const clientsPageSize = 30;

    const clientsList = useSelector<RootState, Array<Client>>(
        state => state.clients.list
    );

    const clientsLoading = useSelector<RootState, boolean>(
        state => state.clients.loading
    );
    // const chatScrollRef = useRef<OverlayScrollbarsComponent>(null);

    useNonInitialEffect(() => {
        fetchClients(1, clientsPageSize);
    }, [props.clientSearchText]);

    useNonInitialEffect(() => {
        if (clientsStatus === ClientsStatus.SaveSuccess) {
            dispatch(clearClientsThunk());
            fetchClients(1, clientsPageSize);
            return () => {
                dispatch(clearClientsThunk());
            };
        }
    }, [clientsStatus]);

    const fetchClients = (page: number, pageSize: number) => {
        if (props.clientSearchText.length === 0) {
            dispatch(fetchClientsThunk(apiClientWrapper, page, pageSize));
        } else if (props.clientSearchText.length >= 3) {
            dispatch(
                searchClientsThunk(
                    apiClientWrapper,
                    props.clientSearchText,
                    page,
                    pageSize
                )
            );
        }
    };

    const selectClientHandler = (clientId: number): void => {
        if (clientId) {
            props.onSelectClient && props.onSelectClient(clientId);
        }
    };

    return (
        <Box className={classes.container}>
            {clientsLoading ? (
                <Box className={classes.loaderContainer}>
                    <PulseLoader color="orange" loading={true} size={10} />
                </Box>
            ) : (
                <Box>
                    <Box
                        className={clsx(classes.clientList)}
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper'
                        }}
                    >
                        <OverlayScrollbarsComponent
                            className={clsx(classes.clientScroll)}
                        >
                            <nav aria-label="client item">
                                <List>
                                    {clientsList.map((client, index) => {
                                        return (
                                            <Box key={index + client.uuid}>
                                                <ListItem
                                                    className={
                                                        classes.clientItemContainer
                                                    }
                                                    onClick={() =>
                                                        selectClientHandler(
                                                            client.id!
                                                        )
                                                    }
                                                >
                                                    <Avatar
                                                        className={
                                                            classes.clientAvatar
                                                        }
                                                    />
                                                    <ListItemText
                                                        className={
                                                            classes.clientText
                                                        }
                                                        primary={
                                                            client.firstName +
                                                            ' ' +
                                                            client.lastName
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    className={
                                                        classes.clientDivider
                                                    }
                                                />
                                            </Box>
                                        );
                                    })}
                                </List>
                            </nav>
                        </OverlayScrollbarsComponent>
                    </Box>
                    <Box className={classes.newClientButtonInList}>
                        <Button
                            color="orange"
                            size="small"
                            startIcon={faPlus}
                            label="Add client"
                            onClick={props.onAddClient}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SearchClientsResult;
