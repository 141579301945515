import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

interface TabHeaderProps {
    label: string;
    selected: boolean;
    onClick?: () => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            height: '100%',
            cursor: 'pointer',
            marginRight: '25px',
            [theme.breakpoints.down('sm')]: {
                marginRight: '15px'
            }
        },
        selectedContainer: {
            borderBottom: '3px solid #222222'
        },
        label: {
            fontFamily: 'Poppins',
            color: '#BCB8AE',
            fontSize: '20px',
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        selectedLabel: {
            color: '#222222'
        }
    })
);

const TabHeader: FunctionComponent<TabHeaderProps> = props => {
    const classes = useStyles(props);

    return (
        <Box
            className={clsx(classes.container, {
                [classes.selectedContainer]: props.selected
            })}
            onClick={props.onClick}
        >
            <Typography
                className={clsx(classes.label, {
                    [classes.selectedLabel]: props.selected
                })}
            >
                {props.label}
            </Typography>
        </Box>
    );
};

export default TabHeader;
