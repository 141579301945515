import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from 'components/UI/CommonStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';

interface QuantityProps {
	value: number;
	className?: string;
	onChange: (value: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},

		boxQty: {
			display: 'flex',
			alignItems: 'center'
		},

		typoQty: {
			display: 'flex',
			[theme.breakpoints.down(wbp)]: {
				paddingRight: `${reduceResolution(17)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingRight: '17px'
			}
		},

		sectionIconQty: {
			'fontSize': 20,
			'display': 'flex',

			'&:hover': {
				cursor: 'pointer'
			}
		},

		typoAmount: {
			fontSize: 16,
			textAlign: 'center',
			padding: '0px 16px',

			[theme.breakpoints.up('md')]: {
				width: 28,
				paddingLeft: '10px',
				paddingRight: '10px'
			}
		}
	})
);

export const Quantity: FunctionComponent<QuantityProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const iconSVG = (icon: IconDefinition, styles: string) => {
		return <FontAwesomeIcon icon={icon} className={styles} />;
	};

	const reduceQtyHandler = (value: number) => {
		props.onChange(value === 0 ? 0 : value - 1);
	};

	const addQtyHandler = (value: number) => {
		props.onChange(value + 1);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={clsx(commonClasses.flexRowCenter, classes.boxQty)}>
				<Box onClick={() => reduceQtyHandler(props.value)}>
					{iconSVG(faMinusCircle, `${classes.sectionIconQty}`)}
				</Box>
				<Typography className={clsx(classes.typoAmount)}>
					{props.value}
				</Typography>
				<Box onClick={() => addQtyHandler(props.value)}>
					{iconSVG(faPlusCircle, `${classes.sectionIconQty}`)}
				</Box>
			</Box>
		</Box>
	);
};

export default Quantity;
