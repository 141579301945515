import { logoutAuth } from '@spike/auth-model';
import {
    LOGIN_CHANGE_IMAGE_ACTION_TYPE,
    LOGIN_CHANGE_MARKETPLACE_ACTION_TYPE,
    LOGIN_END_ACTION_TYPE,
    LOGIN_FAIL_ACTION_TYPE,
    LOGIN_RESET_ACTION_TYPE,
    LOGIN_SET_ROLE_AND_ACCESS_LEVEL_ACTION_TYPE,
    LOGIN_SET_TIME_ZONE_ACTION_TYPE,
    LOGIN_START_ACTION_TYPE,
    LOGIN_SUCCESS_ACTION_TYPE,
    LOGOUT_ACTION_TYPE,
    LoginAction
} from 'actions/login/LoginActionsTypes';
import { getFromStorage } from 'auth';
import { Reducer } from 'redux';
import { LoginState } from './LoginState';
import { ACCESS_LEVEL_ADMIN_ID } from '@spike/model';

const auth = getFromStorage();

const initialState: LoginState = {
    auth,
    failed: false,
    loading: false
};

export const loginReducer: Reducer<LoginState, LoginAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case LOGIN_START_ACTION_TYPE:
            return {
                ...state,
                loading: true,
                failed: false
            };
        case LOGIN_END_ACTION_TYPE:
            return {
                ...state,
                loading: false
            };
        case LOGIN_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                auth: action.payload.auth,
                loading: false
            };
        case LOGIN_FAIL_ACTION_TYPE:
            return {
                ...state,
                failed: true
            };
        case LOGIN_SET_TIME_ZONE_ACTION_TYPE:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    profileCompleted: true,
                    timeZone: action.payload.timeZone
                }
            };
        case LOGIN_SET_ROLE_AND_ACCESS_LEVEL_ACTION_TYPE:
            return {
                ...state,
                auth:
                    state.auth !== undefined &&
                    state.auth.user !== undefined &&
                    state.auth.user.staffId === action.payload.staffId
                        ? {
                              ...state.auth,
                              user: {
                                  ...state.auth.user,
                                  role: { ...action.payload.role },
                                  accessLevel: {
                                      ...action.payload.accessLevel
                                  },
                                  admin:
                                      action.payload.accessLevel.id ===
                                      ACCESS_LEVEL_ADMIN_ID
                              }
                          }
                        : { ...state.auth }
            };
        case LOGIN_CHANGE_IMAGE_ACTION_TYPE:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    user: state.auth.user
                        ? { ...state.auth.user, avatar: action.payload.image }
                        : undefined
                }
            };
        case LOGIN_CHANGE_MARKETPLACE_ACTION_TYPE:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    marketplaceId: action.payload.marketplaceId,
                    addressId: action.payload.addressId,
                    timeZone: action.payload.timeZone,
                    user:
                        state.auth.user !== undefined
                            ? {
                                  ...state.auth.user,
                                  role: action.payload.role,
                                  accessLevel: action.payload.accessLevel,
                                  admin:
                                      action.payload.accessLevel.id ===
                                      ACCESS_LEVEL_ADMIN_ID
                              }
                            : undefined
                }
            };
        case LOGIN_RESET_ACTION_TYPE:
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                auth: logoutAuth,
                failed: false,
                loading: false
            };
        default:
            return state;
    }
};

export default loginReducer;
