import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			padding: '0px 0px 20px',

			[theme.breakpoints.up('lg')]: {
				paddingLeft: '75px',
				paddingRight: '80px',
				paddingBottom: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '101px',
				paddingRight: '107px',
				paddingBottom: '32px'
			}
		}
	})
);

export const BodyResumeSection: FunctionComponent<
	PropsWithChildren
> = props => {
	const classes = useStyles();

	return <Box className={classes.container}>{props.children}</Box>;
};

export default BodyResumeSection;
