import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, Week } from '@spike/model';
import clsx from 'clsx';
import { Checkbox, CheckboxCircle, HourField } from 'components/UI';
import { useTimeZone } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent } from 'react';

interface StaffScheduleDayProps {
	id?: string;
	day: Day;
	marketplaceSchedule: Week;
	errors?: Array<FieldError>;
	onChange?: (day: Day) => void;
	className?: string;
}

export interface Day {
	id?: number;
	uuid: string;
	from: Moment;
	to: Moment;
	on: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				margin: 0,
				padding: '8px 0px'
			}
		},
		checkboxContainer: {
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				width: '40px'
			},
			[theme.breakpoints.only('md')]: {
				width: '40px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '40px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '60px'
			}
		},
		labelErrorContainer: {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
			justifyContent: 'center'
		},
		labelContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		dateContainer: {
			display: 'flex',
			alignItems: 'center',
			paddingRight: 6,

			[theme.breakpoints.up('md')]: {
				paddingRight: 15
			}
		},
		date: {
			fontSize: 16,
			fontWeight: 500,
			lineHeight: 1,
			color: '#000',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		dayContainer: {
			display: 'flex'
		},
		off: {
			color: '#7A7A7A'
		},
		hourOff: {
			'borderColor': '#D3D3D3',
			'& input': {
				color: '#7A7A7A'
			},
			'& button': {
				backgroundColor: 'white'
			},
			'& button > span': {
				color: '#7A7A7A'
			}
		},
		textRight: {
			textAlign: 'right'
		},
		right: {
			justifyContent: 'flex-end'
		},
		businessHoursContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		hourContainer: {
			dispaly: 'flex',
			alignItems: 'center'
		},
		separatorContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: 15,
			width: '10%',
			margin: '0px 4px'
		},
		error: {
			color: '#EA6464'
		},
		errorMessage: {
			fontSize: '10px'
		},
		desktop: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		mobile: {
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}
	})
);

export const StaffScheduleDay: FunctionComponent<
	StaffScheduleDayProps
> = props => {
	const classes = useStyles();

	const timeZone = useTimeZone();

	const marketplaceBusinessHours =
		props.marketplaceSchedule[
			props.day.from.clone().format('dddd').toLowerCase() as keyof Week
		];

	const hasError = props.errors?.some(
		error => error.fieldName === props.day.uuid
	);

	const checkHandler = (check: boolean, timeZone: string) => {
		const newDay = {
			...props.day,
			from: check
				? moment.tz(
						`${props.day.from.clone().format('YYYYMMDD')} ${
							marketplaceBusinessHours?.open || '8:00'
						}`,
						'YYYYMMDD HH:mm',
						timeZone
				  )
				: props.day.from.clone().startOf('day'),
			to: check
				? moment.tz(
						`${props.day.to.clone().format('YYYYMMDD')} ${
							marketplaceBusinessHours?.close || '17:00'
						}`,
						'YYYYMMDD HH:mm',
						timeZone
				  )
				: props.day.to.clone().endOf('day'),
			on: check
		};

		props.onChange && props.onChange(newDay);
	};

	const focusHandler = () => {
		const newDay = {
			...props.day,
			on: true
		};

		props.onChange && props.onChange(newDay);
	};

	const changeFromHandler = (hour24: string, timeZone: string) => {
		const newDay = {
			...props.day,
			on: true,
			from: moment.tz(
				`${props.day.from.clone().format('YYYYMMDD')} ${hour24}`,
				'YYYYMMDD HH:mm',
				timeZone
			)
		};

		props.onChange && props.onChange(newDay);
	};

	const changeToHandler = (hour24: string, timeZone: string) => {
		const newDay = {
			...props.day,
			on: true,
			to: moment.tz(
				`${props.day.to.clone().format('YYYYMMDD')} ${hour24}`,
				'YYYYMMDD HH:mm',
				timeZone
			)
		};

		props.onChange && props.onChange(newDay);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.checkboxContainer}>
				<Checkbox
					iconColor="black"
					checked={props.day.on}
					id={props.id ? props.id + '_checkbox' : props.id}
					onChange={check => checkHandler(check, timeZone)}
				/>
			</Box>
			<Box className={classes.labelErrorContainer}>
				<Box className={classes.labelContainer}>
					<Box className={classes.dateContainer}>
						<Typography
							className={clsx(
								classes.date,
								classes.textRight,
								{ [classes.off]: !props.day.on },
								{ [classes.error]: hasError }
							)}
						>
							{props.day.from.clone().format('MM/DD')}
						</Typography>
					</Box>
					<Box className={classes.dayContainer}>
						<Typography
							className={clsx(
								classes.date,
								{ [classes.off]: !props.day.on },
								{ [classes.error]: hasError }
							)}
						>
							<span className={classes.desktop}>
								{props.day.from.clone().format('dddd')}
							</span>
							<span className={classes.mobile}>
								{props.day.from.clone().format('ddd')}
							</span>
						</Typography>
					</Box>
				</Box>
				{hasError && (
					<Box>
						<Typography
							className={clsx(
								classes.errorMessage,
								classes.error
							)}
						>
							{
								props.errors?.find(
									error => error.fieldName === props.day.uuid
								)?.errorMessage
							}
						</Typography>
					</Box>
				)}
			</Box>
			<Box className={classes.businessHoursContainer}>
				<Box className={classes.hourContainer}>
					<HourField
						className={clsx({ [classes.hourOff]: !props.day.on })}
						value24Hs={
							props.day.on
								? props.day.from.clone().format('HH:mm')
								: marketplaceBusinessHours?.open
						}
						onChange={hour24 => changeFromHandler(hour24, timeZone)}
						onFocus={focusHandler}
						error={hasError}
					/>
				</Box>
				<Box
					className={clsx(classes.separatorContainer, {
						[classes.error]: hasError
					})}
				>
					{' '}
					to{' '}
				</Box>
				<Box className={clsx(classes.hourContainer, classes.right)}>
					<HourField
						className={clsx({ [classes.hourOff]: !props.day.on })}
						value24Hs={
							props.day.on
								? props.day.to.clone().format('HH:mm')
								: marketplaceBusinessHours?.close
						}
						onChange={hour24 => changeToHandler(hour24, timeZone)}
						onFocus={focusHandler}
						error={hasError}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default StaffScheduleDay;
