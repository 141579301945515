import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Period } from '@spike/model';
import clsx from 'clsx';
import { CalendarFilter, ClientSelector } from 'components/UI';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import TransactionsExportCSV from './TransactionsExportCSV';

interface TransactionsFiltersProps {
    period: Period;
    selectedCategoryId: string;
    clientId: number | null;
    optionSelected: string | undefined;
    onChangePeriod: (period: Period, optionSelected: string | undefined) => void;
    onChangeClient: (clientId: number | null) => void;
    className?: string;
    totalElements: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 10,
            display: 'flex',
            backgroundColor: '#F1F1F1',
            justifyContent: 'space-between',

            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(14)}px ${reduceResolution(22)}px`,
                borderRadius: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '14px 22px',
                borderRadius: '12px'
            }
        },
        calendarFilter: {
            [theme.breakpoints.down('sm')]: {
                '& > button': {
                    height: 47,
                    borderWidth: 2,
                    backgroundColor: '#fff'
                }
            }
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 14,
            [theme.breakpoints.down('sm')]: {
                gap: 8
            }
        }
    })
);

export const TransactionsFilters: FunctionComponent<TransactionsFiltersProps> = props => {
    const classes = useStyles();

    const handleChangePeriod = (periodSelected: Period, allTime: boolean, opSelect?: string) => {
        props.onChangePeriod({ ...periodSelected }, opSelect);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <ClientSelector onSelectClient={props.onChangeClient} />
            <Box className={classes.buttonsContainer}>
                <CalendarFilter
                    hideAllTime
                    disableFuture
                    hideUpcoming
                    showLabelOnMobile
                    period={props.period}
                    optionSelected={props.optionSelected}
                    className={classes.calendarFilter}
                    onChange={handleChangePeriod}
                />
                {props.totalElements > 0 && (
                    <TransactionsExportCSV
                        clientId={props.clientId}
                        period={props.period}
                        selectedCategory={props.selectedCategoryId}
                    />
                )}
            </Box>
        </Box>
    );
};

export default TransactionsFilters;
