import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "store";
import isEqual from "lodash/isEqual";
import clsx from "clsx";
import { MarketplaceStaffTraining } from "@spike/marketplace-model";
import { Button } from "components/UI";
import { SelectableOption } from "model";
import { useMasterData } from "hooks";
import { AddOther } from "../UI/AddOther";
import { OptionType } from "../PetTypes/UI/OptionType";
import { useNonInitialEffect } from "@versiondos/hooks";
import { MarketplaceStatus } from "@spike/marketplace-action";
import { BusinessSettingsTitle } from "../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  staffTraining: MarketplaceStaffTraining;
  onSave?: (staffTraining: MarketplaceStaffTraining) => void;
  className?: string;
  openSidebar?: () => void;
  isMobile?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "171px",
      },
      [theme.breakpoints.only("md")]: {
        width: "276px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "368px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "558px",
      },
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    businessSettingsTittle: {
      display: "flex",
      alignItems: "center",
      "& $iconBusinessTitle": {
        fontSize: "14px"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginBottom: "5px",
      }
    },
    iconBusinessTitle: {
      position: "relative",
      marginRight: "8px"
    },
    subtitle: {
      fontWeight: "normal",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "6px",
        lineHeight: "14px",
        marginBottom: "12px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "9px",
        lineHeight: "21px",
        marginBottom: "19px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "12px",
        lineHeight: "28px",
        marginBottom: "25px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "42px",
        marginBottom: "38px"
      },
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "32px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "42px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "63px",
      },
    },
    trainning: {
      "& .MuiCheckbox-root": {
        paddingLeft: "0px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
          paddingRight: "4px !important",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
          paddingRight: "6px !important",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
          paddingRight: "8px !important",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "20px",
          paddingRight: "12px !important",
        },
      },
      "& .MuiTypography-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "24px",
      },
    },
    addOtherContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "92px",
      },
      [theme.breakpoints.only("md")]: {
        width: "149px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "198px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "300px",
      },
    },
  })
);

export const StaffTraining: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const masterData = useMasterData();


  const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
  const [saved, setSaved] = useState(true);
  const [loading, setLoading] = useState(false);
  const [staffTraining, setStaffTraining] = useState<Array<SelectableOption<string>>>(masterData.staff.training.map(training => ({
    element: { ...training }, selected: props.staffTraining.trainings.some(st => st.id === training.id)
  })));
  const [staffOtherTraining, setStaffOtherTraining] = useState<Array<string>>(props.staffTraining.otherTrainings);

  useNonInitialEffect(() => {
    const newStaffTraining: MarketplaceStaffTraining = {
      trainings: staffTraining.filter(t => t.selected).map(t => t.element),
      otherTrainings: [...staffOtherTraining]
    };

    setSaved(isEqual(props.staffTraining, newStaffTraining));
  }, [staffTraining, staffOtherTraining]);

  useNonInitialEffect(() => {
    if (status === MarketplaceStatus.SaveSuccess) {
      setSaved(true);
      setLoading(false);
    }
  }, [status]);

  const handleCheckTraining = (staffT: SelectableOption<string>, checked: boolean) => {
    let staffTrainingAux = [...staffTraining];
    staffTrainingAux.forEach((t) => {
      if (t.element.id === staffT.element.id) {
        t.selected = checked;
      }
    })
    setStaffTraining(staffTrainingAux);
  };

  const handleAddOther = (value: string) => {
    const notExist = staffOtherTraining.indexOf(value.toLowerCase()) === -1;
    if (notExist) {
      const newOthers = [...staffOtherTraining, value];
      setStaffOtherTraining(newOthers);
    } else {
      return null;
    }
  };

  const handleEditOther = (value: string, index: number) => {
    let copyOthers = [...staffOtherTraining];
    copyOthers[index] = value;
    setStaffOtherTraining(copyOthers);
  };

  const handleTypeOtherChange = (
    checked: boolean,
    index: number,
    description: string
  ) => {
    const newOthers = staffOtherTraining.filter((o) => o !== staffOtherTraining[index]);
    setStaffOtherTraining(newOthers);
  };

  const saveHandler = () => {
    const staffTrainingAux = ({
      trainings: [...staffTraining.filter(t => t.selected).map(t => t.element)],
      otherTrainings: [...staffOtherTraining]
    });

    props.onSave && props.onSave(staffTrainingAux);
    props.onSave && setLoading(true);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.titleContainer}>
        <BusinessSettingsTitle className={classes.businessSettingsTittle}> {props.isMobile && (
          <FontAwesomeIcon
            className={classes.iconBusinessTitle}
            onClick={props.openSidebar}
            icon={faArrowLeft}
            size="2x"
          />
        )} Staff Training</BusinessSettingsTitle>
        <Typography className={classes.subtitle}>What type of training does your staff have?</Typography>
      </Box>
      <Box className={classes.contentContainer}>
        {staffTraining.map((staffT, index) => {
          return (
            <OptionType
              key={staffT.element.id}
              index={index}
              checked={staffT.selected}
              optionName={staffT.element.name}
              onTypeChange={(checked: boolean) => {
                handleCheckTraining(staffT, checked)
              }}
            />
          );
        }
        )}
        {staffOtherTraining.map((staffT, index) => {
          return (
            <OptionType
              key={staffT}
              index={index}
              checked={true}
              editable
              optionName={staffT} onEditName={(nameEdited) =>
                handleEditOther(nameEdited, index!)
              }
              onTypeChange={(checked, index, des) =>
                handleTypeOtherChange(checked, index!, des!)
              }
            />
          );
        }
        )}
      </Box>
      <Box className={classes.addOtherContainer}>
        <AddOther onAddType={handleAddOther} />
      </Box>
      <Box className={classes.buttonContainer}>
        <Button label={saved ? "Saved!" : "Save"} onClick={saveHandler} loading={loading} />
      </Box>
    </Box>
  );
};

export default StaffTraining;
