import { Action } from "redux";

//Action Types
export const USERS_RESET_ACTION_TYPE = "USERS_RESET";
export const USERS_CHANGE_PASSWORD_START_ACTION_TYPE = "USERS_CHANGE_PASSWORD_START";
export const USERS_CHANGE_PASSWORD_SUCCESS_ACTION_TYPE = "USERS_CHANGE_PASSWORD_SUCCESS";
export const USERS_ERROR_ACTION_TYPE = "USERS_ERROR";

//Actions
interface UsersResetAction extends Action<typeof USERS_RESET_ACTION_TYPE> {}

interface UsersChangePasswordStartAction extends Action<typeof USERS_CHANGE_PASSWORD_START_ACTION_TYPE> {}

interface UsersChangePasswordSuccessAction extends Action<typeof USERS_CHANGE_PASSWORD_SUCCESS_ACTION_TYPE> {}

interface UsersErrorAction extends Action<typeof USERS_ERROR_ACTION_TYPE> {}

export type UsersAction =
  | UsersResetAction
  | UsersChangePasswordStartAction
  | UsersChangePasswordSuccessAction
  | UsersErrorAction;
