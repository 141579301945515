import React, { useEffect, useState } from 'react';
import { SpecialHour } from '@spike/model';
import {
	Button,
	DateField2,
	HourField,
	OverFullWindow,
	Switch,
	TextField2
} from 'components/UI';
import {
	Box,
	Grid,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import moment, { Moment } from 'moment-timezone';

interface SpecialHourFormProps {
	addNew?: boolean;
	specialHour: SpecialHour;
	onClose: () => void;
	onSave: (hour: SpecialHour) => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		body: {
			'width': '100%',
			'padding': '24px 16px',

			// title
			'& h5': {
				'fontSize': 20,
				'fontWeight': 600,
				'marginBottom': 30,

				'& svg': {
					marginRight: 10,
					verticalAlign: 'middle'
				}
			}
		},
		input: {
			'& p': {
				fontSize: 14,
				marginBottom: 2
			},
			'& input': {
				height: 54,
				fontSize: 16,
				paddingTop: 0,
				paddingBottom: 0
			},
			'& fieldset': {
				borderColor: '#D3D3D3'
			}
		},
		footer: {
			'width': '100%',
			'marginTop': 'auto',
			'padding': '16px 24px',

			'& button': {
				'height': 47,
				'width': '100%',
				'borderRadius': 30,

				'& span': {
					fontSize: 16,
					fontWeight: 600
				}
			}
		},
		scheduleCol: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		openToggle: {
			'display': 'flex',
			'alignItems': 'center',

			'& p': {
				fontSize: 16,
				fontWeight: 500,
				marginRight: 9
			}
		},
		schedulePicker: {
			gap: 8,
			display: 'flex',
			alignItems: 'center'
		},
		error: {
			color: '#EA6464'
		}
	})
);

export const SpecialHourForm: React.FunctionComponent<
	SpecialHourFormProps
> = props => {
	const classes = useStyles();

	const [specialHour, setSpecialHour] = useState<SpecialHour>(
		props.specialHour
	);

	const changeNameHandler = (value: string) => {
		setSpecialHour({
			...specialHour,
			name: value
		});
	};

	const changeDateHandler = (date: Moment) => {
		setSpecialHour({
			...specialHour,
			date: date.format('yyyy-MM-DD')
		});
	};

	const changeCloseHandler = () => {
		setSpecialHour({
			...specialHour,
			isClosed: !specialHour.isClosed,
			businessHours: specialHour.isClosed
				? { open: '8:00', close: '17:00' }
				: undefined
		});
	};

	const changeOpenHourHandler = (value: string) => {
		setSpecialHour({
			...specialHour,
			businessHours: {
				...specialHour.businessHours!,
				open: value
			}
		});
	};

	const changeCloseHourHandler = (value: string) => {
		setSpecialHour({
			...specialHour,
			businessHours: {
				...specialHour.businessHours!,
				close: value
			}
		});
	};

	const saveHandler = () => {
		if (!specialHour) return;

		props.onSave(specialHour);
	};

	return (
		<OverFullWindow onClose={props.onClose}>
			<Box className={classes.body}>
				<Typography variant="h5">
					<FontAwesomeIcon
						icon={faArrowLeft}
						onClick={props.onClose}
					/>
					{props.addNew ? 'Add' : 'Edit'} Special Hour
				</Typography>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField2
							label="Description"
							value={specialHour?.name || ''}
							className={classes.input}
							placeholder="Description name"
							onChange={changeNameHandler}
						/>
					</Grid>
					<Grid item xs={12}>
						<DateField2
							label="Date"
							date={moment(specialHour?.date)}
							className={classes.input}
							onChange={changeDateHandler}
						/>
					</Grid>
					<Grid item xs={12} className={classes.scheduleCol}>
						<Box className={classes.openToggle}>
							<Typography noWrap={true}>
								{specialHour?.isClosed ? 'Closed' : 'Open'}
							</Typography>
							<Switch
								id={`business_settings_schedules_switch_special_hours`}
								checked={!specialHour?.isClosed}
								onChange={changeCloseHandler}
							/>
						</Box>

						{!specialHour?.isClosed && (
							<>
								<Box className={classes.schedulePicker}>
									<Box>
										<HourField
											id="business_settings_special_hour_input_open_hour"
											value24Hs={
												specialHour?.businessHours?.open
											}
											onChange={changeOpenHourHandler}
										/>
									</Box>
									<Box>
										<Typography>to</Typography>
									</Box>
									<Box>
										<HourField
											id="business_settings_special_hour_input_close_hour"
											value24Hs={
												specialHour?.businessHours
													?.close
											}
											onChange={changeCloseHourHandler}
										/>
									</Box>
								</Box>
							</>
						)}
					</Grid>
				</Grid>
			</Box>
			<Box className={classes.footer}>
				<Button label="Save" variant="primary" onClick={saveHandler} />
			</Box>
		</OverFullWindow>
	);
};
