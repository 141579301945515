import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import { cleanAllExceptLogin } from 'actions/ActionUtils';
import { changeMarketplace as changeLoginMarketplace } from 'actions/login/LoginActions';
import { changeSessionMarketplace } from 'auth';
import clsx from 'clsx';
import { useAuth, useMarketplace } from 'hooks';
import { Auth, AuthMarketplace } from '@spike/auth-model';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HelpCenterPath, LogoutPath, MyAccountPath } from 'routes/Paths';
import { headerHeight, wbp } from 'Theme';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { closeMenu } from 'actions/menu/MenuActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            backgroundColor: 'white'
        },
        avatar: {
            margin: 'auto',
            cursor: 'pointer',
            border: '1px solid transparent',
            transition: 'border 0.2s ease-out',

            [theme.breakpoints.down(wbp)]: {
                width: 36,
                height: 36
            },
            [theme.breakpoints.up(wbp)]: {
                width: 42,
                height: 42
            }
        },
        border: {
            border: '1px solid black'
        },

        dropdown: {
            right: 0,
            width: 250,
            top: headerHeight,
            display: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            zIndex: 998,
            borderRadius: 16,
            overflow: 'hidden',
            backgroundColor: '#fff',
            border: '1px solid #D4D4D4',

            [theme.breakpoints.up('sm')]: {
                width: 220
            }
        },

        dropdownHeader: {
            width: '100%',
            backgroundColor: '#F7F7F7',
            padding: '20px 15px 15px 16px'
        },
        name: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 2
        },
        businessName: {
            fontSize: 14,
            fontWeight: 400,
            color: '#969696'
        },

        dropdownBody: {},
        dropdownLink: {
            'color': '#000',
            'display': 'flex',
            'alignItems': 'center',
            'fontSize': 14,
            'fontWeight': 500,
            'cursor': 'pointer',
            'padding': '10px 17px',
            'textDecoration': 'none',
            'transition': 'background-color 0.3s ease',

            '&:hover': {
                color: '#fff',
                backgroundColor: '#000'
            },
            '& > svg': {
                marginLeft: 'auto'
            }
        },
        dropdownLocation: {
            paddingLeft: 35
        },

        dropdownFooter: {
            borderTop: 'solid 1px #D4D4D4'
        },

        logout: {
            color: '#000',
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 500,
            display: 'block',
            cursor: 'pointer',
            padding: '16px 17px 18px'
        }
    })
);

export const ProfileDropdown: FunctionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const auth: Auth = useAuth();
    const name: string | undefined = auth.user && auth.user.firstName;

    const marketplace = useMarketplace();
    const businessName = marketplace.basics.businessName;

    const otherMarketplaces = (auth.marketplaces || []).filter(
        mkt => mkt.id !== auth.marketplaceId
    );

    const avatar: string | undefined = auth.user && auth.user.avatar;

    const [showDropdown, setShowDropdown] = useState(false);
    const [showLocations, setShowLocations] = useState(false);

    const history = useHistory();

    const clickAvatarHandler = () => {
        dispatch(closeMenu());
        setShowDropdown(prev => !prev);
    };

    const clickAwayHandler = () => {
        setShowDropdown(false);
    };

    const clickLogoutHandler = () => {
        setShowDropdown(false);
        history.push(LogoutPath);
    };

    const clickLocationsHandler = () => {
        setShowLocations(prev => !prev);
    };

    const clickMarketplaceHandler = (authMarketplace: AuthMarketplace) => {
        cleanAllExceptLogin(dispatch);
        dispatch(changeLoginMarketplace(authMarketplace));
        changeSessionMarketplace(authMarketplace);
        window.location.reload();
    };

    const dropdownHeader = (
        <Box className={classes.dropdownHeader}>
            <Typography className={classes.name}>{name}</Typography>
            <Typography className={classes.businessName}>
                {businessName}
            </Typography>
        </Box>
    );

    const locations = (
        <Fragment>
            <Box
                className={classes.dropdownLink}
                onClick={clickLocationsHandler}
            >
                Locations
                <FontAwesomeIcon
                    className={clsx({
                        active: showLocations
                    })}
                    icon={showLocations ? faAngleUp : faAngleDown}
                />
            </Box>

            {showLocations &&
                otherMarketplaces
                    .sort((m1, m2) => m1.name.localeCompare(m2.name))
                    .map(m => (
                        <Box
                            key={m.id}
                            className={clsx(
                                classes.dropdownLink,
                                classes.dropdownLocation
                            )}
                            onClick={() => clickMarketplaceHandler(m)}
                        >
                            {m.name}
                        </Box>
                    ))}
        </Fragment>
    );

    const dropdownBody = (
        <Box className={classes.dropdownBody}>
            <Link
                to={isMobile ? MyAccountPath + '?open=menu' : MyAccountPath}
                onClick={() => setShowDropdown(false)}
                className={classes.dropdownLink}
            >
                My Account
            </Link>

            <Link
                to={{ pathname: HelpCenterPath }}
                target="_blank"
                onClick={() => setShowDropdown(false)}
                className={classes.dropdownLink}
            >
                Help Center
            </Link>

            {otherMarketplaces.length > 0 && locations}
        </Box>
    );

    const dropdownFooter = (
        <Box className={classes.dropdownFooter}>
            <Typography className={classes.logout} onClick={clickLogoutHandler}>
                Log Out
            </Typography>
        </Box>
    );

    const dropdown = (
        <Box className={classes.dropdown}>
            {dropdownHeader}
            {dropdownBody}
            {dropdownFooter}
        </Box>
    );

    return (
        <ClickAwayListener onClickAway={clickAwayHandler}>
            <Box className={classes.container}>
                <Box onClick={clickAvatarHandler}>
                    <Avatar
                        alt={name}
                        src={avatar}
                        className={clsx(classes.avatar, {
                            [classes.border]: showDropdown
                        })}
                    />
                </Box>
                {showDropdown && dropdown}
            </Box>
        </ClickAwayListener>
    );
};

export default ProfileDropdown;
