import { FieldError } from "@spike/model";
import useNonInitialEffect from "@versiondos/hooks";
import isEmpty from "lodash/isEmpty";
import { PetMedicalHistory } from "@spike/medical-history-model";
import Pet from "@spike/pet-model";
import { default as Vet, default as VetModel } from "@spike/vet-model";
import { FunctionComponent, useState } from "react";
import { EditMedicalHistory, NewPetEditMedicalHistory } from "./Edit";
import EmptyViewMedicalHistory from "./View/EmptyViewMedicalHistory";
import NewPetViewMedicalHistory from "./View/NewPetViewMedicalHistory";
import ViewMedicalHistory from "./View/ViewMedicalHistory";

interface MedicalHistoryProps {
  pet: Pet;
  vet?: Vet;
  newPet?: boolean;
  saving?: boolean;
  errors?: Array<FieldError>;
  edited?: boolean;
  disabled?: boolean;
  onChangeMedicalHistory?: (medicalHistory: PetMedicalHistory) => void;
  onChangeVet?: (vet?: VetModel) => void;
}

const checkMedicalHistory = (pet: Pet): boolean => {
  return (
    pet.medicalHistory !== undefined &&
    (pet.medicalHistory.dentals.length > 0 ||
      pet.medicalHistory.medicalConditions.length > 0 ||
      pet.medicalHistory.dentals.length > 0 ||
      pet.medicalHistory.vaccines.length > 0)
  );
};

const MedicalHistory: FunctionComponent<MedicalHistoryProps> = (props) => {
  const [showEdition, setShowEdition] = useState(props.edited);

  useNonInitialEffect(() => {
    // eslint-disable-next-line
    if (props.saving! && showEdition) {
      setShowEdition(false);
    }
  }, [props.saving]);

  const hasMedicalHistoryOrVet =
    checkMedicalHistory(props.pet) || !isEmpty(props.vet?.name) || !isEmpty(props.vet?.businessName);

  const editHandler = () => {
    setShowEdition(true);
  };

  const savedHandler = (medicalHistory: PetMedicalHistory, vet?: Vet) => {
    !props.edited && setShowEdition(false);
    props.onChangeMedicalHistory && props.onChangeMedicalHistory(medicalHistory);
    props.onChangeVet && props.onChangeVet(vet);
  }

  if (!showEdition && !hasMedicalHistoryOrVet) {
    return <EmptyViewMedicalHistory disabled={props.disabled} onAdd={editHandler} />;
  } else if (!showEdition && props.newPet) {
    return <NewPetViewMedicalHistory pet={props.pet} vet={props.vet} onEdit={editHandler} />;
  } else if (!showEdition && !props.newPet) {
    return <ViewMedicalHistory pet={props.pet} vet={props.vet} onEdit={editHandler} />;
  } else if (showEdition && props.newPet) {
    return (
      <NewPetEditMedicalHistory
        pet={props.pet}
        vet={props.vet}
        errors={props.errors}
        onChangeMedicalHistory={props.onChangeMedicalHistory}
        onChangeVet={props.onChangeVet}
      />
    );
  } else if (showEdition && !props.newPet) {
    return (
      <EditMedicalHistory
      // eslint-disable-next-line
        petId={props.pet.id!}
        petGender={props.pet.gender}
        medicalHistory={props.pet.medicalHistory}
        vet={props.vet}
        onSaved={savedHandler}
        onClose={props.edited ? undefined : () => setShowEdition(false)}
      />
    );
  } else {
    return null;
  }
};

export default MedicalHistory;
