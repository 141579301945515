import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, ClickAwayListener, Input, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Option } from '@spike/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faSearch,
    IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface Props {
    label: string;
    selected?: Option<number>;
    options: Array<Option<number>>;
    onChange: (option: Option<number>) => void;
    onAdd: () => void;
    className?: string;
    disabled?: boolean;
    error?: boolean;
    default?: boolean;
    type: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        boxSearchOpen: {
            'border': '1px solid #ccc',
            'borderRadius': '20px',
            'color': '#999',
            'cursor': 'default',
            'display': 'flex',
            'justifyContent': 'space-between',
            'width': '100%',
            [theme.breakpoints.down('md')]: {
                padding: '2px 15px',
                fontSize: '14px',
                height: '37px'
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: '50px',
                fontSize: '1rem',
                padding: '12px 12px 3px 30px',
                height: '47px'
            },
            '&:hover': {
                border: '1px solid #222'
            }
        },
        boxSearchClose: {
            'border': '1px solid #ccc',
            'borderRadius': '20px',
            'color': '#999',
            'cursor': 'default',
            'display': 'flex',
            'fontSize': '0.8rem',
            'justifyContent': 'space-between',
            'width': '100%',
            [theme.breakpoints.down('md')]: {
                padding: '2px 15px',
                fontSize: '14px',
                height: '37px'
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: '50px',
                fontSize: '1rem',
                padding: '12px 12px 3px 30px',
                height: '47px'
            },
            '&:hover': {
                border: '1px solid #222'
            }
        },
        boxResultContainer: {
            width: '100%',
            position: 'relative',
            zIndex: 999999
        },
        boxResult: {
            borderLeft: '1px solid #222',
            borderRight: '1px solid #222',
            background: '#fff',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                height: '176px'
            },
            [theme.breakpoints.up('lg')]: {
                height: '220px'
            }
        },
        boxResultOpen: {
            border: '1px solid #222',
            borderRadius: '20px 20px 0 0'
        },
        boxResultItem: {
            'color': '#222',
            'margin': '2px 0',
            'padding': '5px 10px',
            '&:hover': {
                background: '#eee',
                cursor: 'pointer'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        },
        boxValueService: {
            width: '90%',
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('lg')]: {
                width: '90%'
            }
        },
        selected: {
            border: '1px solid #222',
            color: '#222'
        },
        sectionIcon: {
            marginRight: '0.5rem'
        },
        sectionIconSearch: {
            marginRight: '0rem'
        },
        addNewContainer: {
            'display': 'flex',
            'cursor': 'pointer',
            'justifyContent': 'center',
            'background': '#000',
            'borderRadius': '0 0 20px 20px',
            '&:hover': {
                background: '#92B4A7',
                borderColor: '#92B4A7'
            },
            [theme.breakpoints.down('md')]: {
                padding: '16px'
            },
            [theme.breakpoints.up('lg')]: {
                padding: '20px'
            }
        },
        addNew: {
            color: '#fff',
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        },
        icon: {
            [theme.breakpoints.down('md')]: {
                marginRight: '4px'
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: '6px'
            }
        },
        text: {
            [theme.breakpoints.down('md')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        },
        defaultBox: {
            display: 'flex'
        },
        default: {
            fontWeight: 500,
            color: '#5E8677',
            [theme.breakpoints.down('md')]: {
                fontSize: '11px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '14px'
            }
        },
        iconDefault: {
            color: '#5E8677',
            [theme.breakpoints.down('md')]: {
                marginRight: '3px',
                fontSize: '11px',
                marginTop: '2px'
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: '5px',
                fontSize: '14px',
                marginTop: '3px'
            }
        },
        sectionChevronOpen: {
            marginRight: '0rem',
            [theme.breakpoints.down('md')]: {
                marginTop: '3px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '4px'
            }
        },
        selectContainer: {
            position: 'absolute',
            width: '100%'
        },
        disabled: {
            background: '#F1F1F1',
            pointerEvents: 'none'
        },
        emptyOptions: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center'
        },
        emptyText: {
            color: '#7A7A7A',
            [theme.breakpoints.down('md')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '18px'
            }
        }
    })
);

export const EditableSelect: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const iconSVG = (icon: IconDefinition, styles: string) => {
        return <FontAwesomeIcon icon={icon} className={styles} />;
    };

    const [showIconSearch, setShowIconSearch] = useState(true);
    const [showBoxResult, setShowBoxResult] = useState(false);
    const [selected, setSelected] = useState<boolean | undefined>(
        props.selected ? true : false
    );
    const [optionSelected, setOptionelected] = useState<
        Option<number> | undefined
    >(props.selected);
    const [options, setOptions] = useState<Array<Option<number>>>(
        props.options
    );

    useEffect(() => {
        if (props.selected) {
            setOptionelected(props.selected);
            setSelected(props.selected ? true : false);
        }
    }, [props.selected]);

    const selectionHandler = (id: number) => {
        const selection = props.options.filter(p => p.id === id);
        setOptionelected(selection[0]);
        setSelected(true);
        setShowBoxResult(false);
        props.onChange(selection[0]);
    };

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setShowIconSearch(event.target.value.length === 0 ? true : false);
        const optionsListAux = props.options.filter(item => {
            const name = `${item.name.toLowerCase()}`;
            return (
                event.target.value.length === 0 ||
                name.indexOf(event.target.value.toLowerCase()) > -1
            );
        });

        setOptions(optionsListAux);
    };

    const handleAdd = () => {
        props.onAdd();
        setShowBoxResult(false);
    };

    useEffect(() => {
        setOptions(props.options);
        setShowIconSearch(true);
    }, [showBoxResult]);

    return (
        <ClickAwayListener onClickAway={() => setShowBoxResult(false)}>
            <Box className={clsx(classes.container, props.className)}>
                <Box
                    className={clsx(
                        { [classes.boxSearchClose]: !showBoxResult },
                        { [classes.boxSearchOpen]: showBoxResult },
                        { [classes.boxResultOpen]: showBoxResult },
                        { [classes.selected]: selected },
                        { [classes.disabled]: props.disabled }
                    )}
                    onClick={() => setShowBoxResult(true)}
                >
                    <div className={classes.boxValueService}>
                        <Box
                            component="div"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {!showBoxResult ? (
                                optionSelected ? (
                                    <Box>{optionSelected.name}</Box>
                                ) : (
                                    <Box>{`Select ${props.label}`}</Box>
                                )
                            ) : (
                                <Input
                                    className={classes.text}
                                    placeholder={
                                        showIconSearch ? 'Search item' : ''
                                    }
                                    startAdornment={
                                        showIconSearch ? (
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className={classes.icon}
                                            />
                                        ) : undefined
                                    }
                                    disableUnderline={true}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </Box>
                    </div>
                    <Box>
                        {!showBoxResult && props.default ? (
                            <Box className={classes.defaultBox}>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#5E8677"
                                    className={classes.iconDefault}
                                />
                                <Typography className={classes.default}>
                                    Default
                                </Typography>
                            </Box>
                        ) : (
                            iconSVG(
                                faChevronDown,
                                `${
                                    showBoxResult
                                        ? classes.sectionChevronOpen
                                        : classes.sectionIconSearch
                                }`
                            )
                        )}
                    </Box>
                </Box>
                {showBoxResult && (
                    <Box className={classes.boxResultContainer}>
                        <Box className={classes.selectContainer}>
                            <Box className={classes.boxResult}>
                                {props.options.length > 0 ? (
                                    <OverlayScrollbarsComponent
                                        style={{ height: '100%' }}
                                    >
                                        {options.map(option => (
                                            <Box
                                                key={option.id}
                                                className={
                                                    classes.boxResultItem
                                                }
                                                onClick={() =>
                                                    selectionHandler(option.id)
                                                }
                                            >
                                                {option.name}
                                            </Box>
                                        ))}
                                    </OverlayScrollbarsComponent>
                                ) : (
                                    <Box className={classes.emptyOptions}>
                                        <Typography
                                            className={classes.emptyText}
                                        >
                                            No {props.type} added yet.
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box
                                className={classes.addNewContainer}
                                onClick={handleAdd}
                            >
                                <Typography className={classes.addNew}>
                                    + Add New
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    );
};

export default EditableSelect;
