import React, { FunctionComponent, Fragment } from "react";
import { formatDateTime } from "utils/DateUtils";
import { useTimeZone } from "hooks";
import { Moment } from "moment-timezone";
export interface Props {
  date: Moment;
}

export const DateTimeFormat: FunctionComponent<Props> = (props) => {
  const timeZone = useTimeZone();
  const date = formatDateTime(props.date, timeZone);
  
  return (<Fragment>{date}</Fragment>);
};

export default DateTimeFormat;
