import { Option } from "@spike/model";

export interface BusinessAreas {
  grooming: boolean;
  daycare: boolean;
  boarding: boolean;
}

export interface UuidOption<T> {
  id: T;
  uuid: string;
  name: string;
  description?: string;
}

export interface Selectable<T> {
  element: T;
  selected: boolean;
}

export type SelectableOption<T> = Selectable<Option<T>>;


export enum Status {
  ACTIVE = 1,
  DRAFT = 2,
  INACTIVE = 3,
  REVIEW = 4,
}

export type WeekDay = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";