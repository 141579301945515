import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BusinessHours, FieldError, Week } from '@spike/model';
import { fetchOnboardingStepsThunk } from 'actions/onboardingSteps/OnboardingStepsActions';
import { saveDefaultScheduleStaffThunk } from 'actions/staff/StaffActions';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { WeekDay } from 'model';
import { Marketplace } from '@spike/marketplace-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StaffStatus } from 'reducers/staff/StaffState';
import { RootState } from 'store';
import { validateWeek } from '../utils/Validations';
import DefaultBusinessHours from './DefaultBusinessHours';
import DefaultScheduleDays from './DefaultScheduleDays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface DefaultScheduleProps {
	week: Week;
	staffId?: number;
	className?: string;
	onClose?: () => void;
	onChange?: (week: Week) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: 'rgba(255,255,255,0)',

			[theme.breakpoints.down('sm')]: {
				height: '100%'
			},
			[theme.breakpoints.up('md')]: {
				width: '674px'
			}
		},
		header: {
			display: 'none',
			backgroundColor: '#E9F0ED',
			alignItems: 'center',

			[theme.breakpoints.up('md')]: {
				height: '46px',
				display: 'flex',
				borderTopLeftRadius: '24px',
				borderTopRightRadius: '24px',
				paddingLeft: '40px',
				paddingRight: '40px'
			}
		},
		mobileHeader: {
			'fontSize': 20,
			'padding': '15px 16px 10px',
			'textAlign': 'right',

			[theme.breakpoints.up('md')]: {
				display: 'none'
			},

			'& button': {
				all: 'unset'
			}
		},
		headerTitle: {
			fontWeight: 600,
			color: '#5E8677',
			[theme.breakpoints.down('sm')]: {
				fontSize: '8px',
				lineHeight: '14px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '10px',
				lineHeight: '18px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '13px',
				lineHeight: '22px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '16px',
				lineHeight: '24px'
			}
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: 'white',
			padding: '0px 16px',

			[theme.breakpoints.up('md')]: {
				paddingTop: '25px',
				paddingLeft: '40px',
				paddingRight: '40px'
			}
		},
		footer: {
			'width': '100%',
			'display': 'flex',
			'marginTop': 'auto',
			'justifyContent': 'flex-end',
			'backgroundColor': '#fff',
			'padding': '0px 23px 20px',

			[theme.breakpoints.up('md')]: {
				borderBottomLeftRadius: '24px',
				borderBottomRightRadius: '24px',
				paddingTop: '34px',
				paddingBottom: '34px',
				paddingLeft: '40px',
				paddingRight: '40px'
			},

			'& button': {
				[theme.breakpoints.down('sm')]: {
					height: 55,
					width: '100%',
					borderRadius: 30
				}
			}
		},
		titleContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		title: {
			fontSize: 20,
			fontWeight: 600,
			color: '#222222',
			marginBottom: 10,

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		description: {
			fontSize: 14,
			fontWeight: 400,
			marginBottom: 24,
			color: '#000',

			[theme.breakpoints.up('md')]: {
				fontSize: '15px',
				color: '#7A7A7A'
			}
		},
		daysContainer: {
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				paddingTop: '7px'
			},
			[theme.breakpoints.only('md')]: {
				paddingTop: '12px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingTop: '16px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingTop: '24px'
			}
		},
		businessHoursContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			marginBottom: 20
		}
	})
);

export const DefaultSchedule: FunctionComponent<
	DefaultScheduleProps
> = props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const marketplace = useSelector<RootState, Marketplace>(
		state => state.marketplace.marketplace
	);
	const status = useSelector<RootState, StaffStatus>(
		state => state.staff.status
	);

	const [week, setWeek] = useState(props.week);
	const [loading, setLoading] = useState(false);

	const [errors, setErrors] = useState<Array<FieldError>>([]);

	useEffect(() => {
		if (status === StaffStatus.SavingDefaultSchedule) {
			setLoading(true);
		} else if (status === StaffStatus.SaveDefaultScheduleSuccess) {
			setLoading(false);
			props.onClose && props.onClose();
			props.onChange && props.onChange(week);
			dispatch(fetchOnboardingStepsThunk());
		}
	}, [status]);

	const changeBusinessHoursHandler = (
		day: WeekDay,
		businessHours: BusinessHours
	) => {
		setWeek(prevWeek => {
			const newWeek = { ...prevWeek };
			newWeek[day as keyof Week] = { ...businessHours };
			return newWeek;
		});
	};

	const saveHandler = () => {
		//const errors = [...validateWeek(week), ...validateWeekByMarketplaceSchedule(week,marketplace.schedule)];
		const errors = validateWeek(week);

		setErrors(errors);

		if (errors.length === 0) {
			if (props.staffId) {
				setLoading(true);
				dispatch(saveDefaultScheduleStaffThunk(week, props.staffId));
			} else {
				props.onChange && props.onChange(week);
				props.onClose && props.onClose();
			}
		}
	};

	return (
		<ClickAwayListener
			onClickAway={() => {
				props.onClose && props.onClose();
			}}
		>
			<Box className={clsx(classes.container, props.className)}>
				<Box className={classes.header}>
					<Typography className={classes.headerTitle}>
						Schedule
					</Typography>
				</Box>
				<Box className={classes.mobileHeader}>
					<button
						type="button"
						aria-label="Close default schedule"
						onClick={() => props.onClose && props.onClose()}
					>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</Box>
				<Box className={classes.body}>
					<Box className={classes.titleContainer}>
						<Typography className={classes.title}>
							Default Schedule
						</Typography>
						<Typography className={classes.description}>
							This schedule will automatically repeat itself every
							week unless specific changes are made on the weekly
							scheduler view.
						</Typography>
					</Box>
					<Box className={classes.daysContainer}>
						<DefaultScheduleDays
							id="staff_add_member_schedule_default"
							week={week}
							onChange={setWeek}
						/>
					</Box>
					<Box className={classes.businessHoursContainer}>
						{[
							'monday',
							'tuesday',
							'wednesday',
							'thursday',
							'friday',
							'saturday',
							'sunday'
						].map(day => (
							<DefaultBusinessHours
								key={day}
								day={day as WeekDay}
								businessHours={week[day as keyof Week]}
								marketplaceSchedule={marketplace.schedule.week}
								errors={errors}
								onChange={changeBusinessHoursHandler}
							/>
						))}
					</Box>
				</Box>
				<Box className={classes.footer}>
					<Button
						id="staff_add_member_default_schedule_button_save"
						label="Save as Default"
						onClick={saveHandler}
						loading={loading}
					/>
				</Box>
			</Box>
		</ClickAwayListener>
	);
};

export default DefaultSchedule;
