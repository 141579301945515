import { Box, Grid, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { reduceResolution, wbp } from "Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TerminalOrder,
  TerminalModel,
} from "@spike/terminal-model";

import {
  IconDefinition,
  faCartCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { Button } from "components/UI";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { StepsHeaderOrder } from "@spike/masterdata-model";

interface OrderCheckOutProps {
  order: TerminalOrder;
  modelsTerminal: Array<TerminalModel>;
  optionsSteps: Array<StepsHeaderOrder>;
  className?: string;
  loading: boolean;
  finishDisabled: boolean;
  //
  onNext: () => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxCheckOut: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(14)}px ${reduceResolution(0)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "14px 0",
      },
      display: "flex",
      flexDirection: "column",
      height: "95%",
    },

    boxContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },

    boxTitleCheckOut: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(14)}px`,
        paddingLeft: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "14px",
        paddingLeft: `${reduceResolution(35)}px`,
      },
    },

    gridHeaderCheckOut: {
      background: "#F1F1F1",
      borderTop: "1px solid #D4D4D4",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(14)}px ${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "14px 35px",
      },
    },

    gridPaddingLeft: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "35px",
      },
    },

    gridPaddingRight: {
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "35px",
      },
    },

    boxPOS: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(14)}px ${reduceResolution(0)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "14px 0px",
      },
    },

    boxBorder: {
      borderBottom: "1px solid #d4d4d4",
    },

    boxEmptyPOS: {
      color: "#7A7A7A",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(14)}px ${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "14px 35px",
      },
    },

    iconEmptyPos: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "10px",
      },
    },

    gridCenter: {
      textAlign: "center",
    },

    typoTotal: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(5)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "5px 0",
      },
    },

    gridPadding: {
      [theme.breakpoints.down(wbp)]: {
        padding: `0px ${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0px 35px",
      },
    },

    btnPay: {
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(55)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "55px",
      },
    },

    textRight: {
      textAlign: "right",
    },

    gridDetail: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    gridDetailTerminal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    }
  })
);

export const OrderCheckOut: FunctionComponent<OrderCheckOutProps> = (props) => {
  const classes = useStyles();
  const terminalCommonClasses = useTerminalCommonStyles();

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const totalSteps = props.optionsSteps.length;

  const checkEmpty = () => {
    let empty = true;
    let qty = props.order.terminals.reduce((total, model) => total + model.qty, 0);
    (qty > 0) ? empty = false : empty = true;
    return empty
  };

  const getPriceModel = (model: any) => {
    let price = props.modelsTerminal.find((m) => m.id.toUpperCase() === model.id.toUpperCase());
    let value = Number(price!.price) * model.qty;

    return `$ ${value}.00`;
  };

  const nextHandler = () => {
    props.onNext();
  };
  //

  const emptyView = (
    <Box className={classes.boxEmptyPOS}>
      {iconSVG(faCartCircleXmark, `${classes.iconEmptyPos} `)}
      <Typography className={terminalCommonClasses.typo16_400}>Please add a POS Terminal</Typography>
    </Box>
  );

  const subtotalView = (
    <Grid container className={clsx(classes.boxPOS, classes.boxBorder)} key={"subTotal"}>
      <Grid item xs={6} className={classes.gridPaddingLeft}>
        <Typography className={clsx(terminalCommonClasses.typo16_500, classes.typoTotal)}>Subtotal</Typography>
        <Typography className={clsx(terminalCommonClasses.typo16_500, classes.typoTotal)}>Shipping</Typography>
      </Grid>
      <Grid item xs={6} className={classes.gridPaddingRight}>
        <Typography className={clsx(terminalCommonClasses.typo16_400, classes.textRight, classes.typoTotal)}>{`$ ${props.order.price}.00`}</Typography>
        <Typography className={clsx(terminalCommonClasses.typo16_400, classes.textRight, classes.typoTotal)}>Free</Typography>
      </Grid>
    </Grid>
  );

  const totalView = (
    <>
      <Grid container className={clsx(classes.boxPOS)} key={"total"}>
        <Grid item xs={6} className={classes.gridPaddingLeft}>
          <Typography className={terminalCommonClasses.typo20_600}>Order Total</Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridPaddingRight}>
          <Typography className={clsx(terminalCommonClasses.typo20_600, classes.textRight)}>{`$ ${props.order.price}.00`}</Typography>
        </Grid>
      </Grid>

      <Grid container key={"pay"}>
        <Grid item xs={12} className={clsx(classes.gridPadding)}>
          {props.optionsSteps.find((option) => option.order === totalSteps)?.active &&
            <Button
              label={"Finish"}
              color={"green"}
              className={classes.btnPay}
              loading={props.loading}
              disabled={props.finishDisabled}
              //
              onClick={nextHandler} />
          }
          {!props.optionsSteps.find((option) => option.order === totalSteps)?.active &&
            <Button label={"Next"} color={"green"} onClick={nextHandler} className={classes.btnPay} />
          }
        </Grid>
      </Grid>
    </>
  );

  const listView = (
    <Grid container className={classes.gridDetailTerminal}>
      <Grid container key={"list"} className={classes.gridPadding}>
        {props.order.terminals &&
          props.order.terminals
            .filter((model) => model.qty > 0)
            .map((model) => (
              <Grid container className={classes.boxPOS} key={model.name}>
                <Grid item xs={5}><Typography className={terminalCommonClasses.typo16_600}>Cuddles {model.id.toUpperCase()}</Typography></Grid>
                <Grid item xs={1} className={classes.gridCenter}><Typography className={terminalCommonClasses.typo14_400}>{model.qty}</Typography></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}><Typography className={clsx(terminalCommonClasses.typo16_400, classes.textRight)}>{getPriceModel(model)}</Typography></Grid>
              </Grid>)
            )}
      </Grid>
      <Grid container>
        {subtotalView}
        {totalView}
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.boxCheckOut}>
      <Box className={classes.boxTitleCheckOut}>
        <Typography className={terminalCommonClasses.typo28_600}>To checkout</Typography>
      </Box>
      <Box className={classes.boxContainer}>
        <Grid container className={classes.gridHeaderCheckOut}>
          <Grid item xs={5}><Typography className={terminalCommonClasses.typo13_600}>Item</Typography></Grid>
          <Grid item xs={1} className={classes.gridCenter}><Typography className={terminalCommonClasses.typo13_600}>Qty</Typography></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}><Typography className={clsx(terminalCommonClasses.typo13_600, classes.textRight)}>Price</Typography></Grid>
        </Grid>

        <Grid container className={classes.gridDetail}>
          {checkEmpty() && emptyView}
          {!checkEmpty() && listView}
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderCheckOut;
