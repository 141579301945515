import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { useNonInitialEffect } from "@versiondos/hooks";
import { fetchThunk as fetchAddOnServicesThunk } from "@spike/addon-services-action";
import { getBookingThunk, BookingsStatus } from "@spike/bookings-action";
import {
  calculateInvoiceThunk,
  getInvoiceThunk,
  updateInvoiceThunk,
  prepareInvoiceThunk,
  saveInvoiceThunk,
} from "@spike/invoices-action";
import clsx from "clsx";
import { Button, Spinner } from "components/UI";
import uniq from "lodash/uniq";
import AddOnService from "@spike/addon-service-model";
import Booking from "@spike/booking-model";
import InvoiceModel, { InvoiceLine as InvoiceLineModel } from "@spike/invoice-model";
import { showError }from "@spike/notifications-action";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvoicesStatus } from "@spike/invoices-action";
import { RootState } from "store";
import { wbp } from "Theme";
import InvoiceExtras from "./InvoiceExtras";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceLinesGroup from "./InvoiceLinesGroup";
import InvoiceLinesHeader from "./InvoiceLinesHeader";
import InvoiceTotals from "./InvoiceTotals";
import { validate as validateInvoice, validateEmptyExtras } from "@spike/invoice-model";
import { useApiClientWrapper } from "hooks";
import { Product } from "@spike/product-model";
import { fetchProductsThunk } from "@spike/products-action";
import { showErrorThunk } from "@spike/notifications-action";
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from "constants/index";

interface InvoiceProps {
  bookingId: number;
  appointmentIds: Array<number>;
  className?: string;
  onComplete?: (invoice: InvoiceModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: '#FFFFFF',

			[theme.breakpoints.up('md')]: {
				backgroundColor: '#FBFBFB'
			}
		},
		header: {
			marginTop: 32,

			[theme.breakpoints.up('md')]: {
				marginTop: 50
			}
		},
		invoiceLinesMobileWrapper: {
			[theme.breakpoints.down('sm')]: {
				marginTop: 24,
				margin: '0px -16px',
				padding: '32px 16px',
				backgroundColor: '#FAFAFA'
			}
		},
		linesHeader: {
			marginTop: 80,
			marginBottom: 24,

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		products: {
			marginTop: 16,

			[theme.breakpoints.up('md')]: {
				marginTop: 50
			}
		},
		totalsContainer: {
			display: 'flex',
			justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        justifyContent: "flex-end",
      }
		},
		errorsContainer: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down(wbp)]: {
				paddingTop: '16px'
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: '20px'
			}
		},
		error: {
			fontFamily: 'Poppins',
			color: '#C14A4A',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				marginTop: '8px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '18px',
				marginTop: '10px'
			}
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginTop: 16,

			[theme.breakpoints.up('md')]: {
				marginTop: 50
			}
		},
		button: {
      height: 55,
			width: '100%',
			borderRadius: '100px',
      
			[theme.breakpoints.up('md')]: {
				width: '500px',
				height: '72px'
			},

      '& span': {
          [theme.breakpoints.down('sm')]: {
            fontSize: 18
          }
      },
		},
		lineGroup: {
      marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				marginBottom: '20px'
			}
		}
  })
);

export const Invoice: FunctionComponent<InvoiceProps> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const addOnServices = useSelector<RootState, Array<AddOnService>>((state) => state.addOnServices.services);

  const booking = useSelector<RootState, Booking | undefined>((state) => state.bookings.invoiceBooking);
  const bookingsStatus = useSelector<RootState, BookingsStatus>((state) => state.bookings.status);

  const appointments =
    booking?.appointments.filter((appointment) => (props.appointmentIds || []).includes(appointment.id!)) || [];

  const invoiceStatus = useSelector<RootState, InvoicesStatus>((state) => state.invoices.status);
  const invoice = useSelector<RootState, InvoiceModel | undefined>((state) => state.invoices.invoice);

  const [invoiceBuffer, setInvoiceBuffer] = useState<{calculating: boolean, changedInvoice: InvoiceModel | undefined}>({calculating: false, changedInvoice: undefined})

  const [errors, setErrors] = useState<Array<FieldError>>([]);
  const [newLines, setNewLines] = useState<Array<InvoiceLineModel>>([]);

  const [saving, setSaving] = useState(false);
  const [editedUuidLine, setEditedUuidLine] = useState<string  | undefined>();

  const products = useSelector<RootState, Array<Product>>((state) => state.products.list)
    .filter((product) => product)
    .filter((product) => product.businessProduct?.availableForCheckout);

  const petGroups =
    invoice?.lines
      .filter((line) => line.pet && line.service)
      .reduce((map, line) => {
        if (map.has(line.pet!.id)) {
          map.get(line.pet!.id)!.push(line);
        } else {
          map.set(line.pet!.id, [line]);
        }
        return map;
      }, new Map<number, Array<InvoiceLineModel>>()) || new Map<number, Array<InvoiceLineModel>>();

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (addOnServices.length === 0) {
      dispatch(fetchAddOnServicesThunk(apiClientWrapper));
    }
    if (products.length === 0) {
      dispatch(fetchProductsThunk(apiClientWrapper));
    }
    dispatch(getBookingThunk(apiClientWrapper, props.bookingId, "invoice"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNonInitialEffect(() => {
    if (bookingsStatus === BookingsStatus.GetInvoiceBookingSuccess) {

      const invoiceIds = uniq(
        booking!.appointments
          .filter((appointment) => (props.appointmentIds || []).includes(appointment.id!))
          .filter((appointment) => appointment.invoiceId)
          .map((appointment) => appointment.invoiceId)
      );

      if (
        (props.appointmentIds || []).some((id) => !booking?.appointments.some((appointment) => appointment.id === id))
      ) {
        //Not all the appointments belong to the same booking.
        dispatch(showError("Not all the appointments belong to the same booking."));
      } else if (invoiceIds.length > 1) {
        //There are more than one invoice.
        dispatch(
          showError(
            "It is not possible to checkout two invoices at the same time. Appointments belong to different invoices."
          )
        );
      } else if (invoiceIds.length === 0) {
        //Prepares a new appointment because any appointment has an invoice.
        dispatch(prepareInvoiceThunk(apiClientWrapper, booking!, appointments));
      } else if (invoiceIds.length === 1 && (props.appointmentIds || []).length > 0) {
        //Loads the invoice.
        dispatch(getInvoiceThunk(apiClientWrapper, invoiceIds[0]!));
      }
    }
  }, [bookingsStatus]);

  useNonInitialEffect(() => {
    switch (invoiceStatus) {
      case InvoicesStatus.PrepareInvoiceSuccess:
      case InvoicesStatus.UpdateInvoiceSuccess:
        setShowSpinner(false);
        break;
      case InvoicesStatus.GetInvoiceSuccess:
        dispatch(updateInvoiceThunk(apiClientWrapper, invoice!, appointments));
        break;
      case InvoicesStatus.CalculateInvoiceSuccess:
        calculateSuccess();
        break;
      case InvoicesStatus.SaveInvoiceSuccess:
        if (saving) {
          setSaving(false);
          setEditedUuidLine(undefined);
          props.onComplete && props.onComplete({ ...invoice! });
        } else if (invoiceBuffer.calculating) {
          calculateSuccess();
        }
        break;
      case InvoicesStatus.Error:
        setShowSpinner(false);
        setSaving(false);
        setEditedUuidLine(undefined);
        setInvoiceBuffer(prev => ({...prev, calculating: false}));
        break;
    }
  }, [invoiceStatus]);

  useNonInitialEffect(() => {
    if (invoice) {
      const errors = validateInvoice(invoice);
      setErrors(errors);
    } else {
      setErrors([]);
    }
  }, [invoice]);

  const calculateSuccess = () => {
    setEditedUuidLine(undefined);
    const invoiceLineUuids = invoice?.lines.map((line) => line.uuid) || [];
    setNewLines((prev) => prev.filter((newLine) => !invoiceLineUuids!.includes(newLine.uuid)));
    setInvoiceBuffer(prev => ({...prev, calculating: false}));
  };

  const saveInvoiceHandler = () => {
    setSaving(true);
    const errors = [
      ...validateInvoice(invoice!),
      ...validateEmptyExtras(newLines)];

      setErrors(errors);

      uniq(errors.map((error) => error.errorMessage)).forEach((error) => dispatch(showErrorThunk(error)));

      if (errors.length === 0) {
        dispatch(saveInvoiceThunk(apiClientWrapper, invoice!));
    } else {
      setSaving(false);
    }
  };

  const changeLineHandler = (updatedLine: InvoiceLineModel) => {
    setInvoiceBuffer(prev => ({...prev, calculating: true}));
    const updatedInvoice = {
      ...invoice!,
      lines: invoice!.lines.map((line) => (line.uuid === updatedLine.uuid ? { ...updatedLine } : { ...line })),
    };

    //if (invoice?.id) {
      dispatch(saveInvoiceThunk(apiClientWrapper, updatedInvoice));
    //} else {
      //dispatch(calculateInvoiceThunk(apiClientWrapper, updatedInvoice));
    //}
  };

  const addExtraHandler = (extraLine: InvoiceLineModel) => {
    setNewLines((prev) => [...prev, extraLine]);
    if(extraLine.isProduct) {
      amplitude.track(AMPLITUDE.CTA_ADD_PRODUCT_CHECK_OUT);
    }
    if (extraLine.isAddOnService) {
      amplitude.track(AMPLITUDE.CTA_ADD_ADDON_SERVICE_CHECK_OUT);
    }
  };

  const removeExtraHandler = (removedLine: InvoiceLineModel) => {
    setNewLines((prev) => prev.filter((line) => line.uuid !== removedLine.uuid));
    setErrors([
      ...validateInvoice(invoice!),
      ...validateEmptyExtras(newLines.filter((line) => line.uuid !== removedLine.uuid)),
    ]);
    if (invoice?.lines.some((line) => line.uuid === removedLine.uuid)) {
      setInvoiceBuffer(prev => ({...prev, calculating: true}));
      const updatedInvoice = { ...invoice!, lines: invoice!.lines.filter((line) => line.uuid !== removedLine.uuid) };

      if (invoice?.id) {
        dispatch(saveInvoiceThunk(apiClientWrapper, updatedInvoice));
      } else {
        dispatch(calculateInvoiceThunk(apiClientWrapper, updatedInvoice));
      }
    }

    if (removedLine.isProduct) {
      amplitude.track(AMPLITUDE.CTA_REMOVE_PRODUCT_CHECK_OUT);
    }
    if (removedLine.isAddOnService) {
      amplitude.track(AMPLITUDE.CTA_REMOVE_ADDON_SERVICE_CHECK_OUT);
    }

  };

  const changeExtraHandler = (updatedExtraLine: InvoiceLineModel) => {
    if (updatedExtraLine.productId || updatedExtraLine.addOnServiceId) {
      setInvoiceBuffer(prev => ({...prev, calculating: true}));

      let updatedInvoice: null | InvoiceModel = null;

      if (invoice?.lines.some((line) => line.uuid === updatedExtraLine.uuid)) {
        updatedInvoice = {
          ...invoice!,
          lines: invoice!.lines.map((line) =>
            line.uuid === updatedExtraLine.uuid ? { ...updatedExtraLine } : { ...line }
          ),
        };
      } else {
        updatedInvoice = { ...invoice!, lines: [...invoice!.lines, { ...updatedExtraLine }] };
      }

      if (invoice?.id) {
        dispatch(saveInvoiceThunk(apiClientWrapper, updatedInvoice));
      } else {
        dispatch(calculateInvoiceThunk(apiClientWrapper, updatedInvoice));
      }
    } else {
      setNewLines((prev) => prev.map((line) => (line.uuid === updatedExtraLine.uuid ? { ...updatedExtraLine } : line)));
    }
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      {showSpinner && <Spinner />}
      {!showSpinner && invoice && <InvoiceHeader invoice={invoice} className={classes.header} />}

      <Box className={classes.invoiceLinesMobileWrapper}>
        {!showSpinner && invoice && <InvoiceLinesHeader className={classes.linesHeader} />}
        {!showSpinner &&
          petGroups &&
          Array.from(petGroups.entries()).map((entry) => (
            <InvoiceLinesGroup
              key={entry[0]}
              lines={entry[1]}
              editedUuidLine={editedUuidLine}
              saving={invoiceBuffer.calculating}
              errors={errors}
              className={classes.lineGroup}
              onSave={changeLineHandler}
              onEdit={setEditedUuidLine}
              onCancelEdit={()=>setEditedUuidLine(undefined)}
            />
          ))}
        {!showSpinner && invoice && products.length > 0 && (
          <InvoiceExtras
            lines={[...invoice.lines, ...newLines]}
            editedUuidLine={editedUuidLine}
            saving={invoiceBuffer.calculating}
            errors={errors}
            className={classes.products}
            onAdd={addExtraHandler}
            onRemove={removeExtraHandler}
            onSave={changeExtraHandler}
            onEdit={setEditedUuidLine}
            onCancelEdit={()=>setEditedUuidLine(undefined)}
            type="product"
          />
        )}
        {!showSpinner && invoice && addOnServices.length > 0 && (
          <InvoiceExtras
            lines={[...invoice.lines, ...newLines]}
            editedUuidLine={editedUuidLine}
            saving={invoiceBuffer.calculating}
            errors={errors}
            className={classes.products}
            onAdd={addExtraHandler}
            onRemove={removeExtraHandler}
            onSave={changeExtraHandler}
            onEdit={setEditedUuidLine}
            onCancelEdit={()=>setEditedUuidLine(undefined)}
            type="addOnService"
          />
        )}
      </Box>

      {!showSpinner && invoice && (
        <Box className={classes.totalsContainer}>
          <InvoiceTotals invoice={invoice} calculating={invoiceBuffer.calculating} />
        </Box>
      )}
      {!showSpinner && invoice && (
        <Box className={classes.buttonContainer}>
          <Button
            id="booking_invoice_button_pay"
            className={classes.button}
            label="Pay"
            loading={saving}
            disabled={errors.length > 0 || editedUuidLine !== undefined}
            onClick={saveInvoiceHandler}
          />
        </Box>
      )}
    </Box>
  );
};

export default Invoice;
