import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom'
import React, { useState } from 'react';
import PrimaryButton from "rocket/components/Buttons/PrimaryButton";

interface NotMobileYetProps {
    customContainerClass?: string;
    showButton?: boolean; // Prop to control the visibility of the button
    onButtonClick?: () => void; // Event handler prop for the button click
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "#FFFFFF",
            [theme.breakpoints.up("sm")]: {
                display: "none"
            },
            zIndex: 9999,
        },
        wrapperContainer:{
            position: "relative",
            width: "100%",
            height: "100%",
        },
        wrapperTop:{
            backgroundColor: "#F8F5F1",
            position: "absolute",
            height: "35%",
            left: 0,
            top: 0,
            width: "100%",
        },
        wrapperBottom: {
            backgroundColor: "#FFFFFF",
            position: "relative",
            width: "100%",
            top: "35%",
            paddingTop: "35px",
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        imageNotMobileYet: {
            display: "inline-block",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            position: "absolute",
            bottom: "90%",
            width: "300px",
            maxWidth: "65%",
        },
        info:{
            textAlign: "center",
        },
        title:{
            fontFamily: 'Poppins',
            fontSize: "28px",
            fontWeight: 600,
            marginLeft: "auto",
            marginRight: "auto",
            width: "200px",
            marginBottom: "20px",
        },
        subtitle:{
            fontSize: "18px",
            fontWeight: 400,
            width: "285px",
            marginLeft: "auto",
            marginRight: "auto",
        },
        buttonContainer: {
            textAlign: "center",
            width: "100%",
        },
        button: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: 35,
            paddingRight: 35,
            margin: '0px auto',
            display: 'inline-block'
        }
    })
);

const NotMobileYet: React.FC<NotMobileYetProps> = ({
    customContainerClass,
    showButton = true, // Default value is true
    onButtonClick
}) => {
    const classes = useStyles();

    return (
        <Box className={`${classes.container} ${customContainerClass}`}>
            <Box className={classes.wrapperContainer}>
                <Box className={classes.wrapperTop}>
                </Box>
                <Box className={classes.wrapperBottom}>
                    <img
                        src="/images/common/img-nomobileyet.svg"
                        alt=""
                        className={classes.imageNotMobileYet}
                    />
                    <div className={classes.info}>
                        <h1 className={classes.title}>We are not mobile, yet.</h1>
                        <p className={classes.subtitle}>This feature is best enjoyed on a desktop computer. Switch for the full experience!</p>
                    </div>

                    {showButton && (
                        <div className={classes.buttonContainer}>
                            <Link to="/bookings">
                                <PrimaryButton
                                    label='Go to Bookings'
                                    className={classes.button}
                                    onClick={onButtonClick}
                                />
                            </Link>
                        </div>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default NotMobileYet;
