import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import ChooseModalHeader from './Header';
import ChooseModalFooter from './Footer';
import ChooseModalClientResults from './ClientResults';
import Client from '@spike/client-model';
import clsx from 'clsx';
import { uniqBy } from 'lodash';

interface Props {
    title: string;

    show: boolean;

    loading: boolean;

    total: number;
    clients: Client[];

    searchResults: Client[];
    showSearchResults: boolean;

    page?: string | number;

    excluded: number[];
    selected: number[] | 'all';

    onClose: () => void;
    onSearch: (s: string) => void;
    onNextPage: (page: number) => void;
    onSave: (s: number[] | 'all', e: number[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            '&.hidden': {
                display: 'none'
            }
        },
        root: {
            top: 0,
            right: 0,
            bottom: 0,
            width: 590,
            zIndex: 110,
            display: 'flex',
            flexFlow: 'column',
            position: 'fixed',
            backgroundColor: '#fff',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        backdrop: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        results: {
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            backgroundColor: '#fff'
        }
    })
);

const ChooseModalClients: FunctionComponent<Props> = ({
    title,
    page,
    show,
    total,
    clients,
    searchResults,
    showSearchResults,
    selected,
    excluded,
    loading,
    onNextPage,
    onClose,
    onSearch,
    onSave
}) => {
    const classes = useStyles();

    /**
     * Local state
     */
    const [excludedIds, setExcludedIds] = useState<number[]>([]);
    const [selectedIds, setSelectedIds] = useState<number[] | 'all'>('all');

    const [cachedResults, setCachedResults] = useState<Client[]>([]);

    /**
     * Events
     */
    const onSelectionChange = (s: 'all' | number[], e: number[]) => {
        setSelectedIds(s);
        setExcludedIds(e);
    };

    const onSaveSelection = () => onSave(selectedIds, excludedIds);

    /**
     * Effects
     */
    useEffect(() => {
        if (!show) return;

        setSelectedIds(selected);
        setExcludedIds(excluded);
    }, [show]);

    useEffect(() => {
        if (searchResults.length === 0) return;

        setCachedResults(
            uniqBy(cachedResults.concat(searchResults), (c: Client) => c.id)
        );
    }, [searchResults]);

    return (
        <Fragment>
            <Box className={clsx(classes.wrapper, { hidden: !show })}>
                <Box className={classes.backdrop} />
                <Box className={classes.root}>
                    <ChooseModalHeader
                        title={title}
                        variant="clients"
                        onClose={onClose}
                        onSearch={onSearch}
                    />
                    <Box className={classes.results}>
                        {show && (
                            <ChooseModalClientResults
                                page={page}
                                total={total}
                                loading={loading}
                                excluded={excludedIds}
                                selected={selectedIds}
                                clients={
                                    showSearchResults ? searchResults : clients
                                }
                                cached={cachedResults}
                                onNextPage={onNextPage}
                                onChange={onSelectionChange}
                            />
                        )}
                    </Box>

                    <ChooseModalFooter
                        onCancel={onClose}
                        onSave={onSaveSelection}
                    />
                </Box>
            </Box>
        </Fragment>
    );
};

export default ChooseModalClients;
