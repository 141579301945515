import React from 'react';
import { Period } from '@spike/model';
import { createStyles, makeStyles } from '@material-ui/core';
import { Moment } from 'moment-timezone';

export interface AddSlotDatePickerProps {
    period: Period;
    selectedDate?: Moment;
    onDateChange: (date: Moment) => void;
}

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            'display': 'flex',
            'paddingTop': 20,
            'paddingLeft': 16,
            'paddingRight': 16,
            'margin': '0px -16px',
            'overflowX': 'auto',
            'overflowY': 'hidden',
            'justifyContent': 'space-between',
            'borderBottom': 'solid 1px #D4D4D4',

            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },

            [theme.breakpoints.up('md')]: {
                margin: 0,
                width: '100%',
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        dateButton: {
            'border': 0,
            'fontSize': 16,
            'fontWeight': 500,
            'whiteSpace': 'nowrap',
            'fontFamily': 'Poppins',
            'color': '#BCB8AE',
            'appearance': 'none',
            'marginBottom': -1,
            'paddingBottom': 14,
            'background': 'transparent',
            'borderBottom': 'solid 2px transparent',
            'cursor': 'pointer',

            '&[data-active="true"]': {
                color: '#000000',
                borderBottomColor: '#000000'
            }
        }
    })
);

export const AddSlotDatePicker: React.FC<AddSlotDatePickerProps> = props => {
    const classes = useStyles();

    const dateRange = React.useMemo(() => {
        const dates: Array<{
            label: string;
            date: Moment;
        }> = [];
        const current = props.period.from.clone();

        while (current.isSameOrBefore(props.period.to, 'day')) {
            dates.push({
                label: current.format('ddd, DD'),
                date: current.clone()
            });
            current.add(1, 'day');
        }

        return dates;
    }, [props.period]);

    return (
        <div className={classes.root}>
            {dateRange.map((item, i) => (
                <button
                    key={i}
                    type="button"
                    className={classes.dateButton}
                    id={`add-slot-date-picker-${i}`}
                    data-active={props.selectedDate?.isSame(item.date, 'day')}
                    onClick={() => props.onDateChange(item.date.clone())}
                >
                    {item.label}
                </button>
            ))}
        </div>
    );
};
