import { DayCellContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Appointment from '@spike/appointment-model';
import { useMarketplace, useTimeZone } from 'hooks';
import get from 'lodash/get';
import padStart from 'lodash/padStart';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, createRef, useEffect, useMemo } from 'react';
import { debugConsoleLog } from 'utils/GeneralUtils';
import { daysOfWeek } from '../FullCalendar/FullCalendarUtils';
import { useFullCalendarStyles } from '../FullCalendar/StylesFullCalendar';
import MonthDayCellFullCalendar from './MonthDayCellFullCalendar';
import WeekdayHeaderFullCalendar from './WeekdayHeaderFullCalendar';

interface MonthFullCalendarProps {
    month: Moment;
    appointments: Array<Appointment>;
    onBook?: (at: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'width': '100%',
            '& .fc .fc-timegrid-slot-label': {
                border: 'none !important'
            },
            '& .fc-bg-event': {
                opacity: 1
            },
            '& .fc-daygrid-day-top': {
                flexDirection: 'row'
            },
            '& .fc-daygrid-day-number': {
                width: '100%'
            },
            '& .fc-daygrid-day-events': {
                minHeight: '0px !important'
            },
            '& .fc-day-today': {
                backgroundColor: 'white !important'
            }
        },
        dayHeader: {
            backgroundColor: 'white',
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderBottom: '1px solid #D8D8D8 !important',
            verticalAlign: 'middle !important',
            [theme.breakpoints.down('sm')]: {
                height: '24px'
            },
            [theme.breakpoints.only('md')]: {
                height: '35px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '43px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '65px'
            }
        }
    })
);

export const MonthFullCalendar: FunctionComponent<
    MonthFullCalendarProps
> = props => {
    const classes = useStyles();
    const fullcalendarClasses = useFullCalendarStyles();
    const marketplace = useMarketplace();
    const timeZone = useTimeZone();

    const fullCalendarRef = createRef<FullCalendar>();

    useEffect(() => {
        fullCalendarRef.current
            ?.getApi()
            .gotoDate(props.month.format('YYYY-MM'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.month]);

    const appointmentsByDate: Map<string, number> = useMemo(() => {
        return props.appointments.reduce((map, appointment) => {
            const date = appointment.duration.from.format('YYYY-M-D');
            const count = (map.get(date) ?? 0) + 1;
            map.set(date, count);
            return map;
        }, new Map<string, number>());
    }, [props.appointments, timeZone]);

    const dateClickHandler = (args: DateClickArg, timeZone: string) => {
        const date = `${args.date.getFullYear()}-${padStart(
            (args.date.getMonth() + 1).toString(),
            2,
            '0'
        )}-${padStart(args.date.getDate().toString(), 2, '0')}T00:00:00`;

        const at = moment.tz(date, timeZone);

        debugConsoleLog(`date: ${date} moment: ${at.format()}`);

        const dayOfWeek = at.format('dddd').toLocaleLowerCase();
        const businessHours = get(marketplace.schedule.week, dayOfWeek);
        const [hour, minutes] =
            businessHours === null ? ['8', '0'] : businessHours.open.split(':');
        at.hour(hour);
        at.minutes(minutes);

        props.onBook && props.onBook(at);
    };

    const renderMonthDayCell = (
        args: DayCellContentArg,
        appointmentsByDate: Map<string, number>
    ) => (
        <MonthDayCellFullCalendar
            {...args}
            appointmentsCount={
                appointmentsByDate.get(
                    `${args.date.getFullYear()}-${
                        args.date.getMonth() + 1
                    }-${args.date.getDate()}`
                ) ?? 0
            }
        />
    );

    const openDaysOfWeek = useMemo(
        () =>
            Array.from(daysOfWeek.keys())
                .filter(
                    weekDay => get(marketplace.schedule.week, weekDay) !== null
                )
                .map(weekDay => daysOfWeek.get(weekDay)),
        [marketplace.schedule.week, daysOfWeek]
    );

    const specialDays = useMemo(
        () =>
            marketplace.schedule.specialHours.map(specialHour => ({
                start: specialHour.date,
                end: specialHour.date,
                display: 'background',
                color: specialHour.isClosed ? '#f2f2f2' : 'white'
            })),
        [marketplace.schedule.specialHours]
    );

    const fullCalendarComponent = useMemo(
        () => (
            <FullCalendar
                ref={fullCalendarRef}
                plugins={[
                    interactionPlugin,
                    momentTimezonePlugin,
                    dayGridPlugin
                ]}
                initialView="dayGridMonth"
                //timeZone={timeZone}
                editable={true}
                height="100%"
                headerToolbar={false}
                dayHeaderContent={props => (
                    <WeekdayHeaderFullCalendar {...props} />
                )}
                dayHeaderClassNames={[classes.dayHeader]}
                dayCellClassNames={[fullcalendarClasses.dayCell]}
                dayCellContent={args =>
                    renderMonthDayCell(args, appointmentsByDate)
                }
                dateClick={args => dateClickHandler(args, timeZone)}
                firstDay={0}
                businessHours={{
                    daysOfWeek: openDaysOfWeek
                }}
                events={specialDays}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appointmentsByDate, props.month]
    );

    return <Box className={classes.container}>{fullCalendarComponent}</Box>;
};

export default MonthFullCalendar;
