import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { Step as StepModel, StepStatus } from "./SidebarModel";
interface SidebarStepChipProps {
  index: number;
  step: StepModel;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      backgroundColor: "black",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(34)}px`,
        height: `${reduceResolution(29)}px`,
        borderRadius: `${reduceResolution(34)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "34px",
        height: "29px",
        borderRadius: "34px",
      },
    },
    disabled: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    label: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "white",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
      },
    },
    icon: {
      color: "white",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(28)}px !important`,
        height: `${reduceResolution(28)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "28px !important",
        height: "28px !important",
      },
    },
  })
);

export const SidebarStepChip: FunctionComponent<SidebarStepChipProps> = (props) => {
  const classes = useStyles(props);

  const isDisabled = props.step.status === StepStatus.DISABLED;
  const isDone = props.step.status === StepStatus.DONE;
  return isDone ? (
    <FontAwesomeIcon icon={faCircleCheck} className={clsx(classes.icon, props.className)} />
  ) : (
    <Box className={clsx(classes.container, { [classes.disabled]: isDisabled }, props.className)}>
      <Typography className={classes.label}>{props.index}</Typography>
    </Box>
  );
};

export default SidebarStepChip;
