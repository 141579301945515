import React, { useMemo } from 'react';
import { PlanCard } from '../PlanCard';
import { Subscription } from 'model/Subscriptions';
import {
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import { Button, PriceFormat } from 'components/UI';
import moment from 'moment-timezone';

interface Props {
	subscription: Subscription;
	onDowngrade: (subscription: Subscription) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'padding': '21px 23px',

			[theme.breakpoints.up('md')]: {
				padding: '35px 30px'
			},
			'& h1': {
				fontSize: "20px"
			},

			'& h1 small': {
				[theme.breakpoints.down('md')]: {
					fontSize: '1em'
				}
			},
			'& h3': {
				fontSize: "16px",
				lineHeight: 1.4
			},
			'& h3 strong': {
				fontSize: "16px",
				lineHeight: 1.4
			},
		},
		mobile: {
			[theme.breakpoints.up('sm')]: {
				display: 'none'
			}
		},
		desktop: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		button: {
			'height': 47,
			'borderRadius': 30,

			'& span': {
				fontSize: 16,
				fontWeight: 600
			},

			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: 25
			}
		}
	})
);

export const PremiumPlanCard: React.FunctionComponent<Props> = ({
	subscription,
	onDowngrade
}) => {
	const theme = useTheme();
	const classes = useStyles();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const renewalDate = useMemo(() => {
		return moment(subscription.renewAt).format('MMM Do, YYYY');
	}, [subscription.renewAt]);

	const endsAtDate = useMemo(() => {
		return moment(subscription.endAt).format('MMM Do, YYYY');
	}, [subscription.endAt]);

	const isDowngraded = useMemo(() => {
		return subscription.endAt !== null && subscription.endAt.isValid();
	}, [subscription]);

	const downgradeHandler = () => {
		onDowngrade(subscription);
	};

	return (
		<PlanCard className={classes.root}>
			<Grid container spacing={isMobile ? 2 : 5}>
				<Grid item xs={12} md="auto">
					<label>Current Plan</label>
					<Typography variant="h1">
						<small>Premium Plan</small>
					</Typography>
				</Grid>

				{isMobile && (
					<Grid item xs={12}>
						<hr className={classes.mobile} style={{ margin: 0 }} />
					</Grid>
				)}

				<Grid item xs={12} md="auto">
					<label>Price</label>
					<Typography variant="h3">
						<strong>
							<PriceFormat
								price={`${subscription.price?.total}`}
							/>
						</strong>{' '}
						/mo
					</Typography>
				</Grid>
				<Grid item xs>
					<label>Billing Period</label>
					<Typography variant="h3">
						<strong>Monthly</strong>
						&nbsp; (
						{isDowngraded ? (
							<>ends at {endsAtDate}</>
						) : (
							<>renews {renewalDate}</>
						)}
						)
					</Typography>
				</Grid>
			</Grid>

			<hr className={classes.desktop} />

			<Button
				size="medium"
				variant="primary"
				label="Downgrade Plan"
				disabled={isDowngraded}
				onClick={downgradeHandler}
				className={classes.button}
			/>
		</PlanCard>
	);
};
