import { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface ItemLabelIconProps {
  label?: string | JSX.Element;
  icon?: IconDefinition;
  classNameLabel?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
    },
    boxFlexRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: 'center',
    },
    boxIcon: {
      width: "22px",
    },
    sectionIcon: {
    },
    listAlignedIcons: {
      width: "15px !important",
    },
    label: {
      fontSize: "14px",
      paddingTop: "2px",
    },
  })
);

export const ItemLabelIcon: FunctionComponent<ItemLabelIconProps> = (props) => {
  const classes = useStyles();

  const iconSVG = (icon: IconDefinition, styles: string, size: any) => {
    return <FontAwesomeIcon icon={icon} className={styles} size={size} />;
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.boxFlexRow}>
        <Box className={classes.boxIcon}>
          {props.icon && iconSVG(props.icon, `${classes.sectionIcon} ${classes.listAlignedIcons}`, "lg")}
        </Box>
        {(typeof props.label === "string") ?
          <Typography className={clsx(classes.label, props.classNameLabel)}>{props.label}</Typography> : props.label}
      </Box>
    </Box>
  );
};

export default ItemLabelIcon;
