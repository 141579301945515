import React, { FunctionComponent, ChangeEvent, useState, useEffect, } from "react";
import { Box, InputAdornment, OutlinedInput, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";

interface Props {
    title?: string,
    onSearch: (text: string) => void;
    placeholder: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up(768)]: {
                display: 'none'
            },
        },
        containerOpen: {
            top: 55,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            position: 'fixed',
            paddingTop: 13,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: '#fff'
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 25
        },
        title: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.5
        },
        iconContainer: {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            paddingLeft: "14px",
        },
        close: {
            width: 20,
            height: 20,
            marginLeft: 'auto',

            '& span': {
                width: 20,
                height: 2,
                display: 'block',
                position: 'relative',
                backgroundColor: '#000',

                '&:first-child': {
                    top: 9,
                    transform: 'rotate(45deg)'
                },
                '&:last-child': {
                    top: 7,
                    transform: 'rotate(-45deg)'
                }
            }
        },
        searchField: {
            width: "100%",
            paddingLeft: 22,
            borderRadius: 100,
            '& fieldset': {
                borderWidth: 2,
                borderColor: "#222222 !important",
            },
            '& .MuiInputBase-input': {
                padding: '13.5px 20px 13.5px 0px'
            }
        },
        pointer: {
            cursor: "pointer",
        },
        searchIcon: {
            fontSize: 24,
            lineHeight: 1.5
        },
        fonts: {
            fontSize: 16,
            lineHeight: 1.5
        },
    })
);

export const SearchComponentPopup: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const [showSearchBox, setShowSearchBox] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        const timer: NodeJS.Timer = setTimeout(() => {
            props.onSearch(searchText);
        }, 500);
        return () => clearTimeout(timer);
    }, [searchText]);

    const iconClickHandler = (): void => {
        setSearchText("");
        setShowSearchBox((current) => {
            return !current;
        });
    };

    const changeHandler = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.currentTarget.value);
    };

    const keyPressEvent = (e: React.KeyboardEvent) => {
        if(e.key === 'Enter') setShowSearchBox(false)
    }

    const searchIcon = (
        <Box className={classes.iconContainer} onClick={iconClickHandler}>
            <FontAwesomeIcon
                className={clsx(classes.pointer, classes.searchIcon)}
                icon={faSearch}
            />
        </Box>
    );

    const searchBox = (
        <OutlinedInput
            autoFocus
            placeholder={props.placeholder}
            value={searchText}
            className={clsx(classes.searchField, classes.pointer, classes.fonts)}
            onChange={changeHandler}
            onKeyPress={keyPressEvent}
            startAdornment={
                <InputAdornment position="start">
                    <FontAwesomeIcon
                        icon={faSearch}
                        className={classes.fonts}
                        onClick={iconClickHandler}
                    />
                </InputAdornment>
            }
        />
    );

    return (
        <Box className={clsx(classes.container, props.className, { [classes.containerOpen]: showSearchBox })}>
            {showSearchBox && <Box className={classes.titleContainer}>
                <Typography className={classes.title}>{props.title}</Typography>

                <Box className={classes.close} onClick={() => setShowSearchBox(false)}>
                    <span></span><span></span>
                </Box>
            </Box>}

            {showSearchBox ? searchBox : searchIcon}
        </Box>
    );
};

export default SearchComponentPopup;
