import { Grid } from "@material-ui/core";
import { CalendarField } from "components/UI";
import { PetDental } from "@spike/medical-history-model";
import { Moment } from "moment-timezone";
import { FunctionComponent } from "react";

interface NonAnestheticDentalProps {
  dental: PetDental;
  onChange: (dental: PetDental) => void;
  className?: string;
}

export const NonAnestheticDental: FunctionComponent<NonAnestheticDentalProps> = (props) => {
  const changeDateHandler = (cleaningDate: Moment) => {
    props.onChange({ ...props.dental, cleaningDate });
  };

  return (
    <Grid container className={props.className}>
      <Grid item xs={12} md={6}>
        <CalendarField date={props.dental.cleaningDate} rawDate={true} placeholder="Cleaning Date" onChange={changeDateHandler} />
      </Grid>
    </Grid>
  );
};

export default NonAnestheticDental;
