import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import {
    Theme,
    createStyles,
    makeStyles,
    useTheme
} from '@material-ui/core/styles';
import { Button, Spinner } from 'components/UI';
import { FunctionComponent } from 'react';
import Countdown from './Countdown';

interface BlockedProps {
    blockerUser: string;
    className?: string;
    onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        footer: {
            display: 'flex',
            height: 50,
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: 20
        },
        body: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        title: {
            fontFamily: 'Poppins',
            fontSize: 32,
            lineHeight: '120%',
            fontWeight: 600,
            color: '#000000',
            marginTop: 27,
            marginBottom: 22
        },
        description: {
            fontFamily: 'Poppins',
            fontSize: 18,
            lineHeight: '130%',
            fontWeight: 400,
            color: '#000000'
        },
        countdown: {
            marginTop: 20
        },
        button: {
            marginTop: 30
        },
        closeIcon: {
            cursor: 'pointer'
        }
    })
);

export const Blocked: FunctionComponent<BlockedProps> = props => {
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    const blockTimeoutInSeconds = Number(
        process.env.REACT_APP_BLOCK_TIMEOUT_SECONDS || 60
    );

    const decription = isMobile ? (
        <>
            <Typography className={classes.description}>
                Hold On! <strong>{props.blockerUser}</strong>
            </Typography>
            <Typography className={classes.description}>
                is currently processing this
            </Typography>
            <Typography className={classes.description}>
                checkout. Please hang tight
            </Typography>
            <Typography className={classes.description}>
                while they finish up.
            </Typography>
            <Typography className={classes.description}>
                If <strong>{props.blockerUser}</strong>
            </Typography>
            <Typography className={classes.description}>
                exits the checkout screen,
            </Typography>
            <Typography className={classes.description}>
                you can proceed by refreshing
            </Typography>
            <Typography className={classes.description}>this page.</Typography>
        </>
    ) : (
        <>
            <Typography className={classes.description}>
                Hold On! <strong>{props.blockerUser}</strong> is currently
                processing this
            </Typography>
            <Typography className={classes.description}>
                checkout. Please hang tight while they finish up. If
            </Typography>
            <Typography className={classes.description}>
                <strong>{props.blockerUser}</strong> exits the checkout screen,
                you can
            </Typography>
            <Typography className={classes.description}>
                proceed by refreshing this page.
            </Typography>
        </>
    );
    return (
        <Box className={classes.container}>
            <Box className={classes.footer}>
                <FontAwesomeIcon
                    icon={faTimes}
                    fontSize={20}
                    onClick={props.onBack}
                    className={classes.closeIcon}
                />
            </Box>
            <Box className={classes.body}>
                <Spinner />
                <Typography className={classes.title}>
                    Unable to Checkout
                </Typography>
                {decription}
                <Countdown
                    initialTimeInSeconds={blockTimeoutInSeconds}
                    className={classes.countdown}
                    onFinished={props.onBack}
                />
                <Button
                    label="Go to Bookings"
                    onClick={props.onBack}
                    color="orange"
                    className={classes.button}
                />
            </Box>
        </Box>
    );
};

export default Blocked;
