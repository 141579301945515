import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Product } from "@spike/product-model";
import { PriceFormat } from "components/UI";

interface Props {
  product: Product;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingLeft: "0px",
      verticalAlign: "top !important",
      width: "500px",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
      "&.MuiTableCell-root": {
        border: "none",
        paddingTop: "0px !important",
        paddingLeft: "0px !important",
        [theme.breakpoints.down("md")]: {
          padding: "15px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "18px",
        },
      }
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    priceContainer: {
      display: "flex"
    },
  })
);

export const ProductRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const vendorDefault = props.product && props.product.vendors && props.product.vendors.find((v) => v.default === true);

  return (
    <TableRow>
      <TableCell className={clsx(classes.cell)}>
        <Typography className={classes.text}>{props.product.businessProduct?.name ? props.product.businessProduct.name : props.product.name}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <PriceFormat price={props.product.businessProduct?.pricing?.price || "-"} />
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <Typography className={classes.text}>{vendorDefault?.vendor?.name || "-"}</Typography>
      </TableCell>
      
    </TableRow>
  );
};

export default ProductRow;