import { faPlus, faPuzzlePiece } from "@fortawesome/pro-regular-svg-icons";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Button } from "components/UI";
import IconCircle from "components/UI/IconCircle";
import TitlePrimary from "components/UI/Title/TitlePrimary";
import { FunctionComponent } from "react";
import { wbp, reduceResolution } from "Theme";

interface EmptyProps {
  onAdd?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,

      fontSize: 15,
      lineHeight: 1.4,

      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "& .MuiTypography-root": {
        maxWidth: 370,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    icon: {
      margin: "0px auto 15px",
      "& svg": {
        marginRight: -10,
      },
    },
    paragraph: {
      fontSize: 15,
      lineHeight: 1.4,
      marginBottom: 20,
    },
    button: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(280)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "280px",
      },
    }
  })
);

export const Empty: FunctionComponent<EmptyProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <IconCircle icon={faPuzzlePiece} className={classes.icon} />
      <TitlePrimary>Create a new add-on service</TitlePrimary>
      <Typography className={classes.paragraph}>Click the “New Add-On Service” button to add your first one</Typography>

      <Button
        label="New Add-On Service"
        startIcon={faPlus}
        onClick={props.onAdd}
        className={classes.button}
      />
    </Box>
  );
};

export default Empty;
