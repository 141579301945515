import { Box, InputAdornment, InputBaseComponentProps, TextField as MUITextField } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { useNonInitialEffect } from "@versiondos/hooks";
import clsx from "clsx";
import { ChangeEvent, FocusEvent, FunctionComponent, KeyboardEvent, useState } from "react";
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";

export interface OnlyDigitsField2Props {
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: string | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: string, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  multiline?: boolean;
  onEnterPressed?: () => void;
  disabled?: boolean;
  alignRight?: boolean;
  information?: string;
  maxLength?: number;
}

export const OnlyDigitsField2: FunctionComponent<OnlyDigitsField2Props> = (props) => {
  const classes = useFieldStyles();

  const [text, setText] = useState(props.value || "");

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(text, props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const cleanValue = newValue.replace(/\D/g, "");
    setText(cleanValue);
  };

  const onFocusHandler = (event: FocusEvent<HTMLInputElement>): void => {
    event.target.selectionStart = 0;
    event.target.selectionEnd = event.target.value.length;
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  let inputProps = undefined;

  if (props.startIcon) {
    inputProps = {
      startAdornment: <InputAdornment position="start">{props.startIcon}</InputAdornment>,
    };
  } else if (props.endIcon) {
    inputProps = {
      endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment>,
    };
  }

  let properties: InputBaseComponentProps | undefined = undefined;

  if (props.maxLength) {
    properties = { maxLength: props.maxLength };
  }

  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField {...props} />
      <Box>
        <MUITextField
          name={props.name}
          placeholder={props.placeholder}
          variant="outlined"
          value={text}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          className={clsx(classes.field, {
            [classes.startIcon]: props.startIcon,
            [classes.endIcon]: props.endIcon,
            [classes.emptyField]: text.length === 0,
            [classes.fieldError]: hasError,
            [classes.inputTextRight]: props.alignRight,
          })}
          error={hasError}
          InputProps={inputProps}
          multiline={props.multiline}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
          inputProps={properties}
        />
      </Box>
    </Box>
  );
};

export default OnlyDigitsField2;
