import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import Button from 'components/UI/Button';
import { Moment } from 'moment-timezone';
import { FunctionComponent, useEffect } from 'react';
import { reduceResolution, wbp } from 'Theme';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface PaymentDoneProps {
	total: string;
	date: Moment;
	className?: string;
	onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			minHeight: '100%',
			alignItems: 'center',
			flexDirection: 'column',
			justifyContent: 'center',

			[theme.breakpoints.down('sm')]: {
				padding: '0px 16px'
			}
		},
		closeButton: {
			top: 10,
			right: 10,
			zIndex: 10,
			border: 0,
			color: '#000',
			fontSize: 20,
			position: 'absolute',
			backgroundColor: 'transparent',

			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		mobileWrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',

			[theme.breakpoints.down('sm')]: {
				flex: 1
			}
		},
		icon: {
			color: '#5E8677',
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(94)}px !important`,
				height: `${reduceResolution(94)}px !important`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '94px !important',
				height: '94px !important'
			}
		},
		title: {
			fontSize: 26,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',
			marginTop: 24,

			[theme.breakpoints.up('md')]: {
				fontSize: 32,
				marginTop: 15
			}
		},
		date: {
			color: '#000',
			fontSize: 16,
			lineHeight: 1.4,
			fontWeight: 400,
			marginTop: 12,

			[theme.breakpoints.down('sm')]: {
				maxWidth: 250,
				marginLeft: 'auto',
				marginRight: 'auto',
				textAlign: 'center'
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 15,
				marginTop: 15
			}
		},
		total: {
			fontSize: 22,
			lineHeight: 1,
			fontWeight: 500,
			color: '#92B4A7',
			marginTop: 30,

			[theme.breakpoints.up('md')]: {
				fontSize: 28,
				marginTop: 25
			}
		},
		paid: {
			fontSize: 16,
			fontWeight: 600,
			color: '#2C2C2C',
			marginTop: 8,

			[theme.breakpoints.up(wbp)]: {
				fontSize: 16,
				fontWeight: 500
			}
		},
		button: {
			[theme.breakpoints.down('sm')]: {
				'height': 55,
				'width': '100%',
				'marginBottom': 24,

				'& span': {
					fontSize: 18
				}
			},
			[theme.breakpoints.up('md')]: {
				marginTop: '35px'
			}
		}
	})
);

export const PaymentDone: FunctionComponent<PaymentDoneProps> = props => {
	const classes = useStyles();

	const isCompleted = Number(props.total) === 0;

	useEffect(() => {
		amplitude.track(AMPLITUDE.PAYMENT_CONFIRMATION_CHECK_OUT);
	}, []);

	return (
		<Box className={classes.container}>
			<button className={classes.closeButton} onClick={props.onClose}>
				<FontAwesomeIcon icon={faTimes} />
			</button>

			<Box className={classes.mobileWrapper}>
				<FontAwesomeIcon
					icon={faCircleCheck}
					className={classes.icon}
				/>
				<Typography className={classes.title}>
					{isCompleted ? 'Booking Completed!' : 'Payment Successful!'}
				</Typography>
				<Typography
					className={classes.date}
				>{`Payment received on ${props.date.format(
					'dddd, D MMM YYYY'
				)}`}</Typography>
				{!isCompleted && (
					<Typography
						className={classes.total}
						id="payment-done-total"
					>
						${props.total}
					</Typography>
				)}
				{!isCompleted && (
					<Typography className={classes.paid}>Total Paid</Typography>
				)}
			</Box>

			<Button
				color="black"
				label="Go to Bookings"
				onClick={props.onClose}
				className={classes.button}
				id="payment-done-go-to-bookings"
			/>
		</Box>
	);
};

export default PaymentDone;
