import React from 'react';
import { reduceResolution, wbp } from 'Theme';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import DetailTableBody from './DetailTableBody';
import { useDetailTableStyles } from './DetailTableStyle';

import clsx from 'clsx';
import DetailTableCell from './DetailTableModels';

interface DetailTableProps {
	loading: boolean;
	headers: Array<DetailTableCell>;
	rows: Array<Array<DetailTableCell>>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingTop: 8,

			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#FAFAFA'
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: 16,
				margin: '0px -24px'
			}
		},
		header: {
			[theme.breakpoints.down(wbp)]: {
				paddingBottom: `${reduceResolution(63)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingBottom: '63px'
			}
		},
		cell: {
			fontWeight: 400,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(15)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15px'
			}
		},
		cellHeader: {
			padding: '20px 0px'
		},
		numeric: {
			textAlign: 'right'
		}
	})
);

export const DetailTable: React.FunctionComponent<DetailTableProps> = props => {
	const classes = useStyles();
	const tableStyles = useDetailTableStyles();

	const headersDetail = (
		<>
			{props.headers?.map(header => {
				return (
					<TableCell
						key={String(header)}
						className={
							header.numeric
								? clsx(tableStyles.headerCell, classes.numeric)
								: tableStyles.headerCell
						}
					>
						{header.value}
					</TableCell>
				);
			})}
		</>
	);

	const rowsDetail = (
		<>
			{props.rows?.map(row => {
				return (
					<>
						<TableRow>
							{row.map(field => {
								return (
									<TableCell
										key={String(field)}
										className={
											field.numeric
												? clsx(
														tableStyles.cell,
														classes.numeric
												  )
												: tableStyles.cell
										}
									>
										{field.value}
									</TableCell>
								);
							})}
						</TableRow>
					</>
				);
			})}
		</>
	);

	return (
		<Box className={classes.root}>
			<DetailTableBody
				headers={headersDetail}
				rows={rowsDetail}
				loading={props.loading}
				withoutColor={true}
			/>
		</Box>
	);
};

export default DetailTable;
