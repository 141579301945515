import {
	Box,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { InvoiceTipDistribution } from '@spike/invoice-model';
import { reduceResolution, wbp } from 'Theme';
import { FunctionComponent } from 'react';
import Staff from './Staff';
import { CalculatingSpinner } from 'components/UI';

interface TipDistributionRowProps {
	index: number;
	distribution: InvoiceTipDistribution;
	loading?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			gap: 10,
			display: 'flex',
			overflow: 'hidden',
			flexWrap: 'nowrap',
			alignItems: 'center',
			justifyContent: 'space-betweeen',
			border: '1px solid #DDDDDD',
			borderRadius: '12px',

			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(13)}px ${reduceResolution(
					18
				)}px ${reduceResolution(13)}px ${reduceResolution(18)}px`,
				marginBottom: `${reduceResolution(17)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '13px 18px 13px 18px',
				marginBottom: '17px'
			}
		},
		amount: {
			textAlign: 'right',
			width: '100%'
		},
		staff: {
			'maxWidth': '100%',

			'& p': {
				width: '100%',
				display: 'block',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis'
			}
		}
	})
);

const TipDistributionRow: FunctionComponent<
	TipDistributionRowProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box style={{ flex: 1, minWidth: 0 }}>
				<Staff
					staff={props.distribution.staff}
					className={classes.staff}
				/>
			</Box>
			<Box>
				{props.loading ? (
					<CalculatingSpinner />
				) : (
					<Typography
						id={'checkout_tips_value_' + props.index}
						className={classes.amount}
					>
						${props.distribution.amount}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default TipDistributionRow;
