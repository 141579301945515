export interface SmsMessageDto {
    id: number | undefined;
    uuid?: string;
    marketplace_id: number;
    customer_id: number;
    sender_id: number;
    sender_type: SmsSenderType;
    receiver_id: number;
    receiver_type: SmsReceiverType;
    message: string;
    note: boolean;
    notification: boolean;
    sent_at?: Date;
    received_at?: Date;
    read_at?: Date;
    updated_at?: Date;
    created_at?: Date;
}

export enum SmsReceiverType {
    CUSTOMER = 'Customer',
    STAFF = 'Staff'
}

export enum SmsSenderType {
    CUSTOMER = 'Customer',
    STAFF = 'Staff',
    MARKETPLACE = 'Marketplace'
}

export interface SmsGetMessageCountDto {
    total: number;
    unread?: Array<SmsMessageDto>;
}

export interface SmsFetchMessagesDto {
    messages_to?: Array<SmsMessageDto>;
    messages_from?: Array<SmsMessageDto>;
}

export interface SmsSearchMessagesDto {
    sms_messages: Array<SmsMessageDto>;
    page: number;
    size: number;
    total_pages: number;
    total_records: number;
}

export interface SmsSearchChatResponseDto {
    sms_conversations: Array<SmsConversationDto>;
}

export interface SmsConversationDto {
    last_sms_message: SmsMessageDto;
    customer: SmsCustomerDto;
    unread_sms_messages: number;
}

export interface SmsCustomerDto {
    id: number;
    name: string;
}
