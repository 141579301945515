import { Theme, createStyles, makeStyles } from '@material-ui/core';
import AddOnService from '@spike/addon-service-model';
import { InvoiceLine, InvoiceLineType } from '@spike/invoice-model';
import { FieldError } from '@spike/model';
import { Product } from '@spike/product-model';
import { AddOnSelector, ProductSelector, TextField2 } from 'components/UI';
import React from 'react';

type ItemSelectorProps = {
	invoiceLine: InvoiceLine;
	errors?: Array<FieldError>;
	invoiceLineType: InvoiceLineType;
	invoiceProductIds?: Array<number>;
	invoiceAddOnServiceIds?: Array<number>;
	onChange: (
		type: InvoiceLineType,
		product?: Product,
		addonService?: AddOnService,
		description?: string
	) => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		field: {
			[theme.breakpoints.down('sm')]: {
				'& input': {
					height: 36,
					fontSize: 14,
					lineHeight: 1,
					padding: '0px 19px 0px'
				}
			}
		}
	})
);

export const ItemSelector: React.FunctionComponent<
	ItemSelectorProps
> = props => {
	const classes = useStyles();

	const onChangeHandler = (value?: Product | AddOnService | string) => {
		if (props.invoiceLineType === InvoiceLineType.Product) {
			props.onChange(props.invoiceLineType, value as Product);
		}

		if (props.invoiceLineType === InvoiceLineType.AddOnService) {
			props.onChange(
				props.invoiceLineType,
				undefined,
				value as AddOnService
			);
		}

		if (props.invoiceLineType === InvoiceLineType.Custom) {
			props.onChange(
				props.invoiceLineType,
				undefined,
				undefined,
				value as string
			);
		}
	};

	return (
		<>
			{props.invoiceLineType === InvoiceLineType.Product &&
				!props.invoiceLine.productId && (
					<ProductSelector
						name={`${props.invoiceLine.uuid}_selection`}
						excludedIds={props.invoiceProductIds}
						errors={props.errors}
						onSelect={onChangeHandler}
					/>
				)}
			{props.invoiceLineType === InvoiceLineType.AddOnService &&
				!props.invoiceLine.addOnServiceId && (
					<AddOnSelector
						name={`${props.invoiceLine.uuid}_selection`}
						excludedIds={props.invoiceAddOnServiceIds}
						errors={props.errors}
						onSelect={onChangeHandler}
					/>
				)}
			{props.invoiceLineType === InvoiceLineType.Custom && (
				<TextField2
					value=""
					placeholder="Enter description"
					name={`${props.invoiceLine.uuid}_description`}
					errors={props.errors}
					className={classes.field}
					onChange={onChangeHandler}
				/>
			)}
		</>
	);
};
