import {
	Avatar,
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { InvoicePet as InvoicePetModel } from '@spike/invoice-model';
import { wbp } from 'Theme';
import PetName from 'components/Bookings/Table/PetName';

interface InvoicePetProps {
	pet: InvoicePetModel;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			fontSize: 16,
			lineHeight: 1.4,
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				marginBottom: 10,
				paddingBottom: 12,
				alignItems: 'center',
				borderBottom: 'solid 1px #D4D4D4'
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 20
			}
		},
		avatarContainer: {
			marginRight: 8,

			[theme.breakpoints.up('md')]: {
				marginRight: 13
			}
		},
		dataContainer: {
			width: '60%'
		},
		avatar: {
			width: 42,
			height: 42,

			[theme.breakpoints.down('sm')]: {
				borderRadius: 0
			},
			[theme.breakpoints.up('md')]: {
				width: 82,
				height: 82
			}
		},
		name: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '16px',
				lineHeight: '24px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px',
				lineHeight: '30px'
			}
		},
		breed: {
			fontSize: 17,
			lineHeight: 1,
			fontWeight: 400,
			color: '#707070',
			letterSpacing: '1.12px',
			textTransform: 'uppercase',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	})
);

export const InvoicePet: FunctionComponent<InvoicePetProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.avatarContainer}>
				<Avatar className={classes.avatar} src={props.pet.imageUrl} />
			</Box>
			<Box className={classes.dataContainer}>
				<Typography className={classes.name}>
					<PetName
						name={props.pet.name}
						hasMedicalConditions={props.pet.hasMedicalRecord}
					/>
				</Typography>
				<Typography className={classes.breed}>
					{props.pet.breed}
				</Typography>
			</Box>
		</Box>
	);
};

export default InvoicePet;
