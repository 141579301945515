import { reset as resetNotifications } from "@spike/notifications-action";
import { reset as resetAccounts } from "actions/activateAccount/ActivateAccountActions";
import { reset as resetAgreements } from "actions/agreements/AgreementsActions";
import { reset as resetAutocomplete } from "actions/autocomplete/AutocompleteActions";
import { reset as resetAvailability } from "actions/availability/AvailabilityActions";
import { reset as resetAvailableStaff } from "@spike/available-staff-action";
import { resetBookings, resetAppointments } from "@spike/bookings-action";
import { reset as resetClients } from "@spike/clients-action";
import { reset as resetForgotPassword } from "@spike/forgot-password-action";
import { reset as resetInvoices } from "@spike/invoices-action";
import { resetThunk as resetLogin } from "actions/login/LoginActions";
import { reset as resetMarketplace } from "@spike/marketplace-action";
import { fetchMasterDataWithoutMarketplaceThunk, reset as resetMasterdata } from "@spike/masterdata-action";
import { reset as resetNotificationBell } from "actions/notificationBell/NotificationBellActions";
import { reset as resetOnboardingSteps } from "actions/onboardingSteps/OnboardingStepsActions";
import { reset as resetPayments } from "@spike/payments-action";
import { reset as resetPets } from "@spike/pets-action";
import { reset as resetPos } from "@spike/pos-action";
import { reset as resetProductCategories } from "actions/productCategories/ProductCategoriesActions";
import { reset as resetProducts } from "@spike/products-action";
import { reset as resetProductVendors } from "actions/productVendors/ProductVendorsActions";
import { reset as resetReports } from "actions/reports/ReportsActions";
import { reset as resetPassword } from "actions/resetPassword/ResetPasswordActions";
import { reset as resetRevenues } from "actions/revenues/RevenuesActions";
import { reset as resetServices } from "actions/services/ServicesActions";
import { reset as resetSignUp } from "actions/signUp/SignUpActions";
import { reset as resetStaff } from "actions/staff/StaffActions";
import { reset as resetUsers } from "actions/users/UsersActions";
import { reset as resetVets } from "@spike/vets-action";
import ApiClient from "api/ApiClient";
import { Dispatch } from "redux";

// eslint-disable-next-line
export const cleanAll = (dispatch: Dispatch<any>, avoidFetchingMatserData?: boolean ) => {
  dispatch(resetLogin());
  cleanAllExceptLogin(dispatch, avoidFetchingMatserData);
};

export const cleanAllExceptLogin = (dispatch: Dispatch<any>, avoidFetchingMatserData?: boolean) => {
  dispatch(resetAccounts());
  dispatch(resetNotifications());
  dispatch(resetAppointments());
  dispatch(resetAutocomplete());
  dispatch(resetAvailability());
  dispatch(resetAvailableStaff());
  dispatch(resetBookings());
  dispatch(resetClients());
  dispatch(resetForgotPassword());
  dispatch(resetInvoices());
  dispatch(resetMarketplace());
  dispatch(resetMasterdata());
  dispatch(resetPets());
  dispatch(resetReports());
  dispatch(resetPassword());
  dispatch(resetRevenues());
  dispatch(resetServices());
  dispatch(resetSignUp());
  dispatch(resetStaff());
  dispatch(resetUsers());
  dispatch(resetProducts());
  dispatch(resetProductCategories());
  dispatch(resetProductVendors());
  dispatch(resetVets());
  dispatch(resetOnboardingSteps());
  dispatch(resetNotificationBell());
  dispatch(resetPayments());
  dispatch(resetPos());
  dispatch(resetAgreements());
  !avoidFetchingMatserData && dispatch(fetchMasterDataWithoutMarketplaceThunk(ApiClient));
};
