import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FunctionComponent } from 'react';
import PetTypeSelector from './UI/PetTypeSelector';
import { fixedPriceOption, PricingObject, PricingPetTypeIds } from './model';
import { FixedPrice } from './FixedPrice';
import { FixedPriceOption, ServicePricing, VariablePriceOption } from 'model/Service';
import { isEqual, isNull } from 'lodash';
import { VariablePrice } from './VariablePrice';
import { usePricingContext } from './PricingContext/PricingContext';
import { Option } from '@spike/model';
import clsx from 'clsx';

interface PricingTableProps {
    onNext?: (updatedPricing: ServicePricing) => void;
    onServiceDone?: (done: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderRadius: 16,
            border: '1px solid #E0E0E0',
            marginBottom: 24,
            [theme.breakpoints.down('sm')]: {
                border: 'none'
            }
        },
        petTypeSelector: {
            paddingLeft: '5%',
            paddingTop: 24,
            paddingBottom: 24,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0
            }
        },
        borderNone: {
            border: 'none'
        }
    })
);

export const getPricingByOption = (pricingObject: PricingObject, option: Option<string>) => {
    return pricingObject[option.id];
};

const PricingTable: FunctionComponent<PricingTableProps> = props => {
    const classes = useStyles();

    const { pricing: globalPricing, selectedPetType, selectedPricingOption, errors } = usePricingContext().state;
    const { setSelectedPetType, service } = usePricingContext();

    return (
        <Box
            className={clsx(classes.container, {
                [classes.borderNone]: isEqual(selectedPricingOption.id, fixedPriceOption.id)
            })}
        >
            {!isEqual(selectedPricingOption.id, fixedPriceOption.id) &&
                !isNull(selectedPetType) &&
                !isEqual(selectedPetType.id, PricingPetTypeIds.EXOTICS) && (
                    <Box className={classes.petTypeSelector}>
                        <PetTypeSelector
                            petTypes={service.petTypes}
                            selectedPetType={selectedPetType}
                            onSelect={petType => setSelectedPetType(petType)}
                        />
                    </Box>
                )}
            {isEqual(selectedPricingOption.id, fixedPriceOption.id) && (
                <FixedPrice
                    fixedPrices={globalPricing as Array<FixedPriceOption>}
                    errors={errors.filter(error => error.priceUuid === null)}
                    id="services_add_service_pricing_fixed_price"
                    onServiceDone={props.onServiceDone}
                />
            )}
            {!isEqual(selectedPricingOption.id, fixedPriceOption.id) && (
                <VariablePrice
                    variablePrices={globalPricing as Array<VariablePriceOption>}
                    errors={errors.filter(error => error.priceUuid !== null)}
                    id="services_add_service_pricing_variable_price"
                    onServiceDone={props.onServiceDone}
                />
            )}
        </Box>
    );
};

export default PricingTable;
