import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface HeaderItemsProps {
  options: Array<Option<string>>;
  selectedId?: string;
  className?: string;
  onSelect?: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      itemsAlign: "flex-end",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "16px",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "15px",
        paddingRight: "15px",
        minWidth: "650px",
      },
    },
    item: {
      cursor: "pointer",
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(30)}px`,
        marginRight: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "30px",
        marginRight: "24px",
      },
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#F1F1F1",
        borderRadius: "60px",
        height: "32px",
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "15px",
        "& > p": {
          height: "auto",
          color: "#000",
        }
      },
    },
    selectedItem: {
      borderBottom: "2px solid #222222",
      cursor: "default",
      [theme.breakpoints.down("md")]: {
        border: "none",
        backgroundColor: "#000",
        color: "#fff",
        "& > p": {
          color: "#fff",
        }
      }
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#BCB8AE",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      textWrap: 'no-wrap',
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    selectedText: {
      color: "#222222",
    },
  })
);

export const HeaderItems: FunctionComponent<HeaderItemsProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx([classes.container, props.className])}>
      {props.options.map((option) => (
        <Box
          key={option.id}
          className={clsx(classes.item, { [classes.selectedItem]: props.selectedId === option.id })}
          onClick={() => props.onSelect && props.onSelect(option.id)}
        >
          <Typography className={clsx(classes.text, { [classes.selectedText]: props.selectedId === option.id })}>
            {option.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default HeaderItems;
