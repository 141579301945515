import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { SingleChip } from "components/UI";
import React, { FunctionComponent } from "react";
import { OrderStatuses } from "@spike/masterdata-model";
import { useMasterData } from "hooks";

interface OrderStatusProps {
  orderStatus: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: (order: OrderStatuses) => order.backgroundColor,
      textTransform: "capitalize",
      "& p": {
        color: (order: OrderStatuses) => order.fontColor,
      },
    },
  })
);

export const OrderStatus: FunctionComponent<OrderStatusProps> = (props) => {
  const masterData = useMasterData();
  const orderStatus: OrderStatuses | undefined = masterData.orderStatuses.find((order) => order.id === props.orderStatus);
  const classes = useStyles(orderStatus!);

  return (
    <SingleChip className={clsx(classes.default)}>
      {orderStatus?.name || "-"}
    </SingleChip>
  );
};

export default OrderStatus;
