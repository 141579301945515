export default interface SMSCampaign {
	id?: number;
	marketplace_id?: number;
	name: string;
	uuid?: string;
	message: string;
	price?: string;
	editing?: boolean;
	criteria?: string | null;
	unsubscribe_message: string;
	status_id?: SMSCampaignStatus;
	sms_subscriptions?: Array<SMSCampaignSubcription>;
	created_at?: Date;
	updated_at?: Date;
	stats?: {
		sent: number;
		sent_at: Date;
		percentage: number;
	};
	customer_ids?: Array<number>;
}

export interface SMSCampaignSubcription {
	id?: number;
	read: boolean;
	read_at?: Date;
	sent: boolean;
	sent_at?: Date;
	customer_id: number;
	sms_campaign_id?: number;
	created_at?: Date;
	updated_at?: Date;
}

export enum SMSCampaignCustomerGroups {
	activity = "booked_last",
	date = "created_last",
	tag = "by_tag",
	pet = "by_pet",
}

export interface SMSCampaignCustomerGroupOption {
	pet_type?: string;
	days?: string;
	tag?: string;
	records: number;
}

export interface SMSCampaignQuote {
	subscriptions: string;
	subtotal: string;
	tax: string;
	total: string;
}

export enum SMSCampaignStatus {
	all = "all",
	sent = "sent",
	paid = "paid",
	draft = "draft",
	scheduled = "scheduled",
	archived = "archived",
}
