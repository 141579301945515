import React, { FunctionComponent, Fragment } from "react";
import { formatDate } from "utils/DateUtils";
import { useTimeZone } from "hooks";
import { Moment } from "moment-timezone";
import { Box } from "@material-ui/core";

export interface Props {
  date: Moment;
  className?: string;
}

export const DateFormat: FunctionComponent<Props> = (props) => {
  const timeZone = useTimeZone();
  const date = formatDate(props.date, timeZone);

  return (<Fragment><Box className={props.className}>{date}</Box></Fragment>);
};

export default DateFormat;
