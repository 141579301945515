import React, { FunctionComponent } from 'react';
import {
    Box,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPen } from '@fortawesome/pro-light-svg-icons';
import { PercentageField2, PriceField2 } from 'components/UI';
import useStyles from '../styles/PayByLinkStyles';

interface TipSectionProps {
    tips: Array<{ uuid: string; value: number }>;
    selectedTip: string;
    handleTipSelect: (tip: string) => void;
    showCustomTipEdit: boolean;
    customTipType: 'fixed' | 'percentage';
    handleCustomTipTypeSelect: (type: 'fixed' | 'percentage') => void;
    customTipAmount: number;
    handleCustomTipAmountChange: (value: number) => void;
    handleCustomTipConfirm: () => void;
    handleCustomTipCancel: () => void;
    calculateTipAmount: (percentage: number, subtotal: number) => string;
    subtotal: number;
    classes: Record<string, string>;
    handleEditCustomTip: () => void;
}

const TipSection: FunctionComponent<TipSectionProps> = ({
    tips,
    selectedTip,
    handleTipSelect,
    showCustomTipEdit,
    customTipType,
    handleCustomTipTypeSelect,
    customTipAmount,
    handleCustomTipAmountChange,
    handleCustomTipConfirm,
    handleCustomTipCancel,
    calculateTipAmount,
    subtotal,
    handleEditCustomTip,
    classes
}) => {
    return (
        <Box className={classes.wrapperTip}>
            <Typography className={classes.bookingTitle} variant="body2">
                Add a Tip
            </Typography>
            <Box className={classes.tipContainer}>
                {tips.map(tip => (
                    <Box
                        key={tip.uuid}
                        className={`${classes.tipButton} ${
                            selectedTip === `${tip.value}%`
                                ? classes.selectedTipButton
                                : ''
                        }`}
                        onClick={() => handleTipSelect(`${tip.value}%`)}
                    >
                        {tip.value}%
                        <Typography
                            className={classes.tipInDollars}
                            variant="body2"
                        >
                            ${calculateTipAmount(Number(tip.value), subtotal)}
                        </Typography>
                    </Box>
                ))}
                <Box
                    className={`${classes.tipButton} ${
                        selectedTip === 'custom'
                            ? classes.selectedTipButton
                            : ''
                    }`}
                    onClick={() => handleTipSelect('custom')}
                >
                    Custom
                </Box>
            </Box>
            {selectedTip === 'custom' && (
                <Box className={classes.customTipContainer}>
                    {showCustomTipEdit ? (
                        <Box className={classes.customTipContainerEdit}>
                            <Box
                                className={`${classes.customTipButton} ${
                                    customTipType === 'fixed'
                                        ? classes.selectedCustomTipButton
                                        : ''
                                }`}
                                onClick={() =>
                                    handleCustomTipTypeSelect('fixed')
                                }
                            >
                                $
                            </Box>
                            <Box
                                className={`${classes.customTipButton} ${
                                    customTipType === 'percentage'
                                        ? classes.selectedCustomTipButton
                                        : ''
                                }`}
                                onClick={() =>
                                    handleCustomTipTypeSelect('percentage')
                                }
                            >
                                %
                            </Box>

                            {customTipType === 'fixed' ? (
                                <PriceField2
                                    name={'custom_tip'}
                                    scale={2}
                                    precision={6}
                                    value={customTipAmount || 0.0}
                                    onChange={value =>
                                        handleCustomTipAmountChange(
                                            Number(value)
                                        )
                                    }
                                    className={classes.customTipInput}
                                />
                            ) : (
                                <PercentageField2
                                    name={'custom_tip'}
                                    scale={0}
                                    precision={2}
                                    value={customTipAmount || 0.0}
                                    onChange={value =>
                                        handleCustomTipAmountChange(
                                            Number(value)
                                        )
                                    }
                                    className={classes.customTipInput}
                                />
                            )}

                            <Box
                                className={classes.customTipAction}
                                onClick={handleCustomTipConfirm}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </Box>
                            <Box
                                className={classes.customTipAction}
                                onClick={handleCustomTipCancel}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Box>
                        </Box>
                    ) : (
                        customTipAmount &&
                        customTipAmount > 0 && (
                            <>
                                <Box
                                    className={classes.customTipContainerAmount}
                                >
                                    <Typography
                                        className={classes.customTipSet}
                                        variant="body1"
                                    >
                                        {`${
                                            customTipType === 'fixed' ? '$' : ''
                                        }${customTipAmount}${
                                            customTipType === 'percentage'
                                                ? '%'
                                                : ''
                                        }`}
                                    </Typography>
                                    <Box
                                        className={classes.customTipAction}
                                        onClick={handleEditCustomTip}
                                    >
                                        <FontAwesomeIcon icon={faPen} />
                                    </Box>
                                </Box>
                            </>
                        )
                    )}
                </Box>
            )}
            <Box className={classes.noTipContainer}>
                <RadioGroup
                    value={selectedTip}
                    onChange={e => handleTipSelect(e.target.value as string)}
                    className={classes.noTipRadioGroup}
                >
                    <FormControlLabel
                        className={classes.checkUILabel}
                        value="none"
                        control={
                            <Radio
                                className={classes.checkUIItem}
                                color="default"
                            />
                        }
                        label="No tip"
                        checked={
                            customTipAmount === 0 && selectedTip === 'none'
                        }
                    />
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default TipSection;
