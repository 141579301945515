import { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { impersonateThunk } from "actions/login/LoginActions";
import { cleanAll } from "actions/ActionUtils";

interface Props {
  marketplaceId: number;
  token: string;
}
export const Impersonate: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    cleanAll(dispatch, true);
    dispatch(impersonateThunk(props.marketplaceId, props.token));
  }, []);

  document.title = 'Impersonation - Cuddles';

  return null;
};

const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    ...ownProps,
    marketplaceId: ownProps.match.params.marketplaceId ? parseInt(ownProps.match.params.marketplaceId) : undefined,
    token: ownProps.match.params.token,
  };
};

export default connect(mapStateToProps)(Impersonate);

