import { Action } from "redux";
import ProductCategory from "model/ProductCategory";

//Action Types
export const PRODUCT_CATEGORIES_RESET_ACTION_TYPE = "product_categories/reset";
export const PRODUCT_CATEGORIES_FETCH_START_ACTION_TYPE = "product_categories/fetch_start";
export const PRODUCT_CATEGORIES_FETCH_SUCCESS_ACTION_TYPE = "product_categories/fetch_success";
export const PRODUCT_CATEGORIES_GET_START_ACTION_TYPE = "product_categories/get_start";
export const PRODUCT_CATEGORIES_GET_SUCCESS_ACTION_TYPE = "product_categories/get_success";
export const PRODUCT_CATEGORIES_SAVE_START_ACTION_TYPE = "product_categories/save_start";
export const PRODUCT_CATEGORIES_SAVE_SUCCESS_ACTION_TYPE = "product_categories/save_success";
export const PRODUCT_CATEGORIES_SAVE_PRODUCTS_START_ACTION_TYPE = "product_categories/save_products_start";
export const PRODUCT_CATEGORIES_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE = "product_categories/save_products_success";
export const PRODUCT_CATEGORIES_DELETE_PRODUCTS_START_ACTION_TYPE = "product_categories/delete_products_start";
export const PRODUCT_CATEGORIES_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE = "product_categories/delete_products_success";
export const PRODUCT_CATEGORIES_DELETE_START_ACTION_TYPE = "product_categories/delete_start";
export const PRODUCT_CATEGORIES_DELETE_SUCCESS_ACTION_TYPE = "product_categories/delete_success";
export const PRODUCT_CATEGORIES_ERROR_ACTION_TYPE = "product_categories/error";

//Actions
interface ProductCategoriesResetAction
  extends Action<typeof PRODUCT_CATEGORIES_RESET_ACTION_TYPE> { }

interface ProductCategoriesFetchStartAction
  extends Action<typeof PRODUCT_CATEGORIES_FETCH_START_ACTION_TYPE> { }

interface ProductCategoriesFetchSuccessAction
  extends Action<typeof PRODUCT_CATEGORIES_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    categories  : Array<ProductCategory>;
  };
}

interface ProductCategoriesGetStartAction
  extends Action<typeof PRODUCT_CATEGORIES_GET_START_ACTION_TYPE> { }

interface ProductCategoriesGetSuccessAction
  extends Action<typeof PRODUCT_CATEGORIES_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    category: ProductCategory;
  };
}
interface ProductCategoriesSaveStartAction
  extends Action<typeof PRODUCT_CATEGORIES_SAVE_START_ACTION_TYPE> { }

interface ProductCategoriesSaveSuccessAction
  extends Action<typeof PRODUCT_CATEGORIES_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    category: ProductCategory;
  }
}

interface ProductCategoriesSaveProductsStartAction
  extends Action<typeof PRODUCT_CATEGORIES_SAVE_PRODUCTS_START_ACTION_TYPE> { }

interface ProductCategoriesSaveProductsSuccessAction
  extends Action<typeof PRODUCT_CATEGORIES_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE> {
  payload: {
    category: ProductCategory;
  }
}

interface ProductCategoriesDeleteProductsStartAction
  extends Action<typeof PRODUCT_CATEGORIES_DELETE_PRODUCTS_START_ACTION_TYPE> { }

interface ProductCategoriesDeleteProductsSuccessAction
  extends Action<typeof PRODUCT_CATEGORIES_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE> {
  payload: {
    category: ProductCategory;
  }
}

interface ProductCategoriesDeleteStartAction
  extends Action<typeof PRODUCT_CATEGORIES_DELETE_START_ACTION_TYPE> { }

interface ProductCategoriesDeleteSuccessAction
  extends Action<typeof PRODUCT_CATEGORIES_DELETE_SUCCESS_ACTION_TYPE> {
    payload: {
      productCategoryUuid: string;
    }
}

interface ProductCategoriesErrorAction extends Action<typeof PRODUCT_CATEGORIES_ERROR_ACTION_TYPE> { }

export type ProductCategoriesAction =
  ProductCategoriesResetAction
  | ProductCategoriesFetchStartAction
  | ProductCategoriesFetchSuccessAction
  | ProductCategoriesGetStartAction
  | ProductCategoriesGetSuccessAction
  | ProductCategoriesSaveStartAction
  | ProductCategoriesSaveSuccessAction
  | ProductCategoriesSaveProductsStartAction
  | ProductCategoriesSaveProductsSuccessAction
  | ProductCategoriesDeleteProductsStartAction
  | ProductCategoriesDeleteProductsSuccessAction
  | ProductCategoriesDeleteStartAction
  | ProductCategoriesDeleteSuccessAction
  | ProductCategoriesErrorAction;
