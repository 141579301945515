import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Section from 'components/UI/Section';
import { Box, Typography, Dialog, DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import { Staff } from 'model/Staff';
import Button from 'components/UI/Button';
import AddCommissions from './UI/AddCommissions';
import { useDispatch, useSelector } from 'react-redux';
import { useApiClientWrapper, useAuth } from 'hooks';
import {
    CommissionsStatus,
    fetchByStaffMemberThunk
} from '@spike/commissions-action';
import { CommissionPlan } from '@spike/commission-model';
import { RootState } from 'store';
import { Link } from 'react-router-dom';
import { CommissionItem } from './UI/CommisionItem';
import ViewCommission from './UI/ViewCommission';
import useNonInitialEffect from '@versiondos/hooks';
import { MobileDivider } from 'components/UI/MobileDivider';
import { OWNER_ID } from '@spike/model';

interface CommissionsProps {
    className?: string;
    member?: Staff;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullContainer: {
            //width: '100%',
            height: '100%'
        },
        container: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            padding: '0px 16px',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '38px',
                paddingRight: '38px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '50px',
                paddingRight: '50px'
            }
        },
        title: {
            [theme.breakpoints.down('lg')]: {
                fontSize: 20,
                fontWeight: 600
            }
        },
        subtitle: {
            fontSize: 15,
            fontWeight: 400,
            color: '#000',
            textAlign: 'left',
            [theme.breakpoints.up('md')]: {
                marginTop: 20
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left'
            }
        },
        button: {
            height: 40,
            fontWeight: 100,
            marginTop: 25,
            marginBottom: '25px',

            [theme.breakpoints.down('sm')]: {
                marginBottom: 35
            },

            [theme.breakpoints.down('lg')]: {
                'padding': '0px 16px',
                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            },
            [theme.breakpoints.up('xl')]: {
                height: '40px',
                marginBottom: '25px'
            }
        },
        dialog: {
            '& .MuiDialog-paperScrollPaper': {
                [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    borderRadius: 0,
                    maxHeight: '100%'
                }
            },
            '& .MuiDialog-paper': {
                width: '100%',

                [theme.breakpoints.down('sm')]: {
                    margin: 0
                },

                [theme.breakpoints.up('md')]: {
                    borderRadius: '28px'
                    //width: '486px'
                }
            },
            '& .MuiDialogContent-root': {
                overflowY: 'auto',
                padding: '0px'
            }
        },
        commissionListTitle: {
            fontSize: '16px',
            fontWeight: 600
        },
        commisionListTitleContainer: {
            width: '100% !important',
            padding: '0px 16px',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '38px',
                paddingRight: '38px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '50px',
                paddingRight: '50px'
            }
        },
        commissionListItemContainer: {
            width: '100% !important',
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        comissionListItemChipContainer: {
            width: '25%'
        },
        commisionListItemActionTextContainer: {
            width: '25%'
        },
        commisionListItemDescContainer: {
            width: '50%'
        },
        commissionListItemNotLast: {
            borderBottom: '1px #D4D4D4 solid'
        }
    })
);

export const Commissions: FunctionComponent<CommissionsProps> = props => {
    const classes = useStyles();

    const [viewCommission, setViewCommission] = useState<string | undefined>(
        ''
    );
    const [editCommissions, setEditCommissions] = useState<boolean>(false);
    const [saving, setSaving] = useState(false);
    const [activeSelected, setActiveSelected] = useState<boolean>(false);
    const commissionStatus = useSelector<RootState, CommissionsStatus>(
        state => state.commissions.status
    );

    const auth = useAuth();
    const isOwner = auth.user!.role.id === OWNER_ID;

    const dispatch = useDispatch();

    const apiClientWrapper = useApiClientWrapper();

    const commissionPlans = useSelector<RootState, CommissionPlan[]>(
        state => state.commissions.staffMemberCommissionPlans
    );

    useEffect(() => {
        if (props.member) {
            dispatch(
                fetchByStaffMemberThunk(apiClientWrapper, props.member.id!)
            );
        }
    }, []);

    useNonInitialEffect(() => {
        if (commissionStatus === CommissionsStatus.SaveSuccess) {
            dispatch(
                fetchByStaffMemberThunk(apiClientWrapper, props.member!.id!)
            );
        }
    }, [commissionStatus]);

    const saveHandler = () => {
        setEditCommissions(false);
    };

    const handleView = (uuid: string, active: boolean) => {
        setViewCommission(uuid);
        setActiveSelected(active);
    };

    return (
        <>
            <Box className={clsx(classes.fullContainer, props.className)}>
                <MobileDivider />
                <Section title="Commissions" titleClassName={classes.title}>
                    <Box className={classes.container}>
                        <Typography className={classes.subtitle}>
                            Set how much {props.member?.person.firstName} can
                            make. Commission is assigned to team members based
                            on sale attribution, and is summarized in the{' '}
                            {isOwner ? (
                                <Link
                                    to="/payments#COMMISSIONS"
                                    style={{ fontWeight: 600 }}
                                >
                                    Commission Report.
                                </Link>
                            ) : (
                                <>Commission Report.</>
                            )}{' '}
                        </Typography>

                        <Box>
                            <Button
                                id="services_button_add_service"
                                color="green"
                                variant="primary"
                                className={classes.button}
                                onClick={() => setEditCommissions(true)}
                                label="+ Add Commissions"
                            />
                        </Box>
                    </Box>

                    {commissionPlans && commissionPlans.length > 0 && (
                        <>
                            <Box
                                className={classes.commisionListTitleContainer}
                            >
                                <Typography
                                    className={classes.commissionListTitle}
                                >
                                    Active Commission
                                </Typography>
                            </Box>
                            <Box className={classes.container}>
                                {commissionPlans.map(
                                    (commissionPlanItem, index) => {
                                        return (
                                            <CommissionItem
                                                key={commissionPlanItem.uuid}
                                                commissionPlan={
                                                    commissionPlanItem
                                                }
                                                active={
                                                    commissionPlanItem.active
                                                }
                                                isLast={
                                                    index ===
                                                    commissionPlans.length - 1
                                                }
                                                onView={(uuid, active) =>
                                                    handleView(uuid, active)
                                                }
                                            ></CommissionItem>
                                        );
                                    }
                                )}
                            </Box>
                        </>
                    )}
                </Section>
            </Box>
            {editCommissions && (
                <Dialog open={true} className={clsx(classes.dialog)}>
                    <DialogContent>
                        <AddCommissions
                            title={'Add Commissions'}
                            saving={saving}
                            onSave={saveHandler}
                            onClose={() => {
                                setSaving(false);
                                setEditCommissions(false);
                            }}
                            member={props.member}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {viewCommission && (
                <Dialog open={true} className={clsx(classes.dialog)}>
                    <DialogContent>
                        <ViewCommission
                            onClose={() => {
                                setViewCommission(undefined);
                            }}
                            active={activeSelected}
                            commissionPlan={
                                commissionPlans.find(
                                    commission =>
                                        commission.uuid === viewCommission
                                )!
                            }
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default Commissions;
