import React, { FunctionComponent, useEffect, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Moment } from "moment-timezone";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { NewBookingAlert } from "@spike/new-booking-model";
import { AlertNewBooking } from "../UI/AlertNewBooking";
import { Option } from "@spike/model";
import { useMasterData } from "hooks";
import { Switch } from "components/UI";
import SelectAppointmentFrequency from "./SelectAppointmentFrequency";
import PeriodSelectorSection from "./DateSelectorSection/PeriodSelectorSection";

interface PeriodSelectorProps {
    from: Moment;
    to: Moment;
    isRecurrent: boolean;
    duration?: Option<string>;
    frequency?: Option<string>;
    frequencyOptions: Array<Option<string>>;
    alerts?: Array<NewBookingAlert>;
    readOnly?: boolean;
    onChangeFrequencyDuration: (duration: Option<string> | undefined, frequency: Option<string>) => void;
    onChangeRecurrent: (isRecurrent: boolean) => void;
    onChangePeriod: (from: Moment, to: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderBottom: "1px solid #D4D4D4"
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "16px",
            marginBottom: 8,
            [theme.breakpoints.up('md')]: {
                marginBottom: 10
            },
        },
        recurring: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "16px",
            paddingBottom: 18,
            marginRight: 9,
            width: "auto",
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                paddingBottom: 20,
                marginRight: 10
            },
        },
        switch: {
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            },
        },
        selectField: {
            height: "54px !important",
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        headerContainer: {
            display: "flex",
            alignItems: "center"
        },
        alert: {
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            },
        },
        alertText: {
            fontSize: 14,
            lineHeight: "20px"
        },
        dateSelectorContainer: {
            display: "flex",
            flexDirection: "column",
        }
    })
);

export const PeriodSelector: FunctionComponent<PeriodSelectorProps> = (props) => {
    const classes = useStyles();
    const masterData = useMasterData();
    const ref = useRef<HTMLDivElement>(null);

    const onChangeFrequency = (value: Option<string> | undefined) => {
        const scheduleDuration = masterData.scheduleDuration.find((sd) => sd.id === "one_year");

        if (value !== undefined) {
            props.onChangeFrequencyDuration(scheduleDuration, value);
        };
    };

    useEffect(() => {
        ref?.current?.scrollIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, props.readOnly]);

    return (
        <Box className={classes.container}>
            <div ref={ref}>
                <Typography className={classes.title}>Date & Time</Typography> 
            </div>
            <Box className={classes.dateSelectorContainer}>
                <PeriodSelectorSection
                    from={props.from}
                    to={props.to}
                    onSelectPeriod={props.onChangePeriod} 
                />
                <Box className={classes.headerContainer}>
                    <Typography className={classes.recurring}>
                        Recurring
                    </Typography> 
                    <Switch 
                        id="booking_button_frequency"
                        checked={props.isRecurrent} 
                        className={classes.switch}
                        onChange={() => props.onChangeRecurrent(!props.isRecurrent)} 
                    />  
                </Box>
            </Box>
            
            {props.isRecurrent && <SelectAppointmentFrequency 
                placeholder="Frequency"
                options={props.frequencyOptions}
                selectedOption={props.frequency} 
                className={classes.selectField} 
                onSelect={onChangeFrequency} 
            />}
            {props.alerts?.map(alert => 
                <Box 
                    key={`booking_div_${alert.alertType}_alert`}
                    id={`booking_div_${alert.alertType}_alert`}
                >
                    <AlertNewBooking
                        className={classes.alert}
                        bWidth="2px"
                        icon={faExclamationTriangle}
                        iconSize="lg"
                        iconColor="#EAB464"
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                    >
                        <Typography className={classes.alertText}>{alert.message}</Typography>
                    </AlertNewBooking>
                </Box>
            )}
        </Box>
    )
};

export default PeriodSelector;