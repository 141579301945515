import { status } from "@spike/appointment-model";

export const getUndoStatus = (statusid: number) => {
    let statusReturn: number = status.CHECKED_IN;

    switch (statusid) {
        case status.CHECKED_IN:
            statusReturn = status.CONFIRMED;
            break;
        case status.IN_PROGRESS:
            statusReturn = status.CHECKED_IN;
            break;
        case status.READY_FOR_CHECK_OUT:
            statusReturn = status.IN_PROGRESS;
            break;
        default:
            break;
    }

    return statusReturn;
}