import { FunctionComponent } from 'react';
import { Option } from '@spike/model';
import { Radio } from 'components/UI';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { usePricingContext } from '../PricingContext/PricingContext';

export interface ServiceTypeSelectorProps {
    error?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: 24,
            [theme.breakpoints.down('sm')]: {
                gap: 24,
                display: 'flex',
                flexDirection: 'column'
            }
        },
        radio: {
            '& .MuiRadio-root': {
                [theme.breakpoints.down('sm')]: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        }
    })
);

const SELECTOR_ID = 'services_add_service_pricing_pricetype';

export const ServiceTypeSelector: FunctionComponent<ServiceTypeSelectorProps> = props => {
    const classes = useStyles();
    const { selectedPricingOption, pricingOptions } = usePricingContext().state;
    const { updateSelectedPricingOption } = usePricingContext();

    const changeHandler = (value: string) => {
        const selectedOption = pricingOptions.find(option => option.id === value);
        if (selectedOption) {
            updateSelectedPricingOption(selectedOption);
        }
    };

    return (
        <Box className={classes.root}>
            {pricingOptions?.map((option: Option<string>, index: number) => (
                <Radio
                    id={`${SELECTOR_ID}_${option.id}_${index}`}
                    key={`${SELECTOR_ID}_${option.id}_${index}`}
                    label={option.name}
                    className={classes.radio}
                    checked={option.id === selectedPricingOption.id}
                    onChange={() => changeHandler(option.id)}
                />
            ))}
        </Box>
    );
};

export default ServiceTypeSelector;
