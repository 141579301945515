import React, { FunctionComponent, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { StaffMember } from "../../model/Service";
import StaffRemove from "./StaffRemove";
import StaffAdd from "./StaffAdd";

interface Props {
  staff: Array<StaffMember>;
  serviceStaff: Array<StaffMember>;
  onDelete: (staffId: number) => void;
  onAdd: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      height: "fit-content",
    },
  })
);

export const StaffDropdown: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showDeleteView, setShowDeleteView] = useState(true);

  return (
    <Box className={classes.container}>
      {showDeleteView ? (
        <StaffRemove
          serviceStaff={props.serviceStaff}
          onDelete={props.onDelete}
          onAdd={() => {
            setShowDeleteView(false);
          }}
        />
      ) : (
          <StaffAdd
            staff={props.staff.filter(
              (service) =>
                !props.serviceStaff.map((s) => s.id).includes(service.id)
            )}
            onAdd={props.onAdd}
          />
        )}
    </Box>
  );
};

export default StaffDropdown;
