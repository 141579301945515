import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PropsWithChildren } from "react";

const downRight = "down-right";
const downLeft = "down-left";
const upRight = "up-right";
const upLeft = "up-left";

interface Props extends PropsWithChildren {
  className?: string;
  direction?: typeof downRight | typeof downLeft | typeof upRight | typeof upLeft;
  onCollapse: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      display: "flex",
      zIndex: 300,
      flexDirection: "column",
      border: "1px solid #222222",
      background: "#FFFFFF",
      [theme.breakpoints.down("lg")]: {
        borderRadius: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "18px",
        paddingLeft: "18px",
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "20px",
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingRight: "24px",
        paddingLeft: "24px",
      },
    },
    downRight: {
      top: "0px",
      left: "0px",
    },
    downLeft: {
      bottom: "0px",
      right: "0px",
    },
    upRight: {
      bottom: "0px",
      left: "0px",
    },
    upLeft: {
      bottom: "0px",
      right: "0px",
    },
  })
);

export const Dropdown: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const containerStyle = clsx(
    classes.container,
    {
      [classes.downRight]: !props.direction || props.direction === downRight,
      [classes.downLeft]: props.direction === downLeft,
      [classes.upRight]: props.direction === upRight,
      [classes.upLeft]: props.direction === upLeft,
    },
    props.className);

  return (
    <ClickAwayListener onClickAway={props.onCollapse}>
      <Box className={containerStyle}>
        {props.children}
      </Box>
    </ClickAwayListener>
  );
};

export default Dropdown;
