import { Action } from "redux";

//Types
export const ADD_NOTIFICATION_BELL_ACTION_TYPE =
  "ADD_NOTIFICATION_BELL_ACTION_TYPE";
export const REMOVE_NOTIFICATION_BELL_ACTION_TYPE =
  "REMOVE_NOTIFICATION_BELL_ACTION_TYPE";
export const SET_NOTIFICATION_BELL_ACTION_TYPE =
  "SET_NOTIFICATION_BELL_ACTION_TYPE";
export const RESET_NOTIFICATION_BELL_ACTION_TYPE =
  "RESET_NOTIFICATION_BELL_ACTION_TYPE";

interface NotificationBellAddAction
  extends Action<typeof ADD_NOTIFICATION_BELL_ACTION_TYPE> {
  payload?: number;
}

interface NotificationBellSetAction
  extends Action<typeof SET_NOTIFICATION_BELL_ACTION_TYPE> {
  payload: number;
}
interface NotificationBellRemoveAction
  extends Action<typeof REMOVE_NOTIFICATION_BELL_ACTION_TYPE> {
  payload?: number;
}
interface NotificationBellResetAction
  extends Action<typeof RESET_NOTIFICATION_BELL_ACTION_TYPE> {}

//Notification Actions Types
export type NotificationBellAction =
  | NotificationBellAddAction
  | NotificationBellRemoveAction
  | NotificationBellSetAction
  | NotificationBellResetAction;
