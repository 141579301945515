import { Grid } from "@material-ui/core";
import { FunctionComponent } from "react";
import Title from "./Title";
import { PropsWithChildren } from "react";

interface SectionProps extends PropsWithChildren {
  title: string | JSX.Element;
  className?: string;
}

export const Section: FunctionComponent<SectionProps> = (props) => {
  return (
    <Grid container className={props.className}>
      <Grid container>{typeof props.title === "string" ? <Title title={props.title} /> : props.title}</Grid>
      <Grid container>
        <>{props.children}</>
      </Grid>
    </Grid>
  );
};

export default Section;
