import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { CSVLink } from 'react-csv';
import ExportButton from './ExportButton';

interface ExportCSVProps {
    headers: Array<string>;
    records: Array<Array<string>>;
    filename: string;
    exporting: boolean;
    onClick?: () => void;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        csv: {
            'textDecoration': 'none',
            '& button': {
                [theme.breakpoints.down('sm')]: {
                    'height': 39,
                    'width': 39,
                    'alignItems': 'center',
                    'display': 'flex',
                    'justifyContent': 'center',

                    'marginLeft': 10,
                    '& svg': {
                        width: 16,
                        height: 16
                    }
                }
            }
        }
    })
);

const ExportCSV = forwardRef<any, ExportCSVProps>((props, ref) => {
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
        export: () => {
            csvLinkRef.current?.link.click();
        }
    }));

    const csvLinkRef = useRef<any>(null);

    return (
        <>
            <CSVLink
                headers={props.headers}
                data={props.records}
                enclosingCharacter=""
                separator=";"
                filename={props.filename}
                target="_blank"
                className={classes.csv}
                ref={csvLinkRef}
            ></CSVLink>
            <ExportButton exporting={props.exporting} onClick={props.onClick} />
        </>
    );
});

export default ExportCSV;
