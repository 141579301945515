export const AMPLITUDE = {
    TOOLBAR_BOOKINGS: "Toolbar-Bookings",
    CTA_ADD_APPOINTMENT: "CTA-Add-Appointment",
    LOAD_CLIENT_PETS: "Load-Client-Pets",
    LOAD_CLIENT_HAS_NO_PETS: "Load-Client-Has-No-Pets",
    CHECK_PETS: "Check-Pets",
    LOAD_SERVICES: "Load-Services",
    CHECK_SERVICES: "Check-Services",
    LOAD_DATE_AND_TIME: "Load-Date-and-Time",
    CTA_NEXT_WEEK: "CTA-Next-Week",
    CTA_PREVIOUS_WEEK: "CTA-Previous-Week",
    CTA_MORNING: "CTA-Morning",
    CTA_AFTERNOON: "CTA-Afternoon",
    CTA_CUSTOM_TIME: "CTA-Custom-Time",
    CTA_SELECT_TIME: "CTA-Select-Time",
    LOAD_STAFF: "Load-Staff",
    LOAD_FINAL_INFORMATION: "Load-Final-Information",
    CTA_BOOK_APPOINTMENT: "CTA-Book-Appointment",
    LOAD_APPOINTMENT_BOOKED: "Load-Appointment-Booked",
    LOAD_APPOINTMENT_NOT_BOOKED_ERROR: "Load-Appointment-Not-Booked-Error",
    ENABLE_BOOKING_DEPOSIT: "Enable-Booking-Deposit",
    DISABLE_BOOKING_DEPOSIT: "Disable-Booking-Deposit",
    CTA_PAY_AND_BOOK_APPOINTMENT: "CTA-Pay-&-Book-Appointment",
    LOAD_COLLECT_DEPOSIT_PAYMENT_METHOD: "Load-Collect-Deposit-Payment-Method",
    CTA_CASH: "CTA-Cash",
    CTA_POS: "CTA-POS",
    CTA_CREDIT_CARD: "CTA-Credit-Card",
    CTA_OTHER: "CTA-Other",
    PAYMENT_CONFIRMATION_DEPOSIT: "Payment-Confirmation-Deposit",
    CTA_BOOK_APPOINTMENT_CLIENT_PROFILE: "CTA-Book-Appointment-Client-Profile",
    CTA_BOOK: "CTA-Book",
    CTA_ADD_APPOINTMENT_CALENDAR: "CTA-Add-Appointment-Calendar",
    TOOLBAR_CALENDAR: "Toolbar-Calendar",
    LOAD_CLIENT_HAS_NO_PETS_CALENDAR: "Load-Client-Has-No-Pets-Calendar",
    LOAD_CLIENT_PETS_CALENDAR: "Load-Client-Pets-Calendar",
    LOAD_SERVICES_CALENDAR: "Load-Services-Calendar",
    SELECT_SERVICE_CALENDAR: "Select-Service-Calendar",
    LOAD_DATE_AND_TIME_CALENDAR: "Load-Date-and-Time-Calendar",
    CTA_NEXT_WEEK_CALENDAR: "CTA-Next-Week-Calendar",
    CTA_PREVIOUS_WEEK_CALENDAR: "CTA-Previous-Week-Calendar",
    CTA_SELECT_TIME_CALENDAR: "CTA-Select-Time-Calendar",
    LOAD_ADD_APPOINTMENT_RIGHT_PANEL_CALENDAR: "Load-Add-Appointment-Right-Panel-Calendar",
    LOAD_STAFF_CALENDAR: "Load-Staff-Calendar",
    SELECT_STAFF_BOOKING_CALENDAR: "Select-Staff-Booking-Calendar",
    LOAD_FINAL_INFORMATION_CALENDAR: "Load-Final-Information-Calendar",
    CTA_BOOK_APPOINTMENT_CALENDAR: "CTA-Book-Appointment-Calendar",
    LOAD_APPOINTMENT_BOOKED_CALENDAR: "Load-Appointment-Booked-Calendar",
    ENABLE_BOOKING_DEPOSIT_CALENDAR: "Enable-Booking-Deposit-Calendar",
    DISABLE_BOOKING_DEPOSIT_CALENDAR: "Disable-Booking-Deposit-Calendar",
    CTA_PAY_AND_BOOK_APPOINTMENT_CALENDAR: "CTA-Pay-&-Book-Appointment-Calendar",
    CLIENT_LOAD_COLLECT_DEPOSIT_PAYMENT_METHOD: "Client-Load-Collect-Deposit-Payment-Method",
    CTA_CONFIRM_APPOINTMENT_BOOKING_LIST: "CTA-Confirm-Appointment-Booking-List",
    LOAD_CONFIRM_APPOINTMENT_MODAL: "Load-Confirm-Appointment-Modal",
    CTA_CONFIRM_APPOINTMENT: "CTA-Confirm-Appointment",
    BOOK_APPOINTMENT_CONFIRMED: "Book-Appointment-Confirmed",
    CTA_RESCHEDULE_APPOINTMENT: "CTA-Reschedule-Appointment",
    CTA_CANCEL_APPOINTMENT: "CTA-Cancel-Appointment",
    CTA_YES_CANCEL_APPOINTMENT: "CTA-Yes-Cancel-Appointment",
    CTA_NO_CANCEL_APPOINTMENT: "CTA-No-Cancel-Appointment",
    CTA_CHECK_IN_APPOINTMENT_BOOKING_LIST: "CTA-Check-In-Appointment-Booking-List",
    CTA_START_APPOINTMENT_BOOKING_LIST: "CTA-Start-Appointment-Booking-List",
    CTA_COMPLETE_APPOINTMENT_BOOKING_LIST: "CTA-Complete-Appointment-Booking-List",
    CTA_CHECK_OUT_APPOINTMENT_BOOKING_LIST: "CTA-Check-Out-Appointment-Booking-List",
    CTA_SKIP_REPORT: "CTA-Skip-Report",
    CTA_SAVE_REPORT: "CTA-Save-Report",
    LOAD_CHECK_OUT_APPOINTMENT: "Load-Check-Out-Appointment",
    CTA_PAY_CHECK_OUT: "CTA-Pay-Check-Out",
    CTA_ADD_PRODUCT_CHECK_OUT: "CTA-Add-Product-Check-Out",
    CTA_REMOVE_PRODUCT_CHECK_OUT: "CTA-Remove-Product-Check-Out",
    CTA_ADD_ADDON_SERVICE_CHECK_OUT: "CTA-Add-Addon-Service-Check-Out",
    CTA_REMOVE_ADDON_SERVICE_CHECK_OUT: "CTA-Remove-Addon-Service-Check-Out",
    LOAD_PAYMENT_METHOD_CHECK_OUT: "Load-Payment-Method-Check-Out",
    CTA_CASH_CHECK_OUT: "CTA-Cash-Check-Out",
    CTA_POS_CHECK_OUT: "CTA-POS-Check-Out",
    CTA_CREDIT_CARD_CHECK_OUT: "CTA-Credit-Card-Check-Out",
    CTA_OTHER_CHECK_OUT: "CTA-Other-Check-Out",
    CTA_CLOSE_PAYMENT_METHODS: "CTA-Close-Payment-Methods",
    PAYMENT_CONFIRMATION_CHECK_OUT: "Payment-Confirmation-Check-Out",
    CTA_CONFIRM_APPOINTMENT_CLIENT: "CTA-Confirm-Appointment-Client",
    CTA_BOOKING_DETAILS_CLIENT_HISTORY: "CTA-Booking-Details-Client-History",
    CTA_CHECK_IN_APPOINTMENT_BOOKING_CLIENT: "CTA-Check-In-Appointment-Booking-Client",
    CTA_START_APPOINTMENT_BOOKING_CLIENT: "CTA-Start-Appointment-Booking-Client",
    CTA_COMPLETE_APPOINTMENT_BOOKING_CLIENT: "CTA-Complete-Appointment-Booking-Client",
    CTA_CONFIRM_APPOINTMENT_PET: "CTA-Confirm-Appointment-Pet",
    CTA_BOOKING_DETAILS_PET_HISTORY: "CTA-Booking-Details-Pet-History",
    CTA_CHECK_IN_APPOINTMENT_BOOKING_PET: "CTA-Check-In-Appointment-Booking-Pet",
    CTA_START_APPOINTMENT_BOOKING_PET: "CTA-Start-Appointment-Booking-Pet",
    CTA_COMPLETE_APPOINTMENT_BOOKING_PET: "CTA-Complete-Appointment-Booking-Pet",
    CTA_CONFIRM_APPOINTMENT_CALENDAR: "CTA-Confirm-Appointment-Calendar",
    CTA_BOOKING_DETAILS_WEEKLY_CALENDAR: "CTA-Booking-Details-Weekly-Calendar",
    CTA_BOOKING_DETAILS_DAILY_CALENDAR: "CTA-Booking-Details-Daily-Calendar",
    CTA_CHECK_IN_APPOINTMENT_BOOKING_DETAILS: "CTA-Check-In-Appointment-Booking-Details",
    CTA_START_APPOINTMENT_BOOKING_DETAILS: "CTA-Start-Appointment-Booking-Details",
    CTA_COMPLETE_APPOINTMENT_BOOKING_DETAILS: "CTA-Complete-Appointment-Booking-Details",
    CTA_WEEKLY_CALENDAR: "CTA-Weekly-Calendar",
    CTA_DAILY_CALENDAR: "CTA-Daily-Calendar"
};
