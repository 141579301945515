import { useEffect } from 'react';

//Enable debug
export const enableStonlyDebug = () => {
    if (window.StonlyWidget) {
        window.StonlyWidget('enableDebug');
    }
};

// Function to identify a user in Stonly
export const identifyUserInStonly = (userId: string, customProperties: Record<string, any>) => {
    if (window.StonlyWidget) {
        if(customProperties) {
            window.StonlyWidget('identify', userId, customProperties);
        }else{
            window.StonlyWidget('identify', userId);
        }
        
    }
};

// Function to track an event in Stonly
export const trackEventInStonly = (eventName: string) => {
    if (window.StonlyWidget) {
        window.StonlyWidget('track', eventName);
    }
};

// Cleanup session and deidentify user
export const deidentifyUser = () => {
    if (window.StonlyWidget) {
        window.StonlyWidget('cleanup');
    }
};

// Custom hook
export const useStonlyTracking = () => {
    const identifyUser = (userId: string, customProperties: Record<string, any>) => {
        useEffect(() => {
            identifyUserInStonly(userId, customProperties);
        }, [userId, customProperties]);
    };

    const trackEvent = (eventName: string) => {
        useEffect(() => {
            trackEventInStonly(eventName);
        }, [eventName]);
    };

    return { identifyUser, trackEvent };
};
