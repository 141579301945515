import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { PetTypeChip } from 'components/UI';
import Pet from '@spike/pet-model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

interface PetHeaderProps {
	pet: Pet;
	extended?: boolean;
	className?: string;
	onShowPetCard?: () => void;
}

export declare enum PetTypeId {
	DOG = 'dog',
	CAT = 'cat',
	EXOTIC = 'exotic'
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center'
		},
		petContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		heartIcon: {
			color: '#EF4F57',
			width: '30px !important'
		},
		listAlignedIcons: {
			width: '25px ',
			height: '25px ',
			[theme.breakpoints.down('sm')]: {
				width: '20px !important',
				height: '20px !important'
			}
		},
		petHeader: {
			display: 'flex',
			flexDirection: 'column'
		},
		petPopUp: {
			cursor: 'default'
		},
		petCard: {
			'cursor': 'pointer',
			'&:hover': {
				textDecoration: 'underline'
			}
		},
		petResume: {
			fontSize: '0.9rem'
		},
		petBoxResume: {
			display: 'flex',
			flexDirection: 'row',
			padding: '10px 0'
		},
		sectionIcon: {
			width: '400px',
			marginRight: '0.5rem'
		},
		typoPetName: {
			fontWeight: 600,
			fontSize: '28px',
			fontFamily: 'YoungSerif',
			[theme.breakpoints.down('sm')]: {
				fontSize: '24px !important'
			}
		},
		dialog: {
			'& .MuiDialogContent-root': {
				padding: '0px'
			}
		},
		boxFlexRow: {
			display: 'flex',
			flexDirection: 'row'
		},
		boxFlexColumn: {
			display: 'flex',
			flexDirection: 'column'
		},
		iconFace: {
			padding: '0',
			[theme.breakpoints.only('xl')]: {
				paddingTop: '3px'
			}
		},
		itemText: {
			fontSize: '0.9rem'
		},
		boxIcon: {
			width: '28px'
		},
		boxHeart: {
			marginTop: '2px'
		}
	})
);

export const PetHeader: FunctionComponent<PetHeaderProps> = props => {
	const classes = useStyles(props);

	// const [petType, setPetType] = useState<"dog" | "cat" | "exotic">("dog");

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const iconSVG = (icon: IconDefinition, styles: string, size: any) => {
		return <FontAwesomeIcon icon={icon} className={styles} size={size} />;
	};

	// useEffect(() => {
	//   //console.log(props.pet)
	//   if (props.pet!.type!.singular === "Cat") {
	//     setPetType("cat");
	//   }
	//   if (props.pet!.type!.singular === "Dog") {
	//     setPetType("dog");
	//   }
	//   if (props.pet!.type!.singular === "Exotic") {
	//     setPetType("exotic");
	//   }
	// }, [props.pet]);

	const checkMedicalCondition = () => {
		let checkMedical = false;

		try {
			if (
				props.pet?.medicalHistory.medicalConditions.length === 1 &&
				props.pet?.medicalHistory.medicalConditions[0].categoryName !==
					'None'
			) {
				checkMedical = true;
			}

			if (
				props.pet?.medicalHistory.medicalConditions &&
				props.pet?.medicalHistory.medicalConditions.length > 1
			) {
				checkMedical = true;
			}
		} catch (errorMedical) {
			checkMedical = false;
		}
		return checkMedical;
	};

	const petCardHandler = () => {
		props.onShowPetCard && props.onShowPetCard();
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.petHeader}>
				<Box
					onClick={petCardHandler}
					className={clsx(classes.petPopUp, classes.boxFlexRow, {
						[classes.petCard]: props.extended
					})}
				>
					<Typography className={classes.typoPetName}>
						{props.pet?.name}
					</Typography>
				</Box>
				<Box className={clsx(classes.boxFlexRow)}>
					<Box>
						<PetTypeChip petType={props.pet!.type!} />
					</Box>
					<Box className={clsx(classes.boxHeart)}>
						{checkMedicalCondition() &&
							iconSVG(faHeartbeat, `${classes.heartIcon}`, 'md')}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default PetHeader;
