import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Spinner } from "components/UI";
import { FunctionComponent, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { reduceResolution, wbp } from "Theme";

interface PdfViewProps {
  url: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    document: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    page: {
      border: "1px solid #7A7A7A",
      [theme.breakpoints.down(wbp)]: {
        margin: `${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        margin: "40px",
      },
    },
    spinner: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(100)}px`,
        marginBottom: `${reduceResolution(100)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "100px",
        marginBottom: "100px",
      },
    },
  })
);
export const PdfView: FunctionComponent<PdfViewProps> = (props) => {
  const classes = useStyles();

  const [totalPages, setTotalPages] = useState(0);

  return (
    <Document
      file={{ url: props.url }}
      onLoadSuccess={(pdf) => {
        setTotalPages(pdf.numPages);
      }}
      loading={<Spinner className={classes.spinner} />}
      className={clsx(classes.document, props.className)}
    >
      {Array(totalPages)
        .fill(1)
        .map((p, index) => (
          <Page pageNumber={index + 1} className={classes.page} />
        ))}
    </Document>
  );
};

export default PdfView;
