import { useMediaQuery, useTheme } from '@material-ui/core';

export const mobileBreakpoint = 'sm';

export const useIsMobile = (): boolean => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
};

export default useIsMobile;
