import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { ReportItem, ReportItemType } from '../SalesReportsModel';
import { Item } from './Item';

interface SectionProps {
	title: string;
	icon: IconDefinition;
	items: Array<ReportItem>;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		section: {
			width: '100%',
			display: 'flex',
			borderRadius: 12,
			padding: '0px 16px 24px',
			flexDirection: 'column',
			backgroundColor: 'white',
			border: '1px solid #D4D4D4',

			[theme.breakpoints.up('md')]: {
				borderRadius: 26,
				padding: '0px 24px 26px 24px'
			}
		},
		header: {
			height: 62,
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'white',
			borderBottom: '1px solid black',

			[theme.breakpoints.up('md')]: {
				height: 72
			}
		},
		icon: {
			color: '#000',
			fontSize: 20,
			lineHeight: 1,
			marginRight: 6,

			[theme.breakpoints.up('md')]: {
				fontSize: 22
			}
		},
		title: {
			color: '#222',
			fontSize: 18,
			lineHeight: 1,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontSize: 20
			}
		}
	})
);

export const Section: FunctionComponent<SectionProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.section, props.className)}>
			<Box className={classes.header}>
				<FontAwesomeIcon icon={props.icon} className={classes.icon} />
				<Typography className={classes.title}>{props.title}</Typography>
			</Box>
			<Box>
				{props.items.map((item, index) => (
					<Item
						key={item.key}
						item={item}
						topLine={
							props.items[index - 1]?.type ===
								ReportItemType.SUBITEM &&
							item.type !== ReportItemType.SUBITEM
						}
						bottomLine={
							index > 0 &&
							props.items[index + 1]?.type !==
								ReportItemType.TOTAL
						}
						loading={props.loading}
					/>
				))}
			</Box>
		</Box>
	);
};

export default Section;
