import {
    Box,
    Grid,
    Theme,
    createStyles,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Button, PriceField2, TextField2 } from 'components/UI';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddOnService from '@spike/addon-service-model';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import { saveThunk } from '@spike/addon-services-action';
import { AddOnServicesStatus } from '@spike/addon-services-action';
import { RootState } from 'store';
import useNonInitialEffect from '@versiondos/hooks';
import { FieldError } from '@spike/model';
import { validate } from './Validations';
import { MarketplaceTax } from '@spike/marketplace-model';
import { TaxesSelector } from 'components/UI/TaxesSelector';
import { useApiClientWrapper } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

interface AddOnServiceFormProps {
    service: AddOnService;
    onSaved?: (editedService: AddOnService) => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'baseline',
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center'
                //alignItems: "center",
            }
        },
        form: {
            'width': '100%',
            'borderRadius': 12,
            'padding': '20px 15px 30px 15px',
            [theme.breakpoints.up('sm')]: {
                //border: "solid 1px #D3D3D3",
                //padding: "42px 30px 30px 25px",
                paddingLeft: 55
            },

            '& h2': {
                margin: 0,
                color: '#000',
                fontSize: 18,
                lineHeight: 1,
                fontWeight: 500,
                marginBottom: 20,
                letterSpacing: 0
            }
        },
        description: {
            margin: 0,
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '19.6px',
            [theme.breakpoints.up('sm')]: {
                fontSize: 15,
                color: '7A7A7A'
            }
        },
        formHeader: {
            marginBottom: 30
        },
        formFooter: {
            'width': '100%',
            'marginTop': 35,
            'display': 'flex',
            'justifyContent': 'center',
            [theme.breakpoints.down('sm')]: {
                visibility: 'hidden'
            },
            '& button': {
                'height': 47,
                'padding': '0px 21px',
                [theme.breakpoints.up('sm')]: {
                    padding: '0px 42px'
                },

                '& .MuiButton-label': {
                    fontSize: 16
                }
            }
        },
        taxesContainer: {
            'marginTop': '25px',
            '& .MuiFormControlLabel-label': {
                '& p': {
                    fontSize: 16,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '207px',
                    fontWeight: '500'
                }
            },
            '& .subtitle': {
                fontSize: 14
            }
        },
        backButton: {
            marginRight: 10,
            cursor: 'pointer'
        },
        titleButtonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24,

            [theme.breakpoints.down('lg')]: {
                paddingLeft: 16,
                paddingRight: 16
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
                //marginTop: '76px',
                marginBottom: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '102px',
                marginBottom: '27px'
            }
            /*      [theme.breakpoints.up('sm')]: {
        visibility: 'hidden'
      },*/
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center'

            /* [theme.breakpoints.up('md')]: {
   width: '50%'
 }*/
        },
        title: {
            fontSize: 20,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: 27
            }
        },
        labelContainer: {
            '& > div': {
                '&:first-child': {
                    [theme.breakpoints.up('md')]: {
                        paddingLeft: '0 !important'
                    }
                }
            }
        },
        mobileSave: {
            'order': 5,
            'width': '100%',
            'padding': '0px 16px',
            'marginTop': 5,
            'position': 'fixed',
            'marginBottom': 10,
            'bottom': 10,
            'marginLeft': '-13px',

            '& button': {
                'height': 55,
                'width': '100%',
                'borderRadius': 30,

                '& span': {
                    fontSize: 16,
                    fontWeight: 600
                }
            },

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        saveButton: {
            [theme.breakpoints.down('lg')]: {
                width: '127px',
                height: '36px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '170px',
                height: '49px'
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        checkIcon: {
            justifyContent: 'center',
            color: '#5E8677',
            [theme.breakpoints.down('lg')]: {
                fontSize: '82px',
                marginBottom: '7px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '110px',
                marginBottom: '10px'
            }
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '0px 16px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        button: {
            [theme.breakpoints.down('lg')]: {
                minWidth: '111px',
                height: '55px',
                fontSize: '13px',
                marginBottom: '32px',
                borderRadius: 30
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: '148px',
                height: '57px',
                fontSize: '18px',
                marginBottom: '43px'
            }
        },
        buttonServices: {
            'backgroundColor': 'black',
            '&:hover': {
                backgroundColor: '#92B4A7'
            }
        },
        descriptionSuccess: {
            textAlign: 'center',
            width: '100%',
            minWidth: '400px',
            [theme.breakpoints.down('lg')]: {
                fontSize: '16px',
                marginBottom: '34px',
                paddingRight: 20,
                paddingLeft: 20,
                maxWidth: '80%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '30px',
                marginBottom: '45px'
            }
        },
        subtitle: {
            fontSize: 16,
            fontWeight: 600
        },
        containerPanel: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: 30
            }
        }
    })
);

const AddOnServiceFrom: FunctionComponent<AddOnServiceFormProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const servicesStatus = useSelector<RootState, AddOnServicesStatus>(
        state => state.addOnServices.status
    );
    const [editedService, setEditedService] = useState<AddOnService>(
        props.service
    );
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    const [isTaxableService, setIsTaxableService] = useState(
        props.service.taxes.length > 0
    );
    const [selectedTaxes, setSelectedTaxes] = useState<Array<MarketplaceTax>>(
        props.service.taxes
    );
    const [isServiceTaxSelected, setIsServiceTaxSelected] = useState(false);

    useNonInitialEffect(() => {
        switch (servicesStatus) {
            case AddOnServicesStatus.SaveSuccess:
                setSaving(false);
                props.onSaved && props.onSaved(editedService);
                break;
            case AddOnServicesStatus.Error:
                setSaving(false);
        }
    }, [servicesStatus]);

    const changeValueHandler = (value: any, fieldName?: string) => {
        if (fieldName) {
            setEditedService(prev => {
                const updatedContact = { ...editedService };
                set(updatedContact, fieldName, value);
                return updatedContact;
            });
        }
    };

    const taxSelectionHanddler = (taxes: Array<MarketplaceTax>): void => {
        setSelectedTaxes(taxes);
        changeValueHandler(taxes, 'taxes');
        setIsTaxableService(taxes.length > 0);
    };

    const handleTaxesClick = () => {
        setIsServiceTaxSelected(!isServiceTaxSelected);
    };

    const saveHandler = () => {
        const errors = validate(editedService);

        if (isServiceTaxSelected && selectedTaxes.length == 0) {
            errors.push({
                fieldName: 'taxes',
                errorMessage: 'Please select at least one tax'
            });
        }

        setErrors(errors);

        if (errors.length === 0) {
            if (isEqual(props.service, editedService)) {
                //props.onClose && props.onClose();
            } else {
                setSaving(true);
                dispatch(saveThunk(apiClientWrapper, editedService));
            }
        }
    };

    const header = (
        <Fragment>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            onClick={() => props.onClose && props.onClose()}
                            className={classes.backButton}
                        />

                        {editedService.id
                            ? 'Editing Add-On Service'
                            : 'Adding Add-On Service'}
                    </Typography>
                </Box>
            </Box>
        </Fragment>
    );

    return (
        <>
            {
                <Box className={classes.container}>
                    <Grid container className={classes.containerPanel}>
                        <Grid item xs={12} md={7}>
                            {header}
                            <Box className={classes.form}>
                                <Box className={classes.formHeader}>
                                    <p className={classes.subtitle}>
                                        Add-On Details
                                    </p>
                                    <p className={classes.description}>
                                        Add-On services can be added to invoices
                                        if pet(s) need extra care after primary
                                        service. This service type does not
                                        affect appointment times.
                                    </p>
                                </Box>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <TextField2
                                            label="Service Name"
                                            required={true}
                                            value={editedService.name}
                                            placeholder="Ex. 15 Minutes Dematting"
                                            name="name"
                                            errors={errors}
                                            className={classes.labelContainer}
                                            onChange={changeValueHandler}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <PriceField2
                                            label="Price"
                                            required={true}
                                            value={
                                                editedService.price == 0
                                                    ? undefined
                                                    : editedService.price
                                            }
                                            placeholder="0.00"
                                            name="price"
                                            errors={errors}
                                            className={classes.labelContainer}
                                            onChange={changeValueHandler}
                                            scale={2}
                                        />
                                    </Grid>
                                </Grid>
                                <TaxesSelector
                                    checked={
                                        editedService.taxes.length == 0
                                            ? false
                                            : true
                                    }
                                    className={classes.taxesContainer}
                                    selectedTaxes={editedService.taxes}
                                    name="taxes"
                                    title="Taxable Service"
                                    subtitle="Choose the applicable tax rate for this service."
                                    onChange={taxSelectionHanddler}
                                    onTaxClick={handleTaxesClick}
                                    errors={errors.filter(
                                        error => error.fieldName === 'taxes'
                                    )}
                                ></TaxesSelector>
                                <Box className={classes.formFooter}>
                                    <Button
                                        size="large"
                                        color="orange"
                                        label={
                                            editedService.id ? 'Update' : 'Save'
                                        }
                                        onClick={saveHandler}
                                        loading={saving}
                                    />
                                </Box>
                                <Box className={classes.mobileSave}>
                                    <Button
                                        id="service_add_on_button_save"
                                        label="Save"
                                        onClick={saveHandler}
                                        className={classes.saveButton}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
};

export default AddOnServiceFrom;
