import React, { FunctionComponent, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";

interface Props {
  title: string;
  onChange?: (expanded: boolean) => void;
  expandable?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#ffffff",
      border: "1px solid #D3D3D3",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("lg")]: {
        borderRadius: "28px",
        height: "32px",
        paddingLeft: "12px",
        paddingRight: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "37px",
        height: "42px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    title: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
    },
    icon: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
        marginLeft: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
        marginLeft: "20px",
      },
    }
  })
);

export const PriceServicesRowContent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const clickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    props.onChange && props.onChange(!expanded);
    setExpanded((prev) => {
      return !prev;
    });
  };

  const expandableIcon = (
    <FontAwesomeIcon
      icon={expanded ? faCaretUp : faCaretDown}
      className={classes.icon}
    />
  );

  const notExpandableIcon = (
    <FontAwesomeIcon icon={faChevronRight} className={classes.icon} />
  );

  const icon = props.expandable ? expandableIcon : notExpandableIcon;

  return (
    // Permitir hacer click sobre el variable price button
    <Box className={clsx(classes.container, props.className)} onClick={clickHandler}>
      <Typography className={classes.title}>{props.title}</Typography>
      {icon}
    </Box>
  );
};

export default PriceServicesRowContent;
