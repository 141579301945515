import { Fragment, FunctionComponent, useState } from 'react';
import { faArrowLeft, faEllipsis } from '@fortawesome/pro-regular-svg-icons';

import {
    faArchive,
    faCopy,
    faTrashAlt,
    faPen,
    faUserFriends
} from '@fortawesome/pro-regular-svg-icons';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import TooltipButton from 'components/UI/TooltipButton';
import CampaignsOverview from './CampaignOverview';
import SMSCampaign, { SMSCampaignStatus } from 'model/SMSCampaign';
import {
    cloneSMSCampaignThunk,
    deleteCampaignThunk,
    editSMSCampaignThunk,
    updateCampaignStatusThunk
} from 'actions/smsCampaigns/SMSCampaignsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { SMSCampaignsStatus } from 'reducers/smsCampaigns/SMSCampaignsState';
import { useHistory } from 'react-router-dom';
import { ConfirmDialog } from 'components/UI';
import OptionsModal from 'components/Agreements/Modal/OptionsModal';

interface CampaignDetailProps {
    selectedCampaing: SMSCampaign;
    onChangeStatus: (status: string) => void;
    onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minHeight: '100%',
            borderRadius: 20,
            position: 'relative',
            backgroundColor: '#fff',
            border: '1px solid #D4D4D4',
            [theme.breakpoints.down('md')]: {
                border: 'none',
                borderRadius: 'none'
            }
        },
        header: {
            height: 67,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '14px 17px 14px 28px',
            borderBottom: 'solid 1px #D4D4D4',
            [theme.breakpoints.down('md')]: {
                padding: 0
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none'
            }
        },
        title: {
            margin: 0,
            color: '#222',
            fontSize: 16,
            lineHeight: 1.4,
            fontWeight: 600,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('md')]: {
                fontSize: 20
            }
        },
        actions: {
            'paddingLeft': 20,
            'marginLeft': 'auto',

            '& > * + *': {
                marginLeft: 6
            }
        },
        body: {
            width: '100%',
            padding: '18px 28px',
            [theme.breakpoints.down('md')]: {
                padding: 0,
                paddingTop: 20
            }
        },
        groupLabel: {
            color: '#222',
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 13
        },
        divider: {
            border: 'none',
            margin: '18px 0px',
            borderTop: 'solid 1px #D4D4D4'
        },
        status: {
            'fontSize': 14,
            'lineHeight': 1,
            'fontWeight': 600,
            'borderRadius': 12,
            'padding': '8px 10px',
            'display': 'inline-block',
            'backgroundColor': '#FAEFDF',

            '& svg': {
                marginRight: 3
            }
        },
        message: {
            'width': '100%',
            'minHeight': 100,
            'borderRadius': 12,
            'border': '1px solid #D4D4D4',
            'padding': '15px 100px 15px 20px',
            'overflowWrap': 'break-word',
            [theme.breakpoints.down('sm')]: {
                padding: '15px 40px 15px 20px'
            },
            '& p': {
                width: '100%',
                margin: 0,
                fontSize: 13,
                lineHeight: 1.4,
                textWrap: 'wrap'
            }
        },
        loading: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255,0.4)',
            position: 'absolute'
        },
        icon: {
            width: 20,
            height: 20,
            marginRight: 10
        },
        actionBtn: {
            'width': 35,
            'height': 35,
            'borderRadius': 50,
            'background': '#f1f1f1',
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            '& svg': {
                width: 20,
                height: 20
            }
        }
    })
);

const CampaignDetail: FunctionComponent<CampaignDetailProps> = props => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const campaignDetails: SMSCampaign = useSelector(
        (state: RootState) => state.smsCampaigns.detail!
    );

    const [showDelete, setShowDelete] = useState<boolean>(false);

    const GroupLabel = ({ title }: { title: string }) => (
        <Typography className={classes.groupLabel}>{title}</Typography>
    );

    const Divider = () => <hr className={classes.divider} />;

    const StatusBadge = ({ label }: { label: string }) => (
        <Box className={classes.status}>
            <FontAwesomeIcon icon={isMobile ? faUserGroup : faUserFriends} />
            {label}
        </Box>
    );

    const CampaignMessage = () => (
        <Box className={classes.message}>
            <p>{props.selectedCampaing.message}</p>
            <br />
            <p>{props.selectedCampaing.unsubscribe_message}</p>
        </Box>
    );

    const optionsHandler = async (event: string) => {
        if (event === 'archive' && props.selectedCampaing.id) {
            await dispatch(
                updateCampaignStatusThunk(
                    props.selectedCampaing.id,
                    SMSCampaignStatus.archived
                )
            );
        }
        if (event === 'delete' && props.selectedCampaing.id) {
            setShowDelete(true);
        }
        if (event === 'duplicate') {
            await dispatch(cloneSMSCampaignThunk(props.selectedCampaing));
            history.push(`/marketing/sms/create`);
        }
        if (event === 'edit' && props.selectedCampaing.id) {
            await dispatch(editSMSCampaignThunk(props.selectedCampaing));
            history.push(`/marketing/sms/create`);
        }
    };

    const deleteConfirmHandler = async () => {
        if (campaignDetails && campaignDetails.id) {
            await dispatch(deleteCampaignThunk(campaignDetails.id));
            setShowDelete(false);
            props.onChangeStatus && props.onChangeStatus('delete');
        }
    };

    const campaignOptions = [
        {
            icon: faCopy,
            id: 'duplicate',
            title: 'Duplicate',
            disabled: !(
                campaignDetails?.status_id !== SMSCampaignStatus.scheduled
            )
        },
        {
            icon: faPen,
            id: 'edit',
            title: 'Edit',
            disabled: !(campaignDetails?.status_id === SMSCampaignStatus.draft)
        },
        {
            icon: faTrashAlt,
            id: 'delete',
            title: 'Delete',
            disabled: !(campaignDetails?.status_id === SMSCampaignStatus.draft)
        },
        {
            icon: faArchive,
            id: 'archive',
            title: 'Archive',
            disabled: !(
                campaignDetails?.status_id !== SMSCampaignStatus.archived &&
                campaignDetails?.status_id !== SMSCampaignStatus.scheduled
            )
        }
    ];

    return (
        <Fragment>
            <Box className={classes.root}>
                <Box className={classes.header}>
                    {props.onBack && (
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            onClick={() => props.onBack && props.onBack()}
                            className={classes.icon}
                        />
                    )}
                    <Typography className={classes.title}>
                        {campaignDetails?.name}
                    </Typography>
                    {!isMobile ? (
                        <Box className={classes.actions}>
                            {campaignOptions
                                .filter(option => !option.disabled)
                                .map(activeOption => (
                                    <TooltipButton
                                        key={activeOption.id}
                                        icon={activeOption.icon}
                                        tooltip={activeOption.title}
                                        onClick={() =>
                                            optionsHandler(activeOption.id)
                                        }
                                    />
                                ))}
                        </Box>
                    ) : (
                        <Box className={classes.actions}>
                            {props.selectedCampaing.status_id !==
                                SMSCampaignStatus.scheduled && (
                                <OptionsModal
                                    actionComponent={
                                        <Box className={classes.actionBtn}>
                                            <FontAwesomeIcon
                                                icon={faEllipsis}
                                            />
                                        </Box>
                                    }
                                    options={campaignOptions}
                                    onClick={id => optionsHandler(id)}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <Box className={classes.body}>
                    {campaignDetails &&
                        campaignDetails.status_id &&
                        [SMSCampaignStatus.sent].includes(
                            campaignDetails.status_id as SMSCampaignStatus
                        ) && (
                            <Fragment>
                                <GroupLabel title="Overview" />
                                <CampaignsOverview campaign={campaignDetails} />
                                <Divider />
                            </Fragment>
                        )}
                    <GroupLabel title="Recipients" />
                    <StatusBadge
                        label={`${
                            campaignDetails?.sms_subscriptions?.length || 0
                        } Clients`}
                    />
                    <Divider />
                    <GroupLabel title="Text Message" />
                    <CampaignMessage />
                </Box>
            </Box>

            {showDelete && (
                <ConfirmDialog
                    open={true}
                    title={<Typography>Delete campaign</Typography>}
                    question={
                        <Typography>
                            Do you want to <strong>delete</strong> this
                            campaign?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    onCancel={() => setShowDelete(false)}
                    onConfirm={() => deleteConfirmHandler()}
                />
            )}
        </Fragment>
    );
};

export default CampaignDetail;
