import { Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface HeaderTogglerProps {
	open: boolean;
	onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'border': 0,
			'height': 16,
			'padding': 0,
			'flex': '0 0 18px',
			'position': 'relative',
			'background': 'none',

			[theme.breakpoints.up('sm')]: {
				display: 'none'
			},

			'& span': {
				'left': 0,
				'right': 0,
				'height': 2,
				'rotate': 0,
				'opacity': 1,
				'position': 'absolute',
				'borderRadius': 11,
				'backgroundColor': '#000',
				'transition':
					'opacity 0.2s linear, rotate 0.15s ease-out, top linear 0.2s 0.15s',

				'&:first-child': {
					top: 0
				},
				'&:nth-child(2)': {
					top: 7
				},
				'&:last-child': {
					top: 14
				}
			},

			'&.open': {
				'& span': {
					'transition':
						'opacity 0.2s linear, rotate 0.3s 0.15s ease-out, top linear 0.15s',

					'&:first-child': {
						top: 7,
						rotate: '45deg'
					},
					'&:nth-child(2)': {
						opacity: 0
					},
					'&:last-child': {
						top: 7,
						rotate: '-45deg'
					}
				}
			}
		}
	})
);

export const Toggler: React.FunctionComponent<HeaderTogglerProps> = props => {
	const classes = useStyles();

	return (
		<button
			type="button"
			aria-label={props.open ? 'Close menu' : 'Open menu'}
			className={clsx(classes.root, {
				open: props.open
			})}
			onClick={props.onClick}
		>
			<span></span>
			<span></span>
			<span></span>
		</button>
	);
};
