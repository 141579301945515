import React, { FunctionComponent } from "react";
import { Checkbox, FormControlLabel, FormControlLabelProps, makeStyles, Theme } from "@material-ui/core";

interface CustomCheckboxCenterProps extends Omit<FormControlLabelProps, 'control'> {
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiFormControlLabel-label": {
      color: "#000000",
      fontSize: 15,
      lineHeight: 1.6,
      fontFamily: '"Poppins", sans-serif',
      [theme.breakpoints.down(1281)]: {
        fontSize: 14,
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: 1.1,
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#000000",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#222222",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
}));

export const CustomCheckboxCenter: FunctionComponent<CustomCheckboxCenterProps> = (props) => {
  const classes = useStyles();

  return <FormControlLabel {...props} className={classes.root} control={<Checkbox />} />;
};

export default CustomCheckboxCenter;
