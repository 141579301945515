import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import { LogoC } from 'components/UI';
import React, { Fragment } from 'react';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 66,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 16px',
            justifyContent: 'space-between',
            backgroundColor: '#fff',

            [theme.breakpoints.up('md')]: {
                borderBottom: '1px solid #DDDDDD',
                padding: '0px 25px'
            }
        },
        logo: {
            'flex': 1,

            '& svg': {
                [theme.breakpoints.down('sm')]: {
                    width: '41px !important',
                    height: 'auto !important'
                }
            }
        },
        title: {
            margin: 0,
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 500
        },
        items: {
            textAlign: 'center',

            [theme.breakpoints.down('sm')]: {
                marginTop: 8,
                padding: '15px 16px',
                backgroundColor: '#FAFAFA'
            }
        },
        item: {
            'opacity': 0.3,
            'cursor': 'not-allowed',
            'verticalAlign': 'middle',
            'display': 'inline-block',

            '&.active': {
                opacity: 1,
                cursor: 'pointer'
            },

            '&.completed': {
                'opacity': 1,
                'cursor': 'pointer',

                '& span': {
                    backgroundColor: '#92B4A7'
                }
            },

            '& span': {
                width: 20,
                height: 20,
                color: '#fff',
                fontSize: 13,
                marginRight: 8,
                borderRadius: '50%',
                backgroundColor: '#000',
                display: 'inline-block',
                lineHeight: '20px',
                verticalAlign: 'middle',
                margin: 'auto'
            },
            '& p': {
                fontSize: 15,
                lineHeight: 1,
                fontWeight: 500,
                display: 'inline-block',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 13
                }
            }
        },
        itemDivider: {
            'width': 10,
            'color': '#D3D3D3',
            'fontSize': 15,
            'fontWeight': 500,
            'margin': '0px 7px',
            'display': 'inline-block',
            'verticalAlign': 'middle',
            // 'borderBottom': '1px dashed #D3D3D3',

            '&:last-child': {
                display: 'none'
            },
            [theme.breakpoints.down('sm')]: {
                margin: '0px 3px'
            }
        },
        close: {
            'flex': 1,
            'fontSize': 21,
            'cursor': 'pointer',
            'textAlign': 'right',
            '& > *': {
                padding: 5,
                cursor: 'pointer',
                color: '#222222 !important'
            }
        },
        desktop: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

interface Props {
    step?: number;
    closePath: string;
    title?: string;
    items?: Array<WizardItem>;
    onClickClosePath?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

export interface WizardItem {
    title: string;
}

const WizardHeaderItemDivider = () => {
    const classes = useStyles();

    return <Box className={classes.itemDivider}>-</Box>;
};

const WizardHeaderItems = (props: {
    step: number;
    className: string;
    itemClassName: string;
    items?: Array<WizardItem>;
}) => {
    return (
        <Box className={props.className}>
            {props.items &&
                props.items.map((item, i) => (
                    <Fragment key={i}>
                        <Box
                            className={clsx(
                                props.itemClassName,
                                { active: props.step === i + 1 },
                                { completed: props.step > i + 1 }
                            )}
                        >
                            <span>
                                {props.step > i + 1 ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    i + 1
                                )}
                            </span>

                            <Typography>{item.title}</Typography>
                        </Box>

                        <WizardHeaderItemDivider />
                    </Fragment>
                ))}
        </Box>
    );
};

export const WizardHeader = ({
    closePath,
    step,
    items,
    title,
    onClickClosePath
}: Props) => {
    const classes = useStyles();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.logo}>
                    <Img
                        src="/images/logo/logo.png"
                        className={classes.desktop}
                    />
                    <LogoC className={classes.mobile} />
                </Box>

                {!isMobile && step && (
                    <WizardHeaderItems
                        step={step}
                        items={items}
                        className={classes.items}
                        itemClassName={classes.item}
                    />
                )}
                {isMobile && title && (
                    <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                        {title}
                    </Typography>
                )}

                <Box className={classes.close}>
                    {closePath?.length > 0 ? (
                        <Link
                            to={{ pathname: closePath }}
                            onClick={onClickClosePath}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Link>
                    ) : (
                        <Box onClick={onClickClosePath}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Box>
                    )}
                </Box>
            </Box>

            {isMobile && step !== undefined && (
                <WizardHeaderItems
                    step={step}
                    items={items}
                    className={classes.items}
                    itemClassName={classes.item}
                />
            )}
        </>
    );
};

{
    /* <Box className={classes.items}>
                    {items.map((item, i) => (
                        <Fragment key={i}>
                            <Box
                                className={clsx(
                                    classes.item,
                                    { active: step === i + 1 },
                                    { completed: step > i + 1 }
                                )}
                            >
                                <span>
                                    {step > i + 1 ? (
                                        <FontAwesomeIcon icon={faCheck} />
                                    ) : (
                                        i + 1
                                    )}
                                </span>

                                <Typography>{item.title}</Typography>
                            </Box>

                            <Box className={classes.itemDivider} />
                        </Fragment>
                    ))}
                </Box> */
}
