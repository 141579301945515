// @ts-nocheck 
import React, { FunctionComponent, useState } from "react";
import { TextField as MUITextField, Box } from "@material-ui/core";
import clsx from "clsx";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "@spike/model";
import InputMask from 'react-input-mask';
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";
import { useMarketplace } from "hooks";
import { cleanPhoneNumberFormat } from "@spike/phone-utils";

export interface PhoneField3Props {
  id?: string;
  name?: string;
  label?: string | JSX.Element;
  value: string;
  placeholder?: string;
  errors?: Array<FieldError>;
  onChange: (value: string, name?: any,) => void;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  autoComplete?: "off" | "on" | "nope";
  required?: boolean;
  information?: string;
}


export const PhoneField3: FunctionComponent<PhoneField3Props> = (props) => {
  const classes = useFieldStyles();

  const marketplace = useMarketplace();

  const isUSorCA = ["US", "CA"].includes(marketplace.basics.address.country?.id.toUpperCase() || "US");

  const [text, setText] = useState(props.value ? props.value : "");

  const hasError = props.errors?.some(error => error.fieldName === props.name) || false;

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(text, props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);


  const convertToNumbers = (value: string): string => {
    return cleanPhoneNumberFormat(value);
  }

  const changeUSHandler = (event: any): void => {
    const value = convertToNumbers(event.target.value);
    setText(value);
  };

  const changeOtherHandler = (event: any): void => {
    const newValue = event.target.value;
    /^\+{0,1}[0-9]{0,12}$/.test(newValue) && setText(newValue);
  };

  const usPhoneNumberField = (<Box><InputMask mask="(999) 999-9999" value={text} onChange={changeUSHandler} disabled={props.disabled}>
    {(inputProps: any) =>
      <MUITextField
        id={props.id ? props.id + "_number" : props.id}
        name={props.name}
        placeholder={props.placeholder}
        variant="outlined"
        autoFocus={props.autoFocus}
        className={clsx(classes.field,
          {
            [classes.emptyField]: text.length === 0,
            [classes.fieldError]: hasError,
            [classes.disabledField]: props.disabled,
          })}
        error={hasError}
        disabled={props.disabled}
        autoComplete={"nope"}
      />
    }
  </InputMask></Box>
  );

  const otherPhoneNumberField = (<MUITextField
    id={props.id ? props.id + "_number" : props.id}
    name={props.name}
    placeholder="Phone Number"
    variant="outlined"
    autoFocus={props.autoFocus}
    className={clsx(classes.field,
      {
        [classes.emptyField]: text.length === 0,
        [classes.fieldError]: hasError,
        [classes.disabledField]: props.disabled,
      })}
    error={hasError}
    disabled={props.disabled}
    autoComplete={"nope"}
    onChange={changeOtherHandler}
    value={text}
    inputProps={{ maxLength: 13 }}
  />
  );


  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField {...props} />
      {isUSorCA ? usPhoneNumberField : otherPhoneNumberField}
    </Box>);
};

export default PhoneField3;
