import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from 'components/UI';

import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface MedicalHistoryHeaderProps {
	description?: string;
	saving?: boolean;
	className?: string;
	onBack?: () => void;
	onEdit?: () => void;
	onSave?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			[theme.breakpoints.down(wbp)]: {
				paddingBottom: `${reduceResolution(24)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingBottom: '24px'
			},
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				flexWrap: 'unset'
			},
			[theme.breakpoints.down('sm')]: {
				paddingBottom: '15px'
			}
		},
		titleContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		back: {
			cursor: 'pointer',
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(24)}px`,
				marginRight: `${reduceResolution(10)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '24px',
				marginRight: '10px'
			}
		},
		title: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(24)}px`,
				lineHeight: `${reduceResolution(39)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '24px',
				lineHeight: '39px'
			}
		},
		description: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: 'black',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(15)}px`,
				lineHeight: `${reduceResolution(21)}px`,
				marginTop: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15px',
				lineHeight: '21px',
				marginTop: '16px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				lineHeight: '20px',
				marginTop: '10px',
				textWrap: 'nowrap'
			}
		},
		containerButton: {
			[theme.breakpoints.down('md')]: {
				'& svg': {
					width: '13px',
					height: 'auto',
					marginRight: '3px'
				}
			}
		},
		buttonMedical: {
			[theme.breakpoints.down('md')]: {
				'height': '40px',
				'& span': {
					fontSize: '14px'
				}
			}
		},
		buttonMedicalSave: {
			[theme.breakpoints.down('md')]: {
				display: 'none'
			}
		}
	})
);

const MedicalHistoryHeader: FunctionComponent<
	MedicalHistoryHeaderProps
> = props => {
	const classes = useStyles();

	return (
		<Grid container xs={12} className={props.className}>
			<Grid
				container
				xs={12}
				className={clsx(classes.header, props.className)}
			>
				<Grid xs="auto" md={6} item>
					<Box className={classes.titleContainer}>
						{props.onBack && (
							<FontAwesomeIcon
								icon={faArrowLeft}
								className={classes.back}
								onClick={props.onBack}
							/>
						)}
						<Typography className={classes.title}>
							Medical History
						</Typography>
					</Box>
					{props.description && (
						<Typography className={classes.description}>
							{props.description}
						</Typography>
					)}
				</Grid>
				<Grid
					xs
					md={6}
					container
					justifyContent="flex-end"
					className={classes.containerButton}
				>
					{props.onEdit && (
						<Button
							className={classes.buttonMedical}
							startIcon={faPencil}
							color="green"
							label="Edit Medical History"
							onClick={props.onEdit}
						/>
					)}
					{props.onSave && (
						<Button
							className={clsx(
								classes.buttonMedical,
								classes.buttonMedicalSave
							)}
							label="Save Medical History"
							loading={props.saving}
							onClick={props.onSave}
						/>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MedicalHistoryHeader;
