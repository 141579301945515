import { Action } from "redux";
import SMSCampaign, { SMSCampaignStatus } from "model/SMSCampaign";
import Client from "@spike/client-model";
import { SMSCampaignClientGroups, SMSCampaignsStatus } from "reducers/smsCampaigns/SMSCampaignsState";
import { SMSCampaignQuoteDto, SMSCampaignsDto } from "./SMSCampaignsDtos";

/**
 * Types
 */

// Reset
export const SMS_CAMPAIGNS_RESET_ACTION_TYPE = "SMS_CAMPAIGNS_RESET";

// Filter
export const SMS_CAMPAIGNS_SET_FILTER_ACTION_TYPE = "SMS_CAMPAIGNS_SET_FILTER";

// Fetch
export const SMS_CAMPAIGNS_FETCH_GLOBAL_START_ACTION_TYPE = "SMS_CAMPAIGNS_FETCH_GLOBAL_START";
export const SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS";

export const SMS_CAMPAIGNS_FETCH_START_ACTION_TYPE = "SMS_CAMPAIGNS_FETCH_START";
export const SMS_CAMPAIGNS_FETCH_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_FETCH_SUCCESS";

// Getting
export const SMS_CAMPAIGNS_GET_START_ACTION_TYPE = "SMS_CAMPAIGNS_GET_START";
export const SMS_CAMPAIGNS_GET_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_GET_SUCCESS";

// Stats
export const SMS_CAMPAIGNS_GET_STATS_ACTION_TYPE = "SMS_CAMPAIGNS_GET_STATS";

// Save
export const SMS_CAMPAIGNS_SAVE_START_ACTION_TYPE = "SMS_CAMPAIGNS_SAVE_START";
export const SMS_CAMPAIGNS_SAVE_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_SAVE_SUCCESS";
export const SMS_CAMPAIGNS_SAVE_RESET_ACTION_TYPE = "SMS_CAMPAIGNS_SAVE_RESET";

// Update
export const SMS_CAMPAIGNS_UPDATE_START_ACTION_TYPE = "SMS_CAMPAIGNS_UPDATE_START";
export const SMS_CAMPAIGNS_UPDATE_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_UPDATE_SUCCESS";

// Delete
export const SMS_CAMPAIGNS_DELETE_START_ACTION_TYPE = "SMS_CAMPAIGNS_DELETE_START";
export const SMS_CAMPAIGNS_DELETE_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_DELETE_SUCCESS";

// Customers
export const SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START_ACTION_TYPE = "SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START";
export const SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS";

// Clients
export const SMS_CAMPAIGNS_CLIENTS_FETCH_START_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_FETCH_START";
export const SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS";
export const SMS_CAMPAIGNS_CLIENTS_SEARCH_START_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_SEARCH_START";
export const SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS";
export const SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING";

export const SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_START_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_GROUP_FETCH_START";
export const SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_SUCCESS_ACTION_TYPE = "SMS_CAMPAIGNS_CLIENTS_GROUP_FETCH_SUCCESS";

// Dropin
export const SMS_CAMPAIGNS_CREATE_DROPIN_ACTION_TYPE = "SMS_CAMPAIGNS_CREATE_DROPIN";

// Loading
export const SMS_CAMPAIGNS_SET_LOADING_ACTION_TYPE = "SMS_CAMPAIGNS_SET_LOADING";

// Step
export const SMS_CAMPAIGNS_DECREASE_STEP_ACTION_TYPE = "SMS_CAMPAIGNS_DECREASE_STEP";
export const SMS_CAMPAIGNS_INCREASE_STEP_ACTION_TYPE = "SMS_CAMPAIGNS_INCREASE_STEP";

// Status
export const SMS_CAMPAIGNS_UPDATE_STATUS_ACTION_TYPE = "SMS_CAMPAIGNS_UPDATE_STATUS";

// Edit
export const SMS_CAMPAIGNS_EDIT_ACTION_TYPE = "SMS_CAMPAIGN_EDIT";

// Clone
export const SMS_CAMPAIGNS_DUPLICATE_ACTION_TYPE = "SMS_CAMPAIGN_DUPLICATE";

// Quote
export const SMS_CAMPAIGNS_QUOTE_ACTION_TYPE = "SMS_CAMPAIGNS_QUOTE";

/**
 * Actions
 */

// Reset
interface SMSCampaignsResetAction extends Action<typeof SMS_CAMPAIGNS_RESET_ACTION_TYPE> { }

// Stats
interface SMSCampaignsGetStatsAction extends Action<typeof SMS_CAMPAIGNS_GET_STATS_ACTION_TYPE> { }

// Clients
interface SMSCampaignsClientsFetchStartAction extends Action<typeof SMS_CAMPAIGNS_CLIENTS_FETCH_START_ACTION_TYPE> { }
interface SMSCampaignsClientsFetchSuccessAction extends Action<typeof SMS_CAMPAIGNS_CLIENTS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    override: boolean;
    customers: Array<Client>;
    page?: string;
    size?: string;
    total_pages?: number;
    total_records?: number;
  };
}

interface SMSCampaignsClientsSetSearchingAction extends Action<typeof SMS_CAMPAIGNS_CLIENTS_SET_SEARCHING_ACTION_TYPE> {
  payload: {
    searching: boolean
  }
}
interface SMSCampaignsClientsSearchStartAction extends Action<typeof SMS_CAMPAIGNS_CLIENTS_SEARCH_START_ACTION_TYPE> { }
interface SMSCampaignsClientsSearchSuccessAction extends Action<typeof SMS_CAMPAIGNS_CLIENTS_SEARCH_SUCCESS_ACTION_TYPE> {
  payload: {
    customers: Array<Client>;
    page?: string;
    size?: string;
    total_pages?: number;
    total_records?: number;
  };
}

// Customers
interface SMSCampaignsCustomersUpdateStartAction extends Action<typeof SMS_CAMPAIGNS_CUSTOMERS_UPDATE_START_ACTION_TYPE> { }
interface SMSCampaignsCustomersUpdateSuccessAction extends Action<typeof SMS_CAMPAIGNS_CUSTOMERS_UPDATE_SUCCESS_ACTION_TYPE> {
  payload: {
    campaign: SMSCampaign,
  }
}

interface SMSCampaignsCustomersGroupFetchStartAction extends Action<typeof SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_START_ACTION_TYPE> { }
interface SMSCampaignsCustomersGroupFetchSuccessAction extends Action<typeof SMS_CAMPAIGNS_CUSTOMERS_GROUP_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    groups: SMSCampaignClientGroups
  }
}

// Filter
interface SMSCampaignsSetFilterAction extends Action<typeof SMS_CAMPAIGNS_SET_FILTER_ACTION_TYPE> {
  payload: { filter: SMSCampaignStatus }
}

// Fetch
interface SMSCampaignsFetchGlobalStartAction extends Action<typeof SMS_CAMPAIGNS_FETCH_GLOBAL_START_ACTION_TYPE> { }
interface SMSCampaignsFetchGlobalSuccessAction extends Action<typeof SMS_CAMPAIGNS_FETCH_GLOBAL_SUCCESS_ACTION_TYPE> {
  payload: { data: SMSCampaignsDto }
}

interface SMSCampaignsFetchStartAction extends Action<typeof SMS_CAMPAIGNS_FETCH_START_ACTION_TYPE> { }
interface SMSCampaignsFetchSuccessAction extends Action<typeof SMS_CAMPAIGNS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    data: SMSCampaignsDto,
    all?: boolean
  }
}

// Get
interface SMSCampaignsGetStartAction extends Action<typeof SMS_CAMPAIGNS_GET_START_ACTION_TYPE> { }
interface SMSCampaignsGetSuccessAction extends Action<typeof SMS_CAMPAIGNS_GET_SUCCESS_ACTION_TYPE> {
  payload: {
    campaign: SMSCampaign
  }
}

// Save
interface SMSCampaignsSaveResetAction extends Action<typeof SMS_CAMPAIGNS_SAVE_RESET_ACTION_TYPE> { }
interface SMSCampaignsSaveStartAction extends Action<typeof SMS_CAMPAIGNS_SAVE_START_ACTION_TYPE> { }
interface SMSCampaignsSaveSuccessAction extends Action<typeof SMS_CAMPAIGNS_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    campaign: SMSCampaign
  }
}

// Update
interface SMSCampaignsUpdateStartAction extends Action<typeof SMS_CAMPAIGNS_UPDATE_START_ACTION_TYPE> { }
interface SMSCampaignsUpdateSuccessAction extends Action<typeof SMS_CAMPAIGNS_UPDATE_SUCCESS_ACTION_TYPE> {
  payload: {
    campaign: SMSCampaign
  }
}

// Delete
interface SMSCampaignsDeleteStartAction extends Action<typeof SMS_CAMPAIGNS_DELETE_START_ACTION_TYPE> { }
interface SMSCampaignsDeleteSuccessAction extends Action<typeof SMS_CAMPAIGNS_DELETE_SUCCESS_ACTION_TYPE> { }

// Step
interface SMSCampaignsIncreaseStepAction extends Action<typeof SMS_CAMPAIGNS_INCREASE_STEP_ACTION_TYPE> { }
interface SMSCampaignsDecreaseStepAction extends Action<typeof SMS_CAMPAIGNS_DECREASE_STEP_ACTION_TYPE> { }

// Status
interface SMSCampaignsUpdateStatusAction extends Action<typeof SMS_CAMPAIGNS_UPDATE_STATUS_ACTION_TYPE> {
  payload: {
    status: SMSCampaignsStatus
  }
}

// Edit
interface SMSCampaignsEditAction extends Action<typeof SMS_CAMPAIGNS_EDIT_ACTION_TYPE> {
  payload: {
    campaign: SMSCampaign
  }
}

// Duplicate
interface SMSCampaignsDuplicateAction extends Action<typeof SMS_CAMPAIGNS_DUPLICATE_ACTION_TYPE> {
  payload: {
    campaign: SMSCampaign
  }
}

// Quote
interface SMSCampaignsQuoteAction extends Action<typeof SMS_CAMPAIGNS_QUOTE_ACTION_TYPE> {
  payload: {
    quote: SMSCampaignQuoteDto
  }
}


export type SMSCampaignsAction =
  // Reset
  | SMSCampaignsResetAction
  // Stats
  | SMSCampaignsGetStatsAction
  // Filter
  | SMSCampaignsSetFilterAction
  // Fetch
  | SMSCampaignsFetchGlobalStartAction
  | SMSCampaignsFetchGlobalSuccessAction

  | SMSCampaignsFetchStartAction
  | SMSCampaignsFetchSuccessAction
  // Getting
  | SMSCampaignsGetStartAction
  | SMSCampaignsGetSuccessAction
  // Save
  | SMSCampaignsSaveStartAction
  | SMSCampaignsSaveSuccessAction
  | SMSCampaignsSaveResetAction
  // Update
  | SMSCampaignsUpdateStartAction
  | SMSCampaignsUpdateSuccessAction
  // Delete
  | SMSCampaignsDeleteStartAction
  | SMSCampaignsDeleteSuccessAction
  // Step
  | SMSCampaignsIncreaseStepAction
  | SMSCampaignsDecreaseStepAction
  // Customers
  | SMSCampaignsCustomersUpdateStartAction
  | SMSCampaignsCustomersUpdateSuccessAction
  | SMSCampaignsCustomersGroupFetchStartAction
  | SMSCampaignsCustomersGroupFetchSuccessAction
  // Status
  | SMSCampaignsUpdateStatusAction
  // Edit
  | SMSCampaignsEditAction
  // Duplicate
  | SMSCampaignsDuplicateAction
  // Quote
  | SMSCampaignsQuoteAction
  // Clients
  | SMSCampaignsClientsFetchStartAction
  | SMSCampaignsClientsFetchSuccessAction
  | SMSCampaignsClientsSetSearchingAction
  | SMSCampaignsClientsSearchStartAction
  | SMSCampaignsClientsSearchSuccessAction;