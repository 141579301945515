import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

interface NumberProps {
    number: number;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 48,
            height: 74,
            backgroundColor: '#F4F3F0',
            [theme.breakpoints.down(768)]: {
                width: 40,
                height: 61
            }
        },
        number: {
            fontFamily: 'Poppins',
            fontSize: 56,
            fontWeight: 600,
            color: '#000000',
            [theme.breakpoints.down(768)]: {
                fontSize: 44
            }
        }
    })
);

export const Number: FunctionComponent<NumberProps> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Typography className={classes.number}>{props.number}</Typography>
        </Box>
    );
};

export default Number;
