import { Typography, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { CalendarField, Chip2 } from "components/UI";
import { useMasterData } from "hooks";
import { PetDental, DentalIds } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { Moment } from "moment-timezone";

interface AnestheticDentalProps {
  dental: PetDental;
  onChange: (dental: PetDental) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "20px",
      },
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(20)}px`,
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "20px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px"
      }
    },
    gridContainer: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
      }
    }
  })
);

export const AnestheticDental: FunctionComponent<AnestheticDentalProps> = (props) => {
  const classes = useStyles();

  const changeDateHandler = (cleaningDate: Moment) => {
    props.onChange({...props.dental, cleaningDate});
  }

  const changeExtractionsHandler = (teethExtracted: boolean) => {
    props.onChange({...props.dental, teethExtracted});
  }

  return (
      <Grid container className={props.className}>
        <Grid container xs={12} md={6} alignItems="center" className={classes.gridContainer}>
          <Typography className={classes.text}>Teeth extracted</Typography>
          <Chip2 label="Yes" selected={props.dental.teethExtracted === true} onClick={() => changeExtractionsHandler(true)} className={classes.chip}/>
          <Chip2 label="No" selected={props.dental.teethExtracted === false} onClick={() => changeExtractionsHandler(false)} className={classes.chip}/>
        </Grid>
        <Grid item xs={12} md={6}>
          {props.dental.teethExtracted !== undefined && <CalendarField date={props.dental.cleaningDate} rawDate={true}  placeholder="Cleaning Date" onChange={changeDateHandler}/>}
        </Grid>
      </Grid>

  );
};

export default AnestheticDental;
