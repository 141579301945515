import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Img } from "react-image";
import Button from "../UI/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";


interface Props {
  disableAddButton?: boolean;
  onAddClient?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
    },
    image: {
      [theme.breakpoints.down("lg")]: {
        width: "486px",
        height: "242px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "648px",
        height: "322px",
      },
    },
    title: {
      textAlign: "center",
      fontWeight: 700,
      [theme.breakpoints.down("lg")]: {
        marginTop: "13px",
        lineHeight: "27px",
        fontSize: "26px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "20px",
        lineHeight: "36px",
        fontSize: "28px",
      },
    },
    description: {
      textAlign: "center",
      fontWeight: 500,
      [theme.breakpoints.down("lg")]: {
        marginTop: "11px",
        lineHeight: "20px",
        fontSize: "16px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "15px",
        lineHeight: "27px",
        fontSize: "18px",
      },
    },
    button: {
      [theme.breakpoints.down("lg")]: {
        marginTop: "26px",
        marginBottom: "26px",
        width: "150px",
        height: "41px",
        fontSize: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "34px",
        marginBottom: "34px",
        width: "200px",
        height: "55px",
        fontSize: "18px",
      },
    }
  })
);


export const ClientsNotFound: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Img className={classes.image} src="/images/clients/notFoundClients.svg" />
      <Typography className={classes.title}>No clients found under that search.</Typography>
      <Typography className={classes.description}>
        You can always add a new client.
      </Typography>
      <Button id="clients_button_add" label="+ Add Client" className={classes.button} onClick={props.onAddClient} disabled={props.disableAddButton} />
    </Box>
  );
};

export default ClientsNotFound;
