import React, { FunctionComponent } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import BadgeLabel from "./BadgeLabel";
import { Button } from "components/UI";

import { faUsdCircle } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  isNew: boolean;
  onClickPay: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F2D2A2",
      borderRadius: "2.91px",
      padding: "0px 4.36px",
      gap: "3.63px",
    },
    titleContainer: {
      gap: "8.5px",
      marginBottom: "12px",
    },
    title: {
      fontWeight: 500,
      fontSize: "18px",
    },
    buttonPay: {
      width: "218px",
      height: "47px",
    },
  })
);

export const PaymentStep: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const newStatus = props.isNew ? "new" : "coming soon";
  return (
    <Grid>
      <Grid container className={classes.titleContainer}>
        <BadgeLabel label={newStatus} />
        <Typography className={classes.title}>
          Integrate with Payments
        </Typography>
      </Grid>
      <Grid>
        <Button
          startIcon={faUsdCircle}
          label="Cuddles Pay"
          color="green"
          disabled={!props.isNew}
          onClick={props.onClickPay}
          size="large"
          className={classes.buttonPay}
        />
      </Grid>
    </Grid>
  );
};
