import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core';
import Client from '@spike/client-model';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AutoSizer, List, ScrollEventData } from 'react-virtualized';
import { useDispatch } from 'react-redux';
import { fetchClientsPageThunk } from 'actions/smsCampaigns/SMSCampaignsActions';
import { throttle, uniqBy } from 'lodash';
import { formatPhoneNumber } from '@spike/phone-utils';
import { useApiClientWrapper } from 'hooks';

enum FiltersType {
    ALL = 'all',
    SELECTED = 'selected',
    UNSELECTED = 'unselected'
}

interface Props {
    loading: boolean;
    total: number;
    cached: Client[];
    clients: Client[];
    page?: string | number;
    excluded: number[];
    selected: number[] | 'all';

    onNextPage: (page: number) => void;
    onChange: (selected: number[] | 'all', excluded: number[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabs: {
            'top': 0,
            'zIndex': 10,
            'width': '100%',
            'position': 'sticky',
            'padding': '0px 45px',
            'backgroundColor': '#fff',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 22px'
            },

            '& button': {
                'border': 0,
                'fontSize': 16,
                'lineHeight': 1,
                'fontWeight': 500,
                'color': '#BCB8AE',
                'cursor': 'pointer',
                'padding': '18px 0px 14px',
                'backgroundColor': 'transparent',
                'borderBottom': 'solid 2px transparent',

                '& + button': {
                    marginLeft: 22
                },
                '&.active': {
                    color: '#222',
                    borderBottomColor: '#222'
                }
            }
        },
        items: {
            flex: 1,
            width: '100%'
        },
        tableRow: {
            'color': '#222',
            'display': 'flex',
            'alignItems': 'center',
            'padding': '15px 48px',
            [theme.breakpoints.down('sm')]: {
                padding: '15px 22px'
            },

            '& div:nth-child(2)': {
                width: '50%',
                fontWeight: 600
            },
            '& div:last-child': {
                width: '40%'
            },

            '& .MuiButtonBase-root': {
                padding: 0,
                color: '#222',
                marginRight: 18
            }
        },
        tableHead: {
            top: 51,
            zIndex: 10,
            fontSize: 16,
            lineHeight: 1.5,
            fontWeight: 600,
            paddingTop: 15,
            position: 'sticky',
            backgroundColor: '#fff',
            borderTop: '1px solid #D4D4D4',
            borderBottom: '2px solid #000000',
            [theme.breakpoints.down('sm')]: {
                borderTop: 'none'
            }
        },
        tableBodyRow: {
            borderBottom: 'solid 1px #DDDDDD'
        },
        loading: {
            padding: 20,
            width: '100%',
            textAlign: 'center'
        },
        checkbox: {
            'width': 18,
            'height': 18,
            'fontSize': 14,
            'color': '#fff',
            'marginLeft': 3,
            'marginRight': 24,
            'paddingLeft': 1,
            'borderRadius': 2,
            'cursor': 'pointer',
            'border': 'solid 2px #000',

            '& svg': {
                display: 'none'
            },

            "&[data-checked='true']": {
                'backgroundColor': '#000',

                '& svg': {
                    display: 'block'
                }
            }
        },
        listLastChild: {
            borderBottom: 'none'
        }
    })
);

const ChooseModalClientResults: FunctionComponent<Props> = ({
    selected,
    excluded,
    loading,
    total,
    page,
    cached,
    clients,
    onNextPage,
    onChange
}) => {
    const classes = useStyles();
    const apiClientWrapper = useApiClientWrapper();

    /**
     * Local state
     */
    const [filter, setFilter] = useState<FiltersType>(FiltersType.ALL);

    /**
     * Computed
     */
    const selectedAll = (): boolean => selected === 'all';

    /**
     * Events
     */
    const clientToggle = (id: undefined | number, checked: boolean) => {
        if (!id) return;

        if (selected === 'all') {
            switch (filter) {
                case FiltersType.ALL:
                    onChange(
                        selected,
                        checked
                            ? [...filterIds(excluded, id), id]
                            : filterIds(excluded, id)
                    );
                    break;
                case FiltersType.SELECTED:
                    onChange(
                        selected,
                        checked
                            ? [...filterIds(excluded, id), id]
                            : filterIds(excluded, id)
                    );
                    break;
                case FiltersType.UNSELECTED:
                    onChange(
                        selected,
                        checked
                            ? filterIds(excluded, id)
                            : [...filterIds(excluded, id), id]
                    );
                    break;
            }

            return;
        }

        switch (filter) {
            case FiltersType.ALL:
                onChange(
                    checked
                        ? filterIds(selected, id)
                        : [...filterIds(selected, id), id],
                    excluded
                );
                break;
            case FiltersType.SELECTED:
                onChange(
                    checked
                        ? filterIds(selected, id)
                        : [...filterIds(selected, id), id],
                    excluded
                );
                break;
            case FiltersType.UNSELECTED:
                onChange(
                    checked
                        ? [...filterIds(selected, id), id]
                        : filterIds(selected, id),
                    excluded
                );
                break;
        }
    };

    const selectAllToggle = () => {
        onChange(selectedAll() ? [] : 'all', []);
    };

    /**
     * Functions
     */
    const filteredClients = (): Client[] => {
        const allClients =
            filter !== FiltersType.ALL
                ? uniqBy(clients.concat(cached), (c: Client) => c.id)
                : clients;

        return allClients.filter((c: Client, i: number) => {
            if (!c.id) return false;

            if (filter === FiltersType.ALL) return true;

            if (filter === FiltersType.SELECTED && selected === 'all')
                return !excluded.includes(c.id);

            if (filter === FiltersType.SELECTED && Array.isArray(selected))
                return selected.includes(c.id);

            if (filter === FiltersType.UNSELECTED)
                return (
                    (Array.isArray(selected) && !selected.includes(c.id)) ||
                    excluded.includes(c.id)
                );
        });
    };

    const showList = (): boolean => {
        if (filter === FiltersType.SELECTED && selected.length === 0)
            return false;

        return true;
    };

    const isClientChecked = (id?: number): boolean => {
        if (!id) return false;

        if (selected === 'all')
            return (
                (!excluded.includes(id) && filter !== FiltersType.UNSELECTED) ||
                (filter === FiltersType.UNSELECTED && excluded.includes(id))
            );

        if (filter !== FiltersType.UNSELECTED) return selected.includes(id);

        if (filter === FiltersType.UNSELECTED) return !selected.includes(id);

        return false;
    };

    const filterIds = (arr: number[], id: number) => {
        return arr.filter((v: number) => v !== id);
    };

    /**
     * Inline components
     */
    const Filters = () => (
        <Box className={classes.tabs}>
            <button
                type="button"
                onClick={() => setFilter(FiltersType.ALL)}
                className={clsx({ active: filter === FiltersType.ALL })}
            >
                All ({total})
            </button>
            <button
                type="button"
                onClick={() => setFilter(FiltersType.SELECTED)}
                className={clsx({ active: filter === FiltersType.SELECTED })}
            >
                Selected (
                {selected === 'all' ? total - excluded.length : selected.length}
                )
            </button>
            <button
                type="button"
                onClick={() => setFilter(FiltersType.UNSELECTED)}
                className={clsx({ active: filter === FiltersType.UNSELECTED })}
            >
                Unselected (
                {selected === 'all' ? excluded.length : total - selected.length}
                )
            </button>
        </Box>
    );

    const Checkbox = ({
        checked,
        onClick
    }: {
        checked: boolean;
        onClick(c: boolean): void;
    }) => (
        <Fragment>
            <Box
                onClick={() => onClick(checked)}
                data-checked={checked}
                className={classes.checkbox}
            >
                <FontAwesomeIcon icon={faCheck} />
            </Box>
        </Fragment>
    );

    const TableHead = () => (
        <Box className={clsx(classes.tableHead, classes.tableRow)}>
            <Checkbox checked={selectedAll()} onClick={selectAllToggle} />
            <Box>Client Name</Box>
            <Box>Phone</Box>
        </Box>
    );

    const TableRow = ({
        client,
        isLast
    }: {
        client: Client;
        isLast: boolean;
    }) => (
        <Box>
            <Box
                className={clsx(
                    classes.tableBodyRow,
                    classes.tableRow,
                    isLast && classes.listLastChild
                )}
                id={`client-${client.id}`}
            >
                <Checkbox
                    checked={isClientChecked(client.id)}
                    onClick={c => clientToggle(client.id, c)}
                />

                <Box>{`${client.firstName} ${client.lastName}`}</Box>
                <Box>
                    {formatPhoneNumber(
                        client.phone,
                        apiClientWrapper.marketplace!.basics.address.country!.id
                    )}
                </Box>
            </Box>
        </Box>
    );

    const renderTableRowItem = ({
        key,
        index,
        isScrolling,
        isVisible,
        style
    }: {
        key: string;
        index: number;
        isScrolling: boolean;
        isVisible: boolean;
        style: any;
    }) => {
        return (
            <div key={key} style={style}>
                <TableRow
                    client={filteredClients()[index]}
                    isLast={index === filteredClients().length - 1}
                />
            </div>
        );
    };

    const onListScroll = throttle(function (e: ScrollEventData) {
        const listHeight = e.scrollHeight;
        const YPos = e.scrollTop + e.clientHeight;

        const nextPage = parseInt(`${page || 1}`) + 1;

        if (YPos > listHeight * 0.9 && filter === FiltersType.ALL)
            onNextPage(nextPage);
    }, 150);

    return (
        <Fragment>
            <Filters />

            <TableHead />

            <Box className={classes.items}>
                {!loading && showList() && (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                width={width}
                                height={height}
                                rowHeight={51}
                                rowCount={filteredClients().length}
                                rowRenderer={renderTableRowItem}
                                onScroll={onListScroll}
                            />
                        )}
                    </AutoSizer>
                )}

                {loading && (
                    <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Fragment>
    );
};

export default ChooseModalClientResults;
