import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

interface PriceProps {
	price: string;
	refund?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: 24,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			color: '#222222',
			borderRadius: '60px',
			backgroundColor: '#222222',

			padding: '1px 10px 1px 10px',

			[theme.breakpoints.up('md')]: {
				fontSize: '30px',
				padding: '2px 12px 2px 12px'
			}
		},
		text: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 500,
			color: '#ffffff'
		},
		refund: {
			backgroundColor: '#EF4F57'
		}
	})
);

export const Price: FunctionComponent<PriceProps> = props => {
	const classes = useStyles();

	return (
		<Box
			className={clsx(
				classes.container,
				{ [classes.refund]: props.refund },
				props.className
			)}
		>
			<Typography className={classes.text}>${props.price}</Typography>
		</Box>
	);
};

export default Price;
