import { Reducer } from "redux";
import { AvailabilityState, AvailabilityStatus } from "./AvailabilityState";
import {
  AVAILABILITY_RESET_ACTION_TYPE,
  AVAILABILITY_FETCH_START_ACTION_TYPE,
  AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE,
  AVAILABILITY_STAFF_FETCH_START_ACTION_TYPE,
  AVAILABILITY_STAFF_FETCH_SUCCESS_ACTION_TYPE,

  FILTER_AVAILABILITY_FETCH_START_ACTION_TYPE,
  FILTER_AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE,

  AVAILABILITY_FETCH_BOOKING_START_ACTION_TYPE,
  AVAILABILITY_FETCH_BOOKING_SUCCESS_ACTION_TYPE,
  AVAILABILITY_ERROR_ACTION_TYPE,
  AvailabilityAction,
} from "actions/availability/AvailabilityActionsTypes";

const initialState: AvailabilityState = {
  availability: { dates: [] },
  availabilityBooking: { dates: [] },
  filterAvailability: { slots: [] },
  status: AvailabilityStatus.Initial,
  loading: false,
  requestTokens: {
    availability: '',
    availabilityBooking: '',
  }
};

export const availabilityReducer: Reducer<AvailabilityState, AvailabilityAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AVAILABILITY_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    case AVAILABILITY_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.Fetching,
        availability: { dates: [] },
        loading: true,
        requestTokens: {
          ...state.requestTokens,
          availability: action.payload.requestToken,
        }
      };
    case AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchSuccess,
        availability: action.payload.availability,
        loading: false,
      };
    case AVAILABILITY_STAFF_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchingStaff,
        availability: { dates: [] },
        loading: true,
      };
    case AVAILABILITY_STAFF_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchStaffSuccess,
        availability: action.payload.availability,
        loading: false,
      };
    case FILTER_AVAILABILITY_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchingFilter,
        filterAvailability: { slots: [] },
        loading: true,
      };
    case FILTER_AVAILABILITY_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchFilterSuccess,
        filterAvailability: action.payload.filterAvailability,
        loading: false,
      };
    case AVAILABILITY_FETCH_BOOKING_START_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchingBooking,
        availabilityBooking: { dates: [] },
        loading: true,
        requestTokens: {
          ...state.requestTokens,
          availabilityBooking: action.payload.requestToken,
        }
      };
    case AVAILABILITY_FETCH_BOOKING_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.FetchBookingSuccess,
        availabilityBooking: action.payload.availabilityBooking,
        loading: false,
      };

    case AVAILABILITY_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: AvailabilityStatus.Error,
        availability: { dates: [] },
        filterAvailability: { slots: [] },
        loading: false,
      };
    default:
      return state;
  }
};

export default availabilityReducer;
