import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { SelectableOption } from 'model';
import { FunctionComponent } from 'react';

interface Props {
	label: string;
	levels: Array<SelectableOption<number>>;
	isDentalReport: boolean;
	onChange: (value: Array<SelectableOption<number>>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: 16,
			fontWeight: 600,
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 500
			}
		},
		levelsContainer: {
			gap: 16,
			display: 'flex'
		},
		levels: {
			display: 'flex'
		},
		level: {
			width: 35,
			height: 35,
			display: 'flex',
			justifyContent: 'center',
			borderRadius: 30,
			border: '2px solid #000',
			textAlign: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			fontSize: '1rem'
		}
	})
);

export const OralHygieneLevels: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const handleLevelSelected = (l: number): void => {
		const levelsAux = [...props.levels];
		levelsAux.forEach(lvl => {
			if (l === lvl.element.id) {
				lvl.selected = true;
			} else {
				lvl.selected = false;
			}
		});
		props.onChange(levelsAux);
	};

	return (
		<Box>
			<Typography className={classes.label}>{props.label}</Typography>
			<Box className={classes.levelsContainer}>
				{props.levels.map(lvl => (
					<Box key={lvl.element.id} className={classes.levels}>
						<Typography
							key={lvl.element.id}
							onClick={() => {
								if (props.isDentalReport) {
									handleLevelSelected(lvl.element.id);
								}
							}}
							style={{
								backgroundColor: lvl.selected
									? '#92B4A7'
									: '#F1F1F1',
								color: lvl.selected ? '#fff' : '#222222',
								borderColor: lvl.selected ? '#92B4A7' : '#000'
							}}
							className={classes.level}
						>
							{lvl.element.name}
						</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default OralHygieneLevels;
