import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent, useRef, useState } from 'react';
import { wbp } from 'Theme';

interface SidebarButtonProps {
	icon: IconDefinition;
	label: string;
	selected?: boolean;
	className?: string;
	disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		button: {
			'cursor': (props: SidebarButtonProps) =>
				props.disabled ? 'not-allowed' : 'pointer',
			'display': 'flex',
			'justifyContent': 'center',
			'alignItems': 'center',
			'borderRadius': '6px',
			'&:hover': {
				'backgroundColor': '#DBDAD7',
				'& + #label': {
					display: 'flex'
				}
			},
			[theme.breakpoints.down(wbp)]: {
				width: '34px',
				height: '34px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '42px',
				height: '42px'
			}
		},
		selected: {
			backgroundColor: '#DBDAD7'
		},
		icon: {
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				width: '22px !important',
				height: '22px !important'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '28px !important',
				height: '28px !important'
			}
		},
		labelContainer: {
			zIndex: 10,
			display: 'none',
			position: 'fixed',
			backgroundColor: '#000',
			pointerEvents: 'none',

			[theme.breakpoints.down(wbp)]: {
				borderRadius: '3px',
				padding: '3px 8px 3px 8px'
			},
			[theme.breakpoints.up(wbp)]: {
				borderRadius: '4px',
				padding: '4px 10px 4px 10px'
			}
		},
		label: {
			color: '#FFFFFF',
			fontFamily: 'Poppins',
			fontWeight: 500,
			lineHeight: '120%',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px'
			}
		}
	})
);

export const SidebarButton: FunctionComponent<SidebarButtonProps> = props => {
	const ref = useRef<HTMLDivElement>(null);
	const classes = useStyles(props);

	const [y, setY] = useState(0);
	const [x, setX] = useState(0);

	const calculateTooltipPosition = () => {
		if (!ref.current) {
			return;
		}

		const button = ref.current;
		const { scrollWidth } = button;
		const rect = button.getBoundingClientRect();

		setY(rect.top + 7);
		setX(rect.left + scrollWidth + 8);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<div
				ref={ref}
				className={clsx(classes.button, {
					[classes.selected]: props.selected
				})}
				id={`booking_sidebar_button_${props.label}`}
				onMouseEnter={calculateTooltipPosition}
			>
				<FontAwesomeIcon icon={props.icon} className={classes.icon} />
			</div>
			<Box
				id="label"
				className={classes.labelContainer}
				style={{
					top: y,
					left: x
				}}
			>
				<Typography className={classes.label}>{props.label}</Typography>
			</Box>
		</Box>
	);
};

export default SidebarButton;
