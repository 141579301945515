import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Option } from '@spike/model';

import { SelectionBar } from 'components/UI';
import { Fragment, FunctionComponent } from 'react';

interface FiltersProps {
	signed: boolean;
	selectedTotalRecords?: number;
	onSelect?: (signed: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingBottom: 40,
			paddingLeft: 32,
			paddingRight: 32,
			background: '#fafafa',
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 16,
				paddingRight: 16,
				background: '#fff',
			}
		},
		selectionBar: {
			'display': 'inline-flex',
			background: '#fff',

			'& [class*="selectedContainer"] p': {
				fontWeight: 600
			},
			'& [class*="lastButton"]': {
				marginLeft: 4
			}
		}
	})
);

const signed: Option<string> = { id: 'signed', name: 'Signed' };
const unsigned: Option<string> = { id: 'unsigned', name: 'Unsigned' };

const options = [signed, unsigned];

const Filters: FunctionComponent<FiltersProps> = props => {
	const classes = useStyles();

	return (
		<Fragment>
			<Box className={classes.root}>
				<SelectionBar
					options={options}
					selectedId={props.signed ? signed.id : unsigned.id}
					selectedTotalRecords={props.selectedTotalRecords}
					onSelect={selectedId =>
						props.onSelect &&
						props.onSelect(selectedId === signed.id)
					}
					className={classes.selectionBar}
				/>
			</Box>
		</Fragment>
	);
};

export default Filters;
