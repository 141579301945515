import { PetType } from "reducers/businessSettings/PetTypeStatusState";
import {Action} from "redux";


export const BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE = "BUSINESS_SETTINGS_FETCH_START"
export const BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE = "BUSINESS_SETTINGS_FETCH_SUCCESS"
export const BUSINESS_SETTINGS_ERROR_ACTION_TYPE = "BUSINESS_SETTINGS_ERROR"

interface BusinessSettingsFetchStartAction extends Action<typeof BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE> {}

interface BusinessSettingsFetchSuccessAction extends Action<typeof BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE> {
    payload: {
        pet_size: Array<PetType>;
        pet_age: Array<PetType>;
        pet_hair_type: Array<PetType>;
        pet_hair_length: Array<PetType>;
        exotic_types: Array<PetType>;

    };
}

interface BusinessSettingsErrorAction extends Action<typeof BUSINESS_SETTINGS_ERROR_ACTION_TYPE> {}

export type BusinessSettingsAction =
    | BusinessSettingsFetchStartAction
    | BusinessSettingsFetchSuccessAction
    | BusinessSettingsErrorAction
