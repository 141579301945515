import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { WizardFooter, WizardHeader, WizardItem } from 'components/Wizard';

interface Props {
	step?: number;
	closePath: string;
	onSave?: () => void;
	onBack?: () => void;
	onNext?: () => void;
	items?: Array<WizardItem>;
	children: React.ReactNode;
	onClickClosePath?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
	isLoading?: boolean;
	isFooter: boolean;
	saveLabel?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '100%',
			width: '100%',
			display: 'flex',
			flexDirection: 'column'
		},
		container: {
			flex: 1,
			overflowY: 'auto',
			padding: '32px 16px',

			[theme.breakpoints.up('md')]: {
				padding: '65px 0px'
			}
		}
	})
);

function WizardPage({
	closePath,
	step,
	items,
	children,
	onNext,
	onSave,
	onBack,
	onClickClosePath,
	isLoading,
	isFooter,
	saveLabel
}: Props) {
	const classes = useStyles();

	const showBack = () => {
		if (step !== undefined) return step > 1;
	};

	const lastItemActive = () => {
		if (items && items.length > 0) return step === items.length;
	};

	return (
		<Box className={classes.root}>
			<WizardHeader
				step={step}
				items={items}
				closePath={closePath}
				onClickClosePath={onClickClosePath}
			/>

			<Box className={classes.container}>{children}</Box>

			{isFooter && onSave ? (
				<WizardFooter
					isLoading={isLoading}
					saveLabel={saveLabel}
					onSave={onSave}
					showBack={showBack()}
					showSave={lastItemActive()}
					onBack={() => onBack && onBack()}
					onNext={() => onNext && onNext()}
				/>
			) : null}
		</Box>
	);
}

export default WizardPage;
