import React, { Fragment, FunctionComponent } from 'react';
import { faCommentAltLines } from '@fortawesome/pro-regular-svg-icons';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import IconCircle from 'components/UI/IconCircle';
import TitlePrimary from 'components/UI/Title/TitlePrimary';
import { reduceResolution, wbp } from 'Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            fontSize: 15,
            lineHeight: 1.4,
            [theme.breakpoints.down(wbp)]: {
                paddding: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px'
            },
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        icon: {
            margin: '0px auto 15px'
        },
        paragraph: {
            fontSize: 15,
            lineHeight: 1.4,
            marginBottom: 20
        },
        button: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: 35,
            paddingRight: 35,
            margin: '0px auto',
            display: 'inline-block'
        }
    })
);

const ChatIsEmpty: FunctionComponent = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <Box className={classes.root}>
                <IconCircle icon={faCommentAltLines} className={classes.icon} />
                <TitlePrimary>No Chats found</TitlePrimary>
                <Typography className={classes.paragraph}>
                    Click the “New Chat” button to start a new conversation
                </Typography>
            </Box>
        </Fragment>
    );
};

export default ChatIsEmpty;
