import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { Chip2 as Chip } from 'components/UI';
import clsx from 'clsx';

interface ChipsProps {
    icon: JSX.Element;
    selectedTags: Array<Option<string>>;
    tags: Array<Option<string>>;
    className?: string;
    disabled?: boolean;
    onChangeSelection?: (tags: Array<Option<string>>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 12,
            display: 'flex'
        },
        flex: {
            gap: 12,
            display: 'inline-flex',
            flexWrap: 'wrap'
        },
        icon: {
            fontSize: 22
        }
    })
);

const Chips: FunctionComponent<ChipsProps> = props => {
    const classes = useStyles();

    const toggleHandler = (tag: Option<string>, selectedTags: Array<Option<string>>) => {
        const isSelected = selectedTags.some(selectedTag => selectedTag.id === tag.id);

        props.onChangeSelection &&
            props.onChangeSelection(
                isSelected ? selectedTags.filter(selectedTag => selectedTag.id !== tag.id) : [...selectedTags, tag]
            );
    };

    return (
        <Grid item xs={12} className={clsx(classes.container, props.className)}>
            <Grid className={classes.icon}>{props.icon}</Grid>
            <Grid className={classes.flex}>
                {props.tags.map(tag => (
                    <Chip
                        disabled={props.disabled}
                        key={tag.id}
                        label={tag.name}
                        selected={props.selectedTags.some(selected => selected.id === tag.id)}
                        onClick={() => {
                            toggleHandler(tag, props.selectedTags);
                        }}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

export default Chips;
