import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { Fragment, FunctionComponent } from 'react';
import Title from '../ui/Title';
import { SearchClient } from './UI/SearchClient';
import { ClientData } from 'model/SingleSale';
import ClientInfo from './UI/ClientInfo';

interface SingleSaleHeaderProps {
	errors: Array<FieldError>;
	className?: string;
	data: ClientData;
	onAddNewClient: () => void;
	onClientSelected: (clientID: number | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%'
		},
		containerSearch: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		boxSearch: {
			paddingTop: 32,
			paddingBottom: 24,

			[theme.breakpoints.up('md')]: {
				paddingTop: 40,
				paddingBottom: 20
			}
		},
		errorBorder: {
			'& fieldset': {
				'borderColor': '#EF4F57 !important',
				'&:hover': {
					borderColor: '#EF4F57 !important'
				}
			}
		}
	})
);

export const SingleSaleHeader: FunctionComponent<
	SingleSaleHeaderProps
> = props => {
	const classes = useStyles();

	const hasError = props.errors?.some(error => error.fieldName === 'client');

	return (
		<Fragment>
			<Grid container className={clsx(classes.container)}>
				<Grid
					item
					xs={12}
					md={4}
					xl={3}
					className={clsx(
						classes.containerSearch,
						{ [classes.errorBorder]: hasError },
						props.className
					)}
				>
					<Box>
						<Title text="New Sale" />
					</Box>
					<Box className={classes.boxSearch}>
						<SearchClient
							onAddClient={props.onAddNewClient}
							onSelectClient={props.onClientSelected}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={5}>
					{props.data && <ClientInfo data={props.data} />}
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default SingleSaleHeader;
