import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CalculatingSpinner } from 'components/UI';
import { FunctionComponent } from 'react';

interface StrongItemProps {
	label: string;
	value: string | number;
	bottomLine?: boolean;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		row: (props: StrongItemProps) => ({
			'width': '100%',
			'display': 'flex',
			'padding': '16px 0px',
			'alignItems': 'center',
			'justifyContent': 'space-between',
			'borderBottom': props.bottomLine ? '1px solid #D4D4D4' : undefined,

			'&:first-child': {
				paddingBottom: 8
			}
		}),
		text: {
			color: '#000',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600
		}
	})
);

export const StrongItem: FunctionComponent<StrongItemProps> = props => {
	const classes = useStyles(props);

	return (
		<Box className={clsx(classes.row, props.className)}>
			<Typography className={classes.text}>{props.label}</Typography>
			{props.loading ? (
				<CalculatingSpinner />
			) : (
				<Typography className={classes.text}>{props.value}</Typography>
			)}
		</Box>
	);
};

export default StrongItem;
