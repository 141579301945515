import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    createStyles,
    FormLabel,
    makeStyles,
    TextField,
    Theme
} from '@material-ui/core';
import SMSCreateFormGroup from './SMSCreateFormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDollar } from '@fortawesome/pro-solid-svg-icons';
import MessageEditor from './ui/MessageEditor';

interface Props {
    values: { name: string; message: string; unsubscribe: string };
    errors: { name: boolean; message: boolean; unsubscribe: boolean };
    onChange: (values: {
        name: string;
        message: string;
        unsubscribe: string;
    }) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            'width': '100%',
            'borderRadius': 16,
            'marginBottom': 35,
            'display': 'flex',
            'padding': '10px 20px',
            'backgroundColor': '#F8F5F1',
            'border': '2px solid #BCB8AE',
            '& svg': {
                marginTop: 2,
                fontSize: 24,
                marginRight: 11,
                color: '#EAB464'
            },
            '& h6': {
                margin: 0,
                fontSize: 15,
                fontWeight: 600,
                lineHeight: 1.6,
                color: '#222',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 14
                }
            },
            '& p': {
                'margin': 0,
                'padding': 0,
                'fontSize': 15,
                'fontWeight': 400,
                'lineHeight': 1.6,
                'color': '#222',
                [theme.breakpoints.down('sm')]: {
                    fontSize: 14
                },

                '& b': {
                    fontWeight: 600
                }
            }
        },
        input: {
            'width': '100%',
            '& .MuiOutlinedInput': {
                '&-root': {
                    borderRadius: 30
                },
                '&-input': {
                    paddingLeft: 30,
                    paddingRight: 30
                }
            },
            '& .MuiFormLabel': {
                '&-root.Mui-focused': {
                    color: '#222'
                },
                '&-filled': {
                    display: 'none'
                },
                '&-asterisk': {
                    color: '#EAB464',
                    fontWeight: 500
                }
            },
            '& .MuiInputLabel-outlined': {
                'transform': 'translate(30px, 19px) scale(1)',

                '&.MuiInputLabel-shrink': {
                    transform: 'translate(30px, -20px) scale(0.75)'
                }
            },
            '& fieldset': {
                'top': 0,
                'borderColor': 'rgba(0, 0, 0, 0.23)',

                '& legend': {
                    display: 'none'
                }
            },
            '& .Mui-focused': {
                '& fieldset': {
                    borderWidth: '1px !important',
                    borderColor: '#222 !important'
                }
            }
        },
        formInfoTitle: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
                margin: '0 0 20px !important'
            }
        },
        formNoteTitle: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
                margin: '0 0 34px !important'
            }
        },
        divider: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '19px !important'
            }
        }
    })
);

const SMSCreateFirstStep: FunctionComponent<Props> = ({
    values,
    errors,
    onChange
}) => {
    const classes = useStyles();

    const [showAlert, setShowAlert] = useState<boolean>(true);
    const [name, setName] = useState<string>(values.name);
    const [message, setMessage] = useState<string>(values.message);
    const [unsubscribe, setUnsubscribe] = useState<string>(values.unsubscribe);

    const [dirty, setDirty] = useState<{
        name: boolean;
        message: boolean;
        unsubscribe: boolean;
    }>({ name: false, message: false, unsubscribe: true });

    useEffect(() => {
        if (window.location.hash === '#first-time') setShowAlert(false);
    }, []);

    useEffect(() => {
        onChange({ name, message, unsubscribe });
    }, [name, message, unsubscribe]);

    useEffect(() => setDirty({ ...dirty, name: true }), [name]);
    useEffect(() => setDirty({ ...dirty, message: true }), [message]);
    useEffect(() => setDirty({ ...dirty, unsubscribe: true }), [unsubscribe]);

    const Alert = () => (
        <Box className={classes.alert}>
            <FontAwesomeIcon icon={faCommentDollar} />
            <Box>
                <h6>Campaign cost</h6>
                <p>
                    Send promotional SMS to your clients for only <b>5¢/each</b>
                </p>
            </Box>
        </Box>
    );

    return (
        <Fragment>
            {showAlert && <Alert />}
            <SMSCreateFormGroup>
                <FormLabel className={classes.formInfoTitle}>
                    Campaign Information
                </FormLabel>
                <TextField
                    required
                    value={name}
                    variant="outlined"
                    label="Campaign name"
                    className={classes.input}
                    {...(dirty.name && { error: errors.name })}
                    onChange={($e: React.ChangeEvent<HTMLInputElement>) =>
                        setName($e.currentTarget.value)
                    }
                />

                <small>Not visible to clients.</small>

                <hr className={classes.divider} />
            </SMSCreateFormGroup>

            <SMSCreateFormGroup>
                <FormLabel className={classes.formNoteTitle}>
                    Text Message
                </FormLabel>
                <MessageEditor
                    message={message}
                    {...(dirty.message && { error: errors.message })}
                    onChange={(value: string) => setMessage(value)}
                />
            </SMSCreateFormGroup>
        </Fragment>
    );
};

export default SMSCreateFirstStep;
