import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SummaryCard from 'components/UI/SummaryCard';
import { FunctionComponent } from 'react';
import { Option } from '@spike/model';

interface DetailTableSummaryProps {
	loading?: boolean;
	totals: Array<Option<number | string>>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			gap: 16,
			marginTop: 18,
			justifyContent: 'flex-start',

			[theme.breakpoints.up('md')]: {
				marginTop: 32
			}
		},
		border: {
			borderRadius: 14,
			border: '1px solid #D4D4D4'
		}
	})
);

export const DetailTableSummary: FunctionComponent<
	DetailTableSummaryProps
> = props => {
	const classes = useStyles();

	return (
		<Grid container className={classes.container}>
			{props.totals.map(total => {
				return (
					<Grid key={String(total.name)} item xs={12} md={2}>
						<SummaryCard
							title={total.description ?? ''}
							value={total.id || 0}
							loading={props.loading}
							color="white"
							className={classes.border}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default DetailTableSummary;
