import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "store";
import _ from "lodash";
import clsx from "clsx";
import { MarketplaceRequirements } from "@spike/marketplace-model";
import { Button } from "components/UI";
import { SelectableOption } from "model";
import { useMasterData } from "hooks";
import { Accordion, Switch } from "components/UI";
import { useToggle } from "hooks";
import { AddOther } from "../UI/AddOther";
import { OptionType } from "../PetTypes/UI/OptionType";
import { useNonInitialEffect } from "@versiondos/hooks";
import { MarketplaceStatus } from "@spike/marketplace-action";
import { BusinessSettingsTitle } from "../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  requirements: MarketplaceRequirements;
  onSave?: (requirements: MarketplaceRequirements) => void;
  className?: string;
  openSidebar?: () => void;
  isMobile?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileBody: {
      overflow: "hidden"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.only("lg")]: {
        width: "525px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "565px",
      },
    },
    containerNew:{
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
        position: "relative",
        paddingBottom: "145px",
        display: "block",
        "@supports (-webkit-touch-callout: none)": {
          paddingBottom: "160px",
        }
      }
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    businessSettingsTittle: {
      'display': 'flex',
      'alignItems': 'center',
      '& $iconBusinessTitle': {
        fontSize: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        'fontSize': '20px',
        'marginBottom': '25px',
        '& $iconBusinessTitle': {
          fontSize: '20px',
          fontWeight: '300'
        }
      }
    },
    iconBusinessTitle: {
      position: "relative",
      marginRight: "8px"
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "& .MuiAccordionDetails-root": {
        padding: "0px",
      },
    },
    contentContainerMobile:{
      position: "relative"
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "27px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "44px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "58px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "88px",
      },
    },
    accordionTitle: {
      borderBottom: "1px solid #EFEFEF",
      padding: 0,
      alignItems: "center",
      "& .MuiAccordionSummary-content": {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
          paddingTop: "20px",
          fontSize: "6px",
          width: "100%",
        },
        [theme.breakpoints.only("md")]: {
          paddingTop: "20px",
          fontSize: "16px",
          width: "100%"
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
        },
      },
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "inherit",
        transform: "rotate(0deg)",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "row",
          border: "none"
        },
        [theme.breakpoints.only("md")]: {
          display: "flex",
          flexDirection: "row",
          border: "none"
        },
      },
      "& .MuiAccordionSummary-content.Mui-expanded": {
        [theme.breakpoints.down("sm")]: {
          margin: "12px 0",
        },
        [theme.breakpoints.only("md")]: {
          margin: "6px 0",
        },
        [theme.breakpoints.only("lg")]: {
          margin: "8px 0",
        },
        [theme.breakpoints.only("xl")]: {
          margin: "12px 0",
        },
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "46px",
        border: "none"
      },
    },
    titleSection: {
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "100%",
        marginBottom: "5px"
      },
      [theme.breakpoints.only("lg")]: {
        lineHeight: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        lineHeight: "16px",
      },
    },
    requirements: {
      "& .MuiCheckbox-root": {
        paddingLeft: "0px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
          paddingRight: "4px !important",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "16px",
          paddingRight: "6px !important",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "16px",
          paddingRight: "8px !important",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
          paddingRight: "12px !important",
        },
      },
      "& .MuiTypography-root": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "6px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "24px",
      },
    },
    addOtherContainer: {
      [theme.breakpoints.only("lg")]: {
        width: "198px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "300px",
      },
      
      "& .MuiInputBase-root": {
        padding: "0px !important",
      },
      [theme.breakpoints.down("md")]: {
         "& p":{

        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "solid 1px #D3D3D3 !important",
          height: "40px",
          borderRadius: "25px",
          marginLeft: "32px",
          width: "100%"
        },
        "& input[type=text]": {
          paddingTop: "9px",
          paddingBottom: "10px",
          paddingLeft: "14px",
          marginLeft: "10px",
          width: "100%"
        },
        "& .MuiInputBase-adornedStart": {
          width: "calc(100% - 34px)",
          "& input":{
            width: "100%",
            paddingLeft: "7px"
          },
          "& fieldset": {
            top: "-3px"
          },
        },
        "& fieldset": {
          width: "100%",
          top: "2px"
        },
        
      },
      
    },
    circle: {
      color: "000",
      [theme.breakpoints.down("md")]: {
        width: "14px",
        height: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "18px",
        height: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "24px",
        height: "24px",
      },
    },
    spayAndNeuter: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #EFEFEF",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        borderBottom: "none"
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "17px",
        borderBottom: "none"
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "22px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "34px",
      },
      "& .MuiTypography-root": {
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "100%",
          marginBottom: "3px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "16px",
          lineHeight: "15px",
          marginBottom: "5px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "16px",
          lineHeight: "20px",
          marginBottom: "7px",
        },
        [theme.breakpoints.only("xl")]: {
          fontSize: "16px",
          lineHeight: "30px",
          marginBottom: "11px",
        },
      },
    },
    typeContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
        width: "100%",
        "& > div:nth-last-child(2)":{
          marginBottom: "10px"
        }
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "13px",
        width: "100%"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "26px",
      },
    },
    separator: {
      display: 'none',
      backgroundColor: '#F4F3F0',
      height: '16px',
      width: '110%',
      marginLeft: '-5%',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    buttonContainerFull: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "absolute",
        bottom: "60px",
        left: 0,
        paddingLeft: "15px",
        paddingRight: "15px",
        "& button": {
          width: "100%",
          height: "50px",
          borderRadius: "31px",
          "& .MuiButton-label": {
            fontSize: "16px"
          }
        },
        "@supports (-webkit-touch-callout: none)": {
          bottom: "75px"
        }
      },
    },
  })
);

export const PetRequirements: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const masterData = useMasterData();

  const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const [requirements, setRequirements] = useState<Array<SelectableOption<string>>>(
    masterData.vaccines.map((v) => ({
      element: v,
      selected: props.requirements.requirements.some((r) => r.id === v.id),
    }))
  );

  const [otherRequirements, setOtherRequirements] = useState(props.requirements.otherRequirements);

  const [openSpayAndNeuter, handleOpenSpayAndNeuter] = useToggle(props.requirements.spayedAndNeureted);

  const [openType, handleOpenType] = useToggle(props.requirements.requirements.length > 0 || props.requirements.otherRequirements.length > 0);

  const getUpdatedRequirements = (): MarketplaceRequirements => {
    return {
      spayedAndNeureted: openSpayAndNeuter,
      requirements: openType ? requirements.filter(req => req.selected).map(req => req.element) : [],
      otherRequirements: openType ? [...otherRequirements] : [],
    };
  }

  useNonInitialEffect(() => {
    setSaved(_.isEqual(props.requirements, getUpdatedRequirements()));
  }, [openSpayAndNeuter, requirements, otherRequirements]);

  useNonInitialEffect(() => {
    switch(status) {
      case MarketplaceStatus.SaveSuccess:
        setSaved(true);
        setLoading(false);
        break;
      case MarketplaceStatus.Error:
        setLoading(false);
    }
  }, [status]);

  const addOtherHandler = (value: string) => {
    if (!otherRequirements.some(other => other.toLowerCase() === value.toLowerCase())) {
      setOtherRequirements(prev => [...prev, value]);
    }
  };

  const saveHandler = () => {
    props.onSave && setLoading(true);
    props.onSave && props.onSave(getUpdatedRequirements());
  };

  return (
    <Box className={clsx(classes.container, props.className, classes.containerNew)}>
      <Box className={classes.titleContainer}>
        <BusinessSettingsTitle className={classes.businessSettingsTittle} subtitle="Select the requirements needed for pets to attend your facility."> {props.isMobile && (
          <FontAwesomeIcon
            className={classes.iconBusinessTitle}
            onClick={props.openSidebar}
            icon={faArrowLeft}
            size="2x"
          />
        )} Pet Requirements</BusinessSettingsTitle>
      </Box>
      <Box className={clsx(classes.contentContainer, classes.contentContainerMobile)}>
        <Box className={classes.spayAndNeuter} onClick={handleOpenSpayAndNeuter}>
          <Typography>Spay and Neuter</Typography>
          <Switch checked={openSpayAndNeuter} onChange={handleOpenSpayAndNeuter} />
        </Box>

        <Box className={classes.separator}></Box>

        <Accordion
          withoutIcon
          expanded={openType}
          handleExpandedChange={handleOpenType}
          title={"Vaccinations"}
          titleStyle={classes.titleSection}
          titleContainerStyles={`${classes.accordionTitle}`}
          expandComponent={<Switch checked={openType} onChange={handleOpenType} />}
        >
          <Box className={classes.typeContainer}>
            {requirements.map((req, index) => {
              return (
                <OptionType
                  key={req.element.id}
                  index={index}
                  checked={req.selected}
                  optionName={req.element.name}
                  onTypeChange={(selected: boolean) => {
                    setRequirements(prev => prev.map(r => r.element.id === req.element.id ? {...req, selected} : r));
                  }}
                />
              );
            })}
            {otherRequirements.map((other, index) => {
              return (
                <OptionType
                  key={other}
                  index={index}
                  checked={true}
                  editable
                  optionName={other}
                  onEditName={(value) => setOtherRequirements(prev => prev.map((other, otherIndex) => otherIndex === index ? value : other))}
                  onTypeChange={(checked, index, des) => setOtherRequirements(prev => prev.filter((other, otherIndex) => otherIndex !== index))}
                />
              );
            })}
            <Box className={classes.addOtherContainer}>
              <AddOther placeholderText={"Vaccine Name"} onAddType={addOtherHandler} />
            </Box>
          </Box>
        </Accordion>
      </Box>
      <Box className={clsx(classes.buttonContainer, classes.buttonContainerFull)}>
        <Button label={saved ? "Saved" : "Save"} onClick={saveHandler} loading={loading} />
      </Box>
    </Box>
  );
};

export default PetRequirements;
