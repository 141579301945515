import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import StatusButton from './StatusButton';
import clsx from 'clsx';

interface BookingButtonProps {
	loading?: boolean;
	className?: string;
	//
	onConfirm: () => void;
	id?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		confirm: {
			'color': '#EAB464',
			'borderColor': '#EAB464',
			'backgroundColor': '#FFFFFF',
			'&:hover': {
				backgroundColor: '#FAEFDF',
				borderColor: '#EAB464'
			}
		},
		loading: {
			backgroundColor: '#EAB464',
			color: '#FFF'
		}
	})
);

export const BookingButton: FunctionComponent<BookingButtonProps> = props => {
	const classes = useStyles(props);

	return (
		<StatusButton
			label="Confirm"
			className={clsx(classes.confirm, props.className, {
				[classes.loading]: props.loading || false
			})}
			onClick={props.onConfirm}
			loading={props.loading}
			id={`${props.id || ''}`}
		/>
	);
};

export default BookingButton;
