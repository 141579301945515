import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCommonStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 48,
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: 0
            }
        }
    })
);
