import React, { FunctionComponent, DetailedHTMLProps, HTMLAttributes } from "react";
import { makeStyles, Theme } from "@material-ui/core";

export interface BadgeProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#000",
    fontSize: 15,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: 1.3,
    borderRadius: 6,
    textAlign: "center",
    backgroundColor: "#F4F3F0",
  },
}));

export const Badge: FunctionComponent<BadgeProps> = (props) => {
  const classes = useStyles();

  return (
    <div {...props} className={classes.root}>
      {props.children}
    </div>
  );
};

export default Badge;
