import React, { FunctionComponent, MouseEvent, useState } from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { VariableDuration } from "../../../model/Service";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface Props {
  variable: Array<VariableDuration>;
  hover?: boolean;
  onShow: (show: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start"
    },
    chip: {
      background: "#F1F1F1",
      cursor: "pointer",
      justifyContent: "start",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(165)}px`,
        height: `${reduceResolution(40)}px`,
        borderRadius: `${reduceResolution(100)}px`,
        marginBottom: `${reduceResolution(42)}px`
      },
      [theme.breakpoints.up(wbp)]: {
        width: "165px",
        height: "40px",
        borderRadius: "100px",
        marginBottom: "42px"
      },
    },
    durationContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    iconClock: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
        marginRight: `${reduceResolution(10)}px`,
        marginTop: `${reduceResolution(4)}px` 
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        marginRight: "10px",
        marginTop: "4px" 
      },
    },
    arrowIcon: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        marginLeft: `${reduceResolution(15)}px`,
        marginTop: `${reduceResolution(5)}px`
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        marginLeft: "15px",
        marginTop: "5px" 
      },
    },
    duration: {
      display: "flex"
    },
    durationTitle: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
      },
    },
    active: {
      border: "2px solid #000"
    },
    hover: {
      background: "#fff !important"
    }
  })
);

export const ShowDurationComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showExpandedDuration, setShowEspandedDuration] = useState(false);

  const clickMultipleDurationHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowEspandedDuration((prevValue) => {
      props.onShow(!prevValue);
      return !prevValue;
    });
  };

  return (
    <Box className={classes.container} onClick={clickMultipleDurationHandler}>
      <Chip
        label={
          <Box className={classes.durationContainer}>
            <Box className={classes.duration}>
              <FontAwesomeIcon icon={faClock} className={classes.iconClock} />
              <Typography className={classes.durationTitle}>Duration</Typography>
              <FontAwesomeIcon
                icon={showExpandedDuration ? faCaretUp : faCaretDown}
                className={classes.arrowIcon}
              />
            </Box>
          </Box>
        }
        className={clsx(classes.chip, {[classes.active]: showExpandedDuration, [classes.hover]: props.hover})}
      />
    </Box>
  );
};

export default ShowDurationComponent;
