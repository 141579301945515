import {PetTypeStatusEnum, PetTypeStatusState} from "./PetTypeStatusState";
import {
    BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE,
    BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE,
    BusinessSettingsAction
} from "../../actions/businessSettingsSidebar/BusinessSettingsActionsTypes";
import {Reducer} from "redux";

const initialState: PetTypeStatusState = {
    pet_size: [],
    pet_age: [],
    pet_hair_type: [],
    pet_hair_length: [],
    exotic_types: [],
    status: PetTypeStatusEnum.Initial
}
export const BusinessSettingsReducer: Reducer<PetTypeStatusState, BusinessSettingsAction> = (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE:
            return {
                ...state,
                status: PetTypeStatusEnum.Fetching
            };
        case BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                pet_age: action.payload.pet_age,
                pet_size: action.payload.pet_size,
                pet_hair_length: action.payload.pet_hair_length,
                pet_hair_type: action.payload.pet_hair_type,
                exotic_types: action.payload.exotic_types,
                status: PetTypeStatusEnum.FetchSuccess
            }
        default:
            return state;
    }
}

export default BusinessSettingsReducer;
