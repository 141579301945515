import React, { FunctionComponent } from 'react';
import { FormControl, InputAdornment, OutlinedInput } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from './Button';
import clsx from 'clsx';

export interface Props {
    value: string;
    name?: string;
    label: string;
    startIcon?: IconProp;
    blackButton?: boolean;
    classNameCustom?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    onClick?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%'
        },
        button: {
            'height': 39,
            'padding': '0px 28px',
            '& .MuiButton-label': {
                fontSize: 14,
                fontWeight: 600
            }
        },
        blackButton: {
            'color': '#FFFFFF',
            'backgroundColor': '#222222',
            '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#222222'
            }
        },
        input: {
            'fontSize': 16,
            'fontWeight': 400,
            'borderRadius': 30,
            'paddingRight': 8,

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                borderColor: '#222222 !important'
            },
            "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline":
                {
                    borderWidth: '1px !imporant',
                    borderColor: '#222222 !important'
                },
            "&.onlineBookingInput input:not([value='']) ~ .MuiOutlinedInput-notchedOutline":
                {
                    borderWidth: '1px !imporant',
                    borderColor: '#D3D3D3 !important'
                },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EA6464 !important'
            },
            '& input': {
                'height': 54,
                'padding': '0px 20px',

                '&::placeholder': {
                    opacity: 1,
                    color: '#7A7A7A'
                }
            },
            '&.Mui-disabled': {
                background: '#fafafa'
            }
        }
    })
);

export const TextFieldWithButton: FunctionComponent<Props> = ({
    value,
    name,
    label,
    startIcon,
    blackButton,
    classNameCustom,
    disabled,
    onChange,
    onClick
}) => {
    const classes = useStyles();

    const handleChange = (event: any): void => {
        onChange && onChange(event.target.value);
    };

    return (
        <FormControl variant="outlined" className={classes.container}>
            <OutlinedInput
                name={name}
                className={clsx(classes.input, classNameCustom)}
                id="outlined-adornment-password"
                type="text"
                placeholder="Bath, Nail Trimming, Haircut"
                value={value}
                onChange={handleChange}
                disabled={disabled}
                endAdornment={
                    <InputAdornment position="end">
                        <Button
                            color="black"
                            disabled={disabled}
                            label={label}
                            id="copy_button"
                            startIcon={startIcon}
                            className={clsx(classes.button, {
                                [classes.blackButton]: blackButton
                            })}
                            onClick={onClick}
                        />
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default TextFieldWithButton;
