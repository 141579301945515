const stateMapping: { [key: string]: string } = {
    florida: 'FL',
    fl: 'FL',
    colorado: 'CO',
    co: 'CO',
    california: 'CA',
    ca: 'CA'
};

const allowedStates = ['FL', 'CO', 'CA'];

export const normalizeState = (state: string): string => {
    return stateMapping[state.toLowerCase()] || state;
};

export const isStateAllowed = (state: string): boolean => {
    const normalizedState = normalizeState(state);
    return allowedStates.includes(normalizedState);
};
