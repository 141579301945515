import { Grid, GridProps, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface HeaderCellProps extends GridProps {
  title?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  })
);

export const HeaderCell: FunctionComponent<HeaderCellProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid item {...props} >
      <Typography className={classes.title}>{props.title}</Typography>
    </Grid>
  );
};

export default HeaderCell;
