import { Theme, useMediaQuery } from '@material-ui/core';
import { InvoiceLine, InvoiceLineType } from '@spike/invoice-model';
import { FieldError } from '@spike/model';
import { CalculatingSpinner, PriceField2 } from 'components/UI';
import React from 'react';

type ItemSubtotalProps = {
	invoiceLine: InvoiceLine;
	invoiceLineType: InvoiceLineType;
	errors?: Array<FieldError>;
	calculating?: boolean;
	inputClassName?: string;
	onChange: (value?: number, name?: string) => void;
};

export const ItemSubtotal: React.FunctionComponent<
	ItemSubtotalProps
> = props => {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const shouldShowTotal =
		isMobile || props.invoiceLineType !== InvoiceLineType.Custom;

	return (
		<>
			{props.calculating ? (
				<CalculatingSpinner />
			) : shouldShowTotal ? (
				`$${props.invoiceLine.total}`
			) : (
				<PriceField2
					scale={2}
					precision={6}
					value={Number(props.invoiceLine.subtotal)}
					className={props.inputClassName}
					name={`${props.invoiceLine.uuid}_price`}
					errors={props.errors}
					onChange={props.onChange}
				/>
			)}
		</>
	);
};
