import {
	faCat,
	faDog,
	faPlus,
	faUser,
	IconDefinition
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { searchCustomersThunk } from 'actions/autocomplete/AutocompleteActions';
import { clearClientsThunk } from '@spike/clients-action';
import { clearPetsThunk } from '@spike/pets-action';
import clsx from 'clsx';
import { TextFieldNoLabel } from 'components/UI';
import AutocompleteResult from 'model/AutocompleteResult';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	AutocompleteState,
	AutocompleteStatus
} from 'reducers/autocomplete/AutocompleteState';
import { RootState } from 'store';
import { useCommonStyles } from 'components/UI/CommonStyles';
import { reduceResolution, wbp } from 'Theme';
interface SearchClientProps {
	onAddClient: () => void;
	onSelectClient: (clientID: number | undefined) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxItem: {
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(5)}px 0`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '5px 0'
			},
			'&:hover': { background: '#eee' }
		},
		field: {
			'fontSize': 16,
			'width': '100%',

			'&.makeStyles-field-156 .MuiInputBase-root': {
				height: '54px',
				borderRadius: '33px',
				paddingLeft: '20px'
			},
			'&.makeStyles-field-156 input': {
				height: '54px',
				fontSize: '16px'
			},
			'& .MuiInputBase-input': {
				paddingLeft: 35,
				paddingRight: 16
			},

			[theme.breakpoints.up('md')]: {
				width: '406px'
			}
		},
		fieldSearch: {
			'border': '0 !important',

			'& .MuiOutlinedInput-root': {
				border: '10px !important',
				borderRadius: '30px 30px 0 0'
			}
		},
		wrapperResult: {
			width: '100%',
			position: 'relative'
		},
		boxResult: {
			width: '100%',
			cursor: 'default',
			position: 'absolute',
			backgroundColor: '#fff',
			borderLeft: '1px solid #eee',
			borderRight: '1px solid #eee',
			borderBottom: '1px solid #eee',
			borderRadius: '0 0 20px 20px',
			padding: '8px 10px 10px 10px',

			[theme.breakpoints.up('md')]: {
				width: '406px'
			}
		},

		boxTitle: {
			paddingBottom: 16,

			[theme.breakpoints.up('md')]: {
				paddingBottom: 10
			}
		},

		parentTitle: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600
		},
		chipParent: {
			'height': 54,
			'fontSize': 16,
			'width': '100%',
			'background': '#fff',
			'border': '1px solid #222',
			'paddingLeft': 35,
			'paddingRight': 16,

			[theme.breakpoints.up('md')]: {
				padding: '10px 10px'
			},

			'& .MuiChip-deleteIcon': {
				margin: 0
			},
			'&.MuiChip-root': {
				borderRadius: '33px !important',
				justifyContent: 'space-between !important',
				paddingLeft: '25px !important',

				[theme.breakpoints.up('md')]: {
					margin: '40px 0 28px 0!important'
				}
			},
			'& .MuiChip-label': {
				padding: 0
			}
		},

		clientLink: {
			'cursor': 'pointer',
			'textDecoration': 'none',
			'color': '#000',
			'width': '180px',
			'alignItems': 'center',
			'&:hover': {
				color: '#EAB464'
			}
		},

		addNewButton: {
			marginTop: 16,

			[theme.breakpoints.up(wbp)]: {
				marginTop: '5px',
				padding: '14px 16px'
			}
		},
		listAlignedIcons: {
			width: '16px !important'
		},
		parentReadOnly: {
			fontWeight: 600,
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(20)}px ${reduceResolution(0)}px`,
				fontSize: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '20px 0',
				fontSize: '16px'
			}
		},
		sectionIcon: {
			marginRight: '0.5rem'
		},

		boxLoading: {
			textAlign: 'center'
		},

		loading: {
			color: '#AAA'
		},

		desktop: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		mobile: {
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}
	})
);

export const SearchClient: FunctionComponent<SearchClientProps> = props => {
	const classes = useStyles();

	const commonClasses = useCommonStyles();

	const [parentName, setParentName] = useState<string | undefined>();
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [showSearchField, setShowSearchField] = useState(true);
	const [searchingParent, setSearchingParent] = useState(false);
	const [resultParent, setResultParent] = useState(true);

	const parent = useSelector<RootState, AutocompleteState>(
		state => state.autocomplete
	);
	const parents = useSelector<RootState, Array<AutocompleteResult>>(
		state => state.autocomplete.results
	);

	const dispatch = useDispatch();

	const iconSVG = (icon: IconDefinition, styles: string) => {
		return <FontAwesomeIcon icon={icon} className={styles} />;
	};

	useEffect(() => {
		if (parent.status === AutocompleteStatus.SearchingSuccess) {
			setSearchingParent(false);
			parents.length === 0
				? setResultParent(false)
				: setResultParent(true);
		}

		if (parent.status !== AutocompleteStatus.SearchingSuccess) {
			setSearchingParent(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parent.status]);

	const changeHandler = (value: string, name: string) => {
		setParentName(value);
		setShowSearchResult(true);

		if (value === '') {
			setShowSearchResult(false);
		} else {
			dispatch(searchCustomersThunk(value));
		}
	};

	const handlerClient = (value: AutocompleteResult) => {
		setShowSearchResult(false);
		setShowSearchField(false);
		setParentName(value.name);
		props.onSelectClient(value.id);
	};

	const camelize = (str: string) => {
		return str.replace(/\b(\w)/g, s => s.toUpperCase());
	};

	const handlerClearSearch = () => {
		setParentName('');
		setShowSearchField(true);
		setShowSearchResult(false);
		//
		props.onSelectClient(undefined);
	};

	const addNewClient = () => {
		props.onAddClient();
		dispatch(clearClientsThunk());
		dispatch(clearPetsThunk());
	};

	const searchIconType = (parent: AutocompleteResult) => {
		let icon;

		switch (parent.type) {
			case 'Customer':
				icon = iconSVG(
					faUser,
					`${classes.sectionIcon} ${classes.listAlignedIcons}`
				);
				break;
			case 'Pet':
				switch (parent.petType?.singular) {
					case 'Cat':
						icon = iconSVG(
							faCat,
							`${classes.sectionIcon} ${classes.listAlignedIcons}`
						);
						break;
					default:
						icon = iconSVG(
							faDog,
							`${classes.sectionIcon} ${classes.listAlignedIcons}`
						);
						break;
				}
				break;
		}

		return icon;
	};

	const loadingView = (
		<Box className={classes.boxLoading}>
			<CircularProgress size="1.5rem" className={classes.loading} />
		</Box>
	);

	const searchFieldView = (
		<>
			<Box className={clsx(commonClasses.flexRow, classes.boxTitle)}>
				<Typography
					className={clsx(
						commonClasses.typo16_400_uppercase,
						classes.desktop
					)}
				>
					Client
				</Typography>
				<Typography
					className={clsx(classes.parentTitle, classes.mobile)}
				>
					Client
				</Typography>
			</Box>
			<TextFieldNoLabel
				placeholder="Search by email or client name"
				name="name"
				value={parentName ? parentName : ''}
				className={clsx(classes.field, {
					[classes.fieldSearch]: showSearchResult
				})}
				onChange={changeHandler}
				autoComplete="off"
			/>
		</>
	);

	const parentSelectedView = (
		<Box>
			<Box className={clsx(classes.boxTitle, classes.mobile)}>
				<Typography className={classes.parentTitle}>Client</Typography>
			</Box>
			<Chip
				label={parentName}
				variant="default"
				className={classes.chipParent}
				//
				onDelete={handlerClearSearch}
			/>
		</Box>
	);

	const clientLinkView = (
		<Box className={classes.addNewButton}>
			<Box
				onClick={() => addNewClient()}
				className={clsx(classes.clientLink, commonClasses.flexRow)}
			>
				{iconSVG(
					faPlus,
					`${classes.sectionIcon} ${classes.listAlignedIcons}`
				)}{' '}
				<Typography className={commonClasses.typo16_500}>
					Add New Client
				</Typography>
			</Box>
		</Box>
	);
	//

	return (
		<Box className={props.className}>
			{showSearchField && searchFieldView}
			{!showSearchField && parentSelectedView}
			{showSearchResult && (
				<Box className={classes.wrapperResult}>
					<Box className={classes.boxResult}>
						{searchingParent && loadingView}
						{!searchingParent && !resultParent && (
							<Box>Pet parent no found</Box>
						)}
						{!searchingParent &&
							resultParent &&
							parents.map((parent, index) => (
								<Box
									key={parent.id + parent.name}
									className={classes.boxItem}
									onClick={() => handlerClient(parent)}
								>
									{searchIconType(parent)}{' '}
									{camelize(parent.name)}
								</Box>
							))}
					</Box>
				</Box>
			)}
			{!parentName && clientLinkView}
		</Box>
	);
};

export default SearchClient;
