import React, { FunctionComponent } from 'react';
import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import BadgeLabel from './BadgeLabel';
import { Button } from 'components/UI';
import { Link as LinkRouter } from 'react-router-dom';
import { PaymentsPath } from 'routes/Paths';
import { useMarketplace } from 'hooks';
import { reduceResolution, wbp } from 'Theme';
import { useDispatch } from 'react-redux';
import { onboardingCloseModal } from 'actions/onboardingSteps/OnboardingStepsActions';

interface Props {
    isNew: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#FAEFDF',

            [theme.breakpoints.down(wbp)]: {
                borderRadius: `${reduceResolution(10)}px`,
                paddingLeft: `${reduceResolution(20)}px`,
                paddingRight: `${reduceResolution(20)}px`,
                paddingBottom: `${reduceResolution(5)}px`,
                gap: `${reduceResolution(3.63)}px`,
                marginTop: `${reduceResolution(25)}px`,
                marginLeft: `${reduceResolution(10)}px`,
                height: `${reduceResolution(141)}px`,
                width: `${reduceResolution(409)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: '5px',
                gap: '3.63px',
                marginTop: '25px',
                height: '141px',
                width: '409px'
            }
        },
        logo: {},
        payBagde: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(20)}px`,
                marginTop: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '20px',
                marginTop: '15px'
            },
            fontWeight: 600
        },
        titleContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(12)}px`,
                gap: `${reduceResolution(4.5)}px`,
                marginBottom: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '12px',
                gap: '4.5px',
                marginBottom: '12px'
            }
        },
        title: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(5)}px`,
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '5px',
                fontSize: '14px'
            },
            fontWeight: 500
        },

        learnMoreText: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            },
            fontWeight: 500,
            cursor: 'pointer'
        },
        posImg: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(141)}px`,
                width: `${reduceResolution(65)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '141px',
                width: '65px'
            }
        },
        links: {
            textDecoration: 'none',
            color: '#222222'
        },
        buttons: {
            marginTop: '10px'
        },
        buttonPay: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(97)}px`,
                height: `${reduceResolution(35)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '97px',
                height: '35px'
            }
        }
    })
);

export const OnBoardingPaymentsFlyer: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleStartNowClick = () => {
        dispatch(onboardingCloseModal());
    };

    const isSpike =
        process.env.REACT_APP_HOST_URL?.toLowerCase() ===
        'https://spike-api.versiondos.com';

    return (
        <Grid className={classes.container}>
            <Grid container className={classes.titleContainer}>
                <img
                    src="/images/logo/logo.png"
                    alt="Logo"
                    className={classes.logo}
                />
                <BadgeLabel
                    className={classes.payBagde}
                    label="Pay"
                    fontColor="#FFF"
                    bgColor="#92B4A7"
                    hideIcon={true}
                />
                <Typography className={classes.title}>
                    Fast, secure and easy payments
                </Typography>

                <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.buttons}
                >
                    <Grid item xs={6}>
                        <LinkRouter to={PaymentsPath} className={classes.links}>
                            <Button
                                label="Start Now"
                                size="small"
                                onClick={handleStartNowClick}
                                className={classes.buttonPay}
                            />
                        </LinkRouter>
                    </Grid>

                    <Grid item xs={6}>
                        <LinkRouter
                            to={{
                                pathname: isSpike
                                    ? 'https://rocketdev.space/staging/cuddleslanding/payments/'
                                    : 'https://cuddlesapp.com/payments'
                            }}
                            target="_blank"
                            className={classes.links}
                        >
                            <Typography className={classes.learnMoreText}>
                                Learn More
                            </Typography>
                        </LinkRouter>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <img
                    src="/images/onboarding/img-pos.png"
                    alt="pos"
                    className={classes.posImg}
                />
            </Grid>
        </Grid>
    );
};
