import { faBell } from '@fortawesome/pro-light-svg-icons';
import { Option } from '@spike/model';

import { FunctionComponent } from 'react';
import EditSetting from './EditSetting';

export interface Props {
    onEdit?: (
        emailChecked: boolean,
        smsChecked: boolean,
        noticeSelected: Option<string>,
        optionId: string
    ) => void;
    loading: boolean;
}

export const EditAppointments: FunctionComponent<Props> = props => {
    const key = 'client_appointment_reminder';

    const noticeOptions = [
        { id: '2', name: '2 hrs before' },
        { id: '4', name: '4 hrs before' },
        { id: '8', name: '8 hrs before' },
        { id: '12', name: '12 hrs before' },
        { id: '24', name: '1 day before' },
        { id: '48', name: '2 days before' },
        { id: '72', name: '3 days before' }
    ];

    const handleEdit = (
        emailChecked: boolean,
        smsChecked: boolean,
        noticeSelected: Option<string>
    ) => {
        props.onEdit &&
            props.onEdit(emailChecked, smsChecked, noticeSelected, key);
    };

    const subtitle = <>Reminds clients of their upcoming appointment.</>;

    return (
        <EditSetting
            optionId={key}
            noticeOptions={noticeOptions}
            headerIcon={faBell}
            title={'Appointment Reminder'}
            subtitle={subtitle}
            loading={props.loading}
            defaultOption={noticeOptions[4]}
            onEdit={handleEdit}
        />
    );
};

export default EditAppointments;
