import { OnboardingSteps } from "model/OnboardingSteps";
import OnboardingStepsDto from "./OnboardingStepsDtos";

export const convertOnboardingSteps = (
  settings_status: OnboardingStepsDto
): OnboardingSteps => {
  return {
    businessHoursDone: settings_status.calendar_done,
    clientsDone: settings_status.customers_done,
    servicesDone: settings_status.services_done,
    teamDone: settings_status.staffs_done,
    petTypesDone: settings_status.pet_types_done,
    bookingDone: settings_status.booking_done
  };
};
