import React, { FunctionComponent } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
    className?: string;
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'width': '100%',
            'marginBottom': 35,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 19
            },

            '& > p': {
                color: '#000',
                fontSize: 15,
                fontWeight: 400,
                lineHeight: 1.4,
                marginTop: -20,
                marginBottom: 30
            },

            '& small': {
                marginTop: 7,
                fontSize: 13,
                fontWeight: 500,
                lineHeight: 2.05,
                color: '#7A7A7A',
                display: 'block',
                paddingLeft: 25
            },

            '& hr': {
                border: 0,
                marginTop: 30,
                borderTop: '1px solid #D4D4D4'
            },

            '& > .MuiFormLabel-root': {
                'color': '#222',
                'fontSize': 18,
                'lineHeight': 1,
                'fontWeight': 600,
                'marginBottom': 40,
                'display': 'block',

                '& mark': {
                    color: '#EAB464',
                    background: 'none'
                }
            }
        }
    })
);

const SMSCreateFormGroup: FunctionComponent<Props> = ({
    children,
    className
}) => {
    const classes = useStyles();

    return <Box className={clsx(classes.root, className)}>{children}</Box>;
};

export default SMSCreateFormGroup;
