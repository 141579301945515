import React, { FunctionComponent } from "react";
import { TextField as MUITextField } from "@material-ui/core";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { commonStyles } from "Theme";
import clsx from "clsx";
export interface Props {
  label: string;
  value?: number;
  onChange: (year?: number) => void;
  minYear?: number;
  maxYear?: number;
  error?: boolean;
  className?: string;
}

export const YearField: FunctionComponent<Props> = (props) => {
  const commonClasses = commonStyles();

  const validation = (value: NumberFormatValues): boolean => {
    const year = value.floatValue;
    const min: number = parseInt(
      (props.minYear || 1900).toString().substr(0, year?.toString().length)
    );
    const max: number = parseInt(
      (props.maxYear || 2099).toString().substr(0, year?.toString().length)
    );
    return year === undefined || (year >= min && year <= max);
  };

  return (
    <NumberFormat
      value={props.value}
      allowNegative={false}
      decimalScale={0}
      allowLeadingZeros={false}
      customInput={MUITextField}
      variant="outlined"
      onValueChange={(values) => {
        props.onChange(values.floatValue);
      }}
      className={clsx(commonClasses.field, props.className)}
      onFocus={(event: any) => event.target.select()}
      isAllowed={validation}
      placeholder={props.label}
      error={props.error}
    />
  );
};

export default YearField;
