import {
	Box,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import {
	InvoiceDiscount as InvoiceDiscountModel,
	InvoiceLine
} from '@spike/invoice-model';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import CalculatingSpinner from 'components/UI/CalculatingSpinner';
import toNumber from 'lodash/toNumber';
import { Fragment, FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import CancelButton from './buttons/CancelButton';
import EditButton from './buttons/EditButton';
import SaveButton from './buttons/SaveButton';
import { useCommonStyles } from './CommonStyles';
import InvoiceAmount from './InvoiceAmount';
import InvoiceStaff from './InvoiceStaff';
import LineDiscount from './LineDiscount';
import ViewStates from './ViewStates';

interface InvoiceServiceLineProps {
	line: InvoiceLine;
	errors: Array<FieldError>;
	saving: boolean;
	editable: boolean;
	className?: string;
	onSave?: (line: InvoiceLine) => void;
	onEdit?: (uuidLine: string) => void;
	onCancelEdit?: (uuidLine: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(15)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '15px'
			}
		},
		cell: {
			minHeight: 29,
			display: 'flex',
			alignItems: 'center',

			[theme.breakpoints.down('sm')]: {
				'justifyContent': 'space-between',

				'&:not(:first-child):not(:nth-child(2))': {
					marginTop: 10
				}
			},
			[theme.breakpoints.up('md')]: {
				height: '60px'
			}
		},
		cellPadding: {
			[theme.breakpoints.up('md')]: {
				'& + *': {
					paddingLeft: 10
				},

				'& > *': {
					width: '100% !important'
				}
			}
		},
		serviceName: {
			[theme.breakpoints.down('sm')]: {
				fontWeight: 600
			}
		},
		mobileActions: {
			gap: 8,
			display: 'inline-flex',

			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		mobileLabel: {
			fontSize: 14,
			lineHeight: 1,

			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		mobileHidden: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		totalLabel: {
			[theme.breakpoints.down('sm')]: {
				fontWeight: 600
			}
		},
		actionButton: {
			border: 0,
			width: 38,
			height: 38,
			color: '#000',
			borderRadius: '50%',
			backgroundColor: '#F1F1F1'
		},
		desktopSaveButton: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	})
);

const validate = (line: InvoiceLine): Array<FieldError> => {
	const errors: Array<FieldError> = [];
	toNumber(line.discount.amount) > toNumber(line.subtotal) &&
		errors.push({
			fieldName: `${line.uuid}_discount`,
			errorMessage: 'Discount greater than the price.'
		});
	return errors;
};

export const InvoiceServiceLine: FunctionComponent<
	InvoiceServiceLineProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const [editedLine, setEditedLine] = useState(props.line);
	const [viewState, setViewState] = useState<ViewStates>(ViewStates.View);

	const [errors, setErrors] = useState<Array<FieldError>>([]);

	useNonInitialEffect(() => {
		if (!props.saving) {
			setEditedLine(props.line);
			setViewState(ViewStates.View);
		}
	}, [props.saving]);

	const edit = () => {
		props.onEdit && props.onEdit(props.line.uuid);
		setEditedLine({ ...props.line });
		setViewState(ViewStates.Editing);
	};

	const cancel = () => {
		setErrors([]);
		props.onCancelEdit && props.onCancelEdit(props.line.uuid);
		setEditedLine(props.line);
		setViewState(ViewStates.View);
	};

	const save = () => {
		const errors = validate(editedLine);
		setErrors(errors);

		if (errors.length === 0) {
			setViewState(ViewStates.Saving);
			props.onSave && props.onSave({ ...editedLine });
		}
	};

	const changeDiscountHandler = (discount: InvoiceDiscountModel) => {
		setEditedLine(prev => ({ ...prev, discount }));
	};

	const changePriceHandler = (subtotal: number) => {
		setEditedLine(prev => ({ ...prev, subtotal: subtotal.toString() }));
	};

	return (
		<Fragment>
			<Grid item xs={12} md={3} className={classes.cell}>
				<Typography
					className={clsx(
						commonClasses.textCell,
						classes.serviceName
					)}
				>
					{props.line.service?.name}
				</Typography>

				<Box className={classes.mobileActions}>
					{props.editable && viewState === ViewStates.View && (
						<button onClick={edit} className={classes.actionButton}>
							<EditButton />
						</button>
					)}
					{viewState === ViewStates.Editing && (
						<>
							<button
								onClick={cancel}
								className={classes.actionButton}
							>
								<SaveButton onClick={save} />
							</button>
							<button
								onClick={cancel}
								className={classes.actionButton}
							>
								<CancelButton />
							</button>
						</>
					)}
				</Box>
			</Grid>
			<Grid item xs={12} md={2} className={classes.cell}>
				<InvoiceStaff staff={props.line.staff!} />
			</Grid>
			<Grid
				item
				xs={12}
				md={2}
				className={clsx([classes.cell, classes.cellPadding])}
			>
				<Typography className={classes.mobileLabel}>Price</Typography>

				{viewState === ViewStates.Editing ? (
					<InvoiceAmount
						amount={Number(editedLine.subtotal)}
						onChange={changePriceHandler}
					/>
				) : (
					<Typography
						className={clsx(
							commonClasses.textCell,
							commonClasses.amountTextCell,
							{ [commonClasses.editable]: props.editable }
						)}
						onClick={props.editable ? edit : undefined}
					>
						${editedLine.subtotal}
					</Typography>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={2}
				className={clsx([classes.cell, classes.cellPadding])}
			>
				<Typography className={classes.mobileLabel}>
					Discount
				</Typography>

				<LineDiscount
					line={editedLine}
					viewState={viewState}
					errors={[...props.errors, ...errors]}
					editable={props.editable}
					onChange={changeDiscountHandler}
					onEdit={edit}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={2}
				className={clsx(classes.cell, commonClasses.totalCell, {
					[classes.mobileHidden]: viewState === ViewStates.Editing
				})}
			>
				<Typography
					className={clsx(classes.mobileLabel, classes.totalLabel)}
				>
					Total
				</Typography>

				{viewState === ViewStates.Saving ? (
					<CalculatingSpinner />
				) : viewState === ViewStates.Editing ? (
					<SaveButton
						onClick={save}
						className={classes.desktopSaveButton}
					/>
				) : (
					<Typography
						id={`booking_line_total_payment_${props.line.uuid}`}
						className={clsx(
							commonClasses.textCell,
							commonClasses.amountTextCell,
							classes.totalLabel,
							{
								[commonClasses.errorText]:
									Number(props.line.total) < 0
							}
						)}
					>
						${props.line.total}
					</Typography>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={1}
				className={clsx(classes.cell, commonClasses.iconCell)}
			>
				{props.editable && viewState === ViewStates.View && (
					<EditButton onClick={edit} />
				)}
				{viewState === ViewStates.Editing && (
					<CancelButton onClick={cancel} />
				)}
			</Grid>
		</Fragment>
	);
};

export default InvoiceServiceLine;
