import { NewAppointmentDto } from "actions/newBookings/NewBookingsDtos";
import { Booking } from "@spike/booking-model";

export interface NewBookingsState {
  booking: Booking | undefined;
  newAppointment: NewAppointmentDto | undefined;
  status: NewBookingsStatus;
  loading: boolean;
}

export enum NewBookingsStatus {
  Initial,
  Saving,
  SaveSuccess,
  SavingDeposit,
  SaveDepositSuccess,
  SavingCreateAppointment,
  SavingCreateAppointmentSuccess,
  Error
}
