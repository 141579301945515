import { Box, LinearProgress, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { BusinessSettingsTitle } from '../UI';
import moment from 'moment';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertMessage2 } from 'components/AlertMessage2';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { MarketplaceSMSMessagesStats, Subscription } from 'model/Subscriptions';
import { getSMSMessagesStatsThunk } from 'actions/subscriptions/SubscriptionsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'components/UI';

export interface Props {
	openSidebar?: () => void;
	isMobile?: boolean;
	subscription?: Subscription;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			flex: 1,
		},
		titleContainer: {
			display: 'flex',
			width: '100%'
		},
		businessSettingsTittle: {
			'display': 'flex',
			'alignItems': 'center',

			'& $iconBusinessTitle': {
				fontSize: '16px'
			}
		},
		iconBusinessTitle: {
			position: 'relative',
			marginRight: '8px'
		},
		planUsageContainer: {
			display: 'flex',
			borderRadius: 16,
			flexDirection: 'column',
			[theme.breakpoints.up('md')]: {
				border: '1px solid #D4D4D4',
				padding: '30px 32px 34px 30px'
			}
		},
		extraMessagesContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				marginTop: '13px'
			},
			[theme.breakpoints.only('md')]: {
				marginTop: '21px'
			},
			[theme.breakpoints.only('lg')]: {
				marginTop: '28px'
			},
			[theme.breakpoints.only('xl')]: {
				marginTop: '43px'
			}
		},
		subtitle: {
			fontSize: 16,
			fontWeight: 600,
			marginBottom: 15
		},
		label: {
			'fontSize': 16,
			'marginBottom': 29,

			'& strong': {
				fontWeight: 600
			}
		},
		dataSmsContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginBottom: 8
		},
		dataUsage: {
			fontSize: 16,
			fontWeight: 400,
			color: '#969696',
		},
		dataSms: {
			'fontSize': 16,
			'fontWeight': 500,
			'color': '#7A7A7A',

			'&.mobile': {
				marginTop: 15,

				[theme.breakpoints.up('md')]: {
					display: 'none'
				}
			},
			'&.desktop': {
				display: 'none',

				[theme.breakpoints.up('md')]: {
					display: 'block'
				}
			}
		},
		progressBar: {
			'height': 8,
			'borderRadius': 16,

			'&.MuiLinearProgress-colorPrimary': {
				backgroundColor: '#DBDAD7 !important'
			},
			'& .MuiLinearProgress-barColorPrimary': {
				backgroundColor: '#5E8677 !important'
			}
		},
		alertMessage: {
			'fontWeight': 600,
			'width': '100%',
			'marginBottom': '0px',
			fontSize: "16px",
			[theme.breakpoints.down('sm')]: {
				fontSize: '7px',
				lineHeight: '7px',
				marginTop: '9px'
			},
			'& .MuiGrid-item': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}
		}
	})
);

export const TextMessages: FunctionComponent<Props> = ({
	subscription,
	isMobile,
	openSidebar
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const loading = useSelector<RootState, boolean>(
		state => state.subscriptions.loading
	);

	const stats = useSelector<
		RootState,
		MarketplaceSMSMessagesStats | undefined
	>(state => state.subscriptions.stats);

	useEffect(() => {
		dispatch(getSMSMessagesStatsThunk());
		// eslint-disable-next-line
	}, []);

	const getProgress = () => {
		if (stats) {
			return (
				(stats.totalSentSms * 100) /
				(stats.remainingSms + stats.totalSentSms)
			);
		}
		return 0;
	};

	const getFormattedNumber = (value: number) => {
		return new Intl.NumberFormat('en-US').format(value);
	};

	const UsageCount = ({ className }: { className: string }) => (
		<Typography className={clsx(classes.dataSms, className)}>
			{`${
				stats ? getFormattedNumber(stats?.totalSentSms) : 0
			} / ${getFormattedNumber(1000)} SMS used`}
		</Typography>
	);

	const renewalDate = subscription ? (useMemo(() => {
		return moment(subscription.renewAt).format('MMM Do, YYYY');
	}, [subscription.renewAt])) : (moment().format('MMM Do, YYYY')); 

	return (
		<Box className={clsx(classes.container)}>
			{loading && <Spinner />}

			{!loading && (
				<Box>
					<Box className={classes.titleContainer}>
						<BusinessSettingsTitle
							className={classes.businessSettingsTittle}
						>
							{isMobile && (
								<FontAwesomeIcon
									className={classes.iconBusinessTitle}
									onClick={openSidebar}
									icon={faArrowLeft}
									size="2x"
								/>
							)}
							Text Messages
						</BusinessSettingsTitle>
					</Box>

					<Box className={classes.planUsageContainer}>
						<Typography className={classes.subtitle}>
							Plan Usage
						</Typography>

						<Typography className={classes.label}>
							You get{' '}
							<strong>
								{getFormattedNumber(1000)} text messages
							</strong>{' '}
							monthly included in the{' '}
							<strong>Premium Plan</strong> to use in 2-Way SMS
							with your clients.
						</Typography>

						<Box className={classes.dataSmsContainer}>
							<Typography className={classes.dataUsage}>
								Usage resets on {renewalDate}
							</Typography>

							<UsageCount className="desktop" />
						</Box>
						<LinearProgress
							className={classes.progressBar}
							variant="determinate"
							value={getProgress()}
						/>

						<UsageCount className="mobile" />

						{stats?.remainingSms === 0 && (
							<AlertMessage2
								className={classes.alertMessage}
								bWidth="2px"
								icon={faInfoCircle}
								bgColor="#FBB9BC33"
								bdColor="#EF4F57"
							>
								You have reached the maximum SMS sending limit
								of your plan.
							</AlertMessage2>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default TextMessages;
