
import { NewFeature, MediaType } from "model/NewFeature";
import { NewFeatureDto } from "./NewFeaturesDtos";

export const convert = (dto: NewFeatureDto): NewFeature => {
  return {
    id: dto.id,
    title: dto.title,
    description: dto.description,
    mediaSource: dto.media_source_url,
    mediaType: dto.media_type === "video" ? MediaType.VIDEO : MediaType.IMAGE,
    videoPoster: dto.video_poster_url,
  };
};
