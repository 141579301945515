import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { Box } from '@material-ui/core';

interface ImageViewProps {
	url: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(40)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '40px'
			}
		},
		image: {
			width: '100%',
			border: '1px solid black',

			[theme.breakpoints.up('md')]: {
				width: 400
			}
		}
	})
);
export const ImageView: FunctionComponent<ImageViewProps> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<img
				src={props.url}
				className={clsx(classes.image, props.className)}
				alt=""
			/>
		</Box>
	);
};

export default ImageView;
