import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { Box, TableCell } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { getPciThunk, getEnrollmentStatusThunk } from '@spike/payments-action';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { EnrollmentStatus as EnrollmentStatusModel } from '@spike/payments-model';
import { Fragment, FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import { useCommonStyles } from '../CommonStyles';
import { Steps } from '../Enrollment/EnrollmentModel';
import { Header } from '../Header/Header';
import Table from '../Table';
import SetupEdition from './SetupEdition';
import SetupRow from './SetupRow';
import { useApiClientWrapper } from 'hooks';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';
import { SetupRowMobile } from './SetupRowMobile';

interface SetupProps {
	status: EnrollmentStatusModel | undefined;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			marginTop: '50px'
		},
		table: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		mobile: {
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}
	})
);

export const Setup: FunctionComponent<SetupProps> = props => {
	const classes = useStyles();
	const tableStyles = useTableStyles();
	const commonClasses = useCommonStyles();

	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const paymentsStatus = useSelector<RootState, PaymentsStatus>(
		state => state.payments.status
	);
	const pciUrl = useSelector<RootState, string | undefined>(
		state => state.payments.pciUrl
	);

	const [editStep, setEditStep] = useState<Steps | null>(null);
	const [viewStep, setViewStep] = useState<Steps | null>(null);

	useEffect(() => {
		const interval = setInterval(
			() => dispatch(getEnrollmentStatusThunk(apiClientWrapper)),
			40000
		);
		return () => clearInterval(interval);
	}, []);

	useNonInitialEffect(() => {
		switch (paymentsStatus) {
			case PaymentsStatus.GetPciSuccess:
				window.open(pciUrl, 'pci', 'width=800,height=600');
				break;
		}
	}, [paymentsStatus]);

	const headers = (
		<>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Item
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Status
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Date
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			/>
		</>
	);

	const rows =
		props.status !== undefined ? (
			<>
				<SetupRow
					title="Business Profile Details"
					item={props.status?.businessProfile}
					onEdit={() => setEditStep(Steps.BUSINESS_PROFILE)}
					onView={() => setViewStep(Steps.BUSINESS_PROFILE)}
				/>
				<SetupRow
					title="Business Profile Documents"
					item={props.status?.businessProfile}
					onEdit={() => setEditStep(Steps.BUSINESS_PROFILE_DOCUMENT)}
					onView={() => setViewStep(Steps.BUSINESS_PROFILE_DOCUMENT)}
				/>
				<SetupRow
					title="Business Owner Details"
					item={props.status?.shareholders}
					onEdit={() => setEditStep(Steps.SHAREHOLDER)}
					onView={() => setViewStep(Steps.SHAREHOLDER)}
				/>
				<SetupRow
					title="Business Owner Documents"
					item={props.status?.shareholders}
					onEdit={() => setEditStep(Steps.SHAREHOLDER_DOCUMENT)}
					onView={() => setViewStep(Steps.SHAREHOLDER_DOCUMENT)}
				/>
				<SetupRow
					title="Bank Account Details"
					item={props.status?.bankAccounts}
					onEdit={() => setEditStep(Steps.BANK_ACCOUNT)}
					onView={() => setViewStep(Steps.BANK_ACCOUNT)}
				/>
				<SetupRow
					title="Bank Account Document"
					item={props.status?.bankAccounts}
					onEdit={() => setEditStep(Steps.BANK_ACCOUNT_DOCUMENT)}
					onView={() => setViewStep(Steps.BANK_ACCOUNT_DOCUMENT)}
				/>
				<SetupRow
					title="PCI Questionnaire"
					status={props.status?.pciStatus || undefined}
					onEdit={() => dispatch(getPciThunk(apiClientWrapper))}
					editIcon={faArrowUpRightFromSquare}
				/>
			</>
		) : (
			<Spinner className={classes.spinner} />
		);

	return (
		<>
			<Box className={clsx(commonClasses.container, props.className)}>
				<Header
					title="Setup"
					description={[
						'To run verification checks, Cuddles requires to collect business, business owner, and bank account details.',
						'Verifcation takes anywhere from 24 hours to 7 business days. If verification process takes longer or have been declined, you can edit your information per section, start process over, or contact us for questions.'
					]}
				/>
				<Table
					rows={rows}
					headers={headers}
					wrapperClassName={classes.table}
				/>

				<Box className={classes.mobile}>
					<SetupRowMobile
						title="Business Profile Details"
						item={props.status?.businessProfile}
						onEdit={() => setEditStep(Steps.BUSINESS_PROFILE)}
						onView={() => setViewStep(Steps.BUSINESS_PROFILE)}
					/>
					<SetupRowMobile
						title="Business Profile Documents"
						item={props.status?.businessProfile}
						onEdit={() =>
							setEditStep(Steps.BUSINESS_PROFILE_DOCUMENT)
						}
						onView={() =>
							setViewStep(Steps.BUSINESS_PROFILE_DOCUMENT)
						}
					/>
					<SetupRowMobile
						title="Business Owner Details"
						item={props.status?.shareholders}
						onEdit={() => setEditStep(Steps.SHAREHOLDER)}
						onView={() => setViewStep(Steps.SHAREHOLDER)}
					/>
					<SetupRowMobile
						title="Business Owner Documents"
						item={props.status?.shareholders}
						onEdit={() => setEditStep(Steps.SHAREHOLDER_DOCUMENT)}
						onView={() => setViewStep(Steps.SHAREHOLDER_DOCUMENT)}
					/>
					<SetupRowMobile
						title="Bank Account Details"
						item={props.status?.bankAccounts}
						onEdit={() => setEditStep(Steps.BANK_ACCOUNT)}
						onView={() => setViewStep(Steps.BANK_ACCOUNT)}
					/>
					<SetupRowMobile
						title="Bank Account Document"
						item={props.status?.bankAccounts}
						onEdit={() => setEditStep(Steps.BANK_ACCOUNT_DOCUMENT)}
						onView={() => setViewStep(Steps.BANK_ACCOUNT_DOCUMENT)}
					/>
					<SetupRowMobile
						title="PCI Questionnaire"
						status={props.status?.pciStatus || undefined}
						onEdit={() => dispatch(getPciThunk(apiClientWrapper))}
						editIcon={faArrowUpRightFromSquare}
					/>
				</Box>
			</Box>
			{editStep !== null && (
				<SetupEdition
					step={editStep}
					onClose={() => setEditStep(null)}
					onCompleted={() => setEditStep(null)}
				/>
			)}
			{viewStep !== null && (
				<SetupEdition
					step={viewStep}
					onClose={() => setViewStep(null)}
				/>
			)}
		</>
	);
};

export default Setup;
