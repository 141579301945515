import { UserNotification } from 'model/UserNotifications';
import { Action } from 'redux';

//Action Types
export const USER_NOTIFICATIONS_RESET_ACTION_TYPE = 'user_notifications/reset';
export const USER_NOTIFICATIONS_CLEAR_ACTION_TYPE = 'user_notifications/clear';
export const USER_NOTIFICATIONS_ADD_START_ACTION_TYPE =
	'user_notifications/add_start';
export const USER_NOTIFICATIONS_ADD_SUCCESS_ACTION_TYPE =
	'user_notifications/add_success';
export const USER_NOTIFICATIONS_FETCH_START_ACTION_TYPE =
	'user_notifications/fetch_start';
export const USER_NOTIFICATIONS_FETCH_SUCCESS_ACTION_TYPE =
	'user_notifications/fetch_success';
export const USER_NOTIFICATIONS_UPDATE_START_ACTION_TYPE =
	'user_notifications/update_start';
export const USER_NOTIFICATIONS_UPDATE_SUCCESS_ACTION_TYPE =
	'user_notifications/update_success';
export const USER_NOTIFICATIONS_ERROR_ACTION_TYPE = 'user_notifications/error';

//Actions
interface UserNotificationsResetAction
	extends Action<typeof USER_NOTIFICATIONS_RESET_ACTION_TYPE> {}

interface UserNotificationsClearAction
	extends Action<typeof USER_NOTIFICATIONS_CLEAR_ACTION_TYPE> {}

interface UserNotificationsAddStartAction
	extends Action<typeof USER_NOTIFICATIONS_ADD_START_ACTION_TYPE> {}

interface UserNotificationsAddSuccessAction
	extends Action<typeof USER_NOTIFICATIONS_ADD_SUCCESS_ACTION_TYPE> {
	payload: {
		notification: UserNotification;
	};
}

interface UserNotificationsFetchStartAction
	extends Action<typeof USER_NOTIFICATIONS_FETCH_START_ACTION_TYPE> {}

interface UserNotificationsFetchSuccessAction
	extends Action<typeof USER_NOTIFICATIONS_FETCH_SUCCESS_ACTION_TYPE> {
	payload: {
		read: Array<UserNotification>;
		unread: Array<UserNotification>;
	};
}

interface UserNotificationsUpdateStartAction
	extends Action<typeof USER_NOTIFICATIONS_UPDATE_START_ACTION_TYPE> {}

interface UserNotificationsUpdateSuccessAction
	extends Action<typeof USER_NOTIFICATIONS_UPDATE_SUCCESS_ACTION_TYPE> {}

interface UserNotificationsErrorAction
	extends Action<typeof USER_NOTIFICATIONS_ERROR_ACTION_TYPE> {}

export type UserNotificationsAction =
	| UserNotificationsResetAction
	| UserNotificationsClearAction
	| UserNotificationsFetchStartAction
	| UserNotificationsFetchSuccessAction
	| UserNotificationsAddStartAction
	| UserNotificationsAddSuccessAction
	| UserNotificationsUpdateStartAction
	| UserNotificationsUpdateSuccessAction
	| UserNotificationsErrorAction;
