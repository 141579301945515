import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import ResumeCategory from './Category/ResumeCategory';
import ResumeDetails from './Details/ResumeDetails';
import ResumePricing from './Pricing/ResumePricing';
import ResumeServiceStaff from './Staff/ResumeServiceStaff';
import Service from 'model/Service';
import { ServiceCategoryType } from './model';
import { Button } from 'components/UI';

interface ServiceResumeProps {
    service: Service;
    loading: boolean;
    isServiceEdited: boolean;
    onEdit?: (viewId: number) => void;
    onSave?: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        finishContainer: {
            padding: '0px 16px'
        },
        sectionContainer: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                minWidth: '750px',
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('md')]: {
                display: 'block'
            }
        },
        mobileSave: {
            'paddingBottom': '24px',
            '& button': {
                'height': 55,
                'width': '100%',
                'borderRadius': 30,
                '& span': {
                    fontSize: 16,
                    fontWeight: 600
                }
            },
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

const ServiceResume: FunctionComponent<ServiceResumeProps> = props => {
    const classes = useStyles();

    const editHandler = (viewId: number) => {
        props.onEdit && props.onEdit(viewId);
    };

    return (
        <Box className={classes.finishContainer}>
            <Box className={clsx(classes.sectionContainer)}>
                <ResumeCategory
                    onEdit={() => editHandler(ServiceCategoryType.CATEGORY)}
                    petTypes={props.service.petTypes}
                    service={props.service}
                />
            </Box>
            <Box className={classes.separator}></Box>
            <Box className={classes.sectionContainer}>
                <ResumeDetails onEdit={() => editHandler(ServiceCategoryType.DETAIL)} service={props.service} />
            </Box>
            <Box className={classes.separator}></Box>
            <Box className={classes.sectionContainer}>
                <ResumePricing onEdit={() => editHandler(ServiceCategoryType.PRICING)} service={props.service} />
            </Box>
            <Box className={classes.separator}></Box>
            <Box className={classes.sectionContainer}>
                <ResumeServiceStaff
                    onEdit={() => editHandler(ServiceCategoryType.STAFF)}
                    service={props.service}
                    withoutStaff={!props.service.staff || props.service.staff.length === 0}
                />
            </Box>

            <Box className={classes.mobileSave}>
                <Button
                    label="Save"
                    onClick={props.onSave}
                    disabled={props.isServiceEdited}
                    loading={props.loading}
                    color="orange"
                />
            </Box>
        </Box>
    );
};

export default ServiceResume;
