import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { wbp, reduceResolution } from "Theme";

export const useTerminalCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },

    rowContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(15)}px ${reduceResolution(0)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "15px 0px",
      },
    },

    row: {
      background: "#fff",
      "&:hover": {
        background: "#fafafa",
      }
    },

    flexRowCenter: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
    },

    fieldLeftSeparator: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "15px",
      },
    },
    boxRadiusWhite: {
      background: "#fff",
      border: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(18)}px`,
        padding: `${reduceResolution(20)}px ${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "18px",
        padding: "20px 35px",
      },
    },

    typo28_600: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(28)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "28px",
      },
    },

    typo24_600: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
      },
    },

    typo20_600: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
      },
    },

    typo20_400: {
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
      },
    },

    typo18_400: {
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
      },
    },

    typo16_600: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
    },

    typo16_500: {
      fontWeight: 500,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
    },

    typo16_400: {
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
    },

    typo15_400: {
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },

    typo15_400_orange: {
      color: "#EAB464",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },

    typo15_400_grey: {
      color: "#7A7A7A",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },

    typo15_500: {
      fontWeight: 500,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },

    typo14_400: {
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },

    typo14_500: {
      fontWeight: 500,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },

    typo14_600: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },

    typo14_600_green: {
      color: "#5E8677",
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },
    },

    typo13_600: {
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
      },
    },


    typo12_500: {
      fontWeight: 500,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "12px",
      },
    },

    typo_center: {
      textAlign: "center",
    }

  })
);
