import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { Button, Spinner } from 'components/UI';
import Staff, {
    StaffEmergencyContact,
    StaffService,
    StaffPerson,
    StaffSchedule,
    createEmptyStaff
} from 'model/Staff';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Personal from './Personal';
import Company from './Company';
import Services from './Services';
import Schedule from './Schedule';
import Commissions from './Commissions';
import StaffSavedSuccess from './StaffSavedSuccess';
import _ from 'lodash';
import { Status } from 'model';
import { FieldError, OWNER_ID } from '@spike/model';
import { StaffStatus } from 'reducers/staff/StaffState';
import { fetchServicesThunk } from 'actions/services/ServicesActions';
import { getStaffThunk, saveStaffThunk } from 'actions/staff/StaffActions';
import { Service } from 'model/Service';
import { validatePhoneNumber, isEmailValid } from '@spike/validations';
import { OnboardingSteps } from 'model/OnboardingSteps';
import { fetchOnboardingStepsThunk } from 'actions/onboardingSteps/OnboardingStepsActions';
import { validateDays } from 'components/StaffSchedule/utils/Validations';
import {
    showErrorThunk,
    showSuccess as showSuccessNotification
} from '@spike/notifications-action';
import Marketplace from '@spike/marketplace-model';
import { useMarketplace } from 'hooks';
import useNonInitialEffect from '@versiondos/hooks';

interface MemberProps {
    memberId?: number;
    member?: Staff;
    onBack?: () => void;
    onSaved?: (member: Staff) => void;
    onAddAnother?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scroll: {
            height: '100%',
            width: '100%'
        },
        container: {
            width: '100%',
            minHeight: '100%',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            backgroundColor: '#FFF',

            [theme.breakpoints.up('md')]: {
                paddingLeft: 60,
                paddingRight: 60,
                backgroundColor: '#FAFAFA'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 180,
                paddingRight: 180
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: 240,
                paddingRight: 240
            }
        },
        backButton: {
            marginRight: 10,
            cursor: 'pointer'
        },
        titleButtonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24,

            [theme.breakpoints.down('lg')]: {
                paddingLeft: 16,
                paddingRight: 16
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
                marginTop: '76px',
                marginBottom: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '102px',
                marginBottom: '27px'
            }
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        title: {
            fontSize: 20,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: 27
            }
        },
        saveButtonContainer: {
            width: '50%',
            display: 'none',
            justifyContent: 'flex-end',

            [theme.breakpoints.up('md')]: {
                display: 'flex'
            }
        },
        saveButton: {
            [theme.breakpoints.down('lg')]: {
                width: '127px',
                height: '36px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '170px',
                height: '49px'
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        content: {
            width: '100%',
            paddingTop: 30,
            paddingBottom: 26,
            display: 'flex',
            flexWrap: 'wrap',

            [theme.breakpoints.up('md')]: {
                gap: 24,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                paddingTop: '20px'
            }
        },
        personal: {
            [theme.breakpoints.down('lg')]: {
                order: 1
            }
        },
        schedule: {
            [theme.breakpoints.down('lg')]: {
                order: 4
            }
        },
        services: {
            [theme.breakpoints.down('lg')]: {
                order: 3
            }
        },
        company: {
            [theme.breakpoints.down('lg')]: {
                order: 2
            }
        },
        commissions: {
            [theme.breakpoints.down('lg')]: {
                order: 5
            }
        },
        mobileSave: {
            'order': 6,
            'width': '100%',
            'padding': '0px 16px',
            'marginTop': 5,

            '& button': {
                'height': 55,
                'width': '100%',
                'borderRadius': 30,

                '& span': {
                    fontSize: 16,
                    fontWeight: 600
                }
            },

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        spinnerContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            itemsAling: 'center'
        }
    })
);

export interface MemberWrapper {
    member: Staff;
    errors: Array<FieldError>;
}

export const Member: FunctionComponent<MemberProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const marketplace = useMarketplace();

    const onboardingSteps = useSelector<RootState, OnboardingSteps>(
        state => state.onboardingSteps.onboardingSteps
    );
    const { teamDone } = onboardingSteps;

    const services: Array<StaffService> = useSelector<
        RootState,
        Array<Service>
    >(state => state.services.services).filter(
        service => service.status.id === Status.ACTIVE
    );

    const loadingServices = useSelector<RootState, boolean>(
        state => state.services.loading
    );

    const [isServicesLoading, setIsServicesLoading] = useState(true);

    const staffStatus = useSelector<RootState, StaffStatus>(
        state => state.staff.status
    );
    const loading = useSelector<RootState, boolean>(
        state => state.staff.loading
    );
    const savedStaff = useSelector<RootState, Staff | undefined>(
        state => state.staff.savedStaff
    );

    const staff = useSelector<RootState, Staff | undefined>(
        state => state.staff.member
    );

    const newMember: Staff = createEmptyStaff(4, 2);

    const [memberWrapper, setMemberWrapper] = useState<MemberWrapper>({
        member: props.member ? props.member : newMember,
        errors: []
    });

    const [showSuccess, setShowSuccess] = useState(false);

    const [loadingMember, setLoadingMember] = useState(true);

    const isOwner = (props.member ?? staff)?.role?.id === OWNER_ID;

    useEffect(() => {
        if (props.memberId !== undefined && props.member === undefined) {
            dispatch(getStaffThunk(props.memberId));
        } else {
            setLoadingMember(false);
        }
    }, []);

    useEffect(() => {
        if (services.length === 0) {
            dispatch(fetchServicesThunk());
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            setIsServicesLoading(false);
        }
    }, [loadingServices]);

    useNonInitialEffect(() => {
        if (staffStatus === StaffStatus.SaveSuccess) {
            if (props.member || props.memberId) {
                props.onBack && props.onBack();
                dispatch(showSuccessNotification('Staff updated successfully'));
            } else {
                setShowSuccess(true);
            }
            if (teamDone === false) {
                dispatch(fetchOnboardingStepsThunk());
            }
            if (memberWrapper.member.active) {
                setTimeout(() => {
                    // props.onBack && props.onBack();
                    props.onSaved && props.onSaved(savedStaff!);
                }, 1500);
            }
        } else if (staffStatus === StaffStatus.GetSuccess) {
            staff && setMemberWrapper({ member: { ...staff }, errors: [] });
            setLoadingMember(false);
        } else if (staffStatus === StaffStatus.DeleteSuccess) {
            dispatch(fetchOnboardingStepsThunk());
        }
    }, [staffStatus]);

    const handleChangePersonalData = (
        person: StaffPerson,
        emergencyContact: StaffEmergencyContact
    ) => {
        setMemberWrapper(prev => {
            return {
                ...prev,
                member: { ...prev.member, person, emergencyContact }
            };
        });
    };

    const handleChangeCompany = (memberUpdated: any) => {
        setMemberWrapper(prev => {
            return { ...prev, member: { ...prev.member, ...memberUpdated } };
        });
    };

    const handleChangeService = (services: Array<StaffService>) => {
        setMemberWrapper(prev => {
            return {
                ...prev,
                member: { ...prev.member, services: [...services] }
            };
        });
    };

    const handleChangeSchedule = (schedule: StaffSchedule) => {
        setMemberWrapper(prev => {
            return {
                ...prev,
                member: {
                    ...prev.member,
                    schedule
                }
            };
        });
    };

    const isEmergencyContactEmpty = (member: Staff) => {
        return (
            _.isEmpty(member.emergencyContact.firstName) &&
            _.isEmpty(member.emergencyContact.lastName) &&
            _.isEmpty(member.emergencyContact.email) &&
            _.isEmpty(member.emergencyContact.phoneNumber) &&
            _.isEmpty(member.emergencyContact.relationship)
        );
    };

    const validate = (
        member: Staff,
        marketPlace: Marketplace
    ): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (_.isEmpty(member.person.firstName)) {
            errors.push({
                fieldName: 'person.firstName',
                errorMessage: 'First name is required'
            });
        }

        if (_.isEmpty(member.person.lastName)) {
            errors.push({
                fieldName: 'person.lastName',
                errorMessage: 'Last name is required'
            });
        }

        if (_.isEmpty(member.person.email)) {
            errors.push({
                fieldName: 'person.email',
                errorMessage: 'Email is required'
            });
        }

        if (!isEmailValid(member.person.email)) {
            errors.push({
                fieldName: 'person.email',
                errorMessage: 'Email incorrect format'
            });
        }

        member.person.phoneNumber.length > 0 &&
            errors.push(
                ...validatePhoneNumber(
                    member.person.phoneNumber,
                    marketplace.basics.address.country!.id,
                    'person.phoneNumber'
                )
            );

        if (!isEmergencyContactEmpty(member)) {
            if (_.isEmpty(member.emergencyContact.firstName)) {
                errors.push({
                    fieldName: 'emergencyContact.firstName',
                    errorMessage: 'First name is required'
                });
            }

            if (_.isEmpty(member.emergencyContact.lastName)) {
                errors.push({
                    fieldName: 'emergencyContact.lastName',
                    errorMessage: 'Last name is required'
                });
            }

            if (
                !_.isEmpty(member.emergencyContact.email) &&
                !isEmailValid(member.emergencyContact.email)
            ) {
                errors.push({
                    fieldName: 'emergencyContact.email',
                    errorMessage: 'Email incorrect format'
                });
            }

            !_.isEmpty(member.emergencyContact.phoneNumber) &&
                errors.push(
                    ...validatePhoneNumber(
                        member.emergencyContact.phoneNumber,
                        marketplace.basics.address.country!.id,
                        'emergencyContact.phoneNumber'
                    )
                );
        }

        //const scheduleErrors = [...validateDays(member.schedule.customDays), ...validateDaysByMarketplaceSchedule(member.schedule.customDays, marketplace.schedule)];
        const scheduleErrors = validateDays(member.schedule.customDays);

        return [...errors, ...scheduleErrors];
    };

    const handleSave = () => {
        const errors = validate(memberWrapper.member, marketplace);

        setMemberWrapper(prev => ({ ...prev, errors }));

        if (errors.length === 0) {
            dispatch(saveStaffThunk(memberWrapper.member));
        } else {
            errors
                .filter(error =>
                    memberWrapper.member.schedule.customDays.some(
                        day => day.uuid === error.fieldName
                    )
                )
                .forEach(error =>
                    dispatch(
                        showErrorThunk(
                            `${memberWrapper.member.schedule.customDays
                                .find(day => day.uuid === error.fieldName)!
                                .from.format('MM-DD-YYYY')}: ${
                                error.errorMessage
                            }`
                        )
                    )
                );
        }
    };

    const handleBack = () => {
        props.onBack && props.onBack();
    };

    const header = (
        <Fragment>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            onClick={handleBack}
                            className={classes.backButton}
                        />

                        {memberWrapper.member.id
                            ? 'Editing Member'
                            : 'Adding New Member'}
                    </Typography>
                </Box>
                <Box className={classes.saveButtonContainer}>
                    <Button
                        label="Save"
                        id="staff_add_member_button_save"
                        onClick={handleSave}
                        loading={loading}
                        className={classes.saveButton}
                    />
                </Box>
            </Box>
        </Fragment>
    );

    const content = (
        <Box className={classes.content}>
            <Personal
                memberWrapper={memberWrapper}
                className={classes.personal}
                onChange={handleChangePersonalData}
            />

            <Schedule
                schedule={memberWrapper.member.schedule}
                staffId={memberWrapper.member.id}
                className={classes.schedule}
                onChange={handleChangeSchedule}
                errors={memberWrapper.errors}
            />

            <Services
                isEditing={!!memberWrapper.member.id}
                allServices={services}
                className={classes.services}
                servicesSelected={memberWrapper.member.services}
                onChange={handleChangeService}
                isServicesLoading={isServicesLoading}
            />
            {!isOwner && (
                <Company
                    staff={memberWrapper.member}
                    className={classes.company}
                    onChange={handleChangeCompany}
                />
            )}
            {memberWrapper.member && (
                <Commissions
                    member={memberWrapper.member}
                    className={classes.commissions}
                />
            )}

            <Box className={classes.mobileSave}>
                <Button
                    id="staff_add_member_button_save"
                    label="Save"
                    onClick={handleSave}
                    loading={loading}
                    className={classes.saveButton}
                />
            </Box>
        </Box>
    );

    const handleAddAnother = () => {
        setShowSuccess(false);
        const newMember: Staff = createEmptyStaff(4, 2);
        setMemberWrapper({
            member: props.member ? props.member : newMember,
            errors: []
        });
        props.onAddAnother && props.onAddAnother();
    };

    const success = (
        <StaffSavedSuccess
            memberName={`${memberWrapper.member.person.firstName} ${memberWrapper.member.person.lastName}`}
            memberEmail={memberWrapper.member.person.email}
            active={memberWrapper.member.active}
            onCreateService={() => {}}
            onDoLater={handleBack}
            onGoBack={handleBack}
            onAddAnother={handleAddAnother}
        />
    );

    return (
        <OverlayScrollbarsComponent className={classes.scroll}>
            <Box className={classes.container}>
                {!showSuccess && !loadingMember && header}
                {!showSuccess && !loadingMember && content}
                {loadingMember && (
                    <Box className={classes.spinnerContainer}>
                        <Spinner />
                    </Box>
                )}
                {showSuccess && success}
            </Box>
        </OverlayScrollbarsComponent>
    );
};

export default Member;
