import {
	Box,
	TableCell,
	TableRow,
	Theme,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { useMasterData } from 'hooks';
import { Agreement } from 'model/Agreements';
import AgreementsOptions from '../AgreementsOptions';
import { formatDate } from '../utils';
import { FunctionComponent } from 'react';

interface AgreementsTableRowProps {
	agreement: Agreement;
	onShowDetail?: () => void;
	onDeleted?: () => void;
}

interface AgreementsTableRowProps {
	agreement: Agreement;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			overflow: 'hidden',
			maxWidth: '200px',
			textOverflow: 'ellipsis',
			cursor: 'pointer'
		},

		text: {
			fontWeight: 500,
			fontHeigth: 15,
			lineHeight: 1.5,
			[theme.breakpoints.down(768)]: {
				fontHeigth: 14
			}
		}
	})
);

const AgreementsTableRow: FunctionComponent<
	AgreementsTableRowProps
> = props => {
	const sentToOptions = useMasterData().agreementsSendTo;
	const classes = useStyles();
	return (
		<TableRow>
			<TableCell onClick={() => props.onShowDetail && props.onShowDetail()}>
				<Box className={classes.title}>
					<b className={classes.text}>{props.agreement.title}</b>
				</Box>
			</TableCell>
			<TableCell>{formatDate(props.agreement.createdAt)}</TableCell>
			<TableCell>{formatDate(props.agreement.updatedAt)}</TableCell>
			<TableCell>
				{
					sentToOptions.find(
						option => option.id === props.agreement.sendTo
					)?.name
				}
			</TableCell>
			<TableCell>
				<AgreementsOptions agreement={props.agreement} />
			</TableCell>
		</TableRow>
	);
};

export default AgreementsTableRow