import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SelectionBar } from 'components/UI';
import { Period } from '@spike/model';
import { FunctionComponent, useState } from 'react';
import Title from '../ui/Title';
import { Paid, categories } from './Categories';
import Total from './Total';
import TransactionsFilters from './TransactionsFilters';

interface TransactionsHeaderProps {
    period: Period;
    clientId: number | null;
    optionSelected: string | undefined;
    totalElements?: number;
    totalAmount?: string;
    onChangeCategory: (categoryId: string) => void;
    onChangePeriod: (
        period: Period,
        optionSelected: string | undefined
    ) => void;
    onChangeClient: (clientId: number | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingLeft: 16,
            paddingRight: 16,
            [theme.breakpoints.up('md')]: {
                paddingLeft: 24,
                paddingRight: 24
            }
        },
        titleContainer: {
            paddingTop: 24,
            display: 'flex',
            justifyContent: 'space-between',

            [theme.breakpoints.down('sm')]: {
                paddingBottom: 16,
                alignItems: 'center'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 50,
                backgroundColor: '#FAFAFA'
            }
        },
        filterContainer: {
            paddingBottom: 24,
            backgroundColor: '#FAFAFA',

            [theme.breakpoints.down('sm')]: {
                paddingTop: 24
            }
        },
        left: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        right: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
        },
        selectionBar: {
            marginBottom: 16,

            [theme.breakpoints.up('md')]: {
                backgroundColor: '#ffffff'
            }
        }
    })
);

export const TransactionsHeader: FunctionComponent<
    TransactionsHeaderProps
> = props => {
    const classes = useStyles();

    const [selectedCategoryId, setSelectedCategoryId] = useState(Paid.id);

    const changeCategoryHandler = (categoryId: string) => {
        setSelectedCategoryId(categoryId);
        props.onChangeCategory(categoryId);
    };

    return (
        <>
            <Box className={clsx(classes.container, classes.titleContainer)}>
                <Box className={classes.left}>
                    <Title text="Transactions" />
                </Box>
                <Box className={classes.right}>
                    <Total
                        amount={props.totalAmount}
                        title={
                            selectedCategoryId === Paid.id
                                ? 'TOTAL SALES'
                                : 'TOTAL REFUNDED'
                        }
                    />
                </Box>
            </Box>

            <Box className={clsx(classes.container, classes.filterContainer)}>
                <SelectionBar
                    options={[...categories]}
                    selectedId={selectedCategoryId}
                    onSelect={changeCategoryHandler}
                    selectedTotalRecords={props.totalElements}
                    className={classes.selectionBar}
                />
                <TransactionsFilters
                    selectedCategoryId={selectedCategoryId}
                    clientId={props.clientId}
                    period={props.period}
                    optionSelected={props.optionSelected}
                    onChangeClient={props.onChangeClient}
                    onChangePeriod={props.onChangePeriod}
                    totalElements={props.totalElements || 0}
                />
            </Box>
        </>
    );
};

export default TransactionsHeader;
