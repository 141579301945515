import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Selectable } from 'model';
import { PetType } from '@spike/model';
import Checkbox from 'components/UI/Checkbox';
import { useMarketplace } from 'hooks';

interface Props {
    petTypes: Array<Selectable<PetType>>;
    onChange: (selectedPetTypes: Array<Selectable<PetType>>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            fontSize: 14,
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        descriptionContainer: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '22px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '30px'
            }
        },
        description: {
            fontWeight: 600,
            [theme.breakpoints.down('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '20px'
            }
        },
        optionsContainer: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                gap: 16,
                flexDirection: 'column'
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '22px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '30px'
            }
        },
        checkbox: {
            '& > span': {
                paddingLeft: 0
            },
            '& p': {
                fontSize: 16
            },
            '& svg': {
                [theme.breakpoints.down('sm')]: {
                    color: '#000'
                }
            }
        }
    })
);

export const PetTypes: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();
    const filteredOptions = props.petTypes.filter(e => {
        if (e.element.id === 'dogs' && marketplace.petTypes.dogs) {
            return true;
        } else if (e.element.id === 'cats' && marketplace.petTypes.cats) {
            return true;
        } else if (e.element.id === 'exotics' && marketplace.petTypes.exotics) {
            return true;
        } else {
            return false;
        }
    });

    const changeHandler = (id: string, checked: boolean): void => {
        props.petTypes.find(pt => pt.element.id === id)!.selected = checked;

        if (checked) {
            if (id === 'exotics') {
                props.petTypes
                    .filter(pt => pt.element.id !== 'exotics')
                    .forEach(pt => (pt.selected = false));
            } else {
                props.petTypes.find(
                    pt => pt.element.id === 'exotics'
                )!.selected = false;
            }
        }
        props.onChange([...props.petTypes]);
    };

    const options = filteredOptions.map(petType => (
        <Checkbox
            id={`services_add_service_checkbox_${petType.element.singular.toLowerCase()}`}
            key={petType.element.id}
            label={petType.element.plural}
            checked={petType.selected}
            className={classes.checkbox}
            onChange={checked => {
                changeHandler(petType.element.id, checked);
            }}
        />
    ));
    return (
        <Box className={classes.container}>
            <Box>
                <Typography className={classes.title}>PET CATEGORY</Typography>
            </Box>
            <Box className={classes.descriptionContainer}>
                <Typography className={classes.description}>
                    Select a category for this service
                </Typography>
            </Box>
            <Box className={classes.optionsContainer}>{options}</Box>
        </Box>
    );
};

export default PetTypes;
