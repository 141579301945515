import React from 'react';
import {
	Box,
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import {
	faChartLineUp,
	faCommentAltLines,
	faFileSearch,
	faFileSignature
} from '@fortawesome/pro-light-svg-icons';
import { Button } from 'components/UI';
import clsx from 'clsx';

interface Props {
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.down('sm')]: {
				flex: 1,
				flexWrap: 'nowrap',
				flexDirection: 'column'
			},

			'& > *': {
				[theme.breakpoints.down('sm')]: {
					flexBasis: 'unset'
				}
			},

			'& b': {
				fontWeight: 600
			}
		},
		container: {
			[theme.breakpoints.down('sm')]: {
				margin: 0,
				width: '100%'
			}
		},
		icon: {
			fontSize: '6.2em',

			[theme.breakpoints.up('md')]: {
				fontSize: '7em'
			}
		},
		header: {
			'maxWidth': 405,
			'margin': '0px auto',
			'textAlign': 'center',

			'& h2': {
				fontSize: 20,
				lineHeight: 1.1,
				fontWeight: 600,
				marginTop: 15,

				[theme.breakpoints.down(600)]: {
					padding: '0px 45px'
				},
				[theme.breakpoints.up('sm')]: {
					fontSize: 20
				}
			}
		},
		bodyText: {
			'& p': {
				fontSize: 16,
				lineHeight: 1.4
			}
		},
		card: {
			'width': '100%',
			'padding': '22px',
			'borderRadius': 16,
			'backgroundColor': '#F8F8F8',

			[theme.breakpoints.up('sm')]: {
				background: 'none',
				border: 'solid 1px #D4D4D4'
			},

			'& ul': {
				margin: 0,
				fontSize: 16,
				fontWeight: 500,
				listStyle: 'none',
				paddingLeft: 10
			},

			'& li:not(:last-child)': {
				marginBottom: '1em'
			}
		},
		submit: {
			minWidth: 210
		},
		actions: {
			'marginTop': 'auto',

			'& button': {
				'height': 47,
				'width': '100%',
				'padding': '0px 17px',
				'borderRadius': 30,

				'& label': {
					fontSize: 16,
					fontWeight: 600
				}
			}
		}
	})
);

export const DowngradeSuccess: React.FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Grid
			container
			spacing={4}
			className={clsx(classes.root, classes.container)}
		>
			<Grid item xs={12} className={classes.header}>
				<FontAwesomeIcon
					color="#5E8677"
					icon={faCheckCircle}
					className={classes.icon}
				/>
				<Typography variant="h2">
					Your Premium Plan Has Been Canceled
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.bodyText}>
				<Typography>
					We’d have the saddest puppy dog eyes if this was really
					goodbye, but the good news is you can still use our{' '}
					<b>Free</b> Cuddles features! Just remember, you no longer
					have access to the Cuddles Premium Plan features below. 😔
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.bodyText}>
				<Box className={classes.card}>
					<ul>
						<li>
							<FontAwesomeIcon
								size="xl"
								icon={faCommentAltLines}
								style={{ marginRight: 8 }}
							/>
							Two-Way Texting (1,000 SMS)
						</li>
						<li>
							<FontAwesomeIcon
								size="xl"
								icon={faFileSignature}
								style={{ marginRight: 8 }}
							/>
							Agreements
						</li>
						<li>
							<FontAwesomeIcon
								size="xl"
								icon={faChartLineUp}
								style={{ marginRight: 8 }}
							/>
							Insights
						</li>
						<li>
							<FontAwesomeIcon
								size="xl"
								icon={faFileSearch}
								style={{ marginRight: 8 }}
							/>
							Service Reports
						</li>
					</ul>
				</Box>
			</Grid>
			<Grid item xs={12} className={classes.bodyText}>
				<Typography>You can upgrade again at any time. 😃</Typography>
			</Grid>
			<Grid item xs={12} className={classes.actions}>
				<Button
					label="Done"
					size="large"
					color="black"
					className={classes.submit}
					onClick={props.onClose}
				/>
			</Grid>
		</Grid>
	);
};
