import React from 'react';
import Taxes from '../UI/Taxes';
import SelectTax from '../UI/SelectTax';
import { InvoiceLine, InvoiceLineType } from '@spike/invoice-model';
import { MarketplaceTax } from '@spike/marketplace-model';

type ItemTaxesProps = {
	inputClassName?: string;
	invoiceLine: InvoiceLine;
	invoiceLineType: InvoiceLineType;
	onChange: (tax?: MarketplaceTax) => void;
};

export const ItemTaxes: React.FunctionComponent<ItemTaxesProps> = props => {
	return (
		<>
			{props.invoiceLineType === InvoiceLineType.Custom ? (
				<SelectTax
					className={props.inputClassName}
					selectedTax={props.invoiceLine.taxes[0]}
					onSelect={props.onChange}
				/>
			) : props.invoiceLine.taxes.length === 0 ? (
				'-'
			) : (
				<Taxes taxes={props.invoiceLine.taxes} />
			)}
		</>
	);
};
