import React, { createRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ScheduleTypeCalendarToolbar } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarToolbar';
import FullCalendar from '@fullcalendar/react';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { useMarketplace, useTimeZone } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import { Period } from '@spike/model';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ScheduleTypeCalendarSlotLabel } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarSlotLabel';
import { getDateBusinessHours, getWeekBusinessHours } from 'components/Calendar3/FullCalendar/FullCalendarUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import { ScheduleTypeCalendarStaffCell } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarStaffCell';
import { ScheduleTypeCalendarEvent } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarEvent';
import { MultiSlotDrawer } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawer';
import Staff from 'model/Staff';
import { getDateRange } from 'utils/DateUtils';
import { getStaffCustomDayFromDate } from 'utils/StaffScheduleUtils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: 24
        },
        slotLabel: {
            'height': '50px !important',
            'borderTop': 'none !important',
            'borderLeft': 'none !important',
            'borderRight': 'none !important',
            'borderBottom': '1px solid #D8D8D8 !important',
            'backgroundColor': '#FFFFFF',

            '& > div': {
                height: '100%'
            }
        },
        calendarWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: -15,
                marginRight: -15
            },

            '& .fc-timegrid-axis': {
                borderRight: 0
            },
            '& table.fc-scrollgrid': {
                border: 0
            },
            '& .fc-resource-timeline-divider': {
                width: 0,
                border: 0,
                visibility: 'hidden',
                background: 'transparent'
            },
            '& .fc-scrollgrid-section-header th:first-child': {
                borderRight: 0
            }
        },
        resourcesLabel: {
            'margin': 0,
            'height': 50,
            'fontSize': 14,
            'fontWeight': 500,
            'fontFamily': 'Poppins',
            'verticalAlign': 'bottom !important',

            '& .fc-scrollgrid-sync-inner': {
                padding: '0px 0px 0px 24px'
            }
        },
        event: {
            border: 0,
            padding: 5,
            margin: '0px !important',
            backgroundColor: 'white'
        }
    })
);

export const getStaffDaysOfWeek = (staff: Staff, period: Period) => {
    return staff.schedule.customDays.filter(day => day.from.isBetween(period.from, period.to, 'day', '[]'));
};

export const ScheduleTypeCalendar: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const today = moment().tz(timeZone);

    const [clickedDate, setClickedDate] = React.useState<Moment>();
    const [selectedStaff, setSelectedStaff] = React.useState<Staff>();
    const [showAddSlotDrawer, setShowAddSlotDrawer] = React.useState<boolean>(false);

    const [period, setPeriod] = React.useState<Period>({
        from: today.clone().startOf('week'),
        to: today.clone().endOf('week')
    });

    const marketplaceBusinessHours = React.useMemo(() => {
        const businessHours = getWeekBusinessHours(period.from, marketplace.schedule);

        return businessHours.filter(businessHour => {
            if (businessHour.startTime === '00:00:00' && businessHour.endTime === '00:00:00') {
                return false;
            }

            return true;
        });
    }, [period]);

    const staffState = useSelector((state: RootState) => state.staff);

    const fullCalendarRef = createRef<FullCalendar>();

    const staffWithBusinessHours = React.useMemo(() => {
        const dateRange = getDateRange(period.from, period.to, 'days');

        const weekBusinessHours = dateRange.reduce((map, date) => {
            const weekDay = date.clone().weekday();
            const businessHours = getDateBusinessHours(date, marketplace.schedule);

            map.set(
                weekDay ?? -1,
                businessHours
                    ? {
                          ...businessHours,
                          date
                      }
                    : null
            );
            return map;
        }, new Map<number, (Period & { date: Moment }) | null>());

        return staffState.staff.map(staff => {
            const staffBusinessHours = Array.from(weekBusinessHours.values())
                .filter((businessHours): businessHours is Period & { date: Moment } => {
                    if (!businessHours) {
                        return false;
                    }

                    const staffCustomDay = getStaffCustomDayFromDate(staff.schedule, businessHours.date);

                    return staffCustomDay ? staffCustomDay.on : true;
                })
                .map(businessHour => {
                    return {
                        daysOfWeek: [businessHour.date.weekday()],
                        startTime: businessHour.from.format('HH:mm:ss'),
                        endTime: businessHour.to.format('HH:mm:ss')
                    };
                })
                .filter(businessHour => {
                    return businessHour.startTime !== '00:00:00' && businessHour.endTime !== '00:00:00';
                });

            return {
                id: staff.id?.toString() ?? '',
                title: staff.person.firstName,
                businessHours: staffBusinessHours,
                extendedProps: {
                    ...staff
                }
            };
        });
    }, [period, staffState.staff]);

    const goToTodayHandler = () => {
        setPeriod({
            from: today.clone().startOf('week'),
            to: today.clone().endOf('week')
        });
    };

    const dateClickHandler = (info: DateClickArg) => {
        const fullCalendarClickedDate = moment().tz(timeZone);

        fullCalendarClickedDate.set({
            date: info.date.getDate(),
            month: info.date.getMonth(),
            year: info.date.getFullYear()
        });

        setSelectedStaff(staffState.staff.find(staff => staff.id === Number(info.resource?.id)));
        setClickedDate(fullCalendarClickedDate);
        setShowAddSlotDrawer(true);
    };

    /*
     * Change fullcalendar week to the period's start date
     */
    React.useEffect(() => {
        fullCalendarRef.current?.getApi().gotoDate(period.from.toDate());
    }, [period]);

    /*
     * Update the selected staff if the staff has been updated
     */
    React.useEffect(() => {
        const staffUpdated = staffState.staff.find(staff => staff.id === selectedStaff?.id);

        if (staffUpdated) {
            setSelectedStaff(staffUpdated);
        }
    }, [staffState.staff]);

    /*
     * Fetch all staffs
     */
    React.useEffect(() => {
        dispatch(fetchStaffThunk());
    }, []);

    const fullCalendarComponent = React.useMemo(
        () => (
            <FullCalendar
                ref={fullCalendarRef}
                plugins={[interactionPlugin, momentTimezonePlugin, resourceTimelinePlugin]}
                /*
                 * License
                 */
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                /*
                 * Views options
                 */
                initialView="resourceTimelineWeek"
                /*
                 * Options
                 */
                height="550px"
                timeZone={timeZone}
                /*
                 * Header
                 */
                headerToolbar={false}
                /*
                 * Resources
                 */
                resources={staffWithBusinessHours}
                resourceAreaWidth="160px"
                resourceAreaHeaderContent={'Staff'}
                resourceAreaHeaderClassNames={[classes.resourcesLabel]}
                resourceLabelContent={resource => <ScheduleTypeCalendarStaffCell {...resource} />}
                /*
                 * Slots
                 */
                slotMinWidth={130}
                slotDuration={{ day: 1 }}
                slotLabelClassNames={[classes.slotLabel]}
                slotLabelContent={props => <ScheduleTypeCalendarSlotLabel {...props} />}
                /*
                 * Events
                 */
                events={[
                    {
                        id: '1',
                        resourceId: '1857',
                        title: 'Lorem ipsum dolor sit amet',
                        start: today.clone().startOf('week').add(1, 'day').set('hour', 10).toDate(),
                        end: today.clone().startOf('week').add(1, 'day').endOf('day').toDate()
                    }
                ]}
                eventClassNames={[classes.event]}
                eventContent={event => <ScheduleTypeCalendarEvent {...event} />}
                /*
                 *
                 */
                dateClick={dateClickHandler}
            />
        ),
        [period, staffState.staff]
    );

    return (
        <>
            <div className={classes.root}>
                <ScheduleTypeCalendarToolbar
                    selectedWeek={period}
                    onChange={setPeriod}
                    onGoToToday={goToTodayHandler}
                />

                <div className={classes.calendarWrapper}>{fullCalendarComponent}</div>
            </div>

            <MultiSlotDrawer
                period={period}
                open={showAddSlotDrawer}
                defaultDate={clickedDate}
                selectedStaff={selectedStaff}
                onClose={() => setShowAddSlotDrawer(false)}
            />
        </>
    );
};
