import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { BodySection, ActionSection } from 'components/UI/Section';
import Section from '../Section';
import Staff from 'model/Staff';
import Service, { StaffMember } from 'model/Service';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import StaffMembers from './StaffMembers';

interface ServiceStaffProps {
    collapsed?: boolean;
    disabled?: boolean;
    editable?: boolean;
    completed?: boolean;
    modifiable?: boolean;
    service: Service;
    onEdit?: () => void;
    onChange: (staff: Array<StaffMember>) => void;
    onNext: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        titleContainer: {
            display: 'flex'
        },
        title: {
            fontWeight: 600,
            [theme.breakpoints.down('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '22px'
            }
        },
        saffMembersContainer: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '16px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '24px'
            }
        },
        noneStaffDescriptionContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                marginTop: '16px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '24px'
            }
        },
        noneStaffDescription: {
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '16px'
            }
        }
    })
);

export const ServiceStaff: FunctionComponent<ServiceStaffProps> = props => {
    const classes = useStyles();

    const handleChange = (sM: Array<StaffMember>) => {
        props.onChange(sM);
    };

    const [isAutoLoaded, setIsAutoLoaded] = useState(false);

    const allActiveStaff: Array<StaffMember> = useSelector<
        RootState,
        Array<Staff>
    >(state => state.staff.staff)
        .filter(staff => staff.active && !staff.deleted)
        .map(staff => ({
            id: staff.id!,
            ...staff.person
        }));

    useEffect(() => {
        if (allActiveStaff.length > 0 && !isAutoLoaded && !props.service.id) {
            props.onChange(allActiveStaff);
            setIsAutoLoaded(true);
        }
    }, [allActiveStaff]);

    const addStaff = (
        <Fragment>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>
                    Add Staff Members
                </Typography>
            </Box>
            <Box className={classes.saffMembersContainer}>
                <StaffMembers
                    allMembers={allActiveStaff}
                    serviceMembers={props.service.staff}
                    onChange={sM => handleChange(sM)}
                />
            </Box>
        </Fragment>
    );

    const noneStaff = (
        <Fragment>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>
                    No Staff members have been created yet.
                </Typography>
            </Box>
            <Box className={classes.noneStaffDescriptionContainer}>
                <Typography className={classes.noneStaffDescription}>
                    You can come back to add staff once you create them to{' '}
                    <br /> activate the service.
                </Typography>
            </Box>
        </Fragment>
    );

    return (
        <Section
            position={4}
            title="Staff"
            {...props}
            collapsed={props.collapsed}
        >
            <BodySection minHeigth="350px">
                <Box className={classes.container}>
                    {allActiveStaff.length > 0 ? addStaff : noneStaff}
                </Box>
            </BodySection>
            <ActionSection
                disabled={props.disabled}
                buttonLabel="Save"
                id="services_add_service_staff_button_next"
                onNext={props.onNext}
            />
        </Section>
    );
};

export default ServiceStaff;
