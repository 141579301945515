import { Box, Grid, useMediaQuery } from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { HeaderClose } from './UI/HeaderClose';
import { PetInformation } from './UIContent/PetInformation';
import MedicalConditions from 'components/Pet/MedicalHistory/View/MedicalConditions';
import Dentals from 'components/Pet/MedicalHistory/View/Dentals';
import Vaccines from 'components/Pet/MedicalHistory/View/Vaccines';
import clsx from 'clsx';
import Pet, { Note, PetCardAction } from '@spike/pet-model';
import PetActionButton from './UIContent/PetActionButton';
import { reduceResolution, wbp } from 'Theme';
import ViewVet from 'components/Pet/Vet/View';
import VetModel from '@spike/vet-model';

import EditPet from 'components/Pet/EditPet';
import { OverFullWindow } from 'components/UI';
import { savePetThunk } from '@spike/pets-action';
import { validatePet } from 'components/Pet/Validations';
import { useDispatch } from 'react-redux';
import { showErrorThunk } from '@spike/notifications-action';
import { useApiClientWrapper } from 'hooks';
interface PetCardProps {
    optionMenuSelected: string;
    pet: Pet;
    vet?: VetModel;
    className?: string;
    onSaved?: () => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        containerHeader: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(20)}px 0px ${reduceResolution(
                    20
                )}px ${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '20px 0px 20px 30px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0
            }
        },
        containerButtons: {
            borderTop: '1px solid #ddd',
            background: '#fff',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(0)}px ${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '0px 30px'
            },
            [theme.breakpoints.down('sm')]: {
                borderTop: 'none',
                padding: 0
            }
        },
        boxContent: {
            overflowY: 'auto',
            paddingTop: '30px',
            paddingRight: '30px',
            height: 580,
            [theme.breakpoints.down(wbp)]: {
                height: 441
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 0,
                paddingRight: 0,
                height: '100%',
                overflowY: 'unset'
            }
        },
        gridContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0px 0px 0px 20px',
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                padding: 0
            }
        },
        gridButtons: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(10)}px`,
                paddingBottom: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '10px',
                paddingBottom: '10px'
            }
        },
        gridEnd: {
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                flex: 'auto',
                padding: '0px 16px'
            }
        },
        btnLeftContent: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        paddingWrapper: {
            padding: '0px 16px',
            width: '100%'
        }
    })
);

enum EditType {
    PET_INFORMATION = 'pet_information',
    MEDICAL_HISTORY = 'medical_histpry',
    PET_NOTES = 'pet_notes'
}

export const PetCardContent: FunctionComponent<PetCardProps> = props => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const menuOptions = [
        { id: 'info', description: 'Pet Information' },
        { id: 'vaccines', description: 'Vaccines' },
        { id: 'medical', description: 'Medical Condition' },
        { id: 'dental', description: 'Dental' },
        { id: 'vet', description: 'Vet Information' }
    ];

    const [title, setTitle] = useState<string | undefined>(
        menuOptions[0].description
    );
    const [edit, setEdit] = useState<EditType | null>(null);

    const boxContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // eslint-disable-next-line
        var title = menuOptions.find(
            option => option.id === props.optionMenuSelected
        );
        setTitle(title?.description);
        // eslint-disable-next-line
    }, [props.optionMenuSelected]);

    const closeHandler = () => {
        props.onClose && props.onClose();
    };

    const selectActionHandler = (option: string) => {
        switch (option) {
            case PetCardAction.editPetInformation:
                setEdit(EditType.PET_INFORMATION);
                break;
            case PetCardAction.editMedical:
                setEdit(EditType.MEDICAL_HISTORY);
                break;
            case PetCardAction.editDental:
                setEdit(EditType.MEDICAL_HISTORY);
                break;
            case PetCardAction.editVaccines:
                setEdit(EditType.MEDICAL_HISTORY);
                break;
            case PetCardAction.editVet:
                setEdit(EditType.MEDICAL_HISTORY);
                break;
            case 'editNotes':
                setEdit(EditType.PET_NOTES);
                break;
            default:
                break;
        }
    };

    const handleShowPetNotesPageSizeDropdown = () => {
        const ref = boxContentRef;
        // eslint-disable-next-line
        const scrollHeight = ref.current!.scrollHeight;
        // eslint-disable-next-line
        const height = ref.current!.clientHeight;
        const maxScrollTop = scrollHeight - height;
        // eslint-disable-next-line
        ref.current!.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    const handlePetNotesPageSelectedChange = () => {
        const ref = boxContentRef;
        // eslint-disable-next-line
        ref.current!.scrollTop = 0;
    };

    const closeEditHandler = () => {
        setEdit(null);
        props.onClose && props.onClose();
    };

    const savedPetHandler = () => {
        props.onSaved && props.onSaved();
    };

    const savePetNotesHandler = (petNotes: Array<Note>) => {
        // eslint-disable-next-line
        let editPet = { ...props.pet!, notes: petNotes };
        // eslint-disable-next-line
        const errors = validatePet(editPet!);
        errors.forEach(error => dispatch(showErrorThunk(error.errorMessage)));

        if (errors.length === 0 && editPet) {
            dispatch(savePetThunk(apiClientWrapper, editPet));
        }
    };

    const editPet = (
        <OverFullWindow onClose={closeEditHandler}>
            <EditPet
                hideTabs={true}
                overfullWindow={true}
                pet={props.pet}
                hideHeadingComponent={true}
                editPetNotes={edit === EditType.PET_NOTES}
                editMedicalHistory={edit === EditType.MEDICAL_HISTORY}
                onSaved={savedPetHandler}
                onClose={() => setEdit(null)}
            />
        </OverFullWindow>
    );

    return (
        <>
            <Box className={clsx(classes.container, props.className)}>
                {!isMobile && (
                    <Box className={classes.containerHeader}>
                        <Grid container>
                            <Grid item xs={12}>
                                <HeaderClose
                                    title={title}
                                    onClose={closeHandler}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <Box className={classes.gridContent}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div
                                className={classes.boxContent}
                                ref={boxContentRef}
                            >
                                {props.optionMenuSelected === 'info' && (
                                    <PetInformation
                                        onNewPetNote={savePetNotesHandler}
                                        pet={props.pet}
                                        OnShowPageSizeDropdown={
                                            handleShowPetNotesPageSizeDropdown
                                        }
                                        OnPageSelectedChange={
                                            handlePetNotesPageSelectedChange
                                        }
                                    />
                                )}
                                <Box className={classes.paddingWrapper}>
                                    {props.optionMenuSelected ===
                                        'vaccines' && (
                                        <Vaccines pet={props.pet} />
                                    )}
                                    {props.optionMenuSelected === 'medical' && (
                                        <MedicalConditions pet={props.pet} />
                                    )}
                                    {props.optionMenuSelected === 'dental' && (
                                        <Dentals pet={props.pet} />
                                    )}
                                    {props.optionMenuSelected === 'vet' && (
                                        <ViewVet vet={props.vet} />
                                    )}
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>

                <Box className={classes.containerButtons}>
                    <Grid container className={classes.gridButtons}>
                        <Grid
                            item
                            xs={6}
                            className={classes.btnLeftContent}
                        ></Grid>
                        <Grid item xs={12} md={6} className={classes.gridEnd}>
                            <PetActionButton
                                pet={props.pet}
                                optionSelected={props.optionMenuSelected}
                                onSelectAction={selectActionHandler}
                                loading={edit !== null}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {edit !== null && editPet}
        </>
    );
};

export default PetCardContent;
