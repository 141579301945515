import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';

interface BottomModalMobileProps {
	children: ReactNode;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			paddingTop: '25px'
		},
		header: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '25px'
		},
		line: {
			width: '56px',
			height: '6px',
			background: '#DADADA',
			content: ' ',
			borderRadius: '50px'
		}
	})
);

export const BottomModalMobile: FunctionComponent<
	BottomModalMobileProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.header}>
				<Box className={classes.line}></Box>
			</Box>
			{props.children}
		</Box>
	);
};
