import AutocompleteResult from "model/AutocompleteResult";

export interface AutocompleteState {
  results: Array<AutocompleteResult>;
  status: AutocompleteStatus;
  loading: boolean;
}

export enum AutocompleteStatus {
  Initial,
  Searching,
  SearchingSuccess,
  Error
}
