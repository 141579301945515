import React, { FunctionComponent, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button, Checkbox, ConfirmDialog } from 'components/UI';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Appointment, { status } from '@spike/appointment-model';

interface FooterComponentProps {
    appointment?: Appointment;
    withoutButton?: boolean;
    success?: boolean;
    isDetail?: boolean;
    title?: string;
    loading: boolean;
    total?: number | string;
    disabledButton?: boolean;
    hours?: number;
    minutes?: number;
    buttonText: string;
    deposit?: string;
    status?: number;
    onBook: () => void;
    onRemove?: () => void;
    onChangeStatus?: (status: number, sendNotification?: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            margin: '16px 36px 13px 36px',
            [theme.breakpoints.up('md')]: {
                margin: '20px 40px 15px 40px'
            }
        },
        totalContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        depositContainer: {
            marginBottom: '14px !important',
            [theme.breakpoints.up('md')]: {
                marginBottom: '16 !important'
            }
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px'
        },
        normal: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '16px'
        },
        button: {
            height: 50,
            [theme.breakpoints.down('sm')]: {
                borderRadius: 30
            },
            [theme.breakpoints.up('md')]: {
                height: 55
            },
            width: '100%'
        },
        buttonDisabled: {
            backgroundColor: '#D3D3D3 !important'
        },
        textContainer: {
            display: 'flex'
        },
        spacing: {
            marginLeft: 8,
            [theme.breakpoints.up('md')]: {
                marginLeft: 10
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
        },
        iconRemoveContainer: {
            cursor: 'pointer',
            width: 55,
            height: 55,
            borderRadius: '50%',
            backgroundColor: '#F1F1F1',
            textAlign: 'center',
            alignContent: 'center'
        },
        remove: {
            width: '85% !important'
        },
        iconTrash: {
            fontSize: 26
        },
        bookingRequestActionsContainer: {
            'gap': 8,
            'flexDirection': 'row',

            '& button > span': {
                fontSize: '18px !important'
            }
        },
        confirmDialog: {
            '& .MuiDialog-paperWidthSm': {
                maxWidth: 455
            }
        },
        confirmDialogCheckboxContainer: {
            display: 'flex',
            padding: '0px 32px'
        },
        confirmDialogCheckboxText: {
            color: '#7A7A7A',
            textAlign: 'left'
        }
    })
);

export const FooterComponent: FunctionComponent<
    FooterComponentProps
> = props => {
    const classes = useStyles();

    const [showDeclineDialog, setShowDeclineDialog] = useState(false);
    const [sendDeclineNotification, setSendDeclineNotification] =
        useState(true);

    const getTruncatedTotal = (total: string) => {
        const totalInteger = total.split('.');
        return totalInteger[totalInteger.length - 1] === '0' ||
            totalInteger[totalInteger.length - 1] === '00'
            ? totalInteger[0]
            : total;
    };

    const getPriceFormatted = (price: number) => {
        return `${price.toFixed(2)}`;
    };

    const declineHandler = () => {
        setShowDeclineDialog(true);
    };

    if (props.status === status.REQUESTED) {
        return (
            <>
                <Box
                    className={clsx(
                        classes.container,
                        classes.bookingRequestActionsContainer
                    )}
                >
                    <Button
                        label="Decline"
                        variant="danger"
                        loading={props.loading}
                        className={classes.button}
                        onClick={declineHandler}
                    />
                    <Button
                        label="Accept"
                        color="green"
                        loading={props.loading}
                        className={classes.button}
                        onClick={() => props.onChangeStatus?.(status.BOOKED)}
                    />
                </Box>

                <ConfirmDialog
                    open={showDeclineDialog}
                    processing={props.loading}
                    className={classes.confirmDialog}
                    title={<Typography>Decline Appointment</Typography>}
                    question={
                        <Typography>
                            Are you sure you want to decline this appointment?
                        </Typography>
                    }
                    subquestion={
                        <Box className={classes.confirmDialogCheckboxContainer}>
                            <Checkbox
                                checked={sendDeclineNotification}
                                onChange={() =>
                                    setSendDeclineNotification(
                                        !sendDeclineNotification
                                    )
                                }
                                label=" "
                            />
                            <Box>
                                <Typography
                                    className={
                                        classes.confirmDialogCheckboxText
                                    }
                                >
                                    Send a notification to{' '}
                                    {props.appointment?.customer.firstName}{' '}
                                    {props.appointment?.customer.lastName} that
                                    their appointment has been decline.
                                </Typography>
                            </Box>
                        </Box>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Decline"
                    onCancel={() => {
                        setShowDeclineDialog(false);
                        setSendDeclineNotification(true);
                    }}
                    onConfirm={() => {
                        setShowDeclineDialog(false);
                        props.onChangeStatus?.(
                            status.DECLINED,
                            sendDeclineNotification
                        );
                    }}
                />
            </>
        );
    }

    return (
        <Box className={classes.container}>
            {props.deposit && (
                <Box
                    className={clsx(
                        classes.totalContainer,
                        classes.depositContainer
                    )}
                >
                    <Typography className={classes.normal}>Deposit</Typography>
                    <Box className={classes.textContainer}>
                        <Typography
                            className={classes.normal}
                            id="booking_label_deposit_total"
                        >
                            {props.isDetail
                                ? `$${getPriceFormatted(
                                      parseFloat(props.deposit)
                                  )}`
                                : `$${getTruncatedTotal(props.deposit)}`}
                        </Typography>
                    </Box>
                </Box>
            )}
            {(props.total || !props.success || props.deposit) && (
                <Box className={classes.totalContainer}>
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                    <Box className={classes.textContainer}>
                        <Typography
                            className={classes.title}
                            id="booking_label_total"
                        >
                            {props.isDetail && props.total
                                ? `$${getPriceFormatted(
                                      typeof props.total === 'string'
                                          ? parseFloat(props.total)
                                          : props.total
                                  )}`
                                : `$${getTruncatedTotal(
                                      props.total?.toString() || '0'
                                  )}`}
                        </Typography>
                        {(props.hours || props.minutes) && !props.deposit && (
                            <Typography
                                className={clsx(classes.title, classes.spacing)}
                            >
                                {`(${props.hours ? props.hours + ' h ' : ''}${
                                    props.minutes ? props.minutes + ' min' : ''
                                })`}
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            <Box className={classes.buttonContainer}>
                {props.onRemove && (
                    <Box
                        className={classes.iconRemoveContainer}
                        onClick={props.onRemove}
                    >
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            className={classes.iconTrash}
                        />
                    </Box>
                )}
                {!props.withoutButton && (
                    <Button
                        id={
                            props.success
                                ? 'booking_button_done'
                                : 'booking_button_book'
                        }
                        label={props.buttonText}
                        loading={props.loading}
                        color={props.success ? 'black' : 'green'}
                        size="large"
                        disabled={props.disabledButton}
                        variant="primary"
                        className={clsx(classes.button, {
                            [classes.buttonDisabled]: props.disabledButton,
                            [classes.remove]: props.onRemove
                        })}
                        onClick={props.onBook}
                    />
                )}
            </Box>
        </Box>
    );
};

export default FooterComponent;
