import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Section from '../UI/Section';
import { Box, Typography } from '@material-ui/core';
import Staff from 'model/Staff';
import { SelectField } from 'components/UI';
import { useAuth, useMasterData } from 'hooks';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { MobileDivider } from 'components/UI/MobileDivider';
import {
    ACCESS_LEVEL_ADMIN_ID,
    ACCESS_LEVEL_COLLABORATOR_ID,
    OWNER_ID
} from '@spike/model';
import { Option } from '@spike/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

interface Props {
    staff: Staff;
    onChange: (staffUpdated: any) => void;
    disabled?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: '100%'
        },
        title: {
            [theme.breakpoints.down('lg')]: {
                fontSize: 20,
                fontWeight: 600
            }
        },
        bodySection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 16px',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '37px',
                paddingRight: '33px',
                paddingBottom: '29px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '49px',
                paddingRight: '43px',
                paddingBottom: '38px'
            }
        },
        row: {
            [theme.breakpoints.up('md')]: {
                marginTop: 11,
                display: 'flex'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: 20
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: 21
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 0
            }
        },

        role: {
            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginRight: 10
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '15px'
            }
        },

        accessLevel: {
            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginLeft: 10
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: 12
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: 15
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 20
            }
        },

        infoAccessLevelContainer: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                paddingTop: 20
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 16
            }
        },

        infoAccessLevel: {
            [theme.breakpoints.up('md')]: {
                fontSize: 14,
                lineHeight: '18px',
                fontWeight: 400,
                paddingLeft: 5
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                lineHeight: '18px',
                fontWeight: 400,
                paddingLeft: 5
            }
        },

        iconInfo: {
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                paddingTop: 1
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                paddingTop: 1
            }
        }
    })
);

export const Company: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const auth = useAuth();
    const userIsOwner = auth.user!.role.id === OWNER_ID;
    const userIsAdmin = auth.user!.accessLevel.id === ACCESS_LEVEL_ADMIN_ID;
    const staffIsOwner = props.staff.role && props.staff.role.id === OWNER_ID;
    const roles =
        userIsOwner && staffIsOwner
            ? masterData.roles
            : masterData.roles.filter(role => role.id !== OWNER_ID);

    const [staff, setStaff] = useState(props.staff);

    const { role, accessLevel } = staff;

    const companyReturnData = {
        role,
        accessLevel
    };

    useEffect(() => {
        if (!isEmpty(role) || !isEmpty(accessLevel)) {
            props.onChange(companyReturnData);
        }
    }, [role, accessLevel]);

    const handleChangeRole = (role: Option<number | string>): void => {
        const accessLevel = masterData.accessLevel.find(
            accessLevel =>
                accessLevel.id ===
                (role.id === OWNER_ID
                    ? ACCESS_LEVEL_ADMIN_ID
                    : ACCESS_LEVEL_COLLABORATOR_ID)
        );

        if (accessLevel) {
            setStaff({
                ...staff,
                role: { ...role, id: Number(role.id) },
                accessLevel: { ...accessLevel }
            });
        } else {
            setStaff({ ...staff, role: { ...role, id: Number(role.id) } });
        }
    };

    const changeAccessLevelHandler = (
        selectedAccessLevel: Option<number | string>
    ): void => {
        setStaff({
            ...staff,
            accessLevel: {
                ...selectedAccessLevel,
                id: Number(selectedAccessLevel.id)
            }
        });
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <MobileDivider />

            <Section title="Company" {...props} titleClassName={classes.title}>
                <Box className={classes.bodySection}>
                    <Box className={classes.row}>
                        <Box className={clsx(classes.role)}>
                            <SelectField
                                id="staff_add_member_company_input_role"
                                label="Role"
                                placeholder="Role"
                                selectedOption={
                                    props.disabled ? undefined : role
                                }
                                options={roles}
                                onSelect={handleChangeRole}
                                disabled={props.disabled}
                            />
                        </Box>
                        <Box className={classes.accessLevel}>
                            {userIsAdmin && (
                                <SelectField
                                    label="Access Level"
                                    selectedOption={
                                        props.disabled ? undefined : accessLevel
                                    }
                                    options={masterData.accessLevel}
                                    onSelect={changeAccessLevelHandler}
                                    disabled={props.disabled}
                                />
                            )}
                        </Box>
                    </Box>
                    {(props.staff.accessLevel?.id === 1 ||
                        props.staff.accessLevel?.id === 2) && (
                        <Box className={classes.infoAccessLevelContainer}>
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                color="#5E8677"
                                className={classes.iconInfo}
                            />
                            {props.staff.accessLevel?.id === 1 ? (
                                <Typography className={classes.infoAccessLevel}>
                                    Admins have full access and control over all
                                    account details.
                                </Typography>
                            ) : (
                                <Typography className={classes.infoAccessLevel}>
                                    Collaborators can manage Bookings, Calendar,
                                    and Clients.
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Section>
        </Box>
    );
};

export default Company;
