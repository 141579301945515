import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PropsWithChildren } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "14px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: "20px",
        paddingLeft: "21px",
        paddingRight: "21px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "31px",
        paddingLeft: "33px",
        paddingRight: "33px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "42px",
        paddingLeft: "44px",
        paddingRight: "44px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "63px",
        paddingLeft: "67px",
        paddingRight: "67px",
      },
    },
  })
);

export const BodySection: FunctionComponent<PropsWithChildren> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {props.children}
    </Box>
  );
};

export default BodySection;
