import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useDropdownSelectStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 6,
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            position: 'relative',

            [theme.breakpoints.up('md')]: {
                gap: 10
            }
        },
        label: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500
        },
        dropdownToggle: {
            width: 34,
            height: 34,
            fontSize: 20,
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            borderRadius: '50%',
            border: 'solid 1px #D4D4D4',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        dropdownMenu: {
            top: 40,
            left: 0,
            width: 115,
            minHeight: 50,
            maxHeight: 252,
            zIndex: 1000,
            position: 'absolute',
            border: '1px solid #D4D4D4',
            backgroundColor: '#fff',
            paddingTop: 9,
            paddingBottom: 9,
            borderRadius: 11,
            overflowY: 'auto',

            [theme.breakpoints.up('md')]: {
                top: 45,
                maxHeight: 232,
                borderRadius: 12,
                paddingTop: 10,
                paddingBottom: 10
            }
        },
        dropdownItem: {
            'fontSize': 16,
            'lineHeight': '25px',
            'whiteSpace': 'nowrap',
            'overflow': 'hidden',
            'textOverflow': 'ellipsis',
            'padding': '0px 10px',
            'cursor': 'pointer',

            '&:hover': {
                backgroundColor: '#F8F8F8'
            },

            [theme.breakpoints.up('md')]: {
                paddingLeft: 12
            }
        },
        dropdownItemDisabled: {
            opacity: 0.7,
            cursor: 'not-allowed'
        },
        noOptions: {
            fontSize: 14,
            cursor: 'default'
        },
        hidden: {
            display: 'none'
        }
    })
);
