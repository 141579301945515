import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from 'components/UI';
import PoweredByCuddlesPay from 'components/Payments/Transactions/PoweredByCuddlesPay';
import { ApplicationStatus, statusConfig } from './ApplicationStatus';

interface CuddlespayStatusProps {
    status: ApplicationStatus;
    onButtonClick?: () => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            height: '100%'
        },
        body: {
            maxWidth: '495px',
            [theme.breakpoints.down('md')]: {
                padding: '0px 20px'
            },
            [theme.breakpoints.down('md')]: {
                padding: '0px 16px'
            }
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            paddingBottom: '30px',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                '@supports (-webkit-touch-callout: none)': {
                    paddingBottom: '110px'
                }
            }
        },
        image: {
            width: '30vw'
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#000',
            fontSize: '26px',
            lineHeight: '30px',
            marginBottom: '20px',
            [theme.breakpoints.up('sm')]: {
                fontSize: '32px',
                lineHeight: '36px'
            }
        },
        description: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#000',
            fontsize: '16px',
            lineHeight: '21px',
            [theme.breakpoints.up('sm')]: {
                fontSize: '18px',
                lineHeight: '24px'
            }
        },
        descriptionSetup: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '20px',
                paddingRight: '20px'
            }
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                'padding': '25px',
                '& span': {
                    fontSize: '16px'
                }
            }
        },
        containerStatusCuddlesPay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column'
        },
        center: {
            display: 'flex',
            justifyContent: 'center'
        },
        badgeContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            [theme.breakpoints.up('md')]: {
                marginTop: '-30px'
            },
            [theme.breakpoints.down('sm')]: {
                '@supports (-webkit-touch-callout: none)': {
                    marginTop: '-140px'
                }
            }
        },
        badgeIcon: {
            width: '90px',
            height: '90px',
            backgroundColor: '#000',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                width: '80px',
                height: '80px'
            }
        },
        iconPaymentStatus: {
            color: '#FFF',
            fontSize: '36px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '32px'
            }
        },
        buttonContainer: {
            marginTop: '20px'
        }
    })
);

export const CuddlespayStatus: FunctionComponent<CuddlespayStatusProps> = ({
    status,
    onButtonClick,
    className
}) => {
    const classes = useStyles();
    const config = statusConfig[status];

    const setupClass =
        status === ApplicationStatus.Setup ? classes.descriptionSetup : '';

    return (
        <Box className={clsx(classes.container, className)}>
            <Grid container>
                <Grid item xs={12} className={classes.center}>
                    <Box className={classes.containerStatusCuddlesPay}>
                        <Box className={classes.badgeContainer}>
                            <span className={classes.badgeIcon}>
                                <FontAwesomeIcon
                                    className={classes.iconPaymentStatus}
                                    icon={config.icon}
                                />
                            </span>
                        </Box>
                        <Box className={classes.body}>
                            <Typography className={classes.title}>
                                {config.title}
                            </Typography>
                            <Typography
                                className={clsx(
                                    classes.description,
                                    setupClass
                                )}
                            >
                                {config.description}
                            </Typography>
                            {config.showButton && onButtonClick && (
                                <Box className={classes.buttonContainer}>
                                    <Button
                                        size="large"
                                        variant="primary"
                                        label={config.buttonLabel}
                                        onClick={onButtonClick}
                                        className={classes.button}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.footer}>
                            <PoweredByCuddlesPay />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CuddlespayStatus;
