import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Option } from "@spike/model";
import { SelectionBar } from "components/UI";

interface FiltersProps {
  selectedId: string;
  options: Array<Option<string>>;
  onChange: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
      [theme.breakpoints.down(768)]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.between(768, "sm")]: {
        paddingLeft: 27,
        paddingRight: 40,
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: 43,
        paddingRight: 65,
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: 87,
        paddingLeft: 57,
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: 87,
        paddingRight: 132,
      },
    },
    selectionBar: {
      display: "inline-flex",
      backgroundColor: "#fff",

      '& [class*="selectedContainer"] p': {
        fontWeight: 600,
      },
      '& [class*="lastButton"]': {
        marginLeft: 4,
      },
      [theme.breakpoints.down("md")]: {
        "& > div > div": {
          marginLeft: 0,
          paddingLeft: 12,
          paddingRight: 12,
          marginRight: 0,
        },
        "& p":{
          fontSize: 16,
          fontWeight: 500,
        }
      },
    },
  })
);

const Filters = (props: FiltersProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <SelectionBar
          options={props.options}
          selectedId={props.selectedId}
          onSelect={props.onChange}
          className={classes.selectionBar}
        />
      </Box>
    </>
  );
};

export default Filters;
