import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PetVaccine } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { VaccinesExpirationField } from "./VaccinesExpirationField";
import { VaccinesFileField } from "./VaccinesFileField";
import { VaccinesStatusField } from "./VaccinesStatusField";

interface VaccinesTableRowProps {
  vaccine: PetVaccine;
  className?: string;
  last?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "10px 0",
      },
      [theme.breakpoints.down("md")]: {
        padding: "12px 0",
      },
    },
    rowBorder: {
      borderBottom: "1px solid #EFEFEF",
    },
    name: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        lineHeight: `${reduceResolution(24)}px`,
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        lineHeight: "24px",
        fontSize: "15px",
      },
    },
    containerVaccinesContent:{
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        flexWrap: "nowrap",
        position: "relative",
      },
    },
    vaccineName: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: "10px",
      },
    },
    vaccineExpiration: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginBottom: "10px",
      },
    },
    vaccineStatus: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    vaccineFile: {
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: "5px",
        right: "0px",
        width: "auto",
        paddingRigth: "0px",
        "& p": {
          width: "auto",
        },
        "& div": {
          paddingRight: "0px",
        }
      },
    },
  })
);

export const VaccinesTableRow: FunctionComponent<VaccinesTableRowProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.row, classes.containerVaccinesContent, { [classes.rowBorder]: !props.last }, classes.name, props.className)}>
      <Grid item xs={12} sm={3} className={classes.vaccineName}>
        {props.vaccine.type?.name}
      </Grid>
      <Grid item xs={12} sm={3} className={classes.vaccineExpiration}>
        <VaccinesExpirationField preLabel="Exp Date" vaccine={props.vaccine} />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.vaccineStatus}>
        <VaccinesStatusField vaccine={props.vaccine} />
      </Grid>
      <Grid item xs={12} sm={2} className={classes.vaccineFile}>
        <VaccinesFileField vaccine={props.vaccine} />
      </Grid>
    </Grid>
  );
};

export default VaccinesTableRow;
