import { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { Option, PetType, PetTypeIds } from "@spike/model";
import { useMasterData } from "hooks";
import { SelectField } from "components/UI";
import { FieldError } from "@spike/model";

interface Props {
  id?: string;
  petType: PetType | undefined;
  name?: string;
  selected?: Option<string>;
  onChange: (hairColor?: Option<string>, fieldName?: string) => void;
  className?: string;
  errors?: Array<FieldError>;
  required?: boolean;
  disabled?: boolean;
}

export const PetColorField: FunctionComponent<Props> = (props) => {
  const masterData = useMasterData();

  let options: Array<Option<string>> = [];
  if (props.petType?.id === PetTypeIds.DOGS) {
    options = masterData.dogColors
  } else if (props.petType?.id === PetTypeIds.CATS) {
    options = masterData.catColors
  } else if (props.petType?.id === PetTypeIds.EXOTICS) {
    options = masterData.exoticColors
  }

  const onSelectHandler = (value: Option<string | number>, fieldName?: string) => {
    const petColor = options.find((pt) => pt.id === value.id);
    props.onChange(petColor, fieldName);
  };

  return (
    <Box className={clsx(props.className)}>
      <SelectField
        id={props.id}
        label={"Color"}
        name={props.name}
        placeholder={"Color"}
        selectedOption={props.selected}
        options={options}
        onSelect={onSelectHandler}
        errors={props.errors}
        required={props.required}
        disabled={props.petType === undefined || props.disabled}
      />
    </Box>);
};

export default PetColorField;
