import { makeStyles, createStyles, Box } from '@material-ui/core';
import Portal from '@material-ui/core/Portal';
import clsx from 'clsx';
import useWindowSize from 'hooks/useWindowSize';
import { FunctionComponent, ReactNode, useEffect } from 'react';

type OptionsModalModeTypes = 'modal' | 'mobileModal' | 'fullModal';

interface PortalModalProps {
    isActive: boolean;
    children?: ReactNode;
    mode: OptionsModalModeTypes;
    onClose?: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            position: 'absolute',
            inset: 0,
            zIndex: 21
        },
        overlay: {
            height: '100dvh',
            width: '100%',
            position: 'absolute',
            inset: 0,
            background: 'rgba(0, 0, 0, 0.1)',
            zIndex: 10
        },
        modal: {
            width: 'auto',
            height: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 21
        },
        modalMobile: {
            width: '100%',
            position: 'fixed',
            bottom: '0',
            height: 'auto',
            borderRadius: '25px 25px 0px 0px',
            boxSizing: 'border-box',
            backgroundColor: '#fff',
            zIndex: 21,
            transition: 'top 0.4s ease-in-out'
        },
        fullModal: {
            width: '100%',
            height: '100dvh',
            backgroundColor: '#fff',
            zIndex: 21,
            transition: 'height 0.23s linear'
            // display: 'flex',
            // flex: 1
        },
        adressbarActive: {},
        content: {
            height: 'inherit',
            width: 'auto',
            position: 'relative',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column'
        }
    })
);

const PortalModal: FunctionComponent<PortalModalProps> = props => {
    const classes = useStyles();

    const { isAdressbarActive } = useWindowSize();

    const classSelector: Record<OptionsModalModeTypes, string> = {
        modal: classes.modal,
        mobileModal: classes.modalMobile,
        fullModal: classes.fullModal
    };

    const selectedClassName = classSelector[props.mode];

    const root = document.getElementById('root');

    useEffect(() => {
        if (props.isActive && props.mode === 'mobileModal') {
            document.body.style.overflowY = 'hidden';
        }
        if (!props.isActive && props.mode === 'mobileModal') {
            document.body.style.overflowY = 'unset';
        }
        if (props.isActive && props.mode === 'fullModal') {
            root!.style.display = 'none';
        }
        root!.style.display = 'block';
    }, [props.isActive]);

    return props.isActive ? (
        <Portal>
            <Box
                className={
                    props.mode === 'fullModal' ? classes.root : classes.overlay
                }
                onClick={
                    props.mode === 'mobileModal' || props.mode === 'modal'
                        ? () => props.onClose && props.onClose()
                        : undefined
                }
            >
                <Box
                    className={clsx(
                        selectedClassName,
                        isAdressbarActive && props.mode === 'fullModal'
                            ? classes.adressbarActive
                            : null
                    )}
                >
                    <Box
                        className={classes.content}
                        onClick={e => e.stopPropagation()}
                    >
                        {props.children}
                    </Box>
                </Box>
            </Box>
        </Portal>
    ) : null;
};

export default PortalModal;
