import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    OutlinedInput,
    Theme,
    Typography
} from '@material-ui/core';
import { useToggle } from 'hooks';
import { Checkbox } from 'components/UI';
import { EditableDescription } from './EditableDescription';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

export interface Props {
    optionName: string;
    description?: string;
    checked: boolean;
    index?: number;
    onTypeChange: (
        checked: boolean,
        index?: number,
        description?: string
    ) => void;
    editable?: boolean;
    section?: string;
    onEditName?: (optionName: string) => void;
    onChangeDescription?: (description: string) => void;
    disabled?: boolean;
    customTextContainer?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: (props: Props) => ({
            display: 'flex',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                height: 'auto',
                marginBottom: props.section === 'petType' ? '17px' : '0px'
            },
            [theme.breakpoints.only('md')]: {
                height: '35px',
                marginBottom: props.section === 'petType' ? '9px' : '0px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '40px',
                marginBottom: props.section === 'petType' ? '13px' : '0px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '60px',
                marginBottom: props.section === 'petType' ? '19px' : '0px'
            }
        }),
        containerNew: {
            [theme.breakpoints.down('md')]: {
                position: 'relative',
                marginBottom: '17px !important'
            }
        },
        textContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                'marginLeft': '5px',
                'width': '100%',
                'alignItems': 'center',
                '& .MuiOutlinedInput-root': {
                    width: '100%'
                }
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: 'column'
            }
        },
        optionName: {
            cursor: 'pointer',
            fontSize: '16px',
            paddingTop: '4.25px'
        },
        checkBox: {
            [theme.breakpoints.down('sm')]: {
                width: '3px'
            },
            [theme.breakpoints.only('md')]: {
                width: '5px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '7px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '11px'
            }
        },
        containerCheck: {
            'display': 'flex',
            'alignItems': 'center',
            'marginTop': '2.5px',
            '& .MuiCheckbox-root': {
                paddingLeft: '0px !important',
                [theme.breakpoints.down('sm')]: {
                    paddingRight: '3px',
                    fontSize: '6px'
                },
                [theme.breakpoints.only('md')]: {
                    paddingRight: '4px',
                    fontSize: '16px'
                },
                [theme.breakpoints.only('lg')]: {
                    paddingRight: '5px',
                    fontSize: '16px'
                },
                [theme.breakpoints.only('xl')]: {
                    paddingRight: '8px',
                    fontSize: '16px'
                }
            }
        },
        outlineInput: {
            '& .MuiInputBase-root': {
                padding: '0px !important',
                minWidth: '100%'
            },
            [theme.breakpoints.down('md')]: {
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'solid 1px #D3D3D3 !important',
                    height: '40px',
                    borderRadius: '33px',
                    width: '100%'
                },
                '& input[type=text]': {
                    paddingTop: '9px',
                    paddingBottom: '10px',
                    width: '100%'
                }
            }
        },
        checkContainer: {
            [theme.breakpoints.down('md')]: {
                paddingTop: '0px',
                display: 'inline-flex',
                alignItems: 'center',
                marginTop: '-6px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '9px'
            }
        }
    })
);

export const OptionType: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const [openCheck, handleOpenCheck, setOpenCheck] = useToggle(props.checked);
    const [isEditing, handleEdit, setEdit] = useToggle(false);
    const [description, setDescription] = useState(props.description);
    const [inputValue, setInputValue] = useState<string>(props.optionName);

    useEffect(() => {
        setOpenCheck(props.checked);
    }, [props.checked]);

    const handleCheck = () => {
        handleOpenCheck();
        props.onTypeChange(!openCheck, props.index, description);
    };

    const changeDescriptionHandler = (value: string) => {
        setDescription(value);
        props.onChangeDescription && props.onChangeDescription(value);
    };

    const handleClick = () => {
        setEdit(true);
    };

    const handleChange = (e: any) => {
        setInputValue(e.target.value);
        if (e.target.value === '') {
            handleOpenCheck();
            props.onTypeChange(!props.checked, props.index, description);
        }
    };

    const handleBlur = () => {
        setEdit(true);
        if (!isEmpty(inputValue)) {
            props.onEditName && props.onEditName(inputValue);
        }
    };

    const handleEnterKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            setEdit(false);
            if (!isEmpty(inputValue)) {
                props.onEditName && props.onEditName(inputValue);
            }
        }
    };

    return (
        <Box className={clsx(classes.container, classes.containerNew)}>
            <Box className={isEditing ? classes.checkContainer : undefined}>
                <Checkbox
                    checked={openCheck}
                    onChange={handleCheck}
                    className={classes.containerCheck}
                    iconColor={props.disabled ? '#c7c7c7' : '#000000'}
                    disabled={props.disabled}
                />
            </Box>
            <Box
                className={`${classes.textContainer} ${props.customTextContainer}`}
            >
                {isEditing ? (
                    <OutlinedInput
                        value={inputValue}
                        autoFocus={true}
                        onBlur={handleBlur}
                        onKeyPress={handleEnterKeyPress}
                        onChange={handleChange}
                        className={classes.outlineInput}
                    />
                ) : (
                    <Typography
                        className={classes.optionName}
                        onClick={
                            !props.disabled
                                ? props.editable
                                    ? handleClick
                                    : handleCheck
                                : () => null
                        }
                    >
                        {inputValue}
                    </Typography>
                )}
                {props.description !== undefined && (
                    <EditableDescription
                        value={props.description}
                        onChange={changeDescriptionHandler}
                    />
                )}
            </Box>
        </Box>
    );
};
