import React, { FunctionComponent, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { pageView } from "googleAnalytics/GoogleAnalytics";
import Header from 'rocket/components/Header'
import LeftColumn from 'rocket/components/SetPassword/LeftColumn'
import RightColumn from 'rocket/components/NewPassword/RightColumn'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    },
    headerContainer: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center'
        }
    },
    headerLink: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    }
}));

export const NewPassword:FunctionComponent = () => {
    const classes = useStyles()

    useEffect(() => {
        pageView("/newpassword");
    }, []);

    document.title = 'Create your new password - Cuddles'

    return (
        <div id="set-password">
            <Header
                containerStyles={classes.headerContainer} />

            <div className={classes.root}>
                <Grid container spacing={0}>
                    <LeftColumn />
                    <RightColumn />
                </Grid>
            </div>
        </div>
    );
}

export default NewPassword;