import { faUsers, faUserTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Button,
    createStyles,
    FormControl,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import {
    fetchClientGroupsThunk,
    fetchClientsNameThunk,
    fetchClientsPageThunk,
    setSearchingThunk
} from 'actions/smsCampaigns/SMSCampaignsActions';
import clsx from 'clsx';
import { PriceFormat } from 'components/UI';
import ChooseModal, {
    Option as Option2
} from 'components/UI/ChooseModal/ChooseModal';
import ChooseModalClients from 'components/UI/ChooseModal/Clients';
import Client from '@spike/client-model';
import {
    SMSCampaignCustomerGroupOption,
    SMSCampaignCustomerGroups
} from 'model/SMSCampaign';
import { showWarningThunk } from '@spike/notifications-action';
import React, {
    Fragment,
    FunctionComponent,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SMSCampaignsStatus } from 'reducers/smsCampaigns/SMSCampaignsState';
import { RootState } from 'store';
import SMSCreateFormGroup from './SMSCreateFormGroup';
import SMSCreateFormOption from './SMSCreateFormOption';

export enum Recipients {
    ALL = 'all',
    GROUP = 'criteria'
}

type Values = {
    selectedClients: number[] | 'all';
    excludedClients: number[];
    recipients: string;
    group?: SMSCampaignCustomerGroups;
    filter?: SMSCampaignCustomerGroupOption[] | SMSCampaignCustomerGroupOption;
};

interface Props {
    values: Values;
    selectedCount: number;
    onChange: (values: Values) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioOption: {
            'fontSize': 24,
            'width': '100%',
            'display': 'flex',
            'borderRadius': 12,
            'cursor': 'pointer',
            'padding': '15px 20px',
            'margin': '1px 0px 20px 0px',
            'border': '1px solid #D3D3D3',

            '&.active': {
                borderWidth: 2,
                borderColor: '#000',
                margin: '0px 0px 20px 0px'
            },

            '&:last-child': {
                marginBottom: 40
            },

            '& .MuiButtonBase': {
                '&-root': {
                    'padding': 0,
                    'color': '#222',
                    'marginRight': 11,
                    'alignItems': 'flex-start',

                    '& svg': {
                        width: 20,
                        height: 20
                    }
                }
            },

            '& > svg': {
                marginRight: 10,
                color: '#92B4A7'
            },
            '& h6': {
                marginTop: 0,
                marginBottom: 6,
                paddingTop: 2,

                color: '#222',
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 500
            },
            '& p': {
                margin: 0,
                color: '#222',
                fontSize: 14,
                lineHeight: 1.4,
                fontWeight: 400
            }
        },
        allClients: {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'space-between',

            '& p': {
                'margin': 0,
                'fontSize': 15,
                'lineHeight': 1.4,

                '& span': {
                    color: '#222',
                    fontSize: 32,
                    lineHeight: 1,
                    fontWeight: 600,
                    marginRight: 3,
                    letterSpacing: '-0.03em'
                }
            },
            '& button': {
                'height': 39,
                'color': '#fff',
                'fontSize': 14,
                'lineHeight': 1,
                'fontWeight': 500,
                'borderRadius': 30,
                'padding': '0px 26px',
                'textTransform': 'none',
                'backgroundColor': '#222',

                '&:hover': {
                    backgroundColor: '#565656'
                }
            }
        },
        table: {
            'color': '#222',
            'width': '100%',
            'borderRadius': 12,
            'padding': '5px 35px 0px',
            'backgroundColor': '#FAFAFA',
            [theme.breakpoints.down('sm')]: {
                padding: '5px 28px 0px'
            },
            '& hr': {
                margin: 0,
                border: 'none',
                borderTop: '1px solid #D4D4D4'
            },
            '& label': {
                margin: 0
            }
        },
        tableRow: {
            'fontSize': 16,
            'lineHeight': 1,
            'display': 'flex',
            'padding': '23px 0px',
            '& label': {
                fontWeight: 500,
                marginRight: 'auto'
            }
        },
        tableBody: {
            '& + &': {
                paddingTop: 0
            }
        },
        tableTotal: {
            'fontSize': 20,
            'lineHeight': 1,
            'fontWeight': 600,
            'padding': '24px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            },

            '& label': {
                fontWeight: 600
            }
        },
        group: {
            '& > .MuiFormGroup-root': {
                display: 'block'
            },
            '& > .MuiFormControl-root': {
                width: '100%'
            },
            '& > .MuiFormLabel-root': {
                marginBottom: 20
            }
        },
        title: {
            fontSize: 18,
            padding: '20px 0',
            fontWeight: 600,
            color: '#000'
        }
    })
);

const SMSCreateSecondStep: FunctionComponent<Props> = ({
    values,
    selectedCount,
    onChange
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    /**
     * State
     */
    const { clientTags, petTypes } = useSelector(
        (state: RootState) => state.masterData.data
    );

    const groups = useSelector((state: RootState) => state.smsCampaigns.groups);
    const quote = useSelector((state: RootState) => state.smsCampaigns.quote);

    const clients: {
        all: Client[];
        search: Client[];
        status: SMSCampaignsStatus;
        count: number;
        page?: string | number;
        max_pages?: number;
    } = {
        status: useSelector((state: RootState) => state.smsCampaigns.status),
        page: useSelector(
            (state: RootState) => state.smsCampaigns.clients.all?.page
        ),
        max_pages: useSelector(
            (state: RootState) => state.smsCampaigns.clients.all?.total_pages
        ),
        all: useSelector(
            (state: RootState) => state.smsCampaigns.clients.all?.list || []
        ),
        search: useSelector(
            (state: RootState) => state.smsCampaigns.clients.search?.list || []
        ),
        count: useSelector(
            (state: RootState) =>
                state.smsCampaigns.clients.all?.total_records || 0
        )
    };

    /**
     * Local state
     */
    const [showPetModal, setShowPetModal] = useState<boolean>(false);
    const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
    const [showClientsModal, setShowClientsModal] = useState<boolean>(false);

    const [recipients, setRecipients] = useState<string>(values.recipients);
    const [recipientGroup, setRecipientGroup] = useState<
        SMSCampaignCustomerGroups | undefined
    >(values.group);
    const [recipientFilter, setRecipientFilter] = useState<
        | SMSCampaignCustomerGroupOption[]
        | SMSCampaignCustomerGroupOption
        | undefined
    >(values.filter);

    const [selectedClients, setSelectedClients] = useState<number[] | 'all'>(
        values.selectedClients
    );
    const [excludedClients, setExcludedClients] = useState<number[]>(
        values.excludedClients
    );

    /**
     * Events
     */
    const recipientsOptChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRecipientGroup(e.target.value as SMSCampaignCustomerGroups);
    };

    const recipientFilterChanged = (filter: {
        type: string;
        value: string;
    }) => {
        if (!recipientGroup || !groups) return;

        const opt: SMSCampaignCustomerGroupOption | undefined = groups[
            recipientGroup
        ].find((opt: SMSCampaignCustomerGroupOption) => {
            return (opt.days || opt.pet_type || opt.tag) === filter.value;
        });

        setRecipientFilter(opt);
    };

    const onClientsSearch = (search: string) => {
        if (search.length === 0) {
            dispatch(setSearchingThunk(false));
            return;
        }

        if (search.length > 0 && search.length < 3) {
            dispatch(showWarningThunk('Type at least 3 digits.'));
            return;
        }

        dispatch(fetchClientsNameThunk(search, 1, 50));
    };

    const onClientsNextPage = (page: number) => {
        if (
            page === clients.max_pages ||
            clients.status === SMSCampaignsStatus.ClientsFetching ||
            clients.status === SMSCampaignsStatus.ClientsSearching ||
            clients.status === SMSCampaignsStatus.ClientsSearchSuccess
        )
            return false;

        dispatch(fetchClientsPageThunk(page, 50, false));
    };

    const onClientsSave = (selected: number[] | 'all', excluded: number[]) => {
        setSelectedClients(selected);
        setExcludedClients(excluded);
        setShowClientsModal(false);
    };

    const onRecipientsOptionChange = (opt: string) => {
        setRecipients(opt);

        if (opt === Recipients.ALL && opt !== recipients) {
            setSelectedClients('all');
        }
    };

    const tagsModalChange = (opts: Option2[]) => {
        const selectedTags = groups?.by_tag.reduce(
            (arr: SMSCampaignCustomerGroupOption[], tag, i) => {
                if (opts.find(opt => opt.id === tag.tag)) arr.push(tag);
                return arr;
            },
            []
        );

        setRecipientFilter(selectedTags);
        setShowTagsModal(false);
    };

    const petTypesModalChange = (opts: Option2[]) => {
        const selectedTypes = groups?.by_pet.reduce(
            (arr: SMSCampaignCustomerGroupOption[], type, i) => {
                if (opts.find(opt => opt.id === type.pet_type)) arr.push(type);

                return arr;
            },
            []
        );

        setRecipientFilter(selectedTypes);
        setShowPetModal(false);
    };

    /**
     * Computed
     */
    const tagOptions: Option2[] = useMemo(() => {
        const tags = [...clientTags.negative, ...clientTags.positive];

        return (
            groups?.by_tag.map((opt, i) => ({
                label: tags.find(tag => tag.id === opt.tag)?.name || '',
                id: opt.tag || i,
                count: opt.records
            })) || []
        );
    }, [groups, clientTags]);

    const selectedTags: Option2[] = useMemo(() => {
        if (!Array.isArray(values.filter)) return [];

        const tags = [...clientTags.negative, ...clientTags.positive];

        return values.filter.map((opt, i) => ({
            label: tags.find(tag => tag.id === opt.tag)?.name || '',
            id: opt.tag || i,
            count: opt.records
        }));
    }, [values, clientTags]);

    const petOptions: Option2[] = useMemo(() => {
        return (
            groups?.by_pet.map((opt, i) => ({
                label:
                    petTypes.find(type => type.id === opt.pet_type)?.plural ||
                    '',
                id: opt.pet_type || i,
                count: opt.records
            })) || []
        );
    }, [groups, petTypes]);

    const selectedPetTypes: Option2[] = useMemo(() => {
        if (!Array.isArray(values.filter)) return [];

        return values.filter.map((opt, i) => ({
            label:
                petTypes.find(type => type.id === opt.pet_type)?.plural || '',
            id: opt.pet_type || i,
            count: opt.records
        }));
    }, [values, petTypes]);

    const tagsOptionDescription = useMemo(() => {
        if (
            recipientGroup === SMSCampaignCustomerGroups.tag &&
            Array.isArray(recipientFilter)
        ) {
            return (
                'Tags selected: ' +
                selectedTags.map(tag => `<b>${tag.label}</b>`).join(', ')
            );
        }

        return 'Select at least one tag';
    }, [recipientGroup, recipientFilter, selectedTags]);

    const petOptionDescription = useMemo(() => {
        if (
            recipientGroup === SMSCampaignCustomerGroups.pet &&
            Array.isArray(recipientFilter)
        ) {
            return (
                'Pet type selected: ' +
                selectedPetTypes.map(type => `<b>${type.label}</b>`).join(', ')
            );
        }

        return 'Select a pet type';
    }, [recipientGroup, recipientFilter, selectedPetTypes]);

    /**
     * Effects
     */
    useEffect(() => {
        onChange({
            recipients,
            excludedClients,
            selectedClients,
            group: recipientGroup,
            filter: recipientFilter
        });
    }, [
        recipients,
        recipientGroup,
        recipientFilter,
        excludedClients,
        selectedClients
    ]);

    useEffect(() => {
        if (
            !groups ||
            !recipientGroup ||
            ![
                SMSCampaignCustomerGroups.tag,
                SMSCampaignCustomerGroups.pet
            ].includes(recipientGroup as SMSCampaignCustomerGroups)
        )
            return;

        setRecipientFilter(undefined);
    }, [recipientGroup]);

    useEffect(() => {
        dispatch(fetchClientsPageThunk(1, 50));
        dispatch(fetchClientGroupsThunk());
    }, []);

    /**
     * Components
     */
    const RecipientsOptions = () => (
        <Box>
            <Box
                onClick={() => onRecipientsOptionChange(Recipients.ALL)}
                className={clsx(classes.radioOption, {
                    active: recipients === Recipients.ALL
                })}
            >
                <Radio value="all" checked={recipients === Recipients.ALL} />
                <FontAwesomeIcon icon={faUsers} />
                <Box>
                    <h6>All Clients</h6>
                    <p>Send to every client in your client list.</p>
                </Box>
            </Box>
            {false /*groups*/ && (
                <Box
                    onClick={() => onRecipientsOptionChange(Recipients.GROUP)}
                    className={clsx(classes.radioOption, {
                        active: recipients === Recipients.GROUP
                    })}
                >
                    <Radio
                        value="criteria"
                        checked={recipients === Recipients.GROUP}
                    />
                    <FontAwesomeIcon icon={faUserTag} />

                    <Box>
                        <h6>Client Groups</h6>
                        <p>Choose groups of clients in your client list.</p>
                    </Box>
                </Box>
            )}
        </Box>
    );

    const AllClientsComponent = () => (
        <Box className={classes.allClients}>
            <p>
                <span>{selectedCount}</span>
                Clients
            </p>

            {recipients === Recipients.ALL && (
                <Button onClick={() => setShowClientsModal(true)}>Edit</Button>
            )}
        </Box>
    );

    const PriceTable = () => (
        <Fragment>
            {quote && Object.values(quote).length > 0 && (
                <Box className={classes.table}>
                    <Box className={classes.tableRow}>
                        <label>{quote.subscriptions} SMS at $0.05/each</label>
                    </Box>
                    <hr />
                    <Box className={clsx(classes.tableRow, classes.tableBody)}>
                        <label>Subtotal</label>
                        <span>
                            <PriceFormat price={quote.subtotal} />
                        </span>
                    </Box>
                    <Box className={clsx(classes.tableRow, classes.tableBody)}>
                        <label>Tax</label>
                        <span>
                            <PriceFormat price={quote.tax} />
                        </span>
                    </Box>
                    <hr />
                    <Box className={clsx(classes.tableRow, classes.tableTotal)}>
                        <label>Total</label>
                        <span>
                            <PriceFormat price={quote.total} />
                        </span>
                    </Box>
                </Box>
            )}
        </Fragment>
    );

    return (
        <Fragment>
            <ChooseModalClients
                title="Choose Clients"
                excluded={values.excludedClients}
                selected={values.selectedClients}
                loading={clients.status === SMSCampaignsStatus.ClientsSearching}
                page={clients.page}
                clients={clients.all}
                total={clients.count}
                show={showClientsModal}
                searchResults={clients.search}
                showSearchResults={
                    clients.status === SMSCampaignsStatus.ClientsSearchSuccess
                }
                onSearch={onClientsSearch}
                onNextPage={onClientsNextPage}
                onClose={() => setShowClientsModal(false)}
                onSave={onClientsSave}
            />

            <ChooseModal
                title="Add Tag(s)"
                subtitle="Please, select at least one tag"
                selectAllLabel="All Tags"
                value={selectedTags}
                options={tagOptions}
                show={showTagsModal}
                onSave={tagsModalChange}
                onClose={() => setShowTagsModal(false)}
            />

            <ChooseModal
                title="Add Pet Type"
                subtitle="Please, select at least one pet type"
                selectAllLabel="All Pet Type"
                value={selectedPetTypes}
                options={petOptions}
                show={showPetModal}
                onSave={petTypesModalChange}
                onClose={() => setShowPetModal(false)}
            />

            <SMSCreateFormGroup>
                <Box>
                    <FormLabel className={clsx({ [classes.title]: isMobile })}>
                        Recipients
                    </FormLabel>
                    <p>Choose which clients will receive your message.</p>
                </Box>
                <RecipientsOptions />
                {recipients && <hr />}
            </SMSCreateFormGroup>

            {recipients && recipients === Recipients.GROUP && (
                <SMSCreateFormGroup className={classes.group}>
                    <FormLabel>Select a Group</FormLabel>
                    <FormControl>
                        <RadioGroup
                            name="recipientsGroup"
                            onChange={recipientsOptChanged}
                        >
                            <SMSCreateFormOption
                                count={selectedCount}
                                title="By Date Added"
                                selected={recipientGroup}
                                value={SMSCampaignCustomerGroups.date}
                                description="Clients added in the last"
                                filterChange={recipientFilterChanged}
                                filter={{
                                    type: 'dropdown',
                                    value: Array.isArray(recipientFilter)
                                        ? undefined
                                        : recipientFilter,
                                    options: groups?.created_last.map(
                                        (
                                            opt: SMSCampaignCustomerGroupOption
                                        ) => ({
                                            key: opt.days || '',
                                            name: `${opt.days} days`
                                        })
                                    )
                                }}
                            />

                            <SMSCreateFormOption
                                count={selectedCount}
                                selected={recipientGroup}
                                title="By Client Activity"
                                value={SMSCampaignCustomerGroups.activity}
                                description="Clients who have not booked"
                                filterChange={recipientFilterChanged}
                                filter={{
                                    type: 'dropdown',
                                    value: Array.isArray(recipientFilter)
                                        ? undefined
                                        : recipientFilter,
                                    options: groups?.booked_last.map(
                                        (
                                            opt: SMSCampaignCustomerGroupOption
                                        ) => ({
                                            key: opt.days || '',
                                            name: `${opt.days} days`
                                        })
                                    )
                                }}
                            />

                            <SMSCreateFormOption
                                count={selectedCount}
                                title="By Client Tag"
                                selected={recipientGroup}
                                value={SMSCampaignCustomerGroups.tag}
                                description={tagsOptionDescription}
                                filter={{
                                    type: 'button',
                                    label: 'Select Tag(s)',
                                    onClick: () => setShowTagsModal(true)
                                }}
                            />

                            <SMSCreateFormOption
                                count={selectedCount}
                                title="By Pet Type"
                                selected={recipientGroup}
                                value={SMSCampaignCustomerGroups.pet}
                                description={petOptionDescription}
                                filter={{
                                    type: 'button',
                                    label: 'Select Pet Type',
                                    onClick: () => setShowPetModal(true)
                                }}
                            />
                        </RadioGroup>
                    </FormControl>

                    <hr />
                </SMSCreateFormGroup>
            )}
            {recipients && (
                <SMSCreateFormGroup>
                    <FormLabel>Total clients</FormLabel>
                    <p>
                        View and edit the clients your message will be sent to.
                    </p>
                    <AllClientsComponent />
                    <hr />
                </SMSCreateFormGroup>
            )}
            {recipients && (
                <SMSCreateFormGroup>
                    <FormLabel>Price</FormLabel>
                    <PriceTable />
                </SMSCreateFormGroup>
            )}
        </Fragment>
    );
};

export default SMSCreateSecondStep;
