import { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import ProductsHeader from "./Header/ProductsHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsThunk } from "@spike/products-action";
import { ProductsState } from "@spike/products-action";
import { RootState } from "store";
import { fetchThunk as fetchVendorsThunk } from "actions/productVendors/ProductVendorsActions";
import { fetchThunk as fetchCategoriesThunk } from "actions/productCategories/ProductCategoriesActions";
import { ProductVendorsState } from "reducers/productVendors/ProductVendorsState";
import { ProductCategoriesState } from "reducers/productCategories/ProductCategoriesState";
import Table from "./Table/Table";
import { Product } from "@spike/product-model";
import Pagination from "components/UI/Pagination";
import ProductVendor from "model/ProductVendor";
import ProductCategory from "model/ProductCategory";
import ProductComponent from "./Product/ProductComponent";
import VendorComponent from "./Vendor/VendorComponent";
import CategoryComponent from "./Category";
import AssignProductsComponent from "./Category/AssignProductsComponent";
import { useApiClientWrapper } from "hooks";
import NotMobileYet from "components/UI/NotMobileYet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      height: "100%",
      position: "relative",
      borderRadius: "18px"
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    pagination: {
      [theme.breakpoints.down("md")]: {
        paddingRight: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "30px",
      },
    },
    tableContainer: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        minHeight: "calc(100vh - 280px)",
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: "calc(100vh - 350px)",
      },
      flexGrow: 1,
    },
    imageNotMobileYet:{
      display: "flex",
    }
  })
);


export const ProductsComponent: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showNewProduct, setShowNewProduct] = useState(false);
  const [showNewVendor, setShowNewVendor] = useState(false);
  const [showNeworRenameCategory, setShowNeworRenameCategory] = useState(false);
  const [vendorsActive, setVendorsActive] = useState(true);
  const [vendorsDeactive, setVendorsDeactive] = useState(true);
  const [productCategoryFilter, setProductCategoryFilter] = useState<(number | undefined)[]>([undefined]);
  const [productCategoryAllFilter, setProductCategoryAllFilter] = useState(false);
  const [productEdit, setProductEdit] = useState<Product | undefined>(undefined);
  const [productsCategoryFiltered, setProductsCategoryFiltered] = useState(false);
  const [vendorEdit, setVendorEdit] = useState<ProductVendor | undefined>(undefined);
  const [categoryToRename, setCategoryToRename] = useState<ProductCategory | undefined>(undefined);
  const [showAssignProducts, setShowAssignProducts] = useState(false);
  const [redirectionVendor, setRedirectionVendor] = useState(false);
  const [redirectionCategory, setRedirectionCategory] = useState(false);

  const products = useSelector<RootState, ProductsState>((state) => state.products);
  const vendors = useSelector<RootState, ProductVendorsState>((state) => state.productVendors);
  const categories = useSelector<RootState, ProductCategoriesState>((state) => state.productCategories);
  const loadingProducts = useSelector<RootState, boolean>((state) => state.products.loading);
  const loadingVendors = useSelector<RootState, boolean>((state) => state.productVendors.loading);
  const loadingCategories = useSelector<RootState, boolean>((state) => state.productCategories.loading);

  const getCount = (entity: string) => {
    switch (entity) {
      case "products":
        return products.list.length;
      case "vendors":
        return vendors.list.length;
      default: {
        return categories.list.length;
      }
    }
  };

  const tableViews = [{ id: "products", name: "Products", count: getCount("products") }, { id: "vendors", name: "Vendors", count: getCount("vendors") }, { id: "categories", name: "Categories", count: getCount("categories") }];
  const [view, setView] = useState(tableViews[0]);

  let vendorEditRedirection: ProductVendor | undefined = undefined;

  useEffect(() => {
    dispatch(fetchProductsThunk(apiClientWrapper));
    dispatch(fetchVendorsThunk());
    dispatch(fetchCategoriesThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addProductHandler = () => {
    setProductEdit(undefined);
    setShowNewProduct(true);
    setRedirectionVendor(false);
  };

  const addVendorHandler = () => {
    setVendorEdit(undefined);
    setShowNewVendor(true);
    setRedirectionVendor(false);
  };

  const addCategoryHandler = () => {
    setCategoryToRename(undefined);
    setShowNeworRenameCategory(true);
    setRedirectionCategory(false);
  };

  const getProductList = () => {
    let productListFiltered: Array<Product> = [];
    let productListFilteredAux: Array<Product> = [];

    if (searchText) {
      const prodcutListAux = products.list.filter((item) => {
        const name = `${item.name.toLowerCase()}`;
        const brand = item.brand ? item.brand.toLowerCase() : "";
        const manufacturer = item.manufacturer ? item.manufacturer.toLowerCase() : "";
        const category = item.category?.name ? item.category?.name.toLowerCase() : "";
        return (
          searchText.length === 0 || name.indexOf(searchText.toLowerCase()) > -1 ||
          brand.indexOf(searchText.toLowerCase()) > -1 ||
          manufacturer.indexOf(searchText.toLowerCase()) > -1 ||
          category.indexOf(searchText.toLowerCase()) > -1
        );
      });
      productListFiltered = prodcutListAux;
    }
    else {
      productListFiltered = products.list;
    }

    if (!productCategoryAllFilter) {
      if (productCategoryFilter.length > 0 && productCategoryFilter[0] !== undefined) {
        productListFilteredAux = [];
        for (let i = 0; i < productListFiltered.length; i++) {
          const productFound = productCategoryFilter.find((op) => op === productListFiltered[i].category?.id);
          if (productFound) {
            productListFilteredAux.push(productListFiltered[i]);
          }
        }
        productListFiltered = productListFilteredAux;
      }
      if ((productCategoryFilter.length === 0 || productCategoryFilter[0] === undefined) && productsCategoryFiltered) {
        productListFiltered = [];
      }
    }

    return productListFiltered;
  };

  const getVendorList = () => {
    let vendorListFiltered: Array<ProductVendor> = vendors.list;

    if (searchText) {
      const vendorListAux = vendors.list.filter((item) => {
        const name = item.name.toLowerCase();
        const contact = item.contact ? item.contact.toLowerCase() : "";
        const status = item.active ? "active" : "inactive";
        const email = item.email ? item.email.toLowerCase() : "";
        const website = item.website ? item.website.toLowerCase() : "";
        const state = item.address && item.address.state ? item.address.state.toLowerCase() : "";
        return (
          searchText.length === 0 || name.indexOf(searchText.toLowerCase()) > -1 ||
          contact.indexOf(searchText.toLowerCase()) > -1 ||
          status.indexOf(searchText.toLowerCase()) > -1 ||
          email.indexOf(searchText.toLowerCase()) > -1 ||
          website.indexOf(searchText.toLowerCase()) > -1 ||
          state.indexOf(searchText.toLowerCase()) > -1
        );
      });
      vendorListFiltered = vendorListAux;
    }

    if (!vendorsActive && vendorsDeactive) {
      vendorListFiltered = vendorListFiltered.filter((v) => !v.active);
    }

    if (vendorsActive && !vendorsDeactive) {
      vendorListFiltered = vendorListFiltered.filter((v) => v.active);
    }

    if (!vendorsActive && !vendorsDeactive) {
      vendorListFiltered = [];
    }

    return vendorListFiltered;
  };

  const getCategoryList = () => {
    let categoryListFiltered: Array<ProductCategory> = [];

    if (searchText) {
      const categoryListAux = categories.list.filter((item) => {
        const name = `${item.name.toLowerCase()}`;
        const status = item.active ? "active" : "inactive";
        return (
          searchText.length === 0 || name.indexOf(searchText.toLowerCase()) > -1 ||
          status.indexOf(searchText.toLowerCase()) > -1
        );
      });
      categoryListFiltered = categoryListAux;
    }
    else {
      categoryListFiltered = categories.list;
    }

    return categoryListFiltered;
  };

  const getLoading = () => {
    return view.id === "products" ? loadingProducts : view.id === "vendors" ? loadingVendors : loadingCategories;
  };

  const getVisiblePagination = () => {
    return view.id === "products" && products.list.length > 0 ? true
      : view.id === "vendors" && vendors.list.length > 0 ? true
        : view.id === "categories" && categories.list.length > 0 ? true
          : false;
  };

  const selectionViewHandler = (selectedViewId: string) => {
    setPage(1);
    setView(tableViews.find((v) => v.id === selectedViewId)!);
    setPageSize(10);
    setSearchText("");
    setProductCategoryFilter([undefined]);
    setProductCategoryAllFilter(false);
    setProductsCategoryFiltered(false);
  };

  const changePageHandler = (page: number) => {
    setPage(page);
  }

  const changePageSizeHandler = (pageSize: number) => {
    setPageSize(pageSize);
  }

  const handleSelectProductCategory = (ids: (number | undefined)[], all: boolean) => {
    setProductCategoryFilter(ids);
    setProductCategoryAllFilter(all);
    setProductsCategoryFiltered(true);
  }

  const handleSelectVendor = (active: boolean, deactive: boolean) => {
    setVendorsActive(active);
    setVendorsDeactive(deactive);
  }

  const handleBackProduct = () => {
    setShowNewProduct(false);
    setProductEdit(undefined);
    setRedirectionVendor(false);
  };

  const handleEditProduct = (productToEdit: Product) => {
    setShowNewProduct(true);
    setProductEdit(productToEdit);
  };

  const handleEditVendor = (vendorToEdit: ProductVendor) => {
    setShowNewVendor(true);
    setVendorEdit(vendorToEdit);
  };

  const handleEditCategory = (categoryToEdit: ProductCategory) => {
    setShowNeworRenameCategory(true);
    setCategoryToRename(categoryToEdit);
  };

  const handleAssignProducts = (categoryToEdit: ProductCategory) => {
    setShowNeworRenameCategory(false);
    setShowAssignProducts(true);
    setCategoryToRename(categoryToEdit);
  };

  const handleBackVendor = (redirect: boolean) => {
    setShowNewVendor(false);
    setVendorEdit(undefined);
    if (redirect) {
      setShowNewProduct(true);
    }
  };

  const handleBackCategory = () => {
    setShowNeworRenameCategory(false);
    setShowAssignProducts(false);
    setCategoryToRename(undefined);
  };


  const handleVendorSaved = (v: ProductVendor) => {
    const vendorSelected = vendors.list.find((vend) => vend.uuid === v.uuid);
    setVendorEdit(vendorSelected);
    vendorEditRedirection = vendorSelected;
  };

  const handleAddVendor = (p: Product) => {
    setRedirectionVendor(true);
    setVendorEdit(undefined);
    setShowNewVendor(true);
    setShowNewProduct(false);
    setProductEdit(p);
  };

  const handleAddCategory = (p: Product) => {
    setRedirectionCategory(true);
    setCategoryToRename(undefined);
    setShowNeworRenameCategory(true);
    setProductEdit(p);
  };

  const handleRedirectionVendor = () => {
    setShowNewProduct(true);
    setShowNewVendor(false);
    setProductEdit((prevProduct: any) => {
      return {
        ...prevProduct,
        vendor: {
          id: vendorEditRedirection?.id,
          name: vendorEditRedirection?.name
        }
      };
    });
  };

  const handleCategorySaved = (c: ProductCategory) => {
    const categorySelected = categories.list.find((categ) => categ.uuid === c.uuid);
    setCategoryToRename(categorySelected);
    setShowNeworRenameCategory(false);
    setProductEdit((prevProduct: any) => {
      return {
        ...prevProduct,
        category: {
          id: categorySelected?.id,
          name: categorySelected?.name
        }
      };
    });
  };

  const handleShowVendor = (vendorId: number, productoToEdit: Product) => {
    setShowNewVendor(true);
    setShowNewProduct(false);
    setRedirectionVendor(true);
    let vendorSelected = vendors.list.find((v) => v.id === vendorId);
    setVendorEdit(vendorSelected);
    setProductEdit(productoToEdit);
  };

  const addNewProduct = (
    <ProductComponent
      product={productEdit}
      redirection={redirectionVendor || redirectionCategory}
      onBack={handleBackProduct}
      onAddVendor={handleAddVendor}
      onAddCategory={handleAddCategory}
      onShowVendor={handleShowVendor}
    />
  );

  const addNewVendor = (
    <VendorComponent
      vendor={vendorEdit}
      redirection={redirectionVendor}
      onBack={handleBackVendor}
      vendorSaved={handleVendorSaved}
      onRedirection={handleRedirectionVendor}
    />
  );

  const addOrRenameCategory = (
    <CategoryComponent
      category={categoryToRename}
      onAssign={handleAssignProducts}
      categorySaved={handleCategorySaved}
      onClose={handleBackCategory}
    />
  );

  const assignProductsToCategory = (
    <AssignProductsComponent
      category={categoryToRename!}
      products={products.list}
      onClose={handleBackCategory}
    />
  );

  const pagination = (
    <Pagination
      className={classes.pagination}
      count={view.id === "products" ? getProductList().length : view.id === "vendors" ? getVendorList().length : getCategoryList().length}
      page={page}
      pageSize={pageSize}
      onPageChange={changePageHandler}
      onPageSizeChange={changePageSizeHandler}
    />
  );



  


  return (
    <>
    <NotMobileYet />
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        {showNewProduct ?
          addNewProduct :
          showNewVendor ?
            addNewVendor :
            <Box>
              <ProductsHeader
                views={tableViews}
                selectedViewId={view.id}
                onSelectView={selectionViewHandler}
                onSearch={(text) => {
                  setSearchText(text);
                }}
                vendorsActive={vendorsActive}
                vendorsDeactive={vendorsDeactive}
                products={products.list}
                vendors={vendors.list}
                categories={categories.list}
                onAddProduct={addProductHandler}
                onAddVendor={addVendorHandler}
                onAddCategory={addCategoryHandler}
                onSelectProductCategory={handleSelectProductCategory}
                onSelectVendor={handleSelectVendor}
                loading={loadingProducts || loadingVendors || loadingCategories}
              />
              <Box className={classes.tableContainer}>
                <Table
                  selectedView={view.id}
                  loading={getLoading()}
                  products={pageSize === 0 ? getProductList() : getProductList().slice((page - 1) * pageSize, page * pageSize)}
                  vendors={pageSize === 0 ? getVendorList() : getVendorList().slice((page - 1) * pageSize, page * pageSize)}
                  categories={pageSize === 0 ? getCategoryList() : getCategoryList().slice((page - 1) * pageSize, page * pageSize)}
                  onEditProduct={handleEditProduct}
                  onEditVendor={handleEditVendor}
                  onEditCategory={handleEditCategory}
                  onAssign={handleAssignProducts}
                />
              </Box>
              {!getLoading() && getVisiblePagination() && pagination}
            </Box>}
        {showNeworRenameCategory && addOrRenameCategory}
        {showAssignProducts && assignProductsToCategory}
      </Box>
    </Box>
    </>
  );
  

};

export default ProductsComponent;
