import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMasterData } from 'hooks';
import { FunctionComponent } from 'react';
import {
    faSmile,
    faAngry,
    faMeh,
    IconDefinition
} from '@fortawesome/pro-regular-svg-icons';
import Pet from '@spike/pet-model';
import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { wbp, reduceResolution } from 'Theme';

interface PetPersonalitiesProps {
    pet: Pet;
}

export interface Personality {
    behaviorConditionId: number;
    idBehaviour?: number;
    categoryID: number;
    name: string;
    icon: IconDefinition;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            fontSize: 16,
            fontWeight: 400,
            color: '#A3A3A3',
            lineHeight: '18px',
            paddingBottom: 12,
            [theme.breakpoints.down('md')]: {
                fontSize: 15
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                fontWeight: 400
            }
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            flexWrap: 'wrap'
        },
        item: {
            'display': 'flex',
            'flexDirection': 'row',
            'alignItems': 'center',
            'gap': 5,
            'color': '#000000',
            '& svg': {
                width: 20,
                height: 20,
                [theme.breakpoints.down('sm')]: {
                    width: 16,
                    height: 16
                }
            }
        },
        name: {
            fontSize: 18,
            fontWeight: 400,
            whiteSpace: 'nowrap',
            textWrap: 'nowrap',
            [theme.breakpoints.down('md')]: {
                fontSize: 16
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
                fontWeight: 500
            }
        },
        none: {
            border: '1px solid #000',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(23)}px`,
                marginTop: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '23px',
                marginTop: '8px'
            }
        }
    })
);

const PetPersonalities: FunctionComponent<PetPersonalitiesProps> = props => {
    const masterData = useMasterData();
    const classes = useStyles();
    const iconSearch = (icon: string | undefined) => {
        switch (icon) {
            case 'faSmile':
                return faSmile;
            case 'faAngry':
                return faAngry;
            case 'faMeh':
                return faMeh;
            default:
                return faSmile;
        }
    };

    const loadPersonality = () => {
        const array: Array<Personality> = [];

        masterData.medicalHistory.behaviorConditionCategories.forEach(
            behavior => {
                behavior.options.forEach(option => {
                    array.push({
                        behaviorConditionId: option.id,
                        categoryID: behavior.id,
                        name: option.name,
                        icon: iconSearch(behavior.icon)
                    });
                });
            }
        );
        return array;
    };

    const personalities = loadPersonality();

    const activePersonalities = props.pet.personalities.map(
        activePersonality => {
            return personalities.find(
                personality =>
                    personality.behaviorConditionId ===
                    activePersonality.conditionId
            );
        }
    );
    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>PERSONALITY</Typography>
            <Box className={classes.container}>
                {activePersonalities.length > 0 ? (
                    activePersonalities.map((item, index) => (
                        <Box key={index} className={classes.item}>
                            <Typography className={classes.name}>
                                {item?.name}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Box className={classes.none}></Box>
                )}
            </Box>
        </Box>
    );
};

export default PetPersonalities;
