import { Action } from "redux";

//Action Types
export const REVENUES_RESET_ACTION_TYPE = "REVENUES_RESET";
export const REVENUES_FETCH_TODAY_START_ACTION_TYPE = "REVENUES_FETCH_TODAY_START";
export const REVENUES_FETCH_TODAY_SUCCESS_ACTION_TYPE = "REVENUES_FETCH_TODAY_SUCCESS";
export const REVENUES_FETCH_TODAY_CLIENT_START_ACTION_TYPE = "REVENUES_FETCH_CLIENT_TODAY_START";
export const REVENUES_FETCH_TODAY_CLIENT_SUCCESS_ACTION_TYPE = "REVENUES_FETCH_CLIENT_TODAY_SUCCESS";
export const REVENUES_ERROR_ACTION_TYPE = "REVENUES_ERROR";

//Actions
interface RevenuesResetAction extends Action<typeof REVENUES_RESET_ACTION_TYPE> { }

interface RevenuesFetchTodayStartAction extends Action<typeof REVENUES_FETCH_TODAY_START_ACTION_TYPE> { }

interface RevenuesFetchTodaySuccessAction extends Action<typeof REVENUES_FETCH_TODAY_SUCCESS_ACTION_TYPE> {
  payload: {
    amount: number;
  };
}

interface RevenuesFetchTodayClientStartAction extends Action<typeof REVENUES_FETCH_TODAY_CLIENT_START_ACTION_TYPE> { }

interface RevenuesFetchTodayClientSuccessAction extends Action<typeof REVENUES_FETCH_TODAY_CLIENT_SUCCESS_ACTION_TYPE> {
  payload: {
    amountClient: number;
  };
}

interface RevenuesErrorAction extends Action<typeof REVENUES_ERROR_ACTION_TYPE> { }

export type RevenuesAction =
  | RevenuesResetAction
  | RevenuesFetchTodayStartAction
  | RevenuesFetchTodaySuccessAction
  | RevenuesFetchTodayClientStartAction
  | RevenuesFetchTodayClientSuccessAction
  | RevenuesErrorAction;
