import React from 'react';
import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import Client from '@spike/client-model';
import { Button, ConfirmDialog } from 'components/UI';
import { useDispatch } from 'react-redux';
import { saveClientThunk } from '@spike/clients-action';
import { useApiClientWrapper } from 'hooks';

export interface BlockClientProps {
    client: Client;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        label: {
            fontSize: 14,
            color: '#000',

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        button: {
            'height': 47,
            'alignItems': 'center',
            'display': 'inline-flex',
            'padding': '0px 22px',

            '& span': {
                lineHeight: 1
            }
        },
        dialogActions: {
            'width': '100%',
            'display': 'flex',

            '& button': {
                'flex': 1,
                'height': '50px',
                '& + button': {
                    marginLeft: 10
                }
            }
        }
    })
);

const BlockClient: React.FC<BlockClientProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const [isLoading, setIsLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const toggle = async () => {
        isBlocked ? await enable() : await block();
        setShowConfirm(false);
    };

    const enable = async () => {
        if (!props.client.id || isLoading) {
            return;
        }

        setIsLoading(true);
        await dispatch(
            saveClientThunk(apiClientWrapper, {
                ...props.client,
                blocked: false
            })
        );
        setIsLoading(false);
    };

    const block = async () => {
        if (!props.client.id || isLoading) {
            return;
        }

        setIsLoading(true);
        await dispatch(
            saveClientThunk(apiClientWrapper, {
                ...props.client,
                blocked: true
            })
        );
        setIsLoading(false);
    };

    const isBlocked = React.useMemo(() => {
        return props.client.blocked;
    }, [props.client.blocked]);

    return (
        <>
            <div className={classes.root}>
                <Typography className={classes.label}>
                    {isBlocked
                        ? 'This client is currently blocked'
                        : 'This client is currently active'}
                </Typography>

                <Button
                    color={isBlocked ? 'green' : 'black'}
                    label={isBlocked ? 'Unblock Client' : 'Block Client'}
                    className={classes.button}
                    id="block_client_toggle"
                    onClick={() => setShowConfirm(true)}
                />
            </div>

            <ConfirmDialog
                open={showConfirm}
                title={
                    <Typography>
                        {isBlocked ? 'Unblock Client' : 'Block Client'}
                    </Typography>
                }
                onCancel={() => setShowConfirm(false)}
                question={
                    <Typography
                        style={{
                            maxWidth: 380,
                            margin: '0px auto'
                        }}
                    >
                        {isBlocked
                            ? 'Are you sure you want to unblock this client and allow them to book again?'
                            : 'Are you sure you want to block this client?'}
                    </Typography>
                }
                actions={
                    <Box className={classes.dialogActions}>
                        <Button
                            label="No"
                            color="black"
                            disabled={isLoading}
                            id="clients_dialog_block_client_cancel_button"
                            onClick={() => setShowConfirm(false)}
                        />

                        <Button
                            label="Yes"
                            color="green"
                            loading={isLoading}
                            variant={isBlocked ? 'primary' : 'danger'}
                            id="clients_dialog_block_client_confirm_button"
                            onClick={toggle}
                        />
                    </Box>
                }
            />
        </>
    );
};

export default BlockClient;
