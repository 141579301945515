import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MobileMenu } from 'components/Header/MobileMenu';
import Header from 'components/Header/SignedIn';
import { pageView } from 'googleAnalytics/GoogleAnalytics';
import { PropsWithChildren } from 'react';
import { FunctionComponent, useEffect } from 'react';
import { pageSidebarWidth, reduceResolution, sidebarWidth, wbp } from 'Theme';

interface PrivatePageProps extends PropsWithChildren {
	pageName?: string;
	title?: string;
	pageSidebar?: JSX.Element;
	className?: string;
	hideHeader?: boolean;
	onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%'
		},
		left: {
			width: '100%',
			display: 'flex',
			[theme.breakpoints.down(wbp)]: {
				width: (props: PrivatePageProps) =>
					props.onClose ? '222px' : '160px',
				minWidth: '160px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: (props: PrivatePageProps) =>
					props.onClose ? '277px' : '200px',
				minWidth: '200px'
			}
		},
		right: (props: PrivatePageProps) => ({
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			display: 'flex',
			position: 'absolute',
			flexDirection: 'column',

			[theme.breakpoints.up('sm')]: {
				left: props.pageSidebar
					? `${
							reduceResolution(sidebarWidth) +
							reduceResolution(pageSidebarWidth)
					  }px`
					: `${reduceResolution(sidebarWidth)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				left: props.pageSidebar
					? `${sidebarWidth + pageSidebarWidth}px`
					: `${sidebarWidth}px`
			}
		}),
		header: {
			display: 'flex',
			width: '100%'
		},
		page: {
			display: 'flex',
			width: '100%',
			height: '100%',
			overflowY: 'auto',
			overflowX: 'hidden',
			[theme.breakpoints.down('sm')]: {
				'-webkit-overflow-scrolling': 'auto'
			}
		}
	})
);

export const PrivatePage: FunctionComponent<PrivatePageProps> = props => {
	const classes = useStyles(props);

	useEffect(() => {
		if (props.pageName) {
			pageView(props.pageName);
		}
		// eslint-disable-next-line
	}, []);

	document.title = props.title || 'Cuddles';

	return (
		<>
			<Box className={clsx(classes.container, props.className)}>
				{props.pageSidebar && (
					<Box className={classes.left}>{props.pageSidebar}</Box>
				)}
				<Box className={classes.right}>
					{!props.hideHeader && (
						<Box className={classes.header}>
							<Header onClose={props.onClose} />
						</Box>
					)}
					<Box className={classes.page}>{props.children}</Box>
				</Box>
			</Box>
			<MobileMenu />
		</>
	);
};

export default PrivatePage;
