import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import CalculatingSpinner from 'components/UI/CalculatingSpinner';
import Invoice from '@spike/invoice-model';
import { FunctionComponent } from 'react';
import { wbp } from 'Theme';
import { useCommonStyles } from './CommonStyles';

interface InvoiceTotalsProps {
	invoice: Invoice;
	calculating?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.down('sm')]: {
				marginTop: 32
			},
			[theme.breakpoints.up('md')]: {
				width: 490
			}
		},
		text: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 400,
			color: '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		amount: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 400,
			color: '#222222',
			textAlign: 'right',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		totalText: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		totalAmount: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 400,
			color: '#222222',
			textAlign: 'right',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		totalDueText: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				color: '#5E8677'
			}
		},
		totalDueAmount: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			color: '#222222',
			textAlign: 'right',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '18px'
			}
		},

		row: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '12px 0px',

			[theme.breakpoints.up('md')]: {
				height: 69,
				padding: 0
			}
		},
		bottomLine: {
			[theme.breakpoints.up('md')]: {
				borderBottom: '2px solid #F1F1F1'
			}
		}
	})
);

export const InvoiceTotals: FunctionComponent<InvoiceTotalsProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={clsx(classes.row, classes.bottomLine)}>
				<Typography className={classes.text}>Sub Total</Typography>
				{props.calculating ? (
					<CalculatingSpinner />
				) : (
					<Typography
						className={clsx(classes.amount, {
							[commonClasses.errorText]:
								Number(props.invoice.subtotal) < 0
						})}
					>
						${props.invoice.subtotal}
					</Typography>
				)}
			</Box>

			<Box>
				{Object.entries(props.invoice.tax.detail).map((entry, i) => {
					const [key, value] = entry;
					return (
						<Box
							key={i}
							className={clsx(classes.row, classes.bottomLine)}
						>
							<Typography className={classes.text}>
								{key}
							</Typography>
							{props.calculating ? (
								<CalculatingSpinner />
							) : (
								<Typography className={classes.amount}>
									${value}
								</Typography>
							)}
						</Box>
					);
				})}
			</Box>

			{props.invoice.tip && Number(props.invoice.tip.amount) !== 0 && (
				<Box className={clsx(classes.row, classes.bottomLine)}>
					<Typography className={classes.text}>Tip</Typography>
					{props.calculating ? (
						<CalculatingSpinner />
					) : (
						<Typography className={classes.amount}>{`${
							props.invoice.tip?.percentage
								? '(' + props.invoice.tip?.percentage + '%) '
								: ''
						}$${props.invoice.tip.amount}`}</Typography>
					)}
				</Box>
			)}
			<Box
				className={clsx(classes.row, {
					[classes.bottomLine]: props.invoice.deposits.list.length > 0
				})}
			>
				<Typography
					className={clsx({
						[classes.totalText]:
							props.invoice.deposits.list.length > 0,
						[classes.totalDueText]:
							props.invoice.deposits.list.length === 0
					})}
				>
					Total
				</Typography>
				{props.calculating ? (
					<CalculatingSpinner />
				) : (
					<Typography
						id="booking_payment_invoice_total_text"
						className={clsx({
							[classes.totalAmount]:
								props.invoice.deposits.list.length > 0,
							[classes.totalDueAmount]:
								props.invoice.deposits.list.length === 0,
							[commonClasses.errorText]:
								Number(props.invoice.grandTotal) < 0
						})}
					>
						${props.invoice.grandTotal}
					</Typography>
				)}
			</Box>
			{props.invoice.deposits.list.length > 0 && (
				<Box className={clsx(classes.row, classes.bottomLine)}>
					<Typography className={classes.text}>Deposit</Typography>
					{props.calculating ? (
						<CalculatingSpinner />
					) : (
						<Typography className={clsx(classes.amount)}>
							-${props.invoice.deposits.total}
						</Typography>
					)}
				</Box>
			)}
			{props.invoice.deposits.list.length > 0 && (
				<Box className={classes.row}>
					<Typography className={classes.totalDueText}>
						Total Due
					</Typography>
					{props.calculating ? (
						<CalculatingSpinner />
					) : (
						<Typography className={clsx(classes.totalDueAmount)}>
							${props.invoice.totalDue}
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
};

export default InvoiceTotals;
