import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button, Spinner } from 'components/UI';
import clsx from 'clsx';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'gap': 10,
            'width': '100%',
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'flex-end',
            'backgroundColor': '#fff',
            'padding': '16px 16px 24px',

            [theme.breakpoints.up('md')]: {
                height: 95,
                padding: '0px 25px',
                borderTop: 'solid 1px #D4D4D4'
            },

            '& button': {
                margin: 0
            }
        },
        nextBtn: {
            height: 55,
            minWidth: 150,
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                '& > *': {
                    fontSize: '18px !important'
                }
            }
        },
        backBtn: {
            height: 55,
            marginRight: 6,
            color: '#fff',
            backgroundColor: '#222',
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                'color': '#fff',
                'backgroundColor': '#222',
                '& > *': {
                    fontSize: '18px !important'
                }
            }
        },
        circularProgress: {
            width: '40px !important',
            height: '40px !important'
        }
    })
);

interface Props {
    showSave?: boolean;
    showBack?: boolean;
    isLoading?: boolean;
    saveLabel?: string;
    className?: string;
    onSave: () => void;
    onBack: () => void;
    onNext: () => void;
}

export const WizardFooter = ({
    showSave,
    showBack,
    saveLabel,
    isLoading,
    className,
    onSave,
    onNext,
    onBack
}: Props) => {
    const classes = useStyles();

    const BackButton = () => (
        <Button
            label="Back"
            size="large"
            className={classes.backBtn}
            onClick={onBack}
            color="black"
        />
    );

    const label: string | JSX.Element = useMemo(() => {
        if (showSave && isLoading) {
            return (
                <Spinner classNameCircularProgress={classes.circularProgress} />
            );
        }

        if (showSave && !isLoading) {
            return saveLabel || 'Save';
        }

        return 'Next';
    }, [showSave, isLoading, saveLabel]);

    const NextButton = () => (
        <Button
            size="large"
            color="green"
            className={clsx(classes.nextBtn, 'nextBtn')}
            label={label}
            onClick={() => (showSave ? onSave() : onNext())}
        />
    );

    return (
        <Box className={clsx([classes.root, className])}>
            {/* {children} */}

            {showBack && <BackButton />}
            <NextButton />
        </Box>
    );
};
