import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from 'components/UI/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';

export interface Props {
    questionTitle?: string;
    questionSubtitle?: string;
    saveButtonLabel?: string;
    onBackdropClick?: () => void;
    onDiscard?: () => void;
    onSave?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiDialog-paper': {
                border: '1px solid #222222',
                boxShadow: '0px 10px 40px #00000029',
                [theme.breakpoints.down('lg')]: {
                    width: '361px',
                    minHeight: '208px',
                    borderRadius: '15px',
                    paddingTop: '48px',
                    paddingBottom: '52px'
                },
                [theme.breakpoints.up('xl')]: {
                    width: '482px',
                    minHeight: '278px',
                    borderRadius: '20px',
                    paddingTop: '65px',
                    paddingBottom: '70px'
                }
            },
            '& .MuiDialogContent-root': {
                paddingTop: '0px',
                [theme.breakpoints.down('lg')]: {
                    paddingBottom: '18px',
                    paddingLeft: '50px',
                    paddingRight: '50px'
                },
                [theme.breakpoints.up('xl')]: {
                    paddingBottom: '24px',
                    paddingLeft: '67px',
                    paddingRight: '67px'
                }
            },
            '& .MuiDialogActions-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
                justifyContent: 'center'
            }
        },
        button: {
            [theme.breakpoints.down('lg')]: {
                width: 'auto',
                minWidth: '82px',
                height: '36px',
                fontSize: '12px',
                margin: '0px 16px'
            },
            [theme.breakpoints.up('xl')]: {
                width: 'auto',
                minWidth: '110px',
                height: '49px',
                fontSize: '16px',
                margin: '0px 20px'
            }
        },
        confirmBackQuestionTitle: {
            fontWeight: 600,
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px'
            }
        },
        confirmBackQuestion: {
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px'
            }
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    })
);

export const UnsavedChangesPopup: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const saveHandler = () => {
        props.onSave && props.onSave();
    };

    const discardHandler = () => {
        props.onDiscard && props.onDiscard();
    };

    const backdropClickHandler = () => {
        props.onBackdropClick && props.onBackdropClick();
    };

    return (
        <Dialog
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    backdropClickHandler();
                    return;
                }
                props.onDiscard && props.onDiscard();
            }}
            open={true}
            className={classes.dialog}
        >
            <DialogContent>
                <Box className={classes.textContainer}>
                    <Typography className={classes.confirmBackQuestionTitle}>
                        {props.questionTitle || 'There are unsaved changes.'}
                    </Typography>
                    <Typography className={classes.confirmBackQuestion}>
                        {props.questionSubtitle ||
                            'Save changes or discard them.'}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <>
                    <Button
                        onClick={discardHandler}
                        variant="danger"
                        className={clsx(classes.button)}
                        label="Discard"
                    />
                    <Button
                        onClick={saveHandler}
                        variant="secondary"
                        color="black"
                        className={clsx(classes.button)}
                        label={props.saveButtonLabel || 'Save'}
                    />
                </>
            </DialogActions>
        </Dialog>
    );
};

export default UnsavedChangesPopup;
