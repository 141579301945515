import React, { FunctionComponent } from "react";
import { Select } from "components/UI";
import { Option } from "@spike/model";

interface Props {
  label?: string;
  value?: number;
  onChange: (value: number) => void;
  error?: boolean;
  errorMessage?: string;
  className?: string;
}

const months: Array<Option<number>> = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export const MonthSelect: FunctionComponent<Props> = (props) => {
  const changeHandler = (value: string | number) => {
    props.onChange(typeof value === "string" ? parseInt(value) : value);
  };

  return (
    <Select
      label={props.label || "Month"}
      options={months}
      value={props.value}
      onChange={changeHandler}
      className={props.className}
      error={props.error}
      errorMessage={props.errorMessage}
    />
  );
};

export default MonthSelect;
