import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Period } from '@spike/model';
import { useTimeZone } from 'hooks';
import padStart from 'lodash/padStart';
import moment from 'moment-timezone';
import { FunctionComponent } from 'react';
import Calendar from 'react-calendar';
import WeekAheadList from './WeeksAheadList';

interface WeekSelectorProps {
	week: Period;
	onChange?: (week: Period) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			backgroundColor: 'white',
			borderRadius: '20px',
			border: '1px solid #D4D4D4',
			padding: '20px',
			width: '579px',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				width: '100%'
			}
		},
		leftContainer: {
			display: 'flex',
			flexGrow: 1,
			paddingRight: '30px'
		},
		rightContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				marginTop: '20px'
			}
		},
		calendar: {
			'width': '328px',
			'height': '325px',
			'border': 'none',
			'fontFamily': 'poppins',
			'fontSize': '1.1rem',
			'color': '#212529',
			'position': 'relative',
			'backgroundColor': '#FFF',
			[theme.breakpoints.down('sm')]: {
				padding: 0,
				width: '100%',
				overflow: 'hidden'
			},

			'& .react-calendar__month-view__days': {
				[theme.breakpoints.down('sm')]: {
					paddingBottom: 10
				}
			},

			'& .react-calendar__tile:hover': {
				backgroundColor: 'transparent !important'
			},

			'& .react-calendar__month-view__days__day--weekend': {
				color: '#212529',
				fontFamily: 'poppins',
				padding: '5px'
			},

			'& .react-calendar__month-view__weekdays': {
				color: '#92B4A7',
				fontFamily: 'poppins',
				fontWeight: 500,
				textTransform: 'capitalize',
				padding: '0 5px'
			},

			'& .react-calendar__month-view__days__day--neighboringMonth': {
				color: '#fff'
			},

			'& .react-calendar__navigation': {
				marginBottom: 0
			},
			'& button': {
				backgroundColor: '#fff !important'
			},
			'& .react-calendar__navigation button': {
				fontSize: '1rem',
				[theme.breakpoints.down('sm')]: {
					fontSize: '0.85rem'
				}
			},

			'& .react-calendar__navigation button:hover': {
				backgroundColor: '#fff'
			},

			'& .react-calendar__navigation__label:hover, & .react-calendar__navigation button,  & .react-calendar__tile, & .react-calendar__tile:disabled':
				{
					backgroundColor: '#fff',
					fontFamily: 'poppins'
				},
			'& .react-calendar__month-view__days__day--neighboringMonth:hover':
				{
					color: '#fff'
				},
			'& .react-calendar__tile': {
				'color': '#222',
				'padding': '10px 5px',
				'& abbr': {
					width: 24,
					height: 24,
					border: 'solid 1px transparent',
					fontSize: 12,
					margin: 'auto',
					display: 'block',
					lineHeight: '24px'
				}
			},
			'& .react-calendar__navigation__arrow:not([disabled])': {
				color: '#222'
			},
			'& .react-calendar__month-view__days__day:not(.react-calendar__tile--range)':
				{
					'& abbr': {
						'&:hover': {
							backgroundColor: '#FFF !important',
							borderRadius: 24,
							borderColor: '#000',
							color: '#222'
						}
					}
				},
			'& .react-calendar__tile--active': {
				'backgroundColor': '#ebf1ef !important',
				'&:hover': {
					backgroundColor: '#ebf1ef !important'
				}
			},
			'& .react-calendar__tile--rangeStart': {
				'backgroundColor': '#ebf1ef !important',
				'borderTopLeftRadius': 24,
				'borderBottomLeftRadius': 24,
				'&:hover': {
					backgroundColor: '#ebf1ef !important'
				}
			},
			'& .react-calendar__tile--rangeEnd': {
				'backgroundColor': '#ebf1ef !important',
				'borderTopRightRadius': 24,
				'borderBottomRightRadius': 24,
				'&:hover': {
					backgroundColor: '#ebf1ef !important'
				}
			},
			'& .react-calendar__tile:disabled': {
				color: '#d5d5d5'
			},
			'& abbr': {
				textDecoration: 'none'
			},
			'& .react-calendar__navigation__label__labelText': {
				color: '#222',
				fontWeight: 500,
				fontSize: 14,
				[theme.breakpoints.down('sm')]: {
					fontSize: 14,
					fontWeight: 500
				}
			}
		}
	})
);

export const WeekSelectorDropdown: FunctionComponent<
	WeekSelectorProps
> = props => {
	const classes = useStyles();
	const timeZone = useTimeZone();

	const changeWeekHandler = (week: Period) => {
		props.onChange &&
			props.onChange({
				from: week.from.clone(),
				to: week.to.clone()
			});
	};

	const selectDateHandler = (date: Date) => {
		const from = moment
			.tz(
				`${date.getFullYear()}${padStart(
					(date.getMonth() + 1).toString(),
					2,
					'0'
				)}${date.getDate()}`,
				'YYYYMMDD',
				timeZone
			)
			.startOf('week');
		const to = from.clone().endOf('week');
		changeWeekHandler({ from, to });
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.leftContainer}>
				<WeekAheadList week={props.week} onSelect={changeWeekHandler} />
			</Box>
			<Box className={classes.rightContainer}>
				<Calendar
					calendarType="US"
					onClickDay={selectDateHandler}
					value={[
						props.week.from.clone().add(12, 'hours').toDate(),
						props.week.to.clone().subtract(12, 'hours').toDate()
					]}
					showFixedNumberOfWeeks={true}
					className={classes.calendar}
					nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
					prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
					prev2Label={null}
					next2Label={null}
					showNeighboringMonth={false}
					locale={'en'}
					formatShortWeekday={(locale, date) =>
						moment(date).format('dd').substring(0, 1)
					}
					formatMonth={(locale, date) => moment(date).format('MMM')}
				/>
			</Box>
		</Box>
	);
};

export default WeekSelectorDropdown;
