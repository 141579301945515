import React, { FunctionComponent, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useToggle, useMasterData } from 'hooks';
import { Accordion, Switch } from 'components/UI';
import { MarketplaceTypeCats } from '@spike/marketplace-model';
import { Category } from './UI/Category';
import { OrderedOption } from '@spike/model';
import { PetTypeStatusState } from 'reducers/businessSettings/PetTypeStatusState';
import { useCommonStyles } from './UI/commonStyles';

export interface Props {
    id?: string;
    catsType: MarketplaceTypeCats | undefined;
    onChange: (catPetType: MarketplaceTypeCats | undefined) => void;
    petFeatures?: PetTypeStatusState;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordionTitle: {
            'borderBottom': '1px solid #EFEFEF',
            'padding': 0,
            [theme.breakpoints.down('md')]: {
                borderBottom: 'none'
            },
            '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                fontSize: '16px',
                [theme.breakpoints.down('md')]: {
                    fontSize: '6px',
                    marginTop: '28px',
                    paddingBottom: '20px',
                    width: '100%',
                    marginBottom: '0px'
                }
            },

            '&.MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit',
                transform: 'rotate(0deg)',
                [theme.breakpoints.down('sm')]: {
                    borderBottom: '1px solid #D4D4D4',
                    paddingBottom: '3px',
                    display: 'flex',
                    flexDirection: 'row'
                }
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                [theme.breakpoints.down('md')]: {
                    margin: '4px 0',
                    marginBottom: '0px',
                    marginTop: '28px',
                    paddingBottom: '20px'
                },
                [theme.breakpoints.only('md')]: {
                    margin: '6px 0'
                },
                [theme.breakpoints.only('lg')]: {
                    margin: '8px 0'
                },
                [theme.breakpoints.only('xl')]: {
                    margin: '12px 0'
                }
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: '46px'
            }
        },
        title: {
            fontWeight: 500,
            width: '100%',
            fontSize: '16px',
            [theme.breakpoints.down('md')]: {
                lineHeight: '16px',
                fontWeight: '500'
            },
            [theme.breakpoints.only('lg')]: {
                lineHeight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                lineHeight: '30px'
            }
        },
        mobileListStyle: {
            [theme.breakpoints.down('md')]: {
                '& > p': {
                    width: '100% !important',
                    maxWidth: '100% !important'
                },
                '& > div': {
                    width: 'unset'
                }
            }
        },
        lineDivider: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                position: 'relative',
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px'
            }
        }
    })
);

export const CatPetType: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const masterData = useMasterData();
    const [open, handleOpen] = useToggle(props.catsType !== undefined);
    const [hairLength, setHairLength] = useState<string[]>([]);

    useEffect(() => {
        const hairLength: string[] = [];
        props.petFeatures?.pet_hair_length
            .map(x => x.cats)
            .forEach(d => {
                hairLength.push(
                    d?.short ? 'short' : d?.medium ? 'medium' : d?.long ? 'long' : d?.hairless ? 'hairless' : ''
                );
            });
        setHairLength(hairLength);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckAll = () => {
        handleOpen();
        if (!open) {
            props.onChange({
                ages: [...masterData.petAges],
                sizes: masterData.petSizes
                    .filter(s => s.element.id === 'small')
                    .map(s => ({
                        element: {
                            ...s.element
                        },
                        order: s.order
                    })),
                hairTypes: [...masterData.catHairTypes],
                hairLengths: [...masterData.catHairLengths]
            });
        } else {
            props.onChange(undefined);
        }
    };

    const changeHanlder = (attribute: 'ages' | 'hairTypes' | 'hairLengths', options: Array<OrderedOption<string>>) => {
        if (props.catsType) {
            const catsType = { ...props.catsType };
            if (attribute === 'ages') {
                catsType.ages = [...options].map(e => e.element);
            } else {
                catsType[attribute] = [...options];
            }
            props.onChange(catsType);
        }
    };

    return (
        <Accordion
            expanded={open}
            withoutIcon
            handleExpandedChange={handleCheckAll}
            title={'Cats'}
            titleStyle={classes.title}
            titleContainerStyles={classes.accordionTitle}
            expandComponent={<Switch id={props.id} checked={open} onChange={handleCheckAll} />}
            contentContainerStyles={commonClasses.container}
        >
            <Category
                title="AGE"
                masterDataOptions={masterData.petAges.map((e, index) => ({
                    element: e,
                    order: index
                }))}
                options={
                    props.catsType?.ages.map((e, index) => ({
                        element: e,
                        order: index
                    })) || []
                }
                onChange={options => {
                    changeHanlder('ages', options);
                }}
            />

            <Box className={classes.lineDivider}></Box>

            <Category
                title="HAIR TYPE"
                masterDataOptions={masterData.catHairTypes}
                options={props.catsType?.hairTypes || []}
                onChange={options => {
                    changeHanlder('hairTypes', options);
                }}
                requireTwoColumnsOnMobile={false}
                mobileTextContainerStyle={classes.mobileListStyle}
                width={250}
            />

            <Box className={classes.lineDivider}></Box>

            <Category
                title="HAIR LENGTH"
                masterDataOptions={masterData.catHairLengths}
                options={props.catsType?.hairLengths || []}
                onChange={options => {
                    changeHanlder('hairLengths', options);
                }}
                feature={hairLength}
                requireTwoColumnsOnMobile={false}
                mobileTextContainerStyle={classes.mobileListStyle}
            />
        </Accordion>
    );
};
