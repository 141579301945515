import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: '#222222',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '27px',
        textDecoration: 'none',
        '&:hover': {
            color: '#EAB464'
        }
    }
}))

export const HeaderLink: FunctionComponent<LinkProps> = (props) => {
    const classes = useStyles();

    return (
        <Link {...props}
            className={clsx(classes.root, props.className)}>
            {props.children}
        </Link>
    );
}


export default HeaderLink;