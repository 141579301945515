import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

interface InvoiceHeaderAppointmentInfoProps {
	title: string;
	data: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.down('sm')]: {
				paddingRight: 8
			}
		},
		title: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 400,
			color: '#A3A3A3',
			letterSpacing: 1.4,
			textTransform: 'uppercase',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		data: {
			fontSize: 15,
			lineHeight: 1.4,
			fontWeight: 500,
			color: '#222222',
			marginTop: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 23,
				fontWeight: 400
			}
		}
	})
);

export const InvoiceHeaderAppointmentInfo: FunctionComponent<
	InvoiceHeaderAppointmentInfoProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Typography className={classes.title}>{props.title}</Typography>
			<Typography className={classes.data}>{props.data}</Typography>
		</Box>
	);
};

export default InvoiceHeaderAppointmentInfo;
