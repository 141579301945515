import {
  AutocompleteAction,
  AUTOCOMPLETE_RESET_ACTION_TYPE,
  AUTOCOMPLETE_SEARCH_START_ACTION_TYPE,
  AUTOCOMPLETE_SEARCH_SUCCESS_ACTION_TYPE,
  AUTOCOMPLETE_ERROR_ACTION_TYPE,
} from "./AutocompleteActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { AutocompleteState } from "reducers/autocomplete/AutocompleteState";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction }from "@spike/notifications-action";
import store from "store";
import { convert } from "./AutocompleteConverter";
import { AutocompleteResultDto } from "./AutocompleteDtos";
import { alertErrorHandler } from "@spike/notifications-action";

const autocompleteUrl = "/customers_autocomplete";

export const searchCustomersThunk = (
  searchText: string
): ThunkAction<void, AutocompleteState, null, AutocompleteAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(searchStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${autocompleteUrl}?marketplace_id=${marketplaceId}&search=${searchText}`;

    try {
      const response: AxiosResponse<Array<AutocompleteResultDto>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(searchSuccess(response.data));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error fetching clients.");
      }
    }
  };
};

export const reset = (): AutocompleteAction => {
  return {
    type: AUTOCOMPLETE_RESET_ACTION_TYPE,
  };
};

const searchStart = (): AutocompleteAction => {
  return {
    type: AUTOCOMPLETE_SEARCH_START_ACTION_TYPE,
  };
};

export const searchSuccess = (resultDtos: Array<AutocompleteResultDto>): AutocompleteAction => {
  return {
    type: AUTOCOMPLETE_SEARCH_SUCCESS_ACTION_TYPE,
    payload: {
      results: resultDtos.map((dto) => convert(dto)),
    },
  };
};

const error = (): AutocompleteAction => {
  return {
    type: AUTOCOMPLETE_ERROR_ACTION_TYPE,
  };
};
