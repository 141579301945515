import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

type Props = {
    onClikedActions?: () => void;
    className?: {
        actionsDropdownButton: string;
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionsDropdownButton: {
            all: 'unset',
            width: 35,
            height: 35,
            borderRadius: '50%',
            textAlign: 'center',
            backgroundColor: '#F1F1F1',
            marginLeft: 10,
        }
        
    })
);

const MobileButtonActions: React.FC<Props> = ({ onClikedActions, className }) => {
    const classes = useStyles();

    return (
        <button
            type="button"
            aria-label="Open actions"
            className={`${classes.actionsDropdownButton} ${className?.actionsDropdownButton || ''}`}
            onClick={() => onClikedActions && onClikedActions()}
        >
            <FontAwesomeIcon icon={faEllipsis} />
        </button>
    );
};

export default MobileButtonActions;