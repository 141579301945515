import React, { FunctionComponent, ChangeEvent, KeyboardEvent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { FieldError } from "@spike/model";
import LabelField from "./LabelField";
import { useFieldStyles } from "./FieldStyles";

export interface CheckboxField2Props {
  name?: string;
  label?: string | JSX.Element;
  checked: boolean;
  errors?: Array<FieldError>;
  onEnterPressed?: () => void;
  onBlur?: () => void;
  onChange: (checked: boolean, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  labelPosition?: "start" | "end" | "top" | "bottom";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    top: {
      flexDirection: "column",
      justifyContent: "center",
    },
    end: {
      flexDirection: "row",
      alignItems: "center",
    },
    start: {
      flexDirection: "row-reverse",
      alignItems: "center",
    },
    bottom: {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
    checkbox: {
      padding: "0px",
      width: "20px",
      height: "20px",
      "& svg": {
        color: "#222222",
      },
    },
  })
);

export const CheckboxField2: FunctionComponent<CheckboxField2Props> = (props) => {
  const fieldClasses = useFieldStyles();
  const classes = useStyles();

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    props.onChange(checked, props.name);
  };

  const onFocusHandler = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  return (
    <Box
      className={clsx(
        classes.container,
        {
          [classes.start]: props.labelPosition === "start" || !props.labelPosition,
          [classes.end]: props.labelPosition === "end",
          [classes.top]: props.labelPosition === "top",
          [classes.bottom]: props.labelPosition === "bottom",
        },
        props.className
      )}
    >
      <LabelField {...props} />
      <Checkbox
        name={props.name}
        checked={props.checked}
        onBlur={onBlurHandler}
        onChange={changeHandler}
        onFocus={onFocusHandler}
        autoFocus={props.autoFocus}
        className={clsx(fieldClasses.field, classes.checkbox, { [fieldClasses.fieldError]: hasError })}
        onKeyPress={keyPressHandler}
        disabled={props.disabled}
      />
    </Box>
  );
};

export default CheckboxField2;
