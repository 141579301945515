import React, { FunctionComponent, useState } from "react";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { StaffService } from "model/Staff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { DropdownOption } from "./DropdownOption";

interface Props {
  services: Array<StaffService>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
    },
    selectContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F1F1F1",
      whiteSpace: "inherit",
      width: "inherit",
      [theme.breakpoints.down("lg")]: {
        borderRadius: "18px",
        padding: "6px 15px 6px 15px",
        marginBottom: "25px",
        marginRight: "10px"
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "24px",
        padding: "8px 20px 8px 20px",
        marginBottom: "23px",
        marginRight: "40px"
      },
    },
    arrowDownRight: {
      display: "flex",
      float: "right",
      flexDirection: "column",
      justifyContent: "center",
      color: "#222222",
      cursor: "pointer",
      width: "5%"
    },
    text: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },
    },
    numberContainer: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#5E8677",
      borderRadius: "50%",
      [theme.breakpoints.down("lg")]: {
        width: "18px",
        height: "18px",
        marginRight: "8px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "24px",
        height: "24px",
        marginRight: "10px"
      },
    },
    number: {
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px"
      },
    },
    servicesContainer: {
      display: "flex",
    },
    none: {
      color: "#7A7A7A"
    },
    leftSpace: {
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "15px"
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "20px"
      },
    },
    bold: {
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px !important"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px !important"
      },
    },
    optionsContainer: {
      cursor: "default"
    },
    services: {
      display: "flex",
      width: "95%"
    }
  })
);

const StaffServices: FunctionComponent<Props> = ({
  services,
}) => {
  const classes = useStyles();

  const [showServices, setShowServices] = useState(false);
  
  const handleOpenDropDownClick = () => {
    setShowServices((prev) => {
      return !prev;
    });
  };

  return (
    <Box className={classes.container}>
      {services.length === 0 && <Typography className={clsx(classes.text, classes.none, classes.leftSpace)}>None</Typography>}
      {services.length === 1 && <Typography className={clsx(classes.text, classes.leftSpace)}>{services[0].name}</Typography>}
      {services.length > 1 && 
      <Box className={classes.selectContainer} >
        <Box className={classes.servicesContainer} onClick={handleOpenDropDownClick}>
          <Box className={classes.services}>
            <Box className={classes.numberContainer}>
              <Typography className={classes.number}>{services.length}</Typography>
            </Box>
            <Typography className={clsx(classes.text, {[classes.bold]: showServices})}>Services</Typography>
          </Box>
          <Box className={classes.arrowDownRight}>
            <FontAwesomeIcon icon={showServices ? faCaretRight : faCaretDown} />
          </Box>
        </Box>
        <Box className={classes.optionsContainer}>
          {showServices && services.map((service) => {
            return <DropdownOption optionText={service.name}/>
          })}
        </Box>
      </Box>}
    </Box>
  );
};

export default StaffServices;
