import { PetType } from '@spike/model';
import { Option } from '@spike/model';
import { PricingObject, setPricingVariablesOption } from '../model';
import { FixedPriceOption, ServicePricing, VariablePriceOption } from 'model/Service';
import { MarketplaceTax } from '@spike/marketplace-model';
import { PricingStatus } from './PricingReducer';
import { FieldErrorWithKey } from 'components/Service/model';

export const SET_SELECTED_PET_TYPES = 'SET_SELECTED_PET_TYPES';

export const SET_SELECTED_PRICING_OPTION = 'SET_SELECTED_PRICING_OPTION';

export const SET_PRICING_OPTIONS = 'SET_PRICING_OPTIONS';

export const SET_PRICING_OBJECT = 'SET_PRICING_OBJECT';
export const UPDATE_PRICING_OBJECT = 'UPDATE_PRICING_OBJECT';

export const SET_PRICING = 'SET_PRICING';
export const UPDATE_PRICING = 'UPDATE_PRICING';

export const UPDATE_SELECTED_PRICING_OPTION = 'UPDATE_SELECTED_PRICING_OPTION';
export const SET_PET_TYPES = 'SET_PET_TYPES';
export const SET_SELECTED_PET_TYPE = 'SET_SELECTED_PET_TYPE';

export const SET_PRICING_VARIABLES = 'SET_PRICING_VARIABLES';
export const SET_PRICING_TAXES = 'SET_PRICING_TAXES';

export const CREATE_SERVICE_PRICING_OBJECT_START = 'CREATE_SERVICE_PRICING_OBJECT_START';
export const CREATE_SERVICE_PRICING_OBJECT_SUCCESS = 'CREATE_SERVICE_PRICING_OBJECT_SUCCESS';
export const CREATE_SERVICE_PRICING_OBJECT_ERROR = 'CREATE_SERVICE_PRICING_OBJECT_ERROR';

export const RESET_PRICING_STATE = 'RESET_PRICING_STATE';
export const SHOW_PRICING_ERRORS = 'SHOW_PRICING_ERRORS';

const MODULE_NAME = 'PricingActions';

export const setSelectedPricingOption = (option: Option<string>) => {
    return {
        type: SET_SELECTED_PRICING_OPTION,
        payload: option
    };
};

export const setPricingVariables = (option: Option<string>, petTypes: Array<PetType>) => {
    const variables = setPricingVariablesOption(option.id, petTypes);
    return {
        type: SET_PRICING_VARIABLES,
        payload: variables
    };
};

export const updateSelectedPricingOption = (option: Option<string>) => {
    return {
        type: UPDATE_SELECTED_PRICING_OPTION,
        payload: option
    };
};

export const setPricingOptions = (pricingOptions: Array<Option<string>>) => {
    return {
        type: SET_PRICING_OPTIONS,
        payload: pricingOptions
    };
};

export const setPricingObject = (pricingObject: PricingObject) => {
    return {
        type: SET_PRICING_OBJECT,
        payload: pricingObject
    };
};

export const setPricing = (pricing: Array<VariablePriceOption | FixedPriceOption>) => {
    return {
        type: SET_PRICING,
        payload: pricing
    };
};

export const updatePricing = (price: Array<VariablePriceOption | FixedPriceOption>) => {
    return {
        type: UPDATE_PRICING,
        payload: price
    };
};

export const setPetTypes = (petTypes: Array<PetType>) => {
    return {
        type: SET_PET_TYPES,
        payload: petTypes
    };
};

export const setSelectedPetType = (petType: PetType) => {
    return {
        type: SET_SELECTED_PET_TYPE,
        payload: petType
    };
};

export const setPricingTaxes = (taxes: Array<MarketplaceTax>) => {
    return {
        type: SET_PRICING_TAXES,
        payload: taxes
    };
};

export const createServicePricingObjectStart = () => {
    return {
        type: CREATE_SERVICE_PRICING_OBJECT_START,
        status: PricingStatus.CREATING_SERVICE_PRICING_OBJECT
    };
};

export const createServicePricingObjectSuccess = (servicePricingObject: ServicePricing) => {
    return {
        type: CREATE_SERVICE_PRICING_OBJECT_SUCCESS,
        payload: servicePricingObject
    };
};

export const createServicePricingObjectError = (errors: Array<FieldErrorWithKey>) => {
    return {
        type: CREATE_SERVICE_PRICING_OBJECT_ERROR,
        payload: errors
    };
};

export const showPricingErrors = (showErrors: boolean) => {
    return {
        type: SHOW_PRICING_ERRORS,
        payload: showErrors
    };
};

export const resetPricingState = () => ({
    type: RESET_PRICING_STATE
});
