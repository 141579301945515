import React, { FC, useState } from 'react';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Appointment, status } from '@spike/appointment-model';
import { ConfirmDialog, StaffChip } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useHasPremiumAccess } from 'hooks';
import BookingOptionButton from './BookingOptionButton';
import { ButtonStatusAction } from './ButtonStatusAction';
import { isStatusAppointmentOkToAction, isStatusAppointmentOkToOptions } from '../utils/AppointmentUtils';
import { round } from 'lodash';
import { faFile, faFileMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

interface Props {
    appointment?: Appointment;
    appointmentId: number;
    multiple?: boolean;
    customerDeleted?: boolean;
    first: boolean;
    loading: boolean;
    visibleStatus: boolean;
    onBack?: () => void;
    onBook: () => void;
    onCancel: (appointmentId?: number) => void;
    onCancelBooking: () => void;
    onCheckIn: (appointmentId: number) => void;
    onCheckOut: () => void;
    onComplete: (appointment: Appointment) => void;
    onConfirm: () => void;
    onNoShow: (appointmentId: number) => void;
    onDecline: (appointment: Appointment) => void;
    onRescheduleAppointment: () => void;
    onRescheduleBooking: () => void;
    onShowInvoice: (appointmentId: number) => void;
    onShowReport: (appointment: Appointment) => void;
    onUndo: (appointment: Appointment, statusId: number) => void;
    onShowAddReport?: (appointmentId: number, businessAreaId: string, clientId: number) => void;
    onStart: (appointmentId: number) => void;
    onRemoveService: (uuid: string, appointmentId?: number) => void;
    onAddReport: (appointmentId: number, businessAreaId: string, clientId: number) => void;
    onViewReport: (appointment: Appointment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '1px solid #D4D4D4',
            borderRadius: 8,
            padding: 18,
            marginBottom: 18
        },
        content: {
            display: 'flex',
            width: '100%'
        },
        right: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '32%',
            [theme.breakpoints.down('sm')]: {
                width: '45%'
            }
        },
        left: {
            width: '68%',
            [theme.breakpoints.down('sm')]: {
                width: '55%'
            }
        },
        headerContainer: {
            padding: 16,
            display: 'flex',
            flexDirection: 'row'
        },
        image: {
            width: 64,
            height: 64,
            marginRight: 18,
            [theme.breakpoints.up('md')]: {
                marginRight: 20
            }
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            paddingBottom: 6
        },
        iconContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            cursor: 'pointer'
        },
        time: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '21px',
            paddingBottom: 8
        },
        price: {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '21px',
            paddingTop: 8
        },
        icon: {
            color: '#5E8677',
            cursor: 'pointer'
        },
        iconClose: {
            color: '#0B1813',
            cursor: 'pointer'
        },
        report: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '14px',
            textDecoration: 'underline',
            marginLeft: 5,
            paddingBottom: 2,
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                marginTop: 1
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: 3
            }
        },
        green: {
            color: '#5E8677'
        },
        black: {
            color: '#000000'
        },
        staffChip: {
            '& .MuiChip-root': {
                height: 32
            },
            '& .MuiChip-root .MuiChip-avatar': {
                height: '24px !important',
                width: '24px !important',
                margin: '0px !important'
            }
        },
        textDialog: {
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        row: {
            display: 'flex',
            alignItems: 'center'
        },
        buttonContainer: {
            display: 'flex',
            width: '80%',
            marginRight: 15
        },
        optionsContainer: {
            width: '20%'
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        emptyContainer: {
            width: 14
        }
    })
);
const statusToNotShowViewOrAddReport = [status.CANCELLED, status.NO_SHOW];

const statusToNotShowButtonAction = [status.READY_FOR_CHECK_OUT, status.PAYMENT, status.BOOKED];

const statusToNotShowActions = [status.BOOKED];

const statusToNotShowIconClose = [
    status.COMPLETED,
    status.CANCELLED,
    status.NO_SHOW,
    status.DECLINED,
    status.READY_FOR_CHECK_OUT
];

const ServiceComponent: FC<Props> = props => {
    const classes = useStyles();

    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const removeServiceHandler = (uuid: string, appointmentId?: number) => {
        props.onRemoveService(uuid, appointmentId);
    };

    const cancelDeleteHandler = () => {
        setShowDeleteDialog(false);
    };

    const confirmDeleteHandler = () => {
        setShowDeleteDialog(false);
        removeServiceHandler(props.appointment?.uuid!, props.appointment?.id);
    };

    const deleteService = () => {
        props.appointment?.id
            ? setShowDeleteDialog(true)
            : removeServiceHandler(props.appointment?.uuid!, props.appointment?.id);
    };

    const textCancelDialog = (
        <Typography className={classes.textDialog}>
            Are you sure you want to <strong>Delete</strong> this appointment?
        </Typography>
    );

    const deleteDialogView = (
        <ConfirmDialog
            open={showDeleteDialog}
            question={textCancelDialog}
            confirmButtonLabel={'Yes'}
            cancelButtonLabel={'No'}
            //
            onCancel={cancelDeleteHandler}
            onConfirm={confirmDeleteHandler}
        />
    );

    const getServicesTime = () => {
        const timeInMinutes = props.appointment?.duration.to.diff(props.appointment?.duration.from, 'minutes');
        return timeInMinutes! / 60 > 1
            ? `${Math.trunc(timeInMinutes! / 60)}hrs ${timeInMinutes! % 60 !== 0 ? timeInMinutes! % 60 : ''}${
                  timeInMinutes! % 60 !== 0 ? 'min' : ''
              }`
            : `${timeInMinutes! === 60 ? 60 : timeInMinutes! % 60}min`;
    };

    const getPriceFormatted = (price: number) => {
        return price.toFixed(2);
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <Box className={classes.left}>
                    <Typography className={classes.title}>{props.appointment?.services[0]?.name}</Typography>
                    <Typography className={classes.time}>{`${props.appointment?.duration.from.format(
                        'LT'
                    )} (${getServicesTime()})`}</Typography>
                    <Box className={classes.staffChip}>
                        <StaffChip staff={props.appointment?.services[0].staff} />
                    </Box>
                    <Typography className={classes.price}>
                        ${''}
                        {getPriceFormatted(props.appointment!.services[0].price || 0)}
                    </Typography>
                </Box>
                <Box className={classes.right}>
                    <Box className={classes.actionsContainer}>
                        <Box className={classes.row}>
                            {props.appointment &&
                                props.visibleStatus &&
                                isStatusAppointmentOkToAction(props.appointment) &&
                                !statusToNotShowButtonAction.includes(props.appointment.status.id) &&
                                props.multiple && (
                                    <Box className={classes.buttonContainer}>
                                        <ButtonStatusAction
                                            loadingAction={props.loading}
                                            appointment={props.appointment}
                                            onCheckIn={props.onCheckIn}
                                            onCheckOut={props.onCheckOut}
                                            onComplete={() => props.onComplete(props.appointment!)}
                                            onConfirm={props.onConfirm}
                                            onStart={props.onStart}
                                        />
                                    </Box>
                                )}
                            {props.appointment &&
                                props.visibleStatus &&
                                isStatusAppointmentOkToOptions(props.appointment.status.id) &&
                                !statusToNotShowActions.includes(props.appointment.status.id) &&
                                props.multiple && (
                                    <Box className={classes.optionsContainer}>
                                        <BookingOptionButton
                                            appointment={props.appointment}
                                            customerDeleted={props.customerDeleted}
                                            onBook={props.onBook}
                                            onCancel={() =>
                                                props.onCancel(props.appointment ? props.appointment.id : undefined)
                                            }
                                            onCancelBooking={props.onCancelBooking}
                                            onDecline={() => props.onDecline(props.appointment!)}
                                            onNoShow={() => props.onNoShow(props.appointment!.id!)}
                                            onRescheduleAppointment={props.onRescheduleAppointment}
                                            onRescheduleBooking={props.onRescheduleBooking}
                                            onShowReport={() => props.onShowReport(props.appointment!)}
                                            onShowInvoice={() => props.onShowInvoice(props.appointment!.id!)}
                                            onUndo={(appointmentId: number, statusId: number) =>
                                                props.onUndo(props.appointment!, statusId)
                                            }
                                            onShowAddReport={props.onShowAddReport}
                                        />
                                    </Box>
                                )}
                            <Box className={classes.iconContainer}>
                                {!props.first &&
                                props.appointment &&
                                !statusToNotShowIconClose.includes(props.appointment?.status.id) ? (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className={classes.iconClose}
                                        onClick={deleteService}
                                    />
                                ) : (
                                    <Box className={classes.emptyContainer} />
                                )}
                            </Box>
                        </Box>
                    </Box>
                    {props.appointment?.services &&
                        props.appointment.services.length > 0 &&
                        props.appointment.services[0].hasReport &&
                        !props.appointment?.reports.dentalReportId &&
                        !props.appointment?.reports.groomingReportId &&
                        !statusToNotShowViewOrAddReport.includes(props.appointment?.status.id) &&
                        !props.customerDeleted && (
                            <Box
                                className={classes.iconContainer}
                                onClick={() =>
                                    props.onAddReport(
                                        props.appointment!.id!,
                                        props.appointment!.services[0].businessArea.id,
                                        props.appointment!.customer.id
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={faFile} className={classes.green} />
                                <Typography className={clsx(classes.report, classes.green)}>Add report</Typography>
                            </Box>
                        )}
                    {hasReportsPremiumAccess &&
                        props.appointment?.services &&
                        props.appointment.services.length > 0 &&
                        (props.appointment?.reports.dentalReportId || props.appointment?.reports.groomingReportId) &&
                        !statusToNotShowViewOrAddReport.includes(props.appointment?.status.id) && (
                            <Box
                                className={classes.iconContainer}
                                onClick={() => props.onViewReport(props.appointment!)}
                            >
                                <FontAwesomeIcon icon={faFileMagnifyingGlass} className={classes.black} />
                                <Typography className={clsx(classes.report, classes.black)}>View report</Typography>
                            </Box>
                        )}
                </Box>
            </Box>
            {showDeleteDialog && deleteDialogView}
        </Box>
    );
};

export default ServiceComponent;
