import store from 'store';
import { isNull } from 'lodash';
import { Subscription } from 'model/Subscriptions';
import moment from 'moment-timezone';
import { ResourceAccessPlan } from '@spike/masterdata-model';

const subscriptionIsActive = (subscription: Subscription, timeZone: string) => {
	return (
		subscription.active &&
		(isNull(subscription.endAt) ||
			!subscription.endAt.isValid() ||
			subscription.endAt.isAfter(moment().tz(`${timeZone}`)))
	);
};

export const useHasPremiumPlan = (): boolean => {
	const timeZone = store.getState().login.auth.timeZone;
	const subscriptions = store.getState().subscriptions.subscriptions;

	return subscriptions.some(subscription =>
		subscriptionIsActive(subscription, `${timeZone}`)
	);
};

export const usePremiumPlan = (): Subscription | undefined => {
	const timeZone = store.getState().login.auth.timeZone;
	const subscriptions = store.getState().subscriptions.subscriptions;

	return subscriptions.find(subscription =>
		subscriptionIsActive(subscription, `${timeZone}`)
	);
};

export const useUpgradableSubscription = () => {
	const subscriptions = store.getState().subscriptions.subscriptions;

	return subscriptions.find(subscription => {
		return !subscription.active && !subscription.endAt?.isValid();
	});
};

export const useHasPremiumAccess = (resource: string) => {
	const timeZone = store.getState().login.auth.timeZone;
	const { subscriptions } = store.getState().subscriptions;
	const { resourceAccessPlans } = store.getState().masterData.data;

	return hasPremiumAccess(
		subscriptions,
		resourceAccessPlans,
		`${timeZone}`,
		resource
	);
};

export const hasPremiumAccess = (
	subscriptions: Array<Subscription>,
	resourceAccessPlans: Array<ResourceAccessPlan>,
	timeZone: string,
	resource: string
): boolean => {
	const hasPremiumPlan = subscriptions.some(subscription =>
		subscriptionIsActive(subscription, `${timeZone}`)
	);

	const premiumHasAccessToResource = resourceAccessPlans.some(
		plan =>
			plan.planId === 'premium' &&
			plan.allowedResources.some(
				planResource => planResource.resourceId === resource
			)
	);

	return hasPremiumPlan && premiumHasAccessToResource;
};
