import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Invoice } from '@spike/invoice-model';
import React, { FunctionComponent } from 'react';
import Item from './Item';
import { Item as ItemModel } from './ItemModel';
import { Payment } from '@spike/payments-model';

interface ItemsProps {
	invoice: Invoice;
	payment: Payment;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			border: '1px solid #D3D3D3',
			borderRadius: 10,

			[theme.breakpoints.down('sm')]: {
				padding: '0px 16px'
			}
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			borderBottom: 'none',
			padding: '9px 0px 0px',

			[theme.breakpoints.up('md')]: {
				height: '160px',
				overflowY: 'scroll',
				padding: '11px 24px 11px 24px'
			}
		},
		footer: {
			display: 'flex',
			alignItems: 'center',
			padding: '24px 0px',
			borderTop: 'solid 1px #D4D4D4',

			[theme.breakpoints.up('md')]: {
				padding: 24
			}
		},
		footerLabel: {
			width: '60%',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		footerAmount: {
			width: '40%',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			textAlign: 'right',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		}
	})
);

export const Items: FunctionComponent<ItemsProps> = props => {
	const classes = useStyles();

	const items: Array<ItemModel> = props.invoice.lines.map(line => ({
		uuid: line.uuid,
		description: line.description || '',
		amount: line.totalWithTax || line.total
	}));

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.body}>
				{items.map(item => (
					<Item
						key={item.uuid}
						description={item.description}
						price={item.amount}
					/>
				))}
				{props.invoice.tip?.amount &&
					props.invoice.tip?.amount !== '0.00' && (
						<Item
							key="tip"
							description="Tips"
							price={props.invoice.tip?.amount}
						/>
					)}
				{props.payment.refunds.map(refund => (
					<Item
						refund={true}
						key={refund.uuid}
						description={`Refund ${refund.refundDate.format(
							'MM/DD/YYYY'
						)} (${refund.status.name})`}
						price={refund.amount}
					/>
				))}
			</Box>
			<Box className={classes.footer}>
				<Typography className={classes.footerLabel}>Balance</Typography>
				<Typography className={classes.footerAmount}>
					${props.payment.balance}
				</Typography>
			</Box>
		</Box>
	);
};

export default Items;
