import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/UI/V2/Button/Button';

export interface AddSlotHeaderProps {
    onClose: () => void;
    onSetupDefaultSlots: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        topBarContainer: {
            height: 80,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            fontSize: 22,
            fontWeight: 600
        },
        closeButton: {
            fontSize: 20,
            border: 'none',
            cursor: 'pointer',
            appearance: 'none',
            backgroundColor: 'transparent'
        },
        selectDateContainer: {
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',

            '& button': {
                fontSize: 14
            }
        },
        selectDateText: {
            fontSize: 16,
            fontWeight: 600
        }
    })
);

export const AddSlotHeader: React.FC<AddSlotHeaderProps> = props => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.topBarContainer}>
                <Typography className={classes.title}>Add Time Slot</Typography>
                <button type="button" className={classes.closeButton} onClick={props.onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>

            <div className={classes.selectDateContainer}>
                <Typography className={classes.selectDateText}>Select a day</Typography>

                <Button
                    variant="green"
                    leftIcon={faCalendarDays}
                    label="Setup Default Slots"
                    id="add-slot-setup-default-slots-button"
                    onClick={props.onSetupDefaultSlots}
                />
            </div>
        </>
    );
};
