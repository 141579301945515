import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

interface TotalProps {
	amount?: string;
	title: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'center',
			flexDirection: 'column'
		},
		amount: {
			fontSize: 24,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',
			marginBottom: 4,

			[theme.breakpoints.up('md')]: {
				fontSize: 26
			}
		},
		title: {
			fontSize: 12,
			fontWeight: 400,
			color: '#222222',
			letterSpacing: 1
		}
	})
);

export const Total: FunctionComponent<TotalProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Typography className={classes.amount}>
				${props.amount || '0.00'}
			</Typography>
			<Typography className={classes.title}>{props.title}</Typography>
		</Box>
	);
};

export default Total;
