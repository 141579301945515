/**
 * Types
 */

import { MarketplacePaymentMethod } from '@spike/payments-model';
import {
	AdminInvoice,
	MarketplaceSMSMessagesStats,
	Subscription,
	SubscriptionQuote
} from 'model/Subscriptions';
import { Action } from 'redux';

// Reset
export const SUBSCRIPTIONS_RESET_ACTION_TYPE = 'SUBSCRIPTIONS_RESET';

type SubscriptionsResetAction = Action<typeof SUBSCRIPTIONS_RESET_ACTION_TYPE>;

// Fetch
export const SUBSCRIPTIONS_FETCH_START_ACTION_TYPE =
	'SUBSCRIPTIONS_FETCH_START';
export const SUBSCRIPTIONS_FETCH_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTIONS_FETCH_SUCCESS';

// Purge
export const SUBSCRIPTIONS_PURGE_START_ACTION_TYPE =
	'SUBSCRIPTIONS_PURGE_START';
export const SUBSCRIPTIONS_PURGE_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTIONS_PURGE_SUCCESS';

type SubscriptionsFetchStartAction = Action<
	typeof SUBSCRIPTIONS_FETCH_START_ACTION_TYPE
>;
interface SubscriptionsFetchSuccessAction
	extends Action<typeof SUBSCRIPTIONS_FETCH_SUCCESS_ACTION_TYPE> {
	payload: {
		subscriptions: Array<Subscription>;
	};
}

type SubscriptionsPurgeStartAction = Action<
	typeof SUBSCRIPTIONS_PURGE_START_ACTION_TYPE
>;
type SubscriptionsPurgeSuccessAction = Action<
	typeof SUBSCRIPTIONS_PURGE_SUCCESS_ACTION_TYPE
>;

// Save
export const SUBSCRIPTIONS_SAVE_START_ACTION_TYPE = 'SUBSCRIPTIONS_SAVE_START';
export const SUBSCRIPTIONS_SAVE_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTIONS_SAVE_SUCCESS';

type SubscriptionsSaveStartAction = Action<
	typeof SUBSCRIPTIONS_SAVE_START_ACTION_TYPE
>;
interface SubscriptionsSaveSuccessAction
	extends Action<typeof SUBSCRIPTIONS_SAVE_SUCCESS_ACTION_TYPE> {
	payload: {
		subscription: Subscription;
	};
}

// Deactivate
export const SUBSCRIPTIONS_DEACTIVATE_START_ACTION_TYPE =
	'SUBSCRIPTIONS_DEACTIVATE_START';
export const SUBSCRIPTIONS_DEACTIVATE_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTIONS_DEACTIVATE_SUCCESS';

type SubscriptionsDeactivateStartAction = Action<
	typeof SUBSCRIPTIONS_DEACTIVATE_START_ACTION_TYPE
>;
type SubscriptionsDeactivateSuccessAction = Action<
	typeof SUBSCRIPTIONS_DEACTIVATE_SUCCESS_ACTION_TYPE
>;

// Quote
export const SUBSCRIPTION_QUOTE_START_ACTION_TYPE = 'SUBSCRIPTION_QUOTE_START';
export const SUBSCRIPTION_QUOTE_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTION_QUOTE_SUCCESS';

type SubscriptionQuoteStartAction = Action<
	typeof SUBSCRIPTION_QUOTE_START_ACTION_TYPE
>;
interface SubscriptionQuoteSuccessAction
	extends Action<typeof SUBSCRIPTION_QUOTE_SUCCESS_ACTION_TYPE> {
	payload: {
		quote: SubscriptionQuote;
	};
}

// Invoices
export const SUBSCRIPTION_INVOICES_START_ACTION_TYPE =
	'SUBSCRIPTION_INVOICES_START';
export const SUBSCRIPTION_INVOICES_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTION_INVOICES_SUCCESS';

type SubscriptionInvoicesStartAction = Action<
	typeof SUBSCRIPTION_INVOICES_START_ACTION_TYPE
>;
interface SubscriptionInvoicesSuccessAction
	extends Action<typeof SUBSCRIPTION_INVOICES_SUCCESS_ACTION_TYPE> {
	payload: {
		invoices: Array<AdminInvoice>;
	};
}

// Get Payment Method
export const SUBSCRIPTION_GET_PAYMENT_METHOD_START_ACTION_TYPE =
	'SUBSCRIPTION_GET_PAYMENT_METHOD_START';
export const SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS';

type SubscriptionGetPaymentMethodStartAction = Action<
	typeof SUBSCRIPTION_GET_PAYMENT_METHOD_START_ACTION_TYPE
>;
interface SubscriptionGetPaymentMethodSuccessAction
	extends Action<typeof SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS_ACTION_TYPE> {
	payload: {
		paymentMethod: MarketplacePaymentMethod;
	};
}

// Get SMS Messages Stats
export const SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE =
	'SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE';
export const SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE =
	'SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE';

type SubscriptionGetSMSMessagesStatsStartAction = Action<
	typeof SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE
>;
interface SubscriptionGetSMSMessagesStatsSuccessAction
	extends Action<
		typeof SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE
	> {
	payload: {
		stats: MarketplaceSMSMessagesStats;
	};
}

// Get SMS Messages Stats
export const SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE =
	'SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE';

interface SubscriptionTogglePopupAction
	extends Action<typeof SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE> {
	payload: {
		open?: boolean;
	};
}

export type SubscriptionsAction =
	| SubscriptionsResetAction
	| SubscriptionsFetchStartAction
	| SubscriptionsFetchSuccessAction
	| SubscriptionsPurgeStartAction
	| SubscriptionsPurgeSuccessAction
	| SubscriptionsSaveStartAction
	| SubscriptionsSaveSuccessAction
	| SubscriptionsDeactivateStartAction
	| SubscriptionsDeactivateSuccessAction
	| SubscriptionQuoteStartAction
	| SubscriptionQuoteSuccessAction
	| SubscriptionInvoicesStartAction
	| SubscriptionInvoicesSuccessAction
	| SubscriptionGetPaymentMethodStartAction
	| SubscriptionGetPaymentMethodSuccessAction
	| SubscriptionGetSMSMessagesStatsStartAction
	| SubscriptionGetSMSMessagesStatsSuccessAction
	| SubscriptionTogglePopupAction;
