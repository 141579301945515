import { DayHeaderContentArg } from '@fullcalendar/core';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerWeekday: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#222222',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '15px'
            }
        }
    })
);

const weekDays: Map<number, string> = new Map([
    [0, 'Sunday'],
    [1, 'Monday'],
    [2, 'Tuesday'],
    [3, 'Wednesday'],
    [4, 'Thursday'],
    [5, 'Friday'],
    [6, 'Saturday']
]);

export const WeekdayHeaderFullCalendar: FunctionComponent<
    DayHeaderContentArg
> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.headerWeekday}>{weekDays.get(props.dow)}</Box>
    );
};

export default WeekdayHeaderFullCalendar;
