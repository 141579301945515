import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import EditableSelect from '../EditableSelect';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ProductCategoriesState } from 'reducers/productCategories/ProductCategoriesState';
import ProductCategory from 'model/ProductCategory';
import clsx from 'clsx';

interface Props {
    categorySelected?: Option<number>;
    disabled?: boolean;
    onChange: (category: Option<number>) => void;
    onAdd: () => void;
    error: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            fontWeight: 500,
            marginBottom: '2px',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '18px'
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: '22px'
            }
        },
        description: {
            'fontWeight': 300,
            'fontSize': '0.875rem',
            '& input': {
                paddingTop: '0px',
                paddingBottom: '0px',
                fontFamily: 'Poppins',
                [theme.breakpoints.down('md')]: {
                    height: '42px'
                },
                [theme.breakpoints.up('lg')]: {
                    height: '53px'
                }
            }
        },
        nameContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        titleContainer: {
            display: 'flex'
        },
        icon: {
            fontWeight: 600,
            color: '#EAB464',
            padding: '0px'
        },
        error: {
            color: '#EF4F57'
        }
    })
);

export const ProductCategoryComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [category, setCategory] = useState(props.categorySelected);

    const categories = useSelector<RootState, ProductCategoriesState>(
        state => state.productCategories
    );

    useEffect(() => {
        if (props.categorySelected) {
            setCategory(props.categorySelected);
        }
    }, [props.categorySelected]);

    const getFormattedCategory = () => {
        if (category) {
            const categ: Option<number> = {
                id: category!.id!,
                name: category.name,
                description: category.description
            };
            return categ;
        }
    };

    const getOptionsFormatted = (options: Array<ProductCategory>) => {
        const optionsAux: Array<Option<number>> = [];
        options.forEach(item => {
            const optionFound = optionsAux.find(op => op.name === item.name)
                ? true
                : false;
            if (!optionFound && item.active) {
                const itemAux: Option<number> = {
                    id: item.id!,
                    name: item.name
                };
                optionsAux.push(itemAux);
            }
        });

        return optionsAux;
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.nameContainer}>
                {props.error ? (
                    <Box className={classes.titleContainer}>
                        <Typography
                            className={clsx(classes.title, classes.error)}
                        >
                            Category is required
                        </Typography>
                        <Typography
                            className={clsx(classes.title, classes.icon)}
                        >
                            *
                        </Typography>
                    </Box>
                ) : (
                    <Box className={classes.titleContainer}>
                        <Typography className={classes.title}>
                            Category
                        </Typography>
                    </Box>
                )}
                <EditableSelect
                    label="Category"
                    options={getOptionsFormatted(categories.list).sort(
                        (s1, s2) => {
                            return s1.name.localeCompare(s2.name);
                        }
                    )}
                    selected={getFormattedCategory()}
                    onChange={props.onChange}
                    onAdd={props.onAdd}
                    error={props.error}
                    disabled={props.disabled}
                    type="categories"
                />
            </Box>
        </Box>
    );
};

export default ProductCategoryComponent;
