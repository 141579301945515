import { FunctionComponent } from "react";
import {
  Box, Typography
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ProductVendor from "model/ProductVendor";
import SearchComponent from "components/Products/UI/Search";

interface Props {
  vendor: ProductVendor;
  productNumber: number;
  onSearch: (text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    title: {
      fontWeight: 600,
      marginBottom: "11px",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        marginBottom: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        marginBottom: "24px"
      },
    },
    descriptionContainer: {
      background: "#F1F1F1",
      width: "100%",
      height: "auto",
      [theme.breakpoints.down("md")]: {
        marginBottom: "42px"
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "52px"
      },
    },
    description: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        padding: "12px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        padding: "12px"
      },
    },
    nameContainer: {
      display: "flex"
    },
    required: {
      color: "#EAB464"
    },
    searchContainer: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "18px"
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "23px"
      },
    }
  })
);

export const AssignProductsHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.nameContainer}>
        <Typography className={classes.title}>
          Assign products to {props.vendor.name}
        </Typography>
      </Box>
      <Box className={classes.searchContainer}>
        <SearchComponent 
          placeholder="Search by product name or barcode"
          expanded
          onSearch={props.onSearch}
        />
      </Box>
      <Box className={classes.descriptionContainer}>
        <Typography className={classes.description}><strong>{props.productNumber} products</strong> have been assigned</Typography>
      </Box>
    </Box>
  );
};

export default AssignProductsHeader;
