// @ts-nocheck 
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { TextField as MUITextField, Box } from "@material-ui/core";
import clsx from "clsx";
import { FieldError } from "@spike/model";
import InputMask from 'react-input-mask';
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";
import { useMarketplace } from "hooks";
import { cleanPhoneNumberFormat } from "@spike/phone-utils";

export interface ControlledPhoneField3Props {
    name?: string;
    label?: string | JSX.Element;
    value: string;
    placeholder?: string;
    id?: string;
    errors?: Array<FieldError>;
    onChange: (value: string, name?: any,) => void;
    labelClassName?: string;
    className?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    autoComplete?: "off" | "on" | "nope";
    required?: boolean;
    information?: string;
}


export const ControlledPhoneField3: FunctionComponent<ControlledPhoneField3Props> = (props) => {
    const classes = useFieldStyles();

    const marketplace = useMarketplace();

    const isUSorCA = ["US", "CA"].includes(marketplace.basics.address.country?.id.toUpperCase() || "US");

    const hasError = props.errors?.some(error => error.fieldName === props.name) || false;

    const convertToNumbers = (value: string): string => {
        return cleanPhoneNumberFormat(value);
    }

    const usChangeHandler = (event: ChangeEvent<HTMLInputElement>
    ): void => {
        const newValue = convertToNumbers(event.target.value);
        props.onChange(newValue, props.name);
    };

    const otherChangeHandler = (event: ChangeEvent<HTMLInputElement>
    ): void => {
        const newValue = event.target.value;
        /^\+{0,1}[0-9]{0,12}$/.test(newValue) && props.onChange(newValue, props.name);
    };

    const usPhoneNumberField = (<Box>
        <InputMask mask="(999) 999-9999" value={props.value} onChange={usChangeHandler} disabled={props.disabled}>
            {(inputProp: any) =>
                <MUITextField
                    name={props.name}
                    placeholder={props.placeholder}
                    id={props.id}
                    variant="outlined"
                    autoFocus={props.autoFocus}
                    className={clsx(classes.field,
                        {
                            [classes.emptyField]: props.value === null || props.value === undefined || props.value.length === 0,
                            [classes.fieldError]: hasError,
                            [classes.disabledField]: props.disabled,
                        })}
                    error={hasError}
                    disabled={props.disabled}
                    autoComplete={"nope"}
                />
            }
        </InputMask>
    </Box>);

    const otherPhoneNumberField = (<MUITextField
        name={props.name}
        placeholder="Phone Number"
        id={props.id}
        variant="outlined"
        autoFocus={props.autoFocus}
        className={clsx(classes.field,
            {
                [classes.emptyField]: props.value === null || props.value === undefined || props.value.length === 0,
                [classes.fieldError]: hasError,
                [classes.disabledField]: props.disabled,
            })}
        error={hasError}
        disabled={props.disabled}
        autoComplete={"nope"}
        onChange={otherChangeHandler}
        value={props.value}
        inputProps={{ maxLength: 13 }}
    />
    );


    return (
        <Box className={clsx(classes.container, props.className)}>
            <LabelField {...props} />
            {isUSorCA ? usPhoneNumberField : otherPhoneNumberField}
        </Box>);
};

export default ControlledPhoneField3;
