import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, Option } from '@spike/model';
import {
	Button,
	OptionsField,
	PhoneField3,
	SelectField,
	TextField2
} from 'components/UI';
import { useMarketplace, useMasterData } from 'hooks';
import set from 'lodash/set';
import { Contact } from '@spike/client-model';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { validateContact } from '../Validations';
import Info from './Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface ContactFormProps {
	title: string;
	contact: Contact;
	onSave: (contact: Contact) => void;
	onClose: () => void;
	saving?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			[theme.breakpoints.down('sm')]: {
				height: '100%',
				flexWrap: 'nowrap',
				flexDirection: 'column',
				justifyContent: 'flex-start'
			}
		},
		header: {
			padding: '10px 16px 32px',

			[theme.breakpoints.up('md')]: {
				padding: '37px 40px 16px 40px'
			}
		},
		close: {
			'width': '100%',
			'textAlign': 'right',

			[theme.breakpoints.up('md')]: {
				display: 'none'
			},

			'& button': {
				all: 'unset',
				padding: '6px 6px 0px',
				fontSize: 20
			}
		},
		body: {
			padding: '0px 16px',

			[theme.breakpoints.up(wbp)]: {
				padding: '0px 40px 38px 40px'
			}
		},
		title: {
			color: '#000',
			fontWeight: 600,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(22)}px`,
				lineHeight: `${reduceResolution(36)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '22px',
				lineHeight: '36px'
			}
		},
		rowSpacing: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(15)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '15px'
			}
		},
		footer: {
			gap: 10,
			width: '100%',
			display: 'flex',
			flexWrap: 'nowrap',
			padding: '40px 16px 20px',

			[theme.breakpoints.down('sm')]: {
				marginTop: 'auto'
			},
			[theme.breakpoints.up('md')]: {
				gap: 20,
				padding: '16px 40px',
				borderTop: '1px solid #DDDDDD'
			}
		},
		button: {
			width: '100%',

			[theme.breakpoints.down('sm')]: {
				'height': 54,
				'borderRadius': 30,

				'& span': {
					fontSize: 18,
					fontWeight: 600
				}
			}
		}
	})
);

const AuthorizedContact: Option<string> = {
	id: 'authorized',
	name: 'Authorized'
};
const EmergencyContact: Option<string> = {
	id: 'emergency',
	name: 'Authorized & Emergency'
};

const options: Array<Option<string>> = [AuthorizedContact, EmergencyContact];

export const ContactForm: FunctionComponent<ContactFormProps> = props => {
	const classes = useStyles();

	const masterdata = useMasterData();
	const marketplace = useMarketplace();

	const [editedContact, setEditedContact] = useState(props.contact);
	const [errors, setErrors] = useState<Array<FieldError>>([]);

	const changeValueHandler = (value: any, fieldName?: string) => {
		if (fieldName) {
			setEditedContact(prev => {
				const updatedContact = { ...editedContact };
				set(updatedContact, fieldName, value);
				return updatedContact;
			});
		}
	};

	const saveHandler = () => {
		const errors = validateContact(
			editedContact,
			marketplace.basics.address.country!
		);

		setErrors(errors);

		if (errors.length === 0) {
			props.onSave(editedContact);
		}
	};

	return (
		<Grid container className={classes.container}>
			<Box className={classes.header}>
				<Box className={classes.close}>
					<button
						type="button"
						aria-label="Close"
						onClick={() => props.onClose()}
					>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</Box>
				<Typography className={classes.title}>{props.title}</Typography>
			</Box>
			<Box className={classes.body}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField2
							placeholder="First Name"
							label="First Name"
							name="firstName"
							value={editedContact.firstName}
							onChange={changeValueHandler}
							autoComplete={'off'}
							required={true}
							capitalize={true}
							errors={errors}
							maxLength={30}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField2
							placeholder="Last Name"
							label="Last Name"
							name="lastName"
							value={editedContact.lastName}
							autoComplete={'off'}
							onChange={changeValueHandler}
							required={true}
							capitalize={true}
							errors={errors}
							maxLength={30}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} className={classes.rowSpacing}>
					<Grid item xs={12} md={6}>
						<PhoneField3
							label="Phone"
							placeholder="(___) ___-____"
							name="phone"
							autoComplete={'off'}
							value={editedContact.phone}
							onChange={changeValueHandler}
							errors={errors}
							required={true}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SelectField
							label="Relationship"
							placeholder="Relationship"
							name="relationship"
							errors={errors}
							options={[...masterdata.relationships]}
							selectedOption={editedContact.relationship}
							onSelect={changeValueHandler}
							required={true}
						/>
					</Grid>
					<Grid item xs={12}>
						<OptionsField
							label="Contact Type"
							errors={errors}
							options={options}
							selected={
								editedContact.emergencyContact
									? EmergencyContact
									: AuthorizedContact
							}
							onChange={option =>
								setEditedContact(prev => ({
									...prev,
									emergencyContact:
										option.id === EmergencyContact.id
								}))
							}
							required={true}
						/>
					</Grid>
					<Grid item xs={12}>
						<Info
							title={
								editedContact.emergencyContact
									? 'Authorized & Emergency'
									: 'Authorized'
							}
							description={
								editedContact.emergencyContact
									? "Authorized & Emergency contacts can pick-up, drop off & make decisions on behalf of the client's pet."
									: 'Authorized contacts can pick-up and drop off the client’s pet.'
							}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box className={classes.footer}>
				<Button
					className={classes.button}
					label="Discard"
					color="black"
					onClick={props.onClose}
				/>
				<Button
					className={classes.button}
					label="Save"
					color="green"
					loading={props.saving}
					onClick={saveHandler}
				/>
			</Box>
		</Grid>
	);
};

export default ContactForm;
