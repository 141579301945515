import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

interface Props {
  id?: string;
  onNext: () => void;
  disabled?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "21px",
        paddingLeft: "31px",
        paddingRight: "31px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "26px",
        paddingLeft: "39px",
        paddingRight: "39px",
      },
    },
    nextButton: {
      paddingLeft: 0,
      paddingRight: 0,
      color: "#ffffff",
      background: "#92B4A7",
      [theme.breakpoints.down("md")]: {
        width: "93px",
        minWidth: "93px",
        height: "36px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "116px",
        minWidth: "116px",
        height: "45px",
      },
      "& .MuiButton-label": {
        [theme.breakpoints.down("md")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "16px",
        },
      },
      "&:hover": {
        background: "#92B4A7",
      }
    },
  })
);

export const ActionSection: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Button
        id={props.id}
        variant="contained"
        onClick={props.onNext}
        className={classes.nextButton}
        disableFocusRipple={true}
        disableElevation={true}
        disableRipple={true}
        disabled={props.disabled}
      >
        Next
      </Button>
    </Box>
  );
};

export default ActionSection;
