import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Pet from '@spike/pet-model';
import { PetInformationResume } from './PetInformationResume';
import { PetHeader } from './PetHeader';
import { PetImage } from './PetImage';
import clsx from 'clsx';
import { wbp } from 'Theme';

interface PetInformationProps {
	pet: Pet;
	extended?: boolean;
	className?: string;
	onShowPetCard?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			background: '#F8F5F1',
			borderRadius: '20px',
			padding: '18px 0px',
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				background: '#EFEAE6',
				padding: '10px 16px'
			}
		},
		headerContainer: {
			display: 'flex',
			flexDirection: 'row'
		},
		petInformationResumeContainer: {
			[theme.breakpoints.down(wbp)]: {
				paddingLeft: 0
			},
			[theme.breakpoints.up(wbp)]: {
				paddingLeft: 0
			}
		},
		image: {
			width: 100,
			height: 100,
			padding: 0,
			marginRight: 20,
			marginBottom: 10,
			[theme.breakpoints.down('sm')]: {
				padding: 0,
				width: '72px !important',
				height: '72px !important',
				marginRight: '10px !important'
			}
		}
	})
);

export const PetInformation: FunctionComponent<PetInformationProps> = props => {
	const classes = useStyles(props);

	const petCardHandler = () => {
		props.onShowPetCard && props.onShowPetCard();
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.headerContainer}>
				<Box className={classes.image}>
					<PetImage
						petImage={props.pet.image}
						petType={props.pet.type?.id}
					/>
				</Box>
				<PetHeader
					pet={props.pet}
					extended={props.extended}
					onShowPetCard={petCardHandler}
				/>
			</Box>
			<Box>
				<PetInformationResume
					pet={props.pet}
					extended={props.extended}
					className={classes.petInformationResumeContainer}
				/>
			</Box>
		</Box>
	);
};

export default PetInformation;
