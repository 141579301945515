import {
    cancelSeveralLocalAppointmentThunk,
    cancelSeveralLocalBookingThunk,
    removeLocalAppointmentThunk,
    removeLocalBookingThunk,
    removeSeveralLocalAppointmentThunk,
    removeSeveralLocalBookingThunk,
    updateLocalAppointmentThunk,
    updateLocalBookingThunk
} from '@spike/bookings-action';
import ActionCable, { Cable, Channel } from 'actioncable';
import { useMasterData, useTimeZone } from 'hooks';
import { Auth } from '@spike/auth-model';
import { AppointmentsWebSocketsMessage } from 'model/WebSockets';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { debugConsoleLog } from 'utils/GeneralUtils';

const AppointmentsWebSockets: FunctionComponent = () => {
    const auth = useSelector<RootState, Auth>(state => state.login.auth);
    const timeZone = useTimeZone();
    const masterData = useMasterData();

    const dispatch = useDispatch();

    useEffect(() => {
        let cable: Cable;
        let channel: Channel;

        //console.info({ action: "AppointmentsWebSockets useEffect", signedIn: auth.signedIn });

        if (auth.signedIn) {
            [cable, channel] = subscribe(auth.marketplaceId!, auth.addressId!);
        }

        return () => {
            unsubscribe(cable, channel);
        };
    }, []);

    const subscribe = (
        marketplaceId: number,
        addressId: number
    ): [Cable, Channel] => {
        const cable = ActionCable.createConsumer(
            `${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
        );

        const channel = cable!.subscriptions.create(
            {
                channel: 'AppointmentsChannel',
                marketplace_id: marketplaceId,
                address_id: addressId
            },
            { received: (message: any) => messageHandler(message) }
        );

        debugConsoleLog({
            action: 'AppointmentsWebSockets subscribed!',
            channel: 'AppointmentsChannel',
            marketplaceId,
            addressId
        });

        return [cable, channel];
    };

    const unsubscribe = (cable: Cable, channel: Channel) => {
        cable && cable.disconnect();
        channel && channel.unsubscribe();

        debugConsoleLog({
            action: 'AppointmentsWebSockets unsubscribed!',
            cable,
            channel
        });
    };

    const messageHandler = (message: AppointmentsWebSocketsMessage) => {
        debugConsoleLog({
            action: 'AppointmentsWebSockets message received',
            message,
            appointmentId: message.appointment?.id,
            bookingId: message.booking?.id,
            bookingBookedAt: message.booking?.booked_at,
            appointmentBookedAt: message.appointment?.booked_at,
            canceled: message.canceled,
            deleted: message.deleted
        });

        if (
            message.appointment &&
            (message.booking?.appointments || []).some(
                appointment => appointment.id === message.appointment?.id
            )
        ) {
            //debugConsoleLog(`---> Update Appointment, id = ${message.appointment.id}`);
            dispatch(
                updateLocalAppointmentThunk(
                    message.appointment,
                    masterData,
                    timeZone
                )
            );
        }

        if (
            message.appointment &&
            !(message.booking?.appointments || []).some(
                appointment => appointment.id === message.appointment?.id
            )
        ) {
            //debugConsoleLog(`---> Remove Appointment, id = ${message.appointment.id}`);
            dispatch(removeLocalAppointmentThunk(message.appointment.id));
        }

        if (message.booking && message.booking.appointments) {
            //debugConsoleLog(`---> Update Booking, id = ${message.booking.id}`);
            dispatch(
                updateLocalBookingThunk(message.booking, masterData, timeZone)
            );
        }
        if (message.booking && !message.booking.appointments) {
            //debugConsoleLog(`---> Remove Booking, id = ${message.booking.id}`);
            dispatch(removeLocalBookingThunk(message.booking.id));
        }

        if (message.canceled && message.canceled.length > 0) {
            debugConsoleLog(
                `---> Canceled appointments, ids = ${message.canceled}`
            );
            dispatch(
                cancelSeveralLocalAppointmentThunk(
                    [...message.canceled],
                    masterData
                )
            );
            dispatch(
                cancelSeveralLocalBookingThunk(
                    [...message.canceled],
                    masterData
                )
            );
        }

        if (message.deleted && message.deleted.length > 0) {
            debugConsoleLog(
                `---> Deleted appointments, ids = ${message.deleted}`
            );
            dispatch(removeSeveralLocalAppointmentThunk([...message.deleted]));
            dispatch(removeSeveralLocalBookingThunk([...message.deleted]));
        }
    };

    return null;
};

export default AppointmentsWebSockets;
