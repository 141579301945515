import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FieldError, OWNER_ID } from '@spike/model';
import {
    fetchShareholdersThunk,
    getBusinessProfileThunk,
    PaymentsStatus,
    saveShareholderThunk
} from '@spike/payments-action';
import {
    PaymentsBusinessProfile as BusinessProfileModel,
    createAddress,
    createShareholder,
    DocumentTypeIds,
    PaymentsShareholder as ShareholderModel
} from '@spike/payments-model';
import { isEmailValid, validatePhoneNumber } from '@spike/validations';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import { Spinner } from 'components/UI';
import { useApiClientWrapper, useMarketplace, useMasterData } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Staff from 'model/Staff';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
import StepFrame from '../StepFrame';
import { hasNumbers, validateAddress } from '../Validations';
import ShareholderForm from './ShareholderForm';
import ShareholderView from './ShareholderView';

interface ShareholderStepProps {
    className?: string;
    onPrevious?: () => void;
    onNext?: (documentTypeId: string) => void;
    onCompleted?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%'
        }
    })
);

export const ShareholderStep: FunctionComponent<
    ShareholderStepProps
> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const masterData = useMasterData();
    const apiClientWrapper = useApiClientWrapper();

    const staff = useSelector<RootState, Array<Staff>>(
        state => state.staff.staff
    );
    const businessProfile = useSelector<
        RootState,
        BusinessProfileModel | undefined
    >(state => state.payments.businessProfile);
    const shareholder = useSelector<RootState, Array<ShareholderModel>>(
        state => state.payments.shareholders
    )[0];
    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [editedShareholder, setEditedShareholder] = useState<
        ShareholderModel | undefined
    >();
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [saving, setSaving] = useState(false);

    const finish = () => {
        props.onNext && props.onNext(editedShareholder!.documentType!.id);
        props.onCompleted && props.onCompleted();
    };

    useEffect(() => {
        if (staff.length === 0) {
            dispatch(fetchStaffThunk());
        }

        if (businessProfile) {
            dispatch(fetchShareholdersThunk(apiClientWrapper));
        } else {
            dispatch(getBusinessProfileThunk(apiClientWrapper));
        }
    }, []);

    useNonInitialEffect(() => {
        if (PaymentsStatus.FetchShareholdersSuccess === paymentsStatus) {
            if (shareholder) {
                setEditedShareholder(shareholder);
            } else {
                const [owner] = [...staff]
                    .filter(
                        member => member.active && member.role?.id === OWNER_ID
                    )
                    .sort((member, otherMember) => member.id! - otherMember.id!)
                    .map(member => member.person);

                if (businessProfile) {
                    setEditedShareholder(
                        createShareholder(
                            uuid,
                            marketplace,
                            { ...businessProfile.address },
                            owner.firstName,
                            owner.lastName
                        )
                    );
                } else {
                    setEditedShareholder(
                        createShareholder(
                            uuid,
                            marketplace,
                            createAddress(marketplace, masterData),
                            owner.firstName,
                            owner.lastName
                        )
                    );
                }
            }
        } else if (
            PaymentsStatus.GetBusinessProfileSuccess === paymentsStatus
        ) {
            dispatch(fetchShareholdersThunk(apiClientWrapper));
        } else if (PaymentsStatus.SaveShareholderSuccess === paymentsStatus) {
            setSaving(false);
            finish();
        } else if (PaymentsStatus.Error === paymentsStatus) {
            setSaving(false);
        }
    }, [paymentsStatus]);

    const validate = (shareholder: ShareholderModel): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (isEmpty(shareholder.firstName)) {
            errors.push({
                fieldName: 'firstName',
                errorMessage: 'First name is required'
            });
        }

        if (hasNumbers(shareholder.firstName)) {
            errors.push({
                fieldName: 'firstName',
                errorMessage: 'First name cannot have numbers.'
            });
        }

        if (isEmpty(shareholder.lastName)) {
            errors.push({
                fieldName: 'lastName',
                errorMessage: 'Last name is required'
            });
        }

        if (hasNumbers(shareholder.lastName)) {
            errors.push({
                fieldName: 'lastName',
                errorMessage: 'Last name cannot have numbers.'
            });
        }

        if (isEmpty(shareholder.gender)) {
            errors.push({
                fieldName: 'gender',
                errorMessage: 'Gender is required'
            });
        }

        if (isEmpty(shareholder.gender)) {
            errors.push({
                fieldName: 'gender',
                errorMessage: 'Gender is required'
            });
        }

        if (shareholder.dateOfBirth === undefined) {
            errors.push({
                fieldName: 'dateOfBirth',
                errorMessage: 'Date of Birth is required'
            });
        }

        if (!isEmpty(shareholder.email) && !isEmailValid(shareholder.email!)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Invalid email format.'
            });
        }

        if (shareholder.documentType === undefined) {
            errors.push({
                fieldName: 'documentType',
                errorMessage: 'Document Type is required'
            });
        }

        if (isEmpty(shareholder.documentNumber)) {
            if (shareholder?.documentType?.id === DocumentTypeIds.SSN) {
                errors.push({
                    fieldName: 'documentNumber',
                    errorMessage:
                        'Last 4 digits of the Social Security Number (SSN) is required'
                });
            } else {
                errors.push({
                    fieldName: 'documentNumber',
                    errorMessage: 'Document Number is required'
                });
            }
        } else if (
            shareholder?.documentType?.id === DocumentTypeIds.SSN &&
            shareholder.documentNumber.length !== 4
        ) {
            errors.push({
                fieldName: 'documentNumber',
                errorMessage:
                    'Last 4 digits of the Social Security Number (SSN) is required'
            });
        }

        if (isEmpty(shareholder.phoneNumber)) {
            errors.push({
                fieldName: 'phoneNumber',
                errorMessage: 'Phone number is required'
            });
        }

        !isEmpty(shareholder.phoneNumber) &&
            errors.push(
                ...validatePhoneNumber(
                    shareholder.phoneNumber!,
                    marketplace.basics.address.country!.id,
                    'phoneNumber'
                )
            );

        const addressErrors = validateAddress(
            shareholder.address,
            marketplace.basics.address.country!
        );

        return [...errors, ...addressErrors];
    };

    const saveHandler = () => {
        const errors = validate(editedShareholder!);
        setErrors(errors);
        if (errors.length === 0) {
            if (isEqual(shareholder, editedShareholder)) {
                finish();
            } else {
                setSaving(true);
                dispatch(
                    saveShareholderThunk(apiClientWrapper, editedShareholder!)
                );
            }
        }
    };

    const changeShareholderHandler = (shareholder: ShareholderModel) => {
        const newShareholder = { ...shareholder };
        if (
            newShareholder.documentType?.id !==
            editedShareholder?.documentType?.id
        ) {
            newShareholder.documentNumber = '';
        }
        setEditedShareholder(newShareholder);
    };

    return (
        <Box>
            <StepFrame
                title="Business Owner Details"
                btnId="cuddlespay-business-owner"
                description="Cuddles will use your personal information to verify your identity. We will never use or share your personal information to any third parties."
                onPrevious={props.onPrevious}
                onNext={props.onNext ? saveHandler : undefined}
                onCompleted={props.onCompleted ? saveHandler : undefined}
                saving={saving}
                className={props.className}
            >
                {editedShareholder ? (
                    props.onCompleted || props.onNext ? (
                        <ShareholderForm
                            shareholder={editedShareholder!}
                            onChange={changeShareholderHandler}
                            errors={errors}
                        />
                    ) : (
                        <ShareholderView shareholder={editedShareholder!} />
                    )
                ) : (
                    <Spinner />
                )}
            </StepFrame>
        </Box>
    );
};

export default ShareholderStep;
