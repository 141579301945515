import { height } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faPlus, faVideo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Button from "components/UI/V2/Button/Button";
import React, { FunctionComponent } from "react";
import { Img } from 'react-image';

interface Props {
  id?: string;
  onPressAdd?: () => void;
  onPressLinkToVideo?: () => void;
  linkToVideo?: string;
  linkPositionY?: number;
  addButtonLabel?: string;
  titlePage?: string;
  urlImage: string;
  imageWidth: number | string;
  imageHeight: number | string;
  className?: string;
  isMobile?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        paddingTop: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "50px",
      },
    },
    headerContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },

    leftContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: "100px",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "5%",
      },
    },

    rightContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        marginRight: "5%",
      },
      [theme.breakpoints.only("lg")]: {
        marginRight: "105px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "140px",
      },
    },

    title: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "20px",
        fontWeight: 600,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "27px",
        fontWeight: 600,
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "36px",
        fontWeight: 600,
      },
    },
    howToVideoLinkIcon: {
      marginRight: "7px",
    },
    howToVideoLink: {
      cursor: "pointer",
      color: "#5E8677",
      display: "block",
      width: "140px",
      height: "20px",
      position: "relative",
      top: (props) => (props.linkPositionY ? `${props.linkPositionY}px` : "185px"),
      left: "63px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    paperContainer: {
      width: "100%",
      height: "auto",
      backgroundImage: "none",
      padding: "20px",
      [theme.breakpoints.up("sm")]: {
        backgroundImage: (props) => `url(${props.urlImage})`,
        backgroundRepeat: "no-repeat",
        width: (props) => `${props.imageWidth}px`,
        height: (props) => `${props.imageHeight}px`,
        padding: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "none !important",
      }
    },
    tutorialContainer: {
      justifyContent: "center",
      marginTop: "5%",
    },
    containerImgOnboardingMobile:{
      textAlign: "center",
    },
    imageOnboarding: {
      width: "100%",
      height: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "350px",
    }
  })
);

export const OnboardingTutorial: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Grid container className={clsx(classes.container, props.className)}>
      {props.titlePage && props.addButtonLabel && (
        <Box className={classes.headerContainer}>
          <Box className={classes.leftContainer}>
            {props.titlePage && <Typography className={classes.title}>{props.titlePage}</Typography>}
          </Box>
          <Box className={classes.rightContainer}>
            {props.addButtonLabel && (
              <Button
                leftIcon={faPlus}
                label={props.addButtonLabel}
                id={props.id ? props.id+"_button_"+props.addButtonLabel.toLowerCase() : props.titlePage.toLowerCase()+"_"+props.addButtonLabel.toLowerCase()}
                onClick={() => props.onPressAdd && props.onPressAdd()}
              />
            )}
          </Box>
        </Box>
      )}
      <Grid container className={classes.tutorialContainer}>
        <Box className={classes.paperContainer}>
            
          {props.isMobile && (
            <Box className={classes.containerImgOnboardingMobile}>
            <Img
              className={
                classes.imageOnboarding
              }
              src={props.urlImage}
              {...(props.onPressLinkToVideo && { onClick: props.onPressLinkToVideo })}
            />
            </Box>
          )}
          

          {(props.onPressLinkToVideo !== undefined || props.linkToVideo !== undefined) && (
            <Link
              href={props.linkToVideo}
              onClick={props.onPressLinkToVideo}
              underline="always"
              className={classes.howToVideoLink}
            >
              <FontAwesomeIcon icon={faVideo} className={classes.howToVideoLinkIcon} />
              How-to Video
            </Link>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnboardingTutorial;
