import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

interface PointerStepsProps {
  totalSteps: number;
  step?: number;
  className?: string;
  onChange?: (step: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dot: {
      color: "#D3D3D3",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "4px",
        marginRight: "4px",
        width: "7px",
        height: "7px",
      },
      [theme.breakpoints.only("xl")]: {
        marginLeft: "5px",
        marginRight: "5px",
        width: "8px",
        height: "8px",
      },
    },
    pointer: {
      color: "#222222",
    },
    clickable: {
      cursor: "pointer",
    },
  })
);

export const PointerSteps: FunctionComponent<PointerStepsProps> = (props) => {
  const classes = useStyles();

  const steps = _.range(props.totalSteps);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {steps.map((step) => (
        <FontAwesomeIcon
          key={step}
          icon={faCircle}
          className={clsx(
            classes.dot,
            { [classes.pointer]: props.step === step },
            { [classes.clickable]: props.onChange }
          )}
          onClick={() => {
            props.onChange && props.onChange(step);
          }}
        />
      ))}
    </Box>
  );
};

export default PointerSteps;
