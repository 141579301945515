import React, { FunctionComponent, ChangeEvent, KeyboardEvent, useState, useEffect } from "react";
import { TextField as MUITextField, InputAdornment } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "@spike/model";
import { commonStyles } from "Theme";
export interface Props {
  id?: string;
  name?: string;
  label?: string;
  value: string;
  placeholder?: string;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: string, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  multiline?: boolean;
  onEnterPressed?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      width: "100%",

      "& .MuiOutlinedInput-multiline": {
        height: "100%",
        "& .MuiOutlinedInput-inputMultiline": {
          height: "100% !important",
        },
      },
    },
  })
);

export const ControlledTextField: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [label, setLabel] = useState(props.label);
  const [error, setError] = useState(props.errors?.some((e) => e.fieldName === props.name) || false);
  const [text, setText] = useState(props.value);
  const [name, setName] = useState<any>();

  useEffect(() => {
    setText(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.errors) {
      const hasError = props.errors.some((e) => e.fieldName === props.name);
      setError(hasError);
      if (hasError) {
        setLabel(props.errors.find((e) => e.fieldName === props.name)!.errorMessage);
      } else {
        setLabel(props.label);
      }
    }
  }, [props.errors]);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(text, name);
    }, 250);
    return () => clearTimeout(timer);
  }, [text]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setError(false);
    setLabel(props.label);
    setText(event.target.value);
    setName(event.target.name);
  };

  const handleOnFocus = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const handleOnBlur = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  const inputProps =
    props.endIcon === undefined
      ? (props.startIcon === undefined) ? undefined : { startAdornment: <InputAdornment position="start" > {props.startIcon}</InputAdornment> }
      : {
        endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment>,
      };

  return (
    <MUITextField
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      variant="outlined"
      label={label}
      value={text}
      onBlur={handleOnBlur}
      onChange={handleChange}
      onFocus={handleOnFocus}
      autoFocus={props.autoFocus}
      autoComplete={props.autoComplete}
      className={clsx(commonClasses.field, classes.field, props.className)}
      error={error}
      InputProps={inputProps}
      multiline={props.multiline}
      onKeyPress={keyPressHandler}
    />
  );
};

export default ControlledTextField;
