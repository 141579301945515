import { Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ItemLabelRead } from "components/UI/ItemLabelRead";
import { PetDental } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import DentalsTableRow from "./DentalsTableRow";

interface DentalsTableProps {
  dentals: Array<PetDental>;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px !important",
        fontWeight: 500,
        display: "none",
      },
    }
  })
);

export const DentalsTable: FunctionComponent<DentalsTableProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(props.className)}>
      <Grid container>
        <Grid item xs={4}>
          <ItemLabelRead title="Dental Type" classTitle={classes.header} />
        </Grid>
        <Grid item xs={4}>
          <ItemLabelRead title="Extractions" classTitle={classes.header} />
        </Grid>
        <Grid item xs={4}>
          <ItemLabelRead title="Cleaning Date" classTitle={classes.header} />
        </Grid>
      </Grid>
      <Grid container>
        {props.dentals.map((dental, index) => (
          <DentalsTableRow dental={dental} last={index + 1 === props.dentals.length} />
        ))}
      </Grid>
    </Box>
  );
};

export default DentalsTable;
