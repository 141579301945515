import { faFilePdf, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCloudUploadAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ConfirmDialog } from 'components/UI';
import { FunctionComponent, useRef, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
export interface Props {
	imageUrl?: string;
	file?: File;
	removed?: boolean;
	onChangeImage: (imageFile: File, image: string) => void;
	onRemove: () => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    fileContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "100%",
      width: "fit-content",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(50)}px`,
        height: `${reduceResolution(45)}px`,
        paddingLeft: `${reduceResolution(16)}px`,
        paddingRight: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "50px",
        height: "45px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      "& #delete": {
        display: "none",
      },
      "&:hover": {
        "& #delete": {
          display: "block",
        },
        cursor: "pointer",
        color: "#7A7A7A",
        backgroundColor: "#F1F1F1",
      },
      [theme.breakpoints.down("md")]: {
        cursor: "pointer",
        color: "#7A7A7A",
        backgroundColor: "#F1F1F1",
        "& #delete": {
          display: "block",
        },
      }
    },
    iconLeft: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        marginRight: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        marginRight: "10px",
      },
    },
    iconRight: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        marginLeft: `${reduceResolution(10)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        marginLeft: "10px",
      },
    },
    fileName: {
      textDecoration: "none",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "6px",
      },
    },
    cellFileStyle: {
      [theme.breakpoints.only("lg")]: {
        lineHeight: `${reduceResolution(24)}px`,
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.only("xl")]: {
        lineHeight: "24px",
        fontSize: "16px",
      },
    },
    uploadBox: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #000",
      "&:hover": {
        background: "#fff",
      },
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(100)}px`,
        maxWidth: `${reduceResolution(129)}px`,
        height: `${reduceResolution(45)}px`,
        paddingLeft: `${reduceResolution(21)}px`,
        paddingRight: `${reduceResolution(21)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "100px",
        maxWidth: "129px",
        height: "45px",
        paddingLeft: "21px",
        paddingRight: "21px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        borderColor: "#d3d3d3",
        height: "47px",
      }
    },
    upload: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    uploadRow: {
      display: "flex",
      alignItems: "center",
    },
    selected: {
      border: "2px solid #000",
    },
  })
);

export const UploadComponent: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const input = useRef<HTMLInputElement>(null);

	const [fileName, setFileName] = useState(
		props.file ? props.file?.name : ''
	);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const handleChangeClick = () => {
		input.current?.click();
	};

	const handleRemoveFile = () => {
		props.onRemove();
	};

	const handleViewFile = (image: string | undefined) => {
		let w = window.open('about:blank');

		let iframe = w!.document.body.appendChild(
			w!.document.createElement('iframe')
		);
		iframe.src = image ? image : '';
		iframe.width = `${window.innerWidth - 35}`;
		iframe.height = `${window.innerHeight}`;
		iframe.style.border = 'none';
	};

	const imageHandler = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = () => {
			if (reader.readyState === 2) {
				setFileName(file.name);
				props.onChangeImage &&
					props.onChangeImage(file, reader.result?.toString()!);
			}
		};
		reader.readAsDataURL(file);
	};

	return (
		<>
			<Box className={clsx(classes.container, props.className)}>
				<input
					style={{ display: 'none' }}
					type={'file'}
					ref={input}
					onChange={e => imageHandler(e)}
					accept={'.pdf, .png, .jpg, .jpeg'}
					value={''}
				/>
				{props.imageUrl && !props.removed ? (
					<Box className={clsx(classes.fileContainer)}>
						<FontAwesomeIcon
							icon={faFilePdf}
							className={classes.iconLeft}
						/>
						<Typography
							className={clsx(
								classes.cellFileStyle,
								classes.fileName
							)}
							noWrap
							onClick={() => handleViewFile(props.imageUrl)}
						>
							{fileName}
						</Typography>
						<FontAwesomeIcon
							id="delete"
							icon={faTimes}
							className={classes.iconRight}
							onClick={() => setShowDeleteConfirmation(true)}
						/>
					</Box>
				) : (
					<Box
						className={classes.uploadBox}
						onClick={handleChangeClick}
					>
						<Box className={classes.uploadRow}>
							<FontAwesomeIcon
								icon={faCloudUploadAlt}
								className={classes.iconLeft}
							/>
							<Typography className={classes.upload}>
								Upload
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
			{showDeleteConfirmation && (
				<ConfirmDialog
					open={true}
					title={<Typography>{fileName}</Typography>}
					question={
						<Typography>
							Do you want to <strong>delete</strong> this
							<br /> File?
						</Typography>
					}
					cancelButtonLabel="Cancel"
					confirmButtonLabel="Delete"
					onCancel={() => setShowDeleteConfirmation(false)}
					onConfirm={() => {
						setShowDeleteConfirmation(false);
						handleRemoveFile();
					}}
				/>
			)}
		</>
	);
};

export default UploadComponent;
