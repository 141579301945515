import { FunctionComponent, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BodySection, ActionSection } from 'components/UI/Section';
import Section from '../Section';
import ServiceName from './ServiceName';
import ServiceDuration from './ServiceDuration';
import Service, { ServiceDuration as Duration } from 'model/Service';
import TagManager from './TagManager';
import { useMasterData, useMarketplace, useAuth } from 'hooks';
import ServiceDescription from './ServiceDescription';
import ServiceReport from './ServiceReport';
import BusinessLocation from './BusinessLocation';
import ServiceOnlineBooking from './ServiceOnlineBooking';
import { OWNER_ID } from '@spike/model';
import { debugConsoleLog } from 'utils/GeneralUtils';
import { FieldErrorWithKey } from '../model';

interface Props {
    service: Service;
    collapsed?: boolean;
    disabled?: boolean;
    editable?: boolean;
    completed?: boolean;
    modifiable?: boolean;
    isAdmin: boolean;
    errors: Array<FieldErrorWithKey>;
    onEdit?: () => void;
    onNext: () => void;
    onNameChange: (name: string) => void;
    onDescriptionChange: (description: string) => void;
    onDurationChange: (duration: Duration) => void;
    onTagsChange: (tags: Array<string>) => void;
    onReportChange: (report: boolean) => void;
    onBusinessLocationChange?: (businessLocation: any) => void;
    onOnlineBookingChange?: (onlineBooking: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        nameDescriptionDurationContainer: {
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.up('md')]: {
                width: '50%',
                paddingRight: '0px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingRight: '0px'
            }
        },
        nameDescriptionDurantionTagContainer: {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row'
            }
        },
        nameContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column'
        },
        descriptionContainer: {
            marginTop: 14,

            [theme.breakpoints.up('md')]: {
                marginTop: 31
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: 42
            }
        },
        durationContainer: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                marginTop: '44px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '42px'
            }
        },
        businessContainer: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '44px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '42px'
            }
        },
        tagsReportContainer: {
            [theme.breakpoints.down('sm')]: {
                marginTop: 24
            },
            [theme.breakpoints.up('md')]: {
                width: '50%',
                paddingLeft: '50px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '50px'
            }
        }
    })
);

const emptyHours = 0;
const emptyMinutes = 0;

export const Details: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const auth = useAuth();

    const isOwner = auth.user!.role.id === OWNER_ID;

    const isDurationComplete = (duration: Duration): boolean => {
        return (
            (duration.simple !== undefined &&
                (duration.simple.hours !== emptyHours ||
                    duration.simple.minutes !== emptyMinutes)) ||
            (duration.variable.length > 0 &&
                duration.variable.filter(
                    variableDuration =>
                        variableDuration.duration.hours === emptyHours &&
                        variableDuration.duration.minutes === emptyMinutes
                ).length === 0)
        );
    };

    const isComplete = (name: string, duration: Duration): boolean => {
        return name.length > 0 && isDurationComplete(duration);
    };

    const [disabledNext, setDisabledNext] = useState(
        !isComplete(props.service.name, props.service.duration)
    );

    const nameChangeHandler = (name: string) => {
        props.onNameChange(name);
        setDisabledNext(!isComplete(name, props.service.duration));
    };

    const descriptionChangeHandler = (description: string) => {
        props.onDescriptionChange(description);
    };

    const durationChangeHandler = (duration: Duration) => {
        props.onDurationChange(duration);
        setDisabledNext(!isComplete(props.service.name, duration));
    };

    const tagsChangeHandler = (tags: Array<string>) => {
        props.onTagsChange(tags);
    };

    const businessLocationChange = (isMobile: boolean) => {
        props.onBusinessLocationChange &&
            props.onBusinessLocationChange(isMobile);
    };

    const onlineBookingChange = (active: boolean) => {
        debugConsoleLog(String(active));
        props.onOnlineBookingChange && props.onOnlineBookingChange(active);
    };

    const reportChangeHandler = (report: boolean) => {
        props.onReportChange(report);
    };

    const nextHandler = () => {
        props.onNext();
    };

    return (
        <Section position={2} title="Services Details" {...props}>
            <BodySection>
                <Box className={classes.container}>
                    <Box
                        className={classes.nameDescriptionDurantionTagContainer}
                    >
                        <Box
                            className={classes.nameDescriptionDurationContainer}
                        >
                            <Box className={classes.nameContainer}>
                                <ServiceName
                                    text={props.service.name || ''}
                                    error={props.errors.find(
                                        error => error.fieldName === 'name'
                                    )}
                                    onChange={nameChangeHandler}
                                />
                            </Box>
                            <Box className={classes.descriptionContainer}>
                                <ServiceDescription
                                    text={props.service.description}
                                    onChange={descriptionChangeHandler}
                                    max={400}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.tagsReportContainer}>
                            <TagManager
                                systemTags={masterData.serviceTags}
                                tags={props.service.tags}
                                onChange={tagsChangeHandler}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.businessContainer}>
                        <BusinessLocation
                            additionalInfo={marketplace.additionalInfo}
                            editable={props.editable}
                            houseCall={props.service.houseCall}
                            onChange={businessLocationChange}
                        />
                        {(props.isAdmin || isOwner) && (
                            <ServiceOnlineBooking
                                editable={props.editable}
                                active={props.service.olbEnabled}
                                onChange={onlineBookingChange}
                            />
                        )}
                    </Box>
                    <Box className={classes.durationContainer}>
                        <Box>
                            <ServiceDuration
                                petType={props.service.petTypes}
                                duration={props.service.duration}
                                petSizes={
                                    marketplace.petTypes.dogs
                                        ? marketplace.petTypes.dogs?.sizes
                                              .map(e => e.element)
                                              .map(size => ({ ...size }))
                                        : []
                                }
                                onChange={durationChangeHandler}
                                errors={props.errors.filter(
                                    error => error.fieldName === 'duration'
                                )}
                            />
                        </Box>
                        <ServiceReport
                            report={props.service.hasReport}
                            onChange={reportChangeHandler}
                        />
                    </Box>
                </Box>
            </BodySection>
            <ActionSection
                id="services_add_service_details_button_next"
                onNext={nextHandler}
                disabled={disabledNext}
            />
        </Section>
    );
};

export default Details;
