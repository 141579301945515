import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableContainer, Table as TableMUI } from "@material-ui/core";
import TableHeader from "./ProductsTableHeader";
import { TableBody as TableBodyMUI } from "@material-ui/core";
import { RootState } from "store";
import { useSelector } from "react-redux";
import TableSpinner from "components/Products/Table/TableSpinner";
import { ProductsState } from "@spike/products-action";
import { Product } from "@spike/product-model";
import ProductRow from "./ProductRow";

interface Props {
  categoryId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      display: "flex",
      width: "100%",
      overflowX: "visible",
      [theme.breakpoints.down("md")]: {
        paddingTop: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "15px",
      },
    },
    textDialog: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    }
  })
);

export const ProductsTable: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const products = useSelector<RootState, ProductsState>((state) => state.products);
  const loading = useSelector<RootState, boolean>(state => state.products.loading);
  const [productsFiltered, setProductsFiltered] = useState<Array<Product>>(products.list);

  useEffect(() => {
    if (products && products.list.length > 0) {
      let productsAux = products.list.filter((p) => p.category?.id === props.categoryId);
      setProductsFiltered(productsAux);
    }
  }, [products])

  return (
    <TableContainer className={classes.tableContainer}>
      <TableMUI>
        <TableHeader />
        {loading ? (
          <TableSpinner />
        ) : 
          <TableBodyMUI>
            {productsFiltered && productsFiltered.map((p) => {
              return <ProductRow 
                        key={p.uuid}
                        product={p} 
                      />
            })}
          </TableBodyMUI>
        }
      </TableMUI>
    </TableContainer>
  );
};

export default ProductsTable;
