import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import clsx from 'clsx';
import Button from 'components/UI/V2/Button/Button';

interface HeaderProps {
    id?: string;
    title: string | JSX.Element;
    buttonLabel?: string;
    onClick?: () => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
                paddingLeft: '25px',
                paddingRight: '25px',
                paddingTop: '28px'
            }
        },
        title: {
            fontSize: 20,
            fontWeight: 600,
            color: '#222222',
            [theme.breakpoints.up('md')]: {
                fontSize: 24
            }
        },
        button: {
            'height': 55,
            'padding': '0px 20px',
            '& .MuiButton-startIcon': {
                '& svg': {
                    width: 16,
                    height: 16,
                    [theme.breakpoints.down('lg')]: {
                        width: 14,
                        height: 14
                    }
                }
            },
            '& span': {
                fontSize: 18,
                fontWeight: 600
            },
            [theme.breakpoints.down('lg')]: {
                'height': 39,
                'width': 'min-content',
                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        }
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.container)}>
            <Box>
                <Typography className={classes.title}>{props.title}</Typography>
            </Box>
            <Box>
                {props.buttonLabel && (
                    <Button
                        id={props.id}
                        leftIcon={faPlus}
                        label={props.buttonLabel}
                        onClick={props.onClick}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Header;
