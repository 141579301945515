import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Section from 'components/UI/Section';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { StaffPerson, StaffEmergencyContact } from 'model/Staff';
import { MemberWrapper } from './Member';
import ContactDetails from './ContactDetails';
import EmergencyContact from './EmergencyContact';
import { MobileDivider } from 'components/UI/MobileDivider';

interface PersonalProps {
	memberWrapper: MemberWrapper;
	onChange: (
		person: StaffPerson,
		emergencyContact: StaffEmergencyContact
	) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%'
		},
		bodySection: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				paddingLeft: '33px',
				paddingRight: '33px',
				paddingBottom: '10px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '44px',
				paddingRight: '43px',
				paddingBottom: '20px'
			}
		}
	})
);

export const Personal: FunctionComponent<PersonalProps> = props => {
	const classes = useStyles();

	const contactDetailsChangeHandler = (person: StaffPerson) => {
		props.onChange(
			{ ...person },
			{ ...props.memberWrapper.member.emergencyContact }
		);
	};

	const emergencyContactChangeHandler = (
		emergencyContact: StaffEmergencyContact
	) => {
		props.onChange(
			{ ...props.memberWrapper.member.person },
			{ ...emergencyContact }
		);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Section title="Personal">
				<Box className={classes.bodySection}>
					<ContactDetails
						memberWrapper={props.memberWrapper}
						onChange={contactDetailsChangeHandler}
					/>

					<MobileDivider />

					<EmergencyContact
						memberWrapper={props.memberWrapper}
						onChange={emergencyContactChangeHandler}
					/>
				</Box>
			</Section>
		</Box>
	);
};

export default Personal;
