import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
	icon: IconProp;
	color?: string;
	className?: string;
	backgroundColor?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: 90,
			height: 90,
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '50%',
			backgroundColor: '#222'
		},
		icon: {
			fontSize: 36,
			color: '#fff'
		}
	})
);

function IconCircle(props: Props) {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.root, props.className)}>
			<FontAwesomeIcon icon={props.icon} className={classes.icon} />
		</Box>
	);
}

export default IconCircle;
