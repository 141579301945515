import { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  faInfoCircle,
  faExclamationTriangle,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface AlertLabelProps {
  label?: string;
  type?: "info" | "attention" | "warning";
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
    },
    boxFlexRow: {
      display: "flex",
      flexDirection: "row",
    },
    boxIcon: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(38)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "38px",
      },
    },
    sectionIconInfo: {
      color: "#BAA997",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(10)}px`,
        fontSize: `${reduceResolution(28)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "10px",
        fontSize: "28px",
      },
    },
    sectionIconAttention: {
      color: "#EAB464",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(10)}px`,
        fontSize: `${reduceResolution(28)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "10px",
        fontSize: "28px",
      },
    },
    label: {
      color: "#000",
      fontWeight: 500,
      [theme.breakpoints.down(wbp)]: {
        lineHeight: `${reduceResolution(24)}px`,
        fontSize: `${reduceResolution(14)}px`,
        paddingTop: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        lineHeight: "24px",
        fontSize: "14px",
        paddingTop: "3px",
      },
    },
    boxAlert: {
      border: "2px solid #BCB8AE",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(16)}px`,
        padding: `${reduceResolution(10)}px ${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "16px",
        padding: "10px 20px",
      },
    },
    boxInfo: {
      background: "#F8F5F1",
    },
    boxAttention: {
      background: "#F8F5F1",
    }
  })
);

export const AlertLabel: FunctionComponent<AlertLabelProps> = (props) => {
  const classes = useStyles();

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const typeIcon = (type: string | undefined) => {
    switch (type) {
      case "info":
        return (
          <Box className={clsx(classes.boxFlexRow, classes.boxAlert, classes.boxInfo, props.className)}>
            <Box className={classes.boxIcon}>{iconSVG(faInfoCircle, `${classes.sectionIconInfo}`)}</Box>
            <Typography className={classes.label}>{props.label}</Typography>
          </Box>);
      case "attention":
        return (
          <Box className={clsx(classes.boxFlexRow, classes.boxAlert, classes.boxAttention, props.className)}>
            <Box className={classes.boxIcon}>{iconSVG(faExclamationTriangle, `${classes.sectionIconAttention}`)}</Box>
            <Typography className={classes.label}>{props.label}</Typography>
          </Box>)
      default:
        return (
          <Box className={clsx(classes.boxFlexRow, props.className)}>
            <Box className={classes.boxIcon}>{iconSVG(faInfoCircle, `${classes.sectionIconInfo}`)}</Box>
            <Typography className={classes.label}>{props.label}</Typography>
          </Box>)
    }
  };

  return (
    <Box className={classes.container}>
      {typeIcon(props.type)}
    </Box>
  );
};

export default AlertLabel;
