import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import store, { RootState } from 'store';
import clsx from 'clsx';
import { MarketplaceTip } from '@spike/marketplace-model';
import { Button } from 'components/UI';
import { useNonInitialEffect } from '@versiondos/hooks';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { BusinessSettingsTitle } from '../UI';
import { reduceResolution, wbp } from 'Theme';
import { useMarketplace } from 'hooks';
import { TipPercentageField } from 'components/UI/TipPercentageField';
import { MarketplaceTipDto } from '@spike/marketplace-action';
import ApiClient, { createTokenConfig } from 'api/ApiClient';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

export interface Props {
    tips: Array<MarketplaceTip>;
    onSave?: (tips: Array<MarketplaceTip>) => void;
    onContinue?: (tips: Array<MarketplaceTip>) => void;
    onChange?: (tips: Array<MarketplaceTip>) => void;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(565)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: 680
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        businessSettingsTitleContainer: {
            marginBottom: 8
        },
        businessSettingsTitle: {
            'display': 'flex',
            'alignItems': 'center',

            '& $iconBusinessTitle': {
                fontSize: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                marginBottom: '5px'
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        subtitle: {
            width: '100%',
            fontWeight: 400,
            marginBottom: '-20px',
            marginTop: '20px',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(42)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '42px'
            }
        },

        alertContainer: {
            width: '100%',
            display: 'flex',
            marginBottom: 24,
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',

            [theme.breakpoints.down('sm')]: {
                padding: 17,
                borderRadius: 18
            },
            [theme.breakpoints.up('sm')]: {
                padding: 15,
                borderRadius: 20
            }
        },
        alertText: {
            width: '100%',
            lineHeight: 1.4,
            color: '#000 !important',

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px'
            }
        },
        icon: {
            fontSize: 22,
            color: '#BAA997',

            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(28)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '28px'
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                bottom: 0
            }
        },
        right: {
            justifyContent: 'flex-end'
        },
        lineDivider: {
            borderWidth: '1px',
            width: 'inherit',
            marginTop: '18px',
            marginBottom: '-18px',
            borderColor: '#efefef69',
            [theme.breakpoints.up('md')]: {
                visibility: 'hidden'
            }
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '55px',
                borderRadius: '30px',
                marginBottom: '20px',
                marginRight: '30px'
            }
        }
    })
);

export const Tips: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const marketplace = useMarketplace();
    const loading = useSelector<RootState, boolean>(
        state => state.marketplace.loading
    );
    const status = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );
    const [saved, setSaved] = useState(false);
    const storeTips = useSelector<RootState, Array<MarketplaceTip>>(
        state => state.marketplace.marketplace.tips
    );

    const [tips, setTips] = useState([...marketplace.tips]);

    useEffect(() => {
        resetTips();
    }, []);

    useNonInitialEffect(() => {
        props.onChange && props.onChange(tips);
    }, [tips]);

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setSaved(true);
            refreshTips();
        }
    }, [status]);

    const resetTips = async () => {
        const actualTips = await getActualTips();
        const tipOthers = [...actualTips];
        const tipMapped = tipOthers.map(tip => ({
            uuid: tip.uuid,
            percentage: tip.percentage,
            id: tip.id
        }));
        setTips(tipMapped);
    };

    const getActualTips = async () => {
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const getUrl = `tip_rates?marketplace_id=${marketplaceId}`;
        const getResponse: AxiosResponse<Array<MarketplaceTipDto>> =
            await ApiClient.get(
                getUrl,
                createTokenConfig(store.getState().login.auth.token!)
            );
        const actualTips = getResponse.data.map((tip: MarketplaceTipDto) => ({
            id: tip.id,
            uuid: tip.uuid,
            percentage: tip.value
        }));
        return actualTips;
    };

    const refreshTips = () => {
        const tipOthers = [...storeTips];
        const tipMapped = tipOthers.map(tip => ({
            id: tip.id,
            uuid: tip.uuid,
            percentage: tip.percentage
        }));
        setTips(tipMapped);
    };

    const changeTipValueHandler = (value: number | undefined, uuid: string) => {
        const tipOthers = [...tips];
        const index = returnTipIndex(uuid);
        if (index !== undefined) {
            tipOthers[index].percentage = value ?? 0;
        }
        setTips(tipOthers);
        setSaved(false);
    };

    const returnTipIndex = (uuid: string) => {
        const tipIndex = storeTips.map(tip => tip.uuid).indexOf(uuid);
        return tipIndex;
    };

    const clickButtonHandler = () => {
        props.onSave && props.onSave([...tips]);
        props.onContinue && props.onContinue([...tips]);
    };

    const button = (
        <Button
            label={props.onSave ? (saved ? 'Saved!' : 'Save') : 'Continue'}
            onClick={clickButtonHandler}
            loading={loading}
            className={classes.button}
        />
    );

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.titleContainer}>
                <BusinessSettingsTitle
                    subtitle={'Set the tips for your sales.'}
                    containerClassName={classes.businessSettingsTitleContainer}
                    className={classes.businessSettingsTitle}
                >
                    {' '}
                    {props.isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={props.openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}{' '}
                    Tips
                </BusinessSettingsTitle>

                <Box className={classes.alertContainer}>
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        className={classes.icon}
                    />
                    <Box>
                        <Typography className={classes.alertText}>
                            Set your default tip percentages for all online
                            transactions. Please note that tip percentages on
                            POS terminals are pre-set and cannot be customized.
                        </Typography>
                    </Box>
                </Box>

                {tips.map((tip, index) => {
                    return (
                        <>
                            <TipPercentageField
                                key={tip.uuid}
                                tipNumber={index + 1}
                                value={tip.percentage}
                                onChange={value =>
                                    changeTipValueHandler(value, tip.uuid)
                                }
                            ></TipPercentageField>
                            {index !== tips.length - 1 && (
                                <hr className={classes.lineDivider} />
                            )}
                        </>
                    );
                })}
            </Box>

            <Box
                className={clsx(classes.buttonContainer, {
                    [classes.right]: props.onContinue
                })}
            >
                {button}
            </Box>
        </Box>
    );
};

export default Tips;
