import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import ProductCategory, { createEmptyCategory } from "model/ProductCategory";
import CategoryName from "./CategoryName";
import { Button } from "components/UI";
import { saveThunk } from "actions/productCategories/ProductCategoriesActions";
import { ProductCategoriesStatus } from "reducers/productCategories/ProductCategoriesState";
import { RootState } from "store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

interface Props {
  category?: ProductCategory;
  categorySaved: (category: ProductCategory) => void;
  onAssign: (category: ProductCategory) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("md")]: {
        height: "37px"
      },
      [theme.breakpoints.up("lg")]: {
        height: "46px"
      },
      " & .MuiTypography-h6": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("md")]: {
          paddingLeft: "37px",
          paddingTop: "9px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingLeft: "43px",
          paddingTop: "11px",
        },
      },
    },
    title: {
      fontWeight: 600,
      color: "#5E8677",
      letterSpacing: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "24px"
      },
    },
    container: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("md")]: {
          width: "456px",
          minWidth: "456px",
          height: "280px",
          borderRadius: "19px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "570px",
          minWidth: "570px",
          height: "349px",
          borderRadius: "24px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        [theme.breakpoints.down("md")]: {
          padding: "37px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "44px",
        },
      }
    },
    dialogContainer: {
      width: "100%",
      "& .MuiDialog-paper": {
        overflowY: "hidden"
      },
      display: "flex",
      flexDirection: "column",
    },
    buttonsContainer: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        marginTop: "41px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "52px",
      },
    },
    buttonAssing: {
      [theme.breakpoints.down("md")]: {
        marginRight: "11px",
        width: "304px",
        height: "43px",
        "& .MuiButton-label": {
          fontSize: "15px"
        }
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "14px",
        width: "380px",
        height: "54px",
        "& .MuiButton-label": {
          fontSize: "18px"
        }
      },
    },
    buttonSave: {
      [theme.breakpoints.down("md")]: {
        width: "133px",
        height: "43px",
        "& .MuiButton-label": {
          fontSize: "15px"
        }
      },
      [theme.breakpoints.up("lg")]: {
        width: "165px",
        height: "54px",
        "& .MuiButton-label": {
          fontSize: "18px"
        }
      },
    },
    iconTimes: {
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer"
      },
      color: "#000",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        lineHeight: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        lineHeight: "24px"
      },
    },
  })
);

export const CategoryComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const status = useSelector<RootState, ProductCategoriesStatus>(state => state.productCategories.status);
  const loading = useSelector<RootState, boolean>((state) => state.productCategories.loading);

  const [category, setCategory] = useState<ProductCategory | undefined>(props.category);

  const [categoryName, setCategoryName] = useState("");

  const [actionSelected, setActionSelected] = useState("");

  useEffect(() => {
    if (status === ProductCategoriesStatus.SaveSuccess) {
      if(actionSelected === "save") {
        setTimeout(() => {
          props.onClose();
        }, 700);
      }else { 
        setTimeout(() => {
          props.onAssign(category!);
        }, 700);
      }
      props.categorySaved(category!);
    }
  }, [status]);


  const saveCategoryHandler = (action: string) => {
    if(props.category === undefined && categoryName.length > 0) {
      const emptyCategory = createEmptyCategory();
      emptyCategory.name = categoryName;
      dispatch(saveThunk(emptyCategory));
      setCategory(emptyCategory);
    }
    if(category !== undefined && category.name) {
      dispatch(saveThunk(category));
      
    }
    setActionSelected(action);
  };

  const handleChange = (name: string) => {
    if (props.category !== undefined) {
      let categoryAux = {...props.category};
      categoryAux.name = name;
      setCategory(categoryAux);
    }else{
      setCategoryName(name);
    }
  };
  
  return (
    <Dialog open={true} className={clsx(classes.container)}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>{props.category ? "Edit Category" : "Add Category"}</Typography>
        <FontAwesomeIcon icon={faTimes} className={classes.iconTimes} onClick={() => props.onClose()} />
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <CategoryName 
          text={props.category?.name ? props.category.name : ""}
          onChange={handleChange}
        />
        <Box className={classes.buttonsContainer}>
          <Button
            label="Save & assign products"
            loading={loading}
            onClick={() => saveCategoryHandler("assign")}
            variant="secondary"
            size="medium"
            color="green"
            className={classes.buttonAssing}
          />
          <Button
            label="Save"
            loading={loading}
            onClick={() => saveCategoryHandler("save")}
            variant="primary"
            size="medium"
            color="green"
            className={classes.buttonSave}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryComponent;
