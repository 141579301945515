import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import {
	Box,
	ClickAwayListener,
	createStyles,
	makeStyles,
	OutlinedInput,
	Theme,
	Typography
} from '@material-ui/core';
import { useMasterData } from 'hooks';
import Chip from 'components/UI/Chip';
import { Option } from '@spike/model';

interface Props {
	label: string;
	places: Array<number>;
	all: boolean;
	onChangePlace: (place: Array<number>, all: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%'
		},
		problemsContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column'
		},
		problemsContainerDisabled: {
			display: 'flex',
			flexDirection: 'column',
			pointerEvents: 'none'
		},
		titleSection: {
			color: '#A3A3A3',
			[theme.breakpoints.down('lg')]: {
				fontSize: '11px',
				marginBottom: '26px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '14px',
				marginBottom: '34px'
			}
		},
		addCondition: {
			'fontWeight': 600,
			'&:hover': {
				cursor: 'pointer'
			},
			'color': '#5E8677',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				marginTop: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				marginTop: '20px'
			}
		},
		icon: {
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '40px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '53px'
			}
		},
		condContainer: {
			width: '100%',
			height: 'auto',
			border: '1px solid #000',

			[theme.breakpoints.up('md')]: {
				width: '551px',
				paddingLeft: '14px',
				paddingTop: '25px',
				paddingRight: '15px',
				paddingBottom: '25px',
				borderRadius: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '735px',
				paddingLeft: '18px',
				paddingTop: '25px',
				paddingRight: '20px',
				paddingBottom: '29px',
				borderRadius: '23px'
			}
		},
		conditionsContainer: {
			display: 'flex',
			flexWrap: 'wrap'
		},
		conditionsEditablesContainer: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('lg')]: {
				marginLeft: '6px',
				width: '551px'
			},
			[theme.breakpoints.up('xl')]: {
				marginLeft: '8px',
				width: '735px'
			}
		},
		conditionEditableContainer: {
			width: '100%',
			display: 'flex',
			marginBottom: 16,

			[theme.breakpoints.down('sm')]: {
				gap: 8,
				flexWrap: 'wrap',
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				marginBottom: 24
			}
		},
		conditionContainer: {
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
			'backgroundColor': '#F0F4F2',
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				width: '158px',
				height: '34px',
				borderRadius: '18px',
				marginBottom: '8px',
				marginRight: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '210px',
				height: '45px',
				borderRadius: '24px',
				marginBottom: '12px',
				marginRight: '20px'
			}
		},
		conditionSelectedContainer: {
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
			'backgroundColor': '#BDD2CA',
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				width: '158px',
				height: '34px',
				borderRadius: '18px',
				marginBottom: '8px',
				marginRight: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '210px',
				height: '45px',
				borderRadius: '24px',
				marginBottom: '12px',
				marginRight: '20px'
			}
		},
		label: {
			'display': 'flex',
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				paddingTop: '8px',
				paddingBottom: '8px',
				paddingLeft: '15px',
				paddingRight: '15px',
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '12px',
				paddingBottom: '10px',
				paddingLeft: '20px',
				paddingRight: '20px',
				fontSize: '12px'
			}
		},
		arrowContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		conditionsEditablesLabel: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px',
				width: '225px',
				paddingTop: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px',
				width: '300px',
				paddingTop: '16px'
			}
		},
		inputTooth: {
			'gap': 10,
			'padding': 8,
			'fontSize': 16,
			'minHeight': 54,
			'flexWrap': 'wrap',
			'width': '100%',
			'alignItems': 'center',
			'border': '1px solid #EAEAEA',

			[theme.breakpoints.up('md')]: {
				width: '376px',
				padding: '8px',
				minHeight: '53px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '501px',
				padding: '12px',
				minHeight: '68px'
			},

			'& .MuiOutlinedInput-inputAdornedStart': {
				flex: 1,
				padding: 0,
				fontSize: 16,
				paddingLeft: 4
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none !important'
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: 'none !important'
			}
		},
		teeth: {
			cursor: 'pointer',

			[theme.breakpoints.up('xl')]: {
				paddingRight: '12px'
			}
		},
		allTeeth: {
			cursor: 'pointer',
			backgroundColor: '#92B4A7',
			[theme.breakpoints.down('lg')]: {
				marginRight: '6px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '8px'
			}
		},
		teethsContainer: {
			gap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		allTeethContainer: {
			[theme.breakpoints.down('lg')]: {
				width: '700%'
			},
			[theme.breakpoints.up('xl')]: {
				width: '400%'
			}
		},
		allTeethBox: {
			[theme.breakpoints.down('lg')]: {
				width: '90px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '120px'
			}
		},
		findingsFounded: {
			float: 'right',
			position: 'absolute',
			zIndex: 10,
			/* width: "max-content", */
			height: 'auto',
			border: '1px solid #000',
			right: 0,
			backgroundColor: '#fff',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			[theme.breakpoints.down('lg')]: {
				top: '54px',
				borderRadius: '13px',
				minHeight: '47px',
				minWidth: '132px',
				paddingLeft: '13px',
				paddingRight: '13px',
				paddingTop: '13px',
				paddingBottom: '13px'
			},
			[theme.breakpoints.up('xl')]: {
				top: '72px',
				borderRadius: '20px',
				minHeight: '71px',
				minWidth: '200px',
				paddingLeft: '20px',
				paddingRight: '20px',
				paddingTop: '20px',
				paddingBottom: '20px'
			}
		},
		optionsContainer: {
			'&:hover': {
				backgroundColor: '#BDD2CA',
				cursor: 'pointer'
			},
			'display': 'flex',
			'flexDirection': 'column',
			'justifyContent': 'center',
			[theme.breakpoints.down('lg')]: {
				borderRadius: '20px',
				height: '26px',
				paddingLeft: '13px',
				paddingRight: '13px'
			},
			[theme.breakpoints.up('xl')]: {
				borderRadius: '30px',
				height: '40px',
				paddingLeft: '20px',
				paddingRight: '20px'
			}
		},
		optionsNotFound: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '13px',
				paddingRight: '13px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '20px',
				paddingRight: '20px'
			}
		},
		options: {
			width: '100%',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			}
		}
	})
);

export const PlacesInput: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();
	const avaiblePlaces = masterData.groomingReportOptions.findingPlaces;

	const [placeValue, setPlaceValue] = useState('');
	const [placesFounded, setPlacesFounded] = useState(false);
	const [findingsFounded, setFindingsFounded] = useState<
		Array<Option<number>>
	>([]);

	const [all, setAll] = useState(props.all);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setPlaceValue(placeValue);
		if (placeValue.length !== 0) {
			setPlacesFounded(true);
		} else {
			setPlacesFounded(false);
		}
	}, [placeValue]);

	const handleChange = (e: any) => {
		setPlaceValue(e.target.value);
		const valueLowerCase = e.target.value.trim().toLowerCase();
		if (valueLowerCase === 'all') {
			setAll(true);
			props.onChangePlace([], true);
			setPlaceValue('');
		} else {
			const avaiblePlacesAux = avaiblePlaces.filter(item => {
				const name = item.name.toLowerCase();
				return (
					e.target.value.length === 0 ||
					name.indexOf(e.target.value.toLowerCase()) > -1
				);
			});

			setFindingsFounded(avaiblePlacesAux);

			if (avaiblePlacesAux.length === 1) {
				let placeCopy = [...props.places];
				let existPlace =
					avaiblePlacesAux[0].name.toLowerCase() === e.target.value
						? true
						: false;

				setAll(false);
				if (existPlace) {
					let alreadyExistPlace =
						placeCopy.indexOf(avaiblePlacesAux[0].id) !== -1;
					if (!alreadyExistPlace) {
						placeCopy.push(avaiblePlacesAux[0].id);
						props.onChangePlace(placeCopy, false);
						setPlaceValue('');
					}
				}
			}
		}
	};

	const handleDeletePlace = (place: number) => {
		const placeCopy = [...props.places];
		const newTeeth = placeCopy.filter(pl => pl !== place);
		props.onChangePlace(newTeeth, all);
	};

	const handleClosePlacesFounded = () => {
		setPlacesFounded(false);
	};

	const handleSelectOption = (finding: Option<number>) => {
		let placeCopy = [...props.places];
		let alreadyExistPlace = placeCopy.indexOf(finding.id) !== -1;
		setAll(false);
		if (!alreadyExistPlace) {
			placeCopy.push(finding.id);
		}
		props.onChangePlace(placeCopy, false);
		setPlaceValue('');
	};

	const handleDeleteAll = () => {
		const placeCopy = [...props.places];
		setAll(false);
		props.onChangePlace(placeCopy, false);
	};

	const handleFocus = () => {
		if (null !== inputRef.current) {
			inputRef.current.focus();
		}
	};

	const renderLabelPlace = (place: number) => {
		const indexOfPlace = avaiblePlaces.map(pl => pl.id).indexOf(place);
		return avaiblePlaces[indexOfPlace].name;
	};

	return (
		<Box className={classes.conditionEditableContainer}>
			<Typography
				className={classes.conditionsEditablesLabel}
				onClick={handleFocus}
			>
				{props.label}
			</Typography>
			<OutlinedInput
				className={classes.inputTooth}
				onChange={handleChange}
				onClick={handleFocus}
				value={placeValue}
				autoFocus={false}
				inputRef={inputRef}
				startAdornment={
					all ? (
						<Box className={classes.allTeethContainer}>
							<Box className={classes.allTeethBox}>
								<Chip
									center={true}
									className={classes.allTeeth}
									label={'All'}
									onDelete={handleDeleteAll}
								/>
							</Box>
						</Box>
					) : (
						<Box className={classes.teethsContainer}>
							{props.places.map((place, index) => {
								return (
									<Box key={index}>
										<Chip
											center={true}
											className={classes.teeth}
											label={renderLabelPlace(place)}
											onDelete={() =>
												handleDeletePlace(place)
											}
										/>
									</Box>
								);
							})}
						</Box>
					)
				}
			/>
			{placesFounded && (
				<ClickAwayListener onClickAway={handleClosePlacesFounded}>
					<Box className={classes.findingsFounded}>
						{findingsFounded.length > 0 ? (
							findingsFounded.map(ff => (
								<Box
									className={classes.optionsContainer}
									onClick={() => handleSelectOption(ff)}
								>
									<Typography className={classes.options}>
										{ff.name}
									</Typography>
								</Box>
							))
						) : (
							<Box className={classes.optionsNotFound}>
								<Typography className={classes.options}>
									Not found
								</Typography>
							</Box>
						)}
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};

export default PlacesInput;
