import { Action } from "redux";
import Service from "model/Service";

//Action Types
export const SERVICES_RESET_ACTION_TYPE = "SERVICES_RESET";
export const SERVICES_CLEAR_ACTION_TYPE = "SERVICES_CLEAR";
export const SERVICES_FETCH_START_ACTION_TYPE = "SERVICES_FETCH_START";
export const SERVICES_FETCH_SUCCESS_ACTION_TYPE = "SERVICES_FETCH_SUCCESS";
export const SERVICES_SAVE_START_ACTION_TYPE = "SERVICES_SAVE_START";
export const SERVICES_SAVE_SUCCESS_ACTION_TYPE = "SERVICES_SAVE_SUCCESS";
export const SERVICES_DELETE_START_ACTION_TYPE = "SERVICES_DELETE_START";
export const SERVICES_DELETE_SUCCESS_ACTION_TYPE = "SERVICES_DELETE_SUCCESS";
export const SERVICES_ACTIVATE_START_ACTION_TYPE = "SERVICES_ACTIVATE_START";
export const SERVICES_ACTIVATE_SUCCESS_ACTION_TYPE = "SERVICES_ACTIVATE_SUCCESS";
export const SERVICES_INACTIVATE_START_ACTION_TYPE = "SERVICES_INACTIVATE_START";
export const SERVICES_INACTIVATE_SUCCESS_ACTION_TYPE = "SERVICES_INACTIVATE_SUCCESS";
export const SERVICES_PET_TYPES_UPDATE_START_ACTION_TYPE = "SERVICES_PET_TYPE_UPDATE_START";
export const SERVICES_PET_TYPES_UPDATE_SUCCESS_ACTION_TYPE = "SERVICES_PET_TYPE_UPDATE_SUCCESS";
export const SERVICES_BUSINESS_AREA_UPDATE_START_ACTION_TYPE = "SERVICES_BUSINESS_AREA_UPDATE_START";
export const SERVICES_BUSINESS_AREA_UPDATE_SUCCESS_ACTION_TYPE = "SERVICES_BUSINESS_AREA_UPDATE_SUCCESS";
export const SERVICES_STAFF_UPDATE_START_ACTION_TYPE = "SERVICES_STAFF_UPDATE_START";
export const SERVICES_STAFF_UPDATE_SUCCESS_ACTION_TYPE = "SERVICES_STAFF_UPDATE_SUCCESS";
export const SERVICES_ERROR_ACTION_TYPE = "SERVICES_ERROR";

//Actions
interface ServicesResetAction extends Action<typeof SERVICES_RESET_ACTION_TYPE> {}

interface ServicesClearAction extends Action<typeof SERVICES_CLEAR_ACTION_TYPE> {}

interface ServicesFetchStartAction extends Action<typeof SERVICES_FETCH_START_ACTION_TYPE> {}

interface ServicesFetchSuccessAction extends Action<typeof SERVICES_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    services: Array<Service>;
    duplicatedId?: number;
  };
}

interface ServicesSaveStartAction extends Action<typeof SERVICES_SAVE_START_ACTION_TYPE> {}

interface ServicesSaveSuccessAction extends Action<typeof SERVICES_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    service: Service
  }
}

interface ServicesDeleteStartAction extends Action<typeof SERVICES_DELETE_START_ACTION_TYPE> {}

interface ServicesDeleteSuccessAction extends Action<typeof SERVICES_DELETE_SUCCESS_ACTION_TYPE> {
}

interface ServicesActivateStartAction extends Action<typeof SERVICES_ACTIVATE_START_ACTION_TYPE> {}

interface ServicesActivateSuccessAction extends Action<typeof SERVICES_ACTIVATE_SUCCESS_ACTION_TYPE> {
}

interface ServicesInactivateStartAction extends Action<typeof SERVICES_INACTIVATE_START_ACTION_TYPE> {}

interface ServicesInactivateSuccessAction extends Action<typeof SERVICES_INACTIVATE_SUCCESS_ACTION_TYPE> {
}

interface ServicesPetTypesUpdateStartAction extends Action<typeof SERVICES_PET_TYPES_UPDATE_START_ACTION_TYPE> {}

interface ServicesPetTypesUpdateSuccessAction extends Action<typeof SERVICES_PET_TYPES_UPDATE_SUCCESS_ACTION_TYPE> {
}

interface ServicesBusinessAreaUpdateStartAction
  extends Action<typeof SERVICES_BUSINESS_AREA_UPDATE_START_ACTION_TYPE> {}

interface ServicesBusinessAreaUpdateSuccessAction
  extends Action<typeof SERVICES_BUSINESS_AREA_UPDATE_SUCCESS_ACTION_TYPE> {
  }

interface ServicesStaffUpdateStartAction extends Action<typeof SERVICES_STAFF_UPDATE_START_ACTION_TYPE> {}

interface ServicesStaffUpdateSuccessAction extends Action<typeof SERVICES_STAFF_UPDATE_SUCCESS_ACTION_TYPE> {
}

interface ServicesErrorAction extends Action<typeof SERVICES_ERROR_ACTION_TYPE> {}

export type ServicesAction =
  | ServicesResetAction
  | ServicesClearAction
  | ServicesFetchStartAction
  | ServicesFetchSuccessAction
  | ServicesSaveStartAction
  | ServicesSaveSuccessAction
  | ServicesDeleteStartAction
  | ServicesDeleteSuccessAction
  | ServicesActivateStartAction
  | ServicesActivateSuccessAction
  | ServicesInactivateStartAction
  | ServicesInactivateSuccessAction
  | ServicesPetTypesUpdateStartAction
  | ServicesPetTypesUpdateSuccessAction
  | ServicesBusinessAreaUpdateStartAction
  | ServicesBusinessAreaUpdateSuccessAction
  | ServicesStaffUpdateStartAction
  | ServicesStaffUpdateSuccessAction
  | ServicesErrorAction;
