import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import {
    getBusinessProfileThunk,
    saveBusinessProfileThunk
} from '@spike/payments-action';
import { Spinner } from 'components/UI';
import { useApiClientWrapper, useMarketplace, useMasterData } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
    createBusinessProfile,
    PaymentsBusinessProfile as BusinessProfileModel
} from '@spike/payments-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import {
    isEmailValid,
    validatePhoneNumber,
    isWebsiteValid
} from '@spike/validations';
import StepFrame from '../StepFrame';
import { validateAddress } from '../Validations';
import BusinessProfileForm from './BusinessProfileForm';
import BusinessProfileView from './BusinessProfileView';
import { v4 as uuid } from 'uuid';

interface BusinessProfileStepProps {
    className?: string;
    onNext?: () => void;
    onCompleted?: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%'
        }
    })
);

export const BusinessProfileStep: FunctionComponent<
    BusinessProfileStepProps
> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const masterData = useMasterData();
    const apiClientWrapper = useApiClientWrapper();

    const businessProfile = useSelector<
        RootState,
        BusinessProfileModel | undefined
    >(state => state.payments.businessProfile);
    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [editedBusinessProfile, setEditedBusinessProfile] = useState<
        BusinessProfileModel | undefined
    >();
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        dispatch(getBusinessProfileThunk(apiClientWrapper));
    }, []);

    const finish = () => {
        props.onNext && props.onNext();
        props.onCompleted && props.onCompleted();
    };

    useNonInitialEffect(() => {
        if (PaymentsStatus.GetBusinessProfileSuccess === paymentsStatus) {
            setEditedBusinessProfile(
                businessProfile ||
                    createBusinessProfile(marketplace, masterData, uuid)
            );
        } else if (
            PaymentsStatus.SaveBusinessProfileSuccess === paymentsStatus
        ) {
            setSaving(false);
            finish();
        } else if (PaymentsStatus.Error === paymentsStatus) {
            setSaving(false);
        }
    }, [paymentsStatus]);

    const validate = (
        businessProfile: BusinessProfileModel
    ): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (isEmpty(businessProfile.legalName)) {
            errors.push({
                fieldName: 'legalName',
                errorMessage: 'Legal name is required'
            });
        }

        if (isEmpty(businessProfile.legalName)) {
            errors.push({
                fieldName: 'legalName',
                errorMessage: 'Legal Business Name is required'
            });
        }

        if (isEmpty(businessProfile.einNumber)) {
            errors.push({
                fieldName: 'einNumber',
                errorMessage: 'EIN number is required'
            });
        }

        if (
            !isEmpty(businessProfile.einNumber) &&
            businessProfile.einNumber.length !== 9
        ) {
            errors.push({
                fieldName: 'einNumber',
                errorMessage: 'EIN number length must be 9.'
            });
        }

        if (isEmpty(businessProfile.phoneNumber)) {
            errors.push({
                fieldName: 'phoneNumber',
                errorMessage: 'Phone number is required'
            });
        }

        !isEmpty(businessProfile.phoneNumber) &&
            errors.push(
                ...validatePhoneNumber(
                    businessProfile.phoneNumber!,
                    marketplace.basics.address.country!.id,
                    'phoneNumber'
                )
            );

        if (!businessProfile.phoneType) {
            errors.push({
                fieldName: 'phoneType',
                errorMessage: 'Phone type name is required'
            });
        }

        if (isEmpty(businessProfile.email)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Email is required'
            });
        }

        if (
            !isEmpty(businessProfile.email) &&
            !isEmailValid(businessProfile.email)
        ) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Invalid email format.'
            });
        }

        if (isEmpty(businessProfile.website)) {
            errors.push({
                fieldName: 'website',
                errorMessage: 'Website is required'
            });
        }

        if (
            !isEmpty(businessProfile.website) &&
            !isWebsiteValid(businessProfile.website)
        ) {
            errors.push({
                fieldName: 'website',
                errorMessage: 'Invalid website format.'
            });
        }

        if (!businessProfile.termsOfService) {
            errors.push({
                fieldName: 'termsOfService',
                errorMessage: 'Accept the Terms of Service.'
            });
        }

        const addressErrors = validateAddress(
            businessProfile.address,
            marketplace.basics.address.country!
        );

        return [...errors, ...addressErrors];
    };

    const saveHandler = () => {
        const errors = validate(editedBusinessProfile!);
        setErrors(errors);
        if (errors.length === 0) {
            if (isEqual(businessProfile, editedBusinessProfile)) {
                finish();
            } else {
                setSaving(true);
                dispatch(
                    saveBusinessProfileThunk(
                        apiClientWrapper,
                        editedBusinessProfile!
                    )
                );
            }
        }
    };

    return (
        <Box className={classes.container}>
            <StepFrame
                btnId="cuddlespay-business-profile"
                title="Business Profile Detail"
                description="These are your business profile details to verify and validate your business."
                onCompleted={props.onCompleted ? saveHandler : undefined}
                onNext={props.onNext ? saveHandler : undefined}
                saving={saving}
                className={props.className}
            >
                {editedBusinessProfile ? (
                    props.onCompleted || props.onNext ? (
                        <BusinessProfileForm
                            businessProfile={editedBusinessProfile!}
                            onChange={setEditedBusinessProfile}
                            errors={errors}
                        />
                    ) : (
                        <BusinessProfileView
                            businessProfile={editedBusinessProfile!}
                        />
                    )
                ) : (
                    <Spinner />
                )}
            </StepFrame>
        </Box>
    );
};

export default BusinessProfileStep;
