import { Action } from "redux";

//Types
export const RESET_PASSWORD_START_ACTION_TYPE = "RESET_PASSWORD_START";
export const RESET_PASSWORD_END_ACTION_TYPE = "RESET_PASSWORD_END";
export const RESET_PASSWORD_FAIL_ACTION_TYPE = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS_ACTION_TYPE = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_RESET_ACTION_TYPE = "RESET_PASSWORD_RESET";

//Actions
interface ResetPasswordStartAction
  extends Action<typeof RESET_PASSWORD_START_ACTION_TYPE> {}

interface ResetPasswordEndAction
  extends Action<typeof RESET_PASSWORD_END_ACTION_TYPE> {}

interface ResetPasswordFailAction
  extends Action<typeof RESET_PASSWORD_FAIL_ACTION_TYPE> {}

interface ResetPasswordSuccessAction
  extends Action<typeof RESET_PASSWORD_SUCCESS_ACTION_TYPE> {}

  interface ResetPasswordResetAction
  extends Action<typeof RESET_PASSWORD_RESET_ACTION_TYPE> {}

export type ResetPasswordAction =
  | ResetPasswordStartAction
  | ResetPasswordEndAction
  | ResetPasswordFailAction
  | ResetPasswordSuccessAction
  | ResetPasswordResetAction;
