import {
  ADD_NOTIFICATION_BELL_ACTION_TYPE,
  REMOVE_NOTIFICATION_BELL_ACTION_TYPE,
  RESET_NOTIFICATION_BELL_ACTION_TYPE,
  SET_NOTIFICATION_BELL_ACTION_TYPE,
} from "actions/notificationBell/NotificationBellActionsTypes";
import {
  NotificationBellState,
  NotificationBellStatus,
} from "./NotificationBellState";

const initialState: NotificationBellState = {
  notificationAmount: 0,
  status: NotificationBellStatus.Initial,
};

export const notificationBellReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATION_BELL_ACTION_TYPE:
      const addNumber = action.payload ? action.payload : 1;
      const newState: NotificationBellState = {
        notificationAmount: state.notificationAmount + addNumber,
        status: NotificationBellStatus.Add,
      };
      return newState;
    case REMOVE_NOTIFICATION_BELL_ACTION_TYPE:
      const removeNumber = action.payload ? action.payload : 1;
      if (state.notificationAmount >= removeNumber) {
        const newState: NotificationBellState = {
          notificationAmount: state.notificationAmount - removeNumber,
          status: NotificationBellStatus.Remove,
        };
        return newState;
      } else {
        return initialState;
      }
    case SET_NOTIFICATION_BELL_ACTION_TYPE:
      return {
        notificationAmount: action.payload,
        status: NotificationBellStatus.Set,
      };
    case RESET_NOTIFICATION_BELL_ACTION_TYPE:
      return { ...initialState };

    default:
      return state;
  }
};

export default notificationBellReducer;
