import { FunctionComponent } from 'react';
import { createStyles, Switch as SwitchMUI } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface SwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
    color?: string;
    disabled?: boolean;
    id?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        switch: (props: SwitchProps) => ({
            'width': '41px',
            'height': '22px',
            'padding': '0px',
            'borderRadius': '50px',
            '& .MuiSwitch-switchBase': {
                padding: '0px',
                margin: '2px',
                width: '18px',
                height: '18px'
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(19px)'
            },
            '& .MuiSwitch-thumb': {
                width: 16,
                height: 16,
                color: '#E9F0ED',
                boxShadow: 'none'
            },

            '& .MuiSwitch-track': {
                backgroundColor: '#D3D3D3',
                opacity: 1
            },
            '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
                backgroundColor: props.color ? (props.color == 'primary' ? '#e29829' : props.color) : '#5E8677',
                opacity: props.disabled ? 0.4 : 1
            }
        })
    })
);

export const Switch: FunctionComponent<SwitchProps> = props => {
    const classes = useStyles(props);

    return (
        <SwitchMUI
            id={props.id || ''}
            className={clsx(classes.switch, props.className)}
            onChange={(event, checked) => props.onChange(checked)}
            disabled={props.disabled ?? false}
            checked={props.checked}
        />
    );
};

export default Switch;
