import { faCamera, faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useNonInitialEffect from "@versiondos/hooks";
import { cleanLookupThunk, lookupThunk, createProductByCodeThunk } from "@spike/products-action";
import clsx from "clsx";
import SearchComponent from "components/Products/UI/Search";
import { Button, Radio, Spinner } from "components/UI";
import {Product, LookupProduct as LookupProductModel} from "@spike/product-model";
import { FunctionComponent, useEffect, useState } from "react";
import { Img } from "react-image";
import { useDispatch, useSelector } from "react-redux";
import { ProductsStatus } from "@spike/products-action";
import { RootState } from "store";
import { useApiClientWrapper } from "hooks";

interface Props {
  onSelect?: (product: Product) => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("md")]: {
        height: "37px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "46px",
      },
      " & .MuiTypography-h6": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("md")]: {
          paddingLeft: "35px",
          paddingTop: "9px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingLeft: "43px",
          paddingTop: "11px",
        },
      },
    },
    title: {
      fontWeight: 600,
      color: "#5E8677",
      letterSpacing: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    container: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("md")]: {
          width: "582px",
          minWidth: "582px",
          height: "614px",
          borderRadius: "19px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "728px",
          minWidth: "728px",
          height: "768px",
          borderRadius: "24px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        [theme.breakpoints.down("md")]: {
          padding: "42px",
          paddingTop: "30px",
          paddingBottom: "30px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "53px",
          paddingTop: "36px",
          paddingBottom: "36px",
        },
      },
    },
    dialogContainer: {
      width: "100%",
      "& .MuiDialog-paper": {
        overflowY: "hidden",
      },
      display: "flex",
      flexDirection: "column",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        marginTop: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "25px",
      },
    },
    button: {
      [theme.breakpoints.down("md")]: {
        width: "128px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "160px",
      },
    },
    iconClose: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
      },
      float: "right",
      "&:hover": {
        cursor: "pointer",
      },
    },
    description: {
      fontWeight: 400,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
        marginBottom: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px",
        marginBottom: "24px",
      },
    },
    searchContainer: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "20px",
      },
    },
    bodyContainer: {
      width: "100%",
      border: "1px solid #D3D3D3",
      height: "50vh",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "10px",
      },
    },
    productsCounterContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F1F1F1",
      [theme.breakpoints.down("md")]: {
        height: "40px",
        paddingLeft: "9px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "50px",
        paddingLeft: "12px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
    },
    productsCounter: {
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    productsContainer: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      width: "100%",
      bottom: 0,
      [theme.breakpoints.down("md")]: {
        top: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        top: "50px",
      },
    },
    productsNoSearch: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
    },
    productList: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "100%",
      overflowY: "scroll",
    },
    iconSearchContainer: {
      display: "flex",
      justifyContent: "center",
    },
    iconSearch: {
      textAlign: "center",
      color: "#D3D3D3",
      [theme.breakpoints.down("md")]: {
        fontSize: "40px",
        marginBottom: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "50px",
        marginBottom: "10px",
      },
    },
    textSearch: {
      color: "#7A7A7A",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #F1F1F1",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "20px",
      },
    },
    imageBox: {
      [theme.breakpoints.down("md")]: {
        width: "53px",
        height: "57px",
        borderRadius: "8px",
        marginRight: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "66px",
        height: "71px",
        borderRadius: "10px",
        marginRight: "20px",
      },
    },
    uploadBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#F1F1F1",
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px",
        marginRight: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "10px",
        marginRight: "20px",
      },
    },
    uploadIcon: {
      color: "#5E8677",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        lineHeight: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        lineHeight: "30px",
      },
    },
    codeText: {
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "15px",
        marginRight: "16px",
        marginTop: "4px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "17px",
        marginRight: "20px",
        marginTop: "6px",
      },
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px",
      },
    },
    nameContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        marginRight: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "20px",
      },
    },
    rowSection: {
      display: "flex",
    },
    barcodeContainer: {
      display: "flex",
    },
    tagContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "auto",
      background: "#EAB464",
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        height: "15px",
        paddingLeft: "4px",
        paddingRight: "4px",
        borderRadius: "3px",
        marginTop: "4px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "18px",
        paddingLeft: "6px",
        paddingRight: "6px",
        borderRadius: "4px",
        marginTop: "5px",
      },
    },
    tag: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px",
        lineHeight: "27px",
      },
    },
  })
);

export const LookupProduct: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const lookupProducts = useSelector<RootState, Array<LookupProductModel>>((state) => state.products.lookupList);
  const createdProduct = useSelector<RootState, Product | undefined>(state => state.products.createdProduct);
  const productsStatus = useSelector<RootState, ProductsStatus>((state) => state.products.status);
  const searching = useSelector<RootState, number>((state) => state.products.pendingLookup) > 0;

  const [searchText, setSearchText] = useState("");
  const [selectedLookupProduct, setSelectedLookupProduct] = useState<LookupProductModel | null>(null);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    dispatch(cleanLookupThunk());
    return () => {
      dispatch(cleanLookupThunk());
    };
  }, []);

  useNonInitialEffect(()=>{
    switch(productsStatus) {
      case ProductsStatus.CreateProductByCodeSuccess: 
        setCreating(false);
        props.onSelect && props.onSelect(createdProduct!);
        break;
      case ProductsStatus.Error: 
        setCreating(false);
        break;
    }
  },[productsStatus]);

  const searchHandler = (text: string) => {
    setSearchText(text);
    if (text.length >= 3) {
      dispatch(lookupThunk(apiClientWrapper, text));
    }
  };

  const continueHandler = (lookupProduct: LookupProductModel) => {
    setCreating(true);
    dispatch(createProductByCodeThunk(apiClientWrapper, lookupProduct.code));
  }

  return (
    <Box>
      <Dialog open={true} className={clsx(classes.container)}>
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title}>Lookup Product</Typography>
          <FontAwesomeIcon
            icon={faTimes}
            className={classes.iconClose}
            onClick={() => {
              props.onClose && props.onClose();
            }}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContainer}>
          <Typography className={classes.description}>
            Search our database of over 300M products with brand & manufacturer details,
            <br />
            barcodes, product descriptions, and more. Add only one product at a time
          </Typography>
          <Box className={classes.searchContainer}>
            <SearchComponent placeholder="Search by product name or barcode" expanded onSearch={searchHandler} />
          </Box>
          <Box className={classes.bodyContainer}>
            <Box className={classes.productsCounterContainer}>
              <Typography className={classes.productsCounter}>
                <strong>{ searching ? 0 : lookupProducts.length} products</strong> found
              </Typography>
            </Box>
            <Box className={classes.productsContainer}>
              {searching && <Spinner />}
              {!searching && lookupProducts.length > 0 && (
                <Box className={classes.productList}>
                  {lookupProducts.map((product) => {
                    return (
                      <Box key={product.code} className={classes.row}>
                        <Box className={classes.rowSection}>
                          <Box className={classes.imageBox}>
                            {product.imageUrl ? (
                              <Img src={product.imageUrl} className={classes.imageBox} />
                            ) : (
                              <Box className={classes.uploadBox}>
                                <FontAwesomeIcon icon={faCamera} className={clsx(classes.uploadIcon)} />
                              </Box>
                            )}
                          </Box>
                          <Box className={classes.nameContainer}>
                            <Typography className={classes.text}>{product.name}</Typography>
                            <Box className={classes.barcodeContainer}>
                              <Typography className={classes.codeText}>Barcode: {product.code || "-"}</Typography>
                              {product.id && (
                                <Box className={classes.tagContainer}>
                                  <Typography className={classes.tag}>Suggested</Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.rowSection}>
                          <Radio
                            label=""
                            checked={selectedLookupProduct?.code === product.code}
                            onChange={() => setSelectedLookupProduct(product)}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
              {!searching && searchText.length > 0 && lookupProducts.length === 0 && (
                <Box className={classes.productsNoSearch}>
                  <Box className={classes.iconSearchContainer}>
                    <FontAwesomeIcon icon={faBan} className={clsx(classes.iconSearch)} />
                  </Box>
                  <Box className={classes.iconSearchContainer}>
                    <Box className={classes.productsNoSearch}>
                      <Typography className={classes.textSearch}>No results!</Typography>
                      <Typography className={classes.textSearch}>You can always enter products</Typography>
                      <Typography className={classes.textSearch}>manually, or try another name.</Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {!searching && searchText.length === 0 && lookupProducts.length === 0 && (
                <Box className={classes.productsNoSearch}>
                  <Box className={classes.iconSearchContainer}>
                    <FontAwesomeIcon icon={faSearch} className={clsx(classes.iconSearch)} />
                  </Box>
                  <Typography className={classes.textSearch}>No products searched yet</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.buttonsContainer}>
            <Button
              label="Continue"
              loading={creating}
              disabled={!selectedLookupProduct}
              onClick={() => continueHandler(selectedLookupProduct!)}
              variant="primary"
              size="medium"
              color={selectedLookupProduct ? "orange" : "black"}
              className={classes.button}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LookupProduct;
