import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Input, InputAdornment, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, Option } from '@spike/model';
import clsx from 'clsx';
import {
	ChangeEvent,
	FunctionComponent,
	useEffect,
	useRef,
	useState
} from 'react';
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	List,
	ListRowProps
} from 'react-virtualized';
import { wbp } from 'Theme';
import SelectFieldHeader from './SelectFieldHeader';
import { useStyles as useSelectFieldStyles } from './SelectFieldStyles';

interface SelectorFieldSearchProps {
	id?: string;
	label?: string | JSX.Element;
	options: Array<Option<number | string>>;
	selectedOption?: Option<number | string>;
	placeholder?: string;
	name?: string;
	errors?: Array<FieldError>;
	required?: boolean;
	className?: string;
	disabled?: boolean;
	onSelect?: (option?: Option<number | string>, fieldName?: string) => void;
	information?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listContainer: {
			height: '300px',
			overflowY: 'hidden'
		},
		header: {
			zIndex: 10,
			position: 'relative'
		},
		input: {
			'&.MuiInput-underline:before': {
				borderBottom: '1px solid black !important'
			},
			'&.MuiInput-underline:after': {
				borderBottom: '1px solid black !important'
			},
			[theme.breakpoints.down(wbp)]: {
				marginLeft: '16px',
				marginRight: '16px',
				marginBottom: '10px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginLeft: '20px',
				marginRight: '20px',
				marginBottom: '10px'
			}
		},
		body: {
			height: '145px',
			padding: 10
		}
	})
);

const sort = (
	options: Array<Option<number | string>>
): Array<Option<number | string>> =>
	options.sort((option, otherOption) =>
		option.name.toLowerCase().localeCompare(otherOption.name.toLowerCase())
	);

export const SelectorFieldSearch: FunctionComponent<
	SelectorFieldSearchProps
> = props => {
	const classes = useStyles();
	const selectFieldStyles = useSelectFieldStyles();

	const cache = useRef(
		new CellMeasurerCache({ fixedWidth: true, defaultHeight: 30 })
	);

	const [filtered, setFiltered] = useState(props.options);

	useEffect(() => {
		setFiltered(sort(props.options));
	}, [props.options]);

	const selectHandler = (selectedOption: Option<string | number>) => {
		props.onSelect && props.onSelect({ ...selectedOption }, props.name);
		setFiltered(props.options);
	};

	const changeSearchTextHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const tokens = event.target.value
			.split(' ')
			.map(token => token.toLowerCase());

		setFiltered(
			tokens.length === 0
				? sort(props.options)
				: sort(
						props.options.filter(option =>
							tokens.every(
								token =>
									option.name.toLowerCase().indexOf(token) >
									-1
							)
						)
				  )
		);
	};

	const rowRender = (listProps: ListRowProps) => {
		return (
			<CellMeasurer
				key={listProps.key}
				cache={cache.current}
				parent={listProps.parent}
				columnIndex={0}
				rowIndex={listProps.index}
			>
				<Box
					id={
						props.id
							? props.id + '_item_' + listProps.index
							: props.id
					}
					style={listProps.style}
					className={selectFieldStyles.itemContainer}
					onClick={() => selectHandler(filtered[listProps.index])}
				>
					<Typography
						className={clsx(
							selectFieldStyles.item,
							selectFieldStyles.virtualizedItem
						)}
					>
						{filtered[listProps.index].name}
					</Typography>
				</Box>
			</CellMeasurer>
		);
	};

	const list = (
		<Box
			className={clsx(
				selectFieldStyles.listContainer,
				classes.listContainer
			)}
		>
			<Box className={classes.header}>
				<Input
					id={props.id ? props.id + '_input' : props.id}
					startAdornment={
						<InputAdornment position="start">
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</InputAdornment>
					}
					autoFocus={true}
					className={classes.input}
					onChange={changeSearchTextHandler}
				/>
			</Box>
			<Box className={classes.body}>
				<AutoSizer items={filtered}>
					{({ width, height }) => (
						<List
							id={props.id ? props.id + '_list' : props.id}
							items={filtered}
							width={width}
							height={height}
							//rowHeight={({index}) => (Math.ceil(filtered[index].name.length * 12 / width)) * 30}
							rowHeight={30}
							deferredMeasurementCache={cache.current}
							rowRenderer={rowRender}
							rowCount={filtered.length}
						/>
					)}
				</AutoSizer>
			</Box>
		</Box>
	);

	return <SelectFieldHeader {...props}>{list}</SelectFieldHeader>;
};

export default SelectorFieldSearch;
