import { FunctionComponent } from 'react';
import HelpSupport from 'components/HelpSupport';
import PrivatePage from './PrivatePage';

export const HelpAndSupport: FunctionComponent = () => {
	return (
		<PrivatePage title="Help & Support - Cuddles" pageName="/help_support">
			<HelpSupport />
		</PrivatePage>
	);
};

export default HelpAndSupport;
