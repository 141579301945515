import { faUndoAlt } from '@fortawesome/pro-solid-svg-icons';
import { Box, Modal, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import {
    fetchRefundsThunk,
    fetchThunk as fetchPaymentsThunk,
    hasPaymentsThunk
} from '@spike/payments-action';
import clsx from 'clsx';
import { Spinner, ToolbarButton, ToolbarItem } from 'components/UI';
import { PageResult } from '@spike/model';
import { Period } from '@spike/model';
import { Payment, Refund as RefundModel } from '@spike/payments-model';
import moment from 'moment-timezone';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import Table from '../Table';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';
import { Paid } from './Categories';
import PaymentMethod from './PaymentMethod';
import Refund from './Refund';
import State from './State';
import TransactionsEmpty from './TransactionsEmpty';
import TransactionsHeader from './TransactionsHeader';
import ViewTransaction from './TransactionView';
import { wbp, reduceResolution } from 'Theme';
import { useApiClientWrapper } from 'hooks';
import { ApiClientWrapper } from '@spike/api-client';

interface TransactionsProps {
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        alignRight: {
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: '40px'
            }
        }
    })
);

const fetch = (
    apiClientWrapper: ApiClientWrapper,
    dispatch: any,
    categoryId: string,
    clientId: number | null,
    period: Period | null,
    page?: number,
    pageSize?: number
) => {
    if (categoryId === Paid.id) {
        dispatch(
            fetchPaymentsThunk(
                apiClientWrapper,
                clientId,
                period,
                page || 1,
                pageSize || 10
            )
        );
    } else {
        dispatch(
            fetchRefundsThunk(
                apiClientWrapper,
                clientId,
                period,
                page || 1,
                pageSize || 10
            )
        );
    }
};

export const Transactions: FunctionComponent<TransactionsProps> = props => {
    const classes = useStyles();
    const tableStyles = useTableStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const payments = useSelector<RootState, PageResult<Payment>>(
        state => state.payments.payments
    );
    const totalPayments = useSelector<RootState, string | null>(
        state => state.payments.payments.total
    );
    const hasPayments = useSelector<RootState, boolean>(
        state => state.payments.hasPayments
    );
    const refunds = useSelector<RootState, PageResult<RefundModel>>(
        state => state.payments.refunds
    );
    const totalRefunds = useSelector<RootState, string | null>(
        state => state.payments.refunds.total
    );

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [loadingHasPayments, setLoadingHasPayments] = useState(true);
    const [loading, setLoading] = useState(true);
    const [refundPayment, setRefundPayment] = useState<Payment | null>(null);
    const [viewPayment, setViewPayment] = useState<Payment | null>(null);
    const [period, setPeriod] = useState<Period | null>({
        from: moment(),
        to: moment()
    });
    const [clientId, setClientId] = useState<number | null>(null);
    const [categoryId, setCategoryId] = useState(Paid.id);
    const [optionSelected, setOptionSelected] = useState<string | undefined>(
        'Today'
    );

    useEffect(() => {
        dispatch(hasPaymentsThunk(apiClientWrapper));
    }, []);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.HasPaymentsSuccess:
                dispatch(
                    fetchPaymentsThunk(
                        apiClientWrapper,
                        null,
                        { from: moment(), to: moment() },
                        1,
                        10
                    )
                );
                setLoadingHasPayments(false);
                break;
            case PaymentsStatus.FetchSuccess:
                setLoading(false);
                break;
            case PaymentsStatus.FetchRefundsSuccess:
                setLoading(false);
                break;
            case PaymentsStatus.Error:
                setLoadingHasPayments(false);
                setLoading(false);
                break;
        }
    }, [paymentsStatus]);

    const changePageHandler = (page: number) => {
        setLoading(true);
        fetch(
            apiClientWrapper,
            dispatch,
            categoryId,
            clientId,
            period,
            page,
            payments.pagination?.pageSize
        );
    };

    const changePageSizeHandler = (pageSize: number) => {
        setLoading(true);
        fetch(
            apiClientWrapper,
            dispatch,
            categoryId,
            clientId,
            period,
            payments.pagination?.page,
            pageSize
        );
    };

    const changeCategoryHandler = (categoryId: string) => {
        setLoading(true);
        setCategoryId(categoryId);
        fetch(
            apiClientWrapper,
            dispatch,
            categoryId,
            clientId,
            period,
            payments.pagination?.page,
            payments.pagination?.pageSize
        );
    };

    const changePeriodHandler = (period: Period, opSelect?: string) => {
        setLoading(true);
        setPeriod(period);
        setOptionSelected(opSelect);
        fetch(
            apiClientWrapper,
            dispatch,
            categoryId,
            clientId,
            period,
            payments.pagination?.page,
            payments.pagination?.pageSize
        );
    };

    const changeClientHandler = (selectedClientId: number | null) => {
        if (clientId !== selectedClientId) {
            setLoading(true);
            setClientId(selectedClientId);
            fetch(
                apiClientWrapper,
                dispatch,
                categoryId,
                selectedClientId,
                period,
                1,
                payments.pagination?.pageSize
            );
        }
    };

    const paidHeaders = (
        <>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Date
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Invoice
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Client
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Paid Type
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Amount
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Status
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            ></TableCell>
        </>
    );

    const refundHeaders = (
        <>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Date of Refund
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Invoice
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Client
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Amount
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Note
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            >
                Status
            </TableCell>
            <TableCell
                className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
            ></TableCell>
        </>
    );

    const paidRows = (
        <>
            {payments.page.map(payment => (
                <TableRow
                    key={payment.uuid}
                    onClick={() => setViewPayment(payment)}
                    style={{ cursor: 'pointer' }}
                >
                    <TableCell className={tableStyles.cell}>
                        {payment.paidAt.format('MM/DD/YYYY, h:mm a')}
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        {payment.invoice.number}
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        {payment.customer.name}
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        <PaymentMethod
                            paymentMethodId={payment.paymentMethod.id}
                            paymentMethodName={payment.paymentMethod.name}
                            cardDigits={payment.paymentMethod.cardDigits}
                        />
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        {`$${payment.amount}`}
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        <State state={payment.status} type="payment" />
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        {payment.refundable && (
                            <ToolbarButton>
                                <ToolbarItem
                                    text="Refund"
                                    icon={faUndoAlt}
                                    onClick={() => {
                                        setRefundPayment(payment);
                                    }}
                                />
                            </ToolbarButton>
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </>
    );

    const refundRows = (
        <Fragment>
            {refunds.page.map(refund => (
                <TableRow key={refund.uuid}>
                    <TableCell className={tableStyles.cell}>
                        {refund.refundDate.format('MM/DD/YYYY, h:mm a')}
                    </TableCell>
                    <TableCell className={tableStyles.cell}>
                        {refund.invoice.number}
                    </TableCell>
                    <TableCell className={tableStyles.cell}>
                        {`${refund.customer.firstName} ${refund.customer.lastName}`}
                    </TableCell>
                    <TableCell className={tableStyles.cell}>
                        {`($${refund.amount})`}
                    </TableCell>

                    <TableCell className={tableStyles.cell}>
                        {refund.reason || '-'}
                    </TableCell>
                    <TableCell className={tableStyles.cell}>
                        <State state={refund.status} type="refund" />
                    </TableCell>
                </TableRow>
            ))}
        </Fragment>
    );

    return (
        <>
            {loadingHasPayments && <Spinner />}

            {!loadingHasPayments && !hasPayments && <TransactionsEmpty />}

            {!loadingHasPayments && hasPayments && viewPayment === null && (
                <Box className={clsx(classes.container, props.className)}>
                    <TransactionsHeader
                        clientId={clientId}
                        period={period!}
                        optionSelected={optionSelected}
                        onChangeCategory={changeCategoryHandler}
                        onChangePeriod={changePeriodHandler}
                        onChangeClient={changeClientHandler}
                        totalAmount={
                            (categoryId === Paid.id
                                ? totalPayments
                                : totalRefunds) || '0.00'
                        }
                        totalElements={
                            loading
                                ? undefined
                                : categoryId === Paid.id
                                ? payments.pagination?.totalRecords
                                : refunds.pagination?.totalRecords
                        }
                    />
                    <Table
                        headers={
                            categoryId === Paid.id ? paidHeaders : refundHeaders
                        }
                        rows={categoryId === Paid.id ? paidRows : refundRows}
                        pagination={
                            categoryId === Paid.id
                                ? { ...payments.pagination! }
                                : { ...refunds.pagination! }
                        }
                        loading={loading}
                        onChangePage={changePageHandler}
                        onChangePageSize={changePageSizeHandler}
                    />
                </Box>
            )}

            {viewPayment !== null && (
                <ViewTransaction
                    payment={viewPayment}
                    onBack={() => setViewPayment(null)}
                />
            )}

            {refundPayment !== null && (
                <Modal
                    open={true}
                    aria-labelledby="simple-dialog-title"
                    className={classes.modal}
                >
                    <Refund
                        payment={refundPayment!}
                        onClose={() => setRefundPayment(null)}
                    />
                </Modal>
            )}
        </>
    );
};

export default Transactions;
