import { Auth } from '@spike/auth-model';
import { Option } from '@spike/model';
import { Action } from 'redux';

//Types
export const LOGIN_START_ACTION_TYPE = 'login/START';
export const LOGIN_END_ACTION_TYPE = 'login/end';

export const LOGIN_SUCCESS_ACTION_TYPE = 'login/success';
export const LOGIN_FAIL_ACTION_TYPE = 'login/fail';

export const LOGIN_RESET_ACTION_TYPE = 'login/reset';

export const LOGIN_CHANGE_MARKETPLACE_ACTION_TYPE = 'login/change_marketplace';
export const LOGIN_SET_TIME_ZONE_ACTION_TYPE = 'login/set_time_zone';
export const LOGIN_SET_ROLE_AND_ACCESS_LEVEL_ACTION_TYPE =
    'login/set_role_and_access_level';
export const LOGIN_CHANGE_IMAGE_ACTION_TYPE = 'login/change_image';

export const LOGOUT_ACTION_TYPE = 'logout';

//Login
type LoginStartAction = Action<typeof LOGIN_START_ACTION_TYPE>;

type LoginEndAction = Action<typeof LOGIN_END_ACTION_TYPE>;

interface LoginSuccessAction extends Action<typeof LOGIN_SUCCESS_ACTION_TYPE> {
    payload: {
        auth: Auth;
    };
}

interface LoginChangeMarketplaceAction
    extends Action<typeof LOGIN_CHANGE_MARKETPLACE_ACTION_TYPE> {
    payload: {
        marketplaceId: number;
        addressId: number;
        timeZone: string;
        role: Option<number>;
        accessLevel: Option<number>;
    };
}

type LoginFailAction = Action<typeof LOGIN_FAIL_ACTION_TYPE>;

type LoginResetAction = Action<typeof LOGIN_RESET_ACTION_TYPE>;

type LogoutAction = Action<typeof LOGOUT_ACTION_TYPE>;

interface LoginSetTimeZoneAction
    extends Action<typeof LOGIN_SET_TIME_ZONE_ACTION_TYPE> {
    payload: {
        timeZone: string;
    };
}

interface LoginSetRoleAndAccessLevelAction
    extends Action<typeof LOGIN_SET_ROLE_AND_ACCESS_LEVEL_ACTION_TYPE> {
    payload: {
        role: Option<number>;
        accessLevel: Option<number>;
        staffId: number;
    };
}

interface LoginChangeImageAction
    extends Action<typeof LOGIN_CHANGE_IMAGE_ACTION_TYPE> {
    payload: {
        image: string;
    };
}

//LoginAction
export type LoginAction =
    | LoginStartAction
    | LoginEndAction
    | LoginSuccessAction
    | LoginFailAction
    | LoginResetAction
    | LoginChangeMarketplaceAction
    | LogoutAction
    | LoginSetTimeZoneAction
    | LoginSetRoleAndAccessLevelAction
    | LoginChangeImageAction;
