import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface ItemProps {
  id?: string;
  icon?: IconDefinition;
  iconImage?: JSX.Element;
  title: string;
  selected?: boolean;
  subitem?: boolean;
  className?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: ItemProps) => ({
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: props.subitem ? `${reduceResolution(20)}px` : "0px",
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: props.subitem ? "20px" : "0px",
      },
    }),
    item: (props: ItemProps) => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      cursor: props.onClick ? "pointer" : "default",
      [theme.breakpoints.down(wbp)]: {
        minHeight: `${reduceResolution(32)}px`,
        padding: `${reduceResolution(8)}px ${reduceResolution(8)}px ${reduceResolution(8)}px ${reduceResolution(12)}px`,
        borderRadius: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        minHeight: "32px",
        padding: "8px 8px 8px 12px",
        borderRadius: "6px",
      },
      "&:hover": {
        backgroundColor: props.onClick ? "#FAEFDF" : "none",
        width: "100%",
      },
    }),
    selected: {
      backgroundColor: "#FAEFDF",
      width: "100%",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(16)}px !important`,
        height: `${reduceResolution(16)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "16px !important",
        height: "16px !important",
      },
    },
    title: {
      fontFamilty: "Poppins",
      fontWeight: 500,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
        marginLeft: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
        marginLeft: "8px",
      },
    },
  })
);

export const Item: FunctionComponent<ItemProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box
        className={clsx(classes.item, { [classes.selected]: props.selected }, props.className)}
        onClick={props.onClick} id={props.id}
      >
        {props.icon && <FontAwesomeIcon icon={props.icon} className={classes.icon} />}
        {props.iconImage && props.iconImage}
        <Typography className={classes.title}>{props.title}</Typography>
      </Box>
    </Box>
  );
};

export default Item;
