import React, { FunctionComponent, } from "react";
import NumberFormat from "react-number-format";

export interface Props {
  price: number|string;
  className?: string;
}

export const PriceFormat: FunctionComponent<Props> = (props) => {
  
  return (<NumberFormat
    displayType="text"
    prefix="$"
    value={props.price}
    decimalScale={2}
    fixedDecimalScale={true}
    className={props.className}
  />);
};

export default PriceFormat;
