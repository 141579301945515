import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import StatusButton from "./StatusButton";

interface Props {
  onClick: () => void;
  loading?: boolean;
  id?:string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkout: {
      color: "white",
      borderColor: "#222222",
      backgroundColor: "#222222",
      "&:hover": {
        backgroundColor: "#000000",
        borderColor: "#000000",
      }
    },
  })
);

export const CheckoutButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  return (<StatusButton label="Check Out" className={classes.checkout} onClick={props.onClick} spinnerColor="white" loading={props.loading} id={`${props.id||""}`}/>);
};

export default CheckoutButton;
