import React from 'react';
import moment from 'moment-timezone';
import { faPaw } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventContentArg } from '@fullcalendar/core';
import { createStyles, makeStyles } from '@material-ui/core';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontSize: 12,
            color: '#000',
            fontWeight: 500,
            borderRadius: 4,
            padding: '2px 3px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#EFEFEF'
        },
        petsCountBadge: {
            gap: 2,
            fontSize: 11,
            lineHeight: 1,
            fontWeight: 600,
            color: '#ffffff',
            borderRadius: 3.5,
            padding: '4.5px 5px',
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: '#000000'
        }
    })
);

export const ScheduleTypeCalendarEvent: React.FC<EventContentArg> = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span>
                <FontAwesomeIcon icon={faClock} /> {moment(props.event.start).format('h:mm A')}
            </span>

            <span className={classes.petsCountBadge}>
                <FontAwesomeIcon icon={faPaw} />3
            </span>
        </div>
    );
};
