import { FieldError } from '@spike/model';

export enum ServiceCategoryType {
    CATEGORY,
    DETAIL,
    PRICING,
    STAFF
}

export interface FieldErrorWithKey extends FieldError {
    key: number;
    id?: string;
}

export interface PriceError extends FieldErrorWithKey {
    petTypeId: string;
    priceUuid: string | null;
    type: 'inline' | 'alert';
}
