import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { status } from '@spike/appointment-model';

import {
    changeBookingStatusThunk,
    NewBookingsStatus
} from '@spike/new-bookings-action';
import { Button, Checkbox, ConfirmDialog } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import { UserNotification } from 'model/UserNotifications';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

interface NotificationRowActionsProps {
    loading: boolean;
    notification: UserNotification;
    onStatusChange: (status: NewBookingsStatus) => void;
    onStatusChanged: (status: NewBookingsStatus) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            gap: 8,
            display: 'flex'
        },
        confirmDialog: {
            '& .MuiDialog-paperWidthSm': {
                maxWidth: 455
            }
        },
        confirmDialogCheckboxContainer: {
            display: 'flex',
            padding: '0px 32px'
        },
        confirmDialogCheckboxText: {
            color: '#7A7A7A',
            textAlign: 'left'
        }
    })
);

const NotificationRowActions: React.FC<NotificationRowActionsProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const [showDeclineDialog, setShowDeclineDialog] = useState(false);
    const [sendDeclineNotification, setSendDeclineNotification] =
        useState(true);

    const changeBookingStatus = async (
        status: number,
        sendNotification = false
    ) => {
        if (!props.notification.bookingId) {
            return;
        }

        props.onStatusChange(status);

        await dispatch(
            changeBookingStatusThunk(
                apiClientWrapper,
                props.notification.bookingId,
                status,
                false,
                sendNotification
            )
        );

        props.onStatusChanged(status);
    };

    const approveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        changeBookingStatus(status.BOOKED);
    };

    const declineHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        setShowDeclineDialog(true);
    };

    return (
        <>
            <div className={classes.root}>
                <Button
                    label="Decline"
                    variant="danger"
                    loading={props.loading}
                    onClick={declineHandler}
                />
                <Button
                    label="Accept"
                    color="green"
                    loading={props.loading}
                    onClick={approveHandler}
                />
            </div>

            <ConfirmDialog
                open={showDeclineDialog}
                processing={props.loading}
                className={classes.confirmDialog}
                title={<Typography>Decline Appointment</Typography>}
                question={
                    <Typography>
                        Are you sure you want to decline this appointment?
                    </Typography>
                }
                subquestion={
                    <Box className={classes.confirmDialogCheckboxContainer}>
                        <Checkbox
                            checked={sendDeclineNotification}
                            onChange={() =>
                                setSendDeclineNotification(
                                    !sendDeclineNotification
                                )
                            }
                            label=" "
                        />
                        <Box>
                            <Typography
                                className={classes.confirmDialogCheckboxText}
                            >
                                Send a notification to the customer that their
                                appointment has been decline.
                            </Typography>
                        </Box>
                    </Box>
                }
                cancelButtonLabel="Cancel"
                confirmButtonLabel="Decline"
                onCancel={() => {
                    setShowDeclineDialog(false);
                    setSendDeclineNotification(true);
                }}
                onConfirm={() => {
                    setShowDeclineDialog(false);
                    changeBookingStatus(
                        status.DECLINED,
                        sendDeclineNotification
                    );
                }}
            />
        </>
    );
};

export default NotificationRowActions;
