import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "store";
import _, { isEmpty } from "lodash";
import clsx from "clsx";
import { MarketplaceIntegrations } from "@spike/marketplace-model";
import { TextField, Button } from "components/UI";
import { useNonInitialEffect } from "@versiondos/hooks";
import { MarketplaceStatus } from "@spike/marketplace-action";
import { BusinessSettingsTitle } from "../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  integrations: MarketplaceIntegrations;
  onSave?: (integrations: MarketplaceIntegrations) => void;
  onContinue?: (integrations: MarketplaceIntegrations) => void;
  onChange?: (integrations: MarketplaceIntegrations) => void;
  className?: string;
  openSidebar?: () => void;
  isMobile?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "171px",
      },
      [theme.breakpoints.only("md")]: {
        width: "276px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "368px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "558px",
      },
    },
    titleContainer: {
      display: "flex",
      width: "100%",
    },
    businessSettingsTittle: {
      display: "flex",
      alignItems: "center",
      "& $iconBusinessTitle": {
        fontSize: "14px"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginBottom: "5px",
      }
    },
    iconBusinessTitle: {
      position: "relative",
      marginRight: "8px"
    },
    nameContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "32px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "49px",
      },
    },
    addressSuiteContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "14px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "22px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "29px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "44px",
      },
    },
    address: {
      display: "flex",
      width: "70%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "6px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "10px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "13px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "19px",
      },
    },
    suite: {
      display: "flex",
      width: "30%",
    },
    rowContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "9px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "15px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "20px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "31px",
      },
    },
    city: {
      display: "flex",
      width: "33%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "8px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "12px",
      },
    },
    state: {
      display: "flex",
      width: "33%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "4px",
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingRight: "6px",
        paddingLeft: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingRight: "8px",
        paddingLeft: "8px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "12px",
        paddingLeft: "12px",
      },
    },
    zipcode: {
      display: "flex",
      width: "33%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "8px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "12px",
      },
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "27px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "44px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "58px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "88px",
      },
    },
    right: {
      justifyContent: "flex-end",
    }
  })
);

export const Integrations: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const loading = useSelector<RootState, boolean>(state => state.marketplace.loading);
  const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
  const [saved, setSaved] = useState(false);

  const [integrations, setIntegrations] = useState({...props.integrations});

  useNonInitialEffect(() => {
    props.onChange && props.onChange(integrations);
    setSaved(_.isEqual(props.integrations, integrations));
  },[integrations]);

  useNonInitialEffect(() => {
    if(status === MarketplaceStatus.SaveSuccess) {
      setSaved(true);
    }
  },[status]);

  const changeValueHandler = (value: string) => {
    setIntegrations(prev => ({...prev, googleAnalyticsTrackingId: value}));
  };

  const clickButtonHandler = () => {
    props.onSave && props.onSave({ ...integrations });
    props.onContinue && props.onContinue({...integrations});
  };

  const button = (<Button label={props.onSave ? (saved ? "Saved!" : "Save") : "Continue"} onClick={clickButtonHandler} loading={loading} />);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.titleContainer}>
        <BusinessSettingsTitle className={classes.businessSettingsTittle}> {props.isMobile && (
          <FontAwesomeIcon
            className={classes.iconBusinessTitle}
            onClick={props.openSidebar}
            icon={faArrowLeft}
            size="2x"
          />
        )} Integrations</BusinessSettingsTitle>
      </Box>
      <Box className={classes.nameContainer}>
        <TextField
          label="Google Analitycs Tracking ID"
          value={!isEmpty(integrations.googleAnalyticsTrackingId) ? integrations.googleAnalyticsTrackingId! : ""}
          onChange={changeValueHandler}
        />
      </Box>
      <Box className={clsx(classes.buttonContainer,{[classes.right]:props.onContinue})}>
        {button}
      </Box>
    </Box>
  );
};

export default Integrations;
