import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CounterFieldProps } from 'components/UI/V2/CounterField/CounterField';

export const useCounterFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 14,
            display: 'inline-flex',
            alignItems: 'center'
        },
        label: {
            fontSize: 15,
            lineHeight: 1,
            fontWeight: 500
        },
        button: {
            border: 0,
            fontSize: 16,
            padding: 0,
            cursor: 'pointer',
            appearance: 'none',
            background: 'none'
        },
        hidden: {
            display: 'none'
        }
    })
);
