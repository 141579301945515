import {
  NewBookingsAction,
  NEW_BOOKINGS_SAVE_START_ACTION_TYPE,
  NEW_BOOKINGS_SAVE_SUCCESS_ACTION_TYPE,
  NEW_BOOKINGS_DEPOSIT_SAVE_START_ACTION_TYPE,
  NEW_BOOKINGS_DEPOSIT_SAVE_SUCCESS_ACTION_TYPE,
  NEW_BOOKINGS_ERROR_ACTION_TYPE,
  NEW_BOOKINGS_SAVE_CREATE_START_ACTION_TYPE,
  NEW_BOOKINGS_SAVE_CREATE_SUCCESS_ACTION_TYPE
} from "./NewBookingsActionsTypes";
import { ThunkAction } from "redux-thunk";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction }from "@spike/notifications-action";
import store from "store";
import NewBooking, { NewAppointment, NewBookingAppointment } from "model/NewBooking";
import { NewBookingsState } from "reducers/newBookings/NewBookingsState";
import { alertErrorWihtoutFieldHandler } from "@spike/notifications-action";
import { bookedNotification, rescheduledNotification }from "@spike/notifications-action";
import { BookingDto, convertToBooking } from "@spike/bookings-action";
import { NotificationsState } from "@spike/notifications-action";
import { AxiosResponse } from "axios";
import { showError }from "@spike/notifications-action";
import { showSuccess }from "@spike/notifications-action";
import { Booking } from "@spike/booking-model";
import { status as appointmentStatus } from "@spike/appointment-model";
import { NewAppointmentDto, SaveRecurringBookingDto } from "./NewBookingsDtos";
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from "constants/index";

const bookingUrl = "/booking";
const bookingsUrl = "/bookings";
const appointmentsUrl = "/appointments";
const recurring = "/recurring_appointment";

export const saveNewBookingThunk = (
  newBooking: NewBooking
): ThunkAction<void, NewBookingsState | NotificationsState, null, NewBookingsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const masterData = store.getState().masterData.data;
    const timeZone = store.getState().marketplace.marketplace.schedule.timeZone;

    if (!newBooking.reschedule) {

      const bookings = {
        marketplace_id: store.getState().login.auth.marketplaceId,
        uuid: newBooking.uuid,
        customer_id: newBooking.customer_id,
        staff_id: newBooking.staff_id,
        appointments_attributes: newBooking.appointments_attributes,
      };
  

      const url = `${bookingsUrl}?marketplace_id=${marketplaceId}`;
      try {
        const response: AxiosResponse<BookingDto> = await ApiClient.post(url, bookings, createTokenConfig(store.getState().login.auth.token!));
        dispatch(saveSuccess(response.data));
        const booking = convertToBooking(response.data, masterData, timeZone);
        booking.appointments.filter(appointment => appointment.status.id === appointmentStatus.BOOKED).forEach(appointment => dispatch(bookedNotification(appointment)));
        amplitude.track(AMPLITUDE.LOAD_APPOINTMENT_BOOKED);
      } catch (apiError) {
        if (!isCancelled(apiError)) {
          amplitude.track(AMPLITUDE.LOAD_APPOINTMENT_NOT_BOOKED_ERROR);
          dispatch(error());
          alertErrorWihtoutFieldHandler(apiError, dispatch, "Error saving new booking.");
        }
      }
    } else {
      const bookingID = newBooking.booking_id;
      const url = `${bookingsUrl}/${bookingID}?marketplace_id=${marketplaceId}`;

      const bookings = {
        marketplace_id: store.getState().login.auth.marketplaceId,
        customer_id: newBooking.customer_id,
        staff_id: newBooking.staff_id,
        appointments_attributes: newBooking.appointments_attributes,
      };

      try {
        const response: AxiosResponse<BookingDto> = await ApiClient.patch(url, bookings, createTokenConfig(store.getState().login.auth.token!));
        dispatch(saveSuccess(response.data));
        const booking = convertToBooking(response.data, masterData, timeZone);
        booking.appointments.forEach(appointment => dispatch(rescheduledNotification(appointment)));
      } catch (apiError) {
        if (!isCancelled(apiError)) {
          dispatch(error());
          alertErrorWihtoutFieldHandler(apiError, dispatch, "Error updating booking.");
        }
      }
    }
  };
};

export const saveNewBookingDepositThunk = (
  newBooking: NewBooking
): ThunkAction<void, NewBookingsState | NotificationsState, null, NewBookingsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveDepositStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const masterData = store.getState().masterData.data;
    const timeZone = store.getState().marketplace.marketplace.schedule.timeZone;

    const bookings = {
      marketplace_id: store.getState().login.auth.marketplaceId,
      uuid: newBooking.uuid,
      customer_id: newBooking.customer_id,
      staff_id: newBooking.staff_id,
      appointments_attributes: newBooking.appointments_attributes,
    };

    if (!newBooking.reschedule) {
      const url = `${bookingUrl}/create_for_deposits?marketplace_id=${marketplaceId}`;
      try {
        const response: AxiosResponse<BookingDto> = await ApiClient.post(url, bookings, createTokenConfig(store.getState().login.auth.token!));
        const booking = convertToBooking(response.data, masterData, timeZone);
        dispatch(saveDepositSuccess(booking));
      } catch (apiError) {
        if (!isCancelled(apiError)) {
          dispatch(error());
          alertErrorWihtoutFieldHandler(apiError, dispatch, "Error saving new booking.");
        }
      }
    } else {
      const bookingID = newBooking.booking_id;
      const url = `${bookingsUrl}/${bookingID}?marketplace_id=${marketplaceId}`;
      try {
        const response: AxiosResponse<BookingDto> = await ApiClient.patch(url, bookings, createTokenConfig(store.getState().login.auth.token!));
        const booking = convertToBooking(response.data, masterData, timeZone);
        dispatch(saveDepositSuccess(booking));
      } catch (apiError) {
        if (!isCancelled(apiError)) {
          dispatch(error());
          alertErrorWihtoutFieldHandler(apiError, dispatch, "Error updating booking.");
        }
      }
    }
  };
};

export const saveNewBookingRecurringThunk = (
  NewBookingAppointment: NewBookingAppointment
): ThunkAction<void, NewBookingsState | NotificationsState, null, NewBookingsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const masterData = store.getState().masterData.data;
    const timeZone = store.getState().marketplace.marketplace.schedule.timeZone;

    const newBookingAppointment = {
      marketplace_id: store.getState().login.auth.marketplaceId,
      schedule_frequency_id: NewBookingAppointment.schedule_frecuency_id?.id,
      schedule_duration_id: NewBookingAppointment.schedule_duration_id?.id,
      appointment: {
        address_id: NewBookingAppointment.address_id,
        customer_id: NewBookingAppointment.customer_id,
        pet_id: NewBookingAppointment.pet_id,
        staff_id: NewBookingAppointment.staff_id,
        service_id: NewBookingAppointment.service_id,
        booked_at: NewBookingAppointment.booked_at,
        created_by: NewBookingAppointment.created_by,
        notes: NewBookingAppointment.notes
      }
    };

    const url = `${recurring}?marketplace_id=${marketplaceId}`;
    try {
      const response: AxiosResponse<SaveRecurringBookingDto> = await ApiClient.post(url, newBookingAppointment, createTokenConfig(store.getState().login.auth.token!));
      dispatch(saveSuccess(response.data.booking));
      const booking = convertToBooking(response.data.booking, masterData, timeZone);
      booking.appointments.forEach(appointment => dispatch(bookedNotification(appointment)));
      amplitude.track(AMPLITUDE.LOAD_APPOINTMENT_BOOKED);

    } catch (apiError) {
      if (!isCancelled(apiError)) {
        amplitude.track(AMPLITUDE.LOAD_APPOINTMENT_NOT_BOOKED_ERROR);
        dispatch(error());
        alertErrorWihtoutFieldHandler(apiError, dispatch, "Error saving new booking.");
      }
    }
  };
};

export const updateBookingThunk = (
  newBooking: NewBooking
): ThunkAction<void, NewBookingsState | NotificationsState, null, NewBookingsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;

    const bookings = {
      marketplace_id: store.getState().login.auth.marketplaceId,
      uuid: newBooking.uuid,
      customer_id: newBooking.customer_id,
      staff_id: newBooking.staff_id,
      appointments_attributes: newBooking.appointments_attributes,
    };

    const bookingID = newBooking.booking_id;
    const url = `${bookingsUrl}/${bookingID}?marketplace_id=${marketplaceId}`;
    try {
      const response: AxiosResponse<BookingDto> = await ApiClient.patch(url, bookings, createTokenConfig(store.getState().login.auth.token!));
      dispatch(saveSuccess(response.data));
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        alertErrorWihtoutFieldHandler(apiError, dispatch, "Error updating booking.");
      }
    }
  };
};

export const saveCreateAppointmentThunk = (
  newAppointment: NewAppointment,
): ThunkAction<void, NewBookingsState | NotificationsState, null, NewBookingsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveCreateStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${appointmentsUrl}?marketplace_id=${marketplaceId}`;
    const body = {
      appointment: newAppointment
    };

    try {
      const response: AxiosResponse<NewAppointmentDto> = await ApiClient.post(url, body, createTokenConfig(store.getState().login.auth.token!));
      dispatch(saveCreateSuccess(response.data));
      dispatch(showSuccess("Create appointment service successfully"));

    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error creating appointment service."));
      }
    }
  };
};

const saveDepositStart = (): NewBookingsAction => {
  return { type: NEW_BOOKINGS_DEPOSIT_SAVE_START_ACTION_TYPE };
};

const saveStart = (): NewBookingsAction => {
  return { type: NEW_BOOKINGS_SAVE_START_ACTION_TYPE };
};


export const saveSuccess = (booking: BookingDto): NewBookingsAction => {
  return {
    type: NEW_BOOKINGS_SAVE_SUCCESS_ACTION_TYPE,
  };
};

export const saveDepositSuccess = (booking: Booking): NewBookingsAction => {
  return {
    type: NEW_BOOKINGS_DEPOSIT_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      booking: booking,
    },
  };
};


const saveCreateStart = (): NewBookingsAction => {
  return {
    type: NEW_BOOKINGS_SAVE_CREATE_START_ACTION_TYPE,
  };
};

export const saveCreateSuccess = (newAppointmentDto: NewAppointmentDto): NewBookingsAction => {
  return {
    type: NEW_BOOKINGS_SAVE_CREATE_SUCCESS_ACTION_TYPE,
    payload: {
      newAppointment: newAppointmentDto,
    },
  };
};

const error = (): NewBookingsAction => {
  return {
    type: NEW_BOOKINGS_ERROR_ACTION_TYPE,
  };
};
