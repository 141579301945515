import React, { FunctionComponent, Fragment, MouseEvent, ChangeEvent, useState, useEffect } from "react";
import { Avatar, Box, Input, Typography, ClickAwayListener, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import clsx from "clsx";
import { AvailableStaff } from "@spike/available-staff-model";

interface Props {
  selected?: AvailableStaff;
  staff: Array<AvailableStaff>;
  onSelect: (id: number) => void;
  onClickAway?: () => void;
  className?: string;
  loading?: boolean;
  unassignable?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      minWidth: "100%",
    },
    dropdownContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      border: "1px solid #000000",
      backgroundColor: "white",
      minWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "7px",
        paddingTop: "2px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "11px",
        paddingTop: "4px",
        paddingBottom: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "15px",
        paddingTop: "6px",
        paddingBottom: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        borderRadius: "22px",
        paddingTop: "8px",
        paddingBottom: "26px",
      },
    },
    searchBoxContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "13px",
        paddingRight: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "17px",
        paddingRight: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "26px",
        paddingRight: "26px",
      },
    },
    listContainer: {},
    elementContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "8px",
        paddingRight: "15px",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "13px",
        paddingRight: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "17px",
        paddingRight: "15px",
        paddingTop: "6px",
        paddingBottom: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "26px",
        paddingRight: "26px",
        paddingTop: "11px",
        paddingBottom: "11px",
      },
      "&:hover": {
        backgroundColor: "#F4F4F4",
      },
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "18px",
        height: "18px",
        marginRight: "5px",
      },
      [theme.breakpoints.only("md")]: {
        width: "18px",
        height: "18px",
        marginRight: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "20px",
        height: "20px",
        marginRight: "7px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "20px",
        height: "20px",
        marginRight: "8px",
      },
    },
    text: {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "16px",
      },
    },
    center: {
      justifyContent: "center",
    },
    selectedAvatar: {
      opacity: "0.2",
    },
  })
);

export const unassignedId = -1;

const SearchDropDown: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const unassigned = { id: unassignedId, firstName: "Unassigned", lastName: "", avatar: "" };

  const [searchText, setSearchText] = useState("");
  const [filteredStaff, setFilteredStaff] = useState(props.staff);

  const match = (member: AvailableStaff, text: string): boolean => {
    const separator = " ";
    const textTokens: Array<string> = text.split(separator);

    return !textTokens
      .map((token) => token.toLowerCase())
      .some(
        (textToken) =>
          !member.firstName.toLocaleLowerCase().includes(textToken) &&
          !member.lastName.toLocaleLowerCase().includes(textToken)
      );
  };

  const addUnassigned = (staff: Array<AvailableStaff>, unassignable: boolean, selected: AvailableStaff | undefined) => {
    if (unassignable && selected !== undefined) {
      return [unassigned, ...staff];
    } else {
      return [...staff];
    }
  };

  const sort = (staff: Array<AvailableStaff>) => {
    return staff.sort(compareStaff);
  };

  const compareStaff = (member: AvailableStaff, otherMember: AvailableStaff) => {
    if (member.id === unassignedId) {
      return -1;
    } else if (otherMember.id === unassignedId) {
      return 1;
    } else if (member.firstName.toLocaleLowerCase() > otherMember.firstName.toLocaleLowerCase()) {
      return 1;
    } else if (member.firstName.toLocaleLowerCase() < otherMember.firstName.toLocaleLowerCase()) {
      return -1;
    } else if (member.lastName.toLocaleLowerCase() > otherMember.lastName.toLocaleLowerCase()) {
      return 1;
    } else if (member.lastName.toLocaleLowerCase() < otherMember.lastName.toLocaleLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  };

  const search = (text: string) => {
    setFilteredStaff(
      addUnassigned(
        sort(props.staff.filter((member) => match(member, text))),
        props.unassignable || false,
        props.selected
      )
    );
  };

  useEffect(() => {
    search(searchText);
  }, [props.staff]);

  useEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      search(searchText);
    }, 100);
    return () => clearTimeout(timer);
  }, [searchText]);

  const clickAwayHandler = (event: MouseEvent<Document>) => {
    props.onClickAway && props.onClickAway();
  };

  const clickHandler = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const searchAvatar = <Avatar className={clsx(classes.avatar, classes.selectedAvatar)} src={props.selected?.avatar} />;

  const searchBox = (
    <Box className={classes.searchBoxContainer}>
      <Input
        className={classes.text}
        placeholder={props.selected?.firstName || "Name or email"}
        startAdornment={searchAvatar}
        disableUnderline={true}
        onChange={inputChangeHandler}
        autoFocus={true}
      />
    </Box>
  );

  const elements = (
    <Fragment>
      {filteredStaff.map((member) => (
        <Box
          className={classes.elementContainer}
          key={member.id}
          onClick={() => {
            props.onSelect(member.id);
          }}
        >
          <Avatar className={classes.avatar} src={member.avatar} />
          <Typography className={classes.text}>{member.firstName}</Typography>
        </Box>
      ))}
    </Fragment>
  );

  const elementsNotFound = (
    <Box className={classes.elementContainer}>
      <Typography className={classes.text}>Staff not found.</Typography>
    </Box>
  );

  const spinner = (
    <Box className={clsx(classes.elementContainer, classes.center)}>
      <CircularProgress size={20} />
    </Box>
  );

  const list = (
    <OverlayScrollbarsComponent style={{ maxHeight: "130px" }}>
      <Box className={classes.listContainer}>
        {filteredStaff.length > 0 && !props.loading && elements}
        {filteredStaff.length === 0 && !props.loading && elementsNotFound}
        {props.loading && spinner}
      </Box>
    </OverlayScrollbarsComponent>
  );

  const dropDown = (
    <Box className={classes.dropdownContainer}>
      {searchBox}
      {list}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Box className={clsx(classes.container, props.className)} onClick={clickHandler}>
        {dropDown}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchDropDown;
