import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChipMUI from '@material-ui/core/Chip';
import clsx from 'clsx';
import { PetType } from '@spike/model';

interface Props {
	petType: PetType;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chip: {
			borderColor: 'transparent',
			cursor: 'pointer',
			[theme.breakpoints.down('sm')]: {
				width: '50px',
				height: '22px',
				fontSize: '12px'
			},
			[theme.breakpoints.only('md')]: {
				width: '54px',
				height: '21px',
				fontSize: '12px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '52px',
				height: '23px',
				fontSize: '12px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '58px',
				height: '26px',
				fontSize: '14px'
			}
		},
		dog: {
			'color': '#5E8677',
			'backgroundColor': '#E9F0ED',
			'&:hover': {
				backgroundColor: '#E9F0ED !important'
			}
		},
		cat: {
			'color': '#C18733',
			'backgroundColor': '#FAEFDF',
			'&:hover': {
				backgroundColor: '#FAEFDF !important'
			}
		},
		exotic: {
			'color': '#9C8267',
			'backgroundColor': '#EBE7E3',
			'&:hover': {
				backgroundColor: '#E9F0ED !important'
			}
		}
	})
);

export const PetTypeComponent: FunctionComponent<Props> = props => {
	const classes = useStyles();
	return (
		<ChipMUI
			label={props.petType.singular}
			className={clsx(
				classes.chip,
				{
					[classes.dog]: props.petType.id === 'dogs',
					[classes.cat]: props.petType.id === 'cats',
					[classes.exotic]: props.petType.id === 'exotics'
				},
				props.className
			)}
			clickable={false}
		/>
	);
};

export default PetTypeComponent;
