import { Box, FormLabel, Grid, createStyles, makeStyles } from "@material-ui/core";
import RadioOption from "./RadioOption";
import { faEnvelope, faSms } from "@fortawesome/pro-regular-svg-icons";

import Client from "@spike/client-model";

const useStyles = makeStyles(() =>
  createStyles({
    group: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      color: '#222',
      margin: '23px 0'
    },
    radioOption: {
      width: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      borderRadius: 12,
      textAlign: "center",
      padding: "17px 0px",
      backgroundColor: "#fff",
      border: "solid 2px #D3D3D3",
      transition: "border-color 0.1s ease-out",
      "&.active": {
        borderColor: "#222",
      },
      "&.disabled": {
        opacity: 0.4,
        pointerEvents: "none",
        "& svg": {
          color: "#D3D3D3",
        },
      },
      "& svg": {
        color: "#5E8677",
        fontSize: 30,
        marginBottom: 4,
      },
    },
    radioOptionInput: {
      top: 9,
      right: 11,
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      position: "absolute",
      border: "2px solid #E5E5E5",
      "& svg": {
        margin: 0,
        marginTop: 1,
        fontSize: 13,
        color: "#fff",
        display: "none",
      },
      "&.active": {
        borderColor: "#5E8677",
        backgroundColor: "#5E8677",
        "& svg": {
          display: "block",
        },
      },
    }
  }))

const Channel = ({
  clientInfo,
  setChannel,
  channel,
}: {
  clientInfo?: Client;
  setChannel: (value: string) => void;
  channel: string;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.group}>
      <FormLabel className={classes.title}>Channel</FormLabel>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <RadioOption
            label="Email"
            value="email"
            icon={faEnvelope}
            clientInfo={clientInfo}
            setChannel={setChannel}
            channel={channel}
          />
        </Grid>
        <Grid item xs={6}>
          <RadioOption
            label="SMS"
            value="sms"
            icon={faSms}
            clientInfo={clientInfo}
            setChannel={setChannel}
            channel={channel}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Channel