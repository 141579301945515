import MyAccountComponent from "components/MyAccount/MyAccount";
import React, { FunctionComponent } from "react";
import PrivatePage from "./PrivatePage";

export const MyAccount: FunctionComponent = () => {
  return (
    <PrivatePage title="My Account - Cuddles" pageName="/my_account">
      {" "}
      <MyAccountComponent />
    </PrivatePage>
  );
};

export default MyAccount;
