import { DentalReport } from "model/DentalReport";
import { GroomingReport } from "model/GroomerReport";

export interface ReportsState {
  dentalReport?: DentalReport;
  groomingReport?: GroomingReport;
  status: ReportsStatus;
  loading: boolean;
  uuidToId: Map<string, number>;
}

export enum ReportsStatus {
  Initial,
  Cleanup,
  SavingDental,
  SaveDentalSuccess,
  SavingGrooming,
  SaveGroomingSuccess,
  GettingDentalByAppointment,
  GetDentalByAppointmentSuccess,
  GettingGroomingByAppointment,
  GetGroomingByAppointmentSuccess,
  ResendingReport,
  ResendReportSuccess,
  Error,
  GettingDentalPDFByAppointment,
  GetDentalPDFByAppointmentSuccess,
  GettingGroomingPDFByAppointment,
  GetGroomingPDFByAppointmentSuccess,
  ReportSkipped,
} 
