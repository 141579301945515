import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faEye, faPen } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { wbp } from 'Theme';
import clsx from 'clsx';
import { useHasPremiumPlan } from 'hooks';
import useMarketplace from 'hooks/useMarketplace';
import { FunctionComponent } from 'react';

export interface Props {
    title: string;
    className?: string;
    subtitle: string;
    headerIcon: IconDefinition;
    optionId: string;
    showEmail: boolean;
    showSms: boolean;
    onEdit?: () => void;
    onPreview?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingContainer: {
            border: '#DDDDDD 1px solid',
            borderRadius: '18px',
            paddingTop: '20px',
            paddingBottom: '12.5px'
        },
        headerContainer: {
            paddingLeft: '20px',
            paddingRight: '20px',
            display: 'flex',
            gap: '30px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '5px',
                paddingRight: '5px',
                gap: '35px'
            }
        },
        footerContainer: {
            borderTop: '#DDDDDD 1px solid',
            width: '100%',
            display: 'flex',
            paddingTop: '10px'
        },
        leftColumnHeader: {
            width: '5%'
        },
        rightColumnHeader: {
            width: '95%'
        },
        headerTitle: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '100%'
        },
        headerSubTitle: {
            marginTop: '10px',
            marginBottom: '20px',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '140%'
        },
        leftSideFooter: {
            width: '60%',
            display: 'flex',
            gap: '20px',
            justifyContent: 'flex-start',
            marginLeft: '20px'
        },
        rightSideFooter: {
            width: '40%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '20px',
            gap: '20px'
        },
        checkContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        actionText: {
            textDecoration: 'underline',
            marginLeft: '5px',
            fontSize: '14px',
            lineHeight: '27px',
            fontWeight: 500,
            cursor: 'pointer'
        },
        actionIcon: {
            marginLeft: '5px',
            fontSize: '14px',
            lineHeight: '27px',
            fontWeight: 500,
            cursor: 'pointer'
        },
        headerIconContainer: {
            backgroundColor: '#E4DDD5',
            borderRadius: '50px',
            width: '44px',
            height: '44px',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center'
        },
        headerIcon: {
            fontSize: '22px',
            fontWeight: 300
        },
        checkIcon: {
            color: '#5E8677',
            fontSize: '16px',
            fontWeight: 900,
            lineHeight: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        checkIconDisabled: {
            color: '#D4D4D4 !important'
        },
        checkText: {
            fontSize: '14px',
            fontWeight: 500
        },
        checkTextDisabled: {
            color: '#D4D4D4 !important'
        }
    })
);

export const SettingComponent: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const marketplace = useMarketplace();
    const settings = marketplace.notifications.settings;
    const hasPremiumPlan = useHasPremiumPlan();
    const clientNotificationsSetting = settings.find(
        setting => setting.option.id == `client_notifications`
    );
    const emailSetting = settings.find(
        setting => setting.option.id == `${props.optionId}_email`
    );
    const smsSetting = settings.find(
        setting => setting.option.id == `${props.optionId}_sms`
    );

    const emailActive = emailSetting ? emailSetting.active : true;
    const smsActive = smsSetting && hasPremiumPlan ? smsSetting.active : false;

    return (
        <Box className={clsx(classes.settingContainer, props.className)}>
            <Box className={classes.headerContainer}>
                <Box className={classes.leftColumnHeader}>
                    <Box className={classes.headerIconContainer}>
                        <FontAwesomeIcon
                            icon={props.headerIcon}
                            className={classes.headerIcon}
                        />
                    </Box>
                </Box>
                <Box className={classes.rightColumnHeader}>
                    <Box>
                        <Typography className={classes.headerTitle}>
                            {props.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.headerSubTitle}>
                            {props.subtitle}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.footerContainer}>
                <Box className={classes.leftSideFooter}>
                    {props.showEmail && (
                        <Box className={classes.checkContainer}>
                            <FontAwesomeIcon
                                icon={
                                    emailActive &&
                                    clientNotificationsSetting &&
                                    clientNotificationsSetting.active
                                        ? faCheckCircle
                                        : faCircleXmark
                                }
                                className={
                                    emailActive &&
                                    clientNotificationsSetting &&
                                    clientNotificationsSetting.active
                                        ? classes.checkIcon
                                        : clsx(
                                              classes.checkIcon,
                                              classes.checkIconDisabled
                                          )
                                }
                            />
                            <Typography
                                className={
                                    emailActive &&
                                    clientNotificationsSetting &&
                                    clientNotificationsSetting.active
                                        ? classes.checkText
                                        : clsx(
                                              classes.checkText,
                                              classes.checkTextDisabled
                                          )
                                }
                            >
                                Email
                            </Typography>
                        </Box>
                    )}
                    {props.showSms && (
                        <Box className={classes.checkContainer}>
                            <FontAwesomeIcon
                                icon={
                                    smsActive &&
                                    clientNotificationsSetting &&
                                    clientNotificationsSetting.active
                                        ? faCheckCircle
                                        : faCircleXmark
                                }
                                className={
                                    smsActive &&
                                    clientNotificationsSetting &&
                                    clientNotificationsSetting.active
                                        ? classes.checkIcon
                                        : clsx(
                                              classes.checkIcon,
                                              classes.checkIconDisabled
                                          )
                                }
                            />
                            <Typography
                                className={
                                    smsActive &&
                                    clientNotificationsSetting &&
                                    clientNotificationsSetting.active
                                        ? classes.checkText
                                        : clsx(
                                              classes.checkText,
                                              classes.checkTextDisabled
                                          )
                                }
                            >
                                SMS
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box className={classes.rightSideFooter}>
                    {props.onEdit &&
                        clientNotificationsSetting &&
                        clientNotificationsSetting.active && (
                            <Box
                                className={classes.actionContainer}
                                onClick={() => props.onEdit && props.onEdit()}
                            >
                                <FontAwesomeIcon
                                    className={classes.actionIcon}
                                    icon={faPen}
                                />
                                <Typography className={classes.actionText}>
                                    Edit
                                </Typography>
                            </Box>
                        )}

                    {props.onPreview &&
                        clientNotificationsSetting &&
                        clientNotificationsSetting.active && (
                            <Box
                                className={classes.actionContainer}
                                onClick={() =>
                                    props.onPreview && props.onPreview()
                                }
                            >
                                <FontAwesomeIcon
                                    className={classes.actionIcon}
                                    icon={faEye}
                                />
                                <Typography className={classes.actionText}>
                                    Preview
                                </Typography>
                            </Box>
                        )}
                </Box>
            </Box>
        </Box>
    );
};

export default SettingComponent;
