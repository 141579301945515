import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchAgreementsThunk } from 'actions/agreements/AgreementsActions';
import { OverPage, Spinner } from 'components/UI';
import { useMarketplace } from 'hooks';
import { Agreement, createEmptyAgreement } from 'model/Agreements';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import { AgreementsEditModal } from './AgreementsEditModal';
import AgreementsHeader from './AgreementsHeader';
import AgreementsTableFilters from './Table/AgreementsTableFilters';
import AgreementsNotFound from './ui/NotFound';
import AgreementSignatures from './AgreementSignatures';
import Search from './ui/Search';
import AgreementsTable from './Table/AgreementsTable';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			transition: 'all 0.33s linear',
			maxHeight: 'calc(100dvh - 49px - 40px)',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: '#fafafa',
			borderTop: '2px solid #F1F1F1',
			paddingTop: '55px',
			[theme.breakpoints.down('md')]: {
				paddingTop: 17
			},
			[theme.breakpoints.down('sm')]: {
				background: '#ffffff'
			}
		},
		paddingWrapper: {
			paddingLeft: 24,
			paddingRight: 24,
			marginBottom: 34,
			gap: 20,
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down(768)]: {
				paddingLeft: 16,
				paddingRight: 16,
				marginBottom: 34
			},
			[theme.breakpoints.between(768, 'sm')]: {
				paddingLeft: 16,
				paddingRight: 16,
				marginBottom: 34
			}
		},
		overPage: {
			zIndex: 4
		},
		zIndex: {
			zIndex: 0
		}
	})
);

const matchSearchTokens = (
	agreement: Agreement,
	searchTokens: Array<string>
) => {
	return (
		searchTokens.length === 0 ||
		searchTokens.every(
			token => agreement.title.toLowerCase().indexOf(token) > -1
		)
	);
};

export const Agreements: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const marketplace = useMarketplace();
	const [detailAgreementId, setDetailAgreementId] = useState<number | null>(
		null
	);

	const agreementsStatus = useSelector<RootState, AgreementsStatus>(
		state => state.agreements.status
	);
	const agreements = useSelector<RootState, Array<Agreement>>(
		state => state.agreements.agreements
	);

	const [loading, setLoading] = useState(true);
	const [searchTokens, setSearchTokens] = useState<Array<string>>([]);
	const [active, setActive] = useState(true);
	const [editAgreement, setEditAgreement] = useState<Agreement | null>(null);

	const filteredAgreements = agreements
		.filter(agreement => agreement.active === active)
		.filter(agreement => matchSearchTokens(agreement, searchTokens));

	useEffect(() => {
		dispatch(fetchAgreementsThunk());
	}, []);

	useNonInitialEffect(() => {
		switch (agreementsStatus) {
			case AgreementsStatus.FetchSuccess:
				setLoading(false);
				break;
			case AgreementsStatus.Error:
				setLoading(false);
				break;
		}
	}, [agreementsStatus]);

	const newHandler = () => {
		setEditAgreement(createEmptyAgreement(marketplace.id!));
	};

	return (
		<>
			<Box className={classes.container}>
				<Box className={classes.paddingWrapper}>
					<AgreementsHeader
						onClick={
							!loading && agreements.length > 0
								? newHandler
								: undefined
						}
						showButton={agreements.length !== 0}
						buttonLabel="Add Agreement"
						title="Agreements"
					/>
				</Box>
				{loading && <Spinner />}
				{!loading && agreements.length === 0 && (
					<AgreementsNotFound
						buttonAction={newHandler}
						buttonLabel="New Agreement"
						subtitle="Click the “New Agreement” button to add one"
						title="Create a new agreement"
					/>
				)}
				{!loading && agreements.length > 0 && (
					<>
						<Box
							className={clsx([
								classes.paddingWrapper,
								classes.zIndex
							])}
						>
							<Search
								onSearch={text =>
									setSearchTokens(
										text
											.split(' ')
											.filter(token => token.length > 0)
											.map(token => token.toLowerCase())
									)
								}
							/>
							<AgreementsTableFilters
								onSelect={setActive}
								selectedTotalRecords={filteredAgreements.length}
							/>
						</Box>
						<AgreementsTable
							filteredAgreements={filteredAgreements}
							onSelectAgreement={id => setDetailAgreementId(id)}
						/>
					</>
				)}
				{
					<AgreementsEditModal
						isActive={editAgreement !== null}
						agreement={editAgreement!}
						onClose={() => setEditAgreement(null)}
					/>
				}
				{detailAgreementId !== null && (
					<OverPage className={classes.overPage}>
						<AgreementSignatures
							agreementId={detailAgreementId}
							onBack={() => setDetailAgreementId(null)}
						/>
					</OverPage>
				)}
			</Box>
		</>
	);
};

export default Agreements;
