import {
	Box,
	InputAdornment,
	InputBaseComponentProps,
	TextField as MUITextField
} from '@material-ui/core';
import { FieldError } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import React, {
	ChangeEvent,
	ClipboardEvent,
	KeyboardEvent,
	useState
} from 'react';
import { useFieldStyles } from './FieldStyles';
import LabelField from './LabelField';

export interface TextField2Props {
	name?: string;
	label?: string | JSX.Element;
	placeholder?: string;
	value: string;
	required?: boolean;
	errors?: Array<FieldError>;
	onBlur?: () => void;
	onChange: (value: string, name: string) => void;
	onFocus?: () => void;
	className?: string;
	autoFocus?: boolean;
	autoComplete?: 'off' | 'on';
	endIcon?: JSX.Element;
	startIcon?: JSX.Element;
	multiline?: boolean;
	onEnterPressed?: () => void;
	disabled?: boolean;
	capitalize?: boolean;
	upperCase?: boolean;
	maxLength?: number;
	information?: string;
	id?: string;
}

export const TextField2 = React.forwardRef<HTMLInputElement, TextField2Props>(
	(props, ref) => {
		const classes = useFieldStyles();

		const [text, setText] = useState(props.value);

		const hasError = props.errors?.some(
			error => error.fieldName === props.name
		);

		useNonInitialEffect(() => {
			const timer: NodeJS.Timer = setTimeout(() => {
				props.onChange(text, props.name ?? '');
			}, 200);
			return () => clearTimeout(timer);
		}, [text]);

		const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
			const newValue = event.target.value;
			const isLastPositionASpace = newValue?.endsWith(' ');

			if (!isLastPositionASpace && props.capitalize) {
				setText(startCase(newValue.toLowerCase()));
			} else if (!isLastPositionASpace && props.upperCase) {
				setText(newValue?.toUpperCase());
			} else {
				setText(newValue);
			}
		};

		const onPasteHandler = (
			event: ClipboardEvent<HTMLInputElement>
		): void => {
			const newValue = event.clipboardData.getData('text');

			const isLastPositionASpace = newValue?.endsWith(' ');

			if (!isLastPositionASpace && props.capitalize) {
				setText(startCase(newValue.toLowerCase()));
			} else if (!isLastPositionASpace && props.upperCase) {
				setText(newValue?.toUpperCase());
			} else {
				setText(newValue);
			}

			props.onChange(text, props.name ?? '');
		};

		const onFocusHandler = (): void => {
			props.onFocus && props.onFocus();
		};

		const onBlurHandler = (): void => {
			props.onBlur && props.onBlur();
		};

		const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
			event.key === 'Enter' &&
				props.onEnterPressed &&
				props.onEnterPressed();
		};

		let inputProps = {};

		if (props.endIcon) {
			inputProps = {
				...inputProps,
				endAdornment: (
					<InputAdornment position="end">
						{props.endIcon}
					</InputAdornment>
				)
			};
		}

		if (props.startIcon) {
			inputProps = {
				...inputProps,
				startAdornment: (
					<InputAdornment position="start">
						{props.startIcon}
					</InputAdornment>
				)
			};
		}

		let properties: InputBaseComponentProps | undefined = undefined;
		if (props.capitalize) {
			properties = { style: { textTransform: 'capitalize' } };
		} else if (props.upperCase) {
			properties = { style: { textTransform: 'uppercase' } };
		}

		if (props.maxLength) {
			properties = { ...properties, maxLength: props.maxLength };
		}

		return (
			<Box className={clsx(classes.container, props.className)}>
				<LabelField {...props} />
				<Box>
					<MUITextField
						inputRef={ref}
						id={props.id || ''}
						name={props.name}
						placeholder={props.placeholder}
						variant="outlined"
						defaultValue={props.value}
						onBlur={onBlurHandler}
						onChange={changeHandler}
						onFocus={onFocusHandler}
						onPaste={onPasteHandler}
						autoFocus={props.autoFocus}
						autoComplete={'nope'}
						className={clsx(classes.field, {
							[classes.fieldWithStartAdornment]: props.startIcon,
							[classes.fieldWithEndAdornment]: props.endIcon,
							[classes.emptyField]: text.length === 0,
							[classes.disabledField]: props.disabled,
							[classes.fieldError]: hasError
						})}
						error={hasError}
						InputProps={inputProps}
						multiline={props.multiline}
						onKeyDown={keyDownHandler}
						disabled={props.disabled}
						inputProps={properties}
					/>
				</Box>
			</Box>
		);
	}
);
TextField2.displayName = 'TextField2';

export default TextField2;
