import React, { FunctionComponent } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { capitalize } from "lodash";

interface Props {
  optionText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        margin: "12px 15px 12px 12px",
      },
      [theme.breakpoints.up("xl")]: {
        margin: "16px 16px 16px 16px",
      },
    },
    optionText: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
    },
  })
);

export const DropdownOption: FunctionComponent<Props> = ({
  optionText
}) => {
  const classes = useStyles();
  
  return (
    <Box className={classes.optionContainer}>
      <Typography className={classes.optionText}>{capitalize(optionText)}</Typography>
    </Box>
  );
};
