import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import { FieldError, SpecialHour } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import SpecialHourComponent from './SpecialHour';
import cloneDeep from 'lodash/cloneDeep';
import { SpecialHourForm } from './SpecialHourForm';

export interface SpecialHoursProps {
	specialHours: Array<SpecialHour>;
	errors?: Array<FieldError>;
	onChange?: (specialHours: Array<SpecialHour>) => void;
}

export interface SpecialHourWrapper {
	specialHour: SpecialHour;
	error: boolean;
	errorMessage?: string;
}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column'
		},
		header: {
			[theme.breakpoints.down('sm')]: {
				marginBottom: 10
			}
		},
		titleContainer: {
			'width': '100%',
			'display': 'flex',
			'alignItems': 'center',
			'justifyContent': 'space-between',

			[theme.breakpoints.down('sm')]: {
				marginBottom: 8
			},

			'& button': {
				fontWeight: 500,
				display: 'inline-block',
				borderBottom: 'solid 1px #92B4A7',

				[theme.breakpoints.up('md')]: {
					display: 'none'
				}
			}
		},
		title: {
			fontSize: 16,
			fontWeight: 600,
		},
		subTitle: {
			fontSize: 16,
			fontWeight: 400,
			marginRight: '12px',

			[theme.breakpoints.down('sm')]: {
				fontSize: 14
			}
		},
		addDate: {
			all: 'unset',
			fontSize: 14,
			color: '#92B4A7',
			cursor: 'pointer',
			display: 'none',

			[theme.breakpoints.up('md')]: {
				fontSize: 16,
				marginTop: 30,
				display: 'block'
			}
		},
		error: {
			borderColor: '#EA6464',
			color: '#EA6464'
		}
	})
);

const hasError = (
	specialHour: SpecialHour,
	errors?: Array<FieldError>
): string | undefined => {
	return errors?.find(error => error.fieldName === specialHour.uuid)
		?.errorMessage;
};

const AddDateButton: FunctionComponent<{
	className: string;
	onClick: () => void;
}> = ({ className, onClick }) => {
	return (
		<button
			id={'business_settings_schedule_text_new_date_special_hours'}
			className={className}
			onClick={onClick}
		>
			+ Add Special Hour
		</button>
	);
};

export const SpecialHours: FunctionComponent<SpecialHoursProps> = props => {
	const classes = useStyles();

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	const [addNew, setAddNew] = useState<SpecialHourWrapper | null>(null);

	const [wrappers, setWrappers] = useState<Array<SpecialHourWrapper>>(
		props.specialHours.map((specialHour) => {
			const errorMessage = hasError(specialHour, props.errors);
			return {
				specialHour,
				uuid: uuid(),
				error: errorMessage !== undefined,
				errorMessage
			};
		})
	);

	useNonInitialEffect(() => {
		setWrappers(prev =>
			prev.map(wrapper => {
				const errorMessage = hasError(
					wrapper.specialHour,
					props.errors
				);
				return {
					...wrapper,
					errorMessage,
					error: errorMessage !== undefined
				};
			})
		);
	}, [props.errors]);

	const changeHandler = (updatedWrapper: SpecialHourWrapper) => {
		props.onChange &&
			props.onChange(
				wrappers.map(wrapper =>
					wrapper.specialHour.uuid === updatedWrapper.specialHour.uuid
						? cloneDeep(updatedWrapper.specialHour)
						: cloneDeep(wrapper.specialHour)
				)
			);

		setWrappers(prev =>
			prev.map(wrapper =>
				wrapper.specialHour.uuid === updatedWrapper.specialHour.uuid
					? cloneDeep(updatedWrapper)
					: wrapper
			)
		);
	};

	const deleteHandler = (deletedUuid: string) => {
		props.onChange &&
			props.onChange(
				wrappers
					.filter(wrapper => wrapper.specialHour.uuid !== deletedUuid)
					.map(wrapper => cloneDeep(wrapper.specialHour))
			);
		setWrappers(prevWrappers =>
			prevWrappers.filter(
				wrapper => wrapper.specialHour.uuid !== deletedUuid
			)
		);
	};

	const addHandler = () => {
		const newWrapper = {
			uuid: uuid(),
			error: false,
			specialHour: {
				name: isMobile ? '' : 'Description',
				date: '',
				isClosed: true,
				uuid: uuid()
			}
		};

		if (isMobile) {
			setAddNew(newWrapper);
			return;
		}

		saveHandler(newWrapper);
	};

	const saveHandler = (newWrapper: SpecialHourWrapper) => {
		props.onChange &&
			props.onChange([
				...wrappers.map(wrapper => wrapper.specialHour),
				cloneDeep(newWrapper.specialHour)
			]);
		setWrappers(prevWrappers => [...prevWrappers, newWrapper]);
		setAddNew(null);
	};

	return (
		<Box className={clsx(classes.container)}>
			<Box className={clsx(classes.header)}>
				<Box className={classes.titleContainer}>
					<Typography className={classes.title}>
						Special Hours
					</Typography>

					<AddDateButton
						className={classes.addDate}
						onClick={addHandler}
					/>
				</Box>
				<Typography className={classes.subTitle}>
					Enter hours for days where you have an irregular schedule.
				</Typography>
			</Box>

			<Box>
				{wrappers.map(wrapper => (
					<SpecialHourComponent
						key={wrapper.specialHour.uuid}
						specialHourWrapper={wrapper}
						onChange={changeHandler}
						onDelete={deleteHandler}
					/>
				))}
			</Box>

			<AddDateButton className={classes.addDate} onClick={addHandler} />

			{addNew && (
				<SpecialHourForm
					addNew
					specialHour={addNew.specialHour}
					onClose={() => setAddNew(null)}
					onSave={specialHour => {
						saveHandler({
							...addNew,
							specialHour
						});
					}}
				/>
			)}
		</Box>
	);
};

export default SpecialHours;
