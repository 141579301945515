import Box from "@material-ui/core/Box";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Address, FieldError, createEmptyAddress } from "@spike/model";
import clsx from "clsx";
import Section from "components/Products/UI/Section";
import { PhoneField3, TextField2, ZipCodeField3 } from "components/UI";
import { BodySection } from "components/UI/Section";
import { useMarketplace } from "hooks";
import ProductVendor from "model/ProductVendor";
import { FunctionComponent } from "react";

interface Props {
  vendor: ProductVendor;
  collapsed?: boolean;
  disabled?: boolean;
  editable?: boolean;
  completed?: boolean;
  modifiable?: boolean;
  errors: Array<FieldError>;
  onEdit: () => void;
  onChange: (vendor: ProductVendor) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingRight: "24px",
        paddingLeft: "24px"
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "30px",
        paddingLeft: "30px"
      },
    },
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        paddingRight: "70px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "88px",
      },
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
    nameContainer: {
      display: "flex",
      width: "100%",
    },
    contactContainer: {
      display: "flex",
      width: "100%",
     
    },
    middleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "34px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "42px",
      },
    },
    bottomContainer: {
      display: "flex",
      width: "100%",
      alignItems: "flex-end",
      [theme.breakpoints.down("md")]: {
        marginTop: "34px",
        marginBottom: "66px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "42px",
        marginBottom: "83px",
      },
    },
    marginRight: {
      [theme.breakpoints.down("md")]: {
        marginRight: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "16px",
      },
    },
  })
);

export const VendorDetails: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const marketplace = useMarketplace();

  const isAddressEmpty = (address: Address): boolean => {
    return (
      address.street.trim().length === 0 &&
      address.city.trim().length === 0 &&
      address.state.trim().length === 0 &&
      address.zipcode.trim().length === 0
    );
  };

  const nameChangeHandler = (name: string) => {
    let vendortAux = {...props.vendor};
    vendortAux.name = name;
    props.onChange(vendortAux);
  };

  const contactChangeHandler = (contact: string) => {
    let vendortAux = {...props.vendor};
    vendortAux.contact = contact;
    props.onChange(vendortAux);
  };

  const websiteChangeHandler = (website: string) => {
    let vendortAux = {...props.vendor};
    vendortAux.website = website;
    props.onChange(vendortAux);
  };

  const phoneChangeHandler = (phone: string) => {
    let vendortAux = {...props.vendor};
    vendortAux.phone = phone;
    props.onChange(vendortAux);
  };

  const emailChangeHandler = (email: string) => {
    let vendortAux = {...props.vendor};
    vendortAux.email = email;
    props.onChange(vendortAux);
  };

  const handleChangeStreet = (street: string) => {
    let vendorAux = {...props.vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress(marketplace.basics.address.country);
    vendorAux.address = address;
    vendorAux.address.street = street;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    }
    props.onChange(vendorAux);
  };

  const handleChangeCity = (city: string) => {
    let vendorAux = {...props.vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress(marketplace.basics.address.country);
    vendorAux.address = address;
    vendorAux.address.city = city;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    } 
    props.onChange(vendorAux);
  };

  const handleChangeState = (state: string) => {
    let vendorAux = {...props.vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress(marketplace.basics.address.country);
    vendorAux.address = address;
    vendorAux.address.state = state;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    } 
    props.onChange(vendorAux);
  };

  const handleChangeZipCode = (zipcode: string) => {
    let vendorAux = {...props.vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress(marketplace.basics.address.country);
    vendorAux.address = address;
    vendorAux.address.zipcode = zipcode;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    }
    props.onChange(vendorAux);
  };

  return (
    <Section title="Vendor Details" {...props} onEdit={() => props.onEdit()}>
      <BodySection>
        <Box className={classes.container}>
          <Box className={classes.leftContainer}>
            <Box className={classes.nameContainer}>
              <TextField2 
                label="Company Name"
                name="name"
                value={props.vendor.name || ""}
                placeholder={"Company Name"}
                required={true}
                onChange={nameChangeHandler}
                capitalize={true}
                errors={props.errors}
              />
            </Box>
            <Box className={classes.middleContainer}>
              <TextField2 
                label="Website"
                name="website"
                value={props.vendor.website || ""}
                placeholder={"www.example.com"}
                onChange={websiteChangeHandler}
                errors={props.errors}
              />
            </Box>
            <Box className={classes.middleContainer}>
              <TextField2 
                label="Address"
                name="street"
                value={props.vendor.address?.street ? props.vendor.address.street : ""}
                placeholder={"Address"}
                onChange={handleChangeStreet}
                capitalize={true}
                errors={props.errors}
              />
            </Box>
            <Box className={classes.bottomContainer}>
              <TextField2 
                label="City"
                name="city"
                value={props.vendor.address?.city ? props.vendor.address.city : ""}
                placeholder={"City"}
                onChange={handleChangeCity}
                capitalize={true}
                className={clsx(classes.marginRight)}
                errors={props.errors}
              />
              <TextField2 
                label="State"
                name="state"
                value={props.vendor.address?.state ? props.vendor.address.state : ""}
                placeholder={"State"}
                onChange={handleChangeState}
                capitalize={true}
                className={clsx(classes.marginRight)}
                errors={props.errors}
              />
              <ZipCodeField3
                label="Zipcode"
                name="zipcode"
                value={props.vendor.address?.zipcode ? props.vendor.address.zipcode : ""}
                placeholder={"Zipcode"}
                onChange={handleChangeZipCode}
                autoComplete={"off"}
                errors={props.errors}
              />
            </Box>
          </Box>
          <Box className={classes.rightContainer}>
            <Box className={classes.contactContainer}>
              <TextField2 
                label="Contact Name"
                name="contact"
                value={props.vendor.contact || ""}
                placeholder={"Contact Name"}
                onChange={contactChangeHandler}
                capitalize={true}
                errors={props.errors}
              />
            </Box>
            <Box className={classes.middleContainer}>
              <PhoneField3 
                label="Phone Number"
                name="phone"
                placeholder="Phone Number"
                value={props.vendor.phone || ""}
                onChange={phoneChangeHandler}
                errors={props.errors}
              />
            </Box>
            <Box className={classes.bottomContainer}>
              <TextField2 
                label="Email Address"
                name="email"
                value={props.vendor.email || ""}
                placeholder={"Email Address"}
                onChange={emailChangeHandler}
                errors={props.errors}
              />
            </Box>
          </Box>
        </Box>
      </BodySection>
    </Section>
  );
};

export default VendorDetails;
