import { Reducer } from "redux";
import { ReportsState, ReportsStatus } from "./ReportsState";
import {
  REPORTS_RESET_ACTION_TYPE,
  REPORTS_CLEANUP_DENTAL_ACTION_TYPE,
  REPORTS_SAVE_DENTAL_START_ACTION_TYPE,
  REPORTS_SAVE_DENTAL_SUCCESS_ACTION_TYPE,
  REPORTS_GET_DENTAL_BY_APPOINTMENT_START_ACTION_TYPE,
  REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
  REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START_ACTION_TYPE,
  REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
  REPORTS_SAVE_GROOMING_START_ACTION_TYPE,
  REPORTS_SAVE_GROOMING_SUCCESS_ACTION_TYPE,
  REPORTS_GET_GROOMING_BY_APPOINTMENT_START_ACTION_TYPE,
  REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
  REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START_ACTION_TYPE,
  REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE,
  REPORTS_RESEND_REPORT_START_ACTION_TYPE,
  REPORTS_RESEND_REPORT_SUCCESS_ACTION_TYPE,
  REPORTS_ERROR_ACTION_TYPE,
  REPORTS_SKIP_ACTION_TYPE,
  ReportsAction,
} from "actions/reports/ReportsActionsTypes";

const initialState: ReportsState = {
  status: ReportsStatus.Initial,
  loading: false,
  uuidToId: new Map(),
};

export const reportsReducer: Reducer<ReportsState, ReportsAction> = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case REPORTS_CLEANUP_DENTAL_ACTION_TYPE:
      return {
        ...state,
        dentalReport: undefined,
        status: ReportsStatus.Cleanup,
        loading: false,
      };
    case REPORTS_SAVE_DENTAL_START_ACTION_TYPE:
      return {
        ...state,
        status: ReportsStatus.SavingDental,
        loading: true,
      };
    case REPORTS_SAVE_DENTAL_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        dentalReport: action.payload.dentalReport,
        status: ReportsStatus.SaveDentalSuccess,
        loading: false,
      };
    case REPORTS_SKIP_ACTION_TYPE:
      return {
        ...state,
        status: ReportsStatus.ReportSkipped,
        loading: false,
      };
    case REPORTS_SAVE_GROOMING_START_ACTION_TYPE:
      return {
        ...state,
        status: ReportsStatus.SavingGrooming,
        loading: true,
      };
    case REPORTS_SAVE_GROOMING_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        groomingReport: action.payload.groomingReport,
        status: ReportsStatus.SaveGroomingSuccess,
        loading: false,
      };
    case REPORTS_GET_DENTAL_BY_APPOINTMENT_START_ACTION_TYPE:
      return {
        ...state,
        dentalReport: undefined,
        status: ReportsStatus.GettingDentalByAppointment,
        loading: true,
      };
    case REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        dentalReport: action.payload.dentalReport,
        status: ReportsStatus.GetDentalByAppointmentSuccess,
        loading: false,
      };
    case REPORTS_GET_GROOMING_BY_APPOINTMENT_START_ACTION_TYPE:
      return {
        ...state,
        groomingReport: undefined,
        status: ReportsStatus.GettingGroomingByAppointment,
        loading: true,
      };
    case REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        groomingReport: action.payload.groomingReport,
        status: ReportsStatus.GetGroomingByAppointmentSuccess,
        loading: false,
      };
      case REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START_ACTION_TYPE:
      return {
        ...state,
        dentalReportPDF: undefined,
        status: ReportsStatus.GettingDentalPDFByAppointment,
        loading: true,
      };
    case REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        dentalReportPDF: action.payload.dentalReportPDF,
        status: ReportsStatus.GetDentalPDFByAppointmentSuccess,
        loading: false,
      };
    case REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START_ACTION_TYPE:
      return {
        ...state,
        groomingReportPDF: undefined,
        status: ReportsStatus.GettingGroomingPDFByAppointment,
        loading: true,
      };
    case REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        groomingReportPDF: action.payload.groomingReportPDF,
        status: ReportsStatus.GetGroomingPDFByAppointmentSuccess,
        loading: false,
      };
    case REPORTS_RESEND_REPORT_START_ACTION_TYPE:
      return {
        ...state,
        status: ReportsStatus.ResendingReport,
        loading: true,
      };
    case REPORTS_RESEND_REPORT_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ReportsStatus.ResendReportSuccess,
        loading: false,
      };
    case REPORTS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: ReportsStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reportsReducer;
