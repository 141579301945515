import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
  info?: string;
  leftBorder?: boolean;
  className?: string;
}

const useStyles = makeStyles<Theme, Props>(theme =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			maxWidth: '50%',
			width: '100%',
			height: '100%',
			[theme.breakpoints.down('lg')]: {
				paddingRight: '31px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingRight: '42px'
			},
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%'
			}
		},
		leftBorder: {
			borderLeft: '1px solid #BFBFBF',
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '31px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '42px'
			},
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0px',
				borderLeft: 'none'
			}
		},
		titleContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%'
		},
		title: {
			fontSize: 14,
			lineHeight: 1,
			textTransform: 'uppercase',

			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				color: '#7A7A7A',
				fontWeight: 400,
				textTransform: 'unset'
			}
		},
		infoContainer: {},
		info: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			marginTop: 8
		},
		extraInfoContainer: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'flex-between',
			marginTop: 8
		}
	})
);

const Area: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	return (
		<Box
			className={clsx(classes.container, {
				[classes.leftBorder]: props.leftBorder
			})}
		>
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>{props.title}</Typography>
			</Box>
			<Box className={classes.infoContainer}>
				<Typography className={classes.info}>{props.info}</Typography>
			</Box>
			{props.children && (
				<Box className={classes.extraInfoContainer}>
					{props.children}
				</Box>
			)}
		</Box>
	);
};

export default Area;
