import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faPaw,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { Status } from "model";
import { Option, PetType } from "@spike/model";
import Checkbox from "components/UI/Checkbox";
import Dropdown from "components/UI/Dropdown";
import MultiselectionBar from "components/UI/MultiselectionBar";
import { Service, StaffMember } from "model/Service";
import StaffDropdown from "./StaffDropdown";

interface Props {
  selectedQty: number;
  businessAreas: Array<Option<string>>;
  petTypes: Array<PetType>;
  staff: Array<StaffMember>;
  serviceStaff: Array<StaffMember>;
  onClose: () => void;
  onActivate: (serviceIds: Array<number>, active: boolean) => void;
  onDelete: () => void;
  onChangeBusinessArea: (businessAreaId: string) => void;
  onChangePetTypes: (petTypesId: Array<string>) => void;
  onChangeStaff: (staffIds: Array<number>) => void;
  selectedServices: Array<Service>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconsContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    iconContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      cursor: "pointer",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "32px",
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: "44px",
      },
    },
    disabledIcon: {
      cursor: "default",
      color: "#797979",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px",
      },
    },
    dropdown: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "46px",
        paddingTop: "23px",
        paddingBottom: "28px",
        paddingRight: "28px",
        paddingLeft: "28px",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "62px",
        paddingTop: "31px",
        paddingBottom: "38px",
        paddingRight: "38px",
        paddingLeft: "38px",
      },
    },
    businessAreasContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("lg")]: {
        width: "208px",
        height: "155px",
        marginBottom: "46px",
        marginRight: "22px",
        paddingRight: "28px",
        paddingLeft: "28px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "278px",
        height: "207px",
        marginBottom: "62px",
        marginRight: "30px",
        paddingRight: "38px",
        paddingLeft: "38px",
      },
    },
    businessAreasOptionsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: "1 1 auto",
      [theme.breakpoints.down("lg")]: {
        marginTop: "17px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "23px",
      },
      "& p": {
        [theme.breakpoints.down("lg")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "16px",
        },
      },
    },
    petTypesContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("lg")]: {
        width: "208px",
        height: "155px",
        marginLeft: "-65px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "278px",
        height: "207px",
        marginLeft: "-80px",
      },
    },
    petTypesOptionsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: "1 1 auto",
      [theme.breakpoints.down("lg")]: {
        marginTop: "17px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "23px",
      },
      "& p": {
        [theme.breakpoints.down("lg")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "16px",
        },
      },
    },
    staffContainer: {
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      [theme.breakpoints.down("lg")]: {
        width: "fit-content",
        marginBottom: "46px",
        marginLeft: "-55px",
        paddingTop: "23px",
        paddingBottom: "28px",
        paddingRight: "28px",
        paddingLeft: "28px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "fit-content",
        marginBottom: "62px",
        marginLeft: "-70px",
        paddingTop: "31px",
        paddingBottom: "38px",
        paddingRight: "38px",
        paddingLeft: "38px",
      },
    },
  })
);

export const ServicesMultiselectionBar: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showPetTypes, setShowPetTypes] = useState(false);
  const [activate, setActivate] = useState(props.selectedServices.some((s) => s.status.id === Status.ACTIVE));
  const [selectedPetTypeIds, setSelectedPetTypeIds] = useState<Array<string>>([]);
  const [showStaff, setShowStaff] = useState(false);

  useEffect(() => {
    setActivate(props.selectedServices.some((s) => s.status.id === Status.ACTIVE));
  }, [props.selectedServices]);

  const canBeActivated = (service: Service): boolean => {
    return (
      (service.pricing.fixedPrice.length > 0 || service.pricing.variablePrice !== undefined) && service.staff.length > 0
    );
  };

  const disableActiveButton = !props.selectedServices.some((s) => canBeActivated(s));

  const clickPetTypesIconHandler = (): void => {
    setShowPetTypes((prevValue) => {
      return !prevValue;
    });
  };

  const clickStaffIconHandler = (): void => {
    if (props.selectedQty === 1) {
      setShowPetTypes(false);
      setShowStaff((prevValue) => {
        return !prevValue;
      });
    }
  };

  const clickActivateHandler = (): void => {
    if (!disableActiveButton) {
      setShowPetTypes(false);
      props.onActivate(
        props.selectedServices.filter((s) => canBeActivated(s)).map((s) => s.id!),
        !activate
      );
      setActivate((prevValue) => {
        return !prevValue;
      });
    }
  };


  const clickPetTypeHandler = (petTypeId: string, checked: boolean) => {
    setSelectedPetTypeIds((prevValues) => {
      if (checked) {
        prevValues.push(petTypeId);
      } else {
        prevValues = prevValues.filter((id) => id !== petTypeId);
      }
      return prevValues;
    });
    const petTypeIds = selectedPetTypeIds.filter((id) => id !== petTypeId);
    if (checked) {
      petTypeIds.push(petTypeId);
    }
    props.onChangePetTypes(petTypeIds);
  };

  const clickOnDelete = () => {
    setShowPetTypes(false);
    props.onDelete();
  };

  const deleteStaffHandler = (staffId: number) => {
    props.onChangeStaff(props.serviceStaff.map((staff) => staff.id).filter((id) => id !== staffId));
  };

  const addStaffHandler = (staffId: number) => {
    props.onChangeStaff(props.serviceStaff.map((staff) => staff.id).concat([staffId]));
  };

  const petTypes = showPetTypes && (
    <Dropdown
      direction="up-right"
      className={clsx(classes.dropdown, classes.petTypesContainer)}
      onCollapse={() => {
        setShowPetTypes(false);
      }}
    >
      <Box>
        <Typography className={classes.title}>PET CATEGORY</Typography>
      </Box>
      <Box className={classes.petTypesOptionsContainer}>
        {props.petTypes.map((pt) => (
          <Box key={pt.id}>
            <Checkbox
              label={pt.plural}
              checked={false}
              onChange={(checked) => {
                clickPetTypeHandler(pt.id, checked);
              }}
            />
          </Box>
        ))}
      </Box>
    </Dropdown>
  );

  const staff = showStaff && (
    <Dropdown
      direction="up-right"
      className={clsx(classes.dropdown, classes.staffContainer)}
      onCollapse={() => {
        setShowStaff(false);
      }}
    >
      <StaffDropdown
        staff={props.staff}
        serviceStaff={props.serviceStaff}
        onDelete={deleteStaffHandler}
        onAdd={addStaffHandler}
      />
    </Dropdown>
  );

  return (
    <MultiselectionBar selectedQty={props.selectedQty} onClose={props.onClose} onDelete={clickOnDelete}>
      <Box className={classes.iconsContainer}>
        <Box className={classes.iconContainer}>
          <FontAwesomeIcon
            className={clsx(classes.icon, {
              [classes.disabledIcon]: props.selectedQty > 1,
            })}
            icon={faUsers}
            onClick={clickStaffIconHandler}
          />
          {staff}
        </Box>
        <Box className={classes.iconContainer}>
          <FontAwesomeIcon className={classes.icon} icon={faPaw} onClick={clickPetTypesIconHandler} />
          {petTypes}
        </Box>
        {/*
          <Box className={classes.iconContainer}>
            <FontAwesomeIcon
              className={classes.icon}
              icon={faTag}
              onClick={clickBusinessAreasIconHandler}
            />
            {businessAreas}
          </Box>
          */}
        <Box className={classes.iconContainer}>
          <FontAwesomeIcon
            className={clsx(classes.icon, {
              [classes.disabledIcon]: disableActiveButton,
            })}
            icon={!activate ? faToggleOff : faToggleOn}
            onClick={clickActivateHandler}
          />
        </Box>
      </Box>
    </MultiselectionBar>
  );
};

export default ServicesMultiselectionBar;
