import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SelectionBarItem from "./SelectionBarItem";
import clsx from "clsx";

interface Props {
  options: Array<SelectionBarOption>;
  selectedOptionId: string;
  onSelect: (id: string) => void;
  count?: number;
}

export interface SelectionBarOption {
  id: string;
  name: string;
  mark?: Mark;
  count?: number;
  selectedBackgroundColor?: string;
}

export enum Mark {
  WARNING = "warning",
  ERROR = "error",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      background: "#F8F8F8",
      border: "none",
      height: "fit-content",
      [theme.breakpoints.down("md")]: {
        borderRadius: "80px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "100px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    },
    button: {
      marginRight: "14px",
      marginLeft: "14px",
    },
    firstButton: {
      marginLeft: "0px",
    },
    lastButton: {
      marginRight: "0px",
    },
  })
);

export const SelectionBar: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const clickHandler = (id: string) => {
    if (props.selectedOptionId !== id) {
      props.onSelect(id);
    }
  };

  const buttons = props.options.map((option, index) => {
    const buttonStyle = clsx(classes.button, {
      [classes.firstButton]: index === 0,
      [classes.lastButton]: props.options.length === 1 || index + 1 === props.options.length,
    });

    return (
      <Box key={option.id}>
        <SelectionBarItem
          label={option.name}
          selected={option.id === props.selectedOptionId}
          selectedBackgroundColor={option.selectedBackgroundColor}
          onClick={() => {
            clickHandler(option.id);
          }}
          mark={option.mark}
          className={buttonStyle}
          count={props.count}
        />
      </Box>
    );
  });

  return <Box className={classes.container}>{buttons}</Box>;
};

export default SelectionBar;
