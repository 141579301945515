import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

export interface SectionProps extends PropsWithChildren {
    title?: string;
    description?: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderTop: '1px solid #E5E5E5',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(47)}px`,
                paddingTop: `${reduceResolution(47)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '47px',
                paddingTop: '47px'
            }
        },
        title: {
            color: '#222222',
            fontWeight: 600,
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px'
            }
        },
        description: {
            color: '#222222',
            marginTop: '20px',
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                marginTop: `${reduceResolution(20)}px`,
                fontSize: '14px',
                lineHeight: '140%'
            }
        }
    })
);

export const Section: FunctionComponent<SectionProps> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.className)}>
            {props.title && (
                <Typography className={classes.title}>{props.title}</Typography>
            )}
            {props.description && (
                <Typography className={classes.description}>
                    {props.description}
                </Typography>
            )}
            {props.children}
        </Box>
    );
};

export default Section;
