import {
    PAY_BY_LINK_FETCH_ERROR,
    PAY_BY_LINK_FETCH_START,
    PAY_BY_LINK_FETCH_SUCCESS,
    PAY_BY_LINK_RESET_SAVE_TIP_ERROR,
    PAY_BY_LINK_SAVE_TIP_ERROR,
    PAY_BY_LINK_SAVE_TIP_START,
    PAY_BY_LINK_SAVE_TIP_SUCCESS,
    PAY_BY_LINK_LOCK_INVOICE_ERROR,
    PAY_BY_LINK_LOCK_INVOICE_SUCCESS,
    PAY_BY_LINK_LOCK_INVOICE_START,
    PAY_BY_LINK_DROPIN_SESSION_START,
    PAY_BY_LINK_DROPIN_SESSION_SUCCESS,
    PAY_BY_LINK_DROPIN_SESSION_ERROR,
    PayByLinkAction
} from 'actions/payByLink/PayByLinkActionTypes';
import { PaymentLink } from 'actions/payByLink/PayByLinkDto';
import { PayByLinkState } from './payByLinkState';

export const initialState: PayByLinkState = {
    loading: false,
    paymentLink: null,
    error: null,
    fetchError: null,
    saveTipError: null,
    lockInvoiceError: null,
    dropInSessionSuccess: null,
    dropInSessionError: null
};

export const payByLinkReducer = (
    state = initialState,
    action: PayByLinkAction
): PayByLinkState => {
    switch (action.type) {
        case PAY_BY_LINK_FETCH_START:
            return { ...state, loading: true, error: null };
        case PAY_BY_LINK_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentLink: action.payload.paymentLink
            };
        case PAY_BY_LINK_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                fetchError: action.payload.fetchError
            };
        case PAY_BY_LINK_SAVE_TIP_START:
            return { ...state, loading: true, error: null };
        case PAY_BY_LINK_SAVE_TIP_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentLink: {
                    ...state.paymentLink,
                    invoice: action.payload.invoice
                } as PaymentLink
            };
        case PAY_BY_LINK_SAVE_TIP_ERROR:
            return {
                ...state,
                loading: false,
                saveTipError: action.payload.saveTipError
            };
        case PAY_BY_LINK_RESET_SAVE_TIP_ERROR:
            return { ...state, saveTipError: null };

        case PAY_BY_LINK_LOCK_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                lockInvoiceError: action.payload.lockInvoiceError
            };

        case PAY_BY_LINK_LOCK_INVOICE_START:
            return { ...state, loading: true, lockInvoiceError: null };

        case PAY_BY_LINK_LOCK_INVOICE_SUCCESS:
            return { ...state, loading: false, lockInvoiceError: null };

        case PAY_BY_LINK_DROPIN_SESSION_SUCCESS:
            return { ...state, dropInSessionSuccess: true };

        case PAY_BY_LINK_DROPIN_SESSION_ERROR:
            return { ...state, dropInSessionError: action.payload.error };

        default:
            return state;
    }
};
