import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";

interface Props {
  className?: string;
  classNameCircularProgress?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    spinner: {
      [theme.breakpoints.only("xs")]: {
        width: "30px !important",
        height: "30px !important",
      },
      [theme.breakpoints.only("sm")]: {
        width: "35px !important",
        height: "35px !important",
      },
      [theme.breakpoints.only("md")]: {
        width: "35px !important",
        height: "35px !important",
      },
      [theme.breakpoints.only("lg")]: {
        width: "40px !important",
        height: "40px !important",
      },
      [theme.breakpoints.only("xl")]: {
        width: "50px !important",
        height: "50px !important",
      },
    },
  })
);

export const Spinner: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {/* <CircularProgress className={classes.spinner} /> */}
      <CircularProgress className={clsx(classes.spinner, props.classNameCircularProgress)} />
    </Box>
  );

};

export default Spinner;
