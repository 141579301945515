import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { DropdownOption } from "./model";

interface DropdownItemProps {
  option: DropdownOption;
  onClick: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    dropdownItem: {
      width: "100%",
      color: "#222",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.95,
      cursor: "pointer",
      margin: "2px 0px",
      padding: "0px 20px",
      display: "inline-block",

      "& svg": {
        marginRight: 6,
        verticalAlign: "middle",
      },

      "&:hover": {
        backgroundColor: "#FAFAFA",
      },
    },
    disabled: {
      width: "100%",
      color: "#D3D3D3",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.95,
      cursor: "not-allowed",
      margin: "2px 0px",
      padding: "0px 20px",
      display: "inline-block",

      "& svg": {
        marginRight: 6,
        verticalAlign: "middle",
      },
    },
  })
);

const DropdownItem: FunctionComponent<DropdownItemProps> = (props) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.dropdownItem, { [classes.disabled]: props.option.disabled })}
      onClick={(event) => {
        event.stopPropagation();
        props.onClick();
      }}
    >
      <FontAwesomeIcon icon={props.option.icon} />
      {props.option.title}
    </Box>
  );
};

export default DropdownItem;
