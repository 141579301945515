import { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
  info?: string;
  prefix?: string;
  iconColor?: string;
  description?: string;
  subInfo?: string;
  lg?: boolean;
  icon?: IconDefinition;
  leftBorder?: boolean;
  childrenAsItem?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        paddingRight: "34px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "42px",
      },
    },
    leftBorder: {
      borderLeft: "1px solid #BFBFBF",
      [theme.breakpoints.down("md")]: {
        paddingRight: "34px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "42px",
      },
    },
    titleContainer: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
    },
    title: {
      fontWeight: 500,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "6px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "8px",
      },
    },
    info: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    extraInfoContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-between",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "10px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "13px",
      },
    },
    lg: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
      },
    },
    icon: {
      [theme.breakpoints.down("md")]: {
        marginTop: "3px",
        marginRight: "6px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "4px",
        marginRight: "8px",
      },
    },
    description: {
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px",
      },
    }
  })
);

const Area: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.container, {
        [classes.leftBorder]: props.leftBorder,
      })}
    >
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{props.title}</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <Typography className={clsx(classes.info, { [classes.lg]: props.lg })} noWrap>{props.info}</Typography>
        {props.subInfo && <Typography className={clsx(classes.info, { [classes.lg]: props.lg })} noWrap>{props.subInfo}</Typography>}
        {props.description && <Typography className={clsx(classes.description, { [classes.lg]: props.lg })} noWrap>{props.description}</Typography>}
        {props.childrenAsItem &&
          props.children
        }
      </Box>
      {!props.childrenAsItem && <Box className={classes.extraInfoContainer}>{props.children}</Box>
      }
    </Box>
  );
};

export default Area;
