import React, { FunctionComponent } from 'react';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import Typography from '@material-ui/core/Typography';
import ToolbarItemWithConfirmation from './ToolbarItemWithConfirmation';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export interface Props {
    onConfirmedClick: () => void;
    label?: string;
    confirmQuestion?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textDialog: {
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            }
        }
    })
);

export const CancelToolbarItem: FunctionComponent<Props> = props => {
    const classes = useStyles();

    return (
        <ToolbarItemWithConfirmation
            icon={faTimesCircle}
            text="Cancel"
            onConfirmedClick={props.onConfirmedClick}
            confirmQuestion={
                props.confirmQuestion ? (
                    props.confirmQuestion
                ) : (
                    <Typography className={classes.textDialog}>
                        Are you sure you want to <strong>Cancel</strong> this
                        appointment?
                    </Typography>
                )
            }
            confirmButtonLabel="Yes"
            cancelButtonLabel="No"
        />
    );
};

export default CancelToolbarItem;
