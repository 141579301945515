export const OPEN_MY_ACCOUNT_SIDEBAR = 'OPEN_MY_ACCOUNT_SIDEBAR';
export const CLOSE_MY_ACCOUNT_SIDEBAR = 'CLOSE_MY_ACCOUNT_SIDEBAR';
export const TOGGLE_MY_ACCOUNT_SIDEBAR = 'TOGGLE_MY_ACCOUNT_SIDEBAR';

export const openMyAccountSidebar = () => {
    return { type: 'OPEN_MY_ACCOUNT_SIDEBAR' };
};

export const closeMyAccountSidebar = () => {
    return { type: 'CLOSE_MY_ACCOUNT_SIDEBAR' };
};

export const toggleMyAccountSidebar = () => {
    return { type: 'TOGGLE_MY_ACCOUNT_SIDEBAR' };
};
