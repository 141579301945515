import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { Pagination, Spinner } from 'components/UI';
import { Pagination as PaginationModel } from '@spike/model';
import React, {
	FunctionComponent,
	Fragment,
	useRef,
	useState,
	useCallback,
	useEffect
} from 'react';
import { useCommonStyles } from './CommonStyles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { wbp, reduceResolution } from 'Theme';
import { Table as MUITable } from '@material-ui/core';

interface TableProps {
	headers: JSX.Element;
	rows: JSX.Element;
	pagination?: PaginationModel;
	loading?: boolean;
	className?: string;
	wrapperClassName?: string;
  	containerClassname?: string;
	withoutColor?: boolean;
	disableScroll?: boolean;
	onChangePage?: (page: number) => void;
	onChangePageSize?: (pageSize: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(50)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '50px'
			}
		},
		pagination: {
			[theme.breakpoints.up('md')]: {
				marginTop: 32,
				paddingRight: 48
			}
		},
		colorHeader: {
			backgroundColor: '#FFF !important'
		},
		noScroll: {
			overflow: 'visible'
		}
	})
);

export const Table: FunctionComponent<TableProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const ref = useRef<HTMLDivElement>(null);

	const [scrollPos, setScrollPos] = useState(0);

	// The scroll listener
	const handleScroll = useCallback((event: Event) => {
		const div = event.target as HTMLDivElement;

		setScrollPos(div.scrollLeft);
	}, []);

	// Attach the scroll listener to the div
	useEffect(() => {
		const div = ref.current;

		div?.addEventListener('scroll', handleScroll);
	}, [handleScroll]);

	return (
		<>
			<div
				className={clsx(commonClasses.wrapper, props.wrapperClassName, {
					[classes.noScroll]: props.disableScroll
				})}
				ref={ref}
			>
				<MUITable
					className={clsx({
						scrolled: scrollPos > 0
					})}
				>
					<TableHead className={commonClasses.header}>
						{props.headers}
					</TableHead>
					<TableBody className={commonClasses.body}>
						{/* Loading spinner */}
						{props.loading && (
							<TableRow>
								<TableCell colSpan={999}>
									<Spinner className={classes.spinner} />
								</TableCell>
							</TableRow>
						)}

						{/* Table rows */}
						{props.rows}
					</TableBody>
				</MUITable>
			</div>

			{props.pagination && props.pagination.totalPages > 0 && (
				<Pagination
					count={props.pagination.totalRecords}
					page={props.pagination.page}
					pageSize={props.pagination.pageSize}
					onPageChange={props.onChangePage}
					onPageSizeChange={props.onChangePageSize}
					disableAllPageSize={true}
					className={classes.pagination}
				/>
			)}
		</>
	);
};

export default Table;
