import { ResetPasswordState } from "./ResetPasswordState";
import {
  RESET_PASSWORD_START_ACTION_TYPE,
  RESET_PASSWORD_END_ACTION_TYPE,
  RESET_PASSWORD_FAIL_ACTION_TYPE,
  RESET_PASSWORD_SUCCESS_ACTION_TYPE,
  RESET_PASSWORD_RESET_ACTION_TYPE,
  ResetPasswordAction,
} from "../../actions/resetPassword/ResetPasswordActionsTypes";
import { Reducer } from "redux";

const initialState: ResetPasswordState = {
  failed: false,
  success: false,
  loading: false,
};

export const resetPasswordReducer: Reducer<
  ResetPasswordState,
  ResetPasswordAction
> = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_START_ACTION_TYPE:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_END_ACTION_TYPE:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_FAIL_ACTION_TYPE:
      return {
        ...state,
        failed: false,
      };
    case RESET_PASSWORD_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        success: true,
      };
    case RESET_PASSWORD_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
