import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarketplaceStatus } from '@spike/marketplace-action';
import {
    MarketplacePetTypes,
    MarketplaceTypeCats,
    MarketplaceTypeDogs,
    MarketplaceTypeExotics
} from '@spike/marketplace-model';
import { useNonInitialEffect } from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import { fetchPetTypeStatusThunk } from 'actions/businessSettingsSidebar/BusinessSettingsActions';
import clsx from 'clsx';
import { Button, MessageDialog2, Spinner } from 'components/UI';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PetTypeStatusEnum, PetTypeStatusState } from 'reducers/businessSettings/PetTypeStatusState';
import { RootState } from 'store';
import { BusinessSettingsTitle } from '../UI';
import { CatPetType } from './CatPetType';
import { DogPetType } from './DogPetType';
import { ExoticPetType } from './ExoticPetType';
import RedirectionDialog from './UI/RedirectionDialog';
import { validateSizes } from './validations';

export interface PetTypesProps {
    petTypes: MarketplacePetTypes;
    className?: string;
    isMobile?: boolean;
    onSave?: (petTypes: MarketplacePetTypes) => void;
    onContinue?: () => void;
    onChange?: (petTypes: MarketplacePetTypes) => void;
    openSidebar?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileBody: {
            overflow: 'hidden'
        },
        container: {
            display: 'flex',
            width: '100%',
            height: '100%',
            background: 'white',
            flexDirection: 'column',
            padding: '0px 80px 0px 60px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: '100%',
                padding: '0px',
                position: 'relative'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%'
        },
        businessSettingsTittle: {
            'display': 'flex',
            'alignItems': 'center',
            '& $iconBusinessTitle': {
                fontSize: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                'fontSize': '20px',
                'marginBottom': '25px',
                '& $iconBusinessTitle': {
                    fontSize: '20px',
                    fontWeight: '300'
                }
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        contentContainer: {
            'flex': 1,
            '& .MuiAccordionDetails-root': {
                padding: '0px !important',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '8px !important'
                },
                [theme.breakpoints.only('md')]: {
                    marginTop: '13px !important'
                },
                [theme.breakpoints.only('lg')]: {
                    marginTop: '17px !important'
                },
                [theme.breakpoints.only('xl')]: {
                    marginTop: '26px !important'
                }
            }
        },
        messageDialog: {
            width: '100%',
            textAlign: 'left',
            marginLeft: '10px',
            fontSize: '16px'
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '13px',
                paddingBottom: '13px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '22px',
                paddingBottom: '22px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '29px',
                paddingBottom: '29px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '44px',
                paddingBottom: '44px'
            }
        },
        right: {
            justifyContent: 'flex-end'
        },
        boxSpinner: {
            display: 'flex',
            height: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '40px'
            }
        },
        alertContainer: {
            width: '95%',
            display: 'flex',
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',
            [theme.breakpoints.down(wbp)]: {
                borderRadius: 18,
                padding: 17,
                marginBottom: '20px'
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: 20,
                padding: 15,
                marginBottom: '18px'
            },
            [theme.breakpoints.down('md')]: {
                'borderRadius': 18,
                'padding': 15,
                'marginTop': '5px',
                'marginBottom': '10px',
                'width': '100%',
                'fontSize': '16px',
                '& p': {
                    marginBottom: 0
                }
            }
        },
        alertText: {
            width: '100%',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(26)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '28px'
            }
        },
        icon: {
            color: '#BAA997',
            [theme.breakpoints.down(wbp)]: {
                fontSize: '20px',
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px',
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        buttonContainerFull: {
            [theme.breakpoints.down('md')]: {
                'width': '100%',
                'left': '0px',
                'bottom': '0px',
                'paddingBottom': '74px',
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '16px'
                    }
                }
            }
        }
    })
);

export const PetTypes: FunctionComponent<PetTypesProps> = ({
    className,
    petTypes,
    onSave,
    onContinue,
    onChange,
    openSidebar,
    isMobile
}) => {
    const classes = useStyles();

    const loading = useSelector<RootState, boolean>(state => state.marketplace.loading);
    const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
    const [saved, setSaved] = useState(false);

    const petTypesStatus = useSelector<RootState, PetTypeStatusState>(state => state.petTypeStatus);

    const { dogs, cats, exotics } = petTypes;

    const dispatch = useDispatch();

    const [dogType, setDogType] = useState<MarketplaceTypeDogs | undefined>(dogs);
    const [catType, setCatType] = useState<MarketplaceTypeCats | undefined>(cats);
    const [exoticType, setExoticType] = useState<MarketplaceTypeExotics | undefined>(exotics);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [showSizesError, setShowSizesError] = useState(false);

    useEffect(() => {
        dispatch(fetchPetTypeStatusThunk());
    }, []);

    useEffect(() => {
        if (isMobile) {
            document.body.classList.add(classes.mobileBody);
        } else {
            document.body.classList.remove(classes.mobileBody);
        }
    }, [isMobile]);

    useNonInitialEffect(() => {
        if (petTypesStatus.status === PetTypeStatusEnum.FetchSuccess) {
            setLoadingSpinner(false);
        }
    }, [petTypesStatus.status]);

    const loadingView = (
        <Box className={classes.boxSpinner}>
            <Spinner />
        </Box>
    );

    useNonInitialEffect(() => {
        const petTypeAux = {
            dogs: dogType,
            cats: catType,
            exotics: exoticType
        };
        onChange && onChange(petTypeAux);
    }, [dogType, catType, exoticType]);

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setSaved(true);
        }
    }, [status]);

    const clickButtonHandler = () => {
        const isValid = dogType !== undefined ? validateSizes(dogType?.sizes ?? []) : true;

        if (isValid) {
            onSave && onSave({ dogs: dogType, cats: catType, exotics: exoticType });
            onContinue && onContinue();
        } else {
            setShowSizesError(true);
        }
    };

    const button = (
        <Button
            id="business_settings_pet_types_button_save"
            label={onSave ? (saved ? 'Saved!' : 'Save') : 'Continue'}
            onClick={clickButtonHandler}
            loading={loading}
        />
    );
    const alertMessage = (
        <Box className={classes.alertContainer}>
            <FontAwesomeIcon icon={faInfoCircle} className={classes.icon} />
            <Box>
                <Typography className={classes.alertText}>
                    You cannot remove variables that are associated with pending appointments or active services.
                </Typography>
            </Box>
        </Box>
    );

    return (
        <Box className={clsx(classes.container, className)}>
            {!isMobile && alertMessage}
            <Box className={classes.titleContainer}>
                <BusinessSettingsTitle className={classes.businessSettingsTittle}>
                    {isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}{' '}
                    Type of Pets
                </BusinessSettingsTitle>
            </Box>
            {isMobile && alertMessage}
            <Box className={classes.contentContainer}>
                <DogPetType
                    id="business_settings_pet_types_accordion_dog"
                    dogsType={dogType}
                    onChange={setDogType}
                    petFeatures={petTypesStatus}
                />
                <Box className={classes.separator}></Box>
                <CatPetType
                    id="business_settings_pet_types_accordion_cat"
                    catsType={catType}
                    onChange={setCatType}
                    petFeatures={petTypesStatus}
                />
                <Box className={classes.separator}></Box>
                <ExoticPetType
                    id="business_settings_pet_types_accordion_exotic"
                    exoticsType={exoticType}
                    onChange={setExoticType}
                />
            </Box>
            <Box
                className={clsx(classes.buttonContainer, classes.buttonContainerFull, { [classes.right]: onContinue })}
            >
                {button}
            </Box>
            {loadingSpinner && loadingView}
            {saved && (
                <RedirectionDialog
                    icon={faList}
                    buttonLabel="Go to Services"
                    title="Review Pricing"
                    subTitle="Please review your service pricing to reflect any changes you’ve made to the size ranges."
                    path={'/services'}
                    onCancel={() => setSaved(false)}
                />
            )}
            {showSizesError && (
                <MessageDialog2
                    title="Setting Size Ranges"
                    message={[
                        'Make sure each size range connects to the next',
                        'one without any gaps or overlaps. Begin at 0 lbs',
                        'and go up (for example, Small: 0-10 lbs,',
                        'Medium: 11-20 lbs, Large: 21-70 lbs).'
                    ]}
                    onClose={() => setShowSizesError(false)}
                />
            )}
        </Box>
    );
};

export default PetTypes;
