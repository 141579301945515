import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

export interface Props {
    isDisabled?: boolean;
    isDetail?: boolean;
    icon: IconProp;
    onClick: () => void;
    text: string;
    color?: string;
    id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            'width': '100%',
            'color': '#222',
            'fontSize': 14,
            'fontWeight': 500,
            'lineHeight': 1.95,
            'cursor': 'pointer',
            'margin': '2px 0px',
            'padding': '0px 20px',
            'display': 'inline-block',
            'whiteSpace': 'nowrap',
            'textAlign': 'left',

            '& svg': {
                marginRight: 6,
                verticalAlign: 'middle'
            },

            '&:hover': {
                backgroundColor: '#FAFAFA'
            }
        },
        disabled: {
            'width': '100%',
            'color': '#D3D3D3',
            'fontSize': 14,
            'fontWeight': 500,
            'lineHeight': 1.95,
            'cursor': 'not-allowed',
            'margin': '2px 0px',
            'padding': '0px 20px',
            'display': 'inline-block',

            '& svg': {
                marginRight: 6,
                verticalAlign: 'middle'
            }
        },
        icon: {
            width: '15px',
            [theme.breakpoints.down('lg')]: {
                marginRight: '7px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '10px'
            }
        },
        detail: {
            color: '#EF4F57'
        }
    })
);

export const ToolbarItem: FunctionComponent<Props> = props => {
    const classes = useStyles();
    return (
        <Box
            onClick={props.onClick}
            id={props.id && props.id.concat('toolbar_item')}
            className={clsx(classes.item, {
                [classes.detail]: props.isDetail,
                [classes.disabled]: props.isDisabled
            })}
            style={{
                color: props.color
            }}
        >
            <FontAwesomeIcon
                icon={props.icon}
                className={clsx(classes.icon, {
                    [classes.detail]: props.isDetail
                })}
            />

            {props.text}
        </Box>
    );
};

export default ToolbarItem;
