import React, { FunctionComponent } from "react";
import { Box, TableBody, TableRow, TableCell } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  title: string;
  description: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "50px",
      textAlign: "center",
      width: "100%",
    },
    title: {
      fontSize: "2rem",
      fontWeight: 700
    },
    description: {
      textAlign: "center",
      fontSize: "1.5rem",
    },
  })
);

export const TableNotFound: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={10} align="center" height="100px">
          <Box className={classes.container}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.description}>
              {props.description}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableNotFound;
