import { faCheckCircle, faExclamationTriangle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert as AlertModel, AlertSeverity, removeAlertThunk } from "@spike/notifications-action";
import clsx from "clsx";
import { FunctionComponent, MouseEvent } from "react";
import { useDispatch } from "react-redux";

interface AlertProps {
  alert: AlertModel;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flrx-start",
      cursor: "pointer",
      color: "white",
      padding: "10px",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        marginTop: "8px",
        marginRight: "8px",
        minWidth: "185px",
        maxWidth: "500px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "10px",
        marginRight: "10px",
        minWidth: "246px",
        maxWidth: "600px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "10px",
        marginRight: "10px",
        minWidth: "372px",
        maxWidth: "800px",
      },
    },
    alertSuccess: {
      backgroundColor: "green",
    },
    alertWarning: {
      backgroundColor: "yellow",
      color: "black",
    },
    alertError: {
      backgroundColor: "red",
    },
    alertIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "2px",
      [theme.breakpoints.down("md")]: {
        marginTop: "2px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "3px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "2px",
      },
    },
    icon: {
      [theme.breakpoints.down("md")]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "18px",
        height: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "24px",
        height: "24px",
      },
    },
    alertText: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: "10px",
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
      },
    },
  })
);

export const Alert: FunctionComponent<AlertProps> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const getIcon = (alert: AlertModel) => {
    let icon = faCheckCircle;

    if (alert.severity === AlertSeverity.WARNING) {
      icon = faExclamationTriangle;
    } else if (alert.severity === AlertSeverity.ERROR) {
      icon = faTimesCircle;
    }

    return <FontAwesomeIcon className={classes.icon} icon={icon} size="2x" />;
  };

  const clickAlertHandler = (event: MouseEvent, alertKey: string) => {
    event.stopPropagation();
    dispatch(removeAlertThunk(alertKey));
  };

  return (
    <Box
      key={props.alert.key}
      className={clsx(
        classes.alert,
        {
          [classes.alertSuccess]: props.alert.severity === AlertSeverity.SUCCESS,
          [classes.alertWarning]: props.alert.severity === AlertSeverity.WARNING,
          [classes.alertError]: props.alert.severity === AlertSeverity.ERROR,
        },
        props.className
      )}
      onClick={(event) => clickAlertHandler(event, props.alert.key)}
    >
      <Box className={classes.alertIcon}>{getIcon(props.alert)}</Box>
      <Box className={classes.alertText}>
        <Typography className={classes.text}>{props.alert.message}</Typography>
      </Box>
    </Box>
  );
};

export default Alert;
