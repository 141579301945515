import { Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  max: number;
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    counter: {
      color: "#7E7E7E",
      [theme.breakpoints.down("lg")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "12px",
      },
    },
  })
);

export const CharacterCounter: FunctionComponent<Props> = ({ max, text }) => {
  const classes = useStyles();
  const [contador, setContador] = useState(max ? max : 0);

  let cantidadLetras = text.length;

  const handleChange = (): void => {
    setContador(max - cantidadLetras);
  };

  useEffect(() => {
    handleChange();
  }, [cantidadLetras]);

  return (
    <Typography className={classes.counter} onChange={handleChange}>
      {contador}
    </Typography>
  );
};
