import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import PetInformation from "components/PetInformation/PetInformation";
import { Pet } from "@spike/pet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import SidebarPetMenu from "./UISidebar/SidebarPetMenu";
import SidebarPetVisit from "./UISidebar/SidebarPetVisit";

interface PetCardProps {
  pet: Pet;
  optionSelected: string;
  className?: string;
  onSelectMenu: (option: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: "#F8F5F1",
      width: "100%",
      height: "100%",
    },
    containerBox: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(35)}px`,
        paddingBottom: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "35px",
        paddingBottom: "15px",
      },
    },
    boxFlexRow: {
      display: "flex",
      flexDirection: "row",
    },
    boxBorder: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "10px 0",
      },
    },
    containerMenu: {
      cursor: "pointer",
    },
    visitBorder: {
      borderBottom: "1px solid #DBDAD7",
      borderTop: "1px solid #DBDAD7",
      padding: '10px 0px'
    }
  })
);

export const PetCardSidebar: FunctionComponent<PetCardProps> = (props) => {
  const classes = useStyles();

  const selectMenuHandler = (option: string) => {
    props.onSelectMenu(option);
  }

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.containerBox}>
        <Box className={classes.boxBorder}>
          <PetInformation
            pet={props.pet}
          />
          <Box className={classes.boxBorder}>
            <SidebarPetVisit
              pet={props.pet} className={classes.visitBorder} />
          </Box>
        </Box>
      </Box>
      <Box>
        <SidebarPetMenu
          pet={props.pet}
          optionSelected={props.optionSelected}
          onSelectMenu={selectMenuHandler}
        />
      </Box>
    </Box>
  );
};

export default PetCardSidebar;
