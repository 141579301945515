import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

interface Props {
    onAddPetAndServices: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            border: '1px solid #D3D3D3',
            borderRadius: 10,
            backgroundColor: '#F4F3F0',
            alignItems: 'center',
            height: 55,
            paddingLeft: 12,
            paddingRight: 12,
            cursor: 'pointer'
        },
        content: {
            display: 'flex'
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '27px'
        },
        icon: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '27px'
        },
        spacing: {
            marginRight: 10,
            marginTop: 2
        },
        iconSize: {
            fontSize: '16px !important',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
    })
);

export const AddPetAndServiceOption: FunctionComponent<Props> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.container} onClick={props.onAddPetAndServices}>
            <Box className={classes.content}>
                <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={clsx(classes.icon, classes.spacing)}
                />
                <Typography className={classes.title}>
                    Add Pet & Services
                </Typography>
            </Box>
            <FontAwesomeIcon
                icon={faChevronRight}
                className={clsx(classes.icon, classes.iconSize)}
            />
        </Box>
    );
};

export default AddPetAndServiceOption;
