import { FunctionComponent, useState, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { faPen, faCopy, faCircleCheck, faBan, faTrash } from '@fortawesome/pro-light-svg-icons';
import { SelectableOption, Status } from '../../../model';
import { DuplicateDialog } from './DuplicateDialog';
import { Typography } from '@material-ui/core';
import ToolbarButton from 'components/UI/Toolbar/ToolbarButton';
import ToolbarItem from 'components/UI/Toolbar/ToolbarItem';

interface Props {
    className?: string;
    statusId: number;
    name: string;
    duplicateOptions?: Array<SelectableOption<string>>;
    onEdit?: () => void;
    onActivate?: (active: boolean) => void;
    onDuplicate?: (name: string, selectedOptionsIds: Array<string>) => void;
    onDelete?: () => void;
}

export const RowToolbar: FunctionComponent<Props> = props => {
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [confirmStatusDialog, setConfirmStatusDialog] = useState(false);

    const onEditClickHandler = (): void => {
        props.onEdit && props.onEdit();
    };

    const onActiveClickHandler = (): void => {
        props.onActivate && props.onActivate(props.statusId === Status.INACTIVE);
    };

    const onDuplicateClickHandler = (): void => {
        setShowDuplicateDialog(true);
    };

    const onDuplicateHandler = (name: string, selectedOptionIds: Array<string>): void => {
        setShowDuplicateDialog(false);
        props.onDuplicate && props.onDuplicate(name, selectedOptionIds);
    };

    const onDeleteClickHandler = (): void => {
        props.onDelete && props.onDelete();
    };

    const closeDuplicateDialogHandler = (): void => {
        setShowDuplicateDialog(false);
    };

    const disabledActiveButton = props.statusId === Status.DRAFT || !props.onActivate;
    const disabledDuplicateButton = props.statusId === Status.DRAFT;

    return (
        <Fragment>
            <ToolbarButton id="services_toolbar">
                <ToolbarItem text="Edit" icon={faPen} onClick={onEditClickHandler} />
                {props.statusId === Status.INACTIVE && (
                    <ToolbarItem text="Activate" icon={faCircleCheck} onClick={() => setConfirmStatusDialog(true)} />
                )}
                {props.statusId === Status.ACTIVE && (
                    <ToolbarItem text="Deactivate" icon={faBan} onClick={() => setConfirmStatusDialog(true)} />
                )}
                {props.onDelete && <ToolbarItem text="Delete" icon={faTrash} onClick={onDeleteClickHandler} />}
                {props.onDuplicate && (
                    <ToolbarItem
                        text="Duplicate"
                        icon={faCopy}
                        onClick={() => {
                            !disabledDuplicateButton && onDuplicateClickHandler();
                        }}
                    />
                )}
            </ToolbarButton>

            <ConfirmDialog
                open={confirmStatusDialog}
                question={
                    <Box>
                        {props.statusId === Status.INACTIVE ? (
                            <Typography>Are you sure you want to activate this service?</Typography>
                        ) : (
                            <Typography>Are you sure you want to disable this service?</Typography>
                        )}
                    </Box>
                }
                confirmButtonLabel="Confirm"
                cancelButtonLabel="Cancel"
                onConfirm={() => {
                    !disabledActiveButton && onActiveClickHandler();
                }}
                onCancel={() => {
                    setConfirmStatusDialog(false);
                }}
            />

            <DuplicateDialog
                name={props.name}
                onClose={closeDuplicateDialogHandler}
                open={showDuplicateDialog}
                onDuplicate={onDuplicateHandler}
                duplicateOptions={props.duplicateOptions || []}
            />
        </Fragment>
    );
};

export default RowToolbar;
