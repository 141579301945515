import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'width': '100%',

            '& .MuiOutlinedInput': {
                '&-root': {
                    borderRadius: 30
                },
                '&-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: '#D3D3D3 !important'
                },
                '&-input': {
                    '&, &:focus': {
                        backgroundColor: 'transparent'
                    },

                    [theme.breakpoints.down(wbp)]: {
                        padding: `${reduceResolution(14)}px ${reduceResolution(
                            35
                        )}px ${reduceResolution(14)}px ${reduceResolution(
                            35
                        )}px`,
                        fontSize: `${reduceResolution(16)}px`,
                        lineHeight: `${reduceResolution(27)}px`
                    },
                    [theme.breakpoints.up(wbp)]: {
                        padding: '10px 35px 10px 35px',
                        fontSize: '16px',
                        lineHeight: '27px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '16px',
                        lineHeight: '27px'
                    }
                }
            },

            '& .MuiInputLabel': {
                '&-root': {
                    'fontSize': 16,
                    'transform': 'translate(35px, 16px) scale(1)',
                    '&[data-shrink=true]': {
                        opacity: 0
                    }
                }
            }
        },
        errorBorder: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF4F57 !important'
            }
        },
        disabled: {
            '& .MuiOutlinedInput-root': {
                backgroundColor: '#f5f5f5'
            }
        },
        listContainer: {
            borderTop: '1px solid #222222',
            overflowY: 'auto',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: '11px',
                maxHeight: '200px'
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '14px',
                maxHeight: '250px'
            }
        },
        itemContainer: {
            'cursor': 'pointer',
            'borderRadius': '20px',
            'display': 'flex',
            'justofyContent': 'center',
            [theme.breakpoints.down(wbp)]: {
                marginLeft: '12px',
                marginRight: '12px',
                padding: '3px 14px 3px 14px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '15px',
                marginRight: '15px',
                padding: '4px 18px 4px 18px'
            },
            '&:hover': {
                background: '#F8F8F8'
            }
        },
        item: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: '14px'
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },
        itemMD: {
            [theme.breakpoints.down(wbp)]: {
                padding: '4px 0px',
                fontSize: '13px'
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '4px 0px',
                fontSize: '13px'
            }
        },
        virtualizedItem: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        selectWithOptionSelected: {
            '& > fieldset': {
                border: 'solid 1px #222222',
                borderWidth: '1px !important'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'solid 1px #222222 !important',
                borderWidth: '1px !important'
            },
            '&-input': {
                '&:focus': {
                    borderWidth: '1px !important'
                }
            }
        },
        menuItem: {
            'fontsize': '16px !important',
            '.MuiOutlinedInput-input': {
                fontsize: '16px !important'
            }
        }
    })
);
