import React, { FunctionComponent, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Checkbox, HourField } from 'components/UI';
import { BusinessHours } from '@spike/model';

interface Props {
	label?: string;
	selected?: boolean;
	businessHours: BusinessHours;
	onSelected?: (selected: boolean) => void;
	onChange: (businessHours: BusinessHours) => void;
	className?: string;
	errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		label: {
			'display': 'flex',
			'flexDirection': 'column',
			'flex': '1 1 auto',

			'& p': {
				fontSize: 16,
				fontWeight: 500,


			}
		},
		hour: {
			display: 'flex',
			justifyContent: 'center'
		},
		toContainer: {
			display: 'flex',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				width: '25px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '33px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '50px'
			}
		},
		to: {
			fontSize: 15,
			textAlign: 'center',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		error: {
			borderColor: '#EA6464',
			color: '#EA6464'
		},
		errorMessage: {
			fontWeight: 400,
			[theme.breakpoints.down('lg')]: {
				marginLeft: '37px',
				fontSize: '10px !important'
			},
			[theme.breakpoints.only('xl')]: {
				marginLeft: '45px',
				fontSize: '12px !important'
			}
		}
	})
);

export const BusinessDay: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const hasError = props.errorMessage !== undefined;

	const onSelectedHandler = (value: boolean): void => {
		props.onSelected && props.onSelected(value);
	};

	const onChangeOpenHandler = (open: string): void => {
		props.onChange({ ...props.businessHours, open });
	};

	const onChangeCloseHandler = (close: string): void => {
		props.onChange({ ...props.businessHours, close });
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			{props.onSelected && (
				<Box
					className={clsx(classes.label, {
						[classes.error]: hasError
					})}
				>
					<Checkbox
						id={'business_settings_checkbox_' + props.label}
						label={props.label}
						onChange={onSelectedHandler}
						checked={props.selected || false}
						iconColor={hasError ? '#EA6464' : 'black'}
					/>
					{hasError && (
						<Typography
							className={clsx(
								classes.error,
								classes.errorMessage
							)}
						>
							{props.errorMessage}
						</Typography>
					)}
				</Box>
			)}
			{(props.selected || !props.onSelected) && (
				<Fragment>
					<Box className={classes.hour}>
						<HourField
							value24Hs={props.businessHours?.open}
							onChange={onChangeOpenHandler}
							disabled={!(props.selected || true)}
							error={hasError}
						/>
					</Box>
					<Box className={classes.toContainer}>
						<Typography
							className={clsx(classes.to, {
								[classes.error]: hasError
							})}
						>
							to
						</Typography>
					</Box>
					<Box className={classes.hour}>
						<HourField
							value24Hs={props.businessHours?.close}
							onChange={onChangeCloseHandler}
							disabled={!(props.selected || true)}
							error={hasError}
						/>
					</Box>
				</Fragment>
			)}
		</Box>
	);
};

export default BusinessDay;
