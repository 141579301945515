import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import { FieldError, Option } from '@spike/model';
import { FunctionComponent, useMemo } from 'react';
import { useStyles } from './SelectFieldStyles';
import LabelField from './LabelField';
import clsx from 'clsx';

export interface SelectFieldProps {
    id?: string;
    label?: string | JSX.Element;
    name?: string;
    selectedOption?: Option<string | number>;
    placeholder?: string;
    options: Array<Option<string | number>>;
    errors?: Array<FieldError>;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    information?: string;
    onSelect: (option: Option<string | number>, name?: string) => void;
    popUpChild?: boolean;
}

export const SelectField: FunctionComponent<SelectFieldProps> = props => {
    const classes = useStyles();

    const changeHandler = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
        const selectedId = event.target.value as string | number;

        props.onSelect(
            props.options.find(option => option.id === selectedId)!,
            props.name
        );
    };

    const onOpenHandler = () => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    };

    const hasError = useMemo(
        () =>
            props.errors?.some(
                errorField => errorField.fieldName === props.name
            ),
        [props.errors, props.name]
    );

    const selectedValue = useMemo(() => {
        return props.selectedOption?.id;
    }, [props.selectedOption]);

    return (
        <Box
            className={clsx(classes.root, props.className, {
                [classes.errorBorder]: hasError,
                [classes.disabled]: props.disabled
            })}
        >
            <LabelField {...props} />
            <FormControl fullWidth>
                <InputLabel id={`${props.name}-label`}>
                    {props.placeholder ? props.placeholder : props.label}
                </InputLabel>
                <Select
                    id={props.id || ''}
                    variant="outlined"
                    value={selectedValue}
                    onChange={changeHandler}
                    disabled={props.disabled}
                    labelId={`${props.name}-label`}
                    placeholder={props.placeholder}
                    className={
                        selectedValue ? classes.selectWithOptionSelected : ''
                    }
                    MenuProps={props.popUpChild ? { disablePortal: true } : {}}
                    onOpen={onOpenHandler}
                >
                    {props.options.map(opt => (
                        <MenuItem
                            key={opt.id}
                            value={opt.id}
                            id={`${props.id || ''}_${opt.name}`}
                            className={classes.menuItem}
                        >
                            <Typography>
                                {opt.name}{' '}
                                {opt.description && `(${opt.description})`}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectField;
