import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Fragment, FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { useCommonStyles } from "../../CommonStyles";
import { Button } from "components/UI";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
} from "@fortawesome/pro-solid-svg-icons";

interface OrderFinishProps {
  className?: string;
  //
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
      },
      [theme.breakpoints.up(wbp)]: {
      },
    },

    boxFlex: {
      display: "flex",
    },

    typoTittle: {
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
        paddingBottom: `${reduceResolution(20)}px`,
        width: `${reduceResolution(300)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
        paddingBottom: "20px",
        width: "300px",
      },
    },

    typoMessage: {
      color: "#000",
      textAlign: "center",
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(32)}px`,
        width: `${reduceResolution(400)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "400px",
        paddingBottom: "32px",
      },
    },

    boxImage: {
      background: "#000",
      borderRadius: "50px",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(80)}px`,
        height: `${reduceResolution(80)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "80px",
        height: "80px",
      },
    },

    icon: {
      color: "#5E8677",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(80)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "80px",
      },
    }
  })
);

export const OrderFinish: FunctionComponent<OrderFinishProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const terminalCommonClasses = useTerminalCommonStyles();

  return (
    <Fragment>
      <Box className={clsx(commonClasses.container, props.className)}>

        <Box className={clsx(commonClasses.container, props.className, classes.boxContainer)}>
          <FontAwesomeIcon icon={faCircleCheck} className={classes.icon} />

          <Typography className={clsx(classes.boxFlex, classes.typoTittle, terminalCommonClasses.typo28_600)}>Your order has been placed successfully</Typography>
          <Typography className={clsx(classes.boxFlex, terminalCommonClasses.typo15_400, classes.typoMessage)}>You will receive an email notification and you can check the status order from your account.</Typography>
          <Box className={clsx(classes.boxFlex)}>
            <Button color={"black"} label={"Done"} onClick={() => props.onClick && props.onClick()} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default OrderFinish;
