import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import SMSCampaign from 'model/SMSCampaign';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import moment from 'moment-timezone';

interface SearchCampaignItemProps {
    active?: boolean;
    campaign: SMSCampaign;
    onClick?: (campaign: SMSCampaign) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'block',
            cursor: 'pointer',
            borderRadius: 10,
            marginBottom: 8,
            padding: '12px 16px',
            position: 'relative',
            border: 'solid 1px transparent',
            transition:
                'background-color 0.1s ease-in, border-color 0.1s ease-in',
            [theme.breakpoints.up('md')]: {
                '&:hover, &.active': {
                    border: 'solid 1px #000',
                    backgroundColor: '#F8F5F1'
                }
            },
            [theme.breakpoints.down('md')]: {
                borderBottom: 'solid 1px #D4D4D4',
                borderRadius: 0
            },
            [theme.breakpoints.down('xs')]: {
                padding: '12px 0px'
            }
        },
        heading: {
            display: 'flex',
            alignItems: 'bassline',
            justifyContent: 'space-between',
            marginBottom: 12
        },
        title: {
            color: '#222',
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 600,
            paddingRight: 6,
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            [theme.breakpoints.down('md')]: {
                fontSize: 16
            }
        },
        message: {
            fontSize: 13,
            lineHeight: 1.2,
            fontWeigth: 400,
            color: '#7A7A7A',
            paddingRight: 25,
            overflowWrap: 'break-word',
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        created: {
            fontSize: 11,
            lineHeight: 1,
            fontWeight: 500,
            color: '#A2A2A5',
            whiteSpace: 'nowrap'
        }
    })
);

const CampaignItem: FunctionComponent<SearchCampaignItemProps> = props => {
    const classes = useStyles();

    return (
        <Box
            id={`campaign-${props.campaign.id}`}
            className={clsx(classes.root, props.active)}
            onClick={() => props.onClick && props.onClick(props.campaign)}
        >
            <Box className={classes.heading}>
                <Typography className={classes.title}>
                    {props.campaign.name.length < 30
                        ? props.campaign.name
                        : props.campaign.name.slice(0, 30) + '...'}
                </Typography>
                <span className={classes.created}>
                    {moment(props.campaign.created_at).fromNow()}
                </span>
            </Box>
            <Typography className={classes.message}>
                {props.campaign.message.length < 85
                    ? props.campaign.message
                    : props.campaign.message.slice(0, 80) + '...'}
            </Typography>
        </Box>
    );
};

export default CampaignItem;
