import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PetVaccine } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface VaccinesFileFieldProps {
  vaccine: PetVaccine;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(40)}px`,
        paddingTop: `${reduceResolution(6)}px`,
        paddingRight: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "40px",
        paddingTop: "6px",
        paddingRight: "16px",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "5px",
        paddingBottom: "5px",
        alignItems: "center",
      },
    },
    iconFile: {
      width: "auto !important",
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "6px",
      },
    },
    marginIcons: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "6px",
      },
    },
    fileName: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },

      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        width: `${reduceResolution(225)}px`,
        marginRight: `${reduceResolution(2)}px`,
        marginTop: `${reduceResolution(-1)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        width: "225px",
        marginRight: "2px",
        marginTop: "-1px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "auto",
        fontWeight: 500,
        textDecoration: "underline",
        textUnderlineOffset: "5px",
      },
    },
  })
);

export const VaccinesFileField: FunctionComponent<VaccinesFileFieldProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const handleViewFile = (image: string | undefined) => {
    let w = window.open("about:blank");

    let iframe = w!.document.body.appendChild(w!.document.createElement("iframe"));
    iframe.src = image ? image : "";
    iframe.width = `${window.innerWidth - 35}`;
    iframe.height = `${window.innerHeight}`;
    iframe.style.border = "none";
  };

  const renderIconMobile = () => {
      return(
        <FontAwesomeIcon icon={faEye} className={clsx(classes.iconFile, classes.marginIcons)} />
      )
  }

  const renderTextMobile = () => {

      return (
        <Typography
          className={clsx(classes.fileName)}
          noWrap={true}
          onClick={() => handleViewFile(props.vaccine.record?.url)}
        >
          View File
        </Typography>
      )
      
  }

 

  return (
    <Box className={clsx(props.className)}>
      {props.vaccine.record?.url && !props.vaccine.record.remove && (
        <Box className={classes.fileContainer}>
          {props.vaccine.record?.file.name && (
            <>
              {isSmall ? renderIconMobile() : <FontAwesomeIcon icon={faFilePdf} className={clsx(classes.iconFile, classes.marginIcons)} />}
            </>
          )}

          <>
            {isSmall ? renderTextMobile() : 

            <Typography
              className={clsx(classes.fileName)}
              noWrap={true}
              onClick={() => handleViewFile(props.vaccine.record?.url)}
            >
              {props.vaccine.record?.file.name}
            </Typography>}
          </>
        </Box>
      )}
    </Box>
  );
};

export default VaccinesFileField;
