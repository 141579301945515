/* eslint-disable*/
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import Pet from '@spike/pet-model';
import clsx from 'clsx';
import { useMarketplace } from 'hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { petViewOptions } from '../PetCardModel';

import { vaccinesAttention, vaccinesWarning } from '../utils/PetCardUtils';

interface PetTabSelectorMobileProps {
    optionSelected: string;
    pet: Pet;
    className?: string;
    // eslint-disable-next-line no-unused-vars
    onSelectMenu: (id: string) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            'display': 'inline-flex',
            'flexWrap': 'nowrap',
            'gap': 15,
            'overflowX': 'scroll',
            'scrollbarWidth': 'none',
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
                height: 0,
                width: 0
            },
            '& > div:first-child': {
                marginLeft: 16
            },
            '& > div:last-child': {
                marginRight: 16
            },
            '& > div': {
                'borderRadius': 60,
                'padding': '4px 11px',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                '& > p': {
                    fontWeight: 500,
                    textWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    fontSize: 16,
                    lineHeight: '24px'
                }
            }
        },
        item: {
            cursor: 'pointer',
            height: 30,
            background: '#f1f1f1',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center !important'
        },
        selectedItem: {
            borderBottom: '2px solid #222222',
            cursor: 'default',
            background: '#000'
        },
        text: {
            color: '#222222'
        },
        selectedText: {
            color: '#fff'
        },
        warning: {
            'width': 16,
            'height': 16,
            'color': '#EAB464 !important',
            'marginLeft': 5,
            '& > svg': {
                width: 16,
                height: 16
            }
        }
    })
);

const PetTabSelectorMobile: FunctionComponent<
    PetTabSelectorMobileProps
> = props => {
    const classes = useStyles();

    const isDental = useMarketplace().isDental;

    const [selectedOption, setSelectedOption] = useState<string>(
        props.optionSelected
    );
    const [hasWarning, setHasWarning] = useState(false);

    const selectMenuHandler = (option: string) => {
        setSelectedOption(option);
        props.onSelectMenu(option);
    };

    useEffect(() => {
        setSelectedOption(props.optionSelected);

        if (
            vaccinesAttention(props.pet.medicalHistory.vaccines) ||
            vaccinesWarning(props.pet.medicalHistory.vaccines)
        ) {
            setHasWarning(true);
        }
    }, [props.optionSelected]);

    const filteredOptions = !isDental
        ? petViewOptions.filter(option => option.id !== 'dental')
        : petViewOptions;

    return (
        <Box className={clsx([classes.container, props.className])}>
            {filteredOptions.map(option => (
                <Box
                    key={option.id}
                    className={clsx(classes.item, {
                        [classes.selectedItem]:
                            props.optionSelected === option.id
                    })}
                    onClick={() => selectMenuHandler(option.id)}
                >
                    <Typography
                        className={clsx(classes.text, {
                            [classes.selectedText]: selectedOption === option.id
                        })}
                    >
                        {option.name}
                    </Typography>
                    {option.name === 'Vaccines' && hasWarning && (
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className={classes.warning}
                        />
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default PetTabSelectorMobile;
