import { TipDetail, TipsReport } from 'model/TipsReport';
import moment from 'moment-timezone';
import store from 'store';

export const convertToTipDetails = (
    tipsDetails: TipsReport,
    staffs: Array<number>
): Array<TipDetail> => {
    const tipsDetailArray: Array<TipDetail> = [];
    const timeZone = store.getState().login.auth.timeZone;

    tipsDetails.invoices.forEach(invoice => {
        const date = invoice.createdAt
            ? moment(invoice.createdAt).tz(timeZone!).format('MM/DD/YYYY')
            : null;
        const invoiceId = invoice.number;
        const paymentMethod = invoice.paymentType;

        invoice.tips.forEach(tip => {
            const staffFiltered = staffs.find(
                staffId => staffId == tip.staffId
            );
            if (tip.amount !== '0.00' && (staffFiltered || !staffs.length)) {
                tipsDetailArray.push({
                    createdAt: date,
                    staffId: tip.staffId,
                    invoiceId: invoiceId,
                    staffName: tip.staffName,
                    staffImage: tip.staffImage,
                    paymentType: paymentMethod,
                    customerName: invoice.customerName,
                    amount: tip.amount
                });
            }
        });
    });

    return tipsDetailArray;
};

export const convertToStaffFilter = (
    staff: Array<number>
): string | undefined => {
    let staffFilter = '';
    if (staff.length) {
        staff.forEach((s: number) => {
            if (!staffFilter) {
                staffFilter += s;
            } else {
                staffFilter += ',' + s;
            }
        });
    }
    return staffFilter || undefined;
};
