import { Box, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { AlertBox } from 'components/UI/Alerts';
import { FunctionComponent, useEffect, useState } from 'react';
import Category from './Category';
import Details from './Details';
import Pricing from './Pricing';
import ServiceStaff from './Staff';
import { BusinessSettingsPath } from 'routes/Paths';
import { useAuth, useMarketplace, useMasterData } from 'hooks';
import Service, { ServiceDuration, StaffMember } from 'model/Service';
import { validateCategoryStep, validateDetailsStep, validatePricingStep, validateService } from './Validations';
import { ACCESS_LEVEL_ADMIN_ID, Option, PetType, Selectable } from '@spike/model';
import _, { isEmpty } from 'lodash';
import { FieldErrorWithKey, ServiceCategoryType } from './model';
import useNonInitialEffect from '@versiondos/hooks';
import { usePricingContext } from './Pricing/PricingContext/PricingContext';
import { MarketplaceDeposit } from '@spike/marketplace-model';
import NoPetTypesAlert from 'components/UI/V2/Info/Variants/NoPetTypesAlert';

interface SectionState {
    collapsed: boolean;
    disabled: boolean;
    editable: boolean;
    completed: boolean;
}

interface ServiceEditProps {
    service: Service;
    selectedCategory: number;
    onChangeService?: (updatedSevice: Service) => void;
    onSave?: (updatedService: Service) => void;
    onSaveDraft?: (updatedService: Service) => void;
    onChangeServiceName?: (name: string) => void;
    onAddTaxes?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alertContainer: {
            marginTop: '25px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '15px',
                marginRight: '15px'
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '20px'
            }
        },
        sectionContainer: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                minWidth: '750px',
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            }
        },
        lastSectionContainer: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: '1000px',
                marginTop: '30px'
            }
        },
        saveMessage: {
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '15px'
            }
        }
    })
);
interface SectionState {
    collapsed: boolean;
    disabled: boolean;
    editable: boolean;
    completed: boolean;
}

export const useSectionState = (initialState: SectionState) => {
    const { deposit } = useMarketplace();

    const [state, setState] = useState<SectionState>(initialState);

    const updateSection = (updates: Partial<SectionState>) => {
        setState(prev => ({
            ...prev,
            ...updates
        }));
    };

    return [state, updateSection] as const;
};

export const validateAndUpdateSections = (
    service: Service,
    deposit: MarketplaceDeposit,
    sections: {
        category: SectionState;
        details: SectionState;
        pricing: SectionState;
        staff: SectionState;
    },
    updateSections: {
        updateCategory: (updates: Partial<SectionState>) => void;
        updateDetails: (updates: Partial<SectionState>) => void;
        updatePricing: (updates: Partial<SectionState>) => void;
        updateStaff: (updates: Partial<SectionState>) => void;
    }
) => {
    const categoryValid = validateCategoryStep(service);
    const detailsValid = validateDetailsStep(service);
    const pricingValid = validatePricingStep(service.pricing, deposit);

    updateSections.updateCategory({
        collapsed: true,
        disabled: !sections.category.editable,
        completed: categoryValid
    });

    updateSections.updateDetails({
        collapsed: true,
        disabled: !sections.details.editable,
        completed: detailsValid
    });

    updateSections.updatePricing({
        collapsed: true,
        disabled: !sections.pricing.editable,
        completed: pricingValid && categoryValid
    });

    updateSections.updateStaff({
        collapsed: true,
        disabled: !sections.staff.editable
    });

    return { categoryValid, detailsValid, pricingValid };
};

const ServiceEdit: FunctionComponent<ServiceEditProps> = props => {
    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const auth = useAuth();
    const { createServicePricingObject, updatePetTypes, showPricingErrors } = usePricingContext();
    const { servicePricingObject } = usePricingContext().state;
    const isAdmin = auth.user!.accessLevel.id === ACCESS_LEVEL_ADMIN_ID;

    const hasNotPetTypes = marketplace.petTypes
        ? !marketplace.petTypes.cats && !marketplace.petTypes.dogs && !marketplace.petTypes.exotics
        : false;

    const [category, updateCategory] = useSectionState({
        collapsed: !(props.selectedCategory === ServiceCategoryType.CATEGORY),
        disabled: hasNotPetTypes,
        editable: false,
        completed: false
    });

    const [details, updateDetails] = useSectionState({
        collapsed: !(props.selectedCategory === ServiceCategoryType.DETAIL),
        disabled: true,
        editable: false,
        completed: false
    });

    const [pricing, updatePricing] = useSectionState({
        collapsed: !(props.selectedCategory === ServiceCategoryType.PRICING),
        disabled: true,
        editable: false,
        completed: false
    });

    const [staff, updateStaff] = useSectionState({
        collapsed: !(props.selectedCategory === ServiceCategoryType.STAFF),
        disabled: true,
        editable: false,
        completed: false
    });

    const [service, setService] = useState(props.service);
    const [errors, setErrors] = useState<Array<FieldErrorWithKey>>([]);

    const updateService = (updates: Partial<Service>) => {
        setService(prev => ({
            ...prev,
            ...updates
        }));
    };

    useNonInitialEffect(() => {
        props.onChangeService && props.onChangeService(service);
    }, [service]);

    useNonInitialEffect(() => {
        updateService({ pricing: servicePricingObject });
    }, [servicePricingObject]);

    const handleSectionEdit = (section: 'category' | 'details' | 'pricing' | 'staff') => {
        if (!isEmpty(errors)) {
            setErrors(validateService(service, marketplace.deposit));
        }

        const sections = { category, details, pricing, staff };
        const updates = { updateCategory, updateDetails, updatePricing, updateStaff };

        if (!pricing.collapsed && section !== 'pricing') {
            createServicePricingObject();
            showPricingErrors(false);
        }

        validateAndUpdateSections(service, marketplace.deposit, sections, updates);

        const updateSection = {
            category: updateCategory,
            details: updateDetails,
            pricing: updatePricing,
            staff: updateStaff
        }[section];

        updateSection({
            collapsed: false,
            completed: false
        });
    };

    const reportChangeHandler = (hasReport: boolean): void => {
        updateService({ hasReport });
    };

    const categoryCollapseHandler = () => {
        updateCategory({ collapsed: true });
    };

    const categoryChangeHandler = (selectedPetTypes: Array<Selectable<PetType>>): void => {
        const petTypes: PetType[] = selectedPetTypes
            .filter((option: Selectable<PetType>) => option.selected)
            .map((option: Selectable<PetType>) => option.element);
        updatePetTypes(petTypes);
        updateService({ petTypes });
    };

    const categoryNextHandler = () => {
        updateCategory({
            collapsed: true,
            editable: true,
            completed: true
        });

        updateDetails({
            collapsed: false,
            disabled: false,
            completed: false
        });
    };

    const detailsNextHandler = () => {
        updateDetails({
            collapsed: true,
            editable: true,
            completed: true
        });

        updatePricing({
            collapsed: false,
            disabled: false,
            completed: false
        });
    };

    const nameChangeHandler = (name: string): void => {
        updateService({ name });
        props.onChangeServiceName?.(name);
    };

    const businessLocationHandler = (isMobile: boolean) => {
        updateService({ houseCall: isMobile });
    };

    const businessAreaChangeHandler = (businessArea: Option<string>): void => {
        updateService({ businessArea });
    };

    const tagsChangeHandler = (tags: Array<string>): void => {
        updateService({ tags });
    };

    const durationChangeHandler = (duration: ServiceDuration): void => {
        updateService({ duration });
    };

    const descriptionChangeHandler = (description: string): void => {
        updateService({ description });
    };

    const onlineBookingHandler = (active: boolean) => {
        updateService({ olbEnabled: active });
    };

    const pricingNextHandler = () => {
        updateService({ pricing: servicePricingObject });

        updatePricing({
            collapsed: true,
            editable: true,
            completed: true
        });

        updateStaff({
            collapsed: false,
            disabled: false,
            completed: false
        });
    };

    const staffChangeHandler = (staff: Array<StaffMember>) => {
        updateService({ staff: [...staff] });
    };

    const staffNextHandler = () => {
        setService(prevService => ({
            ...prevService,
            staff: prevService.staff.sort((member, otherMember) =>
                member.firstName.localeCompare(otherMember.firstName)
            )
        }));

        updateStaff({
            collapsed: true,
            editable: true,
            completed: true
        });

        const serviceErrors = validateService(service, marketplace.deposit);

        const categoryStepCompleted = validateCategoryStep(service);
        const detailStepCompleted = validateDetailsStep(service);
        const pricingStepCompleted = validatePricingStep(service.pricing, marketplace.deposit);

        if (!categoryStepCompleted || !detailStepCompleted || !pricingStepCompleted) {
            setErrors(serviceErrors);
            if (!categoryStepCompleted) {
                handleSectionEdit('category');
            }
            if (!detailStepCompleted) {
                handleSectionEdit('details');
            }
            if (detailStepCompleted && !pricingStepCompleted) {
                handleSectionEdit('pricing');
                showPricingErrors(true);
            }
        } else {
            props.onSave && props.onSave({ ...service });
        }
    };

    const staticOptionsBusinessAreas = [
        { id: 'dental', name: 'Dental' },
        { id: 'grooming', name: 'Grooming' }
    ];

    return (
        <>
            {hasNotPetTypes && (
                <Box className={classes.alertContainer}>
                    <NoPetTypesAlert />
                </Box>
            )}
            <Box className={classes.sectionContainer}>
                <Category
                    {...category}
                    petTypes={masterData.petTypes.map(pt => ({
                        element: pt,
                        selected: service.petTypes.some(spt => spt.id === pt.id)
                    }))}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={false}
                    optionsBusinessAreas={staticOptionsBusinessAreas}
                    onNext={categoryNextHandler}
                    onChange={categoryChangeHandler}
                    onEdit={() => handleSectionEdit('category')}
                    onCollapse={categoryCollapseHandler}
                    onChangeBusinessArea={businessAreaChangeHandler}
                    marketplaceBasics={marketplace.basics}
                />
            </Box>
            <Box className={classes.sectionContainer}>
                <Details
                    {...details}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={isEmpty(service.petTypes)}
                    isAdmin={isAdmin}
                    errors={errors.filter(error => error.key === ServiceCategoryType.DETAIL)}
                    onNext={detailsNextHandler}
                    onEdit={validateCategoryStep(service) ? () => handleSectionEdit('details') : undefined}
                    onNameChange={nameChangeHandler}
                    onDescriptionChange={descriptionChangeHandler}
                    onDurationChange={durationChangeHandler}
                    onTagsChange={tagsChangeHandler}
                    onReportChange={reportChangeHandler}
                    onBusinessLocationChange={businessLocationHandler}
                    onOnlineBookingChange={onlineBookingHandler}
                />
            </Box>
            <Box className={classes.sectionContainer}>
                <Pricing
                    onNext={pricingNextHandler}
                    onCollapse={() => handleSectionEdit('pricing')}
                    onEdit={() => handleSectionEdit('pricing')}
                    collapsed={pricing.collapsed}
                    disabled={isEmpty(service.petTypes)}
                    modifiable={true}
                    completed={pricing.completed}
                    editable={isMobile ? false : true}
                />
            </Box>
            <Box className={classes.lastSectionContainer}>
                <ServiceStaff
                    {...staff}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={isEmpty(service.petTypes)}
                    onNext={staffNextHandler}
                    onEdit={validateCategoryStep(service) ? () => handleSectionEdit('staff') : undefined}
                    onChange={staffChangeHandler}
                />
            </Box>
        </>
    );
};

export default ServiceEdit;
