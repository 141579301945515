import React, { FunctionComponent, useState } from 'react';
import { Box, Typography, OutlinedInput } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CharacterCounter } from '../../UI/CharacterCounter';

interface Props {
    text: string;
    onChange: (text: string) => void;
    max?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        title: {
            fontSize: 20,
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 24,
                fontSize: 16
            }
        },
        descriptionField: {
            'width': '100%',
            'minHeight': 150,
            'lineHeight': 1.4,
            'borderRadius': 19,
            'alignItems': 'flex-start',
            'padding': '19px 20px 30px',

            '& textarea::placeholder': {
                opacity: 1,
                color: '#7A7A7A'
            }
        },
        counter: {
            right: 15,
            bottom: 10,
            zIndex: 10,
            fontSize: 14,
            position: 'absolute'
        }
    })
);

export const ServiceDescription: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [text, setText] = useState(props.text);
    const [contador] = useState(props.max ? props.max : 0);

    const handleChange = (event: any): void => {
        const newText = event.target.value;
        if (props.max) {
            if (text.length < contador || newText.length < text.length) {
                setText(newText);
                props.onChange(newText);
            }
        } else {
            setText(newText);
            props.onChange(newText);
        }
    };

    const counter = (
        <Box className={classes.counter}>
            <CharacterCounter max={contador} text={text} />
        </Box>
    );

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>
                Write a short description
            </Typography>
            {props.max ? (
                <OutlinedInput
                    value={text}
                    multiline={true}
                    className={classes.descriptionField}
                    placeholder="Anything important to note?"
                    onChange={handleChange}
                    endAdornment={counter}
                />
            ) : (
                <OutlinedInput
                    value={text}
                    multiline={true}
                    placeholder="Anything important to note?"
                    className={classes.descriptionField}
                    onChange={handleChange}
                />
            )}
        </Box>
    );
};

export default ServiceDescription;
