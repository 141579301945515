import {
	Box,
	createStyles,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchSignaturesByClientThunk } from 'actions/agreements/AgreementsActions';
import { Signature } from 'model/Agreements';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import NotFound from '../ui/NotFound';
import Filters from './Filters';
import Header from './Header';
import SignaturesTable from '../Table/SignaturesTable';
import SendModal from '../Send/SendModal';
import Client from '@spike/client-model';
import { Spinner } from 'components/UI';
import { ClientEditHeader } from 'components/Client/ClientEditHeader';
import { Theme } from '@material-ui/core';

interface ClientSignaturesProps {
	client: Client;
	onBook: () => void;
	onClose: () => void;
	onSelect: (key: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			transition: 'all 0.33s linear',
			display: 'flex',
			flexFlow: 'column',
			maxHeight: 'calc(100dvh - 69px)',
			flexGrow: 1,
			[theme.breakpoints.down('sm')]: {
				maxHeight: 'calc(100dvh - 49px - 150px)',
				background: '#ffffff'
			}
		},
		tableContainer: {
			'flexGrow': 1,
			'transition': 'all 0.33s linear',
			'overflowY': 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none'
			}
		},
		editWrapper: {
			padding: '24px 16px 0px 16px'
		}
	})
);

const ClientSignatures: FunctionComponent<ClientSignaturesProps> = props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

	const agreementsStatus = useSelector<RootState, AgreementsStatus>(
		state => state.agreements.status
	);
	const signatures = useSelector<RootState, Array<Signature>>(
		state => state.agreements.signaturesByClient
	);

	const [loading, setLoading] = useState(false);
	const [signed, setSigned] = useState(true);
	const [send, setSend] = useState(false);

	const filteredSignatures = signatures.filter(signature =>
		signed ? signature.status === 'signed' : signature.status === 'unsigned'
	);

	const reload = () => {
		setLoading(true);
		dispatch(fetchSignaturesByClientThunk(props.client.id!));
	};

	useEffect(() => {
		reload();
	}, []);

	useNonInitialEffect(() => {
		switch (agreementsStatus) {
			case AgreementsStatus.FetchSignaturesByClientSuccess:
				setLoading(false);
				break;
			case AgreementsStatus.SendSuccess:
				reload();
				setSigned(false);
				break;
			case AgreementsStatus.SignSuccess:
				reload();
				setSigned(true);
				break;
			case AgreementsStatus.DeleteSignatureSuccess:
				reload();
				break;
			case AgreementsStatus.Error:
				setLoading(false);
				break;
		}
	}, [agreementsStatus]);

	return (
		<Box style={{ display: 'flex', flexFlow: 'column', flexGrow: 1 }}>
			{isMobile && (
				<Box className={classes.editWrapper}>
					<ClientEditHeader
						client={props.client}
						onSelect={props.onSelect}
						onBook={props.onBook}
						onClose={props.onClose}
					/>
				</Box>
			)}
			<Box className={classes.root}>
				<Header
					onAdd={
						loading || signatures.length === 0
							? undefined
							: () => setSend(true)
					}
				/>
				{!loading && signatures.length > 0 && (
					<>
						<Filters
							selectedTotalRecords={filteredSignatures.length}
							onSelect={setSigned}
							signed={signed}
						/>
						<Box className={classes.tableContainer}>
							<SignaturesTable
								signatures={filteredSignatures}
								loading={loading}
								hideSignedDate={!signed}
								hideStatus={true}
							/>
						</Box>
					</>
				)}
				{loading && <Spinner />}
				{!loading && signatures.length === 0 && (
					<NotFound
						buttonAction={() => setSend(true)}
						buttonLabel="Send Agreement"
						title="No agreements found"
						subtitle="This client has not received any agreement yet."
					/>
				)}
			</Box>
			{send && (
				<SendModal
					isActive={send}
					client={props.client}
					onClose={() => setSend(false)}
				/>
			)}
		</Box>
	);
};

export default ClientSignatures;
