import { FieldError, Option, PetType } from "@spike/model";
import { useMasterData } from "hooks";
import { Breeds } from "@spike/masterdata-model";
import { FunctionComponent } from "react";
import SelectorFieldSearch from "./SelectorFieldSearch";

interface Props {
  id?: string;
  petType: PetType | undefined;
  name?: string;
  selected?: Option<number>;
  errors?: Array<FieldError>;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (option?: Option<number>, fieldName?: string) => void;
}

export const BreedSelector: FunctionComponent<Props> = (props) => {
  const masterData = useMasterData();

  const breeds = props.petType?.id !== undefined ? masterData.breeds[props.petType.id as keyof Breeds] : [];

  return (
    <SelectorFieldSearch
      {...props}
      options={breeds}
      selectedOption={props.selected}
      label="Breed"
      placeholder="Breed"
      onSelect={(option, fieldName) =>
        props.onChange && props.onChange(option ? { id: Number(option.id), name: option.name } : undefined, fieldName)
      }
      disabled={props.petType === undefined || props.disabled}
    />
  );
};

export default BreedSelector;
