import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CalculatingSpinner } from 'components/UI';
import { FunctionComponent } from 'react';

interface SubItemProps {
	label: string;
	value: string | number;
	bottomLine?: boolean;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		row: (props: SubItemProps) => ({
			width: '100%',
			display: 'flex',
			padding: '8px 0px',
			alignItems: 'center',
			justifyContent: 'space-between',

			[theme.breakpoints.up('md')]: {
				padding: '16px 0px 16px 24px',
				borderBottom: props.bottomLine ? '1px solid #D4D4D4' : undefined
			}
		}),
		text: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 400,
			color: '#7A7A7A'
		}
	})
);

export const SubItem: FunctionComponent<SubItemProps> = props => {
	const classes = useStyles(props);

	return (
		<Box className={clsx(classes.row, props.className)}>
			<Typography className={classes.text}>{props.label}</Typography>
			{props.loading ? (
				<CalculatingSpinner />
			) : (
				<Typography className={classes.text}>{props.value}</Typography>
			)}
		</Box>
	);
};

export default SubItem;
