import { FunctionComponent, useState } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { BodySection, ActionSection } from 'components/UI/Section';
import Section from '../Section';
import ServiceTypeSelector from './UI/ServiceTypeSelector';
import PricingTable from './PricingTable';
import { usePricingContext } from './PricingContext/PricingContext';
import { isEmpty } from 'lodash';
import { TaxesSelector } from 'components/UI/TaxesSelector';
import { MarketplaceTax } from '@spike/marketplace-model';
import { ServicePricing } from 'model/Service';

interface PricingProps {
    disabled?: boolean;
    collapsed?: boolean;
    editable?: boolean;
    completed?: boolean;
    modifiable?: boolean;
    onEdit?: () => void;
    onCollapse?: () => void;
    onChange?: (servicePricingObject: ServicePricing) => void;
    onNext?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        titleDescriptionSelectorContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        titleDescriptionContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        titleContainer: {
            marginBottom: 10
        },
        title: {
            'fontSize': 20,
            'fontWeight': 600,
            '& span': {
                fontWeight: 600,
                color: '#EAB464'
            }
        },
        descriptionContainer: {
            marginBottom: 25
        },
        description: {
            fontSize: 14,
            fontWeight: 400,
            color: '#000',

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            }
        },
        selectorContainer: {
            display: 'flex'
        },
        priceContainer: {
            display: 'flex',
            [theme.breakpoints.down('lg')]: {
                marginTop: '25px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '33px'
            }
        },
        taxable: {
            '& .MuiCheckbox-root': {
                paddingLeft: '0px !important'
            },
            '& p': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '12px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        errorMessage: {
            color: 'red',
            marginTop: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        }
    })
);

const Pricing: FunctionComponent<PricingProps> = props => {
    const classes = useStyles();

    const { updateTaxes, createServicePricingObject } = usePricingContext();
    const { taxes } = usePricingContext().state;

    const [serviceDone, setServiceDone] = useState(false);

    const collapseHandler = () => {
        props.onCollapse && props.onCollapse();
    };

    const editHandler = () => {
        if (!props.disabled) {
            if (props.collapsed && (props.editable || props.modifiable)) {
                props.onEdit && props.onEdit();
            } else {
                props.onCollapse && props.onCollapse();
            }
        }
    };
    const taxesChangeHandler = (taxes: Array<MarketplaceTax>): void => {
        updateTaxes(taxes);
    };

    const finishHandler = () => {
        createServicePricingObject();
        props.onNext && props.onNext();
    };

    return (
        <Section
            position={3}
            title="Pricing"
            onEdit={props.disabled ? undefined : editHandler}
            onCollapse={props.disabled ? undefined : collapseHandler}
            disabled={props.disabled}
            collapsed={props.collapsed}
            modifiable={props.modifiable}
            editable={props.editable}
            completed={props.completed}
        >
            <BodySection>
                <Box className={classes.container}>
                    <Box className={classes.titleDescriptionContainer}>
                        <Box className={classes.titleContainer}>
                            <Typography className={classes.title}>
                                Service <span>*</span>
                            </Typography>
                            <Box className={classes.descriptionContainer}>
                                <Typography className={classes.description}>
                                    Select whether your service has fixed or variable pricing. Variable pricing allows
                                    you to set prices based on pet size, and/or hair length.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <ServiceTypeSelector />
                    <PricingTable onServiceDone={setServiceDone} />
                    <Box>
                        <TaxesSelector
                            id="services_add_service_pricing_taxes_selector"
                            checked={!isEmpty(taxes)}
                            selectedTaxes={taxes}
                            onChange={taxesChangeHandler}
                            title="Taxable Service"
                            subtitle="Choose the applicable tax for this service."
                        />
                    </Box>
                </Box>
            </BodySection>
            <ActionSection
                id="services_add_service_pricing_button_next"
                onNext={finishHandler}
                disabled={!serviceDone}
            />
        </Section>
    );
};

export default Pricing;
