import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldError, Option } from "@spike/model";
import { useMasterData } from "hooks";
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import { PetMedicalCondition } from "@spike/medical-history-model";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import { MedicalCategory as MedicalCategoryModel } from "../../MedicalConditionModel";
import { convertToCategories, convertToPetMedicalCondition } from "../../MedicalHistoryConverter";
import Categories from "./Categories";
import CategoryConditions from "./CategoryConditions";
import SurgeryCondition from "./SurgeryCondition";

interface EditMedicalConditionsProps {
  gender?: Option<string>;
  medicalConditions: Array<PetMedicalCondition>;
  errors?: Array<FieldError>;
  className?: string;
  onChange?: (medicalConditions: Array<PetMedicalCondition>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "5px",  
      },
    },
  })
);

const SurgeryCategoryId = 10;

export const EditMedicalConditions: FunctionComponent<EditMedicalConditionsProps> = (props) => {
  const classes = useStyles();

  const masterData = useMasterData();

  const [editCategories, setEditCategories] = useState(convertToCategories(props.medicalConditions));

  const changeCategoriesSelectionHandler = (selectedIds: Array<number>) => {
    const categories = editCategories.filter((category) => selectedIds.includes(category.categoryId));

    const addedCategoriesIds = selectedIds.filter((id) => !categories.some((category) => category.categoryId === id));

    const updatedCategories = [
      ...categories,
      ...masterData.medicalHistory.medicalConditionCategories
        .filter((category) => addedCategoriesIds.includes(category.id))
        .map((category) => ({
          categoryId: category.id,
          name: category.name,
          conditions: [],
        })),
    ];

    setEditCategories(updatedCategories);

    props.onChange && props.onChange(updatedCategories.flatMap((category) => convertToPetMedicalCondition(category)));
  };

  const changeCategoryHandler = (category: MedicalCategoryModel) => {
    const updatedCategories = editCategories.map((cat) =>
      cat.categoryId === category.categoryId ? cloneDeep(category) : cat
    );
    setEditCategories(updatedCategories);
    props.onChange && props.onChange(updatedCategories.flatMap((category) => convertToPetMedicalCondition(category)));
  };

  return (
    <Grid xs={12} container className={props.className}>
      <Grid xs={12}>
        <Categories
          gender={props.gender}
          selectedCategoryIds={uniq(editCategories.map((category) => category.categoryId))}
          onChange={changeCategoriesSelectionHandler}
        />
      </Grid>
      {editCategories.length > 0 &&
        editCategories.map((category) => (
          <Grid xs={12} key={category.categoryId} className={classes.section}>
            {category.categoryId === SurgeryCategoryId ? (
              <SurgeryCondition category={category} onChange={changeCategoryHandler} errors={props.errors} />
            ) : (
              <CategoryConditions category={category} onChange={changeCategoryHandler} errors={props.errors} />
            )}
          </Grid>
        ))}
    </Grid>
  );
};

export default EditMedicalConditions;
