import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentsShareholder as ShareholderModel } from '@spike/payments-model';
import { FunctionComponent } from 'react';
import { formatPhoneNumber } from '@spike/phone-utils';
import AddressView from '../AddressView';
import { useCommonStyles } from '../CommonStyles';
import { useMarketplace } from 'hooks';

interface ShareholderViewProps {
	shareholder: ShareholderModel;
	className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			display: 'block',

			[theme.breakpoints.up('md')]: {
				marginRight: 8,
				fontWeight: 500,
				display: 'inline-block'
			}
		}
	})
);

export const ShareholderView: FunctionComponent<
	ShareholderViewProps
> = props => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	const marketplace = useMarketplace();

	return (
		<Grid container spacing={2} className={props.className}>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>First Name:</span>{' '}
				{props.shareholder.firstName}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Last Name:</span>{' '}
				{props.shareholder.lastName}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Gender:</span>{' '}
				{props.shareholder.gender?.name}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Birthday:</span>{' '}
				{props.shareholder.dateOfBirth?.format('MM/DD/YYYY')}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Document Type:</span>{' '}
				{props.shareholder.documentType?.name}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Document Number:</span>{' '}
				{props.shareholder.documentNumber}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Phone Number:</span>{' '}
				{formatPhoneNumber(
					props.shareholder.phoneNumber,
					marketplace.basics.address.country!.id
				)}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Email:</span>{' '}
				{props.shareholder.email}
			</Grid>
			<Grid item container xs={12}>
				<AddressView address={props.shareholder.address} />
			</Grid>
		</Grid>
	);
};

export default ShareholderView;
