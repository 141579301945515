import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import {
    fetchBankAccountsThunk,
    saveDocumentBankAccountThunk
} from '@spike/payments-action';
import {
    useApiClientWrapper,
    useHubspotTrackingCode,
    useMasterData
} from 'hooks';
import { PaymentsBankAccount as BankAccountModel } from '@spike/payments-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import FileView from '../Files/FileView';
import StepFrame from '../StepFrame';
import { validateFile } from '../Validations';
import BankAccountDocumentForm from './BankAccountDocumentForm';

interface BankAccountDocumentStepProps {
    bankAccountId?: number | string;
    loading?: boolean;
    className?: string;
    onPrevious?: () => void;
    onNext?: () => void;
    onCompleted?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: 650,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: 'auto'
            }
        }
    })
);

export const BankAccountDocumentStep: FunctionComponent<
    BankAccountDocumentStepProps
> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const masterData = useMasterData();
    const apiClientWrapper = useApiClientWrapper();

    const { setTrackEvent } = useHubspotTrackingCode();

    const bankAccount = useSelector<RootState, Array<BankAccountModel>>(
        state => state.payments.bankAccounts
    ).find(bankAccount =>
        props.bankAccountId
            ? bankAccount.id === props.bankAccountId
            : bankAccount.primary
    );

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [newBankAccountDocument, setNewBankAccountDocument] =
        useState<File | null>(null);

    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [saving, setSaving] = useState(false);
    const [documentDeleted, setDocumentDeleted] = useState(false);

    const file = newBankAccountDocument
        ? {
              name: newBankAccountDocument.name,
              size: newBankAccountDocument.size
          }
        : bankAccount?.document && !documentDeleted
        ? {
              name: bankAccount!.document!.fileName!,
              size: bankAccount!.document!.fileSize!
          }
        : undefined;

    const finish = () => {
        props.onNext && props.onNext();
        props.onCompleted && props.onCompleted();
    };

    useEffect(() => {
        if (!bankAccount) {
            dispatch(fetchBankAccountsThunk(apiClientWrapper));
        }
    }, []);

    useNonInitialEffect(() => {
        if (PaymentsStatus.SaveDocumentBankAccountSuccess === paymentsStatus) {
            setSaving(false);
            finish();
        } else if (PaymentsStatus.Error === paymentsStatus) {
            setSaving(false);
        }
    }, [paymentsStatus]);

    const addFileHandler = (file: File) => {
        setNewBankAccountDocument(file);
        setErrors([]);
    };

    const removeFileHandler = () => {
        setNewBankAccountDocument(null);
        setDocumentDeleted(true);
        setErrors([]);
    };

    const saveHandler = () => {
        if (
            newBankAccountDocument ||
            !bankAccount?.document ||
            documentDeleted
        ) {
            const errors = validateFile(
                newBankAccountDocument,
                masterData,
                'bank account'
            );
            setErrors(errors);
            if (errors.length === 0) {
                setSaving(true);
                setTrackEvent(
                    `${process.env.REACT_APP_HUBSPOT_EVENT_CUDDLESPAY_BANK_DOCUMENTS}`
                );

                dispatch(
                    saveDocumentBankAccountThunk(
                        apiClientWrapper,
                        bankAccount!.id!,
                        newBankAccountDocument!
                    )
                );
            }
        } else {
            finish();
        }
    };

    return (
        <Box className={classes.container}>
            {props.onCompleted || props.onNext ? (
                <StepFrame
                    btnId="cuddlespay-bank-documents"
                    title="Bank Account Details"
                    onPrevious={props.onPrevious}
                    onNext={props.onNext ? saveHandler : undefined}
                    onCompleted={props.onCompleted ? saveHandler : undefined}
                    saving={saving || props.loading}
                    errors={errors.map(error => error.errorMessage)}
                    className={props.className}
                >
                    <BankAccountDocumentForm
                        file={file}
                        onAddFile={addFileHandler}
                        onRemoveFile={removeFileHandler}
                    />
                </StepFrame>
            ) : (
                <FileView
                    fileUrl={bankAccount?.document?.url || ''}
                    fileName={bankAccount?.document?.fileName || ''}
                    fileSize={bankAccount?.document?.fileSize || 0}
                />
            )}
        </Box>
    );
};

export default BankAccountDocumentStep;
