import React, { FunctionComponent } from 'react';
import Button from '../Button';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
    onSave: () => void;
    onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'width': '100%',
            'textAlign': 'right',
            'padding': '20px 23px',
            'borderTop': '1px solid #D4D4D4',
            [theme.breakpoints.down('xs')]: {
                gap: 10,
                display: 'flex',
                flexDirection: 'row'
            },
            '& button': {
                'height': 55,
                'padding': '0px 23px',
                [theme.breakpoints.down('xs')]: {
                    flex: 1
                },
                '& + button': {
                    marginLeft: 13,
                    [theme.breakpoints.down('xs')]: {
                        marginLeft: 0
                    }
                }
            }
        }
    })
);

const ChooseModalFooter: FunctionComponent<Props> = ({ onCancel, onSave }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Button
                size="large"
                color="black"
                label="Cancel"
                onClick={onCancel}
            />
            <Button size="large" color="green" label="Save" onClick={onSave} />
        </Box>
    );
};

export default ChooseModalFooter;
