import { Action } from 'redux';
import { BulkUpload } from '../../model/BulkUpload';

export const BULKUPLOAD_START_UPLOAD_FILE_TYPE = 'BULKUPLOAD_START_UPLOAD';
export const BULKUPLOAD_ERROR_UPLOADING_FILE_TYPE =
	'BULKUPLOAD_ERROR_UPLOADING_FILE';
export const BULKUPLOAD_PROGRESS_TYPE = 'BULKUPLOAD_PROGRESS';

interface BulkUploadStartUploadAction
	extends Action<typeof BULKUPLOAD_START_UPLOAD_FILE_TYPE> {
	payload: {
		bulkUpload: BulkUpload;
	};
}

interface BulkUploadProgressAction
	extends Action<typeof BULKUPLOAD_PROGRESS_TYPE> {
	payload: {
		event: string;
		errors: number;
		percentage: number;
		record_number: number;
		successfully_imported: number;
		total_records: number;
	};
}

type BulkUploadFileErrorAction = Action<typeof BULKUPLOAD_ERROR_UPLOADING_FILE_TYPE>

export type BulkUploadAction =
	| BulkUploadStartUploadAction
	| BulkUploadFileErrorAction
	| BulkUploadProgressAction;
