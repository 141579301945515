import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { Switch } from 'components/UI';
import { FunctionComponent, useState } from 'react';

export interface RowSwitchProps {
    label: string;
    checked: boolean;
    name: string;
    errors: Array<FieldError>;
    className?: string;
    isMobile?: boolean;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: (props: RowSwitchProps) => ({
            display: 'flex',
            alignItems: 'center'
        }),
        left: (props: RowSwitchProps) => ({
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            marginRight: 15
        }),
        right: (props: RowSwitchProps) => ({
            display: 'flex',
            alignItems: 'center'
        }),
        label: (props: RowSwitchProps) => ({
            color: '#222222',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%'
            }
        }),
        error: {
            color: '#EA6464',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            }
        },
        select: (props: RowSwitchProps) => ({
            [theme.breakpoints.down(wbp)]: {
                minWidth: props.isMobile ? '100%' : `${reduceResolution(250)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                minWidth: props.isMobile ? '100%' : '250px'
            }
        })
    })
);

export const RowSwitch: FunctionComponent<RowSwitchProps> = props => {
    const classes = useStyles(props);

    const [checked, setChecked] = useState(props.checked);

    const error = props.errors.find(error => error.fieldName === props.name);

    const changeHandler = (checked: boolean) => {
        setChecked(checked);
        props.onChange && props.onChange(checked);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.left}>
                <Typography className={classes.label}>{props.label}</Typography>
                {error && (
                    <Typography className={classes.error}>
                        {error.errorMessage}
                    </Typography>
                )}
            </Box>
            <Box className={classes.right}>
                <Switch
                    disabled={props.disabled}
                    checked={checked}
                    onChange={checked => changeHandler(checked)}
                ></Switch>
            </Box>
        </Box>
    );
};

export default RowSwitch;
