import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Switch } from "components/UI";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { NewBookingAlert } from "@spike/new-booking-model";
import { AlertNewBooking } from "../UI/AlertNewBooking";
import clsx from "clsx";

interface Props {
    active: boolean;
    servicePrice?: number;
    alerts?: Array<NewBookingAlert>;
    readOnly?: boolean;
    onChangeDeposit: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderTop: "1px solid #D4D4D4",
            paddingTop: 18,
            paddingBottom: 1,
            [theme.breakpoints.up('md')]: {
                paddingTop: 20
            },
        },
        titleContainer: {
            display: "flex",
            marginBottom: 18,
            paddingRight: 9,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20,
                paddingRight: 10,
            },
        },
        title: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "16px",
            paddingRight: 9,
            [theme.breakpoints.up('md')]: {
                paddingRight: 10
            }
        },
        alert: { 
            marginBottom: "0px !important"
        },
        alertText: {
            fontSize: 14,
            lineHeight: "20px"
        },
        disabled: {
            pointerEvents: "none",
            cursor: "default"
        }
    })
);

export const DepositSelector: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, {[classes.disabled]: props.readOnly})}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>Deposit</Typography> 
                { props.alerts?.length === 0 && 
                    <Switch 
                        id="booking_button_deposit"
                        checked={props.active} 
                        onChange={props.onChangeDeposit} 
                    />}
            </Box>
            {props.alerts?.map(alert => 
                <Box 
                    key={`booking_div_${alert.alertType}_alert`}
                    id={`booking_div_${alert.alertType}_alert`}
                >
                    <AlertNewBooking
                        className={classes.alert}
                        bWidth="2px"
                        icon={faExclamationTriangle}
                        iconSize="lg"
                        iconColor="#EAB464"
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                    >
                        <Typography className={classes.alertText}>
                            Sorry, but deposits for this appointment are not available, this is because the client have other appointment(s) for the same day.
                        </Typography>
                    </AlertNewBooking>
                </Box>
            )}
        </Box>
    )
};

export default DepositSelector