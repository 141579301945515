import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

interface Props {
    item: SidebarItem;
    selected: string;
    onSelectItem: (itemMenu: SidebarItem) => void;
    disabled?: boolean;
    itemAccordion?: boolean;
}

export interface SidebarItem {
    id: string;
    name: string;
    isNew?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '100%',
            '&:hover': {
                cursor: (props: Props) =>
                    props.disabled ? 'not-allowed' : 'pointer'
            },
            'backgroundColor': '#FAEFDF',
            [theme.breakpoints.down('md')]: {
                borderRadius: '50px',
                height: '16px',
                paddingLeft: '16px'
            },
            [theme.breakpoints.only('lg')]: {
                height: 29,
                paddingLeft: 21,
                paddingRight: 8,
                borderRadius: 66
            },
            [theme.breakpoints.only('xl')]: {
                height: 33,
                paddingLeft: 32,
                paddingRight: 10,
                borderRadius: 100
            }
        },
        itemMenuContainerSelected: {
            backgroundColor: '#FAEFDF'
        },
        itemMenuContainer: {
            '&:hover': {
                backgroundColor: '#F5F5F5'
            },
            'backgroundColor': '#FFF'
        },
        itemMenuContainerAccordion: {
            [theme.breakpoints.down('sm')]: {
                'paddingLeft': '28px',
                '& $itemContainer': {
                    'display': 'flex',
                    'alignItems': 'center',
                    'justifyContent': 'space-between',
                    '& $gotolink': {
                        fontSize: '14px'
                    }
                }
            }
        },
        itemContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [theme.breakpoints.down('md')]: {
                fontSize: '9px',
                lineHeight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px',
                lineHeight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '16px',
                lineHeight: '30px'
            }
        },
        itemSelected: {
            fontWeight: 600,
            color: '#D08B25'
        },
        item: {
            color: '#000'
        },
        gotolink: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        itemSpan: {
            gap: 8,
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'space-between'
            }
        },
        newBadge: {
            'fontSize': 10,
            'lineHeight': 1,
            'fontWeight': 600,
            'color': '#ffffff',
            'borderRadius': 5,
            'padding': '5px 4px',
            'backgroundColor': '#EAB464',

            [theme.breakpoints.up('xl')]: {
                fontSize: 12
            },

            '& svg': {
                marginRight: 3
            }
        }
    })
);

export const ItemMenu: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const isSelected = props.item.id === props.selected;
    const isItemAccordion = props.itemAccordion;

    return (
        <Box
            onClick={() => props.onSelectItem(props.item)}
            className={clsx(classes.container, {
                [classes.itemMenuContainerSelected]: isSelected,
                [classes.itemMenuContainer]: !isSelected,
                [classes.itemMenuContainerAccordion]: isItemAccordion
            })}
        >
            <Typography
                className={clsx(classes.itemContainer, {
                    [classes.itemSelected]: isSelected,
                    [classes.item]: !isSelected
                })}
            >
                <span className={classes.itemSpan}>
                    {props.item.name}
                    {props.item.isNew && (
                        <span className={classes.newBadge}>
                            <FontAwesomeIcon icon={faSparkles} />
                            New
                        </span>
                    )}
                </span>

                {isItemAccordion && (
                    <FontAwesomeIcon
                        className={classes.gotolink}
                        icon={faAngleRight}
                        size="2x"
                    />
                )}
            </Typography>
        </Box>
    );
};

export default ItemMenu;
