import { Box, Typography, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Option } from "@spike/model";
import clsx from "clsx";
import { useMasterData } from "hooks";
import { MedicalConditionCategory } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import MedicalCategorySection from "../../MedicalCategorySection";
import { MedicalCategory as MedicalCategoryModel } from "../../MedicalConditionModel";
import Condition from "./Condition";
import { FieldError } from "@spike/model";

export interface CategoryConditionsProps {
  category: MedicalCategoryModel;
  errors?: Array<FieldError>;
  className?: string;
  onChange?: (category: MedicalCategoryModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conditions: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "15px",
        paddingRight: "0px",
        paddingTop: "5px",
        paddingBottom: "10px",
      },
    },
    condition: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
      },
    },
    error: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#EA6464",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(17)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "17px",
      },
    },
  })
);

export const CategoryConditions: FunctionComponent<CategoryConditionsProps> = (props) => {
  const classes = useStyles();

  const masterData = useMasterData();

  const masterDataCategory = masterData.medicalHistory.medicalConditionCategories.find(
    (category) => category.id === props.category.categoryId
  );

  const hasError = props.errors?.some(error => error.fieldName === `category_${props.category.categoryId}`) || false;

  const selectConditionHandler = (
    masterDataCategory: MedicalConditionCategory,
    conditionId: number,
    selected: boolean
  ) => {
    const conditions = props.category.conditions.filter((condition) => condition.conditionId !== conditionId);
    if (selected) {
      const masterDataCondition = masterDataCategory.options.find((option) => option.id === conditionId)!;
      conditions.push({
        conditionId: masterDataCondition.id,
        name: masterDataCondition.name,
      });
    }
    const updatedCategory = { ...props.category, conditions };
    props.onChange && props.onChange(updatedCategory);
  };

  const changeConditionHandler = (
    conditionId: number,
    description?: string,
    medications?: string,
    diagnosis?: Option<string>
  ) => {
    const updatedCategory = {
      ...props.category,
      conditions: props.category.conditions.map((condition) =>
        condition.conditionId === conditionId ? { ...condition, description, medications, diagnosis } : condition
      ),
    };
    props.onChange && props.onChange(updatedCategory);
  };

  return (
    <MedicalCategorySection
      categoryName={props.category.name}
      categoryId={props.category.categoryId}
      className={props.className}
    >
      <Box className={classes.conditions}>
        {hasError && <Typography className={classes.error}>{props.errors?.find(error => error.fieldName === `category_${props.category.categoryId}`)!.errorMessage}</Typography>}
        {masterDataCategory?.options.map((condition, index) => {
          const editedOption = props.category.conditions.find((cond) => cond.conditionId === condition.id);

          return (
          <Condition
            key={condition.id}
            name={condition.name}
            description={editedOption?.description}
            medications={editedOption?.medications}
            diagnoses={editedOption?.diagnosis}
            selected={editedOption !== undefined}
            onSelect={(selected) => selectConditionHandler(masterDataCategory, condition.id, selected)}
            onChange={(description, medications, diagnosis) =>
              changeConditionHandler(condition.id, description, medications, diagnosis)
            }
            className={clsx({ [classes.condition]: index > 0 })}
          />
        )})}
      </Box>
    </MedicalCategorySection>
  );
};

export default CategoryConditions;
