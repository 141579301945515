import {
    Box,
    FormLabel,
    Grid,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Client from '@spike/client-model';
import { faEnvelope, faMobile } from '@fortawesome/pro-regular-svg-icons';
import { formatPhoneNumber } from '@spike/phone-utils';
import { useMarketplace } from 'hooks';
import { FunctionComponent } from 'react';

interface ClientInfoProps {
    onEdit: (() => void) | undefined;
    clientInfo: Client;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        group: {
            'position': 'relative',
            '& label': {
                fontSize: 16,
                fontWeight: 600,
                color: '#000'
            }
        },
        clientInfo: {
            'marginTop': 19,
            'marginBottom': 40,
            'width': '100%',
            'borderRadius': 7,
            'padding': '15px 25px',
            'backgroundColor': '#FBFBFB',
            'fontSize': 14,
            'lineHeight': 1,
            'fontWeight': 400,

            '& svg': {
                fontSize: 16,
                marginRight: 7,
                verticalAlign: 'middle'
            }
        },
        clientName: {
            'fontSize': 15,
            'lineHeight': 1,
            'fontWeight': 600,
            '&:not(:last-child)': {
                marginBottom: 10
            }
        },
        infoGap: {
            gap: 20
        },
        email: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    })
);

const ClientInfo: FunctionComponent<ClientInfoProps> = ({ clientInfo }) => {
    const classes = useStyles();
    const marketplace = useMarketplace();

    return (
        <Box className={classes.group}>
            <FormLabel>Client Information</FormLabel>
            <Box className={classes.clientInfo}>
                <Typography className={classes.clientName}>
                    {clientInfo?.firstName} {clientInfo?.lastName}
                </Typography>

                <Grid container className={classes.infoGap}>
                    {clientInfo?.email && (
                        <Grid xs={12} sm={12} item className={classes.email}>
                            <FontAwesomeIcon icon={faEnvelope} />
                            {clientInfo?.email}
                        </Grid>
                    )}
                    {clientInfo?.phone && (
                        <Grid xs={12} sm={12} item>
                            <FontAwesomeIcon icon={faMobile} />
                            {formatPhoneNumber(
                                clientInfo?.phone || '',
                                marketplace.basics.address.country!.id
                            )}
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};

export default ClientInfo;
