import React from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
	className?: string;
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'width': '100%',
			'borderRadius': 16,
			'backgroundColor': '#fff',
			'border': '1px solid #D4D4D4',

			'& hr': {
				border: 'none',
				margin: '15px 0px',
				borderTop: 'solid 1px #d4d4d4',

				[theme.breakpoints.up('md')]: {
					margin: '20px 0px'
				}
			},
			'& label': {
				fontSize: 14,
				fontWeight: 400,
				lineHeight: 1.2,
				color: '#7A7A7A',
				marginBottom: 4,
				display: 'block',

				[theme.breakpoints.up('md')]: {
					fontSize: 16,
					marginBottom: 8
				}
			},
			'& h1': {
				margin: 0,
				fontSize: 18,
				lineHeight: 1,
				fontWeight: 600,
				color: '#5E8677',

				[theme.breakpoints.up('md')]: {
					fontSize: 24
				}
			},
			'& h2': {
				'fontSize': 16,
				'fontWeight': 400,
				'lineHeight': 1.2,
				'color': '#7A7A7A',
				'display': 'block',

				'& strong, & b': {
					fontSize: 24,
					lineHeight: 1,
					fontWeight: 600,
					color: '#000000',
					verticalAlign: 'middle',

					[theme.breakpoints.up('md')]: {
						fontSize: 24
					}
				}
			},
			'& h3': {
				'fontSize': 16,
				'fontWeight': 400,
				'lineHeight': 1.2,
				'color': '#7A7A7A',
				'display': 'block',

				'& strong, & b': {
					fontSize: 18,
					lineHeight: 1,
					fontWeight: 600,
					color: '#000000',
					verticalAlign: 'middle'
				}
			},
			'& .MuiTypography-body1': {
				fontSize: 14,
				lineHeight: 1.4,
				color: '#000000'
			},
			'& .MuiTypography-body2': {
				fontSize: 14,
				lineHeight: 1.4,
				color: '#000000',

				[theme.breakpoints.up('md')]: {
					fontSize: 16
				}
			},
			'& p': {
				'& strong': {
					fontWeight: 600
				},
				'& b': {
					fontWeight: 500
				}
			},
			'& ul': {
				'paddingLeft': 25,
				'listStyle': 'none',

				[theme.breakpoints.up('md')]: {
					paddingLeft: 40
				},

				'& li': {
					marginBottom: 14
				}
			}
		}
	})
);

export const PlanCard: React.FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(props.className, classes.root)}>
			{props.children}
		</Box>
	);
};
