import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ConfirmDialog } from "components/UI";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";

interface CategoryProps {
  id: number;
  name: string;
  icon?: IconDefinition;
  selected: boolean;
  onChange?: (selected: boolean) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(150)}px`,
        height: `${reduceResolution(130)}px`,
        borderRadius: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "150px",
        height: "130px",
        borderRadius: "16px",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "auto",
        borderRadius: "0px",
        border: "none",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: "30px"
      },
    },
    body: {
      display: "flex",
      width: "100%",
      height: "50%",
      alignItems: "flex-end",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        width: "auto",
      }
    },
    footer: {
      display: "flex",
      width: "100%",
      height: "50%",
      alignItems: "flex-start",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px ${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "10px 20px",
      },
      [theme.breakpoints.down("md")]: {
        height: "auto",
        justifyContent: "flex-start",
        paddingLeft: "8px"
      }
    },
    name: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontColor: "#222222",
      textAlign: "center",
      lineHeight: "100%",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        fontWeight: "500"
      }
    },
    icon: {
      fontColor: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(28)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "28px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        minWidth: "28px",
      },
    },
    selectIcon: {
      position: "absolute",
      color: "#DDDDDD",
      backgroundColor: "#fafafa",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(28)}px`,
        top: `${reduceResolution(-12)}px`,
        right: `${reduceResolution(-12)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "28px",
        top: "-12px",
        right: "-12px",
      },
      [theme.breakpoints.down("md")]: {
        top: "5px",
        right: "auto",
        left: "0px",
        color: "#000",
        backgroundColor: "#FFF",
        fontSize: "21px"
      },
    },
    selectedIcon: {
      color: "#92B4A7",
      [theme.breakpoints.down("md")]: {
        color: "#000",
      }
    },
  })
);

export const Category: FunctionComponent<CategoryProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const showIcon = () => {
    if (props.selected) {
      return (isSmall ? faCheckSquare : faCheckCircle)
    } else {
      return isSmall ? faSquare : faCircle;
    }
  };

  return (
    <>
      <Box
        className={clsx(classes.container, props.className)}
        onClick={(event) =>
          props.selected ? setShowDeleteConfirmation(true) : props.onChange && props.onChange(!props.selected)
        }
      >
        <Box className={classes.body}>
          {props.icon && <FontAwesomeIcon className={classes.icon} icon={props.icon} />}
        </Box>
        <Box className={classes.footer}>
          <Typography className={classes.name}>{props.name}</Typography>
        </Box>
        <FontAwesomeIcon
          className={clsx(classes.selectIcon, { [classes.selectedIcon]: props.selected })}
          icon={showIcon()}
        />
      </Box>
      {showDeleteConfirmation && (
        <ConfirmDialog
          open={true}
          title={<Typography>{props.name}</Typography>}
          question={
            <Typography>
              Do you want to <strong>delete</strong> this
              <br /> Category?
            </Typography>
          }
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Delete"
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => {
            setShowDeleteConfirmation(false);
            props.onChange && props.onChange(!props.selected);
          }}
        />
      )}
    </>
  );
};

export default Category;
