import {
    Box,
    createStyles,
    makeStyles,
    OutlinedInput,
    Theme,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { FunctionComponent, useState } from 'react';

interface Props {
    remark: string;
    isReport: boolean;
    onChange: (value: string) => void;
    totalDecline?: boolean;
    className?: string;
    componentClient?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleSection: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 600,
            color: '#000000',
            marginBottom: 24,

            [theme.breakpoints.up('md')]: {
                fontSize: 14,
                fontWeight: 400,
                color: '#A3A3A3',
                textTransform: 'uppercase'
            }
        },
        noteContainer: {
            zIndex: 0,
            height: 139,
            fontSize: 16,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            borderRadius: 10,

            [theme.breakpoints.up('md')]: {
                height: '297px',
                marginRight: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: '14px',
                height: '450px',
                fontSize: '18px',
                marginRight: '20px'
            }
        },
        noteContainerSummary: (props: Props) => ({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            pointerEvents: 'none',
            borderRadius: 10,
            minHeight: 190,

            [theme.breakpoints.up('md')]: {
                width: props.totalDecline ? '533px' : '503px',
                height: props.totalDecline ? '135px' : '221px',
                fontSize: '14px',
                marginRight: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: '14px',
                width: props.totalDecline ? '711px' : '670px',
                height: props.totalDecline ? '180px' : '295px',
                fontSize: '18px',
                marginRight: '20px'
            }
        }),
        clientView: {
            [theme.breakpoints.down('lg')]: {
                width: '400px',
                marginRight: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '600px'
            }
        }
    })
);

export const Remarks: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const [text, setText] = useState(props.remark);

    const handleChangeNote = (value: string) => {
        setText(value);
        let remarkAux = props.remark.slice();
        remarkAux = value;
        props.onChange(remarkAux);
    };

    return (
        <Box className={props.className}>
            {(props.isReport ||
                (!isEmpty(props.remark) && !props.isReport)) && (
                <Typography className={classes.titleSection}>
                    Remarks
                </Typography>
            )}
            {(props.isReport ||
                (!isEmpty(props.remark) && !props.isReport)) && (
                <Box
                    className={
                        props.isReport
                            ? classes.noteContainer
                            : classes.noteContainerSummary
                    }
                >
                    <OutlinedInput
                        multiline={true}
                        value={text}
                        className={clsx(
                            props.isReport
                                ? classes.noteContainer
                                : classes.noteContainerSummary,
                            { [classes.clientView]: props.componentClient }
                        )}
                        onChange={e => handleChangeNote(e.target.value)}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Remarks;
