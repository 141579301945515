import React, { Fragment, FunctionComponent } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import TitlePrimary from '../UI/Title/TitlePrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCommentSms } from '@fortawesome/pro-light-svg-icons';
import { wbp } from '../../Theme';
import { HelpCenterPath } from '../../routes/Paths';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            fontSize: 15,
            lineHeight: 1.4,
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        borderContainer: {
            padding: '20px',
            display: 'flex',
            width: '75%',
            alignSelf: 'center',
            justifyContent: 'center'
        },
        borderContainerContact: {
            border: '1px solid #DDDDDD',
            borderRadius: '24px',
            padding: '20px',
            display: 'flex',
            marginRight: 10
        },
        icon: {
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                width: '22px !important',
                height: '22px !important'
            },
            [theme.breakpoints.up(wbp)]: {
                width: '28px !important',
                height: '28px !important'
            },
            float: 'left'
        },
        paragraph: {
            fontSize: 15,
            lineHeight: 1.4
        },
        button: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: 35,
            paddingRight: 35,
            margin: '0px auto',
            display: 'inline-block'
        },
        calendar: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '100%',
            color: '#000000',
            marginBottom: '20px',
            marginTop: '10px',
            textAlign: 'center'
        },
        firstHelpText: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '100%',
            color: '#000000',
            marginBottom: '10px',
            marginTop: '10px',
            textAlign: 'left'
        },
        secondHelpText: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '140%',
            color: '#000000',
            marginBottom: '10px',
            textAlign: 'left'
        },
        thirdHelpText: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '34px',
            color: '#222222'
        },
        imageContact: {
            width: '41px',
            height: '41px',
            marginRight: '10px'
        },
        arrowRight: {
            fontWeight: 400,
            fontSize: '30px',
            alignSelf: 'center'
        },
        linkSms: {
            textDecoration: 'none'
        }
    })
);

export const HelpSupport: FunctionComponent = () => {
    const classes = useStyles();

    const redirectHelpCenter = () => {
        const x = document.getElementById('help');
        x?.click();
    };

    return (
        <Fragment>
            <Box className={classes.root}>
                <TitlePrimary>Help & Support</TitlePrimary>
                <Typography className={classes.paragraph}>
                    Cuddles Support Team is available
                </Typography>
                <Typography className={classes.calendar}>
                    Mon-Fri 8AM-8PM EST
                </Typography>
                <Typography className={classes.paragraph}>
                    If it&apos;s outside of business hours please email us at{' '}
                    <a
                        style={{ color: '#EAB464' }}
                        href="mailto:help@cuddlesapp.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        help@cuddlesapp.com.
                    </a>
                </Typography>
                <Typography className={classes.paragraph}>
                    We&apos;ll get back to you when business hours resume.
                </Typography>
                <Typography className={classes.paragraph}>
                    If it is an emergency, please still email or text the team
                    as we will monitor these channels in the event of an
                    incident that has significant impact to your ability to run
                    your business.
                </Typography>

                <Box className={classes.borderContainer}>
                    <Box
                        className={classes.borderContainerContact}
                        onClick={() => redirectHelpCenter()}
                    >
                        <div>
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    icon={faBook}
                                    className={classes.icon}
                                />
                            </div>
                            <Typography className={classes.firstHelpText}>
                                Help Center
                            </Typography>
                            <Typography className={classes.secondHelpText}>
                                Learn how to use Cuddles with our user-friendly
                                guides.
                            </Typography>
                        </div>
                        <Typography className={classes.arrowRight}>
                            {'>'}
                        </Typography>
                        <a
                            id="help"
                            href={HelpCenterPath}
                            target="_blank"
                            rel="noreferrer"
                        />
                    </Box>
                    {/*      <Box className={classes.borderContainerContact}>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    icon={faComments}
                                    className={classes.icon}
                                />
                            </div>
                            <Typography className={classes.firstHelpText}>
                                Live Chat
                            </Typography>
                            <Typography className={classes.secondHelpText}>
                                Chat with one of our specialists for
                                personalized assistance.{' '}
                            </Typography>
                        </div>
                        <Typography className={classes.arrowRight}>
                            {'>'}
                        </Typography>
                    </Box>*/}
                    <Box className={classes.borderContainerContact}>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    icon={faCommentSms}
                                    className={classes.icon}
                                />
                            </div>
                            <Typography className={classes.firstHelpText}>
                                Text Support
                            </Typography>
                            <Typography className={classes.secondHelpText}>
                                Text us at{' '}
                                <a
                                    className={classes.linkSms}
                                    href="sms:+17863776277"
                                >
                                    <span style={{ color: '#EAB464' }}>
                                        786-377-6277
                                    </span>{' '}
                                </a>
                                and we&apos;ll be in touch with you soon.
                            </Typography>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
};

export default HelpSupport;
