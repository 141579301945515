import Marketplace from '@spike/marketplace-model';
import { BusinessHours, createEmptyWeek, Period, Week } from '@spike/model';
import values from 'lodash/values';
import { StaffSchedule, StaffScheduleCustomDay, StaffScheduleByDate } from 'model/Staff';
import moment, { Moment } from 'moment-timezone';

export enum ScheduleStatus {
    MISSING = 'missing',
    DEFAULT = 'default',
    CUSTOMIZED = 'customized'
}

export const isEmpty = (schedule: StaffSchedule) => {
    return !isCustomized(schedule) && !isDefault(schedule);
};

export const isDefault = (schedule: StaffSchedule) => {
    return values(schedule.default).some(businessHours => businessHours !== null);
};

export const isCustomized = (schedule: StaffSchedule) => {
    return schedule.customDays.length > 0;
};

export const isCustomizedFromThisWeekOn = (schedule: StaffSchedule) => {
    const startOfWeek = moment().startOf('isoWeek');
    const result = schedule.customDays.some(customDay => customDay.from.isSameOrAfter(startOfWeek));
    return result;
};

export const getStatusFromThisWeekOn = (schedule: StaffSchedule) => {
    if (isDefault(schedule) && !isCustomizedFromThisWeekOn(schedule)) {
        return ScheduleStatus.DEFAULT;
    } else if (isCustomizedFromThisWeekOn(schedule)) {
        return ScheduleStatus.CUSTOMIZED;
    } else {
        return ScheduleStatus.MISSING;
    }
};

export const createEmptySchedule = (): StaffSchedule => {
    return {
        default: createEmptyWeek(),
        customDays: []
    };
};

export const removeCustomsEqualsAsDefault = (customDays: Array<StaffScheduleCustomDay>, defaultSchedule: Week) => {
    return customDays.filter(customDay => {
        const weekDay = customDay.from.clone().format('dddd').toLowerCase() as keyof Week;
        const defaultWeekDay = defaultSchedule[weekDay];

        if (!customDay.on && (defaultWeekDay === null || defaultWeekDay === undefined)) {
            return false;
        } else if (customDay.on && defaultWeekDay) {
            return (
                customDay.from.clone().format('H:mm') !== defaultWeekDay.open ||
                customDay.to.clone().format('H:mm') !== defaultWeekDay.close
            );
        } else {
            return true;
        }
    });
};

export const getDefaultScheduleFromDate = (schedule: StaffSchedule, date: Moment) => {
    const weekDay = date.clone().format('dddd').toLowerCase() as keyof Week;

    return schedule.default[weekDay] || null;
};

export const getCustomScheduleFromDate = (schedule: StaffSchedule, date: Moment) => {
    return schedule.customDays.find(day => day.from.isSame(date, 'day'));
};

/**
 * Get the staff schedule for a given date
 * Checks for special hours and default schedule from staff and business
 */
export const getStaffScheduleByDate = (
    marketplace: Marketplace,
    schedule: StaffSchedule,
    date: Moment,
    timeZone: string
): StaffScheduleByDate => {
    const weekDay = date.format('dddd').toLowerCase();

    const staffDefaultSchedule = getDefaultScheduleFromDate(schedule, date);
    const staffSpecialHour = getCustomScheduleFromDate(schedule, date);
    const businessDateSchedule = marketplace.schedule.week[weekDay as keyof Week];
    const businessSpecialHour = marketplace.schedule.specialHours.find(specialHour =>
        moment(specialHour.date).isSame(date, 'day')
    );

    const isStaffAvailable = staffSpecialHour?.on ?? !!staffDefaultSchedule;
    const isBusinessClosed = businessSpecialHour?.isClosed ?? !businessDateSchedule;

    const from =
        staffSpecialHour?.from.clone() ||
        moment(
            staffDefaultSchedule?.open || businessSpecialHour?.businessHours?.open || businessDateSchedule?.open,
            'HH:mm'
        ).tz(timeZone, true);
    const to =
        staffSpecialHour?.to.clone() ||
        moment(
            staffDefaultSchedule?.close || businessSpecialHour?.businessHours?.close || businessDateSchedule?.close,
            'HH:mm'
        ).tz(timeZone, true);

    return {
        date,
        from,
        to,
        isBusinessClosed,
        isStaffAvailable
    };
};

export const getStaffResourceFullCalendarBusinessHours = (
    marketplace: Marketplace,
    schedule: StaffSchedule,
    period: Period,
    timeZone: string
) => {
    const result: Array<{
        daysOfWeek: number[];
        startTime: string;
        endTime: string;
    }> = [];
    const weekdayMap: { [key: string]: number } = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6
    };

    for (let date = period.from.clone(); date.isSameOrBefore(period.to, 'day'); date.add(1, 'day')) {
        const weekDay = date.format('dddd').toLowerCase();
        const weekDayNumber = weekdayMap[weekDay];

        const staffScheduleByDate = getStaffScheduleByDate(marketplace, schedule, date, timeZone);

        if (staffScheduleByDate.isStaffAvailable) {
            const startTime = staffScheduleByDate.from.format('HH:mm:ss');
            const endTime = staffScheduleByDate.to.format('HH:mm:ss');

            const existingEntry = result.find(entry => entry.startTime === startTime && entry.endTime === endTime);

            if (existingEntry) {
                existingEntry.daysOfWeek.push(weekDayNumber);
            } else {
                result.push({
                    daysOfWeek: [weekDayNumber],
                    startTime,
                    endTime
                });
            }
        }
    }

    return result;
};

export const isCustomSchedule = (
    schedule: StaffScheduleCustomDay | BusinessHours
): schedule is StaffScheduleCustomDay => {
    return 'from' in schedule && 'to' in schedule;
};

export const isDefaultSchedule = (schedule: StaffScheduleCustomDay | BusinessHours): schedule is BusinessHours => {
    return 'open' in schedule && 'close' in schedule;
};
