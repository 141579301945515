import React, { FunctionComponent } from "react"
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSms } from "@fortawesome/pro-solid-svg-icons"
import { Button } from "components/UI"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        padding: "20px 0px",

        color: "#222",
        fontSize: 18,
        lineHeight: 1.4,

        "& p": {
            padding: "0px 20px",
        }
    },
    column: {
        width: "90%",
        maxWidth: 500,
        margin: "0px auto",

        "& a": {
            textDecoration: "none"
        }
    },
    title: {
        color: "#222",
        fontSize: 62,
        lineHeight: 1,
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 30,
        fontFamily: "Poppins",

        "& svg": {
            width: 72,
            verticalAlign: "super"
        },
        "& b": {
            fontWeight: 600
        }
    },
    only: {
        marginTop: 35,
        padding: "16px 0px",
        borderTop: "1px solid #DDDDDD",
        borderBottom: "1px solid #DDDDDD",

        "& h2": {
            color: "#222",
            fontSize: 48,
            lineHeight: 1,
            fontWeight: 500,
            letterSpacing: "-0.06em",
            marginTop: 0,
            marginBottom: 16,

            "& mark": {
                color: "#EAB464",
                backgroundColor: "transparent"
            }
        },
        "& p": {
            margin: 0,
            fontSize: 16,
            lineHeight: 1.3
        }
    },
    button: {
        height: 55,
        marginTop: 70
    }
}));

const SMSGetStarted: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.column}>
                <h1 className={classes.title}>
                    Client<FontAwesomeIcon icon={faSms} />
                    <br />
                    <b>Text Messages!</b>
                </h1>

                <p>Promote your business with blast campaigns to send quick updates, special offers and discounts.</p>

                <Box className={classes.only}>
                    <h2><mark>Only</mark> 5¢/each</h2>
                    <p>Send promotional SMS to your clients</p>
                </Box>

                <Link to="/marketing/sms/create#first-time">
                    <Button size="large" color="green" label="Get Started" className={classes.button} />
                </Link>
            </Box>
        </Box>
    );
};

export default SMSGetStarted;
