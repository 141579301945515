import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { StaffMember } from "../../model/Service";
import StaffChip from "../UI/StaffChip";
import GroupedStaffChip from "./GroupedStaffChip";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface Props {
  staff: Array<StaffMember>;
  hover?: boolean;
  onShow: (show: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "left",
      [theme.breakpoints.down(wbp)]: {
        marginLeft: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginLeft: "20px",
      },
    },
    staffContainer: {

    },
    hover: {
      "& .MuiChip-root": {
        background: "#fff !important"
      },
    }
  })
);

export const ServiceRowStaff: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const staff = props.staff.map((staff) => (
    <Box key={staff.id} className={clsx(classes.staffContainer, { [classes.hover]: props.hover })}>
      <StaffChip staff={staff} />
    </Box>
  ));

  const groupedStaff = (<GroupedStaffChip staff={props.staff} hover={props.hover} onShow={props.onShow} />);

  return (
    <Box className={classes.container}>
      {props.staff.length > 1 ? groupedStaff : staff}
    </Box>
  );
};

export default ServiceRowStaff;
