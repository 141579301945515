import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import clsx from "clsx";

interface PrimaryLinkProps extends LinkProps {
    label?: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 55,
        width: '100%',
        color: '#fff',
        fontSize: 18,
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: '55px',
        borderRadius: 31,
        letterSpacing: 0,
        fontWeight: 600,
        textTransform: 'none',
        textDecoration: 'none',
        backgroundColor: '#EAB464',
        fontFamily: '"Poppins", sans-serif',
        '&:hover': {
            backgroundColor: '#C18733'
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        }
    }
}));

export const PrimaryLink: FunctionComponent<PrimaryLinkProps>  = (props) => {
    const classes = useStyles(props);

    return (
        <Link {...props} className={clsx(classes.root, props.className)}>
            {props.label || 'Falta label'}
        </Link>
    );
}

export default PrimaryLink;