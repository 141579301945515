import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import logo from "rocket/assets/images/logo.svg";

export interface HeaderProps {
  rightLink?: JSX.Element;
  backLink?: JSX.Element;
  containerStyles?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 990,
    width: "100%",
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: "#ffffff",
    borderBottom: "2px solid #F1F1F1",
    [theme.breakpoints.up("md")]: {
      top: 0,
      left: 0,
      paddingTop: 24,
      paddingBottom: 24,
      position: "absolute",
      borderBottom: "none",
      backgroundColor: "transparent",
    },
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "7%",
      paddingRight: "9%",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "7%",
      paddingRight: "13%",
    },
  },
  logo: {
    width: 140,
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Header: FunctionComponent<HeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={clsx(classes.container, props.containerStyles)}>
        {props.backLink}

        <Grid item>
          <img src={logo} alt="Cuddles Demo Logo" className={classes.logo} />
        </Grid>

        {props.rightLink && <Grid item>{props.rightLink}</Grid>}
      </Grid>
    </div>
  );
};

export default Header;
