import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

interface ListItemProps {
	id: number;
	label: string;
	selected: boolean;
	className?: string;
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer'
		},
		label: {
			fontFamily: 'Poppins',
			fontSize: 14,
			marginLeft: 10
		},
		check: {
			color: '#92B4A7'
		}
	})
);

export const ListItem: FunctionComponent<ListItemProps> = props => {
	const classes = useStyles();

	return (
		<Box
			id={`calendar_option_staff_id_${props.id}`}
			className={clsx(classes.container, props.className)}
			onClick={props.onClick}
		>
			<FontAwesomeIcon icon={props.selected ? faCheckCircle : faCircle} className={classes.check}/>
			<Typography className={classes.label}>{props.label}</Typography>
		</Box>
	);
};

export default ListItem;
