import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface ChipStatusProps {
  label?: string;
  type: "success" | "attention" | "warning"
  classTitle?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: "#5E8677",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#E9F0ED",
      width: "fit-content",
      cursor: "default",
      [theme.breakpoints.down(wbp)]: {
        borderRadius: `${reduceResolution(20)}px`,
        padding: `${reduceResolution(5)}px ${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        borderRadius: "20px",
        padding: "5px 8px",
      },
      [theme.breakpoints.down("md")]: {
        borderRadius: "20px",
        padding: "5px 13px",
      },
    },
    successContainer: {
    },
    attentionContainer: {
      color: "#C18733 !important",
      backgroundColor: "#FFF8DD !important",
    },
    warningContainer: {
      color: "#EF4F57 !important",
      backgroundColor: "#FBB9BC4A !important",
    },
    label: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(13)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    }
  })
);

export const ChipStatus: FunctionComponent<ChipStatusProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container,
      {
        [classes.successContainer]: props.type === "success",
        [classes.attentionContainer]: props.type === "attention",
        [classes.warningContainer]: props.type === "warning",
      }
    )
    }>
      <Typography className={classes.label}>{props.label && props.label}</Typography>
    </Box >
  );
};

export default ChipStatus;
