import { faSms } from '@fortawesome/pro-regular-svg-icons';
import PageSidebar, { PageSidebarItem } from 'components/PageSidebar';
import SMSDashboard from 'components/SMS/SMSCampaigns';
import { Fragment, useState } from 'react';
import PrivatePage from './PrivatePage';

const enum ShowTypes {
    SMSCampaigns = 'SMS',
    Marketplace = 'MARKET'
}

/**
 * Marketing page for SMS Campaigns & Marketplace
 */
const MarketingPage = () => {
    const [show, setShow] = useState<string>(ShowTypes.SMSCampaigns);

    const pageSidebarItems: Array<PageSidebarItem> = [
        { icon: faSms, title: 'SMS Campaigns', key: ShowTypes.SMSCampaigns }
        // { icon: faStore, title: "Marketplace", key: ShowTypes.Marketplace },
    ];

    const sidebar = (
        <PageSidebar
            title="Marketing"
            items={pageSidebarItems}
            selectedKey={show}
            onSelect={key => setShow(key)}
        />
    );

    return (
        <Fragment>
            {show === ShowTypes.SMSCampaigns && (
                <PrivatePage pageSidebar={sidebar}>
                    <SMSDashboard />
                </PrivatePage>
            )}

            {show === ShowTypes.Marketplace && (
                <PrivatePage pageSidebar={sidebar}></PrivatePage>
            )}
        </Fragment>
    );
};

export default MarketingPage;
