import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { DescriptionField2 } from "components/UI";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import MedicalCategorySection from "../../MedicalCategorySection";
import { MedicalCategory as MedicalCategoryModel } from "../../MedicalConditionModel";

export interface SurgeryConditionProps {
  category: MedicalCategoryModel;
  errors?: Array<FieldError>;
  className?: string;
  onChange?: (category: MedicalCategoryModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conditions: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "20px",
      },
    },
    condition: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
      },
    },
    error: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#EA6464",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(17)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "17px",
      },
    },
    conditionTextarea:{
      [theme.breakpoints.down("md")]: {
        "& textarea": {
          fontSize: "15px"
        }
      }
    }
  })
);

export const SurgeryCondition: FunctionComponent<SurgeryConditionProps> = (props) => {
  const classes = useStyles();

  const [condition] = props.category.conditions;

  const changeHandler = (description: string, name?: string) => {
    const [condition] = props.category.conditions;

    const updatedCategory = {
      ...props.category,
      conditions: [
        condition
          ? { ...condition, description }
          : {
              conditionId: 1,
              name: "",
              description,
            },
      ],
    };
    props.onChange && props.onChange(updatedCategory);
  };

  return (
    <MedicalCategorySection
      categoryName={props.category.name}
      categoryId={props.category.categoryId}
      className={props.className}
    >
      <Box className={classes.conditions}>
        <DescriptionField2
          name={`category_${props.category.categoryId}`}
          value={condition?.description || ""}
          label="Description"
          placeholder="Anything important to note?"
          onChange={changeHandler}
          minRows={3}
          errors={props.errors}
          className={classes.conditionTextarea}
        />
      </Box>
    </MedicalCategorySection>
  );
};

export default SurgeryCondition;
