import { Box, Grid, useMediaQuery } from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import { useNonInitialEffect } from '@versiondos/hooks';
import { getPetThunk } from '@spike/pets-action';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { Pet } from '@spike/pet-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import PetCardContent from './PetCardContent';
import { PetCardSidebar } from './PetCardSidebar';
import VetModel from '@spike/vet-model';
import { getVetThunk, reset as resetVet } from '@spike/vets-action';
import { useApiClientWrapper } from 'hooks';
import PortalModal from 'components/UI/Portals';
import PetCardMobile from './PetCardMobile';
import { wbp } from 'Theme';

interface PetCardProps {
    petId: number;
    className?: string;
    onSaved?: () => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: 1132,
            height: 725,
            background: '#fff',
            borderRadius: 24,
            overflow: 'hidden',
            [theme.breakpoints.only('lg')]: {
                width: 1132,
                height: 725
            },
            [theme.breakpoints.down(wbp)]: {
                width: 800,
                height: 550
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%'
            }
        },
        gridContainer: {
            height: '100%'
        },
        boxSpinner: {
            display: 'flex',
            height: '100%'
        }
    })
);

export const PetCard: FunctionComponent<PetCardProps> = props => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const pet = useSelector<RootState, Pet | undefined>(
        state => state.pets.pet
    );
    const [optionMenuSelected, setOptionMenuSelected] = useState('');

    const vet = useSelector<RootState, VetModel | undefined>(
        state => state.vets.vet
    );

    useEffect(() => {
        dispatch(resetVet());
        dispatch(getPetThunk(apiClientWrapper, props.petId));
        // eslint-disable-next-line
    }, []);

    useNonInitialEffect(() => {
        if (pet !== undefined) {
            pet.vetId && dispatch(getVetThunk(apiClientWrapper, pet.vetId));
            checkMedicalCondition(pet);
        }
    }, [pet]);

    const checkMedicalCondition = (pet: Pet) => {
        let checkMedical = 'info';
        try {
            if (
                pet.medicalHistory.medicalConditions.length === 1 &&
                pet.medicalHistory.medicalConditions[0].categoryName !== 'None'
            ) {
                checkMedical = 'medical';
            }

            if (
                pet.medicalHistory.medicalConditions &&
                pet.medicalHistory.medicalConditions.length > 1
            ) {
                checkMedical = 'medical';
            }
        } catch (errorMedical) {
            checkMedical = 'info';
        }
        if (optionMenuSelected === '' || !optionMenuSelected) {
            setOptionMenuSelected(checkMedical);
        }
    };

    const selectMenuHandler = (option: string) => {
        setOptionMenuSelected(option);
    };

    const closeHandler = () => {
        props.onClose && props.onClose();
    };

    const saveHandler = () => {
        props.onSaved && props.onSaved();
    };

    const loadingView = (
        <Box className={classes.boxSpinner}>
            <Spinner />
        </Box>
    );

    return (
        <Box>
            <PortalModal
                isActive={true}
                mode={isMobile ? 'fullModal' : 'modal'}
                onClose={closeHandler}
            >
                <Box className={clsx(classes.container, props.className)}>
                    {pet && !isMobile ? (
                        <Grid container className={classes.gridContainer}>
                            <Grid item lg={5} sm={5} xs={12}>
                                <PetCardSidebar
                                    pet={pet}
                                    optionSelected={optionMenuSelected}
                                    onSelectMenu={selectMenuHandler}
                                />
                            </Grid>
                            <Grid item lg={7} sm={7} xs={12}>
                                <PetCardContent
                                    pet={pet}
                                    vet={vet}
                                    optionMenuSelected={optionMenuSelected}
                                    onSaved={saveHandler}
                                    onClose={closeHandler}
                                />
                            </Grid>
                        </Grid>
                    ) : pet && isMobile ? (
                        <PetCardMobile
                            vet={vet}
                            pet={pet}
                            onClose={closeHandler}
                            optionSelected={optionMenuSelected}
                            onSelectMenu={selectMenuHandler}
                        />
                    ) : (
                        loadingView
                    )}
                </Box>
            </PortalModal>
        </Box>
    );
};

export default PetCard;
