import {
    Box,
    Grid,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Invoice from '@spike/invoice-model';
import React, { FunctionComponent } from 'react';
import { formatPhoneNumber } from '@spike/phone-utils';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import InvoiceHeaderAppointmentInfo from './InvoiceHeaderAppointmentInfo';
import { useMarketplace } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { isTemporalEmail } from 'utils/ValidationUtils';

interface InvoiceHeaderProps {
    invoice: Invoice;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        name: {
            fontSize: 18,
            lineHeight: 1,
            fontWeight: 600,
            color: '#222222',
            marginBottom: 8,

            [theme.breakpoints.up('md')]: {
                fontSize: 26
            }
        },
        text: {
            'fontSize': 15,
            'lineHeight': 1.2,
            'fontWeight': 400,
            'color': '#222222',
            'marginBottom': 4,

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            },

            '& svg': {
                marginRight: 8
            }
        },
        appointmentInfo: {
            marginTop: 24,

            [theme.breakpoints.down('sm')]: {
                'rowGap': 24,
                'paddingTop': 24,
                'borderTop': 'solid 1px #D4D4D4',

                '& div:first-child': {
                    order: 1
                },
                '& div:nth-child(2)': {
                    order: 3
                },
                '& div:last-child': {
                    order: 2
                }
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: '79px'
            }
        }
    })
);

export const InvoiceHeader: FunctionComponent<InvoiceHeaderProps> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Typography className={classes.name}>
                {props.invoice.customer?.name}
            </Typography>
            {!isEmpty(props.invoice.customer?.phone) && (
                <Typography className={classes.text}>
                    <FontAwesomeIcon
                        icon={faPhone}
                        className={'fa-flip-horizontal'}
                    />
                    {formatPhoneNumber(
                        props.invoice.customer?.phone || '',
                        marketplace.basics.address.country!.id
                    )}
                </Typography>
            )}
            {!isEmpty(props.invoice.customer?.email) &&
                !isTemporalEmail(String(props.invoice.customer?.email)) && (
                    <Typography className={classes.text}>
                        <FontAwesomeIcon icon={faEnvelope} />
                        {props.invoice.customer?.email}
                    </Typography>
                )}
            <Grid container className={classes.appointmentInfo}>
                <Grid xs={6} lg={6} item>
                    <InvoiceHeaderAppointmentInfo
                        title="APPT DATE"
                        data={
                            props.invoice.appointmentDate?.format(
                                'dddd, MMM Do yyyy'
                            ) || ''
                        }
                    />
                </Grid>
                <Grid xs={6} lg={4} item>
                    <InvoiceHeaderAppointmentInfo
                        title="DROP OFF & PICK UP"
                        data={`${props.invoice.dropOff?.format(
                            'LT'
                        )} - ${props.invoice.pickUp?.format('LT')}`}
                    />
                </Grid>
                <Grid xs={6} lg={2} item>
                    <InvoiceHeaderAppointmentInfo
                        title="EST DURATION"
                        data={`${
                            props.invoice.duration?.hours
                                ? `${props.invoice.duration?.hours} hrs`
                                : ''
                        }
							${
                                props.invoice.duration?.minutes
                                    ? `${props.invoice.duration?.minutes} min`
                                    : ''
                            }`}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default InvoiceHeader;
