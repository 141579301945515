import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	ClickAwayListener,
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { CommissionPlan } from '@spike/commission-model';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { FunctionComponent } from 'react';

interface ViewCommissionProps {
	onClose: () => void;
	commissionPlan: CommissionPlan;
	active: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			[theme.breakpoints.down('sm')]: {
				height: '100%',
				flexWrap: 'nowrap',
				flexDirection: 'column',
				justifyContent: 'flex-start'
			}
		},
		header: {
			width: '100% !important',
			padding: '10px 16px 32px',

			[theme.breakpoints.up('md')]: {
				padding: '37px 40px 16px 40px'
			}
		},
		close: {
			'cursor': 'pointer',
			'& button': {
				all: 'unset',
				padding: '6px 6px 0px',
				fontSize: 20
			}
		},
		body: {
			width: '100%',
			padding: '0px 16px',
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: '10px',

			[theme.breakpoints.up(wbp)]: {
				padding: '0px 40px 38px 40px'
			}
		},
		title: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		titleFont: {
			color: '#000',
			fontWeight: 600,
			marginBottom: 25,
			marginTop: 10,

			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(25)}px`,
				lineHeight: `${reduceResolution(36)}px`,
				marginBottom: `${reduceResolution(30)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '22px',
				lineHeight: '36px'
			}
		},
		dateContainer: {
			gap: "40px",
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				display: "block"
			},
		},

		subtitle: {
			paddingBottom: '25px',
			borderBottom: '3px #000 solid',
			[theme.breakpoints.down('sm')]: {
				paddingBottom: '0px !important',
			},
		},
		subtitleFont: {
			color: '#000',
			fontWeight: 600,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(19)}px`,
				lineHeight: `${reduceResolution(30)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16',
				lineHeight: '18px'
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '14',
				marginTop: 30,
			},
		},
		subtitleFontActive: {
			[theme.breakpoints.down('sm')]: {
				marginBottom: 25
			},
		},
		dateFont: {
			color: '#000',
			fontWeight: 500,
			marginBottom: 10,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(19)}px`,
				lineHeight: `${reduceResolution(20)}px`,
				marginBottom: `${reduceResolution(25)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15',
				lineHeight: '18px'
			},
			[theme.breakpoints.down('sm')]: {
				marginBottom: 35,
			},

		},
		commissionType: {
			color: '#000',
			fontWeight: 500,
			marginTop: 15,
			marginBottom: 15,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(18)}px`,
				lineHeight: `${reduceResolution(15)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15',
				lineHeight: '15px'
			}
		},
		date: {
			color: '#000',
			fontWeight: 500,
			marginBottom: 20,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(19)}px`,
				lineHeight: `${reduceResolution(15)}px`,
				marginBottom: `${reduceResolution(40)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '15',
				lineHeight: '15px'
			}
		},
		rowSpacing: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(15)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '15px'
			}
		},
		footer: {
			justifyContent: 'end',
			gap: 10,
			width: '100%',
			display: 'flex',
			flexWrap: 'nowrap',
			padding: '10px 10px 10px',

			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
				marginTop: 'auto !important'
			},
			[theme.breakpoints.up('md')]: {
				gap: 20,
				padding: '10px 10px',
				borderTop: '1px solid #DDDDDD'
			}
		},
		button: {
			marginBottom: '15px',
			marginTop: '10px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '0px',
				'height': 54,
				'borderRadius': 30,
				width: "100%",
				'& span': {
					fontSize: 18,
					fontWeight: 600
				}
			}
		},
		commisionListContainer: {
			width: '100% !important',
			padding: '0px 16px'
		},
		commissionListItemContainer: {
			width: '100% !important',
			display: 'flex',
			alignItems: 'center',
			paddingTop: '20px',
			paddingBottom: '20px',
			marginTop: '5px'
		},
		commissionListItemNotLast: {
			borderBottom: '1px #D4D4D4 solid'
		},
		commissionListItemHeaderContainer: {
			width: '100%',
			display: 'flex'
		},
		commissionListItemHeader: {
			display: 'flex',
			width: '100%'
		},
		commissionListItemHeaderTitleContainer: {
			width: '90%'
		},

		commissionListItemHeaderValueContainer: {
			width: '10%'
		},

		itemCommisionTitle: {
			fontSize: '15px',
			fontWeight: 500
		},
		itemCommissionValue: {
			fontSize: '15px',
			fontWeight: 400
		}
	})
);

export const ViewCommission: FunctionComponent<ViewCommissionProps> = props => {
	const classes = useStyles();

	const serviceCommission = props.commissionPlan.commissions.find(
		commission => commission.category.id === 'service'
	);
	const addonCommission = props.commissionPlan.commissions.find(
		commission => commission.category.id === 'addon'
	);
	const productCommission = props.commissionPlan.commissions.find(
		commission => commission.category.id === 'product'
	);

	const commissions = [
		serviceCommission,
		addonCommission,
		productCommission
	].filter(commission => commission !== undefined);

	return (
		<ClickAwayListener onClickAway={() => props.onClose()}>
			<Grid container className={classes.container}>
				<Box className={classes.header}>
					<Box className={classes.title}>
						<Typography className={classes.titleFont}>
							{props.active === true
								? 'Active Commissions'
								: 'Past Commissions'}
						</Typography>
						<Box className={classes.close}>
							<button
								type="button"
								aria-label="Close"
								onClick={() => props.onClose()}
							>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</Box>
					</Box>
					<Box className={classes.subtitle}>
						{props.active ? (
							<Typography className={clsx(classes.subtitleFont,classes.subtitleFontActive)}>
								From{' '}
								{props.commissionPlan.from.format(
									'MMM DD, YYYY'
								)}{' '}
								at {props.commissionPlan.from.format('hh:mm a')}
							</Typography>
						) : (
							<>
								<Box className={classes.dateContainer}>
									<Box>
										<Typography
											className={classes.subtitleFont}
										>
											Start Date
										</Typography>
										<Box >
											<Typography
												className={classes.dateFont}
											>
												{props.commissionPlan.from.format(
													'MMM DD, YYYY'
												)}{' '}
												at{' '}
												{props.commissionPlan.from.format(
													'hh:mm a'
												)}
											</Typography>
										</Box>
									</Box>
									<Box >
										<Typography
											className={classes.subtitleFont}
										>
											End Date
										</Typography>
										<Typography
											className={classes.dateFont}
										>
											{props.commissionPlan.to &&
												props.commissionPlan.to.format(
													'MMM DD, YYYY'
												)}
											{props.commissionPlan.to &&
												' at ' +
												props.commissionPlan.to.format(
													'hh:mm a'
												)}
										</Typography>
									</Box>
								</Box>

							</>
						)}
					</Box>

					<Box className={classes.commisionListContainer}>
						{commissions.map((commission, index) => {
							return (
								<Box
									key={commission?.uuid}
									className={
										index + 1 === commissions.length
											? classes.commissionListItemContainer
											: clsx(
												classes.commissionListItemContainer,
												classes.commissionListItemNotLast
											)
									}
								>
									<Box
										className={
											classes.commissionListItemHeaderContainer
										}
									>
										<Box
											className={
												classes.commissionListItemHeaderTitleContainer
											}
										>
											<Typography
												className={
													classes.itemCommisionTitle
												}
											>
												{commission?.category.id ===
													'service'
													? 'Service'
													: commission?.category
														.id === 'addon'
														? 'Add-On Service'
														: 'Product'}{' '}
												Commission
											</Typography>
										</Box>
										<Box
											className={
												classes.commissionListItemHeaderValueContainer
											}
										>
											<Typography
												className={
													classes.itemCommissionValue
												}
											>
												{Number(
													commission?.rate
												).toFixed(0) === commission?.rate ? Number(
													commission?.rate
												).toFixed(0) : Number(
													commission?.rate
												).toFixed(2)}
												%
											</Typography>
										</Box>
									</Box>
								</Box>
							);
						})}
					</Box>

				</Box>
				<Box className={classes.footer}>
					<Button
						className={classes.button}
						label="Close"
						color="black"
						onClick={props.onClose}
					/>
				</Box>
			</Grid>
		</ClickAwayListener>
	);
};

export default ViewCommission;
