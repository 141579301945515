import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import PercentageChip from "./PercentageChip";
import ProgressBar from "./ProgressBar";

interface SidebarHeaderProps {
  percentage: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      grow: 1,
    },
    header: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "20px",
      },
    },
    progressBar: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(21)}px`,
        marginBottom: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "21px",
        marginBottom: "24px",
      },
    },
  })
);

export const SidebarHeader: FunctionComponent<SidebarHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.header}>
        <Typography className={classes.title}>You progress:</Typography>
        <PercentageChip percentage={props.percentage} />
      </Box>
      <ProgressBar percentage={props.percentage} className={classes.progressBar} />
    </Box>
  );
};

export default SidebarHeader;
