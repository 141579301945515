import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { TextField2, PhoneField3 } from 'components/UI';
import { StaffPerson } from 'model/Staff';
import { MemberWrapper } from './Member';

interface ContactDetailsProps {
    memberWrapper: MemberWrapper;
    onChange: (person: StaffPerson) => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',

            [theme.breakpoints.down('lg')]: {
                paddingLeft: 16,
                paddingRight: 16
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 21,
                paddingBottom: 21
            }
        },
        titleContainer: {
            marginBottom: 15
        },
        title: {
            fontWeight: 500,
            fontFamily: 'Poppins',

            [theme.breakpoints.down('md')]: {
                display: 'none'
            },

            [theme.breakpoints.up('md')]: {
                fontSize: '11px',
                lineHeight: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '14px',
                lineHeight: '22px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px',
                lineHeight: '27px'
            }
        },
        row: {
            [theme.breakpoints.up('md')]: {
                marginTop: 11,
                display: 'flex'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: 20
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: 21
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 20
            }
        },
        rowFirst: {
            marginTop: '0px'
        },
        leftCell: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginRight: 10
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '15px'
            }
        },
        rightCell: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginLeft: 10
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: 12
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: 15
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 20
            }
        },
        input: {
            [theme.breakpoints.down('lg')]: {
                marginBottom: 0
            }
        }
    })
);

export const ContactDetails: FunctionComponent<ContactDetailsProps> = props => {
    const classes = useStyles();

    const prefix = 'person.';

    const onChangeHandler = (value: string, attribute: string) => {
        props.onChange(
            _.set(
                { ...props.memberWrapper.member.person },
                _.replace(attribute, prefix, ''),
                value
            )
        );
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>
                    Contact Details
                </Typography>
            </Box>
            <Box className={clsx(classes.row, classes.rowFirst)}>
                <Box className={classes.leftCell}>
                    <TextField2
                        id="staff_add_member_input_firstname"
                        value={props.memberWrapper.member.person.firstName}
                        label="First Name"
                        placeholder="First Name"
                        name={`${prefix}firstName`}
                        errors={props.memberWrapper.errors}
                        className={classes.input}
                        onChange={onChangeHandler}
                        capitalize={true}
                        required={true}
                        maxLength={30}
                    />
                </Box>
                <Box className={classes.rightCell}>
                    <TextField2
                        id="staff_add_member_input_lastname"
                        value={props.memberWrapper.member.person.lastName}
                        label="Last Name"
                        placeholder="Last Name"
                        name={`${prefix}lastName`}
                        errors={props.memberWrapper.errors}
                        className={classes.input}
                        onChange={onChangeHandler}
                        capitalize={true}
                        required={true}
                        maxLength={30}
                    />
                </Box>
            </Box>
            <Box className={classes.row}>
                <TextField2
                    id="staff_add_member_input_email"
                    value={props.memberWrapper.member.person.email}
                    label="Email"
                    placeholder="Email"
                    name={`${prefix}email`}
                    errors={props.memberWrapper.errors}
                    className={classes.input}
                    onChange={onChangeHandler}
                    required={true}
                />
            </Box>
            <Box className={classes.row}>
                <PhoneField3
                    id="staff_add_member_input_phone"
                    value={props.memberWrapper.member.person.phoneNumber}
                    label="Phone Number"
                    placeholder="Phone Number"
                    name={`${prefix}phoneNumber`}
                    errors={props.memberWrapper.errors}
                    onChange={onChangeHandler}
                />
            </Box>
        </Box>
    );
};

export default ContactDetails;
