import { FieldError, Option, PetType, PetTypeIds } from "@spike/model";
import { useMarketplace } from "hooks";
import { FunctionComponent } from "react";
import { OptionsField } from "components/UI";

interface PetHairTypeProps {
  id?: string;
  petType?: PetType;
  petHairType: Option<string> | undefined;
  name?: string;
  errors?: Array<FieldError>;
  disabled?: boolean;
  onChange?: (option: Option<string>, fieldName?: string) => void;
}

const PetHairType: FunctionComponent<PetHairTypeProps> = (props) => {
  const marketplace = useMarketplace();

  let options =
    props.petType?.id === PetTypeIds.DOGS
      ? marketplace.petTypes.dogs?.hairTypes || []
      : props.petType?.id === PetTypeIds.CATS
        ? marketplace.petTypes.cats?.hairTypes || []
        : [];

  return [PetTypeIds.DOGS.toString(), PetTypeIds.CATS.toString()].includes(props.petType?.id || "") ? (
    <OptionsField
      id={props.id}
      name={props.name}
      options={options.map(e => e.element)}
      selected={props.petHairType}
      errors={props.errors}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  ) : null;
};

export default PetHairType;
