import { faArrowToBottom, faReceipt } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft, faUndoAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Modal, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { sendThunk } from '@spike/payments-action';
import clsx from 'clsx';
import InvoicePdf from 'components/Invoice/InvoicePdf';
import { Button, Link } from 'components/UI';
import { Payment } from '@spike/payments-model';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import Refund from './Refund';
import { useApiClientWrapper } from 'hooks';
import { faEllipsis, faRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { ActionsPopup } from 'components/UI/ActionsPopup/ActionsPopup';

interface TransactionViewProps {
	payment: Payment;
	className?: string;
	onBack: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: '16px 16px 32px',

			[theme.breakpoints.up('md')]: {
				padding: '36px 79px 36px 67px'
			}
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		title: {
			'fontSize': 20,
			'lineHeight': 1,
			'fontWeight': 600,
			'color': '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 36
			},

			'& svg': {
				marginRight: 10,
				cursor: 'pointer'
			}
		},
		headerRight: {
			display: 'flex',
			alignItems: 'center',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		headerDropdownButton: {
			'border': 0,
			'width': 36,
			'height': 36,
			'padding': 0,
			'fontSize': 20,
			'color': '#000000',
			'display': 'inline-flex',
			'alignItems': 'center',
			'justifyContent': 'center',
			'borderRadius': '50%',
			'backgroundColor': '#F1F1F1',

			[theme.breakpoints.up('md')]: {
				display: 'none'
			},

			'& svg': {
				lineHeight: 1,
				verticalAlign: 'middle'
			}
		},

		rightSpace: {
			marginRight: 16,

			[theme.breakpoints.up('md')]: {
				marginRight: 20
			}
		},
		downloadLink: {
			'& span': {
				fontWeight: '500 !important'
			}
		},
		pdf: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center'
		},
		anchor: {
			display: 'none'
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	})
);

export const TransactionView: FunctionComponent<
	TransactionViewProps
> = props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const anchorRef = useRef<HTMLAnchorElement>(null);

	const paymentsStatus = useSelector<RootState, PaymentsStatus>(
		state => state.payments.status
	);
	const [showRefund, setShowRefund] = useState(false);
	const [sendingReceipt, setSendingReceipt] = useState(false);
	const [showMobileDropdown, setShowMobileDropdown] = useState(false);

	useNonInitialEffect(() => {
		if (PaymentsStatus.SendSuccess === paymentsStatus) {
			setSendingReceipt(false);
		}
	}, [paymentsStatus]);

	const sendingReceipHandler = () => {
		setShowMobileDropdown(false);
		setSendingReceipt(true);
		dispatch(
			sendThunk(apiClientWrapper, props.payment.invoice.id, true, false)
		);
	};

	return (
		<>
			<a
				download
				target="_blank"
				ref={anchorRef}
				rel="noreferrer noopener"
				className={classes.anchor}
				href={props.payment.invoice.pdfUrl}
			/>

			<Box className={clsx(classes.container, props.className)}>
				<Box className={classes.header}>
					<Typography className={classes.title}>
						<FontAwesomeIcon
							icon={faArrowLeft}
							onClick={() => props.onBack && props.onBack()}
						/>
						Payment Details
					</Typography>

					<button
						type="button"
						aria-label="Open transaction actions"
						className={classes.headerDropdownButton}
						onClick={() => setShowMobileDropdown(true)}
					>
						<FontAwesomeIcon icon={faEllipsis} />
					</button>

					<Box className={classes.headerRight}>
						<Link
							label="Download PDF"
							color="green"
							startIcon={faArrowToBottom}
							className={clsx(
								classes.downloadLink,
								classes.rightSpace
							)}
							onClick={() =>
								anchorRef.current && anchorRef.current.click()
							}
						/>
						<Button
							label="Send Receipt"
							variant="secondary"
							color="black"
							startIcon={faReceipt}
							className={classes.rightSpace}
							loading={sendingReceipt}
							onClick={sendingReceipHandler}
						/>
						{props.payment.refundable && (
							<Button
								label="Refund"
								color="black"
								startIcon={faUndoAlt}
								onClick={() => {
									setShowRefund(true);
								}}
							/>
						)}
					</Box>
				</Box>

				<InvoicePdf
					url={props.payment.invoice.pdfUrl}
					className={classes.pdf}
				/>
			</Box>

			{showRefund && (
				<Modal
					open={true}
					aria-labelledby="simple-dialog-title"
					className={classes.modal}
				>
					<Refund
						payment={props.payment}
						onClose={() => setShowRefund(false)}
					/>
				</Modal>
			)}

			{showMobileDropdown && (
				<ActionsPopup
					actions={[
						{
							icon: faRotateLeft,
							label: 'Refund',
							onClick: () => {
								setShowRefund(true);
								setShowMobileDropdown(false);
							}
						},
						{
							icon: faReceipt,
							label: 'Receipt',
							onClick: sendingReceipHandler
						},
						{
							icon: faArrowToBottom,
							label: 'Download PDF',
							onClick: () => {
								setShowMobileDropdown(false);
								anchorRef.current && anchorRef.current.click();
							}
						}
					]}
					onClose={() => setShowMobileDropdown(false)}
				/>
			)}
		</>
	);
};

export default TransactionView;
