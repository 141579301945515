import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldError } from "@spike/model";
import useNonInitialEffect from "@versiondos/hooks";
import {
	getBusinessProfileThunk,
	saveDocumentBusinessProfileThunk,
} from "@spike/payments-action";
import { ConfirmDialog } from "components/UI";
import { useApiClientWrapper, useMasterData } from "hooks";
import { PaymentsBusinessProfile as BusinessProfileModel } from "@spike/payments-model";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentsStatus } from "@spike/payments-action";
import { RootState } from "store";
import { wbp } from "Theme";
import DropZoneFile from "../Files/DropZoneFile";
import FileView from "../Files/FileView";
import UploadedFiles from "../Files/UploadedFiles";
import Important from "../Important";
import StepFrame from "../StepFrame";
import { validateFile } from "../Validations";
interface BusinessProfileDocumentStepProps {
	className?: string;
	onPrevious?: () => void;
	onNext?: () => void;
	onCompleted?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: "100%",
		},
		title: {
			fontFamily: "Poppins",
			fontWeight: 500,
			color: "black",
			textTransformation: "capitalize",
			[theme.breakpoints.down(wbp)]: {
				fontSize: "22px",
				lineHeight: "33px",
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "27px",
				lineHeight: "41px",
			},
		},
		important: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: "21px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "26px",
			},
		},
		importantText: {
			fontFamily: "Poppins",
			fontWeight: 400,
			color: "black",
			[theme.breakpoints.down(wbp)]: {
				fontSize: "13px",
				lineHeight: "20px",
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "16px",
				lineHeight: "25px",
			},
		},
		importantTextStrong: {
			fontWeight: 600,
		},
		uploadFile: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: "35px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "44px",
			},
		},
		uploadedFiles: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: "34px",
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "42px",
			},
		},
	})
);

export const BusinessProfileDocumentStep: FunctionComponent<
	BusinessProfileDocumentStepProps
> = (props) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const masterData = useMasterData();
	const apiClientWrapper = useApiClientWrapper();

	const businessProfile = useSelector<RootState, BusinessProfileModel | undefined>(
		(state) => state.payments.businessProfile
	);
	const paymentsStatus = useSelector<RootState, PaymentsStatus>(
		(state) => state.payments.status
	);

	const [newBusinessProfileDocument, setNewBusinessProfileDocument] =
		useState<File | null>(null);

	const [errors, setErrors] = useState<Array<FieldError>>([]);
	const [saving, setSaving] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [documentDeleted, setDocumentDeleted] = useState(false);

	const uploadedFiles = newBusinessProfileDocument
		? [
				{
					name: newBusinessProfileDocument.name,
					size: newBusinessProfileDocument.size,
				},
		  ]
		: businessProfile?.document && !documentDeleted
		? [
				{
					name: businessProfile!.document!.fileName!,
					size: businessProfile!.document!.fileSize!,
				},
		  ]
		: [];

	const finish = () => {
		props.onNext && props.onNext();
		props.onCompleted && props.onCompleted();
	};

	useEffect(() => {
		if (!businessProfile) {
			dispatch(getBusinessProfileThunk(apiClientWrapper));
		}
	}, []);

	useNonInitialEffect(() => {
		if (PaymentsStatus.SaveDocumentBusinessProfileSuccess === paymentsStatus) {
			setSaving(false);
			finish();
		} else if (PaymentsStatus.Error === paymentsStatus) {
			setSaving(false);
		}
	}, [paymentsStatus]);

	const saveHandler = () => {
		if (
			newBusinessProfileDocument ||
			!businessProfile?.document ||
			documentDeleted
		) {
			const errors = validateFile(
				newBusinessProfileDocument,
				masterData,
				"profile document"
			);
			setErrors(errors);
			if (errors.length === 0) {
				setSaving(true);
				dispatch(
					saveDocumentBusinessProfileThunk(
						apiClientWrapper,
						businessProfile!.id!,
						newBusinessProfileDocument!
					)
				);
			}
		} else {
			finish();
		}
	};

	return (
		<Fragment>
			<Box className={classes.container}>
				{props.onCompleted || props.onNext ? (
					<StepFrame
						btnId="cuddlespay-profile-documents"
						title="Business Profile Documents"
						description="Upload a scan or photo of your company’s documents to verify your business."
						onPrevious={props.onPrevious}
						onCompleted={props.onCompleted ? saveHandler : undefined}
						onNext={props.onNext ? saveHandler : undefined}
						saving={saving}
						errors={errors.map((error) => error.errorMessage)}
						className={props.className}
					>
						<Typography className={classes.title}>
							Upload Business Profile Documents
						</Typography>
						<Important className={classes.important}>
							<Typography className={classes.importantText}>
								Make sure the following information is visible:{" "}
								<span className={classes.importantTextStrong}>
									legal business name, address, and Employer
									Identification Number (EIN)
								</span>
							</Typography>
						</Important>
						<DropZoneFile
							className={classes.uploadFile}
							onDrop={(file: File) => {
								setNewBusinessProfileDocument(file);
								setErrors([]);
							}}
						/>
						{uploadedFiles.length > 0 && (
							<UploadedFiles
								files={uploadedFiles}
								className={classes.uploadedFiles}
								onRemove={(fileName) => setShowConfirmation(true)}
							/>
						)}
					</StepFrame>
				) : (
					<FileView
						fileUrl={businessProfile?.document?.url || ""}
						fileName={businessProfile?.document?.fileName || ""}
						fileSize={businessProfile?.document?.fileSize || 0}
					/>
				)}
			</Box>
			<ConfirmDialog
				open={showConfirmation}
				question={
					<Typography>Do you want to delete the document?</Typography>
				}
				cancelButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				onCancel={() => {
					setShowConfirmation(false);
				}}
				onConfirm={() => {
					setShowConfirmation(false);
					setDocumentDeleted(true);
					setNewBusinessProfileDocument(null);
					setErrors([]);
				}}
			/>
		</Fragment>
	);
};

export default BusinessProfileDocumentStep;
