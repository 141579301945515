import React from 'react';
import Table from '../Table';
import PaymentMethod from './PaymentMethod';
import { TipDetail } from 'model/TipsReport';
import { reduceResolution, wbp } from 'Theme';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';

interface TipsDetailTableProps {
    loading: boolean;
    tipsDetail?: Array<TipDetail> | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 8,

            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#FAFAFA'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 16,
                margin: '0px -24px'
            }
        },
        header: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(63)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '63px'
            }
        },
        cell: {
            fontWeight: 400,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15px'
            }
        },
        cellHeader: {
            padding: '20px 0px'
        }
    })
);

export const TipsDetailTable: React.FunctionComponent<
    TipsDetailTableProps
> = props => {
    const classes = useStyles();
    const tableStyles = useTableStyles();

    const headersTipsDetail = (
        <>
            <TableCell className={tableStyles.headerCell}>Date</TableCell>
            <TableCell className={tableStyles.headerCell}>Invoice</TableCell>
            <TableCell className={tableStyles.headerCell}>Customer</TableCell>
            <TableCell className={tableStyles.headerCell}>Staff</TableCell>
            <TableCell className={tableStyles.headerCell}>
                Payment Method
            </TableCell>
            <TableCell className={tableStyles.headerCell}>Tip</TableCell>
        </>
    );

    const rowsTipsDetail = (
        <>
            {props.tipsDetail?.map(tipsDetail => {
                return (
                    <>
                        <TableRow>
                            <TableCell className={tableStyles.cell}>
                                {tipsDetail.createdAt}
                            </TableCell>
                            <TableCell className={tableStyles.cell}>
                                {tipsDetail.invoiceId}
                            </TableCell>
                            <TableCell className={tableStyles.cell}>
                                {tipsDetail.customerName}
                            </TableCell>
                            <TableCell className={tableStyles.cell}>
                                {tipsDetail.staffName}
                            </TableCell>
                            <TableCell className={tableStyles.cell}>
                                <PaymentMethod
                                    paymentMethodId={tipsDetail.paymentType}
                                    paymentMethodName={tipsDetail.paymentType}
                                />
                            </TableCell>
                            <TableCell className={tableStyles.cell}>
                                {`$${tipsDetail.amount}`}
                            </TableCell>
                        </TableRow>
                    </>
                );
            })}
        </>
    );

    return (
        <Box className={classes.root}>
            <Table
                headers={headersTipsDetail}
                rows={props.tipsDetail ? rowsTipsDetail : <></>}
                loading={props.loading}
                withoutColor={true}
            />
        </Box>
    );
};

export default TipsDetailTable;
