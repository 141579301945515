import { Auth } from '@spike/auth-model';
import {
	deleteLocalThunk,
	updateLocalThunk
} from '@spike/block-calendar-action';
import ActionCable, { Cable, Channel } from 'actioncable';
import { useMarketplace, useMasterData, useTimeZone } from 'hooks';
import { BlockCalendarWebSocketsMessage } from 'model/WebSockets';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { debugConsoleLog } from 'utils/GeneralUtils';

const BlockCalendarsWebSockets: FunctionComponent = () => {
	const auth = useSelector<RootState, Auth>(state => state.login.auth);
	const timeZone = useTimeZone();
	const masterData = useMasterData();
	const marketplace = useMarketplace();

	const dispatch = useDispatch();

	useEffect(() => {
		let cable: Cable;
		let channel: Channel;

		debugConsoleLog({
			action: 'BlockCalendarsChannel useEffect',
			signedIn: auth.signedIn
		});

		if (auth.signedIn) {
			[cable, channel] = subscribe(
				auth.marketplaceId!,
				marketplace.schedule.id!
			);
		}

		return () => {
			unsubscribe(cable, channel);
		};
	}, []);

	const subscribe = (
		marketplaceId: number,
		calendarId: number
	): [Cable, Channel] => {
		const cable = ActionCable.createConsumer(
			`${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
		);

		const channel = cable!.subscriptions.create(
			{
				channel: 'BlockCalendarsChannel',
				marketplace_id: marketplaceId,
				calendar_id: calendarId
			},
			{ received: (message: any) => messageHandler(message) }
		);

		debugConsoleLog({
			action: 'BlockCalendarsWebSockets subscribed!',
			channel: 'BlockCalendarsChannel',
			marketplaceId,
			calendarId
		});

		return [cable, channel];
	};

	const unsubscribe = (cable: Cable, channel: Channel) => {
		cable && cable.disconnect();
		channel && channel.unsubscribe();

		debugConsoleLog({
			action: 'BlockCalendarsWebSockets unsubscribed!',
			cable,
			channel
		});
	};

	const messageHandler = (message: BlockCalendarWebSocketsMessage) => {
		debugConsoleLog({
			action: 'BlockCalendarsWebSockets message received',
			message,
			blockCalendar: message.block_calendar,
			deleted: message.deleted
		});

		if (message.block_calendar && !message.destroyed) {
			debugConsoleLog(
				`---> Update Block Calendar, id = ${message.block_calendar.id}`
			);
			dispatch(
				updateLocalThunk(message.block_calendar, timeZone, masterData)
			);
		}

		if (message.block_calendar && message.destroyed) {
			debugConsoleLog(
				`---> Remove Block Calendar, id = ${message.block_calendar.id}`
			);
			dispatch(deleteLocalThunk([message.block_calendar?.id ?? 0]));
		}

		if ((message.deleted ?? []).length > 0) {
			debugConsoleLog(
				`---> Remove Block Calendar, ids = ${message.deleted}`
			);
			dispatch(deleteLocalThunk(message.deleted ?? []));
		}
	};

	return null;
};

export default BlockCalendarsWebSockets;
