import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Theme,
	createStyles,
	makeStyles
} from '@material-ui/core';

import clsx from 'clsx';

import Spinner from './Spinner';

interface ScrolleableTableProps {
	renderRows: JSX.Element[];
	columnLabels: Array<string>;
	loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			'display': 'flex',
			'flexGrow': 1,
			'background': '#fff',
			'overflowY': 'scroll',
			'height': '100%',
			'&::-webkit-scrollbar': {
				display: 'none'
			}
		},
		root: {
			'flexGrow': 1,
			'background': '#fff',
			'overflowX': 'unset',
			[theme.breakpoints.down('md')]: {
				overflowX: 'scroll'
			},
			'&::-webkit-scrollbar': {
				display: 'none'
			}
		},
		tableHead: {
			'borderBottom': 'solid 2px #d4d4d4',
			[theme.breakpoints.up('md')]: {
				background: '#fafafa',
				borderBottom: 'solid 2px #000'
			},
			'& .MuiTableCell-head': {
				'fontSize': 16,
				'lineHeight': 1.5,
				'fontWeight': 600,
				'textWrap': 'nowrap',
				[theme.breakpoints.up('sm')]: {
					background: '#fafafa'
				},

				[theme.breakpoints.down('sm')]: {
					background: '#fff',
					fontSize: 14
				},
				'&:first-child': {
					'left': 0,
					'position': 'sticky',

					[theme.breakpoints.up('md')]: {
						paddingLeft: 24,
						background: '#fafafa'
					},

					[theme.breakpoints.down('md')]: {
						paddingLeft: 16
					},

					'&:before': {
						'content': `''`,
						'top': 0,
						'left': 0,
						'right': 0,
						'bottom': 0,
						'zIndex': -1,
						'display': 'block',
						'position': 'absolute',
						'backgroundColor': '#fff',
						'boxShadow': '0px 0px 0px 0px transparent',
						'transition': 'box-shadow 0.15s ease-out',
						[theme.breakpoints.up('sm')]: {
							background: '#fafafa'
						},

						[theme.breakpoints.down('sm')]: {
							background: '#fff'
						},

						'.scrolled &': {
							boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
						}
					}
				},

				'&:last-child': {
					[theme.breakpoints.only('xs')]: {
						minWidth: 80
					}
				}
			}
		},
		tableBody: {
			'height': 'auto',
			'whiteSpace': 'nowrap',
			'position': 'relative',
			'& .MuiTableCell-body': {
				'height': 54,
				'fontSize': 14,
				'lineHeight': 1,
				'borderBottomColor': '#D4D4D4',
				[theme.breakpoints.up('md')]: {
					height: 73,
					fontSize: 15
				},
				'&:first-child': {
					'left': 0,
					'fontWeight': 500,
					'paddingLeft': 24,
					'position': 'sticky',
					'&:before': {
						'content': `''`,
						'top': 1,
						'left': 0,
						'right': 0,
						'bottom': 1,
						'zIndex': -1,
						'display': 'block',
						'position': 'absolute',
						'backgroundColor': '#fff',
						'boxShadow': '0px 0px 0px 0px transparent',
						'transition': 'box-shadow 0.15s ease-out',
						'.scrolled &': {
							boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
						}
					},
					[theme.breakpoints.down('md')]: {
						paddingLeft: 16
					}
				}
			},

			'& .MuiTableRow-root': {
				'&:last-child': {
					'& .MuiTableCell-body': {
						borderBottom: 0
					}
				}
			}
		},
		spinner: {
			background: 'transparent',
			position: 'absolute',
			left: '50%',
			translateX: '-50%',
			marginTop: 20
		}
	})
);

export const ScrolleableTable: React.FunctionComponent<
	ScrolleableTableProps
> = ({ renderRows, columnLabels, loading }) => {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);

	const [scrollPos, setScrollPos] = useState(0);

	// The scroll listener
	const handleScroll = useCallback((event: Event) => {
		const div = event.target as HTMLDivElement;
		setScrollPos(div.scrollLeft);
	}, []);

	useEffect(() => {
		const div = ref.current;
		div?.addEventListener('scroll', handleScroll);
	}, [handleScroll]);

	return (
		<Box className={classes.wrapper}>
			<div className={classes.root} ref={ref}>
				<Table
					className={clsx({
						scrolled: scrollPos > 0
					})}
				>
					<TableHead className={classes.tableHead}>
						<TableRow>
							{columnLabels.map((label, index) => (
								<TableCell key={index}>{label}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody className={classes.tableBody}>
						{loading !== undefined && loading ? (
							<Spinner className={classes.spinner} />
						) : (
							renderRows
						)}
					</TableBody>
				</Table>
			</div>
		</Box>
	);
};
