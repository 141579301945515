import React, { FunctionComponent, ChangeEvent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useNonInitialEffect } from '@versiondos/hooks';

export interface Props {
    value: string;
    onChange?: (value: string) => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex'
        },
        containerInputMobile: {
            [theme.breakpoints.down('md')]: {
                'position': 'relative',
                '& > input': {
                    width: '100%',
                    height: '38px',
                    fontSize: '16px'
                }
            }
        },
        labelContainer: {
            'border': '1px solid #000',
            'borderRadius': '33px',
            'padding': '1px 12px',
            'width': '160px',
            'height': '32px',
            'display': 'flex',
            'alignItems': 'center',
            '& > p': {
                width: '100%'
            },
            [theme.breakpoints.down('md')]: {
                'height': '38px',
                'paddingLeft': '12px',
                'width': '100%',
                'position': 'relative',
                '& > p': {
                    paddingRight: '14px',
                    display: 'block',
                    fontSize: '16px',
                    top: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    position: 'absolute'
                }
            },
            '&:hover': {
                cursor: 'text',
                [theme.breakpoints.down('lg')]: {
                    borderRadius: '24px',
                    paddingLeft: '12px',
                    paddingRight: '12px'
                },
                [theme.breakpoints.up('xl')]: {
                    borderRadius: '32px',
                    paddingLeft: '18px',
                    paddingRight: '18px'
                }
            }
        },
        label: {
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '14px',
            color: '#000',
            display: 'flex',
            verticalAlign: 'middle',
            minWidth: '100%',
            minHeight: '100%'
        },
        input: {
            'border': '1px solid #CBCBCB',
            'fontWeight': 400,
            'color': '#969696',
            'fontFamily': 'Poppins',
            [theme.breakpoints.down('lg')]: {
                width: '160px',
                height: '32px',
                borderRadius: '24px',
                fontSize: '14px',
                lineHeight: '20px',
                paddingLeft: '12px',
                paddingRight: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '160px',
                height: '32px',
                borderRadius: '32px',
                fontSize: '14px',
                lineHeight: '30px',
                paddingLeft: '18px',
                paddingRight: '18px'
            },
            '&:focus': {
                outline: 'none'
            }
        }
    })
);

export const EditableDescription: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.value);

    useNonInitialEffect(() => {
        const timer: NodeJS.Timer = setTimeout(() => {
            props.onChange && props.onChange(value);
        }, 200);
        return () => clearTimeout(timer);
    }, [value]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const blurHandler = () => {
        setIsEditing(false);
    };

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const value = event.target.value;
        setIsEditing(true);
        setValue(value);
    };

    const description = (
        <Box className={clsx(classes.labelContainer, props.className)}>
            <Typography onClick={handleEdit} className={classes.label} noWrap={true}>
                {value}
            </Typography>
        </Box>
    );

    const input = (
        <input onBlur={blurHandler} onChange={changeHandler} value={value} className={classes.input} autoFocus={true} />
    );

    return (
        <Box className={clsx(classes.containerInputMobile, classes.container)}>{isEditing ? input : description}</Box>
    );
};
