import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from 'components/UI/CommonStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { PriceFormat } from 'components/UI';
import { Moment } from 'moment-timezone';
import { Button } from 'components/UI';
import clsx from 'clsx';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface SaleSuccessProps {
	className?: string;
	receivedDate: Moment;
	total: string;
	//
	onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			height: '100%',
			display: 'flex',
			position: 'relative',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				justifyContent: 'center'
			}
		},

		closeBtn: {
			top: -6,
			right: 0,
			border: 0,
			zIndex: 100,
			fontSize: 20,
			color: '#000000',
			cursor: 'pointer',
			padding: '0px 4px',
			position: 'absolute',
			backgroundColor: 'transparent'
		},

		boxContainer: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'center',
			flexDirection: 'column',

			[theme.breakpoints.down('md')]: {
				flex: 1
			}
		},

		typoTittle: {
			textAlign: 'center',
			[theme.breakpoints.down(wbp)]: {
				paddingTop: `${reduceResolution(20)}px`,
				paddingBottom: `${reduceResolution(10)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: '20px',
				paddingBottom: '10px'
			}
		},

		typoMessage: {
			color: '#000',
			fontSize: 16,
			lineHeight: 1.4,
			textOverflow: 'balance',
			textAlign: 'center',
			marginBottom: 24,

			[theme.breakpoints.down('sm')]: {
				maxWidth: 245
			}
		},

		totalPaid: {
			fontSize: 16,
			lineHeight: 1.4,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},

		icon: {
			fontSize: 82,
			color: '#5E8677'
		},

		title: {
			fontSize: 26,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginTop: 16,
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 28
			}
		},

		button: {
			[theme.breakpoints.down('md')]: {
				'height': 55,
				'borderRadius': 30,

				'& span': {
					fontSize: 18
				}
			},
			[theme.breakpoints.up('md')]: {
				padding: '25px 25px',
				margin: '24px auto 0px'
			}
		}
	})
);

export const SaleSuccess: FunctionComponent<SaleSuccessProps> = props => {
	const classes = useStyles();

	const commonClasses = useCommonStyles();

	return (
		<>
			<Box className={clsx(props.className, classes.container)}>
				<button
					type="button"
					className={classes.closeBtn}
					onClick={props.onClick}
				>
					<FontAwesomeIcon icon={faTimes} />
				</button>

				<Box className={clsx(props.className, classes.boxContainer)}>
					<FontAwesomeIcon
						icon={faCircleCheck}
						className={classes.icon}
					/>
					<Typography className={clsx(classes.title)}>
						Sale Successful!
					</Typography>
					<Typography className={clsx(classes.typoMessage)}>
						Payment received on{' '}
						{props.receivedDate.format('dddd, DD MMM YYYY')}.
					</Typography>

					<Typography
						className={clsx(
							commonClasses.typo28_500_green,
							commonClasses.typo_center
						)}
					>
						<PriceFormat price={props.total} />
					</Typography>
					<Typography className={clsx(classes.totalPaid)}>
						Total Paid
					</Typography>
				</Box>

				<Button
					color={'black'}
					className={classes.button}
					label={'Make Another Sale'}
					onClick={props.onClick}
				/>
			</Box>
		</>
	);
};

export default SaleSuccess;
