import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent, useEffect, useState } from "react";

export interface Props {
  id?: string;
  name: string;
  value: string;
  optional?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  onClick?: () => void;
  onChange?: (name: string, value: string) => void;
  onBlur?: (value: string, name: string, hasError?: boolean) => void;
  className?: string;
  inputStyle?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameOption: {
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      cursor: "text",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        height: "15px",
        width: "120px",
        marginRight: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
        height: "19px",
        width: "160px",
        marginRight: "32px",
      },
    },

    name: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "20px",
      },
    },
    inputEdit: {
      borderColor: "#CBCBCB",
      border: "1px solid #222222",
      fontFamily: "Poppins",
      fontWeight: "inherit",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        height: "24px",
        padding: "0px 10px",
        borderRadius: "15px",
        width: "120px",
        marginRight: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
        height: "32px",
        padding: "0px 15px",
        borderRadius: "20px",
        width: "160px",
        marginRight: "32px",
      },
      "&:focus": {
        outline: "none",
      },
    },
  })
);

export const EditableValueTextField: FunctionComponent<Props> = ({
  id,
  name,
  value,
  error,
  autoFocus,
  optional,
  onClick,
  onChange,
  onBlur,
  className,
  inputStyle,
}) => {
  const classes = useStyles();
  const isEmpty = value.length === 0 ? true : false;

  const [isAutoFocus, setIsAutoFocus] = useState(autoFocus);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const [errorValue, setErrorValue] = useState<boolean>(error ? error : false);

  useEffect(() => {
    if (isEmpty) {
      setInputValue("");
      setIsEditing(true);
    } else {
      setInputValue(value);
    }
  }, [isEditing, value]);

  const handleEdit = () => {
    setIsAutoFocus(true);
    setIsEditing(true);
    onClick && onClick();
  };

  const handleHover = () => {
    if (value.length === 0 && optional) {
      setIsEditing(!isEditing);
    }
  };

  const handleBlur = () => {
    if (!!error) {
      return null;
    }
    setIsEditing(false);

    if (value.length === 0 && !optional) {
      setErrorValue(!errorValue);
      setIsEditing(true);
    }
    onBlur && onBlur(name, inputValue, errorValue);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
    setInputValue(e.target.value);
    onChange && onChange(e.target.name, e.target.value);
  };

  const input = clsx(classes.inputEdit, inputStyle);
  const textStyle = clsx(classes.nameOption, className);

  return isEditing ? (
    <input
      id={id}
      onClick={() => setErrorValue(false)}
      name={name}
      autoFocus={isAutoFocus}
      placeholder={name}
      onBlur={handleBlur}
      onChange={(e) => handleChange(e)}
      value={inputValue}
      className={input}
      style={error ? { border: "1px solid red" } : {}}
      autoComplete={"nope"}
    />
  ) : (
    <Typography
      onClick={handleEdit}
      className={textStyle}
      noWrap={true}
      onMouseEnter={handleHover}
    >
      {value}
    </Typography>
  );
};
