import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarketplacePetSize } from '@spike/marketplace-model';
import { PetSize as MasterDataPetSize } from '@spike/masterdata-model';
import { Ordered } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { FunctionComponent, useState } from 'react';
import { PetSize } from './PetSize';

export interface PetSizesProps {
    masterDataPetSizes: Array<Ordered<MasterDataPetSize>>;
    petSizes: Array<Ordered<MarketplacePetSize>>;
    onChange: (petSizes: Array<Ordered<MarketplacePetSize>>) => void;
    requireTwoColumnsOnMobile?: boolean;
}

interface StyleProps {
    requireTwoColumnsOnMobile?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme =>
    createStyles({
        container: props => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            minWidth: 250,
            [theme.breakpoints.down('md')]: {
                'gap': 0,
                'width': '100%',
                '&:last-child': {
                    borderBottom: 'none'
                },
                ...(props.requireTwoColumnsOnMobile
                    ? {
                          'flexDirection': 'inherit',
                          'flexWrap': 'wrap',
                          '& > p': {
                              width: '100%'
                          },
                          '& > div': {
                              'width': '50%',
                              '& p': {
                                  width: '100%'
                              }
                          }
                      }
                    : {})
            }
        }),
        title: {
            color: '#000000',
            [theme.breakpoints.down('lg')]: {
                fontSize: '11px',
                marginBottom: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
                marginBottom: '24px'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '10px',
                fontSize: '15px',
                marginBottom: '15px',
                textTransform: 'uppercase',
                fontWeight: '600'
            }
        },
        mobileTextContainer: {
            [theme.breakpoints.down('md')]: {
                'display': 'flex',
                'flexDirection': 'inherit',
                'justifyContent': 'space-between',
                'flexWrap': 'wrap',
                'alignItems': 'center',
                '& > p': {
                    width: '46%',
                    maxWidth: '46%'
                },
                '& > div': {
                    width: '54%',
                    maxWidth: '54%'
                }
            }
        },
        optionsTwoColumnsInMobile: {
            [theme.breakpoints.down('md')]: {
                'flexWrap': 'wrap',
                'flexDirection': 'initial',
                '& > div': {
                    width: '50%'
                }
            }
        }
    })
);

export const PetSizes: FunctionComponent<PetSizesProps> = props => {
    const classes = useStyles({
        requireTwoColumnsOnMobile: props.requireTwoColumnsOnMobile
    });

    const [selectedPetSizes, setSelectedPetSizes] = useState([...props.petSizes]);

    const allPetSizes = props.masterDataPetSizes.map(masterDataPetSizeOption => {
        const petSize = props.petSizes.find(petSize => petSize.element.id === masterDataPetSizeOption.element.id);
        return petSize === undefined
            ? {
                  ...masterDataPetSizeOption.element
              }
            : {
                  ...petSize.element
              };
    });

    useNonInitialEffect(() => props.onChange && props.onChange([...selectedPetSizes]), [selectedPetSizes]);

    const changeHandler = (petSizeId: string, checked: boolean, minWeight: number | null, maxWeight: number | null) => {
        if (checked) {
            const selectedPetSize = props.masterDataPetSizes.find(
                masterDataPetSize => masterDataPetSize.element.id === petSizeId
            );

            selectedPetSize &&
                setSelectedPetSizes(prev =>
                    [
                        ...prev.filter(petSize => petSize.element.id !== petSizeId),
                        {
                            element: {
                                ...selectedPetSize.element,
                                description: undefined,
                                minWeight,
                                maxWeight
                            },
                            order: selectedPetSize.order
                        }
                    ].sort((petSize, otherPetSize) => petSize.order - otherPetSize.order)
                );
        } else {
            setSelectedPetSizes(prev => prev.filter(petSize => petSize.element.id !== petSizeId));
        }
    };

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>SIZE</Typography>
            {allPetSizes.map((petSize, index) => (
                <PetSize
                    key={petSize.id}
                    petSize={petSize}
                    checked={selectedPetSizes.some(selectedPetSize => selectedPetSize.element.id === petSize.id)}
                    onChange={(checked, minWeight, maxWeight) =>
                        changeHandler(petSize.id, checked, minWeight, maxWeight)
                    }
                    first={index === 0}
                    last={allPetSizes.length === index + 1}
                />
            ))}
        </Box>
    );
};

export default PetSizes;
