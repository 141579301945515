import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { PropsWithChildren } from 'react';
import { wbp } from 'Theme';

interface ImportantProps extends PropsWithChildren {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			backgroundColor: '#FAEFDF',
			border: '1px solid #EAB464',
			fontFamilty: 'Poppins',
			fontWeight: 400,
			color: 'black',
			[theme.breakpoints.down(wbp)]: {
				padding: '13px 42px 16px 24px',
				borderRadius: '12px',
				fontSize: '13px',
				lineHeight: '20px',
				border: '2px solid #EAB464'
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '16px 53px 20px 30px',
				borderRadius: '15px',
				fontSize: '16px',
				lineHeight: '25px'
			}
		},
		icon: {
			color: '#EAB464',
			[theme.breakpoints.down(wbp)]: {
				width: '34px !important',
				height: '34px !important',
				marginRight: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				width: '43px !important',
				height: '43px !important',
				marginRight: '35px'
			}
		}
	})
);
export const Important: FunctionComponent<ImportantProps> = props => {
	const classes = useStyles();
	return (
		<Box className={clsx(classes.container, props.className)}>
			<FontAwesomeIcon icon={faLightbulbOn} className={classes.icon} />
			{props.children}
		</Box>
	);
};

export default Important;
