import { Grid, Theme, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { PlanCard } from '../PlanCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarCircle } from '@fortawesome/pro-solid-svg-icons';
import {
	faArrowUp,
	faChartLineUp,
	faCommentAltLines,
	faCommentSms,
	faFileSearch,
	faFileSignature
} from '@fortawesome/pro-light-svg-icons';
import { Button } from 'components/UI';

interface Props {
	onUpgrade: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		'padding': '20px 22px 15px',

		[theme.breakpoints.up('md')]: {
			padding: '22px 30px'
		},

		'& h1': {
			fontSize: "20px"
		},
		'& h3': {
			fontSize: "16px",
			lineHeight: 1.4
		},
		'& h3 strong': {
			fontSize: "16px",
			lineHeight: 1.4
		},
		'& h2': {
			fontSize: "16px",

		},
		'& body1': {
			fontSize: "16px",

		},
		'& body2': {
			fontSize: "16px",
		},

		'& button': {
			'height': 47,
			'fontSize': 16,
			'fontWeight': 600,
			'borderRadius': 30,
			'padding': '0px 20px',

			'& span': {
				fontSize: 16,
				display: 'flex',
				alignItems: 'center'
			},

			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: 10
			}
		}
	},
	desktop: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	mobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	}
}));

export const FreePlanCard: React.FunctionComponent<Props> = props => {
	const classes = useStyles();

	const onUpgradeHandler = () => {
		props.onUpgrade();
	};

	return (
		<PlanCard className={classes.root}>
			<label>Current Plan</label>
			<Grid
				container
				spacing={1}
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid item xs>
					<Typography variant="h1">Free</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h2">
						<strong>$0</strong> /mo
					</Typography>
				</Grid>
				<Grid item xs={12} />
				<Grid item xs={12} lg={8}>
					<Typography variant="body1">
						The Cuddles software is completely free to use. Powerful
						technology that can seamlessly run any pet business.
					</Typography>
				</Grid>
			</Grid>

			<hr />

			<Typography variant="body2" style={{ display: 'flex' }}>
				<FontAwesomeIcon
					size="lg"
					color="#EAB464"
					icon={faStarCircle}
					style={{ marginRight: 8 }}
				/>
				<span>
					Upgrade to the <strong>Premium Plan</strong> for additional
					features, including:
				</span>
			</Typography>

			<ul>
				<li>
					<Typography variant="body2">
						<FontAwesomeIcon
							size="xl"
							icon={faCommentAltLines}
							style={{ marginRight: 8 }}
						/>
						<b>Two-Way Texting (1,000 SMS)</b>
					</Typography>
				</li>
				<li>
					<Typography variant="body2">
						<FontAwesomeIcon
							size="xl"
							icon={faFileSignature}
							style={{ marginRight: 8 }}
						/>
						<b>Agreements</b>
					</Typography>
				</li>
				<li>
					<Typography variant="body2">
						<FontAwesomeIcon
							size="xl"
							icon={faChartLineUp}
							style={{ marginRight: 8 }}
						/>
						<b>Insights</b>
					</Typography>
				</li>
				<li>
					<Typography variant="body2">
						<FontAwesomeIcon
							size="xl"
							icon={faFileSearch}
							style={{ marginRight: 8 }}
						/>
						<b>Service Reports</b>
					</Typography>
				</li>
				<li>
					<Typography variant="body2">
						<FontAwesomeIcon
							size="xl"
							icon={faCommentSms}
							style={{ marginRight: 8 }}
						/>
						<b>Client Text Notifications</b>
					</Typography>
				</li>
				<li>
					<Button
						size="medium"
						variant="primary"
						label={
							<>
								Upgrade
								<FontAwesomeIcon
									icon={faArrowUp}
									style={{ marginLeft: 8 }}
									transform={{ rotate: 45 }}
								/>
							</>
						}
						className={classes.desktop}
						onClick={onUpgradeHandler}
					/>
				</li>
			</ul>

			<Button
				size="medium"
				variant="primary"
				label={
					<>
						Upgrade
						<FontAwesomeIcon
							icon={faArrowUp}
							style={{ marginLeft: 8 }}
							transform={{ rotate: 45 }}
						/>
					</>
				}
				className={classes.mobile}
				onClick={onUpgradeHandler}
			/>
		</PlanCard>
	);
};
