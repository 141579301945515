import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { Button } from 'components/UI';
import { FunctionComponent } from 'react';

interface HeaderProps {
	onAdd?: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			padding: '32px 0px',
			backgroundColor: '#FAFAFA',
			paddingTop: 50,
			paddingLeft: 32,
			paddingRight: 32,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 16,
				paddingRight: 16,
			},
			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#fff',
				paddingTop: 16
			},
			[theme.breakpoints.down('xs')]: {
				paddingTop: 0
			}
		},
		right: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: 'auto',
			position: 'relative'
		},
		addBtn: {
			'height': 55,
			'padding': '0px 20px',
			'& .MuiButton-startIcon': {
				'& svg': {
					width: 16,
					height: 16,
					[theme.breakpoints.down('lg')]: {
						width: 14,
						height: 14
					}
				}
			},
			'& span': {
				paddingLeft: 4,
				fontSize: 18,
				fontWeight: 600
			},
			[theme.breakpoints.down('lg')]: {
				'height': 39,
				'padding': '0px 10px',
				'& span': {
					fontSize: 14,
					fontWeight: 600
				}
			}
		},
		headerTitle: {
			'fontWeight': 600,
			'fontSize': 24,
			'& > span': {
				fontSize: 24,
				fontWeight: 600
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: 20,
				fontWeight: 600
			}
		}
	})
);

const Header: FunctionComponent<HeaderProps> = props => {
	const classes = useStyle();

	return (
		<Box className={classes.root}>
			<Typography className={classes.headerTitle}>Agreements</Typography>
			<Box className={classes.right}>
				{props.onAdd && (
					<Button
						label="Add Agreement"
						size="large"
						color="orange"
						startIcon={faPlus}
						className={classes.addBtn}
						onClick={props.onAdd}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Header;
