import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentsSalesReports } from '@spike/payments-model';
import { FunctionComponent } from 'react';
import {
	formatSalesReportItemValue,
	salesReportItemvalueType
} from 'utils/PaymentsUtils';
import SummaryCard from '../../UI/SummaryCard';

interface SummaryProps {
	salesReports?: PaymentsSalesReports;
	loading?: boolean;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		firstCell: {
			display: 'flex',
			[theme.breakpoints.only('xs')]: {
				justifyContent: 'center'
			},
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'flex-start'
			}
		},
		cell: {
			display: 'flex',
			justifyContent: 'center'
		},
		lastCell: {
			display: 'flex',
			[theme.breakpoints.only('xs')]: {
				justifyContent: 'center'
			},
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'flex-end'
			}
		}
	})
);

export const Summary: FunctionComponent<SummaryProps> = props => {
	const classes = useStyles();

	return (
		<Grid
			container
			className={props.className}
			justifyContent="space-between"
			spacing={2}
		>
			<Grid item xs={12} md={2} className={classes.firstCell}>
				<SummaryCard
					title="Completed Transactions"
					value={formatSalesReportItemValue(
						props.salesReports?.completedTransactions,
						salesReportItemvalueType.Quantity
					)}
					info="Number of transactions that occurred on the selected date(s)"
					loading={props.loading}
				/>
			</Grid>
			<Grid item xs={12} md={2} className={classes.cell}>
				<SummaryCard
					title="Gross Sales"
					value={formatSalesReportItemValue(
						props.salesReports?.grossSales,
						salesReportItemvalueType.Money
					)}
					info="Revenue from all services & products sold on a specified date"
					loading={props.loading}
				/>
			</Grid>
			<Grid item xs={12} md={2} className={classes.cell}>
				<SummaryCard
					title="Net Sales"
					value={formatSalesReportItemValue(
						props.salesReports?.netSales,
						salesReportItemvalueType.Money
					)}
					info="Revenue remaining after discounts and refunds"
					loading={props.loading}
				/>
			</Grid>
			<Grid item xs={12} md={2} className={classes.cell}>
				<SummaryCard
					title="Total Sales"
					value={formatSalesReportItemValue(
						props.salesReports?.totalSales,
						salesReportItemvalueType.Money
					)}
					info="Net sales with sales tax and tips included"
					loading={props.loading}
				/>
			</Grid>
			<Grid item xs={12} md={2} className={classes.lastCell}>
				<SummaryCard
					title="Net Total"
					color="dark"
					value={formatSalesReportItemValue(
						props.salesReports?.netTotal,
						salesReportItemvalueType.Money
					)}
					info="Total of sales after fees are subtracted"
					infoAlignment="right"
					loading={props.loading}
				/>
			</Grid>
		</Grid>
	);
};

export default Summary;
