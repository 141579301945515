import React from 'react';
import {
	Box,
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'components/UI';

interface Props {
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'maxWidth': 405,
			'margin': '0px auto',
			'padding': '27px 16px',
			'textAlign': 'center',
			'justifyContent': 'center',
			'flexDirection': 'column',

			'& b': {
				fontWeight: 600
			},
			'& h2': {
				fontSize: 24,
				lineHeight: 1.1,
				fontWeight: 600,
				marginTop: 15,
				marginBottom: 20,

				[theme.breakpoints.up('md')]: {
					fontSize: 32
				}
			},
			'& p': {
				fontSize: 16,
				lineHeight: 1.4,

				[theme.breakpoints.down('xs')]: {
					margin: '0px 5px'
				}
			}
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',

			[theme.breakpoints.down('md')]: {
				flex: 1
			}
		},
		icon: {
			fontSize: '6.2em',

			[theme.breakpoints.up('md')]: {
				fontSize: '7em'
			}
		},
		submit: {
			'height': 47,
			'minWidth': 210,
			'marginTop': 30,
			'borderRadius': 30,

			'& span': {
				fontSize: 16,
				fontWeight: 500
			}
		}
	})
);

export const UpgradeSuccess: React.FunctionComponent<Props> = props => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Box className={classes.wrapper}>
				<FontAwesomeIcon
					icon={faCheckCircle}
					color="#5E8677"
					className={classes.icon}
				/>
				<Typography variant="h2">It’s time to celebrate!</Typography>
				<Typography>
					You’re now a Cuddles <b>Premium</b> member & can start using
					your upgraded features right away!
				</Typography>
			</Box>

			<Button
				label="Done"
				size="large"
				color="black"
				className={classes.submit}
				onClick={props.onClose}
			/>
		</Grid>
	);
};
