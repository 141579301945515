import { SubscriptionsState } from 'reducers/subscriptions/SubscriptionsState';
import { ThunkAction } from 'redux-thunk';
import {
    SUBSCRIPTIONS_DEACTIVATE_START_ACTION_TYPE,
    SUBSCRIPTIONS_DEACTIVATE_SUCCESS_ACTION_TYPE,
    SUBSCRIPTIONS_FETCH_START_ACTION_TYPE,
    SUBSCRIPTIONS_FETCH_SUCCESS_ACTION_TYPE,
    SUBSCRIPTIONS_PURGE_START_ACTION_TYPE,
    SUBSCRIPTIONS_PURGE_SUCCESS_ACTION_TYPE,
    SUBSCRIPTIONS_SAVE_START_ACTION_TYPE,
    SUBSCRIPTIONS_SAVE_SUCCESS_ACTION_TYPE,
    SUBSCRIPTION_GET_PAYMENT_METHOD_START_ACTION_TYPE,
    SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS_ACTION_TYPE,
    SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE,
    SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE,
    SUBSCRIPTION_INVOICES_START_ACTION_TYPE,
    SUBSCRIPTION_INVOICES_SUCCESS_ACTION_TYPE,
    SUBSCRIPTION_QUOTE_START_ACTION_TYPE,
    SUBSCRIPTION_QUOTE_SUCCESS_ACTION_TYPE,
    SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE,
    SubscriptionsAction
} from './SubscriptionsActionsTypes';
import { NotificationsAction, showError } from '@spike/notifications-action';
import { createTokenConfig } from 'api/ApiClient';
import { AxiosResponse } from 'axios';
import ApiClient from 'api';
import store from 'store';
import {
    MarketplaceSMSMessagesStats,
    Subscription,
    SubscriptionQuote
} from 'model/Subscriptions';
import {
    AdminInvoiceDto,
    MarketplaceSMSMessagesStatsDto,
    SubscriptionDto
} from './SubscriptionsDtos';
import {
    convertToAdminInvoice,
    convertToSMSMessagesStats,
    convertToSubscription
} from './SubscriptionsConverter';
import {
    MarketplacePaymentMethodDto,
    convertToPaymentMethod
} from '@spike/payments-action';
import { MarketplacePaymentMethod } from '@spike/payments-model';
import moment from 'moment-timezone';

const purgeUrl = 'subscription/purge';
const subscriptionsUrl = 'subscriptions';
const deactivateUrl = 'deactivate_subscriptions';
const quoteUrl = 'subscription/quote';
const invoicesUrl = '/admin_invoices';
const paymentMethodUrl = '/subscription/get_payment_method';
const smsMessageStats = '/sms_message_stats';

export const fetchSubscriptionsThunk = (): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${subscriptionsUrl}?marketplace_id=${marketplaceId}`;

        if (!token) {
            return;
        }

        try {
            const response: AxiosResponse<Array<SubscriptionDto>> =
                await ApiClient.get(url, createTokenConfig(token));

            dispatch(
                fetchSuccess(
                    response.data.map(dto => convertToSubscription(dto))
                )
            );
        } catch (apiError) {
            dispatch(showError('Error fetching subscription.'));
        }
    };
};

export const purgeSubscriptionsThunk = (): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(purgeStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${purgeUrl}?marketplace_id=${marketplaceId}`;

        if (!token) {
            return;
        }

        try {
            await ApiClient.get(url, createTokenConfig(token));

            dispatch(purgeSuccess());
        } catch (apiError) {
            dispatch(showError('Error purging subscriptions.'));
        }
    };
};

export const saveSubscriptionsThunk = (
    subscription: Subscription
): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(saveStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = subscription?.id
            ? `${subscriptionsUrl}/${subscription?.id}?marketplace_id=${marketplaceId}`
            : `${subscriptionsUrl}?marketplace_id=${marketplaceId}`;

        if (!token) {
            return;
        }

        const apiAction = subscription?.id ? ApiClient.patch : ApiClient.post;

        try {
            const { data }: AxiosResponse<SubscriptionDto> = await apiAction(
                url,
                {
                    active: subscription.active,
                    staff_id: subscription.staffId,
                    marketplace_id: marketplaceId,
                    category_id: subscription.categoryId || 'premium',
                    channel: subscription.channel,
                    start_at: subscription.startAt,
                    end_at: subscription.endAt,
                    promo_code: subscription.promoCode
                },
                createTokenConfig(token)
            );

            const subscriptionData = convertToSubscription(data);

            dispatch(saveSuccess(subscriptionData));
        } catch (apiError) {
            dispatch(showError('Error saving subscription.'));
        }
    };
};

export const fetchSubscriptionInvoicesThunk = (): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(fetchInvoicesStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${invoicesUrl}?marketplace_id=${marketplaceId}`;

        try {
            const { data }: AxiosResponse<Array<AdminInvoiceDto>> =
                await ApiClient.get(url, createTokenConfig(`${token}`));

            dispatch(fetchInvoicesSuccess(data));
        } catch (apiError) {
            dispatch(showError('Error fetching invoices.'));
        }
    };
};

export const deactivateSubscriptionThunk = (
    subscriptionId: number
): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(deactivateStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${deactivateUrl}/${subscriptionId}?marketplace_id=${marketplaceId}`;

        if (!token) {
            return;
        }

        try {
            await ApiClient.post(url, {}, createTokenConfig(token));

            dispatch(deactivateSuccess());
        } catch (apiError) {
            dispatch(showError('Error deactivating subscription.'));
        }
    };
};

export const quoteSubscriptionThunk = (
    promoCode: string
): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(quoteStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${quoteUrl}?marketplace_id=${marketplaceId}&promo_code=${promoCode}&category_id=premium`;

        if (!token) {
            return;
        }

        try {
            const { data: quote }: AxiosResponse<SubscriptionQuote> =
                await ApiClient.get(url, createTokenConfig(token));

            dispatch(quoteSuccess(quote));
        } catch (apiError) {
            dispatch(showError('Error getting quote.'));
        }
    };
};

export const getSubscriptionPaymentMethodThunk = (
    subscriptionId: number
): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getPaymentMethodStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${paymentMethodUrl}?marketplace_id=${marketplaceId}&id=${subscriptionId}`;

        try {
            const { data: method }: AxiosResponse<MarketplacePaymentMethodDto> =
                await ApiClient.get(url, createTokenConfig(`${token}`));

            if (method !== null) {
                dispatch(
                    getPaymentMethodSuccess(convertToPaymentMethod(method))
                );
            }
        } catch (apiError) {
            dispatch(showError('Error getting subscription payment method.'));
        }
    };
};

export const getSMSMessagesStatsThunk = (): ThunkAction<
    void,
    SubscriptionsState,
    null,
    SubscriptionsAction | NotificationsAction
> => {
    return async dispatch => {
        dispatch(getSMSMessagesStatsStart());

        const token = store.getState().login.auth.token;
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${smsMessageStats}?marketplace_id=${marketplaceId}`;

        try {
            const {
                data: stats
            }: AxiosResponse<MarketplaceSMSMessagesStatsDto> =
                await ApiClient.get(url, createTokenConfig(`${token}`));

            dispatch(
                getSMSMessagesStatsSuccess(convertToSMSMessagesStats(stats))
            );
        } catch (apiError) {
            dispatch(
                showError('Error getting subscription sms message stats.')
            );
        }
    };
};

/* Fetch */
const fetchStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_FETCH_START_ACTION_TYPE
    };
};
const fetchSuccess = (
    subscriptions: Array<Subscription>
): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_FETCH_SUCCESS_ACTION_TYPE,
        payload: { subscriptions }
    };
};

/* Purge */
const purgeStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_PURGE_START_ACTION_TYPE
    };
};
const purgeSuccess = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_PURGE_SUCCESS_ACTION_TYPE
    };
};

/* Save */
const saveStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_SAVE_START_ACTION_TYPE
    };
};
const saveSuccess = (subscription: Subscription): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_SAVE_SUCCESS_ACTION_TYPE,
        payload: { subscription }
    };
};

/* Deactivate */
const deactivateStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_DEACTIVATE_START_ACTION_TYPE
    };
};
const deactivateSuccess = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTIONS_DEACTIVATE_SUCCESS_ACTION_TYPE
    };
};

/* Quote */
const quoteStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_QUOTE_START_ACTION_TYPE
    };
};
const quoteSuccess = (quote: SubscriptionQuote): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_QUOTE_SUCCESS_ACTION_TYPE,
        payload: { quote }
    };
};

/* Fetch Invoices */
const fetchInvoicesStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_INVOICES_START_ACTION_TYPE
    };
};
const fetchInvoicesSuccess = (
    invoices: Array<AdminInvoiceDto>
): SubscriptionsAction => {
    const invoicesSorted = invoices.sort(function (a, b) {
        return moment(a.created_at) < moment(b.created_at)
            ? 1
            : moment(b.created_at) < moment(a.created_at)
            ? -1
            : 0;
    });

    return {
        type: SUBSCRIPTION_INVOICES_SUCCESS_ACTION_TYPE,
        payload: {
            invoices: invoicesSorted.map(invoice =>
                convertToAdminInvoice(invoice)
            )
        }
    };
};

/* Get Payment Method */
const getPaymentMethodStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_GET_PAYMENT_METHOD_START_ACTION_TYPE
    };
};
const getPaymentMethodSuccess = (
    paymentMethod: MarketplacePaymentMethod
): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_GET_PAYMENT_METHOD_SUCCESS_ACTION_TYPE,
        payload: {
            paymentMethod
        }
    };
};

/* Get Sms messages stats */
const getSMSMessagesStatsStart = (): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_GET_SMS_MESSAGES_STATS_START_ACTION_TYPE
    };
};

const getSMSMessagesStatsSuccess = (
    stats: MarketplaceSMSMessagesStats
): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_GET_SMS_MESSAGES_STATS_SUCCESS_ACTION_TYPE,
        payload: {
            stats
        }
    };
};

/* Premium plan popup */
export const togglePremiumPlanPopup = (open?: boolean): SubscriptionsAction => {
    return {
        type: SUBSCRIPTION_TOGGLE_POPUP_ACTION_TYPE,
        payload: {
            open
        }
    };
};
