import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface InvoiceStatusProps {
	paid?: boolean;
	unpaid?: boolean;
	pending?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'fontSize': 14,
			'fontWeight': 500,
			'lineHeight': 1.5,
			'padding': '0px 11px',
			'display': 'inline-block',
			'borderRadius': 13,
			'color': '#fff',

			[theme.breakpoints.up('md')]: {
				fontSize: 15
			},

			'&.paid': {
				backgroundColor: '#5E8677'
			},
			'&.unpaid': {
				backgroundColor: '#EF4F57'
			},
			'&.pending': {
				backgroundColor: '#EAB464'
			}
		}
	})
);

export const InvoiceStatus: React.FunctionComponent<
	InvoiceStatusProps
> = props => {
	const classes = useStyles();

	return (
		<span
			className={clsx(classes.root, {
				paid: props.paid,
				unpaid: props.unpaid,
				pending: props.pending
			})}
		>
			{props.paid && 'Paid'}
			{props.unpaid && 'Unpaid'}
			{props.pending && 'Pending'}
		</span>
	);
};
