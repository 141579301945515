import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface LinkProps {
  icon?: IconDefinition;
  id?: string;
  title: string;
  className?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container:  {
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "20px",
      },
    },
    link: (props: LinkProps) => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      cursor: props.onClick ? "pointer" : "default",
      [theme.breakpoints.down(wbp)]: {
        minHeight: `${reduceResolution(32)}px`,
        padding: `${reduceResolution(8)}px ${reduceResolution(8)}px ${reduceResolution(8)}px ${reduceResolution(12)}px`,
        borderRadius: `${reduceResolution(6)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        minHeight: "32px",
        padding: "8px 8px 8px 12px",
        borderRadius: "6px",
      },
    }),
    icon: {
      display: "flex",
      alignItems: "center",
      color: "#92B4A7",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(16)}px !important`,
        height: `${reduceResolution(16)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "16px !important",
        height: "16px !important",
      },
    },
    title: {
      fontFamilty: "Poppins",
      fontWeight: 500,
      color: "#92B4A7",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
        marginLeft: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
        marginLeft: "8px",
      },
    },
    linkUnderline: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid white",
      "&:hover": {
        borderBottomColor: "#92B4A7",
      }
    }
  })
);

export const Link: FunctionComponent<LinkProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
    <Box
      className={clsx(classes.link, props.className)}
      onClick={props.onClick}
    >
      <Box className={classes.linkUnderline} id={props.id||""}>
      {props.icon && <FontAwesomeIcon icon={props.icon} className={classes.icon} />}
      <Typography className={classes.title}>{props.title}</Typography>
      </Box>
    </Box>
    </Box>
  );
};

export default Link;
