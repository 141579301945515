import { FC, useEffect, useMemo } from 'react';
import { useMarketplace } from 'hooks/useMarketplace';
import { Option, PetType, PetTypeIds } from '@spike/model';
import { VariablePriceOption } from 'model/Service';
import { Box, createStyles, Theme, makeStyles, Typography } from '@material-ui/core';
import { byHairLenghtPriceOption, byPetSizeAndHairLengthPriceOption, byPetSizePriceOption } from './model';
import { isEqual } from 'lodash';
import { usePricingContext } from './PricingContext/PricingContext';
import { PriceError } from '../model';
import clsx from 'clsx';
import { MarketplaceDepositType } from '@spike/marketplace-model';
import MinPriceAlert from './UI/MinPriceAlert';
import { MemoizedPriceFieldWithError } from './UI/PriceFieldWithError';
import { usePriceManagement } from './hooks/useVariablePricesManagment';

export interface VariablePriceProps {
    variablePrices: Array<VariablePriceOption>;
    errors: Array<PriceError>;
    id: string;
    onServiceDone?: (done: boolean) => void;
    canEditPrices?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        singleVariablePriceRow: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: 15,
            fotnSize: 16
        },
        singleVariableByHairLengthField: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            pading: '0px 20px',
            gap: 15
        },
        hairLengthsRow: {
            padding: '20px 5%',
            display: 'flex',
            flexDirection: 'row',
            gap: 15,
            [theme.breakpoints.down('sm')]: {
                padding: '10px 16px 9px'
            }
        },
        petSizeNameContainer: {
            height: 40,
            paddingLeft: '5%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #D4D4D4',
            alignItems: 'center',
            borderBottom: '1px solid #D4D4D4',
            justifyContent: 'flex-start',
            backgroundColor: '#F4F3F0',
            gap: 10,
            [theme.breakpoints.down('sm')]: {
                padding: '10px 16px 9px'
            }
        },
        petSizeName: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000',
            textTransform: 'uppercase'
        },
        petSizeDescription: {
            color: '#7A7A7A',
            fontSize: 12,
            fontWeight: 300
        },
        singleSizeDescriptionContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 10
        },
        singleSizeTitle: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000',
            marginBottom: 10
        },
        singleSizeDescription: {
            fontSize: 14,
            fontWeight: 300,
            color: '#7A7A7A',
            marginBottom: 25
        },
        byHairLengthSingleSizeContainer: {
            display: 'grid',
            gridTemplateColumns: '2fr 2fr',
            gap: 15
        },
        bySizeGrid: {
            padding: '10px 0 ',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            alignItems: 'center',
            gap: 20,
            [theme.breakpoints.down('sm')]: {
                gap: 10,
                gridTemplateColumns: '1fr 1fr'
            }
        },
        bySizeHeader: {
            padding: '10px 5%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            borderBottom: '1px solid #000',
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: '1fr 1fr',
                padding: '10px 5%'
            }
        },
        bySizeHeaderText: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000'
        },
        bySizeContainer: {
            '& > div': {
                'borderBottom': '1px solid #D4D4D4',
                '& > div:first-child': {
                    minWidth: '30%'
                }
            },
            '& > div:last-child': {
                border: 'none',
                [theme.breakpoints.down('sm')]: {
                    borderBottom: '1px solid #D4D4D4'
                }
            },

            [theme.breakpoints.down('sm')]: {
                'gridTemplateColumns': '1fr 1fr',
                'padding': 0,
                'width': 'auto',
                '& > div > div:first-child': {
                    width: '50%'
                }
            }
        },

        singleSizeContainer: {
            '& > div': {
                'borderBottom': 'none',
                '& > div:first-child': {
                    width: '100%'
                }
            }
        },
        priceField: {
            height: 42
        },
        bySizePriceField: {
            padding: '10px 5%'
        },
        byHairAndLenghtAlertContainer: {
            padding: '10px 5%',
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            [theme.breakpoints.down('sm')]: {
                padding: '10px 16px 9px',
                gridTemplateColumns: '1fr'
            }
        },
        bySizeAlertContainer: {
            [theme.breakpoints.down('sm')]: {
                gridColumn: 'span 2/span 2'
            }
        },
        errorMessage: {
            color: 'red',
            fontSize: 16,
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        },
        minPriceBySizeAlert: {
            marginBottom: 15,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 10
            }
        },
        minPriceByHairLengthAlert: {
            marginBottom: 15,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 10
            }
        },
        noBorderButton: {
            '& > div': {
                border: 'none !important'
            }
        },
        fieldMaxWidth: {
            width: 200,
            marginBottom: 20
        }
    })
);

export const getFieldUuid = (
    variablePrices: Array<VariablePriceOption>,
    selectedPetType: PetType | null,
    size: Option<string>,
    hairLength?: Option<string>
) => {
    return variablePrices?.find(
        price =>
            isEqual(price.petSize?.id, size.id) &&
            isEqual(price.petType?.id, selectedPetType?.id) &&
            isEqual(price.hairLength?.id, hairLength?.id)
    )?.uuid;
};

export const VariablePrice: FC<VariablePriceProps> = props => {
    const classes = useStyles();
    const { petTypes: petTypesData, deposit } = useMarketplace();
    const { selectedPetType, selectedPricingOption, pricing, showErrors } = usePricingContext().state;
    const { updatePricing } = usePricingContext();

    const { variablePrices, errors, showAlert, setVariablePrices, checkPriceAlerts, handlePriceChange, setErrors } =
        usePriceManagement(
            pricing as Array<VariablePriceOption>,
            { type: deposit.type, value: deposit.value || '0' },
            props.errors || [],
            selectedPricingOption,
            showErrors
        );

    useEffect(() => {
        setVariablePrices(pricing as Array<VariablePriceOption>);
        setErrors([]);
    }, [selectedPricingOption]);

    useEffect(() => {
        if (showErrors) {
            checkPriceAlerts();
            setErrors(props.errors);
        }
    }, [showErrors]);

    const selectedPetTypeData = useMemo(
        () => petTypesData[selectedPetType?.id as keyof typeof petTypesData],
        [petTypesData, selectedPetType]
    );

    const hairLengths = useMemo(
        () =>
            selectedPetTypeData && 'hairLengths' in selectedPetTypeData ? selectedPetTypeData.hairLengths : undefined,
        [selectedPetTypeData]
    );

    useEffect(() => {
        props.onServiceDone &&
            props.onServiceDone(
                variablePrices?.every(
                    price =>
                        price.price !== 0 &&
                        (deposit.type === MarketplaceDepositType.Amount ? Number(deposit.value) <= price.price! : true)
                )
            );
    }, [variablePrices]);

    const updatePricingHandler = () => {
        checkPriceAlerts();
        updatePricing(variablePrices);
    };

    const renderPricingBySize = selectedPetTypeData?.sizes?.map(size => {
        const fieldUuid = getFieldUuid(variablePrices, selectedPetType, size.element);
        return (
            <Box
                key={size.element.id}
                className={clsx({
                    [classes.bySizeGrid]: selectedPetTypeData?.sizes && selectedPetTypeData?.sizes.length > 1,
                    [classes.bySizePriceField]: selectedPricingOption.id === byPetSizePriceOption.id
                })}
            >
                {selectedPetTypeData?.sizes &&
                selectedPetTypeData?.sizes.length === 1 &&
                selectedPricingOption.id === byPetSizePriceOption.id ? (
                    <Box className={classes.singleSizeDescriptionContainer}>
                        <Typography className={classes.singleSizeDescription}>
                            {selectedPetType?.plural} prices are based on a single size
                        </Typography>
                        <Typography className={classes.singleSizeTitle}>Price for {selectedPetType?.plural}</Typography>
                    </Box>
                ) : (
                    <Typography className={classes.singleVariablePriceRow}>{size.element.name}</Typography>
                )}
                <MemoizedPriceFieldWithError
                    className={clsx([
                        classes.priceField,
                        {
                            [classes.fieldMaxWidth]:
                                selectedPetTypeData?.sizes && selectedPetTypeData?.sizes.length === 1
                        }
                    ])}
                    errorClassName={classes.errorMessage}
                    value={
                        variablePrices?.find(
                            price =>
                                isEqual(price.petSize?.id, size.element.id) &&
                                isEqual(price.petType?.id, selectedPetType?.id)
                        )?.price
                    }
                    errors={showErrors ? errors.filter(error => isEqual(error.priceUuid, fieldUuid)) : []}
                    onBlur={updatePricingHandler}
                    onChange={value =>
                        handlePriceChange({
                            price: value,
                            size: size.element,
                            selectedPetType: selectedPetType!,
                            hairLength: undefined
                        })
                    }
                    fieldUuid={fieldUuid}
                />

                {showAlert.some(
                    price =>
                        (isEqual(price.petSize?.id, size.element.id) &&
                            isEqual(price.petType?.id, selectedPetType?.id)) ||
                        errors.find(error => isEqual(error.priceUuid, fieldUuid) && error.type === 'alert')
                ) && (
                    <Box className={classes.bySizeAlertContainer}>
                        <MinPriceAlert
                            pricingOption={selectedPricingOption}
                            petType={selectedPetType!}
                            className={classes.minPriceBySizeAlert}
                        />
                    </Box>
                )}
            </Box>
        );
    });

    const renderPricingBySizeAndHairLength = selectedPetTypeData?.sizes?.map(size => (
        <Box key={size.element.id}>
            {selectedPetTypeData?.sizes && selectedPetTypeData?.sizes.length > 1 && (
                <Box className={classes.petSizeNameContainer}>
                    <Typography className={classes.petSizeName}>{size.element.name}</Typography>
                    <Typography className={classes.petSizeDescription}>{size.element.description}</Typography>
                </Box>
            )}
            <Box
                className={clsx(
                    classes.hairLengthsRow,
                    selectedPetTypeData?.sizes &&
                        selectedPetTypeData?.sizes.length === 1 &&
                        classes.byHairLengthSingleSizeContainer
                )}
            >
                {hairLengths?.map(hairLength => {
                    const fieldUuid = getFieldUuid(variablePrices, selectedPetType, size.element, hairLength.element);
                    return (
                        <Box key={hairLength.element.id}>
                            <Box className={classes.singleVariableByHairLengthField}>{hairLength.element.name}</Box>
                            <MemoizedPriceFieldWithError
                                className={classes.priceField}
                                errorClassName={classes.errorMessage}
                                value={
                                    variablePrices?.find(
                                        price =>
                                            price.petSize?.id === size.element.id &&
                                            price.hairLength?.id === hairLength.element.id &&
                                            price.petType?.id === selectedPetType?.id
                                    )?.price
                                }
                                errors={showErrors ? errors.filter(error => isEqual(error.priceUuid, fieldUuid)) : []}
                                onChange={value =>
                                    handlePriceChange({
                                        price: value,
                                        size: size.element,
                                        selectedPetType: selectedPetType!,
                                        hairLength: hairLength.element,
                                        uuid: fieldUuid
                                    })
                                }
                                onBlur={updatePricingHandler}
                                fieldUuid={fieldUuid}
                            />
                        </Box>
                    );
                })}
            </Box>
            {showAlert.some(
                price => isEqual(price.petSize?.id, size.element.id) && isEqual(price.petType?.id, selectedPetType?.id)
            ) && (
                <Box className={classes.byHairAndLenghtAlertContainer}>
                    <MinPriceAlert
                        pricingOption={selectedPricingOption}
                        petType={selectedPetType!}
                        hairLengths={hairLengths!}
                        className={classes.minPriceByHairLengthAlert}
                    />
                </Box>
            )}
        </Box>
    ));

    return (
        <Box>
            {isEqual(selectedPricingOption.id, byPetSizePriceOption.id) && (
                <Box>
                    {selectedPetTypeData?.sizes && selectedPetTypeData?.sizes.length > 1 && (
                        <Box className={classes.bySizeHeader}>
                            <Typography className={classes.bySizeHeaderText}>
                                {selectedPetType?.singular} Size
                            </Typography>
                            <Typography className={classes.bySizeHeaderText}>Price</Typography>
                        </Box>
                    )}
                    <Box
                        className={clsx([
                            classes.bySizeContainer,
                            {
                                [classes.singleSizeContainer]:
                                    selectedPetTypeData?.sizes && selectedPetTypeData?.sizes.length === 1,
                                [classes.noBorderButton]: isEqual(selectedPetType?.id, PetTypeIds.CATS)
                            }
                        ])}
                    >
                        {renderPricingBySize}
                    </Box>
                </Box>
            )}
            {(isEqual(selectedPricingOption.id, byPetSizeAndHairLengthPriceOption.id) ||
                isEqual(selectedPricingOption.id, byHairLenghtPriceOption.id)) &&
                renderPricingBySizeAndHairLength}
        </Box>
    );
};
