import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface Props {
  title: boolean;
}  

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%"
    },
    titleSection: {
      color: "#A3A3A3",
      [theme.breakpoints.down("lg")]: {
        fontSize: "11px",
        marginBottom: "18px"
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px",
        marginBottom: "24px"
      },
    },
    content: {
      display: "flex",
      flexWrap: "wrap",
    },
    declinedContainer: {
      width: "auto",
      backgroundColor: "#FBB9BC",
      [theme.breakpoints.down("lg")]: {
        height: "34px",
        borderRadius: "18px",
        marginBottom: "8px",
        marginRight: "15px"
      },
      [theme.breakpoints.up("xl")]: {
        height: "45px",
        borderRadius: "24px",
        marginBottom: "12px",
        marginRight: "20px"
      },
    },
    label: {
      "&:hover": {
        cursor: "default"
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "6px",
        paddingBottom: "8px",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontSize: "15px"
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "8px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px"
      },
    },
  })
);

export const Declined: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {props.title && <Typography className={classes.titleSection}>PROCEDURE RECORD</Typography>}
        <Box className={classes.content}>
          <Box className={classes.declinedContainer}>
            <Typography
              className={classes.label}
              onClick={() =>{}}
            >
              Pet Declined
            </Typography>
          </Box>
        </Box>
    </Box>
  );
};

export default Declined;
  