import { Reducer } from "redux";
import { AutocompleteState, AutocompleteStatus } from "./AutocompleteState";
import {
  AUTOCOMPLETE_RESET_ACTION_TYPE,
  AUTOCOMPLETE_SEARCH_START_ACTION_TYPE,
  AUTOCOMPLETE_SEARCH_SUCCESS_ACTION_TYPE,
  AUTOCOMPLETE_ERROR_ACTION_TYPE,
  AutocompleteAction,
} from "actions/autocomplete/AutocompleteActionsTypes";

const initialState: AutocompleteState = {
  results: [],
  status: AutocompleteStatus.Initial,
  loading: false,
};

export const autocompleteReducer: Reducer<AutocompleteState, AutocompleteAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AUTOCOMPLETE_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    case AUTOCOMPLETE_SEARCH_START_ACTION_TYPE:
      return {
        ...state,
        status: AutocompleteStatus.Searching,
        loading: true,
        results: []
      };
    case AUTOCOMPLETE_SEARCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: AutocompleteStatus.SearchingSuccess,
        results: action.payload.results,
        loading: false,
      };
    case AUTOCOMPLETE_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: AutocompleteStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default autocompleteReducer;
