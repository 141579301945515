import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface TitleProps {
	text: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontSize: 24
			}
		}
	})
);

export const Title: React.FunctionComponent<TitleProps> = props => {
	const classes = useStyles();

	return (
		<Typography className={clsx(classes.title, props.className)}>
			{props.text}
		</Typography>
	);
};

export default Title;
