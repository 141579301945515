import { EventContentArg } from '@fullcalendar/core';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			color: 'red',
			height: '100%',
			width: '100%',
			border: '1px solid #000000',
			overflowX: 'hidden',
			overflowY: 'hidden',
			boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
			[theme.breakpoints.down('sm')]: {
				borderRadius: '3px',
				paddingLeft: '6px',
				paddingRight: '6px',
				paddingTop: '2px',
			},
			[theme.breakpoints.only('md')]: {
				borderRadius: '5px',
				paddingLeft: '7px',
				paddingRight: '7px',
				paddingTop: '2px',
			},
			[theme.breakpoints.only('lg')]: {
				borderRadius: '7px',
				paddingLeft: '8px',
				paddingRight: '8px',
				paddingTop: '4px',
			},
			[theme.breakpoints.only('xl')]: {
				borderRadius: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				paddingTop: '5px',
			}
		},
		duration: {
			fontFamily: 'Poppins',
			whiteSpace: 'nowrap',
			overflow: 'hidden !important',
			textOverflow: 'ellipsis',
			color: '#000000',
			fontWeight: 500,
			[theme.breakpoints.down('sm')]: {
				fontSize: '5px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '7px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '10px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '12px'
			}
		}
	})
);

export const TempEventFullCalendar: FunctionComponent<EventContentArg> = props => {
	const classes = useStyles();
	return (
		<Box
			id={`calendar_div_event_${props.event.extendedProps.appointmentId}`}
			className={clsx(
				classes.container,
			)}
		>
			<Typography className={classes.duration}>
				{`${props.event.extendedProps.from.format('h:mm a')}`}
			</Typography>
		</Box>
	);
};

export default TempEventFullCalendar;
