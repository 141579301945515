export interface NewFeature {
  id: number;
  title: string;
  description: string;
  mediaSource: string;
  mediaType: MediaType;
  videoPoster?: string;
}

export enum MediaType {
  IMAGE = "image",
  VIDEO = "video"
}