import React from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';

interface MobileDividerProps {
	style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 16,
			width: '100%',
			margin: '32px 0px',
			backgroundColor: '#F4F3F0',

			[theme.breakpoints.up('sm')]: {
				display: 'none'
			}
		}
	})
);

export const MobileDivider: React.FunctionComponent<
	MobileDividerProps
> = props => {
	const classes = useStyles();

	return <Box className={classes.root} {...props} />;
};
