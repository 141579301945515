import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import HeaderGroomingReport from 'components/UI/HeaderReport';
import Remarks from 'components/UI/Remarks';
import NextGroomingDate from 'components/UI/DateReport';
import Recommendations from 'components/UI/Recommendations';
import Button from 'components/UI/Button';
import { Img } from 'react-image';
import GroomingReport from 'model/GroomerReport';
import OptionsComponent from './UI/OptionsComponent';
import FindingsComponent from './UI/FindingsComponent';
import { isEmpty, reject } from 'lodash';
import { useMasterData } from 'hooks';
import BeforeAndAfterGroomingSummary from './UI/BeforeAndAfter/BeforeAndAfterGroomingSummary';
import { faArrowToBottom, faEdit } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { status } from '@spike/appointment-model';
import { MobileDivider } from 'components/UI/MobileDivider';

interface Props {
    groomingReport: GroomingReport;
    singleImage: boolean;
    onComplete?: (report: GroomingReport) => void;
    onResend?: (report: GroomingReport) => void;
    back: () => void;
    onClose: () => void;
    onEditReport: (appointmentId: number) => void;
    onCompleteReport?: (report: GroomingReport) => void;
    onDownloadReport?: (appointmentId: number) => void;
    sending: boolean;
    declined?: boolean;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            height: 'fit-content',
            flexDirection: 'column',
            backgroundColor: '#ffffff',
            padding: '24px 16px',

            [theme.breakpoints.up('md')]: {
                paddingLeft: '136px',
                paddingRight: '79px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '181px',
                paddingRight: '106px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '274px',
                paddingRight: '160px'
            }
        },
        backTitleContainer: {
            width: '100%',
            display: 'flex',
            position: 'relative',

            [theme.breakpoints.up('md')]: {
                marginBottom: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '36px'
            }
        },
        backContainer: {
            fontSize: 20,
            cursor: 'pointer',
            display: 'inline-flex',

            [theme.breakpoints.down('sm')]: {
                marginRight: 10
            },
            [theme.breakpoints.up('md')]: {
                fontSize: 26,
                marginLeft: -80,
                position: 'absolute'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: 32,
                marginLeft: -106
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: 36,
                marginLeft: -160
            }
        },
        titleButtonContainer: {
            'width': '100%',
            'display': 'flex',

            '& > div': {
                display: 'flex',
                alignItems: 'center'
            },

            [theme.breakpoints.down('sm')]: {
                gap: 24,
                marginBottom: 24
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '56px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '75px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '114px'
            }
        },
        titleContainer: {
            display: 'inline-flex',
            flexDirection: 'column'
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: 26
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: 27
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: 36
            }
        },
        rowContainer: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '20px'
            }
        },
        rightContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexWrap: 'wrap'
            },
            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        leftContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexWrap: 'wrap'
            },
            [theme.breakpoints.up('md')]: {
                width: '50%',
                fontSize: '27px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '36px'
            }
        },
        groomingMap: {
            width: '85%'
        },
        oralHygieneLevelsContainer: {
            display: 'flex'
        },
        oralHygieneLevels: {
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.down('sm')]: {
                order: 2,
                width: '100%'
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '30px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '40px'
            }
        },
        titleSection: {
            color: '#A3A3A3',
            [theme.breakpoints.down('lg')]: {
                fontSize: '11px',
                marginBottom: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px',
                marginBottom: '24px'
            }
        },
        levelsContainer: {
            display: 'flex'
        },
        plaqueContainer: {
            [theme.breakpoints.down('lg')]: {
                marginRight: '98px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '130px'
            }
        },
        procedureRecordsContainer: {
            [theme.breakpoints.down('lg')]: {
                marginBottom: '44px',
                marginTop: (props: Props) =>
                    props.singleImage ? '98px' : '30px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '58px',
                marginTop: (props: Props) =>
                    props.singleImage ? '130px' : '40px'
            }
        },
        recommendationsAndBehaviorContainer: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '22px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '30px'
            }
        },
        recommendationsAndBehaviorContent: {
            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        recommendationsOrBehaviorContent: {
            width: '100%'
        },
        recommendationsContainer: {
            marginTop: 16,

            [theme.breakpoints.down('sm')]: {
                '&.first': {
                    marginTop: 0
                },
                '&:not(:last-child):not(.last)': {
                    paddingBottom: 24,
                    borderBottom: 'solid 1px #D4D4D4'
                }
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '22px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '30px'
            }
        },
        imageContainer: {
            [theme.breakpoints.down('sm')]: {
                order: 2,
                marginTop: 24
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '52px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '70px'
            }
        },
        findingsContainer: {
            [theme.breakpoints.down('sm')]: {
                order: 1
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '22px',
                marginRight: '45px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '30px',
                marginRight: '60px'
            }
        },
        nextDentalDate: {
            [theme.breakpoints.down('sm')]: {
                'marginBottom': 32,

                '& > div': {
                    padding: 0
                }
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '37px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '50px'
            }
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'flex-end'
        },

        buttonComplete: {
            'height': 55,
            'width': '100%',

            '& span': {
                fontSize: 18,
                fontWeight: 600
            },

            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
            [theme.breakpoints.up('md')]: {
                width: 200,
                height: 38
            },
            [theme.breakpoints.up('xl')]: {
                width: 245,
                height: 50
            }
        },
        boxResend: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                gap: 10,
                width: '100%'
            }
        },
        buttonBack: {
            'width': '100%',
            'fontSize': 16,
            'fontWeight': 600,
            'color': '#EAB464',
            'cursor': 'pointer',

            '&:hover': {
                color: '#C18733'
            },

            [theme.breakpoints.down('sm')]: {
                height: 55,
                color: '#ffffff',
                borderRadius: 30,
                lineHeight: '55px',
                textAlign: 'center',
                backgroundColor: '#000000'
            },
            [theme.breakpoints.up('md')]: {
                width: 180,
                padding: '10px 10px 0px 0px'
            }
        },
        finalRowContainer: {
            display: 'flex',
            justifyContent: 'space-between',

            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
                flexDirection: 'column'
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '20%',
                marginBottom: '20px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '35%',
                marginBottom: '26px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '50%',
                marginBottom: '40px'
            },

            [theme.breakpoints.up('md')]: {
                '& > :only-child': {
                    marginLeft: 'auto'
                }
            }
        },
        beforeAndAfterContainer: {
            [theme.breakpoints.down('sm')]: {
                order: 1,
                marginTop: 16,
                marginBottom: 16
            },
            [theme.breakpoints.up('md')]: {
                minHeight: 320
            },
            [theme.breakpoints.only('xl')]: {
                minHeight: 480
            }
        },
        downloadContainer: {
            width: '100%',
            display: 'flex'
        },
        icon: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '3px',
                marginRight: '6px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '5px',
                marginRight: '8px'
            }
        },
        actionOptions: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                gap: 32
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'end'
            }
        },
        downloadContent: {
            'display': 'flex',
            'float': 'right',
            [theme.breakpoints.down('lg')]: {
                marginRight: '40px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '208px'
            },
            'cursor': 'pointer',
            '&:hover': {
                color: '#222'
            },
            'color': '#92B4A7'
        },

        editContent: {
            'float': 'right',
            'display': 'flex',

            [theme.breakpoints.up('md')]: {
                width: 150,
                marginRight: '40px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '180px'
            },
            'cursor': 'pointer',
            '&:hover': {
                color: '#222'
            },
            'color': '#92B4A7'
        },

        download: {
            fontSize: 14,
            fontWeight: 500,

            [theme.breakpoints.up('xl')]: {
                fontSize: 18
            }
        },

        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const GroomingReportSummary: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const masterData = useMasterData();

    const closeHandler = () => {
        props.onClose();
    };

    const completeHandler = () => {
        props.onComplete && props.onComplete(props.groomingReport);
    };

    const completeReportHandler = () => {
        props.onCompleteReport && props.onCompleteReport(props.groomingReport);
    };

    const resendHandler = () => {
        props.onResend && props.onResend(props.groomingReport);
    };

    const handleDownloadPDF = () => {
        props.onDownloadReport &&
            props.onDownloadReport(props.groomingReport.appointment.id!);
    };

    const handleEditReport = () => {
        props.onEditReport &&
            props.onEditReport(props.groomingReport.appointment.id!);
    };

    const header = (
        <Box className={classes.backTitleContainer}>
            <Grid container className={classes.titleButtonContainer}>
                <Grid item xs={12} md={6}>
                    <Box
                        className={classes.backContainer}
                        onClick={() => props.back()}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Box>

                    <Box className={classes.titleContainer}>
                        <Typography
                            className={classes.title}
                        >{`${props.groomingReport.appointment.pet.name}'s Grooming Report`}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box className={classes.downloadContainer}>
                        {props.loading ? (
                            <Spinner />
                        ) : (
                            <Box className={classes.actionOptions}>
                                {props.groomingReport.appointment.status.id !==
                                status.COMPLETED ? (
                                    <Box
                                        className={classes.editContent}
                                        onClick={handleEditReport}
                                    >
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            className={clsx(
                                                classes.download,
                                                classes.icon
                                            )}
                                        />
                                        <Typography
                                            className={classes.download}
                                        >
                                            Edit Report
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box className={classes.editContent}>
                                        <Typography
                                            className={classes.download}
                                        >
                                            {' '}
                                        </Typography>
                                    </Box>
                                )}
                                <Box
                                    className={classes.downloadContent}
                                    onClick={handleDownloadPDF}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowToBottom}
                                        className={clsx(
                                            classes.download,
                                            classes.icon
                                        )}
                                    />
                                    <Typography className={classes.download}>
                                        Download PDF
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Divider />
        </Box>
    );

    const goBackButton = (
        <Button
            label="Go back to booking"
            className={classes.buttonComplete}
            onClick={closeHandler}
        />
    );

    const sendButton = (
        <Box className={classes.boxResend}>
            <Box onClick={closeHandler} className={classes.buttonBack}>
                <span className={classes.desktop}>Go back to booking</span>
                <span className={classes.mobile}>Go back</span>
            </Box>
            <Button
                label="Send Report"
                className={classes.buttonComplete}
                onClick={resendHandler}
                loading={props.sending}
            />
        </Box>
    );

    const resendButton = (
        <Box className={classes.boxResend}>
            <Box onClick={closeHandler} className={classes.buttonBack}>
                Go back to booking
            </Box>
            <Button
                label="Resend Report"
                className={classes.buttonComplete}
                onClick={resendHandler}
                loading={props.sending}
            />
        </Box>
    );

    const completeReportButton = (
        <Button
            label="Complete Report"
            className={classes.buttonComplete}
            onClick={completeReportHandler}
            loading={props.sending}
        />
    );

    return (
        <Box className={classes.container}>
            {header}
            <Box className={classes.rowContainer}>
                <Box className={classes.leftContainer}>
                    <Box className={classes.desktop}>
                        <HeaderGroomingReport
                            appointment={props.groomingReport.appointment}
                            isReport={false}
                            declined={props.declined}
                            isGrooming={true}
                        />
                    </Box>
                    <Box className={classes.imageContainer}>
                        {props.groomingReport.appointment.pet.type.id ===
                        'dogs' ? (
                            <Img
                                src="/images/groomingReport/dogBodyChart.png"
                                className={classes.groomingMap}
                            />
                        ) : (
                            <Img
                                src="/images/groomingReport/catBodyChart.png"
                                className={classes.groomingMap}
                            />
                        )}
                    </Box>
                    {!isEmpty(props.groomingReport.findings) && (
                        <Box className={classes.findingsContainer}>
                            <FindingsComponent
                                findings={props.groomingReport.findings}
                                multipleSelection={true}
                                onChangeSelection={() => {}}
                                isReport={false}
                            />
                        </Box>
                    )}
                </Box>
                <Box className={classes.rightContainer}>
                    <Box className={classes.mobile}>
                        <HeaderGroomingReport
                            appointment={props.groomingReport.appointment}
                            isReport={false}
                            declined={props.declined}
                            isGrooming={true}
                        />
                    </Box>

                    {props.groomingReport.images.every(
                        img => img && img.length > 0
                    ) && (
                        <Box className={classes.beforeAndAfterContainer}>
                            <BeforeAndAfterGroomingSummary
                                images={props.groomingReport.images}
                            />
                        </Box>
                    )}

                    <Box className={classes.oralHygieneLevels}>
                        {props.groomingReport.skin.length > 0 && (
                            <Box className={classes.recommendationsContainer}>
                                <OptionsComponent
                                    masterOptions={
                                        masterData.groomingReportOptions.skin
                                    }
                                    options={props.groomingReport.skin}
                                    title={'Skin'}
                                    isReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                    isGroomingSummary={true}
                                />
                            </Box>
                        )}

                        {props.groomingReport.coat.length > 0 && (
                            <Box className={classes.recommendationsContainer}>
                                <OptionsComponent
                                    masterOptions={
                                        masterData.groomingReportOptions.coat
                                    }
                                    options={props.groomingReport.coat}
                                    title={'Coat'}
                                    isReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                    isGroomingSummary={true}
                                />
                            </Box>
                        )}

                        {props.groomingReport.ears.length > 0 && (
                            <Box className={classes.recommendationsContainer}>
                                <OptionsComponent
                                    masterOptions={
                                        masterData.groomingReportOptions.ears
                                    }
                                    options={props.groomingReport.ears}
                                    title={'Ears'}
                                    isReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                    isGroomingSummary={true}
                                />
                            </Box>
                        )}

                        {props.groomingReport.teeth.length > 0 && (
                            <Box className={classes.recommendationsContainer}>
                                <OptionsComponent
                                    masterOptions={
                                        masterData.groomingReportOptions.teeth
                                    }
                                    options={props.groomingReport.teeth}
                                    title={'Teeth'}
                                    isReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                    isGroomingSummary={true}
                                />
                            </Box>
                        )}

                        {props.groomingReport.nails.length > 0 && (
                            <Box className={classes.recommendationsContainer}>
                                <OptionsComponent
                                    masterOptions={
                                        masterData.groomingReportOptions.nails
                                    }
                                    options={props.groomingReport.nails}
                                    title={'Nails'}
                                    isReport={false}
                                    multipleSelection={true}
                                    onChangeSelection={() => {}}
                                    isGroomingSummary={true}
                                />
                            </Box>
                        )}

                        {props.groomingReport.eyes.length > 0 && (
                            <>
                                <Box
                                    className={clsx(
                                        classes.recommendationsContainer,
                                        'last'
                                    )}
                                >
                                    <OptionsComponent
                                        masterOptions={
                                            masterData.groomingReportOptions
                                                .eyes
                                        }
                                        options={props.groomingReport.eyes}
                                        title={'Eyes'}
                                        isReport={false}
                                        multipleSelection={true}
                                        onChangeSelection={() => {}}
                                        isGroomingSummary={true}
                                    />
                                </Box>

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </>
                        )}

                        <Box
                            className={
                                classes.recommendationsAndBehaviorContainer
                            }
                        >
                            {!isEmpty(props.groomingReport.behavior) && (
                                <>
                                    <Box
                                        className={
                                            !isEmpty(
                                                props.groomingReport.behavior
                                            ) &&
                                            !isEmpty(
                                                props.groomingReport
                                                    .recommendations
                                            )
                                                ? classes.recommendationsAndBehaviorContent
                                                : classes.recommendationsOrBehaviorContent
                                        }
                                    >
                                        <OptionsComponent
                                            masterOptions={
                                                masterData.groomingReportOptions
                                                    .behavior
                                            }
                                            options={
                                                props.groomingReport.behavior
                                            }
                                            title={'Behavior'}
                                            isReport={false}
                                            multipleSelection={true}
                                            onChangeSelection={() => {}}
                                            isGroomingSummary={true}
                                            isBehaviorSummary={true}
                                        />
                                    </Box>

                                    <Box style={{ margin: '0px -16px' }}>
                                        <MobileDivider />
                                    </Box>
                                </>
                            )}

                            {!isEmpty(props.groomingReport.recommendations) && (
                                <>
                                    <Box
                                        className={
                                            !isEmpty(
                                                props.groomingReport.behavior
                                            ) &&
                                            !isEmpty(
                                                props.groomingReport
                                                    .recommendations
                                            )
                                                ? classes.recommendationsAndBehaviorContent
                                                : classes.recommendationsOrBehaviorContent
                                        }
                                    >
                                        <Recommendations
                                            recommendations={
                                                props.groomingReport
                                                    .recommendations
                                            }
                                            isReport={false}
                                            section="grooming"
                                            multipleSelection={true}
                                            onChangeSelection={() => {}}
                                        />
                                    </Box>

                                    <Box style={{ margin: '0px -16px' }}>
                                        <MobileDivider />
                                    </Box>
                                </>
                            )}
                        </Box>

                        {props.groomingReport.remarks && (
                            <>
                                <Box
                                    className={clsx(
                                        classes.recommendationsContainer,
                                        'last',
                                        'first'
                                    )}
                                >
                                    <Remarks
                                        remark={props.groomingReport.remarks}
                                        isReport={false}
                                        onChange={() => {}}
                                    />
                                </Box>

                                <Box style={{ margin: '0px -16px' }}>
                                    <MobileDivider />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.finalRowContainer}>
                {props.groomingReport.nextVisit && (
                    <Box className={classes.nextDentalDate}>
                        <NextGroomingDate
                            nextDentalDate={props.groomingReport.nextVisit}
                            haveReport={!!props.groomingReport.id}
                            section="Next Recommended Visit"
                            isReport={false}
                            isDental={false}
                            onChange={() => {}}
                        />
                    </Box>
                )}

                <Box className={classes.buttonContainer}>
                    {!props.declined && props.onComplete && goBackButton}
                    {props.declined && !props.onResend && completeReportButton}
                    {props.onResend &&
                        props.groomingReport.appointment.status.id !==
                            status.COMPLETED &&
                        sendButton}
                    {props.onResend &&
                        props.groomingReport.appointment.status.id ===
                            status.COMPLETED &&
                        resendButton}
                </Box>
            </Box>
        </Box>
    );
};

export default GroomingReportSummary;
