import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { Option } from '@spike/model';
import clsx from 'clsx';
import PageSizeDropdown from './PageSizeDropdown';

interface Props {
	pageSizeSelected: Option<number>;
	pageSizes: Array<Option<number>>;
	onChange: (pageSizeId: number) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative',
			display: 'flex',
			minWidth: '110px',
			height: '40px'
		},
		select: {
			display: 'flex',
			cursor: 'pointer',
			alignItems: 'center',
			borderRadius: '100px',
			padding: '2px 6px',

			[theme.breakpoints.up('md')]: {
				paddingLeft: '14px',
				paddingRight: '14px',
				paddingTop: '4px',
				paddingBottom: '4px',
				background: '#f2f2f2'
			}
		},
		hide: {
			color: 'transparent'
		},
		selectFont: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			color: '#A3A3A3'
		},
		icon: {
			color: '#A3A3A3',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '6px'
			},
			[theme.breakpoints.only('md')]: {
				marginLeft: '8px'
			},
			[theme.breakpoints.only('lg')]: {
				marginLeft: '10px'
			},
			[theme.breakpoints.only('xl')]: {
				marginLeft: '12px'
			}
		}
	})
);

export const PageSizeTransparent: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const [showDropdown, setShowDropdown] = useState(false);
	const [selected, setSelected] = useState(props.pageSizeSelected);

	const selectClickHandler = (event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();
		setShowDropdown(true);
	};

	const selectPageSizeHandler = (pageSize: number) => {
		setShowDropdown(false);
		setSelected(props.pageSizes.find(option => option.id === pageSize)!);
		props.onChange(pageSize);
	};

	const select = (
		<Box className={classes.select} onClick={selectClickHandler}>
			<Typography className={classes.selectFont}>
				{selected.name}
			</Typography>
			<FontAwesomeIcon icon={faCaretUp} className={classes.icon} />
		</Box>
	);

	const dropdown = (
		<PageSizeDropdown
			pageSizes={props.pageSizes}
			onSelect={selectPageSizeHandler}
			onClose={() => setShowDropdown(false)}
		/>
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			{showDropdown ? dropdown : select}
		</Box>
	);
};

export default PageSizeTransparent;
