import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from 'components/UI';
import { useHistory } from 'react-router-dom';
import Info from '../Info';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noPetTypesInfoText: {
            marginBottom: 5,
            fontSize: 16,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        }
    })
);

export const NoPetTypesAlert = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Info
            textComponent={
                <Box>
                    <Typography className={classes.noPetTypesInfoText}>
                        Oops! It looks like you&apos;re trying to add a service, but we need to set up some{' '}
                        <strong>pet types</strong> first. Go to the business settings page to configure pet types and
                        then come back to add your services!
                    </Typography>
                    <Button
                        label="Add Pet Types"
                        onClick={() => history.push('/business_settings/pets/types')}
                        color="green"
                    />
                </Box>
            }
            icon="alert"
        />
    );
};

export default NoPetTypesAlert;
