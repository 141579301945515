import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles(theme => ({
    successContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 200px)',
        width: '100%',
        flexDirection: 'column'
    },
    successWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 15
    },
    successBadge: {
        width: 90,
        height: 90,
        backgroundColor: '#5E8677',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        color: '#fff',
        fontSize: 40,
        transform: 'none',
        position: 'relative',
        fontWeight: 600
    },
    successContent: {
        textAlign: 'center'
    },
    successTitle: {
        fontSize: 24,
        fontWeight: 600,
        color: '#000',
        marginBottom: 3,
        [theme.breakpoints.up('md')]: {
            fontSize: 32
        }
    },
    successSubtitle: {
        'fontSize': 16,
        'color': '#000',
        'maxWidth': 420,
        '& strong': {
            fontWeight: 600
        }
    },
    totalPaid: {
        fontSize: 32,
        fontWeight: 600,
        color: '#000',
        marginTop: 25
    },
    totalPaidText: {
        fontSize: 16,
        color: '#000',
        fontWeight: 500
    }
}));

interface PaymentSuccessProps {
    totalPaid: string;
    paymentDate: string;
}

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({
    totalPaid,
    paymentDate
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.successContainer}>
            <Box className={classes.successWrapper}>
                <Box className={classes.successBadge}>
                    <FontAwesomeIcon icon={faCheck} />
                </Box>
                <Box className={classes.successContent}>
                    <Typography className={classes.successTitle}>
                        Payment Successful!
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.successSubtitle}
                    >
                        Your payment has been successfully processed on{' '}
                        <strong>{paymentDate}</strong>. Thank you!
                    </Typography>
                    <Typography className={classes.totalPaid}>
                        ${totalPaid}
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.totalPaidText}
                    >
                        Total Paid
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentSuccess;
