import {
    faCalendar,
    faCalendarDays,
    faFileSignature,
    faHeartPulse,
    faImagesUser,
    faPaw,
    faPhoneFlip,
    faPlus
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Client as ClientModel, createEmptyClient } from '@spike/client-model';
import {
    clearRetrievedClientThunk,
    ClientsStatus,
    getClientThunk
} from '@spike/clients-action';
import { createEmptyPet, Pet as PetModel } from '@spike/pet-model';
import { fetchPetsThunk, PetsStatus } from '@spike/pets-action';
import useNonInitialEffect from '@versiondos/hooks';
import {
    fetchOnboardingStepsThunk,
    onboardingCloseModal
} from 'actions/onboardingSteps/OnboardingStepsActions';
import ClientSignatures from 'components/Agreements/ClientSignatures';
import BookingsComponentClient from 'components/Bookings/BookingsComponentClient';
import ClientComponent from 'components/Client';
import PageSidebar from 'components/PageSidebar';
import { PageSidebarItem } from 'components/PageSidebar/PageSidebarModel';
import AddPet from 'components/Pet/AddPet';
import EditPet from 'components/Pet/EditPet';
import { Button, Spinner } from 'components/UI';
import { useApiClientWrapper, useHasPremiumAccess } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import PrivatePage from 'pages/PrivatePage';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import { formatPhoneNumber } from '@spike/phone-utils';
import { v4 as uuid } from 'uuid';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import { UnderlinedButton } from 'components/UI/UnderlinedButton';
import { ActionsPopup } from 'components/UI/ActionsPopup/ActionsPopup';
import { ClientEditHeader } from 'components/Client/ClientEditHeader';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import PremiumFeatureAlert from 'components/UI/PremiumFeatureAlert';
import Tag from 'components/UI/V2/Tag/Tag';
interface ClientProps {
    clientId?: number;
    isNewBooking?: boolean;
    petId?: number;
    hideHeader?: boolean;
    onCloseModal?: () => void;
    onClose?: () => void;
    onClientSaved?: (client: ClientModel) => void;
    onPetSaved?: (pet: PetModel) => void;
    onDelete?: () => void;
}

interface BookingNew {
    clientId: number;
    petId?: number;
    clientName: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexFlow: 'column',

            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#fff'
            }
        },
        page: {
            backgroundColor: '#FAFAFA'
        },
        spinner: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(100)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '100px'
            }
        },
        clientInfo: {
            gap: 6,
            display: 'flex',
            flexDirection: 'column'
        },
        name: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`,
                paddingLeft: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px',
                paddingLeft: '5px'
            }
        },
        phoneContainer: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '5px'
            }
        },
        phone: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`,
                marginLeft: `${reduceResolution(7)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15px',
                marginLeft: '7px'
            }
        },
        phoneIcon: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px'
            }
        },
        mobileTitle: {
            fontSize: 18,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 24,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

export const ClientKey = 'client';
export const PetsKey = 'pets';
export const PrefixPetKey = 'pet:';
export const AddPetKey = 'add_pet';
export const HistoryKey = 'history';
export const AgreementsKey = 'agreements';

const agreementsEnabled = process.env.REACT_APP_AGREEMENTS_ENABLED === 'true';

export const Client: FunctionComponent<ClientProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const hasReportsPremiumAccess = useHasPremiumAccess('agreements');
    const [showActions, setShowActions] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const client = useSelector<RootState, ClientModel | undefined>(
        state => state.clients.client
    );
    const clientsStatus = useSelector<RootState, ClientsStatus>(
        state => state.clients.status
    );

    const pets = useSelector<RootState, Array<PetModel>>(
        state => state.pets.list
    );
    const petsStatus = useSelector<RootState, PetsStatus>(
        state => state.pets.status
    );

    const [addPet, setAddPet] = useState<PetModel | null>(null);
    const [editPet, setEditPet] = useState<PetModel | null>(null);

    const [petNotesEditedComponent, setPetNotesEditedComponent] =
        useState(false);

    const [initialPet, setInitialPet] = useState<PetModel | null>(null);
    const [clientLoading, setClientLoading] = useState(true);
    const [petsLoading, setPetsLoading] = useState(true);
    const [sidebarKey, setSidebarKey] = useState(
        props.petId ? `${PrefixPetKey}${props.petId}` : ClientKey
    );
    const [showNewBooking, setShowNewBooking] = useState<
        BookingNew | undefined
    >(undefined);
    const clientsDone = useSelector<RootState, boolean>(
        state => state.onboardingSteps.onboardingSteps.clientsDone
    );
    const [showNewPet, setShowNewPet] = useState<boolean>(false);
    const [petAdded, setPetAdded] = useState(false);

    const onBookClientHandler = () => {
        if (!client?.id) {
            return;
        }

        setShowNewBooking({
            clientId: client.id!,
            clientName: `${client.firstName} ${client.lastName}`,
            petId: editPet?.id
        });
    };

    useEffect(() => {
        if (showNewBooking)
            amplitude.track(AMPLITUDE.CTA_BOOK_APPOINTMENT_CLIENT_PROFILE);
    }, [showNewBooking]);

    const [isOnBoardingPetActive, setIsOnBoardingPetActive] = useState(false);

    useEffect(() => {
        dispatch(clearRetrievedClientThunk());
        if (props.clientId) {
            dispatch(getClientThunk(apiClientWrapper, props.clientId));
            dispatch(fetchPetsThunk(apiClientWrapper, props.clientId));
        } else {
            setClientLoading(false);
        }
    }, [props.clientId]);

    useNonInitialEffect(() => {
        if (ClientsStatus.GetSuccess === clientsStatus) {
            if (editPet?.id) {
                setEditPet(null);
                setSidebarKey(ClientKey);
            }
            if (props.isNewBooking) {
                setAddPet(createEmptyPet(uuid, props.clientId!));
                setSidebarKey(PetsKey);
            }
            setClientLoading(false);
            if (isOnBoardingPetActive) {
                setAddPet(createEmptyPet(uuid, client?.id!));
                containerRef.current!.scrollTop = 0;
                dispatch(onboardingCloseModal());
                setIsOnBoardingPetActive(false);
            }
        } else if (ClientsStatus.SaveSuccess === clientsStatus) {
            dispatch(onboardingCloseModal());
            setClientLoading(false);
        }
    }, [clientsStatus]);

    useNonInitialEffect(() => {
        if (PetsStatus.FetchSuccess === petsStatus) {
            if (props.petId && initialPet === null) {
                const pet = pets.find(pet => pet.id === props.petId) || null;
                setEditPet(pet);
                setInitialPet(pet);
            }
            setPetsLoading(false);
        }
    }, [petsStatus]);

    const title = client?.id === undefined ? 'Add Client' : 'Edit Client';

    const info = client ? (
        <Box className={classes.clientInfo}>
            <Typography
                className={classes.name}
            >{`${client?.firstName} ${client?.lastName}`}</Typography>

            {!isEmpty(client?.phone) && (
                <Box className={classes.phoneContainer}>
                    <FontAwesomeIcon
                        icon={faPhoneFlip}
                        className={classes.phoneIcon}
                    />
                    <Typography className={classes.phone}>
                        {formatPhoneNumber(
                            client?.phone,
                            apiClientWrapper.marketplace!.basics.address
                                .country!.id
                        )}
                    </Typography>
                </Box>
            )}

            {client.blocked && (
                <Box>
                    <Tag variant="danger">Blocked</Tag>
                </Box>
            )}

            {!client.blocked && (
                <Button
                    size="small"
                    label="Book appointment"
                    color="green"
                    onClick={onBookClientHandler}
                />
            )}
        </Box>
    ) : undefined;

    const sidebarItems: Array<PageSidebarItem> = [
        { icon: faImagesUser, title: 'Client Information', key: ClientKey },
        {
            icon: faPaw,
            title: 'Pets',
            key: PetsKey,
            subitems: [
                ...(pets || []).map(pet => ({
                    title: `${pet.deceased ? '🌈 ' : ''}${pet.name}`,
                    key: `${PrefixPetKey}${pet.id}`
                })),
                {
                    icon: faPlus,
                    title: 'Add Pet',
                    key: AddPetKey,
                    isLink: true
                }
            ]
        },
        { icon: faCalendar, title: 'History', key: HistoryKey },
        { icon: faFileSignature, title: 'Agreements', key: AgreementsKey }
    ]
        .filter(item => client?.id !== undefined || item.key === ClientKey)
        .filter(
            item =>
                (agreementsEnabled && hasReportsPremiumAccess) ||
                item.key !== AgreementsKey
        );

    const selectKeyHandler = (
        key: string,
        containerRef: React.RefObject<HTMLDivElement>
    ) => {
        setSidebarKey(key);
        containerRef.current!.scrollTop = 0;
        setShowActions(false);

        switch (key) {
            case ClientKey:
                setEditPet(null);
                setAddPet(null);
                break;
            case AddPetKey:
                setAddPet(createEmptyPet(uuid, client?.id!));
                setEditPet(null);
                break;
            case AgreementsKey:
                setAddPet(null);
                setEditPet(null);
                break;
            case HistoryKey:
                setAddPet(null);
                setEditPet(null);
                break;
            default:
                const selectedPetId = Number(key.slice(PrefixPetKey.length));
                setEditPet(pets.find(pet => pet.id === selectedPetId) || null);
                setAddPet(null);
        }
    };

    const reload = (client: ClientModel) => {
        setClientLoading(true);
        setPetsLoading(true);
        if (client) {
            dispatch(getClientThunk(apiClientWrapper, client.id!));
            dispatch(fetchPetsThunk(apiClientWrapper, client.id!));
        }
    };

    const saveClientHandler = (savedClient: ClientModel) => {
        reload(savedClient);

        if (clientsDone === false) {
            setIsOnBoardingPetActive(true);
            selectKeyHandler(PetsKey, containerRef);
        }

        props.onClientSaved && props.onClientSaved(savedClient);
    };

    const savePetHandler = (pet: PetModel, petNotesEdited: boolean) => {
        reload(client!);
        setAddPet(null);
        setEditPet({ ...pet });

        if (clientsDone === false) {
            dispatch(fetchOnboardingStepsThunk());
        }

        setPetNotesEditedComponent(petNotesEdited);

        props.onPetSaved && props.onPetSaved({ ...pet });
    };

    const saveAddPetHandler = (
        pet: PetModel,
        containerRef: React.RefObject<HTMLDivElement>
    ) => {
        savePetHandler(pet, false);
        setSidebarKey(`${PrefixPetKey}${pet.id}`);
        containerRef.current!.scrollTop = 0;
        setShowNewPet(false);
        setPetAdded(true);
    };

    const deletePetHandler = (
        containerRef: React.RefObject<HTMLDivElement>
    ) => {
        setSidebarKey(ClientKey);
        setEditPet(null);
        setAddPet(null);
        containerRef.current!.scrollTop = 0;
        reload(client!);
    };

    const backToClientInformation = () => {
        setSidebarKey(ClientKey);
        setEditPet(null);
        setAddPet(null);
    };

    const addNewPet = () => {
        setAddPet(createEmptyPet(uuid, props.clientId!));
        setShowNewPet(true);
    };

    const handleBackNewBooking = () => {
        setShowNewBooking(undefined);
        setPetAdded(false);
        setShowNewPet(false);
    };

    const sidebar = (
        <PageSidebar
            title={title}
            info={info}
            items={sidebarItems}
            selectedKey={sidebarKey}
            onSelect={key => selectKeyHandler(key, containerRef)}
            onBack={props.onClose}
        />
    );

    const newBookingView = (
        <CreateBookingDrawer
            parentID={showNewBooking?.clientId}
            parentName={showNewBooking?.clientName}
            petID={showNewBooking?.petId}
            fromClient={true}
            onBooked={handleBackNewBooking}
            onClose={handleBackNewBooking}
            petAdded={petAdded}
            showTabs={false}
            onAddPet={addNewPet}
        />
    );

    const openActionsMobile = () => {
        setShowActions(true);
    };

    const clientActionsPopup = (
        <ActionsPopup
            actions={[
                {
                    label: 'Client Information',
                    icon: faImagesUser,
                    onClick: () => selectKeyHandler(ClientKey, containerRef)
                },
                {
                    icon: faPaw,
                    label: 'Pets',
                    items: pets.map(pet => ({
                        label: (
                            <>
                                {pet.name}
                                {pet.deceased ? ' 🌈' : ''}
                                {pet.hasMedicalConditions && (
                                    <FontAwesomeIcon
                                        size="lg"
                                        color="#EF4F57"
                                        icon={faHeartPulse}
                                        style={{ marginLeft: 4 }}
                                    />
                                )}
                            </>
                        ),
                        onClick: () =>
                            selectKeyHandler(
                                `${PrefixPetKey}${pet.id}`,
                                containerRef
                            )
                    })),
                    rightElement: (
                        <UnderlinedButton
                            color="green"
                            label="Add Pet"
                            leftIcon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() =>
                                selectKeyHandler(AddPetKey, containerRef)
                            }
                        />
                    ),
                    onClick: () => selectKeyHandler(PetsKey, containerRef)
                },
                {
                    label: 'History',
                    icon: faCalendarDays,
                    onClick: () => selectKeyHandler(HistoryKey, containerRef)
                },
                {
                    label: 'Agreements',
                    icon: faFileSignature,
                    onClick: () => selectKeyHandler(AgreementsKey, containerRef)
                }
            ]}
            onClose={() => setShowActions(false)}
        />
    );

    return (
        <PrivatePage
            title="Client"
            pageName="/client"
            pageSidebar={sidebar}
            className={classes.page}
            hideHeader={props.hideHeader}
            onClose={props.onCloseModal}
        >
            <div ref={containerRef} className={classes.container}>
                {(clientLoading || (petsLoading && props.petId)) && (
                    <Spinner className={classes.spinner} />
                )}
                {!clientLoading && editPet !== null && client && (
                    <EditPet
                        pet={editPet!}
                        onDeleted={() => deletePetHandler(containerRef)}
                        onSaved={savePetHandler}
                        editPetNotes={petNotesEditedComponent}
                        onSelect={backToClientInformation}
                        onSelectDropdown={key =>
                            selectKeyHandler(key, containerRef)
                        }
                        onClikedActions={openActionsMobile}
                    />
                )}
                {!clientLoading && addPet !== null && (
                    <AddPet
                        pet={addPet!}
                        onDeleted={() => deletePetHandler(containerRef)}
                        onSaved={pet => saveAddPetHandler(pet, containerRef)}
                        onSelect={backToClientInformation}
                    />
                )}
                {!clientLoading &&
                    addPet === null &&
                    editPet === null &&
                    sidebarKey === ClientKey && (
                        <ClientComponent
                            client={client || createEmptyClient(uuid)}
                            onSaved={saveClientHandler}
                            onBook={onBookClientHandler}
                            onClose={() => props.onClose && props.onClose()}
                            onDeleted={() =>
                                props.onDelete
                                    ? props.onDelete()
                                    : props.onClose && props.onClose()
                            }
                            onSelect={key =>
                                selectKeyHandler(key, containerRef)
                            }
                        />
                    )}
                {!clientLoading &&
                    addPet === null &&
                    editPet === null &&
                    sidebarKey === AgreementsKey &&
                    client && (
                        <>
                            {hasReportsPremiumAccess ? (
                                <ClientSignatures
                                    client={client!}
                                    onBook={onBookClientHandler}
                                    onClose={() =>
                                        props.onClose && props.onClose()
                                    }
                                    onSelect={key =>
                                        selectKeyHandler(key, containerRef)
                                    }
                                />
                            ) : (
                                <PremiumFeatureAlert
                                    text={
                                        <>
                                            Keep clients informed of your
                                            policies with Agreements. Create
                                            custom agreements, send via email or
                                            text, and automate signature
                                            collection. <b>Upgrade now</b> and
                                            ditch paper agreements.
                                        </>
                                    }
                                    isOpen={true}
                                    onCancel={() =>
                                        selectKeyHandler(
                                            ClientKey,
                                            containerRef
                                        )
                                    }
                                />
                            )}
                        </>
                    )}
                {!clientLoading &&
                    client &&
                    addPet === null &&
                    editPet === null &&
                    sidebarKey === HistoryKey && (
                        <>
                            <Box style={{ backgroundColor: '#fff' }}>
                                <Box
                                    style={{
                                        padding: '24px 16px 0px'
                                    }}
                                >
                                    <ClientEditHeader
                                        client={client}
                                        onBook={onBookClientHandler}
                                        onClose={() =>
                                            props.onClose && props.onClose()
                                        }
                                        onSelect={key =>
                                            selectKeyHandler(key, containerRef)
                                        }
                                    />

                                    <Typography
                                        variant="h3"
                                        className={classes.mobileTitle}
                                    >
                                        History
                                    </Typography>
                                </Box>
                                <BookingsComponentClient
                                    clientID={client!.id!}
                                    petIds={(client?.pets || []).map(
                                        pet => pet.id
                                    )}
                                    from={'client'}
                                />
                            </Box>
                        </>
                    )}
                {showNewBooking && !showNewPet && newBookingView}
            </div>

            {showActions && clientActionsPopup}
        </PrivatePage>
    );
};

export default Client;
