import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { wbp } from "Theme";
import { Img } from "react-image";

interface LogoCuddlesProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      [theme.breakpoints.down(wbp)]: {
        width: "34px !important",
        height: "34px !important",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "42px !important",
        height: "42px !important",
      },
    },
  })
);

export const LogoCuddles: FunctionComponent<LogoCuddlesProps> = (props) => {
  const classes = useStyles();

  return (
    <Img src="/images/logo/logo.png" />
  );
};

export default LogoCuddles;
