const Clarity = {
    consent: (): void => {
        window.clarity('consent');
    },
    identify: (
        customId: string,
        customSessionId?: string,
        customPageId?: string,
        friendlyName?: string
    ): void => {
        window.clarity(
            'identify',
            customId,
            customSessionId,
            customPageId,
            friendlyName
        );
    },
    set: (key: string, value: string | string[]): void => {
        window.clarity('set', key, value);
    },
    event: (value: string): void => {
        window.clarity('event', value);
    }
};

export default Clarity;
