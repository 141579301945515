import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import store from 'store';
import ApiClient, { createTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction, showError } from '@spike/notifications-action';
import {
	TIPSREPORT_ERROR_ACTION_TYPE,
	TIPSREPORT_GET_DETAIL_START_ACTION_TYPE,
	TIPSREPORT_GET_DETAIL_SUCCESS_ACTION_TYPE,
	TipsReportAction
} from './TipsReportActionsTypes';
import { TipsReportState } from 'reducers/tipsReport/TipsReportState';
import { convertToTipsReport } from './tipsReportConverter';
import { InvoicesTipsDetailReportDto } from './TipsReportDto';

export const getInvoicesTipDetailReport = (
	start?: string,
	end?: string,
	staffIds?: string
): ThunkAction<
	void,
	null,
	TipsReportState,
	TipsReportAction | NotificationsAction
> => {
	return async dispatch => {
		dispatch(getInvoicesTipDetailReportStart());

		const { marketplaceId } = store.getState().login.auth;
		let url = `/invoices_tip_detail_report?marketplace_id=${marketplaceId}`;

		if (start) url += `&start=${start}`;
		if (end) url += `&end=${end}`;
		if (staffIds) url += `&staff_ids=${staffIds}`;

		try {
			const response: AxiosResponse<InvoicesTipsDetailReportDto> =
				await ApiClient.get(
					url,
					createTokenConfig(store.getState().login.auth.token!)
				);
			dispatch(getInvoicesTipDetailReportSuccess(response.data));
		} catch (apiError) {
			if (!isCancelled(apiError)) {
				dispatch(error());
				dispatch(showError('Error get invoices tip detail report.'));
			}
		}
	};
};

const getInvoicesTipDetailReportStart = (): TipsReportAction => ({
	type: TIPSREPORT_GET_DETAIL_START_ACTION_TYPE
});

const getInvoicesTipDetailReportSuccess = (
	invoices: InvoicesTipsDetailReportDto
): TipsReportAction => {
	const tipsReportConverted = convertToTipsReport(invoices);
	return {
		type: TIPSREPORT_GET_DETAIL_SUCCESS_ACTION_TYPE,
		payload: {
			invoices: tipsReportConverted.invoices,
			totalTips: tipsReportConverted.totalTips,
			totalAppointments: tipsReportConverted.totalAppointments
		}
	};
};

const error = (): TipsReportAction => ({ type: TIPSREPORT_ERROR_ACTION_TYPE });
