import {
    faAngry,
    faEar,
    faMeh,
    faPoo,
    faSmile
} from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Chip2 as Chip } from 'components/UI';
import { useMasterData } from 'hooks';
import { PetPersonality as PetPersonalityModel } from '@spike/pet-model';
import { FunctionComponent } from 'react';

interface PetPersonalityProps {
    selectedPersonalities: Array<PetPersonalityModel>;
    onChange?: (selectedPersonalities: Array<PetPersonalityModel>) => void;
    className?: string;
    disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        containerPersonality: {
            '& p': {
                fontSize: '16px'
            },
            '& svg': {
                fontSize: '16px',
                marginRight: '5px'
            }
        }
    })
);

const icons = new Map([
    ['faAngry', faAngry],
    ['faMeh', faMeh],
    ['faSmile', faSmile],
    ['faEar', faEar],
    ['faPoo', faPoo]
]);

const PetPersonality: FunctionComponent<PetPersonalityProps> = props => {
    const classes = useStyles();

    const changeHandler = (personality: PetPersonalityModel) => {
        const selectedPersonalities = props.selectedPersonalities.some(
            p => p.conditionId === personality.conditionId
        )
            ? props.selectedPersonalities.filter(
                  p => p.conditionId !== personality.conditionId
              )
            : [...props.selectedPersonalities, { ...personality }];

        props.onChange && props.onChange(selectedPersonalities);
    };

    const personalities: Array<PetPersonalityModel> = useMasterData()
        .medicalHistory.behaviorConditionCategories.flatMap(category =>
            category.options.map(option => ({
                categoryId: category.id,
                categoryIcon: category.icon,
                conditionId: option.id,
                conditionName: option.name
            }))
        )
        .sort((a, b) => a.conditionName.localeCompare(b.conditionName)); // Sorting personalities alphabetically

    return (
        <Grid
            container
            xs={12}
            className={clsx(
                classes.container,
                classes.containerPersonality,
                props.className
            )}
            spacing={2}
        >
            {personalities.map(personality => (
                <Grid key={personality.id} item>
                    <Chip
                        key={personality.id}
                        label={personality.conditionName}
                        selected={props.selectedPersonalities.some(
                            p => p.conditionId === personality.conditionId
                        )}
                        onClick={() => changeHandler(personality)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default PetPersonality;
