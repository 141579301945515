import React, {
    FunctionComponent,
    Fragment,
    ChangeEvent,
    useState
} from 'react';
import {
    Avatar,
    Box,
    InputBase,
    Typography,
    ClickAwayListener,
    Dialog,
    DialogContent
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Staff from 'model/Staff';
import { StaffMember } from 'model/Service';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Member from 'components/Member';

interface Props {
    id?: string;
    onSelect?: (staff: Array<StaffMember>) => void;
    onBack?: () => void;
    staff: Array<StaffMember>;
    className?: string;
    showAddNew?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',

            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        dropdown: {
            borderRadius: 18,
            overflow: 'hidden',
            border: 'solid 1px #000',
            backgroundColor: 'white',

            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
            [theme.breakpoints.up('md')]: {
                minWidth: 164
            }
        },
        header: {
            gap: 6,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px 0px',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        searchTextField: {
            fontFamily: 'Poppins',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px',
                lineHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px',
                lineHeight: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '13px',
                lineHeight: '22px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '16px',
                lineHeight: '27px'
            }
        },
        avatarContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        emptyAvatar: {
            width: 24,
            height: 24,
            borderRadius: '50%',
            border: '1px dashed #D3D3D3'
        },
        body: {
            display: 'flex',
            flexDirection: 'column'
        },
        listItem: {
            'gap': 6,
            'width': '100%',
            'display': 'flex',
            'alignItems': 'center',
            'padding': '7px 16px',

            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#F1F1F1'
            },
            '&:first-child': {
                marginTop: 8
            },
            '&:last-child': {
                marginBottom: 8
            }
        },
        textItem: {
            color: '#222222',
            fontSize: 14,
            lineHeight: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            [theme.breakpoints.up('md')]: {
                fontSize: '15px'
            }
        },
        avatarItem: {
            width: 24,
            height: 24
        },
        scroll: {
            'maxHeight': 200,

            [theme.breakpoints.up('md')]: {
                maxHeight: '345px'
            },

            '& > .os-scrollbar.os-scrollbar-vertical': {
                top: 6,
                right: 4,
                bottom: 6
            }
        },
        footer: {
            'height': 40,
            'width': '100%',
            'display': 'flex',
            'alignItems': 'center',
            'backgroundColor': '#000000',
            'cursor': 'pointer',

            '&:hover': {
                backgroundColor: '#92B4A7'
            },

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        textFooter: {
            fontSize: 15,
            fontWeight: 500,
            width: '100%',
            color: '#FFFFFF',
            textAlign: 'center'
        }
    })
);

const StaffSelector: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const showFooter = props.showAddNew === undefined ? true : props.showAddNew;

    const [filteredStaff, setFilteredStaff] = useState(props.staff);
    const [showNewStaff, setShowNewStaff] = useState(false);

    const match = (member: StaffMember, searchText: string) => {
        const separator = ' ';
        const textTokens: Array<string> = searchText.split(separator);

        return !textTokens
            .map(token => token.toLowerCase())
            .some(
                textToken =>
                    !member.firstName.toLocaleLowerCase().includes(textToken) &&
                    !member.lastName.toLocaleLowerCase().includes(textToken)
            );
    };

    const changeSearchTextHander = (event: ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        setFilteredStaff(
            props.staff
                .filter(member => match(member, searchText))
                .sort((member, otherMember) => {
                    const name = `${member.firstName} ${member.lastName}`;
                    const otherName = `${otherMember.firstName} ${otherMember.lastName}`;
                    return name.localeCompare(otherName);
                })
        );
    };

    const selectHandler = (memberId: number) => {
        const selectedMember = props.staff.find(
            member => member.id === memberId
        );
        props.onSelect && selectedMember && props.onSelect([selectedMember]);
    };

    const selectAllHandler = () => {
        props.onSelect && props.onSelect([...props.staff]);
    };

    const savedHandler = (staff: Staff) => {
        props.onSelect &&
            props.onSelect([
                {
                    id: staff.id!,
                    firstName: staff.person.firstName,
                    lastName: staff.person.lastName,
                    avatar: staff.person.avatar
                }
            ]);
    };

    const clickAwayHandler = () => {
        props.onBack && props.onBack();
    };

    const header = (
        <Box className={classes.header}>
            <Box className={classes.avatarContainer}>
                <Box className={classes.emptyAvatar}></Box>
            </Box>
            <Box>
                <InputBase
                    autoFocus={true}
                    onChange={changeSearchTextHander}
                    className={classes.searchTextField}
                    placeholder="Search Staff"
                />
            </Box>
        </Box>
    );

    const allMembers = (
        <Box
            id={props.id ? props.id + '_list_all' : props.id}
            className={classes.listItem}
            onClick={() => {
                selectAllHandler();
            }}
        >
            <Avatar className={classes.avatarItem} />
            <Typography className={classes.textItem}>All Members</Typography>
        </Box>
    );

    const body = (
        <OverlayScrollbarsComponent className={classes.scroll}>
            <Box
                id={props.id ? props.id + '_list' : props.id}
                className={classes.body}
            >
                {allMembers}
                {filteredStaff.map((member, index) => (
                    <Box
                        id={props.id ? props.id + '_list_' + index : props.id}
                        key={member.id}
                        className={classes.listItem}
                        onClick={() => {
                            selectHandler(member.id);
                        }}
                    >
                        <Avatar
                            className={classes.avatarItem}
                            src={member.avatar}
                        />
                        <Typography
                            className={classes.textItem}
                        >{`${member.firstName} ${member.lastName}`}</Typography>
                    </Box>
                ))}
            </Box>
        </OverlayScrollbarsComponent>
    );

    const footer = (
        <Box className={classes.footer} onClick={() => setShowNewStaff(true)}>
            <Typography
                id={props.id ? props.id + '_text_add_member' : props.id}
                className={classes.textFooter}
            >
                + Add New
            </Typography>
        </Box>
    );

    const dropdown = (
        <Box className={classes.dropdown}>
            {header}
            {body}
            {showFooter && footer}
        </Box>
    );

    const newStaff = (
        <Dialog fullScreen={true} open={showNewStaff}>
            <DialogContent>
                <Member
                    onBack={() => {
                        setShowNewStaff(false);
                    }}
                    onAddAnother={() => setShowNewStaff(true)}
                    onSaved={savedHandler}
                />
            </DialogContent>
        </Dialog>
    );

    return (
        <Fragment>
            {!showNewStaff && (
                <Box className={clsx(classes.container, props.className)}>
                    <ClickAwayListener onClickAway={clickAwayHandler}>
                        {dropdown}
                    </ClickAwayListener>
                </Box>
            )}
            {showNewStaff && newStaff}
        </Fragment>
    );
};

export default StaffSelector;
