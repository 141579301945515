import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import SidebarFooterIcon from "./SidebarFooterIcon";

interface HelpCardProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    left: {
      display: "flex",
    },
    right: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(11)}px 0px 0px ${reduceResolution(11)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "11px 0px 0px 11px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: 16,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
    },
    description: {
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: 14,
        lineHeight: '20px',
        marginTop: `${reduceResolution(9)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: 14,
        lineHeight: '20px',
        marginTop: "9px",
      },
    },
    contactUsContainer: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      width: "min-content",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "6px",
      },
    },
    contactUs: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#222222",
      whiteSpace: "nowrap",
      [theme.breakpoints.down(wbp)]: {
        fontSize: 15,
        lineHeight: `${reduceResolution(34)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
        lineHeight: "34px",
      },
    },
    contactUsIcon: {
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(15)}px !important`,
        height: `${reduceResolution(15)}px !important`,
        marginLeft: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "15px !important",
        height: "15px !important",
        marginLeft: "3px",
      },
    },
  })
);

export const HelpCard: FunctionComponent<HelpCardProps> = (props) => {
  const classes = useStyles();

  const contactUsHandler = () => {
    window.open(process.env.REACT_APP_CONTACT_US_URL);
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.left}>
        <SidebarFooterIcon />
      </Box>
      <Box className={classes.right}>
        <Typography className={classes.title}>Need Help?</Typography>
        <Typography className={classes.description}>
          We will help you with your CuddlesPay
          <br />
          sign up process. Please feel free to
          <br />
          contact us if you have any questions.
        </Typography>
        <Box className={classes.contactUsContainer} onClick={contactUsHandler}>
          <Typography className={classes.contactUs}>Contact Us</Typography>
          <FontAwesomeIcon icon={faChevronRight} className={classes.contactUsIcon} />
        </Box>
      </Box>
    </Box>
  );
};

export default HelpCard;
