import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

interface Props {
    children: React.ReactNode,
    className?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        color: '#BAA997',
        fontSize: "46px",
        lineHeight: 0.95,
        fontWeigth: 600,
        marginBottom: 15,
        fontFamily: 'YoungSerif'
    }
}))

function TitlePrimary(props: Props) {
    const classes = useStyles(props)

    return (
        <Typography className={clsx(props.className, classes.root)}>{props.children}</Typography>
    )
}

export default TitlePrimary
