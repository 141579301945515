import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMasterData } from 'hooks';
import { SelectableOption } from 'model';
import { Option } from '@spike/model';

import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import { Finding } from 'model/GroomerReport';
import PlacesInput from './PlacesInput';
import clsx from 'clsx';

interface Props {
	findings: Array<Finding>;
	multipleSelection?: boolean;
	onChangeSelection: (findings: Array<Finding>) => void;
	isReport: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		problemsContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		problemsContainerDisabled: {
			'display': 'flex',
			'flexDirection': 'column',
			'pointerEvents': 'none',

			'& .MuiInputBase-input': {
				display: 'none !important'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		addCondition: {
			'color': '#92B4A7',
			'marginTop': 12,

			'&:hover': {
				cursor: 'pointer'
			},

			'& p': {
				fontSize: 14,
				lineHeight: 1,
				fontWeight: 500
			},

			[theme.breakpoints.up('md')]: {
				fontSize: 12,
				fontWeight: 400,
				marginBottom: '34px',
				color: '#5E8677'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				marginBottom: '42px'
			}
		},
		icon: {
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '40px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '53px'
			}
		},
		condContainer: {
			top: 30,
			zIndex: 100,
			width: '100%',
			height: 'auto',
			background: '#fff',
			padding: '24px 16px',
			position: 'absolute',
			borderRadius: '20px',
			border: '1px solid #000',

			[theme.breakpoints.up('md')]: {
				padding: 25,
				width: '500px'
			}
		},
		conditionsContainer: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		conditionsEditablesContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				marginLeft: 6
			}
		},
		conditionEditableContainer: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			marginBottom: 16,

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				marginBottom: 24
			}
		},
		conditionContainer: {
			'height': 40,
			'padding': '12px 20px',
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#F0F4F2',
			'borderRadius': 30,

			'&:hover': {
				cursor: 'pointer'
			},

			[theme.breakpoints.up('xl')]: {
				height: '45px'
			}
		},
		conditionSelectedContainer: {
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff'
			}
		},
		label: {
			fontSize: 16,
			lineHeight: 1,
			display: 'flex',
			cursor: 'pointer',

			[theme.breakpoints.up('md')]: {
				fontSize: 12
			}
		},
		arrowContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		conditionsEditablesLabel: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px',
				width: '225px',
				paddingTop: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px',
				width: '300px',
				paddingTop: '16px'
			}
		},
		inputFinding: {
			'minHeight': '53px',
			'height': 'auto',
			'border': '1px solid #EAEAEA',
			'& .MuiOutlinedInput-inputAdornedStart': {
				alignSelf: 'flex-end',
				[theme.breakpoints.down('lg')]: {
					marginBottom: '12px'
				},
				[theme.breakpoints.up('xl')]: {
					marginBottom: '16px'
				}
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				padding: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				padding: '12px'
			}
		},
		finding: {
			cursor: 'pointer',
			[theme.breakpoints.down('lg')]: {
				marginRight: '6px',
				width: '65px',
				marginBottom: '6px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '8px',
				width: '70px',
				marginBottom: '8px'
			}
		},
		teethsContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			width: '700%'
		}
	})
);

export const FindingsComponent: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();
	const [showConditions, setShowConditions] = useState(false);
	const [showConditionsEditables, setShowConditionsEditables] =
		useState(true);
	const [addConditions, setShowAddConditions] = useState(true);

	const [findings, setFindings] = useState<Array<SelectableOption<Finding>>>(
		[]
	);

	const handleSelectFindings = (value: Finding, selected: boolean) => {
		const newFindings = [...findings];

		newFindings.forEach(finding => {
			finding.selected =
				finding.element.id === value
					? selected
					: props.multipleSelection
					? finding.selected
					: false;
		});
		const findingsSelected = newFindings
			.filter(f => f.selected)
			.map(f => f.element.id);

		setFindings(newFindings);
		props.onChangeSelection(findingsSelected);
	};

	const handleAddConditions = () => {
		setShowConditions(true);
		setShowConditionsEditables(false);
		setShowAddConditions(false);
	};

	const handleClickNext = () => {
		setShowConditions(false);
		setShowConditionsEditables(true);
		setShowAddConditions(true);
	};

	const searchPlaces = (find: Option<number>) => {
		let findingsAux = props.findings.find(f => f.finding.id === find.id)!;
		if (isEmpty(findingsAux)) {
			return [];
		} else {
			return findingsAux.places;
		}
	};

	useEffect(() => {
		let findingsSelectable: Array<SelectableOption<Finding>> =
			masterData.groomingReportOptions.findings.map(f => {
				let fdnAux = props.findings.find(
					fnd => fnd.finding.name === f.name
				)!;

				return {
					element: {
						id: {
							finding: f,
							places: searchPlaces(f),
							all: fdnAux?.all
						},
						name: ''
					},
					selected: props.findings.find(
						fin => fin.finding.id === f.id
					)
						? true
						: false
				};
			});
		findingsSelectable.sort((a, b) =>
			a.element.id.finding.name.localeCompare(b.element.id.finding.name)
		);
		setFindings(findingsSelectable);
	}, [props.findings]);

	const handleChangePlace = (
		places: Array<number>,
		all: boolean,
		fin: Finding
	) => {
		const newFindings = [...findings];

		newFindings.forEach(findings => {
			findings.element.id.finding.id === fin.finding.id
				? (fin = {
						finding: fin.finding,
						places,
						all
				  })
				: // eslint-disable-next-line no-self-assign
				  (fin = fin);
		});

		setFindings(newFindings);

		props.onChangeSelection(
			newFindings
				.filter(f => f.selected)
				.map(find =>
					find.element.id.finding.id === fin.finding.id
						? {
								finding: find.element.id.finding,
								places,
								all
						  }
						: find.element.id
				)
		);
	};

	return (
		<Box className={classes.container}>
			{(props.isReport ||
				(!isEmpty(props.findings) && !props.isReport)) && (
				<Typography className={classes.titleSection}>
					Findings
				</Typography>
			)}
			<Box className={classes.problemsContainer}>
				{props.isReport && showConditions && (
					<Box className={classes.condContainer}>
						<Box className={classes.conditionsContainer}>
							{findings.map((finding, index) => {
								return (
									<Box
										key={index}
										className={clsx(
											classes.conditionContainer,
											{
												[classes.conditionSelectedContainer]:
													finding.selected
											}
										)}
										onClick={() =>
											handleSelectFindings(
												finding.element.id,
												!finding.selected
											)
										}
									>
										<Typography className={classes.label}>
											{finding.element.id.finding.name}
										</Typography>
									</Box>
								);
							})}
						</Box>
						<Box className={classes.arrowContainer}>
							<FontAwesomeIcon
								icon={faArrowCircleRight}
								className={classes.icon}
								onClick={handleClickNext}
							/>
						</Box>
					</Box>
				)}
				{showConditionsEditables && (
					<Box
						className={
							props.isReport
								? classes.problemsContainer
								: classes.problemsContainerDisabled
						}
					>
						<Box className={classes.conditionsEditablesContainer}>
							{findings.map((finding, index) => {
								if (finding.selected) {
									return (
										<PlacesInput
											key={index}
											label={
												finding.element.id.finding.name
											}
											all={
												finding
													? finding.element.id.all!
													: false
											}
											places={finding.element.id.places}
											onChangePlace={(places, all) =>
												handleChangePlace(
													places,
													all,
													finding.element.id
												)
											}
										/>
									);
								} else {
									return <></>;
								}
							})}
						</Box>
					</Box>
				)}
				{props.isReport && addConditions && (
					<Box className={classes.addCondition}>
						<Typography onClick={handleAddConditions}>
							+ Add Condition
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default FindingsComponent;
