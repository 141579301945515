import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { SelectableOption } from 'model';
import { Option } from '@spike/model';
import CheckboxCircle from 'components/UI/CheckboxCircle';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

interface Props {
	options: Array<Option<number>>;
	masterOptions: Array<Option<number>>;
	title: string;
	multipleSelection?: boolean;
	isReport: boolean;
	onChangeSelection: (options: Array<Option<number>>) => void;
	isGroomingSummary?: boolean;
	isBehaviorSummary?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				height: '100%'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				marginBottom: 24,
				textTransform: 'uppercase'
			}
		},
		content: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap',
			width: (props: Props) =>
				props.isGroomingSummary ? 'fit-content' : 'auto',

			[theme.breakpoints.up('md')]: {
				justifyContent: 'space-between',
				columnGap: 20,
				rowGap: 12
			}
		},
		optionContainer: {
			height: 40,
			width: 'auto',
			borderRadius: 30,
			display: 'inline-flex',
			alignItems: 'center',
			backgroundColor: '#F0F4F2',

			[theme.breakpoints.up('xl')]: {
				height: 45
			}
		},
		optionSelectedContainer: {
			'height': 40,
			'width': 'auto',
			'borderRadius': 30,
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff !important'
			},

			[theme.breakpoints.up('xl')]: {
				height: 45
			}
		},
		label: {
			'fontSize': 16,
			'display': 'flex',
			'justifyContent': 'center',
			'padding': '0px 23px',

			'&:hover': {
				cursor: 'pointer'
			},

			[theme.breakpoints.up('md')]: {
				width: 102,
				paddingTop: '6px',
				paddingBottom: '8px',
				fontSize: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '8px',
				paddingBottom: '10px',
				fontSize: '20px',
				width: '154px'
			}
		},
		option: {
			display: 'flex',
			width: 'auto',
			[theme.breakpoints.down('lg')]: {
				marginRight: '21px',
				fontSize: '14px',
				marginBottom: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '28px',
				fontSize: '18px',
				marginBottom: '12px'
			}
		},
		displayNone: {
			display: 'none'
		},
		disabled: {
			pointerEvents: 'none'
		},
		optionCheck: {
			display: 'flex',
			width: 'auto',
			[theme.breakpoints.down('lg')]: {
				marginRight: '21px',
				fontSize: '14px',
				marginBottom: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '28px',
				fontSize: '18px',
				marginBottom: '12px'
			}
		},
		optionsCheck: {
			display: 'flex',
			flexWrap: 'wrap'
		}
	})
);

export const OptionsComponent: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const [options, setOptions] = useState<Array<SelectableOption<number>>>([]);

	useEffect(() => {
		let optionsAux = props.masterOptions.map(option => ({
			element: option,
			selected: props.options.find(op => op.id === option.id)
				? true
				: false
		}));
		setOptions(optionsAux);
	}, [props.options]);

	const handleSelectOption = (value: Option<number>, selected: boolean) => {
		if (props.multipleSelection || selected) {
			const newOptions = [...options];

			newOptions.forEach(op => {
				op.selected =
					op.element.id === value.id
						? selected
						: props.multipleSelection
						? op.selected
						: false;
			});

			setOptions(newOptions);
			props.onChangeSelection(
				newOptions.filter(opt => opt.selected).map(opt => opt.element)
			);
		}
	};

	return (
		<Box className={classes.container}>
			{(props.isReport ||
				(!isEmpty(props.options) && !props.isReport)) && (
				<Typography className={classes.titleSection}>
					{props.title}
				</Typography>
			)}
			{props.isBehaviorSummary
				? (props.isReport ||
						(!isEmpty(props.options) && !props.isReport)) && (
						<Box className={classes.optionsCheck}>
							{options.map(option => {
								if (option.selected) {
									return (
										<Box
											key={option.element.id}
											className={classes.optionCheck}
										>
											<CheckboxCircle
												checked={true}
												iconColor="#5E8677"
												onChange={() => {}}
											/>
											{option.element.name}
										</Box>
									);
								} else {
									return <></>;
								}
							})}
						</Box>
				  )
				: (props.isReport ||
						(!isEmpty(props.options) && !props.isReport)) && (
						<Box
							className={
								props.isGroomingSummary
									? clsx(classes.content, classes.disabled)
									: classes.content
							}
						>
							{options.map(option => {
								return (
									<Box
										id={`${
											props.isReport
												? 'grooming_report'
												: 'grooming_report_summary'
										}_${props.title}_${option.element.id}`}
										key={option.element.id}
										className={
											props.isGroomingSummary
												? option.selected
													? classes.optionSelectedContainer
													: classes.displayNone
												: option.selected
												? classes.optionSelectedContainer
												: classes.optionContainer
										}
									>
										<Typography
											className={classes.label}
											onClick={() =>
												handleSelectOption(
													option.element,
													!option.selected
												)
											}
										>
											{option.element.name}
										</Typography>
									</Box>
								);
							})}
						</Box>
				  )}
		</Box>
	);
};

export default OptionsComponent;
