import React, { FunctionComponent, useEffect, MouseEvent } from "react";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import paperPlane from "rocket/assets/images/paper-plane.svg";
import commonsStyles from "rocket/assets/css/Commons";
import { useHistory } from "react-router";
import { ForgotPasswordPath } from "routes/Paths";
import { forgotPasswordThunk, resetThunk } from "@spike/forgot-password-action";
import ApiClient from "api";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    textAlign: "center",
  },
  paperPlane: {
    width: 54,
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  paragraph: {
    maxWidth: 400,
    marginTop: 0,
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 35,
    textAlign: "center",
  },
  column: {
    display: "flex",
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    marginLeft: "auto",
    minHeight: "calc(100vh - 142px)",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F8F5F1",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "55%",
      flexBasis: "55%",
      paddingTop: 0,
      paddingLeft: "7%",
      paddingRight: "9%",
      paddingBottom: 0,
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "100vh",
      paddingTop: 50,
      paddingLeft: "7%",
      paddingRight: "13%",
    },
  },
}));

export const RightColumn: FunctionComponent = () => {
  const classes = useStyles();
  const otherClasses = commonsStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const email = useSelector<RootState, string>(state => state.forgotPassword.email);

  useEffect(() => {
    return () => { dispatch(resetThunk()) };
  }, []);

  useEffect(() => {
    if (!email) {
      history.push(ForgotPasswordPath);
    }
  }, [email, history]);

  const resend = (event: MouseEvent, email: string) => {
    event.preventDefault();
    dispatch(forgotPasswordThunk(email, ApiClient));
  }
  return (
    <Grid item xs={12} className={classes.column}>
      <div className={classes.heading}>
        <img src={paperPlane} alt="Paper Plane" className={classes.paperPlane} />
        <h2 className={classes.title}>Reset link sent</h2>
      </div>

      <p className={classes.paragraph}>
        We just sent a reset password link to your email. It’ll take a couple of minutes.{" "}
      </p>

      <p className={classes.paragraph}>
        Didn’t get the link?{" "}
        <a href="#" className={otherClasses.underlinedLink} onClick={(event) => { email && resend(event, email) }}>
          Resend
        </a>
      </p>
    </Grid>
  );
};

export default RightColumn;
