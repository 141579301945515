import { Action } from 'redux';

// Types
export const MENU_OPEN_ACTION = 'menu/open';
export const MENU_CLOSE_ACTION = 'menu/close';

export const MENU_SET_DROPDOWN_ACTION = 'menu/set_dropdown';

// Interfaces
type MenuOpenAction = Action<typeof MENU_OPEN_ACTION>
type MenuCloseAction = Action<typeof MENU_CLOSE_ACTION>

interface MenuSetDropdownACtion
	extends Action<typeof MENU_SET_DROPDOWN_ACTION> {
	payload: {
		dropdown: string;
	};
}

// Menu Action
export type MenuAction =
	| MenuOpenAction
	| MenuCloseAction
	| MenuSetDropdownACtion;
