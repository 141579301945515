import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { PropsWithChildren } from "react";
import {sidebarWidth, pageSidebarWidth, headerHeight, wbp, reduceResolution} from "Theme";

interface OverPageProps extends PropsWithChildren {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      background: "white",
      zIndex: 20,
      left: 0,
      bottom: 0,
      right: 0,
      overflowY: "auto",
      [theme.breakpoints.down(wbp)]: {
        top: `${reduceResolution(headerHeight)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        top: headerHeight,
      },
    },
  })
);

export const OverPage: FunctionComponent<OverPageProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {props.children}
    </Box>
  );
};

export default OverPage;
