import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useMarketplace } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useState } from 'react';
import CalendarBooking from './CalendarBooking';
import DateSelectorComponent from './DateSelectorComponent';
import HoursBooking from './HoursBooking';

interface PeriodSelectorSectionProps {
	from: Moment;
	to: Moment;
	onSelectPeriod: (from: Moment, to: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			paddingBottom: 18,
			[theme.breakpoints.up('md')]: {
				paddingBottom: 20
			}
		},
		sectionCalendar: {
			display: 'flex',
			justifyContent: 'space-between',
			marginRight: 17,
			[theme.breakpoints.up('md')]: {
				marginRight: 19
			}
		},
		section: {
			display: 'flex',
			marginRight: 0,
			[theme.breakpoints.up('md')]: {
				marginRight: 19
			}
		},
		text: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			fontWeight: 500,
			fontSize: 14,
			lineHeight: '14px',
			marginRight: 9,
			[theme.breakpoints.up('md')]: {
				marginRight: 10,
				fontSize: 16,
				lineHeight: '16px'
			}
		},
		contentDate: {
			top: 40,
			[theme.breakpoints.up('md')]: {
				top: 45,
			},
		},
		contentHours: {
			display: 'flex',
			flexDirection: 'column',
			border: '1px solid #D4D4D4',
			backgroundColor: '#fff',
			height: 252,
			width: 115,
			borderRadius: 11,
			paddingTop: 9,
			paddingBottom: 9,
            top: 40,
            left: -80,
			[theme.breakpoints.up('md')]: {
				height: 232,
				borderRadius: 12,
				paddingTop: 10,
				paddingBottom: 10,
                top: 45,
			},
		}
	})
);

const getDateFormatted = (date: Moment) => {
	const minutes = date.minutes();
	const dateFormatted = date;

	if (minutes > 0 && minutes <= 15) {
		dateFormatted.add(15 - minutes, 'minutes');
	} else if (minutes > 15 && minutes <= 30) {
		dateFormatted.add(30 - minutes, 'minutes');
	} else if (minutes > 30 && minutes <= 45) {
		dateFormatted.add(45 - minutes, 'minutes');
	} else if (minutes > 45 && minutes <= 59) {
		dateFormatted.add(60 - minutes, 'minutes');
	}

	return dateFormatted;
};

export const PeriodSelectorSection: FunctionComponent<
	PeriodSelectorSectionProps
> = props => {
	const classes = useStyles();
	const marketplace = useMarketplace();

	const [expanded, setExpanded] = useState({
		calendar: false,
		from: false,
		to: false
	});

	const expandCalendarHandler = () => {
		setExpanded(prev => ({
			...prev,
			calendar: !prev.calendar,
			from: false,
			to: false
		}));
	};

	const closeCalendarHandler = () => {
		setExpanded(prev => ({ ...prev, calendar: false }));
	};

	const expandFromHandler = () => {
		setExpanded(prev => ({
			...prev,
			calendar: false,
			from: !prev.from,
			to: false
		}));
	};

	const closeFromHandler = () => {
		setExpanded(prev => ({ ...prev, from: false }));
	};

	const expandToHandler = () => {
		setExpanded(prev => ({
			...prev,
			calendar: false,
			from: false,
			to: !prev.to
		}));
	};

	const closeToHandler = () => {
		setExpanded(prev => ({ ...prev, to: false }));
	};

	const selectDateHandler = (selectedDate: Date) => {
		closeCalendarHandler();
		const from = moment.tz(
			`${moment(selectedDate).format('YYYY-MM-DD')} ${props.from
				.format('LT')
				.toUpperCase()}`,
			'YYYY-MM-DD hh:mm A',
			marketplace.schedule.timeZone
		);
		const to = moment.tz(
			`${moment(selectedDate).format('YYYY-MM-DD')} ${props.to
				.format('LT')
				.toUpperCase()}`,
			'YYYY-MM-DD hh:mm A',
			marketplace.schedule.timeZone
		);
		props.onSelectPeriod(from, to);
	};

	const selectFromHandler = (selectedHour: string) => {
		closeFromHandler();
		const from = moment.tz(
			`${getDateFormatted(props.from).format(
				'YYYY-MM-DD'
			)} ${selectedHour.toUpperCase()}`,
			'YYYY-MM-DD hh:mm A',
			marketplace.schedule.timeZone
		);
		props.onSelectPeriod(from, props.to);
	};

	const selectToHandler = (selectedHour: string) => {
		closeToHandler();
		const to = moment.tz(
			`${getDateFormatted(props.from).format(
				'YYYY-MM-DD'
			)} ${selectedHour.toUpperCase()}`,
			'YYYY-MM-DD hh:mm A',
			marketplace.schedule.timeZone
		);
		props.onSelectPeriod(props.from, to);
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.sectionCalendar}>
				<Typography className={classes.text}>
					{props.from.format('dddd, DD MMM YYYY')}
				</Typography>
				<DateSelectorComponent
					id="date"
					expanded={expanded.calendar}
					className={classes.contentDate}
					onExpand={expandCalendarHandler}
					onClickAway={closeCalendarHandler}
				>
					<CalendarBooking
						date={getDateFormatted(props.from)}
						onDaySelect={selectDateHandler}
					/>
				</DateSelectorComponent>
			</Box>
			<Box className={classes.section}>
				<Typography className={classes.text}>
					{props.from.format('LT').toLowerCase()}
				</Typography>
				<DateSelectorComponent
					id="hour"
					expanded={expanded.from}
					className={classes.contentHours}
					onExpand={expandFromHandler}
					onClickAway={closeFromHandler}
				>
					<HoursBooking
						dayMoment={getDateFormatted(props.from)}
						onSelectHour={selectFromHandler}
					/>
				</DateSelectorComponent>
			</Box>
			<Box className={classes.section}>
				<Typography className={classes.text}>
					{props.to.format('LT').toLowerCase()}
				</Typography>
				<DateSelectorComponent
					id="hour"
					expanded={expanded.to}
					className={classes.contentHours}
					onExpand={expandToHandler}
					onClickAway={closeToHandler}
				>
					<HoursBooking
						dayMoment={getDateFormatted(props.to)}
						onSelectHour={selectToHandler}
					/>
				</DateSelectorComponent>
			</Box>
		</Box>
	);
};

export default PeriodSelectorSection;
