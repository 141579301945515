import { isEmpty, isEqual, isNil } from 'lodash';
import { Service, ServicePricing } from 'model/Service';
import { FieldErrorWithKey, ServiceCategoryType } from './model';
import { PetType } from '@spike/model';
import { fixedPriceOption, getPricingVariablesOption } from './Pricing/model';
import { MarketplaceDeposit } from '@spike/marketplace-model';

export const validateServicePetType = (service: Service): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    if (isEmpty(service.petTypes)) {
        errors.push({
            fieldName: 'petType',
            errorMessage: 'Pet type is required',
            key: ServiceCategoryType.CATEGORY
        });
    }
    return errors;
};

export const validateServiceName = (service: Service): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    if (!service.name || service.name.length === 0) {
        errors.push({
            fieldName: 'name',
            errorMessage: 'Service name is required',
            key: ServiceCategoryType.DETAIL
        });
    }
    return errors;
};

export const validateServiceDuration = (service: Service): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];

    if (
        (service.duration.simple === undefined ||
            service.duration.simple.hours + service.duration.simple.minutes === 0) &&
        service.duration.variable.length === 0
    ) {
        errors.push({
            fieldName: 'duration',
            errorMessage: 'Duration is required',
            key: ServiceCategoryType.DETAIL
        });
    }
    if (
        (service.duration.simple === undefined ||
            service.duration.simple.hours + service.duration.simple.minutes === 0) &&
        (service.duration.variable.length === 0 ||
            service.duration.variable.some(
                duration => (duration.duration.hours || 0) + (duration.duration.minutes || 0) === 0
            ))
    ) {
        service.duration.variable
            .filter(duration => duration.duration.hours + duration.duration.minutes === 0)
            .forEach(duration => {
                errors.push({
                    id: duration.petSize.id,
                    fieldName: 'duration',
                    errorMessage: `Duration is required`,
                    key: ServiceCategoryType.DETAIL
                });
            });
    }

    return errors;
};

export const validateServiceStaff = (service: Service): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    if (isEmpty(service.staff)) {
        errors.push({
            fieldName: 'staff',
            errorMessage: 'Staff cannot be empty',
            key: ServiceCategoryType.STAFF
        });
    }
    return errors;
};

export const validateService = (service: Service, deposit: MarketplaceDeposit): Array<FieldErrorWithKey> => {
    const petTypeErrors = validateServicePetType(service);
    const nameErrors = validateServiceName(service);
    const durationErrors = validateServiceDuration(service);
    const pricingErrors = validateServicePricingV2(service.pricing, deposit);

    return [...petTypeErrors, ...nameErrors, ...durationErrors, ...pricingErrors];
};

export const validateCategoryStep = (service: Service): boolean => {
    return isEmpty(validateServicePetType(service));
};

export const validateDetailsStep = (service: Service): boolean => {
    return isEmpty([...validateServiceName(service), ...validateServiceDuration(service)]);
};

export const validatePricingStep = (pricing: ServicePricing, deposit: MarketplaceDeposit): boolean => {
    return isEmpty(validateServicePricingV2(pricing, deposit));
};

export const validateStaffStep = (service: Service) => {
    return isEmpty(validateServiceStaff(service));
};

export const validateServiceComplete = (service: Service, deposit: MarketplaceDeposit) => {
    return isEmpty(validateService(service, deposit));
};

export const validateServicePricingV2 = (
    pricing: ServicePricing,
    deposit: MarketplaceDeposit
): Array<FieldErrorWithKey & { petTypeId: string }> => {
    const errors: Array<FieldErrorWithKey & { petTypeId: string; priceUuid: string | null; type: 'inline' | 'alert' }> =
        [];
    const selectedServiceOption = getPricingVariablesOption(pricing.variablePrice?.variables);
    const depositAmount = Number(deposit.value);
    const isPercentageDeposit = deposit.type === 'percentage';

    if (selectedServiceOption.id === fixedPriceOption.id) {
        pricing.fixedPrice?.map(price => {
            if (isNil(price.price) || isEqual(price.price, 0)) {
                errors.push({
                    fieldName: 'pricing',
                    errorMessage: 'Price is Required',
                    key: ServiceCategoryType.PRICING,
                    petTypeId: price.petType.id,
                    priceUuid: null,
                    type: 'inline'
                });
            } else if (!isPercentageDeposit && price.price < depositAmount) {
                errors.push({
                    fieldName: 'pricing',
                    errorMessage: '',
                    key: ServiceCategoryType.PRICING,
                    petTypeId: price.petType.id,
                    priceUuid: null,
                    type: 'alert'
                });
            }
        });
    }

    if (selectedServiceOption.id !== fixedPriceOption.id) {
        pricing.variablePrice?.prices.map(price => {
            if (isNil(price.price) || isEqual(price.price, 0)) {
                errors.push({
                    fieldName: 'pricing',
                    errorMessage: 'Price is required',
                    key: ServiceCategoryType.PRICING,
                    petTypeId: price.petType!.id,
                    priceUuid: price.uuid,
                    type: 'inline'
                });
            } else if (!isPercentageDeposit && price.price < depositAmount) {
                errors.push({
                    fieldName: 'pricing',
                    errorMessage: '',
                    key: ServiceCategoryType.PRICING,
                    petTypeId: price.petType!.id,
                    priceUuid: price.uuid,
                    type: 'alert'
                });
            }
        });
    }

    return errors;
};
