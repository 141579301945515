import React, { FunctionComponent } from 'react';
import {
    Box,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import clsx from 'clsx';

interface AmountTableProps {
    title: string;
    rows: Array<{
        label: string;
        value: string | number;
        quantity?: number;
        highlighted?: boolean;
        strikeThrough?: boolean;
        discountedValue?: string | number;
    }>;
    total?: { label: string; value: string | number };
    dueTotal?: { label: string; value: string | number };
    deposit?: { label: string; value: string | number };
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        amountContainer: {
            'width': '100%',
            'marginTop': 10,
            'marginBottom': 10,
            'paddingTop': 10,
            'paddingBottom': 10,
            '& strong': {
                fontWeight: 600
            }
        },
        amountContainerTitle: {
            fontSize: 18,
            fontWeight: 600
        },
        amountBody: {
            position: 'relative'
        },
        amountRow: {
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'flex-start',
            'width': '100%',
            'marginBottom': 12,
            '&:first-child': {
                marginTop: 15
            },
            '&:last-child': {
                marginBottom: 0
            }
        },
        amountLeft: {
            color: '#7A7A7A',
            fontWeight: 400
        },
        amountRight: {
            color: '#7A7A7A',
            fontWeight: 500
        },
        amoutTableTotal: {},
        amoutTableDueTotal: {
            fontSize: 18
        },
        highlighted: {
            color: '#000',
            fontWeight: 600
        },
        strikeThrough: {
            textDecoration: 'line-through'
        }
    })
);

const AmountTable: FunctionComponent<AmountTableProps> = ({
    title,
    rows,
    total,
    dueTotal,
    deposit,
    className
}) => {
    const classes = useStyles();

    const calculateDiscountedValue = (
        discountedValue: string | number,
        quantity = 1
    ): string => {
        const value = parseFloat(discountedValue.toString().replace('$', ''));
        return `$${(value * quantity).toFixed(2)}`;
    };

    return (
        <Box className={clsx(classes.amountContainer, className)}>
            <Typography
                className={classes.amountContainerTitle}
                variant="body1"
            >
                {title}
            </Typography>
            <Box className={classes.amountBody}>
                {rows.map((row, index) => (
                    <Box key={index} className={classes.amountRow}>
                        <Typography
                            className={classes.amountLeft}
                            variant="body2"
                            dangerouslySetInnerHTML={{
                                __html:
                                    row.quantity && row.quantity > 1
                                        ? `${row.label} (x${row.quantity})`
                                        : row.label
                            }}
                        />
                        <Typography
                            className={`${classes.amountRight} ${
                                row.highlighted ? classes.highlighted : ''
                            } ${
                                row.strikeThrough ? classes.strikeThrough : ''
                            }`}
                            variant="body2"
                        >
                            <Box
                                component="span"
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-end"
                            >
                                {(() => {
                                    const showDiscountedValue =
                                        row.discountedValue &&
                                        row.discountedValue !== row.value &&
                                        row.value !==
                                            calculateDiscountedValue(
                                                row.discountedValue,
                                                row.quantity
                                            );

                                    return (
                                        <>
                                            {showDiscountedValue &&
                                                row.discountedValue !==
                                                    undefined && (
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.strikeThrough
                                                        }
                                                    >
                                                        {calculateDiscountedValue(
                                                            row.discountedValue,
                                                            row.quantity
                                                        )}
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="body2"
                                                className={
                                                    showDiscountedValue
                                                        ? classes.highlighted
                                                        : ''
                                                }
                                            >
                                                {row.value}
                                            </Typography>
                                        </>
                                    );
                                })()}
                            </Box>
                        </Typography>
                    </Box>
                ))}

                {total && (
                    <Box className={classes.amountRow}>
                        <Typography
                            className={
                                deposit && dueTotal
                                    ? classes.amoutTableTotal
                                    : classes.amoutTableDueTotal
                            }
                            variant={deposit && dueTotal ? 'body2' : 'h6'}
                        >
                            <strong>{total.label}</strong>
                        </Typography>
                        <Typography
                            className={
                                deposit && dueTotal
                                    ? classes.amoutTableTotal
                                    : classes.amoutTableDueTotal
                            }
                            variant={deposit && dueTotal ? 'body2' : 'h6'}
                        >
                            <strong>{total.value}</strong>
                        </Typography>
                    </Box>
                )}

                {deposit && (
                    <Box className={classes.amountRow}>
                        <Typography
                            className={classes.amountLeft}
                            variant="body2"
                        >
                            {deposit.label}
                        </Typography>
                        <Typography
                            className={classes.amountRight}
                            variant="body2"
                        >
                            -{deposit.value}
                        </Typography>
                    </Box>
                )}

                {dueTotal && deposit && (
                    <Box className={classes.amountRow}>
                        <Typography
                            className={classes.amoutTableDueTotal}
                            variant="h6"
                        >
                            <strong>{dueTotal.label}</strong>
                        </Typography>
                        <Typography
                            className={classes.amoutTableDueTotal}
                            variant="h6"
                        >
                            <strong>{dueTotal.value}</strong>
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default AmountTable;
