import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	InputAdornment,
	InputBase,
	Typography,
	ClickAwayListener
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNonInitialEffect } from '@versiondos/hooks';
import { searchCustomersThunk } from 'actions/autocomplete/AutocompleteActions';
import clsx from 'clsx';
import AutocompleteResult from 'model/AutocompleteResult';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteStatus } from 'reducers/autocomplete/AutocompleteState';
import { RootState } from 'store';
import { wbp, reduceResolution } from 'Theme';
import { Spinner } from 'components/UI';

interface ClientSelectorProps {
	onSelectClient: (clientId: number | null) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative'
		},
		icon: {
			fontSize: 18,
			color: '#000000'
		},
		collapsed: {
			height: 47,
			display: 'flex',
			alignItems: 'center',
			border: '1px solid #D3D3D3',
			backgroundColor: 'white',
			padding: '0px 18px',
			borderRadius: 30
		},
		expandedHeader: {
			display: 'flex',
			border: '1px solid #D3D3D3',
			borderBottom: '1px solid #D3D3D3',
			backgroundColor: 'white',
			[theme.breakpoints.down(wbp)]: {
				height: `${reduceResolution(43)}px`,
				borderRadius: `${reduceResolution(24)}px ${reduceResolution(
					24
				)}px 0px 0px`,
				padding: `0px ${reduceResolution(18)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				height: '43px',
				borderRadius: '24px 24px 0px 0px',
				padding: '0px 18px'
			}
		},
		field: {
			'color': '#7A7A7A',
			'backgroundColor': '#ffffff',

			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`,
				fontHeight: `${reduceResolution(21)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				fontHeight: '21px'
			},

			'& .MuiInputBase-input': {
				'fontSize': 14,
				'color': '#7A7A7A',

				[theme.breakpoints.up('md')]: {
					fontSize: 16
				},

				'&::placeholder': {
					opacity: 1
				}
			}
		},
		expandedBody: {
			left: 0,
			width: '100%',
			zIndex: 999,
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			border: '1px solid #D3D3D3',
			borderTop: 'none',
			backgroundColor: 'white',

			[theme.breakpoints.down(wbp)]: {
				top: `${reduceResolution(44)}px`,
				minHeight: `${reduceResolution(47)}px`,
				borderRadius: `0px 0px ${reduceResolution(
					24
				)}px ${reduceResolution(24)}px`,
				padding: `${reduceResolution(22)}px 0px`
			},
			[theme.breakpoints.up(wbp)]: {
				top: '43px',
				minHeight: '47px',
				borderRadius: '0px 0px 24px 24px',
				padding: '22px 0px'
			}
		},
		spinner: {
			'& .MuiCircularProgress-root': {
				[theme.breakpoints.down(wbp)]: {
					width: `${reduceResolution(20)}px !important`,
					height: `${reduceResolution(20)}px !important`
				},
				[theme.breakpoints.up(wbp)]: {
					width: '20px !important',
					height: '20px !important'
				}
			}
		},
		listText: {
			cursor: 'pointer',
			width: '100%',
			fontFamily: 'Poppins',
			fontWeight: 400,
			verticalAlign: 'center',
			color: '#7A7A7A',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`,
				fontHeight: `${reduceResolution(27)}px`,
				padding: `${reduceResolution(4)}px ${reduceResolution(18)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				fontHeight: '27px',
				padding: '4px 18px'
			}
		},
		hover: {
			'&:hover': {
				backgroundColor: '#D3D3D3'
			}
		}
	})
);

export const ClientSelector: FunctionComponent<ClientSelectorProps> = props => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const result = useSelector<RootState, Array<AutocompleteResult>>(
		state => state.autocomplete.results
	);
	const autocompleteStatus = useSelector<RootState, AutocompleteStatus>(
		state => state.autocomplete.status
	);

	const [isLoading, setIsLoading] = useState(false);
	const [text, setText] = useState('');
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [selectedClientText, setSelectedClientText] = useState('');

	useNonInitialEffect(() => {
		const timer: NodeJS.Timer = setTimeout(() => {
			if (text.length > 0) {
				setIsLoading(true);
				dispatch(searchCustomersThunk(text));
			}
		}, 200);
		return () => clearTimeout(timer);
	}, [text]);

	useNonInitialEffect(() => {
		if (
			[
				AutocompleteStatus.SearchingSuccess,
				AutocompleteStatus.Error
			].includes(autocompleteStatus)
		) {
			setIsLoading(false);
		}
	}, [autocompleteStatus]);

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setSelectedClientText('');
		setText(value);
		setIsCollapsed(value.length === 0);
		value.length === 0 && props.onSelectClient(null);
	};

	const selectionHandler = (client: AutocompleteResult) => {
		setText(client.name);
		setSelectedClientText(client.name);
		props.onSelectClient(client.id);
		setIsLoading(false);
		setIsCollapsed(true);
	};

	const clickAwayHandler = () => {
		setIsCollapsed(true);
		if (selectedClientText !== text) {
			setText('');
			props.onSelectClient(null);
		}
	};
	const expanded = (
		<Box className={classes.expandedBody}>
			{isLoading && <Spinner className={classes.spinner} />}
			{!isLoading &&
				result.length > 0 &&
				result.map(client => (
					<Typography
						key={client.id}
						className={clsx(classes.listText, classes.hover)}
						onClick={() => selectionHandler(client)}
					>
						{client.name}
					</Typography>
				))}
			{!isLoading && result.length === 0 && (
				<Typography className={classes.listText}>
					Clients not found.
				</Typography>
			)}
		</Box>
	);

	return (
		<ClickAwayListener onClickAway={clickAwayHandler}>
			<Box className={clsx(classes.container, props.className)}>
				<Box
					className={clsx({
						[classes.collapsed]: isCollapsed,
						[classes.expandedHeader]: !isCollapsed
					})}
				>
					<InputBase
						placeholder="Search by Client"
						value={text}
						onChange={changeHandler}
						startAdornment={
							<InputAdornment position="start">
								<FontAwesomeIcon
									icon={faSearch}
									className={classes.icon}
								/>
							</InputAdornment>
						}
						className={classes.field}
					/>
				</Box>
				{!isCollapsed && expanded}
			</Box>
		</ClickAwayListener>
	);
};

export default ClientSelector;
