import React, { FunctionComponent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Chip from './Chip';
import { StaffMember } from 'model/Service';
import { reduceResolution, wbp } from 'Theme';

interface Props {
	id?: string;
	staff?: StaffMember;
	small?: boolean;
	fullName?: boolean;
	cursorPointer?: boolean;
	fullWidth?: boolean;
	onDelete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chipStaff: {},
		avatar: (props: Props) => ({
			[theme.breakpoints.down('lg')]: {
				height: props.small ? '13px !important' : '16px !important',
				width: props.small ? '13px !important' : '16px !important'
			},
			[theme.breakpoints.up('xl')]: {
				height: props.small ? '18px !important' : '22px !important',
				width: props.small ? '18px !important' : '22px !important'
			},
			[theme.breakpoints.down('md')]: {
				height: props.small ? '18px !important' : '22px !important',
				width: props.small ? '18px !important' : '22px !important',
				marginLeft: props.small ? '0px !important' : '0px !important',
				display: 'inline-block',
				marginRight: '5px !important'
			}
		})
	})
);

export const StaffChip: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const avatar = (
		<Avatar src={props.staff?.avatar} className={classes.avatar} />
	);

	let label = props.fullName
		? props.staff
			? `${props.staff?.firstName} ${props.staff?.lastName}`
			: 'Unassigned'
		: props.staff?.firstName || 'Unassigned';

	return (
		<Chip
			id={props.id}
			className={classes.chipStaff}
			avatar={avatar}
			label={label}
			onDelete={props.onDelete}
		/>
	);
};

export default StaffChip;
