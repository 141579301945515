import { TipInvoice } from "model/TipsReport";

export interface TipsReportState {
    invoices: Array<TipInvoice>;
    totalTips?: number | string;
    totalAppointments?: number | string;
    status: TipsReportStatus;
    loading: boolean
}

export enum TipsReportStatus {
    Initial,
    Getting,
    GettingSuccess,
    Error
  }