import { FunctionComponent } from 'react';
import { PaymentsBankAccount as BankAccountModel } from '@spike/payments-model';
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import AccountType from './AccountType';
import Document from './Document';
import BankAccountStatus from './BankAccountStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import OptionsModal from 'components/Agreements/Modal/OptionsModal';
import { OptionTypes, bankAccountOptions } from './BankAccountsModel';

interface BankAccountCardMobileProps {
    account: BankAccountModel;
    bankAccountsQty: number;
    onSelectOption: (accountId: string) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px #D4D4D4 solid'
        },
        header: {
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        text: {
            fontSize: 14,
            fontWeight: 400
        },
        paddingWrapper: {
            padding: '20px 17px',
            gap: 2,
            display: 'flex',
            flexDirection: 'column'
        },
        dotsIcon: {
            width: 16,
            height: 16,
            color: '#000'
        },
        iconContainer: {
            width: 36,
            height: 36,
            borderRadius: 50,
            background: '#f1f1f1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 10
        },
        status: {
            width: '100%'
        }
    })
);

const BankAccountCardMobile: FunctionComponent<
    BankAccountCardMobileProps
> = props => {
    const classes = useStyles();

    const filteredOptions = bankAccountOptions.filter(option =>
        props.account.document
            ? option.id !== OptionTypes.UploadDocument
            : option
    );

    return (
        <Box className={classes.container}>
            <Box className={classes.paddingWrapper}>
                <Box className={classes.header}>
                    <AccountType
                        bankAccountsQty={props.bankAccountsQty}
                        bankAccount={props.account}
                    />
                    <Box className={classes.buttonsContainer}>
                        {props.account.document && <Document />}
                        {!props.account.primary && (
                            <OptionsModal
                                onClick={optrionId =>
                                    props.onSelectOption(optrionId)
                                }
                                options={filteredOptions}
                                actionComponent={
                                    <Box className={classes.iconContainer}>
                                        <FontAwesomeIcon
                                            icon={faEllipsis}
                                            className={classes.dotsIcon}
                                        />
                                    </Box>
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BankAccountCardMobile;
