import { faFileArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CalendarFilter } from 'components/UI';
import { Period } from '@spike/model';
import { PaymentsSalesReports } from '@spike/payments-model';
import moment from 'moment-timezone';
import { FunctionComponent, useState } from 'react';
import { CSVLink } from 'react-csv';

interface HeaderProps {
    salesReports?: PaymentsSalesReports;
    className?: string;
    onChangePeriod?: (period: Period | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            display: 'flex',
            borderRadius: 12,
            padding: '12px 16px',
            justifyContent: 'space-between',
            backgroundColor: '#F1F1F1',

            [theme.breakpoints.up('md')]: {
                marginTop: 24,
                padding: '14px 20px'
            }
        },
        dateContainer: {
            marginTop: 24,

            [theme.breakpoints.up('md')]: {
                marginTop: '45px'
            }
        },
        date: {
            color: '#222',
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: '26px'
            }
        },
        calendar: {
            '& > button': {
                'color': '#5E8677',
                'border': '2px solid #5E8677',
                'backgroundColor': 'white',
                '&:hover': {
                    backgroundColor: 'white'
                }
            }
        },
        csv: {
            'textDecoration': 'none',

            '& button': {
                [theme.breakpoints.down('sm')]: {
                    'height': 39,
                    'padding': '0px 16px',

                    '& span': {
                        // !important used due MUI adds negative margin
                        margin: '0px !important',
                        fontSize: 14,
                        lineHeight: 1
                    },
                    '& svg': {
                        width: 16,
                        height: 16,
                        marginRight: 4
                    }
                }
            }
        }
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();

    const [period, setPeriod] = useState<Period | null>({
        from: moment(),
        to: moment()
    });

    const csvData = [
        {
            completedTransactions:
                props.salesReports?.completedTransactions ?? 0,
            cuddlesFees: props.salesReports?.cuddlesFees ?? 0,
            discounts: props.salesReports?.discounts ?? 0,
            grossSales: props.salesReports?.grossSales ?? 0,
            grossSalesAddons: props.salesReports?.grossSalesAddons ?? 0,
            grossSalesProducts: props.salesReports?.grossSalesProducts ?? 0,
            grossSalesServices: props.salesReports?.grossSalesServices ?? 0,
            totalDeposits: props.salesReports?.totalDeposits ?? 0,
            netSales: props.salesReports?.netSales ?? 0,
            netTotal: props.salesReports?.netTotal ?? 0,
            refunds: props.salesReports?.refunds ?? 0,
            tax: props.salesReports?.tax ?? 0,
            tip: props.salesReports?.tip ?? 0,
            totalCash: props.salesReports?.totalCash ?? 0,
            totalCreditCards: props.salesReports?.totalCreditCards ?? 0,
            totalOther: props.salesReports?.totalOther ?? 0,
            totalSales: props.salesReports?.totalSales ?? 0,
            from: props.salesReports?.from?.format('YYYY-MM-DD') || '',
            to: props.salesReports?.to?.format('YYYY-MM-DD') || ''
        }
    ];

    const csvHeaders = [
        { label: 'Completed Transactions', key: 'completedTransactions' },
        { label: 'Cuddles Fees', key: 'cuddlesFees' },
        { label: 'Discounts', key: 'discounts' },
        { label: 'Gross Sales', key: 'grossSales' },
        { label: 'Gross Sales Addons', key: 'grossSalesAddons' },
        { label: 'Gross Sales Products', key: 'grossSalesProducts' },
        { label: 'Gross Sales Services', key: 'grossSalesServices' },
        { label: 'Net Sales', key: 'netSales' },
        { label: 'Net Total', key: 'netTotal' },
        { label: 'Refunds', key: 'refunds' },
        { label: 'Tax', key: 'tax' },
        { label: 'Tip', key: 'tip' },
        { label: 'Total Cash', key: 'totalCash' },
        { label: 'Total Credit Cards', key: 'totalCreditCards' },
        { label: 'Total Other', key: 'totalOther' },
        { label: 'Total Sales', key: 'totalSales' },
        { label: 'From', key: 'from' },
        { label: 'To', key: 'to' }
    ];

    const csvFileName = `sales_report_${
        props.salesReports?.from?.format('YYYY-MM-DD') || 'all'
    }_${props.salesReports?.to?.format('YYYY-MM-DD') || 'time'}.csv`;

    const changePeriodHandler = (period: Period, allTime: boolean) => {
        if (allTime) {
            setPeriod(null);
            props.onChangePeriod && props.onChangePeriod(null);
        } else {
            setPeriod({ from: period.from.clone(), to: period.to.clone() });
            props.onChangePeriod &&
                props.onChangePeriod({
                    from: period.from.clone(),
                    to: period.to.clone()
                });
        }
    };

    return (
        <Grid container className={props.className}>
            <Grid container xs={12} className={classes.bar}>
                <CalendarFilter
                    disableFuture
                    showLabelOnMobile
                    hideUpcoming
                    dropdownAlignment="left"
                    optionSelected="Today"
                    period={{ from: moment(), to: moment() }}
                    onChange={changePeriodHandler}
                    className={classes.calendar}
                />
                <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    enclosingCharacter=""
                    separator=","
                    filename={csvFileName}
                    target="_blank"
                    className={classes.csv}
                >
                    <Button
                        startIcon={faFileArrowDown}
                        label="Export"
                        color="black"
                    />
                </CSVLink>
            </Grid>
            <Grid item xs={12} className={classes.dateContainer}>
                <Typography className={classes.date}>
                    {period === null
                        ? 'All Time'
                        : period.from.clone().isSame(period.to.clone(), 'day')
                        ? period.from.format('MMMM DD, YYYY')
                        : `${period.from.format(
                              'MMMM DD, YYYY'
                          )} - ${period.to.format('MMMM DD, YYYY')}`}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;
