// usePreviousLocation.ts
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function usePreviousLocation(): string | undefined {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState<string | undefined>(undefined);

    useEffect(() => {
        return () => {
            // Store the current location as the previous location just before the component unmounts
            setPrevLocation(location.pathname);
        };
    }, [location.pathname]);

    return prevLocation;
}

export default usePreviousLocation;
