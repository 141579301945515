import { faEnvelope, faSms } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { Option } from '@spike/model';
import { Chip, SelectField, Spinner } from 'components/UI';
import Switch from 'components/UI/V2/Switch';
import { useHasPremiumPlan, useMarketplace } from 'hooks';
import { FunctionComponent, useState, ReactNode } from 'react';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';

export interface Props {
    optionId: string;
    noticeOptions: Array<Option<string>>;
    headerIcon: IconDefinition;
    title: string;
    subtitle: string | ReactNode;
    onEdit?: (
        emailChecked: boolean,
        smsChecked: boolean,
        noticeSelected: Option<string>
    ) => void;
    loading: boolean;
    label?: string;
    defaultOption?: Option<string>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editContainer: {
            minWidth: '464px',
            marginTop: '35px',
            [theme.breakpoints.down('sm')]: {
                minWidth: '0px !important',
                padding: '20px 16px',
                marginTop: '25px !important',
                width: '100%'
            }
        },
        editHeader: {
            display: 'flex',
            gap: '15px',
            marginBottom: '10px'
        },
        editSwitchContainer: {
            border: '#D4D4D4 1px solid',
            borderRadius: '20px',
            padding: '20px',
            marginTop: '25px',
            display: 'flex',
            gap: '15px'
        },
        editSwitchContainerDisabled: {
            backgroundColor: '#F8F8F8'
        },
        editSelectContainer: {
            marginTop: '35px'
        },
        editIconContainer: {
            backgroundColor: '#E4DDD5',
            borderRadius: '50px',
            width: '44px',
            height: '44px',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center'
        },
        editSwitchIconContainer: {
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end'
        },
        editIcon: {
            fontSize: '22px',
            fontWeight: 300
        },
        editSwitchIcon: {
            fontSize: '22px',
            fontWeight: 300
        },
        editSwitchDisabled: {
            color: '#7A7A7A !important'
        },
        editSwitchTitle: {
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: 500
        },
        editSwitchSubTitle: {
            marginTop: '6.5px',
            fontSize: '14px',
            lineHeight: '17.36px',
            fontWeight: 400,
            color: '#7A7A7A'
        },
        editHeaderTitle: {
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: 600
        },
        editHeaderSubTitle: {
            marginTop: '8px',
            fontSize: '14px',
            lineHeight: '19.6px',
            fontWeight: 400
        },
        firstColumn: {
            width: '5%'
        },
        column: {
            width: '90%'
        },
        lastColumn: {
            width: '10%',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        selectPlaceHolder: {
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: 600,
            marginBottom: '15px'
        },
        premiumChip: {
            display: 'inline',
            marginLeft: '2px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingTop: '2px',
            paddingBottom: '2px',
            backgroundColor: '#EAB464',
            color: '#fff',
            fontSize: '13px'
        },
        select: {
            '& .MuiInputBase-root': {
                '&.MuiOutlinedInput-root': {
                    color: '#7A7A7A !important'
                }
            }
        }
    })
);

export const EditSetting: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const marketplace = useMarketplace();
    const hasPremiumPlan = useHasPremiumPlan();

    const settings = marketplace.notifications.settings;

    const emailSetting = settings.find(
        setting => setting.option.id == `${props.optionId}_email`
    );
    const smsSetting = settings.find(
        setting => setting.option.id == `${props.optionId}_sms`
    );
    const noticeSetting = settings.find(
        setting => setting.option.id == `${props.optionId}_notice`
    );

    const [emailChecked, setEmailChecked] = useState(
        emailSetting ? emailSetting.active : false
    );
    const [smsChecked, setSmsChecked] = useState(
        smsSetting && hasPremiumPlan ? smsSetting.active : false
    );
    const [noticeSelected, setNoticeSelected] = useState<Option<string>>(
        noticeSetting && noticeSetting.reminderTime
            ? {
                  id: noticeSetting.reminderTime.id,
                  name:
                      Number(noticeSetting.reminderTime.id) < 24
                          ? `${Number(
                                noticeSetting.reminderTime.id
                            )} hrs before`
                          : Number(noticeSetting.reminderTime.id) == 24
                          ? `${
                                Number(noticeSetting.reminderTime.id) / 24
                            } day before`
                          : `${
                                Number(noticeSetting.reminderTime.id) / 24
                            } days before`
              }
            : props.defaultOption ?? { id: '24', name: '1 day before' }
    );

    const noticeOptions = props.noticeOptions;

    useNonInitialEffect(() => {
        props.onEdit && props.onEdit(emailChecked, smsChecked, noticeSelected);
    }, [emailChecked, smsChecked, noticeSelected]);

    const handleSelect = (option: Option<string | number>) => {
        const optionAux = { id: String(option.id), name: option.name };
        setNoticeSelected(optionAux);
    };

    return (
        <>
            {!props.loading ? (
                <Box className={classes.editContainer}>
                    <Box className={classes.editHeader}>
                        <Box>
                            <Box className={classes.editIconContainer}>
                                <FontAwesomeIcon
                                    className={classes.editIcon}
                                    icon={props.headerIcon}
                                ></FontAwesomeIcon>
                            </Box>
                        </Box>
                        <Box>
                            <Typography className={classes.editHeaderTitle}>
                                {props.title}
                            </Typography>{' '}
                            <Typography className={classes.editHeaderSubTitle}>
                                {props.subtitle}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.editSwitchContainer}>
                        <Box className={classes.firstColumn}>
                            <Box className={classes.editSwitchIconContainer}>
                                <FontAwesomeIcon
                                    className={classes.editSwitchIcon}
                                    icon={faEnvelope}
                                ></FontAwesomeIcon>
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Typography className={classes.editSwitchTitle}>
                                Email
                            </Typography>
                            <Typography className={classes.editSwitchSubTitle}>
                                Send this notification via email.
                            </Typography>
                        </Box>

                        <Box className={classes.lastColumn}>
                            <Switch
                                checked={emailChecked}
                                onCheck={() => setEmailChecked(!emailChecked)}
                                name="email"
                            />
                        </Box>
                    </Box>
                    <Box
                        className={
                            hasPremiumPlan
                                ? classes.editSwitchContainer
                                : clsx(
                                      classes.editSwitchContainer,
                                      classes.editSwitchContainerDisabled
                                  )
                        }
                    >
                        <Box className={classes.firstColumn}>
                            <Box className={classes.editSwitchIconContainer}>
                                <FontAwesomeIcon
                                    className={
                                        hasPremiumPlan
                                            ? classes.editSwitchIcon
                                            : clsx(
                                                  classes.editSwitchIcon,
                                                  classes.editSwitchDisabled
                                              )
                                    }
                                    icon={faSms}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.column}>
                            <Typography
                                className={
                                    hasPremiumPlan
                                        ? classes.editSwitchTitle
                                        : clsx(
                                              classes.editSwitchTitle,
                                              classes.editSwitchDisabled
                                          )
                                }
                            >
                                SMS{' '}
                                {!hasPremiumPlan && (
                                    <Chip
                                        className={classes.premiumChip}
                                        label="Premium"
                                    ></Chip>
                                )}
                            </Typography>{' '}
                            <Typography className={classes.editSwitchSubTitle}>
                                Send this notification via text message.
                            </Typography>
                        </Box>
                        <Box className={classes.lastColumn}>
                            <Switch
                                disabled={!hasPremiumPlan}
                                checked={smsChecked}
                                onCheck={() => setSmsChecked(!smsChecked)}
                                name="sms"
                            />
                        </Box>
                    </Box>
                    <Box className={classes.editSelectContainer}>
                        <Box>
                            <Typography className={classes.selectPlaceHolder}>
                                When to send
                            </Typography>
                        </Box>
                        <SelectField
                            className={classes.select}
                            options={noticeOptions}
                            onSelect={handleSelect}
                            selectedOption={noticeSelected}
                        />
                    </Box>
                </Box>
            ) : (
                <Spinner />
            )}
        </>
    );
};

export default EditSetting;
