import { Agreement, AgreementSummary, Signature } from "model/Agreements";

export interface AgreementsState {
  agreements: Array<Agreement>;
  savedAgreement?: Agreement;
  status: AgreementsStatus;
  signaturesByClient: Array<Signature>;
  signaturesByAgreement: Array<Signature>;
  signatureByHash?: Signature;
  summary?: AgreementSummary;
}

export enum AgreementsStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Saving,
  SaveSuccess,
  Sending,
  SendSuccess,
  Resending,
  ResendSuccess,
  SendingSignaturePdf,
  SendSignaturePdfSuccess,
  Deleting,
  DeleteSuccess,
  DeletingSignature,
  DeleteSignatureSuccess,
  Signing,
  SignSuccess,
  FetchingSignaturesByClient,
  FetchSignaturesByClientSuccess,
  FetchingSignaturesByAgreement,
  FetchSignaturesByAgreementSuccess,
  DownloadingPdf,
  DownloadPdfSuccess,
  DownloadingSignaturePdf,
  DownloadSignaturePdfSuccess,
  Printing,
  PrintSuccess,
  PrintingSignature,
  PrintSignatureSuccess,
  GettingSummary,
  GetSummarySuccess,
  GettingSignatureByHash,
  GetSignatureByHashSuccess,
  Error,
}
