import React, { FunctionComponent, useState } from "react";
import CustomInput , { CustomInputProps }from "./CustomInput";
import { makeStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  toggle: {
    top: 0,
    right: 20,
    bottom: 0,
    fontSize: 13,
    cursor: "pointer",
    lineHeight: "54px",
    textDecoration: "underline",
    position: "absolute",
  },
}));

export const CustomPasswordInput: FunctionComponent<CustomInputProps> = (props) => {
  const classes = useStyles();
  const [type, setType] = useState<"password"|"text">("password");

  const changeType = () => {
    setType(type === "password" ? "text" : "password");
  }

  return (
    <div className={classes.root}>
      <CustomInput type={type} {...props} onEnterPressed={props.onEnterPressed}/>
      <span className={classes.toggle} onClick={changeType}>
        {type === "text" ? "Hide" : "Show"}
      </span>
    </div>
  );
};

export default CustomPasswordInput;
