import { FunctionComponent } from 'react';
import {
    Modal,
    makeStyles,
    Theme,
    createStyles,
    Grid
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { OnboardingStepsState } from 'reducers/onboardingSettings/onboardingStepsState';
import { onboardingHideVideo } from 'actions/onboardingSteps/OnboardingStepsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { height, width } from '@fortawesome/pro-light-svg-icons/faTimes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        videoContainer: {
            width: '720px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '15px'
            }
        },
        closeButton: {
            cursor: 'pointer',
            position: 'relative',
            left: '20px',
            bottom: '10px',
            fontSize: '22px',
            [theme.breakpoints.down('sm')]: {
                left: '0px'
            }
        },
        video: {
            borderRadius: '10px',
            height: '480px',
            [theme.breakpoints.down('sm')]: {
                height: '360px'
            }
        }
    })
);

export const OnboardingVideoModal: FunctionComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { showVideo, videoURL } = useSelector<
        RootState,
        OnboardingStepsState
    >(state => state.onboardingSteps);
    const handleHideVideo = () => dispatch(onboardingHideVideo());
    return (
        <Modal
            open={showVideo}
            onClose={handleHideVideo}
            className={classes.modal}
        >
            <Grid container className={classes.videoContainer}>
                <Grid container justifyContent="flex-end">
                    <FontAwesomeIcon
                        icon={faTimes}
                        className={classes.closeButton}
                        onClick={handleHideVideo}
                    />
                </Grid>
                <Grid container justifyContent="center">
                    <iframe
                        src={videoURL}
                        frameBorder="0"
                        width="720"
                        height="480"
                        allowFullScreen
                        className={classes.video}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default OnboardingVideoModal;
