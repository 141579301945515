import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import PageSize from './PageSize';
import PageSizeTransparent from './PageSizeTransparent';
import { Option } from '@spike/model';
import clsx from 'clsx';

interface Props {
	count?: number;
	page: number;
	pageSize?: number;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
	disableAllPageSize?: boolean;
	className?: string;
	hasNextPage?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%',
			justifyContent: 'flex-end',
			alignItems: 'center',
			backgroundColor: 'white',
			[theme.breakpoints.down(768)]: {
				padding: '19px 20px',
				justifyContent: 'space-between'
			},
			[theme.breakpoints.between(768, 'sm')]: {
				paddingTop: '3px',
				paddingBottom: '3px'
			},
			[theme.breakpoints.only('md')]: {
				paddingTop: '5px',
				paddingBottom: '5px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingTop: '7px',
				paddingBottom: '7px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingTop: '10px',
				paddingBottom: '10px'
			}
		},
		pageNumbersContainer: {
			display: 'flex',
			color: '#A3A3A3',
			[theme.breakpoints.down(768)]: {
				marginLeft: 'auto'
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '9px'
			},
			[theme.breakpoints.only('md')]: {
				paddingLeft: '14px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingLeft: '18px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingLeft: '28px'
			}
		},
		arrowsContainer: {
			display: 'flex',
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '9px'
			},
			[theme.breakpoints.only('md')]: {
				paddingLeft: '14px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingLeft: '18px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingLeft: '28px'
			}
		},
		arrow: {
			'display': 'flex',
			'justifyContent': 'center',
			'alignItems': 'center',
			'cursor': 'pointer',
			'width': '25px',
			'height': '25px',
			'border': '2px solid transparent',
			'borderRadius': '100px',
			'color': '#A3A3A3',
			'&:hover': {
				borderColor: '#A3A3A3'
			}
		},
		rightArrow: {
			[theme.breakpoints.down('sm')]: {
				marginLeft: '5px'
			},
			[theme.breakpoints.only('md')]: {
				marginLeft: '8px'
			},
			[theme.breakpoints.only('lg')]: {
				marginLeft: '10px'
			},
			[theme.breakpoints.only('xl')]: {
				marginLeft: '15px'
			}
		}
	})
);

const page10Size: Option<number> = { id: 10, name: 'View 10' };
const page20Size: Option<number> = { id: 20, name: 'View 20' };
const page50Size: Option<number> = { id: 50, name: 'View 50' };
const pageAllSize: Option<number> = { id: 0, name: 'View all' };

const pageSizeOptions: Array<Option<number>> = [
	page10Size,
	page20Size,
	page50Size
	//pageAllSize,
];
export const Pagination: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const pageSizeChangeHandler = (pageSize: number) => {
		props.onPageSizeChange && props.onPageSizeChange(pageSize);
	};

	const increasePageHandler = () => {
		if (
			(props.count &&
				props.pageSize &&
				props.page < Math.ceil(props.count / props.pageSize)) ||
			props.hasNextPage
		) {
			props.onPageChange && props.onPageChange(props.page + 1);
		}
	};

	const decreasePageHandler = () => {
		if (props.page > 1) {
			props.onPageChange && props.onPageChange(props.page - 1);
		}
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			{props.pageSize && (
				<PageSizeTransparent
					pageSizeSelected={
						pageSizeOptions.find(
							option => option.id === props.pageSize
						) || page10Size
					}
					pageSizes={
						props.disableAllPageSize
							? pageSizeOptions.filter(o => o.id !== 0)
							: pageSizeOptions
					}
					onChange={pageSizeChangeHandler}
				/>
			)}

			{props.count && props.pageSize && (
				<Box className={classes.pageNumbersContainer}>
					<Typography>
						{props.page} of{' '}
						{props.pageSize === 0
							? 1
							: Math.ceil(props.count / props.pageSize)}
					</Typography>
				</Box>
			)}
			<Box className={classes.arrowsContainer}>
				{props.page > 1 && (
					<Box
						className={classes.arrow}
						onClick={decreasePageHandler}
					>
						<FontAwesomeIcon icon={faArrowLeft} />
					</Box>
				)}
				{((props.count &&
					props.pageSize &&
					props.page < Math.ceil(props.count / props.pageSize)) ||
					props.hasNextPage) && (
					<Box
						className={clsx(classes.arrow, classes.rightArrow)}
						onClick={increasePageHandler}
					>
						<FontAwesomeIcon icon={faArrowRight} />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Pagination;
