import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import SidebarBody from "./SidebarBody";
import HelpCard from "./HelpCard";
import SidebarHeader from "./SidebarHeader";
import { Step as StepModel } from "./SidebarModel";

interface SidebarProps {
  steps: Array<StepModel>;
  percentage?: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      backgroundColor: "#F4F3F0",
      borderRight: "1px solid #DDDDDD",
    },
    headerBodyContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(59)}px ${reduceResolution(50)}px ${reduceResolution(20)}px ${reduceResolution(
          50
        )}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "59px 50px 20px 50px",
      },
    },
    footer: {
      position: "absolute",
      bottom: 0,
      display: "flex",
      width: "100%",
      backgroundColor: "white",
      borderTop: "1px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(26)}px ${reduceResolution(50)}px ${reduceResolution(19)}px ${reduceResolution(
          50
        )}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "26px 50px 19px 50px",
      },
    },
  })
);

export const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.headerBodyContainer}>
        {props.percentage !== undefined && <SidebarHeader percentage={props.percentage} />}
        <SidebarBody steps={props.steps} />
      </Box>
      <Box className={classes.footer}>
        <HelpCard />
      </Box>
    </Box>
  );
};

export default Sidebar;
