import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from "clsx";

export interface Props {
  open: boolean;
  onClose: () => void;
  message: JSX.Element;
  icon?: IconProp;
  className?: string;
  iconColor?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("lg")]: {
          width: "355px",
          minHeight: "80px",
          borderRadius: "15px",
          paddingTop: "18px",
          paddingBottom: "18px",
        },
        [theme.breakpoints.up("xl")]: {
          width: "474px",
          minHeight: "107px",
          borderRadius: "20px",
          paddingTop: "25px",
          paddingBottom: "25px",
        },
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
        [theme.breakpoints.down("lg")]: {
          paddingLeft: "27px",
          paddingRight: "27px",
        },
        [theme.breakpoints.up("xl")]: {
          paddingLeft: "37px",
          paddingRight: "37px",
        },
      },
    },
    dialogContent: {
      display: "flex",
      alignItems: "center",
    },
    contentContainer: {
      display: "flex",
      width: "100%",
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20%",
      color: "#5E8677",
    },
    messageContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    icon: (props: Props) => ({
      color: props.iconColor || "inherit"
    })
  })
);

export const MessageDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Dialog onClose={props.onClose} open={props.open} className={clsx(classes.dialog, props.className)}>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.contentContainer}>
          {props.icon && <Box className={classes.iconContainer}>
            <FontAwesomeIcon icon={props.icon} className={classes.icon}/>
          </Box>}
          <Box className={classes.messageContainer}>
            {props.message}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MessageDialog;
