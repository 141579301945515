import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMarketplace } from 'hooks';
import { MarketplaceTax } from '@spike/marketplace-model';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
interface SelectTaxProps {
	className?: string;
	selectedTax?: MarketplaceTax;
	onSelect?: (tax: MarketplaceTax | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			position: 'relative',
			borderRadius: '100px',
			border: '1px solid black',
			justifyContent: 'space-between',
			alignItems: 'center',
			cursor: 'pointer',

			[theme.breakpoints.down(wbp)]: {
				height: 36,
				paddingLeft: 19
			},
			[theme.breakpoints.up(wbp)]: {
				height: '46px',
				paddingLeft: '10px',
				minWidth: '100px'
			}
		},
		selectedTax: {
			width: '100%',
			fontSize: 14,
			textAlign: 'right',
			fontFamily: 'Poppins',

			[theme.breakpoints.up('md')]: {
				fontSize: 15
			}
		},
		icon: {
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(18)}px`,
				margin: `0px ${reduceResolution(10)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				margin: '0px 10px'
			}
		},
		list: {
			top: 44,
			left: 0,
			zIndex: 20,
			display: 'flex',
			overflowY: 'auto',
			position: 'absolute',
			flexDirection: 'column',
			border: '1px solid black',
			backgroundColor: 'white',
			padding: '8px 0px',
			borderRadius: 14,
			cursor: 'default',
			maxWidth: 160,

			[theme.breakpoints.down('sm')]: {
				right: 0,
				left: 'auto'
			},
			[theme.breakpoints.up('md')]: {
				top: '55px',
				maxWidth: 200,
				borderRadius: '14px',
				padding: '10px 0px'
			}
		},
		taxContainer: {
			'cursor': 'pointer',
			'padding': '4px 10px',

			'&:hover': {
				background: '#F5F5F5'
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '0px 13px'
			}
		},
		tax: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			color: '#222222',
			overflow: 'hidden',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis'
		}
	})
);

export const SelectTax: FunctionComponent<SelectTaxProps> = props => {
	const classes = useStyles();

	const marketplaceTaxes = [...useMarketplace().taxes];

	const taxes =
		props.selectedTax === undefined
			? marketplaceTaxes
			: [
					{ id: 0, uuid: '0', name: 'No Tax' },
					...marketplaceTaxes.filter(
						tax =>
							!props.selectedTax ||
							tax.id !== props.selectedTax.id ||
							tax.percentage !== props.selectedTax.percentage ||
							tax.name !== props.selectedTax.name
					)
			  ];

	const [expanded, setExpanded] = useState(false);

	const list = (
		<ClickAwayListener onClickAway={() => setExpanded(false)}>
			<Box className={classes.list}>
				{taxes.map(tax => (
					<Box
						key={tax.uuid}
						className={classes.taxContainer}
						onClick={() =>
							props.onSelect &&
							(tax.id === 0
								? props.onSelect(undefined)
								: props.onSelect(tax))
						}
					>
						<Typography className={classes.tax}>
							{tax.id === 0
								? 'No Tax'
								: `${tax.name} ${tax.percentage}%`}
						</Typography>
					</Box>
				))}
			</Box>
		</ClickAwayListener>
	);

	return (
		<Box
			className={clsx(classes.container, props.className)}
			onClick={() => setExpanded(prev => !prev)}
		>
			<Typography className={classes.selectedTax}>
				{props.selectedTax
					? `${props.selectedTax.percentage}%`
					: 'No Tax'}
			</Typography>
			<FontAwesomeIcon
				icon={expanded ? faChevronUp : faChevronDown}
				className={classes.icon}
			/>
			{expanded && list}
		</Box>
	);
};

export default SelectTax;
