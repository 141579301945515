export interface SelectionBarOption {
    id: string;
    name: string;
    mark?: Mark;
    count?: number;
    selectedBackgroundColor?: string;
}

export enum Mark {
    WARNING = 'warning',
    ERROR = 'error'
}
