import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import SummaryCard from '../../UI/SummaryCard';

interface SummaryProps {
	loading?: boolean;
	totalTips?: number | string;
	totalAppointments?: number | string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			gap: 16,
			marginTop: 18,
			justifyContent: 'flex-start',

			[theme.breakpoints.up('md')]: {
				marginTop: 32
			}
		},
		border: {
			borderRadius: 14,
			border: '1px solid #D4D4D4'
		}
	})
);

export const Summary: FunctionComponent<SummaryProps> = props => {
	const classes = useStyles();

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12} md={2}>
				<SummaryCard
					title="Total Appointments"
					value={props.totalAppointments || 0}
					loading={props.loading}
					color="white"
					className={classes.border}
				/>
			</Grid>
			<Grid item xs={12} md={2}>
				<SummaryCard
					title="Total Tips"
					value={`$${props.totalTips || 0}`}
					loading={props.loading}
					color="white"
					className={classes.border}
				/>
			</Grid>
		</Grid>
	);
};

export default Summary;
