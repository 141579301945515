import Client from "@spike/client-model";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import useStyles from "./sendModal.styles";
import { Box, Typography } from "@material-ui/core";

interface RadioOptionProps {
  label: string;
  value: string;
  icon: IconProp;
  clientInfo?: Client;
  setChannel: (value: string) => void;
  channel: string;
}

export default function RadioOption({
  label,
  icon,
  value,
  clientInfo,
  setChannel,
  channel,
}: RadioOptionProps) {
  const classes = useStyles();
  const isDisabled =
    value === "email"
      ? clientInfo && !clientInfo?.email
      : clientInfo && !clientInfo?.phone;

  return (
    <Box
      onClick={() => setChannel(value)}
      className={clsx(
        classes.radioOption,
        { active: channel === value },
        { disabled: isDisabled }
      )}
    >
      <Box
        className={clsx(classes.radioOptionInput, {
          active: channel === value,
        })}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Box>
      <FontAwesomeIcon icon={icon} />
      <Typography>{label}</Typography>
    </Box>
  );
}
