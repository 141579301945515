import { FunctionComponent } from 'react';
import { ReportItem, ReportItemType } from '../SalesReportsModel';
import NormalItem from './NormalItem';
import StrongItem from './StrongItem';
import SubItem from './SubItem';
import TotalItem from './TotalItem';

interface ItemProps {
	item: ReportItem;
	topLine?: boolean;
	bottomLine?: boolean;
	loading?: boolean;
	className?: string;
}

export const Item: FunctionComponent<ItemProps> = props => {
	switch (props.item.type) {
		case ReportItemType.STRONG:
			return (
				<StrongItem
					label={props.item.label}
					value={props.item.value}
					bottomLine={props.bottomLine}
					loading={props.loading}
					className={props.className}
				/>
			);
		case ReportItemType.NORMAL:
			return (
				<NormalItem
					label={props.item.label}
					value={props.item.value}
					topLine={props.topLine}
					bottomLine={props.bottomLine}
					loading={props.loading}
					className={props.className}
				/>
			);
		case ReportItemType.SUBITEM:
			return (
				<SubItem
					label={props.item.label}
					value={props.item.value}
					bottomLine={props.bottomLine}
					loading={props.loading}
					className={props.className}
				/>
			);
		case ReportItemType.TOTAL:
			return (
				<TotalItem
					label={props.item.label}
					value={props.item.value}
					loading={props.loading}
					className={props.className}
				/>
			);
		default:
			return null;
	}
};

export default Item;
