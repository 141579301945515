import {
    faAngry,
    faBabyCarriage,
    faBone,
    faBrain,
    faEar,
    faFrown,
    faHeart,
    faKidneys,
    faLungs,
    faPaw,
    faScalpel,
    faSmile,
    faVial,
    faViruses,
    faHeat,
    faVirus,
    faScalpelLineDashed
} from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { useMasterData } from 'hooks';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Category from './Category';
import clsx from 'clsx';

interface CategoriesProps {
    gender?: Option<string>;
    selectedCategoryIds: Array<number>;
    onChange?: (selectedCategoryIds: Array<number>) => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column'
            }
        },
        cell: {
            display: 'flex',
            justofyContent: 'center',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '20px'
            },
            [theme.breakpoints.down('md')]: {
                'justifyContent': 'flex-start',
                'maxWidth': '100%',
                'width': '100%',
                'paddingTop': '12px',
                'paddingBottom': '12px',
                'paddingLeft': '0px',
                'borderBottom': '1px solid #D4D4D4',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }
        }
    })
);

const icons = new Map([
    ['faHeart', faHeart],
    ['faLungs', faLungs],
    ['faKidneys', faKidneys],
    ['faBone', faBone],
    ['faViruses', faVirus],
    ['faVial', faVial],
    ['faBrain', faBrain],
    ['faBabyCarriage', faBabyCarriage],
    ['faEar', faEar],
    ['faScalpel', faScalpelLineDashed],
    ['faFrown', faFrown],
    ['faAngry', faAngry],
    ['faPaw', faHeat],
    ['faSmile', faSmile]
]);

const PregnancyCaregoryId = 8;
const NoneCaregoryId = 11;

const FemaleGenderId = 'female';

export const Categories: FunctionComponent<CategoriesProps> = props => {
    const classes = useStyles();

    const masterData = useMasterData();
    const masterDataCategories =
        masterData.medicalHistory.medicalConditionCategories;

    const isFemale = props.gender?.id === FemaleGenderId;

    const changeHandler = (categoryId: number, selected: boolean) => {
        const selectedIds = props.selectedCategoryIds.filter(
            id => id !== categoryId
        );
        props.onChange &&
            props.onChange(
                selected ? [...selectedIds, categoryId] : selectedIds
            );
    };

    return (
        <Grid
            xs={12}
            container
            className={clsx(props.className, classes.container)}
        >
            {masterDataCategories
                .filter(
                    category =>
                        category.id !== NoneCaregoryId &&
                        (isFemale || category.id !== PregnancyCaregoryId)
                )
                .map(category => (
                    <Grid
                        item
                        key={category.id}
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        className={classes.cell}
                    >
                        <Category
                            id={category.id}
                            name={category.name}
                            icon={icons.get(category.icon || '')}
                            selected={props.selectedCategoryIds.includes(
                                category.id
                            )}
                            onChange={selected =>
                                changeHandler(category.id, selected)
                            }
                        />
                    </Grid>
                ))}
        </Grid>
    );
};

export default Categories;
