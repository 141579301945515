import { UserNotification } from "model/UserNotifications";

export interface UserNotificationsState {
    read: Array<UserNotification>;
    unread: Array<UserNotification>;
    status: UserNotificationsStatus;
}


export enum UserNotificationsStatus {
    Initial,
    Cleared,
    Fetching,
    FetchSuccess,
    Adding,
    AddSuccess,
    Updating,
    UpdateSuccess,
    Error
}