import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getBookingThunk } from '@spike/bookings-action';
import { fetchServicesThunk, saveServiceThunk } from 'actions/services/ServicesActions';
import { getStaffThunk } from 'actions/staff/StaffActions';
import BookingComponent from 'components/Bookings';
import Member from 'components/Member';
import ServiceComponent from 'components/Service';
import OverFullWindow from 'components/UI/OverFullWindow';
import { Appointment } from '@spike/appointment-model';
import Booking from '@spike/booking-model';
import Service from 'model/Service';
import { Staff as StaffModel } from 'model/Staff';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BookingsState, BookingsStatus } from '@spike/bookings-action';
import { ServicesState, ServicesStatus } from 'reducers/services/ServicesState';
import { StaffState, StaffStatus } from 'reducers/staff/StaffState';
import { RootState } from 'store';
import { useApiClientWrapper } from 'hooks';

interface Props {
    query: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            background: 'white'
        }
    })
);

const GlobalResult: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const query = props.query.split('&');
    const servicesState = useSelector<RootState, ServicesState>(state => state.services);

    const [booking, setBooking] = useState<Booking | undefined>();
    const [appointment, setAppointment] = useState<Array<Appointment | undefined>>();
    const [appointmentId, setAppointmentId] = useState<number>();
    const [member, setMember] = useState<StaffModel | undefined>(undefined);
    const [idParam, setIdParam] = useState<string>();
    const [serviceToEdit, setServiceToEdit] = useState<Service | undefined>(undefined);

    const [showAppointment, setShowAppointment] = useState(false);
    const [showBooking, setShowBooking] = useState(false);
    const [showStaff, setShowStaff] = useState(false);
    const [showService, setShowService] = useState(false);

    const staffs = useSelector<RootState, StaffState>(state => state.staff);

    const bookingsStatus = useSelector<RootState, BookingsState>(state => state.bookings);

    useEffect(() => {
        setShowAppointment(false);
        setShowStaff(false);
        setShowBooking(false);
        setShowService(false);

        setIdParam(query[1]);

        const routeSearch = (type: string) => {
            switch (type) {
                case 'staff':
                    dispatch(getStaffThunk(parseInt(query[1])));
                    break;
                case 'booking':
                    dispatch(getBookingThunk(apiClientWrapper, parseInt(query[1])));
                    setAppointmentId(parseInt(query[3]));
                    break;
                case 'service':
                    dispatch(fetchServicesThunk());
                    break;
                default:
                    break;
            }
        };

        routeSearch(query[0]);
    }, [props.query]);

    useEffect(() => {
        if (staffs.status === StaffStatus.GetSuccess) {
            if (query[0] === 'staff') {
                setMember(staffs.member);
                setShowStaff(true);
            }
        }
    }, [staffs]);

    useEffect(() => {
        if (servicesState.status === ServicesStatus.FetchSuccess) {
            if (query[0] === 'service') {
                let service = [];
                service = servicesState.services.filter(service => {
                    return service.id === parseInt(query[1]);
                });
                setServiceToEdit(service[0]);
                setShowService(true);
            }
        }
    }, [servicesState, query]);

    useEffect(() => {
        if (bookingsStatus.status === BookingsStatus.ConfirmSuccess && query[0] === 'booking') {
            if (bookingsStatus.booking?.appointments.length === 1) {
                setAppointment(
                    bookingsStatus.booking?.appointments.filter(appointment => appointment.id === appointmentId)
                );
                setShowAppointment(true);
            } else {
                setBooking(bookingsStatus.booking);
                setShowBooking(true);
            }
        }
    }, [bookingsStatus, props.query]);

    const backStaffHandler = () => {
        setShowStaff(false);
        history.push('/staff');
    };

    const staffView = (
        <OverFullWindow>
            <Member member={member} onBack={backStaffHandler} />
        </OverFullWindow>
    );
    const saveHandler = (service: Service, changedName?: boolean) => {
        dispatch(saveServiceThunk(service, changedName));
    };

    const backServiceHandler = () => {
        setShowService(false);
        history.push('/services');
    };

    const bookingView = <BookingComponent booking={booking} />;

    const appointmentView = <BookingComponent appointment={appointment && appointment[0]} />;

    const serviceView = (
        <ServiceComponent
            services={servicesState.services}
            onBack={backServiceHandler}
            onSave={saveHandler}
            service={serviceToEdit}
            addStaff={false}
        />
    );

    return (
        <Box className={classes.container}>
            {showStaff && staffView}
            {showBooking && bookingView}
            {showAppointment && appointmentView}
            {showService && serviceView}
        </Box>
    );
};

export default GlobalResult;
