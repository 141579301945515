import React, { FunctionComponent, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DateRangePicker } from 'react-date-range';
import { SelectableOption } from 'model';
import { Period } from '@spike/model';
import { AppointmentDuration } from '@spike/appointment-model';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Box, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from 'components/UI';
import moment from 'moment-timezone';
import { useTimeZone } from 'hooks';

export interface Props {
    appointmentLimits?: Period;
    calendarOptions: Array<SelectableOption<string>>;
    selectedRange: AppointmentDuration;
    optionSelected?: string;
    dropdownAlignment?: 'left' | 'right';
    onSelectOption: (
        opSelect: string,
        dateSelected?: AppointmentDuration
    ) => void;
    onSelectRange: (rangeSelected: AppointmentDuration) => void;
    onChange: (dateSelected: AppointmentDuration, isVisible: boolean) => void;
    onApplyChanges: (
        dateSelected: AppointmentDuration,
        isVisible: boolean
    ) => void;
    className?: string;
    id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: (props: Props) => ({
            height: 'auto',
            paddingBottom: 24,
            backgroundColor: '#fff',
            border: '1px solid black',
            right: props.dropdownAlignment !== 'left' ? 0 : undefined,
            left: props.dropdownAlignment === 'left' ? 0 : undefined,

            [theme.breakpoints.down(768)]: {
                fontSize: 16,
                overflowY: 'auto',
                paddingTop: 24
            },
            [theme.breakpoints.between(768, 'lg')]: {
                width: '270px',
                fontSize: '12px',
                borderRadius: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '350px',
                fontSize: '16px',
                borderRadius: '15px'
            }
        }),
        datePickerContainer: {
            padding: '0px 16px'
        },
        datePickerLabel: {
            color: '#000',
            fontSize: 13,
            lineHeight: 1,
            marginBottom: 8,
            textTransform: 'uppercase'
        },
        calendar: {
            'width': '100%',
            'display': 'flex',
            'flexDirection': 'column',

            '& .rdrCalendarWrapper': {
                [theme.breakpoints.down(768)]: {
                    fontSize: 15
                }
            },

            '& .rdrDefinedRangesWrapper': {
                display: 'none'
            },

            '& .rdrDateDisplay': {
                margin: 0,
                width: '100%'
            },

            '& .rdrDateDisplayWrapper': {
                backgroundColor: '#fff'
            },

            '& .rdrMonthAndYearWrapper': {
                height: 'auto',
                paddingTop: 24,
                paddingBottom: 16
            },

            '& .rdrNextPrevButton': {
                margin: 0
            },

            '& .rdrYearPicker, .rdrMonthPicker': {
                '&:hover': {
                    backgroundColor: '#fff'
                }
            },

            '& .rdrDateInput': {
                'borderRadius': 9,
                'boxShadow': 'none',
                'border': 'solid 1px #D4D4D4',

                '& input': {
                    height: 'auto',
                    color: '#000',
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    padding: '13px 16px'
                }
            },

            '& .rdrDateDisplayItemActive': {
                borderColor: '#000 !important'
            },

            '& .rdrMonth': {
                padding: 0,
                width: '100%'
            },

            '& .rdrWeekDay': {
                fontSize: 14,
                fontWeight: 600,
                color: '#5E8677 !important'
            },

            '& .rdrDayNumber span': {
                fontSize: 15,
                fontWeight: 400,
                color: '#000 !important'
            },

            '& .rdrDayPassive .rdrDayNumber': {
                display: 'none'
            }
        },
        headerCalendar: {
            'fontSize': 20,

            [theme.breakpoints.up(768)]: {
                display: 'none'
            },
            [theme.breakpoints.down(768)]: {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 20
            },

            '& p': {
                margin: 0,
                fontWeight: 600,
                lineHeight: 1.5
            }
        },
        headerClose: {
            'width': 20,
            'height': 20,
            'marginLeft': 'auto',

            '& span': {
                'width': 20,
                'height': 2,
                'display': 'block',
                'position': 'relative',
                'backgroundColor': '#000',

                '&:first-child': {
                    top: 9,
                    transform: 'rotate(45deg)'
                },
                '&:last-child': {
                    top: 7,
                    transform: 'rotate(-45deg)'
                }
            }
        },
        actionsCalendar: {
            gap: 8,
            zIndex: 1,
            display: 'flex',
            marginBottom: 32,
            alignItems: 'start',
            flexDirection: 'column',
            padding: '0px 16px 24px',
            borderBottom: '1px solid #D3D3D3',

            [theme.breakpoints.up('md')]: {
                paddingTop: 16
            }
        },
        calendarOptions: {
            width: '100%',
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            borderRadius: 8,
            cursor: 'pointer',
            padding: '11px 13px'
        },
        calendarOptionsSelected: {
            color: '#ffffff',
            backgroundColor: '#000000'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '0px 16px',
            marginTop: 16
        },
        button: {
            'height': 55,
            'width': '100%',
            'borderRadius': 30,

            '& span': {
                fontSize: 18,
                fontWeight: 600
            }
        }
    })
);

export const CalendarDropdown: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const timeZone = useTimeZone();

    const [optionSelected, setOptionSelected] = useState(props.optionSelected);

    const handleSelect = (ranges: any): void => {
        const selectedRangeAux = {
            from: moment(ranges.range1.startDate),
            to: moment(ranges.range1.endDate)
        };
        selectedRangeCalendar.startDate = selectedRangeAux.from;
        selectedRangeCalendar.endDate = selectedRangeAux.to;
        props.onSelectRange(selectedRangeAux);
    };

    const selectedRangeCalendar = {
        startDate: props.selectedRange.from,
        endDate: props.selectedRange.to
    };

    function createDate(optionFilter: string) {
        let dateFromAux = moment().tz(timeZone);
        let dateToAux = moment().tz(timeZone);

        if (optionFilter === 'Tomorrow') {
            dateFromAux = moment().tz(timeZone).add(1, 'day');
            dateToAux = moment().tz(timeZone).add(1, 'day');
        } else if (optionFilter === 'Next 7 days') {
            dateFromAux = moment().tz(timeZone).add(1, 'day');
            dateToAux = moment().tz(timeZone).add(8, 'day');
        } else if (optionFilter === 'Last 30 days') {
            dateFromAux = moment().tz(timeZone).subtract(29, 'day');
        } else if (optionFilter === 'Last month') {
            dateFromAux = moment()
                .tz(timeZone)
                .subtract(1, 'months')
                .startOf('month');
            dateToAux = moment()
                .tz(timeZone)
                .subtract(1, 'months')
                .endOf('month');
        } else if (optionFilter === 'All time') {
            dateFromAux = moment(props.appointmentLimits?.from).tz(timeZone);
            dateToAux = moment(props.appointmentLimits?.to).tz(timeZone);
        } else if (optionFilter === 'Upcoming') {
            dateFromAux = moment().tz(timeZone);
            dateToAux = moment(props.appointmentLimits?.to).tz(timeZone);
        } else if (optionFilter === 'This month') {
            dateFromAux = moment().tz(timeZone).startOf('month');
            dateToAux = moment().tz(timeZone).endOf('month');
        }

        return { from: dateFromAux, to: dateToAux };
    }

    const handleSelectOption = (optionName: string): void => {
        let selectedRangeAux;

        if (optionName === 'Today') {
            selectedRangeAux = createDate('Today');
        } else if (optionName === 'Tomorrow') {
            selectedRangeAux = createDate('Tomorrow');
        } else if (optionName === 'Next 7 days') {
            selectedRangeAux = createDate('Next 7 days');
        } else if (optionName === 'Last 30 days') {
            selectedRangeAux = createDate('Last 30 days');
        } else if (optionName === 'Last month') {
            selectedRangeAux = createDate('Last month');
        } else if (optionName === 'This month') {
            selectedRangeAux = createDate('This month');
        } else if (optionName === 'Upcoming') {
            selectedRangeAux = createDate('Upcoming');
        } else {
            selectedRangeAux = createDate('All time');
        }

        props.onChange(selectedRangeAux, false);
        props.onSelectOption(optionName, selectedRangeAux);
        setOptionSelected(optionName);
    };

    const handleApplyDate = (): void => {
        props.onApplyChanges(props.selectedRange, false);
        props.onSelectOption('');
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.headerCalendar}>
                <p>Choose Date</p>

                <Box className={classes.headerClose} onClick={handleApplyDate}>
                    <span></span>
                    <span></span>
                </Box>
            </Box>
            <Box className={classes.actionsCalendar}>
                {props.calendarOptions.map((option, index) => (
                    <Box
                        id={`${props.id || ''}_${index}`}
                        key={index}
                        className={clsx(classes.calendarOptions, {
                            [classes.calendarOptionsSelected]:
                                option.element.name === props.optionSelected ||
                                option.selected
                        })}
                        onClick={e => handleSelectOption(option.element.name)}
                    >
                        {option.element.name}
                    </Box>
                ))}
            </Box>

            <Box
                id={`${props.id || ''}_picker`}
                className={classes.datePickerContainer}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography className={classes.datePickerLabel}>
                            From
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.datePickerLabel}>
                            &nbsp; To
                        </Typography>
                    </Grid>
                </Grid>
                <DateRangePicker
                    rangeColors={['#E9F0ED']}
                    showMonthAndYearPickers={false}
                    ranges={[
                        {
                            startDate: selectedRangeCalendar.startDate
                                .clone()
                                .toDate(),
                            endDate: selectedRangeCalendar.endDate
                                .clone()
                                .toDate()
                        }
                    ]}
                    onChange={handleSelect}
                    className={classes.calendar}
                    editableDateInputs={false}
                    weekdayDisplayFormat="EEEEE"
                />
            </Box>
            <Box className={classes.buttonContainer}>
                <Button
                    label="Apply"
                    onClick={handleApplyDate}
                    color="green"
                    className={classes.button}
                    id={`${props.id || ''}_apply_button`}
                />
            </Box>
        </Box>
    );
};

export default CalendarDropdown;
