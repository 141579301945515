import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { StaffHeaderList } from './StaffHeaderList';
import { StaffRowList } from './StaffRowList';
import Staff, { SortFields, SortOrder } from 'model/Staff';
import { useMarketplace } from 'hooks';
import { getStatusFromThisWeekOn } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import Pagination from 'components/UI/Pagination';

interface Props {
    staff: Array<Staff>;
    sortedStaff: (sortedStaff: Array<Staff>) => void;
    editStaff: (staffId: number) => void;
    deleteStaff: (staffDeleted: Staff) => void;
    activate: (arrStaff: Staff) => void;
    resendInvitation: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            backgroundColor: 'white'
        },
        boxGridContainer: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                paddingBottom: '20px'
            }
        },
        pagination: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '30px'
            }
        }
    })
);

export const StaffList: FunctionComponent<Props> = ({
    staff,
    editStaff,
    deleteStaff,
    sortedStaff,
    activate,
    resendInvitation
}) => {
    const classes = useStyles();
    const marketplaceId = useMarketplace().id!;

    const [page, setPage] = useState(1);
    const [pageStaffs, setPageStaffs] = useState<Array<Staff>>(
        staff.slice(0, 10)
    );
    const [pageSize, setPageSize] = useState(10);
    const [filteredAndSorted, setFilteredAndSorted] =
        useState<Array<Staff>>(staff);

    useEffect(() => {
        setFilteredAndSorted(staff);
        setPageStaffs(staff.slice(0, 10));
    }, [staff]);

    const handleActive = (
        staffData: Staff,
        active: boolean,
        marketplaceId: number
    ) => {
        staffData.worksAt.find(w => w.marketplaceId === marketplaceId)!.active =
            active;
        staffData.active = active;
        activate(staffData);
    };

    const onSort = (field: SortFields | undefined, order: SortOrder) => {
        if (field !== undefined) {
            const staffAuxiliar = [...staff];
            if (
                order === SortOrder.ASCENDANT ||
                order === SortOrder.DESCENDANT
            ) {
                staffAuxiliar.sort((staffAux, otherStaff) => {
                    if (field === 'firstName') {
                        if (
                            staffAux.person.firstName.toLowerCase() >
                            otherStaff.person.firstName.toLowerCase()
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            staffAux.person.firstName.toLowerCase() <
                            otherStaff.person.firstName.toLowerCase()
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else if (field === 'schedule') {
                        if (
                            getStatusFromThisWeekOn(staffAux.schedule) >
                            getStatusFromThisWeekOn(otherStaff.schedule)
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            getStatusFromThisWeekOn(staffAux.schedule) <
                            getStatusFromThisWeekOn(otherStaff.schedule)
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else if (field === 'role') {
                        if (
                            staffAux.role &&
                            otherStaff.role &&
                            staffAux.role?.name > otherStaff.role?.name
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            staffAux.role &&
                            otherStaff.role &&
                            staffAux.role?.name < otherStaff.role?.name
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else if (field === 'email') {
                        if (
                            staffAux.person.email.toLowerCase() >
                            otherStaff.person.email.toLowerCase()
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            staffAux.person.email.toLowerCase() <
                            otherStaff.person.email.toLowerCase()
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else if (field === 'phoneNumber') {
                        if (
                            staffAux.person.phoneNumber.toLowerCase() >
                            otherStaff.person.phoneNumber.toLowerCase()
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            staffAux.person.phoneNumber.toLowerCase() <
                            otherStaff.person.phoneNumber.toLowerCase()
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else if (field === 'status') {
                        if (staffAux.active < otherStaff.active) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (staffAux.active > otherStaff.active) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else if (field === 'accessLevel') {
                        if (
                            staffAux.accessLevel &&
                            otherStaff.accessLevel &&
                            staffAux.accessLevel?.name.toLowerCase() >
                                otherStaff.accessLevel?.name.toLowerCase()
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            staffAux.accessLevel &&
                            otherStaff.accessLevel &&
                            staffAux.accessLevel?.name.toLowerCase() <
                                otherStaff.accessLevel?.name.toLowerCase()
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else {
                        return 1;
                    }
                });
            } else {
                staffAuxiliar.sort((staffAux, otherStaff) => {
                    if (
                        staffAux.createdAt &&
                        otherStaff.createdAt &&
                        staffAux.createdAt < otherStaff.createdAt
                    ) {
                        return 1;
                    } else if (
                        staffAux.createdAt &&
                        otherStaff.createdAt &&
                        staffAux.createdAt > otherStaff.createdAt
                    ) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            }
            sortedStaff(staffAuxiliar);
        }
    };

    const handleEdit = (staffId: number) => {
        editStaff(staffId);
    };

    const changePageSizeHandler = (pageSize: number) => {
        setPageSize(pageSize);
        changePage(filteredAndSorted!, page, pageSize);
    };

    const changePageHandler = (page: number) => {
        setPage(page);
        changePage(filteredAndSorted!, page, pageSize);
    };

    const changePage = (
        staffs: Array<Staff>,
        page: number,
        pageSize: number
    ) => {
        if (pageSize === 0) {
            setPageStaffs(staffs);
        } else {
            const startIndex = (page - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            setPageStaffs(staffs.slice(startIndex, endIndex));
        }
    };

    const pagination = (
        <Pagination
            className={classes.pagination}
            count={filteredAndSorted ? filteredAndSorted.length : 0}
            page={page}
            pageSize={pageSize}
            onPageChange={changePageHandler}
            onPageSizeChange={changePageSizeHandler}
        />
    );

    return (
        <Grid container className={classes.tableContainer}>
            <StaffHeaderList onSort={onSort} />
            <Box className={classes.boxGridContainer}>
                <Grid container>
                    {pageStaffs.map(staffData => (
                        <StaffRowList
                            key={staffData.uuid}
                            staffData={staffData}
                            editStaff={handleEdit}
                            deleteStaff={deleteStaff}
                            activate={active =>
                                handleActive(staffData, active, marketplaceId)
                            }
                            resendInvitation={resendInvitation}
                        />
                    ))}
                </Grid>
            </Box>
            {pagination}
        </Grid>
    );
};
