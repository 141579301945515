import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Dialog, Card, CardMedia, CardContent, CardActionArea, CardActions } from "@material-ui/core";
import { MediaType, NewFeature } from "model/NewFeature";
import { useAuth } from "hooks";
import { Button, Spinner } from "components/UI";
import { fetchThunk as fecthNewFeaturesThunk, markAsReadThunk } from "actions/newFeatures/NewFeaturesActions";
import PointerSteps from "./PointerSteps";
import ReactHtmlParser from "react-html-parser";
import VideoPlayer from "./VideoPlayer";
import clsx from "clsx";
import useNonInitialEffect from "@versiondos/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        background: "rgba(255,255,255,0)",
        [theme.breakpoints.down("lg")]: {
          borderRadius: "20px",
          width: "477px",
          maxWidth: "477px",
        },
        [theme.breakpoints.only("xl")]: {
          borderRadius: "30px",
          width: "722px",
          maxWidth: "722px !important",
        },
      },
    },
    card: {
      [theme.breakpoints.down("lg")]: {
        borderRadius: "20px",
        height: "484px",
      },
      [theme.breakpoints.only("xl")]: {
        borderRadius: "30px",
        height: "672px",
      },
    },
    cardMedia: {
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        height: "259px",
      },
      [theme.breakpoints.only("xl")]: {
        height: "393px",
      },
    },
    spinner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    playerWrapper: {
      position: "relative",
      paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
    },
    player: {
      position: "absolute",
      top: 0,
      left: 0,
    },
    cardContent: {
      padding: "0px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        height: "149px",
      },
      [theme.breakpoints.only("xl")]: {
        height: "196px",
      },
    },
    contentContainer: {
      [theme.breakpoints.down("lg")]: {
        paddingRight: "36px",
        paddingLeft: "36px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "55px",
        paddingLeft: "55px",
      },
    },
    titleContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        paddingTop: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "38px",
      },
    },
    title: {
      width: "100%",
      fontFamily: "Poppins",
      fontWeight: 500,
      verticalAlign: "center",
      [theme.breakpoints.down("lg")]: {
        fontSize: "19px",
        lineHeight: "22px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "24px",
        lineHeight: "27px",
      },
    },
    descriptionContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        paddingTop: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "15px",
      },
    },
    description: {
      width: "100%",
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
        lineHeight: "22px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    cardActions: {
      padding: "0px",
      [theme.breakpoints.down("lg")]: {
        height: "75px",
      },
      [theme.breakpoints.only("xl")]: {
        height: "83px",
      },
    },
    actionsContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingRight: "36px",
        paddingLeft: "36px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingRight: "55px",
        paddingLeft: "55px",
      },
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "30%",
    },
    centerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "40%",
    },
    rightContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "30%",
    },
  })
);

export const NewFeatures: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const auth = useAuth();

  const newFeatures = useSelector<RootState, Array<NewFeature>>((state) => state.newFeatures.newFeatures);

  const [showNewFeatures, setShowNewFeatures] = useState(false);

  const [newFeatureIndex, setNewFeatureIndex] = useState(0);

  const [showSpinner, setShowSpinner] = useState(false);

  useNonInitialEffect(() => {
    if (auth.signedIn) {
      dispatch(fecthNewFeaturesThunk());
    }
  }, [auth.signedIn]);

  useNonInitialEffect(() => {
    setShowNewFeatures(newFeatures.length > 0);
  }, [newFeatures]);

  const goToStep = (step: number) => {
    setShowSpinner(true);
    setNewFeatureIndex(step);
    setTimeout(() => { setShowSpinner(false) }, 300);
  }

  const nextHandler = () => {
    if (newFeatureIndex < newFeatures.length - 1) {
      setShowSpinner(true);
      setNewFeatureIndex((prev) => prev + 1);
      setTimeout(() => { setShowSpinner(false) }, 300);
    }
  };

  const gotItHandler = () => {
    if (auth.signedIn) {
      dispatch(
        markAsReadThunk(
          auth.user!.staffId,
          newFeatures.map((newFeature) => newFeature.id)
        )
      );
    }
    setShowNewFeatures(false);
  };

  const dialog = (
    <Dialog onClose={() => setShowNewFeatures(false)} open={true} className={classes.dialog}>
      <Card className={classes.card}>
        <CardActionArea>
          {newFeatures[newFeatureIndex]?.mediaType === MediaType.IMAGE ? (
            <CardMedia className={classes.cardMedia} component="img" image={newFeatures[newFeatureIndex]?.mediaSource} />
          ) : (
            showSpinner ? <Box className={clsx(classes.cardMedia, classes.spinner)} key="1"><Spinner /></Box> : <Box key="2" className={classes.cardMedia}>
              {newFeatures[newFeatureIndex]?.mediaSource && (
                <VideoPlayer
                  poster={newFeatures[newFeatureIndex]?.videoPoster}
                  source={newFeatures[newFeatureIndex]?.mediaSource}
                />
              )}
            </Box>
          )}
          <CardContent className={classes.cardContent}>
            <Box className={classes.contentContainer}>
              <Box className={classes.titleContainer}>
                <Typography className={classes.title}>{newFeatures[newFeatureIndex]?.title}</Typography>
              </Box>
              <Box className={classes.descriptionContainer}>
                <Typography className={classes.description}>
                  {ReactHtmlParser(newFeatures[newFeatureIndex]?.description)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Box className={classes.actionsContainer}>
            <Box className={classes.leftContainer}></Box>
            <Box className={classes.centerContainer}>
              {newFeatures.length > 1 && (
                <PointerSteps
                  totalSteps={newFeatures.length}
                  step={newFeatureIndex}
                  onChange={goToStep}
                />
              )}
            </Box>
            <Box className={classes.rightContainer}>
              {newFeatureIndex < newFeatures.length - 1 ? (
                <Button onClick={nextHandler} label="Next" size="medium" color="black" />
              ) : (
                <Button onClick={gotItHandler} label="Got it!" size="medium" />
              )}
            </Box>
          </Box>
        </CardActions>
      </Card>
    </Dialog>
  );

  return auth.signedIn && showNewFeatures ? dialog : null;
};

export default NewFeatures;
