import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Button } from 'components/UI';
import { FunctionComponent } from 'react';

interface FunctionComponentProps {
	sending?: boolean;
	sendDisabled?: boolean;
	rightButtonLabel: string;
	leftButtonLabel: string;
	rightButtonAction?: () => void;
	leftButtonAction?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		footer: {
			display: 'flex',
			justifyContent: 'flex-end',
			padding: '15px 40px',
			borderTop: 'solid 1px #DDDDDD',
			[theme.breakpoints.down('xs')]: {
				justifyContent: 'space-between'
			}
		},
		button: {
			'height': 47,
			'& + &': {
				marginLeft: 10
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 0,
				padding: '14px, 36px, 14px, 36px',
				width: '174px',
				height: '55px'
			}
		},
		cancel: {
			'backgroundColor': '#EF4F57',
			'&:hover': {
				backgroundColor: '#B92E35'
			}
		}
	})
);

export const Footer: FunctionComponent<FunctionComponentProps> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.footer}>
			<Button
				className={classes.button}
				color="black"
				label={props.leftButtonLabel}
				onClick={props.leftButtonAction}
				disabled={props.sending}
			/>
			<Button
				className={classes.button}
				disabled={props.sendDisabled}
				color="green"
				label={props.rightButtonLabel}
				loading={props.sending}
				onClick={props.rightButtonAction}
			/>
		</Box>
	);
};

export default Footer;
