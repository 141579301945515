import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Button } from "components/UI";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface EmptyViewMedicalHistoryProps {
  onAdd?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
    },
    titleContainer: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(11)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "11px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
      },
    },
    descriptionContainer: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(23)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "23px",
      },
    },
    description: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
      },
    },
    buttonAddMedical:{
      height: "40px",
      "& span": {
        fontSize: "14px",
      }
    }
  })
);

export const EmptyViewMedicalHistory: FunctionComponent<EmptyViewMedicalHistoryProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container xs={12} className={classes.container}>
      <Grid item container xs={12} className={classes.titleContainer}>
        <Typography className={classes.title}>Medical History</Typography>
        {isSmall && <Button className={classes.buttonAddMedical} label="+ Add Medical History" onClick={props.onAdd} />}
      </Grid>
      <Grid item container xs={12} className={classes.descriptionContainer}>
        <Typography className={classes.description}>No medical history added.</Typography>
      </Grid>
      {!isSmall && (
        <Grid xs={12}>
          <Button disabled={props.disabled} label="+ Add Medical History" onClick={props.onAdd} />
        </Grid>
      )}
    </Grid>
  );
};

export default EmptyViewMedicalHistory;
