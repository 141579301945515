import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { PropsWithChildren } from "react";

interface SectionProps extends PropsWithChildren {
  title: string;
  description?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    title: {
      fontFamilty: "Poppins",
      fontWeight: 600,
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(18)}px`,
        lineHeight: `${reduceResolution(39)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        lineHeight: "39px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "30px",
      },
    },
    description: {
      fontFamilty: "Poppins",
      fontWeight: 400,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(24)}px`,
        marginBottom: `${reduceResolution(25)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "25px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  })
);

const Section: FunctionComponent<SectionProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box>
        <Typography className={classes.title}>{props.title}</Typography>
        {props.description && <Typography className={classes.description}>{props.description}</Typography>}
      </Box>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default Section;
