import { convertMarketplace, setMarketplace } from '@spike/marketplace-action';
import { setTimeZone } from 'actions/login/LoginActions';

import { showWarning } from '@spike/notifications-action';
import ActionCable, { Cable, Channel } from 'actioncable';
import { useAuth, useMasterData } from 'hooks';
import { MarketplaceWebSocketsMessage } from 'model/WebSockets';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from '@spike/auth-model';
import { MasterData } from '@spike/masterdata-model';

const MarketplaceWebSockets: FunctionComponent = () => {
    const masterData = useMasterData();
    const auth = useAuth();
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        let cable: Cable;
        let channel: Channel;

        //console.info({ action: "MarketplaceWebSockets useEffect", signedIn: auth.signedIn });

        if (auth.signedIn) {
            [cable, channel] = subscribe(auth, masterData);
        }

        return () => {
            unsubscribe(cable, channel);
        };
    }, [auth.timeZone, auth.marketplaceId, masterData]);

    const subscribe = (
        auth: Auth,
        masterData: MasterData
    ): [Cable, Channel] => {
        const cable = ActionCable.createConsumer(
            `${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
        );

        const channel = cable!.subscriptions.create(
            {
                channel: 'MarketplaceChannel',
                marketplace_id: auth.marketplaceId!
            },
            {
                received: (message: any) => {
                    messageHandler(message, auth, masterData);
                }
            }
        );

        console.info({
            action: 'MarketplaceWebSockets subscribed!',
            channel: 'MarketplaceChannel',
            marketplaceId: auth.marketplaceId!
        });

        return [cable, channel];
    };

    const unsubscribe = (cable: Cable, channel: Channel) => {
        cable && cable.disconnect();
        channel && channel.unsubscribe();

        console.info({
            action: 'MarketplaceWebSockets unsubscribed!',
            cable,
            channel
        });
    };

    const messageHandler = (
        message: MarketplaceWebSocketsMessage,
        auth: Auth,
        masterData: MasterData
    ) => {
        console.info({
            action: 'MarketplaceWebSockets message received',
            message
        });

        const marketplace = convertMarketplace(message.marketplace, masterData);

        if (auth.timeZone && auth.timeZone !== marketplace.schedule.timeZone) {
            dispatch(
                showWarning('Time Zone has changed! Please login in again.')
            );
            setTimeout(() => history.push('/logout'), 1500);
        } else {
            dispatch(setMarketplace(marketplace));
            setTimeZone(
                marketplace.schedule.timeZone,
                marketplace.id!,
                dispatch
            );
        }
    };

    return null;
};

export default MarketplaceWebSockets;
