import React, { FunctionComponent, Fragment } from "react";
import { formatShortDateTime } from "utils/DateUtils";
import { useTimeZone } from "hooks";
import { Moment } from "moment-timezone";
export interface Props {
  date: Moment;
}

export const ShortDateTimeFormat: FunctionComponent<Props> = (props) => {
  const timeZone = useTimeZone();
  const dateTime = formatShortDateTime(props.date, timeZone);

  return (<Fragment>{dateTime}</Fragment>);
};

export default ShortDateTimeFormat;
