import { Option } from "@spike/model";
import { fetchProductsThunk } from "@spike/products-action";
import { Product } from "@spike/product-model";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { SelectorFieldSearch } from "components/UI";
import { FieldError } from "@spike/model";
import { useApiClientWrapper } from "hooks";

interface SearchProductProps {
  name?: string;
  errors?: Array<FieldError>;
  selectedProductId?: number;
  otherSelectedProductIds?: Array<number>;
  className?: string;
  onSelect?: (product: Product) => void;
}

export const SearchProduct: FunctionComponent<SearchProductProps> = (props) => {
  const dispatch = useDispatch();
  const apiCLientWrapper = useApiClientWrapper();

  const products = useSelector<RootState, Array<Product>>((state) => state.products.list)
    .filter((product) => product)
    .filter((product) => product.businessProduct?.availableForCheckout)
    .filter(product => !props.otherSelectedProductIds || !props.otherSelectedProductIds.includes(product.businessProduct!.id!));


  const options: Array<Option<number>> = products.map((product) => ({
    id: product.businessProduct!.id!,
    name: product.businessProduct?.name || product.name,
  }));

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProductsThunk(apiCLientWrapper));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const selectHandler = (option: Option<string | number>) => {
    const selectedProduct = products.find((product) => product.businessProduct!.id === option.id);
    props.onSelect && selectedProduct && props.onSelect(selectedProduct);
  };
 
 return (
  <SelectorFieldSearch
    options={options}
    onSelect={(option) => selectHandler(option!)}
    selectedOption={options.find(option => option.id === props.selectedProductId)}
    placeholder="Search product"
    name={props.name}
    errors={props.errors}
    className={props.className}
  />
 );
};

export default SearchProduct;
