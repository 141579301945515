import {
    Box,
    createStyles,
    Divider,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import AmountPercetageField from 'components/UI/AmountPercetageField';
import { Button, ConfirmDialog, Switch } from 'components/UI';
import {
    MarketplaceDeposit,
    MarketplaceDepositType
} from '@spike/marketplace-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import { BusinessSettingsTitle } from '../UI';
import { Link } from 'react-router-dom';
import { getEnrollmentStatusThunk } from '@spike/payments-action';
import { useApiClientWrapper } from 'hooks';
import { PaymentsStatus } from '@spike/payments-action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { debugConsoleLog } from 'utils/GeneralUtils';

export interface Props {
    deposit: MarketplaceDeposit;
    onSave?: (deposit: MarketplaceDeposit) => void;
    onContinue?: (deposit: MarketplaceDeposit) => void;
    onChange?: (deposit: MarketplaceDeposit) => void;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(450)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '785px'
            }
        },
        headerContainer: {
            display: 'flex'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '90%'
        },
        businessSettingsTitle: {
            'display': 'flex',
            'alignItems': 'center',
            '& $iconBusinessTitle': {
                fontSize: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                marginBottom: '5px'
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        switchContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        headerDivider: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '15px'
            }
        },
        subtitleTerciary: {
            width: '100%',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(25)}px`,
                marginTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '42px',
                marginTop: '20px'
            }
        },
        depositContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(25)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '25px'
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',

            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(28)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '28px'
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                bottom: 0
            }
        },
        right: {
            justifyContent: 'flex-end'
        },
        alertContainer: {
            width: '95%',
            display: 'flex',
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',
            marginTop: '10px',
            [theme.breakpoints.down(wbp)]: {
                borderRadius: 18,
                padding: 17,
                marginBottom: '20px'
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: 20,
                padding: 15,
                marginBottom: '18px'
            }
        },
        alertText: {
            width: '100%',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(26)}px`,
                marginBottom: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '28px',
                marginBottom: '18px'
            }
        },
        icon: {
            color: '#BAA997',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '55px',
                borderRadius: '30px',
                marginRight: '30px',
                marginBottom: '20px'
            }
        }
    })
);

export const Deposit: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const status = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [deposit, setDeposit] = useState({ ...props.deposit });
    const [enabled, setEnabled] = useState(
        props.deposit.type !== MarketplaceDepositType.None.toString()
    );
    const [showDisableConfirmation, setShowDisableConfirmation] =
        useState(false);
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const paymentStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );
    const enrollmentStatus = useSelector<RootState, boolean | undefined>(
        state => state.payments.enrollmentStatus?.enabled
    );
    const [showAlert, setshowAlert] = useState(false);
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    useEffect(() => {
        dispatch(getEnrollmentStatusThunk(apiClientWrapper));
    }, []);

    useNonInitialEffect(() => {
        switch (status) {
            case MarketplaceStatus.SaveSuccess:
                setSaved(true);
                setSaving(false);
                break;
            case MarketplaceStatus.Error:
                setSaving(false);
                break;
        }
    }, [status]);
    useNonInitialEffect(() => {
        if (paymentStatus === PaymentsStatus.GetEnrollmentStatusSuccess) {
            setshowAlert(!enrollmentStatus!);
        }
    }, [paymentStatus]);

    const validate = (deposit: MarketplaceDeposit): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (!deposit.value || Number(deposit.value) <= 0) {
            errors.push({
                fieldName: 'deposit',
                errorMessage: 'A value greater than 0 is required'
            });
        }

        if (
            deposit.type === MarketplaceDepositType.Percentage &&
            Number(deposit.value) > 100
        ) {
            errors.push({
                fieldName: 'deposit',
                errorMessage: 'Percentage cannot be greater than 100.'
            });
        }
        return errors;
    };

    const changeEnableHandler = (checked: boolean) => {
        if (checked) {
            setEnabled(checked);
        } else {
            setShowDisableConfirmation(true);
        }
    };

    const disableHandler = () => {
        const updatedDeposit = {
            type: MarketplaceDepositType.None,
            value: undefined
        };
        setDeposit(updatedDeposit);
        props.onChange && props.onChange(updatedDeposit);
        props.onSave && props.onSave(updatedDeposit);
        setEnabled(false);
        setShowDisableConfirmation(false);
        setSaving(true);
    };

    const changeValueHandler = (
        amount: number | null,
        percentage: number | null
    ) => {
        debugConsoleLog({ amount, percentage });

        const updatedDeposit: MarketplaceDeposit = {
            type: amount
                ? MarketplaceDepositType.Amount
                : percentage
                ? MarketplaceDepositType.Percentage
                : MarketplaceDepositType.None,
            value: amount
                ? amount.toString()
                : percentage
                ? percentage.toString()
                : undefined
        };
        setDeposit(updatedDeposit);
        props.onChange && props.onChange(updatedDeposit);
    };

    const saveHandler = () => {
        const errors = validate(deposit);

        setErrors(errors);

        if (errors.length === 0) {
            setSaving(true);
            props.onSave && props.onSave({ ...deposit });
            props.onContinue && props.onContinue({ ...deposit });
        }
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.headerContainer}>
                <Box className={classes.titleContainer}>
                    <BusinessSettingsTitle
                        subtitle={
                            'Choose if you would like to ask clients for a deposit at the time of booking.'
                        }
                        className={classes.businessSettingsTitle}
                    >
                        {' '}
                        {props.isMobile && (
                            <FontAwesomeIcon
                                className={classes.iconBusinessTitle}
                                onClick={props.openSidebar}
                                icon={faArrowLeft}
                                size="2x"
                            />
                        )}{' '}
                        Deposits
                    </BusinessSettingsTitle>
                </Box>
                {showAlert === false && (
                    <Box className={classes.switchContainer}>
                        <Switch
                            checked={enabled}
                            onChange={checked => changeEnableHandler(checked)}
                        ></Switch>
                        {showDisableConfirmation && (
                            <ConfirmDialog
                                open={true}
                                title={<Typography>Deposit</Typography>}
                                question={
                                    <Typography>
                                        Do you want to <strong>remove</strong>{' '}
                                        the deposit you
                                        <br /> previously set for this
                                        marketplace?
                                    </Typography>
                                }
                                cancelButtonLabel="Cancel"
                                confirmButtonLabel="Remove"
                                onCancel={() =>
                                    setShowDisableConfirmation(false)
                                }
                                onConfirm={disableHandler}
                            />
                        )}
                    </Box>
                )}
            </Box>
            {showAlert && (
                <>
                    <Box className={classes.alertContainer}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={classes.icon}
                        />
                        <Box>
                            <Typography className={classes.alertText}>
                                CuddlesPay is not active on your account. Click
                                below to enroll in CuddlesPay & begin collecting
                                deposits.
                            </Typography>
                            <Link to={'/payments'}>
                                <Button
                                    label={'Add CuddlesPay'}
                                    color={'green'}
                                />
                            </Link>
                        </Box>
                    </Box>
                    {/*<Divider className={classes.headerDivider}></Divider>*/}
                </>
            )}

            {enabled && showAlert === false && (
                <>
                    <Divider className={classes.headerDivider}></Divider>
                    <Typography className={classes.subtitleTerciary}>
                        Set a fixed amount or percentage that the customer must
                        pay when booking.
                    </Typography>
                    <Box className={classes.depositContainer}>
                        <AmountPercetageField
                            name="deposit"
                            amount={
                                deposit.type === MarketplaceDepositType.Amount
                                    ? Number(deposit.value)
                                    : null
                            }
                            percentage={
                                deposit.type ===
                                MarketplaceDepositType.Percentage
                                    ? Number(deposit.value)
                                    : null
                            }
                            onChange={(amount, percentage) =>
                                changeValueHandler(amount, percentage)
                            }
                            errors={errors}
                            precision={3}
                        />
                    </Box>
                    <Box
                        className={clsx(classes.buttonContainer, {
                            [classes.right]: props.onContinue
                        })}
                    >
                        <Button
                            label={
                                props.onSave
                                    ? saved
                                        ? 'Saved!'
                                        : 'Save'
                                    : 'Continue'
                            }
                            onClick={saveHandler}
                            className={classes.button}
                            loading={saving}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Deposit;
