import React, { FunctionComponent } from "react";

interface CheckProps {
  className?: string;
}

export const Check: FunctionComponent<CheckProps> = (props) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M45.614 19.1235C44.3833 9.41468 38.1543 2.23796 29.6667 0.837505C25.5691 0.158499 19.4911 -0.247019 13.8044 1.67212C8.21201 3.55825 4.30773 7.22677 2.19998 12.5739C-1.12904 21.0144 -0.0822393 30.8222 4.86413 37.5652C8.02812 41.8702 15.5538 45.3454 22.9993 45.3454C26.8847 45.3454 30.7513 44.4024 33.9624 42.1249C43.4921 37.8575 46.6796 27.5215 45.614 19.1235Z"
        fill="#92B4A7"
      />
      <path
        d="M21.1787 33.5807C21.0239 33.5799 20.8717 33.5411 20.7355 33.4675C18.6701 32.3689 16.8972 30.6383 15.1808 28.9644C14.236 28.0059 13.2429 27.0963 12.2054 26.2389C11.941 26.0292 11.8085 25.6943 11.8578 25.3604C11.9072 25.0265 12.131 24.7443 12.4449 24.6201C12.7588 24.4959 13.1151 24.5486 13.3796 24.7583C14.4499 25.6118 15.492 26.6445 16.5011 27.6158C17.8533 29.0342 19.3481 30.3093 20.9618 31.4211C23.5835 28.1722 25.5875 24.2444 27.5302 20.4391C28.9448 17.7183 30.3594 14.908 32.0145 12.3382C32.2993 11.9064 32.8788 11.7846 33.3134 12.0652C33.7479 12.3459 33.8753 12.9242 33.5989 13.3614C31.9978 15.9306 30.5279 18.5793 29.1947 21.2973C27.0776 25.4373 24.8897 29.7236 21.8766 33.2506C21.7017 33.4552 21.4478 33.5753 21.1787 33.5807Z"
        fill="white"
      />
    </svg>
  );
};

export default Check;
