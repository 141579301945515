import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { CuddlesPay } from './CuddlesPay';
import clsx from 'clsx';

interface PoweredByCuddlesPayProps {
	className?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			gap: 14,
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			justifyItems: 'center',
			textAlign: 'center'
		},
		poweredBy: {
			width: '100%',
			fontSize: 12,
			fontWeight: 500,
			color: '#BCB8AE'
		},
		cuddlesPay: {
			width: 200
		}
	})
);

export const PoweredByCuddlesPay: FunctionComponent<
	PoweredByCuddlesPayProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Typography className={classes.poweredBy}>POWERED BY</Typography>
			<CuddlesPay className={classes.cuddlesPay} />
		</Box>
	);
};

export default PoweredByCuddlesPay;
