import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { TextField2, PhoneField3, SelectField } from 'components/UI';
import { StaffEmergencyContact } from 'model/Staff';
import { MemberWrapper } from './Member';
import { useMasterData } from 'hooks';
import { Option } from '@spike/model';

interface EmergencyContactProps {
    memberWrapper: MemberWrapper;
    onChange: (emergencyContact: StaffEmergencyContact) => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',

            [theme.breakpoints.down('lg')]: {
                paddingLeft: 16,
                paddingRight: 16
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 21,
                paddingBottom: 21
            }
        },
        titleContainer: {
            marginBottom: 15
        },
        title: {
            fontSize: 20,
            fontWeight: 600,
            fontFamily: 'Poppins',
            [theme.breakpoints.up('md')]: {
                fontSize: 11,
                fontWeight: 500,
                lineHeight: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: 14,
                lineHeight: '22px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: 18,
                lineHeight: '27px'
            }
        },
        row: {
            [theme.breakpoints.up('md')]: {
                marginTop: 11,
                display: 'flex'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: 20
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: 21
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 20
            }
        },
        rowFirst: {
            marginTop: '0px'
        },
        leftCell: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginRight: 10
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '15px'
            }
        },
        rightCell: {
            width: '100%',
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginLeft: 10
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: 12
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: 15
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 20
            }
        },
        input: {
            [theme.breakpoints.down('lg')]: {
                marginBottom: 0
            }
        }
    })
);

export const EmergencyContact: FunctionComponent<
    EmergencyContactProps
> = props => {
    const classes = useStyles();

    const masterdata = useMasterData();

    const prefix = 'emergencyContact.';
    const [optionSelected, setOptionSelected] =
        useState<Option<string | number>>();

    useEffect(() => {
        if (props.memberWrapper.member.emergencyContact.relationship?.id) {
            const option: Option<string | number> = {
                id: props.memberWrapper.member.emergencyContact.relationship.id,
                name: props.memberWrapper.member.emergencyContact.relationship
                    .name
            };
            setOptionSelected(option);
        }
    }, []);

    const onChangeHandler = (value: string, attribute: string) => {
        props.onChange(
            _.set(
                { ...props.memberWrapper.member.emergencyContact },
                _.replace(attribute, prefix, ''),
                value
            )
        );
    };

    const onSelectHandler = (value: Option<string | number>) => {
        setOptionSelected(value);
        props.onChange(
            _.set(
                { ...props.memberWrapper.member.emergencyContact },
                'relationship',
                masterdata.relationships.find(r => r.id === value.id)
            )
        );
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>
                    Emergency Contact
                </Typography>
            </Box>
            <Box className={clsx(classes.row, classes.rowFirst)}>
                <Box className={classes.leftCell}>
                    <TextField2
                        value={
                            props.memberWrapper.member.emergencyContact
                                .firstName
                        }
                        placeholder="First Name"
                        label="First Name"
                        name={`${prefix}firstName`}
                        errors={props.memberWrapper.errors}
                        className={classes.input}
                        onChange={onChangeHandler}
                        capitalize={true}
                    />
                </Box>
                <Box className={classes.rightCell}>
                    <TextField2
                        value={
                            props.memberWrapper.member.emergencyContact.lastName
                        }
                        placeholder="Last Name"
                        label="Last Name"
                        name={`${prefix}lastName`}
                        errors={props.memberWrapper.errors}
                        className={classes.input}
                        onChange={onChangeHandler}
                        capitalize={true}
                    />
                </Box>
            </Box>
            <Box className={classes.row}>
                <PhoneField3
                    value={
                        props.memberWrapper.member.emergencyContact.phoneNumber
                    }
                    label="Phone Number"
                    placeholder="Phone Number"
                    name={`${prefix}phoneNumber`}
                    errors={props.memberWrapper.errors}
                    className={classes.input}
                    onChange={onChangeHandler}
                />
            </Box>
            <Box className={classes.row}>
                <SelectField
                    label="Relationship"
                    placeholder="Relationship"
                    options={[...masterdata.relationships]}
                    selectedOption={optionSelected}
                    onSelect={onSelectHandler}
                />
            </Box>
        </Box>
    );
};

export default EmergencyContact;
