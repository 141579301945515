import { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { PetType, PetTypeIds } from '@spike/model';
import { Button } from 'components/UI';
import { multiplePetTypeIconMap } from '../model';

const useStyles = makeStyles((theme: Theme) => ({
    buttonOption: {
        'height': 'auto !important',
        'borderRadius': 30,
        'display': 'flex',
        'alignItems': 'center',
        'padding': '4px 13px 4px 5px',
        'border': 'solid 1px transparent',
        'transition': 'all 0.3s ease-in-out',

        [theme.breakpoints.up('md')]: {
            padding: '6px 13px 6px 8px'
        },

        '& .MuiButton-startIcon': {
            width: 34,
            height: 34,
            marginLeft: 0,
            marginRight: 7,
            alignItems: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            color: '#fff',
            borderRadius: '50%',

            [theme.breakpoints.up('md')]: {
                'width': 42,
                'height': 42,
                'marginRight': 10,

                '& svg': {
                    fontSize: 24
                }
            }
        },
        '& .MuiButton-label': {
            color: '#000'
        }
    },
    dogOptionButton: {
        'backgroundColor': '#E9F0ED !important',

        '&:hover': {
            backgroundColor: '#E9F0ED !important',
            opacity: 0.8
        },
        '& .MuiButton-startIcon': {
            backgroundColor: '#92B4A7'
        }
    },
    catOptionButton: {
        'backgroundColor': '#FAEFDF !important',

        '&:hover': {
            backgroundColor: '#FAEFDF !important',
            opacity: 0.8
        },
        '& .MuiButton-startIcon': {
            backgroundColor: '#DBA960'
        }
    },
    exoticOptionButton: {
        'backgroundColor': '#F9F0F0 !important',

        '&:hover': {
            backgroundColor: '#F9F0F0 !important',
            opacity: 0.8
        },
        '& .MuiButton-startIcon': {
            backgroundColor: '#DBA960'
        }
    },
    selected: {
        border: 'solid 1px #000'
    }
}));

interface PetTypeSelectorItemProps {
    petType: PetType;
    selectedPetType: PetType;
    onSelect: (petType: PetType) => void;
}

const PetTypeSelectorItem: FunctionComponent<PetTypeSelectorItemProps> = props => {
    const classes = useStyles();

    return (
        <Button
            startElement={<FontAwesomeIcon icon={multiplePetTypeIconMap.get(props.petType.id)!} />}
            className={clsx([
                classes.buttonOption,
                {
                    [classes.dogOptionButton]: props.petType.id === PetTypeIds.DOGS,
                    [classes.catOptionButton]: props.petType.id === PetTypeIds.CATS,
                    [classes.selected]: props.petType.id === props.selectedPetType.id
                }
            ])}
            onClick={() => props.onSelect(props.petType)}
            label={`For ${props.petType.plural}`}
        />
    );
};

export default PetTypeSelectorItem;
