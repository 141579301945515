import { Reducer } from "redux";
import { UsersState, UsersStatus } from "./UsersState";
import {
  USERS_RESET_ACTION_TYPE,
  USERS_CHANGE_PASSWORD_START_ACTION_TYPE,
  USERS_CHANGE_PASSWORD_SUCCESS_ACTION_TYPE,
  USERS_ERROR_ACTION_TYPE,
  UsersAction,
} from "actions/users/UsersActionsTypes";

const initialState: UsersState = {
  status: UsersStatus.Initial,
  loading: false,
};

export const usersReducer: Reducer<UsersState, UsersAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case USERS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case USERS_CHANGE_PASSWORD_START_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.ChangingPassowrd,
        loading: true,
      };
    case USERS_CHANGE_PASSWORD_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: UsersStatus.ChangePasswordSuccess,
        loading: false,
      };
    case USERS_ERROR_ACTION_TYPE:
    return {
      ...state,
      status: UsersStatus.Error,
      loading: false,
    };
    default:
      return state;
  }
};

export default usersReducer;
