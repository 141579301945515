import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store, { history } from "./store";
import { ConnectedRouter } from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import 'overlayscrollbars/css/OverlayScrollbars.css';

import "./index.css";
import 'rocket/assets/css/Base.css';

import * as amplitude from '@amplitude/analytics-browser';

amplitude.init(`${process.env.REACT_APP_AMPLITUDE_KEY}`);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
    </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
