import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9998,
      position: "fixed",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    dialog: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      overflow: "auto",
      position: "fixed",
      padding: "60px 0px",
    },
    modal: {
      width: 600,
      margin: "auto",
      borderRadius: 30,
      backgroundColor: "#fff",
    },
    header: {
      padding: "30px 0px 35px",
    },
    body: {
      fontSize: 16,
      lineHeight: 1.5,
      width: "100%",
      minHeight: 550,
      margin: "0px auto",
      padding: "0px 30px 45px",

      "& > *:last-child": {
        marginBottom: 0,
      },

      "& label": {
        color: "#222",
        fontSize: 16,
        lineHeight: 1,
        fontWeight: 600,
        marginBottom: 15,
        display: "block",
      },
    },
    title: {
      color: "#000",
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.2,
      marginBottom: 0,
    },
    group: {
      marginBottom: 30,
      '& label':{
        marginBottom:24
      }
    },
    radioOption: {
      width: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      borderRadius: 12,
      textAlign: "center",
      padding: "17px 0px",
      backgroundColor: "#fff",
      border: "solid 2px #D3D3D3",
      transition: "border-color 0.1s ease-out",

      "&.active": {
        borderColor: "#222",
      },
      "&.disabled": {
        opacity: 0.4,
        pointerEvents: "none",

        "& svg": {
          color: "#D3D3D3",
        },
      },

      "& svg": {
        color: "#5E8677",
        fontSize: 30,
        marginBottom: 4,
      },
    },
    radioOptionInput: {
      top: 9,
      right: 11,
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      position: "absolute",
      border: "2px solid #E5E5E5",

      "& svg": {
        margin: 0,
        marginTop: 1,
        fontSize: 13,
        color: "#fff",
        display: "none",
      },

      "&.active": {
        borderColor: "#5E8677",
        backgroundColor: "#5E8677",

        "& svg": {
          display: "block",
        },
      },
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "15px 40px",
      borderTop: "solid 1px #DDDDDD",
    },
    button: {
      height: 47,

      "& + &": {
        marginLeft: 10,
      },
    },
    cancel: {
      backgroundColor: "#EF4F57",
      "&:hover": {
        backgroundColor: "#B92E35",
      },
    },
    clientInfo: {
      width: "100%",
      borderRadius: 7,
      padding: "15px 25px",
      backgroundColor: "#FBFBFB",

      fontSize: 14,
      lineHeight: 1,
      fontWeight: 400,

      "& svg": {
        fontSize: 16,
        marginRight: 7,
        verticalAlign: "middle",
      },
    },
    clientName: {
      fontSize: 15,
      lineHeight: 1,
      fontWeight: 600,

      "&:not(:last-child)": {
        marginBottom: 10,
      },
    },
    edit: {
      float: "right",
      fontWeight: 500,
      cursor: "pointer",

      "& svg": {
        marginRight: 6,
      },
    },
    alert: {
      width: "100%",
      fontSize: 15,
      lineHeight: 1.6,
      fontWeight: 500,
      borderRadius: 16,
      marginBottom: 28,
      padding: "16px 25px",
      backgroundColor: "#F8F5F1",
      border: "2px solid #BCB8AE",

      "& svg": {
        fontSize: 28,
        marginRight: 11,
        color: "#BAA997",
        verticalAlign: "middle",
      },
    },
  })
);

export default useStyles;