import { LoginResponseDto } from './LoginDtos';
import { Auth } from '@spike/auth-model';

export const convert = (dto: LoginResponseDto, signedIn: boolean): Auth => {
    const work = dto.marketplaces.find(
        marketplaceDto => marketplaceDto.id === dto.marketplace.id
    )!;

    return {
        user: {
            id: dto.user.id,
            firstName: dto.user.first_name,
            lastName: dto.user.last_name,
            avatar: dto.user.image,
            email: dto.user.email,
            staffId: dto.user.staff_id,
            admin: dto.user.admin,
            role: {
                ...dto.master_data.roles.find(
                    roleDto => roleDto.id === work.role_id
                )!
            },
            accessLevel: {
                ...dto.master_data.access_level.find(
                    accessLevelDto => accessLevelDto.id === work.access_level_id
                )!
            }
        },
        signedIn,
        token: dto.user.token,
        marketplaceId: dto.marketplace.id,
        addressId: dto.marketplace.addresses[0].id,
        timeZone: dto.time_zone,
        marketplaces: dto.marketplaces.map(mktp => ({
            id: mktp.id,
            name: mktp.name,
            city: mktp.city,
            addressId: mktp.address_id,
            admin: mktp.admin,
            timeZone: mktp.time_zone,
            role: {
                ...dto.master_data.roles.find(
                    roleDto => roleDto.id === mktp.role_id
                )!
            },
            accessLevel: {
                ...dto.master_data.access_level.find(
                    accessLevelDto => accessLevelDto.id === mktp.access_level_id
                )!
            }
        })),
        version: process.env.REACT_APP_VERSION
    };
};
