import { faEye, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons';
import {
    faPrint,
    faSignature,
    faTrash
} from '@fortawesome/pro-regular-svg-icons';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import {
    deleteSignatureThunk,
    downloadSignaturePDFThunk,
    printSignatureThunk,
    resendThunk,
    sendSignaturePdfThunk
} from 'actions/agreements/AgreementsActions';
import { ConfirmDialog, OverFullWindow } from 'components/UI';
import { Signature } from 'model/Agreements';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import AgreementSignature from './AgreementsSignature';
import Dropdown from './ui/Dropdown';
import { DropdownOption } from './ui/Dropdown/model';
import OptionsModal from './Modal/OptionsModal';

interface SignatureDropdownProps {
    signature: Signature;
}

const previewOption: DropdownOption = {
    id: 'preview',
    title: 'Preview',
    icon: faEye
};

const downloadOption: DropdownOption = {
    id: 'download',
    title: 'Download PDF',
    icon: faFileDownload
};

const printOption: DropdownOption = {
    id: 'print',
    title: 'Print',
    icon: faPrint
};

const signNowOption: DropdownOption = {
    id: 'sign_now',
    title: 'Sign Now',
    icon: faSignature
};

const sendACopyOption: DropdownOption = {
    id: 'send_a_copy',
    title: 'Send a copy',
    icon: faPaperPlane
};

const resendOption: DropdownOption = {
    id: 'resend',
    title: 'Resend',
    icon: faPaperPlane
};

const deleteOption: DropdownOption = {
    id: 'delete',
    title: 'Delete',
    icon: faTrash
};

const unsignedOptions: Array<DropdownOption> = [
    signNowOption,
    resendOption,
    deleteOption
];

const signedOptions: Array<DropdownOption> = [
    previewOption,
    sendACopyOption,
    downloadOption,
    printOption,
    deleteOption
];

const SignatureDropdown: FunctionComponent<SignatureDropdownProps> = props => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const agreementsStatus = useSelector<RootState, AgreementsStatus>(
        state => state.agreements.status
    );

    const filteredOptions =
        props.signature.status === 'signed' ? signedOptions : unsignedOptions;

    const [selectedOptionId, setSelectedOptionId] = useState<string | null>(
        null
    );
    const [processing, setProcessing] = useState(false);

    useNonInitialEffect(() => {
        switch (agreementsStatus) {
            case AgreementsStatus.DeleteSignatureSuccess:
                setSelectedOptionId(null);
                setProcessing(false);
                break;
            case AgreementsStatus.SignSuccess:
                setSelectedOptionId(null);
                setProcessing(false);
                break;
            case AgreementsStatus.Error:
                setProcessing(false);
                break;
        }
    }, [agreementsStatus]);

    useNonInitialEffect(() => {
        switch (selectedOptionId) {
            case downloadOption.id:
                dispatch(downloadSignaturePDFThunk(props.signature.id));
                setSelectedOptionId(null);
                break;
            case sendACopyOption.id:
                dispatch(sendSignaturePdfThunk(props.signature.id));
                setSelectedOptionId(null);
                break;
            case resendOption.id:
                dispatch(resendThunk(props.signature.id!));
                setSelectedOptionId(null);
                break;
            case printOption.id:
                dispatch(printSignatureThunk(props.signature.id!));
                setSelectedOptionId(null);
                break;
            case previewOption.id:
                dispatch(printSignatureThunk(props.signature.id!));
                setSelectedOptionId(null);
                break;
        }
    }, [selectedOptionId]);

    const deleteHandler = (signatureId: number) => {
        setProcessing(true);
        dispatch(deleteSignatureThunk(signatureId));
    };

    return (
        <>
            {isMobile ? (
                <OptionsModal
                    options={filteredOptions}
                    onClick={setSelectedOptionId}
                />
            ) : (
                <Dropdown
                    options={filteredOptions}
                    onClick={setSelectedOptionId}
                />
            )}
            {deleteOption.id === selectedOptionId && (
                <ConfirmDialog
                    open={true}
                    title={
                        <Typography>
                            {props.signature.title} <br />{' '}
                            {props.signature!.client.firstName}{' '}
                            {props.signature.client.lastName}
                        </Typography>
                    }
                    question={
                        <Typography>
                            Do you want to <strong>delete</strong> this
                            <br /> signature?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    onCancel={() => setSelectedOptionId(null)}
                    onConfirm={() => {
                        deleteHandler(props.signature.id!);
                    }}
                    processing={processing}
                />
            )}
            {signNowOption.id === selectedOptionId && (
                <OverFullWindow onClose={() => setSelectedOptionId(null)}>
                    <AgreementSignature
                        hash={props.signature.secureHash!}
                        onClose={() => {
                            setSelectedOptionId(null);
                        }}
                    />
                </OverFullWindow>
            )}
        </>
    );
};

export default SignatureDropdown;
