import { UserNotification } from 'model/UserNotifications';
import React from 'react';
import NotificationRow from './NotificationRow';

type NotificationsListProps = {
    notifications: Array<UserNotification>;
    emptyMessage: React.ReactElement;
    onRedirect: () => void;
};

export const NotificationsList: React.FunctionComponent<
    NotificationsListProps
> = ({ notifications, emptyMessage, onRedirect }) => {
    return (
        <>
            {/* Notifications list only if there are more than 0 */}
            {notifications.length > 0 &&
                notifications.map(notification => (
                    <NotificationRow
                        key={notification.id}
                        notification={notification}
                        onRedirect={onRedirect}
                    />
                ))}

            {/* Empty message if there are no notifications */}
            {notifications.length === 0 && emptyMessage}
        </>
    );
};
