import React from 'react';
import {
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { MarketplacePaymentMethod } from '@spike/payments-model';
import clsx from 'clsx';

interface Props {
	id: number;
	active: boolean;
	onEdit: () => void;
	card: MarketplacePaymentMethod;
	onOpenDropdown: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'height': 60,
			'width': '100%',
			'fontSize': 14,
			'fontWeight': 500,

			[theme.breakpoints.up('md')]: {
				height: 73
			},

			'&, & p': {
				fontSize: 14
			},

			'&:not(:last-child)': {
				borderBottom: 'solid 1px #D4D4D4'
			},

			[theme.breakpoints.up('sm')]: {
				'&, & p': {
					fontSize: 16
				}
			}
		},
		leftCol: {
			display: 'flex',
			alignItems: 'center'
		},
		centerCol: {
			textAlign: 'right',

			[theme.breakpoints.up('sm')]: {
				textAlign: 'center'
			}
		},
		rightCol: {
			columnGap: 12,
			display: 'flex',
			position: 'relative',
			justifyContent: 'flex-end'
		},

		cardNumber: {
			fontWeight: 500,
			paddingLeft: 10
		},
		defaultBadge: {
			fontSize: 14,
			lineHeight: 1.43,
			color: '#5E8677',
			borderRadius: 30,
			padding: '3.5px 11px',
			display: 'inline-block',
			backgroundColor: '#E9F0ED'
		},
		dropdownBtn: {
			border: 0,
			fontSize: 22,
			cursor: 'pointer',
			backgroundColor: 'transparent'
		},
		dropdown: {
			'top': '120%',
			'zIndex': 100,
			'borderRadius': 14,
			'overflow': 'hidden',
			'position': 'absolute',
			'backgroundColor': '#fff',
			'border': 'solid 1px #000',

			'& button': {
				'border': 0,
				'width': '100%',
				'fontSize': 14,
				'fontWeight': 500,
				'lineHeight': 1.97,
				'cursor': 'pointer',
				'appearance': 'none',
				'padding': '7.5px 20px',
				'backgroundColor': '#fff',

				'& svg': {
					marginRight: 4
				}
			}
		},

		desktop: {
			[theme.breakpoints.only('xs')]: {
				display: 'none'
			}
		},
		mobile: {
			[theme.breakpoints.up('sm')]: {
				display: 'none'
			}
		}
	})
);

const path = '/images/creditCards/';

const icons: Map<string, string> = new Map([
	['VISA', path.concat('icon-visa.svg')],
	['default', path.concat('icon-credit-card.svg')],
	['American Express', path.concat('icon-amex.svg')],
	['MsaterCard', path.concat('icon-mastercard.svg')],
	['Diners Club', path.concat('icon-diners-club.svg')]
]);

export const CardRow: React.FunctionComponent<Props> = ({ card }) => {
	const classes = useStyles();

	return (
		<Grid className={classes.root} container alignItems="center">
			<Grid item xs={12} className={classes.mobile}>
				{card.holderName}
			</Grid>

			<Grid item xs={6} sm={4} className={classes.leftCol}>
				<img
					src={icons.get(card.name) || icons.get('default')}
					alt="Card icon"
				/>

				<Typography className={classes.cardNumber}>
					•••• {card.lastDigits}
				</Typography>
			</Grid>
			<Grid item xs={6} sm={4} className={classes.centerCol}>
				<Typography>
					Exp. {card.expirationMonth}/{card.expirationYear}
				</Typography>
			</Grid>
			<Grid
				item
				xs={4}
				className={clsx(classes.rightCol, classes.desktop)}
			>
				{card.holderName}
				{/* <Box className={classes.defaultBadge}>Default</Box> */}

				{/* <button
					type="button"
					aria-label="Open dropdown"
					className={classes.dropdownBtn}
					onClick={() => props.onOpenDropdown(props.id)}
				>
					<FontAwesomeIcon icon={faEllipsisH} />
				</button> */}

				{/* {props.active && (
					<Box className={classes.dropdown}>
						<button
							type="button"
							aria-label="Edit card"
							onClick={props.onEdit}
						>
							<FontAwesomeIcon icon={faPencil} />
							Edit
						</button>
					</Box>
				)} */}
			</Grid>
		</Grid>
	);
};
