import {
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Box,
	Typography
} from '@material-ui/core';
import { Button, Pagination } from 'components/UI';
import WizardPage from 'pages/WizardPage';
import {
	Fragment,
	FunctionComponent,
	useEffect,
	useRef,
	useState
} from 'react';
import { CSSProperties } from 'react';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import { Img } from 'react-image';
import { FieldError } from '@spike/model';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { uploadFileThunk } from '../../actions/bulkUpload/bulkUploadActions';
import { BulkUploadState } from '../../reducers/bulkUpload/BulkUploadState';
import ProgressBar from '../Payments/Sidebar/ProgressBar';
import exportFromJSON from 'export-from-json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export interface ClientCsv {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	address?: string;
	city?: string;
	state?: string;
	zipcode?: string;
	country?: string;
	pet_name?: string;
	pet_type?: string;
	pet_size?: string;
	pet_breed?: string;
}

export interface ObjectCsv {
	data: Array<ClientCsv>;
	errors: [];
	meta: [];
}

const styles = {
	zone: {
		alignItems: 'center',
		border: `2px dashed #CCC`,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: 20
	} as CSSProperties,
	progressBar: {
		marginTop: 10,
		marginBottom: 10
	} as CSSProperties,
	fileContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #000000',
		borderRadius: '14px',
		padding: 20
	} as CSSProperties,
	fileName: {
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '100%',
		color: '#000000',
		marginBottom: '5px'
	} as CSSProperties,
	fileSize: {
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '100%',
		color: '#7A7A7A'
	} as CSSProperties,
	iconCenter: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	} as CSSProperties,
	fileSubcontainer: {
		display: 'flex',
		width: '80%'
	} as CSSProperties,
	fileSubcontainerDelete: {
		gap: 7,
		display: 'flex',
		justifyContent: 'end',
		width: '20%',
		cursor: 'pointer'
	} as CSSProperties,
	deleteText: {
		fontSize: 13,
		fontWeight: 500,
		lineHeight: '34px',
		color: '#7A7A7A',
		alignItems: 'center',
		display: 'flex'
	} as CSSProperties,
	downloadText: {
		color: '#EAB464',
		cursor: 'pointer',
		textDecoration: 'underline'
	} as CSSProperties,
	clientList: {
		display: 'flex',
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '100%',
		color: '#000000',
		paddingTop: 20,
		paddingBottom: 20,
		border: '1px solid #DDDDDD',
		borderTopStyle: 'unset',
		borderLeftStyle: 'unset',
		borderRightStyle: 'unset',
		width: '100%'
	} as CSSProperties,
	clientListHeader: {
		display: 'flex',
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '24px',
		color: '#222222',
		paddingTop: 20,
		paddingBottom: 20,
		border: '2px solid #000000',
		borderTopStyle: 'unset',
		borderLeftStyle: 'unset',
		borderRightStyle: 'unset',
		width: '100%'
	} as CSSProperties,
	trTableHeader: {
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '24px',
		color: '#222222'
	} as CSSProperties,
	thTable: {
		padding: 20,
		paddingTop: 20,
		paddingBottom: 20,
		textAlign: 'start',
		border: '2px solid #000000',
		borderTopStyle: 'unset',
		borderLeftStyle: 'unset',
		borderRightStyle: 'unset'
	} as CSSProperties,
	trTableContent: {
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '100%',
		color: '#000000'
	} as CSSProperties,
	tdTable: {
		padding: 20,
		paddingTop: 20,
		paddingBottom: 20,
		textAlign: 'start',
		border: '1px solid #DDDDDD',
		borderTopStyle: 'unset',
		borderLeftStyle: 'unset',
		borderRightStyle: 'unset'
	} as CSSProperties,
	tableStyle: {
		borderCollapse: 'collapse',
		width: '100%',
		whiteSpace: 'nowrap'
	} as CSSProperties
};

interface ClientBulkUploadProps {
	onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		borderContainer: {
			'borderRadius': 24,
			'marginBottom': 32,
			'padding': '30px 25px',
			'border': '1px solid #DDDDDD',

			[theme.breakpoints.up('md')]: {
				padding: 40,
				marginBottom: 40
			},

			'& .delete-button': {
				[theme.breakpoints.down('sm')]: {
					height: 38,
					fontSize: 16,
					color: '#000',
					borderRadius: '50%',
					width: '38px !important',
					backgroundColor: '#F1F1F1',
					justifyContent: 'center !important'
				}
			},
			'& .delete-label': {
				[theme.breakpoints.down('sm')]: {
					display: 'none !important'
				}
			}
		},
		errorMessage: {
			marginBottom: 32,
			marginLeft: '0px !important',

			[theme.breakpoints.up('md')]: {
				marginBottom: 40
			}
		},
		borderContainerContact: {
			display: 'flex',
			padding: '20px 16px',
			borderRadius: '24px',
			border: '1px solid #DDDDDD',

			[theme.breakpoints.up('md')]: {
				padding: '20px'
			}
		},
		firstText: {
			fontSize: 18,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',
			marginBottom: 12,

			[theme.breakpoints.up('md')]: {
				fontSize: 20,
				marginBottom: 20
			}
		},
		secondText: {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
			color: '#000000',
			marginBottom: 30,

			[theme.breakpoints.up('md')]: {
				fontSize: 15
			}
		},
		thirdText: {
			fontSize: 14,
			fontWeight: 500,
			lineHeight: 1.4,
			color: '#000000',
			marginBottom: 16,
			textAlign: 'center',
			justifySelf: 'center',

			[theme.breakpoints.up('md')]: {
				fontSize: 15,
				marginBottom: 30
			}
		},
		csvButton: {
			height: 'auto'
		},
		image: {
			width: 47,
			height: 47,
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				marginBottom: 30
			}
		},
		firstHelpText: {
			fontSize: 18,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			margin: '10px 0px',

			[theme.breakpoints.up('md')]: {
				fontSize: 20
			}
		},
		secondHelpText: {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
			color: '#000000',
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 15
			}
		},
		thirdHelpText: {
			'fontSize': 14,
			'fontWeight': 600,
			'lineHeight': 1.4,
			'color': '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 15
			},

			'& svg': {
				marginLeft: 7
			}
		},
		imageContact: {
			width: '41px',
			height: '41px',
			marginRight: '10px'
		},
		fourthText: {
			fontSize: 18,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',
			marginBottom: 16,
			marginTop: 24,

			[theme.breakpoints.up('md')]: {
				fontSize: 20
			}
		},
		checkFile: {
			width: '20px',
			height: '20px',
			marginRight: '10px'
		},
		iconFile: {
			width: '22px',
			height: '29px',
			marginRight: '10px'
		},
		iconDelete: {
			width: '17px',
			height: '17px'
		},
		tableContainer: {
			backgroundColor: 'white',

			[theme.breakpoints.down('sm')]: {
				width: 'auto',
				margin: '0px -16px'
			}
		},
		scrollArrow: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		boxGridContainer: {
			'width': '100%',
			'overflowX': 'scroll',
			//overflowY: 'hidden',

			[theme.breakpoints.up('md')]: {
				overflow: 'hidden',
				paddingBottom: '20px'
			},
			[theme.breakpoints.down('sm')]: {
				'& th': {
					fontSize: 14,
					lineHeight: 1,
					fontWeight: 600,
					borderWidth: '1px !important',
					borderBottomColor: '#D4D4D4 !important'
				},
				'& td, & th': {
					'&:first-child': {
						'left': 0,
						'position': 'sticky',

						'&:before': {
							content: `''`,
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							zIndex: -1,
							display: 'block',
							position: 'absolute',
							backgroundColor: '#fff',
							boxShadow: '0px 0px 0px 0px transparent',
							transition: 'box-shadow 0.15s ease-out'
						}
					}
				}
			},

			'&.scrolled td:first-child, &.scrolled th:first-child': {
				'&:before': {
					boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
				}
			}
		},
		pagination: {
			[theme.breakpoints.down('sm')]: {
				paddingRight: '9px'
			},
			[theme.breakpoints.only('md')]: {
				paddingRight: '15px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingRight: '20px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingRight: '30px'
			}
		},
		tableContainerGrid: {
			[theme.breakpoints.up('md')]: {
				padding: '30px'
			}
		},
		arrowIcon: {
			cursor: 'pointer'
		},
		button: {
			'height': 47,
			'width': 160,
			'justifySelf': 'center',

			'& + &': {
				marginLeft: 10
			},

			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		statusPage: {
			gap: 20,
			height: '100%',
			display: 'flex',
			flexWrap: 'nowrap',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			padding: '0px 16px'
		},
		statusBody: {
			maxWidth: 460,

			[theme.breakpoints.down('sm')]: {
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}
		},
		statusIcon: {
			width: 83,
			height: 83,
			marginBottom: 16
		},
		statusTitle: {
			fontSize: 26,
			fontWeight: 600,
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 32
			}
		},
		statusDescription: {
			fontSize: 16,
			lineHeight: 1.4,

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		statusFooter: {
			width: '100%',

			[theme.breakpoints.down('sm')]: {
				paddingBottom: 24
			}
		},
		progressBar: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	})
);

const errorStyle = {
	color: '#EF4F57',
	fontSize: 14,
	fontWeight: 500,
	marginLeft: '16px'
};

export const ClientBulkUpload: FunctionComponent<
	ClientBulkUploadProps
> = props => {
	const classes = useStyles();

	const [step, setStep] = useState<number>(1);
	const [saving] = useState(false);
	const { CSVReader } = useCSVReader();
	const [parsedCsvData, setParsedCsvData] = useState<Array<ClientCsv>>([]);
	const [file, setFile] = useState(false);
	const [errors, setErrors] = useState<Array<FieldError>>([]);
	const [arrowLeft, setArrowLeft] = useState(false);
	const [arrowRight, setArrowRight] = useState(true);
	const elementRef = useRef<HTMLInputElement>(null);
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	const [icon, setIcon] = useState('');
	const [uploading, setUploading] = useState(false);
	const [textButton, setTextButton] = useState('');
	const progress = useSelector<RootState, BulkUploadState>(
		state => state.bulkUpload
	);

	const [scrollPos, setScrollPos] = useState(0);

	const mandatoryListFields = [
		'first_name*',
		'last_name*',
		'phone*',
		'email*',
		'pet_name*',
		'pet_size*',
		'pet_type*'
	];

	const nextHandler = () => {
		const errors = validate();
		setErrors(errors);
		if (errors.length === 0) {
			setStep(prev => prev + 1);
		}
	};

	useEffect(() => {
		if (
			progress.event == 'record_imported' ||
			progress.event == 'import_started'
		) {
			setTitle('Importing your clients...');
			setContent(
				'We are currently importing your clients. Please do not close the window and wait for the process to finish. This may take a few moments.'
			);
			setIcon('/images/clients/uploading.png');
			setTextButton('Cancel');
		} else if (progress.event == 'import_finished') {
			setTitle('Import has been successfully completed');
			const errors = progress.errors
				? `${progress.errors} with errors`
				: '';
			setContent(
				`${progress.successfully_imported} clients have been added to your client list. ${errors}`
			);
			setIcon('/images/clients/uploaded.png');
			setTextButton('Done');
		}
	}, [progress]);

	const saveHandler = () => {
		setUploading(true);
		if (parsedCsvData !== []) {
			uploadFileThunk(parsedCsvData).then(r => {
				if (r == null || r.status != 200) {
					setTitle('Error during clients import');
					setContent(
						'While processing the bulk upload of clients, an error occurred that prevented the successful completion of the operation.'
					);
					setIcon('/images/clients/notUploaded.png');
					setTextButton('Close');
				}
			});
		}
	};

	const validate = (): Array<FieldError> => {
		const errors: Array<FieldError> = [];

		if (!file) {
			errors.push({
				fieldName: 'file',
				errorMessage: 'Please upload CSV file'
			});
		}

		return errors;
	};

	const validateFormat = (): Array<FieldError> => {
		const errors: Array<FieldError> = [];

		errors.push({
			fieldName: 'file',
			errorMessage: 'File must be on CSV format'
		});

		return errors;
	};

	const [page, setPage] = useState(1);
	const [pageClients, setPageClients] = useState<Array<ClientCsv>>(
		parsedCsvData.slice(0, 10)
	);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		setPageClients(parsedCsvData.slice(0, 10));
	}, [parsedCsvData]);

	const changePageSizeHandler = (pageSize: number) => {
		setPageSize(pageSize);
		changePage(parsedCsvData, page, pageSize);
	};

	const changePageHandler = (page: number) => {
		setPage(page);
		changePage(parsedCsvData, page, pageSize);
	};

	const changePage = (
		clients: Array<ClientCsv>,
		page: number,
		pageSize: number
	) => {
		if (pageSize === 0) {
			setPageClients(parsedCsvData);
		} else {
			const startIndex = (page - 1) * pageSize;
			const endIndex = startIndex + pageSize;
			setPageClients(parsedCsvData.slice(startIndex, endIndex));
		}
	};

	const pagination = (
		<Pagination
			className={classes.pagination}
			count={parsedCsvData ? parsedCsvData.length : 0}
			page={page}
			pageSize={pageSize}
			onPageChange={changePageHandler}
			onPageSizeChange={changePageSizeHandler}
		/>
	);

	const handleHorizantalScrollRight = () => {
		if (elementRef.current != null) {
			elementRef.current.scrollLeft += 500;
			if (
				elementRef.current.scrollLeft +
					elementRef.current.offsetWidth +
					10 >=
				elementRef.current.scrollWidth
			) {
				setArrowRight(false);
			} else {
				setArrowRight(true);
			}
			if (elementRef.current.scrollLeft === 0) {
				setArrowLeft(false);
			} else {
				setArrowLeft(true);
			}
		}
	};

	const handleHorizantalScrollLeft = () => {
		if (elementRef.current != null) {
			elementRef.current.scrollLeft -= 500;
			if (
				elementRef.current.scrollLeft +
					elementRef.current.offsetWidth +
					10 >=
				elementRef.current.scrollWidth
			) {
				setArrowRight(false);
			} else {
				setArrowRight(true);
			}
			if (elementRef.current.scrollLeft === 0) {
				setArrowLeft(false);
			} else {
				setArrowLeft(true);
			}
		}
	};

	const exportToCsv = () => {
		const data = [
			{
				'first_name*': 'Jane',
				'last_name*': 'Doe',
				'email*': 'jane@cuddlesapp.com',
				'phone*': '555-555-5555',
				'address': '',
				'city': '',
				'state': '',
				'zipcode': '',
				'pet_name*': 'Paco',
				'pet_type*': 'dogs',
				'pet_size*': 'medium',
				'pet_breed': 'Chow Chow'
			},
			{
				'first_name*': 'Jane',
				'last_name*': 'Doe',
				'email*': 'jane@cuddlesapp.com',
				'phone*': '555-555-5555',
				'address': '',
				'city': '',
				'state': '',
				'zipcode': '',
				'pet_name*': 'Tommy',
				'pet_type*': 'dogs',
				'pet_size*': 'large',
				'pet_breed': 'Doodle'
			},
			{
				'first_name*': 'John',
				'last_name*': 'Q',
				'email*': 'john@cuddlesapp.com',
				'phone*': '123-456-7890',
				'address': '',
				'city': '',
				'state': '',
				'zipcode': '',
				'pet_name*': 'Cupcake',
				'pet_type*': 'dogs',
				'pet_size*': 'small',
				'pet_breed': 'Shiba Inu'
			}
		];

		const fileName = 'Cuddles Import Template-Clients';
		const exportType = exportFromJSON.types.csv;
		exportFromJSON({ data, fileName, exportType });
	};

	// The scroll listener
	const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const div = event.target as HTMLDivElement;

		setScrollPos(div.scrollLeft);
	};

	return (
		<>
			{!uploading ? (
				<WizardPage
					isLoading={saving}
					step={step}
					items={[
						{
							title: 'Upload File'
						},
						{
							title: 'Preview & Import'
						}
					]}
					onSave={saveHandler}
					onNext={nextHandler}
					onBack={() => setStep(prev => prev - 1)}
					closePath=""
					isFooter={true}
					onClickClosePath={props.onClose}
				>
					{step === 1 && (
						<Grid container justifyContent="center">
							<Grid item md={6} xs={12}>
								<Fragment>
									<Box className={classes.borderContainer}>
										<Typography
											className={classes.firstText}
										>
											Import Clients
										</Typography>
										<Typography
											className={classes.secondText}
										>
											For an easy, successful import{' '}
											<span
												style={styles.downloadText}
												onClick={exportToCsv}
											>
												download
											</span>{' '}
											our pre-designed template and fill
											it out.
										</Typography>
										<CSVReader
											config={{
												header: true
											}}
											onUploadAccepted={(
												results: ObjectCsv
											) => {
												setFile(true);
												setErrors([]);
												mandatoryListFields.forEach(
													field => {
														results = JSON.parse(
															JSON.stringify(
																results
															).replaceAll(
																field,
																field.replace(
																	'*',
																	''
																)
															)
														);
													}
												);
												setParsedCsvData(results.data);
											}}
											onDragOver={(event: DragEvent) => {
												event.preventDefault();
											}}
											onDragLeave={(event: DragEvent) => {
												event.preventDefault();
											}}
											onUploadRejected={() => {
												const errors = validateFormat();
												setErrors(errors);
											}}
										>
											{({
												getRootProps,
												acceptedFile,
												ProgressBar,
												getRemoveFileProps
											}: /* eslint-disable  @typescript-eslint/no-explicit-any */
											any) => (
												<>
													<div
														{...getRootProps()}
														style={Object.assign(
															{},
															styles.zone
														)}
													>
														<Img
															className={
																classes.image
															}
															src="/images/clients/fileImport.svg"
														/>
														<Typography
															className={
																classes.thirdText
															}
														>
															Select your CSV file
															or drag and drop
															here
														</Typography>
														<Button
															label={
																'+ Add CSV File'
															}
															className={
																classes.csvButton
															}
														/>
													</div>
													<div
														style={
															styles.progressBar
														}
													>
														<ProgressBar />
													</div>
													{setFile(acceptedFile)}
													{acceptedFile ? (
														<div>
															<Typography
																className={
																	classes.fourthText
																}
															>
																Files Upload
															</Typography>
															<div
																style={
																	styles.fileContainer
																}
															>
																<div
																	style={
																		styles.fileSubcontainer
																	}
																>
																	<div
																		style={
																			styles.iconCenter
																		}
																	>
																		<Img
																			className={
																				classes.checkFile
																			}
																			src="/images/clients/checkFile.svg"
																		/>
																	</div>
																	<div
																		style={
																			styles.iconCenter
																		}
																	>
																		<Img
																			className={
																				classes.iconFile
																			}
																			src="/images/clients/fileIcon.svg"
																		/>
																	</div>
																	<div>
																		<div
																			style={
																				styles.fileName
																			}
																		>
																			{
																				acceptedFile.name
																			}
																		</div>
																		<div
																			style={
																				styles.fileSize
																			}
																		>
																			{formatFileSize(
																				acceptedFile.size
																			)}
																		</div>
																	</div>
																</div>
																<div
																	style={
																		styles.fileSubcontainerDelete
																	}
																	className="delete-button"
																	{...getRemoveFileProps()}
																>
																	<div
																		style={
																			styles.iconCenter
																		}
																	>
																		<FontAwesomeIcon
																			icon={
																				faTrashAlt
																			}
																		/>
																	</div>
																	<div
																		className="delete-label"
																		style={
																			styles.deleteText
																		}
																	>
																		Delete
																	</div>
																</div>
															</div>
														</div>
													) : (
														''
													)}
												</>
											)}
										</CSVReader>
									</Box>

									<div
										style={{
											display: errors.some(
												error =>
													error.fieldName === 'file'
											)
												? 'inline-block'
												: 'none',
											...errorStyle
										}}
										className={classes.errorMessage}
									>
										{
											errors.find(
												error =>
													error.fieldName === 'file'
											)?.errorMessage
										}
									</div>

									<Box
										className={
											classes.borderContainerContact
										}
									>
										<Img
											className={classes.imageContact}
											src="/images/clients/contactIcon.svg"
										/>
										<div>
											<Typography
												className={
													classes.firstHelpText
												}
											>
												Need help?
											</Typography>
											<Typography
												className={
													classes.secondHelpText
												}
											>
												If you need assistance with the
												process of importing your
												contacts, our support team is
												ready to help you.
											</Typography>
											<Typography
												className={
													classes.thirdHelpText
												}
											>
												<a
													style={{
														color: '#222222',
														textDecoration: 'auto'
													}}
													href="mailto:help@cuddlesapp.com"
													target="_blank"
													rel="noreferrer"
												>
													Contact Us
													<FontAwesomeIcon
														icon={faAngleRight}
													/>
												</a>
											</Typography>
										</div>
									</Box>
								</Fragment>
							</Grid>
						</Grid>
					)}

					{step === 2 && (
						<Grid container justifyContent="center">
							<Grid
								item
								md={10}
								xs={12}
								className={classes.tableContainerGrid}
							>
								<Fragment>
									<Typography className={classes.firstText}>
										Client Import Preview
									</Typography>
									<Typography className={classes.secondText}>
										Please make sure that all the data you
										are importing is correct. Remember that{' '}
										<br />
										any data that does not have the correct
										format will not be imported.
									</Typography>
									<Grid
										container
										className={classes.tableContainer}
									>
										{arrowLeft ? (
											<Grid
												item
												className={classes.scrollArrow}
											>
												<Img
													onClick={() => {
														handleHorizantalScrollLeft();
													}}
													className={
														classes.arrowIcon
													}
													src="/images/clients/arrowLeft.svg"
												/>
											</Grid>
										) : (
											''
										)}
										<Grid item xs={12} md={11}>
											<div
												ref={elementRef}
												onScroll={handleScroll}
												className={clsx(
													classes.boxGridContainer,
													{
														scrolled: scrollPos > 0
													}
												)}
											>
												<table
													style={styles.tableStyle}
												>
													<thead>
														<tr
															style={
																styles.trTableHeader
															}
														>
															<th
																style={
																	styles.thTable
																}
															>
																First Name
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																Last Name
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																Email
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																Address
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																City
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																State
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																Zip Code
															</th>
															{/*<th
																style={
																	styles.thTable
																}
															>
																Country
															</th>*/}
															<th
																style={
																	styles.thTable
																}
															>
																Phone
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																PetName
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																Pet Breed
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																PetType
															</th>
															<th
																style={
																	styles.thTable
																}
															>
																PetSize
															</th>
														</tr>
													</thead>
													<tbody>
														{pageClients &&
															pageClients.map(
																(
																	parsedData: ClientCsv,
																	index:
																		| React.Key
																		| null
																		| undefined
																) => (
																	<tr
																		style={
																			styles.trTableContent
																		}
																		key={
																			index
																		}
																	>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.first_name
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.last_name
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.email
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.address
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.city
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.state
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.zipcode
																			}
																		</td>
																		{/*	<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.country
																			}
																		</td>*/}
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.phone
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.pet_name
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.pet_breed
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.pet_type
																			}
																		</td>
																		<td
																			style={
																				styles.tdTable
																			}
																		>
																			{
																				parsedData.pet_size
																			}
																		</td>
																	</tr>
																)
															)}
													</tbody>
												</table>
											</div>
											{pagination}
										</Grid>
										{arrowRight ? (
											<Grid
												item
												className={classes.scrollArrow}
											>
												<Img
													onClick={() => {
														handleHorizantalScrollRight();
													}}
													className={
														classes.arrowIcon
													}
													src="/images/clients/arrowRight.svg"
												/>
											</Grid>
										) : (
											''
										)}
									</Grid>
								</Fragment>
							</Grid>
						</Grid>
					)}
				</WizardPage>
			) : (
				<Grid
					container
					justifyContent="center"
					className={classes.statusPage}
				>
					<Box className={classes.statusBody}>
						<div style={{ justifySelf: 'center' }}>
							<Img className={classes.statusIcon} src={icon} />
						</div>
						<Typography className={classes.statusTitle}>
							{title}
						</Typography>

						{progress.event != 'import_finished' && (
							<ProgressBar
								percentage={progress.percentage}
								className={classes.progressBar}
							/>
						)}

						<Typography className={classes.statusDescription}>
							{content}
						</Typography>
					</Box>
					<Box className={classes.statusFooter}>
						<Button
							className={classes.button}
							color="black"
							label={textButton}
							onClick={props.onClose}
						/>
					</Box>
				</Grid>
			)}
		</>
	);
};

export default ClientBulkUpload;
