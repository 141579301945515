import {
	MENU_CLOSE_ACTION,
	MENU_OPEN_ACTION,
	MENU_SET_DROPDOWN_ACTION,
	MenuAction
} from './MenuActionsTypes';

export const setMenuDropdown = (dropdown: string): MenuAction => {
	return {
		type: MENU_SET_DROPDOWN_ACTION,
		payload: {
			dropdown
		}
	};
};

export const openMenu = (): MenuAction => {
	return {
		type: MENU_OPEN_ACTION
	};
};

export const closeMenu = (): MenuAction => {
	return {
		type: MENU_CLOSE_ACTION
	};
};
