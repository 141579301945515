import React, {
    FunctionComponent,
    useState,
    useEffect,
    Fragment,
    useRef
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import usePreviousLocation from 'hooks/usePreviousLocation';
import { RootState } from 'store';
import { EnrollmentStatus } from '@spike/payments-model';
import {
    getEnrollmentStatusThunk,
    getHopLinkThunk
} from '@spike/payments-action';
import useNonInitialEffect from '@versiondos/hooks';
import { useApiClientWrapper, useAuth } from 'hooks';
import { showError } from '@spike/notifications-action';
import PrivatePage from './PrivatePage';
import PageSidebar, { PageSidebarItem } from 'components/PageSidebar';
import {
    Dashboard,
    Transactions,
    Transfers,
    SalesReports,
    Introduction,
    SingleSale,
    Terminals,
    BankAccounts
} from 'components/Payments';
import { Spinner } from 'components/UI';
import { PaymentsStatus } from '@spike/payments-action';
import {
    faCircleDollar,
    faChartPie,
    faTableColumns,
    faArrowRightArrowLeft,
    faPlus,
    faHandHoldingDollar,
    faMoneyCheckDollarPen,
    faGear,
    faBuildingColumns
} from '@fortawesome/pro-light-svg-icons';
import TipsDetail from 'components/Payments/TipsDetail';
import CommissionDetail from 'components/Payments/CommissionDetail/CommissionDetail';
import TerminalIcon from 'components/Payments/Terminals/UI/TerminalIcon';
import { ApplicationStatus } from 'components/Payments/Enrollment/Status/ApplicationStatus';
import CuddlespayStatus from 'components/Payments/Enrollment/Status/CuddlespayStatus';
import { ShowTypes } from 'components/Payments/Sidebar/ShowTypes';
import { OWNER_ID } from '@spike/model';
import RestictedDialog from 'components/Payments/ui/RestictedDialog';
import { isNewAccount } from 'utils/PaymentsUtils';

const enabledNewSale: boolean =
    process.env.REACT_APP_SINGLE_PAYMENT_ENABLED?.toLowerCase() === 'true';

const allPageSidebarItems: Array<PageSidebarItem> = [
    { icon: faTableColumns, title: 'Dashboard', key: ShowTypes.Dashboard },
    { icon: faPlus, title: 'New Sale', key: ShowTypes.SingleSale },
    {
        icon: faCircleDollar,
        title: 'Transactions',
        key: ShowTypes.Transactions
    },
    {
        icon: faChartPie,
        title: 'Sales Reports',
        key: ShowTypes.SalesReports
    },
    {
        icon: faHandHoldingDollar,
        title: 'Tips Detail',
        key: ShowTypes.TipsDetail
    },
    {
        icon: faMoneyCheckDollarPen,
        title: 'Commissions',
        key: ShowTypes.Commissions
    },
    {
        icon: faBuildingColumns,
        title: 'Bank Accounts',
        key: ShowTypes.BankAccounts
    },

    {
        iconImage: <TerminalIcon />,
        title: 'Terminals',
        key: ShowTypes.Terminals
    },
    {
        icon: faArrowRightArrowLeft,
        title: 'Payouts',
        key: ShowTypes.Transfers
    },
    {
        icon: faGear,
        title: 'Setup',
        key: ShowTypes.Setup
    }
];

export const Payments: FunctionComponent = () => {
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const location = useLocation();
    const prevLocation = usePreviousLocation();
    const [openRestrictedDialog, setOpenRestrictedDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [polling, setPolling] = useState(false);
    const [show, setShow] = useState<string>(ShowTypes.Introduction);
    const fetchInitiated = useRef(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const auth = useAuth();
    const userIsOwner = auth.user!.role.id === OWNER_ID;
    const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const paymentsEnrollmentStatus = useSelector<
        RootState,
        EnrollmentStatus | undefined
    >(state => state.payments.enrollmentStatus);

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const hopLink = useSelector<RootState, string | undefined>(
        state => state.payments.hopLink
    );

    // Function to fetch or refresh the hopLink
    const fetchHopLink = async (): Promise<void> => {
        await dispatch(getHopLinkThunk(apiClientWrapper));
        fetchInitiated.current = true; // Set that fetching has been initiated
    };

    const handleHopLinkTrigger = async (startPolling: boolean) => {
        setIsButtonDisabled(true);
        if (!userIsOwner) {
            setOpenRestrictedDialog(true);
        } else {
            if (startPolling) {
                setPolling(true);
            }
            await fetchHopLink(); // This sets fetchInitiated to true
        }
    };

    const handleCloseRestrictedDialog = () => {
        setOpenRestrictedDialog(false);
    };

    const applicationStatusMap = {
        [ShowTypes.ApplicationProcessing]: ApplicationStatus.Processing,
        [ShowTypes.ReviewApplication]: ApplicationStatus.Review,
        [ShowTypes.Setup]: ApplicationStatus.Setup
    };

    const filteredPageSidebarItems = allPageSidebarItems.filter(item => {
        if (item.key === ShowTypes.SingleSale) {
            return enabledNewSale;
        }
        if (
            item.key === ShowTypes.Setup &&
            (!isNewAccount(paymentsEnrollmentStatus) || !userIsOwner)
        ) {
            return false;
        }

        if (
            item.key === ShowTypes.BankAccounts &&
            (!isNewAccount(paymentsEnrollmentStatus) || !userIsOwner)
        ) {
            return false;
        }
        return true;
    });

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        let shouldSetShowType = false;
        let showType: ShowTypes = ShowTypes.Dashboard;

        const showTypeKey = Object.keys(ShowTypes).find(
            key => ShowTypes[key as keyof typeof ShowTypes] === hash
        );

        if (showTypeKey) {
            showType = ShowTypes[showTypeKey as keyof typeof ShowTypes];
            shouldSetShowType = true;
        } else if (prevLocation !== '/payments') {
            shouldSetShowType = false;
        }

        if (shouldSetShowType) {
            setShow(showType);
            setLoading(false);
        }
    }, [location]);

    useEffect(() => {
        dispatch(getEnrollmentStatusThunk(apiClientWrapper));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useNonInitialEffect(() => {
        if (![ShowTypes.SingleSale].includes(show as ShowTypes)) {
            if (PaymentsStatus.GetEnrollmentStatusSuccess === paymentsStatus) {
                if (!paymentsEnrollmentStatus) {
                    dispatch(showError('Error fetching status.'));
                    return;
                }

                setLoading(false);

                const showType = isNewAccount(paymentsEnrollmentStatus)
                    ? handleVerificationStatus(
                          paymentsEnrollmentStatus.verificationStatus
                      )
                    : handleLegacyVerification(
                          paymentsEnrollmentStatus.enabled
                      );

                setShow(showType);
            }
        }
    }, [paymentsStatus, paymentsEnrollmentStatus]);

    useEffect(() => {
        if (fetchInitiated.current && hopLink) {
            if (isIOS) {
                window.location.href = hopLink; // Open in the same tab for iOS
            } else {
                window.open(hopLink); // Open in a new tab for other devices
            }
            fetchInitiated.current = false;
            setIsButtonDisabled(false);
        }
    }, [hopLink]);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (polling) {
            interval = setInterval(() => {
                dispatch(getEnrollmentStatusThunk(apiClientWrapper));
            }, 10000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [polling]);

    useEffect(() => {
        if (paymentsEnrollmentStatus?.verificationStatus === 'valid') {
            setPolling(false);
        }
    }, [paymentsEnrollmentStatus?.verificationStatus]);

    const handleVerificationStatus = (status: string | null | undefined) => {
        switch (status) {
            case 'valid':
                return location.hash.replace('#', '') || ShowTypes.Dashboard;
            case 'pending':
                return ShowTypes.ApplicationProcessing;
            case 'invalid':
                return ShowTypes.ReviewApplication;
            case 'new':
                return ShowTypes.Introduction;
            default:
                return ShowTypes.Introduction;
        }
    };

    const handleLegacyVerification = (enabled: boolean) => {
        return enabled
            ? location.hash.replace('#', '') || ShowTypes.Dashboard
            : ShowTypes.Introduction;
    };

    const renderContentPage = (
        component: React.ReactNode,
        pageTitle: string,
        includeSidebar = false
    ) => {
        const sidebar = includeSidebar ? (
            <PageSidebar
                title="Payments"
                items={filteredPageSidebarItems}
                selectedKey={show}
                onSelect={(key: string) => setShow(key as ShowTypes)}
            />
        ) : undefined;

        return (
            <PrivatePage
                title={pageTitle}
                pageSidebar={sidebar}
                pageName="/payments"
            >
                {component}
            </PrivatePage>
        );
    };

    return (
        <Fragment>
            {loading &&
                renderContentPage(<Spinner />, 'Payments - Cuddles', false)}
            {!loading &&
                show === ShowTypes.Introduction &&
                renderContentPage(
                    <Introduction
                        onAddCuddlesPay={() => handleHopLinkTrigger(true)}
                        isButtonDisabled={isButtonDisabled}
                    />,
                    'Payments - Cuddles',
                    false
                )}
            {!loading &&
                show === ShowTypes.ApplicationProcessing &&
                renderContentPage(
                    <CuddlespayStatus status={applicationStatusMap[show]} />,
                    'Payments - Cuddles',
                    false
                )}
            {!loading &&
                show === ShowTypes.ReviewApplication &&
                renderContentPage(
                    <CuddlespayStatus
                        status={applicationStatusMap[show]}
                        onButtonClick={() => handleHopLinkTrigger(true)}
                    />,
                    'Payments - Cuddles',
                    false
                )}
            {!loading &&
                show === ShowTypes.Dashboard &&
                renderContentPage(
                    <Dashboard
                        onGotoTransactions={() =>
                            setShow(ShowTypes.Transactions)
                        }
                    />,
                    'Payments - Cuddles',
                    true
                )}
            {!loading &&
                show === ShowTypes.SingleSale &&
                renderContentPage(<SingleSale />, 'Payments - Cuddles', true)}
            {!loading &&
                show === ShowTypes.Transactions &&
                renderContentPage(<Transactions />, 'Payments - Cuddles', true)}

            {!loading &&
                show === ShowTypes.BankAccounts &&
                renderContentPage(<BankAccounts />, 'Payments - Cuddles', true)}

            {!loading &&
                show === ShowTypes.Terminals &&
                renderContentPage(<Terminals />, 'Payments - Cuddles', true)}
            {!loading &&
                show === ShowTypes.Transfers &&
                renderContentPage(<Transfers />, 'Payments - Cuddles', true)}
            {!loading &&
                show === ShowTypes.SalesReports &&
                renderContentPage(<SalesReports />, 'Payments - Cuddles', true)}
            {!loading &&
                show === ShowTypes.TipsDetail &&
                renderContentPage(<TipsDetail />, 'Payments - Cuddles', true)}
            {!loading &&
                show === ShowTypes.Commissions &&
                renderContentPage(
                    <CommissionDetail />,
                    'Payments - Cuddles',
                    true
                )}
            {!loading &&
                show === ShowTypes.Setup &&
                isNewAccount(paymentsEnrollmentStatus) &&
                renderContentPage(
                    <CuddlespayStatus
                        onButtonClick={() => handleHopLinkTrigger(false)}
                        status={applicationStatusMap[show]}
                    />,
                    'Payments - Cuddles',
                    true
                )}
            {openRestrictedDialog && (
                <RestictedDialog
                    open={openRestrictedDialog}
                    title="Access Restricted"
                    question="Your current role does not permit access to this section. Please contact the owner if you need assistance or require access."
                    handleClose={handleCloseRestrictedDialog}
                    cancelButtonLabel="Done"
                />
            )}
        </Fragment>
    );
};

export default Payments;
