import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import {
    deleteThunk,
    saveThunk,
    downloadAgreementPDFThunk,
    printAgreementThunk
} from 'actions/agreements/AgreementsActions';
import { ConfirmDialog, ToolbarButton, ToolbarItem } from 'components/UI';
import cloneDeep from 'lodash/cloneDeep';
import { Agreement } from 'model/Agreements';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
import AgreementsEditModal from './AgreementsEditModal';
import AgreementPreviewModal from './AgreementPreviewModal';
import Dropdown from './ui/Dropdown';
import OptionsModal from './Modal/OptionsModal';
import { modalOptions } from './Modal/model';
import { dropdownOptions } from './ui/Dropdown/model';
import SendModal from './Send/SendModal';

interface AgreementsOptionsProps {
    agreement: Agreement;
    onDeleted?: () => void;
}

export enum OptionTypes {
    Activate = 'activate',
    Deactivate = 'deactivate',
    Send = 'send',
    Download = 'download',
    Print = 'print',
    Edit = 'edit',
    Duplicate = 'duplicate',
    Delete = 'delete',
    Preview = 'preview'
}

const AgreementsOptions: FunctionComponent<AgreementsOptionsProps> = props => {
    const dispatch = useDispatch();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const options = isMobile ? modalOptions : dropdownOptions;

    const agreementsStatus = useSelector<RootState, AgreementsStatus>(
        state => state.agreements.status
    );

    const filteredOptions = options
        .filter(option =>
            props.agreement.active
                ? option.id !== OptionTypes.Activate
                : option.id !== OptionTypes.Deactivate
        )
        .filter(option =>
            props.agreement.active ? true : option.id !== OptionTypes.Send
        );

    const [selectedOptionId, setSelectedOptionId] = useState<string | null>(
        null
    );
    const [processing, setProcessing] = useState(false);
    const [duplicatedAgreement, setDuplicatedAgreement] =
        useState<Agreement | null>(null);

    useNonInitialEffect(() => {
        switch (agreementsStatus) {
            case AgreementsStatus.DeleteSuccess:
                setSelectedOptionId(null);
                setProcessing(false);
                props.onDeleted && props.onDeleted();
                break;
            case AgreementsStatus.SaveSuccess:
                setSelectedOptionId(null);
                setProcessing(false);
                break;
            case AgreementsStatus.Error:
                setProcessing(false);
                break;
        }
    }, [agreementsStatus]);

    useNonInitialEffect(() => {
        if (selectedOptionId === OptionTypes.Duplicate) {
            setDuplicatedAgreement({
                ...cloneDeep(props.agreement),
                id: undefined,
                uuid: uuid(),
                title: `${props.agreement.title} (duplicated)`
            });
        } else if (selectedOptionId === OptionTypes.Download) {
            dispatch(downloadAgreementPDFThunk(props.agreement.id!));
        } else if (selectedOptionId === OptionTypes.Print) {
            dispatch(printAgreementThunk(props.agreement.id!));
        }
    }, [selectedOptionId]);

    const deleteHandler = () => {
        setProcessing(true);
        dispatch(deleteThunk(props.agreement.id!));
    };

    const changeActiveHandler = (active: boolean) => {
        setProcessing(true);
        dispatch(saveThunk({ ...props.agreement, active }));
    };

    return (
        <>
            {isMobile ? (
                <OptionsModal
                    options={filteredOptions}
                    onClick={setSelectedOptionId}
                />
            ) : (
                <ToolbarButton id="agreements_options">
                    {filteredOptions.map(option => (
                        <ToolbarItem
                            key={option.id}
                            id={option.id}
                            icon={option.icon}
                            text={option.title}
                            isDisabled={option.disabled}
                            onClick={() => setSelectedOptionId(option.id)}
                        />
                    ))}
                </ToolbarButton>
            )}
            {OptionTypes.Delete === selectedOptionId && (
                <ConfirmDialog
                    open={true}
                    title={<Typography>{props.agreement.title}</Typography>}
                    question={
                        <Typography>
                            Do you want to <strong> delete</strong> this
                            <br /> Agreement ?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    onCancel={() => setSelectedOptionId(null)}
                    onConfirm={() => {
                        deleteHandler();
                    }}
                    processing={processing}
                />
            )}

            {OptionTypes.Deactivate === selectedOptionId && (
                <ConfirmDialog
                    open={true}
                    title={<Typography>{props.agreement.title}</Typography>}
                    question={
                        <Typography>
                            Do you want to <strong>deactivate</strong> this
                            <br /> Agreement?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Deactivate"
                    onCancel={() => setSelectedOptionId(null)}
                    onConfirm={() => {
                        changeActiveHandler(false);
                    }}
                    processing={processing}
                />
            )}

            {OptionTypes.Activate === selectedOptionId && (
                <ConfirmDialog
                    open={true}
                    title={<Typography>{props.agreement.title}</Typography>}
                    question={
                        <Typography>
                            Do you want to <strong>activate</strong> this
                            <br /> Agreement?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Activate"
                    onCancel={() => setSelectedOptionId(null)}
                    onConfirm={() => {
                        changeActiveHandler(true);
                    }}
                    processing={processing}
                />
            )}
            {OptionTypes.Send === selectedOptionId && (
                <SendModal
                    onClose={() => setSelectedOptionId(null)}
                    isActive={OptionTypes.Send === selectedOptionId}
                    agreementId={props.agreement.id!}
                />
            )}
            {props.agreement && OptionTypes.Edit === selectedOptionId && (
                <AgreementsEditModal
                    agreement={props.agreement}
                    onClose={() => setSelectedOptionId(null)}
                    isActive={OptionTypes.Edit === selectedOptionId}
                />
            )}
            <AgreementPreviewModal
                agreement={props.agreement}
                onClose={() => setSelectedOptionId(null)}
                isActive={OptionTypes.Preview === selectedOptionId}
            />
            {duplicatedAgreement &&
                OptionTypes.Duplicate === selectedOptionId && (
                    <AgreementsEditModal
                        isActive={
                            duplicatedAgreement !== null &&
                            OptionTypes.Duplicate === selectedOptionId
                        }
                        agreement={duplicatedAgreement}
                        onClose={() => {
                            setSelectedOptionId(null);
                            setDuplicatedAgreement(null);
                        }}
                    />
                )}
        </>
    );
};

export default AgreementsOptions;
