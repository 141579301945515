import { NewFeature } from "model/NewFeature";
import { Action } from "redux";

//Types
export const NEW_FEATURES_RESET_ACTION_TYPE = "new_features/reset";
export const NEW_FEATURES_FETCH_START_ACTION_TYPE = "new_features/fetch_start";
export const NEW_FEATURES_FETCH_SUCCESS_ACTION_TYPE = "new_features/fetch_success";
export const NEW_FEATURES_MARK_AS_READ_START_ACTION_TYPE = "new_features/mark_as_read_start";
export const NEW_FEATURES_MARK_AS_READ_SUCCESS_ACTION_TYPE = "new_features/mark_as_read_success";
export const NEW_FEATURES_ERROR_ACTION_TYPE = "new_features/ferror";

//Actions
interface NewFeaturesResetAction extends Action<typeof NEW_FEATURES_RESET_ACTION_TYPE> {}

interface NewFeaturesFetchStartAction extends Action<typeof NEW_FEATURES_FETCH_START_ACTION_TYPE> {}

interface NewFeaturesFetchSuccessAction extends Action<typeof NEW_FEATURES_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    newFeatures: Array<NewFeature>;
  }
}

interface NewFeaturesMarkAsReadStartAction extends Action<typeof NEW_FEATURES_MARK_AS_READ_START_ACTION_TYPE> {}

interface NewFeaturesMarkAsReadSuccessAction extends Action<typeof NEW_FEATURES_MARK_AS_READ_SUCCESS_ACTION_TYPE> {
  payload: {
    readFeatureIds: Array<number>;
  }
}

interface NewFeaturesErrorAction extends Action<typeof NEW_FEATURES_ERROR_ACTION_TYPE> {}


//NewFeatureAction
export type NewFeaturesAction =
  | NewFeaturesResetAction
  | NewFeaturesFetchStartAction
  | NewFeaturesFetchSuccessAction
  | NewFeaturesMarkAsReadStartAction
  | NewFeaturesMarkAsReadSuccessAction
  | NewFeaturesErrorAction;
