import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { SortFields as SortFieldsProducts, SortOrder as SortOrderProducts } from "@spike/product-model";
import { SortFields as SortFieldsVendors, SortOrder as SortOrderVendors } from "model/ProductVendor";
import { SortFields as SortFieldsCategories, SortOrder as SortOrderCategories } from "model/ProductCategory";
import TableHeaderProductItem from "./TableHeaderProductItem";
import TableHeaderCategoryItem from "./TableHeaderCategoryItem";
import TableHeaderVendorItem from "./TableHeaderVendorItem";

interface Props {
  selectedView: string;
  onSortProducts: (field: SortFieldsProducts | undefined, order: SortOrderProducts) => void;
  onSortVendors: (field: SortFieldsVendors | undefined, order: SortOrderVendors) => void;
  onSortCategories: (field: SortFieldsCategories | undefined, order: SortOrderCategories) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      fontWeight: 600,
      borderBottom: "1px solid #222222",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "16px",
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "20px",
        fontSize: "16px",
      },
    },
    firstCell: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "84px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "105px",
      },
    },
    lastCell: {
      [theme.breakpoints.down("md")]: {
        paddingRight: "63px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "79px",
      },
    },
    imageCell: {
      width: "8%"
    },
    productsCells: {
      width: "12%"
    },
    vendorsNameCells: {
      width: "20%"
    },
    vendorsCells: {
      width: "12%"
    },
    vendorsEmailCells: {
      width: "17%"
    },
    vendorsStatusCells: {
      width: "7%"
    },
    vendorsSmallCells: {
      width: "10%"
    },
    categoriesNameCells: {
      width: "35%"
    },
    categoriesCells: {
      width: "25%"
    },
    categoriesSmallCells: {
      width: "15%"
    },
    categoriesActionsCells: {
      width: "10%"
    },
    row: {
      borderBottom: "1px solid #222222",
    },
    stock: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "-9%"
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "-5%"
      },
    },
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [sortFieldProducts, setSortFieldProducts] = useState<SortFieldsProducts | undefined>(undefined);
  const [sortOrderProducts, setSortOrderProducts] = useState<SortOrderProducts | undefined>(undefined);

  const [sortFieldVendors, setSortFieldVendors] = useState<SortFieldsVendors | undefined>(undefined);
  const [sortOrderVendors, setSortOrderVendors] = useState<SortOrderVendors | undefined>(undefined);

  const [sortFieldCategories, setSortFieldCategories] = useState<SortFieldsCategories | undefined>(undefined);
  const [sortOrderCategories, setSortOrderCategories] = useState<SortOrderCategories | undefined>(undefined);

  useEffect(() => {
    setSortFieldProducts(undefined);
    setSortOrderProducts(undefined);
    setSortFieldVendors(undefined);
    setSortOrderVendors(undefined);
    setSortFieldCategories(undefined);
    setSortOrderCategories(undefined);
  }, [props.selectedView])

  const sortHandlerProducts = (field: SortFieldsProducts | undefined, order: SortOrderProducts) => {
    setSortFieldProducts(field);
    setSortOrderProducts(order);
    props.onSortProducts(field, order);
  };

  const sortHandlerVendors = (field: SortFieldsVendors | undefined, order: SortOrderVendors) => {
    setSortFieldVendors(field);
    setSortOrderVendors(order);
    props.onSortVendors(field, order);
  };

  const sortHandlerCategories = (field: SortFieldsCategories | undefined, order: SortOrderCategories) => {
    setSortFieldCategories(field);
    setSortOrderCategories(order);
    props.onSortCategories(field, order);
  };

  const tableProducts = (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell,classes.imageCell)}></TableCell>
      <TableCell className={clsx(classes.cell)}>
        <TableHeaderProductItem
          label="Product Name"
          sortField={SortFieldsProducts.PRODUCT_NAME}
          sortOrder={sortFieldProducts === SortFieldsProducts.PRODUCT_NAME ? sortOrderProducts : undefined}
          onSort={sortHandlerProducts}
        />
      </TableCell>
      
      <TableCell className={clsx(classes.cell,classes.productsCells)}>
        <TableHeaderProductItem
          label="Brand"
          sortField={SortFieldsProducts.BRAND}
          sortOrder={sortFieldProducts === SortFieldsProducts.BRAND ? sortOrderProducts : undefined}
          onSort={sortHandlerProducts}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.productsCells)}>
        <TableHeaderProductItem
          label="Manufacturer"
          sortField={SortFieldsProducts.MANUFACTURER}
          sortOrder={sortFieldProducts === SortFieldsProducts.MANUFACTURER ? sortOrderProducts : undefined}
          onSort={sortHandlerProducts}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.productsCells)}>
        <TableHeaderProductItem
          label="Category"
          sortField={SortFieldsProducts.CATEGORY}
          sortOrder={sortFieldProducts === SortFieldsProducts.CATEGORY ? sortOrderProducts : undefined}
          onSort={sortHandlerProducts}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.productsCells)}>
        <TableHeaderProductItem
          label="Vendor"
          sortField={SortFieldsProducts.VENDOR}
          sortOrder={sortFieldProducts === SortFieldsProducts.VENDOR ? sortOrderProducts : undefined}
          onSort={sortHandlerProducts}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.productsCells)}>Retail ($)</TableCell>
      <TableCell className={clsx(classes.cell, classes.productsCells)}></TableCell>
    </TableRow>
  )

  const tableVendors = (
    <TableRow>
      <TableCell className={clsx(classes.cell,classes.firstCell,classes.vendorsNameCells)}>
        <TableHeaderVendorItem
          label="Vendor Name"
          sortField={SortFieldsVendors.VENDOR_NAME}
          sortOrder={sortFieldVendors === SortFieldsVendors.VENDOR_NAME ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsCells)}>
        <TableHeaderVendorItem
          label="Contact"
          sortField={SortFieldsVendors.CONTACT}
          sortOrder={sortFieldVendors === SortFieldsVendors.CONTACT ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsCells)}>
        <TableHeaderVendorItem
          label="Phone"
          sortField={SortFieldsVendors.PHONE}
          sortOrder={sortFieldVendors === SortFieldsVendors.PHONE ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsEmailCells)}>
        <TableHeaderVendorItem
          label="Email"
          sortField={SortFieldsVendors.EMAIL}
          sortOrder={sortFieldVendors === SortFieldsVendors.EMAIL ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsEmailCells)}>
        <TableHeaderVendorItem
          label="Website"
          sortField={SortFieldsVendors.WEBSITE}
          sortOrder={sortFieldVendors === SortFieldsVendors.WEBSITE ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsStatusCells)}>
        <TableHeaderVendorItem
          label="State"
          sortField={SortFieldsVendors.STATE}
          sortOrder={sortFieldVendors === SortFieldsVendors.STATE ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsStatusCells)}>
        <TableHeaderVendorItem
          label="Products"
          sortField={SortFieldsVendors.PRODUCTS}
          sortOrder={sortFieldVendors === SortFieldsVendors.PRODUCTS ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.vendorsStatusCells)}>
        <TableHeaderVendorItem
          label="Status"
          sortField={SortFieldsVendors.STATUS}
          sortOrder={sortFieldVendors === SortFieldsVendors.STATUS ? sortOrderVendors : undefined}
          onSort={sortHandlerVendors}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.lastCell,classes.vendorsSmallCells)}></TableCell>
    </TableRow>
  )

  const tableCategories = (
    <TableRow>
      <TableCell className={clsx(classes.cell,classes.firstCell,classes.categoriesNameCells)}>
        <TableHeaderCategoryItem
          label="Category"
          sortField={SortFieldsCategories.CATEGORY}
          sortOrder={sortFieldCategories === SortFieldsCategories.CATEGORY ? sortOrderCategories : undefined}
          onSort={sortHandlerCategories}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.categoriesSmallCells)}>
        <TableHeaderCategoryItem
          label="Products"
          sortField={SortFieldsCategories.PRODUCTS}
          sortOrder={sortFieldCategories === SortFieldsCategories.PRODUCTS ? sortOrderCategories : undefined}
          onSort={sortHandlerCategories}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.categoriesSmallCells)}>Bestsellers</TableCell>
      <TableCell className={clsx(classes.cell,classes.categoriesSmallCells)}>To-do</TableCell>
      <TableCell className={clsx(classes.cell,classes.categoriesSmallCells)}>
        <TableHeaderCategoryItem
          label="Status"
          sortField={SortFieldsCategories.STATUS}
          sortOrder={sortFieldCategories === SortFieldsCategories.STATUS ? sortOrderCategories : undefined}
          onSort={sortHandlerCategories}
        />
      </TableCell>
      <TableCell className={clsx(classes.cell,classes.lastCell,classes.categoriesSmallCells)}></TableCell>
    </TableRow>
  )

  return (
    <TableHead>
      {props.selectedView === "products" && tableProducts}
      {props.selectedView === "vendors" && tableVendors}
      {props.selectedView === "categories" && tableCategories}
    </TableHead>

  );
};

export default TableHeader;
