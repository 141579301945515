import { faBellConcierge, faHeartbeat } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { Button, PetImage } from 'components/UI';
import { Pet } from '@spike/pet-model';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { HeaderItems } from './HeaderItems';
import { getAge } from 'utils/DateUtils';
import { isEmpty } from 'lodash';
import { PetTypeIds } from '@spike/model';
import clsx from 'clsx';
import MobileButtonActions from 'components/Client/MobileButtonActions';

interface HeaderProps {
    pet: Pet;
    views: Array<Option<string>>;
    selectedViewId?: string;
    hideHeadingComponent?: boolean;
    onShow?: (view: string) => void;
    onBook?: () => void;
    onBack?: () => void;
    onSelect?: (key: string) => void;
    onClikedActions?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            backgroundColor: '#E9F0ED',
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(137)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '137px'
            }
        },
        infoContainer: {
            backgroundColor: '#E9F0ED',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(42)}px`,
                paddingBottom: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '42px',
                paddingBottom: '22px'
            }
        },
        name: {
            fontFamily: 'YoungSerif',
            fontWeight: 600,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(30)}px`,
                lineHeight: `${reduceResolution(44)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '30px',
                lineHeight: '44px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '24px',
                lineHeight: '26px'
            }
        },
        description: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(17)}px`,
                lineHeight: `${reduceResolution(27)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '17px',
                lineHeight: '27px'
            }
        },
        heartIcon: {
            color: '#EF4F57',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(21)}px`,
                marginLeft: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '21px',
                marginLeft: '10px'
            }
        },
        deceasedIcon: {
            marginLeft: 4,
            paddingTop: 3
        },
        image: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(161)}px`,
                marginTop: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '161px',
                marginTop: '22px'
            },
            [theme.breakpoints.down('md')]: {
                width: 'auto',
                marginTop: '0px',
                marginRight: '10px'
            },
            '&>div>div:first-child': {
                border: '3px solid white',
                [theme.breakpoints.down(wbp)]: {
                    width: `${reduceResolution(161)}px`,
                    height: `${reduceResolution(161)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    width: '161px',
                    height: '161px'
                },
                [theme.breakpoints.down('md')]: {
                    width: '75px',
                    height: '75px',
                    border: 'none'
                }
            }
        },
        items: {
            backgroundColor: 'white',
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(46)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '46px'
            },
            [theme.breakpoints.down('md')]: {
                'justifyContent': 'center',
                'height': 'auto',
                'overflowX': 'auto',
                'scrollbarWidth': 'none',
                '-ms-overflow-style': 'none',
                '&::-webkit-scrollbar': {
                    height: 0,
                    width: 0
                }
            }
        },
        itemsDesktop: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        headerMobile: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                padding: '15px',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '100%',
                marginBottom: '5px'
            }
        },
        title: {
            fontSize: '20px',
            lineHeight: '22px',
            fontWeight: 600
        },
        iconBack: {
            display: 'inline-block',
            fontSize: '20px',
            fontWeight: 300,
            marginRight: '8px',
            cursor: 'pointer'
        },
        boxHeaderMobile: {
            width: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        boxRightHeaderMobile: {
            justifyContent: 'flex-end'
        },
        bookBtn: {
            height: '35px',
            minWidth: '85px'
        },
        wrapperHeaderContainerPet: {
            [theme.breakpoints.down('md')]: {
                paddingLeft: '15px',
                paddingRight: '15px',
                marginBottom: '10px'
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 0
            }
        },
        headerContainerPet: {
            [theme.breakpoints.down('md')]: {
                'justifyContent': 'flex-start',
                'borderRadius': '12px',
                'height': 'auto',
                'padding': '15px 15px',
                '& .imageWrapper': {
                    height: 'auto',
                    width: 'auto'
                }
            }
        },
        boxMobile: {
            display: 'none !important',
            [theme.breakpoints.down('md')]: {
                display: 'flex !important'
            }
        },
        boxHeaderPetInfo: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        containerHeader: {
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                backgroundColor: '#fff',
                paddingTop: 20
            }
        },
        headerContainerDesktop: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        headerContainerCat: {
            [theme.breakpoints.down('md')]: {
                backgroundColor: '#FAEFDF'
            }
        },
        headerContainerDog: {
            [theme.breakpoints.down('md')]: {
                backgroundColor: '#E9F0ED'
            }
        },
        actionsDropdownButton: {
            all: 'unset',
            width: 35,
            height: 35,
            borderRadius: '50%',
            textAlign: 'center',
            backgroundColor: '#F1F1F1'
        }
    })
);

export const Header: FunctionComponent<HeaderProps> = props => {
    const classes = useStyles();
    const firstSeparator =
        props.pet.breed && (props.pet.birthdate || props.pet.age) ? ' • ' : '';
    const secondSeparator =
        props.pet.gender && (props.pet.breed || props.pet.birthdate)
            ? ' • '
            : '';
    const petAge =
        props.pet.age?.name ||
        (props.pet.birthdate ? `${getAge(props.pet.birthdate)} years` : '');

    return (
        <>
            <Grid container xs={12} className={classes.containerHeader}>
                {!props.hideHeadingComponent && (
                    <Grid xs={12} md={3} className={classes.headerMobile}>
                        <Box className={classes.boxHeaderMobile}>
                            <FontAwesomeIcon
                                className={classes.iconBack}
                                icon={faArrowLeft}
                                size="1x"
                                onClick={props.onBack}
                            />
                            <Typography className={classes.title}>
                                Pet
                            </Typography>
                        </Box>
                        <Box
                            className={clsx(
                                classes.boxHeaderMobile,
                                classes.boxRightHeaderMobile
                            )}
                        >
                            {props.onBook && props.pet.active && (
                                <Button
                                    color="green"
                                    label="Book"
                                    className={classes.bookBtn}
                                    onClick={props.onBook}
                                />
                            )}
                            {props.onClikedActions && (
                                <MobileButtonActions
                                    onClikedActions={
                                        props.onClikedActions &&
                                        props.onClikedActions
                                    }
                                />
                            )}
                        </Box>
                    </Grid>
                )}
                <Grid
                    xs={12}
                    lg={3}
                    md={12}
                    className={classes.wrapperHeaderContainerPet}
                >
                    <Grid
                        container
                        xs={12}
                        className={clsx(
                            classes.headerContainer,
                            classes.headerContainerPet,
                            {
                                [classes.headerContainerCat]:
                                    props.pet &&
                                    !isEmpty(props.pet.type) &&
                                    props.pet.type?.id === PetTypeIds.CATS
                            },
                            {
                                [classes.headerContainerDog]:
                                    props.pet &&
                                    !isEmpty(props.pet.type) &&
                                    props.pet.type?.id === PetTypeIds.DOGS
                            }
                        )}
                        justifyContent="center"
                    >
                        <PetImage
                            pet={props.pet}
                            onChangePet={pet => {}}
                            className={classes.image}
                        />
                        <Box
                            className={clsx(
                                classes.boxMobile,
                                classes.boxHeaderPetInfo
                            )}
                        >
                            <Box
                                display={'flex'}
                                justifyContent="center"
                                alignItems={'center'}
                            >
                                <Typography className={classes.name}>
                                    {props.pet.name}
                                </Typography>
                                {props.pet.hasMedicalConditions && (
                                    <FontAwesomeIcon
                                        icon={faHeartbeat}
                                        className={classes.heartIcon}
                                    />
                                )}
                                <Box className={classes.deceasedIcon}>
                                    {props.pet.deceased && '🌈'}
                                </Box>
                            </Box>
                            <Box>
                                <Typography>
                                    {props.pet.breed?.name}
                                    {firstSeparator}
                                    {petAge}
                                </Typography>
                                <Typography>
                                    {props.pet.gender?.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        className={clsx(classes.items, classes.itemsDesktop)}
                    ></Grid>
                </Grid>
                <Grid container xs={12} lg={9} md={12}>
                    <Grid
                        container
                        xs={12}
                        className={clsx(
                            classes.headerContainer,
                            classes.headerContainerDesktop
                        )}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            md={8}
                            className={classes.infoContainer}
                        >
                            <Grid container item xs={12} alignItems="center">
                                <Typography className={classes.name}>
                                    {props.pet.name}
                                </Typography>
                                {props.pet.hasMedicalConditions && (
                                    <FontAwesomeIcon
                                        icon={faHeartbeat}
                                        className={classes.heartIcon}
                                    />
                                )}
                                <Box className={classes.deceasedIcon}>
                                    {props.pet.deceased && '🌈'}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description}>
                                    {props.pet.breed?.name}
                                    {firstSeparator}
                                    {petAge}
                                    {secondSeparator}
                                    {props.pet.gender?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={4}
                            alignContent="center"
                        >
                            {props.onBook && props.pet.active && (
                                <Button
                                    startIcon={faBellConcierge}
                                    label="Book Appointment"
                                    onClick={props.onBook}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {props.views.length > 0 && (
                        <Grid xs={12} className={classes.items}>
                            <HeaderItems
                                options={props.views}
                                selectedId={props.selectedViewId}
                                onSelect={props.onShow}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Header;
