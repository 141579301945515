import { SmsChat, SmsMessage } from "model/TwoWaySms";

export interface TwoWaySmsState {
  messageAmount: number;
  unreadMessages: Array<SmsMessage>;
  conversation: Array<SmsMessage>;
  chats: Array<SmsChat>;
  savedMessage?: SmsMessage;
  message?: SmsMessage;
  status: TwoWaySmsStatus;
  loading: boolean;
}


export enum TwoWaySmsStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Error,
  Cleared,
  Setting,
  SetConversationSuccess,
  SetConversationsSuccess,
  SetCountSuccess,
  Getting,
  GetSuccess,
  GetCountSuccess,
  FetchingBatch,
  FetchBatchSuccess,
  SaveSuccess,
  Saving,
  Searching,
  SearchSuccess,
  FilterSuccess
}
