import Availability, { FilterAvailability } from "model/Availability";
import { AvailabilityBooking } from "actions/availability/AvailabilityDto"

export interface AvailabilityState {
  availability: Availability;
  availabilityBooking: AvailabilityBooking;
  filterAvailability: FilterAvailability;
  status: AvailabilityStatus;
  loading: boolean;
  requestTokens: {
    availability: string;
    availabilityBooking: string;
  }
}

export enum AvailabilityStatus {
  Initial,

  Fetching,
  FetchSuccess,

  FetchingStaff,
  FetchStaffSuccess,

  FetchingFilter,
  FetchingBooking,
  FetchFilterSuccess,
  FetchBookingSuccess,
  Error
}
