import { Box, createStyles, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';
import { MarketplaceTax } from '@spike/marketplace-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { wbp, reduceResolution } from 'Theme';
import Checkbox from '../Checkbox';
import { useMarketplace } from 'hooks';
import { Button } from 'components/UI';
import { FieldError } from '@spike/model';
import { useFieldStyles } from '../FieldStyles';
import clsx from 'clsx';

interface TaxesSelectorProps {
    id?: string;
    title?: string;
    subtitle?: string;
    name?: string;
    checked?: boolean;
    onSave?: (selectedTaxes: Array<MarketplaceTax>) => void;
    onContinue?: (selectedTaxes: Array<MarketplaceTax>) => void;
    onChange?: (selectedTaxes: Array<MarketplaceTax>) => void;
    className?: string;
    selectedTaxes: Array<MarketplaceTax>;
    onTaxClick?: () => void;
    errors?: Array<FieldError>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        taxesDisplayContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginBottom: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginBottom: '30px'
            }
        },
        title: {
            '& span p': {
                width: 'auto !important'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                lineHeight: `${reduceResolution(42)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                lineHeight: '42px'
            }
        },
        subtitle: {
            fontSize: 14,
            marginTop: 12,
            color: '#969696',

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        taxDisplayContainer: {
            marginTop: 24,
            borderRadius: 14
        },
        taxContainer: {
            'gap': 8,
            'padding': '15px 0px',

            '&:not(:last-child)': {
                borderBottom: 'solid 1px #D4D4D4'
            },
            '& .MuiButtonBase-root': {
                padding: 0
            },
            '& .MuiTypography-root': {
                display: 'none'
            }
        },
        taxLabel: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        taxPercentage: {
            color: 'grey',
            marginLeft: 'auto'
        },
        depositAlert: {
            'display': 'flex',
            'backgroundColor': '#F8F5F1',
            'border': '2px solid #BCB8AE',
            'borderRadius': '10px',
            'padding': '14px 16px 16px',

            '& b': {
                fontWeight: 600
            }
        },
        sectionIconInfo: {
            'color': '#BAA997',
            '&:hover': {
                cursor: 'pointer'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                paddingRight: `${reduceResolution(10)}px`,
                marginTop: `${reduceResolution(2)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px',
                paddingRight: '10px',
                marginTop: '2px'
            }
        },
        boxIcon: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(38)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '38px'
            }
        },
        spanMessage: {
            fontSize: 14,
            lineHeight: 1.3,
            fontWeight: 400
        },
        linkWrapper: {
            textDecoration: 'none',
            marginTop: '20px'
        },
        linkButton: {
            'marginTop': '10px',
            'paddingRight': 20,
            'paddingLeft': 20,
            'height': 40,

            '& .MuiButton-label': {
                fontSize: 14
            }
        },
        listWrapper: {
            padding: '10px 22px',
            borderRadius: '25px',
            backgroundColor: '#FAFAFA',

            [theme.breakpoints.up('md')]: {
                display: 'inline-flex'
            }
        },
        taxList: {
            minWidth: 200,

            [theme.breakpoints.up('md')]: {
                minWidth: 250
            }
        },
        BoxTaxable: {
            marginTop: 15,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
            }
        },
        labelError: {
            fontWeight: 500,
            marginLeft: 12,
            [theme.breakpoints.down('md')]: {
                marginTop: 10,
                marginLeft: 0,
                fontSize: 14
            },
            [theme.breakpoints.down('sm')]: {
                fontWeight: 400
            }
        }
    })
);

export const TaxesSelector: FunctionComponent<TaxesSelectorProps> = props => {
    const classes = useStyles();
    const classesFields = useFieldStyles();
    const marketplace = useMarketplace();

    const [checked, setChecked] = useState(props.checked ?? false);
    const [selectedTaxes, setSelectedTaxes] = useState<MarketplaceTax[]>(props.selectedTaxes || []);

    const [taxes, setTaxes] = useState(() =>
        marketplace.taxes.map(tax => ({
            uuid: tax.uuid,
            id: tax.id,
            name: tax.name,
            percentage: tax.percentage,
            selected: props.selectedTaxes?.some(selectedTax => selectedTax.uuid === tax.uuid) || false
        }))
    );

    useEffect(() => {
        setChecked(props.checked ?? false);
        setSelectedTaxes(props.selectedTaxes || []);

        setTaxes(prevTaxes =>
            prevTaxes.map(tax => ({
                ...tax,
                selected: props.selectedTaxes?.some(selectedTax => selectedTax.uuid === tax.uuid) || false
            }))
        );
    }, [props.checked, props.selectedTaxes]);

    useEffect(() => {
        props.onChange?.(selectedTaxes);
    }, [selectedTaxes]);

    const handleClick = () => {
        const newChecked = !checked;
        setChecked(newChecked);

        if (!newChecked) {
            setSelectedTaxes([]);
            setTaxes(prevTaxes =>
                prevTaxes.map(tax => ({
                    ...tax,
                    selected: false
                }))
            );
        }
    };

    const handleTaxCheck = (uuid: string) => {
        const taxIndex = taxes.findIndex(tax => tax.uuid === uuid);
        if (taxIndex === -1) return;
        const updatedTaxes = [...taxes];
        updatedTaxes[taxIndex] = {
            ...updatedTaxes[taxIndex],
            selected: !updatedTaxes[taxIndex].selected
        };

        setTaxes(updatedTaxes);

        const newSelectedTaxes = updatedTaxes
            .filter(tax => tax.selected)
            .map(tax => ({
                id: tax.id,
                uuid: tax.uuid,
                name: tax.name,
                percentage: tax.percentage
            }));

        setSelectedTaxes(newSelectedTaxes);
    };

    return (
        <Box id={props.id} className={props.className ?? classes.taxesDisplayContainer}>
            <Box className={classes.BoxTaxable}>
                <Checkbox
                    iconColor={'black'}
                    id={props.id ? props.id + '_checkbox_taxable' : props.id}
                    fontWeight={500}
                    className={classes.title}
                    label={props.title ?? 'Taxable'}
                    checked={checked}
                    onChange={handleClick}
                />

                {props.errors && props.errors.length > 0 && (
                    <Typography className={clsx(classesFields.labelError, classes.labelError)}>
                        {props.errors[0].errorMessage}
                    </Typography>
                )}
            </Box>

            <Typography className={classes.subtitle}>{props.subtitle ?? 'Choose the applicable tax: '}</Typography>

            {checked && (
                <Box className={classes.taxDisplayContainer}>
                    {taxes.length > 0 ? (
                        <Box className={classes.listWrapper}>
                            <List disablePadding className={classes.taxList}>
                                <Box>
                                    {taxes.map(tax => (
                                        <ListItem key={tax.uuid} className={classes.taxContainer}>
                                            <Checkbox
                                                iconColor={'black'}
                                                fontWeight={500}
                                                label={''}
                                                checked={tax.selected}
                                                onChange={() => handleTaxCheck(tax.uuid)}
                                            />
                                            <span className={classes.taxLabel}>{tax.name}</span>
                                            <span className={classes.taxPercentage}>{tax.percentage}%</span>
                                        </ListItem>
                                    ))}
                                </Box>
                            </List>
                        </Box>
                    ) : (
                        <Box className={classes.depositAlert}>
                            <Box>
                                <Box className={classes.boxIcon}>
                                    <FontAwesomeIcon icon={faInfoCircle} className={classes.sectionIconInfo} />
                                </Box>
                            </Box>
                            <Box>
                                <Typography className={classes.spanMessage}>
                                    You have not added any tax yet. Click below to add taxes or go to{' '}
                                    <strong>Settings</strong> and then click on <strong>Taxes</strong>.
                                </Typography>
                                <Box className={classes.linkWrapper}>
                                    <Button
                                        id={props.id ? props.id + '_button_add_tax' : props.id}
                                        label="Add Tax"
                                        color="green"
                                        size="medium"
                                        className={classes.linkButton}
                                        onClick={props.onTaxClick}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
export default TaxesSelector;
