import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { wbp, reduceResolution } from "Theme";

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowContainer: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(61)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "61px",
      },
    },
    cellCenter: {
      display: "flex",
      justifyContent: "center",
    },
    cellVerticalCenter: {
      display: "flex",
      alignItems: "center",
    },
  })
);