import React, { FunctionComponent } from 'react';
import {
    Grid,
    Divider,
    Typography,
    Theme,
    createStyles,
    makeStyles
} from '@material-ui/core';
import moment from 'moment';
import { wbp, reduceResolution } from 'Theme';

interface ChatDividerProps {
    date: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatDividerContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '10px'
            }
        },
        chatDividerLeft: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '40px'
            }
        },
        chatDividerRight: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '40px'
            }
        },
        chatDividerText: {
            textAlign: 'center',
            color: '#7A7A7A',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '13px'
            },
            fontWeight: 500
        }
    })
);

const ChatDivider: FunctionComponent<ChatDividerProps> = props => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.chatDividerContainer}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
        >
            <Grid item xs={5}>
                <Divider className={classes.chatDividerLeft} />
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.chatDividerText}>
                    {moment(props.date, 'DD-MM-YYYY').isSame(moment(), 'day')
                        ? 'Today'
                        : moment(props.date, 'DD-MM-YYYY').format(
                              'dddd, D MMMM, YYYY'
                          )}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Divider className={classes.chatDividerRight} />
            </Grid>
        </Grid>
    );
};

export default ChatDivider;
