import React, { FunctionComponent, useState, ChangeEvent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Option } from '@spike/model';
import { OutlinedInput } from '@material-ui/core';
import Dropdown from './Dropdown';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	id?: string;
	options: Array<Option<string | number>>;
	placeholder?: string;
	emptyOptionsText?: string;
	emptySearhcBoxShowAllOptions?: boolean;
	onClickAway: () => void;
	onChangeSearchText: (text: string) => void;
	onSelect: (option: Option<string | number>) => void;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			position: 'relative',

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column'
			}
		},
		searchBox: {
			'height': 47,
			'width': '100%',
			'fontSize': 16,
			'borderRadius': 30,
			'border': '1px solid #E9F0ED',

			[theme.breakpoints.up('md')]: {
				borderRadius: '40px',
				width: '270px',
				height: '40px',
				fontSize: '18px'
			},
			'& input::placeholder': {
				[theme.breakpoints.down('lg')]: {
					fontSize: '14px'
				},
				[theme.breakpoints.up('xl')]: {
					borderRadius: '40px',
					fontSize: '18px'
				}
			}
		},
		dropdown: {
			width: '100%',
			marginTop: 13,

			[theme.breakpoints.down('sm')]: {
				position: 'static'
			},
			[theme.breakpoints.up('md')]: {
				marginTop: '50px'
			}
		},
		dropdownScroll: {
			paddingRight: '10px',
			[theme.breakpoints.down('lg')]: {
				maxHeight: '150px'
			},
			[theme.breakpoints.up('xl')]: {
				maxHeight: '200px'
			}
		},
		dropdownOptionContainer: {
			'display': 'flex',
			'alignItems': 'center',
			'cursor': 'pointer',
			'borderRadius': '100px',
			'paddingTop': '5px',
			'paddingBottom': '5px',
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '15px',
				paddingRight: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '20px',
				paddingRight: '20px'
			},
			'&:hover': {
				background: 'rgb(241, 241, 241)',
				cursor: 'pointer'
			}
		},
		dropdownText: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px'
			}
		},
		startIcon: {
			marginRight: 7
		}
	})
);

export const Autocomplete: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const [searchText, setSearchText] = useState('');
	const [showList, setShowList] = useState(
		props.emptySearhcBoxShowAllOptions || false
	);

	const clickAwayHandler = () => {
		collapseList();
		props.onClickAway();
	};

	const selectHandler = (option: Option<string | number>) => {
		setSearchText(option.name);
		collapseList();
		props.onSelect(option);
	};

	const changeSearchTextHandler = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const text = event.target.value;
		setSearchText(text);
		expandOrCollapseList(text);
		props.onChangeSearchText(text);
	};

	const focusHandler = () => {
		expandOrCollapseList(searchText);
	};

	const expandOrCollapseList = (searchText: string) => {
		setShowList(
			searchText.length > 0 || props.emptySearhcBoxShowAllOptions || false
		);
	};

	const collapseList = () => {
		setShowList(false);
	};

	const dropdownOptions = (
		<Box>
			<OverlayScrollbarsComponent className={classes.dropdownScroll}>
				{props.options.map((option, index) => (
					<Box
						key={option.id}
						onClick={() => {
							selectHandler(option);
						}}
						className={classes.dropdownOptionContainer}
						id={`service_filter_search_option_${index}`}
					>
						<Typography className={classes.dropdownText}>
							{option.name}
						</Typography>
					</Box>
				))}
			</OverlayScrollbarsComponent>
		</Box>
	);

	const dropdownEmpty = (
		<Box>
			<Box>
				<Typography className={classes.dropdownText}>
					{props.emptyOptionsText}
				</Typography>
			</Box>
		</Box>
	);

	const dropdownContent =
		props.options.length === 0 ? dropdownEmpty : dropdownOptions;

	const list = showList && (
		<Dropdown
			direction="down-right"
			onCollapse={() => {}}
			className={classes.dropdown}
		>
			{dropdownContent}
		</Dropdown>
	);

	const searchBox = (
		<OutlinedInput
			className={classes.searchBox}
			value={searchText}
			placeholder={props.placeholder}
			startAdornment={
				<FontAwesomeIcon
					icon={faSearch}
					className={classes.startIcon}
				/>
			}
			onChange={changeSearchTextHandler}
			onFocus={focusHandler}
			id={props.id}
		/>
	);

	return (
		<ClickAwayListener onClickAway={clickAwayHandler}>
			<Box className={clsx(classes.container, props.className)}>
				{searchBox}
				{list}
			</Box>
		</ClickAwayListener>
	);
};

export default Autocomplete;
