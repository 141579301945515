import { FunctionComponent, useEffect, useState } from "react";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Button, CheckboxCircle } from "components/UI";

interface FilterProps {
  label: string;
  active: boolean;
  deactive: boolean;
  onSelect: (active: boolean, deactive: boolean) => void;
  icon: IconDefinition;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    button: {
      [theme.breakpoints.down("md")]: {
        minWidth: "138px",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "174px",
      },
    },
    list: {
      position: "absolute",
      borderWidth: "2px",
      borderStyle: "solid",
      backgroundColor: "white",
      zIndex: 10,
      [theme.breakpoints.down("md")]: {
        top: "48px",
        borderRadius: "13px",
        width: "140px",
        height: "176px"
      },
      [theme.breakpoints.up("lg")]: {
        top: "60px",
        borderRadius: "16px",
        width: "177px",
        height: "220px"
      },
    },
    listBody: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxHeight: "379px",
      },
      [theme.breakpoints.up("lg")]: {
        maxHeight: "471px",
      },
    },
    listFooter: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      borderBottom: "1px solid #D3D3D3",
      [theme.breakpoints.down("md")]: {
        paddingTop: "19px",
        paddingBottom: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "24px",
        paddingBottom: "30px",
      },
    },
    firstOption: {
      [theme.breakpoints.down("md")]: {
        paddingTop: "5px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "7px",
      },
    },
    allStaff: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    option: {
      [theme.breakpoints.down("md")]: {
        paddingTop: "13px",
        paddingLeft: "19px",
        paddingRight: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "16px",
        paddingLeft: "23px",
        paddingRight: "23px",
      },
      "& .MuiTypography-root": {
        fontWeight: 400,
        fontFamily: "Poppins",
        [theme.breakpoints.down("md")]: {
          fontSize: "13px",
          lineHeight: "22px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "16px",
          lineHeight: "27px",
        },
      },
    },
    sectionContainer: {
      borderTop: "1px solid #D3D3D3"
    },
    title: {
      color: "#C0C0C0",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        paddingTop: "13px",
        paddingLeft: "25px",
        fontSize: "11px"
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "16px",
        paddingLeft: "31px",
        fontSize: "14px"
      },
    }
  })
);

export const VendorsFilter: FunctionComponent<FilterProps> = (props) => {
  const classes = useStyles();

  const [showList, setShowList] = useState(false);
  const [activeSelected, setActiveSelected] = useState(props.active);
  const [deactiveSelected, setDeactiveSelected] = useState(props.deactive);

  const closeHandler = () => {
    setShowList(false);
    setActiveSelected(props.active);
    setDeactiveSelected(props.deactive);
  };

  const clickButtonHandler = () => {
    setShowList((prev) => !prev);
    setActiveSelected(props.active);
    setDeactiveSelected(props.deactive);
  };

  const selectOptionActiveHandler = (checked: boolean) => {
    setActiveSelected(checked);
  };

  const selectOptionDeactiveHandler = (checked: boolean) => {
    setDeactiveSelected(checked);
  };

  const clickApplyHandler = () => {
    props.onSelect(activeSelected, deactiveSelected);
    setShowList(false);
  };

  const list = (
    <Box className={classes.list}>
      <Box className={clsx(classes.listBody)}>
        <Box className={classes.sectionContainer}>
          <Typography className={classes.title}>STATUS</Typography>
          <CheckboxCircle
            key="active"
            label="Active"
            checked={activeSelected}
            onChange={(checked: boolean) => {
              selectOptionActiveHandler(checked);
            }}
            className={clsx(classes.option,classes.firstOption)}
          />
          <CheckboxCircle
            key="inactive"
            label="Inactive"
            checked={deactiveSelected}
            onChange={(checked: boolean) => {
              selectOptionDeactiveHandler(checked);
            }}
            className={clsx(classes.option)}
          />
        </Box> 
      </Box>
      <Box className={clsx(classes.listFooter)}>
        <Button label="Apply" color="green" onClick={clickApplyHandler} />
      </Box>
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <Box className={clsx(classes.container, props.className)}>
        <Button
          label={props.label}
          startIcon={props.icon}
          onClick={clickButtonHandler}
          variant="secondary"
          color="green"
          className={classes.button}
        />
        {showList && list}
      </Box>
    </ClickAwayListener>
  );
};

export default VendorsFilter;
