import {
  ResetPasswordAction,
  RESET_PASSWORD_START_ACTION_TYPE,
  RESET_PASSWORD_END_ACTION_TYPE,
  RESET_PASSWORD_FAIL_ACTION_TYPE,
  RESET_PASSWORD_SUCCESS_ACTION_TYPE,
  RESET_PASSWORD_RESET_ACTION_TYPE,
} from "./ResetPasswordActionsTypes";
import { ThunkAction } from "redux-thunk";
import { ResetPasswordState } from "reducers/resetPassword/ResetPasswordState";
import ApiClient, { isCancelled } from "api/ApiClient";
import { push } from "connected-react-router";
import { CallHistoryMethodAction } from "connected-react-router";
import { NotificationsAction }from "@spike/notifications-action";
import { showError }from "@spike/notifications-action";
import { LoginPath } from "routes/Paths";

export const resetThunk = (): ThunkAction<
  void,
  ResetPasswordState,
  null,
  ResetPasswordAction
> => {
  return async (dispatch) => {
    dispatch(reset());
  };
};

export const resetPasswordThunk = (
  password: string,
  token: string
): ThunkAction<
  void,
  ResetPasswordState,
  null,
  ResetPasswordAction | NotificationsAction | CallHistoryMethodAction
> => {
  return async (dispatch) => {
    dispatch(reset());
    dispatch(start());

    const HTTP_STATUS_422_UNPROCESSABLE_ENTITY = 422;

    try {
      await ApiClient.post("reset_password", {
        new_password: password,
        temporal_token: token,
      });
      dispatch(success());
      setTimeout(() => {
        dispatch(push(LoginPath));
      }, 1500);
    } catch (apiError: any) {
      if (
        apiError.response &&
        apiError.response.status === HTTP_STATUS_422_UNPROCESSABLE_ENTITY
      ) {
        dispatch(fail());
        dispatch(showError("Email link has expired."));
      } else {
        dispatch(showError("Error resetting password."));
        console.error(apiError);
      }
    }
    dispatch(end());
  };
};

export const resetCustomerPasswordThunk = (
  password: string,
  token: string
): ThunkAction<
  void,
  ResetPasswordState,
  null,
  ResetPasswordAction | NotificationsAction | CallHistoryMethodAction
> => {
  return async (dispatch) => {
    dispatch(reset());
    dispatch(start());

    const HTTP_STATUS_422_UNPROCESSABLE_ENTITY = 422;

    try {
      await ApiClient.post("reset_password_customer", {
        new_password: password,
        temporal_token: token,
      });
      dispatch(success());

      setTimeout(() => {
        window.close();
      }, 2000);

    } catch (apiError: any) {
      if(!isCancelled(apiError)) {
        if (
          apiError.response &&
          apiError.response.status === HTTP_STATUS_422_UNPROCESSABLE_ENTITY
        ) {
          dispatch(fail());
          dispatch(showError("Email link has expired."));
        } else {
          dispatch(showError("Error resetting password."));
        }
      }
    }
    dispatch(end());
  };
};

export const reset = (): ResetPasswordAction => {
  return {
    type: RESET_PASSWORD_RESET_ACTION_TYPE,
  };
};

const start = (): ResetPasswordAction => {
  return {
    type: RESET_PASSWORD_START_ACTION_TYPE,
  };
};

const fail = (): ResetPasswordAction => {
  return {
    type: RESET_PASSWORD_FAIL_ACTION_TYPE,
  };
};

const success = (): ResetPasswordAction => {
  return {
    type: RESET_PASSWORD_SUCCESS_ACTION_TYPE,
  };
};

const end = (): ResetPasswordAction => {
  return {
    type: RESET_PASSWORD_END_ACTION_TYPE,
  };
};
