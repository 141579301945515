import { MyAccountSidebarState, Action } from './MyAccountSidebarState';

// Check if the current path contains the query parameter "?open=menu"
const queryParameter = window.location.search;
const isOpenMyAccountSideBar = queryParameter.includes('open=menu');

export const initialState: MyAccountSidebarState = {
    isOpenMyAccountSideBar: isOpenMyAccountSideBar,
};

export const MyAccountSidebarReducer = (state: MyAccountSidebarState = initialState, action: Action): MyAccountSidebarState => {
    switch (action.type) {
        case 'OPEN_MY_ACCOUNT_SIDEBAR':
            return { ...state, isOpenMyAccountSideBar: true };
        case 'CLOSE_MY_ACCOUNT_SIDEBAR':
            return { ...state, isOpenMyAccountSideBar: false };
        case 'TOGGLE_MY_ACCOUNT_SIDEBAR':
            return { ...state, isOpenMyAccountSideBar: !state.isOpenMyAccountSideBar };
        default:
            return state;
    }
};

export default MyAccountSidebarReducer;
