import React, {
	FunctionComponent,
	ChangeEvent,
	FocusEvent,
	useState,
	useEffect
} from 'react';
import {
	Box,
	Button as MUIButton,
	InputBase,
	Typography
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { cuddlesStyle } from 'Theme';
import { convertTo24Hours } from 'utils/DateUtils';

export interface Props {
	id?: string;
	className?: string;
	value24Hs?: string;
	disabled?: boolean;
	error?: boolean;
	onFocus?: () => void;
	onChange: (value24Hs: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'width': 94,
			'height': 42,
			'borderRadius': 30,
			'padding': '0px 7px',
			'display': 'flex',
			'alignItems': 'center',
			'border': '1px solid #DBDBDB',
			'color': cuddlesStyle.hourField.colors.text,
			'backgroundColor': cuddlesStyle.hourField.colors.background,

			'&:hover': {
				color: cuddlesStyle.hourField.colors.text,
				background: cuddlesStyle.hourField.colors.background
			},

			[theme.breakpoints.up('lg')]: {
				width: 120
			}
		},
		containerDisabled: {
			'backgroundColor':
				cuddlesStyle.hourField.colors.disabled.background +
				' !important',
			'border': '0 !important',
			'color':
				cuddlesStyle.hourField.colors.disabled.text + ' !important',
			'& input': {
				color:
					cuddlesStyle.hourField.colors.disabled.text + ' !important'
			},
			'& .MuiButton-label': {
				color:
					cuddlesStyle.hourField.colors.disabled.text + ' !important'
			}
		},
		amPmButton: {
			'all': 'unset',
			'padding': 5,
			'lineHeight': 1,
			'border': 'none',
			'borderRadius': 13,
			'marginLeft': 'auto',
			'textTransform': 'uppercase',
			'color': cuddlesStyle.hourField.colors.text + ' !important',

			[theme.breakpoints.up('lg')]: {
				padding: '5px 8px'
			},

			'& .MuiButton-label': {
				fontSize: 14,
				fontWeight: 'normal !important',

				[theme.breakpoints.up('lg')]: {
					fontSize: 18
				}
			}
		},
		am: {
			'background': '#F2D2A2',
			'&:hover': {
				background: '#F2D2A2 !important'
			}
		},
		pm: {
			'background': '#BDD2CA',
			'&:hover': {
				background: '#BDD2CA !important'
			}
		},
		input: {
			'flex': '0 0 18px',

			[theme.breakpoints.up('lg')]: {
				flex: '0 0 22px'
			},

			'& input': {
				width: '100%',
				fontSize: 15,

				[theme.breakpoints.up('lg')]: {
					fontSize: 18
				}
			}
		},
		inputHours: {
			'& input': {
				textAlign: 'right'
			}
		},
		inputMinutes: {
			'& input': {
				textAlign: 'left'
			}
		},
		font: {
			fontSize: 15,
			padding: '0px 2px',
			marginBottom: '3px',

			[theme.breakpoints.up('lg')]: {
				fontSize: 18
			}
		},
		error: {
			borderColor: '#EA6464'
		}
	})
);

const am = 'am';
const pm = 'pm';
const defaultHour = '8:00';

export const HourField: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const parseHours = (value: string) => {
		const [hoursPart] = value.split(':');
		let hours = parseInt(hoursPart);
		if (hours === 0) {
			hours = 12;
		} else if (hours > 12) {
			hours = hours - 12;
		}
		return hours.toString();
	};

	const parseMinutes = (value: string) => {
		const [, minutes] = value.split(':');
		return minutes;
	};

	const parseAmPm = (value: string) => {
		const [hoursPart] = value.split(':');
		let hours = parseInt(hoursPart);
		return hours < 12 ? am : pm;
	};

	const [hours, setHours] = useState(
		props.value24Hs ? parseHours(props.value24Hs) : parseHours(defaultHour)
	);
	const [minutes, setMinutes] = useState(
		props.value24Hs
			? parseMinutes(props.value24Hs)
			: parseMinutes(defaultHour)
	);
	const [amPm, setAmPm] = useState(
		props.value24Hs ? parseAmPm(props.value24Hs) : parseAmPm(defaultHour)
	);

	useEffect(() => {
		setHours(
			props.value24Hs
				? parseHours(props.value24Hs)
				: parseHours(defaultHour)
		);
		setMinutes(
			props.value24Hs
				? parseMinutes(props.value24Hs)
				: parseMinutes(defaultHour)
		);
		setAmPm(
			props.value24Hs
				? parseAmPm(props.value24Hs)
				: parseAmPm(defaultHour)
		);
	}, [props.value24Hs]);

	const isEmpty = (value: string): boolean => {
		return value === '';
	};

	const isNumber = (value: string): boolean => {
		return !Number.isNaN(+value) && Number.isInteger(+value);
	};

	const isHour = (value: string): boolean => {
		return (
			isNumber(value) && value.length <= 2 && +value >= 0 && +value <= 12
		);
	};

	const isMinutes = (value: string): boolean => {
		return (
			isNumber(value) && value.length <= 2 && +value >= 0 && +value <= 59
		);
	};

	const formatHours = (value: string): string => {
		return value.length === 0 ? '0' : value;
	};

	const formatMinutes = (value: string): string => {
		if (value.length === 0) {
			return '00';
		} else if (value.length === 1) {
			return '0' + value;
		} else {
			return value;
		}
	};

	const changeHoursHandler = (event: ChangeEvent<HTMLInputElement>): void => {
		const newValue = event.currentTarget.value;
		if (isEmpty(newValue) || isHour(newValue)) {
			setHours(newValue);
		}
	};

	const changeMinutesHandler = (
		event: ChangeEvent<HTMLInputElement>
	): void => {
		const newValue = event.currentTarget.value;
		if (isEmpty(newValue) || isMinutes(newValue)) {
			setMinutes(newValue);
		}
	};

	const handleClick = (): void => {
		setAmPm(prev => {
			const newValue = prev === am ? pm : am;
			onChange(hours, minutes, newValue);
			return newValue;
		});
	};

	const blurHoursHandler = (event: FocusEvent<HTMLInputElement>): void => {
		setHours(formatHours(hours));
		onChange(formatHours(hours), minutes, amPm);
	};

	const blurMinutesHandler = (event: FocusEvent<HTMLInputElement>): void => {
		setMinutes(formatMinutes(minutes));
		onChange(hours, minutes, amPm);
	};

	const onChange = (hours: string, minutes: string, amPm: string): void => {
		props.onChange(convertTo24Hours(`${hours}:${minutes} ${amPm}`));
	};

	return (
		<Box
			id={props.id}
			className={clsx(
				classes.container,
				{ [classes.containerDisabled]: props.disabled },
				{ [classes.error]: props.error },
				props.className
			)}
		>
			<InputBase
				value={hours}
				className={clsx(classes.inputHours, classes.input)}
				onChange={changeHoursHandler}
				onBlur={blurHoursHandler}
				disabled={props.disabled}
				onFocus={() => props.onFocus && props.onFocus()}
			/>
			<Box>
				<Typography className={classes.font}>:</Typography>
			</Box>
			<InputBase
				value={minutes}
				className={clsx(classes.inputMinutes, classes.input)}
				onChange={changeMinutesHandler}
				onBlur={blurMinutesHandler}
				onFocus={() => props.onFocus && props.onFocus()}
				disabled={props.disabled}
			/>
			<MUIButton
				variant="contained"
				color="primary"
				disableRipple={true}
				disableTouchRipple={true}
				disableElevation={true}
				className={clsx(classes.amPmButton, {
					[classes.am]: amPm === am,
					[classes.pm]: amPm === pm
				})}
				onClick={handleClick}
				disabled={props.disabled}
			>
				{amPm}
			</MUIButton>
		</Box>
	);
};

export default HourField;
