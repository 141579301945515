import {
	faEye,
	faPencil,
	IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { EnrollmentStatusItem as EnrollmentStatusItemModel } from '@spike/payments-model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';
import { Status } from './Status';

interface StatusRowProps {
	title: string;
	item?: null | EnrollmentStatusItemModel;
	status?: Option<string>;
	className?: string;
	editIcon?: IconDefinition;
	onEdit?: () => void;
	onView?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: 'black',
			cursor: 'pointer',

			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(22)}px !important`,
				height: `${reduceResolution(22)}px !important`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '22px !important',
				height: '22px !important'
			}
		}
	})
);
export const StatusRow: FunctionComponent<StatusRowProps> = props => {
	const classes = useStyles();
	const tableClasses = useTableStyles();

	return (
		<TableRow>
			<TableCell className={tableClasses.cell}>{props.title}</TableCell>
			<TableCell className={tableClasses.cell}>
				<Status
					status={props.item?.verificationStatus || props.status}
				/>
			</TableCell>
			<TableCell className={tableClasses.cell}>
				{props.item &&
					`Updated ${props.item.updatedAt?.format('MM/DD/YYYY')}`}
			</TableCell>
			<TableCell className={tableClasses.cell}>
				{props.onEdit &&
					(
						props.status || props.item?.verificationStatus
					)?.id?.toLowerCase() !== 'passed' && (
						<FontAwesomeIcon
							icon={props.editIcon || faPencil}
							className={classes.icon}
							onClick={props.onEdit}
						/>
					)}
				{props.onView &&
					(
						props.status || props.item?.verificationStatus
					)?.id?.toLowerCase() === 'passed' && (
						<FontAwesomeIcon
							icon={props.editIcon || faEye}
							className={classes.icon}
							onClick={props.onView}
						/>
					)}
			</TableCell>
		</TableRow>
	);
};

export default StatusRow;
