import React, { FunctionComponent } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Button, CheckboxCircle } from 'components/UI';
import { Link as LinkRouter } from 'react-router-dom';
import { wbp, reduceResolution } from 'Theme';
import { useSelector } from 'react-redux';
import { OnboardingStepsState } from 'reducers/onboardingSettings/onboardingStepsState';
import { RootState } from 'store';
import { Steps } from 'components/Payments/BankAccounts/BankAccountsModel';

export interface StepType {
    id: number;
    stepContent: JSX.Element;
    checked: boolean;
}
interface Props {
    steps: Array<StepType>;
}

const connectorHeight = 46;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepContainer: {
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            marginBottom: '15px'
        },
        iconContainer: {},
        label: {
            fontWeight: 600,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                maxWidth: `${reduceResolution(290)}px`,
                minWidth: `${reduceResolution(290)}px`,
                fontSize: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                maxWidth: '310px',
                minWidth: '310px',
                fontSize: '15px'
            }
        },
        linksContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        links: {
            cursor: 'pointer',
            color: '#5E8677',
            fontWeight: 500,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px'
            },
            textDecoration: 'none !important'
        },
        checkBoxConnectorContainer: {
            //display: "flex",
            display: 'none',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        connectorStep: {
            width: '2px',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(2)}px`,
                height: `${reduceResolution(connectorHeight)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '2px',
                height: `${connectorHeight}px`
            },
            backgroundColor: '#7A7A7A'
        },
        stepButton: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(100)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '100px'
            }
        },
        stepButtonDisabled: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(100)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '100px'
            },
            backgroundColor: '#D4D4D4 !important',
            cursor: 'not-allowed !important'
        },
        editText: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginTop: `${reduceResolution(20)}px`,
                marginRight: `${reduceResolution(25)}px`,
                marginLeft: `${reduceResolution(25)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            },
            textAlign: 'center',
            fontWeight: 500,
            color: '#222222',
            borderBottom: '2px solid'
        }
    })
);

export const OnboardingStepper: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const { onboardingSteps, openModal } = useSelector<
        RootState,
        OnboardingStepsState
    >(state => state.onboardingSteps);

    const {
        businessHoursDone,
        teamDone,
        servicesDone,
        petTypesDone,
        clientsDone,
        bookingDone
    } = onboardingSteps;

    const StepConector = () => <Box className={classes.connectorStep} />;

    return (
        <Box sx={{ maxWidth: 400 }}>
            {props.steps.map((step, index) => (
                <Box className={classes.stepContainer} key={step.id}>
                    <Box className={classes.checkBoxConnectorContainer}>
                        <CheckboxCircle
                            iconColor="#5E8677"
                            className={classes.iconContainer}
                            checked={step.checked}
                        />
                        {props.steps.length !== index + 1 && <StepConector />}
                    </Box>
                    <Box className={classes.label}>{step.stepContent}</Box>
                    <Box className={classes.linksContainer}>
                        <LinkRouter
                            to={
                                step.id === 6 &&
                                (businessHoursDone === false ||
                                    teamDone === false ||
                                    petTypesDone === false ||
                                    servicesDone === false ||
                                    clientsDone === false)
                                    ? '#'
                                    : step.stepContent.props.firstURL
                            }
                            onClick={
                                step.id === 6 &&
                                (businessHoursDone === false ||
                                    teamDone === false ||
                                    petTypesDone === false ||
                                    servicesDone === false ||
                                    clientsDone === false)
                                    ? () => {}
                                    : step.stepContent.props.onClickFirstLink
                            }
                            className={classes.links}
                            id={step.stepContent.props.buttonId}
                        >
                            {step.checked ? (
                                <Typography
                                    className={classes.editText}
                                    onClick={step.stepContent.props.onClick}
                                >
                                    {step.stepContent.props.firstLinkLabel}
                                </Typography>
                            ) : (
                                <Button
                                    id={'onboarding_button_step_' + step.id}
                                    className={
                                        step.id === 6 &&
                                        (businessHoursDone === false ||
                                            teamDone === false ||
                                            petTypesDone === false ||
                                            servicesDone === false ||
                                            clientsDone === false)
                                            ? classes.stepButtonDisabled
                                            : classes.stepButton
                                    }
                                    color="green"
                                    label={
                                        step.stepContent.props.firstLinkLabel
                                    }
                                    size="small"
                                    onClick={step.stepContent.props.onClick}
                                ></Button>
                            )}
                        </LinkRouter>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
