import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import Checkbox from './Checkbox';
import Button from './Button';

export interface Props {
    className?: string;
    onCancel: () => void;
    onComplete: (sendChecked: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiDialog-paper': {
                boxShadow: 'none',

                [theme.breakpoints.down('xs')]: {
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                    borderRadius: 0
                },
                [theme.breakpoints.up('sm')]: {
                    padding: 0,
                    minWidth: 500,
                    borderRadius: 30
                },
                [theme.breakpoints.up('xl')]: {
                    padding: 0,
                    minWidth: 600,
                    borderRadius: 30
                }
            },
            //Title
            '& .MuiDialogTitle-root': {
                paddingBottom: 0
            },
            // Body
            '& .MuiDialogContent-root': {
                padding: 0,
                width: '70%',
                display: 'flex',
                margin: '0px auto',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                [theme.breakpoints.up('sm')]: {
                    width: '100%'
                },
                [theme.breakpoints.down('sm')]: {}
            },

            // Actions
            '& .MuiDialogActions-root': {
                gap: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 16px',

                [theme.breakpoints.up('sm')]: {
                    marginTop: 37,
                    borderTop: '1px solid #DDDDDD',
                    padding: '17px 30px'
                },
                [theme.breakpoints.down('md')]: {
                    '& > button': {
                        minWidth: '48%'
                    },
                    '& > button:last-child': {
                        marginLeft: 0
                    }
                }
            }
        },
        title: {
            color: 'black',
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 600,
            marginBottom: '16px'
        },
        question: {
            fontFamily: 'Poppins',
            fontSize: 15,
            color: '#000',
            fontWeight: 400
        },
        button: {
            paddingLeft: 30,
            paddingRight: 30,
            height: 50,
            borderRadius: 50,
            width: '50%'
        },
        iconClose: {
            fontSize: 20,
            float: 'right',
            cursor: 'pointer'
        },
        contentContainer: {
            display: 'flex',
            justifyContent: 'center'
        }
    })
);

export const CompleteAppointmentDialog: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const [checked, setChecked] = useState(true);

    return (
        <Dialog
            open={true}
            className={clsx(classes.dialog, props.className)}
            onClose={() => props.onCancel && props.onCancel()}
        >
            <DialogTitle>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.iconClose}
                    onClick={props.onCancel}
                />
            </DialogTitle>

            <DialogContent>
                <Typography className={classes.title}>
                    Complete Appointment
                </Typography>
                <Box className={classes.contentContainer}>
                    <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                    <Typography className={classes.question}>
                        Send ready for pick-up message via text
                    </Typography>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    id="complete_appointment_cancel_button"
                    label="Cancel"
                    onClick={props.onCancel}
                    color="black"
                    className={classes.button}
                />
                <Button
                    id="reschedule_confirm_button"
                    label="Complete"
                    onClick={() => props.onComplete(checked)}
                    color="green"
                    className={classes.button}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CompleteAppointmentDialog;
