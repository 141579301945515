import {
    faCircleDollar,
    faHandHoldingDollar
} from '@fortawesome/pro-regular-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getSalesReportsThunk } from '@spike/payments-action';
import clsx from 'clsx';
import { PaymentsSalesReports } from '@spike/payments-model';
import moment from 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import Header from './Header';
import { ReportItem, ReportItemType } from './SalesReportsModel';
import Section from './Section';
import Summary from './Summary';
import { Period } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { useApiClientWrapper } from 'hooks';
import {
    formatSalesReportItemValue,
    salesReportItemvalueType
} from 'utils/PaymentsUtils';

interface SalesReportsProps {
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: 'fit-content',
            padding: '24px 0px',

            [theme.breakpoints.up('md')]: {
                padding: '50px 24px'
            }
        },
        title: {
            color: '#222',
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px'
            },

            [theme.breakpoints.up('md')]: {
                fontSize: '24px'
            }
        },
        mobileWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
                padding: '24px 16px 0px',
                backgroundColor: '#FAFAFA'
            }
        },
        summary: {
            marginTop: 24,

            [theme.breakpoints.up('md')]: {
                marginTop: '34px'
            }
        },
        section: {
            marginTop: 24,

            [theme.breakpoints.up('md')]: {
                marginTop: '37px'
            }
        }
    })
);

export const SalesReports: FunctionComponent<SalesReportsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const salesReports = useSelector<
        RootState,
        PaymentsSalesReports | undefined
    >(state => state.payments.salesReports);
    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [loading, setLoading] = useState(true);

    const salesItems: Array<ReportItem> = [
        {
            key: '1',
            label: 'Gross Sales',
            value: formatSalesReportItemValue(
                salesReports?.grossSales,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.STRONG
        },
        {
            key: '2',
            label: 'Services',
            value: formatSalesReportItemValue(
                salesReports?.grossSalesServices,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '3',
            label: 'Service Deposits',
            value: formatSalesReportItemValue(
                salesReports?.totalDeposits,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '4',
            label: 'Add-On Services',
            value: formatSalesReportItemValue(
                salesReports?.grossSalesAddons,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '5',
            label: 'Products',
            value: formatSalesReportItemValue(
                salesReports?.grossSalesProducts,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '6',
            label: 'Custom Items',
            value: formatSalesReportItemValue(
                salesReports?.customTotal,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '7',
            label: 'Refunds',
            value: formatSalesReportItemValue(
                salesReports?.refunds,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.NORMAL
        },
        {
            key: '8',
            label: 'Discounts',
            value: formatSalesReportItemValue(
                salesReports?.discounts,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.NORMAL
        },
        {
            key: '9',
            label: 'Net Sales',
            value: formatSalesReportItemValue(
                salesReports?.netSales,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.STRONG
        },
        {
            key: '10',
            label: 'Sales Tax',
            value: formatSalesReportItemValue(
                salesReports?.tax,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.NORMAL
        },
        {
            key: '11',
            label: 'Tips',
            value: formatSalesReportItemValue(
                salesReports?.tip,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.NORMAL
        },
        {
            key: '12',
            label: 'Total Sales',
            value: formatSalesReportItemValue(
                salesReports?.totalSales,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.TOTAL
        }
    ];

    const totalItems: Array<ReportItem> = [
        {
            key: '1',
            label: 'Total Sales',
            value: formatSalesReportItemValue(
                salesReports?.totalSales,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.STRONG
        },
        {
            key: '2',
            label: 'Cash',
            value: formatSalesReportItemValue(
                salesReports?.totalCash,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '3',
            label: 'Credit Cards',
            value: `${formatSalesReportItemValue(
                salesReports?.totalCreditCards,
                salesReportItemvalueType.Money
            )}`,
            type: ReportItemType.SUBITEM
        },
        {
            key: '4',
            label: 'Other',
            value: formatSalesReportItemValue(
                salesReports?.totalOther,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.SUBITEM
        },
        {
            key: '5',
            label: 'Cuddles Fees',
            value: formatSalesReportItemValue(
                salesReports?.cuddlesFees,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.NORMAL
        },
        {
            key: '6',
            label: 'Net Total',
            value: formatSalesReportItemValue(
                salesReports?.netTotal,
                salesReportItemvalueType.Money
            ),
            type: ReportItemType.TOTAL
        }
    ];

    useEffect(() => {
        dispatch(getSalesReportsThunk(apiClientWrapper, moment(), moment()));
    }, []);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.GetSalesReportsSuccess:
                setLoading(false);
                break;
            case PaymentsStatus.Error:
                setLoading(false);
                break;
        }
    }, [paymentsStatus]);

    const changePeriodHandler = (period: Period | null) => {
        setLoading(true);
        dispatch(
            getSalesReportsThunk(
                apiClientWrapper,
                period?.from || null,
                period?.to || null
            )
        );
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Typography className={classes.title}>Sales Reports</Typography>

            <Box className={classes.mobileWrapper}>
                <Header
                    salesReports={salesReports}
                    onChangePeriod={changePeriodHandler}
                />
                <Summary
                    salesReports={salesReports}
                    className={classes.summary}
                    loading={loading}
                />
                <Section
                    icon={faCircleDollar}
                    title="Sales"
                    items={salesItems}
                    className={classes.section}
                    loading={loading}
                />
                <Section
                    icon={faHandHoldingDollar}
                    title="Total"
                    items={totalItems}
                    className={classes.section}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default SalesReports;
