import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { MarketplaceBasics } from "@spike/marketplace-model";
import HeaderInvoice from "./HeaderInvoice";
import SectionDataInvoice from "./SectionDataInvoice";
import TablePricesInvoice from "./TablePricesInvoice";

export interface Props {
  logo?: string;
  basics: MarketplaceBasics;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 0 10px #E9E9E9",
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "155px",
        height: "199px",
        borderRadius: "6px",
        paddingRight: "8px",
        paddingLeft: "8px",
        paddingTop: "12px",
      },
      [theme.breakpoints.only("md")]: {
        width: "249px",
        height: "320px",
        borderRadius: "10px",
        paddingRight: "12px",
        paddingLeft: "12px",
        paddingTop: "20px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "333px",
        height: "427px",
        borderRadius: "13px",
        paddingRight: "17px",
        paddingLeft: "17px",
        paddingTop: "26px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "504px",
        height: "647px",
        borderRadius: "20px",
        paddingRight: "25px",
        paddingLeft: "25px",
        paddingTop: "40px",
      },
    },
    dataContainer: {
    },
    dataInvoice: {
      borderTop: "1px solid #E9E9E9",
      [theme.breakpoints.down("md")]: {
        marginTop: "6px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "8px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "12px",
      },
    },
    sectionDataInvoice: {
      [theme.breakpoints.down("md")]: {
        marginTop: "13px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "27px",
      },
    }
  })
);

export const LogoInvoice: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.container}>
      <Box>
        <HeaderInvoice
          basics={props.basics}
          logo={props.logo}
        />
      </Box>
      <Box className={classes.dataInvoice}>
        <Box className={classes.sectionDataInvoice}>
          <SectionDataInvoice />
        </Box>
      </Box>
      <Box className={classes.dataContainer}>
        <TablePricesInvoice />
      </Box>
    </Box>
  );
};

export default LogoInvoice;
