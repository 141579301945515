import React from 'react';
import { CalculatingSpinner, PriceField2 } from 'components/UI';
import { InvoiceLine, InvoiceLineType } from '@spike/invoice-model';
import { FieldError } from '@spike/model';

type ItemPrice = {
	calculating?: boolean;
	invoiceLine: InvoiceLine;
	invoiceLineType: InvoiceLineType;
	className?: string;
	inputClassName?: string;
	errors?: FieldError[];
	onChange: (subtotal?: number) => void;
};

export const ItemPrice: React.FunctionComponent<ItemPrice> = props => {
	return (
		<span className={props.className}>
			{props.invoiceLineType !== InvoiceLineType.Custom &&
				props.calculating && <CalculatingSpinner />}

			{props.invoiceLineType !== InvoiceLineType.Custom &&
				!props.calculating &&
				`$${props.invoiceLine.subtotal}`}

			{props.invoiceLineType === InvoiceLineType.Custom && (
				<PriceField2
					scale={2}
					precision={6}
					errors={props.errors}
					className={props.inputClassName}
					name={`${props.invoiceLine.uuid}_price`}
					value={Number(props.invoiceLine.subtotal)}
					onChange={props.onChange}
				/>
			)}
		</span>
	);
};
