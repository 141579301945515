import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Pet from '@spike/pet-model';
import {
    faCat,
    faDog,
    faRuler,
    faNarwhal,
    faHeartPulse,
    faAngry,
    faEar,
    faMeh,
    faPoo,
    faSmile
} from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import ItemLabelIcon from 'components/UI/ItemLabelIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip2 as Chip } from 'components/UI';

interface PetHeaderProps {
    pet?: Pet;
    onShowPetCard?: () => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        },
        petHeader: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        petPopUp: {
            cursor: 'pointer',
            paddingRight: 18,
            [theme.breakpoints.up('md')]: {
                paddingRight: 20
            }
        },
        typoPetName: {
            fontWeight: 600,
            fontSize: 16,
            width: 'auto'
        },
        hover: {
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        boxFlex: {
            display: 'flex'
        },
        personalitiesContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 5
        },
        itemLabel: {
            fontSize: '14px !important',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '90%',
            overflow: 'hidden',
            paddingRight: 8
        },
        item: {
            [theme.breakpoints.between(360, 500)]: {
                width: '45%'
            }
        },
        heartIcon: {
            color: '#EF4F57',
            fontWeight: 300,
            fontSize: 17,
            marginLeft: 5,
            [theme.breakpoints.up('md')]: {
                fontSize: 19,
                marginLeft: 7
            }
        },
        chip: {
            'width': 'auto',
            'height': 24,
            'marginBottom': 8,
            'marginRight': 8,
            'cursor': 'default',
            '& .MuiTypography-body1': {
                fontWeight: 500,
                fontSize: '14px !important'
            }
        }
    })
);

const icons = new Map([
    ['faAngry', faAngry],
    ['faMeh', faMeh],
    ['faSmile', faSmile],
    ['faEar', faEar],
    ['faPoo', faPoo]
]);

export const PetDescription: FunctionComponent<PetHeaderProps> = props => {
    const classes = useStyles(props);

    return (
        <Box>
            {!props.pet ? (
                <CircularProgress size="1.5rem" style={{ color: '#EAB464' }} />
            ) : (
                <Box className={clsx(classes.container, props.className)}>
                    <Box className={classes.petHeader}>
                        <Box
                            className={clsx(classes.petPopUp, classes.boxFlex)}
                        >
                            <Typography
                                className={clsx(classes.typoPetName, {
                                    [classes.hover]: props.onShowPetCard
                                })}
                                onClick={() =>
                                    props.onShowPetCard && props.onShowPetCard()
                                }
                            >
                                {props.pet?.name}
                            </Typography>
                            {props.pet?.hasMedicalConditions && (
                                <FontAwesomeIcon
                                    icon={faHeartPulse}
                                    className={classes.heartIcon}
                                />
                            )}
                        </Box>
                        <Box className={classes.boxFlex}>
                            <ItemLabelIcon
                                label={
                                    props.pet?.breed?.name
                                        ? props.pet?.breed?.name
                                        : 'Not defined'
                                }
                                icon={
                                    props.pet?.type?.singular === 'Cat'
                                        ? faCat
                                        : props.pet?.type?.singular === 'Dog'
                                        ? faDog
                                        : faNarwhal
                                }
                                className={classes.item}
                                classNameLabel={classes.itemLabel}
                            />
                            <ItemLabelIcon
                                label={
                                    props.pet?.size?.name
                                        ? props.pet.size.name
                                        : 'Not defined'
                                }
                                icon={faRuler}
                                classNameLabel={classes.itemLabel}
                            />
                        </Box>
                        <Box className={classes.personalitiesContainer}>
                            {props.pet?.personalities?.map(personality => (
                                <Chip
                                    key={personality.id}
                                    label={personality.conditionName}
                                    className={classes.chip}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default PetDescription;
