import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Button, PhoneField3, TextField2, ZipCodeField3 } from "components/UI";
import { RootState } from "store";
import { Address, createEmptyAddress } from "@spike/model";
import ProductVendor, { createEmptyVendor } from "model/ProductVendor";
import { ProductVendorsState, ProductVendorsStatus } from "reducers/productVendors/ProductVendorsState";
import { fetchThunk as fetchVendorsThunk, saveThunk } from "actions/productVendors/ProductVendorsActions";
import { fetchThunk as fetchCategoriesThunk } from "actions/productCategories/ProductCategoriesActions";
import { fetchProductsThunk } from "@spike/products-action";
import { FieldError } from "@spike/model";
import { validateRequired } from "utils/ValidationUtils";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApiClientWrapper } from "hooks";

interface Props {
  onAddVendor: (vendor: ProductVendor) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("md")]: {
        height: "37px"
      },
      [theme.breakpoints.up("lg")]: {
        height: "46px"
      },
      " & .MuiTypography-h6": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "&.MuiDialogTitle-root": {
        [theme.breakpoints.down("md")]: {
          paddingLeft: "34px",
          paddingTop: "9px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingLeft: "43px",
          paddingTop: "11px",
        },
      },
    },
    title: {
      fontWeight: 600,
      color: "#5E8677",
      letterSpacing: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "24px"
      },
    },
    container: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("md")]: {
          width: "663px",
          minWidth: "582px",
          height: "829px",
          borderRadius: "19px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "728px",
          minWidth: "728px",
          height: "829px",
          borderRadius: "24px",
        },
      },
      "& .MuiDialogContent-root": {
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
          padding: "29px",
        },
        [theme.breakpoints.up("lg")]: {
          padding: "36px",
        },
      }
    },
    dialogContainer: {
      width: "100%",
      "& .MuiDialog-paper": {
        overflowY: "hidden"
      },
      display: "flex",
      flexDirection: "column",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        marginTop: "42px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "52px",
      },
    },
    button: {
      [theme.breakpoints.down("md")]: {
        width: "128px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "160px",
      },
    },
    productsContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        height: "320px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "400px",
      },
    },
    check: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "19px",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "24px",
      },
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("md")]: {
          fontSize: "19px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "24px",
        },
      }
    },
    label: {
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    checked: {
      fontWeight: 600
    },
    checkboxContainer: {
      display: "flex"
    },
    productsScrollContainer: {
      [theme.breakpoints.down("md")]: {
        height: "320px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "400px",
      },
    },
    iconTimes: {
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer"
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        lineHeight: "19px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        lineHeight: "24px"
      },
    },
    cancel: {
      fontWeight: 600,
      cursor: "pointer",
      color: "#EAB464",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        lineHeight: "19px",
        marginRight: "24px",
        marginTop: "6px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        lineHeight: "24px",
        marginRight: "30px",
        marginTop: "8px"
      },
    },
    emptyProductsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    emptyProducts: {
      fontWeight: 600,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "22px",
      },
    },
    vendorContainer: {
      display: "flex",
      width: "100%",
    },
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        paddingRight: "11px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "14px",
      },
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
    nameContainer: {
      display: "flex",
      width: "100%",
    },
    contactContainer: {
      display: "flex",
      width: "100%",
     
    },
    middleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "30px",
      },
    },
    bottomContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "30px",
      },
    },
    marginRight: {
      [theme.breakpoints.down("md")]: {
        marginRight: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "16px",
      },
    },
    iconClose: {
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
      },
      float: "right",
      "&:hover": {
        cursor: "pointer"
      }
    },
  })
);

export const AddVendorDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const vendorsState = useSelector<RootState, ProductVendorsState>(state => state.productVendors);
  const status = useSelector<RootState, ProductVendorsStatus>(state => state.productVendors.status);
  const loading = useSelector<RootState, boolean>((state) => state.productVendors.loading);

  const [errors, setErrors] = useState<Array<FieldError>>([]);
  const [vendor, setVendor] = useState<ProductVendor>(createEmptyVendor());

  useEffect(() => {
    if (status === ProductVendorsStatus.SaveSuccess) {
      const vendorSelected = vendorsState.list.find((vend) => vend.uuid === vendor.uuid);
      let vendorAux = {...vendor};
      vendorAux.id = vendorSelected?.id;
      props.onAddVendor(vendorAux);
      dispatch(fetchProductsThunk(apiClientWrapper));   
      dispatch(fetchVendorsThunk());
      dispatch(fetchCategoriesThunk());
      props.onClose();
    }
  }, [status]);

  const cleanErrors = () => {
    setErrors([]);
  };

  const validate = () => {
    const errors: Array<FieldError> = [
      validateRequired("name", { ...vendor })
    ].filter((t): t is FieldError => !!t);

    setErrors([...errors]);

    return errors;
  };

  const isAddressEmpty = (address: Address): boolean => {
    return (
      address.street.trim().length === 0 &&
      address.city.trim().length === 0 &&
      address.state.trim().length === 0 &&
      address.zipcode.trim().length === 0
    );
  };

  const nameChangeHandler = (name: string) => {
    setVendor((prev: any) => {
      return {
        ...prev,
        name
      };
    });
  };

  const contactChangeHandler = (contact: string) => {
    setVendor((prev: any) => {
      return {
        ...prev,
        contact
      };
    });
  };

  const websiteChangeHandler = (website: string) => {
    setVendor((prev: any) => {
      return {
        ...prev,
        website
      };
    });
  };

  const phoneChangeHandler = (phone: string) => {
    setVendor((prev: any) => {
      return {
        ...prev,
        phone
      };
    });
  };

  const emailChangeHandler = (email: string) => {
    setVendor((prev: any) => {
      return {
        ...prev,
        email
      };
    });
  };

  const handleChangeStreet = (street: string) => {
    let vendorAux = {...vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress();
    vendorAux.address = address;
    vendorAux.address.street = street;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    }
  };

  const handleChangeCity = (city: string) => {
    let vendorAux = {...vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress();
    vendorAux.address = address;
    vendorAux.address.city = city;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    } 
    setVendor(vendorAux);
  };

  const handleChangeState = (state: string) => {
    let vendorAux = {...vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress();
    vendorAux.address = address;
    vendorAux.address.state = state;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    } 
    setVendor(vendorAux);
  };

  const handleChangeZipCode = (zipcode: string) => {
    let vendorAux = {...vendor};
    const address = vendorAux.address ? vendorAux.address : createEmptyAddress();
    vendorAux.address = address;
    vendorAux.address.zipcode = zipcode;

    if(isAddressEmpty(address)) {
      vendorAux.address = undefined;
    }
    setVendor(vendorAux);
  };

  const saveVendorHandler = () => {
    cleanErrors();
    const validationErrors = validate();
    if(validationErrors.length === 0) {
      dispatch(saveThunk(vendor));
    }
  };
  
  return (<Box>
    <Dialog open={true} className={clsx(classes.container)}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Add Vendor</Typography>
        <FontAwesomeIcon icon={faTimes} className={classes.iconClose} onClick={() => props.onClose()} />
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <Box className={classes.vendorContainer}>
          <Box className={classes.leftContainer}>
            <Box className={classes.nameContainer}>
              <TextField2 
                label="Company Name"
                name="name"
                value={vendor.name || ""}
                placeholder={"Company Name"}
                required={true}
                onChange={nameChangeHandler}
                capitalize={true}
                errors={errors}
              />
            </Box>
          </Box>
          <Box className={classes.rightContainer}>
            <Box className={classes.contactContainer}>
              <TextField2 
                label="Contact Name"
                name="contact"
                value={vendor.contact || ""}
                placeholder={"Contact Name"}
                onChange={contactChangeHandler}
                capitalize={true}
              />
            </Box>
          </Box>
        </Box>
        <Box>
        <Box className={classes.middleContainer}>
          <TextField2 
            label="Website"
            name="website"
            value={vendor.website || ""}
            placeholder={"www.example.com"}
            onChange={websiteChangeHandler}
          />
        </Box>
        <Box className={classes.middleContainer}>
          <PhoneField3 
            label="Phone Number"
            name="phone"
            placeholder="Phone Number"
            value={vendor.phone || ""}
            onChange={phoneChangeHandler}
          />
        </Box>
        <Box className={classes.middleContainer}>
          <TextField2 
            label="Email Address"
            name="email"
            value={vendor.email || ""}
            placeholder={"Email Address"}
            onChange={emailChangeHandler}
          />
        </Box>
        <Box className={classes.middleContainer}>
          <TextField2 
            label="Address"
            name="street"
            value={vendor.address?.street ? vendor.address.street : ""}
            placeholder={"Address"}
            onChange={handleChangeStreet}
            capitalize={true}
          />
        </Box>
        <Box className={classes.bottomContainer}>
          <TextField2 
            label="City"
            name="city"
            value={vendor.address?.city ? vendor.address.city : ""}
            placeholder={"City"}
            onChange={handleChangeCity}
            capitalize={true}
            className={clsx(classes.marginRight)}
          />
          <TextField2 
            label="State"
            name="state"
            value={vendor.address?.state ? vendor.address.state : ""}
            placeholder={"State"}
            onChange={handleChangeState}
            capitalize={true}
            className={clsx(classes.marginRight)}
          />
          <ZipCodeField3
            label="Zipcode"
            name="zipcode"
            value={vendor.address?.zipcode ? vendor.address.zipcode : ""}
            placeholder={"Zipcode"}
            onChange={handleChangeZipCode}
          />
        </Box>
        </Box>
        <Box className={classes.buttonsContainer}>
         <Button
            label="Add Vendor"
            loading={loading}
            onClick={saveVendorHandler}
            variant="primary"
            size="medium"
            color="orange"
            className={classes.button}
          />
        </Box>
      </DialogContent>
    </Dialog>
  </Box>);
};

export default AddVendorDialog;