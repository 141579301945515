import BusinessSettingsComponent from "components/Marketplace/BusinessSettings";
import React, { FunctionComponent } from "react";
import PrivatePage from "./PrivatePage";

export const BusinessSettings: FunctionComponent = () => {
  return (
    <PrivatePage title="Business Settings - Cuddles" pageName="/business_settings">
      <BusinessSettingsComponent />
    </PrivatePage>
  );
};

export default BusinessSettings;
