import React from 'react';
import { createStyles, Drawer, makeStyles, Theme } from '@material-ui/core';
import { AddSlot } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/AddSlot/AddSlot';
import { Period } from '@spike/model';
import { Moment } from 'moment-timezone';
import Staff from 'model/Staff';

export interface MultiSlotDrawerProps {
    open: boolean;
    period: Period;
    selectedStaff?: Staff;
    defaultDate?: Moment;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiDrawer-paperAnchorRight': {
                width: '100%',

                [theme.breakpoints.up('md')]: {
                    width: 590
                }
            }
        }
    })
);

export enum MultiSlotDrawerView {
    AddSlot = 'addSlot',
    SetupDefaults = 'setupDefaults'
}

export const MultiSlotDrawer: React.FC<MultiSlotDrawerProps> = props => {
    const classes = useStyles();

    const [selectedView, setSelectedView] = React.useState<MultiSlotDrawerView>(MultiSlotDrawerView.AddSlot);

    React.useEffect(() => {
        if (!props.open) {
            setSelectedView(MultiSlotDrawerView.AddSlot);
        }
    }, [props.open]);

    return (
        <Drawer open={props.open} anchor="right" onClose={props.onClose} className={classes.root}>
            {selectedView === MultiSlotDrawerView.AddSlot && (
                <AddSlot
                    period={props.period}
                    defaultDate={props.defaultDate}
                    selectedStaff={props.selectedStaff}
                    onClose={props.onClose}
                    onViewChange={setSelectedView}
                />
            )}
        </Drawer>
    );
};
