import { PetVaccine, VaccineStatus } from "@spike/medical-history-model";
import moment from "moment";

export const vaccineWarning = (vaccine: PetVaccine) => {
    let validation: boolean = false;

    if (vaccine.expiration
        && (vaccine.expiration.isValid())
        && (vaccine.status === VaccineStatus.EXPIRED || moment() > vaccine.expiration)
    ) {
        validation = true;
    };

    return validation;
}

export const vaccinesWarning = (vaccines: Array<PetVaccine>) => {
    let validation: boolean = false;

    vaccines.map((vaccine) => {
        if (vaccine.expiration
            && (vaccine.expiration.isValid())
            && (vaccine.status === VaccineStatus.EXPIRED || moment() > vaccine.expiration)
        ) {
            validation = true;
        };

        return "";
    })

    return validation;
}

export const vaccineAttention = (vaccine: PetVaccine) => {
    let validation: boolean = false;

    if (vaccine.expiration
        && !vaccine.expiration.isValid()) {
        validation = true;
    }

    return validation;
}

export const vaccinesAttention = (vaccines: Array<PetVaccine>) => {
    let validation: boolean = false;

    vaccines.map((vaccine) => {
        if (vaccine.expiration
            && !vaccine.expiration.isValid()) {
            validation = true;
        }
        return "";
    });

    return validation;
}