import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    ClickAwayListener,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import DropdownItem from './DropdownItem';
import { DropdownOption } from './model';
import useNonInitialEffect from '@versiondos/hooks';

interface DropdownProps {
    options: Array<DropdownOption>;
    onClick: (optionId: string) => void;
    id?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '40px'
        },
        toggleIcon: {
            padding: 5,
            cursor: 'pointer'
        },
        dropdown: {
            top: 30,
            right: 0,
            width: 'max-content',
            borderRadius: 14,
            padding: '10px 0px',
            position: 'absolute',
            backgroundColor: '#fff',
            border: 'solid 1px #000',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column'
        }
    })
);

const Dropdown: FunctionComponent<DropdownProps> = props => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    return (
        <Box className={classes.container}>
            <span
                className={classes.toggleIcon}
                onClick={() => {
                    setOpen(prev => !prev);
                }}
            >
                <FontAwesomeIcon icon={faEllipsis} />
            </span>
            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Box className={classes.dropdown}>
                        {props.options.map(option => (
                            <DropdownItem
                                key={option.id}
                                option={option}
                                onClick={() => {
                                    props.onClick(option.id);
                                    setOpen(false);
                                }}
                            />
                        ))}
                    </Box>
                </ClickAwayListener>
            )}
        </Box>
    );
};

export default Dropdown;
