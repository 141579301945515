import { Grid, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { FieldError } from "@spike/model";
import { PhoneField3, TextField2 } from "components/UI";
import set from "lodash/set";
import VetModel from "@spike/vet-model";
import { FunctionComponent } from "react";

interface EditVetProps {
  vet: VetModel;
  errors?: Array<FieldError>;
  onChange?: (vet: VetModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
        "& input":{
          height: "24px"
        }
      }
    }
  })
);

export const EditVet: FunctionComponent<EditVetProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  
  // eslint-disable-next-line
  const changeValueHandler = (value: any, fieldName?: string) => {
    if (fieldName) {
      const updatedVet = { ...props.vet };
      set(updatedVet, fieldName.slice("vet_".length), value);
      props.onChange && props.onChange(updatedVet);
    }
  };
  
  return (
    <Grid xs={12} spacing={isSmall ? 0 : 2} container>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <TextField2
          name="vet_name"
          label="Name"
          value={props.vet.name || ""}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
          placeholder="Name"
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <TextField2
          name="vet_businessName"
          label="Business Name"
          value={props.vet.businessName || ""}
          onChange={changeValueHandler}
          errors={props.errors}
          required={true}
          placeholder="Business Name"
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <PhoneField3
          name="vet_phone"
          label="Phone"
          value={props.vet.phone || ""}
          onChange={changeValueHandler}
          errors={props.errors}
          placeholder="Phone Number"
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <TextField2
          name="vet_email"
          label="Email"
          value={props.vet.email || ""}
          onChange={changeValueHandler}
          errors={props.errors}
          placeholder="Email"
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <TextField2
          name="vet_address"
          label="Address"
          value={props.vet.address || ""}
          onChange={changeValueHandler}
          errors={props.errors}
          placeholder="Address"
        />
      </Grid>
    </Grid>
  );
};

export default EditVet;
