import { faEnvelope, faHouseMedical, faMapPin, faPhoneFlip, faStethoscope } from "@fortawesome/pro-light-svg-icons";
import { Box } from "@material-ui/core";
import VetModel from "@spike/vet-model";
import { FunctionComponent } from "react";
import VetRow from "./VetRow";
import { formatPhoneNumber } from "@spike/phone-utils";
import { useMarketplace } from "hooks";

interface VetProps {
  vet?: VetModel;
}

export const Vet: FunctionComponent<VetProps> = (props) => {
  const marketplace = useMarketplace();

  return (
    <Box>
      <VetRow icon={faStethoscope} text={props.vet?.name} />
      <VetRow icon={faHouseMedical} text={props.vet?.businessName} />
      <VetRow icon={faPhoneFlip} text={formatPhoneNumber(props.vet?.phone||"", marketplace.basics.address.country?.id||"")} underline={true} />
      <VetRow icon={faEnvelope} text={props.vet?.email} underline={true} />
      <VetRow icon={faMapPin} text={props.vet?.address} />
    </Box>
  );
};

export default Vet;
