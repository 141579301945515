import {ThunkAction} from "redux-thunk";
import store from "../../store";
import {AxiosResponse} from "axios";
import ApiClient from "../../api";
import {createTokenConfig, isCancelled} from "../../api/ApiClient";
import { PetTypeStatusState } from "reducers/businessSettings/PetTypeStatusState";
import { BusinessSettingsAction, BUSINESS_SETTINGS_ERROR_ACTION_TYPE, BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE, BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE } from "./BusinessSettingsActionsTypes";

const businessSettingsUrl = "/marketplace/pet_type_status";

export const fetchPetTypeStatusThunk = (): ThunkAction<void, PetTypeStatusState, null, BusinessSettingsAction> => {
    return async (dispatch) => {
        dispatch(fetchStart())
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${businessSettingsUrl}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<PetTypeStatusState> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
            dispatch(fetchSuccess(response.data));
        } catch (apiError) {
            if(!isCancelled(apiError)) {
                dispatch(error());
            }
        }
    }
}

const fetchStart = (): BusinessSettingsAction => {
    return {
        type: BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE
    }
}

export const fetchSuccess = (petTypeStatusState: PetTypeStatusState): BusinessSettingsAction => {
    return {
        type: BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            pet_size: petTypeStatusState.pet_size,
            pet_age: petTypeStatusState.pet_age,
            pet_hair_type: petTypeStatusState.pet_hair_type,
            pet_hair_length: petTypeStatusState.pet_hair_length,
            exotic_types: petTypeStatusState.exotic_types
        }
    }
}

const error = (): BusinessSettingsAction => {
    return {
        type: BUSINESS_SETTINGS_ERROR_ACTION_TYPE
    }
}
