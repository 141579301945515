import { Action } from "redux";
import { TipInvoice } from "model/TipsReport";

//Action Types
export const TIPSREPORT_GET_DETAIL_START_ACTION_TYPE = "tipStaffs/getDetailReport_start";
export const TIPSREPORT_GET_DETAIL_SUCCESS_ACTION_TYPE = "tipStaffs/getDetailReport_success";
export const TIPSREPORT_ERROR_ACTION_TYPE = "tipsReport/error";

//Actions

interface TipsReportGetDetailStartAction extends Action<typeof TIPSREPORT_GET_DETAIL_START_ACTION_TYPE> {}

interface TipsReportGetDetailSuccessAction extends Action<typeof TIPSREPORT_GET_DETAIL_SUCCESS_ACTION_TYPE> {
  payload: {
    invoices: Array<TipInvoice>
    totalTips?: number | string,
    totalAppointments?: number | string
  };
}

interface TipsReportErrorAction extends Action<typeof TIPSREPORT_ERROR_ACTION_TYPE> {}

export type TipsReportAction =
  | TipsReportGetDetailStartAction
  | TipsReportGetDetailSuccessAction
  | TipsReportErrorAction;