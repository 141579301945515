import { faCheck, faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

export enum Steps {
    BANK_ACCOUNT,
    BANK_ACCOUNT_DOCUMENT,
    DONE
}

export interface OptionType {
    id: string;
    icon: IconDefinition;
    title: string;
    disabled?: boolean;
}

export enum OptionTypes {
    SetActive = 'active',
    UploadDocument = 'upload',
    Delete = 'delete'
}

const setActiveOption: OptionType = {
    id: 'active',
    title: 'Set as Primary',
    icon: faCheck
};
const deleteOption: OptionType = {
    id: 'delete',
    title: 'Delete Account',
    icon: faTrashAlt
};
const uploadDocumentOption: OptionType = {
    id: 'upload',
    title: 'Upload Document',
    icon: faFileUpload
};

export const bankAccountOptions: Array<OptionType> = [setActiveOption];
