import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent } from "react";

interface HeaderBodyFooterProps {
  header?: JSX.Element
  body: JSX.Element
  footer?: JSX.Element
  containerStyle?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      width: 'auto',
      flex: 'auto',
    },
    body: {
      display: 'flex',
      flex: 1
    }

  }))
const HeaderBodyFooter: FunctionComponent<HeaderBodyFooterProps> = (props) => {
  const classes = useStyles()
  return (
    <Box className={clsx(classes.container, props.containerStyle)} onClick={(e) => e.stopPropagation()}>
      {props?.header}
      <Box className={classes.body}>
        {props.body}
      </Box>
      {props?.footer}
    </Box>
  )
}

export default HeaderBodyFooter