import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "components/UI";
import { Fragment, FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import Title from "../../ui/Title";

interface TerminalHeaderProps {
  showButton: boolean;
  className?: string;
  //
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFF",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(28)}px ${reduceResolution(24)}px ${reduceResolution(10)}px ${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "28px 24px 10px 24px",
      },
    },

    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(wbp)]: {
        paddingRight: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingRight: "22px",
      },
    },

    left: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },

    right: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
  })
);

export const TerminalHeader: FunctionComponent<TerminalHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={clsx(classes.container, props.className)}>
        <Box className={classes.titleContainer}>
          <Box className={classes.left}>
            <Title text="Terminals" />
          </Box>
          {props.showButton && (
            <Box className={classes.right}>
              <Button label={"+ Order Terminal"} onClick={() => props.onClick && props.onClick()} />
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default TerminalHeader;
