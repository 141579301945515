import { MasterDataAction, set as setMasterData } from "@spike/masterdata-action";
import {
	NotificationsAction,
	showError,
	showSuccess,
} from "@spike/notifications-action";
import { loginSuccess } from "actions/login/LoginActions";
import { LoginAction } from "actions/login/LoginActionsTypes";
import { convert } from "actions/login/LoginConverter";
import { LoginResponseDto } from "actions/login/LoginDtos";
import { setMarketplaceDto, MarketplaceAction } from "@spike/marketplace-action";
import ApiClient, { isCancelled } from "api/ApiClient";
import { removeFromStorage } from "auth";
import { AxiosResponse } from "axios";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { ActivateAccountState } from "reducers/activateAccount/ActivateAccountState";
import { ThunkAction } from "redux-thunk";
import {
	ActivateAccountAction,
	ACTIVATE_ACCOUNT_END_ACTION_TYPE,
	ACTIVATE_ACCOUNT_FAIL_ACTION_TYPE,
	ACTIVATE_ACCOUNT_RESET_ACTION_TYPE,
	ACTIVATE_ACCOUNT_START_ACTION_TYPE,
} from "./ActivateAccountActionsTypes";
import { activateAccountHubspot } from "actions/hubspot/HubSpotActions";

const activateAccountUrl = "activate_account";

export const activateAccountThunk = (
	token: string
): ThunkAction<
	void,
	ActivateAccountState,
	null,
	| ActivateAccountAction
	| CallHistoryMethodAction
	| NotificationsAction
	| LoginAction
	| MasterDataAction
	| MarketplaceAction
> => {
	return async (dispatch) => {
		removeFromStorage();

		dispatch(start());

		const HTTP_STATUS_401_UNAUTHORIZED = 401;

		try {
			const response: AxiosResponse<LoginResponseDto> = await ApiClient.post(
				activateAccountUrl,
				{
					access_token: token,
				}
			);

			dispatch(setMasterData(response.data.master_data));
			dispatch(
				setMarketplaceDto(
					response.data.marketplace,
					response.data.master_data
				)
			);
			dispatch(loginSuccess(convert(response.data, true)));
			dispatch(activateAccountHubspot(response.data.user.email));
			dispatch(showSuccess("Email verified!"));
		} catch (apiError: any) {
			if (!isCancelled(apiError)) {
				if (
					apiError.response &&
					apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED
				) {
					dispatch(fail());
					dispatch(showError("Email verification failed!"));
				} else {
					console.error(apiError);
					dispatch(showError("Error verifying email!"));
				}
			}
		}
		dispatch(push("/"));
		dispatch(end());
	};
};

export const reset = (): ActivateAccountAction => {
	return {
		type: ACTIVATE_ACCOUNT_RESET_ACTION_TYPE,
	};
};

const start = (): ActivateAccountAction => {
	return {
		type: ACTIVATE_ACCOUNT_START_ACTION_TYPE,
	};
};

const fail = (): ActivateAccountAction => {
	return {
		type: ACTIVATE_ACCOUNT_FAIL_ACTION_TYPE,
	};
};

const end = (): ActivateAccountAction => {
	return {
		type: ACTIVATE_ACCOUNT_END_ACTION_TYPE,
	};
};
