import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent, MouseEvent } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface TipProps {
	percentage: string | null;
	amount: string;
	selected?: boolean;
	loading?: boolean;
	className?: string;
	onClick?: () => void;
	id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			gap: 5,
			height: 82,
			width: '100%',
			display: 'flex',
			cursor: 'pointer',
			alignItems: 'center',
			position: 'relative',
			flexDirection: 'column',
			justifyContent: 'center',
			backgroundColor: 'white',
			border: '1px solid #D3D3D3',
			borderRadius: 12,

			[theme.breakpoints.up(wbp)]: {
				height: 93,
				width: 160
			}
		},
		loading: {
			opacity: 0.2
		},
		selectedBorder: {
			border: '2px solid #222222'
		},
		mainText: {
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,
			color: '#222222',
			letterSpacing: '-1px',

			[theme.breakpoints.up('md')]: {
				fontSize: 24
			}
		},
		subText: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 400,
			color: '#7A7A7A'
		},
		icon: {
			position: 'absolute',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(20)}px`,
				top: `${reduceResolution(11)}px`,
				right: `${reduceResolution(11)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px',
				top: '11px',
				right: '11px'
			}
		},
		unselectedIcon: {
			color: '#DDDDDD'
		},
		selectedIcon: {
			color: '#5E8677'
		}
	})
);

export const Tip: FunctionComponent<TipProps> = props => {
	const classes = useStyles();

	const clickHandler = (event: MouseEvent<HTMLElement>) => {
		props.onClick && !props.selected && !props.loading && props.onClick();
	};
	return (
		<Box
			id={props.id}
			className={clsx(
				classes.container,
				{
					[classes.selectedBorder]: props.selected,
					[classes.loading]: props.loading
				},
				props.className
			)}
			onClick={clickHandler}
		>
			{props.selected ? (
				<FontAwesomeIcon
					icon={faCheckCircle}
					className={clsx(classes.icon, classes.selectedIcon)}
				/>
			) : (
				<FontAwesomeIcon
					icon={faCircle}
					className={clsx(classes.icon, classes.unselectedIcon)}
				/>
			)}
			{props.percentage ? (
				<>
					<Typography className={classes.mainText}>
						{props.percentage}%
					</Typography>
					<Typography className={classes.subText}>
						or ${props.amount}
					</Typography>
				</>
			) : (
				<Typography className={classes.mainText}>
					${props.amount}
				</Typography>
			)}
		</Box>
	);
};

export default Tip;
