import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { Period } from '@spike/model';

import DetailTableHeader from './Table/DetailTableHeader';
import DetailTableSummary from './Table/DetailTableSummary';
import { Option } from '@spike/model';
import DetailTable from './Table/DetailTable';
import { Data, Headers } from 'react-csv/lib/core';
import DetailTableCell from './Table/DetailTableModels';

interface DetailTableDashboardProps {
    title: string;
    period: Period;
    totals: Array<Option<number | string>>;
    staffOptions: Array<Option<number>>;
    headers: Array<DetailTableCell>;
    rows: Array<Array<DetailTableCell>>;
    loading: boolean;
    export?: boolean;
    csv?: {
        fileName?: string | undefined;
        data: string | Data | (() => string | Data);
        headers?: Headers | undefined;
    };
    onChangePeriod: (period: Period) => void;
    onStaffChange: (staffs: Array<number>, all: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: 'fit-content',
            padding: '24px 0px',

            [theme.breakpoints.up('md')]: {
                padding: '50px 24px'
            }
        },
        title: {
            color: '#222',
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px'
            },

            [theme.breakpoints.up('md')]: {
                fontSize: '24px'
            }
        },
        mobileWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
                padding: '24px 16px 0px',
                backgroundColor: '#FAFAFA'
            }
        }
    })
);

export const DetailTableDashBoard: FunctionComponent<
    DetailTableDashboardProps
> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>{props.title}</Typography>

            <Box className={classes.mobileWrapper}>
                <DetailTableHeader
                    period={props.period}
                    staffOptions={props.staffOptions}
                    export={props.export}
                    csv={props.csv}
                    onChangePeriod={period => props.onChangePeriod(period)}
                    onStaffChange={props.onStaffChange}
                />
                <DetailTableSummary
                    loading={props.loading}
                    totals={props.totals}
                />
            </Box>

            <DetailTable
                loading={props.loading}
                headers={props.headers}
                rows={props.rows}
            />
        </Box>
    );
};

export default DetailTableDashBoard;
