import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Img } from "react-image";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
    },
    image: {
      [theme.breakpoints.down("lg")]: {
        width: "315px",
        height: "221px",
        marginTop: "36px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "478px",
        height: "320px",
        marginTop: "55px"
      },
    },
    title: {
      textAlign: "center",
      fontWeight: 700,
      [theme.breakpoints.down("lg")]: {
        marginTop: "19px",
        lineHeight: "29px",
        fontSize: "19px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "29px",
        lineHeight: "36px",
        fontSize: "24px",
      },
    },
    description: {
      textAlign: "center",
      fontWeight: 500,
      [theme.breakpoints.down("lg")]: {
        marginTop: "5px",
        lineHeight: "17px",
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "8px",
        lineHeight: "21px",
        fontSize: "15px",
      },
    },
  })
);

export const StaffNotSelected: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Img
        className={classes.image}
        src="/images/bookings/StaffNotSelected.svg"
      />
      <Typography className={classes.title}>No staff has been selected.
      </Typography>
      <Typography className={classes.description}>Please select at least one staff<br />
        member to show appts.
      </Typography>
    </Box>
  );
};

export default StaffNotSelected;
