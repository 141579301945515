import React, { FunctionComponent } from 'react';
import { Box, Collapse, Divider, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import AmountTable from './AmountTable';
import clsx from 'clsx';

interface OrderDetailsProps {
    orderDetailsRows: Array<{ label: string; value: string }>;
    orderDetailsOpen: boolean;
    toggleOrderDetails: () => void;
    classes: Record<string, string>;
}

const OrderDetails: FunctionComponent<OrderDetailsProps> = ({
    orderDetailsRows,
    orderDetailsOpen,
    toggleOrderDetails,
    classes
}) => {
    return (
        <Box
            className={clsx(
                classes.amountContainer,
                classes.amountContainerOrderDetails
            )}
        >
            <Box
                className={classes.orderDetailsHeader}
                onClick={toggleOrderDetails}
            >
                <Typography
                    className={classes.amountContainerTitle}
                    variant="body1"
                >
                    Order Details
                </Typography>
                <FontAwesomeIcon
                    className={`${classes.orderDetailsIcon} ${
                        orderDetailsOpen ? classes.orderDetailsIconOpen : ''
                    }`}
                    icon={faChevronDown}
                />
            </Box>
            <Collapse in={orderDetailsOpen} timeout="auto" unmountOnExit>
                <AmountTable
                    title=""
                    rows={orderDetailsRows}
                    className={classes.orderDetailsTable}
                />
            </Collapse>

            <Grid
                style={{ marginTop: '18px' }}
                item
                xs={12}
                className={classes.divider}
            >
                <Divider />
            </Grid>
        </Box>
    );
};

export default OrderDetails;
