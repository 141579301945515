import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { sendThunk } from 'actions/agreements/AgreementsActions';
import Client from '@spike/client-model';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { Agreement } from 'model/Agreements';
import PortalModal from 'components/UI/Portals';
import { SelectedClient } from './model';
import Footer from './Footer';
import ClientInfo from './ClientInfo';
import SelectAgreement from './SelectAgreement';
import Alert from './Alert';
import Channel from './Channel';
import SearchClients from './SearchClients';
import HeaderBodyFooter from 'components/UI/Portals/variants/HeaderBodyFooter';
import IconCircle from 'components/UI/IconCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

interface SendModalProps {
    agreementId?: number;
    client?: Client;
    isActive: boolean;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: 32,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            background: theme.palette.background.paper,
            width: 600,
            borderRadius: 12,
            [theme.breakpoints.down('sm')]: {
                paddingTop: 0,
                width: '100%'
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 32px',
            [theme.breakpoints.down('sm')]: {
                height: 66,
                justifyContent: 'space-between'
            },
            icon: {
                height: 20,
                width: 20
            }
        },
        headerTitle: {
            'fontWeight': 600,
            '& > span': {
                fontSize: 24,
                fontWeight: 600
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
                fontWeight: 600
            }
        },
        markIcon: {
            height: 20,
            width: 20
        },

        body: {
            height: 'auto',
            minHeight: 540,
            width: 600,
            display: 'flex',
            flexDirection: 'column',
            padding: '0 32px 20px 32px',
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                height: '100%',
                width: '100%'
            }
        },
        sentBody: {
            height: 658,
            width: 600,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            textAlign: 'center',
            padding: '80px 0px',
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                height: 'auto',
                width: '100%'
            }
        },
        sentIcon: {
            height: 80,
            width: 80,
            backgroundColor: '#5E8677 !important'
        },
        sentTitle: {
            fontFamily: 'YoungSerif',
            padding: 0,
            margin: '20px 0px 11px 0px',
            color: '#BAA997',
            fontSize: 44,
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: 24,
                fontFamily: 'Poppins',
                color: 'black'
            }
        },
        sentText: {
            maxWidth: 400,
            padding: 0,
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 364
            }
        }
    })
);

const SendModal: FunctionComponent<SendModalProps> = props => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const classes = useStyles();

    const dispatch = useDispatch();

    const agreementsStatus = useSelector<RootState, AgreementsStatus>(
        state => state.agreements.status
    );

    const [channel, setChannel] = useState('email');
    const [selectedClients, setSelectedClients] = useState<
        Array<SelectedClient>
    >([]);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [selectedAgreement, setSelectedAgreement] =
        useState<Agreement | null>(null);

    useNonInitialEffect(() => {
        switch (agreementsStatus) {
            case AgreementsStatus.SendSuccess:
                setSending(false);
                setSent(true);
                break;
            case AgreementsStatus.Error:
                setSending(false);
                break;
        }
    }, [agreementsStatus]);

    const sendHandler = () => {
        setSending(true);
        props.agreementId &&
            dispatch(
                sendThunk(
                    props.agreementId,
                    selectedClients.map(client => client.id),
                    channel as 'email' | 'sms'
                )
            );
        props.client &&
            selectedAgreement &&
            dispatch(
                sendThunk(
                    selectedAgreement!.id!,
                    [props.client.id!],
                    channel as 'email' | 'sms'
                )
            );
    };

    const sendOtherHandler = () => {
        setSelectedClients([]);
        setSent(false);
    };

    const finishHandler = () => {
        setSelectedClients([]);
        setSent(false);
        props.onClose && props.onClose();
    };

    return (
        <PortalModal
            mode={isMobile ? 'fullModal' : 'modal'}
            isActive={props.isActive}
            onClose={finishHandler}
        >
            <HeaderBodyFooter
                containerStyle={classes.container}
                header={
                    sent ? undefined : (
                        <Box className={classes.header}>
                            <Typography className={classes.headerTitle}>
                                Send Agreement
                            </Typography>
                            {isMobile && (
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className={classes.markIcon}
                                    onClick={finishHandler}
                                />
                            )}
                        </Box>
                    )
                }
                body={
                    sent ? (
                        <Box className={classes.sentBody}>
                            <IconCircle
                                className={classes.sentIcon}
                                icon={faPaperPlane}
                            />
                            <Typography className={classes.sentTitle}>
                                Agreement sent
                            </Typography>
                            <p className={classes.sentText}>
                                The agreement has been successfully sent to{' '}
                                <b>
                                    {channel === 'sms'
                                        ? selectedClients.map(
                                              (client, index) =>
                                                  client.phone +
                                                  (index <
                                                  selectedClients.length - 1
                                                      ? ', '
                                                      : '.')
                                          )
                                        : selectedClients.map(
                                              (client, index) =>
                                                  client.email +
                                                  (index <
                                                  selectedClients.length - 1
                                                      ? ', '
                                                      : ' .')
                                          )}
                                </b>
                            </p>
                        </Box>
                    ) : (
                        <Box className={classes.body}>
                            {props.client &&
                                (!props.client?.email ||
                                    !props.client?.phone) && (
                                    <Alert clientInfo={props.client} />
                                )}
                            {props.client && (
                                <ClientInfo
                                    onEdit={undefined}
                                    clientInfo={props.client}
                                />
                            )}
                            {props.client && (
                                <SelectAgreement
                                    placeholder="Choose an agreement"
                                    onSelect={setSelectedAgreement}
                                />
                            )}
                            <Channel
                                clientInfo={props.client}
                                setChannel={value => {
                                    setChannel(value);
                                    setSelectedClients([]);
                                }}
                                channel={channel}
                            />
                            {!props.client && (
                                <SearchClients
                                    placeholder="Search clients by name"
                                    selectedClients={selectedClients}
                                    onChangeSelection={setSelectedClients}
                                    show={
                                        channel === 'email' ? 'email' : 'phone'
                                    }
                                />
                            )}
                        </Box>
                    )
                }
                footer={
                    <Footer
                        rightButtonAction={
                            sent ? sendOtherHandler : sendHandler
                        }
                        rightButtonLabel={sent ? 'Send Other' : 'Send'}
                        leftButtonAction={finishHandler}
                        leftButtonLabel={sent ? 'Close' : 'Cancel'}
                        sending={sent ? undefined : sending}
                        sendDisabled={
                            sent
                                ? undefined
                                : selectedClients.length === 0 &&
                                  selectedAgreement === null
                        }
                    />
                }
            />
        </PortalModal>
    );
};

export default SendModal;
