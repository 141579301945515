import { FunctionComponent } from "react";
import { Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface ItemLabelReadProps {
  title?: string;
  description?: string | JSX.Element;
  classTitle?: string;
  className?: string;
  classDescription?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 400,
      whiteSpace: "nowrap",
      color: "#919191",
      textTransform: "uppercase",
      [theme.breakpoints.down(wbp)]: {
        letterSpacing: `${reduceResolution(1.12)}px`,
        lineHeight: `${reduceResolution(21)}px`,
        paddingBottom: `${reduceResolution(12)}px`,
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        letterSpacing: "1.12px",
        lineHeight: "21px",
        paddingBottom: "12px",
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        letterSpacing: "0px",
        lineHeight: "18px",
        paddingBottom: "12px",
        fontSize: "14px",
        textTransform: "none",
        color: "#000",
        fontWeight: 500,
      },
    },
    description: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
      },

    }
  })
);

export const ItemLabelRead: FunctionComponent<ItemLabelReadProps> = (props) => {
  const classes = useStyles();

  const typoDescription = (<Typography className={clsx([classes.description, props.classDescription])}>{props.description}</Typography>);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {props.title && <Typography className={clsx(classes.title, props.classTitle)}>{props.title && props.title}</Typography>}
      {
        props.description && (
          (typeof props.description === "string") ? typoDescription : props.description
        )
      }
    </Box>
  );
};

export default ItemLabelRead;
