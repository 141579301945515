import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { InvoiceLine } from '@spike/invoice-model';
import { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import InvoiceExtra from './InvoiceExtra';
import InvoiceExtraHeader from './InvoiceExtraHeader';

interface InvoiceExtrasProps {
	lines: Array<InvoiceLine>;
	saving: boolean;
	errors: Array<FieldError>;
	className?: string;
	type: 'product' | 'addOnService';
	editedUuidLine: string | undefined;
	onAdd?: (invoiceLine: InvoiceLine) => void;
	onRemove?: (invoiceLine: InvoiceLine) => void;
	onSave?: (invoiceLine: InvoiceLine) => void;
	onEdit?: (uuidLine: string) => void;
	onCancelEdit?: (uuidLine: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},

		line: {
			marginBottom: 24,

			[theme.breakpoints.up('md')]: {
				marginTop: '28px'
			}
		},

		addButton: {
			height: 39,
			marginRight: 'auto',

			[theme.breakpoints.down('sm')]: {
				'border': '0px !important',
				'color': '#ffffff !important',
				'backgroundColor': '#92B4A7',

				'&[disabled]': {
					backgroundColor: '#D3D3D3 !important'
				}
			},
			[theme.breakpoints.up('md')]: {
				height: 47,
				marginTop: '50px'
			}
		}
	})
);

export const InvoiceExtras: FunctionComponent<InvoiceExtrasProps> = props => {
	const classes = useStyles(props);

	const extraLines = props.lines.filter(
		line =>
			(props.type === 'product' && line.isProduct) ||
			(props.type === 'addOnService' && line.isAddOnService)
	);

	const addHandler = (type: 'product' | 'addOnService') => {
		props.onAdd &&
			props.onAdd({
				uuid: uuid(),
				isProduct: props.type === 'product',
				isAddOnService: props.type === 'addOnService',
				subtotal: '0.00',
				quantity: 1,
				discount: { amount: '0.00' },
				total: '0.00',
				taxesAmount: '0.00',
				taxes: []
			});
	};

	const deleteHandler = (uuid: string) => {
		const removedLine = props.lines.find(line => line.uuid === uuid);
		props.onRemove && removedLine && props.onRemove(removedLine);
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			{extraLines.length > 0 && <InvoiceExtraHeader />}
			{extraLines.map(line => (
				<InvoiceExtra
					key={line.uuid}
					otherSelectedProductIds={props.lines
						.filter(
							otherLine =>
								otherLine.uuid !== line.uuid &&
								otherLine.isProduct &&
								line.productId !== otherLine.productId
						)
						.map(otherLine => otherLine.productId!)}
					otherSelectedAddOnServiceIds={props.lines
						.filter(
							otherLine =>
								otherLine.uuid !== line.uuid &&
								otherLine.isAddOnService &&
								line.addOnServiceId !== otherLine.addOnServiceId
						)
						.map(otherLine => otherLine.addOnServiceId!)}
					line={line}
					editable={[line.uuid, undefined].includes(
						props.editedUuidLine
					)}
					saving={props.saving}
					className={classes.line}
					errors={props.errors}
					onDelete={deleteHandler}
					onSave={props.onSave}
					onEdit={props.onEdit}
					onCancelEdit={props.onCancelEdit}
				/>
			))}
			<Button
				label={`+ Add ${
					props.type === 'product' ? 'Product' : 'Add-On Service'
				}`}
				variant="secondary"
				color="green"
				className={classes.addButton}
				onClick={() => addHandler(props.type)}
				disabled={
					props.editedUuidLine !== undefined ||
					props.lines.some(
						line =>
							(line.isProduct && !line.productId) ||
							(line.isAddOnService && !line.addOnServiceId)
					)
				}
			/>
		</Box>
	);
};

export default InvoiceExtras;
