import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLock,
    faPause,
    faExclamationTriangle
} from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(theme => ({
    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 200px)',
        width: '100%',
        flexDirection: 'column'
    },
    notFoundWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 15
    },
    notFoundBadge: {
        width: 90,
        height: 90,
        backgroundColor: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        color: '#fff',
        fontSize: 40,
        transform: 'none',
        position: 'relative',
        fontWeight: 600
    },
    notFoundContent: {
        textAlign: 'center'
    },
    notFoundTitle: {
        fontSize: 24,
        fontWeight: 600,
        color: '#000',
        marginBottom: 10,
        [theme.breakpoints.up('md')]: {
            fontSize: 32
        }
    },
    notFoundSubtitle: {
        fontSize: 16,
        color: '#000',
        maxWidth: 600,
        marginBottom: 10
    }
}));

const LockedInvoice: React.FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.notFoundContainer}>
            <Box className={classes.notFoundWrapper}>
                <Box className={classes.notFoundBadge}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                </Box>
                <Box className={classes.notFoundContent}>
                    <Typography className={classes.notFoundTitle}>
                        Checkout Paused
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.notFoundSubtitle}
                    >
                        We’re unable to complete your payment because this
                        invoice is being updated by the business or opened in
                        another tab. Please close any extra tabs and try again
                        to proceed with checkout.
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.notFoundSubtitle}
                    >
                        Please try again later or close any duplicate tabs.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default LockedInvoice;
