import {
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import AddOnService from '@spike/addon-service-model';
import {
	InvoiceDiscount as InvoiceDiscountModel,
	InvoiceLine
} from '@spike/invoice-model';
import { FieldError, Option } from '@spike/model';
import { Product } from '@spike/product-model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { SelectorFieldSearch } from 'components/UI';
import CalculatingSpinner from 'components/UI/CalculatingSpinner';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { wbp } from 'Theme';
import CancelButton from './buttons/CancelButton';
import DeleteButton from './buttons/DeleteButton';
import EditButton from './buttons/EditButton';
import SaveButton from './buttons/SaveButton';
import { useCommonStyles } from './CommonStyles';
import InvoiceQuantity from './InvoiceQuantity';
import LineDiscount from './LineDiscount';
import SearchProduct from './SearchProduct';
import ViewStates from './ViewStates';

interface InvoiceExtraProps {
	otherSelectedProductIds: Array<number>;
	otherSelectedAddOnServiceIds: Array<number>;
	line: InvoiceLine;
	saving: boolean;
	editable: boolean;
	errors: Array<FieldError>;
	className?: string;
	onSave?: (line: InvoiceLine) => void;
	onDelete?: (uuidLine: string) => void;
	onEdit?: (uuidLine: string) => void;
	onCancelEdit?: (uuidLine: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: '#ffffff',
			borderRadius: 16,

			[theme.breakpoints.down('sm')]: {
				gap: 12,
				padding: 16,
				border: 'solid 1px #D4D4D4'
			},
			[theme.breakpoints.up('md')]: {
				height: '100px',
				borderRadius: '19px',
				boxShadow: '0px 10px 20px 0px #0000000A'
			}
		},
		name: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '16px',
				lineHeight: '24px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px',
				lineHeight: '30px'
			}
		},
		total: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: '#000000',
			textAlign: 'right',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '13px',
				lineHeight: '24px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeight: '30px'
			}
		},
		spinner: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: '12px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '15px'
			}
		},
		mobileLabel: {
			fontSize: 14,
			lineHeight: 1,

			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		cell: {
			[theme.breakpoints.down('sm')]: {
				flex: '0 0 100%',
				alignItems: 'center',
				justifyContent: 'space-between !important'
			}
		},
		productName: {
			[theme.breakpoints.down('sm')]: {
				fontWeight: 600
			}
		},
		productCell: {
			[theme.breakpoints.down('sm')]: {
				order: 1,
				maxWidth: '65%',
				flex: '1 0 auto'
			},
			[theme.breakpoints.up('md')]: {
				width: '44%',
				paddingRight: '80px'
			}
		},
		quantityCell: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',

			[theme.breakpoints.down('sm')]: {
				order: 3
			},
			[theme.breakpoints.up('md')]: {
				width: '12.5%'
			}
		},
		priceCell: {
			width: '12%',
			display: 'flex',
			justifyContent: 'flex-end',

			[theme.breakpoints.down('sm')]: {
				order: 4
			}
		},
		discountCell: {
			display: 'flex',
			justifyContent: 'flex-end',

			[theme.breakpoints.down('sm')]: {
				order: 5
			},
			[theme.breakpoints.up('md')]: {
				width: '12.5%',
				paddingLeft: 10
			}
		},
		totalCell: {
			width: '12.5%',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		buttonCell: {
			[theme.breakpoints.down('sm')]: {
				gap: 8,
				order: 2,
				marginLeft: 'auto'
			},
			[theme.breakpoints.up('md')]: {
				width: '6.4%'
			}
		},
		center: {
			display: 'flex',
			justifyContent: 'center'
		},
		button: {
			border: 0,
			backgroundColor: 'transparent',

			[theme.breakpoints.down('sm')]: {
				width: 38,
				height: 38,
				color: '#000',
				paddingTop: 2,
				borderRadius: '50%',
				backgroundColor: '#F1F1F1'
			}
		},
		deleteButton: {
			[theme.breakpoints.down(wbp)]: {
				marginLeft: '8px'
			},
			[theme.breakpoints.up(wbp)]: {
				marginLeft: '10px'
			}
		},
		mobile: {
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}
	})
);

const validate = (line: InvoiceLine): Array<FieldError> => {
	const errors: Array<FieldError> = [];

	toNumber(line.discount.amount) >
		toNumber(line.quantity) * toNumber(line.subtotal) &&
		errors.push({
			fieldName: `${line.uuid}_discount`,
			errorMessage: 'Discount greater than the total.'
		});
	line.isProduct &&
		line.productId === undefined &&
		errors.push({
			fieldName: `${line.uuid}_selection`,
			errorMessage: 'Select a product.'
		});
	line.isAddOnService &&
		line.addOnServiceId === undefined &&
		errors.push({
			fieldName: `${line.uuid}_selection`,
			errorMessage: 'Select an add-on service product.'
		});

	return errors;
};

export const InvoiceExtra: FunctionComponent<InvoiceExtraProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const services = useSelector<RootState, Array<AddOnService>>(
		state => state.addOnServices.services
	);
	const availableAddOnServices: Array<Option<number>> = services
		.filter(
			service =>
				service.active &&
				!props.otherSelectedAddOnServiceIds.includes(service.id!)
		)
		.map(service => ({ id: service.id!, name: service.name }));

	const [editedLine, setEditedLine] = useState(props.line);
	const [viewState, setViewState] = useState<ViewStates>(
		props.line.productId || props.line.addOnServiceId
			? ViewStates.View
			: ViewStates.Editing
	);

	const [errors, setErrors] = useState<Array<FieldError>>([]);

	useNonInitialEffect(() => {
		!props.saving && setViewState(ViewStates.View);
	}, [props.saving]);

	const selectProductHandler = (product: Product) => {
		setEditedLine(prev => ({
			...prev,
			productId: product.businessProduct!.id,
			description: isEmpty(product.businessProduct?.name)
				? product.name
				: product.businessProduct?.name,
			subtotal:
				product.businessProduct?.pricing?.price?.toString() || '0.00',
			quantity: 1
		}));
	};

	const selectAddOnServiceHandler = (option: Option<number | string>) => {
		if (option.id !== props.line.addOnServiceId) {
			const selectedAddOnService = services.find(
				service => service.id === option.id
			);
			selectedAddOnService !== undefined &&
				setEditedLine(prev => ({
					...prev,
					addOnServiceId: selectedAddOnService.id,
					description: selectedAddOnService.name,
					subtotal: selectedAddOnService.price?.toString() || '0.00',
					quantity: 1
				}));
		}
	};

	const changeDiscountHandler = (discount: InvoiceDiscountModel) => {
		setEditedLine(prev => ({ ...prev, discount }));
	};

	const changeQuantityHandler = (quantity: number) => {
		setEditedLine(prev => ({ ...prev, quantity }));
	};

	const edit = () => {
		props.onEdit && props.onEdit(props.line.uuid);
		setEditedLine({ ...props.line });
		setViewState(ViewStates.Editing);
	};

	const cancel = () => {
		setErrors([]);
		props.onCancelEdit && props.onCancelEdit(props.line.uuid);
		!(props.line.productId || props.line.addOnServiceId) &&
			props.onDelete &&
			props.onDelete(props.line.uuid);
		setEditedLine(props.line);
		setViewState(ViewStates.View);
	};

	const save = () => {
		const errors = validate(editedLine);
		setErrors(errors);

		if (errors.length === 0) {
			setViewState(ViewStates.Saving);
			props.onSave && props.onSave({ ...editedLine });
		}
	};

	return (
		<Grid container className={clsx(classes.container, props.className)}>
			<Grid
				item
				className={clsx(commonClasses.firstCell, classes.productCell)}
			>
				{props.line.isProduct &&
					(viewState === ViewStates.Editing ? (
						<SearchProduct
							selectedProductId={
								editedLine.productId || undefined
							}
							otherSelectedProductIds={
								props.otherSelectedProductIds
							}
							name={`${editedLine.uuid}_selection`}
							errors={[...props.errors, ...errors]}
							onSelect={selectProductHandler}
						/>
					) : (
						<Typography
							className={clsx(
								classes.productName,
								commonClasses.textCell,
								commonClasses.editable
							)}
							onClick={edit}
						>
							{editedLine.description}
						</Typography>
					))}
				{props.line.isAddOnService &&
					(viewState === ViewStates.Editing ? (
						<SelectorFieldSearch
							options={availableAddOnServices}
							selectedOption={{
								id: editedLine.addOnServiceId!,
								name:
									services.find(
										service =>
											service.id ===
											editedLine.addOnServiceId
									)?.name || ''
							}}
							onSelect={option => {
								selectAddOnServiceHandler(option!);
							}}
							name={`${editedLine.uuid}_selection`}
							errors={[...props.errors, ...errors]}
						/>
					) : (
						<Typography
							className={clsx(
								commonClasses.textCell,
								commonClasses.editable
							)}
							onClick={edit}
						>
							{editedLine.description}
						</Typography>
					))}
			</Grid>
			<Grid className={clsx(classes.cell, classes.quantityCell)}>
				<Typography className={classes.mobileLabel}>
					Quantity
				</Typography>

				{viewState === ViewStates.Editing ? (
					<InvoiceQuantity
						quantity={editedLine.quantity}
						onChange={changeQuantityHandler}
					/>
				) : (
					<Typography
						className={clsx(
							commonClasses.textCell,
							commonClasses.amountTextCell,
							{ [commonClasses.editable]: props.editable }
						)}
						onClick={props.editable ? edit : undefined}
					>
						{editedLine.quantity}
					</Typography>
				)}
			</Grid>
			<Grid className={clsx(classes.cell, classes.priceCell)}>
				<Typography className={classes.mobileLabel}>Price</Typography>

				<Typography
					className={clsx(
						commonClasses.textCell,
						commonClasses.amountTextCell
					)}
				>
					{editedLine.subtotal}
				</Typography>
			</Grid>
			<Grid
				item
				container
				className={clsx(classes.cell, classes.discountCell)}
			>
				<Typography className={classes.mobileLabel}>
					Discount
				</Typography>

				<LineDiscount
					line={editedLine}
					viewState={viewState}
					errors={[...props.errors, ...errors]}
					editable={props.editable}
					onChange={changeDiscountHandler}
					onEdit={edit}
				/>
			</Grid>
			<Grid
				item
				className={clsx(
					classes.cell,
					classes.totalCell,
					commonClasses.right
				)}
			>
				{' '}
				{viewState === ViewStates.Saving ? (
					<CalculatingSpinner className={classes.spinner} />
				) : viewState === ViewStates.Editing ? (
					<SaveButton onClick={save} />
				) : (
					<Typography
						className={clsx(commonClasses.textCell, {
							[commonClasses.errorText]:
								Number(props.line?.total) < 0
						})}
					>
						${props.line?.total || 0.0}
					</Typography>
				)}
			</Grid>
			<Grid item className={clsx(classes.buttonCell, classes.center)}>
				{props.editable && viewState === ViewStates.View && (
					<button
						type="button"
						onClick={edit}
						className={classes.button}
					>
						<EditButton />
					</button>
				)}
				{viewState === ViewStates.Editing && (
					<>
						<button
							type="button"
							onClick={save}
							className={clsx(classes.mobile, classes.button)}
						>
							<SaveButton />
						</button>
						<button
							type="button"
							onClick={cancel}
							className={classes.button}
						>
							<CancelButton />
						</button>
					</>
				)}
				{props.editable && viewState === ViewStates.View && (
					<button
						type="button"
						className={classes.button}
						onClick={() =>
							props.onDelete && props.onDelete(props.line.uuid)
						}
					>
						<DeleteButton />
					</button>
				)}
			</Grid>
		</Grid>
	);
};

export default InvoiceExtra;
