import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Client from "@spike/client-model";
import React, { FunctionComponent, useEffect, useState } from "react";
import { reduceResolution, wbp } from "Theme";

interface TotalPetsProps {
  client: Client;
  className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
      borderRadius: "100%",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(26)}px`,
        height: `${reduceResolution(26)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "26px",
        height: "26px",
      },
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "white",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
      },
    },
  })
);

const TotalPets: FunctionComponent<TotalPetsProps> = (props) => {
  const classes = useStyles();

  const [petLength, setPetLength] = useState(0);

  useEffect(() => {
    const petActive = props.client.pets.filter((pet) => pet.active && !pet.deceased);
    setPetLength(petActive.length);
  }, [props.client.pets])


  return (
    <Box className={clsx(classes.container, props.className)}>
      <Typography className={classes.text}>{petLength}</Typography>
    </Box>
  );
};

export default TotalPets;
