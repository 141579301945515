import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

export interface CheckboxCardProps {
    id?: string;
    label: string;
    checked: boolean;
    description: string;
    icon: React.ReactNode;
    onChange: (checked: boolean) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            flex: 1,
            width: '100%',
            userSelect: 'none'
        },
        root: (props: CheckboxCardProps) => ({
            gap: 10,
            width: '100%',
            display: 'flex',
            borderRadius: 12,
            borderStyle: 'solid',
            position: 'relative',
            cursor: 'pointer',

            margin: props.checked ? '-1px' : '0px',
            borderWidth: props.checked ? '2px' : '1px',
            borderColor: props.checked ? '#000' : '#D3D3D3',
            padding: props.checked ? '16px 19px 16px 20px' : '16px 20px'
        }),
        iconContainer: {
            'width': 20,
            'flexShrink': 0,

            '& > *': {
                width: '100%',
                fontSize: 20
            }
        },
        label: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 600,
            color: '#000'
        },
        description: {
            margin: 0,
            fontSize: 14,
            lineHeight: 1.4,
            color: '#7A7A7A'
        },
        radioContainer: {
            marginLeft: 'auto'
        },
        radio: (props: CheckboxCardProps) => ({
            'margin': 0,
            'width': 20,
            'height': 20,
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'borderRadius': '50%',
            'pointerEvents': 'none',
            'borderStyle': 'solid',
            'borderWidth': props.checked ? 0 : 2,
            'borderColor': props.checked ? '#000' : '#E5E5E5',

            '& > svg': {
                fontSize: 20,
                color: '#5E8677',

                display: props.checked ? 'block' : 'none'
            }
        })
    })
);

export const CheckboxCard: React.FC<CheckboxCardProps> = props => {
    const classes = useStyles(props);

    return (
        <div className={classes.container}>
            <div
                id={props.id}
                className={classes.root}
                onClick={() => props.onChange(!props.checked)}
            >
                <div className={classes.iconContainer}>{props.icon}</div>

                <div>
                    <label className={classes.label}>{props.label}</label>
                    <p className={classes.description}>{props.description}</p>
                </div>

                <div className={classes.radioContainer}>
                    <div className={classes.radio}>
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </div>
                </div>
            </div>
        </div>
    );
};
