import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { MarketplacePetSize } from '@spike/marketplace-model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { Checkbox } from 'components/UI';
import ControlledWeightField2 from 'components/UI/ControlledWeightField2';
import { FunctionComponent, useState } from 'react';

export interface PetSizeProps {
    petSize: MarketplacePetSize;
    checked: boolean;
    disabled?: boolean;
    first?: boolean;
    last?: boolean;
    onChange?: (checked: boolean, minWeight: number | null, maxWeight: number | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            minWidth: '100%',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                height: 32,
                marginBottom: '17px',
                paddingRight: '3px'
            },
            [theme.breakpoints.only('md')]: {
                alignItems: 'center',
                height: '35px',
                marginBottom: '9px',
                paddingRight: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '40px',
                marginBottom: '13px',
                paddingRight: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '60px',
                marginBottom: '19px',
                paddingRight: '8px'
            }
        },
        left: {
            display: 'flex'
        },
        right: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        },
        name: {
            fontSize: '16px',
            paddingTop: '4.25px'
        },
        check: {
            'display': 'flex',
            'alignItems': 'center',
            'marginTop': '2.5px',
            '& .MuiCheckbox-root': {
                paddingLeft: '0px !important',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '6px'
                },
                [theme.breakpoints.only('md')]: {
                    fontSize: '16px'
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: '16px'
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        weightsContainer: (props: PetSizeProps) => ({
            display: 'flex',
            marginLeft: props.last ? -20 : 0,
            width: '100%',
            justifyContent: props.first ? 'flex-end' : 'flex-start',
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                justifyContent: 'flex-end'
            }
        }),
        weight: {
            'width': 90,
            '& input': {
                padding: '6px 0px 6px 10px',
                fontSize: 15
            },
            '& .MuiInputAdornment-positionEnd': {
                'paddingRight': 0,
                '& p': {
                    fontSize: 15
                }
            },
            '& fieldset': {
                borderColor: '#222222 !important'
            },
            '& .Mui-focused > fieldset': {
                border: '1px solid #D3D3D3 !important'
            },
            '& .Mui-focused > input': {
                color: '#D3D3D3'
            },
            '& .Mui-focused > .MuiInputAdornment-positionEnd': {
                color: '#D3D3D3'
            }
        },
        minWeight: (props: PetSizeProps) => ({
            marginLeft: props.last ? 10 : 0,
            marginRight: props.last ? 0 : 10
        }),
        maxWeight: {
            marginLeft: 10
        },
        firstMinWeightText: {
            maxWidth: 90,
            textAlign: 'right',
            paddingRight: 20,
            fontSize: 15
        }
    })
);

export const PetSize: FunctionComponent<PetSizeProps> = props => {
    const classes = useStyles(props);

    const [values, setValues] = useState({
        checked: props.checked,
        minWeight: props.first ? 0 : props.petSize.minWeight,
        maxWeight: props.petSize.maxWeight
    });

    useNonInitialEffect(
        () => props.onChange && props.onChange(values.checked, values.minWeight, values.maxWeight),
        [values]
    );

    return (
        <Box className={classes.container}>
            <Box className={classes.left}>
                <Checkbox
                    checked={values.checked}
                    onChange={checked => setValues(prev => ({ ...prev, checked }))}
                    className={classes.check}
                    iconColor={props.disabled ? '#c7c7c7' : '#000000'}
                    disabled={props.disabled}
                />
            </Box>
            <Box className={classes.right}>
                <Typography className={classes.name}>{props.petSize.name}</Typography>
                {props.checked && (
                    <Box className={classes.weightsContainer}>
                        {props.last && '+'}
                        {props.first ? (
                            <Typography className={classes.firstMinWeightText}>0 Lbs</Typography>
                        ) : (
                            <ControlledWeightField2
                                value={props.petSize.minWeight === null ? undefined : props.petSize.minWeight}
                                onChange={weight =>
                                    setValues(prev => ({
                                        ...prev,
                                        minWeight: weight === undefined ? null : weight
                                    }))
                                }
                                alignRight={true}
                                className={clsx(classes.weight, classes.minWeight)}
                            />
                        )}
                        {!props.last && '-'}
                        {!props.last && (
                            <ControlledWeightField2
                                value={props.petSize.maxWeight === null ? undefined : props.petSize.maxWeight}
                                onChange={weight =>
                                    setValues(prev => ({
                                        ...prev,
                                        maxWeight: weight === undefined ? null : weight
                                    }))
                                }
                                alignRight={true}
                                className={clsx(classes.weight, classes.maxWeight)}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
