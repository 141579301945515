import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, ClickAwayListener } from "@material-ui/core";
import { Option } from "@spike/model";
import clsx from "clsx";

interface Props {
  pageSizes: Array<Option<number>>;
  onSelect: (pageSize: number) => void;
  onClose: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsContainer: {
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      border: "2px solid #A3A3A3",
      background: "white",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "7px",
        paddingTop: "6px",
        paddingBottom: "6px",
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "11px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      [theme.breakpoints.only("xl")]: {
        borderRadius: "22px",
        paddingTop: "16px",
        paddingBottom: "16px",
      },
    },
    item: {
      cursor: "pointer",
      whiteSpace: "nowrap",
      fontWeight: 500,
      color: "#A3A3A3",
      "&:hover": {
        backgroundColor: "#F1F1F1",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "17px",
        paddingLeft: "7px",
        paddingRight: "7px",
        paddingTop: "2px",
        paddingBottom: "2px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "16px",
        lineHeight: "24px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
        lineHeight: "27px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "7px",
        paddingBottom: "7px",
      },
    },
    lastItem: {
      marginBottom: "0px"
    }
  })
);

export const PageSizeDropdown: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const items = props.pageSizes.map((item, index) => (
    <Box key={item.id} className={clsx(classes.item,{[classes.lastItem]: index+1 === props.pageSizes.length})} onClick={() => props.onSelect(item.id)}>
      <Typography>{item.name}</Typography>
    </Box>
  ));

  return (
    <ClickAwayListener onClickAway={props.onClose}>
      <Box className={clsx(classes.itemsContainer, props.className)}>
        {items}
      </Box>
    </ClickAwayListener>
  );
};

export default PageSizeDropdown;
