import React, { FunctionComponent, ChangeEvent, useState } from "react";
import { Box, Button as MUIButton, InputBase } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { cuddlesStyle } from "../../Theme";

export interface Props {
  name?: string;
  value: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number, name?: string) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      borderWidth: "1px",
      borderColor: cuddlesStyle.incrementField.colors.border,
      borderStyle: "solid",
      color: cuddlesStyle.incrementField.colors.text,
      backgroundColor: cuddlesStyle.incrementField.colors.background,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        borderRadius: "23px",
        height: "23px",
        width: "51px",
        fontSize: "6px",
        lineHeight: "9px"
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: "23px",
        height: "23px",
        width: "82px",
        fontSize: "10px",
        lineHeight: "15px"
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: "30px",
        height: "30px",
        width: "109px",
        fontSize: "13px",
        lineHeight: "20px"
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "45px",
        height: "45px",
        width: "165px",
        fontSize: "20px",
        lineHeight: "30px"
      },
      "&:hover": {
        color: cuddlesStyle.incrementField.colors.text,
        background: cuddlesStyle.incrementField.colors.background,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: cuddlesStyle.incrementField.colors.border,
        "& button": {
          color: cuddlesStyle.incrementField.colors.text,
          background: "transparent",
        },
      },
    },
    signButton: {
      width: "40%",
      minWidth: "40%",
      height: "100%",
      padding: "0px",
      color: cuddlesStyle.incrementField.colors.text,
      background: "transparent",
      border: "none",
      borderRadius: 0,
    },
    input: {
      "& input": {
        textAlign: "center",
      },
    },
  })
);

export const IncrementField: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [value, setValue] = useState(props.value);

  const clickSubstractHandler = (): void => {
    let newValue = value - 1;
    if (props.minValue === undefined || newValue >= props.minValue) {
      change(newValue);
    }
  };

  const clickAddHandler = (): void => {
    let newValue = value + 1;
    if (props.maxValue === undefined || newValue <= props.maxValue) {
      change(newValue);
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.currentTarget.value;
    if(newValue === "" ) {
      change(0);
    } else if(!Number.isNaN(+newValue) && Number.isInteger(+newValue)){
      change(Number.parseInt(newValue));
    }
  };

  const change = (newValue: number):void => {
    setValue(newValue);
    props.onChange(newValue, props.name);
  }

  return (
    <Box className={clsx(classes.container, props.className)}>
      <MUIButton
        variant="contained"
        color="primary"
        disableRipple={true}
        disableTouchRipple={true}
        disableElevation={true}
        onClick={clickSubstractHandler}
        className={classes.signButton}
      >
        -
      </MUIButton>
      <InputBase
        value={value}
        className={classes.input}
        onChange={changeHandler}
      />
      <MUIButton
        variant="contained"
        color="primary"
        disableRipple={true}
        disableTouchRipple={true}
        disableElevation={true}
        onClick={clickAddHandler}
        className={classes.signButton}
      >
        +
      </MUIButton>
    </Box>
  );
};

export default IncrementField;
