import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useMasterData } from "hooks";
import isEmpty from "lodash/isEmpty";
import { Product } from "@spike/product-model";
import { FunctionComponent } from "react";
import { Img } from "react-image";
import { reduceResolution, wbp } from "Theme";
import { getPriceBySize } from "../../Utils";
import Area from "../Area";
import ResumeSection, { BodyResumeSection } from "../ResumeSection";

interface Props {
  product: Product;
  onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
    },
    leftContainer: {
      width: "25%",
    },
    rightContainer: {
      width: "70%",
    },

    imageBox: {
      [theme.breakpoints.down("md")]: {
        width: "147px",
        height: "147px",
        borderRadius: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "184px",
        height: "184px",
        borderRadius: "10px",
      },
    },
    uploadBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#E9F0ED",
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "10px",
      },
    },
    uploadIcon: {
      color: "#5E8677",
      [theme.breakpoints.down("md")]: {
        fontSize: "39px",
        lineHeight: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "49px",
        lineHeight: "30px",
      },
    },
    itemContainer: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        marginTop: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "10px",
      },
    },
    priceContainer: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginRight: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "10px",
      },
    },
    pricingTitle: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(22)}px`,
        marginTop: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "22px",
        marginTop: "30px",
      },
      fontWeight: 600,
    },
    pricingDivider: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(15)}px`,
        marginBottom: `${reduceResolution(25)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "15px",
        marginBottom: "25px",
      },
    },
    rightDataContainer: {
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(27)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "27px",
      },
    },
    item: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(35)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "35px",
      },
      minWidth: "250px",
    },
    itemExtra: {
      //display: "block"
    },
    taxContainer: {
      display: "flex",
    },
    taxNameContainer: {
      width: "65%",
    },
    taxPercentageContainer: {
      width: "35%",
    },
    taxPercentage: {
      fontWeight: 300,
      color: "#7A7A7A !important",
    },
  })
);

export const ResumeDetails: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const masterData = useMasterData();

  return (
    <ResumeSection title="Details" {...props}>
      <BodyResumeSection>
        <Box className={classes.container}>
          <Box className={classes.leftContainer}>
            <Box className={classes.imageBox}>
              {props.product.businessProduct?.imageUrl ? (
                <Img src={props.product.businessProduct?.imageUrl} className={classes.imageBox} />
              ) : (
                <Box className={classes.uploadBox}>
                  <FontAwesomeIcon icon={faCamera} className={classes.uploadIcon} />
                </Box>
              )}
            </Box>
          </Box>

          <Box className={classes.rightContainer}>
            <Box className={classes.rightDataContainer}>
              <Area
                title="PRODUCT"
                info={
                  isEmpty(props.product.businessProduct?.name)
                    ? props.product.name
                    : props.product.businessProduct?.name
                }
                subInfo={props.product.businessProduct?.description}
                description={props.product.businessProduct?.code || "-"}
              />
            </Box>
            <Box className={classes.rightDataContainer}>
              {props.product.manufacturer && (
                <Box className={classes.item}>
                  <Area
                    title="BRAND"
                    info={
                      isEmpty(props.product.businessProduct?.brand)
                        ? props.product.brand
                        : props.product.businessProduct?.brand
                    }
                  />
                </Box>
              )}
              {props.product.category && (
                <Box className={classes.item}>
                  <Area title="CATEGORY" info={props.product.category.name} />
                </Box>
              )}

              {props.product.manufacturer && (
                <Box className={classes.item}>
                  <Area
                    title="MANUFACTURER"
                    info={
                      isEmpty(props.product.businessProduct?.manufacturer)
                        ? props.product.manufacturer
                        : props.product.businessProduct?.manufacturer
                    }
                  />
                </Box>
              )}
            </Box>
            <Box className={classes.rightDataContainer}>
              {props.product.size?.unit && (
                <Box className={classes.itemContainer}>
                  <Area
                    title="UNIT MEASUREMENT"
                    info={
                      props.product.businessProduct?.size && props.product.businessProduct?.size.unit
                        ? props.product.businessProduct?.size.unit.name
                        : "Whole Product"
                    }
                  />
                </Box>
              )}
            </Box>
            <Area title="PRODUCT DESCRIPTION" info={props.product.businessProduct?.description} />
            <Typography className={classes.pricingTitle}>Pricing</Typography>
            <Divider className={classes.pricingDivider}></Divider>

            <Box className={classes.rightDataContainer}>
              {props.product.businessProduct?.pricing?.price && (
                <Box className={classes.item}>
                  <Area title="RETAIL PRICE" info={getPriceBySize(props.product.businessProduct, masterData)} />
                </Box>
              )}

              {props.product.businessProduct?.pricing?.taxes && (
                <Box className={classes.item}>
                  <Area title="TAXES" childrenAsItem={true}>
                    {props.product.businessProduct?.pricing.taxes.length > 0 ? (
                      <Box>
                        {props.product.businessProduct?.pricing.taxes.map((tax) => {
                          return (
                            <Box className={classes.taxContainer} key={tax.uuid}>
                              <Box className={classes.taxNameContainer}>
                                <Typography>{tax.name}</Typography>
                              </Box>
                              <Box className={classes.taxPercentageContainer}>
                                <Typography className={classes.taxPercentage}>{tax.percentage}%</Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <Typography className={classes.itemExtra}>Not Taxable</Typography>
                    )}
                  </Area>
                </Box>
              )}
              <Box className={classes.item}>
                <Area
                  title="AVAILABLE FOR CHECKOUT"
                  info={props.product.businessProduct?.availableForCheckout ? "YES" : "NO"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </BodyResumeSection>
    </ResumeSection>
  );
};

export default ResumeDetails;
