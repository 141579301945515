import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useMasterData } from 'hooks';
import { SelectableOption } from 'model';
import { Option } from '@spike/model';
import CheckboxCircle from 'components/UI/CheckboxCircle';
import { isEmpty } from 'lodash';

interface Props {
	procedureRecords: Array<Option<number>>;
	isDentalReport: boolean;
	multipleSelection?: boolean;
	onChangeSelection: (procedureRecords: Array<Option<number>>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column'
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				marginBottom: 24,
				textTransform: 'uppercase'
			}
		},
		content: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap',

			[theme.breakpoints.up('md')]: {
				columnGap: 20,
				rowGap: 12
			}
		},
		procedureRecordContainer: {
			height: 40,
			width: 'auto',
			borderRadius: 30,
			display: 'inline-flex',
			alignItems: 'center',
			backgroundColor: '#F0F4F2',

			[theme.breakpoints.up('xl')]: {
				height: 45
			}
		},
		procedureRecordSelectedContainer: {
			'height': 40,
			'width': 'auto',
			'borderRadius': 30,
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff !important'
			},

			[theme.breakpoints.up('xl')]: {
				height: 45
			}
		},
		label: {
			'fontSize': 16,
			'display': 'flex',
			'justifyContent': 'center',
			'padding': '0px 23px',

			'&:hover': {
				cursor: 'pointer'
			}
		},
		procedureRecord: {
			display: 'flex',
			width: 'auto',
			[theme.breakpoints.down('lg')]: {
				marginRight: '21px',
				fontSize: '14px',
				marginBottom: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '28px',
				fontSize: '18px',
				marginBottom: '12px'
			}
		}
	})
);

export const ProcedureRecords: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const masterData = useMasterData();

	const [procedureRecords, setProcedureRecords] = useState<
		Array<SelectableOption<number>>
	>([]);

	useEffect(() => {
		const procedureRecordsAux =
			masterData.dentalReportOptions.dentalProcedures.map(
				procedureRecord => ({
					element: procedureRecord,
					selected: props.procedureRecords.find(
						rec => rec.id === procedureRecord.id
					)
						? true
						: false
				})
			);
		setProcedureRecords(procedureRecordsAux);
	}, [props.procedureRecords]);

	const handleSelectProcedureRecord = (
		value: Option<number>,
		selected: boolean
	) => {
		if (props.multipleSelection || selected) {
			const newProcedureRecord = [...procedureRecords];

			newProcedureRecord.forEach(procOption => {
				procOption.selected =
					procOption.element.id === value.id
						? selected
						: props.multipleSelection
						? procOption.selected
						: false;
			});

			setProcedureRecords(newProcedureRecord);
			props.onChangeSelection(
				newProcedureRecord
					.filter(rec => rec.selected)
					.map(rec => rec.element)
			);
		}
	};

	return (
		<Box className={classes.container}>
			{(props.isDentalReport ||
				(!isEmpty(props.procedureRecords) &&
					!props.isDentalReport)) && (
				<Typography className={classes.titleSection}>
					Procedure Record
				</Typography>
			)}
			{(props.isDentalReport ||
				(!isEmpty(props.procedureRecords) &&
					!props.isDentalReport)) && (
				<Box className={classes.content}>
					{props.isDentalReport
						? procedureRecords.map(procedureRecord => {
								return (
									<Box
										key={procedureRecord.element.id}
										className={
											procedureRecord.selected
												? classes.procedureRecordSelectedContainer
												: classes.procedureRecordContainer
										}
									>
										<Typography
											className={classes.label}
											onClick={() =>
												handleSelectProcedureRecord(
													procedureRecord.element,
													!procedureRecord.selected
												)
											}
										>
											{procedureRecord.element.name}
										</Typography>
									</Box>
								);
						  })
						: props.procedureRecords.map(procedureRecord => {
								return (
									<Box
										key={procedureRecord.id}
										className={classes.procedureRecord}
									>
										<CheckboxCircle
											checked={true}
											onChange={() => {}}
										/>
										{procedureRecord.name}
									</Box>
								);
						  })}
				</Box>
			)}
		</Box>
	);
};

export default ProcedureRecords;
