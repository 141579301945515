import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import Number from './Number';

interface CountdownProps {
    initialTimeInSeconds: number;
    className?: string;
    onFinished?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        separator: {
            fontFamily: 'Poppins',
            fontSize: 56,
            fontWeight: 600,
            color: '#000000',
            [theme.breakpoints.down(768)]: {
                fontSize: 44
            }
        },
        number: {
            marginLeft: 5,
            marginRight: 5
        }
    })
);

export const Countdown: FunctionComponent<CountdownProps> = props => {
    const classes = useStyles();

    const [time, setTime] = useState(props.initialTimeInSeconds);
    const minutes = Math.trunc(time / 60);
    const seconds = time % 60;

    useEffect(() => {
        const interval = setInterval(
            () => setTime(prev => (prev > 0 ? prev - 1 : 0)),
            1000
        );
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        time === 0 && props.onFinished && props.onFinished();
    }, [time]);

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Number
                number={Math.trunc(minutes / 10)}
                className={classes.number}
            />
            <Number number={minutes % 10} className={classes.number} />
            <Typography className={classes.separator}>:</Typography>
            <Number
                number={Math.trunc(seconds / 10)}
                className={classes.number}
            />
            <Number number={seconds % 10} className={classes.number} />
        </Box>
    );
};

export default Countdown;
