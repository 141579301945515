import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Fragment, FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from '../../CommonStyles';
import { useTerminalCommonStyles } from '../TerminalCommonStyles';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import useNonInitialEffect from '@versiondos/hooks';
import { useApiClientWrapper, useMasterData } from 'hooks';
import { RootState } from 'store';
import { useMarketplace } from 'hooks';
import {
    paymentMethodIcons,
    paymentMethodIconsColor
} from '@spike/payments-model';
import { Button } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { formatPhoneNumber } from '@spike/phone-utils';

import Title from '../../ui/Title';
import { OrderStatus } from './OrderStatus';
import { OrderCancelPopUp } from './OrderCancelPopUp';
import { OrderReceivedPopUp } from './OrderReceivedPopUp';
import {
    TerminalModel,
    TerminalOrder,
    statusOrder
} from '@spike/terminal-model';
import {
    fetchTerminalsThunk,
    fetchTerminalsOrderThunk,
    cancelTerminalOrderThunk,
    changeStatusOrder,
    TerminalsStatus
} from '@spike/terminals-action';
import { TerminalImage } from '../UI/TerminalImage';
import { ZendeskAPI } from 'react-zendesk';
import { faHandHoldingUsd } from '@fortawesome/pro-duotone-svg-icons';
import NotMobileYet from 'components/UI/NotMobileYet';
interface OrderDetailProps {
    order: TerminalOrder;
    className?: string;
    //
    onClose?: () => void;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        baseContainer: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(0)}px ${reduceResolution(
                    70
                )}px 0 ${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '0 70px 0 30px'
            }
        },
        boxOrder: {
            height: '100%'
        },
        textOverflow: {},
        boxHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(20)}px 0`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '20px 0'
            }
        },

        boxContent: {
            background: '#FAFAFA',
            height: '100%'
        },

        contentContainer: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(25)}px 0 ${reduceResolution(
                    25
                )}px ${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '25px 0 25px 40px'
            }
        },

        boxTitle: {
            display: 'flex'
        },

        boxOrderStatus: {
            width: 'fit-content',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '5px'
            }
        },

        left: {
            display: 'flex',
            justifyContent: 'flex-start'
        },

        right: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
        },

        backIcon: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(27)}px`,
                paddingRight: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '27px',
                paddingRight: '20px'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },

        eyeIcon: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '12px'
            }
        },

        buttonHelp: {
            marginRight: '10px'
        },

        buttonReceived: {
            marginRight: '10px'
        },

        typoName: {
            fontWeight: 600,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },

        typoItems: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`,
                paddingTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '20px',
                paddingTop: '20px'
            }
        },

        descriptionModel: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(220)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '220px'
            }
        },

        typoPaymentInfo: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`,
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '20px',
                paddingBottom: '20px'
            }
        },

        typoSubtotal: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '15px'
            }
        },

        boxDeliveryName: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(15)}px`,
                paddingBottom: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '15px',
                paddingBottom: '15px'
            }
        },
        gridInfo: {
            background: '#fff',
            border: '1px solid #DDDDDD',
            [theme.breakpoints.down(wbp)]: {
                borderRadius: `${reduceResolution(18)}px`,
                padding: `${reduceResolution(35)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: '18px',
                padding: '35px'
            }
        },

        gridContact: {
            borderBottom: '1px solid #DDDDDD',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '20px'
            }
        },

        gridDelivery: {
            borderBottom: '1px solid #DDDDDD',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`,
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '20px',
                paddingBottom: '20px'
            }
        },

        boxItems: {
            width: '100%'
        },

        gridTotal: {
            background: '#fff',
            border: '1px solid #DDDDDD',
            [theme.breakpoints.down(wbp)]: {
                borderRadius: `${reduceResolution(18)}px`,
                padding: `${reduceResolution(19)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: '18px',
                padding: '19px'
            }
        },

        typoQty: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '20px'
            }
        },
        gridItemSecond: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '20px'
            }
        },

        gridCenter: {
            textAlign: 'center'
        },

        paymentTypeIcon: {
            paddingRight: '5px'
        },
        typoTracking: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: '5px'
            }
        }
    })
);

export const OrderDetail: FunctionComponent<OrderDetailProps> = props => {
    const classes = useStyles();

    const apiClientWrapper = useApiClientWrapper();

    const masterData = useMasterData();
    const modelsTerminal: Array<TerminalModel> = masterData.terminalsModels;

    const commonClasses = useCommonStyles();
    const terminalCommonClasses = useTerminalCommonStyles();
    const marketplace = useMarketplace();
    const dispatch = useDispatch();

    const timeZone = marketplace.schedule.timeZone;
    const createAt = moment(props.order.createAt!);
    const createAtFormat = createAt
        ? createAt.tz(timeZone).format('MMM DD, HH:mm A').toString()
        : '';

    const orderStatus = useSelector<RootState, TerminalsStatus>(
        state => state.terminals.status
    );

    const [showCancelPopUp, setShowCancelPoppUp] = useState<boolean>(false);
    const [showReceivedPopUp, setShowReceivedPopUp] = useState<boolean>(false);

    useNonInitialEffect(() => {
        if (TerminalsStatus.CancelTerminalOrderSuccess === orderStatus) {
            dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
            setShowCancelPoppUp(false);
            props.onClose && props.onClose();
        }

        if (TerminalsStatus.ChangeStatusOrdersSuccess === orderStatus) {
            dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
            dispatch(fetchTerminalsThunk(apiClientWrapper));
            props.onClose && props.onClose();
        }
    }, [orderStatus]);

    const backHandler = () => {
        props.onClose && props.onClose();
    };

    const contactUsHandler = () => {
        ZendeskAPI('webWidget', 'show');
        ZendeskAPI('webWidget', 'open');
    };

    const showCancelHandler = () => {
        setShowCancelPoppUp(true);
    };

    const closeCancelHandler = () => {
        setShowCancelPoppUp(false);
    };

    const confirmCancelHandler = () => {
        dispatch(cancelTerminalOrderThunk(apiClientWrapper, props.order.id!));
    };

    const receiveHandler = () => {
        dispatch(
            changeStatusOrder(
                apiClientWrapper,
                props.order!.id!,
                statusOrder.RECEIVED
            )
        );
    };

    const checkIconPaymentMethod = () => {
        if (props.order.adminPayments && props.order.adminPayments.length > 0) {
            return (
                <FontAwesomeIcon
                    icon={
                        paymentMethodIcons.get(
                            props.order.adminPayments[0].paymentMethodId
                        ) || faHandHoldingUsd
                    }
                    size="1x"
                    color={
                        paymentMethodIconsColor.get(
                            props.order.adminPayments[0].paymentMethodId
                        ) || '#FFF'
                    }
                    className={classes.paymentTypeIcon}
                />
            );
        } else return <></>;
    };

    const checkPaymentMethod = () => {
        let payment = 'Pending';

        if (props.order.adminPayments && props.order.adminPayments.length > 0) {
            payment = props.order.adminPayments[0].cardDigits;
        }

        return payment;
    };

    //

    const cancelOrderView = (
        <OrderCancelPopUp
            //
            onClose={closeCancelHandler}
            onConfirmCancel={confirmCancelHandler}
        />
    );

    const receivedOrderView = (
        <OrderReceivedPopUp
            order={props.order}
            //
            onClose={() => setShowReceivedPopUp(false)}
            onConfirmCancel={receiveHandler}
        />
    );

    const header = (
        <Box className={classes.boxHeader}>
            <Box className={classes.boxTitle}>
                <Box className={classes.boxTitle}>
                    <Box onClick={backHandler}>
                        <FontAwesomeIcon
                            className={classes.backIcon}
                            icon={faArrowLeft}
                            size="4x"
                        />
                    </Box>
                    <Box>
                        <Title text={`Order #${props.order.number}`} />
                        <Box>{createAtFormat}</Box>
                        <Box className={classes.boxOrderStatus}>
                            <OrderStatus orderStatus={props.order.status} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.right}>
                {props.order.status === statusOrder.SHIPPED && (
                    <Button
                        className={classes.buttonReceived}
                        label={'Received'}
                        variant={'primary'}
                        color={'green'}
                        onClick={() => setShowReceivedPopUp(true)}
                    />
                )}

                <Button
                    className={clsx({
                        [classes.buttonHelp]:
                            props.order.status !== statusOrder.SHIPPED
                    })}
                    label={'Need Help?'}
                    variant={'secondary'}
                    color={'secondaryGreen'}
                    onClick={contactUsHandler}
                />

                {props.order.status !== statusOrder.CANCELLED &&
                    props.order.status !== statusOrder.SHIPPED &&
                    props.order.status !== statusOrder.RECEIVED && (
                        <Button
                            label={'Cancel Order'}
                            variant={'danger'}
                            onClick={showCancelHandler}
                        />
                    )}
            </Box>
        </Box>
    );

    const gridInfo = (
        <Grid container className={classes.gridContact}>
            <Grid item xs={4}>
                <Typography className={classes.typoName}>
                    Billing Information
                </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={7}>
                <Typography className={terminalCommonClasses.typo16_500}>
                    {marketplace.basics.businessName}
                </Typography>
                <Typography className={terminalCommonClasses.typo14_400}>
                    {marketplace.basics.businessName}
                </Typography>
                <Typography
                    className={clsx(
                        terminalCommonClasses.typo14_400,
                        classes.textOverflow
                    )}
                >
                    {marketplace.basics.email}
                </Typography>
                <Typography
                    className={clsx(
                        terminalCommonClasses.typo14_400,
                        classes.textOverflow
                    )}
                >
                    {marketplace.basics.phone
                        ? formatPhoneNumber(
                              marketplace.basics.phone,
                              apiClientWrapper.marketplace!.basics.address
                                  .country!.id
                          )
                        : '-'}
                </Typography>
            </Grid>
        </Grid>
    );

    const gridDelivery = (
        <Grid container className={classes.gridDelivery}>
            <Grid item xs={4}>
                <Typography className={classes.typoName}>
                    Delivery Information
                </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={7}>
                {props.order.status !== statusOrder.NEW ? (
                    <Box>
                        <Box className={terminalCommonClasses.flexRow}>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo15_400_grey,
                                    classes.typoTracking
                                )}
                            >
                                Tracking #:
                            </Typography>
                            <Typography
                                className={terminalCommonClasses.typo15_400}
                            >
                                {props.order.trackingNumber
                                    ? props.order.trackingNumber
                                    : 'Pending'}
                            </Typography>
                        </Box>
                        <Box className={terminalCommonClasses.flexRow}>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo15_400_grey,
                                    classes.typoTracking
                                )}
                            >
                                Courier:
                            </Typography>
                            <Typography
                                className={terminalCommonClasses.typo15_400}
                            >
                                {props.order.courier
                                    ? props.order.courier
                                    : 'Pending'}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography
                        className={terminalCommonClasses.typo15_400_grey}
                    >
                        Pending...
                    </Typography>
                )}
            </Grid>
        </Grid>
    );

    const gridItems = (
        <Box className={classes.boxItems}>
            <Typography className={clsx(classes.typoName, classes.typoItems)}>
                Items
            </Typography>

            {modelsTerminal.map((model, index) => {
                const qty = props.order.terminals
                    .filter(
                        terminal =>
                            model.id.toUpperCase() === terminal.id.toUpperCase()
                    )
                    .reduce((total, terminal) => total + 1, 0);
                if (qty > 0) {
                    return (
                        <Grid
                            container
                            className={clsx({
                                [classes.gridItemSecond]: index > 0
                            })}
                            key={model.id}
                        >
                            <Grid item xs={1}>
                                {model.id.toUpperCase() ? (
                                    <TerminalImage id={model.id} />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Typography
                                    className={
                                        terminalCommonClasses.typo15_400_orange
                                    }
                                >
                                    {model.type}
                                </Typography>
                                <Typography
                                    className={terminalCommonClasses.typo16_600}
                                >
                                    {model.name}
                                </Typography>
                                <Typography
                                    className={clsx(
                                        terminalCommonClasses.typo15_400,
                                        classes.descriptionModel
                                    )}
                                >
                                    {model.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.gridCenter}>
                                <Typography
                                    className={terminalCommonClasses.typo14_600}
                                >
                                    {index === 0 ? 'Qty' : ''}
                                </Typography>
                                <Typography
                                    className={clsx(
                                        terminalCommonClasses.typo14_600,
                                        classes.typoQty
                                    )}
                                >
                                    {qty}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.gridCenter}>
                                <Typography
                                    className={terminalCommonClasses.typo14_600}
                                >
                                    {index === 0 ? 'Price' : ''}
                                </Typography>
                                <Typography
                                    className={clsx(
                                        terminalCommonClasses.typo16_600,
                                        classes.typoQty
                                    )}
                                >
                                    ${model.price}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                } else {
                    return <></>;
                }
            })}
        </Box>
    );

    const content = (
        <Box className={classes.contentContainer}>
            <Grid container>
                <Grid item xs={8}>
                    <Grid container className={classes.gridInfo}>
                        {gridInfo}
                        {gridDelivery}
                        {gridItems}
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                    <Typography className={classes.typoName}>
                        Shipping Address
                    </Typography>
                    <Box className={classes.boxDeliveryName}>
                        <Typography
                            className={clsx(
                                terminalCommonClasses.typo16_600,
                                classes.textOverflow
                            )}
                        >
                            {props.order.deliveryName
                                ? props.order.deliveryName
                                : '-'}
                        </Typography>
                        <Typography
                            className={clsx(
                                terminalCommonClasses.typo15_400,
                                classes.textOverflow
                            )}
                        >
                            {props.order.deliveryPhone
                                ? formatPhoneNumber(
                                      props.order.deliveryPhone,
                                      apiClientWrapper.marketplace!.basics
                                          .address.country!.id
                                  )
                                : '-'}
                        </Typography>
                    </Box>

                    <Typography className={terminalCommonClasses.typo15_400}>
                        {props.order.deliveryAddress}
                    </Typography>
                    <Typography
                        className={terminalCommonClasses.typo15_400}
                    >{`${props.order.deliveryState}, ${props.order.deliveryZipcode}`}</Typography>
                    <Typography className={terminalCommonClasses.typo15_400}>
                        {props.order.deliveryCountry}
                    </Typography>

                    <Box className={classes.typoPaymentInfo}>
                        <Typography className={clsx(classes.typoName)}>
                            Payment Info
                        </Typography>
                        <Typography
                            className={clsx(terminalCommonClasses.typo16_400)}
                        >
                            {checkIconPaymentMethod()}
                            {checkPaymentMethod()}
                        </Typography>
                    </Box>

                    <Grid container className={classes.gridTotal}>
                        <Grid item xs={8}>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo14_500,
                                    classes.typoSubtotal
                                )}
                            >
                                Subtotal
                            </Typography>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo14_500,
                                    classes.typoSubtotal
                                )}
                            >
                                Shipping
                            </Typography>
                            <Typography
                                className={terminalCommonClasses.typo16_600}
                            >
                                Order Total
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo14_400,
                                    classes.typoSubtotal
                                )}
                            >{`$ ${Number(props.order.price).toFixed(
                                2
                            )}`}</Typography>
                            <Typography
                                className={clsx(
                                    terminalCommonClasses.typo14_600_green,
                                    classes.typoSubtotal
                                )}
                            >
                                Free
                            </Typography>
                            <Typography
                                className={terminalCommonClasses.typo14_600}
                            >{`$ ${Number(props.order.price).toFixed(
                                2
                            )}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Fragment>
            <NotMobileYet />
            <Box
                className={clsx(
                    commonClasses.container,
                    props.className,
                    classes.boxOrder
                )}
            >
                <Box className={classes.baseContainer}>{header}</Box>
                <Box className={classes.boxContent}>
                    <Box className={clsx(classes.baseContainer)}>{content}</Box>
                </Box>
            </Box>

            {showCancelPopUp && cancelOrderView}
            {showReceivedPopUp && receivedOrderView}
        </Fragment>
    );
};

export default OrderDetail;
