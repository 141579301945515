import { Box } from "@material-ui/core";
import Client from "@spike/client-model";
import useStyles from "./sendModal.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

export default function Alert({ clientInfo }: { clientInfo: Client }) {
  const classes = useStyles();
  return (
    <Box className={classes.alert}>
      <FontAwesomeIcon icon={faInfoCircle} />
      Client without
      {!clientInfo?.email && " email"}
      {!clientInfo?.email && !clientInfo?.phone && " & "}
      {!clientInfo?.phone && " phone number"}
    </Box>
  );
}
