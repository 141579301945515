import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { capitalize } from 'lodash';
import { Checkbox, TextFieldWithButton, Chip } from 'components/UI';
import { MobileDivider } from 'components/UI/MobileDivider';

interface Props {
    systemTags: Array<string>;
    onChange: (tags: Array<string>) => void;
    tags: Array<string>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            'fontSize': 16,
            'fontWeight': 600,
            'lineHeight': 1.4,
            'marginBottom': 6,

            [theme.breakpoints.up('md')]: {
                fontSize: 20,
                fontWeight: 600
            },

            '& span': {
                fontWeight: 400,
                color: '#969696',

                [theme.breakpoints.up('md')]: {
                    fontSize: 16
                }
            }
        },
        description: {
            fontSize: 14,
            lineHeight: 1.4,
            color: '#000000',
            marginBottom: 16,

            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                color: '#969696'
            }
        },
        systemTagContainer: {
            gap: 14,
            marginTop: 29,
            display: 'flex',
            flexWrap: 'wrap'
        },
        systemTag: {
            '& .MuiCheckbox-root': {
                paddingLeft: 0
            },
            '& svg': {
                fontSize: 20,
                color: '#000'
            },
            '& p': {
                fontSize: 14,

                [theme.breakpoints.up('md')]: {
                    fontSize: 16
                }
            }
        },
        tagContainer: {
            gap: 12,
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 24
        },
        tagInput: {
            fontSize: 16
        }
    })
);

const tagSeparator = ',';

export const TagManager: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [tags, setTags] = useState<Array<string>>(
        props.tags.map(tag => capitalize(tag))
    );

    const [value, setValue] = useState('');

    useEffect(() => {
        props.onChange(tags.map(tag => tag.toLowerCase()));
    }, [tags]);

    const convertValueToTags = (valueToConvert: string): Array<string> => {
        if (valueToConvert && valueToConvert.length > 0) {
            const tags = valueToConvert.split(tagSeparator);
            return tags
                .map(tag => tag.trimLeft().trimRight())
                .map(tag => capitalize(tag))
                .filter(tag => tag.length > 0);
        } else {
            return [];
        }
    };

    const removeDuplicated = (newTags: Array<string>): Array<string> => {
        return newTags.filter(tag => !tags.includes(tag));
    };

    const handleAddTag = (event: any): void => {
        event.preventDefault();
        if (value.length > 0) {
            const newTags = removeDuplicated(convertValueToTags(value));
            setTags(prev => {
                return prev.concat(newTags).slice(0, 20);
            });
            setValue('');
        }
    };

    const handleSysTag = (index: any) => {
        const capitalizeSystemTag = capitalize(props.systemTags[index]);
        const existSystemTag = tags.indexOf(capitalizeSystemTag) !== -1;

        if (existSystemTag) {
            setTags(prev => {
                return prev.filter(tag => tag !== capitalizeSystemTag);
            });
        } else {
            if (tags.length < 20) {
                setTags([...tags, capitalizeSystemTag]);
            }
        }
    };

    const handleRemoveTag = (index: any): void => {
        setTags(tags.filter((tag, i) => i !== index));
    };

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>
                Add tags <span>(Up to 20)</span>
            </Typography>
            <Typography className={classes.description}>
                Use tags to define the treatments included.
            </Typography>
            <form onSubmit={handleAddTag}>
                <TextFieldWithButton
                    disabled={tags.length >= 20}
                    name="value"
                    value={value}
                    label="Add"
                    onChange={setValue}
                    onClick={handleAddTag}
                    classNameCustom={classes.tagInput}
                />
            </form>

            <Box className={classes.systemTagContainer}>
                {props.systemTags.map((sysTag, index) => (
                    <Checkbox
                        key={index}
                        icon={<FontAwesomeIcon icon={faPlusCircle} size="xs" />}
                        checkedIcon={
                            <FontAwesomeIcon icon={faCheckCircle} size="xs" />
                        }
                        label={capitalize(sysTag.toString())}
                        checked={
                            tags.indexOf(
                                capitalize(props.systemTags[index])
                            ) !== -1
                        }
                        onChange={() => handleSysTag(index)}
                        className={classes.systemTag}
                    />
                ))}
            </Box>
            <Box className={classes.tagContainer}>
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={capitalize(tag)}
                        onDelete={() => handleRemoveTag(index)}
                    />
                ))}
            </Box>

            <Box style={{ margin: '0px -16px' }}>
                <MobileDivider />
            </Box>
        </Box>
    );
};

export default TagManager;
