import React, { useEffect } from 'react';
import {
	Box,
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { BusinessSettingsTitle } from '../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { CardGroup } from './Card/CardGroup';
import { InvoicesList } from './Invoices/InvoicesList';
import { usePremiumPlan } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { MarketplacePaymentMethod } from '@spike/payments-model';
import {
	fetchSubscriptionInvoicesThunk,
	getSubscriptionPaymentMethodThunk
} from 'actions/subscriptions/SubscriptionsActions';
import { AdminInvoice } from 'model/Subscriptions';
import { MobileDivider } from 'components/UI/MobileDivider';

export interface Props {
	openSidebar?: () => void;
	isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		container: {
			width: '100%',
			paddingRight: 16,

			[theme.breakpoints.up('md')]: {
				paddingRight: 45
			}
		},
		iconBusinessTitle: {
			position: 'relative',
			marginRight: '8px'
		},
		listGrid: {
			[theme.breakpoints.up('sm')]: {
				marginBottom: 30
			},
			[theme.breakpoints.up('md')]: {
				marginBottom: 60
			},

			'& h3': {
				color: '#222',
				fontSize: 16,
				lineHeight: 1,
				fontWeight: 600,
				marginBottom: 14,
			},
			'& .MuiTypography-body2': {
				margin: 0,
				fontSize: 16,
				lineHeight: 1.4,

			}
		},
		emptyBox: {
			width: '100%',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			borderRadius: 18,

			[theme.breakpoints.down('md')]: {
				marginBottom: 20
			},

			[theme.breakpoints.up('md')]: {
				padding: '29px 24px',
				border: 'solid 1px #DDDDDD'
			}
		},
		invoicesTitle: {
			fontSize: 16,
			lineHeight: 2,
			fontWeight: 600,
			marginBottom: 14
		},
		cardTitle: {
			fontSize: 16,
			lineHeight: 2,
			fontWeight: 600
		},
		cardText: {
			fontSize: 16
		},
		invoicesEmpty: {
			'fontSize': 16,
			'lineHeight': 1,
			'fontWeight': 500,

			'& svg': {
				marginRight: 9,
				color: '#5E8677'
			}
		}
	})
);

export const Billing: React.FunctionComponent<Props> = ({
	openSidebar,
	isMobile
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const premiumPlan = usePremiumPlan();

	const invoices = useSelector<RootState, Array<AdminInvoice>>(
		state => state.subscriptions.invoices
	);

	const paymentMethod = useSelector<
		RootState,
		MarketplacePaymentMethod | undefined
	>(state => state.subscriptions.paymentMethod);

	const NoResultsBox = ({ message }: { message: string }) => (
		<Box className={classes.emptyBox}>{message}</Box>
	);

	const InvoicesEmpty = () => (
		<Typography className={classes.invoicesEmpty}>
			<FontAwesomeIcon icon={faInfoCircle} /> No invoices found.
		</Typography>
	);

	useEffect(() => {
		dispatch(fetchSubscriptionInvoicesThunk());
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (premiumPlan?.id) {
			dispatch(getSubscriptionPaymentMethodThunk(premiumPlan.id));
		}
		// eslint-disable-next-line
	}, [premiumPlan]);

	return (
		<Box className={classes.root}>
			<Box className={classes.container}>
				<BusinessSettingsTitle>
					{isMobile && (
						<FontAwesomeIcon
							onClick={openSidebar}
							icon={faArrowLeft}
							size="2x"
						/>
					)}
					Billing
				</BusinessSettingsTitle>

				<Grid container spacing={3} className={classes.listGrid}>
					<Grid item xs={11} md={4}>
						<Typography variant="h3" className={classes.cardTitle}>Card Details</Typography>
						<Typography variant="body2" className={classes.cardText}>
							This card will be used for billing purposes &
							recurring fees if applicable.
						</Typography>
					</Grid>
					<Grid item xs={12} md>
						{paymentMethod ? (
							<CardGroup cards={[paymentMethod]} />
						) : (
							<NoResultsBox message="No credit card on file." />
						)}
					</Grid>
				</Grid>
			</Box>

			<MobileDivider style={{ marginTop: 10 }} />

			<Box className={classes.container}>
				{/* <Grid container spacing={3} className={classes.listGrid}>
					<Grid item xs={4}>
						<Typography variant="h3">Billing Address</Typography>
						<Typography variant="body2">
							Please provide the billing address associated with your
							card on file.
						</Typography>
					</Grid>
					<Grid item xs>
						{showData ? (
							<AddressGroup />
						) : (
							<NoResultsBox message="No billing address added." />
						)}
					</Grid>
				</Grid> */}

				<Typography variant="h3" className={classes.invoicesTitle}>
					Billing-Invoices & Fees
				</Typography>

				{invoices.length === 0 && <InvoicesEmpty />}
			</Box>

			{invoices.length > 0 && (
				<>
					{/* <Box className={classes.container}>
						<InvoicesFilter
							selectedFilter={selectedFilter}
							onSelect={selectFilterHandler}
						/>
					</Box> */}
					<InvoicesList invoices={invoices} />
				</>
			)}
		</Box>
	);
};
