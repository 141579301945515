import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import isEmpty from "lodash/isEmpty";

interface VetRowProps {
  icon: IconDefinition;
  text?: string;
  underline?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        padding: `0px ${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "3px 0px",
      },
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(36)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "36px",
      },
    },
    icon: {
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      }
    },
    text: (props: VetRowProps) => ({
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "black",
      textDecorationLine: props.underline && props.text ? "underline" : "none",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(16)}px`,
        lineHeight: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
      },
    }),
  })
);

export const VetRow: FunctionComponent<VetRowProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>
        <FontAwesomeIcon icon={props.icon} className={classes.icon} />
      </Box>
      <Typography className={classes.text}>{isEmpty(props.text) ? "Not Specified" : props.text}</Typography>
    </Box>
  );
};

export default VetRow;
