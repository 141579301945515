import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface SideBarPetMenuItemProps {
  option: string | JSX.Element;
  selected: boolean;
  className?: string;
  //
  onSelect: (option: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemMenu: {
      fontWeight: 500,
      cursor: "pointer",
      fontSize: 18,
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px ${reduceResolution(35)}px`,
        fontSize: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        padding: "10px 35px",
      },
      "&:hover": {
        background: "#E6E3DD",
        color: "#000",
      }
    },
    itemSelected: {
      background: "#E6E3DD",
    },
  })
);

export const SidebarPetMenuItem: FunctionComponent<SideBarPetMenuItemProps> = (props) => {
  const classes = useStyles();

  const selectMenuHandler = (option: string) => {
    props.onSelect(option);
  }

  return (
    <Box className={clsx(classes.itemMenu, props.className,
      { [classes.itemSelected]: props.selected }
    )}

      onClick={() => selectMenuHandler("info")}>{props.option}</Box>
  );
};

export default SidebarPetMenuItem;
