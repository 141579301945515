import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import SelectionBarItem from './SelectionBarItem';

export interface SelectionBarProps {
	options: Array<Option<string>>;
	selectedId: string;
	selectedTotalRecords?: number;
	background?: string;
	borderColor?: string;
	className?: string;
	//
	onSelect: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: (props: SelectionBarProps) => ({
			gap: 10,
			display: 'flex',
			background: props.background || '#F8F8F8',
			border: props.borderColor
				? `1px solid ${props.borderColor}`
				: 'none',
			height: 'fit-content',
			width: 'fit-content',
			[theme.breakpoints.up('md')]: {
				gap: 14,
				paddingTop: 5,
				paddingLeft: 8,
				paddingRight: 8,
				paddingBottom: 5,
				borderRadius: 30
			}
		})
	})
);

export const SelectionBar: FunctionComponent<SelectionBarProps> = props => {
	const classes = useStyles(props);

	const [selectedId, setSelectedId] = useState(props.selectedId);

	useEffect(() => {
		setSelectedId(props.selectedId);
	}, [props.selectedId]);

	const clickHandler = (id: string) => {
		setSelectedId(id);
		props.onSelect(id);
	};

	const buttons = props.options.map(option => {
		return (
			<Fragment key={option.id}>
				<Box>
					<SelectionBarItem
						label={
							option.id === selectedId &&
							props.selectedTotalRecords !== undefined
								? `${option.name} (${props.selectedTotalRecords})`
								: option.name
						}
						selected={option.id === selectedId}
						onClick={() => {
							clickHandler(option.id);
						}}
					/>
				</Box>
			</Fragment>
		);
	});

	return (
		<Box className={clsx(classes.container, props.className)}>
			{buttons}
		</Box>
	);
};

export default SelectionBar;
