import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { SortFields, SortOrder } from 'model/Staff';
import React, { FunctionComponent, useState } from 'react';
import TableHeaderItem from './TableHeaderItem';

interface Props {
	onSort: (field: SortFields, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			borderBottom: '1px solid #222222',

			[theme.breakpoints.down('sm')]: {
				display: 'none'
			},
			[theme.breakpoints.up('md')]: {
				backgroundColor: '#FAFAFA'
			},
			[theme.breakpoints.down('lg')]: {
				paddingBottom: '10px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingBottom: '20px'
			}
		},
		rowContainer: {
			display: 'flex',
			width: '100%',
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			}
		},
		leftSpace: {
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '32px'
			}
		},
		rightSpace: {
			[theme.breakpoints.down('lg')]: {
				marginLeft: '-15px'
			},
			[theme.breakpoints.up('xl')]: {
				marginLeft: '-20px'
			}
		},
		headerInactive: {
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '75px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '105px'
			}
		},
		leftSpaceServices: {
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '45px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '60px'
			}
		}
	})
);

export const StaffHeaderList: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const [sortField, setSortField] = useState<SortFields | undefined>(
		undefined
	);
	const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(
		undefined
	);

	const sortHandler = (field: SortFields, order: SortOrder) => {
		setSortField(field);
		setSortOrder(order);
		props.onSort(field, order);
	};

	return (
		<Grid container className={classes.container}>
			<Grid container className={classes.rowContainer}>
				<Box className={classes.headerInactive}></Box>
				<Grid item xs={2}>
					<TableHeaderItem
						label="First Name"
						sortField={SortFields.FIRST_NAME}
						sortOrder={
							sortField === SortFields.FIRST_NAME
								? sortOrder
								: undefined
						}
						onSort={sortHandler}
					/>
				</Grid>
				<Grid item xs={1}>
					<TableHeaderItem
						label="Role"
						sortField={SortFields.ROLE}
						sortOrder={
							sortField === SortFields.ROLE
								? sortOrder
								: undefined
						}
						onSort={sortHandler}
					/>
				</Grid>
				<Grid item xs={1}>
					<TableHeaderItem
						label="Schedule"
						sortField={SortFields.SCHEDULE}
						sortOrder={
							sortField === SortFields.SCHEDULE
								? sortOrder
								: undefined
						}
						onSort={sortHandler}
					/>
				</Grid>
				<Grid item xs={2} className={classes.leftSpaceServices}>
					Services
				</Grid>
				<Grid item xs={3}>
					<TableHeaderItem
						label="Email"
						sortField={SortFields.EMAIL}
						sortOrder={
							sortField === SortFields.EMAIL
								? sortOrder
								: undefined
						}
						onSort={sortHandler}
					/>
				</Grid>
				<Grid item xs={1} className={classes.rightSpace}>
					<TableHeaderItem
						label="Phone Number"
						sortField={SortFields.PHONE_NUMBER}
						sortOrder={
							sortField === SortFields.PHONE_NUMBER
								? sortOrder
								: undefined
						}
						onSort={sortHandler}
					/>
				</Grid>
				<Grid item xs={1} className={classes.leftSpace}></Grid>
			</Grid>
		</Grid>
	);
};
