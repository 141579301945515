import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import Appointment, { status } from '@spike/appointment-model';
import { AvailableStaff } from '@spike/available-staff-model';
import EditableStaffChip from 'components/UI/EditableStaffChip';

interface Props {
    appointment: Appointment;
    onAssignStaff: (
        appointmentId: number,
        member: AvailableStaff | undefined
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        staffContainer: {
            gap: 10,
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                gap: 6,
                display: 'inline-flex'
            }
        },
        alignCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        staffWarning: {
            color: '#E36E74',

            [theme.breakpoints.down('sm')]: {
                right: 14,
                position: 'absolute'
            }
        },
        chipWithIssue: {
            '& > div': {
                [theme.breakpoints.down('sm')]: {
                    paddingRight: 16
                }
            }
        }
    })
);

export const Staff: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const reassignable = [
        status.BOOKED,
        status.CONFIRMED,
        status.CHECKED_IN,
        status.IN_PROGRESS
    ];

    const [service] = props.appointment.services;

    const issueDescription =
        service.staff === undefined
            ? 'Please consider reassigning this appointment to someone with availability.'
            : '';

    const hasStaffIssue = service.staff === undefined;

    return (
        <Box className={classes.staffContainer}>
            <Box className={classes.alignCenter}>
                <EditableStaffChip
                    selectedMember={service.staff}
                    appointmentId={props.appointment.id!}
                    onSelectMember={member => {
                        props.onAssignStaff(props.appointment.id!, member);
                    }}
                    editable={reassignable.includes(
                        props.appointment.status.id
                    )}
                    unassignable={true}
                    className={hasStaffIssue ? classes.chipWithIssue : ''}
                />
            </Box>
            <Box className={classes.alignCenter}>
                {hasStaffIssue && (
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className={classes.staffWarning}
                        title={issueDescription}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Staff;
