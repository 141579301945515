import React, { FunctionComponent } from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import ViewSelector from './ViewSelector';
import { Link } from 'react-router-dom';
import { SMSCampaignStatus } from 'model/SMSCampaign';
import { SelectionBarOption } from './ViewSelector/model';
import Button from 'components/UI/V2/Button/Button';

interface SMSDashboardHeaderProps {
    selectedFilter: string;
    onFilterSelect: (opt: SMSCampaignStatus) => void;
}

const filters: SelectionBarOption[] = [
    {
        id: SMSCampaignStatus.sent,
        name: 'Sent'
    },
    {
        id: SMSCampaignStatus.scheduled,
        name: 'Pending'
    },
    {
        id: SMSCampaignStatus.draft,
        name: 'Drafts'
    },
    {
        id: SMSCampaignStatus.archived,
        name: 'Archived'
    }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 40,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                alignItems: 'flex-start',
                paddingTop: 20
            }
        },
        desktopContainer: {
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                padding: '0 16px'
            }
        },
        titleColumn: {
            'marginRight': 30,
            '& p': {
                margin: 0,
                fontSize: 24,
                lineHeight: 1,
                fontWeight: 600,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 20
                }
            }
        },
        viewSelector: {
            [theme.breakpoints.down('md')]: {
                paddingTop: 20,
                width: '100%'
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: 20
            }
        },
        btnColumn: {
            'marginLeft': 'auto',
            '& a': {
                textDecoration: 'none'
            }
        },
        button: {
            'height': 55,
            'padding': '0px 20px',
            [theme.breakpoints.down('lg')]: {
                height: 39
            },
            '& .MuiButton-startIcon': {
                '& svg': {
                    width: 16,
                    height: 16,
                    marginRight: 2
                }
            },
            '& span': {
                fontSize: 18,
                fontWeight: 600,
                [theme.breakpoints.down('lg')]: {
                    fontSize: 14
                }
            }
        }
    })
);

const SMSDashboardHeader: FunctionComponent<
    SMSDashboardHeaderProps
> = props => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const newSMSPath =
        props.selectedFilter === 'drafts'
            ? '/marketing/sms/get-started'
            : '/marketing/sms/create';

    const onSelect = (opt: string) => {
        props.onFilterSelect && props.onFilterSelect(opt as SMSCampaignStatus);
    };
    return (
        <Box className={classes.root}>
            <Box className={classes.desktopContainer}>
                <Box className={classes.titleColumn}>
                    <Typography>SMS Campaigns</Typography>
                </Box>

                {!isMobile && (
                    <Box className={classes.viewSelector}>
                        <ViewSelector
                            options={filters}
                            selectedOptionId={props.selectedFilter}
                            onSelect={onSelect}
                        />
                    </Box>
                )}
                <Box className={classes.btnColumn}>
                    <Link to={newSMSPath}>
                        <Button leftIcon={faPlus} label="New SMS" />
                    </Link>
                </Box>
            </Box>

            {isMobile && (
                <Box className={classes.viewSelector}>
                    <ViewSelector
                        options={filters}
                        selectedOptionId={props.selectedFilter}
                        onSelect={onSelect}
                    />
                </Box>
            )}
        </Box>
    );
};

export default SMSDashboardHeader;
