import { Country, FieldError } from '@spike/model';
import { PetMedicalHistory } from '@spike/medical-history-model';
import Vet from '@spike/vet-model';
import isEmpty from 'lodash/isEmpty';
import { isEmailValid, validatePhoneNumber } from '@spike/validations';

const SurgeryCategoryId = 10;

export const validate = (
    country: Country,
    medicalHistory: PetMedicalHistory,
    vet?: Vet
): Array<FieldError> => {
    const vetErrors = validateVet(country, vet);
    const vaccinesErrors = validateVaccines(medicalHistory);
    const medicalConditionsErrors = validateMedicalConditions(medicalHistory);

    return [...vetErrors, ...vaccinesErrors, ...medicalConditionsErrors];
};

export const validateVet = (country: Country, vet?: Vet): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    const emptyVet = isEmptyVet(vet);

    if (!emptyVet && isEmpty(vet?.name)) {
        errors.push({ fieldName: 'vet_name', errorMessage: 'Is Required' });
    }

    if (!emptyVet && isEmpty(vet?.businessName)) {
        errors.push({
            fieldName: 'vet_businessName',
            errorMessage: 'Is Required'
        });
    }

    if (!isEmpty(vet?.email) && !isEmailValid(vet?.email || '')) {
        errors.push({
            fieldName: 'vet_email',
            errorMessage: 'Invalid email format.'
        });
    }

    !isEmpty(vet?.phone) &&
        errors.push(
            ...validatePhoneNumber(vet?.phone || '', country.id, 'vet_phone')
        );

    return errors;
};

export const validateVaccines = (
    medicalHistory: PetMedicalHistory
): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (medicalHistory.vaccines.some(vaccine => vaccine.type === undefined)) {
        errors.push({ fieldName: 'vaccine_type', errorMessage: 'Is Required' });
    }

    if (
        medicalHistory.vaccines.some(
            vaccine => vaccine.expiration === undefined
        )
    ) {
        errors.push({
            fieldName: 'vaccine_expiration',
            errorMessage: 'Is Required'
        });
    }

    return errors;
};

export const validateMedicalConditions = (
    medicalHistory: PetMedicalHistory
): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    medicalHistory.medicalConditions
        .filter(
            medicalCondition =>
                medicalCondition.medicalConditionCategoryId !==
                SurgeryCategoryId
        )
        .filter(medicalCondition => !medicalCondition.medicalConditionId)
        .forEach(medicalCondition => {
            errors.push({
                fieldName: `category_${medicalCondition.medicalConditionCategoryId}`,
                errorMessage: 'Select at least one.'
            });
        });

    const surgery = medicalHistory.medicalConditions.find(
        medicalCondition =>
            medicalCondition.medicalConditionCategoryId === SurgeryCategoryId
    );

    if (surgery && isEmpty(surgery.explanation)) {
        errors.push({
            fieldName: `category_${surgery.medicalConditionCategoryId}`,
            errorMessage: 'Description is required'
        });
    }

    return errors;
};

export const isEmptyVet = (vet?: Vet): boolean => {
    return (
        !vet ||
        (isEmpty(vet.name) &&
            isEmpty(vet.businessName) &&
            isEmpty(vet.email) &&
            isEmpty(vet.phone) &&
            isEmpty(vet.address))
    );
};
