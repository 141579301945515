import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import PrimaryLink from '../Links/PrimaryLink';
import { LoginPath } from "routes/Paths";

const useStyles = makeStyles((theme: Theme) => ({
    emoji: {
        fontSize: 50,
        marginBottom: 10,
    },
    title: {
        maxWidth: 300,
        textAlign: 'center',
        margin: '0 auto 20px auto',
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 40
        }
    },
    paragraph: {
        maxWidth: 260,
        marginTop: 0,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 35,
        textAlign: 'center',
        '& + &': {
            marginBottom: 30
        }
    },
    column: {
        display: 'flex',
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 60,
        marginLeft: 'auto',
        minHeight: 'calc(100vh - 142px)',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#F8F5F1',
        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: 100,
            paddingRight: 100,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '55%',
            flexBasis: '55%',
            paddingTop: 0,
            paddingLeft: '7%',
            paddingRight: '9%',
            paddingBottom: 0,
            backgroundColor: '#ffffff',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 110,
            paddingLeft: '7%',
            paddingRight: '13%',
        }
    },
    button: {
        width: 'auto',
        paddingLeft: 36,
        paddingRight: 36
    },
    formColumn: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 16
    }
}));

export const RightColumn: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.column}>
            <h2 className={classes.emoji}>🎉</h2>

            <h2 className={classes.title}>New password, new you</h2>

            <p className={classes.paragraph}>Your new password was created succesfully.</p>

            <PrimaryLink to={LoginPath} label="Go to login" className={classes.button} />
        </Grid>
    );
}

export default RightColumn;