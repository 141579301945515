import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PetDental } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import RawDateFormat from "components/UI/RawDateFormat";
import { ItemLabelRead } from "components/UI";

interface DentalsTableRowProps {
  dental: PetDental;
  className?: string;
  last?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(10)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "10px 0",
      },
    },
    rowBorder: {
      borderBottom: "1px solid #EFEFEF",
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down(wbp)]: {
        lineHeight: `${reduceResolution(24)}px`,
        fontSize: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        lineHeight: "24px",
        fontSize: "15px",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "21px",
        fontSize: "14px",
        fontWeight: 400,
      },
    },
    header: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
        fontSize: "14px !important",
        fontWeight: 500,
        paddingBottom: "2px",
      },
    },
    containerDentalsTable: {
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        flexWrap: "nowrap",
        paddingTop: "0px",
      },
    },
    itemDental: {
      [theme.breakpoints.down("md")]: {
        paddingTop: "10px",
      },
    }
  })
);

export const DentalsTableRow: FunctionComponent<DentalsTableRowProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.row, classes.containerDentalsTable, { [classes.rowBorder]: !props.last }, props.className)}>
      <Grid item xs={4} className={classes.itemDental}>
        <ItemLabelRead title="Dental Type" classTitle={classes.header} />
        <Typography className={classes.text}>{props.dental.name}</Typography>
      </Grid>
      <Grid item xs={4} className={classes.itemDental}>
        <ItemLabelRead title="Extractions" classTitle={classes.header} />
        <Typography className={classes.text}>
          {props.dental.teethExtracted === undefined ? "" : props.dental.teethExtracted ? "YES" : "NO"}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.itemDental}>
        <ItemLabelRead title="Cleaning Date" classTitle={classes.header} />
        {props.dental.cleaningDate ? <RawDateFormat className={classes.text} date={props.dental.cleaningDate} /> : ""}
      </Grid>
    </Grid>
  );
};

export default DentalsTableRow;
