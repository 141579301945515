import { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { Product } from "@spike/product-model";
import { PriceFormat } from "components/UI";

interface Props {
  product: Product;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingLeft: "0px",
      verticalAlign: "top !important",
      width: "45%",
      "&.MuiTableCell-root": {
        border: "none !important"
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        paddingTop: "21px",
        paddingBottom: "21px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    nameContainer: {
      display: "flex"
    },
    priceContainer: {
      display: "flex"
    },
  })
);

export const ProductRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={clsx(classes.cell)}>
        <Box className={classes.nameContainer}>
          <Typography className={classes.text}>{props.product.businessProduct?.name ? props.product.businessProduct?.name : props.product.name}</Typography>
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <Typography className={classes.text}>{props.product.category?.name}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell)}>
        <Box className={classes.priceContainer}>
          <PriceFormat price={props.product?.businessProduct?.pricing?.price || "-"} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;