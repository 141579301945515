import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
    Box,
    Typography,
    makeStyles,
    Theme,
    createStyles
} from '@material-ui/core';
import Client from '@spike/client-model';
import { fetchClientsThunk } from '@spike/clients-action';
import { Button } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';

export interface Props {
    className?: string;
    chatSearchActive?: boolean;
    onNewChatRequest: (chatSearchActive?: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(wbp)]: {
                marginBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginBottom: '20px'
            }
        },
        titleColumn: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '30px'
            },

            '& p': {
                margin: 0,
                fontSize: 24,
                lineHeight: 1,
                fontWeight: 600
            }
        },
        btnColumn: {
            'marginLeft': 'auto',
            '& a': {
                textDecoration: 'none'
            }
        },
        button: {
            'padding': '0px 20px',
            '& .MuiButton-startIcon': {
                '& svg': {
                    width: 16,
                    height: 16,
                    [theme.breakpoints.down('lg')]: {
                        width: 14,
                        height: 14
                    }
                }
            },
            '& span': {
                fontSize: 18,
                fontWeight: 600
            },
            [theme.breakpoints.down('lg')]: {
                'height': 39,
                'padding': '0px 20px',
                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        }
    })
);

export const Header: FunctionComponent<Props> = props => {
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const classes = useStyles();

    const unreadCount = useSelector<RootState, number>(
        state => state.twoWaySms.messageAmount
    );
    const clients = useSelector<RootState, Array<Client>>(
        state => state.clients.list
    );

    const handleNewChatRequest = () => {
        props.onNewChatRequest && props.onNewChatRequest(false);
        if (!clients || (clients && clients.length === 0)) {
            dispatch(fetchClientsThunk(apiClientWrapper, 1, 8));
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.titleColumn}>
                <Typography>Inbox ({unreadCount})</Typography>
            </Box>
            <Box className={classes.btnColumn}>
                <Button
                    className={classes.button}
                    color="orange"
                    size="medium"
                    startIcon={faPlus}
                    label="New Chat"
                    onClick={handleNewChatRequest}
                />
            </Box>
        </Box>
    );
};

export default Header;
