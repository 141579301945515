import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiClientWrapper, createApiClientWrapper } from '@spike/api-client';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
let abortController = new AbortController();

const sessionUuidKey = 'sessionUuid';

const getSession = (): string => {
    const sessionUuid = sessionStorage.getItem(sessionUuidKey);
    const windowName = window.name;

    if (sessionUuid && windowName.length > 0) {
        return sessionUuid;
    } else {
        const newSessionUuid = uuid();

        sessionStorage.setItem(sessionUuidKey, newSessionUuid);

        //To detect a new Tab created by duplication, because the sessionStorage
        //is copied but not the window.name.
        window.name = newSessionUuid;

        return newSessionUuid;
    }
};

export const sessionUuid = getSession();

const config: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_BASE_URL}`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Session-Uuid': sessionUuid
    },
    timeout: 60000
};

const ApiClient: AxiosInstance = axios.create(config);

ApiClient.interceptors.response.use(
    response => {
        const headerVersion =
            response.headers[
                process.env.REACT_APP_VERSION_HEADER_NAME || 'version'
            ];

        if (
            headerVersion !== undefined &&
            headerVersion !== process.env.REACT_APP_VERSION
        ) {
            window.location.reload();
        }

        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export const createWrapper = (state: RootState): ApiClientWrapper => {
    const authMarketplaceId = state.login.auth.marketplaceId!;
    const marketplace = state.marketplace.marketplace;

    return createApiClientWrapper(
        ApiClient,
        createTokenConfig(state.login.auth.token!),
        createMultipartTokenConfig(state.login.auth.token!),
        authMarketplaceId,
        state.login.auth.timeZone!,
        state.masterData.data,
        uuid,
        authMarketplaceId === marketplace?.id ? marketplace : undefined
    );
};

export const createTokenConfig = (token: string): AxiosRequestConfig => {
    if (token) {
        return {
            headers: {
                Authorization: `Bearer ${token}`
            },
            signal: abortController.signal
        };
    } else {
        return {};
    }
};

export const createMultipartTokenConfig = (
    token: string
): AxiosRequestConfig => {
    const tokenConfig = createTokenConfig(token);
    return {
        ...tokenConfig,
        headers: {
            ...tokenConfig.headers,
            'Content-Type': 'multipart/mixed'
        },
        signal: abortController.signal
    };
};

export const cancelAllRequests = () => {
    abortController.abort();
    abortController = new AbortController();
};

export const isCancelled = (error: any) => {
    return axios.isCancel(error);
};

export default ApiClient;
