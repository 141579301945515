import React, { FunctionComponent, KeyboardEvent, useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export interface Props {
  label: string;
  putLabelToPlaceholder?: boolean;
  value: string;
  error?: boolean;
  errorMessage?: string;
  onChange: (value: string) => void;
  onEnterPressed?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
    },
    outlined: {
      borderRadius: "100px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !important",
        borderColor: "#222222 !important",
      },
      "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !imporant",
        borderColor: "#222222 !important",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EA6464 !important",
      },
    },
    showHideLink: {
      cursor: "pointer",
      color: "#606060",
      marginRight: 8,
      textDecoration: "underline",
      width: 30,
      [theme.breakpoints.only("xs")]: {
        fontSize: "6pt",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "6pt",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "8pt",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "10pt",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "12pt",
      },
    },
    label: {
      "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -16px) scale(0.75) !important",
      }
    }
  })
);

export const PasswordField: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [label, setLabel] = useState(props.label);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
    if(props.errorMessage && props.errorMessage.length > 0) {
      setLabel(props.errorMessage);
    } else {
      setLabel(props.label);
    }
  }, [props.error, props.errorMessage, props.label]);

  const handleChange = (event: any): void => {
    setError(false);
    setLabel(props.label);
    props.onChange(event.target.value);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    (event.key === "Enter") && props.onEnterPressed && props.onEnterPressed();
  }

  return (
    <FormControl variant="outlined" className={classes.container}>
      {!props.putLabelToPlaceholder && <InputLabel htmlFor="outlined-adornment-password" error={error} className={classes.label}>
        {label}
      </InputLabel>}
      
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={props.value}
        onChange={handleChange}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <Box
              className={classes.showHideLink}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? "Hide" : "Show"}
            </Box>
          </InputAdornment>
        }
        onKeyPress={keyPressHandler}
        className={classes.outlined}
        placeholder={props.putLabelToPlaceholder ? props.label : ""}
      />
    </FormControl>
  );
};

export default PasswordField;
