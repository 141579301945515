import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';
import { Mark } from './model';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface ViewSelectorProps {
    label: string;
    selected: boolean;
    selectedBackgroundColor?: string;
    onClick: () => void;
    mark?: Mark;
    count?: number;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minWidth: 70,
            height: '35px',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            borderRadius: '30px',
            [theme.breakpoints.down('md')]: {
                background: '#f1f1f1',
                paddingLeft: '11px !important',
                paddingRight: '11px !important',
                height: 32
            },
            [theme.breakpoints.between('md', 'xl')]: {
                paddingLeft: '15px',
                paddingRight: '15px'
            },
            [theme.breakpoints.up(1025)]: {
                paddingLeft: '14px',
                paddingRight: '14px'
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: '34px',
                paddingLeft: '25px',
                paddingRight: '25px'
            }
        },
        selectedContainer: {
            background: (props: ViewSelectorProps) =>
                props.selectedBackgroundColor || '#92B4A7'
        },
        label: {
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('md')]: {
                fontSize: 15,
                fontWeight: 500,
                lineHeight: '24px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px'
            }
        },
        errorLabel: {
            [theme.breakpoints.down('md')]: {
                marginRight: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '8px'
            }
        },
        selectedLabel: {
            color: '#ffffff'
        },
        mark: {
            position: 'absolute',
            verticalAlign: 'top',
            [theme.breakpoints.down('md')]: {
                right: '4px',
                fontSize: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                right: '4px',
                fontSize: '8px'
            },
            [theme.breakpoints.only('xl')]: {
                right: '8px',
                fontSize: '11px'
            }
        },
        warningMark: {
            color: theme.palette.primary.main
        },
        errorMark: {
            color: '#E36E74',
            [theme.breakpoints.down('md')]: {
                width: '11px !important',
                height: '11px !important',
                marginLeft: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '13px !important',
                height: '13px !important'
            },
            [theme.breakpoints.only('xl')]: {
                width: '16px !important',
                height: '16px !important'
            }
        }
    })
);

const createMark = (
    markType: Mark,
    classes: ClassNameMap<'mark' | 'warningMark' | 'errorMark'>
) => {
    if (markType === Mark.WARNING) {
        return (
            <FontAwesomeIcon
                className={clsx(classes.mark, classes.warningMark)}
                icon={faCircle}
            />
        );
    } else if (markType === Mark.ERROR) {
        return (
            <FontAwesomeIcon
                className={clsx(classes.mark, classes.errorMark)}
                icon={faExclamationCircle}
            />
        );
    } else {
        return null;
    }
};

export const ViewSelectorItem: FunctionComponent<ViewSelectorProps> = props => {
    const classes = useStyles(props);

    <FontAwesomeIcon className={classes.mark} icon={faCircle} />;

    const count = props.selected && props.count ? ` (${props.count})` : '';
    return (
        <Box
            className={clsx(
                {
                    [classes.container]: true,
                    [classes.selectedContainer]: props.selected
                },
                props.className
            )}
            onClick={props.onClick}
        >
            <Typography
                className={clsx({
                    [classes.label]: true,
                    [classes.errorLabel]: props.mark === Mark.ERROR,
                    [classes.selectedLabel]: props.selected
                })}
            >
                {props.label}
                {count}
            </Typography>
            {props.mark && !props.selected && createMark(props.mark, classes)}
        </Box>
    );
};

export default ViewSelectorItem;
