import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import { WeekDay } from 'model';

interface DaySelectorProps {
	id?: string;
	day: WeekDay;
	selected: boolean;
	className?: string;
	onClick?: (day: WeekDay) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: 42,
			height: 42,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#F1F1F1',
			borderRadius: '56px',
			cursor: 'pointer',

			[theme.breakpoints.up('md')]: {
				width: 56,
				height: 56
			}
		},
		label: {
			fontSize: 16,
			fontWeight: 500,
			color: '#222222',

			[theme.breakpoints.up('md')]: {
				fontSize: 18
			}
		},
		selected: {
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#fff'
			}
		}
	})
);

export const DaySelector: FunctionComponent<DaySelectorProps> = props => {
	const classes = useStyles();

	return (
		<Box
			id={props.id}
			className={clsx(
				classes.container,
				{ [classes.selected]: props.selected },
				props.className
			)}
			onClick={() => {
				props.onClick && props.onClick(props.day);
			}}
		>
			<Typography className={classes.label}>
				{_.capitalize(props.day.substr(0, 2))}
			</Typography>
		</Box>
	);
};

export default DaySelector;
