import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PetService } from '@spike/new-booking-model';
import { Moment } from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Staff from 'model/Staff';
import { StaffChip } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPencil } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface Props {
    data: AppointmentDataVaraible;
    onEdit: (data: AppointmentDataVaraible) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        serviceStaffContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 15,
            border: '1px solid #D4D4D4',
            borderRadius: 8,
            height: 113,
            width: '100%'
        },
        infoContainer: {
            width: '85%'
        },
        serviceName: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '16px',
            paddingBottom: 7,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        serviceDataContainer: {
            display: 'flex'
        },
        priceAndDuration: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '14px',
            color: '#7A7A7A',
            paddingRight: 8
        },
        spaceClock: {
            paddingRight: '3px !important'
        },
        staff: {
            paddingTop: 7
        },
        editBox: {
            display: 'flex',
            width: '15%',
            justifyContent: 'flex-end'
        },
        editContainer: {
            width: 38,
            height: 38,
            cursor: 'pointer',
            background: '#F1F1F1',
            borderRadius: '50%',
            alignContent: 'center',
            textAlign: 'center'
        }
    })
);

export const ServicesCard: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const staffsState = useSelector<RootState, Array<Staff>>(
        state => state.staff.staff
    );

    const formatStaffToStaffMember = (staffId: number) => {
        const staffSelected = staffsState.find(staff => staff.id === staffId);
        return {
            id: staffSelected?.id!,
            firstName: staffSelected?.person.firstName || '',
            lastName: staffSelected?.person.lastName || '',
            avatar: staffSelected?.person.avatar || ''
        };
    };

    const getServicesTime = (serv: PetServiceWrapper) => {
        const timeInMinutes = serv.durationInMinutes;
        return timeInMinutes! / 60 > 1
            ? `${Math.trunc(timeInMinutes! / 60)}hrs ${
                  timeInMinutes! % 60 !== 0 ? timeInMinutes! % 60 : ''
              }${timeInMinutes! % 60 !== 0 ? 'min' : ''}`
            : `${timeInMinutes! === 60 ? 60 : timeInMinutes! % 60}min`;
    };

    return (
        <Box className={classes.serviceStaffContainer}>
            <Box className={classes.infoContainer}>
                <Typography className={classes.serviceName}>
                    {props.data.service.service.name}
                </Typography>
                <Box className={classes.serviceDataContainer}>
                    <Typography className={classes.priceAndDuration}>
                        {`$${props.data.service.price}`}
                    </Typography>
                    <Box className={classes.serviceDataContainer}>
                        <FontAwesomeIcon
                            icon={faClock}
                            className={clsx(
                                classes.priceAndDuration,
                                classes.spaceClock
                            )}
                        />
                        <Typography className={classes.priceAndDuration}>
                            {getServicesTime(props.data.service)}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.staff}>
                    <StaffChip
                        staff={formatStaffToStaffMember(props.data.staffId)}
                    />
                </Box>
            </Box>
            <Box className={classes.editBox}>
                <Box
                    className={classes.editContainer}
                    onClick={() => props.onEdit(props.data)}
                >
                    <FontAwesomeIcon icon={faPencil} />
                </Box>
            </Box>
        </Box>
    );
};

export default ServicesCard;
