import { Box, Typography } from '@material-ui/core';
import { PriceError } from '../../model';
import { PriceField } from 'components/UI';
import { isEmpty, isEqual } from 'lodash';
import { FunctionComponent, memo } from 'react';

interface PriceFieldWithErrorProps {
    value?: number;
    fieldUuid?: string;
    errors: Array<PriceError>;
    min?: number;
    max?: number;
    onChange: (value: number | undefined) => void;
    onBlur: () => void;
    disabled?: boolean;
    className?: string;
    errorClassName?: string;
    containerClassName?: string;
}

const PriceFieldWithError: FunctionComponent<PriceFieldWithErrorProps> = props => {
    const hasError = props.errors.find(error => isEqual(error.priceUuid, props.fieldUuid)) !== undefined;
    const errorMessage = props.errors.find(error => isEqual(error.priceUuid, props.fieldUuid))?.errorMessage;

    return (
        <Box className={props.containerClassName} display="flex" flexDirection="column">
            <PriceField
                className={props.className}
                placeholder="$0.00"
                value={props.value}
                error={hasError}
                min={props.min}
                max={props.max}
                onBlur={props.onBlur}
                onChange={props.onChange}
                disabled={props.disabled}
            />
            {!isEmpty(errorMessage) && (
                <Typography
                    color="error"
                    variant="caption"
                    className={props.errorClassName}
                    style={{ marginTop: '4px' }}
                >
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );
};

export const MemoizedPriceFieldWithError = memo(PriceFieldWithError);
