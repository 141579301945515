import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell, TableRow } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { PriceFormat } from "components/UI";
import { VendorCost } from "@spike/product-model";
import ProductVendor from "model/ProductVendor";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { formatPhoneNumber } from "@spike/phone-utils";
import { useMarketplace } from "hooks";

interface Props {
  vendorCost: VendorCost;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      width: "100%",
    },
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      fontWeight: 500,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "16px",
        paddingTop: "16px",
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "20px",
        paddingTop: "20px",
        fontSize: "16px",
      },
      "&.MuiTableCell-root": {
        borderBottom: "none",
      },
    },
    iconCheck: {
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    iconCell: {
      width: "3%",
    },
    normalCell: {
      width: "15%",
    },
    largeCell: {
      width: "25%",
    },
  })
);

export const VendorRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const marketplace = useMarketplace();

  const vendors = useSelector<RootState, Array<ProductVendor>>((state) => state.productVendors.list);

  const vendor = vendors.find((vendor) => vendor.id === props.vendorCost.vendor?.id);

  return (
    <TableRow className={clsx(classes.row)}>
      <TableCell className={clsx(classes.cell, classes.iconCell)}>
        {props.vendorCost.default && (
          <FontAwesomeIcon icon={faCheckCircle} color="#5E8677" className={classes.iconCheck} />
        )}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.normalCell)}>{props.vendorCost.vendor?.name || "-"}</TableCell>
      <TableCell className={clsx(classes.cell, classes.normalCell)}>
        {props.vendorCost.vendor?.contact || "-"}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.normalCell)}>
        {formatPhoneNumber(props.vendorCost.vendor?.phone || "-", marketplace.basics.address.country!.id)}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.largeCell)}>{props.vendorCost.vendor?.email || "-"}</TableCell>
      <TableCell className={clsx(classes.cell, classes.largeCell)}>
        {vendor && vendor.address
          ? vendor.address.street + "," + vendor.address.city + "," + vendor.address.state
          : "-"}
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.normalCell)}>
        <PriceFormat price={props.vendorCost.cost || "-"} />
      </TableCell>
    </TableRow>
  );
};

export default VendorRow;
