import { FunctionComponent, Fragment, useState, useEffect, useRef } from "react";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { Button } from "components/UI";
import { Product, createEmptyProduct } from "@spike/product-model";
import { ProductsStatus } from "@spike/products-action";
import Details from "./Details";
import ResumeDetails from "./Details/ResumeDetails";
import ResumeVendorsAndCost from "./VendorsAndCost/ResumeVendorsAndCost";
import { deleteProductThunk, fetchProductsThunk, saveProductThunk } from "@spike/products-action";
import { fetchThunk as fetchVendorsThunk } from "actions/productVendors/ProductVendorsActions";
import { fetchThunk as fetchCategoriesThunk } from "actions/productCategories/ProductCategoriesActions";
import { FieldError } from "@spike/model";
import { validateRequired } from "utils/ValidationUtils";
import VendorsAndCost from "./VendorsAndCost";
import ConfirmDialog from "../UI/Dialog";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import isEmpty from "lodash/isEmpty";
import { v4 as uuid } from "uuid";
import { useApiClientWrapper } from "hooks";

interface Props {
  product?: Product;
  redirection: boolean;
  onBack: () => void;
  onAddVendor: (product: Product) => void;
  onAddCategory: (product: Product) => void;
  onShowVendor: (id: number, product: Product) => void;
}
interface SectionState {
  collapsed: boolean;
  editable: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#FAFAFA",
      display: "flex",
      flexDirection: "column",
      width: "100%",    
      height: "100%"
    },
    headerContainer: {
      backgroundColor: "#FAFAFA",
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "120px",
        paddingRight: "120px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "150px",
        paddingRight: "150px",
      },
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#FAFAFA",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "112px",
        paddingRight: "112px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "140px",
        paddingRight: "140px",
      },
    },
    successContainer: {
      height: "100%",
      backgroundColor: "#fff !important",
    },
    backContainer: {
      display: "flex",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      width: "32px",
      height: "32px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
        marginLeft: "-72px",
        fontSize: "29px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "40px",
        marginLeft: "-90px",
        fontSize: "36px",
      },
    },
    titleButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        minWidth: "80%",
        marginTop: "25px",
        marginBottom: "22px",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "77%",
        marginTop: "31px",
        marginBottom: "27px",
      },
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "29px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "36px",
      },
    },
    sectionContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        minWidth: "800px",
        marginTop: "31px",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "1000px",
        marginTop: "39px",
      },
    },
    saveMessage: {
      width: "100%",
      textAlign: "left",
      [theme.breakpoints.up("lg")]: {
        fontSize: "15px",
      },
    },
    finishButton: {
      [theme.breakpoints.down("md")]: {
        height: "39px",
        width: "136px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "49px",
        width: "170px",
      },
      "& .MuiButton-label": {
        [theme.breakpoints.down("md")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "16px",
        },
      },
    },
    detailsContainer: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "30px",
      },
    },
    stockContainer: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "96px",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "120px",
      },
    },
    saveButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        paddingTop: "42px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "53px",
      },
    },
    unsavedChanges: {
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    availableForCheckoutBox: {
      display: "flex"
    },
    iconCheck: {
      color: "#5E8677",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        marginTop: "3px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
        marginTop: "2px"
      },
    },
    availableText: {
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        paddingLeft: "11px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        paddingLeft: "14px",
      },
    },
    deleteContainer: {
      display: "flex",
      width: "auto",
      justifyContent: "flex-end"
    },
    deleteContent: {
      display: "flex",
    },
    deleteBox: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "48px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "60px",
      },
    },
    iconDelete: {
      fontWeight: 500,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        marginRight: "3px",
        marginTop: "2px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        marginRight: "6px",
        marginTop: "3px"
      },
    },
    delete: {
      fontWeight: 500,
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    textDialog: {
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    titleDialog: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        lineHeight: "29px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        lineHeight: "36px",
      },
    }
  })
);

enum ProductStep {
  EditStep,
  FinishStep,
  SuccessStep,
}

export const ProductComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const newProduct: Product = {...createEmptyProduct(uuid), createdFromScratch: true};

  const [product, setProduct] = useState(props.product ? props.product : newProduct);

  const [errors, setErrors] = useState<Array<FieldError>>([]);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);

  const [showWarningDeleteProduct, setShowWarningDeleteProduct] = useState(false);

  const isNewProduct = () => {
    return props.product === undefined;
  };

  const loading = useSelector<RootState, boolean>(state => state.products.loading);
  const status = useSelector<RootState, ProductsStatus>(state => state.products.status);

  const [step, setStep] = useState<ProductStep>(isNewProduct() || props.redirection ? ProductStep.EditStep : ProductStep.FinishStep);

  const [withVendors, setWithVendors] = useState(props.product && props.product.vendors && props.product.vendors.length > 0 ? true : false);

  const [lookup, setLookup] = useState(false);

  const vendorsEditRef = useRef<HTMLDivElement>(null);

  const isSuccessStep = () => {
    return step === ProductStep.SuccessStep;
  };

  const [editVendor, setEditVendor] = useState(false);

  const [details, setDetails] = useState<SectionState>({
    collapsed: false,
    editable: true,
  });
  const [vendorCost, setVendorCost] = useState<SectionState>({
    collapsed: false,
    editable: true,
  });


  useEffect(() => {
    if (status === ProductsStatus.SaveSuccess || status === ProductsStatus.DeleteSuccess) {
      dispatch(fetchProductsThunk(apiClientWrapper));   
      dispatch(fetchVendorsThunk());
      dispatch(fetchCategoriesThunk());  
      setUnsavedChanges(false);
      props.onBack();
    }
  }, [status]);

  useEffect(() => {
    if (props.product) {
      setProduct(props.product); 
    }
  }, [props.product, lookup]);

  useEffect(() => {
    if(editVendor) {
      if (null !== vendorsEditRef.current) {
        vendorsEditRef.current.scrollIntoView();
      }
    }
  }, [editVendor]);


  const cleanErrors = () => {
    setErrors([]);
  };

  const validate = () => {
    const errors: Array<FieldError> = product.createdFromScratch ? [
      validateRequired("name", { ...product }),
      validateRequired("manufacturer", { ...product }),
      validateRequired("brand", { ...product }),
      //validateRequired("code", { ...product }),
    ].filter((t): t is FieldError => !!t) : 
    [
      validateRequired("name", { ...product }),
      validateRequired("manufacturer", { ...product }),
      validateRequired("brand", { ...product }),
    ].filter((t): t is FieldError => !!t)
  
    setErrors([...errors]);

    return errors;
  };

  const editDetailsHandler = () => {
    setDetails((prev) => {
      return { ...prev, collapsed: false, editable: true };
    });

    setVendorCost((prev) => {
      return { ...prev, collapsed: true, editable: true };
    });

    setWithVendors(true);
    setStep(ProductStep.EditStep);
  };

  const editVendorCostHandler = () => {
    setWithVendors(true);
    setEditVendor(true);
    setDetails((prev) => {
      return { ...prev, collapsed: true, editable: true };
    });

    setVendorCost((prev) => {
      return { ...prev, collapsed: false, editable: true };
    });
    setStep(ProductStep.EditStep);
  };
  
  const handleSaveProduct = () => {
    cleanErrors();
    const validationErrors = validate();
    if(validationErrors.length === 0) {
      let productAux = {...product};
      let vendorsAux = product.vendors && product.vendors.filter((v) =>v.vendor !== undefined);
      productAux.vendors = vendorsAux;
      dispatch(saveProductThunk(apiClientWrapper, productAux));
    }
  };

  const handleBack = () => {
    if(unsavedChanges) {
      setShowUnsavedChanges(true);
    } else {
      dispatch(fetchProductsThunk(apiClientWrapper));   
      dispatch(fetchVendorsThunk());
      dispatch(fetchCategoriesThunk());  
      props.onBack();
    }
  };

  const hadleConfirmBack = () => {
    dispatch(fetchProductsThunk(apiClientWrapper));   
    dispatch(fetchVendorsThunk());
    dispatch(fetchCategoriesThunk());  
    props.onBack();
  };

  const changeProductHandler = (product: Product) => {
    setProduct(product);
    setUnsavedChanges(true);
  };

  const hadleChangeVendorCost = (productVendorCost: Product) => {
    setProduct(productVendorCost);
    setUnsavedChanges(true);
  };

  const handleDeleteProduct = () => {
    if(product.id) {
      setShowWarningDeleteProduct(true)
    } 
  }

  const handleConfirmDeleteProduct = () => {
    dispatch(deleteProductThunk(apiClientWrapper, product));
  }

  const headerTitle = <Typography className={classes.title}>{product?.id === undefined ? "Add Product" : isEmpty(product?.businessProduct?.name) ? product.name: product?.businessProduct?.name }</Typography>;

  const header = (
    <Fragment>
      <Box className={classes.backContainer} onClick={handleBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Box>
      <Box className={classes.titleButtonContainer}>
        <Box className={classes.titleContainer}>{headerTitle}</Box>
      </Box>
      <Box className={classes.saveButtonContainer}>
        {step !== ProductStep.FinishStep ? <Button 
          className={classes.finishButton} 
          label="Save" 
          onClick={handleSaveProduct} 
          loading={loading} 
          disabled={false}
        /> :
          product.businessProduct?.availableForCheckout ? 
            <Box className={classes.availableForCheckoutBox}>
              <FontAwesomeIcon icon={faCheckCircle} className={classes.iconCheck}/>
              <Typography className={classes.availableText}>Available for checkout</Typography>
            </Box>
          : undefined
        }
      </Box>
    </Fragment>
  );

  const editContent = (
    <Fragment>
      <Box className={classes.sectionContainer}>
        <Box className={classes.detailsContainer}>
          <Details
            {...details}
            product={product}
            showLookup={isNewProduct()}
            withVendors={withVendors}
            onChange={changeProductHandler}
            onAddCategory={() => props.onAddCategory(product)}
            onAddVendor={() => setWithVendors(true)}
            onLookup={() =>setLookup(true)}
            onEdit={editDetailsHandler}
            errors={errors}
          />
        </Box>
        {withVendors && <div className={classes.stockContainer} ref={vendorsEditRef}>
          <VendorsAndCost
            {...vendorCost}
            product={product}
            onChange={hadleChangeVendorCost}
            onWithoutVendor={() => setWithVendors(false)}
            onShowVendor={(vendorId) => props.onShowVendor(vendorId, product)}
            onEdit={editVendorCostHandler}
          />
        </div>}
        {((product.id && product.createdFromScratch && !lookup) || (product.id && !product.createdFromScratch)) && <Box className={classes.deleteBox}>
          <Box className={classes.deleteContainer}>
            <Box className={classes.deleteContent} onClick={handleDeleteProduct}>
              <FontAwesomeIcon icon={faTrashAlt} className={classes.iconDelete}/>
              <Typography className={classes.delete}>Delete Product</Typography>
            </Box>
          </Box>
        </Box>}
      </Box>
    </Fragment>
  );

  const finishContent = (
    <Fragment>
      <Box className={classes.sectionContainer}>
        <ResumeDetails onEdit={editDetailsHandler} product={product} />
      </Box>
      <Box className={classes.sectionContainer}>
        <ResumeVendorsAndCost onEdit={editVendorCostHandler} product={product} />
      </Box>
    </Fragment>
  );

  const stepContent = (): JSX.Element => {
    switch (step) {
      case ProductStep.EditStep: {
        return editContent;
      }
      default: {
        return finishContent;
      }
    }
  };

  return (
    <Fragment>
      <Box className={classes.container}>
        {!isSuccessStep() && <Box className={clsx(classes.headerContainer)}>{header}</Box>}
        <Box
          className={clsx(classes.bodyContainer, {
            [classes.successContainer]: isSuccessStep(),
          })}
        >
          {stepContent()}
        </Box>
        {showUnsavedChanges && 
          <ConfirmDialog 
            open={true}
            title="You have unsaved changes."
            question={<Typography className={classes.unsavedChanges}>Are you sure you want to leave this page without completing or saving changes?</Typography>}
            cancelButtonLabel="Cancel"
            confirmButtonLabel="Leave Page"
            centered
            onCancel={() => setShowUnsavedChanges(false)}
            onConfirm={hadleConfirmBack}
          />}
          {showWarningDeleteProduct && 
          <ConfirmDialog 
            open={true}
            centered
            onCancel={() => setShowWarningDeleteProduct(false)}
            onConfirm={handleConfirmDeleteProduct}
            question={
              <Typography className={classes.titleDialog}>
                Are you sure you want to delete this product?
              </Typography>
            }
            subQuestion={
              <Typography className={classes.textDialog}>
                Deleting product will permanently remove it from your database. You can always recreate it in the future.
              </Typography>
            }
            confirmButtonLabel="Delete"
            cancelButtonLabel="Nevermind"
          />}
      </Box>
    </Fragment>
  );
};

export default ProductComponent;
