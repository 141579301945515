import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ViewSelectorItem } from './ViewSelectorItem';
import { SelectionBarOption } from './model';

interface ViewSelectorProps {
    options: Array<SelectionBarOption>;
    selectedOptionId: string;
    onSelect: (id: string) => void;
    count?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            background: '#ffffff',
            border: 'none',
            height: 'fit-content',
            [theme.breakpoints.down(768)]: {
                padding: 0
            },
            [theme.breakpoints.down('md')]: {
                'gap': 9,
                'overflowX': 'scroll',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                '& > div:first-child': {
                    marginLeft: 16
                },
                '& > div:last-child': {
                    marginRight: 16
                }
            },
            [theme.breakpoints.up('lg')]: {
                borderRadius: '100px',
                paddingLeft: '6px',
                paddingRight: '6px',
                paddingTop: '4px',
                paddingBottom: '4px'
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: '100px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '5px',
                paddingBottom: '5px'
            }
        },
        button: {
            marginRight: '8px',
            marginLeft: '8px',
            [theme.breakpoints.down(1025)]: {
                margin: '0px 7px'
            },
            [theme.breakpoints.down(768)]: {
                margin: 0
            }
        },
        firstButton: {
            marginLeft: '0px'
        },
        lastButton: {
            marginRight: '0px'
        }
    })
);

export const ViewSelector: FunctionComponent<ViewSelectorProps> = props => {
    const classes = useStyles(props);

    const clickHandler = (id: string) => {
        if (props.selectedOptionId !== id) {
            props.onSelect(id);
        }
    };

    const desktopButtons = props.options.map((option, index) => {
        const buttonStyle = clsx(classes.button, {
            [classes.firstButton]: index === 0,
            [classes.lastButton]:
                props.options.length === 1 || index + 1 === props.options.length
        });

        return (
            <Box key={option.id}>
                <ViewSelectorItem
                    label={option.name}
                    selected={option.id === props.selectedOptionId}
                    selectedBackgroundColor={option.selectedBackgroundColor}
                    onClick={() => {
                        clickHandler(option.id);
                    }}
                    mark={option.mark}
                    className={buttonStyle}
                    count={props.count}
                />
            </Box>
        );
    });

    return <Box className={classes.container}>{desktopButtons}</Box>;
};

export default ViewSelector;
