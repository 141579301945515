import React, { FunctionComponent, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { SearchComponent } from "components/UI";
import clsx from "clsx";
import SearchComponentPopup from "./SearchComponentPopup";

interface Props {
  title?: string;
  searchPlaceholder?: string;
  onSearch?: (text: string) => void;
  onBack?: () => void;
  className?: string;
  rightElements?: JSX.Element;
  loading?: boolean;
  id?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 1,
      width: "100%",
      display: "flex",
      position: 'relative',
      [theme.breakpoints.down(768)]: {
        paddingTop: 10,
        paddingLeft: 16,
        paddingRight: 16
      },
      [theme.breakpoints.between(768, "sm")]: {
        paddingTop: "13px",
        paddingRight: "40px",
        paddingLeft: "27px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "21px",
        paddingRight: "65px",
        paddingLeft: "43px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "28px",
        paddingRight: "87px",
        paddingLeft: "57px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "43px",
        paddingRight: "132px",
        paddingLeft: "87px",
      },
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    leftHeaderContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingRight: "20px",
      height: "100%",
    },
    backContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
        marginLeft: "-20px",
      },
      [theme.breakpoints.only("md")]: {
        width: "50px",
        marginLeft: "-50px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "70px",
        marginLeft: "-70px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "100px",
        marginLeft: "-100px",
      },
    },
    backIcon: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "36px",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    mobileTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 25
    },
    searchClose: {
      width: 20,
      height: 20,
      marginLeft: 'auto',

      '& span': {
        width: 20,
        height: 2,
        display: 'block',
        position: 'relative',
        backgroundColor: '#000',

        '&:first-child': {
          top: 9,
          transform: 'rotate(45deg)'
        },
        '&:last-child': {
          top: 7,
          transform: 'rotate(-45deg)'
        }
      }
    },
    title: {
      fontWeight: 600,
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "36px",
      },
    },
    searchContainer: {
      width: "100%",
      [theme.breakpoints.up(768)]: {
        display: "flex",
        alignItems: "center",
      },
      [theme.breakpoints.down(768)]: {
        width: 'auto'
      }
    },
    searchContainerOpen: {
      top: 54,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      paddingTop: 15,
      paddingLeft: 20,
      paddingRight: 20,
      position: 'fixed',
      backgroundColor: '#fff'
    },
    searchBox: {
      marginLeft: "20px",
      [theme.breakpoints.down(768)]: {
        marginLeft: 0
      }
    },
  })
);

export const Header: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const [searchOpen, setSearchOpen] = useState(false)

  const searchHandler = (text: string) => {
    props.onSearch && props.onSearch(text);

    isMobile && setSearchOpen(!searchOpen)
  };

  const clickBackButtonHandler = () => {
    !props.loading && props.onBack && props.onBack();
  };

  const back = props.onBack && (
    <Box className={classes.backContainer} onClick={clickBackButtonHandler}>
      <FontAwesomeIcon
        className={classes.backIcon}
        icon={faArrowLeft}
        size="4x"
      />
    </Box>
  );

  const title = props.title && (
    <Box className={classes.titleContainer}>
      <Typography className={classes.title}>{props.title}</Typography>
    </Box>
  );

  const search = props.onSearch && (
    isMobile ?
      <SearchComponentPopup
        title={props.title}
        onSearch={searchHandler}
        placeholder={props.searchPlaceholder || "Search"}
        className={classes.searchBox} />
      :
      <SearchComponent
        id={`${props.id || ""}_header`}
        onSearch={searchHandler}
        placeholder={props.searchPlaceholder || "Search"}
        className={classes.searchBox} />
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.headerContainer}>
        <Grid container>
          <Grid item xs={props.rightElements ? 8 : 12}>
            <Box className={classes.leftHeaderContainer}>
              {back}
              {title}
              {search}
            </Box>
          </Grid>
          {props.rightElements && <Grid item xs={4}>
            {props.rightElements}
          </Grid>}
        </Grid>
      </Box>
    </Box >
  );
};

export default Header;
