import React, { FunctionComponent, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StaffChip from "../UI/StaffChip";
import TextField from "../UI/TextField";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { StaffMember } from "../../model/Service";

interface Props {
  staff: Array<StaffMember>;
  onAdd: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("lg")]: {
        width: "195px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "259px",
      },
      height: "100%",
    },
    contentContainer: {
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "column",
      [theme.breakpoints.down("lg")]: {
        marginTop: "15px",
        marginBottom: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "21px",
        marginBottom: "21px",
      },
    },
    memberContainer: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: "12px",
        marginRight: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "17px",
      },
    },
    actionContainer: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      marginTop: "10px",
    },
    searchTextField: {
      "& .MuiOutlinedInput-input": {
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down("lg")]: {
          height: "17px",
          paddingLeft: "16px",
          paddingRight: "16px",
        },
        [theme.breakpoints.up("xl")]: {
          height: "23px",
          paddingLeft: "22px",
          paddingRight: "22px",
        },
      },
      "& .MuiOutlinedInput-root": {
        [theme.breakpoints.down("lg")]: {
          height: "32px"
        },
        [theme.breakpoints.up("xl")]: {
          height: "43px"
        },
      },
      "& .MuiInputBase-input": {
        [theme.breakpoints.down("lg")]: {
          fontSize: "12pt"
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: "16pt"
        },
      },
      "& .MuiInputLabel-outlined": {
        [theme.breakpoints.down("lg")]: {
          transform: "translate(16px, 9px) scale(1)",
          fontSize: "12pt",
        },
        [theme.breakpoints.up("xl")]: {
          transform: "translate(22px, 12px) scale(1)",
          fontSize: "16pt",
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        [theme.breakpoints.down("lg")]: {
          transform: "translate(16px, -18px) scale(0.75)",
        },
        [theme.breakpoints.up("xl")]: {
          transform: "translate(22px, -23px) scale(0.75)",
        },
      }

    },
  })
);

export const StaffAdd: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const [staff, setStaff] = useState(props.staff);

  useEffect(() => {
    filterStaff(searchText);
  }, [props.staff]);

  const filterStaff = (searchText: string): void => {
    if (searchText.length > 0) {
      setStaff(props.staff.filter(s => s.firstName.includes(searchText) || s.lastName.includes(searchText)));
    } else {
      setStaff(props.staff);
    }
  }

  const searchTextChangeHandler = (text: string): void => {
    setSearchText(text);
    filterStaff(text);
  }

  const members = staff
    .sort((s1, s2) => {
      return s1.firstName.localeCompare(s2.firstName);
    })
    .map((member) => (
      <Box key={member.id} className={classes.memberContainer}>
        <Box
          onClick={() => {
            props.onAdd(member.id);
          }}
        >
          <StaffChip staff={member} small={true} fullName={true} cursorPointer={true} fullWidth={true} />
        </Box>
      </Box>
    ));

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <OverlayScrollbarsComponent style={{ maxHeight: '120px' }}>
          {members}
        </OverlayScrollbarsComponent>
      </Box>
      <Box className={classes.actionContainer}>
        <TextField label="Add a new member" value={searchText} onChange={searchTextChangeHandler} className={classes.searchTextField} />
      </Box>
    </Box>
  );
};

export default StaffAdd;
