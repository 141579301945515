import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import { showError } from '@spike/notifications-action';
import { useNonInitialEffect } from '@versiondos/hooks';
import { signUpThunk } from 'actions/signUp/SignUpActions';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { useMasterData } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import SignUp from 'model/SignUp';
import { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SignUpStatus } from 'reducers/signUp/SignUpState';
import CustomInput from 'rocket/components/Forms/CustomInput';
import { SignUpPath, SignUpVerifyPath } from 'routes/Paths';
import { RootState } from 'store';
import { debugConsoleLog } from 'utils/GeneralUtils';
import { Option } from '@spike/model';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        marginBottom: 45,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 40
        }
    },
    subtitle: {
        fontWeight: 500,
        marginTop: 10,
        marginBottom: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            marginBottom: 20
        }
    },
    column: {
        display: 'flex',
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 60,
        marginLeft: 'auto',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#F8F5F1',
        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: 100,
            paddingRight: 100
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '55%',
            flexBasis: '55%',
            paddingTop: 0,
            paddingLeft: '7%',
            paddingRight: '9%',
            paddingBottom: 80,
            backgroundColor: '#ffffff'
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '7%',
            paddingRight: '13%'
        }
    },
    checkUIItem: {
        padding: '5px !important'
    },
    checkUILabel: {
        fontSize: '14px'
    },
    formColumn: {
        marginLeft: '5%',
        marginBottom: 16
    },
    newBusinessContainer: {
        marginTop: '15px'
    },
    checksColumn: {
        padding: '4px !important',
        width: '100%',
        display: 'flex',
        marginLeft: '5%',
        marginRight: '20%'
    },
    checkContainer: {
        width: '50%',
        justifyContent: 'center'
    },
    checkLabel: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px !important'
        },
        fontSize: '14px'
    },
    primaryButton: {
        width: '100%',
        height: '55px'
    },
    menuItem: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px'
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '12px'
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.only('xl')]: {
            fontSize: '18px'
        }
    },
    alert: {
        'padding': 20,
        'width': '100%',
        'color': '#222',
        'marginTop': 20,
        'display': 'flex',
        'borderRadius': 13,
        'backgroundColor': '#F8F5F1',
        'border': 'solid 2px #BCB8AE',

        '& svg': {
            fontSize: 22,
            marginRight: 6,
            color: '#BAA997'
        }
    },
    root: {
        'padding': '5px !important',
        '&$checked': {
            color: 'black'
        }
    },
    checked: {},
    asterisk: {
        color: '#EAB464',
        fontWeight: 600
    },
    recaptchaBox: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

interface signUpBusinesss {
    locations: Option<number> | undefined;
    groomers: Option<number> | undefined;
    software: Option<string> | undefined;
    otherSoftware?: string;
    services: Array<Option<string>>;
    newBusiness: boolean;
}

const initialFormValues = {
    locations: { id: 0, name: '0' },
    groomers: { id: 0, name: '0' },
    software: { id: '', name: '' },
    otherSofrware: '',
    services: [{ id: '', name: '' }],
    newBusiness: false
};

const isCompletedNum = (value: number | undefined) => value !== undefined && value !== 0;
const isCompleted = (value: string | undefined) => value !== undefined && !isEmpty(trim(value));

export const RightColumn: FunctionComponent = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const status = useSelector<RootState, SignUpStatus>(state => state.signUp.status);
    const loading = useSelector<RootState, boolean>(state => state.signUp.loading);
    const data = useSelector<RootState, SignUp | undefined>(state => state.signUp.data);
    const [values, setValues] = useState<SignUp | signUpBusinesss>(
        data
            ? {
                  locations: { id: 0, name: '0' },
                  groomers: { id: 0, name: '0' },
                  software: { id: '', name: '' },
                  otherSoftware: '',
                  services: [],
                  newBusiness: false
              }
            : initialFormValues
    );
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    const masterData = useMasterData();

    const [services, setServices] = useState(
        masterData.services.map(service => {
            return { ...service, checked: false };
        })
    );

    debugConsoleLog(masterData);

    useEffect(() => {
        if (!data) {
            history.push(SignUpPath);
        }
    }, [data, history]);

    useNonInitialEffect(() => {
        if (status === SignUpStatus.Success) {
            history.push(SignUpVerifyPath);
        } else if (status === SignUpStatus.Failed) {
            dispatch(showError('Sign Up error.'));
        }
    }, [status]);

    useNonInitialEffect(() => {
        const servicesAux = services
            .filter(service => service.checked === true)
            .map(service => {
                return { id: service.id, name: service.name };
            });
        setValues({ ...values, services: [...servicesAux] });
    }, [services]);

    useNonInitialEffect(() => {
        debugConsoleLog(values);
    }, [values]);

    const [captchaValue, setCaptchaValue] = useState<string | null>(null);

    const isSingUpEnabled =
        isCompletedNum(values.locations!.id) &&
        isCompletedNum(values.groomers!.id) &&
        isCompleted(values.software!.id) &&
        isCompleted(values.software!.name) &&
        values.services.length > 0 &&
        captchaValue !== null;

    const handleFormSubmit = async (event: MouseEvent) => {
        event.preventDefault();

        const signUp: SignUp = {
            ...data!,
            locations: values.locations,
            groomers: values.groomers,
            software: values.software,
            otherSoftware: values.otherSoftware,
            services: values.services,
            newBusiness: values.newBusiness
        };

        dispatch(signUpThunk(signUp));
    };

    const handleCheck = (name: string) => {
        const serviceAux = services.findIndex(service => service.id === name);

        const servicesAux = [...services];
        servicesAux[serviceAux] = {
            ...servicesAux[serviceAux],
            checked: !services[serviceAux].checked
        };
        setServices([...servicesAux]);
    };

    const changeNewBusinessHandler = (checked: boolean) => {
        setValues({ ...values, newBusiness: checked });
    };

    const changeLocationHandler = (value: number) => {
        const name = value === 10 ? '+10' : String(value);
        setValues({ ...values, locations: { id: value, name: name } });
    };

    const changeGroomerHandler = (value: number) => {
        const name = value === 10 ? '+10' : String(value);
        setValues({ ...values, groomers: { id: value, name: name } });
    };

    const changeSoftwareHandler = (value: string) => {
        const software = masterData.softwares.find(software => software.id === value);
        const name = software ? software.name : value.charAt(0).toUpperCase() + value.slice(1);
        setValues({ ...values, software: { id: value, name: name } });
    };

    const changeSoftwareNameHandler = (value: string) => {
        setValues({ ...values, otherSoftware: value });
    };

    const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
    };

    const handleCaptchaExpired = () => {
        setCaptchaValue(null);
    };

    console.log('RECAPTCHA Site Key:', process.env.REACT_APP_RECAPTCHA_SITE_KEY);

    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    if (!siteKey) {
        throw new Error('REACT_APP_RECAPTCHA_SITE_KEY is not defined');
    }

    return (
        <Grid item xs={12} className={classes.column}>
            <h2 className={classes.title}>Tell us about your business</h2>

            <Grid container spacing={2}>
                <Grid className={classes.formColumn} item xs={12}>
                    <CustomInput
                        id="signup_input_location_count"
                        select
                        required
                        name="locationCount"
                        label="Number of locations"
                        error={errors.some(error => error.fieldName === 'locationCount')}
                        defaultValue={data?.locations}
                        onChange={value => changeLocationHandler(Number(value.target.value))}
                    >
                        {masterData.locations.map(location => (
                            <MenuItem
                                id={'signup_list_item_location_count_' + location.id}
                                value={location.id}
                                key={location.id}
                                className={classes.menuItem}
                            >
                                {location.name}
                            </MenuItem>
                        ))}
                    </CustomInput>
                </Grid>

                <Grid className={classes.formColumn} item xs={12}>
                    <CustomInput
                        id="signup_input_groomer_count"
                        select
                        required
                        name="groomerCount"
                        label="Number of staffs"
                        error={errors.some(error => error.fieldName === 'groomerCount')}
                        defaultValue={data?.groomers}
                        onChange={value => changeGroomerHandler(Number(value.target.value))}
                    >
                        {masterData.groomers.map(groomer => (
                            <MenuItem
                                id={'signup_list_item_groomer_count_' + groomer.id}
                                value={groomer.id}
                                key={groomer.id}
                                className={classes.menuItem}
                            >
                                {groomer.name}
                            </MenuItem>
                        ))}
                    </CustomInput>
                </Grid>
                <Grid className={classes.formColumn} item xs={12}>
                    <CustomInput
                        id="signup_input_software"
                        select
                        required
                        name="software"
                        label="What software are you currently using?"
                        error={errors.some(error => error.fieldName === 'software')}
                        defaultValue={data?.software}
                        onChange={value => changeSoftwareHandler(value.target.value)}
                    >
                        {masterData.softwares.map(software => (
                            <MenuItem
                                id={'signup_list_item_software_' + software.id}
                                value={software.id}
                                key={software.id}
                                className={classes.menuItem}
                            >
                                {software.name}
                            </MenuItem>
                        ))}
                    </CustomInput>
                </Grid>
                {values.software && values.software.id === 'other' && (
                    <Grid className={classes.formColumn} item xs={12}>
                        <CustomInput
                            id="signup_input_software_name"
                            label="Software Name"
                            placeholder="Software Name"
                            required
                            name="softwareName"
                            onBlur={value => changeSoftwareNameHandler(value.target.value)}
                            onChange={value => changeSoftwareNameHandler(value.target.value)}
                            error={errors.some(error => error.fieldName === 'softwareName')}
                            defaultValue={values.otherSoftware}
                        />
                    </Grid>
                )}

                <Grid item xs={12} className={classes.checksColumn}>
                    <Typography className={classes.subtitle}>
                        What services do you offer? <span className={classes.asterisk}> *</span>
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.checksColumn}>
                    <Box className={classes.checkContainer}>
                        <Checkbox
                            id="signup_checkbox_services_grooming"
                            checked={
                                services.find(service => service.id === 'grooming')
                                    ? services.find(service => service.id === 'grooming')!.checked
                                    : false
                            }
                            onChange={() => handleCheck('grooming')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked
                            }}
                        />{' '}
                        <span className={classes.checkLabel}>Grooming</span>
                    </Box>
                    <Box className={classes.checkContainer}>
                        <Checkbox
                            id="signup_checkbox_services_daycare"
                            checked={
                                services.find(service => service.id === 'daycare')
                                    ? services.find(service => service.id === 'daycare')!.checked
                                    : false
                            }
                            onChange={() => handleCheck('daycare')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked
                            }}
                        />
                        <span className={classes.checkLabel}>Daycare</span>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.checksColumn}>
                    <Box className={classes.checkContainer}>
                        <Checkbox
                            id="signup_checkbox_services_mobile"
                            checked={
                                services.find(service => service.id === 'mobile_grooming')
                                    ? services.find(service => service.id === 'mobile_grooming')!.checked
                                    : false
                            }
                            onChange={() => handleCheck('mobile_grooming')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked
                            }}
                        />
                        <span className={classes.checkLabel}>Mobile Grooming</span>
                    </Box>
                    <Box className={classes.checkContainer}>
                        <Checkbox
                            id="signup_checkbox_services_retail"
                            checked={
                                services.find(service => service.id === 'retail')
                                    ? services.find(service => service.id === 'retail')!.checked
                                    : false
                            }
                            onChange={() => handleCheck('retail')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked
                            }}
                        />
                        <span className={classes.checkLabel}>Retail</span>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.checksColumn}>
                    <Box className={classes.checkContainer}>
                        <Checkbox
                            id="signup_checkbox_services_boarding"
                            checked={
                                services.find(service => service.id === 'boarding')
                                    ? services.find(service => service.id === 'boarding')!.checked
                                    : false
                            }
                            onChange={() => handleCheck('boarding')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked
                            }}
                        />
                        <span className={classes.checkLabel}>Boarding</span>
                    </Box>
                    <Box className={classes.checkContainer}>
                        <Checkbox
                            id="signup_checkbox_services_training"
                            checked={
                                services.find(service => service.id === 'training')
                                    ? services.find(service => service.id === 'training')!.checked
                                    : false
                            }
                            onChange={() => handleCheck('training')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked
                            }}
                        />
                        <span className={classes.checkLabel}>Training</span>
                    </Box>
                </Grid>

                <Grid className={clsx(classes.newBusinessContainer, classes.formColumn)} item xs={12}>
                    <Typography className={classes.subtitle}>
                        {' '}
                        New or Existing Business? <span className={classes.asterisk}> *</span>
                    </Typography>
                    <RadioGroup value={values.newBusiness}>
                        <FormControlLabel
                            className={classes.checkUILabel}
                            value="true"
                            control={
                                <Radio className={classes.checkUIItem} id="signup_new_business_new" color="default" />
                            }
                            label="New"
                            checked={values.newBusiness}
                            onChange={() => changeNewBusinessHandler(true)}
                        />
                        <FormControlLabel
                            className={classes.checkUILabel}
                            value="false"
                            control={
                                <Radio
                                    className={classes.checkUIItem}
                                    id="signup_new_business_existing"
                                    color="default"
                                />
                            }
                            checked={!values.newBusiness}
                            label="Existing"
                            onChange={() => changeNewBusinessHandler(false)}
                        />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12} className={classes.recaptchaBox}>
                    <ReCAPTCHA sitekey={siteKey} onChange={handleCaptchaChange} onExpired={handleCaptchaExpired} />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        id="signup_button_signup"
                        onClick={handleFormSubmit}
                        loading={loading}
                        label="Sign Me Up!"
                        size="large"
                        disabled={!isSingUpEnabled}
                        className={classes.primaryButton}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RightColumn;
