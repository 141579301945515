import React, { FunctionComponent } from 'react';
import { Option, OrderedOption, PetType } from '@spike/model';
import { Box, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import { fixedPriceOption, byPetSizePriceOption, byPetSizeAndHairLengthPriceOption, PricingPetTypeIds } from '../model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import clsx from 'clsx';

interface MinPriceAlertProps {
    pricingOption: Option<string>;
    hairLengths?: Array<OrderedOption<string>>;
    petTypes?: Array<PetType>;
    petType?: PetType;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 10,
            width: '100%',
            display: 'flex',
            borderRadius: 10,
            flexDirection: 'row',
            backgroundColor: '#F8F5F1',
            padding: '19px 20px 19px 16px',
            border: '2px solid #BCB8AE'
        },
        icon: {
            fontSize: '1.3rem',
            marginInline: '3px',
            marginTop: '2px'
        },
        text: {
            fontSize: 14,
            fontWeight: 400
        }
    })
);

const MinPriceAlert: FunctionComponent<MinPriceAlertProps> = props => {
    const classes = useStyles();

    const fixedMessage = (
        <Typography className={classes.text}>
            {props.petType?.id === PricingPetTypeIds.EXOTICS ? (
                <>
                    The price for <strong>Exotics</strong> is less than the deposit amount.
                </>
            ) : (
                <>
                    The price for <strong>Dogs or Cats</strong> is less than the deposit amount.
                </>
            )}
        </Typography>
    );

    const byPetSizeMessage = (
        <Typography className={classes.text}>
            {'The price entered is lower than the price you have set for your sdeposits.'}
        </Typography>
    );

    const byPetSizeAndHairLengthMessage = (
        <Typography className={classes.text}>
            The price entered for{' '}
            <strong>
                {props.hairLengths
                    ?.map((hairLength, index) => {
                        if (index === props.hairLengths!.length - 1) {
                            return hairLength.element.name;
                        }
                        if (index === props.hairLengths!.length - 2) {
                            return `${hairLength.element.name} & `;
                        }
                        return `${hairLength.element.name}, `;
                    })
                    .join('')}
            </strong>{' '}
            is lower than the price you have set for your deposits.
        </Typography>
    );
    return (
        <Box className={clsx(classes.container, props.className)}>
            <FontAwesomeIcon icon={faInfoCircle} color="#BCB8AE" className={classes.icon} />
            {isEqual(props.pricingOption.id, fixedPriceOption.id) && fixedMessage}
            {isEqual(props.pricingOption.id, byPetSizePriceOption.id) && byPetSizeMessage}
            {isEqual(props.pricingOption.id, byPetSizeAndHairLengthPriceOption.id) && byPetSizeAndHairLengthMessage}
        </Box>
    );
};

export default MinPriceAlert;
