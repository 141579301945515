import React, {
    FunctionComponent,
    ChangeEvent,
    useRef,
    useState,
    Fragment,
    useEffect,
    MouseEvent
} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import _ from 'lodash';
import clsx from 'clsx';
import { StaffEmergencyContact, StaffPerson } from 'model/Staff';
import {
    TextField2,
    PhoneField3,
    Button,
    Date as DateComponent
} from 'components/UI';
import { FieldError, Country } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import { StaffStatus } from 'reducers/staff/StaffState';
import { isEmailValid, validatePhoneNumber } from '@spike/validations';
import { Moment } from 'moment';
import { useMarketplace, useMasterData } from 'hooks';
import { CropperImage } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/pro-duotone-svg-icons';
import { faCameraAlt } from '@fortawesome/pro-regular-svg-icons';

export interface Props {
    person: StaffPerson;
    emergencyContact: StaffEmergencyContact;
    onSave: (
        person: StaffPerson,
        emergencyContact: StaffEmergencyContact
    ) => void;
    className?: string;
    openSidebar?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '0px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '-50px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '-66px'
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: '-100px'
            }
        },
        imageContainer: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',

            [theme.breakpoints.up('md')]: {
                marginBottom: 24
            },

            [theme.breakpoints.down('sm')]: {
                width: '61px'
            },
            [theme.breakpoints.only('md')]: {
                width: '99px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '132px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '200px'
            },
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        imageContainerMobile: {
            display: 'inline-flex',
            position: 'relative',
            width: 'auto'
        },
        content: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                width: 500
            },
            [theme.breakpoints.only('xl')]: {
                width: 700
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '20px'
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '32px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '42px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '64px'
            }
        },
        title: {
            fontWeight: 600,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'center'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '16px',
                lineHeight: '21px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '21px',
                lineHeight: '28px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '32px',
                lineHeight: '42px'
            }
        },
        titleField: {
            width: '100%',
            paddingLeft: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '25px',
                marginTop: '-3px',
                marginBottom: '2px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '14px',
                lineHeight: '21px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '14px',
                lineHeight: '28px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px',
                lineHeight: '42px'
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '27px',
                marginBottom: '27px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '44px',
                marginBottom: '44px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: 32,
                marginBottom: 32
            }
        },
        buttonContainerFull: {
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '18px'
                    }
                },
                '@supports (-webkit-touch-callout: none)': {
                    paddingBottom: '70px'
                }
            }
        },
        rowContainer: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                marginBottom: '15px',
                flexDirection: 'column'
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: 16
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: 24
            }
        },
        name: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginRight: '0px',
                marginBottom: '15px',
                marginTop: '15px'
            },
            [theme.breakpoints.only('md')]: {
                width: '134px',
                marginRight: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '178px',
                marginRight: '13px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '270px',
                marginRight: '20px'
            }
        },
        surname: {
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
            [theme.breakpoints.only('md')]: {
                width: '134px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '178px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '270px'
            }
        },
        emergencyContactTitleContainer: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '12px',
                marginTop: '30px'
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '20px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '26px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '40px'
            }
        },
        emergencyContactTitle: {
            fontWeight: 300,
            color: '222222',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '100%',
                textTransform: 'none',
                color: '#000',
                fontWeight: 600
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '16px',
                lineHeight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '8px',
                lineHeight: '20px',
                textTransform: 'Uppercase'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px',
                lineHeight: '30px',
                textTransform: 'Uppercase'
            }
        },
        inputField: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    minHeight: '25px',
                    padding: '14px 28px 14px 28px'
                }
            }
        },
        avatar: {
            borderRadius: '50%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',

            [theme.breakpoints.down('lg')]: {
                width: '90px',
                height: '90px',
                marginBottom: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '126px',
                height: '126px'
            }
        },
        editPhoto: {
            '&:hover': {
                cursor: 'pointer'
            },

            'fontWeight': 500,

            [theme.breakpoints.down('sm')]: {
                fontSize: '4px',
                lineHeight: '8px',
                marginLeft: '8px',
                opacity: 0
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '7px',
                lineHeight: '13px',
                marginLeft: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '9px',
                lineHeight: '18px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px',
                lineHeight: '27px'
            }
        },
        maxSize: {
            color: '#969696',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '14px',
                marginLeft: '10px',
                marginRight: '6px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '14px',
                lineHeight: '13px',
                marginLeft: '15px',
                marginRight: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '12px',
                lineHeight: '27px',
                marginLeft: '30px',
                marginRight: '20px'
            }
        },
        warningSize: {
            color: 'red',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '6px',
                lineHeight: '13px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '8px',
                lineHeight: '18px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px',
                lineHeight: '27px'
            }
        },
        sizeContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '-2px'
        },
        addressContainer: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                marginBottom: '35px'
            },
            [theme.breakpoints.only('md')]: {
                height: 'auto',
                marginBottom: '46px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '35px',
                marginBottom: '61px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '53px',
                marginBottom: '92px'
            }
        },
        iconTitle: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline-flex',
                marginRight: '10px',
                fontSize: '18px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        cameraBadge: {
            backgroundColor: '#fff',
            borderRadius: '50%',
            height: '38px',
            width: '38px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '62px',
            top: '60px'
        },
        iconCamera: {
            color: '#000',
            fontSize: '14px'
        },
        fakePlaceholder: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                position: 'absolute',
                color: '#7a7a7a',
                top: '15px',
                left: '36px',
                pointerEvents: 'none'
            }
        },
        rowContainerBirthday: {
            position: 'relative'
        }
    })
);

export const PersonalInfo: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const loading = useSelector<RootState, boolean>(
        state => state.marketplace.loading
    );
    const status = useSelector<RootState, StaffStatus>(
        state => state.staff.status
    );
    const [saved, setSaved] = useState(false);

    const [warningImage, setWarningImage] = useState(false);
    const [person, setPerson] = useState({ ...props.person });
    const [hideFakePlaceholder, setHideFakePlaceholder] = useState(false);
    const [emergencyContact, setEmergencyContact] = useState({
        ...props.emergencyContact
    });
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const imageInput = useRef<HTMLInputElement>(null);

    const [selectedImage, setSelectedImage] = useState<string | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();
    const [showCropper, setShowCropper] = useState(false);

    useNonInitialEffect(() => {
        setSaved(
            _.isEqual(props.person, person) &&
                _.isEqual(props.emergencyContact, emergencyContact)
        );
    }, [person, emergencyContact]);

    useNonInitialEffect(() => {
        if (status === StaffStatus.SaveSuccess) {
            setSaved(true);
        }
    }, [status]);

    const cleanErrors = (fieldName: string) => {
        setErrors(prev => prev.filter(e => e.fieldName !== fieldName));
    };

    const validate = () => {
        const errors: Array<FieldError> = [];

        if (_.isEmpty(person.firstName)) {
            errors.push({
                fieldName: 'firstName',
                errorMessage: 'First Name is required'
            });
        }
        if (_.isEmpty(person.email)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Email is required'
            });
        }

        !_.isEmpty(person.phoneNumber) &&
            errors.push(
                ...validatePhoneNumber(
                    person.phoneNumber,
                    marketplace.basics.address.country!.id,
                    'phoneNumber'
                )
            );

        if (!isEmailValid(person.email)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Email incorrect format.'
            });
        }
        setErrors([...errors]);

        return errors;
    };

    const changeValuePersonHandler = (value: string, fieldName: string) => {
        cleanErrors(fieldName);

        const attributes = fieldName.split('.');

        setPerson((prev: any) => {
            if (attributes.length === 1) {
                prev[attributes[0]] = value;
            } else if (attributes.length === 2) {
                prev[attributes[0]][attributes[1]] = value;
            }
            return { ...prev };
        });
    };

    const changeBirthdatePerson = (birthdate: Moment | undefined) => {
        setPerson(prev => ({ ...prev, birthdate }));
    };

    const changeFirstNameEmergencyContact = (value: string) => {
        cleanErrors('name');
        const emergencyContactAux = { ...emergencyContact };
        emergencyContactAux.firstName = value;
        setEmergencyContact(emergencyContactAux);
    };

    const changeLastNameEmergencyContact = (value: string) => {
        cleanErrors('last');
        const emergencyContactAux = { ...emergencyContact };
        emergencyContactAux.lastName = value;
        setEmergencyContact(emergencyContactAux);
    };

    const changePhoneNumberEmergencyContact = (value: string) => {
        cleanErrors('phone');
        const emergencyContactAux = { ...emergencyContact };
        emergencyContactAux.phoneNumber = value;
        setEmergencyContact(emergencyContactAux);
    };

    const clickButtonHandler = () => {
        const validationErrors = validate();
        if (validationErrors.length === 0) {
            props.onSave({ ...person }, { ...emergencyContact });
        }
    };

    const handleImageClick = (event: MouseEvent) => {
        imageInput.current?.click();
    };

    const handleMonthSelected = (month: number) => {
        setHideFakePlaceholder(true);
    };

    const selectImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];
        if (file.size / 1024 <= masterData.imagesMaxSizes.avatarKb) {
            setWarningImage(false);
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setSelectedImage(reader.result?.toString());
                    setShowCropper(true);
                    setFileName(file.name);
                }
            };
            reader.readAsDataURL(file);
        } else {
            setWarningImage(true);
        }
    };

    const cropHandler = (file: File) => {
        setWarningImage(false);
        setPerson(prev => ({ ...prev, fileToUpload: file }));
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setPerson(prev => ({
                    ...prev,
                    avatar: reader.result?.toString()!
                }));
                setShowCropper(false);
            }
        };
        reader.readAsDataURL(file);
    };

    const button = (
        <Button
            label={saved ? 'Saved!' : 'Save'}
            onClick={clickButtonHandler}
            loading={loading}
        />
    );

    return (
        <Fragment>
            <Box className={clsx(classes.container, props.className)}>
                <Box className={classes.content}>
                    <Box className={classes.titleContainer}>
                        <Typography className={classes.title}>
                            <FontAwesomeIcon
                                className={classes.iconTitle}
                                onClick={props.openSidebar}
                                icon={faArrowLeft}
                                size="2x"
                            />
                            Personal Info
                        </Typography>
                    </Box>
                    <Box
                        onClick={handleImageClick}
                        className={clsx(
                            classes.imageContainer,
                            classes.imageContainerMobile
                        )}
                    >
                        <input
                            style={{ display: 'none' }}
                            type={'file'}
                            ref={imageInput}
                            onChange={selectImageHandler}
                            accept=".png, .jpg, .jpeg"
                            value={''}
                        />
                        <Box
                            className={classes.avatar}
                            style={{
                                backgroundImage: 'url(' + person.avatar + ')'
                            }}
                        />
                        <Box className={classes.sizeContainer}>
                            <Typography
                                className={classes.maxSize}
                            >{`Max size: ${masterData.imagesMaxSizes.avatarKb}KB`}</Typography>
                            {warningImage && (
                                <Typography className={classes.warningSize}>
                                    Maximum size exceeded
                                </Typography>
                            )}
                        </Box>
                        <Box className={classes.cameraBadge}>
                            <FontAwesomeIcon
                                className={classes.iconCamera}
                                icon={faCameraAlt}
                                size="1x"
                            />
                        </Box>
                        <Typography
                            className={classes.editPhoto}
                            onClick={handleImageClick}
                        >
                            Edit Photo
                        </Typography>
                    </Box>
                    <Box className={classes.rowContainer}>
                        <TextField2
                            label="First Name"
                            name="firstName"
                            placeholder="First Name"
                            value={person.firstName}
                            onChange={changeValuePersonHandler}
                            errors={errors}
                            className={clsx(classes.name, classes.inputField)}
                            capitalize={true}
                            required={true}
                            maxLength={30}
                        />
                        <TextField2
                            label="Last Name"
                            placeholder="Last Name"
                            name="lastName"
                            value={person.lastName}
                            onChange={changeValuePersonHandler}
                            className={clsx(
                                classes.surname,
                                classes.inputField
                            )}
                            errors={errors}
                            capitalize={true}
                            maxLength={30}
                        />
                    </Box>
                    <Box className={classes.rowContainer}>
                        <TextField2
                            label="Work Email"
                            placeholder="Work Email"
                            name="email"
                            value={person.email}
                            onChange={changeValuePersonHandler}
                            errors={errors}
                            required={true}
                            className={classes.inputField}
                        />
                    </Box>
                    <Box className={clsx(classes.rowContainer)}>
                        <PhoneField3
                            label="Phone Number"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={person.phoneNumber}
                            onChange={changeValuePersonHandler}
                            errors={errors}
                            className={classes.inputField}
                        />
                    </Box>
                    <Typography className={classes.titleField}>
                        Birthday
                    </Typography>
                    <Box
                        className={clsx(
                            classes.rowContainer,
                            classes.rowContainerBirthday
                        )}
                    >
                        <DateComponent
                            date={person.birthdate}
                            minYear={1921}
                            maxYear={2021}
                            errors={errors}
                            onChange={changeBirthdatePerson}
                            handleMonthSelected={handleMonthSelected}
                        />

                        {hideFakePlaceholder == false && (
                            <Typography className={classes.fakePlaceholder}>
                                Month
                            </Typography>
                        )}
                    </Box>
                    <Box className={classes.addressContainer}>
                        <TextField2
                            label="Address"
                            placeholder="Address"
                            name="address"
                            value={person.address}
                            onChange={changeValuePersonHandler}
                            errors={errors}
                            className={classes.inputField}
                        />
                    </Box>
                    <Box className={classes.separator}></Box>
                    <Box className={classes.emergencyContactTitleContainer}>
                        <Typography className={classes.emergencyContactTitle}>
                            Emergency Contact
                        </Typography>
                    </Box>
                    <Box className={classes.rowContainer}>
                        <TextField2
                            label="First Name"
                            placeholder="First Name"
                            name="name"
                            value={emergencyContact.firstName}
                            onChange={changeFirstNameEmergencyContact}
                            errors={errors}
                            className={clsx(classes.name, classes.inputField)}
                            capitalize={true}
                        />
                        <TextField2
                            label="Last Name"
                            placeholder="Last Name"
                            name="last"
                            value={emergencyContact.lastName}
                            onChange={changeLastNameEmergencyContact}
                            className={clsx(
                                classes.surname,
                                classes.inputField
                            )}
                            errors={errors}
                            capitalize={true}
                        />
                    </Box>
                    <Box className={classes.rowContainer}>
                        <PhoneField3
                            label="Phone Number"
                            placeholder="Phone Number"
                            name="phone"
                            value={emergencyContact.phoneNumber}
                            onChange={changePhoneNumberEmergencyContact}
                            errors={errors}
                            className={classes.inputField}
                        />
                    </Box>
                    <Box
                        className={clsx(
                            classes.buttonContainerFull,
                            classes.buttonContainer
                        )}
                    >
                        {button}
                    </Box>
                </Box>
            </Box>
            {showCropper && (
                <CropperImage
                    image={selectedImage!}
                    fileName={fileName!}
                    cropWidth={256}
                    cropHeight={256}
                    onClose={() => setShowCropper(false)}
                    onCrop={cropHandler}
                />
            )}
        </Fragment>
    );
};

export default PersonalInfo;
