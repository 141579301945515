import React, {
	FunctionComponent,
	ChangeEvent,
	useState,
	useEffect
} from 'react';
import { Box, InputAdornment, OutlinedInput } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

type IconColors = 'black';
interface Props {
	open?: boolean;
	onBlur?: () => void;
	onFocus?: () => void;
	onSearch: (text: string) => void;
	searchText?: string;
	placeholder: string;
	iconColor?: IconColors;
	className?: string;
	id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'center',
			width: '100%',
			[theme.breakpoints.down('xs')]: {
				height: '38px'
			},
			[theme.breakpoints.only('sm')]: {
				height: '39px'
			},
			[theme.breakpoints.only('md')]: {
				height: '40px'
			},
			[theme.breakpoints.only('lg')]: {
				height: '45px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '62px'
			}
		},
		iconContainer: {
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'center',
			paddingLeft: '14px'
		},
		searchField: {
			'height': '100%',
			'borderRadius': '100px',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: '1px !important',
				borderColor: '#222222 !important'
			},
			"&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline":
				{
					borderWidth: '1px !imporant',
					borderColor: '#222222 !important'
				},
			'&.Mui-error .MuiOutlinedInput-notchedOutline': {
				borderColor: '#EA6464 !important'
			}
		},
		pointer: {
			cursor: 'pointer'
		},
		fonts: {
			[theme.breakpoints.down(768)]: {
				fontSize: '20px'
			},
			[theme.breakpoints.between(768, 'sm')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '15px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '20px'
			}
		}
	})
);

export const SearchComponent: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const [showSearchBox, setShowSearchBox] = useState(props.open || false);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		const timer: NodeJS.Timer = setTimeout(() => {
			props.searchText !== searchText && props.onSearch(searchText);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchText]);

	useEffect(() => {
		if (props.searchText && props.searchText !== searchText) {
			setSearchText(props.searchText || '');
		}
	}, [props.searchText]);

	const iconClickHandler = (): void => {
		setSearchText('');
		setShowSearchBox(current => {
			return !current;
		});
	};

	const changeHandler = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		setSearchText(event.currentTarget.value);
	};

	const searchIcon = (
		<Box
			className={classes.iconContainer}
			onClick={iconClickHandler}
			id={`${props.id || ''}_search_button`}
		>
			<FontAwesomeIcon
				className={clsx(classes.pointer, classes.fonts)}
				icon={faSearch}
			/>
		</Box>
	);

	const iconColors = new Map<string, string>([['black', '#000']]);

	const searchBox = (
		<OutlinedInput
			id={`${props.id || ''}_search_input`}
			placeholder={props.placeholder}
			value={searchText}
			className={clsx(
				classes.searchField,
				classes.pointer,
				classes.fonts
			)}
			onFocus={() => props.onFocus && props.onFocus()}
			onBlur={() => props.onBlur && props.onBlur()}
			onChange={changeHandler}
			startAdornment={
				<InputAdornment position="start">
					<FontAwesomeIcon
						color={
							props.iconColor
								? iconColors.get(props.iconColor)
								: undefined
						}
						id={`${props.id || ''}_search_icon`}
						icon={faSearch}
						className={classes.fonts}
					/>
				</InputAdornment>
			}
		/>
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			{showSearchBox ? searchBox : searchIcon}
		</Box>
	);
};

export default SearchComponent;
