import React from "react";
import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRepeat } from "@fortawesome/pro-regular-svg-icons";
import { AppointmentCountRecurrent } from "@spike/appointment-model";

interface Props {
	recurrent?: AppointmentCountRecurrent;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			fontSize: 14,
			lineHeight: "14px",
			"& > svg": {
				marginRight: 5,
				fontSize: 16,
				lineHeight: "24px"
			},
		},
	})
);

const BookingRecurrencyInfo: React.FunctionComponent<Props> = ({ recurrent }) => {
	const classes = useStyles();

	return (
		<>
			{recurrent?.frecuency && (
				<Box className={classes.root}>
					<FontAwesomeIcon icon={faArrowsRepeat} />
					Repeats {recurrent?.frecuency.name.toLowerCase()} until{" "}
					{recurrent.lastDate.format("ll")}
				</Box>
			)}
		</>
	);
};

export default BookingRecurrencyInfo;
