export interface ReportItem {
  key: string;
  label: string;
  value: string | number;
  type: ReportItemType;
}

export enum ReportItemType {
  STRONG = "strong",
  NORMAL = "normal",
  SUBITEM = "subitem",
  TOTAL = "total"
}