import React, { FunctionComponent, ChangeEvent, KeyboardEvent, useState, useEffect } from "react";
import { TextField as MUITextField, InputAdornment } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "@spike/model";
import { commonStyles } from "Theme";
import capitalize from "lodash/capitalize";

export interface TextFieldProps {
  id?: string;
  name?: string;
  label: string;
  value: string;
  placeholder?: string;
  error?: boolean; //Deprecated, use errors
  errorMessage?: string; //Deprecated. use errors
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: string, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  endIcon?: JSX.Element;
  multiline?: boolean;
  onEnterPressed?: () => void;
  disabled?: boolean;
  capitalize?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      width: "100%",

      "& div.Mui-disabled": {
        backgroundColor: "#f5f5f5",
      },
    },
  })
);

export const TextField: FunctionComponent<TextFieldProps> = (props) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const [label, setLabel] = useState(props.label);
  const [error, setError] = useState(props.error);
  const [text, setText] = useState(props.value);
  const [name, setName] = useState<any>();

  useEffect(() => {
    if (props.errors) {
      const hasError = props.errors.some((e) => e.fieldName === props.name);
      setError(hasError);
      if (hasError) {
        setLabel(props.errors.find((e) => e.fieldName === props.name)!.errorMessage);
      } else {
        setLabel(props.label);
      }
    } else {
      //Deprecated
      setError(props.error);
      if (props.errorMessage && props.errorMessage.length > 0) {
        setLabel(props.errorMessage);
      } else {
        setLabel(props.label);
      }
    }
  }, [props.error, props.errorMessage, props.label, props.errors, props.name]);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(text, name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setError(false);
    setLabel(props.label);
    setText(props.capitalize ? capitalize(event.target.value) : event.target.value);
    setName(event.target.name);
  };

  const handleOnFocus = (event: any): void => {
    props.onFocus && props.onFocus();
  };

  const handleOnBlur = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };

  const inputProps =
    props.endIcon === undefined
      ? undefined
      : {
        endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment>,
      };

  return (
    <MUITextField
      id={props.id?? 'textfield_search'}
      name={props.name}
      placeholder={props.placeholder}
      variant="outlined"
      label={label}
      defaultValue={props.value}
      onBlur={handleOnBlur}
      onChange={handleChange}
      onFocus={handleOnFocus}
      autoFocus={props.autoFocus}
      autoComplete={"nope"}
      className={clsx(commonClasses.field, props.className)}
      error={error}
      InputProps={inputProps}
      multiline={props.multiline}
      onKeyPress={keyPressHandler}
      disabled={props.disabled}
      inputProps={ props.capitalize ? { style: { textTransform: "capitalize" } } : undefined }
    />
  );
};

export default TextField;
