import Service from "../../model/Service";

export interface ServicesState {
  services: Array<Service>;
  savedService?: Service;
  status: ServicesStatus;
  loading: boolean;
  duplicatedServiceId?: number;
}

export enum ServicesStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Saving,
  SaveSuccess,
  Deleting,
  DeleteSuccess,
  Activating,
  ActivateSuccess,
  PetTypesUpdating,
  PetTypesUpdateSuccess,
  BusinessAreaUpdating,
  BusinessAreaUpdateSuccess,
  StaffUpdating,
  StaffUpdateSuccess,
  Inactivating,
  InactivateSuccess,
  Error
} 
