import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Pet from '@spike/pet-model';
import {
    faCat,
    faDog,
    faRuler,
    faMars,
    faNarwhal,
    faHeat
} from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import ItemLabelIcon from 'components/UI/ItemLabelIcon';

interface PetHeaderProps {
    pet?: Pet;
    className?: string;
}

export declare enum PetTypeId {
    DOG = 'dog',
    CAT = 'cat',
    EXOTIC = 'exotic'
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        },
        petHeader: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.down(380)]: {
                width: '75%'
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        },
        petPopUp: {
            cursor: 'default',
            marginRight: 18,
            [theme.breakpoints.up('md')]: {
                marginRight: 20
            }
        },
        typoPetName: {
            fontWeight: 600,
            fontSize: 16,
            marginRight: 9,
            width: 'auto',
            [theme.breakpoints.up('md')]: {
                marginRight: 10
            }
        },
        boxFlex: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'column',
                width: '26%'
            }
        },
        boxFlexRow: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up('md')]: {
                width: '74%'
            }
        },
        leftContainer: {
            marginRight: 18,
            [theme.breakpoints.down('sm')]: {
                width: '45%'
            },
            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        rightContainer: {
            [theme.breakpoints.down('sm')]: {
                width: '45%'
            },
            [theme.breakpoints.up('md')]: {
                width: '50%'
            }
        },
        itemLabel: {
            fontSize: '14px !important',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '90%',
            overflow: 'hidden',
            [theme.breakpoints.down(380)]: {
                width: 150
            }
        },
        petType: {
            '& .MuiTypography-body1': {
                fontSize: '14px !important'
            }
        },
        petIcon: {
            width: 50,
            height: 24,
            borderRadius: 18,
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                width: 52,
                height: 26,
                borderRadius: 20
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        petIconDogs: {
            color: '#5e8677',
            backgroundColor: '#e9f0ed'
        },
        petIconCats: {
            color: '#c18733',
            backgroundColor: '#faefdf'
        },
        petIconOthers: {
            color: '#9c8267',
            backgroundColor: '#ebe7e3'
        },
        chip: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            lineHeight: '14px',
            height: '100%'
        }
    })
);

export const PetDescription: FunctionComponent<PetHeaderProps> = props => {
    const classes = useStyles(props);

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.petHeader}>
                <Box className={clsx(classes.petPopUp, classes.boxFlex)}>
                    <Typography className={classes.typoPetName}>
                        {props.pet?.name}
                    </Typography>
                    {props.pet?.type && (
                        <Box
                            className={clsx([
                                classes.petIcon,
                                {
                                    [classes.petIconDogs]:
                                        props.pet?.type.singular === 'Dog'
                                },
                                {
                                    [classes.petIconCats]:
                                        props.pet?.type.singular === 'Cat'
                                },
                                {
                                    [classes.petIconOthers]:
                                        props.pet?.type.singular === 'Exotic'
                                }
                            ])}
                        >
                            <Typography className={classes.chip}>
                                {props.pet?.type.singular}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box className={classes.boxFlexRow}>
                    <Box className={classes.leftContainer}>
                        <ItemLabelIcon
                            label={
                                props.pet?.breed?.name
                                    ? props.pet?.breed?.name
                                    : 'Not defined'
                            }
                            icon={
                                props.pet?.type?.singular === 'Cat'
                                    ? faCat
                                    : props.pet?.type?.singular === 'Dog'
                                    ? faDog
                                    : faNarwhal
                            }
                            classNameLabel={classes.itemLabel}
                        />
                        <ItemLabelIcon
                            label={
                                props.pet?.size?.name
                                    ? props.pet.size.name
                                    : 'Not defined'
                            }
                            icon={faRuler}
                            classNameLabel={classes.itemLabel}
                        />
                    </Box>
                    <Box className={classes.rightContainer}>
                        <ItemLabelIcon
                            label={
                                props.pet?.hair?.name
                                    ? props.pet.hair.name
                                    : 'Not defined'
                            }
                            icon={faHeat}
                            classNameLabel={classes.itemLabel}
                        />
                        <ItemLabelIcon
                            label={
                                props.pet?.gender?.name
                                    ? props.pet.gender.name
                                    : 'Not defined'
                            }
                            icon={faMars}
                            classNameLabel={classes.itemLabel}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PetDescription;
