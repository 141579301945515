import React, { FunctionComponent } from "react";
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface AlertProps {
	icon: IconProp;
	bgColor: string;
	bdColor: string;
	bWidth?: string;
	className?: string;
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			borderRadius: 16,
			
			borderStyle: "solid",
			borderColor: "#000",
			marginBottom: 30,

			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(12)}px ${reduceResolution(15)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: "14px 15px",
			},

			"& strong, & b": {
				fontWeight: 600,
			},
		},
		box: {
			marginLeft: 8,
		},
	})
);

export const AlertMessage2: FunctionComponent<AlertProps> = (props) => {
	const classes = useStyles();

	return (
		<Box
			className={clsx(classes.root, props.className)}
			style={{
				backgroundColor: props.bgColor,
				borderColor: props.bdColor,
				borderWidth: props.bWidth || "1px",
			}}
		>
			<Grid container xs={12} spacing={0}>
				<Grid item>
					<FontAwesomeIcon
						size="xl"
						icon={props.icon}
						color={props.bdColor}
					/>
				</Grid>
				<Grid item xs>
					<Box className={classes.box}>{props.children}</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
