import {
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, Option } from '@spike/model';
import clsx from 'clsx';
import { ChangeEvent, FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import LabelField from './LabelField';

interface OptionsFieldProps {
	id?: string;
	label?: string;
	name?: string;
	selected?: Option<string>;
	options: Array<Option<string>>;
	errors?: Array<FieldError>;
	required?: boolean;
	disabled?: boolean;
	onChange?: (option: Option<string>, fieldName?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		radio: {
			'color': '#222222',
			'&.Mui-checked': {
				color: '#222222'
			}
		},
		labelField: {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 0,
				marginBottom: 8
			}
		},
		label: {
			'gap': 7,
			'margin': 0,

			'& .MuiButtonBase-root': {
				padding: 0
			},

			'& svg': {
				[theme.breakpoints.down(wbp)]: {
					width: `${reduceResolution(20)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					width: '20px'
				}
			},

			'& .MuiFormControlLabel-label': {
				color: '#222',
				fontSize: 15,
				lineHeight: 1,
				fontWeight: 500,

				[theme.breakpoints.up('md')]: {
					fontSize: 16
				}
			}
		},
		group: {
			rowGap: 10,
			columnGap: 20
		}
	})
);

export const OptionsField: FunctionComponent<OptionsFieldProps> = props => {
	const classes = useStyles();

	const changeHandler = (event: ChangeEvent, value: string) => {
		props.onChange &&
			props.onChange(
				props.options.find(option => option.id === value)!,
				props.name
			);
	};

	return (
		<Grid container xs={12}>
			<FormControl id={props.id}>
				<LabelField
					label={props.label}
					required={props.required}
					errors={props.errors}
					name={props.name}
					className={classes.labelField}
				/>
				<RadioGroup
					row
					onChange={changeHandler}
					name={props.name}
					className={classes.group}
				>
					{props.options.map((option, index) => (
						<FormControlLabel
							key={option.id}
							label={option.name}
							value={option.id}
							checked={option.id === props.selected?.id}
							disabled={props.disabled}
							className={clsx(classes.label)}
							control={
								<Radio
									id={`booking_payment_custom_tip_input_${option.id}`}
									className={classes.radio}
								/>
							}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</Grid>
	);
};

export default OptionsField;
