import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons';
import { Option } from '@spike/model';
import { FunctionComponent } from 'react';
import EditSetting from './EditSetting';

export interface Props {
    onEdit?: (
        emailChecked: boolean,
        smsChecked: boolean,
        noticeSelected: Option<string>,
        optionId: string
    ) => void;
    loading: boolean;
}

export const EditConfirmation: FunctionComponent<Props> = props => {
    const key = 'client_confirmation_request';

    const noticeOptions = [
        { id: '24', name: '1 day before' },
        { id: '48', name: '2 days before' },
        { id: '72', name: '3 days before' },
        { id: '96', name: '4 days before' },
        { id: '120', name: '5 days before' },
        { id: '144', name: '6 days before' },
        { id: '168', name: '7 days before' }
    ];

    const handleEdit = (
        emailChecked: boolean,
        smsChecked: boolean,
        noticeSelected: Option<string>
    ) => {
        props.onEdit &&
            props.onEdit(emailChecked, smsChecked, noticeSelected, key);
    };

    const subtitle = (
        <>Requests unconfirmed clients to confirm their upcoming appointment.</>
    );

    return (
        <EditSetting
            optionId={key}
            noticeOptions={noticeOptions}
            headerIcon={faClipboardCheck}
            title={'Confirmation Request'}
            subtitle={subtitle}
            defaultOption={noticeOptions[1]}
            loading={props.loading}
            onEdit={handleEdit}
        />
    );
};

export default EditConfirmation;
