import { FunctionComponent, useState, useEffect } from 'react';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Switch } from 'components/UI';
import { MobileDivider } from 'components/UI/MobileDivider';
import { debugConsoleLog } from 'utils/GeneralUtils';
import useNonInitialEffect from '@versiondos/hooks';

interface Props {
    active: boolean;
    editable: boolean | undefined;
    onChange: (active: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',

            [theme.breakpoints.up('xs')]: {
                marginTop: '0px',
                marginBottom: '0px'
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: '30px',
                marginBottom: '10px'
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '0px !important',
                marginBottom: '0px !important'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '0px !important',
                marginBottom: '0px !important'
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: '50px',
                width: '50%',
                marginTop: '0px'
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginTop: -2
        },
        secondaryContainer: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                marginTop: '20px',
                marginBottom: '20px'
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: '20px',
                marginBottom: '20px'
            }
        },
        descriptionContainer: {
            width: '25%',
            [theme.breakpoints.up('xs')]: {
                width: '45%'
            },
            [theme.breakpoints.up('sm')]: {
                width: '45%'
            },
            [theme.breakpoints.up('md')]: {
                width: '35%'
            }
        },
        switchContainer: {
            width: '75%',
            [theme.breakpoints.up('xs')]: {
                width: '55%'
            },
            [theme.breakpoints.up('sm')]: {
                width: '55%'
            },
            [theme.breakpoints.up('md')]: {
                width: '65%'
            }
        },
        description: {
            [theme.breakpoints.only('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '16px'
            }
        }
    })
);

export const ServiceOnlineBooking: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const [enabled, setEnabled] = useState(props.active);

    useNonInitialEffect(() => {
        props.onChange && props.onChange(enabled);
    }, [enabled]);

    useEffect(() => {
        if (props.editable) {
            setEnabled(props.active);
        }
        props.onChange && props.onChange(enabled);
    }, []);

    const changeEnableHandler = (checked: boolean) => {
        debugConsoleLog(String(checked));
        setEnabled(checked);
    };

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Online Booking</Typography>

            <Box className={classes.secondaryContainer}>
                <Box className={classes.descriptionContainer}>
                    <Typography className={classes.description}>
                        Bookable online
                    </Typography>
                </Box>

                <Box className={classes.switchContainer}>
                    <Switch
                        checked={enabled}
                        onChange={checked => changeEnableHandler(checked)}
                    />
                </Box>
            </Box>

            <Box style={{ margin: '0px -16px' }}>
                <MobileDivider />
            </Box>
        </Box>
    );
};

export default ServiceOnlineBooking;
