import Client from "@spike/client-model";
import SMSCampaign, { SMSCampaignCustomerGroupOption, SMSCampaignCustomerGroups, SMSCampaignQuote, SMSCampaignStatus } from "model/SMSCampaign";

export interface SMSCampaignsState {
    step: number,
    filter: SMSCampaignStatus,
    detail?: SMSCampaign,
    created?: SMSCampaign,
    status: SMSCampaignsStatus,
    groups?: SMSCampaignClientGroups,
    quote?: SMSCampaignQuote,

    all?: {
        page: number,
        size: number,
        total_pages: number,
        total_records: number,
        sms_campaigns: Array<SMSCampaign>,
    },

    campaigns?: {
        page: number,
        size: number,
        total_pages: number,
        total_records: number,
        sms_campaigns: Array<SMSCampaign>,
    },

    clients: {
        all?: {
            list: Array<Client>,
            page?: string;
            size?: string;
            total_pages?: number;
            total_records?: number;
        },
        search?: {
            list: Array<Client>,
            page?: string;
            size?: string;
            total_pages?: number;
            total_records?: number;
        }
    };
}

export interface SMSCampaignClientGroups {
    [SMSCampaignCustomerGroups.pet]: SMSCampaignCustomerGroupOption[],
    [SMSCampaignCustomerGroups.tag]: SMSCampaignCustomerGroupOption[],
    [SMSCampaignCustomerGroups.activity]: SMSCampaignCustomerGroupOption[],
    [SMSCampaignCustomerGroups.date]: SMSCampaignCustomerGroupOption[],
}

export enum SMSCampaignsStatus {
    Initial,

    Cleared,

    Error,

    GlobalFetching,
    GlobalFetchSuccess,

    Fetching,
    FetchSuccess,

    Getting,
    GetSuccess,

    Saving,
    SaveSuccess,

    Deleting,
    DeleteSuccess,

    Updating,
    UpdateSuccess,

    ClientsFetching,
    ClientsFetchSuccess,
    ClientsSearching,
    ClientsSearchSuccess
}