import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';

import { Tip as InvoiceTip } from '@spike/invoice-model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import CancelButton from 'components/Invoice/buttons/CancelButton';
import EditButton from 'components/Invoice/buttons/EditButton';
import SaveButton from 'components/Invoice/buttons/SaveButton';
import ViewStates from 'components/Invoice/ViewStates';
import AmountPercetageField from 'components/UI/AmountPercetageField';
import toNumber from 'lodash/toNumber';
import { FunctionComponent, useState } from 'react';
import { isSameTip } from './TipUtils';

interface CustomTipProps {
	tip: InvoiceTip | null;
	onlyAmount: boolean;
	saving: boolean;
	className?: string;
	onChange: (tip: InvoiceTip | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%'
		},
		text: {
			fontFamily: "Poppins",
			fontSize: '24px',
		},
		button: {
			marginLeft: '16px'
		},
		editable: {
			cursor: 'pointer'
		}
	})
);

export const CustomTip: FunctionComponent<CustomTipProps> = props => {
	const classes = useStyles();

	const [tip, setTip] = useState<InvoiceTip | null>(props.tip);
	const [viewState, setViewState] = useState<ViewStates>(ViewStates.View);

	useNonInitialEffect(() => {
		if (!props.saving) {
			setTip(props.tip);
			setViewState(ViewStates.View);
		}
	}, [props.saving]);

	const cancel = () => {
		setViewState(ViewStates.View);
		setTip(props.tip);
	};

	const edit = () => {
		setViewState(ViewStates.Editing);
	};

	const save = () => {
		if(isSameTip(props.tip, tip)) {
			setViewState(ViewStates.View);
		} else {
			setViewState(ViewStates.Saving);
			props.onChange(tip);
		}
	};

	const onChangeHandler = (
		amount: number | null,
		percentage: number | null
	) => {
		setTip({
			amount: amount?.toString() ?? null,
			percentage: percentage?.toString() ?? null
		});
	};

	return (
		<Box className={classes.container}>
			{viewState === ViewStates.Editing ? (
				<AmountPercetageField
					amount={tip?.amount ? Number(tip.amount) : null}
					percentage={tip?.percentage ? Number(tip.percentage) : null}
					onChange={onChangeHandler}
					onlyAmount={props.onlyAmount}
				/>
			) : (
				<Typography className={clsx(classes.text, {[classes.editable]: viewState === ViewStates.View})} onClick={() => viewState === ViewStates.View && edit()}>
					{tip?.percentage !== null
						? `${toNumber(tip?.percentage)}%`
						: `$${toNumber(tip?.amount).toFixed(2)}`}
				</Typography>
			)}
			{viewState === ViewStates.View && (
				<EditButton onClick={edit} size={22} className={classes.button} />
			)}
			{viewState === ViewStates.Editing && (
				<>
					<SaveButton onClick={save} size={20} className={classes.button} />
					<CancelButton onClick={cancel} size={20} className={classes.button} />
				</>
			)}
		</Box>
	);
};

export default CustomTip;
