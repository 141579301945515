import {
    Box,
    createStyles,
    FormLabel,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Option } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchAgreementsThunk } from 'actions/agreements/AgreementsActions';
import { SelectField } from 'components/UI';
import Spinner from 'components/UI/Spinner';
import useWindowSize from 'hooks/useWindowSize';
import { Agreement } from 'model/Agreements';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';

interface SelectAgreementProps {
    placeholder: string;
    onSelect?: (agreement: Agreement | null) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16
        },
        select: {
            marginBottom: '20px'
        },
        label: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000'
        },
        notCreated: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#222222',
            fontSize: 20,
            width: '100%',
            textAlign: 'center'
        }
    })
);

export const SelectAgreement: FunctionComponent<
    SelectAgreementProps
> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { windowSize } = useWindowSize();

    const agreements = useSelector<RootState, Array<Agreement>>(
        state => state.agreements.agreements
    );
    const agreementsStatus = useSelector<RootState, AgreementsStatus>(
        state => state.agreements.status
    );

    const [selected, setSelected] = useState<
        Option<string | number> | undefined
    >(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (agreements.length === 0) {
            dispatch(fetchAgreementsThunk());
        } else {
            setLoading(false);
        }
    }, []);

    useNonInitialEffect(() => {
        switch (agreementsStatus) {
            case AgreementsStatus.FetchSuccess:
                setLoading(false);
                break;
            case AgreementsStatus.Error:
                setLoading(false);
                break;
        }
    }, [agreementsStatus]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectionHandler = (
        option: Option<string | number> | undefined,
        name?: string
    ) => {
        setSelected(option);
        props.onSelect &&
            props.onSelect(
                agreements?.find(agreement => agreement.id === option?.id) ||
                    null
            );
    };

    const str =
        windowSize.width < 400
            ? 35
            : windowSize.width < 500
            ? 45
            : windowSize.width < 600
            ? 55
            : 70;

    return (
        <Box className={classes.root}>
            {loading && <Spinner />}
            {!loading && agreements.length > 0 && (
                <>
                    <FormLabel className={classes.label}>
                        Select Agreement
                    </FormLabel>
                    <SelectField
                        name="agreements"
                        placeholder="Select an agreement"
                        options={agreements
                            .filter(activeAgreement => activeAgreement.active)
                            .map(agreement => ({
                                id: agreement.id!,
                                name:
                                    agreement.title.length < str
                                        ? agreement.title
                                        : agreement.title.substring(0, str) +
                                          ' ..'
                            }))}
                        selectedOption={selected}
                        onSelect={selectionHandler}
                        className={classes.select}
                    />
                </>
            )}
            {!loading && agreements.length === 0 && (
                <Typography className={classes.notCreated}>
                    Agreements not created.
                </Typography>
            )}
        </Box>
    );
};

export default SelectAgreement;
