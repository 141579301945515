import {
  ProductVendorsAction,
  PRODUCT_VENDORS_RESET_ACTION_TYPE,
  PRODUCT_VENDORS_FETCH_START_ACTION_TYPE,
  PRODUCT_VENDORS_FETCH_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_GET_START_ACTION_TYPE,
  PRODUCT_VENDORS_GET_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_START_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_PRODUCTS_START_ACTION_TYPE,
  PRODUCT_VENDORS_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_PRODUCTS_START_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_START_ACTION_TYPE,
  PRODUCT_VENDORS_DELETE_SUCCESS_ACTION_TYPE,
  PRODUCT_VENDORS_ERROR_ACTION_TYPE,
} from "./ProductVendorsActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { ProductVendorsState } from "reducers/productVendors/ProductVendorsState";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction }from "@spike/notifications-action";
import store from "store";
import { convertToProductVendor } from "./ProductVendorsConverter";
import ProductVendor from "model/ProductVendor";
import { ProductVendorDto } from "./ProductVendorsDtos";
import { alertErrorHandler } from "@spike/notifications-action";
import { showSuccess }from "@spike/notifications-action";

const vendorsUrl = "/vendors";

export const fetchThunk = (): ThunkAction<void, ProductVendorsState, null, ProductVendorsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(fetchStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${vendorsUrl}?marketplace_id=${marketplaceId}`;
    
    try {
      const response: AxiosResponse<Array<ProductVendorDto>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchSuccess(response.data.map((vendorDto) => convertToProductVendor(vendorDto))));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error fetching product vendors.");
      }
    }
  };
};

export const getThunk = (
  vendorId: number
): ThunkAction<void, ProductVendorsState, null, ProductVendorsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(getStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${vendorsUrl}/${vendorId}?marketplace_id=${marketplaceId}`;

    try {
      const response: AxiosResponse<ProductVendorDto> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(getSuccess(convertToProductVendor(response.data)));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error getting product vendor.");
      }
    }
  };
};

export const saveThunk = (
  vendor: ProductVendor
): ThunkAction<void, ProductVendorsState, null, ProductVendorsAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const apiClientMethod = vendor.id ? ApiClient.patch : ApiClient.post;

    const saveUrl = vendor.id
      ? `${vendorsUrl}/${vendor.id}?marketplace_id=${marketplaceId}`
      : `${vendorsUrl}?marketplace_id=${marketplaceId}`;

    const body = {
      id: vendor.id,
      uuid: vendor.uuid,
      name: vendor.name,
      contact_name: vendor.contact,
      website: vendor.website,
      email: vendor.email,
      phone: vendor.phone,
      marketplace_id: marketplaceId,
      addresses_attributes: vendor.address ? [{
        id: vendor.address.id,
        marketplace_id: marketplaceId,
        address_line_one: vendor.address.state,
        address_line_two: vendor.address.suite,
        city: vendor.address.city,
        state: vendor.address.state,
        zipcode: vendor.address.zipcode,
        country: "US",
      }]:[],
      active: vendor.active,
      deleted: vendor.deleted
    };

    try {
      const response: AxiosResponse<ProductVendorDto> = await apiClientMethod(saveUrl, body, createTokenConfig(store.getState().login.auth.token!));
      dispatch(saveSuccess(convertToProductVendor(response.data)));
      dispatch(fetchThunk());
      dispatch(showSuccess("Product Vendor saved successfully"));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error saving product vendor.");
      }
    }
  };
};

export const saveProductsThunk = (
  vendor: ProductVendor
): ThunkAction<void, ProductVendorsState, null, ProductVendorsAction | NotificationsAction> => {
  return async (dispatch) => {

    dispatch(saveProductsStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${vendorsUrl}/${vendor.id}/add_products?marketplace_id=${marketplaceId}`;

    const bodyRequest = {
      id: vendor.id,
      product_ids: vendor.products.map(p => p.id).join(","),
    };

    try {
      const response: AxiosResponse<ProductVendorDto> = await  ApiClient.patch(
        url,
        bodyRequest,
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(saveProductsSuccess(convertToProductVendor(response.data)));
      dispatch(fetchThunk());
      dispatch(showSuccess("Product Vendor saved successfully"));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error saving product vendor.");
      }
    }
  };
};

export const deleteProductsThunk = (
  vendor: ProductVendor
): ThunkAction<void, ProductVendorsState, null, ProductVendorsAction | NotificationsAction> => {
  return async (dispatch) => {

    dispatch(deleteProductsStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${vendorsUrl}/${vendor.id}/remove_products?marketplace_id=${marketplaceId}`;

    const bodyRequest = {
      id: vendor.id,
      product_ids: vendor.products.map(p => p.id).join(","),
    };

    try {
      const response: AxiosResponse<ProductVendorDto> = await  ApiClient.patch(
        url,
        bodyRequest,
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(deleteProductsSuccess(convertToProductVendor(response.data)));
      dispatch(fetchThunk());
      dispatch(showSuccess("Product Vendor deleted successfully"));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error deleting product vendor.");
      }
    }
  };
};

export const deleteThunk = (
  vendor: ProductVendor
): ThunkAction<void, ProductVendorsState, null, ProductVendorsAction | NotificationsAction> => {
  return async (dispatch) => {

    dispatch(deleteStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${vendorsUrl}/${vendor.id}?marketplace_id=${marketplaceId}`;

    try {
      const response: AxiosResponse<ProductVendorDto> = await ApiClient.delete(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(deleteSuccess(vendor));
      dispatch(showSuccess("Product vendor deleted"));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        alertErrorHandler(apiError, dispatch, "Error deleting product vendor.");
      }
    }
  };
};

export const reset = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_RESET_ACTION_TYPE,
  };
};

const fetchStart = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_FETCH_START_ACTION_TYPE,
  };
};

export const fetchSuccess = (vendors: Array<ProductVendor>): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      vendors,
    },
  };
};

const getStart = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_GET_START_ACTION_TYPE,
  };
};

export const getSuccess = (vendor: ProductVendor): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_GET_SUCCESS_ACTION_TYPE,
    payload: {
      vendor
    },
  };
};

const saveStart = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_SAVE_START_ACTION_TYPE,
  };
};

export const saveSuccess = (vendor: ProductVendor): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      vendor,
    },
  };
};

const saveProductsStart = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_SAVE_PRODUCTS_START_ACTION_TYPE,
  };
};

export const saveProductsSuccess = (vendor: ProductVendor): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_SAVE_PRODUCTS_SUCCESS_ACTION_TYPE,
    payload: {
      vendor,
    },
  };
};

const deleteProductsStart = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_DELETE_PRODUCTS_START_ACTION_TYPE,
  };
};

export const deleteProductsSuccess = (vendor: ProductVendor): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_DELETE_PRODUCTS_SUCCESS_ACTION_TYPE,
    payload: {
      vendor,
    },
  };
};

const deleteStart = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_DELETE_START_ACTION_TYPE,
  };
};

export const deleteSuccess = (vendor: ProductVendor): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_DELETE_SUCCESS_ACTION_TYPE,
    payload: {
      vendorUuid: vendor.uuid
    }
  };
};

const error = (): ProductVendorsAction => {
  return {
    type: PRODUCT_VENDORS_ERROR_ACTION_TYPE
  };
};
