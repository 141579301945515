import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchClientsThunk, searchClientsThunk } from '@spike/clients-action';
import { Spinner } from 'components/UI';
import Button from 'components/UI/Button';
import SearchComponent from 'components/UI/SearchComponent';
import Client from '@spike/client-model';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    List,
    ListRowProps
} from 'react-virtualized';
import { ClientsStatus } from '@spike/clients-action';
import { RootState } from 'store';
import { formatPhoneNumber } from '@spike/phone-utils';
import { SelectedClient } from './model';
import { useApiClientWrapper } from 'hooks';

enum Columns {
    email = 'email',
    phone = 'phone'
}

interface SearchClientsProps {
    placeholder: string;
    show: 'email' | 'phone';
    selectedClients: Array<SelectedClient>;
    onChangeSelection?: (selectedClients: Array<SelectedClient>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
            paddingBottom: 20,
            position: 'relative'
        },
        title: {
            fontSize: 16,
            fontWeight: 600,
            color: '#222',
            margin: '23px 0'
        },
        search: {
            'height': 54,
            'width': '100%',
            '& .MuiInputBase': {
                '&-root': {
                    backgroundColor: '#fff',
                    width: '100%'
                },
                '&-input': {
                    fontSize: 16
                }
            },
            '& .MuiInputAdornment-root': {
                'opacity': 0.3,
                'pointer-events': 'none',

                '& svg': {
                    cursor: 'default'
                }
            }
        },
        dropdown: {
            display: 'flex',
            flexGrow: 1,
            borderRadius: 12,
            overflowY: 'auto',
            padding: '12px 15px',
            backgroundColor: '#fff',
            boxShadow: '0px 0px 21px rgba(0, 0, 0, 0.08)',
            marginTop: '16px',
            [theme.breakpoints.up('sm')]: {
                height: 154
            }
        },
        resultItem: {
            'backgroundColor': 'red',
            'width': '100%',
            'display': 'flex',
            'justifyContent': 'space-between',
            'padding': 10,
            'fontSize': 14,
            'height': '66px',
            'alignItems': 'center',
            'borderBottom': 'solid 1px #D4D4D4',
            'transition': 'background-color 0.1s ease-out',
            '&:hover': {
                backgroundColor: '#F8F5F1;'
            }
        },
        details: {
            'display': 'flex',
            'flexDirection': 'row',
            'flexGrow': 1,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            },
            '& > div:nth-child(1)': {
                width: '40%',
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                }
            },
            '&> div:nth-child(2)': {
                width: 'auto',
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                }
            }
        },
        clientName: { fontSize: '14px', fontWeight: 600 },
        button: {
            'height': 37,
            'padding': '6px, 16px, 6px, 16px',
            '& span': {
                fontSize: 14
            }
        },
        spinnerStyle: {
            marginTop: '40px'
        },
        paginationSpinner: {
            position: 'absolute',
            margin: 'auto',
            bottom: 0
        }
    })
);

const pageSize = 20;

export const SearchClients: FunctionComponent<SearchClientsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const cache = useRef(
        new CellMeasurerCache({ fixedWidth: true, minHeight: 66 })
    );

    const clientsList = useSelector<RootState, Array<Client>>(
        state => state.clients.list
    );

    const clientsTotalPages = useSelector<RootState, number>(
        state => state.clients.total_pages!
    );

    const clientsStatus = useSelector<RootState, ClientsStatus>(
        state => state.clients.status
    );

    const selectedClientIds = props.selectedClients.map(client => client.id);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [allClients, setAllClients] = useState<Array<Client>>(clientsList);
    const [loadingPage, setLoadingPage] = useState<boolean>(false);

    useNonInitialEffect(() => {
        switch (clientsStatus) {
            case ClientsStatus.FetchSuccess:
                setAllClients(prev => [
                    ...prev,
                    ...clientsList.filter(
                        client =>
                            !prev.some(
                                prevClient => prevClient.id === client.id
                            )
                    )
                ]);
                setLoading(false);
                setLoadingPage(false);
                break;
            case ClientsStatus.Error:
                setLoading(false);
                break;
        }
    }, [clientsStatus]);

    useNonInitialEffect(() => {
        setLoading(true);
        setAllClients([]);
        setCurrentPage(1);
        cache.current.clearAll();
    }, [searchText]);

    useEffect(() => {
        setAllClients([]);
        dispatch(fetchClientsThunk(apiClientWrapper, 1, pageSize));
    }, []);

    useNonInitialEffect(() => {
        if (searchText.length === 0) {
            dispatch(
                fetchClientsThunk(apiClientWrapper, currentPage, pageSize)
            );
        } else {
            dispatch(
                searchClientsThunk(
                    apiClientWrapper,
                    searchText,
                    currentPage,
                    pageSize
                )
            );
        }
    }, [searchText, currentPage]);

    const clients = useMemo(() => {
        return allClients.length > 0 && props.show === 'email'
            ? allClients.filter(
                  client => client.email && client.email.length !== 0
              )
            : allClients.length > 0 && props.show === 'phone'
            ? allClients.filter(
                  client => client.phone && client.phone.length !== 0
              )
            : [];
    }, [allClients, props.show]);

    const showColumn: Columns = props.show
        ? (props.show as Columns)
        : Columns.email;

    const rowRender = (listProps: ListRowProps) => {
        const client = clients[listProps.index];
        const selected = selectedClientIds.includes(client.id!);
        const clientName = `${client.firstName + ' ' + client.lastName}`;

        return (
            <CellMeasurer
                key={listProps.key}
                cache={cache.current}
                parent={listProps.parent}
                columnIndex={0}
                rowIndex={listProps.index}
            >
                <Box
                    key={client.id}
                    className={classes.resultItem}
                    style={{
                        backgroundColor: selected ? '#F8F5F1' : 'inherit',
                        ...listProps.style
                    }}
                >
                    <Box className={classes.details}>
                        <Box className={classes.clientName}>
                            {clientName.length > (isMobile ? 20 : 15)
                                ? `${clientName.substring(
                                      0,
                                      isMobile ? 20 : 15
                                  )}...`
                                : clientName}
                        </Box>
                        <Box>
                            {showColumn === Columns.email
                                ? client.email.length > (isMobile ? 20 : 23)
                                    ? `${client.email.substring(
                                          0,
                                          isMobile ? 20 : 23
                                      )}...`
                                    : client.email
                                : client.phone}
                        </Box>
                    </Box>
                    <Box>
                        {selected ? (
                            <Button
                                color="orange"
                                label="Unselect"
                                className={classes.button}
                                onClick={() => {
                                    props.onChangeSelection &&
                                        props.onChangeSelection(
                                            props.selectedClients.filter(
                                                selectedClient =>
                                                    selectedClient.id !==
                                                    client.id!
                                            )
                                        );
                                }}
                            />
                        ) : (
                            <Button
                                color="orange"
                                label="Select"
                                className={classes.button}
                                onClick={() => {
                                    props.onChangeSelection &&
                                        props.onChangeSelection([
                                            ...props.selectedClients,
                                            {
                                                id: client.id!,
                                                name: `${client.firstName} ${client.lastName}`,
                                                email: client.email,
                                                phone: formatPhoneNumber(
                                                    client.phone,
                                                    apiClientWrapper.marketplace!
                                                        .basics.address.country!
                                                        .id
                                                )
                                            }
                                        ]);
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </CellMeasurer>
        );
    };
    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>Select a client</Typography>
            <Box>
                <SearchComponent
                    open={true}
                    onSearch={setSearchText}
                    className={classes.search}
                    placeholder={props.placeholder}
                />
            </Box>

            {loading && <Spinner className={classes.spinnerStyle} />}

            {!loading && clients.length > 0 && (
                <Box className={classes.dropdown}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                width={width}
                                height={height}
                                rowHeight={cache.current.rowHeight}
                                deferredMeasurementCache={cache.current}
                                rowRenderer={rowRender}
                                rowCount={clients.length}
                                overscanRowCount={5}
                                onScroll={({
                                    clientHeight,
                                    scrollHeight,
                                    scrollTop
                                }) => {
                                    const isScrollAtBottom =
                                        scrollHeight *
                                            (currentPage === 1 ? 0.4 : 0.85) -
                                            clientHeight <=
                                        scrollTop;
                                    if (
                                        isScrollAtBottom &&
                                        currentPage < clientsTotalPages &&
                                        !loadingPage
                                    ) {
                                        setLoadingPage(true);
                                        setCurrentPage(prev => prev + 1);
                                    }
                                }}
                            />
                        )}
                    </AutoSizer>
                    {/* {loadingPage && <Spinner className={classes.paginationSpinner} />} */}
                </Box>
            )}
        </Box>
    );
};

export default SearchClients;
