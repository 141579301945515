import { FunctionComponent } from 'react';
import { Box, Typography, OutlinedInput } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import { isEmpty } from 'lodash';

interface Props {
    text: string;
    error?: FieldError;
    onChange: (text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%'
        },
        title: {
            'fontSize': 20,
            'fontWeight': 600,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 24,
                fontSize: 16
            },
            '& span': {
                fontWeight: 600,
                color: '#EAB464'
            }
        },
        description: {
            'width': '100%',

            [theme.breakpoints.only('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '18px'
            },
            'borderRadius': '100px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                borderColor: '#222222 !important'
            },
            "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline":
                {
                    borderWidth: '1px !imporant',
                    borderColor: '#222222 !important'
                },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EA6464 !important'
            },
            '& input': {
                'height': 54,
                'paddingTop': '0px',
                'paddingBottom': '0px',
                'paddingRight': '35px',
                'paddingLeft': '35px',

                '&::placeholder': {
                    opacity: 1,
                    color: '#7A7A7A'
                }
            },
            '& legend': {
                maxWidth: 0 //Avoid the label border interuption.
            }
        },
        errorMessage: {
            color: 'red',
            marginBottom: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        }
    })
);

export const ServiceName: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const handleChange = (event: any): void => {
        props.onChange(event.target.value);
    };

    return (
        <Box className={classes.container}>
            {props.error && isEmpty(props.text) ? (
                <Typography className={classes.errorMessage}>
                    {props.error.errorMessage}
                </Typography>
            ) : (
                <Typography className={classes.title}>
                    Service <span>*</span>
                </Typography>
            )}
            <OutlinedInput
                error={props.error !== undefined && isEmpty(props.text)}
                id={'service_add_service_input_name'}
                label={''}
                value={props.text}
                onChange={handleChange}
                className={classes.description}
                placeholder={'Service Name'}
            />
        </Box>
    );
};

export default ServiceName;
