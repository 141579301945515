import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import PoweredByCuddlesPay from './PoweredByCuddlesPay';
import Title from '../ui/Title';
import clsx from 'clsx';

interface TransactionsEmptyProps {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			justifyItems: 'center',
			backgroundColor: '#FAFAFA',
			paddingBottom: 45,

			[theme.breakpoints.up('md')]: {
				paddingBottom: '65px'
			}
		},
		header: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-start',
			paddingTop: 24,
			paddingLeft: 16,
			paddingRight: 16,
			paddingBottom: 24,

			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#ffffff'
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: 50,
				paddingLeft: 24,
				paddingRight: 24
			}
		},
		body: {
			gap: 8,
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			textAlign: 'center'
		},
		footer: {
			display: 'flex',
			bottom: 0
		},
		message: {
			width: '100%',
			fontSize: 26,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',

			[theme.breakpoints.up('md')]: {
				fontSize: 32
			}
		},
		description: {
			fontSize: 18,
			lineHeight: 1.4,
			fontWeight: 400,
			color: '#222222',

			[theme.breakpoints.down('sm')]: {
				margin: '0px auto',
				maxWidth: 216
			}
		}
	})
);

export const TransactionsEmpty: FunctionComponent<
	TransactionsEmptyProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.header}>
				<Title text="Transactions" />
			</Box>
			<Box className={classes.body}>
				<Typography className={classes.message}>
					No Transactions Yet
				</Typography>
				<Typography className={classes.description}>
					You have no registered transactions
				</Typography>
			</Box>
			<Box className={classes.footer}>
				<PoweredByCuddlesPay />
			</Box>
		</Box>
	);
};

export default TransactionsEmpty;
