import { faFileSignature, faUser } from '@fortawesome/pro-regular-svg-icons';
import Agreements from 'components/Agreements';
import ClientsComponent from 'components/Clients';
import PageSidebar from 'components/PageSidebar';
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Client from './Client';
import PrivatePage from './PrivatePage';
import { useHasPremiumAccess } from 'hooks';
import { AgreementsPath } from 'routes/Paths';
import PremiumFeatureAlert from 'components/UI/PremiumFeatureAlert';
import { useHistory } from 'react-router-dom';

interface MatchParams {
    hash?: string;
}

type ClientsProps = RouteComponentProps<MatchParams>;

interface EditData {
    clientId?: number;
    petId?: number;
}

const ClientsItem = {
    icon: faUser,
    title: 'Clients',
    key: 'clients'
};

const AgreementsItem = {
    icon: faFileSignature,
    title: 'Agreements',
    key: 'agreements'
};

const agreementsEnabled = process.env.REACT_APP_AGREEMENTS_ENABLED === 'true';

const secret = `${process.env.REACT_APP_GLOBAL_SECRET}`;

const convertHash = (
    hash: string | undefined,
    secret: string
): EditData | null => {
    if (hash) {
        const json: EditData = JSON.parse(
            AES.decrypt(decodeURIComponent(hash), secret).toString(
                CryptoJS.enc.Utf8
            )
        );

        if (json.clientId === undefined && json.petId === undefined) {
            return null;
        } else {
            return json;
        }
    } else {
        return null;
    }
};

export const Clients: FunctionComponent<ClientsProps> = props => {
    const hasReportsPremiumAccess = useHasPremiumAccess('agreements');
    const history = useHistory();
    const [selectedKey, setSelectedKey] = useState<string>(ClientsItem.key);
    const [editData, setEditData] = useState<EditData | null>(
        convertHash(props.match.params.hash, secret)
    );

    const sidebarItems = useMemo(() => {
        return [ClientsItem, AgreementsItem].filter(item => {
            if (item.key === AgreementsItem.key) {
                return agreementsEnabled;
            }

            return true;
        });
    }, [hasReportsPremiumAccess]);

    useEffect(() => {
        setEditData(convertHash(props.match.params.hash, secret));
    }, [props.match.params.hash]);

    useEffect(() => {
        if (location.hash === '#agreements') {
            setSelectedKey(AgreementsItem.key);
        } else {
            setSelectedKey(ClientsItem.key);
        }
    }, [location.hash]);

    const editHandler = (clientId?: number, petId?: number) => {
        setEditData({ clientId, petId });
    };

    const sidebar = (
        <PageSidebar
            title="Clients"
            items={sidebarItems}
            selectedKey={selectedKey}
            onSelect={setSelectedKey}
        />
    );

    const clients = (
        <PrivatePage
            title="Clients - Cuddles"
            pageName="/clients"
            pageSidebar={sidebar}
        >
            <ClientsComponent
                onAddClient={() => editHandler()}
                onEditClient={editHandler}
                onEditPet={editHandler}
            />
        </PrivatePage>
    );

    const closePremiumModalHandler = () => {
        setSelectedKey(ClientsItem.key);
        history.push('/clients');
    };

    const agreements = (
        <PrivatePage
            title="Agreements - Cuddles"
            pageName={AgreementsPath}
            pageSidebar={sidebar}
        >
            {hasReportsPremiumAccess ? (
                <Agreements />
            ) : (
                <PremiumFeatureAlert
                    text={
                        <>
                            Keep clients informed of your policies with
                            Agreements. Create custom agreements, send via email
                            or text, and automate signature collection.
                            <b> Upgrade now</b> and ditch paper agreements.
                        </>
                    }
                    isOpen={true}
                    onCancel={closePremiumModalHandler}
                />
            )}
        </PrivatePage>
    );

    const client = <Client {...editData} onClose={() => setEditData(null)} />;

    switch (selectedKey) {
        case AgreementsItem.key:
            return agreements;
        default:
            return editData === null ? clients : client;
    }
};

export default Clients;
