import React from 'react';

interface TextOverflowProps {
    text: string;
    length: number;
}

const TextOverflow: React.FC<TextOverflowProps> = props => {
    const text =
        props.text.length > props.length
            ? `${props.text.slice(0, props.length)}...`
            : props.text;

    return <span>{text}</span>;
};

export default TextOverflow;
