import { faFile } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface DocumentProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#F8F5F1",
      borderRadius: "34px",
      [theme.breakpoints.down(wbp)]: {
        width: 36,
        height: 36,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "34px",
        height: "34px",
      },
    },
    icon: {
      color: "#BAA997",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(16)}px !important`,
        height: `${reduceResolution(16)}px !important`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "16px !important",
        height: "16px !important",
      },
    }
  })
);

export const Document: FunctionComponent<DocumentProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      <FontAwesomeIcon icon={faFile} className={classes.icon} />
    </Box>
  );
};

export default Document;
