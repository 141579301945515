import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Option } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { SelectionBar } from 'components/UI';
import { Fragment, FunctionComponent, useState } from 'react';

interface AgreementsTableFiltersProps {
	selectedTotalRecords?: number;
	onSelect?: (active: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			// marginBottom: 40,
			// paddingLeft: 24,
			// paddingRight: 24,
			// [theme.breakpoints.down(768)]: {
			// 	paddingLeft: 16,
			// 	paddingRight: 16,
			// 	marginBottom: 34
			// },
			// [theme.breakpoints.between(768, 'sm')]: {
			// 	paddingLeft: 16,
			// 	paddingRight: 16,
			// 	marginBottom: 34
			// }
		},
		selectionBar: {
			'display': 'inline-flex',
			'backgroundColor': '#fff',
			'& [class*="selectedContainer"] p': {
				fontWeight: 600
			},
			'& [class*="lastButton"]': {
				marginLeft: 4
			},
			[theme.breakpoints.up('lg')]: {
				backgroundColor: '#fff'
			}
		}
	})
);

const active: Option<string> = { id: 'active', name: 'Active' };
const inactive: Option<string> = { id: 'inactive', name: 'Inactive' };

const options = [active, inactive];

const AgreementsTableFilters: FunctionComponent<
	AgreementsTableFiltersProps
> = props => {
	const classes = useStyles();

	const [selectedId, setSelectedId] = useState(active.id);

	useNonInitialEffect(() => {
		props.onSelect && props.onSelect(selectedId === active.id);
	}, [selectedId]);

	return (
		<Fragment>
			<Box className={classes.root}>
				<SelectionBar
					options={options}
					selectedId={selectedId}
					selectedTotalRecords={props.selectedTotalRecords}
					onSelect={setSelectedId}
					className={classes.selectionBar}
				/>
			</Box>
		</Fragment>
	);
};

export default AgreementsTableFilters;
