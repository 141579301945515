import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
    MarketplaceStatus,
    changeOlbStatusThunk,
    saveOlbAllowNewClientsThunk,
    saveOlbAllowSelectStaffThunk,
    saveOlbBookingRequestThunk,
    saveOlbLimitsThunk
} from '@spike/marketplace-action';
import {
    MarketplaceOnlineBooking,
    MarketplaceTimeLimit
} from '@spike/marketplace-model';
import { ACCESS_LEVEL_ADMIN_ID, FieldError, OWNER_ID } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { Switch, TextFieldWithButton } from 'components/UI';
import { useApiClientWrapper, useAuth, useMasterData } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { BusinessSettingsTitle } from '../UI';
import Limit from './Limit';
import RowSwitch from './RowSwitch';
import Section from './Section';

export interface Props {
    onlineBooking: MarketplaceOnlineBooking;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 100,
            [theme.breakpoints.only('md')]: {
                width: '368px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '658px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '900px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        headerContainer: {
            display: 'flex',
            [theme.breakpoints.only('md')]: {
                marginBottom: '2px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '6.5px'
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: '5px'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
        },
        businessSettingsTittle: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '5px'
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        row: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.only('md')]: {
                marginTop: '4px',
                marginBottom: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '13px',
                marginBottom: '13px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        rowInput: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    height: '52px !important',
                    fontSize: '16px',
                    fontWeight: '400',
                    textOverflow: 'ellipsis !important',
                    paddingRight: '5px !important'
                },
                '& button': {
                    'height': '37px',
                    'width': '100px',
                    'paddingLeft': '16px',
                    'paddingRight': '15px',
                    '& .MuiButton-label': {
                        'fontSize': '14px',
                        'fontWeight': '600',
                        '& .MuiButton-startIcon': {
                            'marginRight': '7px',
                            '& svg': {
                                width: '14px',
                                height: '14px'
                            }
                        }
                    }
                },
                '& fieldset': {
                    borderColor: '#f00 !important'
                }
            }
        },
        switchContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        subtitle: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '0px'
            }
        },
        alert: {
            color: '#000',
            fontWeight: 600,
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px',
                lineHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                lineHeight: '21px'
            },
            [theme.breakpoints.only('lg')]: {
                lineHeight: '28px'
            },
            [theme.breakpoints.only('xl')]: {
                lineHeight: '42px'
            }
        },
        alertContainer: {
            width: '100%',
            display: 'flex',
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',
            [theme.breakpoints.down('sm')]: {
                borderRadius: 18,
                padding: 17,
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: 20,
                padding: 15,
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        alertText: {
            color: '#000 !important',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '18px'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px',
                lineHeight: '22px'
            }
        },
        icon: {
            color: '#BAA997',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        sectionRow: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '22px'
            }
        }
    })
);

const validate = (
    from: MarketplaceTimeLimit | null,
    to: MarketplaceTimeLimit | null
): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (
        from !== null &&
        from.offsetSeconds !== null &&
        to !== null &&
        to.offsetSeconds !== null &&
        from.offsetSeconds > to.offsetSeconds
    ) {
        errors.push({ fieldName: 'from', errorMessage: `Is after ${to.name}` });
        errors.push({
            fieldName: 'to',
            errorMessage: `Is before ${from.name}`
        });
    }

    return errors;
};

export const OnlineBooking: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const onlineBookingPath = `${process.env.REACT_APP_ONLINE_BOOKING_HOST_URL}${props.onlineBooking.slug}`;
    const auth = useAuth();
    const masterData = useMasterData();

    const isAdmin = auth.user!.accessLevel.id === ACCESS_LEVEL_ADMIN_ID;

    const [butonMessage, setButtonMessage] = useState('Copy');
    const [butonIcon, setButtonIcon] = useState(faCopy);
    const [urlCopied, setUrlCopied] = useState(false);
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [enabled, setEnabled] = useState(props.onlineBooking.active);
    const [showSuccess, setShowSuccesss] = useState(
        props.onlineBooking.slug ? true : false
    );
    const [limits, setLimits] = useState({
        from: props.onlineBooking.from,
        to: props.onlineBooking.to
    });

    const marketplaceStatus = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    useNonInitialEffect(() => {
        setLimits({
            from: props.onlineBooking.from,
            to: props.onlineBooking.to
        });
    }, [props.onlineBooking]);

    useNonInitialEffect(() => {
        if (
            marketplaceStatus === MarketplaceStatus.ChangeOlbStatusSuccess &&
            enabled
        ) {
            setShowSuccesss(true);
        }
        if (marketplaceStatus === MarketplaceStatus.Error) {
            setEnabled(false);
        }
    }, [marketplaceStatus]);

    useNonInitialEffect(() => {
        setButtonMessage('Copied');
        setButtonIcon(faCheck);
    }, [urlCopied]);

    const copyToClipboard = () => {
        setUrlCopied(true);
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(onlineBookingPath);
        } else {
            document.execCommand('copy', true, onlineBookingPath);
        }
    };

    const changeEnableHandler = (checked: boolean) => {
        setEnabled(checked);
        dispatch(changeOlbStatusThunk(apiClientWrapper, checked));
    };

    const selectFromHandler = (from: MarketplaceTimeLimit | null) => {
        setLimits(prev => ({ ...prev, from }));
        const errors = validate(from, limits.to);
        setErrors(errors);
        isEmpty(errors) &&
            dispatch(
                saveOlbLimitsThunk(
                    apiClientWrapper,
                    from,
                    props.onlineBooking.to
                )
            );
    };

    const selectToHandler = (to: MarketplaceTimeLimit | null) => {
        setLimits(prev => ({ ...prev, to }));
        const errors = validate(limits.from, to);
        setErrors(errors);
        isEmpty(errors) &&
            dispatch(
                saveOlbLimitsThunk(
                    apiClientWrapper,
                    props.onlineBooking.from,
                    to
                )
            );
    };

    const changeAllowClientsSelectStaffHandler = (checked: boolean) => {
        dispatch(saveOlbAllowSelectStaffThunk(apiClientWrapper, checked));
    };

    const changeAllowNewClientsHandler = (checked: boolean) => {
        dispatch(saveOlbAllowNewClientsThunk(apiClientWrapper, checked));
    };

    const changeBoolingRequestHandler = (checked: boolean) => {
        dispatch(saveOlbBookingRequestThunk(apiClientWrapper, checked));
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.headerContainer}>
                <Box className={classes.titleContainer}>
                    <BusinessSettingsTitle
                        className={classes.businessSettingsTittle}
                    >
                        {' '}
                        {props.isMobile && (
                            <FontAwesomeIcon
                                className={classes.iconBusinessTitle}
                                onClick={props.openSidebar}
                                icon={faArrowLeft}
                                size="2x"
                            />
                        )}{' '}
                        Online Booking
                    </BusinessSettingsTitle>
                </Box>
                <Box className={classes.switchContainer}>
                    {isAdmin && (
                        <Switch
                            checked={enabled}
                            onChange={checked => changeEnableHandler(checked)}
                        ></Switch>
                    )}
                </Box>
            </Box>
            {enabled && showSuccess ? (
                <>
                    <Box className={classes.alertContainer}>
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={classes.icon}
                        />
                        <Box>
                            <Typography className={classes.alertText}>
                                Your online booking is currently enabled, and
                                you&apos;re ready to accept bookings from pet
                                parents.
                            </Typography>
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <Box className={classes.alertContainer}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={classes.icon}
                        />
                        <Box>
                            <Typography className={classes.alertText}>
                                Your online booking is currently disabled.
                                Enable to allow pet parents to book appointments
                                online.
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}

            {enabled && (
                <>
                    <Box className={classes.row}>
                        <Typography className={classes.subtitle}>
                            Share your link today and connect with your clients
                            to schedule their next appointment.
                        </Typography>
                    </Box>
                    <Box className={clsx(classes.row, classes.rowInput)}>
                        <TextFieldWithButton
                            value={onlineBookingPath}
                            label={butonMessage}
                            startIcon={butonIcon}
                            blackButton={true}
                            onClick={copyToClipboard}
                            classNameCustom="onlineBookingInput"
                        />
                    </Box>
                    <Section
                        title="Scheduling Rules"
                        description="Set how far in advance your clients can book online."
                    >
                        <Limit
                            name="from"
                            limits={masterData.olbFromLimits}
                            selected={limits.from}
                            label="How close to an appointment start time can clients book?"
                            errors={errors}
                            disabled={!isAdmin}
                            isMobile={props.isMobile}
                            className={classes.sectionRow}
                            onSelect={selectFromHandler}
                        />
                        <Limit
                            name="to"
                            limits={masterData.olbToLimits}
                            selected={limits.to}
                            label="How far in advance can clients book appointments?"
                            errors={errors}
                            disabled={!isAdmin}
                            isMobile={props.isMobile}
                            className={classes.sectionRow}
                            onSelect={selectToHandler}
                        />
                        <RowSwitch
                            label="Allow clients to select staff members"
                            checked={
                                props.onlineBooking.allowClientsSelectStaff
                            }
                            name="allowClientsSelectStaff"
                            errors={errors}
                            isMobile={props.isMobile}
                            disabled={!isAdmin}
                            onChange={changeAllowClientsSelectStaffHandler}
                            className={classes.sectionRow}
                        />
                    </Section>
                    <Section
                        title="Booking Permissions"
                        description="Set who can make an appointment online."
                    >
                        <RowSwitch
                            label="Allow new clients"
                            checked={props.onlineBooking.allowNewClients}
                            name="allowNewClients"
                            errors={errors}
                            isMobile={props.isMobile}
                            disabled={!isAdmin}
                            onChange={changeAllowNewClientsHandler}
                            className={classes.sectionRow}
                        />
                        <RowSwitch
                            label="Review and approve each appointment request manually"
                            checked={props.onlineBooking.bookingRequestsEnabled}
                            name="bookingRequestsEnabled"
                            errors={errors}
                            isMobile={props.isMobile}
                            disabled={!isAdmin}
                            onChange={changeBoolingRequestHandler}
                            className={classes.sectionRow}
                        />
                    </Section>
                </>
            )}
        </Box>
    );
};

export default OnlineBooking;
