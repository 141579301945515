import Result from "components/GlobalSearch/GlobalResult";
import React, { Fragment, FunctionComponent } from "react";
import AppointmentsWebSockets from "WebSockets";
import PrivatePage from "./PrivatePage";

interface GlobalResultProps {
  query: string;
}

export const GlobalResult: FunctionComponent<GlobalResultProps> = (props) => {
  return (
    <Fragment>
      <AppointmentsWebSockets />
      <PrivatePage title="Global Result - Cuddles" pageName="/globalResult">
        <Result query={props.query} />
      </PrivatePage>
    </Fragment>
  );
};

export default GlobalResult;
