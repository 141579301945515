/* eslint-disable react/no-unescaped-entities */
import { Box, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { PetMedicalHistory } from '@spike/medical-history-model';
import { FieldError } from '@spike/model';
import { Pet } from '@spike/pet-model';
import { PetsStatus, deletePetThunk, savePetThunk } from '@spike/pets-action';
import { VetsStatus, saveVetThunk } from '@spike/vets-action';
import useNonInitialEffect from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import { Button, ConfirmDialog } from 'components/UI';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import VetModel, { createEmptyVet } from '@spike/vet-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import ImageAddPet from './ImageAddPet';
import MedicalHistory from './MedicalHistory';
import { validate as validateMedicalHistoryAndVet } from './MedicalHistory/Edit/Validations';
import PetForm from './PetForm';
import { validatePet } from './Validations';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AlertBox from 'components/UI/Alerts/alertBox';
import { BusinessSettingsPath } from 'routes/Paths';
import { NoPetTypesAlert } from 'components/UI/V2/Info/Variants/NoPetTypesAlert';

interface AddPetProps {
    pet: Pet;
    onSaved?: (pet: Pet) => void;
    onDeleted?: () => void;
    onSelect?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: '#F1F1F1',
            minHeight: '100%',
            height: 'fit-content',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(39)}px ${reduceResolution(56)}px ${reduceResolution(
                    80
                )}px ${reduceResolution(61)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '39px 56px 80px 61px'
            },
            [theme.breakpoints.down('sm')]: {
                'padding': '15px',
                'paddingBottom': '20px',
                'backgroundColor': '#FFFFFF',
                'overflowX': 'hidden',
                '@supports (-webkit-touch-callout: none)': {
                    paddingBottom: '70px'
                }
            }
        },

        titleContainer: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '22px'
            },
            [theme.breakpoints.down('md')]: {
                paddingBottom: '15px',
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(28)}px`,
                lineHeight: `${reduceResolution(39)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '28px',
                lineHeight: '39px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '20px',
                lineHeight: '22px'
            }
        },
        formPet: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '18px'
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0px',
                paddingRight: '0px'
            }
        },
        containerForm: {
            backgroundColor: '#FFFFFF',
            border: '1px solid #DDDDDD',
            [theme.breakpoints.down(wbp)]: {
                borderRadius: `${reduceResolution(24)}px`,
                paddingLeft: `${reduceResolution(33)}px`,
                minHeight: `${reduceResolution(100)}%`,
                paddingRight: `${reduceResolution(31)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                borderRadius: '24px',
                paddingLeft: '33px',
                minHeight: '100%',
                paddingRight: '31px'
            },
            [theme.breakpoints.down('sm')]: {
                borderRadius: '0px',
                minHeight: '100%',
                paddingLeft: '0px',
                paddingRight: '0px',
                border: 'none'
            }
        },
        petImage: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(44)}px`,
                paddingLeft: `${reduceResolution(28)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '44px',
                paddingLeft: '28px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '15px',
                marginBottom: '10px',
                paddingLeft: '0px'
            }
        },
        containerMedicalHistory: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(35)}px`,
                paddingRight: `${reduceResolution(31)}px`,
                paddingBottom: `${reduceResolution(53)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '35px',
                paddingRight: '31px',
                paddingBottom: '53px'
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: '20px'
            }
        },
        containerMedicalHistoryItems: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(35)}px`,
                paddingRight: `${reduceResolution(31)}px`,
                paddingBottom: `${reduceResolution(53)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '35px',
                paddingRight: '31px',
                paddingBottom: '53px'
            }
        },
        footer: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(30)}px`,
                paddingBottom: `${reduceResolution(32)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '30px',
                paddingBottom: '32px'
            },
            [theme.breakpoints.down('md')]: {
                paddingTop: '10px'
            }
        },
        divider: {
            border: '0px solid #DDDDDD',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(25)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '25px'
            }
        },
        deleteButton: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '12px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        saveButton: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '50px',
                borderRadius: '30px',
                backgroundColor: '#EAB464 !important'
            }
        },
        iconBack: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline-block',
                fontSize: '20px',
                fontWeight: '300',
                marginRight: '8px',
                cursor: 'pointer'
            }
        },
        noPetTypesInfo: {
            marginTop: 25
        }
    })
);

const Separator = () => {
    return <Box className={useStyles().separator}></Box>;
};

const useIsPortrait = () => {
    const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setIsPortrait(window.innerWidth < window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isPortrait;
};

export const AddPet: FunctionComponent<AddPetProps> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const isPortrait = useIsPortrait();

    const setBodyStyles = () => {
        document.body.style.height = '100vh';
        document.body.style.overflowY = 'hidden';
    };

    const removeBodyStyles = () => {
        document.body.style.height = '';
        document.body.style.overflowY = '';
    };

    useEffect(() => {
        if (isSmall) {
            if (isPortrait) {
                setTimeout(setBodyStyles, 2000);
            } else {
                removeBodyStyles();
            }
        }
    }, [isSmall, isPortrait]);

    useEffect(() => {
        return () => {
            removeBodyStyles();
        };
    }, [history]);

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();

    const hasNotPetTypes = marketplace.petTypes
        ? !marketplace.petTypes.cats && !marketplace.petTypes.dogs && !marketplace.petTypes.exotics
        : false;

    const petsState = useSelector<RootState, PetsStatus>(state => state.pets.status);
    const savedPet = useSelector<RootState, Pet | undefined>(state => state.pets.savedPet);

    const vetsState = useSelector<RootState, VetsStatus>(state => state.vets.status);
    const savedVet = useSelector<RootState, VetModel | undefined>(state => state.vets.savedVet);

    const [editPet, setEditPet] = useState(props.pet);
    const [editVet, setEditVet] = useState<VetModel | undefined>(
        props.pet.id !== undefined && props.pet.vetId !== undefined && props.pet.vetId === savedVet?.id
            ? { ...savedVet }
            : createEmptyVet(uuid)
    );

    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [savingPet, setSavingPet] = useState(false);
    const [savingVet, setSavingVet] = useState(false);

    const [deleting, setDeleting] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useNonInitialEffect(() => {
        if (!isEqual(editPet, props.pet)) {
            setEditPet(props.pet);
        }

        if (editVet?.id !== props.pet.vetId || props.pet.vetId === undefined) {
            setEditVet(createEmptyVet(uuid));
        }
    }, [props.pet]);

    useNonInitialEffect(() => {
        switch (vetsState) {
            case VetsStatus.SaveSuccess:
                // eslint-disable-next-line
                setEditVet({ ...savedVet! });
                dispatch(
                    savePetThunk(apiClientWrapper, {
                        ...editPet,
                        // eslint-disable-next-line
                        vetId: savedVet!.id
                    })
                );
                setSavingVet(false);
                break;
            case VetsStatus.Error:
                setSavingVet(false);
                break;
        }
    }, [vetsState]);

    useNonInitialEffect(() => {
        switch (petsState) {
            case PetsStatus.SavePetSuccess:
                setSavingPet(false);
                // eslint-disable-next-line
                setEditPet(savedPet!);
                // eslint-disable-next-line
                props.onSaved && props.onSaved(savedPet!);
                break;
            case PetsStatus.SaveVetIdSuccess:
                setSavingVet(false);
                break;
            case PetsStatus.DeleteSuccess:
                setDeleting(false);
                props.onDeleted && props.onDeleted();
                break;
            case PetsStatus.Error:
                setSavingPet(false);
                break;
        }
    }, [petsState]);

    const saveHandler = () => {
        const errors = [
            ...validatePet(editPet),
            ...validateMedicalHistoryAndVet(
                // eslint-disable-next-line
                apiClientWrapper.marketplace!.basics.address.country!,
                editPet.medicalHistory,
                editVet
            )
        ];
        setErrors(errors);

        if (errors.length === 0) {
            if (!isEmpty(editVet?.name) || !isEmpty(editVet?.businessName)) {
                setSavingVet(true);
                // eslint-disable-next-line
                dispatch(saveVetThunk(apiClientWrapper, editVet!));
            } else {
                setSavingPet(true);
                dispatch(savePetThunk(apiClientWrapper, editPet));
            }
        }
    };

    const changeMedicalHistoryHandler = (medicalHistory: PetMedicalHistory) => {
        setEditPet(prev => ({
            ...prev,
            medicalHistory: cloneDeep(medicalHistory)
        }));
    };

    const changeVetHandler = (vet?: VetModel) => {
        setEditPet(prev => ({ ...prev, vetId: vet?.id }));
        setEditVet(vet);
    };

    const deleteHandler = () => {
        setDeleting(true);
        // eslint-disable-next-line
        dispatch(deletePetThunk(apiClientWrapper, editPet.id!));
    };

    const handleBackClick = () => {
        removeBodyStyles();
        props.onSelect && props.onSelect();
    };

    const header = (
        <Grid item xs={12} className={classes.titleContainer}>
            <FontAwesomeIcon className={classes.iconBack} icon={faArrowLeft} size="1x" onClick={handleBackClick} />
            <Typography className={classes.title}>Add Pet</Typography>
        </Grid>
    );

    const body = (
        <Grid item xs={12} className={classes.containerForm}>
            {hasNotPetTypes && (
                <Box className={classes.noPetTypesInfo}>
                    <NoPetTypesAlert />
                </Box>
            )}
            <Grid container item xs={12}>
                <Grid item xs={12} md={4} className={classes.petImage}>
                    <ImageAddPet pet={editPet} onChange={setEditPet} />
                </Grid>
                <Grid item xs={12} md={8} className={classes.formPet}>
                    <PetForm disabled={hasNotPetTypes} new={true} pet={editPet} errors={errors} onChange={setEditPet} />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.divider}>
                {isSmall ? <Separator /> : <Divider />}
            </Grid>
            <Grid item xs={12} className={classes.containerMedicalHistory}>
                <MedicalHistory
                    pet={editPet}
                    vet={editVet}
                    newPet={true}
                    saving={savingPet || savingVet}
                    onChangeMedicalHistory={changeMedicalHistoryHandler}
                    onChangeVet={changeVetHandler}
                    disabled={hasNotPetTypes}
                    errors={errors}
                />
            </Grid>
        </Grid>
    );

    const footer = (
        <Grid className={classes.footer} item container xs={12} justifyContent="flex-end">
            {editPet.id && editPet.destroyable && (
                <Button
                    label="Delete"
                    variant="danger"
                    className={classes.deleteButton}
                    loading={deleting}
                    disabled={savingPet || savingVet}
                    onClick={() => setShowDeleteConfirmation(true)}
                />
            )}
            <Button
                label="Save Pet"
                color="green"
                id="clients_button_save_pet"
                disabled={hasNotPetTypes}
                onClick={saveHandler}
                loading={savingPet || savingVet}
                className={classes.saveButton}
            />
        </Grid>
    );

    return (
        <>
            <Grid container item xs={12} className={classes.container}>
                {header}
                {body}
                {footer}
            </Grid>
            {showDeleteConfirmation && (
                <ConfirmDialog
                    open={true}
                    // eslint-disable-next-line
                    title={<Typography>{editPet!.name}</Typography>}
                    question={
                        <Typography>
                            Do you want to <strong>delete</strong> this
                            <br /> Pet?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => {
                        setShowDeleteConfirmation(false);
                        deleteHandler();
                    }}
                />
            )}
        </>
    );
};

export default AddPet;
