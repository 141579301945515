import { BusinessSettingsSidebarState, Action } from './BusinessSettingsSidebarState';

// Check if the current path contains the query parameter "?open=settings"
const queryParameter = window.location.search;
const isOpenBusinessSettingsSideBar = queryParameter.includes('open=settings');

export const initialState: BusinessSettingsSidebarState = {
    isOpenBusinessSettingsSideBar: isOpenBusinessSettingsSideBar,
};

export const BusinessSettingsSidebarReducer = (state: BusinessSettingsSidebarState = initialState, action: Action): BusinessSettingsSidebarState => {
    switch (action.type) {
        case 'OPEN_BUSINESS_SETTINGS_SIDEBAR':
            return { ...state, isOpenBusinessSettingsSideBar: true };
        case 'CLOSE_BUSINESS_SETTINGS_SIDEBAR':
            return { ...state, isOpenBusinessSettingsSideBar: false };
        case 'TOGGLE_BUSINESS_SETTINGS_SIDEBAR':
            return { ...state, isOpenBusinessSettingsSideBar: !state.isOpenBusinessSettingsSideBar };
        default:
            return state;
    }
};

export default BusinessSettingsSidebarReducer;
