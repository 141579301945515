import { DayHeaderContentArg } from '@fullcalendar/core';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTimeZone } from 'hooks';
import { FunctionComponent } from 'react';
import { getDay, getWeekdayName } from 'utils/DateUtils';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		headerWeekday: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down('sm')]: {
				fontSize: '8px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '10px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '15px'
			}
		},
		headerDay: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontFamily: 'Poppins',
			fontWeight: 400,
			color: '#222222',
			[theme.breakpoints.down('sm')]: {
				fontSize: '9px',
				width: '18px',
				height: '18px',
				borderRadius: '18px'
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '12px',
				width: '23px',
				height: '23px',
				borderRadius: '23px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '18px',
				width: '30px',
				height: '30px',
				borderRadius: '30px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '22px',
				width: '42px',
				height: '42px',
				borderRadius: '42px'
			}
		},
		headerToday: {
			backgroundColor: '#BDD2CA'
		}
	})
);

export const DayHeaderFullCalendar: FunctionComponent<
	DayHeaderContentArg
> = props => {
	const classes = useStyles();
	const timeZone = useTimeZone();

	return (
		<>
			<Box className={classes.headerWeekday}>
				{getWeekdayName(props.date, timeZone)}
			</Box>
			<Box
				className={clsx(classes.headerDay, {
					[classes.headerToday]: props.isToday
				})}
			>
				{getDay(props.date, timeZone)}
			</Box>
		</>
	);
};

export default DayHeaderFullCalendar;
