import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutThunk } from "actions/login/LoginActions";
import { cancelAllRequests } from "api/ApiClient";

export const Logout: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    cancelAllRequests();
    dispatch(logoutThunk());
  }, []);

  document.title = 'Log out - Cuddles';

  return null;
};

export default Logout;
