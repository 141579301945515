import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { ItemLabelIcon } from "components/UI/ItemLabelIcon";
import { PetVaccine } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import RawDateFormat from "components/UI/RawDateFormat";

interface VaccinesExpirationFieldProps {
  vaccine: PetVaccine;
  className?: string;
  preLabel?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      fontFamily: "Poppins",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(15)}px`,
        lineHeight: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "15px",
        lineHeight: "24px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "21px",
        color: "#000000"
      },
    },
    expLabel:{
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
        marginRight: "8px",
        fontSize: "13px",
        fontWeight: 400,
        color: "#707070",
        minWidth: "max-content",
      }
    },
    boxExp:{
      [theme.breakpoints.down("md")]: {
        display: "flex",
        alignItems: "center",
        width: "100%",
      }
    }
  })
);

export const VaccinesExpirationField: FunctionComponent<VaccinesExpirationFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(props.className)}>
      <Box className={classes.boxExp}>
        {props.preLabel && <span className={classes.expLabel}>{props.preLabel}</span>}

        <ItemLabelIcon
          label={
            props.vaccine.expiration?.isValid() ? (
              <>
                <RawDateFormat className={classes.date} date={props.vaccine.expiration} />
              </>
            ) : (
              <Box className={classes.date}>None</Box>
            )
          }
          icon={faCalendarAlt}
        />
      </Box>
    </Box>
  );
};

export default VaccinesExpirationField;
