import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { FieldError, PetTypeIds } from '@spike/model';
import { Pet } from '@spike/pet-model';
import clsx from 'clsx';
import {
    BreedSelector,
    CalendarField2,
    ControlledTextField2,
    PetColorField,
    PetGenderField,
    PetTypeField
} from 'components/UI';
import set from 'lodash/set';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import AgeField from './AgeField';
import PetHairLength from './PetHairLength';
import PetHairType from './PetHairType';
import PetPersonality from './PetPersonality';
import Section from './Section';
import ControlledWeightField2 from 'components/UI/ControlledWeightField2';
import { getSizeByWeight } from 'utils/WeightUtil';
import { useMarketplace, useMasterData } from 'hooks';
import PetSizes from 'components/Marketplace/BusinessSettings/PetTypes/UI/PetSizes';
import useNonInitialEffect from '@versiondos/hooks';

interface PetFormProps {
    pet: Pet;
    errors: Array<FieldError>;
    new?: boolean;
    disabled?: boolean;
    onChange?: (pet: Pet) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            background: '#FFFFFF',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(34)}px`,
                paddingBottom: `${reduceResolution(18)}px`,
                borderRadius: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '34px',
                paddingBottom: '18px',
                borderRadius: '24px'
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: '0px',
                paddingBottom: '15px',
                borderRadius: '0px'
            }
        },
        sectionMarginTop: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(27)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '27px'
            }
        },
        mobileDefaultField: {
            [theme.breakpoints.down('sm')]: {
                'marginBottom': '15px',
                '& #clients_input_pet_age': {
                    height: '50px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        gridContainer: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: '0px'
            }
        },
        fieldStandarized: {
            [theme.breakpoints.down('sm')]: {
                '& .MuiOutlinedInput-root': {
                    height: '50px',
                    background: '#FFFFFF'
                },
                '& #clients_input_pet_breed_header': {
                    'height': '50px',
                    '& > div:nth-child(2)': {
                        paddingRight: '15px'
                    }
                },
                '& svg': {
                    opacity: '1'
                },
                '& .MuiInputLabel-formControl': {
                    zIndex: '1'
                }
            }
        },
        fieldDropdownStandarized: {
            [theme.breakpoints.down('sm')]: {
                '& > div:nth-child(2)': {
                    'height': '50px',
                    '& > div': {
                        background: '#FFFFFF'
                    }
                },
                '& svg': {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                '& .MuiSelect-icon': {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                '& .MuiTypography-body1': {
                    fontSize: '16px'
                }
            }
        },
        fieldDropdownBithday: {
            [theme.breakpoints.down('sm')]: {
                '& > div:nth-child(2)': {
                    'height': '50px',
                    '& > div > div:nth-child(3)': {
                        paddingRight: '5px'
                    }
                },
                '& .MuiTypography-body1 + div': {
                    height: 'auto'
                },
                '& svg:not(.fa-circle-info)': {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                '& .MuiSelect-icon': {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                '& #clients_input_pet_name': {
                    paddingLeft: '27px'
                },
                '& .fa-calendar-days': {
                    opacity: '0',
                    marginLeft: '-13px'
                }
            }
        },
        sectionRadiobuttoms: {
            '& #clients_input_pet_hairtype label': {
                'width': '42%',
                '& span': {
                    fontSize: '15px',
                    fontWeight: '500'
                }
            },
            '& #clients_input_pet_hairlength label': {
                'width': '42%',
                '& span': {
                    fontSize: '15px',
                    fontWeight: '500'
                }
            }
        }
    })
);

const PetFormData: FunctionComponent<PetFormProps> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const marketplace = useMarketplace();
    const masterData = useMasterData();

    const [size, setSize] = useState(props.pet.size);

    useNonInitialEffect(() => {
        setSize(props.pet.size);
    }, [props.pet.size]);

    const changeValueHandler = (value: any, fieldName?: string) => {
        if (fieldName) {
            const updatedPet = { ...props.pet };
            set(updatedPet, fieldName, value);

            if (fieldName === 'type') {
                updatedPet.breed = undefined;
                updatedPet.color = undefined;
                updatedPet.hair = undefined;
                updatedPet.hairLength = undefined;

                switch (value.id) {
                    case PetTypeIds.CATS:
                        const [catSize] = (marketplace.petTypes.cats?.sizes ?? []).map(
                            orderedSize => orderedSize.element
                        );
                        updatedPet.size = catSize;
                        setSize(catSize);
                        break;
                    case PetTypeIds.EXOTICS:
                        const exoticSize = masterData.petSizes
                            .map(orderedSize => orderedSize.element)
                            .find(size => size.id === 'medium');
                        updatedPet.size = exoticSize;
                        setSize(exoticSize);
                        break;
                    default:
                        const dogSize = updatedPet?.weight
                            ? getSizeByWeight(
                                  updatedPet?.weight,
                                  marketplace.petTypes.dogs?.sizes ?? []
                              )
                            : undefined;
                        updatedPet.size = dogSize ? { ...dogSize } : undefined;
                        setSize(dogSize ? { ...dogSize } : undefined);
                }
            }

            if (fieldName === 'age') {
                updatedPet.birthdate = undefined;
            }

            if (fieldName === 'birthdate') {
                updatedPet.age = undefined;
            }

            if (fieldName === 'weight' && updatedPet.type?.id === PetTypeIds.DOGS) {
                const size = getSizeByWeight(value, marketplace.petTypes.dogs?.sizes ?? []);

                setSize(size ? { ...size } : undefined);
                set(updatedPet, 'size', size ? { ...size } : undefined);
            }

            props.onChange && props.onChange(updatedPet);
        }
    };

    return (
        <Grid className={classes.container} id="clients_div_pet_form">
            <Grid>
                <Grid>
                    <Grid className={classes.gridContainer} container xs={12} spacing={isSmall ? 0 : 4}>
                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <PetTypeField
                                name="type"
                                id="clients_input_pet_type"
                                selected={props.pet.type}
                                onChange={changeValueHandler}
                                required={true}
                                errors={props.errors}
                                disabled={props.disabled}
                                className={classes.fieldStandarized}
                            />
                        </Grid>
                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <ControlledTextField2
                                placeholder="Pet Name"
                                label="Pet Name"
                                name="name"
                                id="clients_input_pet_name"
                                value={props.pet.name}
                                onChange={changeValueHandler}
                                autoComplete="off"
                                required={true}
                                capitalize={true}
                                errors={props.errors}
                                maxLength={30}
                                className={classes.fieldStandarized}
                                allowSpecialCharacters={true}
                                withEmojis={true}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <ControlledWeightField2
                                placeholder="Pet Weight"
                                label="Pet Weight"
                                name="weight"
                                id="clients_input_pet_weight"
                                required={true}
                                value={props.pet.weight ? Math.trunc(props.pet.weight) : undefined}
                                onChange={changeValueHandler}
                                autoComplete="off"
                                errors={props.errors}
                                className={classes.fieldStandarized}
                                disabled={props.disabled}
                                allowEmptyValue={true}
                                petSizeName={size?.name}
                            />
                        </Grid>

                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <BreedSelector
                                petType={props.pet.type}
                                selected={props.pet.breed}
                                id="clients_input_pet_breed"
                                onChange={changeValueHandler}
                                name="breed"
                                errors={props.errors}
                                className={clsx(classes.fieldStandarized, classes.fieldDropdownStandarized)}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <PetGenderField
                                selected={props.pet.gender}
                                onChange={changeValueHandler}
                                name="gender"
                                id="clients_input_pet_gender"
                                errors={props.errors}
                                className={classes.fieldStandarized}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <PetColorField
                                petType={props.pet.type}
                                selected={props.pet.color}
                                name="color"
                                id="clients_input_pet_color"
                                onChange={changeValueHandler}
                                errors={props.errors}
                                className={classes.fieldStandarized}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid
                            className={classes.mobileDefaultField}
                            item
                            xs={12}
                            sm={12}
                            md={props.new ? 12 : 6}
                            lg={props.new ? 6 : 4}
                        >
                            <AgeField
                                age={props.pet.age}
                                birthdate={props.pet.birthdate}
                                name="age"
                                id="clients_input_pet_age"
                                errors={props.errors}
                                onChange={changeValueHandler}
                                className={clsx(classes.fieldStandarized, classes.fieldDropdownStandarized)}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={props.new ? 12 : 6} lg={props.new ? 6 : 4}>
                            <CalendarField2
                                label="Birthdate"
                                placeholder="Birthdate"
                                name="birthdate"
                                id="clients_input_pet_birthdate"
                                date={props.pet.birthdate}
                                onChange={changeValueHandler}
                                errors={props.errors}
                                information="If you enter the pet's birthday, Cuddles will update the age automatically."
                                className={clsx(classes.fieldStandarized, classes.fieldDropdownBithday)}
                                disabled={props.disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Section
                    title="Personality"
                    description="You can select more than one personality."
                    className={classes.sectionMarginTop}
                >
                    <PetPersonality
                        selectedPersonalities={[...props.pet.personalities]}
                        onChange={personalities => changeValueHandler(personalities, 'personalities')}
                        disabled={props.disabled}
                    />
                </Section>
            </Grid>
            {props.pet.type !== undefined && (
                <Grid item>
                    <Section title="Hair Type" className={clsx(classes.sectionMarginTop, classes.sectionRadiobuttoms)}>
                        <PetHairType
                            id="clients_input_pet_hairtype"
                            petType={props.pet.type}
                            petHairType={props.pet.hair}
                            name="hair"
                            errors={props.errors}
                            onChange={changeValueHandler}
                            disabled={props.disabled}
                        />
                    </Section>
                </Grid>
            )}
            {props.pet.type !== undefined && (
                <Grid item>
                    <Section
                        title="Hair Length"
                        className={clsx(classes.sectionMarginTop, classes.sectionRadiobuttoms)}
                    >
                        <PetHairLength
                            id="clients_input_pet_hairlength"
                            petType={props.pet.type}
                            petHairLength={props.pet.hairLength}
                            name="hairLength"
                            errors={props.errors}
                            onChange={changeValueHandler}
                            disabled={props.disabled}
                        />
                    </Section>
                </Grid>
            )}
            {/* <Grid item>
        <Section title="General Notes" className={classes.sectionMarginTop}>
          <DescriptionField2
            value={props.pet.groomingNotes}
            maxRows={4}
            minRows={4}
            placeholder="Anything important to note?"
            name="groomingNotes"
            onChange={changeValueHandler}
            errors={props.errors}
          />
        </Section>
      </Grid> */}
        </Grid>
    );
};

export default PetFormData;
