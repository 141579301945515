import React, { FunctionComponent, Fragment } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TableRow, TableCell, useTheme, useMediaQuery, Grid, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Booking from '@spike/booking-model';
import { DateTimeFormat, PetAvatar, PriceFormat, ShortDateTimeFormat } from 'components/UI';
import Staff from './Staff';
import PetTypeComponent from './PetTypeComponent';
import { BookingButton } from 'components/UI';
import { BookingOptions } from 'components/UI';
import { AvailableStaff } from '@spike/available-staff-model';
import { PetName } from './PetName';
import CustomerName from './CustomerName';

interface Props {
    booking: Booking;
    viewClient?: boolean;
    isDetail?: boolean;
    loading?: boolean;
    onShow: (bookindId: number, appointmentId: number) => void;
    onCancel: (appointmentId: number) => void;
    onConfirm: (bookingId: number) => void;
    onAssignStaff: (appointmentId: number, member: AvailableStaff | undefined) => void;
    onShowPet: (petId: number) => void;
    onShowClient: (clientId: number) => void;
    onReschedule: (appointmentId: number) => void;
    onNoShow: (appointmentId: number) => void;
    onShowReport?: () => void;
    onShowAddReport?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            'cursor': 'pointer',
            '&:hover': {
                backgroundColor: '#FAFAFA !important'
            }
        },
        noCell: {
            height: '0px',
            padding: '0px'
        },
        cell: {
            paddingLeft: '0px',
            borderBottom: 'none',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                paddingTop: '8px',
                paddingBottom: '8px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '13px',
                paddingTop: '13px',
                paddingBottom: '13px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '14px',
                paddingTop: '17px',
                paddingBottom: '17px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px',
                paddingTop: '26px',
                paddingBottom: '26px'
            }
        },
        firstCell: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '27px'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '43px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '57px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '87px'
            }
        },
        cellDate: {
            [theme.breakpoints.between(768, 1025)]: {
                width: '140px'
            }
        },
        lastRowCell: {
            borderBottom: '2px solid #EFEFEF'
        },
        mobileItemCell: {
            padding: '0px 0px 24px'
        },
        mobileItem: {
            borderRadius: 14,
            padding: '0px 16px',
            border: 'solid 1px #D4D4D4'
        },
        mobileItemHeader: {
            gap: 10,
            display: 'flex',
            flexWrap: 'wrap',
            padding: '14px 0px 11px',
            justifyContent: 'space-between'
        },
        mobileItemPet: {
            gap: 6,
            flex: 1,
            display: 'flex',
            alignItems: 'center'
        },
        mobileItemAvatar: {
            'width': 44,
            'height': 44,
            'flex': '0 0 44px',

            '& svg': {
                width: '27px !important',
                height: '27px !important'
            }
        },
        mobileItemPetBreed: {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 400,
            color: '#707070',
            marginTop: 4
        },
        mobileItemDate: {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 500,
            paddingTop: 6
        },
        mobileItemBody: {
            gap: 6,
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 0px',
            borderTop: 'solid 1px #D4D4D4',
            borderBottom: 'solid 1px #D4D4D4'
        },
        mobileItemService: {
            flex: 1,
            fontSize: 14,
            lineHeight: 1.4,
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        mobileItemFooter: {
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 600,
            padding: '20px 0px'
        },
        mobileItemButton: {
            'height': 40,
            'width': '100%',

            '& span': {
                fontSize: '14px !important',
                fontWeight: '600 !important'
            }
        }
    })
);

export const BookingRow: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up(1025));
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    const booking = {
        ...props.booking,
        appointments: props.booking.appointments.filter(appointment => appointment.status.name !== 'Canceled')
    } as Booking;

    const camelize = (str: string) => {
        return str.replace(/\b(\w)/g, s => s.toUpperCase());
    };

    const singleClientAppointment = (
        <TableRow className={classes.row}>
            <TableCell
                className={clsx(classes.cell, classes.firstCell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                {booking.appointments[0].services[0].name}
            </TableCell>
            <TableCell className={clsx(classes.cell, classes.lastRowCell)}>
                <PetName
                    name={booking.appointments[0].pet.name}
                    hasMedicalConditions={booking.appointments[0].pet.hasMedicalConditions}
                    onClick={() => props.onShowPet(booking.appointments[0].pet.id)}
                />
            </TableCell>
            <TableCell
                className={clsx(classes.cell, classes.cellDate, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <DateTimeFormat date={booking.appointments[0].duration.from} />
            </TableCell>
            <TableCell
                className={clsx(classes.cell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <Staff appointment={booking.appointments[0]} onAssignStaff={props.onAssignStaff} />
            </TableCell>
            {isDesktop ? (
                <>
                    <TableCell
                        className={clsx(classes.cell, classes.lastRowCell)}
                        onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
                    >
                        <PriceFormat price={booking.appointments[0].total} />
                    </TableCell>
                </>
            ) : null}
            <TableCell
                className={clsx(classes.cell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <BookingButton
                    loading={props.loading}
                    onConfirm={() => props.onConfirm(props.booking.id!)}
                    id="booking_table"
                />
            </TableCell>
            {isDesktop ? (
                <TableCell className={clsx(classes.cell, classes.lastRowCell)}>
                    <BookingOptions
                        isDetail={props.isDetail}
                        booking={props.booking}
                        customerDeleted={props.booking?.customer?.deleted}
                        onReschedule={() => props.onReschedule(props.booking.id!)}
                        onCancel={() => props.onCancel(props.booking.id!)}
                        onNoShow={() => props.onNoShow(props.booking.id!)}
                        onShowAddReport={props.onShowAddReport}
                        onShowReport={props.onShowReport}
                    />
                </TableCell>
            ) : null}
            <TableCell className={clsx(classes.cell)}></TableCell>
        </TableRow>
    );

    const singleAppointment = (
        <TableRow className={classes.row}>
            <TableCell
                className={clsx(classes.cell, classes.firstCell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <CustomerName
                    name={props.booking.customer.firstName.concat(' ').concat(props.booking.customer.lastName)}
                    hasMedicalConditions={false}
                    onClick={() => props.onShowClient(props.booking.customer.id)}
                />
            </TableCell>
            <TableCell className={clsx(classes.cell, classes.lastRowCell)}>
                <PetName
                    name={booking.appointments[0].pet.name}
                    hasMedicalConditions={booking.appointments[0].pet.hasMedicalConditions}
                    onClick={() => props.onShowPet(booking.appointments[0].pet.id)}
                />
            </TableCell>
            {isDesktop ? (
                <>
                    <TableCell
                        className={clsx(classes.cell, classes.lastRowCell)}
                        onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
                    >
                        {booking.appointments[0].pet.breed.name}
                    </TableCell>
                </>
            ) : null}
            <TableCell
                className={clsx(classes.cell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <PetTypeComponent petType={booking.appointments[0].pet.type} />
            </TableCell>
            <TableCell
                className={clsx(classes.cell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                {booking.appointments[0].services[0].name}
            </TableCell>
            <TableCell
                className={clsx(classes.cell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <Staff appointment={booking.appointments[0]} onAssignStaff={props.onAssignStaff} />
            </TableCell>
            <TableCell
                className={clsx(classes.cell, classes.cellDate, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <DateTimeFormat date={booking.appointments[0].duration.from} />
            </TableCell>
            {isDesktop ? (
                <>
                    <TableCell
                        className={clsx(classes.cell, classes.lastRowCell)}
                        onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
                    >
                        <PriceFormat price={booking.appointments[0].total} />
                    </TableCell>
                </>
            ) : null}
            <TableCell
                className={clsx(classes.cell, classes.lastRowCell)}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <BookingButton
                    loading={props.loading}
                    onConfirm={() => props.onConfirm(props.booking.id!)}
                    id="booking_table"
                />
            </TableCell>
            {isDesktop ? (
                <>
                    <TableCell className={clsx(classes.cell, classes.lastRowCell)}>
                        <BookingOptions
                            isDetail={props.isDetail}
                            booking={props.booking}
                            customerDeleted={props.booking?.customer?.deleted}
                            onReschedule={() => props.onReschedule(props.booking.id!)}
                            onCancel={() => props.onCancel(props.booking.id!)}
                            onNoShow={() => props.onNoShow(props.booking.id!)}
                            onShowAddReport={props.onShowAddReport}
                            onShowReport={props.onShowReport}
                        />
                    </TableCell>
                </>
            ) : null}
        </TableRow>
    );

    const multipleAppointments = (
        <Fragment>
            <TableRow>
                <TableCell
                    className={clsx(classes.cell, classes.firstCell, classes.lastRowCell)}
                    rowSpan={booking.appointments.length + 1}
                    onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
                >{`${props.booking.customer.firstName} ${props.booking.customer.lastName}`}</TableCell>
                {isDesktop ? (
                    <>
                        <TableCell className={classes.noCell}></TableCell>
                        <TableCell className={classes.noCell}></TableCell>
                    </>
                ) : null}
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell
                    className={clsx(classes.cell, classes.lastRowCell)}
                    rowSpan={booking.appointments.length + 1}
                    onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
                >
                    <BookingButton
                        loading={props.loading}
                        onConfirm={() => props.onConfirm(props.booking.id!)}
                        id="booking_table"
                    />
                </TableCell>
                {isDesktop ? (
                    <TableCell
                        className={clsx(classes.cell, classes.lastRowCell)}
                        rowSpan={booking.appointments.length + 1}
                    >
                        <BookingOptions
                            isDetail={props.isDetail}
                            withoutReschedule={true}
                            customerDeleted={props.booking?.customer?.deleted}
                            onReschedule={() => props.onReschedule(props.booking.id!)}
                            onCancel={() => props.onCancel(props.booking.id!)}
                            onNoShow={() => props.onNoShow(props.booking.id!)}
                        />
                    </TableCell>
                ) : null}
            </TableRow>

            {booking.appointments.map((appointment, index) => (
                <TableRow key={appointment.uuid} className={classes.row}>
                    <TableCell
                        className={clsx(classes.cell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                    >
                        <PetName
                            name={appointment.pet.name}
                            hasMedicalConditions={appointment.pet.hasMedicalConditions}
                            onClick={() => props.onShowPet(appointment.pet.id)}
                        />
                    </TableCell>
                    {isDesktop ? (
                        <>
                            <TableCell
                                className={clsx(classes.cell, {
                                    [classes.lastRowCell]: index + 1 === booking.appointments.length
                                })}
                                onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                            >
                                {appointment.pet.breed.name}
                            </TableCell>
                        </>
                    ) : null}
                    <TableCell
                        className={clsx(classes.cell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        <PetTypeComponent petType={appointment.pet.type} />
                    </TableCell>
                    <TableCell
                        className={clsx(classes.cell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        {appointment.services[0].name}
                    </TableCell>
                    <TableCell
                        className={clsx(classes.cell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        <Staff appointment={appointment} onAssignStaff={props.onAssignStaff} />
                    </TableCell>
                    <TableCell
                        className={clsx(classes.cell, classes.cellDate, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        <DateTimeFormat date={appointment.duration.from} />
                    </TableCell>
                    {isDesktop ? (
                        <>
                            <TableCell
                                className={clsx(classes.cell, {
                                    [classes.lastRowCell]: index + 1 === booking.appointments.length
                                })}
                                onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                            >
                                <PriceFormat price={appointment.total} />
                            </TableCell>
                        </>
                    ) : null}
                </TableRow>
            ))}
        </Fragment>
    );

    const multipleClientAppointments = (
        <Fragment>
            <TableRow>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell className={classes.noCell}></TableCell>
                <TableCell
                    className={clsx(classes.cell, classes.lastRowCell)}
                    rowSpan={booking.appointments.length + 1}
                    onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
                >
                    <BookingButton
                        loading={props.loading}
                        onConfirm={() => props.onConfirm(props.booking.id!)}
                        id="booking_table"
                    />
                </TableCell>
                {isDesktop ? (
                    <>
                        <TableCell
                            className={clsx(classes.cell, classes.lastRowCell)}
                            rowSpan={booking.appointments.length + 1}
                        >
                            <BookingOptions
                                isDetail={props.isDetail}
                                withoutReschedule={true}
                                customerDeleted={props.booking?.customer?.deleted}
                                onReschedule={() => props.onReschedule(props.booking.id!)}
                                onCancel={() => props.onCancel(props.booking.id!)}
                                onNoShow={() => props.onNoShow(props.booking.id!)}
                            />
                        </TableCell>
                    </>
                ) : null}
            </TableRow>

            {booking.appointments.map((appointment, index) => (
                <TableRow key={appointment.uuid} className={classes.row}>
                    <TableCell
                        className={clsx(classes.cell, classes.firstCell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        {appointment.services[0].name}
                    </TableCell>
                    <TableCell
                        className={clsx(classes.cell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                    >
                        <PetName
                            name={appointment.pet.name}
                            hasMedicalConditions={appointment.pet.hasMedicalConditions}
                            onClick={() => props.onShowPet(appointment.pet.id)}
                        />
                    </TableCell>
                    <TableCell
                        className={clsx(classes.cell, classes.cellDate, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        <DateTimeFormat date={appointment.duration.from} />
                    </TableCell>
                    <TableCell
                        className={clsx(classes.cell, {
                            [classes.lastRowCell]: index + 1 === booking.appointments.length
                        })}
                        onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                    >
                        <Staff appointment={appointment} onAssignStaff={props.onAssignStaff} />
                    </TableCell>
                    {isDesktop ? (
                        <>
                            <TableCell
                                className={clsx(classes.cell, {
                                    [classes.lastRowCell]: index + 1 === booking.appointments.length
                                })}
                                onClick={() => props.onShow(props.booking.id!, appointment.id!)}
                            >
                                <PriceFormat price={appointment.total} />
                            </TableCell>
                        </>
                    ) : null}
                </TableRow>
            ))}
        </Fragment>
    );

    const mobileSingleAppointmentItem = (
        <TableRow>
            <TableCell
                className={classes.mobileItemCell}
                onClick={() => props.onShow(props.booking.id!, booking.appointments[0].id!)}
            >
                <Grid container className={classes.mobileItem}>
                    <Grid item xs={12} className={classes.mobileItemHeader}>
                        <Box className={classes.mobileItemPet}>
                            <PetAvatar
                                petType={booking.appointments[0].pet.type}
                                imageUrl={booking.appointments[0].pet.image}
                                className={classes.mobileItemAvatar}
                            />

                            <Box>
                                <PetName
                                    bold={true}
                                    name={booking.appointments[0].pet?.name}
                                    hasMedicalConditions={booking.appointments[0].pet.hasMedicalConditions}
                                    onClick={() => props.onShowPet(booking.appointments[0].pet.id)}
                                />
                                <Typography className={classes.mobileItemPetBreed}>
                                    {booking.appointments[0].pet.breed.name}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={classes.mobileItemDate}>
                            <ShortDateTimeFormat date={booking.appointments[0].duration.from} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.mobileItemBody}>
                        <Typography className={classes.mobileItemService}>
                            {booking.appointments[0].services[0].name}
                        </Typography>

                        <Staff appointment={booking.appointments[0]} onAssignStaff={props.onAssignStaff} />
                    </Grid>
                    <Grid item xs={12} className={classes.mobileItemFooter}>
                        <BookingButton
                            loading={props.loading}
                            className={classes.mobileItemButton}
                            onConfirm={() => props.onConfirm(props.booking.id!)}
                            id="booking_table"
                        />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    const mobileMultipleAppointmentItem = (
        <>
            <TableRow>
                <TableCell className={classes.mobileItemCell}>
                    <Grid container className={classes.mobileItem}>
                        {booking.appointments.map(appointment => (
                            <>
                                <Grid item xs={12} className={classes.mobileItemHeader}>
                                    <Box className={classes.mobileItemPet}>
                                        <PetAvatar
                                            petType={appointment.pet.type}
                                            imageUrl={appointment.pet.image}
                                            className={classes.mobileItemAvatar}
                                        />

                                        <Box>
                                            <PetName
                                                bold={true}
                                                name={appointment.pet?.name}
                                                hasMedicalConditions={appointment.pet.hasMedicalConditions}
                                                onClick={() => props.onShowPet(appointment.pet.id)}
                                            />
                                            <Typography className={classes.mobileItemPetBreed}>
                                                {appointment.pet.breed.name}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box className={classes.mobileItemDate}>
                                        <ShortDateTimeFormat date={appointment.duration.from} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className={classes.mobileItemBody}>
                                    <Typography className={classes.mobileItemService}>
                                        {appointment.services[0].name}
                                    </Typography>

                                    <Staff appointment={appointment} onAssignStaff={props.onAssignStaff} />
                                </Grid>
                            </>
                        ))}

                        <Grid item xs={12} className={classes.mobileItemFooter}>
                            <BookingButton
                                loading={props.loading}
                                className={classes.mobileItemButton}
                                onConfirm={() => props.onConfirm(booking.id!)}
                                id="booking_table"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );

    const getResultView = () => {
        if (booking.appointments.length === 1) {
            if (props.viewClient) {
                return isMobile ? mobileSingleAppointmentItem : singleClientAppointment;
            } else {
                return isMobile ? mobileSingleAppointmentItem : singleAppointment;
            }
        } else {
            if (props.viewClient) {
                return isMobile ? mobileSingleAppointmentItem : multipleClientAppointments;
            } else {
                return isMobile ? mobileMultipleAppointmentItem : multipleAppointments;
            }
        }
    };

    return getResultView();
};

export default BookingRow;
