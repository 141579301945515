import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import { useCommonStyles } from "../../CommonStyles";
import { useNonInitialEffect } from "@versiondos/hooks";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTerminalsThunk,
  getTerminalThunk,
  saveTerminalThunk
} from "@spike/terminals-action";

import { Button } from "components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import Title from "../../ui/Title";
import { TextField2 } from "components/UI";
import { Terminal, TerminalModel } from "@spike/terminal-model";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { useApiClientWrapper, useMarketplace, useMasterData } from "hooks";
import { TerminalImage } from "../UI/TerminalImage";
import { RootState } from "store";
import { TerminalsStatus } from "@spike/terminals-action";
import { ZendeskAPI } from "react-zendesk";
import TerminalActivate from "../UI/TerminalActivate";
interface TerminalDetailProps {
  terminal: Terminal;
  className?: string;
  //
  onClose?: () => void;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseContainer: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(0)}px ${reduceResolution(70)}px 0 ${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0 70px 0 30px",
      },
    },

    boxTerminals: {
      height: "100%",
    },

    boxHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(40)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "40px 0",
      },
    },

    boxContent: {
      background: "#FAFAFA",
      height: "100%",
    },

    contentContainer: {
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(50)}px 0`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "50px 0",
      },

    },

    boxTitle: {
      display: "flex",
      alignItems: "center",
    },

    left: {
      display: "flex",
      justifyContent: "flex-start",
    },

    right: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },

    boxTerminalImage: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(30)}px`,

        width: `${reduceResolution(50)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "30px",
        width: "50px",
      },
    },

    boxSerial: {

    },
    backIcon: {
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(27)}px`,
        paddingRight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "27px",
        paddingRight: "20px",
      },
      "&:hover": {
        cursor: "pointer",
      }
    },

    buttonHelp: {
      marginRight: "10px",
    },

    typoSerial: {
      paddingRight: "2px",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(80)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "80px",
      },
    },

    typoLocation: {
      paddingRight: "2px",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(75)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "75px",
      },
    },
    informationContent: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(40)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "40px",
      },
    }
  })
);

export const TerminalDetail: FunctionComponent<TerminalDetailProps> = (props) => {
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const terminalCommonClasses = useTerminalCommonStyles();
  const marketplace = useMarketplace();
  const masterData = useMasterData();
  const businessName = marketplace.basics.businessName;

  const modelsTerminal: Array<TerminalModel> = masterData.terminalsModels;
  const model = modelsTerminal.find((m) => m.id.toUpperCase() === "S1E");

  const terminalsStatus = useSelector<RootState, TerminalsStatus>((state) => state.terminals.status);
  const terminal = useSelector<RootState, Terminal | undefined>((state) => state.terminals.terminal);

  const [active, setActive] = useState<boolean>(props.terminal.active);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.terminal.name);
  const [editedTerminal, setEditedTerminal] = useState<Terminal>(props.terminal);

  useEffect(() => {
    switch (terminalsStatus) {
      case TerminalsStatus.SaveTerminalSuccess:
        dispatch(fetchTerminalsThunk(apiClientWrapper));
        dispatch(getTerminalThunk(apiClientWrapper, editedTerminal.id!));
        setLoadingButton(false);
        break;
      case TerminalsStatus.GetTerminalSuccess:
        terminal && setEditedTerminal(terminal);
        break;
      default:
        break;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminalsStatus])

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      dispatch(saveTerminalThunk(apiClientWrapper, { ...editedTerminal, name }));
    }, 1000);
    return () => clearTimeout(timer);
  }, [name]);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      dispatch(saveTerminalThunk(apiClientWrapper, { ...editedTerminal, active }));
    }, 1000);
    return () => clearTimeout(timer);
  }, [active]);

  const contactUsHandler = () => {
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');
  };

  const changeNameHandler = (name: string) => {
    setName(name.trimEnd());
  };

  const activeTerminalHandler = (active: boolean) => {
    setLoadingButton(true);
    setActive(active);
  };

  const backHandler = () => {
    props.onClose && props.onClose()
  };


  const header = (
    <Box className={classes.boxHeader}>
      <Box className={classes.boxTitle}>
        <Box onClick={backHandler}>
          <FontAwesomeIcon
            className={classes.backIcon}
            icon={faArrowLeft}
            size="4x"
          />
        </Box>
        <Title text={name} />
      </Box>
      <Box className={classes.right}>
        <Button className={classes.buttonHelp} label={"Need Help?"} variant={"secondary"} color={"green"} onClick={contactUsHandler} />
        {!editedTerminal.active
          &&
          <Button
            label={"Enable"}
            variant={"primary"}
            color={"green"}
            onClick={() => activeTerminalHandler(true)}
            loading={loadingButton} />}

        {editedTerminal.active
          &&
          <Button
            label={"Disable"}
            variant={"danger"}
            onClick={() => activeTerminalHandler(false)}
            loading={loadingButton}
          />}
      </Box>
    </Box>
  );

  const content = (
    model &&
    <Box className={classes.contentContainer}>
      <Grid container>
        <Grid item xs={3}>
          <Box>
            <Typography className={terminalCommonClasses.typo20_600}>Terminal Name</Typography>
            <Typography className={terminalCommonClasses.typo15_400}>Change the name to identify the terminal.</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={8}>
          <Box className={terminalCommonClasses.boxRadiusWhite}>
            <TextField2
              label="Terminal Name"
              placeholder="Terminal Name"
              name="terminalName"
              value={editedTerminal.name}
              onChange={changeNameHandler}
              required={true}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container className={classes.informationContent}>
        <Grid item xs={3}>
          <Box>
            <Typography className={terminalCommonClasses.typo20_600}>Terminal Information</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={8}>
          <Box className={terminalCommonClasses.boxRadiusWhite}>
            <Grid container>
              <Grid item xs={10}>
                <Box className={terminalCommonClasses.flexRow}>
                  <Box className={classes.boxTerminalImage}>
                    <TerminalImage id={model!.id} />
                  </Box>
                  <Box>
                    <Typography className={terminalCommonClasses.typo15_400_orange}>{model?.type}</Typography>
                    <Typography className={terminalCommonClasses.typo16_600}>Cuddles {editedTerminal.model.toUpperCase()}</Typography>
                    <Box className={clsx(terminalCommonClasses.flexRow, classes.boxSerial)}>
                      <Typography className={clsx(terminalCommonClasses.typo15_500, classes.typoSerial)}>Serial #:</Typography>
                      <Typography className={terminalCommonClasses.typo15_400}>{editedTerminal.serial}</Typography>
                    </Box>
                    <Box className={terminalCommonClasses.flexRow}>
                      <Typography className={clsx(terminalCommonClasses.typo15_500, classes.typoLocation)}>Location:</Typography>
                      <Typography className={terminalCommonClasses.typo15_400}>{businessName}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}><TerminalActivate activate={editedTerminal.active} /></Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Fragment>
      <Box className={clsx(commonClasses.container, props.className, classes.boxTerminals)}>
        <Box className={classes.baseContainer}>
          {header}
        </Box>
        <Box className={classes.boxContent}>
          <Box className={clsx(classes.baseContainer)}>
            {content}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default TerminalDetail;
