import { Tip as InvoiceTip } from "@spike/invoice-model";
import { TipOption } from "./TipModel";
import { CheckoutTip, createNotSelectedTip } from "./TipModel";
import toNumber from 'lodash/toNumber';

export const isNoInvoiceTip = (tip: InvoiceTip | null): boolean =>
  tip === null || tip.amount === null;

export const isDefaultCheckoutTip = (tip: CheckoutTip | null): boolean =>
  tip !== null && tip.default !== null;

export const isNoCheckoutTip = (tip: CheckoutTip | null): boolean =>
  tip !== null && (tip.customTip !== null && Number(tip.customTip.amount) === 0);

export const isCustomCheckoutTip = (tip: CheckoutTip | null): boolean =>
  tip !== null && (tip.customTip?.amount !== null && Number(tip.customTip?.amount) !== 0);

export const convertToCheckoutTip = (invoiceTip: InvoiceTip | null, defaultTips: Array<TipOption>): CheckoutTip => {

  if (invoiceTip === null || invoiceTip.amount === null) {
    return createNotSelectedTip();
  } else {
    const defaultTip = defaultTips.find(
      (dt) => (invoiceTip.percentage !== null && Number(dt.percentage) === Number(invoiceTip.percentage)) || Number(dt.amount) === Number(invoiceTip.amount)
    );

    return defaultTip
      ? {
          default: { ...defaultTip },
          customTip: null
        }
      : {
          default: null,
          customTip: {...invoiceTip},
        };
  }
};

export const convertToInvoiceTip = (checkoutTip: CheckoutTip | null): InvoiceTip | null => {
  if (checkoutTip === null) {
    return null;
  } else if (checkoutTip.default) {
    return {
      amount: checkoutTip.default.amount,
      percentage: checkoutTip.default.percentage,
    };
  } else {
    return checkoutTip.customTip === null ? null : {...checkoutTip.customTip};
  }
};

const convertToNumber = (value: string | null | undefined): number | null | undefined => {
	if(value === null || value === undefined) {
		return value;
	} else {
		return toNumber(value);
	}
}

export const isSameTip = (invoiceTip: InvoiceTip | null | undefined, tip: InvoiceTip | null | undefined): boolean => {
	if(invoiceTip === undefined && tip === undefined) {
		return true;
	} else if(invoiceTip === null && tip === null) {
		return true;
	} else if(invoiceTip?.percentage === null && convertToNumber(invoiceTip?.amount) === convertToNumber(tip?.amount)) {
		return true;
	} else if(invoiceTip?.percentage !== null && convertToNumber(invoiceTip?.percentage) === convertToNumber(tip?.percentage)) {		
		return true;
	} else {
		return false;
	}
}
