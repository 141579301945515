import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { Button, PasswordField } from "components/UI";
import { FieldError } from "@spike/model";
import { useNonInitialEffect } from "@versiondos/hooks";
import { UsersStatus } from "reducers/users/UsersState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  onSave: (oldPassword: string, newPassword: string, confirmNewPassword: string) => void;
  className?: string;
  openSidebar?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.only("md")]: {
        width: "100%",
      },
      [theme.breakpoints.only("lg")]: {
        width: "368px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "558px",
      },
    },
    titleContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "8px",
      }
    },
    title: {
      fontWeight: 600,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "16px",
        lineHeight: "21px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "21px",
        lineHeight: "28px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "32px",
        lineHeight: "42px",
      },
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "14px",
        marginLeft: "-8px"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "23px",
        marginLeft: "-12px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "31px",
        marginLeft: "-17px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "47px",
        marginLeft: "-25px"
      },
    },
    content: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "21px",
        marginLeft: "0px",
        "& input": {
          paddingLeft: "27px",
        },
        "& input + div > div": {
          display: "none",
        }
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "35px",
        marginLeft: "0px"
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "46px",
        marginLeft: "-17px"
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "70px",
        marginLeft: "-25px"
      },
    },
    updateContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "50px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "76px",
      },
    },
    updateTitle: {
      fontWeight: 300,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 400,
        marginLeft: "18px",
        marginBottom: "10px"
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 400,
        marginLeft: "15px",
        marginBottom: "12px"
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "8px",
        lineHeight: "20px",
        marginLeft: "17px",
        marginBottom: "16px"
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "12px",
        lineHeight: "30px",
        marginLeft: "25px",
        marginBottom: "24px"
      },
    },
    updateTitleConfirm: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      }
    },
    newPassword: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "26px",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "34px",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "52px",
      },
    },
    iconTitle: {
      display: 'none',
      [theme.breakpoints.down("sm")]: {
        display: 'inline-flex',
        marginRight: '10px',
        fontSize: '18px',
      }
    },
    buttonContainerFull: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "absolute",
        bottom: "25px",
        left: 0,
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "50px",
        "& button": {
          width: "100%",
          height: "50px",
          borderRadius: "31px",
          "& .MuiButton-label": {
            fontSize: "18px"
          }
        },
        "@supports (-webkit-touch-callout: none)": {
          paddingBottom: "80px"
        },
      },
    },
  })
);

export const Security: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const loading = useSelector<RootState, boolean>(state => state.users.loading);
  const status = useSelector<RootState, UsersStatus>(state => state.users.status);
  const [saved, setSaved] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<Array<FieldError>>([]);

  useNonInitialEffect(() => {
    setSaved(false);
  },[currentPassword, newPassword, confirmPassword, ]);

  useNonInitialEffect(() => {
    if(status === UsersStatus.ChangePasswordSuccess) {
      setSaved(true);
    }
  },[status]);

  const validate = () => {
    let errorAux = [];
    if (currentPassword.length === 0) {
      const error = {fieldName: "Current Password", errorMessage: "Current Password is required"};
      errorAux.push(error);
    }

    if (newPassword.length === 0) {
      const error = {fieldName: "New Password", errorMessage: "New Password is required"};
      errorAux.push(error);
    } else if (currentPassword === newPassword) {
      const error = {fieldName: "New Password", errorMessage: "New Password is incorrect"};
      errorAux.push(error);
    }

    if (confirmPassword.length === 0) {
      const error = {fieldName: "Confirm Password", errorMessage: "Confirm Password is required"};
      errorAux.push(error);
    } else if (confirmPassword !== newPassword) {
      const error = {fieldName: "Confirm Password", errorMessage: "Confirm Password is incorrect"};
      errorAux.push(error);
    }
    setErrors(errorAux);
    return errorAux;
  };

  const clickButtonHandler = () => {
    const validationErrors = validate();
    if (validationErrors.length === 0) {
      props.onSave(currentPassword, newPassword, confirmPassword);
    }
  };

  const handlePasswordError = (fieldName: string) => {
    const error = errors.find((e) => e.fieldName === fieldName);
    return !isEmpty(error);
  };

  const handlePasswordErrorMessage = (fieldName: string) => {
    const error = errors.find((e) => e.fieldName === fieldName);
    return error?.errorMessage;
  };

  const button = (<Button label={saved ? "Saved!" : "Save"} onClick={clickButtonHandler} loading={loading} />);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>
          <FontAwesomeIcon
            className={classes.iconTitle}
            onClick={props.openSidebar}
            icon={faArrowLeft}
            size="2x"
          />
          Security
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.updateTitle}>Current Password</Typography>
        <PasswordField
          label="Current Password"
          putLabelToPlaceholder={true}
          value={currentPassword}
          onChange={setCurrentPassword}
          error={handlePasswordError("Current Password")}
          errorMessage={handlePasswordErrorMessage("Current Password")}
        />
        <Box className={classes.updateContainer}>
          <Typography className={classes.updateTitle}>New Password</Typography>
          <Box className={classes.newPassword}>
            <PasswordField
              label="New Password"
              putLabelToPlaceholder={true}
              value={newPassword}
              onChange={setNewPassword}
              error={handlePasswordError("New Password")}
              errorMessage={handlePasswordErrorMessage("New Password")}
            />
          </Box>
          <Typography className={clsx(classes.updateTitle, classes.updateTitleConfirm)}>Confirm Password</Typography>
          <Box>
            <PasswordField
              label="Confirm Password"
              putLabelToPlaceholder={true}
              value={confirmPassword}
              onChange={setConfirmPassword}
              error={handlePasswordError("Confirm Password")}
              errorMessage={handlePasswordErrorMessage("Confirm Password")}
            />
          </Box>
        </Box>
      </Box>
      <Box className={clsx(classes.buttonContainer,classes.buttonContainerFull)}>
        {button}
      </Box>
    </Box>
  );
};

export default Security;
