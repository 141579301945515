import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@material-ui/core';
import { status } from '@spike/appointment-model';
import clsx from 'clsx';
import React from 'react';

interface NotificationRowRequestStatusProps {
    bookingStatus?: number;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: 4
        },
        badge: {
            gap: 5,
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 30,
            padding: '3.5px 10px',
            display: 'flex',
            alignItems: 'center'
        },
        bookedStatus: {
            color: '#5E8677',
            backgroundColor: '#E9F0ED'
        },
        declinedStatus: {
            color: '#CC444B',
            backgroundColor: '#FFE5E7'
        }
    })
);

const NotificationRowRequestStatus: React.FC<
    NotificationRowRequestStatusProps
> = props => {
    const classes = useStyles();

    const isApproved =
        props.bookingStatus !== status.REQUESTED &&
        props.bookingStatus !== status.DECLINED;

    const isDeclined = props.bookingStatus === status.DECLINED;

    return (
        <div className={classes.root}>
            {isApproved && (
                <div className={clsx(classes.badge, classes.bookedStatus)}>
                    <FontAwesomeIcon icon={faCheck} />
                    Accepted
                </div>
            )}

            {isDeclined && (
                <div className={clsx(classes.badge, classes.declinedStatus)}>
                    <FontAwesomeIcon icon={faTimes} />
                    Declined
                </div>
            )}
        </div>
    );
};

export default NotificationRowRequestStatus;
