import {
    faFilePdf,
    faFileSignature,
    faPrint
} from '@fortawesome/pro-regular-svg-icons';
import {
    Box,
    Container,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';
import {
    downloadSignaturePDFThunk,
    printSignatureThunk,
    getSignatureByHashThunk,
    signThunk
} from 'actions/agreements/AgreementsActions';
import { formatDate } from 'components/Agreements/utils';
import { Button, Spinner } from 'components/UI';
import IconCircle from 'components/UI/IconCircle';
import QuillContent from 'components/UI/QuillContent';
import SignPad from 'components/UI/SignPad';
import { Signature } from 'model/Agreements';
import moment from 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { RootState } from 'store';
import OptionsModal from './Modal/OptionsModal';
import { DropdownOption } from './ui/Dropdown/model';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash';
interface AgreementsSignatureProps {
    hash: string;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            transition: 'all 0.33s linear',
            paddingBottom: 50,
            width: '100%',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 15
            }
        },
        header: {
            height: 56,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff'
        },
        logo: {
            height: 35
        },
        headerBtns: {
            'marginLeft': 'auto',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },

            '& button': {
                'height': 39,
                'marginRight': 10,
                'padding': '0px 12px',
                'display': 'inline-block',

                '& span': {
                    fontSize: 14,
                    marginLeft: 0,
                    marginRight: 4
                },
                '& svg': {
                    width: 14,
                    height: 14,
                    verticalAlign: 'middle'
                }
            }
        },
        headerDropdown: {
            display: 'none',
            marginLeft: 'auto',
            position: 'relative',

            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        dropdownIcon: {
            padding: 5,
            cursor: 'pointer',
            verticalAlign: 'middle'
        },
        dropdown: {
            top: 25,
            right: 0,
            width: 160,
            borderRadius: 14,
            padding: '10px 0px',
            position: 'absolute',
            backgroundColor: '#fff',
            border: 'solid 1px #000'
        },
        dropdownItem: {
            'width': '100%',
            'color': '#222',
            'fontSize': 14,
            'fontWeight': 500,
            'lineHeight': 1.95,
            'cursor': 'pointer',
            'margin': '2px 0px',
            'padding': '0px 20px',
            'display': 'inline-block',

            '& svg': {
                marginRight: 6,
                verticalAlign: 'middle'
            },

            '&:hover': {
                backgroundColor: '#FAFAFA'
            }
        },
        printBtn: {
            'color': '#222',
            'padding': '5px 16px',
            'backgroundColor': '#fff',
            'border': 'solid 2px #222',

            '&:hover': {
                color: '#fff',
                backgroundColor: '#222'
            }
        },
        title: {
            color: '#222',
            width: '100%',
            fontSize: 32,
            lineHeight: 1,
            fontWeight: 600,
            padding: '75px 0px',
            textAlign: 'center',
            backgroundColor: '#E4DDD5',

            [theme.breakpoints.down('md')]: {
                fontSize: 22,
                padding: '45px 0px'
            }
        },
        content: {
            paddingLeft: 0,
            paddingRight: 0
        },
        body: {
            'paddingTop': 50,
            'flex': 'auto',
            'display': 'flex',
            'flexGrow': 1,
            '& hr': {
                border: 'none',
                margin: '35px 0px',
                borderTop: 'solid 1px #D4D4D4'
            }
        },
        info: {
            'marginBottom': 6,

            '& label': {
                margin: 0,
                fontWeight: 600
            }
        },
        sign: {
            'fontSize': 16,
            'lineHeight': 1.4,
            'paddingTop': 50,
            '& label': {
                marginBottom: 15
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        footer: {
            'paddingTop': 50,
            'textAlign': 'right',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center'
            },
            '& button:not(:first-child)': {
                height: 47,
                marginLeft: 18,
                padding: '0px 30px',
                [theme.breakpoints.down('sm')]: {
                    marginLeft: 0
                }
            }
        },
        clear: {
            'padding': 0,
            'color': '#222',
            'borderRadius': 0,
            'minWidth': 'unset',
            'backgroundColor': '#fff !important',
            'height': 'auto !important',
            'borderBottom': 'solid 1px #222',

            '& span': {
                lineHeight: 1.25
            },

            '&:hover': {
                color: '#222',
                backgroundColor: '#fff !important'
            }
        },
        spinnerStyle: {
            height: '100%'
        },
        signedContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100vh'
        },
        signedIcon: {
            marginBottom: 12,
            backgroundColor: '#5E8677'
        },
        signedTitle: {
            fontSize: 44,
            fontWeight: 600,
            marginBottom: 15,
            color: '#BAA997',
            fontFamily: 'YoungSerif',
            marginTop: '30px',
            textAlign: 'center',
            lineHeight: '120%'
        },
        okButton: {
            marginTop: '80px'
        },
        wrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        footerButtonSize: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                padding: '14px 36px',
                width: '174px',
                height: '55px !important'
            }
        },
        buttonsContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'row',
                gap: 5,
                justifyContent: 'center',
                flexWrap: 'nowrap'
            }
        }
    })
);

const convertToFile = (url: string, fileName: string): Promise<File> => {
    return fetch(url)
        .then(res => res.blob())
        .then(blob => {
            return new File([blob], fileName, { type: 'image/png' });
        });
};

const downloadOption: DropdownOption = {
    id: 'download',
    title: 'Download PDF',
    icon: faFileDownload
};
const printOption: DropdownOption = {
    id: 'print',
    title: 'Print',
    icon: faPrint
};

const modalOptions: Array<DropdownOption> = [downloadOption, printOption];

export const AgreementsSignature: FunctionComponent<
    AgreementsSignatureProps
> = props => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();
    let sigPad: any = null;

    const signature = useSelector<RootState, Signature | undefined>(
        state => state.agreements.signatureByHash
    );
    const agreementsStatus = useSelector<RootState, AgreementsStatus>(
        state => state.agreements.status
    );

    const [loading, setLoading] = useState(true);
    const [signing, setSigning] = useState(false);
    const [signed, setSigned] = useState(false);

    const clearSignPad = () => {
        window.dispatchEvent(new Event('resize'));
    };

    const sign = () => {
        setSigning(true);
        convertToFile(
            sigPad?.getTrimmedCanvas().toDataURL('image/png'),
            `signature_${signature?.id!}.png`
        ).then(file =>
            dispatch(signThunk(signature!.marketplaceId, signature!.id!, file))
        );
    };

    useEffect(() => {
        dispatch(getSignatureByHashThunk(props.hash));
    }, []);

    useNonInitialEffect(() => {
        switch (agreementsStatus) {
            case AgreementsStatus.GetSignatureByHashSuccess:
                setLoading(false);
                break;
            case AgreementsStatus.SignSuccess:
                setSigning(false);
                setSigned(true);
                break;
            case AgreementsStatus.Error:
                setLoading(false);
                setSigning(false);
                break;
        }
    }, [agreementsStatus]);

    const downloadHandler = () => {
        if (signature) {
            dispatch(downloadSignaturePDFThunk(signature.id!));
        }
    };

    const printHandler = () => {
        if (signature) {
            dispatch(printSignatureThunk(signature.id!));
        }
    };

    const selectOptionHandler = (optionId: string) => {
        if (optionId === printOption.id) {
            printHandler();
        }
        if (optionId === downloadOption.id) {
            downloadHandler();
        }
    };

    const header = (
        <Box>
            <Container className={classes.header}>
                <img
                    src="/images/logo/logo.png"
                    alt="Logo"
                    className={classes.logo}
                />

                {isEmpty(props.hash) && (
                    <>
                        <Box className={classes.headerBtns}>
                            <Button
                                size="small"
                                label="Print"
                                startIcon={faPrint}
                                className={classes.printBtn}
                                onClick={printHandler}
                            />
                            <Button
                                size="small"
                                color="green"
                                label="Download PDF"
                                startIcon={faFilePdf}
                                onClick={downloadHandler}
                            />
                        </Box>
                        <Box className={classes.headerDropdown}>
                            <OptionsModal
                                options={modalOptions}
                                onClick={selectOptionHandler}
                            />
                        </Box>
                    </>
                )}
            </Container>
        </Box>
    );

    const title = <Box className={classes.title}>{signature?.title}</Box>;

    const content = (
        <Box className={classes.body}>
            <Container>
                <Grid justifyContent="center" container>
                    <Grid xs={12} md={6} item>
                        <Box>
                            <Box className={classes.info}>
                                <label>Date Sent:</label>{' '}
                                {formatDate(moment(signature?.sentAt))}
                            </Box>
                            <QuillContent
                                className={classes.content}
                                content={`<p>${signature?.content}</p>`}
                            />
                            <hr />
                            <Box className={classes.info}>
                                <label>Printed Name:</label>{' '}
                                {`${signature?.client.firstName} ${signature?.client.lastName}`}
                            </Box>
                            <Box className={classes.info}>
                                <label>Date:</label> {formatDate(moment())}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

    const signBoxPad = (
        <Container className={classes.sign}>
            <Grid justifyContent="center" container>
                <Grid md={6} item>
                    <Box className={classes.info}>
                        <label>Sign Here:</label>
                    </Box>
                    <SignPad
                        onRef={ref => {
                            sigPad = ref;
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );

    const footer = (
        <Container className={classes.footer}>
            <Grid justifyContent="center" container spacing={5}>
                <Grid md={6} xs={12} item className={classes.buttonsContainer}>
                    <Button
                        size="large"
                        label="Clear"
                        color={isMobile ? 'black' : undefined}
                        className={
                            isMobile ? classes.footerButtonSize : classes.clear
                        }
                        onClick={clearSignPad}
                    />
                    <Button
                        size="large"
                        color="green"
                        label="Submit"
                        onClick={sign}
                        loading={signing}
                        className={
                            isMobile ? classes.footerButtonSize : undefined
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );

    return (
        <>
            {loading ? (
                <Spinner className={classes.spinnerStyle} />
            ) : (
                <Box className={classes.root}>
                    {signed ? (
                        <Box className={classes.signedContainer}>
                            <IconCircle
                                className={classes.signedIcon}
                                icon={faFileSignature}
                            />
                            <Typography className={classes.signedTitle}>
                                Agreement signed!
                            </Typography>
                            {props.onClose && (
                                <Button
                                    size="large"
                                    label="Ok"
                                    color="black"
                                    className={classes.okButton}
                                    onClick={props.onClose}
                                />
                            )}
                        </Box>
                    ) : (
                        <Box className={classes.wrapper}>
                            {!props.onClose && header}
                            {title}
                            {content}
                            {signBoxPad}
                            {footer}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default AgreementsSignature;
