import React, { FunctionComponent } from "react";
import {
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import BadgeLabel from "./BadgeLabel";

interface Props {
  isNew: boolean;
  onClickFindOutMore: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F2D2A2",
      borderRadius: "2.91px",
      padding: "0px 4.36px",
      gap: "3.63px",
    },
    titleContainer: {
      gap: "8.5px",
      marginBottom: "12px",
    },
    title: {
      fontStyle: "italic",
      color: "#7A7A7A",
      fontSize: "18px",
    },
    subtitle: {
      color: "#7A7A7A",
      fontSize: "16px",
    },
    link: {
      cursor: "pointer",
      color: "#5E8677",
      fontSize: "16px",
    },
  })
);

export const PaymentStepSetUpMarketplace: FunctionComponent<Props> = (
  props
) => {
  const classes = useStyles();
  const newStatus = props.isNew ? "new" : "coming soon";
  return (
    <Grid>
      <Grid container className={classes.titleContainer}>
        <BadgeLabel label={newStatus} />
        <Typography className={classes.title}>
          Set up Marketplace
        </Typography>
        <Typography className={classes.subtitle}>
          Only available with Cuddles Pay
        </Typography>
        {props.isNew && (
          <Link
            href={"#"}
            onClick={() => {}}
            underline="always"
            className={classes.link}
          >
            Find out more!{" "}
          </Link>
        )}
      </Grid>
      <Grid></Grid>
    </Grid>
  );
};
