import React, { FunctionComponent } from 'react';
import ResumeSection, { BodyResumeSection } from '../ResumeSection';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PetType } from '@spike/model';
import Service from 'model/Service';

interface Props {
	petTypes: Array<PetType>;
	onEdit: () => void;
	service?: Service;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex'
		},
		sectionContainer: {
			width: '100%'
		},
		titleContainer: {},
		title: {
			fontSize: 14,
			lineHeight: 1,
			textTransform: 'uppercase',

			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				color: '#7A7A7A',
				fontWeight: 400,
				textTransform: 'unset'
			}
		},
		infoContainer: {
			width: '100%'
		},
		info: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			marginTop: 8
		}
	})
);

export const ResumeCategory: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const petTypes = props.petTypes.map((petType, index) => {
		const postfix =
			props.petTypes.length === 1 || props.petTypes.length - 1 === index
				? ''
				: index === props.petTypes.length - 2
				? ' &\u00a0'
				: ',\u00a0';
		return (
			<Typography key={petType.id} className={classes.info}>
				{petType.plural + postfix}
			</Typography>
		);
	});

	return (
		<ResumeSection title="Category" service={props.service} {...props}>
			<BodyResumeSection>
				<Box className={classes.container}>
					<Box className={classes.sectionContainer}>
						<Box className={classes.titleContainer}>
							<Typography className={classes.title}>
								Pet Catergory
							</Typography>
						</Box>
						<Box className={classes.infoContainer}>{petTypes}</Box>
					</Box>
				</Box>
			</BodyResumeSection>
		</ResumeSection>
	);
};

export default ResumeCategory;
