import { FieldError } from "@spike/model";
import useNonInitialEffect from "@versiondos/hooks";
import { Pet, Note } from "@spike/pet-model";
import { FunctionComponent, RefObject, useRef, useState } from "react";
import { Avatar, Box, ClickAwayListener, createStyles, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import { wbp, reduceResolution } from "Theme";
import { Button, Chip } from "components/UI";
import { v4 as uuid } from "uuid";
import { useApiClientWrapper, useAuth } from "hooks";
import clsx from "clsx";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { fetchPetsThunk, PetsStatus } from "@spike/pets-action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

interface PetNotesProps {
    pet: Pet;
    newPet?: boolean;
    saving?: boolean;
    errors?: Array<FieldError>;
    edited?: boolean;
    editable: boolean;
    title?: boolean;
    widget?: boolean;
    scrollableParent?: boolean;
    overfullWindow?: boolean;
    bgColor?: string; // hex
    hideRightDate?: boolean
    dateDivider?: boolean;
    scrollable?: boolean;
    maxHeight?: number;
    pagination?: boolean;

    onChangePetNotes?: (petNotes: Array<Note>) => void;
    OnShowPageSizeDropdown?: () => void;
    OnPageSelectedChange?: () => void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: (props: PetNotesProps) => ({
            [theme.breakpoints.down(wbp)]: {
                padding: !props.widget ? `${reduceResolution(50)}px` : "20px",
                marginRight: !props.widget ? `${reduceResolution(50)}px` : "0px",
                marginLeft: !props.widget ? `${reduceResolution(50)}px` : "0px",

            },
            [theme.breakpoints.up(wbp)]: {
                padding: !props.widget ? "50px" : "20px",
                marginRight: !props.widget ? "50px" : "0px",
                marginLeft: !props.widget ? "50px" : "0px"
            },
            [theme.breakpoints.down("md")]: {
                padding: !props.widget ? "0px" : "20px",
                marginRight: !props.widget ? "0px" : "0px",
                marginLeft: !props.widget ? "0px" : "0px"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px 15px 16px'
            },

            backgroundColor: props.bgColor ? props.bgColor : '',
            borderRadius: props.bgColor ? 24 : 'none'
        }),
        title: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: "24px",
            },
            fontWeight: 600,
        },
        subTitle: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`,
                marginTop: `${reduceResolution(10)}px`,
                [theme.breakpoints.down("md")]: {
                    fontSize: "14px"
                }

            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: "15px",
                marginTop: "10px"
            },
            fontWeight: 400,
        },
        divider: {
            width: "100%",
            [theme.breakpoints.down(wbp)]: {

                marginTop: `${reduceResolution(25)}px`,
                marginBottom: `${reduceResolution(25)}px`,
            },
            [theme.breakpoints.up(wbp)]: {

                marginTop: "25px",
                marginBottom: "25px"
            },
            [theme.breakpoints.down("md")]: {
                width: "100%",
            },

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        textBox: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(155)}px`,
                marginTop: `${reduceResolution(25)}px`,
                marginBottom: `${reduceResolution(15)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                height: "155px",
                marginTop: "25px",
                marginBottom: "15px"
            },
            [theme.breakpoints.down("md")]: {
                height: "155px",
                marginTop: "25px",
                marginBottom: "90px"
            },
            width: "100%",
            backgroundColor: "#fff",
            border: "1px #D4D4D4 solid",
            borderRadius: "15px"
        },
        textInput: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(90)}px`,
                marginTop: `${reduceResolution(10)}px`,
                paddingLeft: `${reduceResolution(25)}px`,
                paddingRight: `${reduceResolution(25)}px`,
                fontSize: 14,
                lineHeight: 1.7,
            },
            [theme.breakpoints.up(wbp)]: {
                height: "90px",
                marginTop: "10px",
                paddingLeft: "25px",
                paddingRight: "25px",
                fontSize: 14,
                lineHeight: 1.7,
            },
            [theme.breakpoints.down("md")]: {
                height: "135px",
                marginTop: "10px",
                fontSize: 16,
                lineHeight: 1.5,
            },
            border: 0,
            width: "100%",
            resize: "none",
            outline: "0px !important",
            fontWeight: 400,
            color: "#222222",
            fontFamily: "Poppins",
            backgroundColor: "#FFF !important",
            '& :disabled': {
                backgroundColor: "#FFF !important"
            },
            '& fieldset': {
                border: "0px !important"
            },

            '& .MuiInputBase-root': {
                [theme.breakpoints.down(wbp)]: {
                    height: `${reduceResolution(100)}px`,
                },
                [theme.breakpoints.up(wbp)]: {
                    height: "100px",
                },
                alignItems: "start"
            }
        },
        footerContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "right",
            [theme.breakpoints.down("md")]: {
                justifyContent: "center",
                position: "relative",
                top: "17px",
                width: "100%"
            }
        },
        footerItem: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(10)}px`,
                paddingLeft: `${reduceResolution(10)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: "10px",
                paddingLeft: "10px",
            },
            [theme.breakpoints.down("md")]: {
                width: "100%",
                paddingRight: "0px",
                paddingLeft: "0px",
                fontSize: "18px",
            }
        },
        postButton: {
            "&.Mui-disabled": {
                backgroundColor: "#D3D3D3 !important",
            },
            [theme.breakpoints.down("md")]: {
                width: "100%",
                height: "50px",
                "& .MuiButton-label": {
                    fontSize: "18px",
                }
            }
        },

        noteContainer: (props: PetNotesProps) => ({
            [theme.breakpoints.down(wbp)]: {
                padding: props.widget === true ? `${reduceResolution(15)}px` : `${reduceResolution(20)}px`,
                minHeight: `${reduceResolution(80)}px`,
                marginTop: `${reduceResolution(20)}px`,
                marginBottom: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                padding: props.widget === true ? "15px" : "20px",
                minHeight: "80px",
                marginTop: "20px",
                marginBottom: "20px"
            },
            backgroundColor: "#fff",
            width: "100% !important",
            borderRadius: "15px",
            border: "1px #D4D4D4 solid",

        }),
        noteWholeContainer: (props: PetNotesProps) => ({
            flexWrap: props.widget === true ? 'nowrap' : 'wrap',
            [theme.breakpoints.down("md")]: {
                flexWrap: props.widget === true ? 'nowrap' : 'unset',
            }
        }),
        noteDividerContainer: {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justify: "center",
            width: "100%",
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(20)}px`,
                marginBottom: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: "20px",
                marginBottom: "20px"
            },
            [theme.breakpoints.down("md")]: {
                display: "none"
            }
        },
        noteDividerColumnCenter: {
            minWidth: "15%"
        },
        noteDividerColumnSides: {
            minWidth: "42.5%"
        },
        noteDividerLeft: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(40)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: "40px",
            },
        },
        noteDividerRight: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(40)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: "40px",
            },
        },
        noteDividerText: {
            textAlign: "center",
            color: "#7A7A7A",
            [theme.breakpoints.down(wbp)]: {
                fontSize: 13,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: "13px",
            },
            fontWeight: 500
        },
        noteAuthorAvatarContainer: (props: PetNotesProps) => ({
            flexBasis: props.widget ? '8.3%' : '5% !important',
            [theme.breakpoints.down("md")]: {
                flexBasis: props.widget ? '8.3%' : 'unset !important',
                maxWidth: props.widget ? '8.3%' : 'none',
                marginRight: props.widget ? '4px' : '10px',
            }
        }),
        noteContentContainer: (props: PetNotesProps) => ({
            marginLeft: props.widget === true ? '6px !important' : '0',
        }),
        noteAuthorAvatar: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "32px",
            height: "32px"
        },
        noteAuthorTitle: (props: PetNotesProps) => ({
            [theme.breakpoints.down(wbp)]: {
                fontSize: props.widget === true ? 14 : 15,

            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: props.widget === true ? "14px" : "15px",
            },
            [theme.breakpoints.down("md")]: {
                fontSize: "15px"
            },
            fontWeight: 600
        }),
        noteAuthorChip: (props: PetNotesProps) => ({
            [theme.breakpoints.down(wbp)]: {
                fontSize: props.widget === true ? 13 : 14,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: props.widget === true ? "13px !important" : "14px",
            },
            padding: '2px 4px',
            marginLeft: "10px",
            display: "inline !important",
            backgroundColor: "#000",
            color: "#fff",
            "& .MuiChip-label": {
                paddingLeft: "9px",
                paddingRight: "9px"
            }
        }),
        noteAuthorChipStaff: {
            backgroundColor: "#000",
            color: "#fff"
        },

        noteAuthorPetParent: {
            backgroundColor: "#EAB464",
            color: "#fff"
        },
        noteTimestamp: (props: PetNotesProps) => ({
            [theme.breakpoints.down(wbp)]: {
                fontSize: props.widget === true ? 12.5 : 13,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: props.widget === true ? "12.5px !important" : "13px",
            },
            color: "#7A7A7A",
            fontWeight: 400,
            paddingLeft: props.widget === true ? "0" : "5px",
            textAlign: props.widget === true ? "right" : "left"
        }),
        noteTimestampDesktop: (props: PetNotesProps) => ({
            [theme.breakpoints.down("md")]: {
                display: props.widget === true ? "block" : "none"
            }
        }),
        noteText: (props: PetNotesProps) => ({
            wordBreak: "break-all",
            [theme.breakpoints.down(wbp)]: {
                fontSize: props.widget === true ? 13 : 14,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: props.widget === true ? "13px !important" : "14px",
            },
            [theme.breakpoints.down("md")]: {
                fontSize: "14px",
                marginTop: "5px"
            },
        }),
        wrapper: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        scroll: {
            width: "100%",
            height: "100%",
            minHeight: "320px",
            overflowY: "scroll",
            overflowX: "hidden",
            [theme.breakpoints.down(wbp)]: {
                maxHeight: reduceResolution(340),
                paddingRight: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                maxHeight: 340,
                paddingRight: "15px",
            },

        },
        paginationFooterContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: "20px",
            },
            [theme.breakpoints.down("md")]: {
                justifyContent: "space-between !important",
            },
            textAlign: "center"
        },
        actionIcon: {
            cursor: "pointer",
            color: "#A3A3A3"
        },
        actionIconDisabled: {
            cursor: "default",
            color: "#D4D4D4"
        },
        paginationActionIcon: {
            marginLeft: "10px",
            marginRight: "10px"
        },
        pageSizeDropDownTrigger: {
            position: "relative",
            [theme.breakpoints.down("md")]: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
            }
        },
        pageSizeDropdownContent: (props: PetNotesProps) => ({
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            zIndex: 5,
            borderRadius: 18,
            overflow: "hidden",
            border: "2px solid #222",
            backgroundColor: "white",
            boxShadow: "0p 8px 10px rgba(0, 0, 0, 0.08)",
            minHeight: "45px",
            height: "auto",
            [theme.breakpoints.down(wbp)]: {
                left: props.widget && props.scrollable ? `${reduceResolution(40)}px` : props.widget ? `-${reduceResolution(20)}px` : props.overfullWindow ? `${reduceResolution(10)}px` : `${reduceResolution(50)}px`,
                top: `${reduceResolution(25)}px`,
                width: props.widget ? `${reduceResolution(120)}px` : props.overfullWindow ? `${reduceResolution(220)}px` : `${reduceResolution(150)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                left: props.widget && props.scrollable ? "40px" : props.widget ? "-20px" : props.overfullWindow ? "10px" : "50px",
                top: "25px",
                width: props.widget ? "120px" : props.overfullWindow ? "220px" : "150px",
            },

        }),
        pageSizeItem: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(20)}px`,
                paddingRight: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "20px",
                paddingRight: "20px",
            },
            cursor: "pointer",
            '& :hover': {
                backgroundColor: "#F8F5F1"
            }
        },
        pageSizeItemDisabled: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(20)}px`,
                paddingRight: `${reduceResolution(20)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: "20px",
                paddingRight: "20px",
            },
            cursor: "default !important",
        },
        pageSizeItemText: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`,
                lineHeight: `${reduceResolution(27)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: "14px",
                lineHeight: "27px"
            },
        },
        pageSizeItemTextDisabled: {
            color: "#D4D4D4"
        },
        footerText: {
            textWrap: 'nowrap',
            color: "#A3A3A3"
        },
        footerTextDesktop: {
            [theme.breakpoints.down("md")]: {
                display: "none"
            }
        },
        footerTextMobile: {
            display: "none",
            [theme.breakpoints.down("md")]: {
                display: "inline-flex"
            }
        },
        containerNoteMobile: {
            display: "none",
            [theme.breakpoints.down("md")]: {
                display: "flex",
                marginTop: "5px",
                "& $noteText": {
                    fontSize: "13px",
                    lineHeight: "1.5",
                    fontWeight: 500,
                    color: "#7A7A7A",
                }
            }
        },
        paginationActions: {
            [theme.breakpoints.down("md")]: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            }
        }

    })
);

const PetNotes: FunctionComponent<PetNotesProps> = (props) => {
    const classes = useStyles(props);

    const [showEdition, setShowEdition] = useState(props.edited);
    const auth = useAuth();
    const [newNote, setNewNote] = useState({ uuid: uuid(), body: '', author: auth.user?.firstName + ' ' + auth.user?.lastName, staffId: auth.user?.staffId });
    const [newNoteText, setNewNoteText] = useState('');
    const [pageSelected, setPageSelected] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showPageSizeDropdown, setShowPageSizeDropdown] = useState(false);

    const petStatus = useSelector<RootState, PetsStatus>((state) => state.pets.status);

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const timeZone = auth.timeZone!;
    const textarea = useRef<HTMLTextAreaElement>(null);

    const clientId = props.pet.clientId;
    const petNotesScrollRef = useRef<HTMLDivElement>(null);
    const petNotes = props.pet.notes.sort(function (a, b) { return (moment(b.createdAt) > moment(a.createdAt)) ? 1 : ((moment(a.createdAt) > moment(b.createdAt)) ? -1 : 0); });

    const reducePetNoteInDays = (pages: Array<Array<Note>>, pageNumber: number) => {
        const page = pages[pageNumber - 1] === undefined ? pages[0] : pages[pageNumber - 1];

        return page.reduce((map, note: Note) => {
            const notes = map.get(moment(note.createdAt).tz(timeZone).format("DD-MM-YYYY")) || [];
            map.set(moment(note.createdAt).tz(timeZone).format("DD-MM-YYYY"), [...notes, note]);
            return map;
        }, new Map<string, Array<Note>>());

    };

    const petNotesPages = petNotes.reduce((pages, note: Note, index) => {
        const pageLimit = Math.floor(index / pageSize);

        if (!pages[pageLimit]) {
            pages[pageLimit] = [] as Array<Note>;
        }

        pages[pageLimit].push(note);

        return pages;
    }, new Array<Array<Note>>());

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSelected(1);
        setPageSize(newPageSize);
        setShowPageSizeDropdown(false);
    };


    const handlePageSelectedChange = (increase: boolean) => {
        if (increase) {
            setPageSelected(pageSelected + 1);
        } else {
            setPageSelected(pageSelected - 1);
        }
        if (props.scrollable) {
            scrollToTopHandler(petNotesScrollRef);
        } else if (props.scrollableParent) {
            props.OnPageSelectedChange && props.OnPageSelectedChange();
        }

    };

    useNonInitialEffect(() => {
        if (props.saving! && showEdition) {
            setShowEdition(false);
        }
    }, [props.saving]);

    useNonInitialEffect(() => {

        switch (petStatus) {
            case PetsStatus.SavingPet:
                const newNoteOther0 = { ...newNote, uuid: uuid() }
                setNewNote(newNoteOther0);
                break;
            case PetsStatus.SavePetSuccess:
                const newNoteOther = { ...newNote, uuid: uuid() }
                setNewNote(newNoteOther);
                resetNewNoteText();
                reload();
                scrollToTopHandler(petNotesScrollRef);
                break;
            case PetsStatus.GetSuccess:
                const newUuidG = uuid();
                setNewNote({ ...newNote, uuid: newUuidG });
                reload();
                break;
            case PetsStatus.Error:
                const newUuidE = uuid();
                setNewNote({ ...newNote, uuid: newUuidE });
                reload();
                break;
        }

        const newNoteOther0 = { ...newNote, uuid: uuid() }
        setNewNote(newNoteOther0);
    }, [petStatus]);


    const saveHandler = (newNote: Note) => {
        const petNotesOther = [];
        const newNoteOther = { ...newNote, uuid: uuid() }
        petNotesOther.push(newNoteOther);
        props.onChangePetNotes && props.onChangePetNotes(petNotesOther);
    }

    const changeNewNoteTextHandler = (text: string) => {
        const newNoteOther = newNote;
        newNoteOther.body = text;
        setNewNote(newNoteOther);
        setNewNoteText(text);
    };

    const resetNewNoteText = () => {
        if (textarea.current) {
            textarea.current.value = ''
            changeNewNoteTextHandler('');
        }
    };

    const reload = () => {
        dispatch(fetchPetsThunk(apiClientWrapper, clientId));
    };

    const handlePageSizeChangerClickAway = () => {
        setShowPageSizeDropdown(false);
    };

    const scrollToTopHandler = (ref: RefObject<HTMLDivElement>) => {
        if (props.scrollable) {
            ref.current!.scrollTop = 0;
        } else {
            window.scrollTo(0, 0);
        }
    };

    const scrollToBottomHandler = (ref: RefObject<HTMLDivElement>) => {
        const scrollHeight = ref.current!.scrollHeight;
        const height = ref.current!.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ref.current!.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    const showPetNotesDropdownHandler = () => {
        setShowPageSizeDropdown(!showPageSizeDropdown);
    }

    useNonInitialEffect(() => {
        if (showPageSizeDropdown === true && props.widget) {
            if (props.scrollable === true) {
                scrollToBottomHandler(petNotesScrollRef);
            } else {
                setTimeout(function () {
                    props.OnShowPageSizeDropdown && props.OnShowPageSizeDropdown();
                }, 250);

            }
        }
    }, [showPageSizeDropdown]);

    return (
        <Box className={classes.container}>

            {props.editable === true &&
                <>
                    {props.title === true &&
                        <>
                            <Typography className={classes.title}>Pet Notes</Typography>
                            <Typography className={classes.subTitle}>Enter important notes about the pet.</Typography>
                        </>
                    }

                    <Box className={classes.textBox}>
                        <Grid container>
                            <Grid container>
                                <textarea
                                    ref={textarea}
                                    defaultValue={newNoteText}
                                    className={classes.textInput}
                                    maxLength={1000}
                                    placeholder="Write a note here..."
                                    onChange={($e: React.ChangeEvent<HTMLTextAreaElement>) => changeNewNoteTextHandler($e.currentTarget.value)}
                                ></textarea>
                            </Grid>

                            <Grid container className={classes.footerContainer} alignItems="flex-end" justifyContent="flex-end" alignContent="flex-end">
                                <Box className={classes.footerContainer}>
                                    <Box className={classes.footerItem}><Button onClick={() => saveHandler(newNote)} className={classes.postButton} label="Post" disabled={newNoteText.length === 0}></Button></Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {props.editable === true && !props.widget &&
                        <Divider className={classes.divider}></Divider>
                    }
                </>
            }

            <Grid container justifyContent="flex-start">
                <Box className={classes.wrapper}>
                    <div className={(props.scrollable && petNotes.length !== 0) ? classes.scroll : ''} ref={petNotesScrollRef}>
                        {petNotes.length !== 0 ?
                            Array.from(reducePetNoteInDays(petNotesPages, pageSelected)).map(([day, notes]) => {
                                return (
                                    <>

                                        {props.dateDivider === true &&
                                            <Box className={classes.noteDividerContainer} justifyContent="center" alignContent="center" alignItems="center">
                                                <Box className={classes.noteDividerColumnSides}>
                                                    <Divider className={classes.noteDividerLeft}></Divider>
                                                </Box>

                                                <Box className={classes.noteDividerColumnCenter}>
                                                    <Typography className={classes.noteDividerText}>{moment(day, 'DD-MM-YYYY').isSame(moment().tz(timeZone), 'day') ? 'Today' : moment(day, 'DD-MM-YYYY').format('dddd, D MMMM, YYYY')}</Typography>
                                                </Box>

                                                <Box className={classes.noteDividerColumnSides}>
                                                    <Divider className={classes.noteDividerRight}></Divider>
                                                </Box>
                                            </Box>
                                        }

                                        {notes.map((note) => {
                                            return (
                                                <Box className={classes.noteContainer} key={note.id}>
                                                    <Grid container className={classes.noteWholeContainer}>
                                                        <Grid item xs={1} className={classes.noteAuthorAvatarContainer}>
                                                            <Avatar className={classes.noteAuthorAvatar}></Avatar>
                                                        </Grid>
                                                        <Grid item xs={11} className={classes.noteContentContainer}>
                                                            <Grid container>
                                                                <Grid item xs={props.hideRightDate ? 11 : props.widget === true ? 5 : 11}>
                                                                    <Typography className={classes.noteAuthorTitle}>{note.author}
                                                                        {props.widget === true ?
                                                                            <Chip className={clsx(classes.noteAuthorChip, note.staffId ? classes.noteAuthorChipStaff : classes.noteAuthorPetParent)} label={note.staffId ? 'Staff' : "Pet Parent"}></Chip>
                                                                            :
                                                                            <small className={clsx(classes.noteTimestamp, classes.noteTimestampDesktop)}>  on {moment(note.createdAt).tz(timeZone).isSame(moment().tz(timeZone), 'day') ? 'Today' : moment(note.createdAt).tz(timeZone).format('dddd, D MMMM')} at {moment(note.createdAt).tz(timeZone).format('h:mm a')}</small>
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                {props.hideRightDate !== true &&
                                                                    <Grid item xs={props.widget === true ? 7 : 1}>
                                                                        {props.widget === true &&
                                                                            <Typography className={classes.noteTimestamp}> {moment(note.createdAt).tz(timeZone).isSame(moment().tz(timeZone), 'day') ? 'Today' : moment(note.createdAt).tz(timeZone).format('dddd, D MMMM, YYYY')} at {moment(note.createdAt).tz(timeZone).format('h:mm a')}</Typography>
                                                                        }
                                                                    </Grid>

                                                                }
                                                            </Grid>
                                                            <Grid container>
                                                                <Typography className={classes.noteText}>
                                                                    {props.widget === true ?
                                                                        note.body.length < 120 ? note.body : note.body.slice(0, 120) + "..."
                                                                        :
                                                                        note.body
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container className={clsx(classes.containerNoteMobile)}>
                                                                <Typography className={classes.noteText}>
                                                                    {moment(note.createdAt).tz(timeZone).isSame(moment().tz(timeZone), 'day') ? 'Today' : moment(note.createdAt).tz(timeZone).format('dddd, D MMMM')} at {moment(note.createdAt).tz(timeZone).format('h:mm a')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            )
                                        })
                                        }



                                    </>);
                            })

                            : <Typography className={classes.subTitle}>There are no notes for this pet.</Typography>}
                    </div>
                </Box>
                {petNotes.length !== 0 && props.pagination &&
                    <Grid container justifyContent="center" alignContent="center" className={classes.paginationFooterContainer}>
                        <Grid className={classes.pageSizeDropDownTrigger} item xs={3}><Typography className={classes.footerText}> View {pageSize} <FontAwesomeIcon className={classes.actionIcon} icon={faCaretDown} onClick={() => showPetNotesDropdownHandler()}></FontAwesomeIcon> </Typography>
                            {showPageSizeDropdown &&
                                <ClickAwayListener onClickAway={handlePageSizeChangerClickAway}>
                                    <Box className={classes.pageSizeDropdownContent}>
                                        <Box className={classes.pageSizeItem}><Typography className={classes.pageSizeItemText} onClick={() => handlePageSizeChange(10)}>10 notes</Typography></Box>
                                        <Box className={petNotes.length > 10 ? classes.pageSizeItem : classes.pageSizeItemDisabled}><Typography className={petNotes.length > 10 ? classes.pageSizeItemText : clsx(classes.pageSizeItemText, classes.pageSizeItemTextDisabled)} onClick={() => petNotes.length > 10 ? handlePageSizeChange(20) : {}}>20 notes</Typography></Box>
                                        <Box className={petNotes.length > 20 ? classes.pageSizeItem : classes.pageSizeItemDisabled}><Typography className={petNotes.length > 20 ? classes.pageSizeItemText : clsx(classes.pageSizeItemText, classes.pageSizeItemTextDisabled)} onClick={() => petNotes.length > 20 ? handlePageSizeChange(50) : {}}>50 notes</Typography></Box>
                                    </Box>
                                </ClickAwayListener>}
                        </Grid>
                        <Grid item xs={3}><Typography className={clsx(classes.footerText, classes.footerTextDesktop)}> {pageSelected} of {petNotesPages.length}</Typography></Grid>
                        <Grid className={classes.paginationActions} item xs={4} md={3}>
                            <Typography className={clsx(classes.footerText, classes.footerTextMobile)}> {pageSelected} of {petNotesPages.length}</Typography>
                            <FontAwesomeIcon className={pageSelected === 1 ? clsx(classes.actionIconDisabled, classes.paginationActionIcon) : clsx(classes.actionIcon, classes.paginationActionIcon)} icon={faArrowLeft} onClick={() => pageSelected === 1 ? {} : handlePageSelectedChange(false)}></FontAwesomeIcon>
                            <FontAwesomeIcon className={pageSelected === petNotesPages.length ? clsx(classes.actionIconDisabled, classes.paginationActionIcon) : clsx(classes.actionIcon, classes.paginationActionIcon)} onClick={() => pageSelected === petNotesPages.length ? {} : handlePageSelectedChange(true)} icon={faArrowRight}></FontAwesomeIcon>
                        </Grid>

                    </Grid>
                }

            </Grid>

        </Box>
    );
}

export default PetNotes;
