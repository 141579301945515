import {
	Box,
	createStyles,
	makeStyles,
	OutlinedInput,
	Theme,
	Typography
} from '@material-ui/core';
import { PetType } from '@spike/model';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import Chip from 'components/UI/Chip';

interface Props {
	petType: PetType;
	label: string;
	isDentalReport: boolean;
	teeth: Array<number>;
	allTeeth: boolean;
	onChangeTeeth: (teeth: Array<number>, allTeeth: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		problemsContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		problemsContainerDisabled: {
			'display': 'flex',
			'flexDirection': 'column',
			'pointerEvents': 'none',

			'& .MuiInputBase-input': {
				display: 'none !important'
			}
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			color: '#000000',
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				textTransform: 'uppercase'
			}
		},
		addCondition: {
			'color': '#92B4A7',
			'marginTop': 12,

			'&:hover': {
				cursor: 'pointer'
			},

			'& p': {
				fontSize: 14,
				lineHeight: 1,
				fontWeight: 500,

				[theme.breakpoints.up('xl')]: {
					fontSize: '16px',
					marginBottom: '42px'
				}
			},

			[theme.breakpoints.up('md')]: {
				fontSize: 12,
				fontWeight: 400,
				marginBottom: '34px',
				color: '#5E8677'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				marginBottom: '42px'
			}
		},
		icon: {
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '40px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '53px'
			}
		},
		condContainer: {
			top: 30,
			zIndex: 100,
			width: '100%',
			height: 'auto',
			background: '#fff',
			padding: '24px 16px',
			position: 'absolute',
			borderRadius: '20px',
			border: '1px solid #000',

			[theme.breakpoints.up('md')]: {
				padding: 25,
				width: '500px'
			}
		},
		conditionsContainer: {
			rowGap: 16,
			columnGap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		conditionsEditablesContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.up('md')]: {
				marginLeft: 6
			}
		},
		conditionEditableContainer: {
			'width': '100%',
			'display': 'flex',

			'&:not(:last-child)': {
				marginBottom: 16
			},

			[theme.breakpoints.down('sm')]: {
				gap: 8,
				flexWrap: 'wrap',
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				marginBottom: 24
			}
		},
		conditionContainer: {
			'height': 40,
			'padding': '12px 20px',
			'display': 'inline-flex',
			'alignItems': 'center',
			'backgroundColor': '#F0F4F2',
			'borderRadius': 30,

			'&:hover': {
				cursor: 'pointer'
			},

			[theme.breakpoints.up('xl')]: {
				height: '45px'
			}
		},
		conditionSelectedContainer: {
			'backgroundColor': '#92B4A7',

			'& p': {
				color: '#ffffff'
			}
		},
		label: {
			fontSize: 16,
			lineHeight: 1,
			display: 'flex',
			cursor: 'pointer',

			[theme.breakpoints.up('md')]: {
				fontSize: 12
			}
		},
		arrowContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		conditionsEditablesLabel: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px',
				width: '225px',
				paddingTop: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px',
				width: '300px',
				paddingTop: '16px'
			}
		},
		inputTooth: {
			'gap': 10,
			'padding': 8,
			'fontSize': 16,
			'minHeight': 54,
			'flexWrap': 'wrap',
			'width': '100%',
			'alignItems': 'center',
			'border': '1px solid #EAEAEA',

			[theme.breakpoints.up('md')]: {
				width: '376px',
				padding: '8px',
				minHeight: '53px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '501px',
				padding: '12px',
				minHeight: '68px'
			},

			'& .MuiOutlinedInput-inputAdornedStart': {
				flex: 1,
				padding: 0,
				fontSize: 16,
				paddingLeft: 4
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none !important'
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: 'none !important'
			}
		},
		teeth: {
			cursor: 'pointer'
		},
		allTeeth: {
			cursor: 'pointer',
			backgroundColor: '#92B4A7',
			[theme.breakpoints.down('lg')]: {
				marginRight: '6px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '8px'
			}
		},
		teethsContainer: {
			gap: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		allTeethContainer: {
			[theme.breakpoints.down('lg')]: {
				width: '700%'
			},
			[theme.breakpoints.up('xl')]: {
				width: '400%'
			}
		},
		allTeethBox: {
			[theme.breakpoints.down('lg')]: {
				width: '90px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '120px'
			}
		}
	})
);

export const ToothInput: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const [toothValue, setToothValue] = useState('');

	const [allTeeths, setAllTeeths] = useState(props.allTeeth);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setToothValue(toothValue);
	}, [toothValue]);

	const isValidTooth = (toothParam: number) => {
		if (props.petType.id === 'dogs') {
			if (
				(toothParam >= 101 && toothParam <= 111) ||
				(toothParam >= 201 && toothParam <= 211) ||
				(toothParam >= 301 && toothParam <= 311) ||
				(toothParam >= 401 && toothParam <= 411)
			) {
				return true;
			} else {
				return false;
			}
		} else if (props.petType.id === 'cats') {
			if (
				(toothParam >= 101 && toothParam <= 109) ||
				(toothParam >= 201 && toothParam <= 209) ||
				(toothParam >= 301 && toothParam <= 309) ||
				(toothParam >= 401 && toothParam <= 409)
			) {
				return true;
			} else {
				return false;
			}
		}
	};

	const handleChange = (e: any) => {
		if (e.target.value.length <= 3) {
			setToothValue(e.target.value);

			if (e.target.value.toLowerCase() === 'all') {
				setAllTeeths(true);
				props.onChangeTeeth([], true);
				setToothValue('');
			}

			if (
				isValidTooth(parseInt(e.target.value)) &&
				!props.teeth.includes(parseInt(e.target.value))
			) {
				setAllTeeths(false);
				const teethCopy = [...props.teeth];
				const toothNumber = parseInt(e.target.value);
				const newTeeth = [...teethCopy, toothNumber];
				props.onChangeTeeth(newTeeth, false);
				setToothValue('');
			}
		} else {
			return null;
		}
	};

	const handleDeleteTeeth = (tooth: number) => {
		const teethCopy = [...props.teeth];
		const newTeeth = teethCopy.filter(th => th !== tooth);
		props.onChangeTeeth(newTeeth, allTeeths);
	};

	const handleDeleteAllTeeth = () => {
		const teethCopy = [...props.teeth];
		setAllTeeths(false);
		props.onChangeTeeth(teethCopy, false);
	};

	const handleFocus = () => {
		if (null !== inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<Box className={classes.conditionEditableContainer}>
			<Typography className={classes.conditionsEditablesLabel}>
				{props.label}
			</Typography>
			<OutlinedInput
				className={classes.inputTooth}
				onChange={handleChange}
				onClick={handleFocus}
				multiline={true}
				value={toothValue}
				autoFocus={false}
				inputRef={inputRef}
				startAdornment={
					allTeeths ? (
						<Box className={classes.allTeethContainer}>
							<Box
								className={classes.allTeethBox}
								onClick={() => handleDeleteAllTeeth()}
							>
								<Chip
									center={true}
									className={classes.allTeeth}
									label={'All Teeth'}
								/>
							</Box>
						</Box>
					) : (
						<Box className={classes.teethsContainer}>
							{props.teeth.map((tooth, index) => {
								return (
									<Box
										key={index}
										onClick={() => handleDeleteTeeth(tooth)}
									>
										<Chip
											center={true}
											className={classes.teeth}
											label={`${tooth}`}
										/>
									</Box>
								);
							})}
						</Box>
					)
				}
			/>
		</Box>
	);
};

export default ToothInput;
