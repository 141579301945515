import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from 'rocket/assets/images/logo.svg';
import womanWithPuppies from 'rocket/assets/images/woman-with-puppies.png';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: 176,
        margin: 'auto',
        display: 'block',
        marginBottom: 64
    },
    column: {
        display: 'none',
        minHeight: '100vh',
        textAlign: 'center',
        flexDirection: 'column',
        paddingTop: 24,
        position: 'relative',
        backgroundColor: '#FAEFDF',
        '&::before': {
            content: '""',
            left: 0,
            zIndex: 0,
            bottom: 0,
            height: '22vw',
            width: '100%',
            display: 'block',
            position: 'absolute',
            backgroundColor: 'rgba(242, 210, 162, 0.38)'
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '45%',
            flexBasis: '45%',
            display: 'flex',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    title: {
        maxWidth: '440px',
        display: 'block',
        margin: '0px auto 20px auto',
        [theme.breakpoints.up("sm") && theme.breakpoints.down("md")]: {
            fontSize: 52
        },
        [theme.breakpoints.up("md")]: {
            marginTop: 'auto'
        }
    },
    image: {
        width: '100%',
        zIndex: 10,
        position: 'relative',
        [theme.breakpoints.up("md")]: {
            marginTop: 'auto'
        }
    }
}));

export const LeftColumn: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.column}>
            <img src={logo} alt="Cuddles Demo Logo" className={classes.logo} />

            <h1 className={classes.title}>Grooming just got better.</h1>

            <img src={womanWithPuppies} className={classes.image} alt="Woman with puppies" />
        </Grid>
    );
}



export default LeftColumn;