import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%'
		},
		titleSection: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				marginBottom: 24,
				textTransform: 'uppercase'
			}
		},
		content: {
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center'
			}
		},
		dogDentalChart: {
			display: 'flex',
			[theme.breakpoints.down('lg')]: {
				width: '120px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '160px'
			}
		},
		dogDentalChartText: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '15px',
				marginBottom: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '20px',
				marginBottom: '20px'
			}
		},
		circle: {
			borderRadius: '50%',
			[theme.breakpoints.down('lg')]: {
				width: '20px',
				height: '20px',
				marginRight: '11px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '26px',
				height: '26px',
				marginRight: '14px'
			}
		},
		affectedTooth: {
			display: 'flex',
			[theme.breakpoints.down('lg')]: {
				width: '150px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '200px'
			}
		}
	})
);

export const DogDentalChart: FunctionComponent = () => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography className={classes.titleSection}>
				Dog Dental Chart
			</Typography>
			<Box className={classes.content}>
				<Box className={classes.dogDentalChart}>
					<Typography
						className={classes.circle}
						style={{ backgroundColor: '#DEE8E4' }}
					></Typography>
					<Typography className={classes.dogDentalChartText}>
						Incisors
					</Typography>
				</Box>
				<Box className={classes.dogDentalChart}>
					<Typography
						className={classes.circle}
						style={{ backgroundColor: '#5E8677' }}
					></Typography>
					<Typography className={classes.dogDentalChartText}>
						Premolars
					</Typography>
				</Box>
			</Box>
			<Box className={classes.content}>
				<Box className={classes.dogDentalChart}>
					<Typography
						className={classes.circle}
						style={{ backgroundColor: '#92B4A7' }}
					></Typography>
					<Typography className={classes.dogDentalChartText}>
						Canines
					</Typography>
				</Box>
				<Box className={classes.dogDentalChart}>
					<Typography
						className={classes.circle}
						style={{ backgroundColor: '#FAEFDF' }}
					></Typography>
					<Typography className={classes.dogDentalChartText}>
						Molars
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default DogDentalChart;
