import { FunctionComponent, Fragment } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, DialogTitle, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Button } from "components/UI";

export interface Props {
  open: boolean;
  title?: string;
  question: JSX.Element;
  subQuestion?: JSX.Element;
  actions?: JSX.Element;
  iconClose?: boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  centered?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        [theme.breakpoints.down("md")]: {
          width: "371px",
          minHeight: "194px",
          borderRadius: "16px",
          paddingBottom: "26px",
          paddingTop: "12px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "464px",
          minHeight: "243px",
          borderRadius: "20px",
          paddingBottom: "32px",
          paddingTop: "15px",
        },
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0px",
        [theme.breakpoints.down("md")]: {
          paddingBottom: "16px",
          paddingLeft: "42px",
          paddingRight: "42px",
        },
        [theme.breakpoints.up("lg")]: {
          paddingBottom: "20px",
          paddingLeft: "52px",
          paddingRight: "52px",
        },
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "center",
      },
    },
    cancelButton: {
      color: "#000",
      borderColor: "#000",
      [theme.breakpoints.down("md")]: {
        marginRight: "13px",
        width: "136px",
        height: "43px",
        "& .MuiButton-label": {
          fontSize: "15px"
        }
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "16px",
        width: "170px",
        height: "54px",
        "& .MuiButton-label": {
          fontSize: "18px"
        }
      },
      "&:hover": {
        backgroundColor: "#FFF0F0"
      }
    },
    confirmButton: {
      [theme.breakpoints.down("md")]: {
        marginRight: "13px",
        width: "136px",
        height: "43px",
        "& .MuiButton-label": {
          fontSize: "15px"
        }
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "16px",
        width: "170px",
        height: "54px",
        "& .MuiButton-label": {
          fontSize: "18px"
        }
      },
    },
    iconClose: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
      },
      float: "right",
      "&:hover": {
        cursor: "pointer"
      }
    },
    contentContainer: (props: Props) => ({
      display: "flex",
      justifyContent: "center",
      textAlign: props.centered ? "center" : "start",
    }),
    title: (props: Props) => ({
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        paddingBottom: "5px",
        paddingLeft: props.centered ? "0px" : "21px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        paddingBottom: "6px",
        paddingLeft: props.centered ? "0px" : "26px"
      },
    }),
    titleContainer: (props: Props) => ({
      display: "flex",
      justifyContent: props.centered ? "center" : "start",
    }),
  })
);

export const ConfirmDialog: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const cancelHandler = () => {
    props.onCancel && props.onCancel();
  };

  const confirmHandler = () => {
    props.onConfirm && props.onConfirm();
  };

  return (
    <Dialog onClose={cancelHandler} open={props.open} className={classes.dialog}>
      <DialogTitle>
        <Box className={classes.titleContainer}>
          {props.title && <Typography className={classes.title}>{props.title}</Typography>}
          {props.iconClose && <FontAwesomeIcon icon={faTimes} className={classes.iconClose} onClick={cancelHandler} />}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>{props.question}</DialogContent>
      {props.subQuestion && <DialogContent className={classes.contentContainer}>{props.subQuestion}</DialogContent>}
      <DialogActions>
        {props.actions ? (
          props.actions
        ) : (
          <Fragment>
            <Button
              label={props.cancelButtonLabel}
              onClick={cancelHandler}
              variant="secondary"
              size="medium"
              className={classes.cancelButton}
            />
            <Button
              label={props.confirmButtonLabel}
              onClick={confirmHandler}
              variant="danger"
              size="medium"
              className={classes.confirmButton}
            />
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
