import React, { FunctionComponent, useState } from 'react';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	ClickAwayListener,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { DateField } from '@spike/datefield';
import { EditableValueTextField } from 'components/UI/EditableValueTextField';
import moment from 'moment';
import { lgPixel, mdPixel, xlPixel } from 'utils/VariableUtils';
import clsx from 'clsx';

export interface SpecialHourDateProps {
	date: string;
	label: string;
	name: string;
	onClick?: () => void;
	onChangeLabel?: (value: string) => void;
	onBlur?: (value: string, name: string, hasError?: boolean) => void;
	onDateChange: (value: string, name?: any) => void;
	error?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dateValue: {
			display: 'flex',
			alignItems: 'center',
			fontWeight: 500,
			cursor: 'text',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				height: '15px',
				width: '120px',
				marginRight: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px',
				height: '19px',
				width: '160px',
				marginRight: '32px'
			}
		},

		name: {
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '20px'
			}
		},
		inputDateContainer: {
			gap: 8,
			display: 'flex',
			alignItems: 'center'
		},
		inputDate: {
			'width': '100%',

			'&:focus': {
				outline: 'none'
			}
		},
		descriptionLabel: {
			fontWeight: 400,
			marginRight: '12px',
			color: '#7A7A7A',
			[theme.breakpoints.down('md')]: {
				fontSize: mdPixel(16),
				lineHeight: '15px'
			},
			[theme.breakpoints.only('lg')]: {
				fontSize: lgPixel(16),
				lineHeight: '20px'
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: xlPixel(16),
				lineHeight: '30px'
			}
		},
		error: {
			color: '#EA6464'
		}
	})
);

export const SpecialHourDate: FunctionComponent<
	SpecialHourDateProps
> = props => {
	const classes = useStyles();

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [labelValue, setLabelValue] = useState(props.label);

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleBlur = () => {
		setIsEditing(false);
		props.onChangeLabel && props.onChangeLabel(labelValue);
	};

	const handleChangeLabel = (value: string) => {
		setLabelValue(value);
	};

	const changeDateHandler = (value: string, name?: any) => {
		const date =
			value.length === 10 && moment(value).isValid() ? value : '';
		props.onDateChange(date, name);
	};

	return (
		<Box>
			<EditableValueTextField
				id={'business_settings_input_special_hour_description'}
				className={classes.descriptionLabel}
				name="description"
				value={labelValue}
				onChange={(_, value) => handleChangeLabel(value)}
				onBlur={handleBlur}
			/>
			<Box className={classes.inputDateContainer}>
				<Box>
					<FontAwesomeIcon
						icon={faCalendarAlt}
						className={clsx({ [classes.error]: props.error })}
					/>
				</Box>
				{isEditing ? (
					<ClickAwayListener onClickAway={handleBlur}>
						<Box>
							<DateField
								label=""
								onChange={changeDateHandler}
								value={props.date}
								className={classes.inputDate}
								autoFocus={true}
							/>
						</Box>
					</ClickAwayListener>
				) : (
					<Typography
						id="business_settings_input_special_hour_description_date"
						onClick={handleEdit}
						className={clsx(classes.dateValue, {
							[classes.error]: props.error
						})}
						noWrap={true}
					>
						{props.date !== ''
							? moment(props.date).format('MM/DD/YYYY')
							: 'mm/dd/yyyy'}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default SpecialHourDate;
