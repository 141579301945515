import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMenu, useMenuLinks } from 'hooks/useMenu';
import { headerHeight } from 'Theme';
import clsx from 'clsx';
import { useApiClientWrapper, useAuth } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { closeMenu, setMenuDropdown } from 'actions/menu/MenuActions';
import { MenuLink } from 'model/Menu';
import { RootState } from '../../store';
import { EnrollmentStatus } from '@spike/payments-model';
import { getEnrollmentStatusThunk } from '@spike/payments-action';
import { OWNER_ID } from '@spike/model';
import { isNewAccount } from 'utils/PaymentsUtils';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            bottom: 0,
            width: 280,
            zIndex: 9999,
            overflowY: 'auto',
            top: headerHeight,
            position: 'fixed',
            padding: '30px 16px',
            backgroundColor: '#F4F3F0',
            transition: 'left 0.4s ease-in-out'
        },
        backdrop: {
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9998,
            top: headerHeight,
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.4)'
        },
        link: {
            'height': 38,
            'color': '#000',
            'fontSize': 14,
            'fontWeight': 500,
            'borderRadius': 8,
            'marginBottom': 11,
            'display': 'flex',
            'flexWrap': 'wrap',
            'padding': '0px 15px',
            'alignItems': 'center',
            'textDecoration': 'none',
            'backgroundColor': 'transparent',

            '&.open': {
                backgroundColor: 'rgba(255,255,255,0.6)'
            },
            '&.active': {
                backgroundColor: '#fff'
            },

            '& svg': {
                fontSize: 21,
                marginRight: 8
            }
        },
        childLink: {
            'height': 33,
            'marginBottom': 8,
            'paddingLeft': 44,

            '&:last-child': {
                marginBottom: 8
            }
        }
    })
);

export const MobileMenu: React.FC = () => {
    const classes = useStyles();
    const apiClientWrapper = useApiClientWrapper();
    const links = useMenuLinks();
    const location = useLocation();
    const dispatch = useDispatch();
    const { open, dropdown: activeDropdown } = useMenu();
    const auth = useAuth();
    const isOwner = auth.user!.role.id === OWNER_ID;

    /**
     * Get cuddles pay enrollment status
     */
    useEffect(() => {
        // Check if the current pathname is not '/payments'
        if (location.pathname !== '/payments') {
            dispatch(getEnrollmentStatusThunk(apiClientWrapper));
        }
        // eslint-disable-next-line
    }, [location]);

    const entrollmentStatus = useSelector<
        RootState,
        EnrollmentStatus | undefined
    >(state => state.payments.enrollmentStatus);

    const isLinkActive = (path: string) => {
        return path === location.pathname || location.pathname.includes(path);
    };

    const openDropdown = (link: MenuLink) => {
        return (
            (isLinkActive(link.path) && activeDropdown === link.id) ||
            activeDropdown === link.id
        );
    };

    const clearSelectedDropdown = () => {
        dispatch(setMenuDropdown(''));
    };

    const closeHandler = () => {
        dispatch(closeMenu());
    };

    const linkClickHandler = () => {
        clearSelectedDropdown();
        closeHandler();
    };

    const dropdownClickHandler = (link: MenuLink) => {
        if (activeDropdown === link.id) {
            clearSelectedDropdown();
            return;
        }

        dispatch(setMenuDropdown(link.id));
    };

    return (
        <>
            {open && (
                <Box className={classes.backdrop} onClick={closeHandler} />
            )}

            <Box
                className={classes.root}
                style={{
                    left: open ? 0 : -300
                }}
            >
                {links.map(link => (
                    <Box key={link.id}>
                        {(link.childs && link.id != 'payments') ||
                        (entrollmentStatus &&
                            entrollmentStatus?.enabled &&
                            link.id == 'payments') ? (
                            <Box
                                className={clsx(classes.link, {
                                    active: isLinkActive(link.path),
                                    open: openDropdown(link)
                                })}
                                onClick={() => dropdownClickHandler(link)}
                            >
                                <FontAwesomeIcon icon={link.icon} />
                                {link.label}
                            </Box>
                        ) : (
                            <Link
                                to={link.path}
                                className={clsx(classes.link, {
                                    active: isLinkActive(link.path)
                                })}
                                onClick={linkClickHandler}
                            >
                                <FontAwesomeIcon icon={link.icon} />
                                {link.label}
                            </Link>
                        )}

                        {openDropdown(link) && (
                            <Box>
                                {link.childs?.map(child =>
                                    (child.id === 'setup' ||
                                        child.id === 'bank-accounts') &&
                                    (!isOwner ||
                                        !isNewAccount(
                                            entrollmentStatus
                                        )) ? null : (
                                        <Link
                                            key={child.id}
                                            to={child.path}
                                            className={clsx(
                                                classes.link,
                                                classes.childLink
                                            )}
                                            onClick={closeHandler}
                                        >
                                            {child.label}
                                        </Link>
                                    )
                                )}
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </>
    );
};
