import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Button from 'components/UI/V2/Button/Button';
import { FunctionComponent } from 'react';

interface AgreementsHeaderProps {
    buttonLabel: string;
    title: string;
    id?: string;
    onClick?: () => void;
    showButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        title: {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '24px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '27px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '24px'
            }
        }
    })
);

export const AgreementsHeader: FunctionComponent<
    AgreementsHeaderProps
> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>{props.title}</Typography>
            {props.showButton && (
                <Button
                    leftIcon={faPlus}
                    label={props.buttonLabel}
                    onClick={props.onClick}
                />
            )}
        </Box>
    );
};

export default AgreementsHeader;
