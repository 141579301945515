import { Box, Divider, Theme, createStyles, makeStyles } from "@material-ui/core";
import { FunctionComponent } from "react";
import Pet from "@spike/pet-model";
import PetInformation from "components/PetInformation/PetInformation";
import SidebarPetVisit from "./UISidebar/SidebarPetVisit";
import VetModel from "@spike/vet-model";
import PetCardContent from "./PetCardContent";
import { Header, OverFullWindow } from "components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import PetTabSelectorMobile from "./UI/PetTabSelectorMobile";
interface PetCardMobileProps {
  pet: Pet
  vet?: VetModel
  optionSelected: string;
  onClose?: () => void;
  onSelectMenu: (option: string) => void;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },

    header: {
      width: '100%',
      padding: '10px 0px 20px 0px'
    },
    headerRightContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    markIcon: {
      height: 20,
      width: 20
    },
    viewSelector: {
      padding: '0px 0px 20px 0px'
    },
    paddingWrapper: {
      padding: '0px 16px',
      width: '100%'
    },
    horizontalSelector: {
      minWidth: 'auto',
      paddingRight: 16
    },
    content: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        flexGrow: 1
      }
    },
    divider: {
      margin: '20px 0px',
      background: '#DBDAD7'
    },
    visitContainer: {
      [theme.breakpoints.down('md')]: {
        paddingTop: 23
      }
    },
    petInfo: {
      background: '#F8F5F1'
    }

  })
);


const PetCardMobile: FunctionComponent<PetCardMobileProps> = (props) => {
  const classes = useStyles()
  return (
    <OverFullWindow hideHeader>
      <Box className={classes.container}>

        <Box className={classes.paddingWrapper}>
          <Box>
            <Header title="Pet Card" className={classes.header}
              rightElements={
                <Box
                  className={classes.headerRightContainer}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className={classes.markIcon}
                    onClick={() => props.onClose && props.onClose()}
                  />
                </Box>
              } />
          </Box>
          <PetInformation
            pet={props.pet}
          />
          <SidebarPetVisit
            className={classes.visitContainer}
            pet={props.pet}
          />
          <Divider className={classes.divider} />
        </Box>
        <PetTabSelectorMobile
          pet={props.pet}
          className={classes.viewSelector}
          optionSelected={props.optionSelected}
          onSelectMenu={props.onSelectMenu}
        />
        <PetCardContent
          pet={props.pet}
          vet={props.vet}
          optionMenuSelected={props.optionSelected}
          onClose={props.onClose}
          className={classes.content}
        />
      </Box>

    </OverFullWindow>
  )
}

export default PetCardMobile