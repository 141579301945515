import { OnboardingSteps } from "model/OnboardingSteps";

export interface OnboardingStepsState {
  status: OnboardingStepsStatus;
  loading: boolean;
  onboardingSteps: OnboardingSteps;
  openModal: boolean;
  videoURL: string;
  showVideo: boolean;
}

export enum OnboardingStepsStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Setting,
  SetSuccess,
  Set,
  Saving,
  SaveSuccess,
  Cleared,
  Error,
}
