import { EnrollmentStatus } from '@spike/payments-model';

export enum salesReportItemvalueType {
    Quantity = 'Quantity',
    Money = 'Money'
}

const Formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export const isNegativeSalesReportItemValue = (value: number | undefined) => {
    if (value && value > 0) {
        return false;
    } else {
        return true;
    }
};

export const formatSalesReportItemValue = (
    value: number | undefined,
    type: salesReportItemvalueType
) => {
    if (!value) {
        if (type === salesReportItemvalueType.Quantity) {
            return '0';
        } else {
            return '$0.00';
        }
    } else {
        return isNegativeSalesReportItemValue(value)
            ? '(' + Formatter.format(value).replaceAll('-', '') + ')'
            : type === salesReportItemvalueType.Quantity
            ? value
            : Formatter.format(value);
    }
};

export const isNewAccount = (status: EnrollmentStatus | undefined): boolean => {
    return status?.verificationStatus !== undefined;
};
