import { SmsChat, SmsMessage } from "model/TwoWaySms";
import { Action } from "redux";

//Action Types
export const TWOWAYSMS_RESET_ACTION_TYPE = "TWOWAYSMS_RESET";
export const TWOWAYSMS_CLEAR_ACTION_TYPE = "TWOWAYSMS_CLEAR";
export const TWOWAYSMS_SET_STORE_START_ACTION_TYPE = "TWOWAYSMS_SET_STORE_START";
export const TWOWAYSMS_FETCH_START_ACTION_TYPE = "TWOWAYSMS_FETCH_START";
export const TWOWAYSMS_FETCH_SUCCESS_ACTION_TYPE = "TWOWAYSMS_FETCH_SUCCESS";
export const TWOWAYSMS_SET_CONVERSATION_SUCCESS_ACTION_TYPE = "TWOWAYSMS_SET_CONVERSATION_SUCCESS";
export const TWOWAYSMS_FETCH_CONVERSATIONS_START_ACTION_TYPE = "TWOWAYSMS_FETCH_CONVERSATIONS_START";
export const TWOWAYSMS_FETCH_CONVERSATIONS_SUCCESS_ACTION_TYPE = "TWOWAYSMS_FETCH_CONVERSATIONS_SUCCESS";
export const TWOWAYSMS_SET_CONVERSATIONS_SUCCESS_ACTION_TYPE = "TWOWAYSMS_SET_CONVERSATIONS_SUCCESS";
export const TWOWAYSMS_SEARCH_START_ACTION_TYPE = "TWOWAYSMS_SEARCH_START";
export const TWOWAYSMS_SEARCH_SUCCESS_ACTION_TYPE = "TWOWAYSMS_SEARCH_SUCCESS";
export const TWOWAYSMS_FILTER_SUCCESS_ACTION_TYPE = "TWOWAYSMS_FILTER_SUCCESS";
export const TWOWAYSMS_GET_COUNT_START_ACTION_TYPE = "TWOWAYSMS_GET_COUNT_START";
export const TWOWAYSMS_GET_COUNT_SUCCESS_ACTION_TYPE = "TWOWAYSMS_GET_COUNT_SUCCESS";
export const TWOWAYSMS_SET_COUNT_SUCCESS_ACTION_TYPE = "TWOWAYSMS_SET_COUNT_SUCCESS";
export const TWOWAYSMS_SAVE_START_ACTION_TYPE = "TWOWAYSMS_SAVE_START";
export const TWOWAYSMS_SAVE_SUCCESS_ACTION_TYPE = "TWOWAYSMS_SAVE_SUCCESS"
export const TWOWAYSMS_ERROR_ACTION_TYPE = "TWOWAYSMS_ERROR";

//Actions
interface TwoWaySmsResetAction extends Action<typeof TWOWAYSMS_RESET_ACTION_TYPE> { }

interface TwoWaySmsClearAction extends Action<typeof TWOWAYSMS_CLEAR_ACTION_TYPE> { }

interface TwoWaySmsSetStoreStartAction extends Action<typeof TWOWAYSMS_SET_STORE_START_ACTION_TYPE> { }

interface TwoWaySmsSetConversationSuccessAction extends Action<typeof TWOWAYSMS_SET_CONVERSATION_SUCCESS_ACTION_TYPE> {
    payload: {
        conversation: Array<SmsMessage>;
    }
};


interface TwoWaySmsFetchStartAction extends Action<typeof TWOWAYSMS_FETCH_START_ACTION_TYPE> { }

interface TwoWaySmsFetchSuccessAction extends Action<typeof TWOWAYSMS_FETCH_SUCCESS_ACTION_TYPE> {
    payload: {
        conversation: Array<SmsMessage>;
    }
};


interface TwoWaySmsFetchConversationsStartAction extends Action<typeof TWOWAYSMS_FETCH_CONVERSATIONS_START_ACTION_TYPE> { }

interface TwoWaySmsFetchConversationsSuccessAction extends Action<typeof TWOWAYSMS_FETCH_CONVERSATIONS_SUCCESS_ACTION_TYPE> {
    payload: {
        chats: Array<SmsChat>;
    }
};


interface TwoWaySmsSetConversationsAction extends Action<typeof TWOWAYSMS_SET_CONVERSATIONS_SUCCESS_ACTION_TYPE> {
    payload: {
        chats: Array<SmsChat>;
    }
};


interface TwoWaySmsGetCountStartAction extends Action<typeof TWOWAYSMS_GET_COUNT_START_ACTION_TYPE> { }

interface TwoWaySmsGetCountSuccessAction extends Action<typeof TWOWAYSMS_GET_COUNT_SUCCESS_ACTION_TYPE> {
    payload: {
        messageQuantity: number;
        unreadMessages: Array<SmsMessage>;
    }
};

interface TwoWaySmsSetCountSuccessAction extends Action<typeof TWOWAYSMS_SET_COUNT_SUCCESS_ACTION_TYPE> {
    payload: {
        messageQuantity: number;
    }
};

interface TwoWaySmsSearchStartAction extends Action<typeof TWOWAYSMS_SEARCH_START_ACTION_TYPE> { }

interface TwoWaySmsSearchSuccessAction extends Action<typeof TWOWAYSMS_SEARCH_SUCCESS_ACTION_TYPE> {
    payload: {
        chats: Array<SmsChat>;
    }
};

interface TwoWaySmsFilterSuccessAction extends Action<typeof TWOWAYSMS_FILTER_SUCCESS_ACTION_TYPE> {
    payload: {
        chats: Array<SmsChat>;
    }
};



interface TwoWaySmsSaveStartAction extends Action<typeof TWOWAYSMS_SAVE_START_ACTION_TYPE> { }

interface TwoWaySmsSaveSuccessAction extends Action<typeof TWOWAYSMS_SAVE_SUCCESS_ACTION_TYPE> {
    payload: {
        message: SmsMessage;
    }
}

interface TwoWaySmsErrorAction extends Action<typeof TWOWAYSMS_ERROR_ACTION_TYPE> { }

export type TwoWaySmsAction =
    | TwoWaySmsResetAction
    | TwoWaySmsClearAction
    | TwoWaySmsSetStoreStartAction
    | TwoWaySmsSetConversationSuccessAction
    | TwoWaySmsFetchStartAction
    | TwoWaySmsFetchSuccessAction
    | TwoWaySmsFetchConversationsStartAction
    | TwoWaySmsFetchConversationsSuccessAction
    | TwoWaySmsSetConversationsAction
    | TwoWaySmsSetCountSuccessAction
    | TwoWaySmsSearchStartAction
    | TwoWaySmsSearchSuccessAction
    | TwoWaySmsFilterSuccessAction
    | TwoWaySmsGetCountStartAction
    | TwoWaySmsGetCountSuccessAction
    | TwoWaySmsSaveStartAction
    | TwoWaySmsSaveSuccessAction
    | TwoWaySmsErrorAction;
