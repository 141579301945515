import {
	faHouseMedical,
	faStethoscope,
	faSyringe,
	faTooth
} from '@fortawesome/pro-regular-svg-icons';
import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Pet from '@spike/pet-model';
import VetModel from '@spike/vet-model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Vet from '../../Vet/View';
import MedicalHistoryHeader from '../MedicalHistoryHeader';
import MedicalHistorySection from '../MedicalHistorySection';
import MedicalConditions from './MedicalConditions';
import ViewVaccines from './Vaccines';
import { useMarketplace } from 'hooks';
import Dentals from './Dentals';

interface ViewMedicalHistoryProps {
	pet: Pet;
	vet?: VetModel;
	onEdit?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			[theme.breakpoints.down(wbp)]: {
				marginBottom: `${reduceResolution(32)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginBottom: '32px'
			},
			[theme.breakpoints.down("md")]: {
				marginBottom: '0px',
				paddingBottom: '5px !important'
			}
		},
		section: {
			[theme.breakpoints.down(wbp)]: {
				marginBottom: `${reduceResolution(24)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginBottom: '24px'
			}
		},
		title: {
			fontFamily: 'Poppins',
			fontWeight: 600,
			color: '#222222',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(24)}px`,
				lineHeight: `${reduceResolution(39)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '24px',
				lineHeight: '39px'
			}
		},
		separator: {
			display: 'none',
			backgroundColor: '#F4F3F0',
			height: '16px',
			width: '110%',
			marginLeft: '-5%',
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		}
	})
);

export const ViewMedicalHistory: FunctionComponent<
	ViewMedicalHistoryProps
> = props => {
	const classes = useStyles();

	const isDental = useMarketplace().isDental;

	const Separator = () => {
		return <Box className={useStyles().separator}></Box>;
	};

	return (
		<>
			<Grid container xs={12}>
				<MedicalHistoryHeader
					description="As disclosed by the owner."
					onEdit={props.onEdit}
					className={classes.header}
				/>
				<Grid container xs={12}>
					<MedicalHistorySection
						title="Medical Conditions"
						icon={faHouseMedical}
						className={classes.section}
						initExpanded={true}
					>
						<MedicalConditions pet={props.pet} />
					</MedicalHistorySection>
				</Grid>
			</Grid>

			<Separator></Separator>

			<Grid container xs={12}>

				{isDental && (
					<>
					<Grid container xs={12}>
						<MedicalHistorySection
							title="Dentals"
							icon={faTooth}
							className={classes.section}
							initExpanded={true}
						>
							<Dentals pet={props.pet} />
						</MedicalHistorySection>
					</Grid>
					</>
				)}

			</Grid>

			{isDental && (
			<>
				<Separator></Separator>
			</>
			)}

			<Grid container xs={12}>
				<Grid container xs={12}>
					<MedicalHistorySection
						title="Vaccines"
						icon={faSyringe}
						className={classes.section}
						initExpanded={true}
					>
						<ViewVaccines pet={props.pet} />
					</MedicalHistorySection>
				</Grid>
			</Grid>

				<Separator></Separator>
			
			<Grid container xs={12}>

				<Grid container xs={12}>
					<MedicalHistorySection
						title="Vet"
						icon={faStethoscope}
						className={classes.section}
						initExpanded={true}
					>
						<Vet vet={props.vet} />
					</MedicalHistorySection>
				</Grid>
			</Grid>
		</>
	);
};

export default ViewMedicalHistory;
