import { createStyles, LinearProgress, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { wbp } from "Theme";

interface CalculatingSpinnerProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      [theme.breakpoints.down(wbp)]: {
        width: "48px",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "60px",
      },
    },
  })
);

export const CalculatingSpinner: FunctionComponent<CalculatingSpinnerProps> = (props) => {
  const classes = useStyles();

  return <LinearProgress className={clsx(classes.spinner, props.className)} />;
};

export default CalculatingSpinner;
