import { Reducer } from "redux";
import { RevenuesState, RevenuesStatus } from "./RevenuesState";
import {
  REVENUES_RESET_ACTION_TYPE,
  REVENUES_FETCH_TODAY_START_ACTION_TYPE,
  REVENUES_FETCH_TODAY_SUCCESS_ACTION_TYPE,
  REVENUES_ERROR_ACTION_TYPE,
  RevenuesAction,
} from "actions/revenues/RevenuesActionsTypes";

const initialState: RevenuesState = {
  today: 0,
  status: RevenuesStatus.Initial,
  loading: false,
};

export const revenuesReducer: Reducer<RevenuesState, RevenuesAction> = (state = initialState, action) => {
  switch (action.type) {
    case REVENUES_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case REVENUES_FETCH_TODAY_START_ACTION_TYPE:
      return {
        ...state,
        status: RevenuesStatus.FetchingToday,
        loading: true,
      };
    case REVENUES_FETCH_TODAY_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        today: action.payload.amount,
        status: RevenuesStatus.FetchTodaySuccess,
        loading: false,
      };
    case REVENUES_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: RevenuesStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default revenuesReducer;
