import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useEffect, useState } from 'react';
import Header from './Header';
import TipsDetailTable from './TipsDetailTable';
import { getInvoicesTipDetailReport } from 'actions/tipsReport/TipsReportActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
	TipsReportState,
	TipsReportStatus
} from 'reducers/tipsReport/TipsReportState';
import {
	convertToStaffFilter,
	convertToTipDetails
} from './TipDetailsConverter';
import Summary from './Summary';
import { TipDetail, TipsReport } from 'model/TipsReport';
import { Period } from '@spike/model';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			height: 'fit-content',
			padding: '24px 0px',

			[theme.breakpoints.up('md')]: {
				padding: '50px 24px'
			}
		},
		title: {
			color: '#222',
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,

			[theme.breakpoints.down('sm')]: {
				padding: '0px 16px'
			},

			[theme.breakpoints.up('md')]: {
				fontSize: '24px'
			}
		},
		mobileWrapper: {
			[theme.breakpoints.down('sm')]: {
				marginTop: 24,
				padding: '24px 16px 0px',
				backgroundColor: '#FAFAFA'
			}
		}
	})
);

export const TipsDetail: FunctionComponent = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const tipsLoading = useSelector<RootState, boolean>(
		state => state.tipsReport.loading
	);
	const tipStatus = useSelector<RootState, TipsReportStatus>(
		state => state.tipsReport.status
	);
	const tipsDetail = useSelector<RootState, TipsReportState>(
		state => state.tipsReport
	);

	const [updateStaffs, setUpdateStaffs] = useState<boolean>(true);
	const [tipDetailFilterStaff, setTipDetailFilterStaff] = useState<
		TipsReport | undefined
	>(undefined);
	const [tipDetailsConverted, setTipDetailsConverted] =
		useState<Array<TipDetail> | null>(null);
	const [period, setPeriod] = useState<Period>({
		from: moment().startOf('month'),
		to: moment().endOf('month')
	});
	const [staffsToFilter, setStaffsToFilter] = useState<Array<number>>([]);

	useEffect(() => {
		dispatch(
			getInvoicesTipDetailReport(
				period.from.format('YYYY/MM/DD'),
				period.to.format('YYYY/MM/DD'),
				convertToStaffFilter(staffsToFilter)
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [period, staffsToFilter]);

	useEffect(() => {
		if (tipStatus === TipsReportStatus.GettingSuccess) {
			setTipDetailsConverted(
				convertToTipDetails(tipsDetail, staffsToFilter)
			);
			if (updateStaffs) {
				setTipDetailFilterStaff(tipsDetail);
				setUpdateStaffs(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tipStatus]);

	const handleChangePeriod = (dateFilter: Period) => {
		setPeriod({
			from: dateFilter.from,
			to: dateFilter.to
		});

		setUpdateStaffs(true);
	};

	const handleSelectStaff = (staffs: Array<number>) => {
		setStaffsToFilter(staffs);
	};

	return (
		<Box className={classes.container}>
			<Typography className={classes.title}>Tips Detail</Typography>

			<Box className={classes.mobileWrapper}>
				<Header
					period={period}
					tipsDetail={tipDetailFilterStaff}
					onChangePeriod={handleChangePeriod}
					onStaffChange={handleSelectStaff}
				/>
				<Summary
					loading={tipsLoading}
					totalTips={tipsDetail.totalTips}
					totalAppointments={tipsDetail.totalAppointments}
				/>
			</Box>

			<TipsDetailTable
				loading={tipsLoading}
				tipsDetail={tipDetailsConverted}
			/>
		</Box>
	);
};

export default TipsDetail;
