import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { useCommonStyles } from "./CommonStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderBottom: "2px solid #DDDDDD",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(15)}px`,
        paddingBottom: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#7A7A7A",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        lineHeight: `${reduceResolution(18)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
  })
);

const TableHeaderDetail: FunctionComponent = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Grid item xs={12} container className={classes.container}>
      <Grid item xs={3}>
        <Typography className={classes.text}>Pet Name</Typography>
      </Grid>
      <Grid item xs={1} className={commonClasses.cellCenter}>
        <Typography className={classes.text}>Type</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.text}>Breed</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography className={classes.text}>Gender</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography className={classes.text}>Age</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.text}>Medical Conditions?</Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};
export default TableHeaderDetail;
