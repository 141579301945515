import ProductsComponent from "components/Products";
import React, { FunctionComponent } from "react";
import PrivatePage from "./PrivatePage";

export const Products: FunctionComponent = () => {
  return (
    <PrivatePage title="Products - Cuddles" pageName="/products">
      <ProductsComponent />
    </PrivatePage>
  );
};

export default Products;
