import React, { FunctionComponent, Fragment } from "react";
import { formatTime } from "utils/DateUtils";
import { useTimeZone } from "hooks";
import { Moment } from "moment";

export interface Props {
  date: Moment;
}

export const TimeFormat: FunctionComponent<Props> = (props) => {
  const timeZone = useTimeZone();
  const time = formatTime(props.date, timeZone);
  
  return (<Fragment>{time}</Fragment>);
};

export default TimeFormat;
