import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { InvoiceTipDistribution } from '@spike/invoice-model';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import Section from 'components/Checkout/Section';
import { FunctionComponent } from 'react';
import GridHeader from './GridHeader';
import TipDistributionRow from './TipDistributionRow';

interface TipDistributionProps {
	tipDistribution: Array<InvoiceTipDistribution>;
	loading?: boolean;
	className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			backgroundColor: '#FFF',
			borderBottom: '2px solid black',
			[theme.breakpoints.down(wbp)]: {
				paddingTop: `${reduceResolution(0)}px`,
				paddingBottom: `${reduceResolution(14)}px`,
				marginBottom: `${reduceResolution(22)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingTop: '0px',
				paddingBottom: '18px',
				marginBottom: '22px'
			}
		},
		row: {
			width: '100%'
		}
	})
);

const TipDistribution: FunctionComponent<TipDistributionProps> = props => {
	const classes = useStyles();

	return (
		<Section title="Tip Distribution" className={props.className}>
			<Grid container className={clsx(classes.header)}>
				<GridHeader xs={9} title="Staff Member" />
				<GridHeader xs={3} title="Amount" align="right" />
			</Grid>
			{props.tipDistribution.map((distribution, index) => (
				<Box className={classes.row} key={distribution.id}>
					<TipDistributionRow
						index={index}
						distribution={distribution}
						loading={props.loading}
					/>
				</Box>
			))}
		</Section>
	);
};

export default TipDistribution;
