import DentalReport from "model/DentalReport";
import GroomingReport from "model/GroomerReport";
import { Action } from "redux";

//Action Types
export const REPORTS_RESET_ACTION_TYPE = "REPORTS_RESET";

export const REPORTS_CLEANUP_DENTAL_ACTION_TYPE = "REPORTS_CLEANUP_DENTAL";

export const REPORTS_SAVE_DENTAL_START_ACTION_TYPE = "REPORTS_SAVE_DENTAL_START";
export const REPORTS_SAVE_DENTAL_SUCCESS_ACTION_TYPE = "REPORTS_SAVE_DENTAL_SUCCESS";
export const REPORTS_GET_DENTAL_BY_APPOINTMENT_START_ACTION_TYPE = "REPORTS_GET_DENTAL_BY_APPOINTMENT_START";
export const REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS_ACTION_TYPE = "REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS";
export const REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START_ACTION_TYPE = "REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START";
export const REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE = "REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS";
export const REPORTS_SKIP_ACTION_TYPE = "REPORTS_SKIP_ACTION_TYPE";

export const REPORTS_SAVE_GROOMING_START_ACTION_TYPE = "REPORTS_SAVE_GROOMING_START";
export const REPORTS_SAVE_GROOMING_SUCCESS_ACTION_TYPE = "REPORTS_SAVE_GROOMING_SUCCESS";
export const REPORTS_GET_GROOMING_BY_APPOINTMENT_START_ACTION_TYPE = "REPORTS_GET_GROOMING_BY_APPOINTMENT_START";
export const REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS_ACTION_TYPE = "REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS";
export const REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START_ACTION_TYPE = "REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START";
export const REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE = "REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS";

export const REPORTS_RESEND_REPORT_START_ACTION_TYPE = "REPORTS_RESEND_REPORT_START";
export const REPORTS_RESEND_REPORT_SUCCESS_ACTION_TYPE = "REPORTS_RESEND_REPORT_SUCCESS";
export const REPORTS_ERROR_ACTION_TYPE = "REPORTS_ERROR";

//Actions
interface ReportsResetAction extends Action<typeof REPORTS_RESET_ACTION_TYPE> {}

interface ReportsCleanupDentalAction extends Action<typeof REPORTS_CLEANUP_DENTAL_ACTION_TYPE> {}

interface ReportsSaveDentalStartAction extends Action<typeof REPORTS_SAVE_DENTAL_START_ACTION_TYPE> {}

interface ReportsSaveDentalSuccessAction extends Action<typeof REPORTS_SAVE_DENTAL_SUCCESS_ACTION_TYPE> {
  payload: {
    dentalReport: DentalReport;
  };
}

interface ReportsSkipAction extends Action<typeof REPORTS_SKIP_ACTION_TYPE> {}

interface ReportsGetDentalByAppointmentStartAction
  extends Action<typeof REPORTS_GET_DENTAL_BY_APPOINTMENT_START_ACTION_TYPE> {}

interface ReportsGetDentalByAppointmentSuccessAction
  extends Action<typeof REPORTS_GET_DENTAL_BY_APPOINTMENT_SUCCESS_ACTION_TYPE> {
  payload: {
    dentalReport: DentalReport;
  };
}

interface ReportsSaveGroomingStartAction extends Action<typeof REPORTS_SAVE_GROOMING_START_ACTION_TYPE> {}

interface ReportsSaveGroomingSuccessAction extends Action<typeof REPORTS_SAVE_GROOMING_SUCCESS_ACTION_TYPE> {
  payload: {
    groomingReport: GroomingReport;
  };
}

interface ReportsGetGroomingByAppointmentStartAction
  extends Action<typeof REPORTS_GET_GROOMING_BY_APPOINTMENT_START_ACTION_TYPE> {}

interface ReportsGetGroomingByAppointmentSuccessAction
  extends Action<typeof REPORTS_GET_GROOMING_BY_APPOINTMENT_SUCCESS_ACTION_TYPE> {
  payload: {
    groomingReport: GroomingReport;
  };
}

interface ReportsGetDentalPDFByAppointmentStartAction
  extends Action<typeof REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_START_ACTION_TYPE> {}

interface ReportsGetDentalPDFByAppointmentSuccessAction
  extends Action<typeof REPORTS_GET_DENTAL_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE> {
  payload: {
    dentalReportPDF: any;
  };
}

interface ReportsGetGroomingPDFByAppointmentStartAction
  extends Action<typeof REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_START_ACTION_TYPE> {}

interface ReportsGetGroomingPDFByAppointmentSuccessAction
  extends Action<typeof REPORTS_GET_GROOMING_PDF_BY_APPOINTMENT_SUCCESS_ACTION_TYPE> {
  payload: {
    groomingReportPDF: any;
  };
}

interface ReportsResendReportStartAction extends Action<typeof REPORTS_RESEND_REPORT_START_ACTION_TYPE> {}

interface ReportsResendReportSuccessAction extends Action<typeof REPORTS_RESEND_REPORT_SUCCESS_ACTION_TYPE> {}

interface ReportsErrorAction extends Action<typeof REPORTS_ERROR_ACTION_TYPE> {}

export type ReportsAction =
  | ReportsResetAction
  | ReportsCleanupDentalAction
  | ReportsSaveDentalStartAction
  | ReportsSaveDentalSuccessAction
  | ReportsGetDentalByAppointmentStartAction
  | ReportsGetDentalByAppointmentSuccessAction
  | ReportsSaveGroomingStartAction
  | ReportsSaveGroomingSuccessAction
  | ReportsGetGroomingByAppointmentStartAction
  | ReportsGetGroomingByAppointmentSuccessAction
  | ReportsResendReportStartAction
  | ReportsResendReportSuccessAction
  | ReportsErrorAction
  | ReportsGetDentalPDFByAppointmentStartAction
  | ReportsGetDentalPDFByAppointmentSuccessAction
  | ReportsGetGroomingPDFByAppointmentStartAction
  | ReportsGetGroomingPDFByAppointmentSuccessAction
  | ReportsSkipAction