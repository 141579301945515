import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { wbp, reduceResolution } from 'Theme';

export const useCommonStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        paddingLeft: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(47)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '47px'
            }
        },
        paddingRight: {
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(56)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: '56px'
            }
        },
        body: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(82)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '82px'
            }
        },
        gridHeader: {
            borderBottom: '2px solid #E5E5E5',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '20px'
            }
        },
        columnTitle: {
            fontFamiliy: 'Poppins',
            fontWeight: 600,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                lineHeight: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                lineHeight: '24px'
            }
        },
        redStatus: {
            color: '#EF4F57'
        },
        greenStatus: {
            color: '#5E8677'
        },
        row: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(18)}px 0px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '18px 0px'
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#F1F1F1'
            }
        },
        firtCell: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        statusText: {
            fontFamiliy: 'Poppins',
            fontWeight: 500,
            color: 'black',
            textTransformation: 'capitalized',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`,
                lineHeight: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px',
                lineHeight: '24px'
            }
        },
        rowText: {
            fontFamiliy: 'Poppins',
            fontWeight: 400,
            color: 'black',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`,
                lineHeight: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px',
                lineHeight: '24px'
            }
        },
        verticalCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        center: {
            display: 'flex',
            justifyContent: 'center'
        },
        noShowOnMobile: {
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        },
        noShowOnDesktop: {
            [theme.breakpoints.up('sm')]: {
                display: 'none !important'
            }
        },
        noOverflowXonMobile: {
            [theme.breakpoints.up('sm')]: {
                overflowX: 'unset'
            }
        },
        hideElement: {
            display: 'none !important'
        },
        addButton: {
            'height': 55,
            'padding': '0px 20px',
            '& .MuiButton-startIcon': {
                '& svg': {
                    width: 16,
                    height: 16,
                    [theme.breakpoints.down('lg')]: {
                        width: 14,
                        height: 14
                    }
                }
            },
            '& span': {
                fontSize: 18,
                fontWeight: 600
            },
            [theme.breakpoints.down('lg')]: {
                'height': 39,
                'padding': '0px 20px',
                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        }
    })
);
