import {
  AgreementsAction,
  AGREEMENTS_DELETE_SIGNATURE_START,
  AGREEMENTS_DELETE_SIGNATURE_SUCCESS,
  AGREEMENTS_DELETE_START,
  AGREEMENTS_DELETE_SUCCESS,
  AGREEMENTS_DOWNLOAD_PDF_START,
  AGREEMENTS_DOWNLOAD_PDF_SUCCESS,
  AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_START,
  AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_SUCCESS,
  AGREEMENTS_ERROR,
  AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_START,
  AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_SUCCESS,
  AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_START,
  AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_SUCCESS,
  AGREEMENTS_FETCH_START,
  AGREEMENTS_FETCH_SUCCESS,
  AGREEMENTS_GET_SIGNATURE_BY_HASH_START,
  AGREEMENTS_GET_SIGNATURE_BY_HASH_SUCCESS,
  AGREEMENTS_GET_SUMMARY_START,
  AGREEMENTS_GET_SUMMARY_SUCCESS,
  AGREEMENTS_PRINT_SIGNATURE_START,
  AGREEMENTS_PRINT_SIGNATURE_SUCCESS,
  AGREEMENTS_PRINT_START,
  AGREEMENTS_PRINT_SUCCESS,
  AGREEMENTS_RESEND_START,
  AGREEMENTS_RESEND_SUCCESS,
  AGREEMENTS_RESET,
  AGREEMENTS_SAVE_START,
  AGREEMENTS_SAVE_SUCCESS,
  AGREEMENTS_SEND_SIGNATURE_PDF_START,
  AGREEMENTS_SEND_SIGNATURE_PDF_SUCCESS,
  AGREEMENTS_SEND_START,
  AGREEMENTS_SEND_SUCCESS,
  AGREEMENTS_SIGN_START,
  AGREEMENTS_SIGN_SUCCESS,
} from "actions/agreements/AgreementsActionsTypes";
import cloneDeep from "lodash/cloneDeep";
import { Reducer } from "redux";
import { AgreementsState, AgreementsStatus } from "./AgreementsState";

const initialState: AgreementsState = {
  agreements: [],
  status: AgreementsStatus.Initial,
  signaturesByClient: [],
  signaturesByAgreement: [],
};

export const agreementsReducer: Reducer<AgreementsState, AgreementsAction> = (state = initialState, action) => {
  switch (action.type) {
    case AGREEMENTS_RESET:
      return {
        ...initialState,
      };
    case AGREEMENTS_FETCH_START:
      return {
        ...state,
        status: AgreementsStatus.Fetching,
        agreements: [],
      };
    case AGREEMENTS_FETCH_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.FetchSuccess,
        agreements: [...action.payload.agreements],
      };
    case AGREEMENTS_SAVE_START:
      return {
        ...state,
        status: AgreementsStatus.Saving,
        savedAgreement: undefined,
      };
    case AGREEMENTS_SAVE_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.SaveSuccess,
        agreements: state.agreements.some((agreement) => agreement.uuid === action.payload.agreement.uuid)
          ? state.agreements.map((agreement) =>
              agreement.uuid === action.payload.agreement.uuid ? { ...action.payload.agreement } : agreement
            )
          : [...state.agreements, { ...action.payload.agreement }],
        savedAgreement: { ...action.payload.agreement },
        summary: (state.summary?.agreement.uuid === action.payload.agreement.uuid) ? ({...state.summary, agreement: cloneDeep(action.payload.agreement)}) : state.summary,
      };
    case AGREEMENTS_GET_SUMMARY_START:
      return {
        ...state,
        status: AgreementsStatus.GettingSummary,
        summary: undefined,
      };
    case AGREEMENTS_GET_SUMMARY_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.GetSummarySuccess,
        summary: cloneDeep(action.payload.summary),
      };
    case AGREEMENTS_DOWNLOAD_PDF_START:
      return {
        ...state,
        status: AgreementsStatus.DownloadingPdf,
      };
    case AGREEMENTS_DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.DownloadPdfSuccess,
      };
    case AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_START:
      return {
        ...state,
        status: AgreementsStatus.DownloadingSignaturePdf,
      };
    case AGREEMENTS_DOWNLOAD_SIGNATURE_PDF_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.DownloadSignaturePdfSuccess,
      };
    case AGREEMENTS_PRINT_START:
      return {
        ...state,
        status: AgreementsStatus.Printing,
      };
    case AGREEMENTS_PRINT_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.PrintSuccess,
      };
    case AGREEMENTS_PRINT_SIGNATURE_START:
      return {
        ...state,
        status: AgreementsStatus.PrintingSignature,
      };
    case AGREEMENTS_PRINT_SIGNATURE_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.PrintSignatureSuccess,
      };

    case AGREEMENTS_ERROR:
      return {
        ...state,
        status: AgreementsStatus.Error,
      };
    case AGREEMENTS_SIGN_START:
      return {
        ...state,
        status: AgreementsStatus.Signing,
      };
    case AGREEMENTS_SIGN_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.SignSuccess,
      };
    case AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_START:
      return {
        ...state,
        signaturessByClient: [],
        status: AgreementsStatus.FetchingSignaturesByClient,
      };
    case AGREEMENTS_FETCH_SIGNATURES_BY_CLIENT_SUCCESS:
      return {
        ...state,
        signaturesByClient: [...action.payload.signatures],
        status: AgreementsStatus.FetchSignaturesByClientSuccess,
      };
    case AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_START:
      return {
        ...state,
        signaturessByAgreement: [],
        status: AgreementsStatus.FetchingSignaturesByAgreement,
      };
    case AGREEMENTS_FETCH_SIGNATURES_BY_AGREEMENT_SUCCESS:
      return {
        ...state,
        signaturesByAgreement: [...action.payload.signatures],
        status: AgreementsStatus.FetchSignaturesByAgreementSuccess,
      };
    case AGREEMENTS_GET_SIGNATURE_BY_HASH_START:
      return {
        ...state,
        signatureByHash: undefined,
        status: AgreementsStatus.GettingSignatureByHash,
      };
    case AGREEMENTS_GET_SIGNATURE_BY_HASH_SUCCESS:
      return {
        ...state,
        signatureByHash: { ...action.payload.signature },
        status: AgreementsStatus.GetSignatureByHashSuccess,
      };
    case AGREEMENTS_SEND_START:
      return {
        ...state,
        status: AgreementsStatus.Sending,
      };
    case AGREEMENTS_SEND_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.SendSuccess,
        summary:
          state.summary?.agreement.uuid === action.payload.summary?.agreement.uuid
            ? cloneDeep(action.payload.summary)
            : state.summary,
      };
    case AGREEMENTS_RESEND_START:
      return {
        ...state,
        status: AgreementsStatus.Resending,
      };
    case AGREEMENTS_RESEND_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.ResendSuccess,
        signaturesByClient: state.signaturesByClient.map((signature) =>
          signature.uuid === action.payload.signature.uuid ? cloneDeep(action.payload.signature) : signature
        ),
        signaturesByAgreement: state.signaturesByAgreement.map((signature) =>
          signature.uuid === action.payload.signature.uuid ? cloneDeep(action.payload.signature) : signature
        ),
      };
    case AGREEMENTS_SEND_SIGNATURE_PDF_START:
      return {
        ...state,
        status: AgreementsStatus.SendingSignaturePdf,
      };
    case AGREEMENTS_SEND_SIGNATURE_PDF_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.SendSignaturePdfSuccess,
        signaturesByClient: state.signaturesByClient.map((signature) =>
          signature.uuid === action.payload.signature.uuid ? cloneDeep(action.payload.signature) : signature
        ),
        signaturesByAgreement: state.signaturesByAgreement.map((signature) =>
          signature.uuid === action.payload.signature.uuid ? cloneDeep(action.payload.signature) : signature
        ),
      };
    case AGREEMENTS_DELETE_START:
      return {
        ...state,
        status: AgreementsStatus.Deleting,
      };
    case AGREEMENTS_DELETE_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.DeleteSuccess,
        agreements: state.agreements.filter((agreement) => agreement.id !== action.payload.agreementId),
        savedAgreement: state.savedAgreement?.id === action.payload.agreementId ? undefined : state.savedAgreement,
      };
    case AGREEMENTS_DELETE_SIGNATURE_START:
      return {
        ...state,
        status: AgreementsStatus.DeletingSignature,
      };
    case AGREEMENTS_DELETE_SIGNATURE_SUCCESS:
      return {
        ...state,
        status: AgreementsStatus.DeleteSignatureSuccess,
        signaturesByAgreement: state.signaturesByAgreement.filter(
          (signature) => signature.id !== action.payload.signatureId
        ),
        signaturesByClient: state.signaturesByClient.filter((signature) => signature.id !== action.payload.signatureId),
        signatureByHash: state.signatureByHash?.id === action.payload.signatureId ? undefined : state.signatureByHash,
      };
    default:
      return state;
  }
};

export default agreementsReducer;
