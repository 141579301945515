import {
	Grid,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useCommonStyles } from './CommonStyles';

interface InvoiceLinesHeaderProps {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%'
		},
		sectionIconInfo: {
			'color': '#5E8677',
			'&:hover': {
				cursor: 'pointer'
			},
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(20)}px`,
				marginLeft: `${reduceResolution(3)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '20px',
				marginLeft: '3px'
			}
		}
	})
);

export const InvoiceLinesHeader: FunctionComponent<
	InvoiceLinesHeaderProps
> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<Grid container className={clsx(classes.container, props.className)}>
			<Grid item xs={3}>
				<Typography
					className={clsx(
						commonClasses.titleText,
						commonClasses.firstHeader
					)}
				>
					Pet
				</Typography>
			</Grid>
			<Grid item container xs={9}>
				<Grid item xs={3}>
					<Typography className={commonClasses.titleText}>
						Service
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography className={commonClasses.titleText}>
						Staff
					</Typography>
				</Grid>
				<Grid item xs={2} className={commonClasses.totalCell}>
					<Typography className={commonClasses.titleText}>
						Price
					</Typography>
				</Grid>
				<Grid item xs={2} className={commonClasses.totalCell}>
					<Typography className={commonClasses.titleText}>
						Discount
					</Typography>
				</Grid>
				<Grid item xs={2} className={commonClasses.totalCell}>
					<Typography className={commonClasses.titleText}>
						Total
					</Typography>
				</Grid>
				<Grid item xs={1}></Grid>
			</Grid>
		</Grid>
	);
};

export default InvoiceLinesHeader;
