import {
	USER_NOTIFICATIONS_CLEAR_ACTION_TYPE,
	USER_NOTIFICATIONS_ERROR_ACTION_TYPE,
	USER_NOTIFICATIONS_FETCH_START_ACTION_TYPE,
	USER_NOTIFICATIONS_FETCH_SUCCESS_ACTION_TYPE,
	USER_NOTIFICATIONS_UPDATE_START_ACTION_TYPE,
	USER_NOTIFICATIONS_UPDATE_SUCCESS_ACTION_TYPE,
	USER_NOTIFICATIONS_RESET_ACTION_TYPE,
	USER_NOTIFICATIONS_ADD_START_ACTION_TYPE,
	USER_NOTIFICATIONS_ADD_SUCCESS_ACTION_TYPE,
	UserNotificationsAction
} from 'actions/userNotifications/userNotificationActionTypes';
import {
	UserNotificationsState,
	UserNotificationsStatus
} from './UserNotificationsState';
import { UserNotification } from 'model/UserNotifications';

const initialState: UserNotificationsState = {
	unread: [],
	read: [],
	status: UserNotificationsStatus.Initial,
};

export const userNotificationsReducer = (
	state = initialState,
	action: UserNotificationsAction
) => {
	switch (action.type) {
		case USER_NOTIFICATIONS_RESET_ACTION_TYPE:
			return {
				...initialState
			};
		case USER_NOTIFICATIONS_CLEAR_ACTION_TYPE:
			return {
				...state,
				status: UserNotificationsStatus.Cleared,
			};
		case USER_NOTIFICATIONS_ADD_START_ACTION_TYPE:
			return {
				...state,
				status: UserNotificationsStatus.Adding,
			};
		case USER_NOTIFICATIONS_ADD_SUCCESS_ACTION_TYPE:
			return {
				...state,
				unread: sortNotifications([...state.unread.filter(notification => notification.id !== action.payload.notification.id), action.payload.notification]),
				status: UserNotificationsStatus.AddSuccess,
			};
		case USER_NOTIFICATIONS_FETCH_START_ACTION_TYPE:
			return {
				...state,
				status: UserNotificationsStatus.Fetching,
			};
		case USER_NOTIFICATIONS_FETCH_SUCCESS_ACTION_TYPE:
			return {
				...state,
				read: sortNotifications(action.payload.read),
				unread: sortNotifications(action.payload.unread),
				status: UserNotificationsStatus.FetchSuccess,
			};

		case USER_NOTIFICATIONS_UPDATE_START_ACTION_TYPE:
			return {
				...state,
				status: UserNotificationsStatus.Updating,
			};
		case USER_NOTIFICATIONS_UPDATE_SUCCESS_ACTION_TYPE:
			return {
				...state,
				status: UserNotificationsStatus.UpdateSuccess,
			};

		case USER_NOTIFICATIONS_ERROR_ACTION_TYPE:
			return {
				...state,
				status: UserNotificationsStatus.Error,
			};

		default:
			return state;
	}
};

const sortNotifications = (notifications: Array<UserNotification>): Array<UserNotification> => 
	notifications.sort((notification, otherNotification) => (notification.createdAt.valueOf() - otherNotification.createdAt.valueOf()) * -1);

export default userNotificationsReducer;
