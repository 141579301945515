import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import LeftColumn from "rocket/components/Signup/LeftColumn";
import RightColumn from "rocket/components/SignupBusiness/RightColumn";
import Header from "rocket/components/Header";
import HeaderLink from "rocket/components/Commons/HeaderLink";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headerLink: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const SignupBusiness: FunctionComponent = (props) => {
  const classes = useStyles();

  useEffect(() => {
    pageView("/signup/business");
  }, []);

  document.title = "Tell us about your business! - Cuddles";

  return (
    <div id="signup-business">
      <Header rightLink={<HeaderLink to="/login">Login</HeaderLink>} />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <LeftColumn />
          <RightColumn />
        </Grid>
      </div>
    </div>
  );
};

export default SignupBusiness;
