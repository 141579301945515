import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  title?: JSX.Element;
  message?: JSX.Element;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        paddingTop: "72px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "96px",
      },
    },
    title: {
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        fontSize: "20px",
        fontWeight: 600,
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "24px",
        fontWeight: 600,
      },
    },
    description: {
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        marginTop: "8px",
        fontSize: "15px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "12px",
        fontSize: "18px",
      },
    },
  })
);

export const ResultNotFound: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const defaultMessage = (<Typography className={classes.description}>
    What you search was unfortunately not found or
    <br />
    doesn't exist, try to find something else.
  </Typography>);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{props.title ? props.title : "Oops! No result found"}</Typography>
      {!props.message && defaultMessage}
      {props.message && (<Typography className={classes.description}>
       {props.message}
      </Typography>)}
    </Box>
  );
};

export default ResultNotFound;
