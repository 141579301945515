import {
	Box,
	createStyles,
	makeStyles,
	Radio,
	Typography
} from '@material-ui/core';
import { Option } from '@spike/model';
import { FunctionComponent } from 'react';

interface AgreementsSendOptionProps {
	option: Option<string>;
	selected?: boolean;
	onSelect?: () => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		radioOption: {
			'color': '#222',
			'height': '100%',
			'cursor': 'pointer',
			'padding': '20px 15px',
			'display': 'flex',
			'alignItems': 'flex-start',
			'borderRadius': 12,
			'backgroundColor': '#fff',
			'border': '1px solid #D3D3D3',

			'& .MuiRadio-root': {
				'padding': 0,
				'color': '#222',
				'marginRight': 7,
				'& .MuiSvgIcon-root': {
					fontSize: '15px'
				}
			},
			'& p': {
				fontSize: 16,
				lineHeight: 1,
				fontWeight: 500,
				marginBottom: 9
			},
			'& span': {
				fontSize: 14,
				lineHeight: 1.2,
				fontWeight: 400,
				display: 'block'
			}
		}
	})
);

export const AgreementsSendOption: FunctionComponent<
	AgreementsSendOptionProps
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.radioOption} onClick={props.onSelect}>
			<Radio value={props.option.id} checked={props.selected} />
			<Box>
				<Typography>{props.option.name}</Typography>
				<span>{props.option.description}</span>
			</Box>
		</Box>
	);
};

export default AgreementsSendOption;
