import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@material-ui/core/Box';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Option, PetType } from '@spike/model';
import { updateStaffServicesThunk } from 'actions/services/ServicesActions';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { Grid } from 'components/UI/Grid';
import { GridElement } from 'components/UI/Grid/Grid';
import MessageDialog from 'components/UI/MessageDialog';
import { SelectionBar } from 'components/UI/SelectionBar';
import Spinner from 'components/UI/Spinner';
import { useMasterData } from 'hooks';
import { SelectableOption, Status } from 'model';
import Service, { SortFields, SortOrder, StaffMember } from 'model/Service';
import { Staff } from 'model/Staff';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServicesStatus } from 'reducers/services/ServicesState';
import { RootState } from 'store';
import { ResultNotFound } from './ResultsNotFound';
import ServicesHeader from './ServicesHeader';
import { ServicesHeaderList } from './ServicesHeaderList';
import ServicesMultiselectionBar from './ServicesMultiselectionBar';
import { ServicesNotCreated } from './ServicesNotCreated';
import ServicesRowContent from './ServicesRowContent';
import isEmpty from 'lodash/isEmpty';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';

interface Props {
    businessAreas: Array<Option<string>>;
    petTypes: Array<PetType>;
    duplicatedServiceId?: number;
    save: (service: Service, duplicated?: boolean) => void;
    delete: (serviceIds: Array<number>) => void;
    activate: (serviceIds: Array<number>, active: boolean) => void;
    updatePetTypes: (
        serviceIds: Array<number>,
        petTypeIds: Array<string>
    ) => void;
    updateBusinessArea: (
        serviceIds: Array<number>,
        businessAreaId: string
    ) => void;
    addService: () => void;
    editService: (service: Service) => void;
    addStaff: (service: Service) => void;
}

interface Filter {
    status: string;
    searchText: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: 'white',
            height: '100%'
        },
        headerContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '39px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '52px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '70px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '94px'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '30px'
            }
        },
        selectorsContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '26px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '35px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '47px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '63px'
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '15px',
                paddingRight: '15px'
            }
        },
        selectorContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            border: '1px solid red',
            [theme.breakpoints.down('sm')]: {
                width: '94px',
                paddingRight: '10px'
            },
            [theme.breakpoints.only('md')]: {
                width: '126px',
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '168px',
                paddingRight: '20px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '224px',
                paddingRight: '40px'
            }
        },
        selectorBarContainer: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '70px'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '70px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '70px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '90px'
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '0px'
            }
        },
        gridContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(58)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '58px'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '20px'
            }
        },
        deleteConfirmTextContainer: {
            width: '100%'
        },
        deleteConfirmText: {
            width: '100%',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '15px'
            }
        },
        deleteConfirmTextTitle: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                fontSize: '24px',
                fontWeight: 600,
                marginBottom: '10px'
            }
        },
        deleteConfirmServices: {
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px',
                marginTop: '17px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '15px',
                marginTop: '23px'
            }
        },
        messageDialog: {
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '15px'
            }
        },
        deleteIcon: {
            [theme.breakpoints.down('lg')]: {
                marginLeft: '16px',
                marginRight: '16px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '22px',
                marginRight: '22px'
            }
        },
        spinner: {
            marginTop: '20px'
        },
        servicesNotCreatedContainer: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '96px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '128px'
            }
        },
        grid: {
            '& .makeStyles-toolbarButtonContainer-249': {
                alignItems: 'start !important',
                [theme.breakpoints.down('lg')]: {
                    marginTop: '8px'
                },
                [theme.breakpoints.up('xl')]: {
                    marginTop: '12px'
                }
            }
        },
        gridServices: {
            [theme.breakpoints.down('md')]: {
                '& > div > div:first-child': {
                    display: 'none'
                },
                '& > div > div:nth-child(2)': {
                    width: '100%'
                }
            }
        },
        selectorBarBlock: {
            backgroundColor: '#FFFFFF'
        },
        actionsMobile: {
            position: 'relative'
        }
    })
);

export const ServicesTable: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const masterData = useMasterData();

    const petTypesDuplicateOption: SelectableOption<string> = {
        element: { id: 'pet_type', name: 'Pet Type' },
        selected: true
    };

    const descriptionDuplicateOption: SelectableOption<string> = {
        element: { id: 'services_description', name: 'Services Description' },
        selected: false
    };

    const staffDuplicateOption: SelectableOption<string> = {
        element: { id: 'staff', name: 'Members' },
        selected: false
    };

    const priceDuplicateOption: SelectableOption<string> = {
        element: { id: 'price', name: 'Pricing' },
        selected: false
    };

    const durationDuplicateOption: SelectableOption<string> = {
        element: { id: 'services_duration', name: 'Services Duration' },
        selected: false
    };

    const tagsDuplicateOption: SelectableOption<string> = {
        element: { id: 'services_tags', name: 'Services Tags' },
        selected: false
    };

    const duplicateOptions: Array<SelectableOption<string>> = [
        petTypesDuplicateOption,
        descriptionDuplicateOption,
        staffDuplicateOption,
        priceDuplicateOption,
        durationDuplicateOption,
        tagsDuplicateOption
    ];

    const activeOption: Option<string> = {
        id: 'active',
        name: 'Active'
    };

    const inactiveOption: Option<string> = {
        id: 'inactive',
        name: 'Inactive'
    };

    const reviewOption: Option<string> = {
        id: 'review',
        name: 'Review'
    };

    const allOption: Option<string> = {
        id: 'all',
        name: 'All'
    };

    const services = useSelector<RootState, Array<Service>>(
        state => state.services.services
    );
    const status = useSelector<RootState, ServicesStatus>(
        state => state.services.status
    );

    const staff: Array<StaffMember> = useSelector<RootState, Array<Staff>>(
        state => state.staff.staff
    ).map(staff => ({
        id: staff.id!,
        firstName: staff.person.firstName,
        lastName: staff.person.lastName,
        avatar: staff.person.avatar
    }));

    const [sortedAndFilteredServices, setSortedAndFilteredServices] =
        useState(services);
    const [selected, setSelected] = useState<Array<number>>([]);
    const [selectedQty, setSelectedQty] = useState(0);
    const [showMultiselectionBar, setShowMultiselectionBar] = useState(false);

    const [filter, setFilter] = useState<Filter>({
        status: activeOption.id,
        searchText: ''
    });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState<JSX.Element>(
        <Fragment />
    );
    const [servicesToDelete, setServicesToDelete] = useState<Array<Service>>(
        []
    );
    const [empty, setEmpty] = useState(false);
    const [servicesNotCreated, setServicesNotCreated] = useState(false);

    const [filterOptions, setFilterOptions] = useState<Array<Option<string>>>([
        activeOption,
        inactiveOption,
        allOption
    ]);

    useEffect(() => {
        filterServices(filter);
        const hasReviews = services.some(
            service => service.status.id === Status.REVIEW
        );

        const status: Array<Option<string>> = [activeOption, inactiveOption];

        if (hasReviews) {
            status.unshift(reviewOption);
        }

        status.push(allOption);

        setFilterOptions(status);
    }, [services]);

    const deleteMessage = (
        <Typography className={classes.messageDialog}>
            Services were
            <br />
            deleted successfully.
        </Typography>
    );

    const activateMessage = (
        <Typography className={classes.messageDialog}>
            Services were activated.
        </Typography>
    );

    const inactivateMessage = (
        <Typography className={classes.messageDialog}>
            Services were inactivated.
        </Typography>
    );

    useEffect(() => {
        let showMessage = false;

        if (status === ServicesStatus.FetchSuccess) {
            setServicesNotCreated(services.length === 0);
        }

        if (status === ServicesStatus.DeleteSuccess) {
            setMessageDialog(deleteMessage);
            showMessage = true;
            setServicesNotCreated(services.length === 0);
        }

        if (status === ServicesStatus.ActivateSuccess) {
            setMessageDialog(activateMessage);
            showMessage = true;
        }

        if (status === ServicesStatus.InactivateSuccess) {
            setMessageDialog(inactivateMessage);
            showMessage = true;
        }

        if (showMessage) {
            setOpenMessageDialog(true);
            setTimeout(() => {
                setOpenMessageDialog(false);
            }, 1500);
        }
    }, [status]);

    const onDeleteHandler = (ids: Array<number>): void => {
        setServicesToDelete(
            services
                .filter(service => service.destroyable)
                .filter(service => ids.includes(service.id!))
        );
        setOpenDeleteDialog(true);
    };

    const onActivateHandler = (ids: Array<number>, active: boolean): void => {
        const status = masterData.status.find(
            s => s.id === (active ? Status.ACTIVE : Status.INACTIVE)
        )!;

        setSortedAndFilteredServices(prevServices => {
            prevServices
                .filter(service => ids.includes(service.id!))
                .forEach(service => (service.status = status));
            return prevServices;
        });
        props.activate(ids, active);
    };

    const clearSelector = () => {};

    const onConfirmDeleteHandler = (): void => {
        props.delete(servicesToDelete.map(service => service.id!));
        setSortedAndFilteredServices(prevServices => {
            return prevServices.filter(
                service => !servicesToDelete.some(s => s.id === service.id)
            );
        });
        setSelected(prevValue => {
            return prevValue.filter(
                selectedId => !servicesToDelete.some(s => s.id === selectedId)
            );
        });
        setServicesToDelete([]);
        setOpenDeleteDialog(false);
        setShowMultiselectionBar(false);
    };

    const onSelectFilter = (status: string): void => {
        setSelected([]);
        setShowMultiselectionBar(false);
        setFilter(prevFilter => {
            return { ...prevFilter, status };
        });
        filterServices({ ...filter, status });
    };

    const matchByName = (service: Service, searchText: string): boolean => {
        if (isEmpty(searchText)) {
            return true;
        } else {
            const tokens = searchText
                .split(' ')
                .map(token => token.toLowerCase());
            const serviceName = service.name.toLowerCase();
            return tokens.every(token => serviceName.indexOf(token) > -1);
        }
    };

    const filterServices = (filter: Filter): void => {
        const filtered = services.filter(
            service =>
                (filter.status === allOption.id ||
                    (filter.status === activeOption.id &&
                        service.status.id === Status.ACTIVE) ||
                    (filter.status === inactiveOption.id &&
                        service.status.id === Status.INACTIVE) ||
                    (filter.status === reviewOption.id &&
                        service.status.id === Status.REVIEW)) &&
                matchByName(service, filter.searchText)
        );

        const sortedAndFiltered = filtered;
        const sortedAndFilteredIds = sortedAndFiltered.map(
            service => service.id
        );

        const selectedIds = selected.filter(selectedId =>
            sortedAndFilteredIds.includes(selectedId)
        );

        setSortedAndFilteredServices(sortedAndFiltered);
        setSelected(selectedIds);
        setSelectedQty(selectedIds.length);
        setShowMultiselectionBar(selectedIds.length > 0);

        setEmpty(services.length > 0 && sortedAndFiltered.length === 0);
    };

    const onSort = (field: SortFields, order: SortOrder) => {
        if (field !== undefined) {
            const serviceAuxiliar: Array<Service> = [
                ...sortedAndFilteredServices
            ];
            if (
                order === SortOrder.ASCENDANT ||
                order === SortOrder.DESCENDANT
            ) {
                serviceAuxiliar.sort((serviceAux, otherService) => {
                    if (field === 'name') {
                        if (
                            serviceAux.name.toLowerCase() >
                            otherService.name.toLowerCase()
                        ) {
                            return 1 * (order === SortOrder.ASCENDANT ? 1 : -1);
                        } else if (
                            serviceAux.name.toLowerCase() <
                            otherService.name.toLowerCase()
                        ) {
                            return (
                                -1 * (order === SortOrder.ASCENDANT ? 1 : -1)
                            );
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                });
                setSortedAndFilteredServices(serviceAuxiliar);
            } else {
                filterServices(filter);
            }
        }
    };

    const searchHandler = (searchText: string) => {
        setFilter(prevFilter => {
            return { ...prevFilter, searchText };
        });
        filterServices({ ...filter, searchText });
    };

    const onChangeBusinessAreaHandler = (
        servicesIds: Array<number>,
        businessAreaId: string
    ): void => {
        props.updateBusinessArea(servicesIds, businessAreaId);

        const businessArea = props.businessAreas.find(
            ba => ba.id === businessAreaId
        )!;

        setSortedAndFilteredServices(prevServices => {
            prevServices
                .filter(s => servicesIds.includes(s.id!))
                .forEach(s => (s.businessArea = businessArea));
            return prevServices;
        });
    };

    const onChangePetTypesHandler = (
        servicesIds: Array<number>,
        petTypeIds: Array<string>
    ): void => {
        props.updatePetTypes(servicesIds, petTypeIds);

        const petTypes = props.petTypes.filter(pt =>
            petTypeIds.includes(pt.id)
        );

        setSortedAndFilteredServices(prevServices => {
            prevServices
                .filter(s => servicesIds.includes(s.id!))
                .forEach(s => (s.petTypes = petTypes));
            return prevServices;
        });
    };

    const onChangeStaffHandler = (staffIds: Array<number>): void => {
        if (selected.length === 1) {
            const [serviceId] = selected;

            dispatch(updateStaffServicesThunk(serviceId, staffIds));

            const newStaff = staff.filter(st => staffIds.includes(st.id!));

            setSortedAndFilteredServices(prevServices => {
                prevServices
                    .filter(s => s.id === serviceId)
                    .forEach(s => (s.staff = newStaff));
                return prevServices;
            });
        }
    };

    const onMultiselectBarCloseHandler = (): void => {
        setSelected([]);
        setShowMultiselectionBar(false);
        clearSelector();
    };

    const singleDeleteQuestion = (service: Service) => {
        return (
            <Box className={classes.deleteConfirmTextContainer}>
                <Typography className={classes.deleteConfirmTextTitle}>
                    Delete Service
                </Typography>
                <Typography
                    id="services_modal_label_delete"
                    className={classes.deleteConfirmText}
                >
                    Are you sure you want to delete
                </Typography>
                <Typography className={classes.deleteConfirmText}>
                    <b>{service.name}</b> service?
                </Typography>
            </Box>
        );
    };

    const severalDeleteQuestion = (services: Array<Service>) => {
        return (
            <Box className={classes.deleteConfirmTextContainer}>
                <Typography className={classes.deleteConfirmText}>
                    Are you sure you want to delete
                    <br />
                    the <b>following services?</b>
                </Typography>
                {services.map(s => {
                    return (
                        <Typography
                            key={s.uuid}
                            className={classes.deleteConfirmServices}
                        >
                            <FontAwesomeIcon
                                className={classes.deleteIcon}
                                icon={faCheck}
                            />{' '}
                            <b>{s.name}</b>
                        </Typography>
                    );
                })}
            </Box>
        );
    };

    const canBeActivated = (service: Service): boolean => {
        return (
            (service.pricing.fixedPrice.length > 0 ||
                service.pricing.variablePrice !== undefined) &&
            service.staff.length > 0
        );
    };

    const gridElements: Array<GridElement> = sortedAndFilteredServices.map(
        service => ({
            id: service.id!,
            name: service.name,
            selected: selected.includes(service.id!),
            statusId: service.status.id,
            duplicated: props.duplicatedServiceId?.toString() === service.uuid,
            disableActivateButton: !canBeActivated(service),
            disableDeleteButton: !service.destroyable,
            content: (
                <ServicesRowContent
                    key={service.uuid}
                    service={service}
                    onEdit={() => {
                        props.editService(service);
                    }}
                    onAddStaff={() => {
                        props.addStaff(service);
                    }}
                />
            )
        })
    );

    return (
        <Box className={classes.container}>
            <Box className={classes.headerContainer}>
                <ServicesHeader
                    onSearch={searchHandler}
                    hasServices={!servicesNotCreated}
                    onAddService={props.addService}
                />
            </Box>

            {servicesNotCreated ? (
                <Box className={classes.servicesNotCreatedContainer}>
                    <ServicesNotCreated
                        disableAddButton={false}
                        onAddService={props.addService}
                    />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.selectorsContainer}>
                        <Box className={classes.selectorBarContainer}>
                            <SelectionBar
                                className={classes.selectorBarBlock}
                                options={filterOptions}
                                selectedId={filter.status}
                                onSelect={onSelectFilter}
                            />
                        </Box>
                    </Box>
                    {empty ? (
                        <ResultNotFound />
                    ) : (
                        <Box className={classes.gridContainer}>
                            <ServicesHeaderList onSort={onSort} />

                            {services.length === 0 && !empty && (
                                <Spinner className={classes.spinner} />
                            )}

                            <Grid
                                elements={gridElements}
                                onDelete={(id: number) => onDeleteHandler([id])}
                                onActivate={(id: number, active: boolean) => {
                                    onActivateHandler([id], active);
                                }}
                                onEdit={(serviceId: number) => {
                                    props.editService(
                                        services.find(
                                            service => service.id === serviceId
                                        )!
                                    );
                                }}
                                duplicateOptions={duplicateOptions}
                                className={clsx(
                                    classes.grid,
                                    classes.gridServices
                                )}
                                mobileToolbar={true}
                                isMobile={isMobile}
                            />
                        </Box>
                    )}
                </Fragment>
            )}
            <ConfirmDialog
                id="service_list_modal_"
                open={openDeleteDialog}
                question={
                    servicesToDelete.length === 1
                        ? singleDeleteQuestion(servicesToDelete[0])
                        : severalDeleteQuestion(servicesToDelete)
                }
                confirmButtonLabel="Confirm"
                cancelButtonLabel="Cancel"
                onConfirm={onConfirmDeleteHandler}
                onCancel={() => {
                    setOpenDeleteDialog(false);
                }}
            />
            <MessageDialog
                open={openMessageDialog}
                onClose={() => {
                    setOpenMessageDialog(false);
                }}
                message={messageDialog}
                icon={faCheckCircle}
            />
            {showMultiselectionBar && (
                <ServicesMultiselectionBar
                    selectedQty={selectedQty}
                    businessAreas={props.businessAreas}
                    petTypes={props.petTypes}
                    staff={staff}
                    serviceStaff={
                        selectedQty === 1
                            ? services.find(service =>
                                  selected.includes(service.id!)
                              )!.staff
                            : []
                    }
                    onClose={onMultiselectBarCloseHandler}
                    onDelete={() => {
                        onDeleteHandler(selected);
                    }}
                    onActivate={(selectedIds, active) => {
                        onActivateHandler(selectedIds, active);
                    }}
                    onChangeBusinessArea={businessAreaId => {
                        onChangeBusinessAreaHandler(selected, businessAreaId);
                    }}
                    onChangePetTypes={petTypeIds => {
                        onChangePetTypesHandler(selected, petTypeIds);
                    }}
                    onChangeStaff={onChangeStaffHandler}
                    selectedServices={services.filter(service =>
                        selected.includes(service.id!)
                    )}
                />
            )}
        </Box>
    );
};

export default ServicesTable;
