import { Divider, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Option } from '@spike/model';
import { useMasterData } from 'hooks';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Chips from './Chips';

interface TagChipsProps {
    disabled?: boolean;
    id?: string;
    selectedPositiveTags: Array<Option<string>>;
    selectedNegativeTags: Array<Option<string>>;
    className?: string;
    onChangeSelectionPositiveTags?: (tags: Array<Option<string>>) => void;
    onChangeSelectionNegativeTags?: (tags: Array<Option<string>>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            'margin': '24px 0px',

            '& hr': {
                backgroundColor: '#D4D4D4'
            }
        }
    })
);

const TagChips: FunctionComponent<TagChipsProps> = props => {
    const classes = useStyles();
    const masterData = useMasterData();

    return (
        <Grid id={props.id} container item xs={12} className={props.className}>
            <Chips
                icon={<>👍</>}
                tags={masterData.clientTags.positive}
                selectedTags={props.selectedPositiveTags}
                onChangeSelection={props.onChangeSelectionPositiveTags}
                disabled={props.disabled}
            />
            <Grid item xs={12} className={classes.divider}>
                <Divider />
            </Grid>
            <Chips
                icon={<>👎</>}
                tags={masterData.clientTags.negative}
                selectedTags={props.selectedNegativeTags}
                onChangeSelection={props.onChangeSelectionNegativeTags}
                disabled={props.disabled}
            />
        </Grid>
    );
};

export default TagChips;
