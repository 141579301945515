import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useTagStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            all: 'unset',
            gap: 6,
            lineHeight: 1,
            fontWeight: 500,
            borderRadius: 30,
            whiteSpace: 'nowrap',
            fontFamily: 'Poppins',
            alignItems: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            transition: 'background-color 0.2s ease-in-out'
        },

        // Variants
        danger: {
            color: '#ffffff',
            backgroundColor: '#EF4F57'
        },

        // Sizes
        small: {
            fontSize: 13,
            padding: '5.5px 11px'
        },
        medium: {
            fontSize: 14,
            padding: '8px 11px'
        }
    })
);
