import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { Mark } from "../SelectionBar";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

interface Props {
  label: string;
  selected: boolean;
  selectedBackgroundColor?: string;
  onClick: () => void;
  mark?: Mark;
  count?: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        height: "30px",
        borderRadius: "30px",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "34px",
        borderRadius: "34px",
        paddingLeft: "25px",
        paddingRight: "25px",
      },
    },
    selectedContainer: {
      background: (props: Props) => props.selectedBackgroundColor || "#92B4A7",
    },
    label: {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    errorLabel: {
      [theme.breakpoints.down("md")]: {
        marginRight: "6px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "8px",
      },
    },
    selectedLabel: {
      color: "#ffffff",
    },
    mark: {
      position: "absolute",
      verticalAlign: "top",
      [theme.breakpoints.down("md")]: {
        right: "6px",
        fontSize: "9px",
      },
      [theme.breakpoints.up("lg")]: {
        right: "8px",
        fontSize: "11px",
      },
    },
    warningMark: {
      color: theme.palette.primary.main,
    },
    errorMark: {
      color: "#E36E74",
      [theme.breakpoints.down("md")]: {
        width: "13px !important",
        height: "13px !important",
        marginLeft: "4px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "16px !important",
        height: "16px !important",
      },  
    }
  })
);

const createMark = (markType: Mark, classes: ClassNameMap<"mark" | "warningMark" | "errorMark">) => {
  if(markType === Mark.WARNING) {
    return (<FontAwesomeIcon className={clsx(classes.mark, classes.warningMark)} icon={faCircle}/>);
  } else if (markType === Mark.ERROR) {
    return (<FontAwesomeIcon className={clsx(classes.mark, classes.errorMark)} icon={faExclamationCircle}/>);
  } else {
    return null;
  }
}

export const SelectionBarItem: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  (<FontAwesomeIcon className={classes.mark} icon={faCircle}/>);

  const count = (props.selected && props.count) ? ` (${props.count})`: ""; 
  return (
    <Box
      className={clsx(
        {
          [classes.container]: true,
          [classes.selectedContainer]: props.selected,
        },
        props.className
      )}
      onClick={props.onClick}
    >
      <Typography
        className={clsx({
          [classes.label]: true,
          [classes.errorLabel]: props.mark === Mark.ERROR,
          [classes.selectedLabel]: props.selected,
        })}
      >
        {props.label}{count}
      </Typography>
      {props.mark && !props.selected && createMark(props.mark, classes)}
    </Box>
  );
};

export default SelectionBarItem;
