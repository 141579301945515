import { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PropsWithChildren } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingTop: "50px",
        paddingLeft: "54px",
        paddingRight: "54px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "63px",
        paddingLeft: "67px",
        paddingRight: "67px",
      },
    },
  })
);

export const BodySection: FunctionComponent<PropsWithChildren> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {props.children}
    </Box>
  );
};

export default BodySection;
