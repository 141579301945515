import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SingleChip } from 'components/UI';
import { InvoiceLineType } from '@spike/invoice-model';
import { FunctionComponent } from 'react';

interface ItemTypeProps {
	type: InvoiceLineType;
	className?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		default: {
			'backgroundColor': '#FFF8DD',
			'& p': {
				color: '#C18733',
				fontSize: '14px'
			},
			'width': '80px'
		},

		product: {
			'backgroundColor': '#EAB464',
			'& p': { color: '#fff' }
		},

		addOnService: {
			'backgroundColor': '#5E8677',
			'& p': { color: '#FFF' }
		},

		custom: {
			'backgroundColor': '#222222',
			'& p': { color: '#FFF' }
		}
	})
);

const labels: Map<InvoiceLineType, string> = new Map([
	[InvoiceLineType.Product, 'Product'],
	[InvoiceLineType.AddOnService, 'Add-on'],
	[InvoiceLineType.Custom, 'Custom']
]);

export const ItemType: FunctionComponent<ItemTypeProps> = props => {
	const classes = useStyles();

	return (
		<SingleChip
			className={clsx(
				classes.default,
				{ [classes.product]: props.type === InvoiceLineType.Product },
				{
					[classes.addOnService]:
						props.type === InvoiceLineType.AddOnService
				},
				{ [classes.custom]: props.type === InvoiceLineType.Custom }
			)}
		>
			{labels.get(props.type) || '-'}
		</SingleChip>
	);
};

export default ItemType;
