import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    createStyles,
    FormControlLabel,
    makeStyles,
    Radio,
    Theme,
    Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'components/UI';
import { SMSCampaignCustomerGroupOption } from 'model/SMSCampaign';

export interface SMSCreateFormOptionModel {
    key: string;
    name: string;
}

export interface SMSCreateFormOptionFilter {
    type: 'button' | 'dropdown';
    label?: string;
    onClick?: () => void;
    options?: SMSCreateFormOptionModel[];
    value?: SMSCampaignCustomerGroupOption;
}

interface Props {
    value: string;
    title: string;
    count?: number;
    selected?: string;
    description: string;

    filter: SMSCreateFormOptionFilter;
    filterChange?: (value: { type: string; value: string }) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'marginBottom': 20,

            '& .MuiButtonBase-root': {
                'color': '#222',
                'padding': '0px 8px 0px',

                '& svg': {
                    width: 20,
                    height: 20
                }
            },
            '& .MuiFormControlLabel': {
                '&-root': {
                    margin: 0,
                    width: '100%',
                    display: 'inline-flex',
                    alignItems: 'flex-start'
                },
                '&-label': {
                    'flex': 1,
                    'margin': 0,
                    'fontSize': 16,
                    'lineHeight': 1,
                    'fontWeight': 500,

                    '& mark': {
                        color: '#7A7A7A',
                        background: 'none'
                    }
                },
                '&-description': {
                    'margin': 0,
                    'marginTop': 8,
                    'fontSize': 14,
                    'lineHeight': 1,
                    'fontWeight': 400,
                    'display': 'inline-flex',

                    '& span': {
                        'fontWeight': 600,
                        'color': '#EAB464',
                        'paddingBottom': 3,
                        'position': 'relative',
                        'borderBottom': '1px dashed #000000',

                        '& svg': {
                            left: '100%',
                            paddingLeft: 5,
                            paddingBottom: 5,
                            position: 'absolute'
                        }
                    }
                }
            }
        },
        label: {
            'width': '100%',
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'space-between',

            '& .MuiButtonBase-root': {
                color: '#fff',
                padding: '0px 13px'
            }
        },
        dropdownWrapper: {
            position: 'relative',
            display: 'inline-block'
        },
        dropdown: {
            top: 25,
            right: -20,
            width: 100,
            borderRadius: 14,
            padding: '10px 0px',
            position: 'absolute',
            backgroundColor: '#fff',
            border: 'solid 1px #000'
        },
        dropdownItem: {
            'width': '100%',
            'color': '#222',
            'fontSize': 14,
            'fontWeight': 500,
            'lineHeight': 1.95,
            'cursor': 'pointer',
            'margin': '2px 0px',
            'padding': '0px 15px',
            'display': 'inline-block',

            '& svg': {
                marginRight: 6,
                verticalAlign: 'middle'
            },

            '&:hover': {
                backgroundColor: '#FAFAFA'
            }
        }
    })
);

const SMSCreateFormOption: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [dropdown, setDropdown] = useState<boolean>(false);

    useEffect(() => {
        if (!props.filterChange) return;
        if (!props.filter.options) return;
        if (props.filter.options && props.filter.options.length === 0) return;

        props.filterChange({
            type: props.filter.type,
            value: props.filter.options[0].key
        });
    }, []);

    const optSelected = (opt: SMSCreateFormOptionModel) => {
        setDropdown(false);
        props.filterChange &&
            props.filterChange({ type: props.filter.type, value: opt.key });
    };

    const selected = () =>
        props.filter.options?.find((opt: SMSCreateFormOptionModel, i) => {
            return opt.key === props.filter.value?.days;
        });

    const FilterOptions = () => (
        <Box className={classes.dropdown}>
            {props.filter.options?.map((opt: SMSCreateFormOptionModel, i) => (
                <Box
                    key={i}
                    className={classes.dropdownItem}
                    onClick={() => optSelected(opt)}
                >
                    {opt.name}
                </Box>
            ))}
        </Box>
    );

    return (
        <Box className={classes.root}>
            <FormControlLabel
                control={<Radio checked={props.selected === props.value} />}
                value={props.value}
                label={
                    <Box className={classes.label}>
                        <Box>
                            <Typography className="MuiFormControlLabel-label">
                                {props.title}
                                {props.selected === props.value && (
                                    <mark>({props.count} clients)</mark>
                                )}
                            </Typography>

                            {props.selected === props.value && (
                                <Box className="MuiFormControlLabel-description">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: props.description
                                        }}
                                    ></div>

                                    {props.filter.type === 'dropdown' && (
                                        <Box
                                            className={classes.dropdownWrapper}
                                        >
                                            &nbsp;
                                            <span
                                                onClick={() =>
                                                    setDropdown(!dropdown)
                                                }
                                            >
                                                {selected()?.name}
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                />
                                            </span>
                                            {dropdown && <FilterOptions />}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>

                        {props.selected === props.value &&
                            props.filter.type === 'button' && (
                                <Button
                                    color="black"
                                    label={props.filter.label}
                                    onClick={() =>
                                        props.filter.onClick &&
                                        props.filter.onClick()
                                    }
                                />
                            )}
                    </Box>
                }
            />
        </Box>
    );
};

export default SMSCreateFormOption;
