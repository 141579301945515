import React, { FunctionComponent, useState } from 'react';
import { BodySection, ActionSection } from 'components/UI/Section';
import Section from '../Section';
import { Option, PetType } from '@spike/model';
import { Selectable } from 'model';
import PetTypes from './PetTypes';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Service from 'model/Service';
import BusinessArea from './BusinessArea/BusinessArea';
import clsx from 'clsx';
import { MobileDivider } from 'components/UI/MobileDivider';
import { MarketplaceBasics } from '@spike/marketplace-model';
import { isStateAllowed, normalizeState } from 'utils/StateUtils';

interface Props {
    collapsed?: boolean;
    disabled?: boolean;
    editable?: boolean;
    completed?: boolean;
    modifiable?: boolean;
    service: Service;
    optionsBusinessAreas: Array<Option<string>>;
    petTypes: Array<Selectable<PetType>>;
    onEdit?: () => void;
    onChange: (selectedPetType: Array<Selectable<PetType>>) => void;
    onChangeBusinessArea: (businessArea: Option<string>) => void;
    onCollapse: () => void;
    onNext: () => void;
    marketplaceBasics: MarketplaceBasics;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        row: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                display: 'flex'
            }
        },
        rowDivider: {
            margin: '0px -16px'
        },
        businessAreaContainer: {
            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginTop: '36px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '47px'
            }
        },
        businessAreasNone: {
            display: 'none'
        },
        petTypesContainer: {
            width: '50%'
        },
        houseCallContainer: {
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                marginTop: '36px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '47px'
            }
        }
    })
);

export const Category: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const existSelected = (options: Array<Selectable<PetType>>) => {
        return options.some(option => option.selected);
    };

    const [disabledNext, setDisabledNext] = useState(
        !existSelected(props.petTypes)
    );

    const changeHandler = (
        selectedPetTypes: Array<Selectable<PetType>>
    ): void => {
        setDisabledNext(!existSelected(selectedPetTypes));
        props.onChange(selectedPetTypes);
    };

    const normalizedState = normalizeState(
        props.marketplaceBasics.address.state
    );
    const allowedState = isStateAllowed(normalizedState);

    return (
        <Section
            position={1}
            title="Category"
            {...props}
            collapsed={props.collapsed}
            onCollapse={props.onCollapse}
        >
            <BodySection>
                <Box className={classes.container}>
                    <Box className={classes.row}>
                        <Box className={classes.petTypesContainer}>
                            <PetTypes
                                petTypes={props.petTypes}
                                onChange={changeHandler}
                            />
                        </Box>
                        <Box className={classes.rowDivider}>
                            <MobileDivider />
                        </Box>
                        <Box
                            className={clsx(classes.businessAreaContainer, {
                                [classes.businessAreasNone]: !allowedState
                            })}
                        >
                            <BusinessArea
                                businessArea={props.service.businessArea}
                                options={props.optionsBusinessAreas}
                                onChange={props.onChangeBusinessArea}
                            />
                        </Box>
                    </Box>
                </Box>
            </BodySection>
            <ActionSection
                id="services_add_service_button_next"
                onNext={props.onNext}
                disabled={disabledNext}
            />
        </Section>
    );
};

export default Category;
