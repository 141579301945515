import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import { FieldError, Option } from '@spike/model';
import clsx from 'clsx';
import { ConfirmDialog, CalendarField } from 'components/UI';
import { PetVaccine, VaccineStatus } from '@spike/medical-history-model';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import UploadComponent from './UploadComponent';
import VaccineTypes from './VaccineTypes';

interface VaccineRowProps {
    vaccine: PetVaccine;
    errors?: Array<FieldError>;
    onChange?: (vaccine: PetVaccine) => void;
    onRemove?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        vaccinesRow: {
            display: 'flex',
            width: '95%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                flexDirection: 'column'
            }
        },
        vaccinesRowContent: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
                position: 'relative',
                paddingTop: '15px'
            }
        },
        vaccinesRowContainer: {
            'display': 'flex',
            'width': '100%',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'height': 'auto',
            '&:hover': {
                background: '#F8F8F8',
                borderRadius: '12px',
                [theme.breakpoints.down('md')]: {
                    background: 'none',
                    borderRadius: '0px'
                }
            },
            [theme.breakpoints.down(wbp)]: {
                minHeight: `${reduceResolution(45)}px !important`,
                marginBottom: `${reduceResolution(12)}px`,
                paddingLeft: `${reduceResolution(40)}px`,
                paddingTop: `${reduceResolution(12)}px`,
                paddingBottom: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                minHeight: '45px !important',
                marginBottom: '12px',
                paddingLeft: '40px',
                paddingTop: '12px',
                paddingBottom: '12px'
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '0px',
                paddingTop: '10px',
                paddingBottom: '15px'
            }
        },
        marginCells: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '40px'
            }
        },
        cellContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '33%'
        },
        vaccineContainer: {
            width: '32%',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        dateContainer: {
            width: '38%',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        fileContainer: {
            width: '28%',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        icon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                lineHeight: `${reduceResolution(24)}px`,
                fontSize: `${reduceResolution(16)}px`,
                marginRight: `${reduceResolution(29)}px`,
                marginTop: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                lineHeight: '24px',
                fontSize: '16px',
                marginRight: '29px',
                marginTop: '13px'
            },
            [theme.breakpoints.down('md')]: {
                marginRight: '5px',
                marginTop: '0px',
                lineHeight: '16px',
                fontSize: '16px',
                color: '#EF4F57'
            }
        },
        iconContainer: {
            width: '5%',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                position: 'absolute',
                right: '2px',
                top: '0px'
            }
        },
        delete: {
            color: '#EF4F57',
            fontSize: '14px',
            textDecoration: 'underline',
            textUnderlineOffset: '3px'
        },
        labelDefault: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                fontSize: '14px',
                paddingLeft: '20px',
                marginBottom: '5px',
                [theme.breakpoints.down('md')]: {
                    '& b': {
                        fontWeight: 400,
                        fontSize: '12px',
                        position: 'relative',
                        top: '-1px',
                        left: '-2px',
                        color: '#EAB464'
                    }
                }
            }
        }
    })
);

export const VaccineRow: FunctionComponent<VaccineRowProps> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const changeTypeHandler = (type: Option<string>) => {
        props.onChange && props.onChange({ ...props.vaccine, type });
    };

    const changeExpirationDateHandler = (expiration: Moment) => {
        props.onChange &&
            props.onChange({
                ...props.vaccine,
                expiration,
                status: expiration.isBefore(moment())
                    ? VaccineStatus.EXPIRED
                    : VaccineStatus.ACTIVE
            });
    };

    const changeFileHandler = (imageFile: File, image: string) => {
        props.onChange &&
            props.onChange({
                ...props.vaccine,
                record: {
                    remove: false,
                    upload: true,
                    file: imageFile,
                    fileName: imageFile.name,
                    url: image
                }
            });
    };

    const deleteFileHandler = () => {
        props.onChange &&
            props.onChange({
                ...props.vaccine,
                record: {
                    ...props.vaccine.record!,
                    remove: true,
                    upload: false
                }
            });
    };

    return (
        <>
            <Box className={classes.vaccinesRowContainer}>
                <Box className={classes.vaccinesRowContent}>
                    <Box className={classes.vaccinesRow}>
                        <Box
                            className={clsx(
                                classes.cellContainer,
                                classes.vaccineContainer
                            )}
                        >
                            <Typography className={classes.labelDefault}>
                                Add Vaccine <b>*</b>
                            </Typography>
                            <VaccineTypes
                                type={props.vaccine.type}
                                onChange={changeTypeHandler}
                                errors={props.errors}
                            />
                        </Box>
                        <Box
                            className={clsx(
                                classes.cellContainer,
                                classes.dateContainer
                            )}
                        >
                            <CalendarField
                                name="vaccine_expiration"
                                placeholder="Add Expiration Date"
                                date={props.vaccine.expiration}
                                rawDate={true}
                                onChange={changeExpirationDateHandler}
                                errors={props.errors}
                            />
                        </Box>
                        <Box
                            className={clsx(
                                classes.cellContainer,
                                classes.fileContainer
                            )}
                        >
                            <UploadComponent
                                imageUrl={props.vaccine.record?.url}
                                // @ts-ignore:next-line
                                file={props.vaccine.record?.file}
                                removed={props.vaccine.record?.remove}
                                onChangeImage={changeFileHandler}
                                onRemove={deleteFileHandler}
                            />
                        </Box>
                    </Box>
                    {props.onRemove && (
                        <Box className={classes.iconContainer}>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className={classes.icon}
                                onClick={() => setShowDeleteConfirmation(true)}
                            />
                            {isSmall && (
                                <Typography
                                    onClick={() =>
                                        setShowDeleteConfirmation(true)
                                    }
                                    className={classes.delete}
                                >
                                    Delete
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {showDeleteConfirmation && (
                <ConfirmDialog
                    open={true}
                    title={<Typography>{props.vaccine.type?.name}</Typography>}
                    question={
                        <Typography>
                            Do you want to <strong>delete</strong> this
                            <br /> Vaccine?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => {
                        setShowDeleteConfirmation(false);
                        props.onRemove && props.onRemove();
                    }}
                />
            )}
        </>
    );
};

export default VaccineRow;
