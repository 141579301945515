import { Box, Typography } from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import { useTerminalCommonStyles } from '../TerminalCommonStyles';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AdyenPayment } from 'components/Payments/Adyen/AdyenPayment';

interface OrderPaymentMethodProps {
	invoiceId: number;
	className?: string;
	//
	onClose?: () => void;
	onComplete: () => void;
	onError: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxContactInfo: {
			background: '#fff',
			border: '1px solid #DDDDDD',
			[theme.breakpoints.down(wbp)]: {
				borderRadius: `${reduceResolution(18)}px`,
				padding: `${reduceResolution(50)}px ${reduceResolution(35)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				borderRadius: '18px',
				padding: '50px 35px'
			}
		},

		boxErrorInfo: {
			background: '#fff',
			color: '#EF4F57',
			[theme.breakpoints.down(wbp)]: {
				borderRadius: `${reduceResolution(18)}px`,
				padding: `${reduceResolution(10)}px ${reduceResolution(35)}px`,
				marginTop: `${reduceResolution(20)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				borderRadius: '18px',
				padding: '10px 35px',
				marginTop: '20px'
			}
		},

		typoTitle: {
			[theme.breakpoints.down(wbp)]: {
				padding: `${reduceResolution(20)}px 0`
			},
			[theme.breakpoints.up(wbp)]: {
				padding: '20px 0'
			}
		}
	})
);

export interface CompletedInfo {
	success: boolean;
	message: string;
	sessionId: string;
}

export const OrderPaymentMethod: FunctionComponent<
	OrderPaymentMethodProps
> = props => {
	const classes = useStyles();
	const terminalCommonClasses = useTerminalCommonStyles();

	const [showPaymentError, setShowPaymentError] = useState<boolean>(false);
	const [messageError, setMessageError] = useState<string>();

	const completePaymentHandler = (info: CompletedInfo) => {
		info.success
			? successPaymentHandler()
			: errorPaymentHandler(info.message);
	};

	const successPaymentHandler = () => {
		setShowPaymentError(false);
		props.onComplete();
	};

	const errorPaymentHandler = (message: string) => {
		setMessageError(message);
		if (message.length > 0) setShowPaymentError(true);
		props.onError();
	};

	return (
		<Box>
			<Box className={classes.typoTitle}>
				<Typography className={clsx(terminalCommonClasses.typo20_600)}>
					Payment Method
				</Typography>
				<Typography className={terminalCommonClasses.typo15_400}>
					Your payment is 100% secure.
				</Typography>
			</Box>

			<Box className={classes.boxContactInfo}>
				<AdyenPayment
					adminInvoiceId={props.invoiceId}
					onComplete={completePaymentHandler}
					onError={errorPaymentHandler}
					hideRemovePaymentMethodButton={true}
					hideStoredPaymentMethods={true}
				/>
			</Box>
			{showPaymentError && (
				<Box className={classes.boxErrorInfo}>{messageError}</Box>
			)}
		</Box>
	);
};

export default OrderPaymentMethod;
