import React, { FunctionComponent, useEffect, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltLines as faIcon } from '@fortawesome/pro-regular-svg-icons';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { TwoWaySMSPath } from 'routes/Paths';
import { headerHeight, reduceResolution, wbp } from 'Theme';
import { useHasPremiumAccess } from 'hooks';
import clsx from 'clsx';
import { fetchTwoWaySmsCountThunk } from 'actions/twoWaySms/twoWaySmsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { isNumber } from 'lodash';
import { Link as LinkRouter } from 'react-router-dom';

interface Props {
	messageAmount?: number;
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'width': 40,
			'height': 40,
			'cursor': 'pointer',
			'position': 'relative',
			'borderRadius': '50px',
			'display': 'inline-flex',
			'alignItems': 'center',
			'justifyContent': 'center',
			'backgroundColor': 'transparent',
			'transition': 'background-color 0.2s ease-out',

			'&:hover': {
				'backgroundColor': '#ededed',
				'& + #label': {
					display: 'flex'
				}
			}
		},
		customBadge: {
			top: 0,
			right: 26,
			zIndex: 0,
			left: 'auto',
			color: '#fff',
			fontSize: 12,
			lineHeight: 1,
			fontWeight: 600,
			borderRadius: 3,
			transform: 'none',
			alignItems: 'center',
			padding: '2px 6px 1px',
			justifyContent: 'center',
			backgroundColor: '#EF4F57'
		},
		icon: {
			fontSize: 24,
			color: '#000000'
		},
		labelContainer: {
			zIndex: 10,
			top: headerHeight,
			position: 'absolute',
			backgroundColor: '#000',

			[theme.breakpoints.down(wbp)]: {
				right: `-${reduceResolution(45)}%`,
				borderRadius: `${reduceResolution(4)}px`,
				padding: `${reduceResolution(4)}px ${reduceResolution(
					10
				)}px ${reduceResolution(4)}px ${reduceResolution(10)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				borderRadius: '4px',
				padding: '4px 10px 4px 10px'
			}
		},
		labelContainerVisible: {
			display: 'block'
		},
		labelContainerInVisible: {
			display: 'none'
		},
		label: {
			color: '#FFFFFF',
			fontFamily: 'Poppins',
			fontWeight: 500,
			lineHeight: '120%',
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px'
			}
		}
	})
);

export const MessageIcon: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const hasReportsPremiumAccess = useHasPremiumAccess('two_way_sms');

	const [iconHover, setIconHover] = useState(false);
	const messageAmount = useSelector<RootState, number>(
		state => state.twoWaySms.messageAmount
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchTwoWaySmsCountThunk());
		// eslint-disable-next-line
	}, []);

	const onIconHover = () => {
		setIconHover(true);
	};

	const onIconUnHover = () => {
		setIconHover(false);
	};

	return hasReportsPremiumAccess ? (
		<LinkRouter to={TwoWaySMSPath}>
			<Badge
				onClick={props.onClick}
				onMouseOver={onIconHover}
				onMouseOut={onIconUnHover}
				classes={{ badge: classes.customBadge }}
				className={classes.container}
				badgeContent={isNumber(messageAmount) ? messageAmount : 0}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<FontAwesomeIcon className={classes.icon} icon={faIcon} />
				<Box
					id="label"
					className={clsx(
						classes.labelContainer,
						iconHover
							? classes.labelContainerVisible
							: classes.labelContainerInVisible
					)}
				>
					<Typography className={classes.label}>Messaging</Typography>
				</Box>
			</Badge>
		</LinkRouter>
	) : (
		<></>
	);
};

export default MessageIcon;
