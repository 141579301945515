import { FieldError, Option } from "@spike/model";
import SelectorFieldSearch from "./SelectorFieldSearch";
import { fetchProductsThunk } from "@spike/products-action";
import { Product } from "@spike/product-model";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useApiClientWrapper } from "hooks";

interface Props {
  name?: string;
  excludedIds?: Array<number>;
  errors?: Array<FieldError>;
  className?: string;
  onSelect?: (product: Product) => void;
}

export const ProductSelector: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const products = useSelector<RootState, Array<Product>>((state) => state.products.list)
    .filter((product) => product.active)
    .filter((product) => product.businessProduct?.availableForCheckout)
    .filter(product => props.excludedIds === undefined || !props.excludedIds.includes(product.businessProduct!.id!));


  const options: Array<Option<number>> = products.map((product) => ({
    id: product.businessProduct?.id!,
    name: product.businessProduct?.name || product.name,
  }));

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProductsThunk(apiClientWrapper));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandler = (option: Option<string | number> | undefined) => {
    const selectedProduct = products.find((product) => product.businessProduct?.id === option!.id);
    props.onSelect && selectedProduct && props.onSelect(selectedProduct);
  };

  return (
    <SelectorFieldSearch
      {...props}
      options={options}
      placeholder="Search product"
      onSelect={selectHandler}
    />
  );
};

export default ProductSelector;
