import { Option, PetType } from '@spike/model';
import { v4 as createUuid } from 'uuid';
import { MasterData } from '@spike/masterdata-model';
import { Marketplace, MarketplaceTax } from '@spike/marketplace-model';
import { Status } from 'model';

export interface Service {
    id?: number;
    uuid: string;
    name: string;
    description: string;
    duration: ServiceDuration;
    pricing: ServicePricing;
    businessArea?: Option<string>;
    petTypes: Array<PetType>;
    staff: Array<StaffMember>;
    status: Option<number>;
    tags: Array<string>;
    destroyable: boolean;
    editable: boolean;
    hasReport: boolean;
    houseCall: boolean;
    olbEnabled: boolean;
}

export interface StaffMember {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
}

export interface StaffService {
    uuid: string;
    name: string;
}

export interface ServiceDuration {
    simple?: Duration;
    variable: Array<VariableDuration>;
}

export interface Duration {
    hours: number;
    minutes: number;
}

export interface VariableDuration {
    petSize: Option<string>;
    duration: Duration;
}

export interface ServicePricing {
    taxable: boolean;
    taxes: Array<MarketplaceTax>;
    fixedPrice: Array<FixedPriceOption>;
    variablePrice?: VariablePrice;
}

export interface FixedPriceOption {
    petType: PetType;
    price?: number;
}

export interface VariablePrice {
    variables: VariablePriceVariables;
    prices: Array<VariablePriceOption>;
}

export interface VariablePriceConstants {
    DOGS: string;
    CATS: string;
    BY_PET_SIZE: string;
    BY_PET_SIZE_AND_HAIR_LENGTH: string;
    BY_HAIR_LENGTH: string;
}

export interface OptionsCache {
    dogs: Option<string>;
    cats: Option<string>;
}

export interface VariablePriceCache {
    petType: string;
    priceType: string;
    variablePrice?: VariablePrice;
}

export interface VariablePriceVariables {
    petTypeVariable: boolean;
    petSizeVariable: boolean;
    hairTypeVariable: boolean;
    hairLengthVariable: boolean;
    exoticTypeVariable: boolean;
}

export interface VariablePriceOption {
    id?: number;
    uuid: string;
    petType?: PetType;
    petSize?: Option<string>;
    hairType?: Option<string>;
    hairLength?: Option<string>;
    exoticType?: Option<string>;
    price?: number;
}

export interface UnifiedPriceObject {
    hairLengths: Array<Option<string>>;
    petSize: Option<string> | undefined;
    petType: PetType | undefined;
    prices: Array<VariablePriceOption>;
}

export interface AddonService {
    name: string;
    price: number;
    status: AddonServiceStatus;
}

export enum AddonServiceStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export enum SortFields {
    NAME = 'name',
    DURATION = 'duration'
}

export enum SortOrder {
    ASCENDANT = 'ascendant',
    DESCENDANT = 'descendant',
    DEFAULT = 'default'
}

export const createEmptyService = (masterData: MasterData, marketplace: Marketplace): Service => {
    return {
        uuid: createUuid(),
        businessArea: marketplace.businessAreas[0],
        name: '',
        description: '',
        duration: { simple: { hours: 0, minutes: 0 }, variable: [] },
        pricing: {
            taxable: false,
            taxes: [],
            fixedPrice: [],
            variablePrice: undefined
        },
        petTypes: [],
        staff: [],
        status: masterData.status.find(s => s.id === Status.DRAFT)!,
        tags: [],
        destroyable: true,
        editable: true,
        hasReport: true,
        houseCall: marketplace.additionalInfo.mobileBusiness,
        olbEnabled: true
    };
};

export default Service;
