import {
  NewFeaturesAction,
  NEW_FEATURES_RESET_ACTION_TYPE,
  NEW_FEATURES_FETCH_START_ACTION_TYPE,
  NEW_FEATURES_FETCH_SUCCESS_ACTION_TYPE,
  NEW_FEATURES_MARK_AS_READ_START_ACTION_TYPE,
  NEW_FEATURES_MARK_AS_READ_SUCCESS_ACTION_TYPE,
  NEW_FEATURES_ERROR_ACTION_TYPE,
} from "./NewFeaturesActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { NewFeaturesState } from "reducers/newFeatures/NewFeaturesState";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction }from "@spike/notifications-action";
import { showError }from "@spike/notifications-action";
import { convert } from "./NewFeaturesConverter";
import store from "store";
import { NewFeature } from "model/NewFeature";
import { NewFeatureDto } from "./NewFeaturesDtos";

export const resetThunk = (): ThunkAction<void, NewFeaturesState, null, NewFeaturesAction> => {
  return async (dispatch) => {
    dispatch(reset());
  };
};

const newFeaturesUrl = "/marketing_videos";
const markAsReadUrl = "/staffs";

export const fetchThunk = (): ThunkAction<void, NewFeaturesState, null, NewFeaturesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(fetchStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${newFeaturesUrl}?marketplace_id=${marketplaceId}`;

    try {
      const response: AxiosResponse<Array<NewFeatureDto>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchSuccess(response.data.map((dto) => convert(dto))));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Fetching new features error."));
      }
    }
  };
};

export const markAsReadThunk = (
  staffId: number,
  readFeatureIds: Array<number>
): ThunkAction<void, NewFeaturesState, null, NewFeaturesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(markAsReadStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${markAsReadUrl}/${staffId}?marketplace_id=${marketplaceId}`;
    const body = {
      content_displays_attributes: readFeatureIds.map((featureId) => ({ content_id: featureId })),
    };

    try {
      await ApiClient.patch(url, body, createTokenConfig(store.getState().login.auth.token!));
      dispatch(markAsReadSuccess(readFeatureIds));
    } catch (apiError) {
      if(!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Marking features as read."));
      }
    }
  };
};

const reset = (): NewFeaturesAction => {
  return {
    type: NEW_FEATURES_RESET_ACTION_TYPE,
  };
};

const fetchStart = (): NewFeaturesAction => {
  return {
    type: NEW_FEATURES_FETCH_START_ACTION_TYPE,
  };
};

const fetchSuccess = (newFeatures: Array<NewFeature>): NewFeaturesAction => {
  return {
    type: NEW_FEATURES_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      newFeatures,
    },
  };
};

const markAsReadStart = (): NewFeaturesAction => {
  return {
    type: NEW_FEATURES_MARK_AS_READ_START_ACTION_TYPE,
  };
};

const markAsReadSuccess = (readFeatureIds: Array<number>): NewFeaturesAction => {
  return {
    type: NEW_FEATURES_MARK_AS_READ_SUCCESS_ACTION_TYPE,
    payload: {
      readFeatureIds,
    },
  };
};

const error = (): NewFeaturesAction => {
  return {
    type: NEW_FEATURES_ERROR_ACTION_TYPE,
  };
};
