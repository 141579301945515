import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { faSmile, faAngry, faMeh, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { ItemLabelRead } from "components/UI/ItemLabelRead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pet from "@spike/pet-model";
import { useMasterData } from "hooks";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";

interface PetPersonalityProps {
  pet: Pet;
  label?: string;
  className?: string;
}

export interface Personality {
  behaviorConditionId: number;
  idBehaviour?: number;
  categoryID: number;
  name: string;
  icon: IconDefinition;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxFlexRow: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
    },
    iconFace: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(8)}px`,
        marginTop: `${reduceResolution(3)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "8px",
        marginTop: "3px",
      },
    },
    itemText: {
      fontWeight: 400,
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(14)}px`,
        paddingTop: `${reduceResolution(4)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "14px",
        paddingTop: "4px",
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 400,
      color: '#A3A3A3',
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    description: {
      fontSize: 18,
      fontWeight: 400,
      color: '#000000',
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        fontWeight: 500,
      },
    },
    classIcon: {
      height: 21,
      width: 21,
      [theme.breakpoints.down('sm')]: {
        height: 20,
        width: 20
      }
    }
  })
);

export const PetPersonality: FunctionComponent<PetPersonalityProps> = (props) => {
  const classes = useStyles();
  const masterData = useMasterData();

  const [listPersonality, setListPersonality] = useState<Array<Personality>>();
  const [personalitySelected, setPersonalitySelected] = useState<Personality>();

  useEffect(() => {
    loadPersonality();
  }, [props.pet]);

  useEffect(() => {
    if ((props.pet && props.pet.personalities.length > 0) && (listPersonality !== undefined)) {
      const selection = listPersonality?.filter((p) => p.behaviorConditionId === props.pet!.personalities[0]!.conditionId);
      setPersonalitySelected(selection![0]);
    }
  }, [listPersonality]);

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const loadPersonality = () => {
    const array: Array<Personality> = [];

    masterData.medicalHistory.behaviorConditionCategories
      .forEach((behavior) => {
        behavior.options.forEach((option) => {
          array.push({ behaviorConditionId: option.id, categoryID: behavior.id, name: option.name, icon: iconSearch(behavior.icon) });
        })
      })
    setListPersonality(array);
  };


  const iconSearch = (icon: string | undefined) => {
    switch (icon) {
      case "faSmile":
        return faSmile;
      case "faAngry":
        return faAngry;
      case "faMeh":
        return faMeh;
      default:
        return faSmile;
    }
  }

  const descriptionPersonality = () => {
    let description;

    if (personalitySelected) {
      description = (<Box className={classes.boxFlexRow}>
        {iconSVG(personalitySelected.icon, `${clsx([classes.iconFace, classes.classIcon])}`)}
        <Typography className={clsx([classes.itemText, classes.description])}>{personalitySelected?.name}</Typography>
      </Box>);
    }
    else {
      description = (<Box className={classes.boxFlexRow}>
        {iconSVG(faSmile, `${clsx([classes.iconFace, classes.classIcon])}`)}
        <Typography className={clsx([classes.itemText, classes.description])}>Friendly</Typography>
      </Box>);
    };

    return description;
  }





  return (
    <Box className={clsx(props.className)}>
      <ItemLabelRead title={props.label} description={descriptionPersonality()} classDescription={classes.description} classTitle={classes.title} />
    </Box>);
};

export default PetPersonality;
