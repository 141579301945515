import { ChangeEvent, FunctionComponent, InputHTMLAttributes } from "react"

import { useSwitchStyles } from "./Switch.styles"
import clsx from "clsx"

// type Variants = 'default'

interface SwitchProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'className' | 'onChange'> {
  // variant:Variant,
  onCheck: (name: string, checked: boolean) => void
}

const Switch: FunctionComponent<SwitchProps> = (props) => {

  const classes = useSwitchStyles()

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    props.onCheck && props.onCheck(event.target.name, event.target.checked)
  }


  return <div className={clsx(classes.root, {
    [classes.checked]: props.checked,
    [classes.disabled]: props.disabled
  })}
  >
    <span
      className={clsx(classes.slide, {
        [classes.slideChecked]: props.checked
      })}></span>
    <input className={classes.input} onChange={changeHandler} type="checkbox" {...props} />
  </div>

}

export default Switch