import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import ControlledNumberField2 from './ControlledNumberField2';
import { NumberField2Props } from './NumberField2';
import { Theme } from '@fullcalendar/core/internal';

export interface WeightField2Props extends Omit<NumberField2Props, 'endIcon' | 'startIcon' | 'scale' | 'precision'> {
    petSizeName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative'
        },
        sizeTag: {
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            backgroundColor: '#000',
            justifyItems: 'center',
            alignItems: 'center',
            borderRadius: 21,
            padding: '2px 10px 2px 10px'
        },
        sizeText: {
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 600,
            color: '#FFF',
            textTransform: 'capitalize'
        }
    })
);

export const ControlledWeightField2: FunctionComponent<WeightField2Props> = props => {
    const classes = useStyles(props);

    const petSizeTag = (
        <Box className={classes.sizeTag}>
            <Typography className={classes.sizeText}>{props.petSizeName}</Typography>
        </Box>
    );

    return (
        <Box className={classes.container}>
            {props.petSizeName !== undefined && petSizeTag}
            <ControlledNumberField2 {...props} precision={3} scale={0} endIcon={<Typography>Lbs</Typography>} />
        </Box>
    );
};

export default ControlledWeightField2;
