import React, { FunctionComponent, useState, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '../UI';
import { reduceResolution, wbp } from 'Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/UI/V2/Button/Button';

interface Props {
    onSearch?: (text: string) => void;
    hasServices?: boolean;
    onAddService?: () => void;
    disableAddButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '15px',
                paddingRight: '15px',
                flexDirection: 'column',
                position: 'relative'
            }
        },
        leftContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '75px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '75px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '75px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '100px'
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '0px',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }
        },
        rightContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginRight: '58px'
            },
            [theme.breakpoints.only('md')]: {
                marginRight: '78px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '105px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '140px'
            },
            [theme.breakpoints.down('md')]: {
                position: 'absolute',
                top: '-5px',
                right: '15px',
                marginRight: '0px'
            }
        },
        title: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '15px',
                fontWeight: 500
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '20px',
                fontWeight: 600
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '27px',
                fontWeight: 600
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '36px',
                fontWeight: 600
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                fontWeight: 600
            }
        },
        sort: {
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '22px',
                fontSize: '28px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '30px',
                fontSize: '20px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '40px',
                fontSize: '22px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '54px',
                fontSize: '24px'
            }
        },
        search: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: '160px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '13px',
                width: '200px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '18px',
                width: '280px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '24px',
                width: '328px'
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '0px',
                backgroundColor: '#F1F1F1',
                padding: '12px 11px',
                borderRadius: '12px',
                width: '100%',
                marginTop: '25px',
                position: 'relative'
            }
        },
        searchInput: {
            [theme.breakpoints.down('md')]: {
                'width': '100%',
                'position': 'relative',
                '& label': {
                    display: 'none'
                },
                '& .MuiOutlinedInput-root': {
                    height: '47px',
                    width: '100%',
                    backgroundColor: '#FFF',
                    borderRadius: '26px'
                },
                '& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder':
                    {
                        opacity: '1 !important',
                        color: '#7A7A7A'
                    },
                '& input': {
                    fontSize: '14px',
                    color: '#000',
                    paddingLeft: '40px'
                }
            }
        },
        searchIcon: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                fontSize: '18px',
                color: '#000',
                position: 'absolute',
                top: '27px',
                left: '24px',
                display: 'inline-block',
                zIndex: 9
            }
        },
        addServiceButton: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(30)}px`,
                paddingTop: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '30px',
                paddingTop: '30px'
            },
            '& .MuiButton-label': {
                fontWeight: 500
            },
            [theme.breakpoints.down('md')]: {
                'paddingBottom': '15px',
                'paddingTop': '15px',
                'display': 'inline-flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                'height': '40px',
                '& span': {
                    fontSize: '14px',
                    fontWeight: '600'
                }
            }
        }
    })
);

export const ServicesHeader: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const [searchText, setSearchText] = useState('');

    const searchTextChangeHandler = (text: string): void => {
        setSearchText(text);
        props.onSearch && props.onSearch(text);
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.leftContainer}>
                <Typography className={classes.title}>
                    Primary Services
                </Typography>
                {props.hasServices !== false ? (
                    <Fragment>
                        <Box className={classes.search}>
                            <FontAwesomeIcon
                                className={classes.searchIcon}
                                icon={faSearch}
                            />
                            <TextField
                                id="services_list_input_search"
                                value={searchText}
                                label="Search by service name"
                                onChange={searchTextChangeHandler}
                                className={classes.searchInput}
                                placeholder="Search by service name"
                            />
                        </Box>
                    </Fragment>
                ) : null}
            </Box>
            <Box className={classes.rightContainer}>
                {props.hasServices !== false ? (
                    <Button
                        leftIcon={faPlus}
                        label="Add Service"
                        id="services_button_add_service"
                        disabled={props.disableAddButton}
                        onClick={() => {
                            props.onAddService && props.onAddService();
                        }}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default ServicesHeader;
