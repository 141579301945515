import { Typography } from "@material-ui/core";
import moment from "moment-timezone";
import { FunctionComponent, useEffect, useState } from "react";
import { useMarketplace, useMasterData } from "hooks";
import { Option } from "@spike/model";

interface Props {
  className?: string;
}

const getHour = (timeZoneCode: string, timeZones: Array<Option<string>>) => {
  const timeZoneLabel = timeZones.find(timeZone => timeZone.id === timeZoneCode)?.name || timeZoneCode;
  return `${moment().tz(timeZoneCode)?.format("MMM D, h:mm a")} - ${timeZoneLabel}`
}

export const DateTime: FunctionComponent<Props> = (props) => {
  const marketplace = useMarketplace();
  const timeZones = useMasterData().timeZones;
 
  const [date, setDate] = useState(getHour(marketplace.schedule.timeZone, timeZones));

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(getHour(marketplace.schedule.timeZone, timeZones));
    }, 1000);
    return () => clearInterval(interval);
  }, [marketplace]);

  return <Typography className={props.className}>{date}</Typography>;
};

export default DateTime;

