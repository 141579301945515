import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { ClientPet } from "@spike/client-model";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";

import {
  faCat,
  faDog,
  faNarwhal,
} from "@fortawesome/pro-light-svg-icons"

interface ChipPetSiblingProps {
  pet: ClientPet;
  selected?: boolean;
  className?: string;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      borderRadius: 20,
      background: "#E4DDD5",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "30px",
      padding: 4,
      [theme.breakpoints.up('md')]: {
        borderRadius: 31,
        padding: 5
      },
      "&:hover": {
        cursor: "pointer",
      }
    },
    containerDog: {
      background: "#E9F0ED",
      border: "2px solid #E9F0ED", 
      borderRadius: 20,
      height: "100%",
      [theme.breakpoints.up('md')]: {
        borderRadius: 31
      },
    },
    containerCat: {
      background: "#FAEFDF",
      border: "2px solid #FAEFDF",
      borderRadius: 20,
      height: "100%",
      [theme.breakpoints.up('md')]: {
        borderRadius: 31
      },
    },
    containerName: {
      width: "100%",
      display: "contents"
    },
    iconContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    icon: {
      color: "#fff",
      borderRadius: 20,
      background: "#BAA997",
      padding: 4,
      [theme.breakpoints.up('md')]: {
        padding: 5
      },
    },
    iconDog: {
      color: "#fff",
      background: "#92B4A7",
    },
    iconCat: {
      color: "#fff",
      background: "#DBA960",
    },
    selected: {
      border: "2px solid #000000"
    },
    name: {
      fontWeight: 500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingLeft: 5,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 7
      },
    }
  })
);

export const ChipPetSibling: FunctionComponent<ChipPetSiblingProps> = (props) => {
  const classes = useStyles();

  const icon = (pet: ClientPet) => {
    switch (pet.type?.id) {
      case "dogs":
        return faDog;
      case "cats":
        return faCat;
      default:
        return faNarwhal
    }
  }

  return (
    <Box className={clsx(
      classes.container,
      { [classes.selected ]: props.selected },
      { [classes.containerDog]: !isEmpty(props.pet?.type) && props.pet?.type?.id === "dogs" },
      { [classes.containerCat]: !isEmpty(props.pet?.type) && props.pet?.type?.id === "cats" },

      props.className)}>
      <Box className={classes.iconContainer}>
        <FontAwesomeIcon
          icon={icon(props.pet!)}
          className={clsx(classes.icon,
            { [classes.iconDog]: !isEmpty(props.pet?.type) && props.pet?.type?.id === "dogs" },
            { [classes.iconCat]: !isEmpty(props.pet?.type) && props.pet?.type?.id === "cats" },
          )}
        />
      </Box>
      <Box
        className={clsx(
          classes.containerName,
          { [classes.containerDog]: !isEmpty(props.pet?.type) && props.pet?.type?.id === "dogs" },
          { [classes.containerCat]: !isEmpty(props.pet?.type) && props.pet?.type?.id === "cats" },
        )}
      >
        <Typography className={classes.name}>{props.pet.name}</Typography>
      </Box>
    </Box>
  );
};

export default ChipPetSibling;
