import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

interface DeleteButtonProps {
	className?: string;
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			cursor: 'pointer',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 60,
			backgroundColor: '#F1F1F1',
			width: 60,
		},
	})
);

export const DeleteButton: FunctionComponent<DeleteButtonProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)} onClick={props.onClick}>
			<FontAwesomeIcon icon={faTrashAlt} fontSize={20}/>
		</Box>
	);
};

export default DeleteButton;
