import { ActivateAccountState } from "./ActivateAccountState";
import {
  ACTIVATE_ACCOUNT_RESET_ACTION_TYPE,
  ACTIVATE_ACCOUNT_START_ACTION_TYPE,
  ACTIVATE_ACCOUNT_END_ACTION_TYPE,
  ACTIVATE_ACCOUNT_FAIL_ACTION_TYPE,
  ActivateAccountAction,
} from "../../actions/activateAccount/ActivateAccountActionsTypes";
import { Reducer } from "redux";

const initialState: ActivateAccountState = {
  failed: false,
  loading: false,
};

export const activateAccountReducer: Reducer<ActivateAccountState, ActivateAccountAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ACTIVATE_ACCOUNT_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case ACTIVATE_ACCOUNT_START_ACTION_TYPE:
      return {
        ...state,
        loading: true,
        failed: false,
      };
    case ACTIVATE_ACCOUNT_END_ACTION_TYPE:
      return {
        ...state,
        loading: false,
      };
    case ACTIVATE_ACCOUNT_FAIL_ACTION_TYPE:
      return {
        ...state,
        failed: true,
      };

    default:
      return state;
  }
};

export default activateAccountReducer;
