import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { wbp, reduceResolution } from 'Theme';

export const useFieldStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		labelContainer: {
			width: '100%',
			display: 'flex',
			position: 'relative',
			paddingLeft: 24
		},
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: '#222222',
			marginBottom: 2,

			[theme.breakpoints.up('md')]: {
				fontSize: '16px',
				lineHeigth: '27px'
			}
		},
		labelError: {
			color: '#EF4F57'
		},
		required: {
			fontWeight: 600,
			color: '#EAB464',
			[theme.breakpoints.down(wbp)]: {
				marginTop: `-${reduceResolution(3)}px`,
				fontSize: `${reduceResolution(16)}px`,
				lineHeigth: `${reduceResolution(27)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeigth: '27px',
				marginTop: '-3px'
			}
		},
		fieldContainer: {
			width: '100%',
			marginTop: '2px'
		},
		field: {
			'width': '100%',
			'& input': {
				fontSize: 16,
				fontWeight: 400,
				lineHeight: 1.7,
				color: '#222222',
				fontFamily: 'Poppins',
				padding: '14px 35px 14px 35px'
			},
			'& .MuiOutlinedInput-root': {
				[theme.breakpoints.down(wbp)]: {
					borderRadius: `${reduceResolution(33)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					borderRadius: '33px'
				},
				'& .MuiOutlinedInput-input:-webkit-autofill': {}
			},
			'& fieldset': {
				border: '1px solid #222222',
				borderColor: '#222222 !important',
				[theme.breakpoints.down(wbp)]: {
					borderRadius: `${reduceResolution(33)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					borderRadius: '33px'
				}
			},
			'& input.focus-visible ~ fieldset': {
				border: '1px solid #222222 !important'
			},
			'& .MuiOutlinedInput-multiline': {
				'height': '100%',
				'& .MuiOutlinedInput-inputMultiline': {
					height: '100% !important'
				}
			},
			'& div.Mui-disabled': {
				backgroundColor: '#f5f5f5',
				color: '#7A7A7A',
				[theme.breakpoints.down(wbp)]: {
					borderRadius: `${reduceResolution(33)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					borderRadius: '33px'
				}
			}
		},
		fieldWithStartAdornment: {
			'& input': {
				paddingLeft: '0px'
			},
			'& .MuiInputAdornment-positionStart': {
				paddingLeft: '0px !important',
				[theme.breakpoints.down(wbp)]: {
					paddingRight: `${reduceResolution(10)}px !important`
				},
				[theme.breakpoints.up(wbp)]: {
					paddingRight: '10px !important'
				}
			}
		},
		fieldWithEndAdornment: {
			'& input': {
				paddingRight: '0px'
			},
			'& .MuiInputAdornment-positionEnd': {
				paddingRight: '0px !important',
				[theme.breakpoints.down(wbp)]: {
					paddingLeft: `${reduceResolution(10)}px !important`
				},
				[theme.breakpoints.up(wbp)]: {
					paddingLeft: '10px !important'
				}
			}
		},
		descriptionField: {
			'display': 'flex',
			'width': '100%',
			'padding': '0px',
			'& textarea': {
				'color': '#222',
				'fontSize': 16,
				'lineHeight': 1.4,
				'fontWeight': 400,
				'padding': '16px 20px',

				'&::placeholder': {
					opacity: 1,
					color: '#7A7A7A'
				},

				[theme.breakpoints.up('md')]: {
					fontSize: 16,
					lineHeight: 1.4,
					padding: '16px 35px'
				}
			},
			'& fieldset': {
				border: '1px solid #222222',
				[theme.breakpoints.down(wbp)]: {
					borderRadius: `${reduceResolution(18)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					borderRadius: '18px'
				}
			},
			'& textarea.focus-visible ~ fieldset': {
				border: '1px solid #222222 !important'
			},
			'&.Mui-disabled': {
				backgroundColor: '#f5f5f5',
				color: '#7A7A7A',
				[theme.breakpoints.down(wbp)]: {
					borderRadius: `${reduceResolution(18)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					borderRadius: '18px'
				}
			}
		},
		emptyField: {
			'& fieldset': {
				border: '1px solid #D3D3D3 !important'
			},
			'& .MuiInputAdornment-root': {
				color: '#7A7A7A'
			},
			'& .MuiInputBase-input::placeholder': {
				opacity: 1,
				color: '#7A7A7A !important'
			}
		},
		disabledField: {
			'& fieldset': {
				border: '1px solid #D3D3D3 !important',
				color: '#7A7A7A'
			},
			'& .MuiInputAdornment-root': {
				color: '#7A7A7A'
			},
			'& fieldset :hover': {
				cursor: 'not-allowed'
			},
			'& .MuiInputBase-input.Mui-disabled': {
				color: '#7A7A7A'
			}
		},
		fieldError: {
			'& fieldset': {
				border: '1px solid #EF4F57 !important'
			}
		},
		errorBorder: {
			borderColor: '#EF4F57 !important'
		},
		startIcon: {
			'& input': {
				paddingLeft: '0px'
			},
			'& .MuiInputAdornment-positionStart': {
				[theme.breakpoints.down(wbp)]: {
					paddingLeft: `${reduceResolution(20)}px`,
					marginRight: `${reduceResolution(5)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					paddingLeft: '20px',
					marginRight: '5px'
				}
			}
		},
		endIcon: {
			'& input': {
				paddingRight: '0px'
			},
			'& .MuiInputAdornment-positionEnd': {
				[theme.breakpoints.down(wbp)]: {
					paddingRight: `${reduceResolution(20)}px`,
					marginLeft: `${reduceResolution(5)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					paddingRight: '20px',
					marginLeft: '5px'
				}
			}
		},
		adornmentText: {
			fontFamily: 'Poppins',
			fontWeight: 400,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(16)}px`,
				lineHeight: `${reduceResolution(27)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '16px',
				lineHeight: '27px'
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
				lineHeight: '27px'
			}
		},
		inputTextRight: {
			'& input': {
				textAlign: 'right'
			}
		},
		inputTextLeft: {
			'& input': {
				textAlign: 'left'
			}
		},
		disabled: {
			'backgroundColor': '#f5f5f5',
			'& div': {
				cursor: 'default'
			}
		}
	})
);
