import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import { useCommonStyles } from '../CommonStyles';
import clsx from 'clsx';

interface ButtonProps {
	icon: IconDefinition;
	size?: number;
	className?: string;
	onClick?: () => void;
}

export const Button: FunctionComponent<ButtonProps> = props => {
	const commonClasses = useCommonStyles();

	return (
		<FontAwesomeIcon
			icon={props.icon}
			className={clsx(commonClasses.icon, props.className)}
			fontSize={props.size ?? 16}
			onClick={props.onClick}
		/>
	);
};

export default Button;
