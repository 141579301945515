import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Option } from '@spike/model';
import { useMasterData } from 'hooks';
import { FunctionComponent, useState, useEffect } from 'react';
import PaymentMethod from './PaymentMethod';
import Section from '../Section';
import { getPaymentMethods } from '@spike/payments-model';

interface PaymentMethodsProps {
    selectedId: string | null;
    paymentsEnabled: boolean;
    isFreeServicesInvoice?: boolean;
    className?: string;
    disabled?: boolean;
    source?: string;
    hasPremiumPlan?: boolean;
    onSelect: (paymentMethod: Option<string>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
        }
    })
);

export const PaymentMethods: FunctionComponent<PaymentMethodsProps> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const [paymentMethods, setPaymentMethods] = useState<Option<string>[]>([]);

    useEffect(() => {
        const fetchedPaymentMethods = getPaymentMethods(
            masterData,
            props.paymentsEnabled
        );

        // Move "Pay by Link" to the end of the array
        const sortedPaymentMethods = fetchedPaymentMethods.sort((a, b) => {
            if (a.id === 'pay_by_link') return 1;
            if (b.id === 'pay_by_link') return -1;
            return 0;
        });

        setPaymentMethods(sortedPaymentMethods);
    }, [masterData, props.paymentsEnabled, props.source]);

    // Filter the payment methods to exclude "Pay By Link" if source is not "booking"
    const filteredPaymentMethods = paymentMethods.filter(
        method => props.source === 'booking' || method.id !== 'pay_by_link'
    );

    return (
        <Section title="Payment Method" className={props.className}>
            <Grid container spacing={2}>
                {filteredPaymentMethods.map(paymentMethod => (
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.cell}
                        key={paymentMethod.id}
                    >
                        <PaymentMethod
                            {...paymentMethod}
                            onClick={() =>
                                props.hasPremiumPlan !== false ||
                                paymentMethod.id !== 'pay_by_link'
                                    ? props.onSelect(paymentMethod)
                                    : null
                            }
                            selected={props.selectedId === paymentMethod.id}
                            disabled={
                                paymentMethod.id === 'pos' &&
                                props.isFreeServicesInvoice
                                    ? true
                                    : props.disabled
                            }
                            showPremiumBadge={
                                !props.hasPremiumPlan &&
                                paymentMethod.id === 'pay_by_link'
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
};

export default PaymentMethods;
