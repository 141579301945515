import { IMethodParam, IPropsUseSetTrackEvent } from '../model/Hubspot'

export type IPushParams = [IMethodParam, (string | object)?]

declare global {
    interface Window {
        _hsq: any
        
    }
}

/**
 * The shape of the hook
 */
export type IUseHubspotTrackingCode = {
    setContentType: (contentType: string) => void
    setPathPageView: (path: string) => void
    setTrackPageView: () => void
    setIdentity: (email: string, customPropertities?: {}) => void
    setTrackEvent: (eventId: string) => void
}

export const useHubspotTrackingCode = (): IUseHubspotTrackingCode => {

    const _hsq = typeof window !== 'undefined' && window._hsq ? window._hsq : []
    
    const setContentType = (contentType: string): void => {
        _hsq.push(['setContentType', contentType])
    }

    const setTrackPageView = () => {
        _hsq.push(['trackPageView'])
    }

    const setPathPageView = (path: string): void => {
        // This function updates the path
        _hsq.push(['setPath', path])
        // This function track the current updated page path
        setTrackPageView()
    }

    const setIdentity = (email: string, customPropertities?: {}) => {
        _hsq.push([
            'identify',
            {
                email,
                ...customPropertities
            }
        ])
    }

    const setTrackEvent = (eventId : string) => {
        _hsq.push([
            'trackEvent',
            {
                id: eventId
            }
        ])
    }

    return {
        setContentType,
        setPathPageView,
        setTrackPageView,
        setIdentity,
        setTrackEvent
    }
}

export default useHubspotTrackingCode