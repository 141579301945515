// src/components/PayByLink/styles/PayByLinkStyles.ts
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { max } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            transition: 'all 0.33s linear',
            paddingBottom: 50,
            width: '100%',
            height: '100%',
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 15
            }
        },
        body: {
            'paddingTop': 40,
            'flex': 'auto',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'width': '100%',
            'maxWidth': 600,
            'paddingLeft': 15,
            'paddingRight': 15,
            '& hr': {
                border: 'none',
                margin: '35px 0px',
                borderTop: 'solid 1px #D4D4D4'
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 10
            }
        },
        spinnerStyle: {
            height: '100%'
        },
        wrapper: {
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
        },
        logo: {
            'width': 120,
            'borderRadius': 8,
            'overflow': 'hidden',
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'marginRight': theme.spacing(2),
            '& img': {
                maxWidth: '100%',
                display: 'block'
            }
        },
        storeInfo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid #D4D4D4',
            paddingBottom: 20,
            marginBottom: 30,
            [theme.breakpoints.down('md')]: {
                display: 'block',
                paddingBottom: 30
            }
        },
        storeInfoIcon: {
            minWidth: 15
        },
        storeDetails: {
            display: 'flex',
            flexDirection: 'column'
        },
        storeName: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: 1.2
        },
        storeContact: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            fontSize: 14,
            color: '#000'
        },
        storeAddress: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 5,
            fontSize: 14,
            lineHeight: 1.2,
            color: '#000'
        },
        boxDefault: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            backgroundColor: '#f9f9f9',
            padding: theme.spacing(2),
            borderRadius: 12,
            marginBottom: theme.spacing(2)
        },
        bookingDetails: {
            display: 'flex'
        },
        bookingDetailsInfo: {
            paddingLeft: 15,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        customBadge: {
            width: 50,
            height: 50,
            backgroundColor: '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 50,
            color: '#fff',
            fontSize: 20,
            transform: 'none',
            position: 'relative'
        },
        wrapperTip: {
            position: 'relative',
            width: '100%',
            borderBottom: '1px solid #D4D4D4',
            paddingBottom: 25,
            paddingTop: 20,
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 20,
                paddingTop: 5
            }
        },
        tipContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: 15,
            gap: 15,
            flexWrap: 'wrap'
        },
        tipButton: {
            'display': 'inline-flex',
            'flex': '1 1',
            'border': '1px solid #D3D3D3',
            'borderRadius': 8,
            'padding': theme.spacing(1, 2),
            'fontSize': 18,
            'fontWeight': 600,
            'textAlign': 'center',
            'cursor': 'pointer',
            'height': 65,
            'alignItems': 'center',
            'justifyContent': 'center',
            'flexDirection': 'column',
            '&:hover': {
                backgroundColor: '#fcfcfc'
            },
            [theme.breakpoints.down('sm')]: {
                flex: '1 1 45%'
            }
        },
        selectedTipButton: {
            borderColor: '#000',
            boxShadow: '0 0 0 1px #000'
        },
        customTipContainer: {
            width: '100%',
            marginTop: 20,
            gap: theme.spacing(1)
        },
        customTipButton: {
            display: 'inline-flex',
            border: '1px solid #D3D3D3',
            borderRadius: '8px',
            padding: theme.spacing(1, 2),
            fontSize: 18,
            fontWeight: 600,
            textAlign: 'center',
            cursor: 'pointer',
            height: 54,
            width: 54,
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down(365)]: {
                width: 40,
                height: 40,
                padding: 8
            }
        },
        selectedCustomTipButton: {
            backgroundColor: '#000',
            color: '#fff'
        },
        customTipInput: {
            'flex': 1,
            'maxWidth': 175,
            'marginLeft': 5,
            'marginRight': 5,
            '& .MuiInputBase-root': {
                height: 54,
                fontSize: 16
            },
            [theme.breakpoints.down(365)]: {
                '& .MuiInputBase-adornedStart': {
                    paddingLeft: 6
                }
            }
        },
        customTipAction: {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'width': 40,
            'height': 40,
            'borderRadius': '50%',
            'backgroundColor': '#f0f0f0',
            'cursor': 'pointer',
            '&:hover': {
                backgroundColor: '#e0e0e0'
            }
        },
        customTipContainerEdit: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 8
        },
        customTipContainerAmount: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        customTipSet: {
            marginRight: 10,
            fontSize: 26,
            fontWeight: 600
        },
        tipInDollars: {
            display: 'block',
            color: '#999',
            fontSize: 13,
            fontWeight: 400
        },
        noTipContainer: {
            marginTop: 15
        },
        noTipRadioGroup: {
            display: 'inline-flex'
        },
        checkUIItem: {
            padding: '5px !important'
        },
        checkUILabel: {
            fontSize: '14px'
        },
        invoiceContent: {
            position: 'relative',
            width: '100%'
        },
        spinnerContent: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 250
        },
        amountContainer: {
            'width': '100%',
            'marginTop': 10,
            'marginBottom': 10,
            'paddingTop': 10,
            'paddingBottom': 10,
            '& strong': {
                fontWeight: 600
            }
        },
        amountContainerOrderDetails: {
            paddingBottom: 0,
            marginBottom: 0
        },
        amountContainerTitle: {
            fontSize: 18,
            fontWeight: 600
        },
        footer: {
            marginTop: 70,
            textAlign: 'center',
            paddingBottom: 30,
            [theme.breakpoints.down('sm')]: {
                marginTop: 40
            }
        },
        footerText: {
            textTransform: 'uppercase',
            fontSize: 10,
            letterSpacing: '0.96px',
            color: '#000',
            opacity: 0.5,
            marginBottom: 5,
            paddingLeft: 2
        },
        titleContainer: {
            width: '100%',
            backgroundColor: '#fff',
            borderBottom: '1px solid #D4D4D4',
            textAlign: 'center',
            padding: theme.spacing(2, 0),
            position: 'relative',
            top: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                borderBottom: 'none'
            }
        },
        title: {
            fontWeight: 500,
            fontSize: 20
        },
        bookingTitle: {
            fontWeight: 600,
            fontSize: 18,
            marginBottom: 5
        },
        divider: {
            'width': '100%',
            'flex': 'none',
            'margin': '5px 0px',
            '& hr': {
                margin: 0,
                backgroundColor: '#D4D4D4'
            }
        },
        orderDetailsHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer'
        },
        orderDetailsIcon: {
            transition: 'transform 0.3s'
        },
        orderDetailsIconOpen: {
            transform: 'rotate(180deg)'
        },
        orderDetailsTable: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            marginBottom: '0 !important'
        },
        paymentBlock: {
            width: '100%',
            display: 'block',
            paddingTop: 15
        },
        subtitle: {
            fontSize: 16
        },
        cuddlesLogo: {
            display: 'inline-block'
        },
        error: {
            color: '#FF0000'
        },
        button: {
            position: 'relative',
            width: '100%',
            minHeight: 50
        },
        payButtonBlock: {
            marginTop: 25,
            [theme.breakpoints.down('sm')]: {
                marginTop: 15
            }
        },
        dropinContainer: {
            paddingTop: 10
        },
        backButton: {
            'display': 'flex',
            'alignItems': 'center',
            'position': 'absolute',
            'top': 'auto',
            'bottom': 'auto',
            'left': 25,
            'cursor': 'pointer',
            '& svg': {
                marginRight: 8
            },
            '& p': {
                fontWeight: 500
            }
        },
        backButtonText: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'inline-block'
            }
        },
        alertBox: {
            'marginTop': 25,
            'marginBottom': 25,
            '& p': {
                'fontWeight': 500,
                'fontSize': 14,
                '& strong': {
                    fontWeight: 600
                }
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 10
            }
        },
        notFoundContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 200px)',
            width: '100%',
            flexDirection: 'column'
        },
        blockedCursor: {
            cursor: 'not-allowed',
            pointerEvents: 'none'
        }
    })
);

export default useStyles;
