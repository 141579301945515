import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import MetricBox from 'components/UI/MetricBox';
import { FunctionComponent } from 'react';
import { Metric } from './model';

interface MetricsProps {
	metrics: Array<Metric>;
	onChange?: (metric: Metric) => void;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%'
		},
		container: {
			gap: 20,
			[theme.breakpoints.down('sm')]: {
				gap: 0
			}
		}
	})
);

export const Metrics: FunctionComponent<MetricsProps> = props => {
	const classes = useStyles();

	return (
		<Box>
			<Grid spacing={2} container className={classes.container}>
				{props.metrics.map((metric, index) => (
					<Grid xs={12} md={3} item key={index}>
						<MetricBox
							value={metric.value}
							bgColor={metric.color}
							title={metric.title}
							icon={metric.icon}
							onClick={() => {
								props.onChange && props.onChange(metric);
							}}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default Metrics;
