import TabsHeader from 'components/UI/TabsHeader/TabsHeader';
import { FunctionComponent } from 'react';

interface TabsProps {
    title?: string;
    tabId: string;
    depositCollect?: boolean;
    onClose: () => void;
    onSelect: (tabId: string) => void;
}

export const bookingTabId = 'booking';
export const blockedTimeTabId = 'blocking';

export const Tabs: FunctionComponent<TabsProps> = props => {
    const tabOptions = [
        { id: bookingTabId, name: props.title ?? 'Add Appointment' },
        { id: blockedTimeTabId, name: 'Blocked Time' }
    ];

    const tabs = props.depositCollect
        ? tabOptions.filter(option => option.id === bookingTabId)
        : tabOptions;

    const selectTabHandler = (tabId: string) => {
        props.onSelect(tabId);
    };

    return (
        <TabsHeader
            tabs={tabs}
            depositCollect={props.depositCollect}
            selectedTabId={props.tabId}
            onClose={props.onClose}
            onClick={selectTabHandler}
        />
    );
};

export default Tabs;
