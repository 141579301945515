import { faHouseMedical, faStethoscope, faSyringe, faTooth } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MedicalConditions from "./MedicalConditions";
import ViewVaccines from "./Vaccines";
import Pet from "@spike/pet-model";
import VetModel from "@spike/vet-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import Vet from "../../Vet/View";
import MedicalHistoryHeader from "../MedicalHistoryHeader";
import NewPetMedicalHistorySection from "../NewPetMedicalHistorySection";
import { useMarketplace } from "hooks";
import Dentals from "./Dentals";

interface NewPetViewMedicalHistoryProps {
  pet: Pet;
  vet?: VetModel;
  onEdit?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(24)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "24px",
      },
    },
    section: {
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(41)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "41px",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#222222",
      [theme.breakpoints.down(wbp)]: {
        fontSize: `${reduceResolution(24)}px`,
        lineHeight: `${reduceResolution(39)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "24px",
        lineHeight: "39px",
      },
    },
  })
);

export const NewPetViewMedicalHistory: FunctionComponent<NewPetViewMedicalHistoryProps> = (props) => {
  const classes = useStyles();

  const isDental = useMarketplace().isDental;

  return (
    <Grid container xs={12}>
      <MedicalHistoryHeader onEdit={props.onEdit} />
      <Grid container xs={12}>
        <NewPetMedicalHistorySection title="Vaccines" icon={faSyringe} className={classes.section}>
          <ViewVaccines pet={props.pet} />
        </NewPetMedicalHistorySection>
      </Grid>
      <Grid container xs={12}>
        <NewPetMedicalHistorySection title="Vet" icon={faStethoscope} className={classes.section}>
          <Vet vet={props.vet} />
        </NewPetMedicalHistorySection>
      </Grid>
      <Grid container xs={12}>
        <NewPetMedicalHistorySection title="Medical Conditions" icon={faHouseMedical} className={classes.section}>
          <MedicalConditions pet={props.pet} />
        </NewPetMedicalHistorySection>
      </Grid>
      {isDental && <Grid container xs={12}>
        <NewPetMedicalHistorySection title="Dentals" icon={faTooth} className={classes.section}>
          <Dentals pet={props.pet} />
        </NewPetMedicalHistorySection>
      </Grid>}
    </Grid>
  );
};

export default NewPetViewMedicalHistory;
