import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { pageView } from "googleAnalytics/GoogleAnalytics";
import LeftColumn from "rocket/components/Login/LeftColumn";
import RightColumn from "rocket/components/SignupVerify/RightColumn";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headerLink: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const SignupVerify: FunctionComponent = (props) => {
  const classes = useStyles();

  useEffect(() => {
    pageView("/signup/verify");
  }, []);

  document.title = "Verify your email - Cuddles";

  return (
    <div id="signup-verify">
      <div className={classes.root}>
        <Grid container spacing={0}>
          <LeftColumn />
          <RightColumn />
        </Grid>
      </div>
    </div>
  );
};

export default SignupVerify;
