import {
	SignUpAction,
	SIGN_UP_RESET_ACTION_TYPE,
	SIGN_UP_START_ACTION_TYPE,
	SIGN_UP_SUCCESS_ACTION_TYPE,
	SIGN_UP_FAILED_ACTION_TYPE,
	SIGN_UP_EMAIL_VERIFICATION_START_ACTION_TYPE,
	SIGN_UP_EMAIL_VERIFICATION_SUCCESS_ACTION_TYPE,
	SIGN_UP_EMAIL_VERIFICATION_FAILED_ACTION_TYPE,
	SIGN_UP_ERROR_ACTION_TYPE,
	SIGN_UP_SET_DATA_ACTION_TYPE,
} from "./SignUpActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { SignUpState } from "reducers/signUp/SignUpState";
import ApiClient, { isCancelled } from "api/ApiClient";
import { CallHistoryMethodAction } from "connected-react-router";
import { NotificationsAction, alertErrorHandler } from "@spike/notifications-action";
import { showSuccess } from "@spike/notifications-action";
import SignUp from "model/SignUp";
import { signupHubspot } from "actions/hubspot/HubSpotActions";
import { identifyUserInStonly } from "hooks";
import { debugConsoleLog } from "utils/GeneralUtils";

const registerUrl = "create_business";
const verifyEmailUrl = "check_email";
const resendVerificationEmailUrl = "resend_register";

const HTTP_STATUS_401_UNAUTHORIZED = 401;
const OTHER_SOFTWARE_ID = 'other';
export interface SignUpRecord {
	first_name: string;
	last_name: string;
	phone: string;
	email: string;
	password: string;
	business_name: string;
	business_type: string;
	business_area_list: Array<string>;
	company_role: string;
	email_optout: boolean;
}

export interface VerificationEmailResponse {
	registered: boolean;
}

export const resetThunk = (): ThunkAction<void, SignUpState, null, SignUpAction> => {
	return async (dispatch) => {
		dispatch(reset());
	};
};

export const setDataThunk = (
	data: SignUp
): ThunkAction<void, SignUpState, null, SignUpAction> => {
	return async (dispatch) => {
		dispatch(setData(data));
	};
};

export const signUpThunk = (
	signUp: SignUp
): ThunkAction<
	void,
	SignUpState,
	null,
	SignUpAction | CallHistoryMethodAction | NotificationsAction
> => {

	debugConsoleLog({signUp});

	return async (dispatch) => {
		dispatch(start());
		try {
			const response = await ApiClient.post(registerUrl, {
				first_name: signUp.firstName,
				promocode: signUp.promocode,
				last_name: signUp.lastName,
				email: signUp.email.trim().toLowerCase(),
				password: signUp.password,
				terms: signUp.terms,
				privacy: signUp.privacy,
				business_name: signUp.businessName,
				business_type: signUp.businessType,
				phone_number: signUp.phoneNumber,
				address: signUp.address,
				suite: signUp.suite,
				city: signUp.city,
				state: signUp.state,
				zipcode: signUp.zipcode,
				hear_about_us: signUp.hearAboutUs,
				sms_marketing_optin: signUp.smsMarketingOptIn,
				country: signUp.country,
				locations: signUp.locations?.id,
				groomers: signUp.groomers?.id,
				software: signUp.software?.id?.toLowerCase() === OTHER_SOFTWARE_ID.toLowerCase() ? (signUp.otherSoftware ?? signUp.software?.id) : signUp.software?.id,
				services: signUp.services.map(service => service.id),
				new_business: signUp.newBusiness,
			});

			dispatch(signupHubspot(signUp, response.data.marketplace_id));

			/* Stonly tracking user and send properties */
			const marketplace_id = response.data.marketplace_id;
			const user_id = response.data.id;
			const staff_id = response.data.staff_id;
			const created_at = new Date();

			//First identify user without properties
			identifyUserInStonly(user_id.toString(), {});
			//Then we add the properties
			identifyUserInStonly(user_id.toString(), {
				"user-created-at": created_at,
				"user-id": user_id,
				"market-created": created_at,
				"marketplace-id": marketplace_id,
			});

			dispatch(success());
		} catch (apiError: any) {
			if (!isCancelled(apiError)) {
				if (
					apiError.response &&
					apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED
				) {
					dispatch(failed());
				} else {
					dispatch(error());
					alertErrorHandler(apiError, dispatch, "Error signing in.");
				}
			}
		}
	};
};

export const verifyEmailThunk = (
	email: string
): ThunkAction<void, SignUpState, null, SignUpAction | NotificationsAction> => {
	return async (dispatch) => {
		dispatch(emailVerificationStart());
		try {
			const response: AxiosResponse<VerificationEmailResponse> =
				await ApiClient.post(verifyEmailUrl, {
					email: email.toLocaleLowerCase(),
				});

			if (response.data.registered) {
				dispatch(emailVerificationFailed());
			} else {
				dispatch(emailVerificationSuccess());
			}
		} catch (apiError: any) {
			if (!isCancelled(apiError)) {
				dispatch(error());
			}
		}
	};
};

export const resendVerificationEmailThunk = (
	email: string
): ThunkAction<
	void,
	SignUpState,
	null,
	SignUpAction | CallHistoryMethodAction | NotificationsAction
> => {
	return async (dispatch) => {
		dispatch(start());
		try {
			await ApiClient.post(resendVerificationEmailUrl, {
				email: email.toLocaleLowerCase(),
			});
			dispatch(showSuccess("Email resent!"));
		} catch (apiError: any) {
			if (!isCancelled(apiError)) {
				dispatch(error());
			}
		}
	};
};

export const reset = (): SignUpAction => {
	return {
		type: SIGN_UP_RESET_ACTION_TYPE,
	};
};

export const setData = (data: SignUp): SignUpAction => {
	return {
		type: SIGN_UP_SET_DATA_ACTION_TYPE,
		payload: {
			data,
		},
	};
};

const start = (): SignUpAction => {
	return {
		type: SIGN_UP_START_ACTION_TYPE,
	};
};

const success = (): SignUpAction => {
	return {
		type: SIGN_UP_SUCCESS_ACTION_TYPE,
	};
};

const failed = (): SignUpAction => {
	return {
		type: SIGN_UP_FAILED_ACTION_TYPE,
	};
};

const emailVerificationStart = (): SignUpAction => {
	return {
		type: SIGN_UP_EMAIL_VERIFICATION_START_ACTION_TYPE,
	};
};

const emailVerificationSuccess = (): SignUpAction => {
	return {
		type: SIGN_UP_EMAIL_VERIFICATION_SUCCESS_ACTION_TYPE,
	};
};

const emailVerificationFailed = (): SignUpAction => {
	return {
		type: SIGN_UP_EMAIL_VERIFICATION_FAILED_ACTION_TYPE,
	};
};

const error = (): SignUpAction => {
	return {
		type: SIGN_UP_ERROR_ACTION_TYPE,
	};
};
