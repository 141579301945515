import React from 'react';
import Staff from 'model/Staff';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import { getStaffScheduleByDate } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { Link } from 'react-router-dom';
import { useMarketplace, useTimeZone } from 'hooks';

export interface StaffCardProps {
    staff: Staff;
    selectedDate?: Moment;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 8,
            width: '100%',
            borderRadius: 8,
            position: 'relative',
            padding: '11px 45px 11px 13px',
            border: 'solid 1px #D4D4D4',
            alignItems: 'center',
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                paddingTop: 15,
                paddingBottom: 15
            }
        },
        avatar: {
            width: 44,
            height: 44,
            borderRadius: '50%',
            objectFit: 'cover'
        },
        staffInfo: {
            flex: 1
        },
        staffName: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            }
        },
        dateLabel: {
            fontSize: 14,
            lineHeight: 1,
            color: '#7A7A7A',
            marginTop: 4,

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        editButton: {
            top: 13,
            right: 10,
            border: 0,
            fontSize: 18,
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            position: 'absolute'
        }
    })
);

export const StaffCard: React.FC<StaffCardProps> = props => {
    const classes = useStyles();

    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const staffScheduleLabel = React.useMemo(() => {
        if (!props.selectedDate) {
            return '';
        }

        const daySchedule = getStaffScheduleByDate(marketplace, props.staff.schedule, props.selectedDate, timeZone);

        const dayLabel = props.selectedDate.format('dddd');
        const dateLabel = props.selectedDate.format('DD MMM');

        if (!daySchedule?.isStaffAvailable || daySchedule?.isBusinessClosed) {
            return `${dayLabel}`;
        }

        const startTime = daySchedule.from.format('h:mm A');
        const endTime = daySchedule.to.format('h:mm A');

        return `${dayLabel}, ${dateLabel}, ${startTime} - ${endTime}`;
    }, [props.staff, props.selectedDate]);

    return (
        <div className={classes.root}>
            <img alt={props.staff.person.firstName} src={props.staff.person.avatar} className={classes.avatar} />

            <div className={classes.staffInfo}>
                <Typography className={classes.staffName}>
                    {props.staff.person.firstName} {props.staff.person.lastName}
                </Typography>
                <Typography className={classes.dateLabel}>{staffScheduleLabel}</Typography>
            </div>

            <Link id="add-slot-staff-card-edit-button" to={`/staff#staffId=${props.staff.id}`}>
                <button type="button" className={classes.editButton}>
                    <FontAwesomeIcon icon={faPen} />
                </button>
            </Link>
        </div>
    );
};
