import { FieldError } from '@spike/model';
import { Pet } from '@spike/pet-model';
import isEmpty from 'lodash/isEmpty';

export const validatePet = (pet: Pet): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (!pet.type) {
        errors.push({
            fieldName: 'type',
            errorMessage: 'Pet type is required'
        });
    }

    if (isEmpty(pet.name)) {
        errors.push({
            fieldName: 'name',
            errorMessage: 'Pet name is required'
        });
    }

    if (!pet.weight) {
        errors.push({
            fieldName: 'weight',
            errorMessage: 'Pet weight is required'
        });
    }

    return errors;
};
