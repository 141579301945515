import { Option } from "@spike/model";

export const Paid = { id: "paid", name: "Paid" };
export const Refunded = { id: "refunded", name: "Refunded" };

export const categories: Array<Option<string>> = [Paid, Refunded];

export enum PaymentStates {
  AUTHORIZED = "Authorised",
  REFUNDED = "Refunded",
  REFUND_SCHEDULED = "RefundScheduled",
  REFUNDED_BULK = "RefundedBulk",
  CANCELLED = "Cancelled",
  SENT_FOR_SETTLE = "SentForSettle",
  ERROR = "Error",
  SETTLE_SCHEDULED = "SettleScheduled",
  SETTLED = "Settled",
  SEND_FOR_REFUND = "SentForRefund",
  REFUSED = "Refused",
  EXPIRED = "Expired",
  AUTHORISED_PENDING = "AuthorisedPending",
  SETTLED_BULK = "SettledBulk",
}

export enum RefundStates {
  PENDING = "pending",
  RECEIVED = "received",
  FAILED = "failed",
  SUCCESS = "success"
}