import { TipInvoice, TipsReport } from 'model/TipsReport';
import moment from 'moment';
import store from 'store';
import { InvoicesTipsDetailReportDto } from './TipsReportDto';

export const convertToTipsReport = (
    invoices: InvoicesTipsDetailReportDto
): TipsReport => {
    const timeZone = store.getState().login.auth.timeZone;

    const tipsReport: TipsReport = {
        invoices: [],
        totalTips: invoices.total_tip_amount,
        totalAppointments: invoices.total_appointment_count
    };

    invoices.invoices?.forEach(invoice => {
        const invoiceConverted: TipInvoice = {
            id: invoice.id,
            customerName: invoice.customer_name,
            createdAt: moment(invoice.created_at).tz(timeZone!),
            marketplaceId: invoice.marketplace_id,
            number: invoice.number,
            uuid: invoice.uuid,
            tips: [],
            paymentType: invoice.payment_type
        };

        if (invoice.tips.length > 0) {
            invoice.tips.forEach(inv => {
                invoiceConverted.tips.push({
                    id: inv.id,
                    staffId: inv.staff_id,
                    invoiceId: inv.invoice_id,
                    staffName: inv.staff_name,
                    staffImage: inv.staff_image,
                    amount: inv.amount
                });
            });
        } else if (invoice.invoice_lines) {
            invoiceConverted.tips.push({
                id: invoice.id ?? 0,
                staffId: invoice.invoice_lines.shift()?.staff_id ?? 0,
                invoiceId: invoice.id ?? 0,
                staffName: invoice.invoice_lines.shift()?.staff_name || '',
                staffImage: invoice.invoice_lines.shift()?.staff_image || '',
                amount: invoice.tip_amount ?? '0.00'
            });
        }

        tipsReport.invoices.push(invoiceConverted);
    });

    tipsReport.invoices = tipsReport.invoices.sort(function (a, b) {
        return moment(a.createdAt) < moment(b.createdAt)
            ? 1
            : moment(b.createdAt) < moment(a.createdAt)
            ? -1
            : 0;
    });

    return tipsReport;
};
