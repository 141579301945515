import { Auth, AuthMarketplace, logoutAuth } from '@spike/auth-model';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { ACCESS_LEVEL_ADMIN_ID, Option } from '@spike/model';

const authKey = `${process.env.REACT_APP_AUTH_KEY}`;
const secret = `${process.env.REACT_APP_AUTH_SECRET}`;

const setLocalAuth = (auth: Auth) => {
    setAuth(auth, localStorage);
};

const setSessionAuth = (auth: Auth) => {
    setAuth(auth, sessionStorage);
};

const getLocalAuth = () => {
    return getAuth(localStorage);
};

const getSessionAuth = () => {
    return getAuth(sessionStorage);
};

const cleanLocal = () => {
    cleanAuth(localStorage);
};

const cleanSession = () => {
    cleanAuth(sessionStorage);
};

const cleanAuth = (storage: Storage) => {
    storage.removeItem(authKey);
};

const setAuth = (auth: Auth, storage: Storage) => {
    const encryptedAuth = AES.encrypt(JSON.stringify(auth), secret).toString();
    storage.setItem(authKey, encryptedAuth);
};

const getAuth = (storage: Storage): Auth | null => {
    try {
        const encryptedAuth = storage.getItem(authKey);

        if (encryptedAuth) {
            return JSON.parse(
                AES.decrypt(encryptedAuth, secret).toString(CryptoJS.enc.Utf8)
            );
        } else {
            return null;
        }
    } catch (error) {
        cleanSession();
        cleanLocal();
        return null;
    }
};

export const saveInStorage = (auth: Auth, local: boolean): void => {
    if (local) {
        setLocalAuth(auth);
    } else {
        cleanLocal();
    }

    setSessionAuth(auth);
};

export const getFromStorage = (): Auth => {
    let auth = getSessionAuth();

    if (auth === null) {
        auth = getLocalAuth();
        if (auth !== null) {
            setSessionAuth(auth);
        }
    }

    if (auth === null) {
        return { ...logoutAuth };
    } else {
        return auth;
    }
};

export const removeFromStorage = (): void => {
    cleanSession();
    cleanLocal();
};

export const setTimeZoneInStorage = (
    timeZone: string,
    marketplaceId: number
): void => {
    const sessionAuth = getSessionAuth();
    const localAuth = getLocalAuth();

    if (sessionAuth && sessionAuth.marketplaceId === marketplaceId) {
        sessionAuth.timeZone = timeZone;
        setSessionAuth(sessionAuth);
    }

    if (localAuth && localAuth.marketplaceId === marketplaceId) {
        localAuth.timeZone = timeZone;
        setLocalAuth(localAuth);
    }
};

export const setRoleAndAccessLevelInStorage = (
    role: Option<number>,
    accessLevel: Option<number>,
    staffId: number
): void => {
    const sessionAuth = getSessionAuth();
    const localAuth = getLocalAuth();

    if (
        sessionAuth &&
        sessionAuth.user &&
        sessionAuth.user.staffId === staffId
    ) {
        sessionAuth.user.role = { ...role };
        sessionAuth.user.accessLevel = { ...accessLevel };
        sessionAuth.user.admin = accessLevel.id === ACCESS_LEVEL_ADMIN_ID;
        setSessionAuth(sessionAuth);
    }

    if (localAuth && localAuth.user && localAuth.user.staffId === staffId) {
        localAuth.user.role = { ...role };
        localAuth.user.accessLevel = { ...accessLevel };
        localAuth.user.admin = accessLevel.id === ACCESS_LEVEL_ADMIN_ID;
        setLocalAuth(localAuth);
    }
};

export const changeAvatarInStorage = (
    avatar: string,
    staffId: number
): void => {
    const sessionAuth = getSessionAuth();
    const localAuth = getLocalAuth();

    if (sessionAuth && sessionAuth.user?.staffId === staffId) {
        sessionAuth.user!.avatar = avatar;
        setSessionAuth(sessionAuth);
    }

    if (localAuth && localAuth.user?.staffId === staffId) {
        localAuth.user!.avatar = avatar;
        setLocalAuth(localAuth);
    }
};

export const changeSessionMarketplace = (authMarketplace: AuthMarketplace) => {
    const sessionAuth = getSessionAuth();
    sessionAuth!.marketplaceId = authMarketplace.id;
    sessionAuth!.addressId = authMarketplace.addressId;
    sessionAuth!.timeZone = authMarketplace.timeZone;
    sessionAuth!.user!.admin = authMarketplace.admin;
    sessionAuth!.user!.role = { ...authMarketplace.role };
    sessionAuth!.user!.accessLevel = { ...authMarketplace.accessLevel };
    setSessionAuth(sessionAuth!);
};
