import { ResourceLabelContentArg } from '@fullcalendar/resource';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		avatar: {
			[theme.breakpoints.down('sm')]: {
				width: '30px',
				height: '30px',
				marginBottom: '4px'
			},
			[theme.breakpoints.only('md')]: {
				width: '34px',
				height: '34px',
				marginBottom: '6px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '42px',
				height: '42px',
				marginBottom: '8px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '52px',
				height: '52px',
				marginBottom: '10px'
			}
		},
		name: {
			fontFamily: 'Poppins',
			fontWeight: 500,
			color: '#222222',
			[theme.breakpoints.down('sm')]: {
				fontSize: '11px',
				lineHeight: '14px',
			},
			[theme.breakpoints.only('md')]: {
				fontSize: '12px',
				lineHeight: '15px',

			},
			[theme.breakpoints.only('lg')]: {
				fontSize: '14px',
				lineHeight: '17px',
			},
			[theme.breakpoints.only('xl')]: {
				fontSize: '16px',
				lineHeight: '19px',
			}
		}
	})
);

export const ResourceHeaderFullCalendar: FunctionComponent<
	ResourceLabelContentArg
> = props => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Avatar
				className={classes.avatar}
				src={props.resource.extendedProps.avatar}
			/>
			<Typography className={classes.name}>
				{`${props.resource.extendedProps.firstName} ${props.resource.extendedProps.lastName}`}
			</Typography>
		</Box>
	);
};

export default ResourceHeaderFullCalendar;
