import { Option, Period } from '@spike/model';
import { FunctionComponent, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import WeekAheadItem from './WeeksAheadItem';
import moment from 'moment-timezone';
import { useTimeZone } from 'hooks';

interface WeekAheadListProps {
    week: Period;
	onSelect?: (week: Period) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
            flexDirection: 'column',
            width: '100%',
		},
	})
);

const options: Array<Option<number>> =[
    {id: 0, name: 'This week'},
    {id: 1, name: 'Next week'},
    {id: 2, name: 'In 2 weeks'},
    {id: 3, name: 'In 3 weeks'},
    {id: 4, name: 'In 4 weeks'},
    {id: 5, name: 'In 5 weeks'},
    {id: 6, name: 'In 6 weeks'},
    {id: 7, name: 'In 7 weeks'},
    {id: 8, name: 'In 8 weeks'},
];

export const WeekAheadList: FunctionComponent<WeekAheadListProps> = props => {
	const classes = useStyles();
    const timeZone = useTimeZone();

    const selectedWeekId = props.week.from.week() - moment.tz(timeZone).week() ;
    
    const onSelectHandler = (weeks: number) => {
        const thisWeekMonday = moment.tz(timeZone).startOf('week');
        const selectedWeek = {from: thisWeekMonday.clone().add(weeks,'weeks'), to:thisWeekMonday.clone().add(weeks,'weeks').endOf('week')};
        props.onSelect && props.onSelect(selectedWeek);
    };

	return (
		<Box className={classes.container}>
            {options.map(option => <WeekAheadItem key={option.id} label={option.name} selected={option.id === selectedWeekId} onClick={()=>onSelectHandler(option.id)}/>)}
        </Box>
	);
};

export default WeekAheadList;
