import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { MedicalCondition as MedicalConditionModel } from '../../MedicalConditionModel';
import MedicalConditionSection from './MedicalConditionSection';

export interface MedicalConditionProps {
    condition: MedicalConditionModel;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingTop: 40,
            [theme.breakpoints.down(wbp)]: {
                paddingTop: 20
            },
            [theme.breakpoints.down('md')]: {
                paddingBottom: '12px',
                borderBottom: '1px solid #F1F1F1'
            }
        },
        header: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%'
        },
        body: {
            display: 'flex',
            width: '100%',
            gap: 10,
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(17)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '17px'
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        bodySection: {
            display: 'flex',
            width: '33%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#222222',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`,
                lineHeight: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px',
                lineHeight: '30px'
            }
        }
    })
);

export const MedicalCondition: FunctionComponent<
    MedicalConditionProps
> = props => {
    const classes = useStyles();

    const isSurgery = props.condition.id === 1;

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.header}>
                <Typography className={classes.title}>
                    {isSurgery ? 'Surgery' : props.condition.name}
                </Typography>
            </Box>
            <Box className={classes.body}>
                <Box className={classes.bodySection}>
                    <MedicalConditionSection
                        title="Description"
                        text={props.condition.description}
                    />
                </Box>
                <Box className={classes.bodySection}>
                    <MedicalConditionSection
                        title="Medication"
                        text={props.condition.medications}
                    />
                </Box>
                <Box className={classes.bodySection}>
                    <MedicalConditionSection
                        title="Diagnosis"
                        text={props.condition.diagnosis?.name}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default MedicalCondition;
