import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { SingleChip } from "components/UI";
import React, { FunctionComponent } from "react";

interface TerminalActivateProps {
  activate: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: "#FFF8DD",
      textTransform: "capitalize",
      "& p": {
        color: "#C18733",
      },
      width: "fit-content",
    },

    activate: {
      backgroundColor: "#5E8677",
      "& p": { color: "#FFF", },
    },

    deactivate: {
      backgroundColor: "#F4F3F0",
      "& p": { color: "#222", },
    },
  })
);

export const TerminalActivate: FunctionComponent<TerminalActivateProps> = (props) => {
  const classes = useStyles();

  return (
    <SingleChip className={
      clsx(classes.default,
        { [classes.activate]: props.activate },
        { [classes.deactivate]: !props.activate },
      )}>
      {props.activate && "Enabled"}
      {!props.activate && "Disabled"}
    </SingleChip>
  );
};

export default TerminalActivate;
