import { Box, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Chip2 } from "components/UI";
import { useMasterData } from "hooks";
import { DentalIds, PetDental } from "@spike/medical-history-model";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import NonAnestheticDental from "./NonAnestheticDental";
import AnestheticDental from "./AnestheticDental";

interface DentalsProps {
  dentals: Array<PetDental>;
  onChange: (dentals: Array<PetDental>) => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      [theme.breakpoints.down(wbp)]: {
        marginRight: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginRight: "20px",
      },
      [theme.breakpoints.down("md")]: {
        marginRight: "15px",
        marginBottom: "10px",
      }
    },
  })
);

export const Dentals: FunctionComponent<DentalsProps> = (props) => {
  const classes = useStyles();

  const masterData = useMasterData();

  const [dental] = props.dentals;

  const selectHandler = (dentalId: string) => {
    const name = masterData.medicalHistory.dentals.find((d) => d.id === dentalId)?.name || "";
    switch (dentalId) {
      case DentalIds.NoCleaning:
        props.onChange([{ id: dental?.id, dentalId, name }]);
        break;
      case DentalIds.NonAnestheticDental:
        props.onChange([{ id: dental?.id, dentalId, name }]);
        break;
      case DentalIds.AnestheticDental:
        props.onChange([{ id: dental?.id, dentalId, name }]);
        break;
    }
  };

  const changeDentalHandler = (dental: PetDental) => {
    props.onChange([{ ...dental }]);
  }
  /*
  {
    id?: number;
    dentalId: string;
    teethExtracted?: boolean;
    cleaningDate?: Moment;
    name: string;
  }*/

  return (
    <Box className={props.className}>
      <Grid container>
        <Grid container xs={12} md={6}>
          {masterData.medicalHistory.dentals.map((dental) => (
            <Chip2
              key={dental.id}
              label={dental.name}
              selected={props.dentals.some((petDental) => petDental.dentalId === dental.id)}
              onClick={() => selectHandler(dental.id)}
              className={classes.chip}
            />
          ))}
        </Grid>
        {dental?.dentalId === DentalIds.NonAnestheticDental && <Grid item xs={12} md={6}>
            <NonAnestheticDental dental={dental} onChange={changeDentalHandler} />
        </Grid>}
        {dental?.dentalId === DentalIds.AnestheticDental && <Grid item xs={12} md={6}>
            <AnestheticDental dental={dental} onChange={changeDentalHandler} />
        </Grid>}
      </Grid>
    </Box>
  );
};

export default Dentals;
