import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import clsx from "clsx";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";

interface Props extends ButtonProps {
  label?: string;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 55,
    width: "100%",
    color: "#fff",
    fontSize: 18,
    display: "inline-block",
    textAlign: "center",
    borderRadius: 31,
    letterSpacing: 0,
    fontWeight: 600,
    textTransform: "none",
    textDecoration: "none",
    backgroundColor: "#EAB464",
    fontFamily: '"Poppins", sans-serif',
    "&:hover": {
      backgroundColor: "#C18733",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    width: "100%",
    height: "100%",
  },
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const PrimaryButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const spinner = (
    <div className={classes.spinnerContainer}>
      <PulseLoader color="#fff" loading={props.loading} css={override} size={5} />
    </div>
  );

  return (
    <Button {...props} className={clsx(classes.root, props.className)}>
      {!props.loading && (props.label || "Falta label")}
      {props.loading && spinner}
    </Button>
  );
};

export default PrimaryButton;
