import { Action } from "redux";

//Types
export const ACTIVATE_ACCOUNT_START_ACTION_TYPE = "ACTIVATE_ACCOUNT_START";
export const ACTIVATE_ACCOUNT_END_ACTION_TYPE = "ACTIVATE_ACCOUNT_END";
export const ACTIVATE_ACCOUNT_FAIL_ACTION_TYPE = "ACTIVATE_ACCOUNT_FAIL";
export const ACTIVATE_ACCOUNT_RESET_ACTION_TYPE = "ACTIVATE_ACCOUNT_RESET";

//Actions
interface ActivateAccountStartAction
  extends Action<typeof ACTIVATE_ACCOUNT_START_ACTION_TYPE> {}

interface ActivateAccountEndAction
  extends Action<typeof ACTIVATE_ACCOUNT_END_ACTION_TYPE> {}

interface ActivateAccountFailAction
  extends Action<typeof ACTIVATE_ACCOUNT_FAIL_ACTION_TYPE> {}

interface ActivateAccountResetAction
  extends Action<typeof ACTIVATE_ACCOUNT_RESET_ACTION_TYPE> {}

export type ActivateAccountAction =
  | ActivateAccountStartAction
  | ActivateAccountEndAction
  | ActivateAccountFailAction
  | ActivateAccountResetAction;
