import clsx from 'clsx';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

interface InfoProps {
	title: string;
	description: string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			borderRadius: 16,
			padding: '11px 0px 15px',
			backgroundColor: '#F8F5F1',
			border: '2px solid #BCB8AE'
		},
		header: {
			gap: 8,
			display: 'flex',
			alignItems: 'center',
			paddingLeft: 16,
			paddingRight: 22,
			marginBottom: 8,

			[theme.breakpoints.up('md')]: {
				padding: '0px 16px'
			}
		},
		body: {
			padding: '0px 22px 0px 44px'
		},
		title: {
			color: '#000',
			fontSize: 14,
			lineHeight: 1.4,
			fontWeight: 500
		},
		icon: {
			fontSize: 20,
			color: '#BAA997'
		},
		description: {
			color: '#222',
			fontSize: 13,
			fontWeight: 400,
			lineHeight: 1.3
		}
	})
);
export const Info: FunctionComponent<InfoProps> = props => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.header}>
				<FontAwesomeIcon icon={faInfoCircle} className={classes.icon} />
				<Typography className={classes.title}>{props.title}</Typography>
			</Box>
			<Box className={classes.body}>
				<Typography className={classes.description}>
					{props.description}
				</Typography>
			</Box>
		</Box>
	);
};

export default Info;
