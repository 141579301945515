import React, { useState } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { CardRow } from './CardRow';
import { UpdateCard } from './UpdateCard';
import { MarketplacePaymentMethod } from '@spike/payments-model';

interface CardGroupProps {
	cards: Array<MarketplacePaymentMethod>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			borderRadius: 18,

			[theme.breakpoints.up('sm')]: {
				padding: '6px 32px',
				border: 'solid 1px #DDDDDD'
			}
		}
	})
);

export const CardGroup: React.FunctionComponent<CardGroupProps> = ({
	cards
}) => {
	const classes = useStyles();

	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [activeId, setActiveId] = useState<null | number>(null);

	const openDropdownHandler = (id: number) => {
		if (id === activeId) {
			setActiveId(null);
			return;
		}

		setActiveId(id);
	};

	const editHandler = () => {
		setActiveId(null);
		setShowEditModal(true);
	};

	return (
		<>
			<Box className={classes.root}>
				{cards.map((card, i) => (
					<CardRow
						id={i}
						key={i}
						card={card}
						active={false}
						onEdit={editHandler}
						onOpenDropdown={openDropdownHandler}
					/>
				))}
			</Box>

			<UpdateCard
				open={showEditModal}
				onClose={() => setShowEditModal(false)}
				onComplete={() => setShowEditModal(false)}
			/>
		</>
	);
};
