import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Option } from '@spike/model';
import { FunctionComponent } from 'react';
import TabHeader from './TabHeader';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

interface TabsHeaderProps {
    tabs: Array<Option<string>>;
    depositCollect?: boolean;
    selectedTabId: string;
    className?: string;
    onClick?: (tabId: string) => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%'
            //height: '42px',
        },
        tabsContainer: {
            display: 'flex',
            flexGrow: 1
        },
        buttonsContainer: {
            dispaly: 'flex',
            justifyContent: 'center'
        },
        icon: {
            cursor: 'pointer',
            fontSize: 22,
            [theme.breakpoints.up('md')]: {
                fontSize: 24
            }
        }
    })
);

const TabsHeader: FunctionComponent<TabsHeaderProps> = props => {
    const classes = useStyles(props);

    return (
        <Box className={classes.container}>
            <Box className={classes.tabsContainer}>
                {props.tabs.map(tab => (
                    <TabHeader
                        key={tab.id}
                        label={tab.name}
                        selected={tab.id === props.selectedTabId}
                        onClick={() => props.onClick && props.onClick(tab.id)}
                    />
                ))}
            </Box>
            {props.onClose && (
                <Box className={classes.buttonsContainer}>
                    <FontAwesomeIcon
                        icon={props.depositCollect ? faArrowLeft : faTimes}
                        fontSize={16}
                        onClick={props.onClose}
                        className={classes.icon}
                    />
                </Box>
            )}
        </Box>
    );
};

export default TabsHeader;
