import React, { FunctionComponent, MouseEvent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCaretRight,
	faCheckCircle,
	faTrash
} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import ToolbarButton from 'components/UI/Toolbar/ToolbarButton';
import { ToolbarItemWithConfirmation } from 'components/UI/Toolbar/ToolbarItemWithConfirmation';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
	position?: number;
	title?: string;
	collapsed?: boolean;
	disabled?: boolean;
	editable?: boolean;
	completed?: boolean;
	removeQuestion?: JSX.Element;
	onEdit?: () => void;
	onRemove?: () => void;
	onHeaderClick?: () => void;
	className?: string;
	titleClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			position: 'relative',
			flexDirection: 'column'
		},
		headerContainer: {
			width: '100%',
			display: 'flex',
			padding: '0px 16px',

			[theme.breakpoints.down('sm')]: {
				marginBottom: 20
			},
			[theme.breakpoints.up('md')]: {
				height: '34px',
				padding: '0px 26px',
				background: '#E9F0ED',
				borderTopLeftRadius: '18px',
				borderTopRightRadius: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '46px',
				borderTopLeftRadius: '24px',
				borderTopRightRadius: '24px'
			}
		},
		collapsedHeaderContainer: {
			background: '#EFEFEF',
			[theme.breakpoints.down('lg')]: {
				borderBottomLeftRadius: '18px',
				borderBottomRightRadius: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				borderBottomLeftRadius: '24px',
				borderBottomRightRadius: '24px'
			}
		},
		leftHeaderContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end'
		},
		noneLeftHeaderContainer: {
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '26px'
			}
		},
		completedCheck: {
			color: '#5E8677'
		},
		positionCircle: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: '#5E8677',
			[theme.breakpoints.down('lg')]: {
				width: '20px',
				height: '20px',
				borderRadius: '20px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '26px',
				height: '26px',
				borderRadius: '26px'
			}
		},
		position: {
			background: '#5E8677',
			color: '#FFFFFF',
			fontWeight: 500,
			[theme.breakpoints.down('lg')]: {
				fontSize: '10px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '14px'
			}
		},
		centerHeaderContainer: {
			display: 'flex',
			flex: '1 1 auto',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				paddingLeft: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingLeft: '10px'
			}
		},
		centerHeaderNoLeft: {
			paddingLeft: 0
		},
		title: {
			color: '#000',
			fontSize: 16,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				color: '#5E8677'
			}
		},
		rightHeaderContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				width: '150px',
				paddingRight: '32px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '200px',
				paddingRight: '43px'
			}
		},
		bodyContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			background: '#FFFFFF',
			[theme.breakpoints.down('lg')]: {
				borderBottomLeftRadius: '18px',
				borderBottomRightRadius: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				borderBottomLeftRadius: '24px',
				borderBottomRightRadius: '24px'
			}
		},
		disabled: {
			color: '#989898',
			background: '#EFEFEF'
		},
		postionDisabled: {
			background: '#989898',
			color: '#EFEFEF'
		},
		editLink: {
			cursor: 'pointer',
			textDecoration: 'underline',
			fontWeight: 500,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px'
			}
		},
		clickeable: {
			cursor: 'pointer'
		}
	})
);

export const Section: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const headerContainerStyle = clsx(classes.headerContainer, {
		[classes.collapsedHeaderContainer]: props.collapsed,
		[classes.disabled]: props.disabled
	});

	const titleStyle = clsx(classes.title, props.titleClassName, {
		[classes.disabled]: props.disabled
	});

	const positionCircleStyle = clsx(classes.positionCircle, {
		[classes.postionDisabled]: props.disabled
	});

	const positionStyle = clsx(classes.position, {
		[classes.postionDisabled]: props.disabled
	});

	const menu = (
		<Box>
			<ToolbarButton>
				<ToolbarItemWithConfirmation
					icon={faTrash}
					text={'Delete'}
					onConfirmedClick={() => props.onRemove && props.onRemove()}
					confirmQuestion={
						props.removeQuestion || (
							<Typography>
								Are you sure you want to delete it?
							</Typography>
						)
					}
					confirmButtonLabel="Delete"
					cancelButtonLabel="Cancel"
				/>
			</ToolbarButton>
		</Box>
	);

	const editHandler = (event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();
		props.onEdit && props.onEdit();
	};

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box
				className={clsx(headerContainerStyle, {
					[classes.clickeable]: props.onHeaderClick
				})}
				onClick={() => {
					props.onHeaderClick && props.onHeaderClick();
				}}
			>
				{(props.position || props.completed) && (
					<Box
						className={clsx(classes.leftHeaderContainer, {
							[classes.noneLeftHeaderContainer]:
								!props.position && !props.completed
						})}
					>
						{props.completed ? (
							<FontAwesomeIcon
								size="lg"
								icon={faCheckCircle}
								className={classes.completedCheck}
							/>
						) : props.position ? (
							<Box className={positionCircleStyle}>
								<Typography className={positionStyle}>
									{props.position}
								</Typography>
							</Box>
						) : null}
					</Box>
				)}
				<Box
					className={clsx(classes.centerHeaderContainer, {
						[classes.centerHeaderNoLeft]:
							!props.position && !props.completed
					})}
				>
					<Typography className={titleStyle}>
						{props.title}
					</Typography>
				</Box>
				<Box className={classes.rightHeaderContainer}>
					{props.collapsed && !props.editable && (
						<FontAwesomeIcon icon={faCaretRight} />
					)}
					{props.collapsed && props.editable && (
						<Box onClick={editHandler}>
							<Typography className={classes.editLink}>
								Edit
							</Typography>
						</Box>
					)}
					{props.onRemove && menu}
				</Box>
			</Box>
			{!props.collapsed && (
				<Box className={classes.bodyContainer}>{props.children}</Box>
			)}
		</Box>
	);
};

export default Section;
