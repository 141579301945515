import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from 'components/UI';
import React, { FunctionComponent } from 'react';
import { useCommonStyles } from '../CommonStyles';
import Title from '../ui/Title';

interface HeaderProps {
	title: string | JSX.Element;
	description: Array<string> | JSX.Element;
	buttonLabel?: string;
	onClick?: () => void;
	rightComponent?: JSX.Element;
	className?: string;
	titleContainerClassName?: string;
	descriptionClassName?: string;
	rightHeaderClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			backgroundColor: '#FAFAFA',
			padding: '30px 15px 8px',
			[theme.breakpoints.up('md')]: {
				padding: '54px 49px 0px 24px'
			}
		},
		leftHeader: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',

			[theme.breakpoints.up('md')]: {
				width: '60%'
			}
		},
		rightHeader: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		titleContainer: {},
		title: {
			fontSize: 20,
			lineHeight: 1,
			fontWeight: 600,

			[theme.breakpoints.up('md')]: {
				fontSize: 24,
				marginBottom: 16
			}
		},
		desktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'block'
			}
		},
		mobileTitle: {
			width: '100%',
			padding: '24px 16px',
			backgroundColor: '#ffffff'
		},
		description: {
			color: 'black',
			fontSize: 14,
			lineHeight: 1.4,
			fontWeight: 400,

			[theme.breakpoints.up('md')]: {
				width: '411px',
				fontSize: 15
			}
		}
	})
);

export const Header: FunctionComponent<HeaderProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<>
			<Box className={classes.mobileTitle}>
				<Typography className={classes.title}>{props.title}</Typography>
			</Box>

			<Box
				className={clsx(
					classes.container,
					commonClasses.paddingLeft,
					commonClasses.paddingRight,
					props.className
				)}
			>
				<Box className={classes.leftHeader}>
					<Box
						className={clsx(
							props.titleContainerClassName,
							classes.desktop
						)}
					>
						<Typography className={classes.title}>
							{typeof props.title === 'string' ? (
								<Title text={props.title} />
							) : (
								props.title
							)}
						</Typography>
					</Box>

					{Array.isArray(props.description) ? (
						<Typography
							className={clsx(
								classes.description,
								props.descriptionClassName
							)}
						>
							{props.description.map(descr => (
								<>
									{descr}
									<br />
									<br />
								</>
							))}
						</Typography>
					) : (
						props.description
					)}
				</Box>
				<Box
					className={clsx(
						classes.rightHeader,
						props.rightHeaderClassName
					)}
				>
					{props.buttonLabel && !props.rightComponent && (
						<Button
							label={props.buttonLabel}
							onClick={() => props.onClick && props.onClick()}
						/>
					)}
					{props.rightComponent}
				</Box>
			</Box>
		</>
	);
};

export default Header;
