import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import Header from './Header';
import SMSCampaign, { SMSCampaignStatus } from 'model/SMSCampaign';
import CampaignsDetail from './CampaignDetail/CampaignDetail';
import SMSCampaignsIsEmpty from './SMSCampaignsIsEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCampaignsByStatusThunk,
    fetchCampaignsGlobalThunk,
    getCampaignThunk,
    setFilterThunk
} from 'actions/smsCampaigns/SMSCampaignsActions';
import useNonInitialEffect from '@versiondos/hooks';
import SearchCampaign from './SearchCampaign/SearchCampaign';
import { RootState } from 'store';
import { SMSCampaignsStatus } from 'reducers/smsCampaigns/SMSCampaignsState';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: 'calc(100vh - 56px)',
            overflowY: 'scroll',
            paddingBottom: 40,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FAFAFA',
            [theme.breakpoints.down('md')]: {
                backgroundColor: '#FFF',
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
            },
            [theme.breakpoints.only('md')]: {
                backgroundColor: '#FFF',
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
            },
            [theme.breakpoints.up('lg')]: {
                paddingRight: 24,
                paddingLeft: 24
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: 87,
                paddingRight: 132
            }
        },
        content: {
            'flex': 1,
            'maxHeight': '100%',
            'marginTop': 40,
            '& > div': {
                [theme.breakpoints.up('lg')]: {
                    height: '100%'
                }
            }
        },
        loading: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        searchContainerMobile: {
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            height: '100%'
        },
        detailsContainerMobile: {
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            height: '100%',
            padding: '0 16px'
        }
    })
);

const PAGE_LENGTH = 25;

const SMSCampaigns: FunctionComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const page: number = useSelector(
        (state: RootState) => state.smsCampaigns.campaigns?.page || 0
    );
    const maxPages: number = useSelector(
        (state: RootState) => state.smsCampaigns.campaigns?.total_pages || 0
    );
    const campaigns: SMSCampaign[] = useSelector(
        (state: RootState) => state.smsCampaigns.campaigns?.sms_campaigns || []
    );

    const status: SMSCampaignsStatus = useSelector(
        (state: RootState) => state.smsCampaigns.status
    );

    const allCount: number = useSelector(
        (state: RootState) => state.smsCampaigns.all?.total_records || 0
    );
    const filter: SMSCampaignStatus = useSelector(
        (state: RootState) => state.smsCampaigns.filter
    );

    const [selectedCampaign, setSelectedCampaign] = useState<
        SMSCampaign | undefined
    >();

    useEffect(() => {
        dispatch(fetchCampaignsByStatusThunk(1, PAGE_LENGTH, filter));
        if (allCount === 0) {
            dispatch(fetchCampaignsGlobalThunk());
        }
    }, []);

    useNonInitialEffect(() => {
        if (selectedCampaign && selectedCampaign.id) {
            dispatch(getCampaignThunk(selectedCampaign.id));
        }
    }, [selectedCampaign]);

    useNonInitialEffect(() => {
        dispatch(fetchCampaignsByStatusThunk(1, PAGE_LENGTH, filter));
    }, [filter]);

    const filtersHandler = (id: SMSCampaignStatus) => {
        if (id === filter) return;
        setSelectedCampaign(undefined);
        dispatch(setFilterThunk(id));
    };

    const campaignHandler = (campaign?: SMSCampaign) => {
        setSelectedCampaign(campaign);
    };

    const campaignChangeStatusHandler = (status: string) => {
        if (status === 'delete') setSelectedCampaign(undefined);
    };

    useNonInitialEffect(() => {
        if (status === SMSCampaignsStatus.UpdateSuccess) {
            dispatch(fetchCampaignsByStatusThunk(1, PAGE_LENGTH, filter));
        }
    }, [status]);

    const pageHandler = () => {
        dispatch(fetchCampaignsByStatusThunk(page + 1, PAGE_LENGTH, filter));
    };

    return (
        <>
            <Box className={classes.root}>
                {status === SMSCampaignsStatus.GlobalFetching && (
                    <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                )}
                {status !== SMSCampaignsStatus.GlobalFetching && (
                    <Fragment>
                        {allCount === 0 && SMSCampaignsStatus.GetSuccess && (
                            <SMSCampaignsIsEmpty />
                        )}
                        {!isMobile && allCount > 0 && (
                            <>
                                <Header
                                    selectedFilter={filter}
                                    onFilterSelect={filtersHandler}
                                />

                                <Grid
                                    container
                                    spacing={3}
                                    className={classes.content}
                                >
                                    <Grid item xs={12} lg={4}>
                                        <SearchCampaign
                                            page={page}
                                            maxPages={maxPages}
                                            loading={
                                                status ===
                                                SMSCampaignsStatus.Fetching
                                            }
                                            campaigns={campaigns}
                                            selectedCampaign={selectedCampaign}
                                            onNextPage={pageHandler}
                                            onCampaignChange={campaignHandler}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        {status !==
                                            SMSCampaignsStatus.GetSuccess &&
                                            status !==
                                                SMSCampaignsStatus.FetchSuccess &&
                                            status !==
                                                SMSCampaignsStatus.GlobalFetchSuccess && (
                                                <Box
                                                    className={classes.loading}
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            )}
                                        {selectedCampaign !== undefined &&
                                            status !==
                                                SMSCampaignsStatus.Getting && (
                                                <CampaignsDetail
                                                    selectedCampaing={
                                                        selectedCampaign
                                                    }
                                                    onChangeStatus={
                                                        campaignChangeStatusHandler
                                                    }
                                                />
                                            )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {isMobile && allCount > 0 && (
                            <>
                                {selectedCampaign === undefined &&
                                    status !== SMSCampaignsStatus.Getting && (
                                        <Box
                                            className={
                                                classes.searchContainerMobile
                                            }
                                        >
                                            <Header
                                                selectedFilter={filter}
                                                onFilterSelect={filtersHandler}
                                            />
                                            <SearchCampaign
                                                page={page}
                                                maxPages={maxPages}
                                                loading={
                                                    status ===
                                                    SMSCampaignsStatus.Fetching
                                                }
                                                campaigns={campaigns}
                                                onNextPage={pageHandler}
                                                onCampaignChange={
                                                    campaignHandler
                                                }
                                            />
                                        </Box>
                                    )}
                                {selectedCampaign !== undefined &&
                                    status !== SMSCampaignsStatus.Getting && (
                                        <Box
                                            className={
                                                classes.detailsContainerMobile
                                            }
                                        >
                                            <CampaignsDetail
                                                onBack={() =>
                                                    setSelectedCampaign(
                                                        undefined
                                                    )
                                                }
                                                selectedCampaing={
                                                    selectedCampaign
                                                }
                                                onChangeStatus={
                                                    campaignChangeStatusHandler
                                                }
                                            />
                                        </Box>
                                    )}
                            </>
                        )}
                    </Fragment>
                )}
            </Box>
        </>
    );
};

export default SMSCampaigns;
