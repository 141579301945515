import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentsAddress } from '@spike/payments-model';
import { useMarketplace, useMasterData } from 'hooks';
import { FunctionComponent } from 'react';
import { useCommonStyles } from './CommonStyles';

interface AddressViewProps {
	address: PaymentsAddress;
	className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			display: 'block',

			[theme.breakpoints.up('md')]: {
				marginRight: 8,
				fontWeight: 500,
				display: 'inline-block'
			}
		}
	})
);

export const AddressView: FunctionComponent<AddressViewProps> = props => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	const marketplace = useMarketplace();
	const masterData = useMasterData();

	return (
		<Grid item container spacing={2} className={props.className}>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Street Number:</span>{' '}
				{props.address.streetNumber}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Street:</span>{' '}
				{props.address.street}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>City:</span>{' '}
				{props.address.city}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>State:</span>{' '}
				{marketplace.basics.address.country?.id.toUpperCase() === 'US'
					? masterData.usStates.find(
							state => state.id === props.address.state
					  )?.name
					: props.address.state}
			</Grid>
			<Grid item xs={12} md={6} className={commonClasses.formCell}>
				<span className={classes.label}>Zipcode:</span>{' '}
				{props.address.zipcode}
			</Grid>
		</Grid>
	);
};

export default AddressView;
