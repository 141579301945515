import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Button from '../Button';

interface OverFullWindowFooterProps {
    className?: string;
    onSave?: () => void;
    onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderTop: '1px solid #DDDDDD;',

            [theme.breakpoints.down(wbp)]: {
                minHeight: `${reduceResolution(66)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                minHeight: '66px'
            },
            [theme.breakpoints.down('sm')]: {
                borderTop: '0px solid #DDDDDD;',
                marginBottom: '3.5vh'
            }
        },
        leftContainer: {
            width: '20%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: 16,

            [theme.breakpoints.up('md')]: {
                paddingLeft: '32px'
            }
        },
        centerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        rightContainer: {
            width: '20%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '20px',
            alignItems: 'center',
            paddingRight: 14,

            [theme.breakpoints.up('md')]: {
                paddingRight: '32px'
            }
        },
        logo: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(42)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '42px'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: 'black',
            textAlign: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px'
            }
        },
        closeIcon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(24)}px !important`,
                height: `${reduceResolution(24)}px !important`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '24px !important',
                height: '24px !important'
            }
        },
        actionButton: {
            [theme.breakpoints.down('sm')]: {
                'width': '85%',
                'height': '55px',
                '& span > span': {
                    fontSize: '18px'
                },
                '&.MuiButton-label': {
                    fontSize: '18px !important'
                }
            }
        },
        actionButtons: {
            [theme.breakpoints.down('sm')]: {
                'width': '45%',
                'height': '46px',
                'marginRight': '5px',
                'marginLeft': '5px',
                'marginTop': '18px',
                '&.MuiButton-label': {
                    fontSize: '18px'
                }
            }
        }
    })
);
export const OverFullWindowFooter: FunctionComponent<
    OverFullWindowFooterProps
> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={clsx(classes.container, props.className)}>
            {!isMobile && <Box className={classes.leftContainer}></Box>}
            {!isMobile && <Box className={classes.centerContainer}></Box>}
            <Box
                className={
                    isMobile ? classes.centerContainer : classes.rightContainer
                }
            >
                {props.onBack && (
                    <Button
                        className={
                            props.onSave
                                ? classes.actionButtons
                                : classes.actionButton
                        }
                        onClick={() => props.onBack && props.onBack()}
                        label="Back"
                        color="black"
                    ></Button>
                )}
                {props.onSave && (
                    <Button
                        className={
                            props.onBack
                                ? classes.actionButtons
                                : classes.actionButton
                        }
                        onClick={() => props.onSave && props.onSave()}
                        label="Save"
                        color="green"
                    ></Button>
                )}
            </Box>
        </Box>
    );
};

export default OverFullWindowFooter;
