import { Box, createStyles, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ConfirmDialog } from "components/UI";
import React, { Fragment, FunctionComponent, useState } from "react";
import { wbp } from "Theme";
import DropZoneFile from "../Files/DropZoneFile";
import Important from "../Important";
import UploadedFiles, { UploadedFileModel } from "../Files/UploadedFiles";
import HelpCard from "components/Payments/Sidebar/HelpCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/pro-solid-svg-icons";

interface BankAccountDocumentFormProps {
  file?: UploadedFileModel;
  className?: string;
  onAddFile?: (file: File) => void;
  onRemoveFile?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "Poppins",
      color: "black",
      textTransformation: "capitalize",
      fontSize: 20,
      fontWeight: 600,
      lineHeigth: 40,
      padding: '20px 0'

    },
    subtitle: {
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: "14px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    important: {
      background: '#F8F5F1',
      display: 'flex',
      alignItems: 'start',
      [theme.breakpoints.down(wbp)]: {
        marginTop: "21px",
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "26px",
      },
    },
    importantText: {
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "black",
      [theme.breakpoints.down(wbp)]: {
        fontSize: "13px",
        lineHeight: "20px",
      },
      [theme.breakpoints.up(wbp)]: {
        fontSize: "16px",
        lineHeight: "25px",
      },
    },
    importantTextStrong: {
      fontWeight: 600,
    },
    uploadFile: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: "35px",
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "44px",
      },
      '& svg': {
        color: '#5E8677',
        width: '40px',
        height: '40px',
      }
    },
    uploadedFiles: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: "34px",
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "42px",
      },

    },
    info: {
      background: '#FBFAF9',
      borderRadius: 12,
      paddingLeft: 14,
      paddingRight: 14,
      paddingTop: 17,
      paddingBottom: 10,
    }
  })
);

export const BankAccountDocumentForm: FunctionComponent<BankAccountDocumentFormProps> = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const files = props.file ? [props.file] : [];
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <Fragment>
      <Box>
        {isMobile && <HelpCard className={classes.info} />}
        <Typography className={classes.title}>Bank Account Documentation</Typography>
        <Typography className={classes.subtitle}>
          Provide a bank statement, voided check, or online banking screenshot. It should clearly show the bank logo,
          your account number, and your name.
        </Typography>
        <Important className={classes.important}>
          <Typography className={classes.importantText}>
            It should <strong>clearly show</strong> the bank logo or bank name, your account number, and name of the account holder.
          </Typography>
        </Important>
        <DropZoneFile
          icon={<FontAwesomeIcon icon={faCloudArrowUp} />}
          className={classes.uploadFile}
          onDrop={(file: File) => {
            props.onAddFile && props.onAddFile(file);
          }}
        />
        {files.length > 0 && (
          <UploadedFiles
            files={files}
            className={classes.uploadedFiles}
            onRemove={(fileName) => setShowConfirmation(true)}
            title="Uploaded File"
          />
        )}
      </Box>
      <ConfirmDialog
        open={showConfirmation}
        question={<Typography>Do you want to delete the document?</Typography>}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        onCancel={() => {
          setShowConfirmation(false);
        }}
        onConfirm={() => {
          setShowConfirmation(false);
          props.onRemoveFile && props.onRemoveFile();
        }}
      />
    </Fragment>
  );
};

export default BankAccountDocumentForm;
