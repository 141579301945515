import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { wbp, reduceResolution } from "Theme";
import { Step as StepModel } from "./SidebarModel";
import SidebarStep from "./SidebarStep";

interface SidebarBodyProps {
  steps: Array<StepModel>;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      grow: 1,
    },
    step: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(14)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "14px",
      },
    },
  })
);

export const SidebarBody: FunctionComponent<SidebarBodyProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.className)}>
      {props.steps.map((step, index) => (
        <SidebarStep className={classes.step} key={step.key} index={index+1} step={step} />
      ))}
    </Box>
  );
};

export default SidebarBody;
