import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ClickAwayListener, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useTimeZone } from 'hooks';
import padStart from 'lodash/padStart';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useState } from 'react';
import Calendar from 'react-calendar';
import PrevNextSelector from './PrevNextSelector';

interface MonthSelectorProps {
    date: Moment;
    onChange?: (date: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
        },
        calendarContainer: {
            position: 'absolute',
            top: '40px',
            left: '-30%',
            right: '-30%'
        },
        calendar: {
            'width': '90%',
            'marginTop': '5px',
            'border': '1px solid #e5e5e5',
            'fontFamily': 'poppins',
            'fontSize': '1.1rem',
            'borderRadius': '20px',
            'padding': '15px 10px',
            'color': '#212529',
            'marginLeft': 'auto',
            'marginRight': 'auto',
            'position': 'relative',
            'backgroundColor': '#FFF',
            'zIndex': 9,
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                width: '100%',
                borderRadius: 14,
                overflow: 'hidden'
            },

            '& .react-calendar__month-view__days': {
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: 10
                }
            },

            '& .react-calendar__tile:hover': {
                backgroundColor: 'transparent !important'
            },

            '& .react-calendar__month-view__days__day--weekend': {
                color: '#212529',
                fontFamily: 'poppins',
                padding: '5px'
            },

            '& .react-calendar__month-view__weekdays': {
                color: '#92B4A7',
                fontFamily: 'poppins',
                fontWeight: 500,
                textTransform: 'capitalize',
                padding: '0 5px'
            },

            '& .react-calendar__month-view__days__day--neighboringMonth': {
                color: '#fff'
            },

            '& .react-calendar__navigation': {
                [theme.breakpoints.down('sm')]: {
                    marginBottom: 10
                }
            },

            '& .react-calendar__navigation button': {
                borderBottom: '1px solid #e5e5e5',
                fontSize: '1rem',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '0.85rem'
                }
            },

            '& .react-calendar__navigation button:hover': {
                backgroundColor: '#fff'
            },

            '& .react-calendar__navigation__label:hover, & .react-calendar__navigation button,  & .react-calendar__tile, & .react-calendar__tile:disabled':
                {
                    backgroundColor: '#fff',
                    fontFamily: 'poppins'
                },
            '& .react-calendar__month-view__days__day--neighboringMonth:hover':
                {
                    color: '#fff'
                },
            '& .react-calendar__tile': {
                'color': '#222',
                'padding': '0px 5px',

                '& abbr': {
                    height: 30,
                    border: 'solid 1px transparent',
                    fontSize: 14,
                    margin: 'auto',
                    borderRadius: 30,
                    display: 'block',
                    lineHeight: '30px'
                }
            },
            '& .react-calendar__navigation__arrow:not([disabled])': {
                color: '#222'
            },
            '& .react-calendar__month-view__days__day:not(.react-calendar__tile--active)':
                {
                    '& abbr': {
                        '&:hover': {
                            backgroundColor: '#FFF !important',
                            borderColor: '#000'
                        }
                    }
                },
            '& .react-calendar__tile--active': {
                'backgroundColor': 'transparent !important',

                '& > abbr': {
                    backgroundColor: '#ebf1ef !important'
                }
            },
            '& .react-calendar__tile:disabled': {
                color: '#d5d5d5'
            },
            '& abbr': {
                textDecoration: 'none'
            },
            '& .react-calendar__navigation__label__labelText': {
                color: '#222',
                fontWeight: 600,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 14,
                    fontWeight: 500
                }
            }
        }
    })
);

const breakpoint = 1200;

export const MonthSelector: FunctionComponent<MonthSelectorProps> = props => {
    const classes = useStyles();

    const theme = useTheme();
    const timeZone = useTimeZone();

    const [showCalendar, setShowCalendar] = useState(false);
    const isMedium = useMediaQuery(theme.breakpoints.down(breakpoint));

    const label = `${isMedium ? props.date
        .format('MMM') : props.date
        .format('MMMM')}, ${props.date.format('YYYY')}`;

    const changeMonth = (months: number) => {
        props.onChange &&
            props.onChange(
                props.date.clone().add(months, 'months').startOf('month')
            );
    };

    const clickPrevHandler = () => {
        changeMonth(-1);
    };

    const clickNextHandler = () => {
        changeMonth(1);
    };

    const selectMonth = (date: Date) => {
        const ddmmyyyy = `${padStart(
            date.getDate().toString(),
            2,
            '0'
        )}${padStart(
            (date.getMonth() + 1).toString(),
            2,
            '0'
        )}${date.getFullYear()}`;
        props.onChange &&
            props.onChange(moment.tz(ddmmyyyy, 'DDMMYYYY', timeZone).clone());
        setShowCalendar(false);
    };

    const calendar = (
        <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
            <Box className={classes.calendarContainer}>
                <Calendar
                    calendarType="US"
                    onClickMonth={selectMonth}
                    value={props.date.format('YYYY/MM/DD')}
                    className={classes.calendar}
                    nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                    prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                    prev2Label={null}
                    next2Label={null}
                    showNeighboringMonth={false}
                    locale={'en'}
                    defaultView="year"
                    maxDetail="year"
                />
            </Box>
        </ClickAwayListener>
    );

    return (
        <Box className={classes.container}>
            <PrevNextSelector
                onPrev={clickPrevHandler}
                onNext={clickNextHandler}
                onClick={() => setShowCalendar(prev => !prev)}
                label={label}
            />
            {showCalendar && calendar}
        </Box>
    );
};

export default MonthSelector;
