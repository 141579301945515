import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { wbp, reduceResolution } from 'Theme';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .ql-container.ql-snow': {
				[theme.breakpoints.down(wbp)]: {
					height: `${reduceResolution(500)}px !important`
				},
				[theme.breakpoints.up(wbp)]: {
					height: '500px !important'
				}
			},
			'& .ql-toolbar': {
				borderRadius: '10px 10px 0px 0px',
				[theme.breakpoints.down('sm')]: {
					'& > .ql-formats': {
						'marginRight': 8,
						'& > button': {
							width: 22
						}
					}
				}
			},
			'& .ql-container': {
				borderRadius: '0px 0px 10px 10px'
			},
			'& .ql-editor': {
				'minHeight': 400,
				'maxWidth': 'calc(100vw - 50px)',
				'& *': {
					lineHeight: 1.4,
					fontFamily: 'Poppins, sans-serif'
				},

				'& h1': {
					fontSize: 28,
					fontWeight: 600,
					marginBottom: 10
				},
				'& h2': {
					fontSize: 24,
					fontWeight: 600,
					marginBottom: 8
				},
				'& p, & ul, & ol': {
					fontSize: 15,
					fontWeight: 400,
					lineHeight: 1.4,
					paddingLeft: '0px'
				},
				'& li': {
					marginBottom: '1rem'
				},

				'& .ql-size-large': {
					fontSize: '1.25rem'
				},
				'& .ql-size-huge': {
					fontSize: '1.5rem'
				}
			}
		}
	})
);

interface Props {
	value?: string;
	onChange?: (value: string) => void;
}

const QuillEditor = ({ value, onChange }: Props) => {
	const classes = useStyles();
	const container = useRef<HTMLDivElement>(null);
	const [editor, setEditor] = useState<Quill | null>(null);

	useEffect(() => {
		if (!container.current || editor) return;

		if (value) container.current.innerHTML = value;

		// eslint-disable-next-line prefer-const
		let currEditor = new Quill(container.current, {
			modules: {
				toolbar: [
					[{ size: [] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ color: [] }, { background: [] }],
					[{ script: 'super' }, { script: 'sub' }],
					[{ header: '1' }, { header: '2' }],
					[
						{ list: 'ordered' },
						{ list: 'bullet' },
						{ indent: '-1' },
						{ indent: '+1' }
					],
					[{ align: [] }],
					['link'],
					['clean']
				]
			},
			placeholder: '',
			theme: 'snow'
		});

		currEditor.on('text-change', () => {
			if (!container.current) return;

			const value =
				container.current.querySelector('.ql-editor')?.innerHTML;

			if (!value) return;

			if (onChange) onChange(value);
		});

		setEditor(currEditor);
	}, []);

	return (
		<Box className={classes.root}>
			<div id="container" ref={container}></div>
		</Box>
	);
};

export default QuillEditor;
