import {
    faChevronDown,
    faChevronUp,
    faUser
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, DateFormat } from 'components/UI';
import capitalize from 'lodash/capitalize';
import Client from '@spike/client-model';
import moment, { Moment } from 'moment-timezone';
import React, {
    FunctionComponent,
    MouseEvent,
    useEffect,
    useState
} from 'react';
import { reduceResolution, wbp } from 'Theme';
import { formatPhoneNumber } from '@spike/phone-utils';
import { useCommonStyles } from './CommonStyles';
import TotalPets from './TotalPets';
import { useMarketplace } from 'hooks';

interface ClientRowProps {
    client: Client;
    expanded: boolean;
    onToggle: () => void;
    onBook?: () => void;
    onEdit?: () => void;
    className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clientRow: {
            cursor: 'pointer',

            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            [theme.breakpoints.up('md')]: {
                padding: '14px 0px'
            }
        },
        clientCellText: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: 'black',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15px'
            }
        },
        clientNameCol: {
            flex: 1,
            overflow: 'hidden'
        },
        clientName: {
            fontSize: 16,
            fontWeight: 500,
            color: '#000',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        clientEmail: {
            fontSize: 14,
            fontWeight: 400,
            color: '#7A7A7A',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        clientAvatar: {
            width: 42,
            height: 42,
            fontSize: 20,
            color: '#fff',
            marginRight: 10,
            flex: '0 0 42px',
            borderRadius: '50%',
            alignItems: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            backgroundColor: '#DCCCBB',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                'height': 39,
                'padding': '0px 19px',

                '& span': {
                    fontSize: 14,
                    fontWeight: 600
                }
            }
        },
        expandIcon: {
            cursor: 'pointer'
        },
        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
    })
);

export const geTotalVisits = (client: Client) => {
    return client.pets.reduce((total, pet) => total + pet.totalVisits, 0);
};

export const getLastVisit = (client: Client) => {
    const lastVisit: Moment | null = client.pets.reduce<Moment | null>(
        (lastVisit, pet) =>
            pet.lastVisitDate &&
            (lastVisit === null || moment(pet.lastVisitDate).isAfter(lastVisit))
                ? moment(pet.lastVisitDate)
                : lastVisit,
        null
    );

    return lastVisit === null ? '-' : <DateFormat date={lastVisit} />;
};

export const getNextVisit = (client: Client) => {
    const nextVisit: Moment | null = client.pets.reduce<Moment | null>(
        (nextVisit, pet) =>
            pet.nextVisitDate &&
            (nextVisit === null ||
                moment(pet.nextVisitDate).isBefore(nextVisit))
                ? moment(pet.nextVisitDate)
                : nextVisit,
        null
    );

    return nextVisit === null ? '-' : <DateFormat date={nextVisit} />;
};

const ClientRow: FunctionComponent<ClientRowProps> = props => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const marketplace = useMarketplace();

    const [petLength, setPetLength] = useState(0);

    useEffect(() => {
        const petActive = props.client.pets.filter(
            pet => pet.active && !pet.deceased
        );
        setPetLength(petActive.length);
    }, [props.client.pets]);

    const editHandler = (event: MouseEvent) => {
        event.stopPropagation();
        props.onEdit && props.onEdit();
    };

    const bookHandler = (event: MouseEvent) => {
        event.stopPropagation();
        props.onBook && props.onBook();
    };

    return (
        <Grid
            container
            item
            xs={12}
            className={clsx(classes.clientRow, props.className)}
            onClick={editHandler}
        >
            <Grid
                item
                md={3}
                xs="auto"
                className={clsx(
                    classes.clientNameCol,
                    commonClasses.cellVerticalCenter
                )}
            >
                <Box className={classes.clientAvatar}>
                    <FontAwesomeIcon icon={faUser} />
                </Box>
                <Box
                    style={{ overflow: 'hidden' }}
                    id={`clients_list_client_${props.client.uuid}`}
                >
                    <Typography className={classes.clientName}>
                        {`${props.client.firstName} ${props.client.lastName}`}
                    </Typography>
                    <Typography className={classes.clientEmail}>
                        {props.client.email}
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
                xs={1}
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    commonClasses.cellCenter,
                    classes.desktop
                )}
            >
                <TotalPets client={props.client} />
            </Grid>
            <Grid
                item
                xs={2}
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    classes.desktop
                )}
            >
                <Typography className={classes.clientCellText}>
                    {formatPhoneNumber(
                        props.client.phone,
                        marketplace.basics.address.country!.id
                    ) || '-'}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    commonClasses.cellCenter,
                    classes.desktop
                )}
            >
                <Typography
                    className={clsx(classes.clientCellText, classes.desktop)}
                >
                    {geTotalVisits(props.client)}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    classes.desktop
                )}
            >
                <Typography
                    className={clsx(classes.clientCellText, classes.desktop)}
                >
                    {getLastVisit(props.client)}
                </Typography>
            </Grid>
            <Grid
                item
                xs={2}
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    classes.desktop
                )}
            >
                <Typography className={classes.clientCellText}>
                    {getNextVisit(props.client)}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                xs="auto"
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    commonClasses.cellCenter
                )}
            >
                {props.onBook && petLength > 0 && (
                    <Button
                        label="Book"
                        color="green"
                        className={classes.button}
                        onClick={bookHandler}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={1}
                className={clsx(
                    commonClasses.cellVerticalCenter,
                    commonClasses.cellCenter,
                    classes.desktop
                )}
            >
                <FontAwesomeIcon
                    icon={props.expanded ? faChevronUp : faChevronDown}
                    className={classes.expandIcon}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        props.onToggle();
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default ClientRow;
