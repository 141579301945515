import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import logo from 'rocket/assets/images/logo.svg';
import orangeCat from 'rocket/assets/images/orange-cat.png';

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        width: 176,
        margin: '0 auto',
        display: 'block',
        marginBottom: 64,
        alignSelf: 'flex-start'
    },
    column: {
        display: 'none',
        height: '100vh',
        textAlign: 'center',
        overflow: 'hidden',
        paddingTop: 24,
        top: 0,
        left: 0,
        position: 'fixed',
        justifyContent: 'center',
        backgroundColor: '#ECE9E6',
        [theme.breakpoints.up("md")]: {
            maxWidth: '45%',
            flexBasis: '45%',
            display: 'flex',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    title: {
        top: 0,
        zIndex: 11,
        bottom: 100,
        height: 160,
        margin: 'auto',
        color: '#ffffff',
        fontSize: 135,
        display: 'block',
        position: 'absolute',
        [theme.breakpoints.down("md")]: {
            fontSize: 100
        },
        [theme.breakpoints.up("md")]: {
            marginTop: 'auto'
        }
    },
    image: {
        width: '90%',
        zIndex: 10,
        position: 'relative',
        [theme.breakpoints.up("md")]: {
            marginTop: 'auto'
        }
    }
}))

export const LeftColumn: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.column}>
            <img src={logo} alt="Cuddles Demo Logo" className={classes.logo} />

            <img src={orangeCat} className={classes.image} alt="Woman with puppies" />
        </Grid>
    )
}

export default LeftColumn;