import { createStyles, makeStyles, OutlinedInput, Theme, Typography } from "@material-ui/core";

import { useToggle } from "hooks";
import { Checkbox } from 'components/UI';
import { isEmpty } from "lodash";
import React, { FunctionComponent, useState } from "react";
import { lgPixel, xlPixel } from "utils/VariableUtils";

export interface Props {
  onAddType: (value: string) => void;
  placeholderText?: string;
}

const lineHeight = 30;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addOther: {
      fontWeight: 600,
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        color: "#92B4A7",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "27px"
      },
      [theme.breakpoints.only("lg")]: {
        lineHeight: lgPixel(lineHeight),
      },
      [theme.breakpoints.only("xl")]: {
        lineHeight: xlPixel(lineHeight),
      },
    },
    containerCheck: {
      "& .MuiCheckbox-root": {
        padding: "0px !important",
        [theme.breakpoints.down("sm")]: {
          marginRight: "4px !important"
        },
        [theme.breakpoints.only("md")]: {
          marginRight: "6px !important"
        },
        [theme.breakpoints.only("lg")]: {
          marginRight: "8px !important"
        },
        [theme.breakpoints.only("xl")]: {
          marginRight: "12px !important"
        },
      },
    },
    outlineInput: {
      "& .MuiInputBase-root": {
        padding: "0px !important",
      },
    },
  })
);

export const AddOther: FunctionComponent<Props> = ({ onAddType, placeholderText }) => {
  const classes = useStyles();

  const [openCheck, handleOpenCheck] = useToggle(true);

  const [isAutoFocus, setIsAutoFocus] = useState(false);
  const [isAddingOther, setIsAddingOther] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const handleClick = () => {
    setIsAutoFocus(true);
    setIsAddingOther(true);
  };

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const handleBlur = () => {
    setIsAutoFocus(true);
    setIsAddingOther(false);
    if (!isEmpty(inputValue)) {
      onAddType(inputValue);
      setInputValue("");
    }
  };
  const handleEnterKeyPress = (event: any) => {
    if (event.key === "Enter") {
      setIsAddingOther(false);
      if (!isEmpty(inputValue)) {
        onAddType(inputValue);
        setInputValue("");
      }
    }
  };

  return isAddingOther ? (
    <OutlinedInput
      autoFocus={isAutoFocus}
      onBlur={handleBlur}
      onKeyPress={handleEnterKeyPress}
      onChange={handleChange}
      className={classes.outlineInput}
      placeholder={placeholderText || ""}
      startAdornment={
        <Checkbox
          checked={openCheck}
          onChange={handleOpenCheck}
          className={classes.containerCheck}
          iconColor={"#000000"}
        />
      }
    />
  ) : (
    <Typography className={classes.addOther} onClick={handleClick} noWrap={true}>
      + Add other
    </Typography>
  );
};
