import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DateSelectorSection from './DateSelectorSection';
import { Moment } from 'moment-timezone';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { NewBookingAlert } from '@spike/new-booking-model';
import { AlertNewBooking } from '../UI/AlertNewBooking';
import { Option } from '@spike/model';
import { useMasterData } from 'hooks';
import { Switch } from 'components/UI';
import SelectAppointmentFrequency from './SelectAppointmentFrequency';

interface Props {
    isCollectDeposit?: boolean;
    date: Moment;
    isRecurrent: boolean;
    duration?: Option<string>;
    frequency?: Option<string>;
    alerts?: Array<NewBookingAlert>;
    readOnly?: boolean;
    multiplePet?: boolean;
    showAlertBusinessHour?: boolean;
    onChangeFrequencyDuration: (
        duration: Option<string> | undefined,
        frequency: Option<string>
    ) => void;
    onChangeRecurrent: (isRecurrent: boolean) => void;
    onChangeDate: (date: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderBottom: '1px solid #D4D4D4'
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            marginBottom: 8,
            [theme.breakpoints.up('md')]: {
                marginBottom: 10
            }
        },
        recurring: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '16px',
            paddingBottom: 18,
            marginRight: 9,
            width: 'auto',
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                paddingBottom: 20,
                marginRight: 10
            }
        },
        switch: {
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        selectField: {
            height: '54px !important',
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        headerContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        alert: {
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        alertText: {
            fontSize: 14,
            lineHeight: '20px'
        },
        dateSelectorContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        }
    })
);

export const DateSelector: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const ref = useRef<HTMLDivElement>(null);

    const onChangeFrequency = (value: Option<string> | undefined) => {
        const scheduleDuration = masterData.scheduleDuration.find(
            sd => sd.id === 'one_year'
        );

        if (value !== undefined) {
            props.onChangeFrequencyDuration(scheduleDuration, value);
        }
    };

    useEffect(() => {
        ref?.current?.scrollIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, props.readOnly]);

    return (
        <Box className={classes.container}>
            <div ref={ref}>
                <Typography className={classes.title}>Date & Time</Typography>
            </div>
            <Box className={classes.dateSelectorContainer}>
                <DateSelectorSection
                    date={props.date}
                    onSelectDate={props.onChangeDate}
                />
                {!props.isCollectDeposit && !props.multiplePet && (
                    <Box className={classes.headerContainer}>
                        <Typography className={classes.recurring}>
                            Recurring
                        </Typography>
                        <Switch
                            id="booking_button_frequency"
                            checked={props.isRecurrent}
                            className={classes.switch}
                            onChange={() =>
                                props.onChangeRecurrent(!props.isRecurrent)
                            }
                        />
                    </Box>
                )}
            </Box>

            {props.isRecurrent && (
                <SelectAppointmentFrequency
                    placeholder="Frequency"
                    options={masterData.scheduleFrecuency}
                    selectedOption={props.frequency}
                    className={classes.selectField}
                    onSelect={onChangeFrequency}
                />
            )}
            {props.alerts?.map(alert => {
                if (alert.alertType !== 'date_time') {
                    return (
                        <Box
                            key={`booking_div_${alert.alertType}_alert`}
                            id={`booking_div_${alert.alertType}_alert`}
                        >
                            <AlertNewBooking
                                className={classes.alert}
                                bWidth="2px"
                                icon={faExclamationTriangle}
                                iconSize="lg"
                                iconColor="#EAB464"
                                bgColor="#F8F5F1"
                                bdColor="#BCB8AE"
                            >
                                <Typography className={classes.alertText}>
                                    {alert.message}
                                </Typography>
                            </AlertNewBooking>
                        </Box>
                    );
                }
            })}
            {props.showAlertBusinessHour && (
                <Box
                    key="booking_div_date_time_alert"
                    id="booking_div_date_time_alert"
                >
                    <AlertNewBooking
                        className={classes.alert}
                        bWidth="2px"
                        icon={faExclamationTriangle}
                        iconSize="lg"
                        iconColor="#EAB464"
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                    >
                        <Typography className={classes.alertText}>
                            Date/Time outside business hours
                        </Typography>
                    </AlertNewBooking>
                </Box>
            )}
        </Box>
    );
};

export default DateSelector;
