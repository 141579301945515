import React, { FunctionComponent } from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
  petType?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipPet: {
      border: "0px",
      fontSize: "0.7rem",
      fontWeight: 500,
      marginLeft: "5px",
      height: "25px",
      width: "60px",
      [theme.breakpoints.down('sm')]: {
        width: 66,
        fontSize: 13
      }
    },
    chipPets: {
      background: "#f1f1f1",
      fontSize: "0.5rem",
    },
    chipDog: {
      backgroundColor: "#E9F0ED",
    },
    chipCat: {
      backgroundColor: "#FAEFDF",
      color: "#222222",
    },
    chipExotic: {
      backgroundColor: "#EBE7E3",
      color: "#222222",
    },
  })
);

export const ChipPet: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Chip
      className={clsx(
        classes.chipPet,
        { [classes.chipDog]: props.petType === "Dog" },
        { [classes.chipCat]: props.petType === "Cat" },
        { [classes.chipExotic]: props.petType === "Exotic" },
        props.className
      )}
      label={props.petType}
    />
  );
};

export default ChipPet;
