import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ConfirmDialog } from 'components/UI';

export interface TitleProps {
    open: boolean;
    handleClose: () => void;
    title: string;
    question: string;
    cancelButtonLabel: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.down('sm')]: {
                fontSize: 26
            }
        },
        question: {
            fontSize: 16,
            lineHeight: '140%',
            padding: '0px 40px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 20px'
            }
        },
        dialog: {
            '& .MuiDialog-paper': {
                maxWidth: '510px'
            },
            [theme.breakpoints.down('sm')]: {
                '& .MuiDialogActions-root button': {
                    width: '100%'
                }
            }
        }
    })
);

export const RestictedDialog: React.FunctionComponent<TitleProps> = props => {
    const classes = useStyles();

    return (
        <ConfirmDialog
            className={classes.dialog}
            open={props.open}
            title={
                <Typography className={classes.title}>{props.title}</Typography>
            }
            question={
                <Typography className={classes.question}>
                    {props.question}
                </Typography>
            }
            cancelButtonLabel="Done"
            onCancel={props.handleClose}
        />
    );
};

export default RestictedDialog;
