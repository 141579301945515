import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Img } from 'react-image';
import clsx from 'clsx';
import {
	BookingsView,
	completedView,
	cancelledView,
	upcomingView,
	allView,
	pendingView,
	staffIssuesView
} from '@spike/bookings-view';

interface Props {
	view: BookingsView;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			minHeight: '100%',
			alignItems: 'center',
			justiContent: 'center'
		},
		imageUpcoming: {
			width: '90%',
			marginTop: 30,

			[theme.breakpoints.up('md')]: {
				width: '497px',
				height: '225px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '662px',
				height: '300px',
				marginTop: '41px'
			}
		},
		imageCompleted: {
			width: '90%',
			marginTop: 30,

			[theme.breakpoints.up('md')]: {
				width: '367px',
				height: '231px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '489px',
				height: '308px',
				marginTop: '34px'
			}
		},
		imageCancelled: {
			width: '100%',

			[theme.breakpoints.up('md')]: {
				width: '468px',
				height: '236px',
				marginTop: '17px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '624px',
				height: '315px',
				marginTop: '23px'
			}
		},
		title: {
			textAlign: 'center',
			fontWeight: 700,
			[theme.breakpoints.down('lg')]: {
				marginTop: '13px',
				lineHeight: '27px',
				fontSize: '24px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '20px',
				lineHeight: '36px',
				fontSize: '26px'
			}
		},
		description: {
			textAlign: 'center',
			fontWeight: 500,
			[theme.breakpoints.down('lg')]: {
				lineHeight: '20px',
				fontSize: '16px'
			},
			[theme.breakpoints.up('xl')]: {
				lineHeight: '27px',
				fontSize: '18px'
			}
		},
		button: {
			[theme.breakpoints.down('lg')]: {
				marginTop: '26px',
				marginBottom: '26px',
				width: '150px',
				height: '41px',
				fontSize: '14px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '34px',
				marginBottom: '34px',
				width: '200px',
				height: '55px',
				fontSize: '18px'
			}
		}
	})
);

export const BookingsNotCreated: FunctionComponent<Props> = props => {
	const classes = useStyles();

	let image = '/images/bookings/EmptyCancelled.svg';
	let firstLine = 'Raise the woof!';
	let secondLine = 'There are currently no cancelations.';

	if (props.view.id === upcomingView.id) {
		image = '/images/bookings/EmptyUpcoming.svg';
		firstLine = 'Don’t get too comfy.';
		secondLine = 'This will fill up soon enough.';
	} else if (props.view.id === completedView.id) {
		image = '/images/bookings/EmptyCompleted.svg';
		firstLine = 'Almost there.';
		secondLine = 'Nothing completed just yet.';
	} else if (props.view.id === staffIssuesView.id) {
		secondLine = 'There are currently no staff issues.';
	} else if (props.view.id === pendingView.id) {
		secondLine = 'There are currently no pendings.';
	}

	return (
		<Box className={classes.container}>
			<Img
				className={clsx({
					[classes.imageCancelled]: [
						cancelledView.id,
						allView.id,
						staffIssuesView.id,
						pendingView.id
					].includes(props.view.id),
					[classes.imageCompleted]:
						props.view.id === completedView.id,
					[classes.imageUpcoming]: props.view.id === upcomingView.id
				})}
				src={image}
			/>
			<Typography className={classes.title}>{firstLine}</Typography>
			<Typography className={classes.description}>
				{secondLine}
			</Typography>
		</Box>
	);
};

export default BookingsNotCreated;
