import React, { FunctionComponent } from "react";
import { Link } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      cursor: "pointer",
      textDecoration: "none",
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xs")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "19px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);
  

export const BackButton: FunctionComponent<Props> = (props) => {
  
  const classes = useStyles(props);
  return (
    <Link className={classes.back} onClick={props.onClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </Link>
  );
};

export default BackButton;
