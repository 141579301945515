import React, { FunctionComponent } from 'react';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';

interface DentalReportImagesSummaryProps {
	images: Array<string>;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',

			[theme.breakpoints.down('sm')]: {
				marginBottom: 32
			}
		},
		titleContainer: {},
		title: {
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 600,
			marginBottom: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 14,
				fontWeight: 400,
				color: '#A3A3A3',
				marginBottom: 24,
				textTransform: 'uppercase'
			}
		},
		singleImageContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			cursor: 'pointer',
			backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23222222FF' stroke-width='1' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
			borderRadius: '30px',
			overflow: 'hidden',
			[theme.breakpoints.down('md')]: {
				width: '450px',
				height: '270px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '525px',
				height: '315px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '750px',
				height: '450px'
			}
		},
		imagesContainer: {
			gap: 16,
			height: 350,
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			cursor: 'pointer',
			overflow: 'hidden',

			[theme.breakpoints.up('md')]: {
				width: '450px',
				height: '238px',
				borderRadius: 30,
				backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23222222FF' stroke-width='1' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
			},
			[theme.breakpoints.only('lg')]: {
				width: '525px',
				height: '317px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '750px',
				height: '480px'
			}
		},
		image: {
			width: '100%',
			height: '100%',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',

			[theme.breakpoints.down('sm')]: {
				border: 0,
				borderRadius: 20,
				overflow: 'hidden'
			}
		},
		singleImage: {
			backgroundImage: (props: DentalReportImagesSummaryProps) =>
				'url(' + props.images[0] + ')'
		},
		beforeImage: {
			backgroundImage: (props: DentalReportImagesSummaryProps) =>
				'url(' + props.images[0] + ')'
		},
		afterImage: {
			backgroundImage: (props: DentalReportImagesSummaryProps) =>
				'url(' + props.images[1] + ')'
		}
	})
);

export const DentalReportImagesSummary: FunctionComponent<
	DentalReportImagesSummaryProps
> = props => {
	const classes = useStyles(props);

	const isSingleImage =
		props.images.filter(image => image !== null).length === 1;

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>
					Before & After Picture
				</Typography>
			</Box>

			<Box
				className={clsx({
					[classes.singleImageContainer]: isSingleImage,
					[classes.imagesContainer]: !isSingleImage
				})}
			>
				<Box
					className={clsx(classes.image, {
						[classes.singleImage]: isSingleImage,
						[classes.beforeImage]: !isSingleImage
					})}
				/>
				{!isSingleImage && (
					<Box className={clsx(classes.image, classes.afterImage)} />
				)}
			</Box>
		</Box>
	);
};

export default DentalReportImagesSummary;
