import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { wbp } from "Theme";

interface LogoCProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      [theme.breakpoints.down(wbp)]: {
        width: "34px !important",
        height: "34px !important",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "42px !important",
        height: "42px !important",
      },
    },
  })
);

export const LogoC: FunctionComponent<LogoCProps> = (props) => {
  const classes = useStyles();

  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(classes.logo, props.className)}
    >
      <rect x="0.888672" y="-6.10352e-05" width="41.1122" height="41.1122" rx="7.87797" fill="#222222" />
      <path
        d="M33.6368 22.227C33.9807 22.318 34.2788 22.4089 34.1641 22.8865C33.2699 26.8433 31.5273 33.097 24.6029 34.2795C15.9817 35.7349 10.2724 30.7775 8.96547 23.1366C7.95661 17.2013 9.46991 8.60535 19.7649 6.87706C24.5341 6.08113 30.037 6.62691 32.1006 11.6753L32.1693 11.9027C32.2381 12.2666 32.0547 12.5395 31.6649 12.6987L27.3543 15.0637C27.1938 15.1546 27.0333 15.2001 26.9187 15.2229C26.6206 15.2684 26.4372 15.1546 26.4372 14.9272L26.4142 14.3815L26.3225 13.7447C25.8869 11.2205 24.0755 9.03742 19.9713 9.71964C14.8811 10.5838 14.1474 16.5646 14.8352 20.5442C16.0046 27.4346 19.2605 31.7326 24.6029 30.8457C29.9223 29.9361 30.5643 25.1378 31.2751 22.3862C31.3668 22.0451 31.5503 21.8631 31.8025 21.8177C31.9401 21.7949 32.1006 21.8177 32.284 21.8859L33.6368 22.227Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoC;
