import React, { FunctionComponent } from "react";
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface AlertProps {
	icon: IconProp;
	iconSize?: SizeProp;
	iconColor: string;
	bgColor: string;
	bdColor: string;
	bWidth?: string;
	className?: string;
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			borderRadius: 16,
			
			borderStyle: "solid",
			borderColor: "#000",
			marginBottom: 30,

			[theme.breakpoints.down('sm')]: {
				padding: "12px 13px"
			},
			[theme.breakpoints.up('md')]: {
				padding: "14px 15px",
			},

			"& strong, & b": {
				fontWeight: 600,
			},
		},
		box: {
			marginLeft: 8,
		},
	})
);

export const AlertNewBooking: FunctionComponent<AlertProps> = (props) => {
	const classes = useStyles();

	return (
		<Box
			className={clsx(classes.root, props.className)}
			style={{
				backgroundColor: props.bgColor,
				borderColor: props.bdColor,
				borderWidth: props.bWidth || "1px",
			}}
		>
			<Grid container xs={12} spacing={0}>
				<Grid item>
					<FontAwesomeIcon
						size={props.iconSize || "xl"}
						icon={props.icon}
						color={props.iconColor}
					/>
				</Grid>
				<Grid item xs>
					<Box className={classes.box}>{props.children}</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
