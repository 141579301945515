import lowerCase from 'lodash/lowerCase';
import capitalize from 'lodash/capitalize';
import { startCase } from 'lodash';

export const isTemporalEmail = (email: string) => {
    return email.includes('@temporal');
};

export const validateRequired = (
    fieldName: string,
    formData: any,
    errorFieldName?: string
) => {
    const attributes = fieldName.split('.');
    const [lastAttribute] = fieldName
        .split('.')
        .map(a => lowerCase(a))
        .reverse();
    const capitalizedLastAttribute = lastAttribute
        .split(' ')
        .map(w => capitalize(w))
        .join(' ');

    let data: any = { ...formData };

    attributes.forEach(attribute => {
        data = data[attribute];
    });

    return !data || data.length === 0
        ? {
              fieldName,
              errorMessage: `${
                  errorFieldName || capitalizedLastAttribute
              } is required`
          }
        : undefined;
};

export const customCapitalization = (
    value: string,
    allowSpecialCharacters: boolean,
    withEmojis?: boolean
): string => {
    // When not allowing special characters, use startCase for general capitalization.
    if (!allowSpecialCharacters) {
        return startCase(value.toLowerCase());
    }

    const emojis =
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    const hasEmojis = withEmojis && value.match(emojis);
    const allowedSpecialChars = [' ', '-', '’', "'", '.', '&', '/'];

    let filteredValue = value
        .split('')
        .filter(
            char =>
                char.match(/[A-Za-z]/) ||
                allowedSpecialChars.includes(char) ||
                hasEmojis
        )
        .join('');

    // Ensure the first letter of the entire input is capitalized.
    // The rest of the string, including characters following special characters, remains as entered by the user.
    if (filteredValue.length > 0) {
        filteredValue =
            filteredValue.charAt(0).toUpperCase() + filteredValue.slice(1);
    }

    return filteredValue;
};
