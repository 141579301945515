import { Reducer } from "redux";
import { NewBookingsState, NewBookingsStatus } from "./NewBookingsState";
import {
  NEW_BOOKINGS_SAVE_START_ACTION_TYPE,
  NEW_BOOKINGS_SAVE_SUCCESS_ACTION_TYPE,
  NEW_BOOKINGS_DEPOSIT_SAVE_START_ACTION_TYPE,
  NEW_BOOKINGS_DEPOSIT_SAVE_SUCCESS_ACTION_TYPE,
  NEW_BOOKINGS_ERROR_ACTION_TYPE,
  NEW_BOOKINGS_SAVE_CREATE_START_ACTION_TYPE,
  NEW_BOOKINGS_SAVE_CREATE_SUCCESS_ACTION_TYPE,
  NewBookingsAction,
} from "actions/newBookings/NewBookingsActionsTypes";

const initialState: NewBookingsState = {
  booking: undefined,
  newAppointment: undefined,
  status: NewBookingsStatus.Initial,
  loading: false,
};

export const newBookingsReducer: Reducer<NewBookingsState, NewBookingsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NEW_BOOKINGS_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: NewBookingsStatus.Saving,
        loading: true,
      };
    case NEW_BOOKINGS_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: NewBookingsStatus.SaveSuccess,
        loading: false,
      };
    case NEW_BOOKINGS_DEPOSIT_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: NewBookingsStatus.SavingDeposit,
        loading: true,
      };
    case NEW_BOOKINGS_DEPOSIT_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: NewBookingsStatus.SaveDepositSuccess,
        booking: action.payload.booking,
        loading: false,
      };
    case NEW_BOOKINGS_SAVE_CREATE_START_ACTION_TYPE:
      return {
        ...state,
        status: NewBookingsStatus.SavingCreateAppointment,
        loading: true,
      };
    case NEW_BOOKINGS_SAVE_CREATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        newAppointment: action.payload.newAppointment,
        status: NewBookingsStatus.SavingCreateAppointmentSuccess,
        loading: false,
      };
    case NEW_BOOKINGS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: NewBookingsStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default newBookingsReducer;
