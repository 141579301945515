import React, { FunctionComponent } from 'react';
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import SearchComponent from '../SearchComponent';

interface Props {
    title: string;
    subtitle?: string;
    variant: 'clients' | 'custom';

    onClose: () => void;
    onSearch: (search: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            position: 'relative',
            padding: '20px 22px 25px',
            backgroundColor: '#FAFAFA',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 22px 25px'
            }
        },
        close: {
            top: 20,
            right: 30,
            border: 0,
            fontSize: 24,
            lineHeight: 1,
            cursor: 'pointer',
            position: 'absolute',
            backgroundColor: 'transparent',
            color: '#000000 !important',
            [theme.breakpoints.down('sm')]: {
                right: 16
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 8
        },
        subtitle: {
            margin: 0,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.4
        },
        search: {
            'height': 47,
            'width': '100%',
            'marginTop': 22,
            'padding': '0px 10px',
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
            '& .MuiInputBase': {
                '&-root': {
                    padding: '0px 20px',
                    backgroundColor: '#fff',
                    [theme.breakpoints.down('sm')]: {
                        padding: '0px 22px'
                    }
                },
                '&-input': {
                    fontSize: 16
                }
            },
            '& .MuiInputAdornment-root': {
                'opacity': 0.3,
                'pointer-events': 'none',

                '& svg': {
                    width: 14,
                    cursor: 'default'
                }
            }
        }
    })
);

const ChooseModalHeader: FunctionComponent<Props> = ({
    title,
    subtitle,
    variant,
    onClose,
    onSearch
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <button className={classes.close} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <p className={classes.title}>{title}</p>
            {subtitle && <p className={classes.subtitle}>{subtitle}</p>}

            {variant === 'clients' && (
                <SearchComponent
                    open={true}
                    onSearch={onSearch}
                    placeholder="Search Client"
                    className={classes.search}
                />
            )}
        </Box>
    );
};

export default ChooseModalHeader;
