import ProductVendor from "model/ProductVendor";
import { ProductVendorDto } from "./ProductVendorsDtos";
import { convertToProduct } from "@spike/products-action";
import { store } from "store";

export const convertToProductVendor = (vendorDto: ProductVendorDto): ProductVendor => {
  const [address] = vendorDto.addresses.filter((address) => address.active);

  return {
    id: vendorDto.id,
    uuid: vendorDto.uuid,
    name: vendorDto.name,
    contact: vendorDto.contact_name,
    phone: vendorDto.phone,
    email: vendorDto.email,
    address: address ? {
      street: address?.address_line_one,
      city: address?.city,
      state: address?.state,
      zipcode: address?.zipcode,
    } : undefined,
    products: vendorDto.products.map(product => convertToProduct(product, store.getState().masterData.data, store.getState().marketplace.marketplace!)),
    active: vendorDto.active,
    deleted: vendorDto.deleted,
    website: vendorDto.website
  };
};
