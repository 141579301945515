import React, { FunctionComponent, ReactNode } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Accordion as AccordionMUI,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { faCaretDown, faCaretUp, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  id?: string;
  title: string;
  titleStyle?: string;
  titleContainerStyles?: string;
  titleIcon?: IconDefinition;
  titleIconStyles?: string;
  expandIcon?: IconDefinition;
  expandComponent?: ReactNode;
  withoutIcon?: true;
  expandIconStyle?: string;
  contentContainerStyles?: string;
  containerStyles?: string;
  expanded: boolean;
  handleExpandedChange: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "12px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "20px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "26px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "40px",
      },
    },
    content: {
      display: "flex",
    },
    expandIcon: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "26px",
      },
    },
    boxShadowNone: {
      boxShadow: "none",
      "&.MuiAccordion-root:before": {
        height: "0px",
      },
      "&.MuiAccordion-root.Mui-expanded": {
        marginTop: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0.614rem",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "0.99rem",
      },
      [theme.breakpoints.only("lg")]: {
        marginBottom: "1.32rem",
      },
      [theme.breakpoints.only("xl")]: {
        marginBottom: "2rem",
      },
    },
  })
);

export const Accordion: FunctionComponent<Props> = ({
  id,
  title,
  titleStyle,
  titleContainerStyles,
  titleIcon,
  titleIconStyles,
  expandIcon,
  expandComponent,
  expandIconStyle,
  children,
  contentContainerStyles,
  containerStyles,
  expanded,
  withoutIcon,
  handleExpandedChange,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AccordionMUI

      expanded={expanded}
      onChange={handleExpandedChange}
      className={`${containerStyles}  ${classes.boxShadowNone}`}
    >
      <AccordionSummary

        expandIcon={
          !withoutIcon ? (
            expandIcon ? (
              <FontAwesomeIcon icon={faCaretUp} className={`${classes.expandIcon} ${expandIconStyle}`} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} className={`${classes.expandIcon} ${expandIconStyle}`} />
            )
          ) : undefined
        }
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        className={`${titleContainerStyles} ${smDownBreakpoint && classes.container}`}
      >
        {titleIcon && <FontAwesomeIcon icon={titleIcon} className={titleIconStyles} />}
        <Typography className={titleStyle}>{title}</Typography>
        {expandComponent}
      </AccordionSummary>
      <AccordionDetails
        className={`
          ${classes.content}
          ${contentContainerStyles}`}
      >
        {children}
      </AccordionDetails>
    </AccordionMUI>
  );
};

export default Accordion;
