import { Moment } from 'moment-timezone';

export interface UserNotification {
    id: number | undefined;
    uuid?: string;
    marketplaceId: number;
    receiptId?: number;
    appointmentId?: number;
    bookingId?: number;
    clientId?: number;
    bookingStatus?: number;
    subject: string | HTMLBodyElement;
    body: string | HTMLBodyElement;
    type: UserNotificationType;
    subtype?: UserNotificationSubtype;
    sentAt?: Moment;
    readAt?: Moment;
    updatedAt?: Moment;
    openedAt?: Moment;
    createdAt: Moment;
}

export enum UserNotificationType {
    BOOKING = 'Booking',
    INTAKE_FORM = 'IntakeForm',
    BOOKING_REQUEST = 'BookingRequest',
    NONE = 'None'
}

export enum UserNotificationSubtype {
    CONFIRMED = 'CONFIRMED',
    CANCELED = 'CANCELED'
}

export enum RecipientType {
    CUSTOMER = 'Customer',
    STAFF = 'Staff'
}
