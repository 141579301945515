import MasterData from "@spike/masterdata-model";
import { BusinessProduct } from "@spike/product-model";

export const getPriceBySize = (businessProduct: BusinessProduct | undefined, masterData: MasterData) => {
  const unit = getUnit(businessProduct, masterData);
  const price = Number(businessProduct?.pricing?.price);
  
  return price ? `$${price.toFixed(2)} / ${businessProduct?.size?.amount || ""}${unit}` : "-";
};

export const getUnit = (businessProduct: BusinessProduct | undefined, masterData: MasterData) => {
  return masterData.productUnits.find((unit) => unit.id === businessProduct?.size?.unit?.id)?.unit || "ea";
};
