import { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { SidebarPetMenuItem } from "./SidebarPetMenuItem";
import Pet from "@spike/pet-model";
import {
  faExclamationTriangle,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { vaccinesWarning, vaccinesAttention } from "components/PetCard/utils/PetCardUtils";
import clsx from "clsx";
import { reduceResolution, wbp } from "Theme";
import { useMarketplace } from "hooks";
import { petViewOptions } from "../PetCardModel";


interface SideBarPetMenuProps {
  pet: Pet;
  optionSelected: string;
  className?: string;
  onSelectMenu: (option: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    boxFlex: {
      display: "flex",
    },
    boxIcon: {
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(38)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "38px",
      },
    },
    sectionIconInfo: {
      color: "#EAB464",
      [theme.breakpoints.down(wbp)]: {
        marginLeft: `${reduceResolution(10)}px`,
        left: `${reduceResolution(10)}px`,
        fontSize: `${reduceResolution(16)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginLeft: "10px",
        left: "10px",
        fontSize: "16px",
      },
    },
  })
);



export const SidebarPetMenu: FunctionComponent<SideBarPetMenuProps> = (props) => {
  const classes = useStyles();

  const isDental = useMarketplace().isDental;

  const [option, setOption] = useState<string>(props.optionSelected);
  const [hasWarning, setHasWarning] = useState(false);

  const iconSVG = (icon: IconDefinition, styles: string) => {
    return <FontAwesomeIcon icon={icon} className={styles} />;
  };

  const selectMenuHandler = (option: string) => {
    setOption(option);
    props.onSelectMenu(option);
  }

  useEffect(() => {
    setOption(props.optionSelected);

    if (
      (vaccinesAttention(props.pet.medicalHistory.vaccines)) ||
      (vaccinesWarning(props.pet.medicalHistory.vaccines))
    ) {
      setHasWarning(true);
    }

  }, [props.optionSelected]);

  const optionVaccineWarning = (
    <Box className={classes.boxFlex}>
      Vaccines
      <Box className={classes.boxIcon}>{iconSVG(faExclamationTriangle, `${classes.sectionIconInfo}`)}</Box>
    </Box >)


  const filteredOptions = !isDental ? petViewOptions.filter(option => option.id !== 'dental') : petViewOptions

  const items = filteredOptions.map((item) => <SidebarPetMenuItem
    key={item.id}
    onSelect={() => selectMenuHandler(item.id)}
    option={item.name === 'Vaccines' ? hasWarning ? optionVaccineWarning : item.name : item.name}
    selected={option === item.id} />)
  return (
    <Box className={clsx(classes.container, props.className)}>
      {items}
    </Box>
  );
};



export default SidebarPetMenu;
