import { Box, createStyles, InputBase, makeStyles, Theme, Typography } from "@material-ui/core";
import { useNonInitialEffect } from "@versiondos/hooks";
import clsx from "clsx";
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useCommonStyles } from "./CommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-light-svg-icons";
import isEmpty from "lodash/isEmpty";
import { wbp } from "Theme";

interface InvoiceQuantityProps {
  quantity: number | undefined;
  className?: string;
  onChange?: (quantity: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down(wbp)]: {
        width: "72px",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "90px",
      },
    },
    input: {
      width: "30px",
      "& input": {
        textAlign: "center",
      },
    },
    icon: {
      cursor: "pointer",
      [theme.breakpoints.down(wbp)]: {
        width: "19px !important",
        height: "19px !important",
      },
      [theme.breakpoints.up(wbp)]: {
        width: "24px !important",
        height: "24px !important",
      },
    },
  })
);

export const InvoiceQuantity: FunctionComponent<InvoiceQuantityProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [value, setValue] = useState(props.quantity?.toString() || "0");

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange && props.onChange(Number(value));
    }, 200);
    return () => clearTimeout(timer);
  }, [value]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const text = event.target.value;
    const number = Number(text);

    if (text === "" || (!isNaN(number) && number >= 0)) {
      setValue(text);
    }
  };

  const convertToNumber = (value: string) => {
    return Number(isEmpty(value) ? 0 : Number(value));
  };
  const minusClickHandler = () => {
    setValue((prev) => (convertToNumber(prev) > 1 ? (convertToNumber(prev) - 1).toString() : prev));
  };

  const plusClickHandler = () => {
    setValue((prev) => (convertToNumber(prev) + 1).toString());
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <FontAwesomeIcon icon={faMinusCircle} className={classes.icon}  onClick={minusClickHandler}/>
      <InputBase className={clsx(commonClasses.inputText, classes.input)} value={value} onChange={changeHandler} />
      <FontAwesomeIcon icon={faPlusCircle} className={classes.icon} onClick={plusClickHandler}/>
    </Box>
  );
};

export default InvoiceQuantity;
