import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Switch, TextFieldWithButton } from 'components/UI';
import { BusinessSettingsTitle } from '../UI';
import { reduceResolution, wbp } from 'Theme';
import { useApiClientWrapper, useAuth, useMarketplace } from 'hooks';
import { ACCESS_LEVEL_ADMIN_ID } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
    changeIntakeFormStatusThunk,
    MarketplaceStatus
} from '@spike/marketplace-action';
import clsx from 'clsx';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';

export interface IntakeFormProps {
    isMobile?: boolean;
    openSidebar?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.only('md')]: {
                width: '368px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '658px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '900px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        headerContainer: {
            display: 'flex',
            [theme.breakpoints.only('md')]: {
                marginBottom: '2px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '6.5px'
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: '5px'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
        },
        businessSettingsTittle: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '5px'
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        row: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.only('md')]: {
                marginTop: '4px',
                marginBottom: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '13px',
                marginBottom: '13px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        rowInput: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    height: '52px !important',
                    fontSize: '16px',
                    fontWeight: '400',
                    textOverflow: 'ellipsis !important',
                    paddingRight: '5px !important'
                },
                '& button': {
                    'height': '37px',
                    'width': '100px',
                    'paddingLeft': '16px',
                    'paddingRight': '15px',
                    '& .MuiButton-label': {
                        'fontSize': '14px',
                        'fontWeight': '600',
                        '& .MuiButton-startIcon': {
                            'marginRight': '7px',
                            '& svg': {
                                width: '14px',
                                height: '14px'
                            }
                        }
                    }
                },
                '& fieldset': {
                    borderColor: '#f00 !important'
                }
            }
        },
        switchContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        subtitle: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '0px'
            }
        },
        alert: {
            color: '#000',
            fontWeight: 600,
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px',
                lineHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                lineHeight: '21px'
            },
            [theme.breakpoints.only('lg')]: {
                lineHeight: '28px'
            },
            [theme.breakpoints.only('xl')]: {
                lineHeight: '42px'
            }
        },
        alertContainer: {
            width: '100%',
            display: 'flex',
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',
            [theme.breakpoints.down('sm')]: {
                borderRadius: 18,
                padding: 17,
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: 20,
                padding: 15,
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        alertText: {
            color: '#000 !important',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '18px'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px',
                lineHeight: '22px'
            }
        },
        icon: {
            color: '#BAA997',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        sectionRow: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '22px'
            }
        }
    })
);

const IntakeForm: React.FC<IntakeFormProps> = props => {
    const auth = useAuth();
    const classes = useStyles();
    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const apiClientWrapper = useApiClientWrapper();

    const intakeFormUrl = `${process.env.REACT_APP_ONLINE_BOOKING_HOST_URL}${marketplace.onlineBooking.slug}/intake-form`;

    const [urlCopied, setUrlCopied] = React.useState(false);
    const [butonIcon, setButtonIcon] = React.useState(faCopy);
    const [butonMessage, setButtonMessage] = React.useState('Copy');
    const [enabled, setEnabled] = React.useState(
        marketplace.intakeForm.enabled
    );

    const marketplaceStatus = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );

    const isAdmin = auth.user!.accessLevel.id === ACCESS_LEVEL_ADMIN_ID;

    useNonInitialEffect(() => {
        if (marketplaceStatus === MarketplaceStatus.Error) {
            setEnabled(false);
        }
    }, [marketplaceStatus]);

    useNonInitialEffect(() => {
        setButtonMessage('Copied');
        setButtonIcon(faCheck);
    }, [urlCopied]);

    const changeEnableHandler = (checked: boolean) => {
        setEnabled(checked);
        dispatch(changeIntakeFormStatusThunk(apiClientWrapper, checked));
    };

    const copyToClipboard = () => {
        setUrlCopied(true);
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(intakeFormUrl);
        } else {
            document.execCommand('copy', true, intakeFormUrl);
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.headerContainer}>
                <Box className={classes.titleContainer}>
                    <BusinessSettingsTitle
                        className={classes.businessSettingsTittle}
                    >
                        {' '}
                        {props.isMobile && (
                            <FontAwesomeIcon
                                className={classes.iconBusinessTitle}
                                onClick={props.openSidebar}
                                icon={faArrowLeft}
                                size="2x"
                            />
                        )}{' '}
                        Intake Form
                    </BusinessSettingsTitle>
                </Box>
                <Box className={classes.switchContainer}>
                    {isAdmin && (
                        <Switch
                            checked={marketplace.intakeForm.enabled}
                            onChange={checked => changeEnableHandler(checked)}
                        ></Switch>
                    )}
                </Box>
            </Box>

            {enabled ? (
                <>
                    <Box className={classes.alertContainer}>
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={classes.icon}
                        />
                        <Box>
                            <Typography className={classes.alertText}>
                                Your intake form is currently enabled, allowing
                                pet parents to create client profiles
                                effortlessly.
                            </Typography>
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <Box className={classes.alertContainer}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={classes.icon}
                        />
                        <Box>
                            <Typography className={classes.alertText}>
                                Your intake form is disabled. Enable to allow
                                pet parents to create client profiles online.
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}

            {enabled && (
                <>
                    <Box className={classes.row}>
                        <Typography className={classes.subtitle}>
                            Share your intake form link today to connect with
                            clients and efficiently create their profiles
                        </Typography>
                    </Box>
                    <Box className={clsx(classes.row, classes.rowInput)}>
                        <TextFieldWithButton
                            value={intakeFormUrl}
                            label={butonMessage}
                            startIcon={butonIcon}
                            blackButton={true}
                            onClick={copyToClipboard}
                            classNameCustom="onlineBookingInput"
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default IntakeForm;
