import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
} from "react";
import { Box, InputAdornment, OutlinedInput } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

interface Props {
  viewSelected?: string;
  dashboard?: boolean;
  onSearch: (text: string) => void;
  expanded?: boolean;
  placeholder: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: Props) => ({
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        height: props.dashboard ? "34px" : "43px",
      },
      [theme.breakpoints.up("lg")]: {
        height: props.dashboard ? "43px" : "54px",
      },
    }),
    iconContainer: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      paddingLeft: "14px",
    },
    searchField: {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: "123px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "154px",
      },
      borderRadius: "100px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !important",
        borderColor: "#222222 !important",
      },
      "&:not(.Mui-error) input:not([value='']) ~ .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !imporant",
        borderColor: "#222222 !important",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#EA6464 !important",
      },
    },
    searchFieldExpanded: {
      width: "100%",
    },
    pointer: {
      cursor: "pointer",
    },
    fonts: {
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    iconFont: {
      color: "#7A7A7A"
    },
    iconSearch: {
      color: "#D3D3D3 !important"
    },
    searchFieldStatic: {
      width: "100% !important"
    }
  })
);

export const SearchComponent: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    setSearchText("");
  }, [props.viewSelected]);

  const iconClickHandler = (): void => {
    setSearchText("");
    setShowSearchBox((current) => {
      return !current;
    });
  };

  const changeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setSearchText(event.currentTarget.value);
  };

  const searchBox = (
    <OutlinedInput
      autoFocus
      placeholder={props.placeholder}
      value={searchText}
      className={clsx(classes.searchField, classes.pointer, classes.fonts, {[classes.searchFieldExpanded]: showSearchBox && !props.expanded, [classes.searchFieldStatic]: props.expanded})}
      onChange={changeHandler}
      startAdornment={
        <InputAdornment position="start">
          <FontAwesomeIcon
            icon={faSearch}
            className={clsx(classes.fonts, classes.iconFont, {[classes.iconSearch]: props.expanded})}
            onClick={iconClickHandler}
          />
        </InputAdornment>
      }
      onFocus={() => setShowSearchBox(true)}
      onBlur={() => setShowSearchBox(false)}
    />
  );

  return (
    <Box className={clsx(classes.container, props.className)}>
      {searchBox}
    </Box>
  );
};

export default SearchComponent;
