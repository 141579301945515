import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface ProgressBarProps {
  percentage: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      backgroundColor: "#DBDAD7",
      width: "100%",
      [theme.breakpoints.down(wbp)]: {
        height: `${reduceResolution(8)}px`,
        borderRadius: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        height: "8px",
        borderRadius: "8px",
      },
    },
    percentage: (props: ProgressBarProps) => ({
      fontFamily: "Poppins",
      fontWeight: 600,
      backgroundColor: "#5E8677",
      width: props.percentage + "%",
      height: "100%",
      borderRadius: "10px",
    }),
  })
);

export const ProgressBar: FunctionComponent<ProgressBarProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      <Box className={classes.percentage}></Box>
    </Box>
  );
};

export default ProgressBar;
