import { FunctionComponent, useState } from 'react';
import {
    Box,
    createStyles,
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import { BusinessPlanPath } from 'routes/Paths';
import TitlePrimary from './Title/TitlePrimary';
import { Img } from 'react-image';

interface PremiumFeatureAlertProps {
    isOpen: boolean;
    onCancel: () => void;
    text: string | JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            '& .MuiDialog-paper': {
                maxWidth: 'unset',
                overflow: 'hidden',
                maxHeight: 'unset'
            }
        },
        root: {
            position: 'relative',
            overflow: 'hidden',
            background: '#FAEFDF',
            borderRadius: 30,
            width: 684,
            minHeight: 390,
            paddingLeft: 45,
            paddingTop: 65,
            paddingBottom: 30,
            [theme.breakpoints.down('sm')]: {
                width: 346,
                height: 600,
                paddingLeft: 0,
                paddingTop: 40
            }
        },
        body: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        title: {
            color: '#000',
            paddingTop: 20,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                fontSize: 32
            }
        },
        content: {
            heigth: '100%',
            width: '45%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        text: {
            padding: '5px 0px 25px 0px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 23px',
                textAlign: 'center'
            }
        },
        imageContainer: {
            width: '50%',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        image: {
            width: '120%',
            position: 'absolute',
            top: 50,
            left: 0,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                top: 0,
                left: 0
            }
        },
        ButtonsContainer: {
            width: '100%',
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 10,
                margin: '15px 0 20px'
            }
        },
        submitBtn: {
            'padding': '26px 20px',
            '& > span': {
                fontSize: '18px !important',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px !important'
                }
            },
            [theme.breakpoints.down('sm')]: {}
        },
        cancelBtn: {
            'padding': 0,
            'color': '#222',
            'borderRadius': 0,
            'minWidth': 'unset',
            'backgroundColor': 'transparent !important',
            'borderBottom': 'solid 1px #222',
            'height': 25,
            '& span': {
                fontSize: 16,
                lineHeight: 1.25
            },
            '&:hover': {
                color: '#222',
                backgroundColor: 'transparent !important'
            }
        }
    })
);

const PremiumFeatureAlert: FunctionComponent<
    PremiumFeatureAlertProps
> = props => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

    const cancelHandler = () => {
        props.onCancel && props.onCancel();
        setIsOpen(false);
    };

    const confirmHandler = () => {
        history.push(BusinessPlanPath);
    };

    const history = useHistory();

    const buttons = (
        <Box className={classes.ButtonsContainer}>
            <Button
                variant="primary"
                color="orange"
                disabled={false}
                onClick={confirmHandler}
                label={'Upgrade Today'}
                className={classes.submitBtn}
            />
            <Button
                variant="primary"
                color="black"
                disabled={false}
                onClick={cancelHandler}
                label={'No, thanks'}
                className={classes.cancelBtn}
            />
        </Box>
    );

    return (
        <Dialog open={isOpen} className={classes.wrapper}>
            <DialogContent className={classes.root}>
                <Box className={classes.body}>
                    <Box className={classes.content}>
                        <TitlePrimary className={classes.title}>
                            Premium
                            <br />
                            Feature <Img src="images/common/icon-stars.svg" />
                        </TitlePrimary>
                        <Typography className={classes.text}>
                            {props.text}
                        </Typography>
                        {buttons}
                    </Box>
                    <Box className={classes.imageContainer}>
                        <Img
                            src="images/premium/not-premium-doggie.png"
                            className={classes.image}
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PremiumFeatureAlert;
