import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	ClickAwayListener,
	createStyles,
	InputBase,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { FieldError, Option } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import { InvoiceDiscount as InvoiceDiscountModel } from '@spike/invoice-model';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useCommonStyles } from 'components/UI/CommonStyles';
import { wbp } from 'Theme';

interface DiscountProps {
	discount?: InvoiceDiscountModel;
	className?: string;
	name?: string;
	errors?: Array<FieldError>;
	onChange?: (discount: InvoiceDiscountModel, name?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: 36,
			width: 150,
			display: 'flex',
			position: 'relative',
			alignItems: 'center',
			borderRadius: '100px',
			border: '1px solid #222222',

			[theme.breakpoints.up('md')]: {
				width: 180,
				height: 52
			}
		},
		valueContainer: {
			width: '60%',
			display: 'flex',
			alignContent: 'center',
			paddingLeft: 12,

			[theme.breakpoints.up('md')]: {
				paddingLeft: '19px'
			}
		},
		dropdownContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '40%',
			borderLeft: '1px solid #F1F1F1',
			height: '100%',
			cursor: 'pointer'
		},
		chevron: {
			width: 12,
			height: 12,
			marginLeft: 5,

			[theme.breakpoints.up('md')]: {
				width: 11,
				height: 11
			}
		},
		text: {
			fontSize: 14,
			lineHeight: 1,
			fontWeight: 400,
			color: '#222222',
			textTransform: 'capitalize',

			[theme.breakpoints.up('md')]: {
				fontSize: 16
			}
		},
		adornment: {
			marginTop: '-1px'
		},
		list: {
			position: 'absolute',
			right: 0,
			top: 0,
			marginTop: -1,
			marginRight: -1,
			zIndex: 10,
			border: '1px solid #000000',
			backgroundColor: '#FFFFFF',
			[theme.breakpoints.down(wbp)]: {
				borderRadius: '11px',
				padding: '10px 0px',
				width: '60px'
			},
			[theme.breakpoints.up(wbp)]: {
				borderRadius: '14px',
				padding: '12px 0px',
				width: '75px'
			}
		},
		listItem: {
			'cursor': 'pointer',
			'fontFamily': 'Poppins',
			'fontWeight': 400,
			'color': '#222222',
			'textTransform': 'uppercase',
			'textAlign': 'center',
			'width': '100%',
			[theme.breakpoints.down(wbp)]: {
				fontSize: '14px',
				lineHeight: '22px'
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: '18px',
				lineHeight: '27px'
			},
			'&:hover': {
				backgroundColor: '#F4F4F4'
			}
		},
		inputRight: {
			'& input': {
				paddingRight: 11,
				textAlign: 'right'
			}
		}
	})
);

const usd: Option<string> = { id: 'usd', name: 'USD', description: '$' };
const percentage: Option<string> = { id: '%', name: '%', description: '%' };

const options: Array<Option<string>> = [usd, percentage];

export const Discount: FunctionComponent<DiscountProps> = props => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const [selected, setSelected] = useState<Option<string>>(
		props.discount?.percentage ? percentage : usd
	);
	const [showList, setShowList] = useState(false);
	const [value, setValue] = useState(
		props.discount?.percentage?.toString() ||
			props.discount?.amount?.toString() ||
			'0.00'
	);

	const hasError = props.errors?.some(
		error => error.fieldName === props.name
	);

	useNonInitialEffect(() => {
		const timer: NodeJS.Timer = setTimeout(() => {
			props.onChange &&
				props.onChange(
					selected.id === usd.id
						? {
								amount: value
						  }
						: {
								percentage: Number(value)
						  },
					props.name
				);
		}, 200);
		return () => clearTimeout(timer);
	}, [value]);

	const selectHandler = (option: Option<string>) => {
		setSelected(option);
		setValue('0');
		setShowList(false);
	};

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();

		const text = event.target.value;
		const number = Number(text);

		if (text === '' || (!isNaN(number) && number >= 0)) {
			setValue(text);
		}
	};

	const dollarAdornment =
		selected.id === usd.id ? (
			<Typography
				className={clsx(commonClasses.typo16_400, classes.adornment)}
			>
				$
			</Typography>
		) : null;

	return (
		<Box
			className={clsx(classes.container, props.className, {
				[commonClasses.errorBorder]: hasError,
				[commonClasses.errorText]: hasError
			})}
		>
			<Box className={classes.valueContainer}>
				<InputBase
					className={clsx(
						commonClasses.typo16_400,
						classes.inputRight,
						{ [commonClasses.errorText]: hasError }
					)}
					value={value}
					placeholder="0.00"
					onChange={changeHandler}
					startAdornment={dollarAdornment}
				/>
			</Box>
			<Box
				className={classes.dropdownContainer}
				onClick={() => setShowList(true)}
			>
				<Typography
					className={clsx(classes.text, {
						[commonClasses.errorText]: hasError
					})}
				>
					{selected.name}
				</Typography>
				<FontAwesomeIcon
					icon={faChevronDown}
					className={classes.chevron}
				/>
			</Box>
			{showList && (
				<ClickAwayListener onClickAway={() => setShowList(false)}>
					<Box className={classes.list}>
						{options.map(option => (
							<Typography
								key={option.id}
								className={classes.listItem}
								onClick={() => selectHandler(option)}
							>
								{option.name}
							</Typography>
						))}
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};

export default Discount;
